type Props = {
  color: string
  width?: number
  height?: number
}

export const EditIcon: React.FC<Props> = ({ color, width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7773 2.22248C16.74 1.18516 15.0582 1.18516 14.0208 2.22248L12.6816 3.56175L16.438 7.31822L17.7773 5.97896C18.8146 4.94163 18.8146 3.2598 17.7773 2.22248ZM15.4271 8.32915L11.6706 4.57267L3.10246 13.1409C2.5682 13.6751 2.20695 14.3577 2.06566 15.0999L1.66133 17.2239C1.53556 17.8846 2.11518 18.4642 2.77587 18.3385L4.89986 17.9341C5.64209 17.7928 6.32467 17.4316 6.85894 16.8973L15.4271 8.32915Z"
        fill={color}
      />
    </svg>
  )
}
