import { Benchmark, ItemBenchmark } from "@modules/benchmark/benchmarkTypes"
import { ItemBox } from "@modules/box/boxTypes"
import { ExploreContentType, ExploreFilter } from "@modules/explore/exploreTypes"
import { Profile } from "@modules/profile/profileTypes"
import { ItemProgram, ProgramTypeOf } from "@modules/programs/programTypes"
import { useMemo } from "react"
import backgroundBenchmark from "@images/backgroundBenchmark.png"
import backgroundProfile from "@images/backgroundProfile.png"
import { BoxIcon } from "@images/svgIcons/boxIcon"
import { BenchmarkIcon } from "@images/svgIcons/benchmarkIcon"
import { ProfileIcon } from "@images/svgIcons/profileIcon"
import { InfinityIcon } from "@images/svgIcons/infinityIcon"
import { OnOffIcon } from "@images/svgIcons/onOffIcon"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { useTheme } from "styled-components"

export type HuProgramMiniCardsProps = {
  item: ItemProgram | ItemBox | ItemBenchmark | Benchmark | Profile
  onViewDetailsClick: (itemId: string, typeOf: ExploreContentType, programType?: ProgramTypeOf) => void
  isSelected: boolean
  className?: string
  backgroundImage?: string
} & React.HTMLAttributes<HTMLDivElement>

export const HuProgramMiniCards: React.FC<HuProgramMiniCardsProps> = ({
  item,
  onViewDetailsClick,
  isSelected,
  className = "",
  backgroundImage = "",
  ...props
}) => {
  const theme = useTheme()


  const itemImage = useMemo(() => {
    if (backgroundImage !== "") {
      return backgroundImage
    }

    switch (item._type) {
      case ExploreFilter.PROGRAM:
        return item.image.path
      case ExploreFilter.BOX:
        return item.cover.path
      case ExploreFilter.BENCHMARK:
        return backgroundBenchmark
      case ExploreFilter.PROFILE:
        return backgroundProfile
    }
  }, [item])

  const itemTitle = useMemo(() => {
    switch (item._type) {
      case ExploreFilter.PROGRAM:
        return item.title
      case ExploreFilter.BOX:
        return item.name
      case ExploreFilter.BENCHMARK:
        return item.title
    }
  }, [item])

  const itemIcon = useMemo(() => {
    switch (item._type) {
      case ExploreFilter.PROGRAM:
        if (item._programType === ProgramInfiniteTypeOf) {
          return <InfinityIcon color={theme.mainWhite}  width={15} height={15} />
        } else return <OnOffIcon color={theme.mainWhite} width={15} height={15} />
      case ExploreFilter.BOX:
        return <BoxIcon color={theme.primaryColor} width={15} height={15}/>
      case ExploreFilter.BENCHMARK:
        return <BenchmarkIcon color={theme.mainWhite} width={15} height={15}/>
      case ExploreFilter.PROFILE:
        return <ProfileIcon color={theme.mainWhite} width={15} height={15} />
    }
  }, [item])

  const defaultClassName =
    "flex flex-column justify-content-between w-12rem h-10rem border-round-3xl bg-cover bg-center"
  const styleMaindiv = {
    background: ` linear-gradient(180deg, rgba(34, 34, 34, 0) 33%, rgba(34, 34, 34, 1) 100%) , url(${itemImage})`,
  }
  
  return (
    <div
      onClick={() => {
        if (item._type && isSelected) {
          const programType = "_programType" in item ? item._programType : undefined
          onViewDetailsClick(item.id, item._type, programType)
        }
      }}
      style={styleMaindiv}
      className={className ? className : defaultClassName}
      {...props}
    >
      <div className=" m-2 align-items-center justify-content-center border-round-3xl">{itemIcon}</div>
      <div className="p-2 ml-2">{itemTitle}</div>
    </div>
  )
}
