import { LogoHustleUpSmall } from "@images/logoHustleUpSmall"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { DefaultTheme } from "styled-components"
import OnboardingBoxStripe from "@images/payment/onboardingBoxStripe.svg"
import { HUTag } from "@modules/ui/components/huTag"
import { WarningIcon } from "@images/svgIcons/warningIcon"
import { HUButton } from "@modules/ui/components/huButton"
import { SmallArrowIcon } from "@images/svgIcons/arrowIcon"

export const BoxOnboardingStripeFirstPage = (
  theme: DefaultTheme,
  handleButtonNavigation: (onNext: boolean) => void,
) => {
  return (
    <div className="relative w-full">
      {/* HEARDER */}
      <div className="flex align-items-center flex-column w-full mt-5 px-3 text-center">
        <div>
          <LogoHustleUpSmall color={theme.mainWhite} />
        </div>
        <HUText fontStyle="HL" color={theme.mainWhite} className="mt-3 ">
          {word("box.stripe.onboarding.title")}
        </HUText>
        <HUText fontStyle="BL" color={theme.mainWhite} className="mt-1">
          {word("box.stripe.onboarding.screen1.subtitle")}
        </HUText>
      </div>
      {/* CONTENT */}
      <div className="flex sm:flex-row flex-column justify-content-center align-items-center sm:my-8 my-4 gap-3">
        {/* ILLUSTRATION FOR SMALL DEVICE  */}
        <div className="flex align-items-center justify-content-center sm:hidden " style={{ width: "318px" }}>
          <img src={OnboardingBoxStripe} width={198} height={114} />
        </div>
        {/* LEFT */}
        <div className="flex flex-column" style={{ width: "318px" }}>
          <HUText fontStyle="HM" color={theme.mainWhite}>
            {word("box.stripe.onboarding.screen1.content")}
          </HUText>
          <HUTag className="mt-5" style={{ width: "318px", height: "50px" }}>
            <WarningIcon color={theme.neutralColor100} width={30} height={30} />
            <HUText fontStyle="BS" color={theme.neutralColor100}>
              {word("box.stripe.onboarding.screen1.tag")}
            </HUText>
          </HUTag>
        </div>
        {/* RIGHT */}
        <div className="sm:flex hidden " style={{ width: "318px" }}>
          <img src={OnboardingBoxStripe} width={396} height={229} />
        </div>
      </div>
      {/* BUTTON */}
      <div className="flex flex-column align-items-center sm:mb-4 mb-8 sm:pt-0 pt-4">
        <HUButton
          type="Right_Icon"
          size="M"
          colorType="Primary"
          icon={{ icon: () => <SmallArrowIcon color={theme.neutralColor900} width={14} height={14} /> }}
          text={word("box.stripe.onboarding.screen1.button")}
          onClick={() => handleButtonNavigation(false)}
        />
      </div>
    </div>
  )
}
