import { BoxPlanningApi } from "@modules/boxPlanning/services/boxPlanningApi"
import { ItemBoxSlot } from "@modules/boxSlot/boxSlotTypes"
import { CurrentSpaceManager } from "@modules/core/services/currentSpaceManager"
import { WebService } from "@modules/core/services/webService"
import { UserService } from "@modules/profile/services/userService"
import dayjs from "dayjs"
import { observable } from "micro-observables"

export class BoxPlanningService implements WebService {
  private _planningSlots = observable<ItemBoxSlot[]>([])
  readonly planningSlots = this._planningSlots.readOnly()
  private _isLoadingSlots = observable<boolean>(true)
  readonly isLoadingSlots = this._isLoadingSlots.readOnly()

  constructor(
    private api: BoxPlanningApi,
    private currentSpaceManager: CurrentSpaceManager,
    private useService: UserService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedIn() {
    /*  this.updatePlanningSlotsWithActualDate()*/
  }

  async onSpaceChange(): Promise<void> {
    this._planningSlots.set([])
    this.updatePlanningSlotsWithActualDate()
  }

  async onLoggedOut(): Promise<void> {
    this._planningSlots.set([])
  }

  async getPlanningSlots(startDate: string, endDate: string, programId?: string) {
    const result = await this.api.getSlots(startDate, endDate, programId)
    return result
  }

  async updatePlanningSlots(startDate: string, endDate: string, programId?: string) {
    this._isLoadingSlots.set(true)
    const result = await this.getPlanningSlots(startDate, endDate, programId)
    this._planningSlots.set(result)
    this._isLoadingSlots.set(false)
  }

  private updatePlanningSlotsWithActualDate() {
    if (!!this.currentSpaceManager.boxId && this.useService.isLoggedIn()) {
      this.updatePlanningSlots(dayjs().startOf("month").toISOString(), dayjs().endOf("month").toISOString())
    }
  }
}
