import { ArticleDto, convertArticleSchemaDtoToArticle } from "@modules/article/gateway/articleApiDto"
import { ArticleApiGateway } from "@modules/article/gateway/articleApiGateway"
import { i18n } from "@modules/core/utils/i18n"
import { SanityService } from "@modules/services/sanityService"

export class SanityArticleApi implements ArticleApiGateway {
  private lastCreatedAt?: string
  private lastId?: string
  private lastPage: number = 0

  async getArticles({ page }: { page: number }): Promise<{ page: number; content: ArticleDto[] }> {
    const language = i18n.locale

    if (page === 1) {
      const result = await SanityService.client.fetch<ArticleDto[]>(
        `*[_type == 'article' && language == 'en'] | order(_createdAt desc) [0...10] {
          ...,
          ...*[_type == "translation.metadata" && references(^._id)][0].translations[value->language == $language][0].value->{
            ...,
          },
        }`,
        {
          language,
        },
      )

      if (result.length === 10) {
        this.lastCreatedAt = result[result.length - 1]._createdAt
        this.lastId = result[result.length - 1]._id
        this.lastPage = 1
      } else {
        this.lastId = "end"
        this.lastPage = 1
      }

      return {
        page,
        content: result.map((articleDto) => convertArticleSchemaDtoToArticle(articleDto)),
      }
    }

    if (page > 1 && page === this.lastPage + 1) {
      if (this.lastId === "end") {
        return Promise.resolve({ page, content: [] })
      }

      if (this.lastCreatedAt && this.lastId) {
        const result = await SanityService.client.fetch<ArticleDto[]>(
          `*[_type == 'article' && language == 'en'] $$ (_createdAt < $lastCreatedAt || (_createdAt == $lastCreatedAt && _id < $lastId) | order(_createdAt desc) [0...10] {
            ...,
            ...*[_type == "translation.metadata" && references(^._id)][0].translations[value->language == $language][0].  value->{
              ...,
            },
          }`,
          {
            lastCreatedAt: this.lastCreatedAt,
            lastId: this.lastId,
            language,
          },
        )

        if (result.length === 10) {
          this.lastCreatedAt = result[result.length - 1]._createdAt
          this.lastId = result[result.length - 1]._id
          this.lastPage = page
        } else {
          this.lastId = "end"
          this.lastPage = page
        }

        return {
          page,
          content: result.map((articleDto) => convertArticleSchemaDtoToArticle(articleDto)),
        }
      }
    }

    return Promise.resolve({ page, content: [] })
  }
}
