import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const TypicalWeekIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_8544_50969)">
      <path
        d="M10.5536 4.12137H9.09684C8.83926 4.12137 8.59223 4.01905 8.41009 3.83691C8.22796 3.65477 8.12563 3.40775 8.12563 3.15017V1.69336M3.75521 4.60697V8.88027C3.75521 9.02595 3.85233 9.17163 3.94945 9.26875C4.04658 9.36587 4.19226 9.46299 4.33794 9.46299H7.64003M1.81281 6.54938V10.8227C1.81281 10.9684 1.90993 11.114 2.00705 11.2112C2.10417 11.3083 2.24985 11.4054 2.39553 11.4054H5.69762M10.5536 3.63577V6.79218C10.5536 7.18066 10.2137 7.52059 9.82524 7.52059H6.42603C6.03754 7.52059 5.69762 7.18066 5.69762 6.79218V2.42176C5.69762 2.03328 6.03754 1.69336 6.42603 1.69336H8.61124L10.5536 3.63577Z"
        stroke={props.color}
        strokeWidth="1.24869"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8544_50969">
        <rect width="11.6545" height="11.6545" fill="white" transform="translate(0.355957 0.722656)" />
      </clipPath>
    </defs>
  </svg>
)
