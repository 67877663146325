import { Badge } from "primereact/badge"
import { Avatar, AvatarProps } from "stream-chat-react"
import styled from "styled-components"

type ChatAvatarProps = {
  isOnline?: boolean
} & AvatarProps

export const ChatAvatar: React.FC<ChatAvatarProps> = (props) => {
  return (
    <Container>
      <Avatar {...props} />
      {props.isOnline && (
        <BadgeContainer>
          <Badge severity="success" size="normal" />
        </BadgeContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`
const BadgeContainer = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  background-color: white;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .p-badge {
    width: 0.6rem;
    min-width: 0.6rem;
    height: 0.6rem;
  }
`
