import { BoxProgrammationTypeOf } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxProgrammationSession } from "@modules/boxSlot/services/boxSlotDto"
import { Service } from "@modules/core/services/serviceType"
import {
  ProgramInfiniteSession,
  ProgramInfiniteSessionDraft,
  ProgramOnOffSession,
  ProgramOnOffSessionDraft,
} from "@modules/programSession/programSessionTypes"
import { ProgramInfiniteSessionApi } from "@modules/programSession/services/programInfiniteSessionApi"
import { ProgramOnOffSessionApi } from "@modules/programSession/services/programOnOffSessionApi"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"

export class ProgramSessionService implements Service {
  private copySession: ProgramInfiniteSession | ProgramOnOffSession | BoxProgrammationSession | undefined

  constructor(
    private programInfiniteSessionApi: ProgramInfiniteSessionApi,
    private programOnOffSessionApi: ProgramOnOffSessionApi,
  ) {}

  setCopySession = (sessionToCopy: ProgramInfiniteSession | ProgramOnOffSession | BoxProgrammationSession) => {
    this.copySession = sessionToCopy
  }

  getCopySession = () => {
    return this.copySession
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onLoggedIn(): Promise<void> {}

  isProgramOnOffSessionDraft(
    session: ProgramInfiniteSessionDraft | ProgramOnOffSessionDraft,
  ): session is ProgramOnOffSessionDraft {
    return (session as ProgramOnOffSessionDraft).restDay !== undefined
  }

  async getDetailedSession(
    id: string,
    programId: string,
    programType: typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf,
    sessionDate?: string,
  ) {
    if (programType === ProgramInfiniteTypeOf) return this.programInfiniteSessionApi.getDetailedSession(id)
    else if (programType === ProgramOnOffTypeOf)
      return this.programOnOffSessionApi.getDetailedSession(id, programId, sessionDate)
  }

  async createSession(session: ProgramInfiniteSessionDraft | ProgramOnOffSessionDraft): Promise<string | undefined> {
    if (this.isProgramOnOffSessionDraft(session)) {
      const programSession = await this.programOnOffSessionApi.createSession(session)
      return programSession.id
    } else {
      const programSession = await this.programInfiniteSessionApi.createSession(session)
      return programSession.id
    }
  }

  async updateSession(
    id: string,
    session: ProgramInfiniteSessionDraft | ProgramOnOffSessionDraft,
  ): Promise<string | undefined> {
    if (this.isProgramOnOffSessionDraft(session)) {
      const programSession = await this.programOnOffSessionApi.updateSession(id, session)
      return programSession.id
    } else {
      const programSession = await this.programInfiniteSessionApi.updateSession(id, session)
      return programSession.id
    }
  }

  async deleteSession(
    id: string,
    programId: string,
    programType: typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf | typeof BoxProgrammationTypeOf,
  ) {
    if (programType === ProgramInfiniteTypeOf) await this.programInfiniteSessionApi.deleteSession(id)
    else if (programType === ProgramOnOffTypeOf) await this.programOnOffSessionApi.deleteSession(id, programId)
  }

  async changeSessionIndex(id: string, position: number) {
    await this.programInfiniteSessionApi.changeSessionIndex(id, position)
  }
}
