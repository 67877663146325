import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ViewProgrammationIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_8545_52117)">
      <path
        d="M9.73038 2.78298L8.55813 1.61073C8.37604 1.42859 8.12905 1.32623 7.87149 1.32617H3.90315C3.64557 1.32617 3.39854 1.42849 3.2164 1.61063C3.03427 1.79277 2.93195 2.0398 2.93195 2.29738V10.067C2.93195 10.3246 3.03427 10.5716 3.2164 10.7538C3.39854 10.9359 3.64557 11.0382 3.90315 11.0382H9.73038C9.98796 11.0382 10.235 10.9359 10.4171 10.7538C10.5993 10.5716 10.7016 10.3246 10.7016 10.067M4.87435 9.09581H5.35996M11.3707 6.48618C11.5642 6.29274 11.6729 6.03037 11.6729 5.75681C11.6729 5.48324 11.5642 5.22087 11.3707 5.02743C11.1773 4.83399 10.9149 4.72531 10.6414 4.72531C10.3678 4.72531 10.1054 4.83399 9.91199 5.02743L7.96473 6.97567C7.84927 7.09106 7.76477 7.23368 7.71901 7.39037L7.31256 8.78405C7.30038 8.82583 7.29965 8.87013 7.31045 8.91229C7.32125 8.95446 7.34319 8.99294 7.37397 9.02372C7.40474 9.0545 7.44323 9.07644 7.48539 9.08724C7.52756 9.09804 7.57185 9.09731 7.61364 9.08512L9.00731 8.67867C9.164 8.63292 9.30663 8.54841 9.42202 8.43296L11.3707 6.48618Z"
        stroke={props.color}
        strokeWidth="1.24869"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8545_52117">
        <rect width="11.6545" height="11.6545" fill={props.color} transform="translate(0.989502 0.355469)" />
      </clipPath>
    </defs>
  </svg>
)
