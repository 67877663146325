import { AppBannerService } from "@modules/appBanner/services/appBannerService"
import { ArticleApiGateway } from "@modules/article/gateway/articleApiGateway"
import { SanityArticleApi } from "@modules/article/gateway/sanityArticleApi"
import { AuthService } from "@modules/auth/services/authService"
import { BenchmarkApi } from "@modules/benchmark/services/benchmarkApi"
import { BenchmarkService } from "@modules/benchmark/services/benchmarkService"
import { BoxApi } from "@modules/box/services/boxApi"
import { BoxService } from "@modules/box/services/boxService"
import { BoxB2BProgramApi } from "@modules/boxB2BProgram/services/boxB2BProgramApi"
import { BoxB2BProgramService } from "@modules/boxB2BProgram/services/boxB2BProgramService"
import { OfferApi } from "@modules/boxOffer/services/boxOfferApi"
import { BoxOfferService } from "@modules/boxOffer/services/boxOfferService"
import { BoxPlanningApi } from "@modules/boxPlanning/services/boxPlanningApi"
import { BoxPlanningService } from "@modules/boxPlanning/services/boxPlanningService"
import { BoxProgrammationApi } from "@modules/boxProgrammation/services/boxProgrammationApi"
import { BoxProgrammationService } from "@modules/boxProgrammation/services/boxProgrammationService"
import { BoxProgrammationTemplatePopUpService } from "@modules/boxProgrammation/services/boxProgrammationTemplatePopUpService"
import { BoxSlotApi } from "@modules/boxSlot/services/boxSlotApi"
import { BoxSlotService } from "@modules/boxSlot/services/boxSlotService"
import { BoxSlotTemplateApi } from "@modules/boxSlotTemplate/services/boxSlotTemplateApi"
import { BoxSlotTemplateService } from "@modules/boxSlotTemplate/services/boxSlotTemplateService"
import { BoxStaffApi } from "@modules/boxStaff/services/boxStaffApi"
import { BoxStaffService } from "@modules/boxStaff/services/boxStaffService"
import { ChatApi } from "@modules/chat/services/chatApi"
import { ChatService } from "@modules/chat/services/chatService"
import { Config } from "@modules/config"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { ApiService } from "@modules/core/services/apiService"
import { ConfigService } from "@modules/core/services/configService"
import { CurrentSpaceManager } from "@modules/core/services/currentSpaceManager"
import { DateService } from "@modules/core/services/dateService"
import { WebServiceManager } from "@modules/core/services/webServiceManager"
import { ExerciseApi } from "@modules/exercises/services/exerciseApi"
import { ExerciseService } from "@modules/exercises/services/exerciseService"
import { ExploreApi } from "@modules/explore/services/exploreApi"
import { ExploreService } from "@modules/explore/services/exploreService"
import { FeatureToggleService } from "@modules/featureToggle/services/featureToggleService"
import { BoxFinanceApi } from "@modules/finances/services/boxFinanceApi"
import { BoxFinanceService } from "@modules/finances/services/boxFinanceService"
import { UserFinanceApi } from "@modules/finances/services/userFinanceApi"
import { UserFinanceService } from "@modules/finances/services/userFinanceService"
import { LanguageService } from "@modules/language/services/languageService"
import { MemberApi } from "@modules/members/services/memberApi"
import { MemberService } from "@modules/members/services/memberService"
import { ObjectivesApi } from "@modules/objectives/services/objectivesApi"
import { ObjectivesService } from "@modules/objectives/services/objectivesService"
import { PaymentApi } from "@modules/payment/services/paymentApi"
import { PaymentService } from "@modules/payment/services/paymentService"
import { MyProfileService } from "@modules/profile/myProfile/service/myProfile"
import { UserApi } from "@modules/profile/services/userApi"
import { UserService } from "@modules/profile/services/userService"
import { UserProfileService } from "@modules/profile/userProfile/services/userProfileService"
import { ProgramInfiniteSessionApi } from "@modules/programSession/services/programInfiniteSessionApi"
import { ProgramOnOffSessionApi } from "@modules/programSession/services/programOnOffSessionApi"
import { ProgramSessionService } from "@modules/programSession/services/programSessionService"
import { ProgramApi } from "@modules/programs/services/programApi"
import { ProgramInfiniteApi } from "@modules/programs/services/programInfiniteApi"
import { ProgramInfiniteService } from "@modules/programs/services/programInfiniteService"
import { ProgramOnOffApi } from "@modules/programs/services/programOnOffApi"
import { ProgramOnOffService } from "@modules/programs/services/programOnOffService"
import { ProgramService } from "@modules/programs/services/programService"
import { PromoCodeApi } from "@modules/promoCode/services/promoCodeApi"
import { PromoCodeService } from "@modules/promoCode/services/promoCodeService"
import { RemoteConfigService } from "@modules/remoteConfig/services/remoteConfigService"
import { ScoreboardApi } from "@modules/scoreboard/services/scoreboardApi"
import { ScoreboardService } from "@modules/scoreboard/services/scoreboardService"
import { AssetApi } from "@modules/services/assetApi"
import { AssetService } from "@modules/services/assetService"
import { LocalStorageService } from "@modules/storage/services/localStorageService"
import { WebStorage } from "@modules/storage/storage"
import { PlanningService } from "@modules/training/services/planningService"
import { TrainingApi } from "@modules/training/services/trainingApi"
import { TrainingService } from "@modules/training/services/trainingService"

export const getAllServices = ({
  customAuthService,
  customApiClient,
  customUserApi,
  customBoxApi,
  customBoxProgrammationApi,
  customChatApi,
  customMemberApi,
  customOfferApi,
  customAssetApi,
  customProgramApi,
  customProgramInfiniteApi,
  customProgramOnOffApi,
  customProgramInfiniteSessionApi,
  customProgramOnOffSessionApi,
  customExploreApi,
  customArticleGateway,
  customScoreboardApi,
  customBenchmarkApi,
  customObjectivesApi,
  customBoxPlanningApi,
  customBoxSlotTemplateApi,
  customBoxB2BProgramApi,
  customBoxSlotApi,
  customPaymentApi,
  customBoxFinanceApi,
  customUserFinanceApi,
  customBoxStaffApi,
  customTrainingApi,
  customExerciseApi,
  customPromoCodeApi,
}: {
  customAuthService?: AuthService
  customApiClient?: ApiClient
  customUserApi?: UserApi
  customBoxApi?: BoxApi
  customBoxProgrammationApi?: BoxProgrammationApi
  customChatApi?: ChatApi
  customMemberApi?: MemberApi
  customOfferApi?: OfferApi
  customAssetApi?: AssetApi
  customProgramApi?: ProgramApi
  customProgramInfiniteApi?: ProgramInfiniteApi
  customProgramOnOffApi?: ProgramOnOffApi
  customProgramInfiniteSessionApi?: ProgramInfiniteSessionApi
  customProgramOnOffSessionApi?: ProgramOnOffSessionApi
  customExploreApi?: ExploreApi
  customArticleGateway?: ArticleApiGateway
  customScoreboardApi?: ScoreboardApi
  customBenchmarkApi?: BenchmarkApi
  customObjectivesApi?: ObjectivesApi
  customBoxPlanningApi?: BoxPlanningApi
  customBoxSlotTemplateApi?: BoxSlotTemplateApi
  customBoxB2BProgramApi?: BoxB2BProgramApi
  customBoxSlotApi?: BoxSlotApi
  customPaymentApi?: PaymentApi
  customBoxFinanceApi?: BoxFinanceApi
  customUserFinanceApi?: UserFinanceApi
  customBoxStaffApi?: BoxStaffApi
  customTrainingApi?: TrainingApi
  customExerciseApi?: ExerciseApi
  customPromoCodeApi?: PromoCodeApi
} = {}) => {
  ConfigService.setConfig(Config)
  ConfigService.setDevMode(import.meta.env.DEV)

  const apiClient = customApiClient || new ApiClient()

  const userApi = customUserApi || new UserApi(apiClient)
  const boxApi = customBoxApi || new BoxApi(apiClient)
  const boxProgrammationApi = customBoxProgrammationApi || new BoxProgrammationApi(apiClient)
  const chatApi = customChatApi || new ChatApi(apiClient)
  const memberApi = customMemberApi || new MemberApi(apiClient)
  const offerApi = customOfferApi || new OfferApi(apiClient)
  const assetApi = customAssetApi || new AssetApi(apiClient)
  const programApi = customProgramApi || new ProgramApi(apiClient)
  const programInfiniteApi = customProgramInfiniteApi || new ProgramInfiniteApi(apiClient)
  const programOnOffApi = customProgramOnOffApi || new ProgramOnOffApi(apiClient)
  const programInfiniteSessionApi = customProgramInfiniteSessionApi || new ProgramInfiniteSessionApi(apiClient)
  const programOnOffSessionApi = customProgramOnOffSessionApi || new ProgramOnOffSessionApi(apiClient)
  const exploreApi = customExploreApi ?? new ExploreApi(apiClient)
  const articleApi = customArticleGateway ?? new SanityArticleApi()
  const scoreboardApi = customScoreboardApi ?? new ScoreboardApi(apiClient)
  const benchmarkApi = customBenchmarkApi || new BenchmarkApi(apiClient)
  const objectivesApi = customObjectivesApi || new ObjectivesApi(apiClient)
  const boxPlanningApi = customBoxPlanningApi || new BoxPlanningApi(apiClient)
  const boxSlotTemplateApi = customBoxSlotTemplateApi || new BoxSlotTemplateApi(apiClient)
  const boxB2BProgramApi = customBoxB2BProgramApi || new BoxB2BProgramApi(apiClient)
  const boxSlotApi = customBoxSlotApi || new BoxSlotApi(apiClient)
  const paymentApi = customPaymentApi || new PaymentApi(apiClient)
  const boxFinanceApi = customBoxFinanceApi || new BoxFinanceApi(apiClient)
  const userFinanceApi = customUserFinanceApi || new UserFinanceApi(apiClient)
  const boxStaffApi = customBoxStaffApi || new BoxStaffApi(apiClient)
  const trainingApi = customTrainingApi || new TrainingApi(apiClient)
  const exerciseApi = customExerciseApi || new ExerciseApi(apiClient)
  const promoCodeApi = customPromoCodeApi || new PromoCodeApi(apiClient)

  const localStorageService = new LocalStorageService(new WebStorage())
  const currentSpaceManager = new CurrentSpaceManager(localStorageService, apiClient)
  const serviceManager = new WebServiceManager(ConfigService.devMode, currentSpaceManager)
  serviceManager.setActiveInstance(serviceManager)

  serviceManager.register(localStorageService)
  serviceManager.register(currentSpaceManager)
  const featureToggleService = serviceManager.register(new FeatureToggleService(localStorageService))
  const authService = serviceManager.register(customAuthService || new AuthService())
  const languageService = serviceManager.register(
    new LanguageService(localStorageService, apiClient, featureToggleService),
  )
  const profileService = serviceManager.register(new MyProfileService(currentSpaceManager, localStorageService))
  const userService = serviceManager.register(
    new UserService(authService, userApi, localStorageService, serviceManager),
  )
  const apiService = serviceManager.register(new ApiService(apiClient, userService, authService))
  const boxService = serviceManager.register(new BoxService(boxApi, currentSpaceManager))
  const offerService = serviceManager.register(new BoxOfferService(offerApi))
  const memberService = serviceManager.register(new MemberService(memberApi))
  const paymentService = serviceManager.register(new PaymentService(userService, paymentApi))
  const planningService = serviceManager.register(
    new PlanningService(programInfiniteSessionApi, programOnOffSessionApi),
  )
  const programInfiniteService = serviceManager.register(
    new ProgramInfiniteService(programInfiniteApi, planningService, programApi),
  )
  const programOnOffService = serviceManager.register(
    new ProgramOnOffService(programOnOffApi, planningService, programApi),
  )
  const programService = serviceManager.register(
    new ProgramService(programApi, programInfiniteService, programOnOffService, planningService, paymentService),
  )
  const programSessionService = serviceManager.register(
    new ProgramSessionService(programInfiniteSessionApi, programOnOffSessionApi),
  )
  const promoCodeService = serviceManager.register(new PromoCodeService(promoCodeApi))
  const benchmarkService = serviceManager.register(new BenchmarkService(benchmarkApi))
  const objectivesService = serviceManager.register(new ObjectivesService(objectivesApi))
  const chatService = serviceManager.register(new ChatService(userService, chatApi))
  const dateService = serviceManager.register(new DateService(languageService, featureToggleService))
  const boxPlanningService = serviceManager.register(
    new BoxPlanningService(boxPlanningApi, currentSpaceManager, userService),
  )
  const boxProgrammationService = serviceManager.register(
    new BoxProgrammationService(boxProgrammationApi, currentSpaceManager, localStorageService),
  )
  const boxSlotTemplateService = serviceManager.register(new BoxSlotTemplateService(boxSlotTemplateApi))
  const boxSlotService = serviceManager.register(new BoxSlotService(boxSlotApi))
  const boxB2BProgramService = serviceManager.register(new BoxB2BProgramService(boxB2BProgramApi))

  const remoteConfigService = serviceManager.register(new RemoteConfigService())
  const boxFinanceService = serviceManager.register(new BoxFinanceService(boxFinanceApi))
  const userFinanceService = serviceManager.register(new UserFinanceService(userFinanceApi))
  const appBannerService = serviceManager.register(new AppBannerService(localStorageService))
  const boxProgrammationTemplatePopUpService = serviceManager.register(
    new BoxProgrammationTemplatePopUpService(localStorageService),
  )
  const boxStaffService = serviceManager.register(new BoxStaffService(boxStaffApi))
  const trainingService = serviceManager.register(new TrainingService(trainingApi))
  const sessionService = serviceManager.register(new ExerciseService(exerciseApi))
  const userProfileService = serviceManager.register(new UserProfileService(userApi))
  const exploreService = serviceManager.register(
    new ExploreService(
      exploreApi,
      programService,
      benchmarkService,
      userProfileService,
      boxService,
      localStorageService,
    ),
  )
  const scoreboardService = serviceManager.register(new ScoreboardService(scoreboardApi))
  // Outside service manager
  const assetService = new AssetService(assetApi)

  const allServices = {
    serviceManager,
    apiService,
    appBannerService,
    boxProgrammationTemplatePopUpService,
    assetService,
    authService,
    boxPlanningService,
    boxProgrammationService,
    boxService,
    boxSlotService,
    boxSlotTemplateService,
    boxB2BProgramService,
    boxStaffService,
    chatService,
    currentSpaceManager,
    dateService,
    featureToggleService,
    boxFinanceService,
    userFinanceService,
    languageService,
    localStorageService,
    memberService,
    offerService,
    paymentService,
    planningService,
    profileService,
    exploreService,
    articleApi,
    scoreboardService,
    programService,
    programInfiniteService,
    programOnOffService,
    benchmarkService,
    objectivesService,
    programSessionService,
    promoCodeService,
    trainingService,
    sessionService,
    remoteConfigService,
    userService,
    userProfileService,
  } as const

  return allServices
}
