import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { SessionExerciseScoreValueInput } from "@modules/exercises/components/sessionExerciseScoreValueInput"
import { ScoreFormikType } from "@modules/exercises/exerciseFormikTypes"
import { Exercise, ExerciseScoreDifficulties } from "@modules/exercises/exerciseTypes"
import { convertScoreFromDraft } from "@modules/exercises/exerciseUtils"
import { ParentSessionOrigin } from "@modules/exercises/sessionTypes"
import { getScoreValidationSchema } from "@modules/scoreboard/scoreTypes"
import { convertScoreToInputValue } from "@modules/scoreboard/scoreUtils"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { useUnit } from "@modules/utils/hooks/useUnit"
import { useFormik } from "formik"
import { useState } from "react"
import styled, { css, keyframes, useTheme } from "styled-components"

type ScoreEntryProps = {
  exercise: Exercise
  parentSessionOrigin: ParentSessionOrigin
  setIsScoreShown: (state: boolean) => void
  refreshSession?: () => void
  isScoreShown: boolean
}

export const ScoreEntry: React.FC<ScoreEntryProps> = ({
  exercise,
  parentSessionOrigin,
  setIsScoreShown,
  refreshSession,
  isScoreShown,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { sessionService } = useServicesContext()
  const toast = useToast()
  const theme = useTheme()
  const unit = useUnit()

  const exerciseScoreFormik = useFormik<ScoreFormikType>({
    initialValues: {
      value: convertScoreToInputValue(exercise.score?.value, exercise.scoreType, unit),
      difficulty: exercise.score?.difficulty ?? "RX",
      note: exercise.score?.note ?? "",
    },
    validationSchema: getScoreValidationSchema(exercise.scoreType),
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true)
        await sessionService.addScoreToExercice(
          parentSessionOrigin,
          exercise,
          convertScoreFromDraft(values, exercise.scoreType, unit),
        )
        toast.show({
          severity: "success",
          summary: word("score.scoreUpdated.toastSuccess"),
        })
        setIsSubmitting(false)
        setTimeout(() => {
          setIsScoreShown(false)
          refreshSession && refreshSession()
        }, 500)
      } catch (e: any) {
        setIsSubmitting(false)
        console.error("Error on update a score", e)
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      }
    },
  })

  return (
    <Container $isScoreShown={isScoreShown}>
      <HUStack gap={5}>
        <HURow gap={24}>
          <FormikInput
            flex
            isRequiredInput
            formikStyle={{ display: "flex", flexGrow: 1 }}
            label={word("exercise.score.difficulty")}
            getFieldProps={exerciseScoreFormik.getFieldProps}
            isInvalid={false}
            name="difficulty"
            type="dropdown"
            dropdownProps={{
              options: [...ExerciseScoreDifficulties],
              value: exerciseScoreFormik.values.difficulty,
            }}
            setFieldValue={exerciseScoreFormik.setFieldValue}
          />
          <SessionExerciseScoreValueInput scoreType={exercise.scoreType} formik={exerciseScoreFormik} />
        </HURow>
        <FormikInput
          flex
          label={word("session.scoreboard.card.comment")}
          placeHolder={word("exercise.score.addComment")}
          formikStyle={{ display: "flex", flexGrow: 1 }}
          getFieldProps={exerciseScoreFormik.getFieldProps}
          setFieldValue={exerciseScoreFormik.setFieldValue}
          isInvalid={false}
          name="note"
          type="textarea"
          textareaProps={{ autoResize: true, rows: 5, maxLength: 100 }}
        />
        <HUText fontStyle="BS" color={theme.neutralColor700}>
          {word("exercise.score.commentVisibleForAll")}
        </HUText>
        <HURow className="mt-4 flex justify-content-end">
          <HUButton
            type="Default"
            size="M"
            colorType="Primary"
            text={word("global.save")}
            onClick={() => exerciseScoreFormik.submitForm()}
            className="p-button p-button-primary"
            loading={isSubmitting}
          />
        </HURow>
      </HUStack>
    </Container>
  )
}

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`
const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Container = styled.div<{ $isScoreShown: boolean }>`
  background-color: ${({ theme }) => theme.neutralColor100};
  padding: 20px 16px;
  border-radius: 30px;
  margin-top: 10px;
  animation: ${({ $isScoreShown }) =>
    $isScoreShown
      ? css`
          ${fadeInAnimation} 1s ease-out
        `
      : css`
          ${fadeOutAnimation} 1s ease-out
        `};
`
