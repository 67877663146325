import { InfinityIcon } from "@images/svgIcons/infinityIcon"
import { LinkIcon } from "@images/svgIcons/linkIcon"
import { OnOffIcon } from "@images/svgIcons/onOffIcon"
import { PercentIcon } from "@images/svgIcons/percentIcon"
import { TimeIcon } from "@images/svgIcons/timeIcon"
import { word } from "@modules/core/utils/i18n"
import { Tutorial, TutorialId } from "@modules/tutorials/tutorialsType"

export const dataTutorials: () => Tutorial[] = () => [
  {
    id: TutorialId.create_infinite_program,
    title: word("tutorials.createProgramInfinite.title"),
    subTitle: word("tutorials.createProgramInfinite.subTitle"),
    linkVideoFr: "https://www.youtube.com/embed/zY-RqbDOw9I?si=iSLp57RDAM0Yy7X3",
    linkVideoEn: "https://www.youtube.com/embed/zY-RqbDOw9I?si=iSLp57RDAM0Yy7X3",
    linkVideoBr: "https://www.youtube.com/embed/lQfYFXg5XAg?si=GBf1ePnXqxJal0Sv",
    icon: InfinityIcon,
    textButton: word("tutorials.createProgramInfinite.title"),
  },
  {
    id: TutorialId.create_onOff_program,
    title: word("tutorials.createProgramOnOff.title"),
    subTitle: word("tutorials.createProgramOnOff.subTitle"),
    linkVideoFr: "https://www.youtube.com/embed/ydhSgKWjfJc?si=dm7G9ceIpmSzS5nG",
    linkVideoEn: "https://www.youtube.com/embed/WW2_ok-pOm4?si=hfprmX5uZ-YXi47Z",
    linkVideoBr: "https://www.youtube.com/embed/nkib3ZiNwbA?si=pAzNzk-FKhPpz3Pd",
    icon: OnOffIcon,
    textButton: word("tutorials.createProgramOnOff.title"),
  },
  {
    id: TutorialId.dynamic_link,
    title: word("tutorials.dynamicLink.title"),
    subTitle: word("tutorials.dynamicLink.subTitle"),
    linkVideoFr: "https://www.youtube.com/embed/XVKZRnC9Pdk?si=KWKOBDtazUEJg0PG",
    linkVideoEn: "https://www.youtube.com/embed/XVKZRnC9Pdk?si=KWKOBDtazUEJg0PG",
    linkVideoBr: "https://www.youtube.com/embed/ZzJnYKv9ILY?si=-jUmPT1veMHRYQyr",
    icon: LinkIcon,
    textButton: word("planning.seePrograms"),
  },
  {
    id: TutorialId.trial_period,
    title: word("tutorials.trialPeriod.title"),
    subTitle: word("tutorials.trialPeriod.subTitle"),
    linkVideoFr: "https://www.youtube.com/embed/_kQpARYnWGk?si=rS7t72OpN4dJt76o",
    linkVideoEn: "https://www.youtube.com/embed/_kQpARYnWGk?si=rS7t72OpN4dJt76o",
    linkVideoBr: "https://www.youtube.com/embed/bVjvMorGPUw?si=eMkwDuOd_vn7GA05",
    icon: TimeIcon,
    textButton: word("planning.seePrograms"),
  },
  {
    id: TutorialId.promotion_code,
    title: word("tutorials.promotionCode.title"),
    subTitle: word("tutorials.promotionCode.subTitle"),
    linkVideoFr: "https://www.youtube.com/embed/E3_ibiFJEoQ?si=TUczt2G5YHx6RiPR",
    linkVideoEn: "https://www.youtube.com/embed/E3_ibiFJEoQ?si=TUczt2G5YHx6RiPR",
    linkVideoBr: "https://www.youtube.com/embed/CfvBdZoxAXI?si=LNeDmL6Oh0TsKnM2",
    icon: PercentIcon,
    textButton: word("tutorials.promotionCode.title"),
  },
]
