import { HUText } from "@modules/ui/components/huText"
import { Column, ColumnProps } from "primereact/column"
import { DataTableProps, DataTableValueArray, DataTable as Datatable } from "primereact/datatable"
import { classNames } from "primereact/utils"
import React from "react"
import { DefaultTheme } from "styled-components"

const defaultPaginatorTemplate =
  "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
const defaultRowsPerPageOptions = [5, 10, 25]

type Props<T extends DataTableValueArray> = {
  children?: React.ReactNode
} & DataTableProps<T>

export const DataTable = <T extends DataTableValueArray>({ children, className, ...props }: Props<T>) => {
  const { totalRecords, rows } = props
  return (
    <Datatable
      className={classNames("datatable-responsive", className)}
      paginatorTemplate={defaultPaginatorTemplate}
      rowsPerPageOptions={defaultRowsPerPageOptions}
      {...props}
      paginator={(totalRecords ?? 0) >= (rows ?? 0) ? props.paginator : false}
    >
      {children}
    </Datatable>
  )
}

export function renderTableColumns<T, U extends string>(
  columnList: readonly U[],
  renderHeaderColumn: (key: U) => string | JSX.Element | undefined,
  renderValueColumn: (key: U, value: T, theme: DefaultTheme, language: string) => string | JSX.Element,
  theme: DefaultTheme,
  language: string,
  columnProps?: (key: U) => ColumnProps,
) {
  return columnList.map((key) =>
    renderTableColumn(key, renderHeaderColumn, renderValueColumn, theme, language, columnProps),
  )
}

export function renderTableColumn<T, U extends string>(
  key: U,
  renderHeaderColumn: (key: U) => string | JSX.Element | undefined,
  renderValueColumn: (key: U, value: T, theme: DefaultTheme, language: string) => string | JSX.Element,
  theme: DefaultTheme,
  language: string,
  columnProps?: (key: U) => ColumnProps,
) {
  return (
    <Column
      key={key}
      field={key}
      header={
        <HUText fontStyle="TS" color={theme.mainWhite}>
          {renderHeaderColumn(key)}
        </HUText>
      }
      body={(value: T) => renderValueColumn(key, value, theme, language)}
      {...columnProps?.(key)}
    />
  )
}
