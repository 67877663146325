import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const TimeTableIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6702_348757)">
      <path
        d="M2.90479 9.66524H21.3548M8.02979 2.49023V5.56523M16.2298 2.49023V5.56523M7.00479 22.9902H17.2548C19.5192 22.9902 21.3548 21.1546 21.3548 18.8902V8.12774C21.3548 5.86337 19.5192 4.02773 17.2548 4.02773H7.00479C4.74042 4.02773 2.90479 5.86337 2.90479 8.12773V18.8902C2.90479 21.1546 4.74042 22.9902 7.00479 22.9902Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.2601 8.82654V24.0045M8.04148 8.82617L8.04126 23.0248" stroke="white" strokeWidth="2.5" />
      <path d="M2.86719 16.9805H21.3772" stroke="white" strokeWidth="2.5" />
    </g>
    <defs>
      <clipPath id="clip0_6702_348757">
        <rect width="24" height="24" fill={props.color} transform="translate(0.129639 0.740234)" />
      </clipPath>
    </defs>
  </svg>
)
