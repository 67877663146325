import { ChronoIcon } from "@images/svgIcons/chronoIcon"
import { Benchmark } from "@modules/benchmark/benchmarkTypes"
import { displayTotalTime, getTrainingChronoTitle } from "@modules/chrono/chronoUtils"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { Linkify } from "@modules/ui/components/linkifyText"
import { Checkbox } from "primereact/checkbox"
import styled, { useTheme } from "styled-components"

type BenchmarkExerciseProps = {
  benchmark: Benchmark
}

export const BenchmarkExercise: React.FC<BenchmarkExerciseProps> = ({ benchmark }) => {
  const theme = useTheme()

  return (
    <BlackCardContainer>
      <HeaderCard>
        <CheckboxView checked={true} $checked={false} disabled={true} />
        <HUText className="ml-2" fontStyle="TS" color={theme.mainWhite}>
          {word("exercise.category.wod.name")}
        </HUText>
      </HeaderCard>
      <WhiteCardContainer>
        <div className="flex align-items-center mb-4">
          <ChronoContainer>
            <ChronoIcon color={theme.neutralColor900} width={15} height={15} />
            <HUText fontStyle="LL">
              {benchmark.chrono?.type
                ? getTrainingChronoTitle(benchmark.chrono.type) + " - " + displayTotalTime(benchmark.chrono)
                : word("chrono.title.no_chrono")}
            </HUText>
          </ChronoContainer>
        </div>
        <HUText style={{ whiteSpace: "pre-line" }} fontStyle="BM">
          <Linkify youtubeProps={{ allowEmbed: true }} content={benchmark.description} />
        </HUText>
      </WhiteCardContainer>
    </BlackCardContainer>
  )
}

const ChronoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`
const BlackCardContainer = styled.div`
  background-color: ${({ theme }) => theme.secondaryColor800};
  display: flex;
  flex-direction: column;
  padding: 16px 7px 7px 7px;
  border-radius: 33px;
  margin-bottom: 10px;
  margin-top: 10px;
`
const WhiteCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.mainWhite};
  display: flex;
  padding: 28px 22px 22px 22px;
  border-radius: 28px;
`

const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px 10px 16px;
`
const CheckboxView = styled(Checkbox)<{ $checked: boolean }>`
  height: 28px;
  width: 28px;
  cursor: auto !important;
  .p-checkbox-box {
    color: ${({ theme }) => theme.mainWhite};
    border-radius: 50%;
    height: 28px;
    width: 28px;
    cursor: ${({ $checked }) => ($checked ? "auto" : "pointer")} !important;
  }
  .p-checkbox-box.p-highlight {
    border-color: ${({ theme, $checked }) => ($checked ? theme.textLink : theme.mainWhite)} !important;
    background: ${({ theme, $checked }) => ($checked ? theme.textLink : theme.mainWhite)} !important;
  }
  .p-checkbox-box.p-highlight:hover {
    border-color: ${({ theme }) => theme.textLink} !important;
    background: ${({ theme }) => theme.textLink} !important;
    color: ${({ theme }) => theme.mainWhite} !important;
  }
  .p-checkbox-box .p-checkbox-icon {
    color: ${({ theme, $checked }) => ($checked ? theme.mainWhite : theme.neutralColor)} !important;
  }
`
