import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios"

export interface ApiInterceptor<V> {
  onFulfilled?: (value: V) => V | Promise<V>
  onRejected?: (error: unknown) => unknown
}

export function addRequestInterceptor(
  axiosInstance: AxiosInstance,
  interceptor: ApiInterceptor<InternalAxiosRequestConfig>,
): number {
  return axiosInstance.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected)
}

export function addResponseInterceptor(
  axiosInstance: AxiosInstance,
  interceptor: ApiInterceptor<AxiosResponse>,
): number {
  return axiosInstance.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected)
}
