import { AddressDto, convertAddressFromDto } from "@modules/address/addressDto"
import { BirthDate, GenderCategory } from "@modules/auth/user"
import { HUImageDto, convertImageFromDto } from "@modules/dtos/imageDto"
import { Profile, ProfileTypeOf } from "@modules/profile/profileTypes"
import dayjs from "dayjs"

export type ProfileDto = {
  id: string
  username: string
  firstname: string
  lastname: string
  avatar?: HUImageDto
  genderCategory: GenderCategory
  birthdate: BirthDate
  address: AddressDto
}

export function convertProfileFromDto(dto: ProfileDto): Profile {
  return {
    id: dto.id,
    _type: ProfileTypeOf,
    firstname: dto.firstname,
    lastname: dto.lastname,
    username: dto.username,
    avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
    genderCategory: dto.genderCategory,
    birthdate: dto.birthdate,
  }
}

export function convertSASRequestsProfileFromDto(dto: RequestsSASProfileDto) {
  const { subscriptionId, subscriptionDate, ...profile } = dto
  return {
    subscriptionId,
    subscriptionDate: dayjs(subscriptionDate).toDate(),
    profile: convertProfileFromDto(profile),
  }
}

export type RequestsSASProfileDto = {
  subscriptionId: string
  subscriptionDate: string
} & ProfileDto

// if back return an userId
export type UserProfileDto = {
  userId: string
  username: string
  firstname: string
  lastname: string
  avatar?: HUImageDto
  genderCategory: GenderCategory
  birthdate: BirthDate
  address: AddressDto
}

export function convertUserProfileFromDto(dto: UserProfileDto): Profile {
  return {
    id: dto.userId,
    _type: ProfileTypeOf,
    firstname: dto.firstname,
    lastname: dto.lastname,
    username: dto.username,
    avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
    genderCategory: dto.genderCategory,
    birthdate: dto.birthdate,
    address: dto.address ? convertAddressFromDto(dto.address) : undefined,
  }
}


