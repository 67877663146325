import {
  ArticleContentImageDto,
  ArticleDto,
  ArticleTagDto,
  ArticleThumbnailDto,
  ArticleVideoDto,
  PortableTextBlockDto,
} from "@modules/article/gateway/articleApiDto"
import { SanityService } from "@modules/services/sanityService"
import { convertYoutubeLinkToEmbed } from "@modules/utils/convertYoutubeLink"

export type ArticleImage = {
  _type: "thumbnail" | "content_image"
  _key?: string
  uri: string
  alt_text?: string
}

export type ArticleVideo = {
  _type: "content_video"
  _key?: string
  uri: string
  alt_text?: string
}

export class Article {
  public readonly _id: string
  public readonly _type: "article"
  public readonly _rev: string
  public readonly _createdAt: string
  public readonly _updatedAt: string
  public readonly title: string
  public readonly description: string
  public readonly author: string
  public readonly thumbnail: ArticleImage
  public readonly tag: ArticleTagDto
  public readonly content: (PortableTextBlockDto | ArticleImage | ArticleVideo)[]
  public readonly seo_id: {
    current: string
    _type: string
  }

  constructor(articleDto: ArticleDto) {
    this._id = articleDto._id
    this._type = articleDto._type
    this._rev = articleDto._rev
    this._createdAt = articleDto._createdAt
    this._updatedAt = articleDto._updatedAt
    this.title = articleDto.title
    this.description = articleDto.description
    this.author = articleDto.author
    this.thumbnail = this.buildImageUri(articleDto.thumbnail)
    this.tag = articleDto.tag
    this.content = articleDto.content.map((content) => {
      if (content._type === "content_image") {
        return this.buildImageUri(content)
      }

      if (content._type === "content_video") {
        return this.buildYoutubeVideoUri(content)
      }

      return content
    })
    this.seo_id = articleDto.seo_id
  }

  buildImageUri(ImageObject: ArticleContentImageDto | ArticleThumbnailDto): ArticleImage {
    let imageKey: string | undefined = undefined
    let imageUri: string | undefined = undefined
    if ("_key" in ImageObject) {
      imageKey = ImageObject._key
    }
    if (ImageObject._type === "thumbnail") {
      imageUri = SanityService.imageBuilder.image(ImageObject.asset).width(1024).url()
    }

    return {
      _type: ImageObject._type,
      _key: imageKey,
      alt_text: ImageObject.alt_text,
      uri: imageUri ?? SanityService.imageBuilder.image(ImageObject).width(1024).url(),
    }
  }

  buildYoutubeVideoUri(videoObject: ArticleVideoDto): ArticleVideo {
    return {
      _type: videoObject._type,
      _key: videoObject._key,
      alt_text: videoObject.alt_text,
      uri: convertYoutubeLinkToEmbed(videoObject.video_url),
    }
  }
}
