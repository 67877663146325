import { FetchStrategy, PaginatedStore, usePaginatedStore, useStore } from "@betomorrow/micro-stores"
import { useSelectedBoxId } from "@modules/box/useBox"
import { BoxProgrammation, ItemBoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { useAsync } from "@modules/hooks/useAsync"
import { useMemo } from "react"

export const useBoxProgrammation = (id?: string, fetchStrategy?: FetchStrategy) => {
  const { boxProgrammationService } = useServicesContext()

  return useStore(id, boxProgrammationService.boxProgrammationStore, fetchStrategy)
}

export const useBoxProgrammations = (options?: { page?: number; size?: number; fetchStrategy?: FetchStrategy }) => {
  const { boxProgrammationService } = useServicesContext()
  const boxId = useSelectedBoxId()

  const paginatedProgramsStore = useMemo(
    () =>
      new PaginatedStore<ItemBoxProgrammation, BoxProgrammation, "id">(() =>
        boxProgrammationService.getBoxProgrammations({ page: options?.page, size: options?.size }),
      ).present(boxProgrammationService.boxProgrammationStore),
    [boxId, options?.page, options?.size],
  )

  return usePaginatedStore(paginatedProgramsStore, options?.fetchStrategy)
}

export const useBoxProgrammationDefaultSettings = (id?: string) => {
  const { boxProgrammationService } = useServicesContext()
  return useAsync(() => {
    if (id) return boxProgrammationService.getBoxProgrammationDefaultValues(id)
    else return new Promise<null>(() => null)
  }, [id])
}
