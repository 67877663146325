import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PencilIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3332 0.666749C11.5552 -0.111248 10.2938 -0.111246 9.51579 0.666751L8.78215 1.4004L11.5995 4.21778L12.3332 3.48413C13.1112 2.70613 13.1112 1.44475 12.3332 0.666749ZM10.2997 5.51755L7.48237 2.70017L1.32695 8.85559C0.926254 9.25629 0.65532 9.76823 0.549349 10.3249L0.386487 11.1805C0.224782 12.0299 0.970011 12.7751 1.81947 12.6134L2.67501 12.4506C3.23169 12.3446 3.74363 12.0737 4.14433 11.673L10.2997 5.51755Z"
      fill={props.color}
    />
  </svg>
)
