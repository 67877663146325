import { useServicesContext } from "@modules/core/services/services.context"
import dayjs from "dayjs"

export const useLocaleWeekDays = () => {
  const { languageService } = useServicesContext()

  const englishWeekDays = languageService.getDateInLocalEn().localeData().weekdays()
  const weekDaysArray = [0, 1, 2, 3, 4, 5, 6].map((localWeekDay) => {
    const weekDay = dayjs().weekday(localWeekDay)
    const weekDayVal = weekDay.toDate().getDay()

    return {
      label: weekDay.format("dddd"),
      value: englishWeekDays[weekDayVal].toUpperCase(),
    }
  })

  return weekDaysArray
}
