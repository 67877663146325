import { ContentImage, ContentVideo } from "@modules/article/components/articleComponent"
import { Article } from "@modules/article/entities/articleEntity"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { PortableText } from "@portabletext/react"
import dayjs from "dayjs"
import styled, { useTheme } from "styled-components"
type ArticleResumeProps = {
  article: Article
}

export const ArticleResume: React.FC<ArticleResumeProps> = ({ article }) => {
  const theme = useTheme()

  const portableTextComponents = {
    list: {
      // Ex. 1: customizing common list types
      bullet: ({ children }: any) => <ul className="mb-4">{children}</ul>,
    },

    listItem: {
      bullet: ({ children }: any) => <li className="mb-2">{children}</li>,
      checkmarks: ({ children }: any) => <ol className="mb-4">{children}</ol>,
    },
    marks: {
      link: ({ value, children }: any) => {
        return (
          <Link href={value?.href} target={"_blank"}>
            {children}
          </Link>
        )
      },
    },
    types: {
      content_image: ContentImage,
      content_video: ContentVideo,
    },
  }

  return (
    <>
      {article ? (
        <div className="flex flex-column flex-grow-1">
          <HeaderContainer>
            <div className="relative flex justify-content-center">
              <CategoryTag>
                <HUText fontStyle="LL" color={theme.mainWhite}>
                  {word(`article.tag.${article.tag}`)}
                </HUText>
              </CategoryTag>
              <ImageContainer
                $bgImage={article.thumbnail.uri}
                className="p-5 mb-5 flex-row justify-content-center align-items-center overflow-hidden gap-2 border-noround-top "
              >
                <GradientOverlay />
                <TitleContainer>
                  <HURow className="align-items-center gap-2">
                    <HUText fontStyle="HS" color={theme.mainWhite}>
                      {article.title}
                    </HUText>
                  </HURow>
                </TitleContainer>
              </ImageContainer>
            </div>
          </HeaderContainer>
          <ContentContainer>
            <AuthorInfos fontStyle="BS">
              {word("article.author_infos", {
                date: dayjs(article._createdAt).format("LL"),
                author: article.author,
              })}
            </AuthorInfos>

            <div className="px-4 mt-4">
              <PortableText value={article.content} components={portableTextComponents} />
            </div>
          </ContentContainer>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

const HeaderContainer = styled.div`
  margin: -85px -20px;
`

const ImageContainer = styled.div<{ $bgImage?: string }>`
  position: relative;
  border-radius: 33px;
  border-width: 0px;
  height: 290px;
  background-image: ${({ $bgImage }) => `url(${$bgImage})`};
  background-size: cover;
  background-position: center;
  width: 100%;
`
const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.8) 99.9%);
  border-radius: 33px;
  border-width: 0px;
  height: 290px;
  width: 100%;
`
const TitleContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 30px;
  text-align: center;
`
const CategoryTag = styled.div`
  position: absolute;
  top: 45px;
  left: 20px;
  border-radius: 44px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(7px);
  padding: 9px 12px 8px 12px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  z-index: 2;
`
const AuthorInfos = styled(HUText)`
  margin-bottom: 10px;
`
const ContentContainer = styled.div`
  margin-top: 70px;
`
const Link = styled.a`
  color: ${({ theme }) => theme.textLink};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`
