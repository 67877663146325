import { useCurrentBox } from "@modules/box/useBox"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { defaultMonthValue } from "@modules/finances/components/defaultMonth"
import { usePeriodParam } from "@modules/finances/hooks/usePeriod"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { downloadFile } from "@modules/utils/downloadFile"
import { LoggerType } from "@modules/utils/loggerUtils"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { useMemo, useState } from "react"
import styled from "styled-components"

export type ExportModalRef = {
  open: () => void
}

type Props = {
  onHide: () => void
  visible: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const ExportModal: React.FC<Props> = ({ onHide, visible }) => {
  const { result: boxId } = useCurrentBox()
  const toast = useToast()
  const [period] = usePeriodParam()
  const { boxFinanceService, userFinanceService } = useServicesContext()
  const financeService = boxId ? boxFinanceService : userFinanceService

  const initialDateValue = useMemo(
    () => ({
      start: dayjs(period ?? defaultMonthValue)
        .startOf("month")
        .toDate(),
      end: dayjs(period ?? defaultMonthValue)
        .endOf("month")
        .toDate(),
    }),
    [period],
  )

  const formik = useFormik<{ date: Date[] }>({
    initialValues: { date: [initialDateValue.start, initialDateValue.end] },
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        setLoading(true)
        const [startDate, endDate] = formik.values.date
        const result = await financeService.exportTransactions(
          dayjs(startDate).toISOString(),
          dayjs(endDate).toISOString(),
        )
        downloadFile("transaction.csv", result, "text/csv")
        toast.show({
          severity: "success",
          summary: word("finances.page.export.success"),
        })
        onHide()
      } catch (e: any) {
        Sentry.captureException(`${LoggerType.Financial} Export data ${e.message}`)
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      } finally {
        setLoading(false)
      }
    },
  })
  const [loading, setLoading] = useState(false)

  const maxDate = useMemo(() => new Date(dayjs().endOf("month").toDate()), [])
  return (
    <HUDialog headerSeparator visible={visible} header={word("finances.page.export.modal.header")} onHide={onHide}>
      <DialogContent gap={24}>
        <HUStack gap={0}>
          <HUText fontStyle="TM">{word("finances.page.export.modal.title")}</HUText>
          <HUText fontStyle="BM">{word("finances.page.export.modal.description")}</HUText>
        </HUStack>

        <FormikInput
          flex
          formikStyle={{ flexGrow: 1 }}
          label={word("finances.page.export.modal.input.date.label")}
          getFieldProps={formik.getFieldProps}
          isInvalid={false}
          name="date"
          type="calendarRange"
          calendarRangeProps={{
            selectionMode: "range",
            readOnlyInput: true,
            showButtonBar: false,
            panelClassName: "export-date",
            hideOnRangeSelection: true,
            maxDate,
            showIcon: true,
          }}
          isRequiredInput
        />
      </DialogContent>
      <DialogFooter>
        <HUButton text={word("global.cancel")} colorType="Tertiary" onClick={onHide} type="Default" size="M" />
        <HUButton
          text={word("global.export")}
          type="Default"
          size="M"
          colorType="Primary"
          className="ml-2"
          loading={loading}
          disabled={formik.values.date.some((v) => v === null)}
          onClick={() => formik.submitForm()}
        />
      </DialogFooter>
    </HUDialog>
  )
}

const DialogContent = styled(HUStack)`
  padding: 24px 0px;
`

const DialogFooter = styled.div`
  display: flex;
  justify-content: end;
  padding: 40px 0px 0px 0px;
`
