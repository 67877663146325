import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const MoneyIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="2" y="4" width="20" height="16" rx="4" stroke={props.color} strokeWidth="1.5" />
    <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 17 17)" fill={props.color} />
    <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 9)" fill={props.color} />
    <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 10 14)" stroke={props.color} strokeWidth="1.5" />
  </svg>
)
