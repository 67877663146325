import { boxProgrammationColors } from "@modules/boxProgrammation/components/boxProgrammationColors"
import { HURow } from "@modules/ui/components/layout"
import styled from "styled-components"

export const BoxProgrammationColorSelection: React.FC<{
  selectedColor: string
  onChangeSelectedColor: (arg0: string) => void
}> = ({ selectedColor, onChangeSelectedColor }) => {
  return (
    <HURow className="justify-content-around px-4">
      {Object.entries(boxProgrammationColors).map(([key, value]) => (
        <ColorContainer key={key} $isSelected={key === selectedColor} onClick={() => onChangeSelectedColor(key)}>
          <ColorCircle key={key} $color={value.background} />
        </ColorContainer>
      ))}
    </HURow>
  )
}

const ColorCircle = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
`

const ColorContainer = styled.div<{ $isSelected: boolean }>`
  width: 38px;
  height: 38px;
  border-radius: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ $isSelected, theme }) => ($isSelected ? "2px solid " + theme.mainWhite : "1px solid transparent")};
  transition: border 0.2s ease;
  &:hover {
    border: 1px solid ${({ theme }) => theme.neutralColor400};
  }
`
