import { WarningHexagonalIcon } from "@images/svgIcons/warningIcon"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProgramInfoForm } from "@modules/programs/components/programInfoForm"
import {
  ProgramFormikType,
  convertProgramDraftToFormik,
  convertProgramFormFormik,
  getValidationSchema as getProgramValidationSchema,
} from "@modules/programs/programFormikType"
import { ProgramInfinite, ProgramInfiniteTypeOf, convertProgramToDraft } from "@modules/programs/programInfiniteTypes"
import {
  ProgramOnOffFormikType,
  convertProgramOnOffDraftToFormik,
  convertProgramOnOffFormFormik,
  getValidationSchema as getProgOnOffValidationSchema,
} from "@modules/programs/programOnOffFormikType"
import {
  ProgramOnOff,
  ProgramOnOffStatus,
  ProgramOnOffTypeOf,
  convertProgramOnOffToDraft,
} from "@modules/programs/programOnOffTypes"
import { Program, ProgramTypeOf } from "@modules/programs/programTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HURow } from "@modules/ui/components/layout"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { useDraft } from "@modules/utils/useDraft"
import { useFormik } from "formik"
import { useState } from "react"
import styled, { useTheme } from "styled-components"

type ProgramFormProps = {
  program?: Program | undefined
  programType?: ProgramTypeOf
  onFinish?: (success?: boolean) => void
}

export const ProgramForm: React.FC<ProgramFormProps> = ({ program, programType, onFinish }) => {
  const { programService } = useServicesContext()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [loadingImageUpload, setLoadingImageUpload] = useState(false)
  const mySpaceNavigate = useMySpaceNavigate()
  const confirmPopup = useConfirmPopup()
  const theme = useTheme()
  const { hideSidebar } = useSidebar()

  const { draft: programDraft } = useDraft(() => {
    if (
      (!program && programType === ProgramInfiniteTypeOf) ||
      (program && program._programType === ProgramInfiniteTypeOf)
    ) {
      return convertProgramToDraft(program)
    } else if (
      (!program && programType === ProgramOnOffTypeOf) ||
      (program && program._programType === ProgramOnOffTypeOf)
    ) {
      return convertProgramOnOffToDraft(program)
    } else {
      throw new Error("Unexpected program type")
    }
  })

  const confirmDeleteProgram = (program: ProgramInfinite | ProgramOnOff) => {
    confirmPopup.show({
      title: word("global.delete"),
      message: word("program.form.delete.confirmation.subtitle", {
        programName: program.title,
      }),
      accept: async () => {
        await programService.deleteProgram(program.id, program._programType)
        mySpaceNavigate(Path.MySpace.Training)
      },
      handleErrorMessage: (e) =>
        e.code === "OFFER_LINKED_TO_PROGRAM" ? word("programmation.delete.error.linked_to_offer") : e.message,
      acceptValidated: word("program.delete.confirm.message"),
      footerProps: {
        align: true,
      },
    })
  }

  const getInitialValues = () => {
    if (programDraft._programType === ProgramInfiniteTypeOf) {
      return convertProgramDraftToFormik(programDraft)
    } else {
      return convertProgramOnOffDraftToFormik(programDraft)
    }
  }

  const getValidationSchema = () => {
    if (programDraft._programType === ProgramInfiniteTypeOf) {
      return getProgramValidationSchema({ imageRequired: !!program })
    } else if (programDraft._programType === ProgramOnOffTypeOf) {
      return getProgOnOffValidationSchema({ imageRequired: !!program })
    }
  }

  const formik = useFormik<ProgramFormikType | ProgramOnOffFormikType>({
    initialValues: getInitialValues(),

    validationSchema: getValidationSchema(),
    onSubmit: async () => {
      const onSubmitEnded = () => {
        toast.show({
          severity: "success",
          summary: !program ? word("program.createProgram.success") : word("program.editProgram.success"),
        })

        hideSidebar()
        onFinish && onFinish(true)
      }
      try {
        setLoading(true)

        const partialProgramDraft = (formikValue: ProgramFormikType) => convertProgramFormFormik(formikValue)
        const partialProgramOnOffDraft = (formikValue: ProgramOnOffFormikType) =>
          convertProgramOnOffFormFormik(formikValue)

        if (program) {
          if (program._programType === ProgramInfiniteTypeOf) {
            await programService.updateProgram(program.id, partialProgramDraft(formik.values as ProgramFormikType))

            onSubmitEnded()
          } else if (program._programType === ProgramOnOffTypeOf) {
            const updateProgramOnOff = async () => {
              await programService.updateProgram(
                program.id,
                partialProgramOnOffDraft(formik.values as ProgramOnOffFormikType),
              )

              onSubmitEnded()
            }

            if (
              "duration" in formik.initialValues &&
              "duration" in formik.values &&
              formik.initialValues.duration &&
              formik.values.duration &&
              formik.initialValues.duration > formik.values.duration
            ) {
              confirmPopup.show({
                title: word("program.onOff.durationReductionWarning.title"),
                message: word("program.onOff.durationReductionWarning.message", {
                  programName: program.title,
                }),
                accept: async () => {
                  await updateProgramOnOff()
                },
                footerProps: {
                  align: true,
                },
              })
            } else {
              await updateProgramOnOff()
            }
          } else {
            onSubmitEnded()
          }
        } else {
          if (programType === ProgramInfiniteTypeOf) {
            const newProgram = await programService.createProgram(
              partialProgramDraft(formik.values as ProgramFormikType),
            )
            mySpaceNavigate(Path.MySpace.ProgramDetails(newProgram.id))
          } else if (programType === ProgramOnOffTypeOf) {
            const newProgram = await programService.createProgram(
              partialProgramOnOffDraft(formik.values as ProgramOnOffFormikType),
            )
            mySpaceNavigate(Path.MySpace.ProgramOnOffDetails(newProgram.id))
          }
          onSubmitEnded()
        }
      } catch (e: any) {
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      } finally {
        setLoading(false)
      }
    },
  })

  const generateTitle = () => {
    if (program) {
      //If it's a program con
      if (program._programType === ProgramInfiniteTypeOf) {
        return word("program.form.editProgram.title")
      } else if (program._programType === ProgramOnOffTypeOf) {
        return word("program.onOff.formik.title.editProgram")
      }
    } else if (programType === ProgramInfiniteTypeOf) {
      return word("program.form.addProgram.title")
    } else if (programType === ProgramOnOffTypeOf) {
      return word("program.onOff.formik.title.createProgram")
    } else return "Error condition"
  }

  return (
    <div className="flex flex-column justify-content-center">
      <HUText fontStyle="HS" className="mb-3">
        {generateTitle()}
      </HUText>
      {!program && (
        <HUText fontStyle="BS" className="mb-3">
          {word("program.form.addProgram.description")}
        </HUText>
      )}

      <HURow className="flex align-items-start">
        <WarningHexagonalIcon color={theme.neutralColor600} width={24} height={24} />
        <HUText fontStyle="BS" className="mb-3 ml-2">
          {word("picture.warning.message")}
        </HUText>
      </HURow>

      <form onSubmit={formik.handleSubmit} className="flex flex-column">
        <div className="border-round-lg">
          <FormikInput
            isRequiredInput={!!program}
            type="image"
            imageProps={{
              type: "cover",
              placeHolder: {
                message: word("program.form.uploadImage.text1"),
              },
              showPreviewAsCover: true,
              prefixPath: "/programs",
              onStartUpload: () => setLoadingImageUpload(true),
              onEndUpload: () => setLoadingImageUpload(false),
            }}
            label={word("program.form.image.label")}
            name="image"
            getFieldProps={formik.getFieldProps}
            setFieldValue={formik.setFieldValue}
            isInvalid={isFormFieldInValid("image", formik)}
            error={getFormErrorMessage("image", formik, { showAlways: true })}
          />
        </div>

        <FormContainer className="flex-column">
          <ProgramInfoForm
            programId={program?.id}
            isEditForm={program !== undefined}
            isOnOffProgram={programDraft._programType === ProgramOnOffTypeOf}
            isFormFieldInValid={(input) => isFormFieldInValid(input, formik)}
            getFormErrorMessage={(input, errorMessage) => getFormErrorMessage(input, formik, { errorMessage })}
            formik={{
              values: formik.values,
              getFieldProps: formik.getFieldProps,
              setFieldValue: formik.setFieldValue,
            }}
          />
          <div className="flex justify-content-end mt-3">
            <div className="flex column gap-3 sm:flex-row flex-grow-1">
              {program &&
              (("status" in program && program.status !== ProgramOnOffStatus.PUBLISHED) || !("status" in program)) ? (
                <HUButton
                  type="Default"
                  size="M"
                  colorType="Quaternary"
                  text={word("global.delete")}
                  onClick={() => program && confirmDeleteProgram(program)}
                  className="w-full"
                />
              ) : (
                <HUButton
                  type="Default"
                  size="M"
                  colorType="Quaternary"
                  text={word("global.cancel")}
                  onClick={() => {
                    hideSidebar()
                    onFinish && onFinish(true)
                  }}
                  className="w-full"
                />
              )}
              <HUButton
                type="Default"
                size="M"
                colorType="Primary"
                text={word("box.slot.form.submit.button")}
                className="mr-2 w-full"
                loading={loading}
                disabled={loadingImageUpload}
                onClick={formik.submitForm}
              />
            </div>
          </div>
        </FormContainer>
      </form>
    </div>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
