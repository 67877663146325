import { BackgroundProfileIcon } from "@images/backgroundProfileIcon"
import { ArrowIcon } from "@images/svgIcons/arrowIcon"
import { PencilEditIcon } from "@images/svgIcons/pencilEditIcon"
import { BoxOffer, BoxOfferDraft, BoxOfferType } from "@modules/boxOffer/boxOfferTypes"
import { boxOffersInformation } from "@modules/boxOffer/components/boxOfferCardsView"
import {
  BoxOfferFormattedPrice,
  BoxOfferInputCustomLabel,
  BoxOfferRuleCard,
} from "@modules/boxOffer/components/boxOfferFormSection"
import { useBoxOfferSidebar } from "@modules/boxOffer/hooks/useBoxOfferSidebar"
import { useBoxProgrammations } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { useAsync } from "@modules/hooks/useAsync"
import { HUButton } from "@modules/ui/components/huButton"
import { HUTag } from "@modules/ui/components/huTag"
import { HUText } from "@modules/ui/components/huText"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { capitalize } from "@modules/utils/stringUtils"
import { TrashIcon } from "primereact/icons/trash"
import { useCallback, useMemo } from "react"
import styled, { useTheme } from "styled-components"

type BoxOfferResumeViewProps = {
  formOffer?: BoxOfferDraft
  offerId?: string
  rightButtonAction?: () => void
  leftButtonAction?: () => void
  loading?: boolean
}

export const BoxOfferResumeView = ({
  formOffer,
  offerId,
  rightButtonAction,
  leftButtonAction,
  loading,
}: BoxOfferResumeViewProps) => {
  const { navigateToBoxOfferForm, hideSidebar } = useBoxOfferSidebar()
  const confirmPopup = useConfirmPopup()
  const { offerService } = useServicesContext()
  const theme = useTheme()
  const { result: programs } = useBoxProgrammations({ size: 50 })
  const { value: offer } = useAsync(
    async () => formOffer ?? (await offerService.offerStore.fetch(offerId ?? "")),
    [formOffer, offerId, offerService],
  )
  const offerInformation = useMemo(
    () => boxOffersInformation().find((infos) => infos.type === offer?.type),
    [offer?.type],
  )
  const filteredPrograms = useMemo(
    () => programs.filter((program) => offer?.programs.includes(program.id)),
    [offer?.programs, programs],
  )

  const removeOfferDialog = async () =>
    confirmPopup.show({
      title: word("box.offer.remove.title"),
      message: word("box.offer.remove.description"),
      accept: async () => {
        if (offer && "id" in offer) await offerService.delete(offer.id).then(() => hideSidebar())
      },
      acceptValidated: word("box.offer.remove.success.toast"),
      footerProps: {
        align: true,
      },
    })

  const offerSubtitle = useMemo(() => {
    if (offer && [BoxOfferType.Trial, BoxOfferType.TicketsBook, BoxOfferType.DropIn].includes(offer.type)) {
      if (offer.validityPeriodMonths) {
        return word("box.offer.form.infos.validity", { count: offer.validityPeriodMonths })
      }
      return word("box.offer.form.infos.validity.unlimited")
    } else if (offer && "minimumMonthlyDuration" in offer && offer.minimumMonthlyDuration) {
      return word("box.offer.form.infos.commitment", { count: offer.minimumMonthlyDuration })
    }
    return word("box.offer.form.infos.no.commitment")
  }, [offer])

  const handleRightAction = useCallback(
    () => rightButtonAction ?? navigateToBoxOfferForm(undefined, offer as BoxOffer),
    [navigateToBoxOfferForm, offer, rightButtonAction],
  )

  return offerInformation && offer ? (
    <NoPaddingSidebarHeader>
      <div className="flex h-14rem border-round-bottom-2xl mb-5" style={{ backgroundColor: theme.neutralColor50 }}>
        <div className="flex flex-column align-items-center m-auto">
          {offerInformation.icon}
          <HUText fontStyle="HS" className="mt-2">
            {offer.name}
          </HUText>
          <HUText fontStyle="BM" color={theme.neutralColor700}>
            {capitalize(offerSubtitle)}
          </HUText>
          <BoxOfferFormattedPrice price={offer.price} currency={offer.currency} />
        </div>
        <BoxOfferResumeHeader>
          <BackgroundProfileIcon color={theme.primaryColor800} />
        </BoxOfferResumeHeader>
      </div>
      <div className="flex flex-column gap-5 p-4 pt-0">
        <HUText fontStyle="BM" color={theme.neutralColor700}>
          {offer.description}
        </HUText>
        {offer.type !== BoxOfferType.DropIn && (
          <div
            className={`flex justify-content-between gap-5 ${![BoxOfferType.Subscription, BoxOfferType.Millenium].includes(offer.type) ? "text-center" : "flex-column"}`}
          >
            {[BoxOfferType.TicketsBook, BoxOfferType.Trial].includes(offer.type) && "count" in offer && (
              <div className="w-full">
                <BoxOfferInputCustomLabel label={word("offer.form.numberOfSessions.title")} />
                <BoxOfferRuleCard $borderSize={2} $resume={true}>
                  <HUText className="w-full text-center" fontStyle="TS">
                    {word("offer.form.numberOfSessions.included", { count: offer.count ?? 0 })}
                  </HUText>
                </BoxOfferRuleCard>
              </div>
            )}
            <div className="w-full">
              <BoxOfferInputCustomLabel label={word("box.offer.form.infos.restrictedPeriod.title")} />
              <div
                style={{
                  display: "grid",
                  gridAutoFlow: [BoxOfferType.Subscription, BoxOfferType.Millenium].includes(offer.type)
                    ? "column"
                    : "row",
                  gap: "0.6rem",
                  gridTemplateColumns: [BoxOfferType.Subscription, BoxOfferType.Millenium].includes(offer.type)
                    ? "repeat(auto-fit, minmax(0, 1fr))"
                    : undefined,
                }}
              >
                {offer.rules && offer.rules.length > 0 ? (
                  offer.rules.map((rule, index) => (
                    <BoxOfferRuleCard key={index} $borderSize={2} $resume={true}>
                      <HUText
                        className="w-full text-center"
                        fontStyle="TS"
                      >{`${rule.value} ${word("offer.form.rules.sessions", { count: rule.value })} ${word(`offer.form.rules.period.per.${rule.period}`)}`}</HUText>
                    </BoxOfferRuleCard>
                  ))
                ) : (
                  <BoxOfferRuleCard $borderSize={2} $resume={true}>
                    <HUText className="w-full text-center" fontStyle="TS">
                      {word("box.offer.form.infos.rules.unlimited")}
                    </HUText>
                  </BoxOfferRuleCard>
                )}
              </div>
            </div>
            {BoxOfferType.Trial === offer.type && (
              <div className="w-full">
                <BoxOfferInputCustomLabel label={word("box.offer.form.infos.renewal.title")} />
                <BoxOfferRuleCard $borderSize={2}>
                  <HUText className="w-full text-center" fontStyle="TS">
                    {offer.renewable
                      ? word("box.offer.form.infos.renewal.yes")
                      : word("box.offer.form.infos.renewal.no")}
                  </HUText>
                </BoxOfferRuleCard>
              </div>
            )}
          </div>
        )}
        <div className="mb-5">
          <BoxOfferInputCustomLabel label={word("box.offer.form.infos.activitiesIncluded.title")} />
          <BoxOfferDetailsContainer>
            <HUText fontStyle="BS">{word("box.offer.resume.activitiesIncluded.details")}</HUText>
            <div className="flex flex-wrap gap-2 mt-2">
              {filteredPrograms.map((program, index) => (
                <HUTag key={index} className="bg-white">
                  <HUText fontStyle="LM" color={theme.mainBlack}>
                    {program.name}
                  </HUText>
                </HUTag>
              ))}
            </div>
          </BoxOfferDetailsContainer>
        </div>
        <div className="flex gap-5">
          <HUButton
            text={leftButtonAction ? word("global.previous") : word("global.delete")}
            onClick={leftButtonAction ?? removeOfferDialog}
            type={formOffer ? "Rounded" : "Right_Icon"}
            size="M"
            colorType="Quaternary"
            fullWidth
            icon={{
              iconView: formOffer ? (
                <ArrowIcon arrowDirection="left" color={theme.mainWhite} width={14} height={14} />
              ) : (
                <TrashIcon color={theme.mainWhite} />
              ),
            }}
          />
          <HUButton
            text={formOffer ? word("global.save") : word("global.edit")}
            onClick={handleRightAction}
            type="Right_Icon"
            size="M"
            colorType="Primary"
            fullWidth
            loading={loading}
            submit={formOffer !== undefined}
            icon={
              formOffer
                ? undefined
                : {
                    iconView: <PencilEditIcon color={theme.mainWhite} />,
                  }
            }
          />
        </div>
      </div>
    </NoPaddingSidebarHeader>
  ) : null
}

const NoPaddingSidebarHeader = styled.div`
  margin: -55px -20px;
  position: relative;
`

const BoxOfferDetailsContainer = styled.div`
  border: ${({ theme }) => `2px solid ${theme.primaryColor}`};
  border-radius: 1.25rem;
  min-height: 100px;
  padding: 1.75rem 2rem;
`
const BoxOfferResumeHeader = styled.div`
  background-color: ${({ theme }) => theme.primaryColor700};
  overflow: hidden;
  clip-path: polygon(32% 0%, 100% 0%, 100% 100%, 0% 100%);
  border-bottom-right-radius: 1rem;
`
