import { Currency, allCurrencies } from "@modules/currency/currency"
import { InvoiceStatus, allInvoiceStatus } from "@modules/finances/types/financesType"
import { convertToType } from "@modules/utils/enum"
import { isInArray } from "@modules/utils/isInArray"
import { LoggerType } from "@modules/utils/loggerUtils"

export type CustomerHistoryDto = {
  stripeChargeId: string
  name: number
  amount: number
  currency: Currency
  date: string
  status: InvoiceStatus
}
export type CustomerHistory = {
  stripeChargeId: string
  name: number
  amount: number
  currency: Currency
  date: string
  status: InvoiceStatus
}

export function convertItemCustomerHistoryFromDto(dto: CustomerHistoryDto): CustomerHistory {
  if (!isInArray(allCurrencies, dto.currency)) {
    console.log(LoggerType.Financial, "Currency transactions not supported")
  }
  if (!isInArray(allInvoiceStatus, dto.status)) {
    console.log(LoggerType.Financial, "Status transactions not supported (" + dto.status + ")")
  }

  return {
    amount: dto.amount,
    stripeChargeId: dto.stripeChargeId,
    name: dto.name,
    currency: convertToType(allCurrencies, dto.currency as Currency, "EUR"),
    date: dto.date,
    status: dto.status as InvoiceStatus,
  }
}
