import { word } from "@modules/core/utils/i18n"
import { ExerciseFormikType } from "@modules/exercises/exerciseFormikTypes"
import { Exercise } from "@modules/exercises/exerciseTypes"
import { getRpeColor } from "@modules/exercises/rpeUtils"
import { HUText } from "@modules/ui/components/huText"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

type RpeTagProps = {
  exercise: ExerciseFormikType | Exercise
}
export const RpeTag: React.FC<RpeTagProps> = ({ exercise }) => {
  const theme = useTheme()

  const borderColorRpe = useMemo(() => {
    return getRpeColor(exercise.rpe)
  }, [exercise.rpe])

  return (
    <RpeTagContainer $borderColorRpe={borderColorRpe}>
      <HUText type="label" color={theme.neutralColor900} fontStyle="LL">
        {word("sessionForm.exercise.rpe.label").toUpperCase()} {exercise.rpe}
      </HUText>
    </RpeTagContainer>
  )
}

const RpeTagContainer = styled.div<{ $borderColorRpe: string }>`
  display: flex;
  margin-right: auto;
  margin-bottom: 15px;
  height: 30px;
  padding: 0px 6px;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  border: ${({ $borderColorRpe }) => ` 1px solid${$borderColorRpe}`};
`
