import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const MessagesIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.54716 19.4534C1.3768 19.4563 1.20826 19.418 1.05595 19.3416C0.903639 19.2652 0.772083 19.1531 0.672526 19.0148C0.57297 18.8766 0.508373 18.7162 0.484257 18.5476C0.460141 18.3789 0.477223 18.2069 0.534044 18.0463L2.29293 12.249C1.93323 11.2608 1.74294 10.219 1.73008 9.16743C1.72731 7.95367 1.97147 6.75203 2.44771 5.6356C3.02503 4.26062 3.93078 3.04824 5.08559 2.10469C6.24039 1.16114 7.60899 0.515232 9.07145 0.223574C10.5339 -0.068085 12.0456 0.0034076 13.474 0.431786C14.9024 0.860164 16.2039 1.63235 17.2645 2.68067C18.0875 3.51682 18.7462 4.50007 19.2063 5.57931C19.6738 6.69319 19.9146 7.88908 19.9146 9.09708C19.9146 10.3051 19.6738 11.501 19.2063 12.6148C18.7462 13.6941 18.0875 14.6773 17.2645 15.5135C16.0307 16.7452 14.4696 17.5973 12.7662 17.9689C11.0629 18.3405 9.28874 18.216 7.654 17.6101L1.85672 19.4112C1.75589 19.439 1.65177 19.4532 1.54716 19.4534ZM10.82 2.1319C9.88695 2.12919 8.96306 2.31588 8.10428 2.68067C6.4406 3.38277 5.11058 4.69768 4.38952 6.35322C4.02767 7.21536 3.84131 8.14098 3.84131 9.07597C3.84131 10.011 4.02767 10.9366 4.38952 11.7987C4.47334 12.0306 4.47334 12.2845 4.38952 12.5163L3.09498 16.7377L7.3163 15.4431C7.54816 15.3593 7.80206 15.3593 8.03392 15.4431C9.74252 16.1527 11.6619 16.1607 13.3763 15.4655C15.0908 14.7702 16.4624 13.4276 17.1942 11.7284C17.6059 10.6759 17.7581 9.53965 17.6377 8.4159C17.5173 7.29214 17.128 6.21392 16.5027 5.2725C15.8774 4.33107 15.0345 3.55414 14.0453 3.00747C13.0561 2.46079 11.9498 2.16047 10.82 2.1319Z"
      fill={props.color}
    />
    <path
      d="M23.3577 23.942C23.255 23.9558 23.1509 23.9558 23.0482 23.942L17.2509 22.1832C15.0729 23.0056 12.6615 22.9554 10.5197 22.043C8.37781 21.1307 6.67086 19.4267 5.75483 17.2864C5.69222 17.1598 5.6564 17.0216 5.64962 16.8805C5.64284 16.7394 5.66524 16.5984 5.71543 16.4663C5.76561 16.3343 5.8425 16.214 5.94128 16.113C6.04006 16.012 6.15861 15.9324 6.28953 15.8793C6.41684 15.8208 6.55453 15.7882 6.69457 15.7835C6.83461 15.7787 6.97419 15.8019 7.10517 15.8517C7.23616 15.9015 7.35591 15.9768 7.45746 16.0734C7.55901 16.1699 7.64032 16.2857 7.69663 16.414C8.03109 17.2111 8.50791 17.9406 9.10374 18.5669C10.0903 19.5427 11.3435 20.2049 12.7055 20.4703C14.0675 20.7357 15.4776 20.5924 16.7584 20.0584C16.9903 19.9746 17.2442 19.9746 17.476 20.0584L21.6973 21.353L20.4028 17.1317C20.319 16.8998 20.319 16.6459 20.4028 16.414C20.7676 15.5552 20.9543 14.6314 20.9516 13.6983C20.9549 12.7811 20.7763 11.8723 20.4261 11.0245C20.0759 10.1767 19.561 9.40684 18.9113 8.75937C18.6266 8.39964 18.3111 8.06536 17.9685 7.76032C17.8455 7.68651 17.7388 7.58838 17.655 7.47194C17.5712 7.3555 17.512 7.22319 17.481 7.0831C17.45 6.943 17.448 6.79807 17.4749 6.65715C17.5019 6.51623 17.5573 6.38228 17.6377 6.2635C17.7182 6.14471 17.822 6.04359 17.9429 5.96629C18.0638 5.889 18.1992 5.83716 18.3407 5.81395C18.4823 5.79074 18.6272 5.79664 18.7664 5.8313C18.9056 5.86595 19.0363 5.92864 19.1505 6.01551C19.6538 6.34043 20.125 6.71266 20.5576 7.12713C21.3851 7.95957 22.0444 8.94374 22.4994 10.0258C22.9768 11.1468 23.2209 12.3533 23.217 13.5717C23.2141 14.6516 23.0236 15.7229 22.6542 16.7377L24.4131 22.5349C24.4699 22.6956 24.487 22.8676 24.4628 23.0362C24.4387 23.2049 24.3741 23.3652 24.2746 23.5035C24.175 23.6418 24.0435 23.7539 23.8912 23.8303C23.7388 23.9066 23.5703 23.945 23.3999 23.942H23.3577Z"
      fill={props.color}
    />
  </svg>
)
