import { LayoutContext } from "@modules/app/context/layoutcontext"
import { MenuButtonsRef } from "@modules/app/types/layout"
import { forwardRef, useContext, useImperativeHandle, useRef } from "react"
import styled from "styled-components"

export const MenuToggle = forwardRef<MenuButtonsRef>((_, ref) => {
  const { onMenuToggle, layoutState } = useContext(LayoutContext)
  const menubuttonRef = useRef(null)
  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
  }))

  return (
    <div className="toggle-menubutton">
      <button
        ref={menubuttonRef}
        type="button"
        className={`top-menubutton p-link p-trigger ${layoutState.staticMenuDesktopInactive ? "menu-collapsed" : ""}`}
        onClick={onMenuToggle}
      >
        <Chevron className={`pi pi-chevron-left`} />
      </button>
    </div>
  )
})

const Chevron = styled.i`
  .pi-chevron-left:hover {
    color: ${({ theme }) => theme.buttonPrimaryTextColor};
  }
`
