import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import styled, { css } from "styled-components"

export const EMPTY_VIEW_VERTICAL_PADDING = 40

export type EmptyViewPaddingType = "Default" | "In_table"

export type EmptyViewProps = {
  image?: {
    src: string
    background?: string
    padding?: string
  }
  title?: string
  description?: string
  textAlign?: "center" | "left" | "right"
  button?: React.ReactNode
  flexGrow?: boolean
  children?: React.ReactNode
  paddingType?: EmptyViewPaddingType
  imagePosition?: "before" | "after"
  disposition?: "row" | "column" | "row-reverse" | "column-reverse"
} & React.HTMLAttributes<HTMLDivElement>

export const EmptyView: React.FC<EmptyViewProps> = ({
  image,
  flexGrow,
  title,
  description,
  textAlign = "center",
  button,
  children,
  paddingType,
  imagePosition = "before",
  disposition = "column",
  ...props
}) => {
  return (
    <Container $flexGrow={flexGrow} $paddingType={paddingType} {...props}>
      <InformationContainer $disposition={disposition}>
        {image && imagePosition === "before" && (
          <Illustration
            $flexGrow={flexGrow}
            $imageBackgroundColor={image.background}
            className="flex"
            $padding={image.padding}
          >
            <img src={image.src} style={{ width: 200 }} />
          </Illustration>
        )}
        <TextContent gap={[6, 32]} $textAlign={textAlign}>
          {title && (
            <HUText fontStyle="HS" className={`text-${textAlign}`} style={{ wordBreak: "break-word" }}>
              {title}
            </HUText>
          )}
          {description && (
            // todo use good color
            <HUText fontStyle="BM" className={`text-${textAlign}`} color="var(--text-color-secondary)">
              {description}
            </HUText>
          )}
          {button && <div className="align-self-center">{button}</div>}
        </TextContent>
        {image && imagePosition === "after" && (
          <Illustration
            $flexGrow={flexGrow}
            $imageBackgroundColor={image.background}
            className="flex"
            $padding={image.padding}
          >
            <img src={image.src} style={{ width: 200 }} />
          </Illustration>
        )}
      </InformationContainer>
      {children && <Content>{children}</Content>}
    </Container>
  )
}

const getPadding = (paddingType?: EmptyViewPaddingType) => {
  switch (paddingType) {
    case "Default":
      return `${EMPTY_VIEW_VERTICAL_PADDING}px 0`
    case "In_table":
      return `${EMPTY_VIEW_VERTICAL_PADDING - 16}px 0`
  }
}

const Container = styled.div<{ $flexGrow?: boolean; $paddingType?: EmptyViewPaddingType }>`
  ${({ $flexGrow }) =>
    $flexGrow &&
    css`
      flex-grow: 1;
      max-height: 100%;
    `}
  border-radius: 22px;
  ${({ $paddingType }) =>
    $paddingType &&
    css`
      padding: ${getPadding($paddingType)};
    `}
`

const Illustration = styled.div<{ $imageBackgroundColor?: string; $flexGrow?: boolean; $padding?: string }>`
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  padding: ${({ $padding }) => $padding};
  ${({ $flexGrow }) =>
    $flexGrow &&
    css`
      flex-grow: 1;
      max-height: 60%;
    `}
  ${({ $imageBackgroundColor }) =>
    $imageBackgroundColor &&
    css`
      background-color: ${$imageBackgroundColor};
      margin-bottom: 18px;
      border-radius: 22px;
    `}
`

const TextContent = styled(HUStack)<{ $textAlign?: string }>`
  > span {
    max-width: 400px;
    align-self: ${({ $textAlign }) => $textAlign || "center"};
  }
`

const Content = styled.div`
  display: flex;
  padding-top: 22px;
  justify-content: center;
`

const InformationContainer = styled.div<{ $disposition: "row" | "column" | "row-reverse" | "column-reverse" }>`
  display: flex;
  flex-direction: ${({ $disposition }) => $disposition};
  align-items: center;
`
