import { Store } from "@betomorrow/micro-stores"
import { AsyncObservable } from "@modules/asyncObservable/asyncObservable"
import { BoxOffer, BoxOfferDraft } from "@modules/boxOffer/boxOfferTypes"
import { OfferApi } from "@modules/boxOffer/services/boxOfferApi"
import { WebService } from "@modules/core/services/webService"
import { updateInArray } from "@modules/utils/objectUtils"

export class BoxOfferService implements WebService {
  offers = new AsyncObservable<BoxOffer[]>(() => this.api.getBoxOffers())
  offerStore = new Store<BoxOffer>((id) => this.api.getBoxOffer(id))

  constructor(private api: OfferApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init() {}

  async onLoggedOut(): Promise<void> {
    this.offers.set([])
    this.offerStore.clear()
  }

  async onSpaceChange(): Promise<void> {
    this.offers.set([])
    this.offerStore.clear()
  }

  async create(offer: BoxOfferDraft) {
    const result = await this.api.createBoxOffer(offer)
    this.offers.set([result, ...(this.offers.get() ?? [])])
    this.offerStore.save(result)
  }

  async update(id: string, offer: BoxOfferDraft) {
    const result = await this.api.update(id, offer)
    const foundIndex = this.offers.get()?.findIndex((offer) => offer.id === result.id)

    if (foundIndex !== undefined && foundIndex > -1) {
      this.offers.update((offers) => [...updateInArray(offers ?? [], foundIndex, () => result)])
      this.offerStore.save(result)
    }
  }

  async delete(id: string) {
    await this.api.delete(id)
    this.offers.update((offers) => offers?.filter((it) => it.id !== id) ?? [])
    this.offerStore.remove(id)
  }
}
