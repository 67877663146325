export function updateElementInArray<T>(
  array: T[],
  find: (el: T, index: number) => boolean,
  update: (el: T) => T,
): T[] {
  const index = array.findIndex(find)
  if (index < 0) {
    return array
  } else {
    const elem = array[index]
    const copy = [...array]
    copy.splice(index, 1, update(elem))
    return copy
  }
}

export function updateInArray<T>(array: T[], index: number, update: (el: T) => T): T[] {
  return updateElementInArray(array, (_, i) => i === index, update)
}

export function replaceInArray<T>(array: readonly T[], index: number, newValue: T): readonly T[] {
  return [...array.slice(0, index), newValue, ...array.slice(index + 1)]
}

export function insertInArray<T>(array: readonly T[], index: number, newValue: T): readonly T[] {
  return [...array.slice(0, index), newValue, ...array.slice(index)]
}

export function removeInArray<T>(array: readonly T[], index: number): readonly T[] {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function toggleInArray<T>(array: T[], isElement: (el: T) => boolean, newValue: T): readonly T[] {
  const index = array.findIndex((value) => isElement(value))
  const newArray = array.length === 0 ? [newValue] : index >= 0 ? removeInArray(array, index) : array.concat(newValue)
  return newArray
}

export function addOrReplaceInArray<T>(array: T[], isElement: (el: T) => boolean, newValue: T): T[] {
  let isFound = false
  const result = updateElementInArray(
    array,
    (el) => isElement(el),
    () => {
      isFound = true
      return newValue
    },
  )
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (isFound) {
    return result
  } else {
    return array.concat(newValue)
  }
}

export function moveInArray<T>(array: readonly T[], index: number, newIndex: number): readonly T[] {
  const value = array[index]
  const newArray = removeInArray(array, index)
  return insertInArray(newArray, newIndex, value)
}
