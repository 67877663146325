import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PencilEditIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none" {...props}>
    <mask id="path-1-inside-1_8310_3568" fill={props.color}>
      <path d="M10.0158 0.916019C10.7938 0.138022 12.0552 0.13802 12.8332 0.916018C13.6112 1.69401 13.6112 2.9554 12.8332 3.73339L4.64433 11.9222C4.24363 12.3229 3.73169 12.5939 3.17501 12.6998L2.31947 12.8627C1.47001 13.0244 0.724781 12.2792 0.886486 11.4297L1.04935 10.5742C1.15532 10.0175 1.42625 9.50556 1.82695 9.10486L10.0158 0.916019Z" />
    </mask>
    <path
      d="M1.82695 9.10486L2.88761 10.1655L1.82695 9.10486ZM1.04935 10.5742L-0.42419 10.2937L1.04935 10.5742ZM4.64433 11.9222L5.70499 12.9829L4.64433 11.9222ZM3.17501 12.6998L2.8945 11.2263L3.17501 12.6998ZM12.8332 3.73339L13.8938 4.79405L12.8332 3.73339ZM10.0158 0.916019L11.0765 1.97668L11.0765 1.97668L10.0158 0.916019ZM2.31947 12.8627L2.59997 14.3362L2.31947 12.8627ZM0.886486 11.4297L-0.587053 11.1492L0.886486 11.4297ZM11.7725 2.67273L3.58367 10.8616L5.70499 12.9829L13.8938 4.79405L11.7725 2.67273ZM2.88761 10.1655L11.0765 1.97668L8.95513 -0.144641L0.766292 8.0442L2.88761 10.1655ZM2.8945 11.2263L2.03896 11.3892L2.59997 14.3362L3.45551 14.1734L2.8945 11.2263ZM2.36002 11.7102L2.52289 10.8547L-0.42419 10.2937L-0.587053 11.1492L2.36002 11.7102ZM0.766292 8.0442C0.152836 8.65765 -0.261953 9.44142 -0.42419 10.2937L2.52289 10.8547C2.57259 10.5936 2.69967 10.3535 2.88761 10.1655L0.766292 8.0442ZM3.58367 10.8616C3.39573 11.0495 3.1556 11.1766 2.8945 11.2263L3.45551 14.1734C4.30777 14.0111 5.09153 13.5963 5.70499 12.9829L3.58367 10.8616ZM11.7725 1.97668C11.9647 2.16889 11.9647 2.48052 11.7725 2.67273L13.8938 4.79405C15.2576 3.43027 15.2576 1.21914 13.8938 -0.144642L11.7725 1.97668ZM13.8938 -0.144642C12.53 -1.50843 10.3189 -1.50842 8.95513 -0.144641L11.0765 1.97668C11.2687 1.78447 11.5803 1.78447 11.7725 1.97668L13.8938 -0.144642ZM2.03896 11.3892C2.22929 11.3529 2.39625 11.5199 2.36002 11.7102L-0.587053 11.1492C-0.946693 13.0385 0.710734 14.6959 2.59997 14.3362L2.03896 11.3892Z"
      fill={props.color}
      mask="url(#path-1-inside-1_8310_3568)"
    />
    <path d="M9.3335 2.66602L11.0835 4.41602" stroke={props.color} strokeWidth="1.5" strokeLinecap="square" />
  </svg>
)
