import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import {
  BoxSlotSessionFormikType,
  convertBoxSlotSessionToFormik,
  convertFormikToBoxSessionDraft,
  getBoxSessionValidationSchema,
} from "@modules/boxSlot/boxSessionFormikTypes"
import { BoxSlot } from "@modules/boxSlot/boxSlotTypes"
import { BoxProgrammationSession } from "@modules/boxSlot/services/boxSlotDto"
import { ChronoType } from "@modules/chrono/chronoTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ExerciseFormikType } from "@modules/exercises/exerciseFormikTypes"
import {
  ProgramInfiniteSessionFormikType,
  ProgramOnOffSessionFormikType,
  ProgramSessionInputKey,
  convertFormikToProgramInfiniteSessionDraft,
  convertFormikToProgramOnOffSessionDraft,
  convertProgramInfiniteSessionToFormik,
  convertProgramOnOffSessionToFormik,
  getProgramInfiniteSessionValidationSchema,
  getProgramOnOffSessionValidationSchema,
} from "@modules/programSession/programSessionFormikType"
import {
  ProgramInfiniteSession,
  ProgramInfiniteSessionTypeOf,
  ProgramOnOffSession,
  ProgramOnOffSessionTypeOf,
  convertProgramInfiniteSessionToDraft,
  convertProgramOnOffSessionToDraft,
} from "@modules/programSession/programSessionTypes"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { Program } from "@modules/programs/programTypes"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { SimpleFormik } from "@src/typings/formik"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { isEqual } from "lodash"
import { MutableRefObject, createContext, useCallback, useEffect, useRef, useState } from "react"

type SessionFormContextType = {
  program?: Program | BoxProgrammation
  formik: SimpleFormik<
    ProgramInfiniteSessionFormikType | ProgramOnOffSessionFormikType | BoxSlotSessionFormikType,
    ProgramSessionInputKey
  > & {
    setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean) => void
    submitForm: () => void | Promise<any>
    dirty: boolean
  }
  isSubmitting: boolean
  updatedExerciseIndex: number | null
  initialUpdatedExercise?: ExerciseFormikType
  isEditingExercise: boolean
  editedSlot?: BoxSlot
  setEditedSession: (arg?: ProgramInfiniteSession | ProgramOnOffSession | BoxProgrammationSession) => void
  setEditedSlot: (arg?: BoxSlot) => void
  setDateSession: (arg?: string | number) => void
  setProgram: (arg: Program | BoxProgrammation) => void
  setUpdatedExerciseIndex: (arg: number | null) => void
  setInitialUpdatedExercise: (arg: ExerciseFormikType | undefined) => void
  setIsEditingExercise: (arg: boolean) => void
  onHideSessionForm: MutableRefObject<() => Promise<boolean>>
  onHideExerciseForm: MutableRefObject<() => Promise<boolean>>
  onHideChronoForm: MutableRefObject<() => Promise<boolean>>
}

export const SessionFormContext = createContext({} as SessionFormContextType)

export const SessionFormProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [editedSession, setEditedSession] = useState<
    ProgramInfiniteSession | ProgramOnOffSession | BoxProgrammationSession | undefined
  >()
  const [editedSlot, setEditedSlot] = useState<BoxSlot | undefined>()
  const [dateSession, setDateSession] = useState<string | number | undefined>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [updatedExerciseIndex, setUpdatedExerciseIndex] = useState<number | null>(null)
  const [program, setProgram] = useState<Program | BoxProgrammation>()
  const [initialUpdatedExercise, setInitialUpdatedExercise] = useState<ExerciseFormikType>()
  const [isEditingExercise, setIsEditingExercise] = useState<boolean>(false)

  const onHideSessionForm = useRef<() => Promise<boolean>>(() => Promise.resolve(true))
  const onHideExerciseForm = useRef<() => Promise<boolean>>(() => Promise.resolve(true))
  const onHideChronoForm = useRef<() => Promise<boolean>>(() => Promise.resolve(true))

  const { programSessionService, boxSlotService } = useServicesContext()
  const confirmPopup = useConfirmPopup()

  const isOnOffSessionForm =
    (program && "_programType" in program && !editedSession && program._programType === ProgramOnOffTypeOf) ||
    (program && editedSession && editedSession._type === ProgramOnOffSessionTypeOf) ||
    false
  const isContinuousSessionForm =
    (program && "_programType" in program && !editedSession && program._programType === ProgramInfiniteTypeOf) ||
    (program && editedSession && editedSession._type === ProgramInfiniteSessionTypeOf) ||
    false

  const getInitialValues = useCallback(() => {
    if (isOnOffSessionForm) {
      return {
        ...convertProgramOnOffSessionToFormik({
          ...convertProgramOnOffSessionToDraft(editedSession as ProgramOnOffSession),
          programId: editedSession && "programId" in editedSession ? editedSession.programId : program?.id ?? "",
          dayIndex:
            editedSession && "index" in editedSession && typeof editedSession.index !== "undefined"
              ? editedSession.index
              : typeof dateSession === "number"
                ? dateSession
                : undefined,
        }),
      }
    } else if (isContinuousSessionForm) {
      return {
        ...convertProgramInfiniteSessionToFormik({
          ...convertProgramInfiniteSessionToDraft(editedSession as ProgramInfiniteSession),
          hideSessionContent:
            editedSession?.hideSessionContent !== undefined
              ? editedSession.hideSessionContent
              : program?.defaultHideSessionContent ?? false,
          date:
            editedSession && "date" in editedSession && editedSession.date
              ? editedSession.date
              : dateSession
                ? dayjs(dateSession).toString()
                : undefined,
          programId:
            editedSession && "programId" in editedSession && editedSession.programId
              ? editedSession.programId
              : program?.id ?? "",
        }),
      }
    } else {
      return convertBoxSlotSessionToFormik({
        ...editedSession,
        hideSessionContent:
          editedSession?.hideSessionContent !== undefined
            ? editedSession.hideSessionContent
            : program?.defaultHideSessionContent ?? false,
        date:
          editedSession && "date" in editedSession && editedSession.date
            ? editedSession.date
            : dateSession
              ? dayjs(dateSession).toString()
              : dayjs().startOf("day").toDate(),
      } as BoxProgrammationSession)
    }
  }, [editedSession, isOnOffSessionForm, isContinuousSessionForm, program, dateSession])

  const sessionFormSubmitHandler = async (
    sessionFormikValues: ProgramInfiniteSessionFormikType | ProgramOnOffSessionFormikType | BoxSlotSessionFormikType,
  ) => {
    if (editedSession) {
      if (isOnOffSessionForm) {
        await programSessionService.updateSession(
          editedSession.id,
          convertFormikToProgramOnOffSessionDraft(sessionFormikValues as ProgramOnOffSessionFormikType),
        )
      } else if (isContinuousSessionForm) {
        await programSessionService.updateSession(
          editedSession.id,
          convertFormikToProgramInfiniteSessionDraft(sessionFormikValues as ProgramInfiniteSessionFormikType),
        )
      } else if (program) {
        await boxSlotService.updateSession(
          editedSession.id,
          convertFormikToBoxSessionDraft(sessionFormikValues as BoxSlotSessionFormikType, program.id),
        )
      }
    } else {
      if (isOnOffSessionForm) {
        await programSessionService.createSession(
          convertFormikToProgramOnOffSessionDraft(sessionFormikValues as ProgramOnOffSessionFormikType),
        )
      } else if (isContinuousSessionForm) {
        await programSessionService.createSession(
          convertFormikToProgramInfiniteSessionDraft(sessionFormikValues as ProgramInfiniteSessionFormikType),
        )
      } else if (program && editedSlot) {
        await boxSlotService.createSessionAndUpdateSlot(
          convertFormikToBoxSessionDraft(sessionFormikValues as BoxSlotSessionFormikType, program.id),
          editedSlot.id,
          editedSlot,
        )
      }
    }
  }

  const formik = useFormik<ProgramInfiniteSessionFormikType | ProgramOnOffSessionFormikType | BoxSlotSessionFormikType>(
    {
      initialValues: getInitialValues(),
      validationSchema: isOnOffSessionForm
        ? getProgramOnOffSessionValidationSchema
        : isContinuousSessionForm
          ? getProgramInfiniteSessionValidationSchema
          : getBoxSessionValidationSchema,
      enableReinitialize: true,
      onSubmit: async () => {
        setIsSubmitting(true)
        let result
        try {
          result = await sessionFormSubmitHandler(formik.values)
        } finally {
          setIsSubmitting(false)
          return result
        }
      },
    },
  )

  useEffect(() => {
    onHideSessionForm.current = async () => {
      if (formik.dirty) {
        const confirm = await new Promise<boolean>((resolve) => {
          confirmPopup.show({
            title: word("program.sidebar.closeMessage.title"),
            message: word("program.sidebar.closeMessage.message"),
            accept: async () => {
              setEditedSession(undefined)
              setDateSession(undefined)
              resolve(true)
            },
            cancel: async () => {
              resolve(false)
            },
            footerProps: {
              align: true,
              confirmText: word("global.ok"),
            },
          })
        })

        return confirm
      } else {
        setEditedSlot(undefined)
        setEditedSession(undefined)
        setDateSession(undefined)
        return true
      }
    }
  }, [formik.values, formik.dirty])

  useEffect(() => {
    onHideExerciseForm.current = async () => {
      if (!isEditingExercise) {
        formik.setFieldTouched(`exercises[${updatedExerciseIndex}].description`, false)
        formik.setFieldValue("exercises", formik.values.exercises.slice(0, -1))
        return true
      } else {
        if (
          updatedExerciseIndex !== null &&
          initialUpdatedExercise &&
          !isEqual(formik.values.exercises[updatedExerciseIndex], initialUpdatedExercise)
        ) {
          const confirm = await new Promise<boolean>((resolve) => {
            confirmPopup.show({
              title: word("program.exercise.closeMessage.title"),
              message: word("program.exercise.closeMessage.message"),
              accept: async () => {
                formik.setFieldValue(
                  "exercises",
                  formik.values.exercises.map((exercise, index) => {
                    if (index === updatedExerciseIndex) return initialUpdatedExercise
                    else return exercise
                  }),
                )
                setUpdatedExerciseIndex(null)
                setInitialUpdatedExercise(undefined)
                resolve(true)
              },
              cancel: async () => {
                resolve(false)
              },
              footerProps: {
                align: true,
                confirmText: word("global.ok"),
              },
            })
          })

          return confirm
        } else {
          setUpdatedExerciseIndex(null)
          setInitialUpdatedExercise(undefined)
          return true
        }
      }
    }
  }, [formik.values, formik.dirty, isEditingExercise, updatedExerciseIndex, initialUpdatedExercise])

  useEffect(() => {
    onHideChronoForm.current = async () => {
      if (updatedExerciseIndex !== null) {
        await formik.setValues(
          (values) => ({
            ...values,
            exercises: values.exercises.map((exercise, index) => {
              if (index === updatedExerciseIndex) {
                switch (formik.values.exercises[updatedExerciseIndex].chronoType) {
                  case ChronoType.AMRAP:
                    return {
                      ...exercise,
                      chronoTabata: undefined,
                      chronoEmom: undefined,
                      chronoForTime: undefined,
                      chrono: formik.values.exercises[updatedExerciseIndex].chronoAmrap ?? undefined,
                    }
                  case ChronoType.EMOM:
                    return {
                      ...exercise,
                      chronoTabata: undefined,
                      chronoAmrap: undefined,
                      chronoForTime: undefined,
                      chrono: formik.values.exercises[updatedExerciseIndex].chronoEmom ?? undefined,
                    }
                  case ChronoType.FOR_TIME:
                    return {
                      ...exercise,
                      chronoTabata: undefined,
                      chronoAmrap: undefined,
                      chronoEmom: undefined,
                      chrono: formik.values.exercises[updatedExerciseIndex].chronoForTime ?? undefined,
                    }
                  case ChronoType.TABATA:
                    return {
                      ...exercise,
                      chronoEmom: undefined,
                      chronoAmrap: undefined,
                      chronoForTime: undefined,
                      chrono: formik.values.exercises[updatedExerciseIndex].chronoTabata ?? undefined,
                    }
                  default:
                    return {
                      ...exercise,
                      chronoEmom: undefined,
                      chronoAmrap: undefined,
                      chronoForTime: undefined,
                      chronoTabata: undefined,
                      chrono: undefined,
                    }
                }
              } else return exercise
            }),
          }),
          false,
        )
      }

      return true
    }
  }, [formik.values, formik.dirty, isEditingExercise, updatedExerciseIndex, initialUpdatedExercise])

  const value = {
    program,
    formik,
    isSubmitting,
    updatedExerciseIndex,
    initialUpdatedExercise,
    isEditingExercise,
    editedSlot,
    setProgram,
    setEditedSession,
    setEditedSlot,
    setDateSession,
    setUpdatedExerciseIndex,
    setInitialUpdatedExercise,
    setIsEditingExercise,
    onHideSessionForm,
    onHideExerciseForm,
    onHideChronoForm,
  }

  return <SessionFormContext.Provider value={value}>{children}</SessionFormContext.Provider>
}
