import { colors } from "@modules/ui/styles/colors"

export type BoxProgrammationColorType =
  | "purple500"
  | "purple200"
  | "blue500"
  | "blue200"
  | "green500"
  | "green200"
  | "yellow500"
  | "yellow200"
  | "orange500"
  | "orange200"

export const boxProgrammationColors: {
  [key in BoxProgrammationColorType]: { background: string; text: string; icon: string }
} = {
  purple500: { background: colors.purple500, text: colors.white, icon: colors.electricPurple200 },
  purple200: { background: colors.purple200, text: colors.neutral900, icon: colors.defaultIconColor },
  blue500: { background: colors.blue500, text: colors.neutral900, icon: colors.defaultIconColor },
  blue200: { background: colors.blue200, text: colors.neutral900, icon: colors.defaultIconColor },
  green500: { background: colors.green500, text: colors.neutral900, icon: colors.defaultIconColor },
  green200: { background: colors.green200, text: colors.neutral900, icon: colors.defaultIconColor },
  yellow500: { background: colors.yellow500, text: colors.neutral900, icon: colors.defaultIconColor },
  yellow200: { background: colors.yellow200, text: colors.neutral900, icon: colors.defaultIconColor },
  orange500: { background: colors.orange500, text: colors.neutral900, icon: colors.defaultIconColor },
  orange200: { background: colors.orange200, text: colors.neutral900, icon: colors.defaultIconColor },
}
