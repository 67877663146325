import { Language, getFormatedLocaleNumber } from "@modules/language/utils/languageUtils"
import { HUUnit } from "@modules/utils/unitType"
import convert from "convert-units"

export const convertDistanceFromMeter = (value: number, unit: HUUnit) => {
  const unitTo = unit === "IMPERIAL" ? "mi" : "m"
  const localeValue = convert(value).from("mm").to(unitTo)
  return localeValue
}

export const convertDistanceFromMeterText = (value: number, unit: HUUnit, language: Language) => {
  const unitTo = unit === "IMPERIAL" ? "mi" : "km"
  const localeValue = convert(value).from("mm").to(unitTo)
  const bestLocaleValue = convert(localeValue)
    .from(unitTo)
    .toBest({ exclude: ["ft-us", "ft", "in", "yd"], cutOffNumber: 1 })

  return (
    getFormatedLocaleNumber(Number(Math.max(bestLocaleValue.val, 0.1).toFixed(1)), language) +
    " " +
    bestLocaleValue.unit
  )
}
