import { Config } from "@modules/config"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProgramForm } from "@modules/programs/components/programForm"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { usePromoCodeSidebar } from "@modules/promoCode/hooks/usePromoCodeSidebar"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { TrainingProgramsFiltersView } from "@modules/training/components/trainingProgramsFilterView"
import { dataTutorials } from "@modules/tutorials/dataTutorials"
import { Tutorial, TutorialId } from "@modules/tutorials/tutorialsType"
import { ScreenHeader } from "@modules/ui/components/header"
import { HUButton } from "@modules/ui/components/huButton"
import { HUButtonIcon } from "@modules/ui/components/huButtonIcon"
import { HUText } from "@modules/ui/components/huText"
import { YouTubeVideo } from "@modules/utils/components/youTubeVideo"
import { Link } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const TutorialsPage: React.FC = () => {
  const theme = useTheme()
  const tutorialsData: Tutorial[] = dataTutorials()
  const mySpaceNavigate = useMySpaceNavigate()
  const { sidebarNavigateTo } = useSidebar()
  const { languageService } = useServicesContext()
  const language = languageService.getLocalLanguage()
  const { navigateToPromoCodeListView } = usePromoCodeSidebar()

  const navigateToProgramInfinite = () => {
    mySpaceNavigate(Path.MySpace.Training)
    setTimeout(() => {
      sidebarNavigateTo({
        content: <ProgramForm programType={ProgramInfiniteTypeOf} />,
      })
    }, 100)
  }

  const navigateToProgramOnOff = () => {
    mySpaceNavigate(Path.MySpace.Training)
    setTimeout(() => {
      sidebarNavigateTo({
        content: <ProgramForm programType={ProgramOnOffTypeOf} />,
      })
    }, 100)
  }

  const onOpenProgramListFilter = () => {
    mySpaceNavigate(Path.MySpace.Training)
    setTimeout(() => {
      sidebarNavigateTo({
        content: <TrainingProgramsFiltersView allowProgramSelection={false} />,
        replaceAllComponents: true,
      })
    }, 100)
  }

  const navigateToPromoCode = () => {
    mySpaceNavigate(Path.MySpace.Finances)
    setTimeout(() => {
      navigateToPromoCodeListView()
    }, 100)
  }

  const buttonNavigateTo = (tutorial: Tutorial) => {
    switch (tutorial.id) {
      case TutorialId.create_infinite_program:
        navigateToProgramInfinite()
        break
      case TutorialId.create_onOff_program:
        navigateToProgramOnOff()
        break
      case TutorialId.dynamic_link:
      case TutorialId.trial_period:
        onOpenProgramListFilter()
        break
      case TutorialId.promotion_code:
        navigateToPromoCode()
    }
  }

  return (
    <>
      <ScreenHeader title={word("tutorials.header.title")} subTitle={word("tutorials.header.subTitle")} />
      <ContentContainer>
        {tutorialsData.map((tutorial, i) => {
          return (
            <BlocTutorial key={i}>
              <div className="flex flex-column">
                <div className="flex">
                  <HUText fontStyle="TL">{tutorial.title}</HUText>
                  {tutorial.icon && (
                    <ContainerIcon>
                      <HUButtonIcon
                        icon={{ icon: tutorial.icon }}
                        iconColor={theme.neutralColor900}
                        defaultIconSize={24}
                      />
                    </ContainerIcon>
                  )}
                </div>
                <ContainerTextVideo>
                  <div className="flex flex-column justify-content-between pr-4">
                    <HUText fontStyle="BM" color={theme.neutralColor700}>
                      {tutorial.subTitle}
                    </HUText>
                    {tutorial.textButton && (
                      <div className="flex mt-4">
                        <HUButton
                          type="Default"
                          colorType="Primary"
                          size="M"
                          text={tutorial.textButton}
                          onClick={() => buttonNavigateTo(tutorial)}
                        ></HUButton>
                      </div>
                    )}
                  </div>
                  <ContainerVideo>
                    <YouTubeVideo
                      link={
                        language === "fr"
                          ? tutorial.linkVideoFr
                          : language === "br"
                            ? tutorial.linkVideoBr
                            : tutorial.linkVideoEn
                      }
                    />
                  </ContainerVideo>
                </ContainerTextVideo>
              </div>
            </BlocTutorial>
          )
        })}
      </ContentContainer>
      <div className="text-center">
        <HUText color={theme.neutralColor700}>{word("global.contactUs")}</HUText>
        <PressableButton to={`mailto:${Config.CONTACT_EMAIL}`}>
          <HUText fontWeight="semibold">{Config.CONTACT_EMAIL}</HUText>
        </PressableButton>
        <HUText color={theme.neutralColor700}>{word("global.happyToHelp")}</HUText>
      </div>
    </>
  )
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const BlocTutorial = styled.div`
  display: flex;
  flex-direction: row;
  white-space: pre-line;
  gap: 20px;
  padding: 30px;
  border-radius: 33px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.neutralColor100};
`
const PressableButton = styled(Link)`
  span {
    text-decoration: underline;
    padding-left: 2px;
  }
`
const ContainerIcon = styled.div`
  border-radius: 23px;
  height: 32px;
  width: 32px;
  background-color: ${({ theme }) => theme.mainWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`

const ContainerTextVideo = styled.div`
  display: flex;
  margin-top: 10px;

  @media screen and (max-width: 1260px) {
    flex-direction: column;
  }
`

const ContainerVideo = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1260px) {
    margin-top: 30px;
  }
`
