export function convertToEnum<T extends { [key: string]: string }>(
  enumValues: T,
  value: string | undefined | null,
  defaultValue: T[keyof T],
): T[keyof T]
export function convertToEnum<T extends { [key: string]: string }>(
  enumValues: T,
  value: string | undefined | null,
  defaultValue?: T[keyof T] | undefined,
): T[keyof T] | undefined
export function convertToEnum<T extends { [key: string]: string }>(
  enumValues: T,
  value: string | undefined | null,
  defaultValue: T[keyof T] | undefined = undefined,
): T[keyof T] | undefined {
  return isIncludedInEnum(enumValues, value) ? (value as T[keyof T]) : defaultValue
}

export function isIncludedInEnum<T extends { [key: string]: string }>(
  enumValues: T,
  value: string | undefined | null,
): boolean {
  return value ? Object.values(enumValues).includes(value) : false
}

export function convertToType<T extends string>(
  typesValues: readonly T[],
  value: string | undefined | null,
  defaultValue: T,
): T
export function convertToType<T extends string>(
  typesValues: readonly T[],
  value: string | undefined | null,
  defaultValue?: T | undefined,
): T | undefined
export function convertToType<T extends string>(
  typesValues: readonly T[],
  value: string | undefined | null,
  defaultValue: T | undefined = undefined,
): T | undefined {
  return isIncludedInTypes(typesValues, value) ? (value as T) : defaultValue
}

export function isIncludedInTypes<T extends string>(typesValues: readonly T[], value: T | undefined | null): boolean {
  return value ? typesValues.includes(value) : false
}
