import Union from "@images/payment/unionLogo.svg"
import { CheckmarkIcon } from "@images/svgIcons/checkmarkIcon"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import React from "react"
import styled, { useTheme } from "styled-components"

interface AdvantagesViewProps {
  title: string
  description: string
  subtitle: string
  advantages: string[]
  className?: string
  fontSizeBig?: boolean
}

export const AdvantagesView: React.FC<AdvantagesViewProps> = ({
  title,
  description,
  subtitle,
  advantages,
  className,
  fontSizeBig,
}) => {
  const theme = useTheme()

  return (
    <Container className={className}>
      <HUStack gap={32}>
        <HUStack gap={16}>
          <Text fontStyle={fontSizeBig ? "HL" : "TL"}>{title}</Text>
          <Text fontStyle="BM" color={theme.neutralColor200}>
            {description}
          </Text>
        </HUStack>
        <HUStack gap={30}>
          <Text fontStyle={fontSizeBig ? "HS" : "BL"}>{subtitle}</Text>
          {advantages.map((value, index) => (
            <HURow key={index} align gap={16}>
              <Check
                style={{
                  width: fontSizeBig ? "2.5rem" : "30px",
                  height: fontSizeBig ? "2.5rem" : "30px",
                  borderRadius: fontSizeBig ? "1.75rem" : "15px",
                }}
              >
                <CheckmarkIcon
                  color={theme.mainWhite}
                  style={{
                    width: fontSizeBig ? "22px" : "16px",
                    height: fontSizeBig ? "22px" : "16px",
                  }}
                />
              </Check>
              <Text fontStyle={fontSizeBig ? "TL" : "LL"}>{value}</Text>
            </HURow>
          ))}
        </HUStack>
      </HUStack>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryColor900};
  background-image: url(${Union});
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  flex-direction: column;
`

const Text = styled(HUText)`
  color: ${({ theme }) => theme.mainWhite};
`

const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryColor};
`
