import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getCurrencySymbol } from "@modules/currency/currency"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { ProgramListSelection } from "@modules/promoCode/components/programListSelection"
import { usePromoCodeList } from "@modules/promoCode/hooks/usePromoCodes"
import { usePromoCodeSidebar } from "@modules/promoCode/hooks/usePromoCodeSidebar"
import { PromoCodeType } from "@modules/promoCode/services/promoCodeDto"
import {
  convertFormikToPromoCodeDraft,
  getPromoCodesValidationSchema,
  PromoCodeFormikType,
} from "@modules/promoCode/types/promoCodeFormikType"
import { PromoCode } from "@modules/promoCode/types/promoCodeTypes"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { displayedFullDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { useFormik } from "formik"
import React, { useMemo, useState } from "react"
import styled, { useTheme } from "styled-components"

export const PromoCodeFormView: React.FC<{ updatedPromoCode?: PromoCode }> = ({ updatedPromoCode }) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const toast = useToast()
  const theme = useTheme()
  const language = useLanguage()
  const { fetch: refetechPromoCodeList } = usePromoCodeList(false)
  const { promoCodeService } = useServicesContext()
  const { navigateToPromoCodeDetailsView, navigateToPromoCodeListView } = usePromoCodeSidebar()

  const promoCodeTypesOptions = useMemo(() => {
    return [
      { value: PromoCodeType.PERCENT, label: word("promoCode.form.input.type.value.percentage") },
      { value: PromoCodeType.DIRECT, label: word("promoCode.form.input.type.value.fixedAmount") },
    ]
  }, [])

  const expirationOptions = useMemo(() => {
    return [
      { value: true, label: word("promoCode.form.input.expiration.value.unlimited") },
      { value: false, label: word("promoCode.form.input.expiration.value.limited") },
    ]
  }, [])

  const applicationDurationOptions = useMemo(() => {
    return [
      { value: true, label: word("promoCode.form.input.applicationDuration.value.unlimited") },
      { value: false, label: word("promoCode.form.input.applicationDuration.value.limited") },
    ]
  }, [])

  const maxAttendeesOptions = useMemo(() => {
    return [
      { value: true, label: word("promoCode.form.input.maxAttendees.value.limited") },
      { value: false, label: word("promoCode.form.input.maxAttendees.value.unlimited") },
    ]
  }, [])

  const isCreation = !updatedPromoCode

  const promoCodeFormik = useFormik<PromoCodeFormikType>({
    initialValues: {
      id: updatedPromoCode?.id,
      title: updatedPromoCode?.title ?? "",
      code: updatedPromoCode?.code ?? "",
      value: updatedPromoCode?.value ?? undefined,
      currency: "EUR", // TODO Default currency
      onlyNewCustomer: updatedPromoCode?.onlyNewCustomer ?? false,
      maxAttendeesLimited: (updatedPromoCode?.maxAttendees ?? 0) !== 0,
      maxAttendees: updatedPromoCode?.maxAttendees ?? undefined,
      noExpirationDate: !updatedPromoCode?.expirationDate,
      expirationDate: updatedPromoCode?.expirationDate ? dayjs(updatedPromoCode.expirationDate).toDate() : undefined,
      noDurationLimit: !updatedPromoCode?.durationInMonths,
      durationInMonths: updatedPromoCode?.durationInMonths ?? undefined,
      type: updatedPromoCode?.type ?? PromoCodeType.PERCENT,
      programs: updatedPromoCode?.programs.map((p) => p.id) ?? [],
    },
    validationSchema: getPromoCodesValidationSchema(),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsUpdating(true)
      if (values.id) {
        try {
          await promoCodeService.updatePromoCode(
            values.id,
            convertFormikToPromoCodeDraft(promoCodeFormik.values as PromoCodeFormikType),
          )

          toast.show({
            severity: "success",
            summary: word("promoCode.form.submit.success"),
          })

          setTimeout(async () => {
            const newPromoCodeList = await refetechPromoCodeList()
            navigateToPromoCodeListView()
            const newUpdatedPromoCode: PromoCode | undefined = newPromoCodeList?.find(
              (p) => p.id === updatedPromoCode?.id,
            )
            if (newUpdatedPromoCode) setTimeout(() => navigateToPromoCodeDetailsView(newUpdatedPromoCode), 50)
          }, 500)
        } catch (e: any) {
          console.error("Error on create objective", e)
          const regex = /An active promotion code with.*already exists\./

          toast.show({
            severity: "error",
            summary: word("global.error.label"),
            detail: regex.test(e.message)
              ? word("promoCode.form.submit.errorDuplicate", { code: promoCodeFormik.values.code })
              : e.message,
          })
        } finally {
          setIsUpdating(false)
        }
      } else {
        try {
          const newPromoCode = await promoCodeService.createPromoCode(
            convertFormikToPromoCodeDraft(promoCodeFormik.values as PromoCodeFormikType),
          )

          toast.show({
            severity: "success",
            summary: word("promoCode.form.submit.success"),
          })

          setTimeout(() => {
            navigateToPromoCodeDetailsView(newPromoCode, true)
          }, 500)
        } catch (e: any) {
          console.error("Error on create objective", e)
          const regex = /An active promotion code with.*already exists\./

          toast.show({
            severity: "error",
            summary: word("global.error.label"),
            detail: regex.test(e.message)
              ? word("promoCode.form.submit.errorDuplicate", { code: promoCodeFormik.values.code })
              : e.message,
          })
        } finally {
          setIsUpdating(false)
        }
      }
    },
  })

  const onSelectProgram = (id: string) => {
    if (promoCodeFormik.values.programs && promoCodeFormik.values.programs.indexOf(id) >= 0) {
      promoCodeFormik.setFieldValue(
        "programs",
        [...promoCodeFormik.values.programs].filter((programId) => programId !== id),
      )
    } else promoCodeFormik.setFieldValue("programs", [...(promoCodeFormik.values.programs || [])].concat(id))
  }

  const onSelectAllPrograms = (allIds: string[]) => {
    if (allIds.length === promoCodeFormik.values.programs.length) {
      promoCodeFormik.setFieldValue("programs", [])
    } else promoCodeFormik.setFieldValue("programs", allIds)
  }

  return (
    <Container gap={0}>
      <HUText fontStyle="TL">{word("promoCode.form.title")}</HUText>
      <HUText fontStyle="BM" className="mb-6 mt-4">
        {word("promoCode.form.description")}
      </HUText>
      <InputStack gap={0}>
        <FormikInput
          flex
          formikStyle={{ display: "flex", flexGrow: 1 }}
          label={word("promoCode.form.input.title.label")}
          name="title"
          isRequiredInput
          getFieldProps={promoCodeFormik.getFieldProps}
          error={getFormErrorMessage("title", promoCodeFormik)}
          isInvalid={isFormFieldInValid("title", promoCodeFormik)}
          setFieldValue={promoCodeFormik.setFieldValue}
          placeHolder={word("promoCode.form.input.title.placeholder")}
          textProps={{ maxLength: 40 }}
        />
      </InputStack>
      <InputStack gap={0}>
        <FormikInput
          flex
          formikStyle={{ display: "flex", flexGrow: 1 }}
          label={word("promoCode.form.input.code.label")}
          name="code"
          isRequiredInput
          getFieldProps={promoCodeFormik.getFieldProps}
          isInvalid={isFormFieldInValid("code", promoCodeFormik)}
          setFieldValue={promoCodeFormik.setFieldValue}
          error={getFormErrorMessage("code", promoCodeFormik)}
          placeHolder={word("promoCode.form.input.code.placeholder")}
          textProps={{
            maxLength: 30,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              promoCodeFormik.setFieldValue("code", e.target.value.toUpperCase())
            },
          }}
        />
        <HUText
          fontStyle="BS"
          color={theme.neutralColor600}
          className="mb-4"
          style={{ whiteSpace: "pre-line", marginTop: -8 }}
        >
          {word("promoCode.form.input.code.description")}
        </HUText>
      </InputStack>
      <InputStack gap={0}>
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          label={word("promoCode.form.input.type.label")}
          isRequiredInput
          getFieldProps={promoCodeFormik.getFieldProps}
          name="type"
          setFieldValue={promoCodeFormik.setFieldValue}
          isInvalid={isFormFieldInValid("type", promoCodeFormik)}
          error={getFormErrorMessage("type", promoCodeFormik)}
          type="dropdown"
          dropdownProps={{
            value: promoCodeFormik.values.type,
            options: promoCodeTypesOptions,
          }}
        />
        <FormikInput
          isRequiredInput
          formikStyle={{ display: "flex", flexGrow: 1 }}
          isInvalid={isFormFieldInValid("value", promoCodeFormik)}
          getFieldProps={promoCodeFormik.getFieldProps}
          name="value"
          error={getFormErrorMessage("value", promoCodeFormik)}
          type="number"
          numberProps={{
            min: 0,
            max: promoCodeFormik.values.type === PromoCodeType.PERCENT ? 100 : 1e5,
            maxFractionDigits: 2,
            minFractionDigits: 2,
          }}
          setFieldValue={promoCodeFormik.setFieldValue}
          rightButton={
            <HUText fontStyle="TM" className="ml-1">
              {promoCodeFormik.values.type === PromoCodeType.DIRECT
                ? getCurrencySymbol(promoCodeFormik.values.currency, language)
                : " %"}
            </HUText>
          }
        />
        <HUText
          fontStyle="BS"
          color={theme.neutralColor600}
          className="mb-4"
          style={{ whiteSpace: "pre-line", marginTop: -8 }}
        >
          {word("promoCode.form.input.type.description")}
        </HUText>
      </InputStack>
      <InputStack gap={0}>
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          label={word("promoCode.form.input.expiration.label")}
          isRequiredInput
          getFieldProps={promoCodeFormik.getFieldProps}
          name="noExpirationDate"
          setFieldValue={promoCodeFormik.setFieldValue}
          isInvalid={isFormFieldInValid("noExpirationDate", promoCodeFormik)}
          error={getFormErrorMessage("noExpirationDate", promoCodeFormik)}
          type="dropdown"
          dropdownProps={{
            value: promoCodeFormik.values.noExpirationDate,
            options: expirationOptions,
            onChange: (e) => {
              if (e.target.value) {
                promoCodeFormik.setFieldValue("expirationDate", undefined)
                promoCodeFormik.setFieldTouched("expirationDate", false)
              }
            },
          }}
        />

        {!promoCodeFormik.values.noExpirationDate && (
          <FormikInput
            formikStyle={{ flexGrow: 1 }}
            isRequiredInput
            getFieldProps={promoCodeFormik.getFieldProps}
            name="expirationDate"
            type="calendar"
            isInvalid={isFormFieldInValid("expirationDate", promoCodeFormik)}
            error={getFormErrorMessage("expirationDate", promoCodeFormik)}
            calendarProps={{
              minDate: dayjs().toDate(),
              showIcon: true,
              dateFormat: displayedFullDateFormat,
              inputStyle: { textTransform: "capitalize" },
              maxDate: dayjs().add(1, "year").toDate(),
            }}
          />
        )}
        <HUText
          fontStyle="BS"
          color={theme.neutralColor600}
          className="mb-4"
          style={{ whiteSpace: "pre-line", marginTop: -8 }}
        >
          {word("promoCode.form.input.expirationDate.description")}
        </HUText>
      </InputStack>
      <InputStack gap={0}>
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          label={word("promoCode.form.input.applicationDuration.label")}
          isRequiredInput
          getFieldProps={promoCodeFormik.getFieldProps}
          name="noDurationLimit"
          setFieldValue={promoCodeFormik.setFieldValue}
          isInvalid={isFormFieldInValid("noDurationLimit", promoCodeFormik)}
          error={getFormErrorMessage("noDurationLimit", promoCodeFormik)}
          type="dropdown"
          dropdownProps={{
            value: promoCodeFormik.values.noDurationLimit,
            options: applicationDurationOptions,
            onChange: (e) => {
              if (e.target.value) promoCodeFormik.setFieldValue("durationInMonths", undefined)
              else promoCodeFormik.setFieldValue("durationInMonths", 1)
            },
          }}
        />

        {!promoCodeFormik.values.noDurationLimit && (
          <FormikInput
            isRequiredInput
            formikStyle={{ display: "flex", flexGrow: 1 }}
            isInvalid={isFormFieldInValid("durationInMonths", promoCodeFormik)}
            getFieldProps={promoCodeFormik.getFieldProps}
            name="durationInMonths"
            error={getFormErrorMessage("durationInMonths", promoCodeFormik)}
            type="number"
            numberProps={{
              min: 0,
              max: 120,
            }}
            setFieldValue={promoCodeFormik.setFieldValue}
            rightButton={
              <HUText fontStyle="TM" className="ml-1">
                {word("global.month.label", { count: promoCodeFormik.values.durationInMonths ?? 0 })}
              </HUText>
            }
          />
        )}
        <HUText
          fontStyle="BS"
          color={theme.neutralColor600}
          className="mb-4"
          style={{ whiteSpace: "pre-line", marginTop: -8 }}
        >
          {word("promoCode.form.input.applicationDuration.description")}
        </HUText>
      </InputStack>
      <InputStack gap={0}>
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          label={word("promoCode.form.input.maxAttendees.label")}
          isRequiredInput
          getFieldProps={promoCodeFormik.getFieldProps}
          name="maxAttendeesLimited"
          setFieldValue={promoCodeFormik.setFieldValue}
          isInvalid={isFormFieldInValid("maxAttendeesLimited", promoCodeFormik)}
          error={getFormErrorMessage("maxAttendeesLimited", promoCodeFormik)}
          type="dropdown"
          dropdownProps={{
            value: promoCodeFormik.values.maxAttendeesLimited,
            options: maxAttendeesOptions,
            onChange: (e) => {
              if (e.target.value) promoCodeFormik.setFieldValue("maxAttendees", 1)
              else promoCodeFormik.setFieldValue("maxAttendees", undefined)
            },
          }}
        />
        {promoCodeFormik.values.maxAttendeesLimited && (
          <FormikInput
            isRequiredInput
            isInvalid={isFormFieldInValid("maxAttendees", promoCodeFormik)}
            getFieldProps={promoCodeFormik.getFieldProps}
            formikStyle={{ display: "flex", flexGrow: 1 }}
            name="maxAttendees"
            error={getFormErrorMessage("maxAttendees", promoCodeFormik)}
            type="number"
            numberProps={{
              min: 0,
              max: 1e5,
            }}
            setFieldValue={promoCodeFormik.setFieldValue}
            rightButton={
              <HUText fontStyle="TM" className="ml-1">
                {word("global.customer.label", { count: promoCodeFormik.values.maxAttendees ?? 0 })}
              </HUText>
            }
          />
        )}
        <HUText
          fontStyle="BS"
          color={theme.neutralColor600}
          className="mb-4"
          style={{ whiteSpace: "pre-line", marginTop: -8 }}
        >
          {word("promoCode.form.input.maxAttendees.description")}
        </HUText>
      </InputStack>
      <InputStack gap={0}>
        <FormikInput
          formikStyle={{ marginBottom: -5 }}
          label={word("promoCode.form.input.filter.label")}
          type="switchInline"
          textSwitch={word("promoCode.form.input.onlyNewCustomer.label")}
          name="onlyNewCustomer"
          getFieldProps={promoCodeFormik.getFieldProps}
          isInvalid={isFormFieldInValid("onlyNewCustomer", promoCodeFormik)}
          error={getFormErrorMessage("onlyNewCustomer", promoCodeFormik)}
          setFieldValue={promoCodeFormik.setFieldValue}
        />
        <HUText
          fontStyle="BS"
          color={theme.neutralColor600}
          className="mb-4"
          style={{ whiteSpace: "pre-line", marginTop: -8 }}
        >
          {word("promoCode.form.input.onlyNewCustomer.description")}
        </HUText>
      </InputStack>
      <HUText type="label" color={theme.neutralColor700} fontStyle="LS" style={{ lineHeight: "21px", marginBottom: 7 }}>
        {word("promoCode.form.input.relatedPrograms").toUpperCase()}
        <HUText fontStyle="LL" color={theme.necessaryInput}>
          {" *"}
        </HUText>
      </HUText>

      <ProgramListSelection
        onSelectProgram={onSelectProgram}
        onSelectAllPrograms={onSelectAllPrograms}
        selectedProgramIds={promoCodeFormik.values.programs || []}
        promoCodeType={promoCodeFormik.values.type}
        promoCodeValue={promoCodeFormik.values.value}
        promoCodeDuration={promoCodeFormik.values.durationInMonths}
        error={getFormErrorMessage("programs", promoCodeFormik)}
      />
      <HUButton
        type="Default"
        colorType="Primary"
        size="M"
        className="w-full mt-5"
        loading={isUpdating}
        onClick={promoCodeFormik.submitForm}
        text={isCreation ? word("promoCode.form.submit.create") : word("promoCode.form.submit.update")}
      />
    </Container>
  )
}

const Container = styled(HUStack)`
  > .p-disabled,
  .p-component:disabled {
    background-color: ${({ theme }) => theme.neutralColor100};
    border-color: ${({ theme }) => theme.neutralColor100};
  }
`

const InputStack = styled(HUStack)`
  margin-bottom: 10px;
`
