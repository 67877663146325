import { OneIcon } from "@images/svgIcons/numbers/oneIcon"
import { StepSeparator } from "@images/svgIcons/navigation/stepSeparator"
import { FiveIcon } from "@images/svgIcons/numbers/fiveIcon"
import { useTheme } from "styled-components"
import { TwoIcon } from "@images/svgIcons/numbers/twoIcon"
import { ThreeIcon } from "@images/svgIcons/numbers/threeIcon"
import { FourIcon } from "@images/svgIcons/numbers/fourIcon"

type BoxFormPageNumberProps = {
  disabled: boolean
  pageNumber: number
}
export const BoxFormPageNumber = ({ disabled, pageNumber }: BoxFormPageNumberProps) => {
  const theme = useTheme()

  const NumberIcon = () => {
    const props = {
      color: theme.mainWhite,
      className: "absolute",
      width: 27,
      height: 35,
      style: { zIndex: 1, marginTop: "6px", marginLeft: "10px" },
    }
    switch (pageNumber) {
      case 1:
        return <OneIcon {...props} />
      case 2:
        return <TwoIcon {...props} />
      case 3:
        return <ThreeIcon {...props} />
      case 4:
        return <FourIcon {...props} />
      case 5:
        return <FiveIcon {...props} />
    }
  }
  return (
    <div style={{ width: "70px", height: "70px" }}>
      <NumberIcon />
      <StepSeparator color={disabled ? theme.neutralColor400 : theme.primaryColor} className="absolute" />
      <StepSeparator
        color={disabled ? theme.neutralColor400 : theme.primaryColor}
        className="absolute"
        style={{ marginTop: "10px" }}
      />
      <FiveIcon
        color={disabled ? theme.neutralColor400 : theme.primaryColor}
        className="absolute"
        width={27}
        height={35}
        style={{ marginLeft: "40px", marginTop: "40px" }}
      />
    </div>
  )
}
