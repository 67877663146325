import { CardioIcon } from "@images/svgIcons/cardioIcon"
import { ChronoIcon } from "@images/svgIcons/chronoIcon"
import { GymIcon } from "@images/svgIcons/gymIcon"
import { WeightliftingIcon } from "@images/svgIcons/weightliftingIcon"
import { BoxProgrammationSession } from "@modules/boxSlot/services/boxSlotDto"
import { displayTotalTime, getTrainingChronoTitle } from "@modules/chrono/chronoUtils"
import { getDisplayExerciseCategory } from "@modules/exercises/exerciseUtils"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { Linkify } from "@modules/ui/components/linkifyText"
import styled, { useTheme } from "styled-components"

type BoxProgrammationSessionEventProps = {
  session: BoxProgrammationSession
  onClickOnSession?: (id: string) => void
  isUpdating?: boolean
  isDetailsOpen?: boolean
}

export const BoxProgrammationSessionEvent: React.FC<BoxProgrammationSessionEventProps> = ({
  session,
  onClickOnSession,
  isUpdating = false,
  isDetailsOpen = false,
}) => {
  const theme = useTheme()

  if (!session) return <></>

  const { id, name, exercises, cardioTag, gymnasticTag, weightliftingTag } = session
  const shouldDisplayTags = cardioTag || gymnasticTag || weightliftingTag

  return (
    <Container
      key={id}
      id={id}
      className={"border-bottom-1 p-2 gap-2" + (!!onClickOnSession ? " cursor-pointer" : "")}
      onClick={() => !!onClickOnSession && onClickOnSession(id)}
      $isDetailsOpen={isDetailsOpen}
    >
      <HURow className="justify-content-between align-items-center">
        <ProgramName fontStyle="TS" className="event-title" $isAuthor={true}>
          {name}
        </ProgramName>
        {isUpdating && <i className="pi pi-spin pi-spinner w-1 h-1" />}
      </HURow>

      {shouldDisplayTags && (
        <HURow className="justify-content-start align-items-center gap-2 mt-1">
          {gymnasticTag && <GymIcon color={"black"} width={30} height={"30"} />}
          {weightliftingTag && <WeightliftingIcon color={"black"} width={30} height={"30"} />}
          {cardioTag && <CardioIcon color={"black"} width={30} height={"30"} />}
        </HURow>
      )}

      {exercises.length > 0 && (
        <ExerciceStack>
          {exercises.map((exercise, exerciseIndex) => (
            <HUStack gap={8} key={exerciseIndex}>
              <HUText fontStyle="TS" className="event-category">
                {getDisplayExerciseCategory(exercise.category)}
              </HUText>
              {exercise.chrono?.type && (
                <div className="flex align-items-center">
                  <ChronoIcon color={theme.neutralColor700} width={14} height={14} />
                  <div className="flex flex-column">
                    <HUText fontStyle="BXS" className="event-content ml-2" color={theme.neutralColor700}>
                      {getTrainingChronoTitle(exercise.chrono.type)}
                    </HUText>
                    <HUText fontStyle="BXS" className="event-content ml-2" color={theme.neutralColor700}>
                      {displayTotalTime(exercise.chrono)}
                    </HUText>
                  </div>
                </div>
              )}
              <HUText
                fontStyle="BS"
                style={{ whiteSpace: "break-spaces", textWrap: "pretty" }}
                className="event-content"
              >
                <Linkify content={exercise.description} youtubeProps={{ allowEmbed: true }} />
              </HUText>
            </HUStack>
          ))}
        </ExerciceStack>
      )}
    </Container>
  )
}

const Container = styled.div<{ $isDetailsOpen: boolean }>`
  background-color: ${({ theme, $isDetailsOpen }) => ($isDetailsOpen ? theme.primaryColor100 : "transparent")};

  &:hover {
    .event-title {
      font-size: 1.05rem;
    }

    .hidden-session-icon i {
      color: ${({ theme }) => theme.secondaryColor300};
    }
  }
`

const ProgramName = styled(HUText)<{ $isAuthor: boolean }>`
  background-color: ${({ $isAuthor, theme }) => ($isAuthor ? theme.neutralColor100 : theme.secondaryColor)};
  color: ${({ $isAuthor, theme }) => ($isAuthor ? theme.textPrimary : theme.mainWhite)};
  padding: 3px 8px;
  border-radius: 15px;
  line-height: 1rem;
`

const ExerciceStack = styled(HUStack)`
  position: relative;
  margin-top: 0.5rem;

  .hidden-session-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & > i {
      color: ${({ theme }) => theme.secondaryColor200};
      font-size: 2rem;
    }
  }
`
