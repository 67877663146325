import { LoggerType } from "@modules/utils/loggerUtils"
import da from "@strings/da.json"
import de from "@strings/de.json"
import el from "@strings/el.json"
import en from "@strings/en.json"
import es from "@strings/es.json"
import fr from "@strings/fr.json"
import is from "@strings/is.json"
import it from "@strings/it.json"
import ko from "@strings/ko.json"
import MapWordingKeys from "@strings/map.json"
import nl from "@strings/nl.json"
import pt from "@strings/pt.json"
import br from "@strings/pt_BR.json"
import { I18n, TranslateOptions } from "i18n-js"

const WordingKeys = { ...MapWordingKeys } as const
export type WordingKey = keyof typeof WordingKeys

export const translations = { fr, en, it, de, es, pt, el, is, da, nl, br, ko }

export const i18n = new I18n(translations, { defaultLocale: "en", enableFallback: true })
const i18nFallback = new I18n({ en })
i18n.pluralization.register("ko", () => {
  return ["other"]
})

export function word<T extends WordingKey>(key: T, options?: TranslateOptions): string {
  const value = i18n.t(key, options)
  if (typeof value !== "string" || !value) {
    console.warn(LoggerType.Wording + "missing key", key, options)

    const fallbackValue = i18nFallback.t(key, options)
    return typeof fallbackValue === "string" ? fallbackValue : "missing"
  }
  return typeof value === "string" ? value : "missing"
}
