import React from "react"
import { IconSvgProps } from "@modules/ui/components/huButtonIcon"

export const TwoIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="73" height="101" viewBox="0 0 73 101" fill="none" {...props}>
    <path
      d="M1.32979 100.184V78.9074C1.32979 74.6521 2.03901 70.9287 3.45745 67.7372C4.96454 64.4571 7.2695 61.5759 10.3723 59.0936C13.5638 56.6113 17.6862 54.3064 22.7394 52.1787L37.5 45.9287C41.3121 44.333 44.0603 42.4269 45.7447 40.2106C47.5177 37.9943 48.4043 35.2018 48.4043 31.833C48.4043 28.7301 47.3404 26.1149 45.2128 23.9872C43.1738 21.7709 40.1596 20.6627 36.1702 20.6627C32.0922 20.6627 29.0337 21.8152 26.9947 24.1202C24.9557 26.3365 23.9362 29.3507 23.9362 33.1627H0C0 26.7798 1.37411 21.106 4.12234 16.1415C6.87057 11.1769 10.9043 7.27622 16.2234 4.43934C21.6312 1.60246 28.2801 0.184021 36.1702 0.184021C43.9716 0.184021 50.5319 1.55814 55.8511 4.30636C61.2589 6.96593 65.3369 10.645 68.0851 15.3436C70.922 19.9535 72.3404 25.184 72.3404 31.0351V32.6308C72.3404 40.1663 70.3014 46.372 66.2234 51.2478C62.1454 56.1237 55.5851 60.512 46.5425 64.4127L31.7819 70.6627C29.6543 71.5493 28.0585 72.5244 26.9947 73.5883C26.0195 74.6521 25.5319 76.0705 25.5319 77.8436V82.6308L19.8138 78.9074H72.0745V100.184H1.32979Z"
      fill={props.color}
    />
  </svg>
)
