import { useState } from "react"

export function useDraft<T>(convert: () => T) {
  const [draft, setDraft] = useState(convert())

  const setField = (fields: Partial<T>) => {
    setDraft((d) => ({ ...d, ...fields }))
  }

  return { draft, setField }
}
