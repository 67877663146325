import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const WeightliftingIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="1" width="60" height="60" rx="18" fill="#806FF7" />
    <rect x="1" y="1" width="60" height="60" rx="18" stroke="url(#paint0_linear_470_97558)" strokeWidth="1.11776" />
    <rect x="12" y="25.231" width="3" height="12.4615" rx="1.5" fill="white" />
    <rect
      opacity="0.4"
      x="45.498"
      y="30.4619"
      width="2"
      height="29.4978"
      rx="1"
      transform="rotate(90 45.498 30.4619)"
      fill="white"
    />
    <rect x="14" y="19.0005" width="3" height="24.923" rx="1.5" fill="white" />
    <rect width="3" height="12.4615" rx="1.5" transform="matrix(-1 0 0 1 49.498 25.231)" fill="white" />
    <rect width="3" height="24.923" rx="1.5" transform="matrix(-1 0 0 1 47.498 19)" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_470_97558"
        x1="3.15569"
        y1="2"
        x2="43.3973"
        y2="67.9826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.04" />
        <stop offset="0.188137" stopColor="white" stopOpacity="0" />
        <stop offset="0.708289" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.06" />
      </linearGradient>
    </defs>
  </svg>
)
