import { BoxSlotTypeOf } from "@modules/boxSlot/boxSlotTypes"
import { ProfileDto } from "@modules/profile/profileDtos"
import { ParticipantStatusDto } from "@modules/profile/subscriber/subscriberDtos"
import { SessionTypeOf } from "@modules/programSession/programSessionTypes"
import {
  ItemProgramInfiniteSessionDto,
  ItemProgramOnOffSessionDto,
} from "@modules/programSession/services/programSessionDto"
import { ProgramType, ProgramVisibility } from "@modules/programs/programTypes"
import {
  TrainingContent,
  convertToTrainingContent,
  convertTrainingItemBoxSlotFromDto,
  convertTrainingItemProgramSessionFromDto,
} from "@modules/training/trainingTypes"

export type ItemTrainingContentTypesDto =
  | ItemProgramInfiniteSessionDto
  | ItemProgramOnOffSessionDto
  | TrainingItemBoxSlotDto

type TrainingProgramDto = {
  id: string
  name: string
}

type TrainingBoxDto = {
  id: string
  name: string
}

type TrainingBaseContentDto = {
  type: string
  content: ItemTrainingContentTypesDto
}

export type TrainingProgamSessionContentDto = {
  type: typeof SessionTypeOf
  content: TrainingItemSessionDto
} & TrainingBaseContentDto

export type TrainingBoxSlotContentDto = {
  type: typeof BoxSlotTypeOf
  content: TrainingItemBoxSlotDto
} & TrainingBaseContentDto

export type TrainingItemSessionDto = {
  id: string
  name: string
  description: string
  date: string
  owner: ProfileDto
  programId: string
  programName: string
  programVisibility: ProgramVisibility
  programType: ProgramType
  hideSessionContent: boolean
  weightliftingTag: boolean
  gymnasticTag: boolean
  cardioTag: boolean
  index?: number
  restDay?: boolean
}

export type TrainingItemBoxSlotDto = {
  id: string
  startDate: string
  endDate: string
  program: TrainingProgramDto
  box: TrainingBoxDto
  owner: ProfileDto
  participantCount: number
  participantLimit: number
  sessionId?: string
  participantStatus: ParticipantStatusDto
}

export type TrainingContentDto = TrainingProgamSessionContentDto | TrainingBoxSlotContentDto

export function convertTrainingContentFromDto(dto: TrainingContentDto): TrainingContent | undefined {
  if (dto.type === SessionTypeOf) {
    return convertToTrainingContent(convertTrainingItemProgramSessionFromDto(dto.content))
  } else if (dto.type === BoxSlotTypeOf) {
    return convertToTrainingContent(convertTrainingItemBoxSlotFromDto(dto.content))
  }

  return undefined
}
