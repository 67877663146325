import { useCurrentBox } from "@modules/box/useBox"
import { useServicesContext } from "@modules/core/services/services.context"
import { defaultMonthValue } from "@modules/finances/components/defaultMonth"
import { usePeriodParam } from "@modules/finances/hooks/usePeriod"
import { allTransactionsStatus } from "@modules/finances/types/financesType"
import {
  BoxItemTransaction,
  MemberTransaction,
  TransactionMember,
  UserItemTransaction,
  UserTransaction,
} from "@modules/finances/types/transactionType"
import { useAsync } from "@modules/hooks/useAsync"
import { isInArray } from "@modules/utils/isInArray"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useQueryParam } from "@modules/utils/navigation/useQueryParam"
import { useObservable } from "micro-observables"
import { useEffect, useState } from "react"

export const useTransactions = (size: number) => {
  const { result: boxId } = useCurrentBox()
  const { boxFinanceService, userFinanceService } = useServicesContext()
  const financeService = boxId ? boxFinanceService : userFinanceService
  const refreshingCount = useObservable(financeService.refreshingCount)
  const [transactionsTab] = useQueryParam("tab")
  const [period] = usePeriodParam()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [forceRefresh, setForceRefresh] = useState(0)

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [allTransactions, setAllTransactions] = useState<BoxItemTransaction[][] | UserItemTransaction[][]>([])
  const [nextPageToFetch, setNextPageToFetch] = useState<string | undefined>(undefined)

  const fetch = async () => {
    if (!allTransactions[currentPageIndex] && !loading) {
      try {
        setLoading(true)
        const result = await financeService.getTransactions(
          isInArray(allTransactionsStatus, transactionsTab) ? transactionsTab : undefined,
          period ?? defaultMonthValue,
          nextPageToFetch,
          size,
        )
        const newArray = allTransactions
        newArray[currentPageIndex] = result.data
        setAllTransactions([...newArray])
        setNextPageToFetch(result.nextPage)
      } catch (e) {
        console.log(LoggerType.Financial, "get transactions ", JSON.stringify(e))
        setError(JSON.stringify(e))
      } finally {
        setLoading(false)
      }
    }
  }

  const cleanData = () => {
    setAllTransactions([])
    setNextPageToFetch(undefined)
    setCurrentPageIndex(0)
    setForceRefresh((v) => v + 1)
  }

  useEffect(() => {
    cleanData()
  }, [transactionsTab, size, refreshingCount, period])

  useEffect(() => {
    fetch()
  }, [currentPageIndex, forceRefresh])

  const currentData = allTransactions[currentPageIndex] ?? []

  return {
    loading,
    error,
    value: currentData,
    previous: currentPageIndex > 0 ? () => setCurrentPageIndex((v) => v - 1) : undefined,
    next:
      currentPageIndex < allTransactions.length - 1 || nextPageToFetch
        ? () => setCurrentPageIndex((v) => v + 1)
        : undefined,
  }
}

type TransactionResponse = UserTransaction | MemberTransaction

export const useTransaction = (
  id: string,
): { value: TransactionResponse | null; error: Error | null; loading: boolean } => {
  const { result: boxId } = useCurrentBox()
  const { userFinanceService } = useServicesContext()
  const { boxFinanceService } = useServicesContext()

  return useAsync(
    () => (id && !boxId ? userFinanceService.getTransaction(id) : boxFinanceService.getBoxTransaction(id)),
    [id],
  )
}

export const isUserTransaction = (transaction: TransactionResponse): transaction is UserTransaction => {
  return (transaction as UserTransaction).user !== undefined
}

export const isMemberTransaction = (transaction: TransactionResponse): transaction is MemberTransaction => {
  return (transaction as MemberTransaction).member !== undefined
}

export const useTransactionUser = (transactionDetails: TransactionResponse | null): TransactionMember | undefined => {
  const [user, setUser] = useState<TransactionMember | undefined>(undefined)

  useEffect(() => {
    if (transactionDetails) {
      if (isUserTransaction(transactionDetails)) {
        setUser(transactionDetails.user)
      } else if (isMemberTransaction(transactionDetails)) {
        setUser(transactionDetails.member)
      }
    }
  }, [transactionDetails])
  return user
}
