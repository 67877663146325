import { EventType } from "@modules/calendar/calendarTypes"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import styled from "styled-components"

export const DefaultCalendarEvent: React.FC<{ index: number; event: EventType; displayDetailedEvents: boolean }> = ({
  index,
  event,
  displayDetailedEvents,
}) => {
  const { title, content } = event

  return (
    <Container key={index} gap={2} className="border-bottom-1 p-2">
      <HUText fontStyle="TM">{title}</HUText>
      {displayDetailedEvents && <HUText fontStyle="TS">{content}</HUText>}
    </Container>
  )
}

const Container = styled(HUStack)``
