import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import styled from "styled-components"

export const BoxProgrammingHeaderLeftView: React.FC = () => {
  return (
    <Container gap={10} className="p-1 md:p-2 xl:p-4 mb-5">
      <HUText fontStyle="TM">{word("box.programmation.activity.typicalWeek")}</HUText>
      <HUText fontStyle="BS">{word("box.programmation.typicalWeek.subTitle")}</HUText>
    </Container>
  )
}

const Container = styled(HUStack)`
  border-radius: 33px;
  height: 200px;
  background-color: ${({ theme }) => theme.surfaceCard};
`
