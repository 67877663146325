import { ApiClient } from "@modules/core/api/client/apiClient"
import { Objective, ObjectiveDraft } from "@modules/objectives/objectivesTypes"
import {
  ObjectiveDto,
  convertObjectiveDraftToDto,
  convertObjectiveFromDto,
} from "@modules/objectives/services/objectivesDto"

export class ObjectivesApi {
  private baseUrl = "/objectives"

  constructor(private api: ApiClient) {}

  async getObjectivePrimary(): Promise<Objective | undefined> {
    const resultDto = await this.api.get<ObjectiveDto>(`${this.baseUrl}/primary`)
    return resultDto.data ? convertObjectiveFromDto(resultDto.data) : undefined
  }

  async createNewObjective(objective: ObjectiveDraft): Promise<Objective> {
    const resultDto = await this.api.post<ObjectiveDto>(`${this.baseUrl}`, convertObjectiveDraftToDto(objective))
    return convertObjectiveFromDto(resultDto.data)
  }

  async updateObjective(objective: ObjectiveDraft): Promise<Objective> {
    const resultDto = await this.api.put<ObjectiveDto>(
      `${this.baseUrl}/${objective.id}`,
      convertObjectiveDraftToDto(objective),
    )
    return convertObjectiveFromDto(resultDto.data)
  }

  async deleteObjective(id: string): Promise<void> {
    await this.api.delete<ObjectiveDto>(`${this.baseUrl}/${id}`)
  }
}
