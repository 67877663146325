import { ProgramCalendarEventType } from "@modules/calendar/calendarTypes"
import { word } from "@modules/core/utils/i18n"
import { Exercise, ExerciseDraft } from "@modules/exercises/exerciseTypes"
import { ProgramAuthor } from "@modules/profile/profileTypes"
import { ProgramVisibility } from "@modules/programs/programTypes"
import { DefaultTheme } from "styled-components"

export const SessionTypeOf = "SESSION"
export const ProgramInfiniteSessionTypeOf = "CONTINUOUS_PROGRAM_SESSION"
export const ProgramOnOffSessionTypeOf = "ONOFF_PROGRAM_SESSION"

export type ItemProgramInfiniteSession = {
  id: string
  _type: typeof ProgramInfiniteSessionTypeOf
  name: string
  programId: string
  date: string
  virtualProgramByDateIndex: number
  programVisibility: ProgramVisibility
  exercises: Exercise[]
  cardioTag?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  hideSessionContent: boolean
  author: ProgramAuthor
}

export type ItemProgramOnOffSession = {
  id: string
  _type: typeof ProgramOnOffSessionTypeOf
  name: string
  programId: string
  date?: string
  restDay: boolean
  index: number
  programVisibility: ProgramVisibility
  exercises: Exercise[]
  cardioTag?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  hideSessionContent: boolean
  author: ProgramAuthor
}

export type ProgramInfiniteSession = {
  id: string
  _type: typeof ProgramInfiniteSessionTypeOf
  name: string
  date: string
  programId: string
  description: string
  programVisibility: ProgramVisibility
  exercises: Exercise[]
  virtualProgramByDateIndex?: number
  cardioTag?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  author: ProgramAuthor
  hideSessionContent: boolean
}

export type ProgramOnOffSession = {
  id: string
  _type: typeof ProgramOnOffSessionTypeOf
  name: string
  date?: string
  restDay: boolean
  index: number
  programId: string
  description: string
  programVisibility: ProgramVisibility
  exercises: Exercise[]
  cardioTag?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  author: ProgramAuthor
  hideSessionContent: boolean
}

export type ProgramInfiniteSessionDraft = {
  name: string
  programId: string
  date?: string
  description: string
  exercises: ExerciseDraft[]
  cardioTag?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  hideSessionContent: boolean
  virtualProgramByDateIndex?: number
}

export type ProgramOnOffSessionDraft = {
  name: string
  programId: string
  dayIndex?: number
  restDay: boolean
  description: string
  exercises: ExerciseDraft[]
  cardioTag?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  hideSessionContent: boolean
}

export const convertItemProgramSessionsToEvent = (
  sessions: (ItemProgramInfiniteSession | ItemProgramOnOffSession)[],
  userId: string,
  theme: DefaultTheme,
): ProgramCalendarEventType[] => {
  return sessions.map((session) => {
    const isProgramUser = session.author.id === userId
    const typeSpecificData =
      session._type === ProgramInfiniteSessionTypeOf
        ? {
            date: session.date,
            virtualProgramByDateIndex: session.virtualProgramByDateIndex,
          }
        : {
            date: session.date,
            dayIndex: session.index,
            restDay: session.restDay,
          }

    return {
      id: session.id,
      title: session.name,
      color: theme.mainWhite,
      textColor: theme.neutralColor900,
      editable: isProgramUser,
      exercises: session.exercises,
      cardioTag: session.cardioTag,
      gymnasticTag: session.gymnasticTag,
      weightliftingTag: session.weightliftingTag,
      hideSessionContent: session.hideSessionContent,
      ...typeSpecificData,
    }
  })
}

export function convertProgramInfiniteSessionToDraft(
  session: ProgramInfiniteSession | undefined | null,
): ProgramInfiniteSessionDraft {
  return {
    name: session?.name ?? word("program.session.name.default"),
    description: session?.description ?? "",
    exercises: session?.exercises ?? [],
    programId: session?.programId ?? "",
    date: session?.date ?? "",
    hideSessionContent: session?.hideSessionContent ?? false,
    cardioTag: session?.cardioTag ?? false,
    gymnasticTag: session?.gymnasticTag ?? false,
    weightliftingTag: session?.weightliftingTag ?? false,
  }
}

export function convertProgramOnOffSessionToDraft(
  session: ProgramOnOffSession | undefined | null,
): ProgramOnOffSessionDraft {
  return {
    name: session?.name ?? word("program.session.name.default"),
    description: session?.description ?? "",
    exercises: session?.exercises ?? [],
    programId: session?.programId ?? "",
    hideSessionContent: session?.hideSessionContent ?? false,
    cardioTag: session?.cardioTag ?? false,
    gymnasticTag: session?.gymnasticTag ?? false,
    weightliftingTag: session?.weightliftingTag ?? false,
    dayIndex: session?.index,
    restDay: session?.restDay ?? false,
  }
}
