import checkImage from "@images/svgIcons/checkIcon.svg"
import { ProfileFormikType, ProfileInputKey } from "@modules/auth/profileFormikTypes"
import { word } from "@modules/core/utils/i18n"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import styled, { useTheme } from "styled-components"

export type RegisterFormOnePageProps = {
  handleSubmit: () => void
  onPrevious: () => void
  formik: SimpleFormik<ProfileFormikType, ProfileInputKey>
  isFormFieldInValid: (name: ProfileInputKey) => boolean
  getFormErrorMessage: (name: ProfileInputKey, errorMessage?: string) => JSX.Element | undefined
  disabled: boolean
}
export const RegisterFormOnePage = ({
  handleSubmit,
  onPrevious,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  disabled,
}: RegisterFormOnePageProps) => {
  const theme = useTheme()
  return (
    <Container>
      <HUStack gap={20} className="relative mb-4">
        <HUButton
          type="Left_Icon"
          size="XS"
          colorType="Tertiary"
          text={word("global.previous")}
          className=""
          onClick={onPrevious}
          icon={{
            iconView: <span className={`pi pi-arrow-left`} style={{ color: theme.textPrimary }} />,
          }}
        />
        <CheckImage src={checkImage} width={100} />
        <HUText fontStyle="TM">1/4</HUText>
        <HUText fontStyle="TM">{word("auth.signup.form.page.1.title")}</HUText>
        <HUText fontStyle="BS" color={theme.neutralColor700}>
          {word("auth.signup.form.page.1.description")}
        </HUText>
        <FormikInput
          flex
          isInvalid={isFormFieldInValid("firstname")}
          getFieldProps={formik.getFieldProps}
          name="firstname"
          error={getFormErrorMessage("firstname")}
          placeHolder={word("auth.signup.form.first_name")}
        />
        <FormikInput
          flex
          isInvalid={isFormFieldInValid("lastname")}
          getFieldProps={formik.getFieldProps}
          name="lastname"
          error={getFormErrorMessage("lastname")}
          placeHolder={word("auth.signup.form.last_name")}
        />
      </HUStack>
      <HUButton
        type="Default"
        size="M"
        colorType="Primary"
        text={word("global.next")}
        style={{ width: "100%" }}
        onClick={handleSubmit}
        disabled={disabled}
        submit
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`
const CheckImage = styled.img`
  position: absolute;
  top: -8%;
  right: 0px;
`
