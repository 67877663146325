import { Chrono } from "@modules/chrono/chronoTypes"

export const BenchmarkTypeOf = "BENCHMARK"

export const BenchmarkCategories = ["HUSTLE_UP", "GIRLS", "OPEN", "GAMES", "HEROES"] as const
export type BenchmarkCategory = (typeof BenchmarkCategories)[number]

export type ItemBenchmark = {
  id: string
  _type: typeof BenchmarkTypeOf
  title: string
  category: BenchmarkCategory
  chrono?: Chrono
  isFavorite: boolean
}

export type Benchmark = {
  description: string
} & ItemBenchmark
