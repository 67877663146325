import { ArticleCard } from "@modules/article/components/articleCard"
import { useArticle } from "@modules/article/hooks/useArticle"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import styled from "styled-components"

export const NewsResume: React.FC = () => {
  const { articleList } = useArticle()

  return (
    <Container>
      <HUText fontStyle="TL">{word("home.screen.articles.title")}</HUText>

      <ArticleList>{articleList?.map((article) => <ArticleCard key={article._id} article={article} />)}</ArticleList>
    </Container>
  )
}

const Container = styled.div`
  overflow-y: auto;
  @media screen and (max-width: 1350px) {
    margin-top: 10px;
  }
`

const ArticleList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  @media screen and (max-width: 1350px) {
    padding: 12px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    justify-items: center;
    gap: 20px;
    max-height: 100%;
  }
`
