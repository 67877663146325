import { HUButton } from "@modules/ui/components/huButton"
import { Menu } from "primereact/menu"
import { MenuItem } from "primereact/menuitem"
import { useRef } from "react"
import styled, { useTheme } from "styled-components"

export type EllipsisMenuProps = {
  disabled?: boolean
  items: MenuItem[]
  width?: number
  buttonClassName?: string
  style?: React.CSSProperties
}

export const EllipsisMenu: React.FC<EllipsisMenuProps> = ({
  disabled,
  items,
  width = 200,
  buttonClassName = "h-2rem w-2rem mr-2",
  style,
}) => {
  const menuRight = useRef<Menu>(null)
  const theme = useTheme()

  return (
    <Container className="flex justify-content-center relative">
      <MenuContainer
        appendTo={"self"}
        $width={width}
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_left"
        popupAlignment="right"
        theme={theme}
      />
      <HUButton
        disabled={disabled}
        type="Rounded"
        colorType="Secondary"
        size="M"
        onClick={(event) => menuRight && menuRight.current?.toggle(event)}
        aria-controls="popup_menu_right"
        aria-haspopup
        icon={{
          iconView: <span className={`pi pi-ellipsis-v`} style={{ color: theme.buttonQuaternaryTextColor }} />,
        }}
        className={buttonClassName}
        style={style}
      />
    </Container>
  )
}

const Container = styled.div`
  .border-none-hover:hover {
    border: none !important;
  }
`

const MenuContainer = styled(Menu)<{ $width: number }>`
  border-radius: 20px;
  margin-top: 5px;
  width: ${({ $width }) => $width}px !important;
  top: 30px !important;
  left: inherit !important;
  right: 0px !important;
  background-color: ${({ theme }) => theme.background} !important;
  border: 1px solid ${({ theme }) => theme.neutralColor400} !important;

  ul li:first-child {
    display: none;
  }
  padding: 10px 0;
  .p-menuitem-link:not(.p-disabled):last-child:hover {
    border-radius: 5px;
  }

  .p-menuitem-text {
    margin-left: 10px;
    color: ${({ theme }) => theme.neutralColor700} !important;
  }
`
