import { FetchStrategy, useStore } from "@betomorrow/micro-stores"
import { useServicesContext } from "@modules/core/services/services.context"
import { useAsync } from "@modules/hooks/useAsync"

export const useBoxSlotSessionDetails = (id?: string, fetchStrategy?: FetchStrategy) => {
  const { boxSlotService } = useServicesContext()
  return useStore(id, boxSlotService.slotStore, fetchStrategy)
}

export const useBoxSessionDetails = (id: string) => {
  const { boxSlotService } = useServicesContext()
  return useAsync(() => (id ? boxSlotService.getSessionDetails(id) : Promise.resolve(null)), [])
}
