import { BOX_SLOT_PARTICIPANTS_LIMIT_MAX, BOX_SLOT_PARTICIPANTS_LIMIT_MIN } from "@modules/boxSlot/boxSlotTypes"
import { BoxSlotTemplateDraft, Weekday } from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import { generateTimeSlots } from "@modules/boxSlotTemplate/boxSlotTemplateUtils"
import { word } from "@modules/core/utils/i18n"
import { convertTimeStringToTimeValue } from "@modules/duration/timeConverter"
import * as Yup from "yup"

export type BoxSlotTemplateGroupInputKey =
  | "boxProgrammationId"
  | "participantLimit"
  | "slotDuration"
  | "limitStart"
  | "limitEnd"
  | "limitCancel"
  | "location"
  | "slotTemplates"
  | `${string}.startTime`
  | `${string}.endTime`

export type BoxSlotTemplateGroupFormikType = {
  boxProgrammationId: string
  participantLimit: number
  slotDuration: string
  limitStart?: number
  limitEnd?: number
  limitCancel?: number
  location?: string
  slotTemplatesRanges: BoxSlotTemplateRangeFormikType[]
}

export type BoxSlotTemplateRangeFormikType = {
  index: number
  weekDay: string
  startTime: Date
  endTime: Date
}

export function getValidationSchema() {
  return Yup.object({
    boxProgrammationId: Yup.string().required(word("global.form.required")),
    slotDuration: Yup.string().required(word("global.form.required")),
    participantLimit: Yup.number()
      .required(word("global.form.required"))
      .min(
        BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
        word("global.form.minimumValue", { minValue: BOX_SLOT_PARTICIPANTS_LIMIT_MIN }),
      )
      .max(
        BOX_SLOT_PARTICIPANTS_LIMIT_MAX,
        word("global.form.maximumValue", { maxValue: BOX_SLOT_PARTICIPANTS_LIMIT_MAX }),
      ),
    slotTemplatesRanges: Yup.array()
      .of(
        Yup.object({
          weekDay: Yup.string().required(word("global.form.required")).trim(),
          startTime: Yup.string().required(word("global.form.required")),
          endTime: Yup.string().required(word("global.form.required")),
        }),
      )
      .min(1, word("box.slotTemplateGroup.form.validation.missingRange"))
      .required(),
    location: Yup.string()
      .trim()
      .nullable()
      .max(40, word("form.maximum.characters", { count: 40 })),
    limitStart: Yup.number()
      .min(1, word("global.form.minimumValue", { minValue: 1 }))
      .required(word("global.form.required")),
    limitEnd: Yup.number()
      .min(1, word("global.form.minimumValue", { minValue: 1 }))
      .required(word("global.form.required")),
    limitCancel: Yup.number()
      .min(1, word("global.form.minimumValue", { minValue: 1 }))
      .required(word("global.form.required")),
  })
}

export const convertBoxSlotTemplatesListFormToDraft = (
  formik: BoxSlotTemplateGroupFormikType,
): BoxSlotTemplateDraft[] => {
  let boxSlotTemplatesBeingCreated: BoxSlotTemplateDraft[] = []
  formik.slotTemplatesRanges.forEach((range) => {
    boxSlotTemplatesBeingCreated = [
      ...boxSlotTemplatesBeingCreated,
      ...generateTimeSlots(
        range.startTime,
        range.endTime,
        convertTimeStringToTimeValue("HH:mm", formik.slotDuration) ?? 0,
        formik.participantLimit ?? 0,
        range.weekDay as Weekday,
      ),
    ]
  })

  return boxSlotTemplatesBeingCreated.map((boxSlotTemplateBeingCreated) => ({
    ...boxSlotTemplateBeingCreated,
    location: formik.location,
    participation: {
      limitStart: formik.limitStart,
      limitEnd: formik.limitEnd,
      limitCancel: formik.limitCancel,
    },
  }))
}
