import { ItemBoxSlot } from "@modules/boxSlot/boxSlotTypes"
import { ItemBoxSlotDto, convertItemBoxSlotFromDto } from "@modules/boxSlot/services/boxSlotDto"
import { ApiClient } from "@modules/core/api/client/apiClient"
import dayjs from "dayjs"

export class BoxPlanningApi {
  private baseUrl = "/boxes/slots"

  constructor(private api: ApiClient) {}

  async getSlots(startDate: string, endDate: string, programId?: string): Promise<ItemBoxSlot[]> {
    const result = await this.api.get<ItemBoxSlotDto[]>(`${this.baseUrl}`, {
      params: {
        startTime: dayjs(startDate).toISOString(), // todo ben check (before was dayjs().format(defaultDateFormat)),
        endTime: dayjs(endDate).toISOString(), // todo ben check (before was dayjs().format(defaultDateFormat)),
        programId,
      },
    })

    return result.data.map((slotDto) => convertItemBoxSlotFromDto(slotDto))
  }
}
