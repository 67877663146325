import { BoxSlotSessionFormikType } from "@modules/boxSlot/boxSessionFormikTypes"
import { ChronoType } from "@modules/chrono/chronoTypes"
import {
  GetChronoDefaults,
  MAX_NUMBER_INPUT,
  MIN_NUMBER_INPUT,
  getTrainingChronoTitle,
} from "@modules/chrono/chronoUtils"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import {
  ProgramInfiniteSessionFormikType,
  ProgramOnOffSessionFormikType,
} from "@modules/programSession/programSessionFormikType"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import _ from "lodash"
import { InputNumberChangeEvent } from "primereact/inputnumber"
import { useEffect } from "react"
import styled from "styled-components"

export type AmrapInfos = {
  trainingTime: number
  restTime: number
}

const amrapInit: AmrapInfos = {
  trainingTime: 600,
  restTime: 0,
}

type ChronoSettingsAMRAPFormProps = {
  index: number
  formik: SimpleFormik<ProgramInfiniteSessionFormikType | ProgramOnOffSessionFormikType | BoxSlotSessionFormikType, any>
}

export const ChronoSettingsAMRAPForm: React.FC<ChronoSettingsAMRAPFormProps> = ({ index, formik }) => {
  const formikNameBase = `exercises[${index}].chronoAmrap`
  const formikValue = formik.values.exercises[index].chronoAmrap

  useEffect(() => {
    if (!formikValue) {
      formik.setFieldValue(formikNameBase, GetChronoDefaults(ChronoType.AMRAP))
    }
  }, [])

  if (index === null) return <></>

  const addSet = () => {
    if (formikValue) {
      formik.setFieldValue(`${formikNameBase}.trainingTime`, [...formikValue.trainingTime, amrapInit.trainingTime])
      formik.setFieldValue(`${formikNameBase}.restTime`, [...formikValue.restTime, amrapInit.restTime])
    }
  }

  const deleteSet = (index: number) => {
    if (formikValue) {
      formik.setFieldValue(
        `${formikNameBase}.trainingTime`,
        formikValue.trainingTime.filter((_, i) => i !== index),
      )
      formik.setFieldValue(
        `${formikNameBase}.restTime`,
        formikValue.restTime.filter((_, i) => i !== index),
      )
    }
  }

  const handleSetChange = (e: InputNumberChangeEvent) => {
    if (e.value && formikValue) {
      const numberOfSets = formikValue.numberOfSets
      if (e.value > numberOfSets) {
        addSet()
      } else if (e.value < numberOfSets) {
        deleteSet(numberOfSets - 1)
      }
    }
    e.originalEvent.preventDefault()
  }

  return (
    <HUStack gap={5}>
      <FormikInput
        formikStyle={{ display: "flex", flexGrow: 1 }}
        textLabel={word("chrono.settings.label.training_time")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid(`${formikNameBase}.trainingTime[0]`, formik)}
        error={getFormErrorMessage(`${formikNameBase}.trainingTime[0]`, formik)}
        name={`${formikNameBase}.trainingTime[0]`}
        type="maskedInput"
        maskedInputProps={{
          placeholder: "00min 00sec",
          slotChar: "00min 00sec",
          mask: "99min 99sec",
          onChange: (e) => {
            formik.setFieldValue(`${formikNameBase}.trainingTime[0]`, e.target.value)
          },
        }}
      />
      <FormikInput
        formikStyle={{ display: "flex", flexGrow: 1 }}
        textLabel={word("chrono.settings.label.number_sets")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid(`${formikNameBase}.numberOfSets`, formik)}
        error={getFormErrorMessage(`${formikNameBase}.numberOfSets`, formik)}
        setFieldValue={formik.setFieldValue}
        name={`${formikNameBase}.numberOfSets`}
        type="numberChrono"
        numberProps={{
          max: MAX_NUMBER_INPUT,
          min: MIN_NUMBER_INPUT,

          onChange: handleSetChange,
        }}
      />
      <HUStack gap={10}>
        {formikValue &&
          _.range(1, formikValue.numberOfSets).map((amarapIndex) => (
            <HUStack key={amarapIndex} gap={5}>
              <HUText fontStyle="BL">{`${getTrainingChronoTitle(ChronoType.AMRAP)} ${amarapIndex + 1}`}</HUText>
              <AMARAPContainer key={amarapIndex}>
                <FormikInput
                  flex
                  formikStyle={{ display: "flex", flexGrow: 1 }}
                  textLabel={word("chrono.settings.label.rest_time")}
                  getFieldProps={formik.getFieldProps}
                  isInvalid={isFormFieldInValid(`${formikNameBase}.restTime[${amarapIndex}]`, formik)}
                  error={getFormErrorMessage(`${formikNameBase}.restTime[${amarapIndex}]`, formik)}
                  name={`${formikNameBase}.restTime[${amarapIndex}]`}
                  type="maskedInput"
                  maskedInputProps={{
                    placeholder: "00min 00sec",
                    slotChar: "00min 00sec",
                    mask: "99min 99sec",
                    onChange: (e) => {
                      formik.setFieldValue(`${formikNameBase}.restTime[${amarapIndex}]`, e.target.value)
                    },
                  }}
                />
                <FormikInput
                  flex
                  formikStyle={{ display: "flex", flexGrow: 1 }}
                  textLabel={word("chrono.settings.label.training_time")}
                  getFieldProps={formik.getFieldProps}
                  isInvalid={isFormFieldInValid(`${formikNameBase}.trainingTime[${amarapIndex}]`, formik)}
                  error={getFormErrorMessage(`${formikNameBase}.trainingTime[${amarapIndex}]`, formik)}
                  name={`${formikNameBase}.trainingTime[${amarapIndex}]`}
                  type="maskedInput"
                  maskedInputProps={{
                    placeholder: "00min 00sec",
                    slotChar: "00min 00sec",
                    mask: "99min 99sec",
                    onChange: (e) => {
                      formik.setFieldValue(`${formikNameBase}.trainingTime[${amarapIndex}]`, e.target.value)
                    },
                  }}
                />
              </AMARAPContainer>
            </HUStack>
          ))}
      </HUStack>
    </HUStack>
  )
}

const AMARAPContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
`
