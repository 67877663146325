import { CircleDashedIcon } from "@images/svgIcons/circleDashed"
import { ForbiddenIcon } from "@images/svgIcons/forbiddenIcon"
import { BoxProgrammationSessionEvent } from "@modules/boxProgrammationSessions/components/boxProgrammationSessionEvent"
import { ItemBoxSlot } from "@modules/boxSlot/boxSlotTypes"
import { BoxProgrammationSession } from "@modules/boxSlot/services/boxSlotDto"
import { AddEventButton } from "@modules/calendar/components/addEventButton"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { dateSeparator } from "@modules/utils/dateUtils"
import dayjs, { Dayjs } from "dayjs"
import styled, { useTheme } from "styled-components"

export const BoxProgrammationSessionsCalendarDayColumn: React.FC<{
  sessions: BoxProgrammationSession[]
  slots?: ItemBoxSlot[]
  onAddSessionClick: (arg0: dayjs.Dayjs) => void
  onClickOnSession: (arg0: string) => void
  date: Dayjs | undefined
}> = ({ sessions, slots, onAddSessionClick, date, onClickOnSession }) => {
  const theme = useTheme()

  return (
    <Container
      id={`${dateSeparator}`}
      className={"pt-2 pl-0 pr-0 lg:pl-1 lg:pr-1 dayCell " + (dayjs(date).isSame(dayjs(), "date") ? "today" : "future")}
      style={{
        backgroundColor: typeof slots === "undefined" || slots.length === 0 ? theme.neutralColor50 : "transparent",
      }}
    >
      <div style={{ minHeight: "auto" }}>
        {sessions.map((session) => (
          <BoxProgrammationSessionEvent key={session.id} session={session} onClickOnSession={onClickOnSession} />
        ))}
      </div>
      {sessions.length === 0 &&
        (slots && slots.length > 0 ? (
          <HURow className="justify-content-center w-full align-items-center mt-1" gap={5}>
            <CircleDashedIcon color={theme.mainWhite} />
            <HUText fontStyle="BS" className="w-6 text-center">
              {word("box.programmation.calendar.emptySlots")}
            </HUText>
          </HURow>
        ) : (
          <HURow className="justify-content-center w-full align-items-center mt-1" gap={5}>
            <ForbiddenIcon color={theme.neutralColor} />
            <HUText fontStyle="BS" color={theme.neutralColor} className="w-6 text-center">
              {word("box.programmation.calendar.noSlot")}
            </HUText>
          </HURow>
        ))}
      {sessions.length === 0 && (typeof slots === "undefined" || slots.length > 0) && (
        <AddEventButton
          onClick={() => {
            if (date) onAddSessionClick(date)
          }}
          displayText
          addEventTitle={word("planning.addSession.button")}
        />
      )}
    </Container>
  )
}

const Container = styled.td`
  vertical-align: top;
  text-align: left;
  word-break: break-word;
  &.past {
    cursor: not-allowed;
  }
  &.past .add {
    display: none;
  }
  > .add {
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;
    margin-top: 20px;
    gap: 10px;
  }
  &:not(.past):not(.dragging):hover .add {
    opacity: 1;
  }
`
