import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const LogoutIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M16.5 8.25L19.25 11M19.25 11L16.5 13.75M19.25 11H8.25"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 6.875V6.41667C13.75 4.39162 12.1084 2.75 10.0833 2.75H6.41667C4.39162 2.75 2.75 4.39162 2.75 6.41667V15.5833C2.75 17.6084 4.39162 19.25 6.41667 19.25H10.0833C12.1084 19.25 13.75 17.6084 13.75 15.5833V15.125"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
