import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { LanguageDropdown } from "@modules/language/components/languageDropdown"
import { Language } from "@modules/language/utils/languageUtils"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import React, { useImperativeHandle, useState } from "react"
import { useTheme } from "styled-components"

export type LanguagePopupRef = {
  show: () => void
}

export const LanguagePopup = React.forwardRef<LanguagePopupRef>((_, ref) => {
  const [popup, showPopup] = useState(false)
  const [language, setLanguage] = useState<Language | undefined>()
  const theme = useTheme()
  const { languageService } = useServicesContext()
  useImperativeHandle(
    ref,
    () => {
      return {
        show: () => showPopup(true),
      }
    },
    [],
  )

  return (
    <>
      <HUDialog visible={popup} header={word("languages.title")} headerSeparator onHide={() => showPopup(false)}>
        <HUStack gap={20} style={{ paddingTop: 24, paddingBottom: 24 }}>
          <HUText fontStyle="BM" color={theme.neutralColor600}>
            {word("languages.subTitle")}
          </HUText>
          <LanguageDropdown onChange={setLanguage} selectedLanguage={language} longValues />
          <HURow style={{ alignSelf: "end" }} gap={7}>
            <HUButton
              type="Default"
              size="S"
              colorType="Quaternary"
              text={word("global.cancel")}
              onClick={() => {
                showPopup(false)
              }}
            />
            <HUButton
              type="Default"
              size="S"
              colorType="Primary"
              text={word("global.save")}
              onClick={() => {
                language && languageService.setUserLanguage(language)
                showPopup(false)
              }}
              className="p-button p-button-primary"
            />
          </HURow>
        </HUStack>
      </HUDialog>
    </>
  )
})
