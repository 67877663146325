import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const GroupIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="12" cy="16.5" rx="6" ry="2.5" stroke={props.color} strokeWidth="1.5" strokeLinejoin="round" />
    <circle cx="12" cy="8" r="3" stroke={props.color} strokeWidth="1.5" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.44547 13.2615C5.20689 13.313 4.06913 13.5361 3.18592 13.8894C2.68122 14.0913 2.22245 14.3505 1.87759 14.6766C1.53115 15.0042 1.25 15.4512 1.25 16C1.25 16.5488 1.53115 16.9958 1.87759 17.3234C2.22245 17.6495 2.68122 17.9087 3.18592 18.1106C3.68571 18.3105 4.26701 18.4687 4.90197 18.5778C4.40834 18.0453 4.09852 17.4503 4.01985 16.8195C3.92341 16.787 3.83104 16.7531 3.74301 16.7179C3.34289 16.5578 3.06943 16.386 2.90826 16.2336C2.7498 16.0837 2.74999 16.0046 2.75 16.0001L2.75 16L2.75 15.9999C2.74999 15.9954 2.7498 15.9163 2.90826 15.7664C3.06943 15.614 3.34289 15.4422 3.74301 15.2821C3.94597 15.201 4.17201 15.1266 4.41787 15.0608C4.83157 14.371 5.53447 13.756 6.44547 13.2615Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9803 16.8195C19.9016 17.4503 19.5918 18.0453 19.0982 18.5778C19.7331 18.4687 20.3144 18.3105 20.8142 18.1106C21.3189 17.9087 21.7777 17.6495 22.1226 17.3234C22.469 16.9958 22.7502 16.5488 22.7502 16C22.7502 15.4512 22.469 15.0042 22.1226 14.6766C21.7777 14.3505 21.3189 14.0913 20.8142 13.8894C19.931 13.5361 18.7933 13.313 17.5547 13.2615C18.4657 13.756 19.1686 14.371 19.5823 15.0608C19.8281 15.1266 20.0542 15.201 20.2571 15.2821C20.6573 15.4422 20.9307 15.614 21.0919 15.7664C21.2504 15.9163 21.2502 15.9954 21.2502 15.9999V16V16.0001C21.2502 16.0046 21.2504 16.0837 21.0919 16.2336C20.9307 16.386 20.6573 16.5578 20.2571 16.7179C20.1691 16.7531 20.0767 16.787 19.9803 16.8195Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5147 10.1522C16.2948 10.6126 16.0066 11.0341 15.6631 11.4036C16.0589 11.6243 16.5149 11.75 17.0003 11.75C18.5191 11.75 19.7503 10.5188 19.7503 9C19.7503 7.48122 18.5191 6.25 17.0003 6.25C16.896 6.25 16.7931 6.2558 16.6919 6.26711C16.8639 6.73272 16.9686 7.23096 16.9942 7.75001C16.9962 7.75 16.9983 7.75 17.0003 7.75C17.6907 7.75 18.2503 8.30964 18.2503 9C18.2503 9.69036 17.6907 10.25 17.0003 10.25C16.8281 10.25 16.664 10.2152 16.5147 10.1522Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30845 6.26711C7.20719 6.2558 7.10427 6.25 7 6.25C5.48122 6.25 4.25 7.48122 4.25 9C4.25 10.5188 5.48122 11.75 7 11.75C7.48537 11.75 7.94138 11.6243 8.33721 11.4036C7.99374 11.0341 7.70549 10.6126 7.4856 10.1522C7.33631 10.2152 7.17222 10.25 7 10.25C6.30964 10.25 5.75 9.69036 5.75 9C5.75 8.30964 6.30964 7.75 7 7.75C7.00205 7.75 7.00409 7.75 7.00614 7.75001C7.0317 7.23096 7.13641 6.73272 7.30845 6.26711Z"
      fill={props.color}
    />
  </svg>
)
