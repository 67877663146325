import { Skeleton } from "primereact/skeleton"
import styled from "styled-components"

export const LoadingPage: React.FC = () => {
  return (
    <Container className="flex justify-content-center">
      <div className="card p-fluid col-12 p-0">
        <Skeleton height="100vh" />
      </div>
    </Container>
  )
}

const Container = styled.div`
  .card {
    border: none;
  }
`
