import { CalendarProvider } from "@modules/calendar/context/calendarContext"
import { useServicesContext } from "@modules/core/services/services.context"
import { Path } from "@modules/navigation/routes"
import { ProgramDetailsContent } from "@modules/programs/components/programDetailsContent"
import { useProgram } from "@modules/programs/hooks/usePrograms"
import { useProgramOnOffProgression } from "@modules/programs/hooks/useProgramsOnOff"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf, ProgressionStatusProgramOnOff } from "@modules/programs/programOnOffTypes"
import { ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import dayjs from "dayjs"
import { useCallback, useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"

export const ProgramDetailsPage: React.FC = () => {
  const { planningService } = useServicesContext()
  const { programId } = useParams()
  const location = useLocation()
  const isProgramOnOffLocation = location.pathname.includes(Path.MySpace.ProgramOnOffDetails(programId ?? ""))

  const { result: program, loading } = useProgram(
    programId,
    isProgramOnOffLocation ? ProgramOnOffTypeOf : ProgramInfiniteTypeOf,
  )
  const isProgramOnOff = program?._programType === ProgramOnOffTypeOf
  const { isAuthor, isAcceptedSharedCoach } = useProgramRights(program ?? undefined)
  const { result: progression } = useProgramOnOffProgression(
    program?.id,
    !isProgramOnOff ||
      !(
        program.subscriptionStatus === ProgramSubscriptionStatus.ACTIVE ||
        program.subscriptionStatus === ProgramSubscriptionStatus.PENDING_CANCELLATION
      ),
  )

  const isUndatedProgram = useMemo(
    () =>
      isProgramOnOff &&
      (isAuthor ||
        isAcceptedSharedCoach ||
        !progression ||
        (progression.status !== ProgressionStatusProgramOnOff.STARTED &&
          progression.status !== ProgressionStatusProgramOnOff.COMPLETED)),
    [isAuthor, isProgramOnOff, isAcceptedSharedCoach, progression],
  )

  const canUserSeeSessions = useMemo(
    () =>
      isAuthor ||
      isAcceptedSharedCoach ||
      program?.subscriptionStatus === ProgramSubscriptionStatus.ACTIVE ||
      program?.subscriptionStatus === ProgramSubscriptionStatus.PENDING_CANCELLATION ||
      program?.subscriptionStatus === ProgramSubscriptionStatus.TRIALING,
    [isAuthor, isAcceptedSharedCoach, program?.subscriptionStatus],
  )

  const onDateSet = useCallback(
    (startDate: number | dayjs.Dayjs, endDate: number | dayjs.Dayjs) => {
      if (program && canUserSeeSessions && !loading) {
        if (isUndatedProgram) {
          if (typeof startDate === "number" && typeof endDate === "number")
            planningService.updateProgramUnDatedSessions(startDate, endDate, program.id)
        } else {
          planningService.updateProgramDatedSessions(
            startDate.toString(),
            endDate.toString(),
            program.id,
            program._programType,
          )
        }
      }
    },
    [program, canUserSeeSessions, loading, isUndatedProgram],
  )
  const refreshEvents = useCallback(
    async (startDate: number | dayjs.Dayjs, endDate: number | dayjs.Dayjs) => {
      if (program && canUserSeeSessions) {
        if (isUndatedProgram) {
          if (typeof startDate === "number" && typeof endDate === "number")
            planningService.updateProgramUnDatedSessions(startDate, endDate, program.id)
        } else {
          await planningService.updateProgramDatedSessions(
            startDate.toString() ?? "",
            endDate.toString() ?? "",
            program.id,
            program._programType,
          )
        }
      }
    },
    [program, canUserSeeSessions, isUndatedProgram],
  )

  const initialDate = useMemo(() => {
    return isUndatedProgram ? undefined : dayjs().startOf("day")
  }, [isUndatedProgram])

  const currentDayIndex = useMemo(() => {
    return isProgramOnOff && progression ? progression.currentIndex : undefined
  }, [isProgramOnOff, progression])

  return (
    <CalendarProvider
      isUndated={isUndatedProgram}
      initialDate={initialDate}
      maxDayIndex={(isProgramOnOff && program.duration) || undefined}
      currentDayIndex={currentDayIndex}
      onDateSet={onDateSet}
      refreshEvents={refreshEvents}
      defaultDisplayDetailedEvents={false}
      defaultView="dayGridWeek"
    >
      <ProgramDetailsContent program={program || undefined} loading={loading} />
    </CalendarProvider>
  )
}
