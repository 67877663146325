import { AmrapIcon } from "@images/svgIcons/amrapIcon"
import { EmomIcon } from "@images/svgIcons/emomIcon"
import { ForTimeIcon } from "@images/svgIcons/forTimeIcon"
import { TabataIcon } from "@images/svgIcons/tabataIcon"
import { ChronoType } from "@modules/chrono/chronoTypes"
import { ChronoSettingsAMRAPForm } from "@modules/chrono/components/chronoSettingsAMRAPForm"
import { ChronoSettingsEMOMForm } from "@modules/chrono/components/chronoSettingsEMOMForm"
import { ChronoSettingsForTimeForm } from "@modules/chrono/components/chronoSettingsForTimeForm"
import { ChronoSettingsTABATAForm } from "@modules/chrono/components/chronoSettingsTABATAForm"
import { word } from "@modules/core/utils/i18n"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { Accordion, AccordionTab } from "primereact/accordion"
import { useState } from "react"
import styled, { useTheme } from "styled-components"

export const ModelChrono: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>()
  const { sidebarNavigateBack } = useSessionSidebar()
  const { formik, updatedExerciseIndex } = useSessionFormContext()
  const theme = useTheme()

  if (updatedExerciseIndex === null) return <></>

  const formikNameBase = `exercises[${updatedExerciseIndex}]`

  const setChronoFormik = async (chronoType: ChronoType) => {
    await formik.setFieldValue(`${formikNameBase}.chronoType`, chronoType)

    sidebarNavigateBack()
  }

  const onChangeTab = (index: number | number[] | null) => {
    if (typeof index === "number" || index === null) setActiveIndex(index)
    if (typeof index === "number") {
      const tabs = document.getElementsByClassName("accordion-tab-" + index)
      if (tabs && tabs.length === 1) {
        setTimeout(() => {
          document
            .getElementById("sidebar-content")
            ?.scrollTo({ top: (tabs[0] as HTMLElement).offsetTop, behavior: "smooth" })
        }, 200)
      }
    }
  }

  return (
    <>
      {/* AMRAP */}
      <AccordionContainer onTabChange={(e) => onChangeTab(e.index)} activeIndex={activeIndex}>
        <AccordionTab
          header={
            <div className="flex">
              <AmrapIcon color={"white"} />
              <div className="flex flex-column justify-content-around ml-4">
                <HUText fontStyle="LL">{word("chrono.title.amrap")}</HUText>
                <HUText fontStyle="BS" color={theme.neutralColor600}>
                  {word("chrono.amrap.definition")}
                </HUText>
              </div>
            </div>
          }
          className="accordion-tab-0"
        >
          <ChronoSettingsAMRAPForm index={updatedExerciseIndex} formik={formik} />
          <HUButton
            type="Default"
            colorType="Primary"
            size="L"
            text={word("chrono.button.validate")}
            className="w-full mr-2"
            onClick={() => {
              setChronoFormik(ChronoType.AMRAP)
            }}
          />
        </AccordionTab>
        {/* EMOM */}
        <AccordionTab
          header={
            <div className="flex">
              <EmomIcon color={"white"} />
              <div className="flex flex-column justify-content-around	ml-4">
                <HUText fontStyle="LL">{word("chrono.title.emom")}</HUText>
                <HUText fontStyle="BS" color={theme.neutralColor600}>
                  {word("chrono.emom.definition")}
                </HUText>
              </div>
            </div>
          }
          className="accordion-tab-1"
        >
          <ChronoSettingsEMOMForm index={updatedExerciseIndex} formik={formik} />
          <HUButton
            type="Default"
            colorType="Primary"
            size="L"
            text={word("chrono.button.validate")}
            className="w-full mr-2"
            onClick={() => {
              setChronoFormik(ChronoType.EMOM)
            }}
          />
        </AccordionTab>
        {/* TABATA */}
        <AccordionTab
          header={
            <div className="flex">
              <TabataIcon color={"white"} />
              <div className="flex flex-column justify-content-around	ml-4">
                <HUText fontStyle="LL">{word("chrono.title.tabata")}</HUText>
                <HUText fontStyle="BS" color={theme.neutralColor600}>
                  {word("chrono.tabata.definition")}
                </HUText>
              </div>
            </div>
          }
          className="accordion-tab-2"
        >
          <ChronoSettingsTABATAForm index={updatedExerciseIndex} formik={formik} />
          <HUButton
            type="Default"
            colorType="Primary"
            size="L"
            text={word("chrono.button.validate")}
            className="w-full mr-2"
            onClick={() => {
              setChronoFormik(ChronoType.TABATA)
            }}
          />
        </AccordionTab>
        {/* FORTIME */}
        <AccordionTab
          header={
            <div className="flex">
              <ForTimeIcon color={"white"} />
              <div className="flex flex-column justify-content-around	ml-4">
                <HUText fontStyle="LL">{word("chrono.title.for_time")}</HUText>
                <HUText fontStyle="BS" color={theme.neutralColor600}>
                  {word("chrono.forTime.definition")}
                </HUText>
              </div>
            </div>
          }
          className="accordion-tab-3"
        >
          <ChronoSettingsForTimeForm index={updatedExerciseIndex} formik={formik} />
          <HUButton
            type="Default"
            colorType="Primary"
            size="L"
            text={word("chrono.button.validate")}
            className="w-full mr-2"
            onClick={() => {
              setChronoFormik(ChronoType.FOR_TIME)
            }}
          />
        </AccordionTab>
      </AccordionContainer>
    </>
  )
}

const AccordionContainer = styled(Accordion)`
  .p-accordion-tab {
    margin-bottom: 20px;
  }

  .p-toggleable-content-enter-active {
    transition: max-height 0.1s ease-in-out;
    transition-delay: 0.1s;
  }

  .p-toggleable-content-exit-active {
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  }
  .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
  }
  .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: ${({ theme }) => theme.neutralColor100};
  }

  .p-accordion-header a:first-child {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .p-accordion-content {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    transform: rotate(90);
  }
`
