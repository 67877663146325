import { BoxForm } from "@modules/box/components/boxForm"
import { useCurrentBox } from "@modules/box/useBox"
import { BoxStaff } from "@modules/boxStaff/components/boxStaff"
import { word } from "@modules/core/utils/i18n"
import { BoxBanking } from "@modules/owner/boxSettings/components/boxBanking"
import { ErrorCard } from "@modules/ui/components/error/errorCard"
import { ScreenHeader } from "@modules/ui/components/header"
import { LoadingPage } from "@modules/ui/components/loadingPage"
import { isInArray } from "@modules/utils/isInArray"
import { useQueryParam } from "@modules/utils/navigation/useQueryParam"
import { TabMenu } from "primereact/tabmenu"
import styled from "styled-components"

const allTabs = ["box", "banking", "role"] as const
type Tab = (typeof allTabs)[number]

export const BoxSettingsPage: React.FC = () => {
  const [tab, setTab] = useQueryParam("tab")
  const selectedTab: Tab = isInArray(allTabs, tab) ? tab : "box"

  const { result: box, loading, error } = useCurrentBox()

  const tabModel: { label: string; tab: Tab; command: () => void }[] = [
    { label: word("box.settings.page.tabs.box"), command: () => setTab("box"), tab: "box" },
    {
      label: word("box.settings.page.tabs.banking"),
      command: () => setTab("banking"),
      tab: "banking",
    },
    {
      label: word("box.settings.page.tabs.role"),
      command: () => setTab("role"),
      tab: "role",
    },
  ]

  const activeIndex = tabModel.findIndex((m) => m.tab === selectedTab)

  return (
    <div className="w-full">
      <ScreenHeader title={word("box.settings.page.title")} subTitle={word("box.settings.page.subtitle")} />
      <Container>
        {box ? (
          <>
            <TabMenuStyled model={tabModel} activeIndex={activeIndex} />
            {selectedTab === "box" ? (
              <BoxForm box={box} />
            ) : selectedTab === "banking" ? (
              <BoxBanking box={box} />
            ) : selectedTab === "role" ? (
              <BoxStaff />
            ) : null}
          </>
        ) : loading ? (
          <LoadingPage />
        ) : error ? (
          <ErrorCard />
        ) : null}
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`

const TabMenuStyled = styled(TabMenu)`
  .p-tabmenu-nav {
    background-color: transparent;
  }
  .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    border-color: ${({ theme }) => theme.textPrimary};
    color: ${({ theme }) => theme.textPrimary};
    background-color: transparent;
  }

  .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background-color: transparent;
  }

  .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
`
