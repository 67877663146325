import { User, UserDraft } from "@modules/auth/user"
import { HUImageDraftDto, HUImageDto, convertImageFromDto } from "@modules/dtos/imageDto"
import { StripeAccountStatus } from "@modules/payment/stripeTypes"
import dayjs from "dayjs"

export interface UserDto {
  id: string
  avatar?: HUImageDto
  email: string
  firstname: string
  lastname: string
  username: string
  locale: string // todo
  inscriptionDate: string
  notificationsEnabled: boolean
  stripeAccountStatus?: StripeAccountStatus
}

export interface UserDraftDto {
  firstname: string
  lastname: string
  username: string
  avatar?: HUImageDraftDto
}

export function convertUserFromDto(dto: UserDto): User {
  return {
    ...dto,
    inscriptionDate: dayjs(dto.inscriptionDate).toDate(),
    avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
  }
}

export function convertUserToDto(draft: Partial<UserDraft>): Partial<UserDraftDto> {
  return {
    ...draft,
    avatar: draft.avatar?.localBase64
      ? {
          content: draft.avatar.localBase64,
          contentType: "base64",
        }
      : undefined,
  }
}
