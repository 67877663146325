import { Path } from "@modules/navigation/routes"
import { RouteNavigate, RoutePath, useBaseNavigate } from "@modules/navigation/useBaseNavigate"
import { NavigateOptions } from "react-router-dom"

export const useMySpaceNavigate = () => {
  const navigate = useBaseNavigate()
  return (path: RoutePath, options?: NavigateOptions) => navigate(path, getMySpacePath, options)
}

export const getMySpacePath = (path: Omit<RoutePath, RouteNavigate.Back>) => {
  if (path === RouteNavigate.Root) {
    return Path.MySpace.Root
  } else {
    return Path.MySpace.Root + "/" + path
  }
}
