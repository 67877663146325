import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ResumeIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.58026 1.51099L1.66235 4.4289M1.66235 4.4289L4.58026 7.3468M1.66235 4.4289C4.94019 4.4289 7.18243 4.4289 9.87853 4.4289C11.7869 4.4289 13.334 5.97594 13.334 7.88431V7.88431C13.334 9.79269 11.7869 11.3397 9.87856 11.3397H7.67368"
      stroke={props.color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
