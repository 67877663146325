import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BOX_SLOT_PARTICIPANTS_LIMIT_MAX, BOX_SLOT_PARTICIPANTS_LIMIT_MIN } from "@modules/boxSlot/boxSlotTypes"
import { BoxSlotTemplate, BoxSlotTemplateDraft } from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import { word } from "@modules/core/utils/i18n"
import { requiredInput } from "@modules/form/formUtils"
import { defaultDateFormat, initialEndTime, initialStartTime } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import * as Yup from "yup"

export type BoxSlotTemplateInputKey =
  | "day"
  | "startDateTime"
  | "endDateTime"
  | "sessionId"
  | "participantLimit"
  | "limitStart"
  | "limitEnd"
  | "limitCancel"
  | "location"

export type BoxSlotTemplateFormikType = {
  day: string
  startDateTime: Date
  endDateTime: Date
  boxProgrammationId: string
  sessionId?: string
  participantLimit: number
  limitStart?: number
  limitEnd?: number
  limitCancel?: number
  location?: string
}

export function getValidationSchema() {
  return Yup.object({
    day: requiredInput(Yup.string().trim()),
    startDateTime: requiredInput(Yup.string().trim()),
    endDateTime: requiredInput(Yup.string().trim()),
    participantLimit: Yup.number()
      .required(word("global.form.required"))
      .min(
        BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
        word("global.form.minimumValue", { minValue: BOX_SLOT_PARTICIPANTS_LIMIT_MIN }),
      )
      .max(
        BOX_SLOT_PARTICIPANTS_LIMIT_MAX,
        word("global.form.maximumValue", { maxValue: BOX_SLOT_PARTICIPANTS_LIMIT_MAX }),
      ),
    location: Yup.string()
      .trim()
      .nullable()
      .max(40, word("form.maximum.characters", { count: 40 })),
    limitStart: Yup.number()
      .nullable()
      .min(1, word("global.form.minimumValue", { minValue: 1 })),
    limitEnd: Yup.number()
      .nullable()
      .min(1, word("global.form.minimumValue", { minValue: 1 })),
    limitCancel: Yup.number()
      .nullable()
      .min(1, word("global.form.minimumValue", { minValue: 1 }))
      .required(word("global.form.required")),
  })
}

export const convertBoxSlotTemplateDraftToFormik = (
  boxSlotTemplate: BoxSlotTemplate | BoxSlotTemplateDraft | null,
  boxProgrammation: BoxProgrammation,
): BoxSlotTemplateFormikType => {
  const formattedDay = dayjs().startOf("day").format(defaultDateFormat)
  const startTime = boxSlotTemplate?.startTime ?? initialStartTime

  const endTime = boxSlotTemplate?.endTime ?? initialEndTime
  const startDateTime = dayjs(`${formattedDay}T${startTime}`)
  const endDateTime = dayjs(`${formattedDay}T${endTime}`)

  return {
    day: boxSlotTemplate?.weekDay ?? "",
    startDateTime: startDateTime.toDate(),
    endDateTime: endDateTime.toDate(),
    boxProgrammationId: boxProgrammation.id,
    participantLimit: boxSlotTemplate?.participantLimit ?? BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
    location: boxSlotTemplate?.location ?? "",
    limitStart: boxSlotTemplate?.participation?.limitStart,
    limitEnd: boxSlotTemplate?.participation?.limitEnd,
    limitCancel: boxSlotTemplate?.participation?.limitCancel,
  }
}

export const convertBoxSlotTemplateFormFormik = (formik: BoxSlotTemplateFormikType): BoxSlotTemplateDraft => {
  return {
    weekDay: formik.day.toUpperCase(),
    startTime: dayjs(formik.startDateTime).format("HH:mm:ss"),
    endTime: dayjs(formik.endDateTime).format("HH:mm:ss"),
    participantLimit: formik.participantLimit ?? BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
    location: formik.location,
    participation: {
      limitStart: formik.limitStart,
      limitEnd: formik.limitEnd,
      limitCancel: formik.limitCancel,
    },
  }
}
