import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import styled, { css } from "styled-components"

export type HUInputProps = {
  className?: string
  hasError?: boolean
  password?: boolean
  value: string
  inputRegex?: RegExp
  isValueHidden?: boolean
  flex?: boolean
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
} & React.InputHTMLAttributes<HTMLInputElement>

export const HUInput: React.FC<HUInputProps> = ({
  hasError,
  className,
  password,
  onChange,
  isValueHidden,
  inputRegex,
  value,
  flex,
  onFocus,
  onBlur,
  ...rest
}) => {
  const inputClassName = "w-full" + (hasError ? " p-invalid" : "")
  const textValue = isValueHidden ? "" : value

  return (
    <Container $flex={flex} className={className}>
      {password ? (
        <Password
          id="password"
          type="password"
          value={textValue}
          className={inputClassName}
          inputClassName={inputClassName}
          placeholder="Password"
          toggleMask
          feedback={false}
          onInput={onChange}
          {...rest}
        />
      ) : (
        <InputText
          type="text"
          value={textValue}
          className={inputClassName}
          onChange={(v) => {
            if (inputRegex) {
              if (inputRegex.test(v.target.value)) {
                return onChange?.(v)
              }
              return
            } else {
              return onChange?.(v)
            }
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          {...rest}
        />
      )}
    </Container>
  )
}

const Container = styled.div<{ $flex?: boolean }>`
  ${({ $flex }) =>
    $flex &&
    css`
      flex: 1;
    `}
`
