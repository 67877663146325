import { FetchStrategy, usePaginatedStore, useStore } from "@betomorrow/micro-stores"
import { useServicesContext } from "@modules/core/services/services.context"
import { ProgramFilter } from "@modules/programs/programTypes"
import { useRefreshPaginatedStore } from "@modules/store"
import { useMemo } from "react"

export const useProgramInfinite = (id?: string, fetchStrategy?: FetchStrategy) => {
  const { programInfiniteService } = useServicesContext()
  return useStore(id, programInfiniteService.programInfiniteStore, fetchStrategy)
}

export const useProgramsInfinite = (options: {
  programFilter?: ProgramFilter
  page?: number
  size?: number
  sort?: "SUBSCRIPTION_DATE" | "CREATION_DATE"
  fetchStrategy?: FetchStrategy
}) => {
  const { programInfiniteService } = useServicesContext()

  const paginatedProgramsStore = useMemo(
    () =>
      options.programFilter === "OWNED"
        ? programInfiniteService.paginatedOwnedprogramInfiniteStore
        : options.programFilter === "SUBSCRIBED"
          ? programInfiniteService.paginatedSubscribedprogramInfiniteStore
          : programInfiniteService.paginatedAllprogramInfiniteStore,
    [options.programFilter, options.page, options.size],
  )

  return usePaginatedStore(paginatedProgramsStore, options.fetchStrategy)
}

export const useProgramInfiniteSubscribers = (id: string, fetchStrategy?: FetchStrategy) => {
  const { programInfiniteService } = useServicesContext()
  const programInfiniteSubscribersStore = useMemo(() => {
    return programInfiniteService.getPaginatedProgramInfiniteSubscribersStore(id)
  }, [id])

  return useRefreshPaginatedStore(usePaginatedStore(programInfiniteSubscribersStore, fetchStrategy))
}
