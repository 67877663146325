import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BoxProgrammationVisibilityPublicIcon: React.FC<IconSvgProps> = ({ color = "#D1D1D1", ...props }) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.483 4.05456C16.9366 4.45942 16.5825 5.10893 16.5825 5.84115C16.5825 7.06845 15.5876 8.06337 14.3603 8.06337C13.133 8.06337 12.1381 9.05829 12.1381 10.2856C12.1381 11.5129 13.133 12.5078 14.3603 12.5078C15.5876 12.5078 16.5825 13.5027 16.5825 14.73C16.5825 15.9573 17.5774 16.9523 18.8047 16.9523H21.0986M17.483 4.05456C15.937 3.07497 14.1037 2.50781 12.1381 2.50781C6.99078 2.50781 2.75181 6.39676 2.19909 11.3967M17.483 4.05456C20.2808 5.82727 22.1381 8.95064 22.1381 12.5078C22.1381 18.0307 17.6609 22.5078 12.1381 22.5078C9.75662 22.5078 7.56961 21.6754 5.85213 20.2856M5.85213 20.2856L8.80473 20.2856C10.032 20.2856 11.027 19.2907 11.027 18.0634C11.027 16.8361 10.032 15.8411 8.80473 15.8411C7.57743 15.8411 6.58251 14.8462 6.58251 13.6189C6.58251 12.3916 5.58758 11.3967 4.36028 11.3967H2.19909M5.85213 20.2856C3.58658 18.4523 2.13806 15.6492 2.13806 12.5078C2.13806 12.1322 2.15877 11.7615 2.19909 11.3967"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
