import {
  BoxProgrammationFormikType,
  convertBoxProgrammationDraftToFormik,
  getValidationSchema as getBoxProgrammationValidationSchema,
} from "@modules/boxProgrammation/boxProgrammationFormikTypes"
import { BoxProgrammation, convertBoxProgrammationToDraft } from "@modules/boxProgrammation/boxProgrammationTypes"
import { useFormik } from "formik"

export const useBoxProgrammationFormik = (programmation?: BoxProgrammation) => {
  const formik = useFormik<BoxProgrammationFormikType>({
    initialValues: convertBoxProgrammationDraftToFormik(
      convertBoxProgrammationToDraft(programmation as BoxProgrammation),
    ),
    validationSchema: getBoxProgrammationValidationSchema({ imageRequired: !!programmation }),
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  return {
    formik,
  }
}
