import { HUInput } from "@modules/ui/components/huInput"
import { HURow } from "@modules/ui/components/layout"
import { countries, getEmojiFlag, TCountryCode } from "countries-list"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import { ChangeEvent, useEffect, useState } from "react"

export type HUPhoneInputProps = {
  id?: string
  phoneNumber?: string
  countryCode?: TCountryCode | undefined
  onPhoneNumberChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onPhoneNumberBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onPhoneCodeChange?: (event: DropdownChangeEvent) => void
  required?: boolean
  disabled?: boolean
  hasError?: boolean
  placeholder?: string
}

export const HUPhoneInput: React.FC<HUPhoneInputProps> = ({
  id,
  phoneNumber,
  countryCode,
  onPhoneNumberChange,
  onPhoneNumberBlur,
  onPhoneCodeChange,
  required,
  disabled,
  hasError,
  placeholder,
}) => {
  const [phoneCountry, setPhoneCountry] = useState<TCountryCode>(countryCode || "FR")

  useEffect(() => {
    setPhoneCountry(countryCode || "FR")
  }, [countryCode])

  const countriesOptions = Object.entries(countries).map(([key, value]) => {
    return { label: value.native, code: key, ...value }
  })

  const items = countriesOptions.map((countryOptions) => {
    if (countryOptions.name === "Kosovo") {
      return {
        label:
          getEmojiFlag(countryOptions.code.toUpperCase() as TCountryCode) + " " + countryOptions.native + " (+383)",
        shortLabel: getEmojiFlag(countryOptions.code.toUpperCase() as TCountryCode) + " (+383)",
        value: countryOptions.code,
      }
    }
    return {
      label:
        getEmojiFlag(countryOptions.code.toUpperCase() as TCountryCode) +
        " " +
        countryOptions.native +
        " (+" +
        countryOptions.phone +
        ")",
      shortLabel: getEmojiFlag(countryOptions.code.toUpperCase() as TCountryCode) + " (+" + countryOptions.phone + ")",
      value: countryOptions.code,
    }
  })

  return (
    <HURow className="flex-grow-1" gap={10}>
      <Dropdown
        value={phoneCountry}
        onChange={(e) => {
          setPhoneCountry(e.target.value)
          if (onPhoneCodeChange) onPhoneCodeChange(e)
        }}
        options={items}
        optionValue="value"
        optionLabel="label"
        valueTemplate={(option) => <div>{option?.shortLabel}</div>}
        disabled={disabled}
        required={required}
        className={hasError ? "p-invalid" : ""}
      />

      <HUInput
        className="w-full"
        onChange={(e) => {
          if (onPhoneNumberChange) onPhoneNumberChange(e)
        }}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        value={phoneNumber ?? ""}
        onBlur={onPhoneNumberBlur}
        id={id}
        hasError={hasError}
      />
    </HURow>
  )
}
