import { BoxFormikType, BoxInputKey } from "@modules/box/boxFormikType"
import { BoxPhotosForm } from "@modules/box/components/boxPhotosForm"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { FormikInput } from "@modules/ui/components/formikInput"
import { SimpleFormik } from "@src/typings/formik"
import { Divider } from "primereact/divider"

type Props = {
  formik: SimpleFormik<BoxFormikType, BoxInputKey> & {
    setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean) => void
  }
  setIsImageUploading: (isImageUploading: boolean) => void
}

export const BoxSecondaryInfoForm: React.FC<Props> = ({ formik, setIsImageUploading }) => {
  return (
    <div className="card p-fluid col-12 md:col-5 mb-0 p-5">
      <FormikInput
        isRequiredInput
        type="image"
        imageProps={{
          type: "logo",
          setIsImageUploading,
          onTouch: () => formik.setFieldTouched("logo", true, false),
        }}
        name="logo"
        label={word("box.form.logo.label")}
        setFieldValue={formik.setFieldValue}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("logo", formik)}
        error={getFormErrorMessage("logo", formik)}
      />
      <Divider />
      <FormikInput
        isRequiredInput
        type="image"
        imageProps={{
          type: "cover",
          placeHolder: {
            message: word("program.form.uploadImage.text1"),
          },
          setIsImageUploading,
          onTouch: () => formik.setFieldTouched("logo", true, false),
        }}
        name="cover"
        label={word("box.form.cover.label")}
        setFieldValue={formik.setFieldValue}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("cover", formik)}
        error={getFormErrorMessage("cover", formik)}
      />
      <Divider />
      <BoxPhotosForm
        boxPhotos={formik.getFieldProps("boxPhotos").value}
        setBoxPhotos={(photos) => formik.setFieldValue("boxPhotos", photos)}
        setIsImageUploading={setIsImageUploading}
      />
    </div>
  )
}
