import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PauseIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      width="4.17104"
      height="12.9766"
      rx="2.08552"
      transform="matrix(1 0 -0.148327 0.988938 2.625 0.645264)"
      fill={props.color}
    />
    <rect
      width="4.17104"
      height="12.9766"
      rx="2.08552"
      transform="matrix(1 0 -0.148327 0.988938 9.1582 0.645752)"
      fill={props.color}
    />
  </svg>
)
