import { TrashIcon } from "@images/svgIcons/trashIcon"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { ProgramReductionItem } from "@modules/promoCode/components/programReductionItem"
import { usePromoCodeSidebar } from "@modules/promoCode/hooks/usePromoCodeSidebar"
import { PromoCode } from "@modules/promoCode/types/promoCodeTypes"
import {
  getFormattedPromoCodeExpiration,
  getFormattedPromoCodeReduction,
} from "@modules/promoCode/utils/promoCodeUtils"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { EllipsisMenu } from "@modules/ui/components/ellipsisMenu"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { EditIcon } from "@modules/ui/icons/editIcon"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import { shortenText } from "@modules/utils/stringUtils"
import dayjs from "dayjs"
import { Tag } from "primereact/tag"
import React, { useEffect } from "react"
import styled, { useTheme } from "styled-components"

export const PromoCodeInfoResume: React.FC<{ promoCode: PromoCode }> = ({ promoCode }) => {
  const theme = useTheme()
  const language = useLanguage()
  const confirmPopup = useConfirmPopup()
  const toast = useToast()
  const { promoCodeService } = useServicesContext()
  const { setCustomHeaderIcons } = useSidebar()
  const { navigateToPromoCodeFormView, navigateToPromoCodeListView } = usePromoCodeSidebar()

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(promoCode.code)
    toast.show({ severity: "info", detail: word("promoCode.view.copyCode.success") })
  }

  const deletePromoCode = async () => {
    confirmPopup.show({
      title: word("promoCode.view.deleteConfirm.title"),
      message: word("promoCode.view.deleteConfirm.message"),
      accept: async () => {
        try {
          await promoCodeService.deletePromoCode(promoCode.id)

          navigateToPromoCodeListView()
          toast.show({ detail: word("promoCode.view.deleteConfirm.success"), severity: "success" })
        } catch (e: any) {
          toast.show({ severity: "error", summary: word("global.error.label"), detail: e.message })
        }
      },
      footerProps: {
        align: true,
      },
    })
  }

  useEffect(() => {
    const canBeEdited =
      !promoCode.archived &&
      (!promoCode.expirationDate || !dayjs(promoCode.expirationDate).isBefore(dayjs().format(defaultDateFormat)))
    const canBeDeleted = !promoCode.archived

    const editionSubMenu = {
      label: word("promoCode.view.edit"),
      icon: <EditIcon color={theme.neutralColor900} />,
      command: () => navigateToPromoCodeFormView(promoCode),
    }
    const deletionSubMenu = {
      label: word("promoCode.view.delete"),
      icon: <TrashIcon color={theme.neutralColor900} width={20} height={20} />,
      command: () => deletePromoCode(),
    }

    const menuItems = []
    if (canBeEdited) menuItems.push(editionSubMenu)
    if (canBeDeleted) menuItems.push(deletionSubMenu)

    if (menuItems.length > 0) {
      setCustomHeaderIcons([
        {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick: () => {},
          button: (
            <EllipsisMenu
              disabled={false}
              key={"ellipsis-menu"}
              items={[
                {
                  items: menuItems,
                },
              ]}
              buttonClassName="border-none-hover h-2rem w-2rem mr-2"
            />
          ),
        },
      ])
    }
  }, [])

  return (
    <Container>
      <TopContainer className="p-5 mb-5 flex-column justify-content-between align-items-start overflow-hidden gap-2 border-noround-top">
        <TypeContainer
          className="mt-2"
          rounded
          icon={<span style={{ color: theme.primaryColor }} className="pi pi-bolt mr-2" />}
        >
          <HUText fontStyle="LM" color={theme.mainWhite}>
            {word("promoCode.view.tagLabel")}
          </HUText>
        </TypeContainer>
        <HUStack gap={5}>
          <HUText color="white" fontStyle="HS" className="mb-2">
            {shortenText(promoCode.title, 30)}
          </HUText>
          <HUText color="white" fontStyle="BM">
            {word("promoCode.view.codeToShare")}
          </HUText>
          <HURow>
            <HUText color="white" fontStyle="HL" style={{ wordBreak: "break-all" }}>
              {promoCode.code}
            </HUText>
            <span
              className="copy-button ml-2 p-2 pi pi-copy"
              style={{ color: theme.mainWhite, fontSize: 20 }}
              onClick={copyCodeToClipboard}
            />
          </HURow>
        </HUStack>
      </TopContainer>

      <InformationContainer>
        <PromoCodeInformationRow
          label={word("promoCode.view.discount")}
          value={getFormattedPromoCodeReduction(promoCode, language)}
          iconClassName="pi pi-percentage"
        />
        <PromoCodeInformationRow
          label={word("promoCode.view.expirationDate")}
          value={getFormattedPromoCodeExpiration(promoCode)}
          iconClassName="pi pi-clock"
        />
        <PromoCodeInformationRow
          label={word("promoCode.view.durationInMonths")}
          value={
            promoCode.durationInMonths
              ? `${promoCode.durationInMonths} ${word("global.month.label", { count: promoCode.durationInMonths })}`
              : word("promoCode.form.input.applicationDuration.value.unlimited")
          }
          iconClassName="pi pi-history"
        />
        <PromoCodeInformationRow
          label={word("promoCode.view.maxAttendees")}
          value={
            promoCode.maxAttendees
              ? `${promoCode.maxAttendees} ${word("global.customer.label", { count: promoCode.maxAttendees })}`
              : word("promoCode.form.input.maxAttendees.value.unlimited")
          }
          iconClassName="pi pi-users"
        />
        <PromoCodeInformationRow
          label={
            promoCode.onlyNewCustomer ? word("promoCode.view.newCustomersOnly") : word("promoCode.view.everyCustomers")
          }
          iconClassName="pi pi-filter"
        />
      </InformationContainer>

      <ProgramContainer gap={10}>
        <HUText type="label" color={theme.neutralColor700} fontStyle="LS" style={{ lineHeight: "21px" }}>
          {word("promoCode.form.input.relatedPrograms").toUpperCase()}
        </HUText>
        {promoCode.programs.map((program) => (
          <ProgramReductionItem
            key={program.id}
            program={program}
            promoCodeType={promoCode.type}
            promoCodeValue={promoCode.value}
            promoCodeDuration={promoCode.durationInMonths}
          />
        ))}
      </ProgramContainer>
    </Container>
  )
}

const PromoCodeInformationRow: React.FC<{ label: string; value?: string; iconClassName?: string }> = ({
  label,
  value,
  iconClassName,
}) => {
  const theme = useTheme()
  return (
    <HURow gap={10} className="align-items-center">
      {iconClassName && <span className={iconClassName} style={{ color: theme.neutralColor700 }} />}
      <HUText fontStyle="BM" color={theme.neutralColor700}>
        {label}
      </HUText>
      <HUText fontStyle="TM">{value}</HUText>
    </HURow>
  )
}

const Container = styled.div`
  height: 100%;
`

const TopContainer = styled(HUStack)`
  border-radius: 0px 0px 33px 33px;
  border-width: 0px;
  height: 300px;
  margin: -52px -20px;
  background: ${({ theme }) =>
    `linear-gradient(270deg, ${theme.secondaryColor} 0.32%, ${theme.secondaryColor800} 99.49%)`};
  .copy-button {
    transition: color 0.2s ease;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.neutralColor300} !important;
    }
  }
`

const InformationContainer = styled(HUStack)``
const ProgramContainer = styled(HUStack)`
  margin-top: 20px;
  padding-bottom: 20px;
`

const TypeContainer = styled(Tag)`
  align-items: center !important;
  align-self: flex-start !important;
  background-color: rgba(28, 28, 28, 0.8);

  &.p-tag {
    padding: 9px 14px 9px 10px;
    height: 34px;
    display: flex;
  }
`
