import { HustleupSmallIcon } from "@images/svgIcons/hustleUpSmallIcon"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { animatedSkeletonStyle } from "@modules/ui/styles/animation"
import styled, { DefaultTheme, useTheme } from "styled-components"

type CardType = "Primary" | "Secondary"
type Props = {
  cardType: CardType
  cardTitle: string
  cardSubtitle?: string
  value?: string | boolean
  icon?: string
  loading: boolean
  buttonLabel?: string
  buttonIcon?: string
  isButtonLoading?: boolean
  onButtonClick?: () => void
} & React.HTMLAttributes<HTMLDivElement>

export const FinanceCard: React.FC<Props> = ({
  cardType,
  cardTitle,
  cardSubtitle,
  value = "--",
  icon,
  loading,
  buttonLabel,
  buttonIcon,
  isButtonLoading = false,
  onButtonClick,
  ...props
}) => {
  const theme = useTheme()
  const color = cardType === "Primary" ? theme.buttonPrimaryTextColor : theme.textPrimary
  return (
    <Container $cardType={cardType} $loading={loading} {...props}>
      <HURow align gap={10}>
        {icon && <span className={`p-button-icon p-c ${icon}`} />}
        <HUText fontStyle="BL" color={color}>
          {cardTitle}
        </HUText>
      </HURow>
      {cardSubtitle && (
        <HUText fontStyle="BS" color={color}>
          {cardSubtitle}
        </HUText>
      )}
      {buttonLabel && !!onButtonClick && (
        <HURow>
          <HUButton
            text={buttonLabel}
            onClick={onButtonClick}
            icon={buttonIcon ? { iconView: <span className={`p-button-icon p-c ${buttonIcon}`} /> } : undefined}
            type="Right_Icon"
            size="M"
            colorType="Primary"
            loading={isButtonLoading}
            style={{ zIndex: 2 }}
          />
        </HURow>
      )}
      {value && (
        <HUText fontStyle="DS" color={color}>
          {value}
        </HUText>
      )}
      <HustleupSmallIconStyled />
    </Container>
  )
}

const Container = styled.div<{ $cardType: CardType; $loading: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ $cardType, theme }) =>
    $cardType === "Primary"
      ? `linear-gradient(90deg, ${theme.primaryColor} 0%, ${theme.primaryColor600} 100%)`
      : theme.surfaceCard};
  padding: 25px 24px 20px;
  border-radius: 21px;
  gap: 38px;

  ${({ $cardType, $loading, theme }) =>
    $loading &&
    ($cardType === "Primary"
      ? animatedSkeletonStyle(theme as DefaultTheme, theme.primaryColor, theme.primaryColor600)
      : animatedSkeletonStyle(theme as DefaultTheme, theme.surfaceCard))}
`

const HustleupSmallIconStyled = styled(HustleupSmallIcon)`
  position: absolute;
  top: 0;
  right: 0;
  opacity: ${({ theme }) => theme.financeCardBackgroundIconOpacity};
`
