import { BoxSlotSessionFormikType } from "@modules/boxSlot/boxSessionFormikTypes"
import { ChronoType } from "@modules/chrono/chronoTypes"
import { GetChronoDefaults, MAX_NUMBER_INPUT, MIN_NUMBER_INPUT } from "@modules/chrono/chronoUtils"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import {
  ProgramInfiniteSessionFormikType,
  ProgramOnOffSessionFormikType,
} from "@modules/programSession/programSessionFormikType"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import { DropdownChangeEvent } from "primereact/dropdown"
import { useEffect } from "react"

type ChronoForTimeProps = {
  index: number
  formik: SimpleFormik<ProgramInfiniteSessionFormikType | ProgramOnOffSessionFormikType | BoxSlotSessionFormikType, any>
}

export const ChronoSettingsForTimeForm: React.FC<ChronoForTimeProps> = ({ formik, index }) => {
  const formikNameBase = `exercises[${index}].chronoForTime`
  const formikValue = formik.values.exercises[index].chronoForTime

  useEffect(() => {
    if (!formikValue) {
      formik.setFieldValue(formikNameBase, GetChronoDefaults(ChronoType.FOR_TIME))
    }
  }, [])

  if (index === null) return <></>

  const timeOptions = [
    { value: true, label: word("chrono.settings.for_time.options.no_limit") },
    { value: false, label: word("chrono.settings.for_time.options.with_limit") },
  ]

  return (
    <HUStack gap={5}>
      <FormikInput
        formikStyle={{ flexGrow: 1 }}
        textLabel={word("chrono.settings.for_time.label.no_limit")}
        getFieldProps={formik.getFieldProps}
        name={`${formikNameBase}.noLimit`}
        setFieldValue={formik.setFieldValue}
        isInvalid={isFormFieldInValid(`${formikNameBase}.noLimit`, formik)}
        error={getFormErrorMessage(`${formikNameBase}.noLimit`, formik)}
        type="dropdown"
        dropdownProps={{
          value: formikValue?.noLimit,
          options: timeOptions,
          onChange: (e: DropdownChangeEvent) => {
            if (e.target.value) {
              formik.setFieldValue(`${formikNameBase}.trainingTime`, 0)
              formik.setFieldValue(`${formikNameBase}.numberOfSets`, 1)
              formik.setFieldValue(`${formikNameBase}.restTime`, 0)
            }
          },
        }}
      />
      {!formikValue?.noLimit && (
        <>
          <FormikInput
            formikStyle={{ display: "flex", flexGrow: 1 }}
            textLabel={word("chrono.settings.label.training_time")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid(`${formikNameBase}.trainingTime`, formik)}
            error={getFormErrorMessage(`${formikNameBase}.trainingTime`, formik)}
            name={`${formikNameBase}.trainingTime`}
            type="maskedInput"
            maskedInputProps={{
              placeholder: "00min 00sec",
              slotChar: "00min 00sec",
              mask: "99min 99sec",
              onChange: (e) => {
                formik.setFieldValue(`${formikNameBase}.trainingTime`, e.target.value)
              },
            }}
          />
          <FormikInput
            formikStyle={{ display: "flex", flexGrow: 1 }}
            textLabel={word("chrono.settings.label.number_sets")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid(`${formikNameBase}.numberOfSets`, formik)}
            error={getFormErrorMessage(`${formikNameBase}.numberOfSets`, formik)}
            setFieldValue={formik.setFieldValue}
            name={`${formikNameBase}.numberOfSets`}
            type="numberChrono"
            numberProps={{
              max: MAX_NUMBER_INPUT,
              min: MIN_NUMBER_INPUT,
              showButtons: false,
            }}
          />
          <FormikInput
            flex
            formikStyle={{ display: "flex", flexGrow: 1 }}
            textLabel={word("chrono.settings.label.rest_between_sets")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid(`${formikNameBase}.restTime`, formik)}
            error={getFormErrorMessage(`${formikNameBase}.restTime`, formik)}
            name={`${formikNameBase}.restTime`}
            type="maskedInput"
            maskedInputProps={{
              placeholder: "00min 00sec",
              slotChar: "00min 00sec",
              mask: "99min 99sec",
              onChange: (e) => {
                formik.setFieldValue(`${formikNameBase}.restTime`, e.target.value)
              },
            }}
          />
        </>
      )}
    </HUStack>
  )
}
