import { Store } from "@betomorrow/micro-stores"
import { AsyncObservable } from "@modules/asyncObservable/asyncObservable"
import { Box, BoxDraft, ItemBox } from "@modules/box/boxTypes"
import { BoxApi } from "@modules/box/services/boxApi"
import { CurrentSpaceManager } from "@modules/core/services/currentSpaceManager"
import { Service } from "@modules/core/services/serviceType"

export class BoxService implements Service {
  boxs = new AsyncObservable<ItemBox[]>(() => this.api.getBoxs())
  boxStore = new Store<Box>((id) => this.api.getBox(id))

  constructor(
    private api: BoxApi,
    private currentSpaceManager: CurrentSpaceManager,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init() {}

  async onLoggedOut(): Promise<void> {
    this.boxs.set(null)
    this.boxStore.clear()
  }

  async createBox(box: BoxDraft) {
    const result = await this.api.createBox(box)
    this.boxStore.save(result)
    this.boxs.fetch()
    return result
  }

  async updateBox(id: string, box: BoxDraft) {
    const result = await this.api.updateBox(id, box)
    this.boxs.fetch()
    this.boxStore.save(result)
  }

  async deleteBox(id: string) {
    await this.api.deleteBox(id)
    this.boxStore.remove(id)
    this.boxs.update((boxs) => boxs?.filter((it) => it.id !== id) ?? [])

    if (this.currentSpaceManager.boxId === id) {
      this.currentSpaceManager.set(undefined)
    }
  }
}
