import { word } from "@modules/core/utils/i18n"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { RequestsSASProfile } from "@modules/profile/profileTypes"
import { useProgramSasRequests } from "@modules/programs/hooks/useProgramSasRequests"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import styled, { useTheme } from "styled-components"

type SASRequestsToAcceptViewProps = {
  sasRequests: readonly RequestsSASProfile[]
  programId: string
  toggleIsSASRequestToAcceptView: () => void
  hideIsSASRequestToAcceptView: () => void
  list: () => void
  onEndRequestOperation: (arg: RequestsSASProfile) => void
}

export const SASRequestsToAcceptView: React.FC<SASRequestsToAcceptViewProps> = ({
  sasRequests,
  programId,
  toggleIsSASRequestToAcceptView,
  hideIsSASRequestToAcceptView,
  list,
  onEndRequestOperation,
}) => {
  const theme = useTheme()

  const { acceptRequest, rejectRequest, isRespondingRequestId } = useProgramSasRequests(
    programId,
    sasRequests,
    onEndRequestOperation,
    hideIsSASRequestToAcceptView,
    list,
  )

  return (
    <>
      <div className="flex align-items-center">
        <i
          className={"pi pi-arrow-left cursor-pointer"}
          style={{ fontSize: "1.2rem", marginRight: "33%" }}
          onClick={() => toggleIsSASRequestToAcceptView()}
        />
        <HUText fontStyle="TS">{word("program.sas.list.header.title")}</HUText>
      </div>
      <StyledProgramsSubscribersTable
        paginator={sasRequests.length > 10}
        rows={10}
        rowsPerPageOptions={[10, 25, 50]}
        value={sasRequests as RequestsSASProfile[]}
        dataKey="subscriptionId"
        emptyMessage={word("global.no_result")}
        header={null}
      >
        <Column
          field="user"
          body={(user) => (
            <div className="flex align-items-center h-2rem">
              <ProfileAvatar
                className="mr-2"
                profile={{ avatar: user?.profile.avatar, username: user?.profile.firstname || "" }}
                size={55}
              />
              <div className="flex flex-column">
                <HUText fontStyle="LL" className="mb-1">{`${user.profile.firstname} ${user.profile.lastname}`}</HUText>
                <HUText fontStyle="BS" color={theme.neutralColor600}>
                  {`@${user.profile.username}`}
                </HUText>
              </div>
            </div>
          )}
        />
        <Column
          field="actions"
          body={(data) => (
            <div className="flex justify-content-end gap-2">
              <HUButton
                type="Default"
                colorType="Primary"
                size="XS"
                text={word("program.sas.list.confirm")}
                style={{ padding: "5px 10px" }}
                onClick={() => !isRespondingRequestId && acceptRequest(data)}
                loading={isRespondingRequestId === data.subscriptionId}
              />
              <HUButton
                type="Default"
                colorType="Secondary"
                size="XS"
                text={word("program.sas.list.refuse")}
                style={{ padding: "5px 10px" }}
                onClick={() => !isRespondingRequestId && rejectRequest(data)}
                loading={isRespondingRequestId === data.subscriptionId}
              />
            </div>
          )}
        />
      </StyledProgramsSubscribersTable>
    </>
  )
}

const StyledProgramsSubscribersTable = styled(DataTable)`
  .p-datatable-thead > tr > th {
    display: none;
  }
  .p-datatable-tbody > tr {
    border: none;
  }
  .p-datatable-tbody > tr > td:first-child {
    padding-left: 0px;
  }
`
