import { PasteIcon } from "@images/svgIcons/pasteIcon"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ExerciseCategories } from "@modules/exercises/exerciseTypes"
import { getDisplayExerciseCategory } from "@modules/exercises/exerciseUtils"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { EllipsisMenu } from "@modules/ui/components/ellipsisMenu"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { MenuItem } from "primereact/menuitem"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

export const ExerciseDraftForm: React.FC = () => {
  const { formik, updatedExerciseIndex, isEditingExercise } = useSessionFormContext()
  const theme = useTheme()
  const toast = useToast()
  const { sessionService } = useServicesContext()
  const copyExercise = sessionService.getCopyExercise()

  const exercisesCategory = useMemo(
    () =>
      ExerciseCategories.map((exerciseCategory) => {
        return { value: exerciseCategory, label: getDisplayExerciseCategory(exerciseCategory) }
      }),
    [],
  )

  if (updatedExerciseIndex === null) return <></>

  const formikNameBase = `exercises[${updatedExerciseIndex}]`
  const formikValue = formik.values.exercises[updatedExerciseIndex]

  const ellipsisItems: MenuItem[] = [
    {
      items: [
        {
          label: word("program.exercise.paste.label"),
          icon: <PasteIcon color={theme.neutralColor900} />,
          command: () => {
            onExercisePaste()
          },
        },
      ],
    },
  ]

  const onExercisePaste = () => {
    try {
      if (copyExercise) {
        const newExercise = {
          category: copyExercise.category,
          chrono: copyExercise.chrono,
          chronoAmrap: copyExercise.chronoAmrap,
          chronoEmom: copyExercise.chronoEmom,
          chronoForTime: copyExercise.chronoForTime,
          chronoTabata: copyExercise.chronoTabata,
          chronoType: copyExercise.chrono?.type,
          description: copyExercise.description,
          id: null,
          scoreType: copyExercise.scoreType,
          rpe: copyExercise.rpe,
          title: "",
        }
        const newExercises = [...formik.values.exercises]
        newExercises[updatedExerciseIndex] = newExercise

        formik.setFieldValue("exercises", newExercises)
      }

      toast.show({
        severity: "success",
        summary: word("program.exercise.paste.success"),
      })
    } catch (e: any) {
      toast.show({
        severity: "error",
        summary: word("program.exercise.paste.error"),
      })
    }
  }

  return (
    <>
      <HUStack gap={10}>
        <div className="flex align-items-center">
          <HUStackContainer gap={4}>
            <HUText fontStyle="HS">
              {isEditingExercise
                ? word("program.planning.edit.exercise.title")
                : word("program.planning.create.exercise.title")}
            </HUText>
            <HUText fontStyle="BS" color={theme.neutralColor600}>
              {word("program.planning.create.exercise.description")}
            </HUText>
          </HUStackContainer>
          <div className="flex flex-1 justify-content-end gap-2">
            <EllipsisMenu items={ellipsisItems} disabled={!copyExercise} />
          </div>
        </div>
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          label={word("sessionForm.exercise.category.label")}
          isRequiredInput
          getFieldProps={formik.getFieldProps}
          name={`${formikNameBase}.category`}
          setFieldValue={formik.setFieldValue}
          isInvalid={isFormFieldInValid(`${formikNameBase}.category`, formik)}
          error={getFormErrorMessage(`${formikNameBase}.category`, formik)}
          type="dropdown"
          placeHolder={word("sessionForm.exercise.category.placeHolder")}
          dropdownProps={{
            value: formikValue.category,
            options: exercisesCategory,
          }}
        />
        <FormikInput
          flex
          formikStyle={{ display: "flex", flexGrow: 1 }}
          label={word("sessionForm.exercise.description.label")}
          isRequiredInput
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid(`${formikNameBase}.description`, formik)}
          error={getFormErrorMessage(`${formikNameBase}.description`, formik)}
          name={`${formikNameBase}.description`}
          type="textarea"
          rowNumber={11}
          placeHolder={word("program.planning.create.exercise.placeholder")}
          textareaProps={{ autoResize: true }}
        />
      </HUStack>
    </>
  )
}

const HUStackContainer = styled(HUStack)`
  max-width: 90%;
`
