import { BoxOfferItemDto, convertItemBoxOfferFromDto } from "@modules/boxOffer/services/boxOfferDto"
import {
  BoxProgrammation,
  BoxProgrammationDefaultSettings,
  BoxProgrammationDraft,
  BoxProgrammationLight,
  BoxProgrammationTypeOf,
  BoxProgrammationVisibility,
  ItemBoxProgrammation,
} from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxSlotParticipation } from "@modules/boxSlot/boxSlotTypes"
import { HUImageDto, convertImageFromDto } from "@modules/dtos/imageDto"
import { ExerciseDraftDto, ExerciseDto } from "@modules/exercises/services/exercisesDto"
import { ProfileDto, convertProfileFromDto } from "@modules/profile/profileDtos"

export type ItemBoxProgrammationDto = {
  id: string
  name: string
  image: HUImageDto
  owner: ProfileDto
  subscriptionCount: number
  color: string
  visibility?: BoxProgrammationVisibility
}

export type BoxProgrammationDto = {
  description: string
  defaultHideSessionContent: boolean
  restrictions: BoxOfferItemDto[]
} & ItemBoxProgrammationDto

export type BoxProgrammationLightDto = BoxProgrammationLight

export type BoxProgrammationDraftDto = {
  image?: string
  name: string
  description: string
  color: string
  defaultHideSessionContent: boolean
  visibility?: BoxProgrammationVisibility
  offerIds?: string[]
}

export type BoxProgrammationSessionDto = {
  sessionId: string
  name: string
  programId: string
  programColor: string
  description: string
  date: string
  owner: ProfileDto
  exercises?: ExerciseDto[]
  hideSessionContent?: boolean
  weightliftingTag?: boolean
  gymnasticTag?: boolean
  cardioTag?: boolean
}

export type BoxProgrammationSessionDraftDto = {
  name: string
  programId: string
  description: string
  exercises: ExerciseDraftDto[]
  hideSessionContent?: boolean
  weightliftingTag?: boolean
  gymnasticTag?: boolean
  cardioTag?: boolean
}

export function convertItemBoxProgrammationFromDto(dto: ItemBoxProgrammationDto): ItemBoxProgrammation {
  return {
    id: dto.id,
    _type: BoxProgrammationTypeOf,
    _programType: BoxProgrammationTypeOf,
    name: dto.name,
    image: convertImageFromDto(dto.image),
    author: convertProfileFromDto(dto.owner),
    coachs: [convertProfileFromDto(dto.owner)],
    subscriptionCount: dto.subscriptionCount,
    color: dto.color,
    visibility: dto.visibility,
  }
}

export function convertBoxProgrammationFromDto(dto: BoxProgrammationDto): BoxProgrammation {
  return {
    ...convertItemBoxProgrammationFromDto(dto),
    description: dto.description,
    defaultHideSessionContent: dto.defaultHideSessionContent,
    restrictions: dto.restrictions
      ? dto.restrictions.map((restriction) => convertItemBoxOfferFromDto(restriction))
      : [],
  }
}

export function convertBoxProgrammationDraftToDto(draft: BoxProgrammationDraft): BoxProgrammationDraftDto {
  return {
    name: draft.name,
    description: draft.description,
    image: draft.image?.path,
    color: draft.color,
    defaultHideSessionContent: draft.defaultHideSessionContent,
    visibility: draft.visibility,
    offerIds: draft.restrictions,
  }
}

export function convertPartialBoxProgrammationDraftToDto(
  draft: Partial<BoxProgrammationDraft>,
): Partial<BoxProgrammationDraftDto> {
  return {
    name: draft.name,
    description: draft.description,
    image: draft.image?.path,
    color: draft.color,
    defaultHideSessionContent: draft.defaultHideSessionContent,
    visibility: draft.visibility,
    offerIds: draft.restrictions,
  }
}

export type BoxProgrammationDefaultSettingsDto = {
  duration?: number
  participantLimit?: number
  location?: string
  participation?: BoxSlotParticipation
}

export const convertBoxProgrammationDefaultValuesFromDto = (
  dto: BoxProgrammationDefaultSettingsDto,
): BoxProgrammationDefaultSettings => {
  return {
    duration: dto.duration,
    participantLimit: dto.participantLimit,
    location: dto.location,
    participation: dto.participation,
  }
}

export const convertBoxProgrammationDefaultValuesToDto = (
  draft: BoxProgrammationDefaultSettings,
): BoxProgrammationDefaultSettingsDto => {
  return {
    duration: draft.duration,
    participantLimit: draft.participantLimit,
    location: draft.location,
    participation: draft.participation,
  }
}
