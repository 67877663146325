export type StripeAccountStatus = "UNDEFINED" | "REQUIRE_ADDITIONAL_INFORMATION" | "OK"

export enum StripeErrorCode {
  STRIPE_ACCOUNT_ALREADY_EXISTS = "STRIPE_ACCOUNT_ALREADY_EXISTS",
}

type Continent = "Africa" | "Europe" | "NorthAmerica" | "SouthAmerica" | "Asia" | "Oceania"

export enum StripeCountryCode {
  AU = "AU", // Australia
  AT = "AT", // Austria
  BE = "BE", // Belgium
  BG = "BG", // Bulgaria
  CA = "CA", // Canada
  HR = "HR", // Hungary
  CY = "CY", // Cyprus
  CZ = "CZ", // Czech Republic
  DK = "DK", // Denmark
  EE = "EE", // Estonia
  FI = "FI", // Finland
  FR = "FR", // France
  DE = "DE", // Germany
  // GH = "GH", // Ghana
  // GI = "GI", // Gibraltar
  GR = "GR", // Greece
  HK = "HK", // Honk-Kong
  HU = "HU", // Hungary
  // ID = "ID", // Indonesia
  IE = "IE", // Ireland
  IT = "IT", // Italy
  JP = "JP", // Japan
  // KE = "KE", // Kenya
  LV = "LV", // Latvia
  LI = "LI", // Liechtenstein
  LT = "LT", // Lithunania
  LU = "LU", // Luxembourg
  // MY = "MY", // Malaysia
  MT = "MT", // Malta
  MX = "MX", // Mexico
  NL = "NL", // Netherlands
  NZ = "NZ", // New Zealand
  // NG = "NG", // Nigeria
  NO = "NO", // Norway
  PL = "PL", // Poland
  PT = "PT", // Portugal
  RO = "RO", // Romania
  SG = "SG", // Singapore
  SK = "SK", // Slovakia
  SI = "SI", // Slovenia
  // ZA = "ZA", // South Africa
  ES = "ES", // Spain
  SE = "SE", // Sweden
  CH = "CH", // Switzerland
  GB = "GB", // United Kingdom
  US = "US", // United States
}

type ContinentType = {
  id: Continent
  countries: StripeCountryCode[]
}

export const continents: ContinentType[] = [
  {
    id: "Europe",
    countries: [
      StripeCountryCode.AT,
      StripeCountryCode.BE,
      StripeCountryCode.BG,
      StripeCountryCode.HR,
      StripeCountryCode.CY,
      StripeCountryCode.CZ,
      StripeCountryCode.DK,
      StripeCountryCode.EE,
      StripeCountryCode.FI,
      StripeCountryCode.FR,
      StripeCountryCode.DE,
      // StripeCountryCode.GI,
      StripeCountryCode.GR,
      StripeCountryCode.HU,
      StripeCountryCode.IE,
      StripeCountryCode.IT,
      StripeCountryCode.LV,
      StripeCountryCode.LI,
      StripeCountryCode.LT,
      StripeCountryCode.LU,
      StripeCountryCode.MT,
      StripeCountryCode.NL,
      StripeCountryCode.NO,
      StripeCountryCode.PL,
      StripeCountryCode.PT,
      StripeCountryCode.RO,
      StripeCountryCode.SK,
      StripeCountryCode.SI,
      StripeCountryCode.ES,
      StripeCountryCode.SE,
      StripeCountryCode.CH,
      StripeCountryCode.GB,
    ],
  },
  {
    id: "NorthAmerica",
    countries: [StripeCountryCode.CA, StripeCountryCode.MX, StripeCountryCode.US],
  },
  {
    id: "Asia",
    countries: [
      StripeCountryCode.HK,
      // StripeCountryCode.ID,
      StripeCountryCode.JP,
      // StripeCountryCode.MY,
      StripeCountryCode.SG,
    ],
  },
  // { id: "Africa", countries: [StripeCountryCode.GH, StripeCountryCode.KE, StripeCountryCode.NG] },
  { id: "Oceania", countries: [StripeCountryCode.AU] },
]
