import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const OnOffIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.0625 7.42924C12.0625 5.91235 10.5552 4.86454 9.29818 5.71363C6.10051 7.87364 4 11.5387 4 15.6886C4 22.3156 9.35653 27.6713 15.9832 27.6713C22.644 27.6713 28 22.3156 28 15.6886C28 11.5435 25.9045 7.88222 22.7049 5.72122C21.4457 4.87074 19.9355 5.92045 19.9355 7.43997C19.9355 8.23515 20.3789 8.95043 20.9987 9.44867C22.8201 10.913 23.9829 13.1609 23.9829 15.6886C23.9829 20.0836 20.412 23.6878 15.9832 23.6878C11.5883 23.6878 7.9832 20.0833 7.9832 15.6886C7.9832 13.1544 9.16331 10.9013 10.9963 9.43718C11.618 8.94055 12.0625 8.22497 12.0625 7.42924Z"
      fill={props.color}
    />
    <rect x="14.0625" y="3.21338" width="3.87695" height="10.6891" rx="1.93848" fill={props.color} />
  </svg>
)
