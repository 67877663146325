import { CalendarIcon } from "@images/svgIcons/calendarIcon"
import { GroupIcon } from "@images/svgIcons/groupIcon"
import { MessagesIcon } from "@images/svgIcons/messagesIcon"
import { MoneyIcon } from "@images/svgIcons/moneyIcon"
import { FiveIcon } from "@images/svgIcons/numbers/fiveIcon"
import { FourIcon } from "@images/svgIcons/numbers/fourIcon"
import { OneIcon } from "@images/svgIcons/numbers/oneIcon"
import { SixIcon } from "@images/svgIcons/numbers/sixIcon"
import { ThreeIcon } from "@images/svgIcons/numbers/threeIcon"
import { TwoIcon } from "@images/svgIcons/numbers/twoIcon"
import { ProductsIcon } from "@images/svgIcons/productsIcon"
import { UnionSmallIcon } from "@images/svgIcons/unionSmallIcon"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import React, { useState } from "react"
import styled, { useTheme } from "styled-components"

type WP = {
  onClick: () => void
}

export const BoxFormWelcomePage = ({ onClick }: WP) => {
  const [isSummary, setIsSummary] = useState(false)
  const theme = useTheme()
  return (
    <>
      <div className="flex flex-column align-items-center">
        <UnionSmallIcon color={theme.mainWhite} />
        <HUText fontStyle="DS" className="mb-2 mt-3">
          {isSummary ? word("box.creation.welcome.summary.title") : word("box.creation.welcome.title")}
        </HUText>
        <HUText fontStyle="HS" color={theme.neutralColor600}>
          {isSummary ? word("box.creation.welcome.summary.description") : word("box.creation.welcome.description")}
        </HUText>
      </div>
      {isSummary ? <WelcomeSummaryContainer /> : <WelcomeCardsContainer />}
      <HUButton
        type="Rounded"
        size="M"
        colorType="Primary"
        text={isSummary ? word("box.creation.welcome.button.create") : word("box.creation.welcome.button.start")}
        className="mb-5"
        onClick={() => (isSummary ? onClick() : setIsSummary(true))}
      />
    </>
  )
}

type InformationCardProps = {
  key: "PLANNING" | "OFFERS" | "MEMBERS" | "FINANCE" | "CHAT"
  icon: React.ReactNode
}

const WelcomeCardsContainer = () => {
  const theme = useTheme()
  const informationCards: InformationCardProps[] = [
    { key: "PLANNING", icon: <CalendarIcon color={theme.mainWhite} /> },
    { key: "OFFERS", icon: <ProductsIcon color={theme.mainWhite} /> },
    { key: "MEMBERS", icon: <GroupIcon color={theme.mainWhite} /> },
    { key: "FINANCE", icon: <MoneyIcon color={theme.mainWhite} /> },
    { key: "CHAT", icon: <MessagesIcon color={theme.mainWhite} /> },
  ]
  return (
    <div className="flex gap-5 flex-wrap justify-content-center">
      {informationCards.map(({ key, icon }) => (
        <StyledInfoCard key={key}>
          <div className="flex align-items-center mb-3 gap-3">
            {icon}
            <HUText fontStyle="TL">{word(`box.creation.welcome.infos.${key}.title`)}</HUText>
          </div>
          <div className="flex flex-column gap-3">
            <HUText fontStyle="BM" color={theme.neutralColor700}>
              {word(`box.creation.welcome.infos.${key}.description.1`)}
            </HUText>
            <HUText fontStyle="BM" color={theme.neutralColor700}>
              {word(`box.creation.welcome.infos.${key}.description.2`)}
            </HUText>
          </div>
        </StyledInfoCard>
      ))}
    </div>
  )
}

type SummaryProps = {
  step: "1" | "2" | "3" | "4" | "5" | "6"
  icon: React.ReactNode
  top: string
  widthRem?: number
}

const WelcomeSummaryContainer = () => {
  const theme = useTheme()
  const steps: SummaryProps[] = [
    { step: "1", icon: <OneIcon color={theme.primaryColor700} />, top: "97px" },
    { step: "2", icon: <TwoIcon color={theme.primaryColor700} />, top: "25px" },
    { step: "3", icon: <ThreeIcon color={theme.primaryColor700} />, top: "140px" },
    { step: "4", icon: <FourIcon color={theme.primaryColor700} />, top: "25px" },
    { step: "5", icon: <FiveIcon color={theme.primaryColor700} />, top: "94px", widthRem: 10 },
    { step: "6", icon: <SixIcon color={theme.primaryColor700} />, top: "-19px" },
  ]
  return (
    <StyledSummaryContainer className="flex flex-wrap p-3 md:p-5 justify-content-center w-full row-gap-8">
      {steps.map(({ step, icon, top, widthRem = 9 }) => (
        <StyledSummaryStep key={step} $top={top}>
          {icon}
          <HUText
            fontStyle="HM"
            className={`absolute text-left w-${widthRem}rem`}
            style={{ left: "2.5rem", top: "1.875rem" }}
          >
            {word(`box.creation.welcome.summary.step.${step}`)}
          </HUText>
        </StyledSummaryStep>
      ))}
    </StyledSummaryContainer>
  )
}

const StyledSummaryContainer = styled.div`
  margin-bottom: 11rem;
  column-gap: 8rem;
  @media screen and (max-width: 1360px) {
    margin-bottom: 4rem;
  }
`

const StyledSummaryStep = styled.div<{ $top: string }>`
  position: relative;
  top: ${({ $top }) => $top};
  @media screen and (max-width: 1360px) {
    top: unset;
  }
`

const StyledInfoCard = styled.div`
  width: 13.3rem;
  border-radius: 1.25rem;
  padding: 1.25rem;
  border: ${({ theme }) => `1px solid ${theme.neutralColor300}`};
  background: ${({ theme }) => theme.mainBlack};
`
