import { Page } from "@betomorrow/micro-stores"

export type Paginated<T> = {
  content: readonly T[]
  contentSize: number // content.length
  offset: number
  page: number // current page
  pageSize: number // max size page
  totalElements: number
  totalPages: number
  last: boolean
}

export function toPage<T>(data: Paginated<T>): Page<T> {
  return {
    page: data.page,
    content: data.content,
    totalPages: data.totalPages,
    totalSize: data.totalElements,
  }
}

export function toFakePage<T>(data: T[]): Page<T> {
  return {
    page: 1,
    content: data,
    totalPages: 1,
    totalSize: data.length,
  }
}

export type PaginatedCursor<T> = {
  data: T[]
  hasMore: boolean
  nextPage?: string
}
