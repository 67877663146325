import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import type { DefaultGenerics, MessageResponse } from "stream-chat"

import { useChatClient } from "@modules/chat/hooks/useChatClient"
import { DEFAULT_PAGINATION_LIMIT } from "@modules/chat/utils/chatConstants"

export const useSearchedMessages = (messageFilters: string = "") => {
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [error, setError] = useState<Error | boolean>(false)
  const [messages, setMessages] = useState<MessageResponse<DefaultGenerics>[]>()
  const offset = useRef(0)
  const hasMoreResults = useRef(true)
  const queryInProgress = useRef(false)
  const { chatClient } = useChatClient()

  const done = () => {
    queryInProgress.current = false
    setLoading(false)
    setRefreshing(false)
  }

  const reset = () => {
    setMessages(undefined)
    offset.current = 0
    hasMoreResults.current = true
  }

  const fetchMessages = async () => {
    if (!messageFilters) {
      reset()
      done()
      return
    }

    if (queryInProgress.current) {
      done()
      return
    }

    setLoading(true)

    try {
      queryInProgress.current = true

      if (!hasMoreResults.current) {
        queryInProgress.current = false
        done()
        return
      }

      const res = await chatClient?.search(
        {
          members: {
            $in: [chatClient.user?.id || null],
          },
        },
        { text: { $q: messageFilters } },
        {
          limit: DEFAULT_PAGINATION_LIMIT,
          offset: offset.current,
        },
      )

      const newMessages = res?.results.map((r) => r.message)
      if (!newMessages) {
        queryInProgress.current = false
        done()
        return
      }

      if (offset.current === 0) {
        setMessages(newMessages)
      } else {
        setMessages(_.uniqBy(_.concat(messages ?? [], newMessages), (it) => it.id))
      }
      offset.current = offset.current + newMessages.length

      if (newMessages.length < DEFAULT_PAGINATION_LIMIT) {
        hasMoreResults.current = false
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err)
      } else {
        setError(true)
      }
    }

    done()
  }

  const loadMore = async () => {
    setLoadingMore(true)
    await fetchMessages()
    setLoadingMore(false)
  }

  useEffect(() => {
    reloadList()
  }, [messageFilters])

  const refreshList = () => {
    if (!chatClient?.user?.id) {
      return
    }

    offset.current = 0
    hasMoreResults.current = true

    setRefreshing(true)
    fetchMessages()
  }

  const reloadList = () => {
    reset()
    setMessages([])
    fetchMessages()
  }

  return {
    error,
    loading,
    loadMore,
    loadingMore,
    messages,
    refreshing,
    refreshList,
    reloadList,
    reset,
  }
}
