export const LogoHustleUpSmall: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="35" height="35" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        id="logoHuBlack"
        d="M10.4377 3.28539L6.80377 10.1604H19.4002L23.9308 1.58894C24.1406 1.19213 23.8481 0.717773 23.3936 0.717773H14.7353C12.9284 0.717773 11.2716 1.7076 10.4377 3.28539ZM4.59976 15.2751L0.0691718 23.8466C-0.140566 24.2434 0.151948 24.7178 0.606377 24.7178H9.26469C11.0716 24.7178 12.7284 23.728 13.5623 22.1502L17.1962 15.2751H4.59976Z"
        fill={props.color}
      />
    </svg>
  )
}
