import { AsyncObservable } from "@modules/asyncObservable/asyncObservable"
import {
  BoxSlotTemplateDraft,
  BoxSlotsTemplateWeek,
  BoxSlotsTemplateWeekPartial,
} from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import { BoxSlotTemplateApi } from "@modules/boxSlotTemplate/services/boxSlotTemplateApi"
import { Service } from "@modules/core/services/serviceType"
import { observable } from "micro-observables"

export class BoxSlotTemplateService implements Service {
  boxSlotTemplateWeekStore = new AsyncObservable<BoxSlotsTemplateWeekPartial>(() =>
    this.api.getAllBoxSlotsTemplateWeek(),
  )
  private _boxSlotTemplatesBeingCreated = observable<BoxSlotTemplateDraft[]>([])
  readonly boxSlotTemplatesBeingCreated = this._boxSlotTemplatesBeingCreated.readOnly()

  constructor(private api: BoxSlotTemplateApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedOut(): Promise<void> {
    this.boxSlotTemplateWeekStore.set(null)
  }

  async getBoxSlotTemplate(boxProgrammationId: string, id: string) {
    return this.api.getBoxSlotTemplate(boxProgrammationId, id)
  }

  async getBoxSlotsTemplateWeek(boxProgrammationId: string): Promise<BoxSlotsTemplateWeek> {
    return this.getBoxSlotsTemplateWeek(boxProgrammationId)
  }

  async createBoxSlotTemplate(boxProgrammationId: string, boxSlotTemplateDraft: BoxSlotTemplateDraft) {
    const boxSlotTemplateId = (await this.api.createBoxSlotTemplate(boxProgrammationId, boxSlotTemplateDraft)).id

    if (boxSlotTemplateId) {
      this.boxSlotTemplateWeekStore.fetch()
    }
    return boxSlotTemplateId
  }

  async updateBoxSlotTemplate(boxProgrammationId: string, id: string, boxSlotTemplateDraft: BoxSlotTemplateDraft) {
    const updatedBoxSlotTemplate = await this.api.updateBoxSlotTemplate(boxProgrammationId, id, boxSlotTemplateDraft)

    if (updatedBoxSlotTemplate) {
      this.boxSlotTemplateWeekStore.fetch()
    }
    return updatedBoxSlotTemplate
  }

  async deleteBoxSlotTemplate(boxProgrammationId: string, id: string) {
    await this.api.deleteBoxSlotTemplate(boxProgrammationId, id)

    this.boxSlotTemplateWeekStore.fetch()
  }

  async createBoxSlotsTemplateList(boxProgrammationId: string, boxSlotsTemplateListDraft: BoxSlotTemplateDraft[]) {
    const boxSlotTemplates = await this.api.createBoxSlotsTemplateList(boxProgrammationId, boxSlotsTemplateListDraft)

    if (boxSlotTemplates) {
      this.boxSlotTemplateWeekStore.fetch()
    }
    return boxSlotTemplates
  }

  async updateBoxSlotTemplatesBeingCreated(boxSlotTemplatesDraft: BoxSlotTemplateDraft[]) {
    this._boxSlotTemplatesBeingCreated.set(boxSlotTemplatesDraft)
  }
}
