import { Currency, currencySymbol } from "@modules/currency/currency"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import styled, { useTheme } from "styled-components"
import React from "react"
import { InformationAlertIcon } from "@images/svgIcons/informationAlertIcon"

type BoxOfferFormattedPriceProps = {
  price?: number
  currency?: Currency
}

type BoxOfferFormSectionProps = {
  title: string
  icon: string | React.ReactNode
  description?: string
  className?: string
}

type BoxOfferInputCustomLabelProps = {
  label: string
  description?: string
  isRequired?: boolean
  margin?: string
}

type BoxOfferFormAlertCardProps = {
  title: string
  description?: string
}

export const BoxOfferFormattedPrice = ({ price, currency }: BoxOfferFormattedPriceProps) => {
  const language = useLanguage()
  if (!price) return <HUText fontStyle="HS">{word("offer_list.price.free")}</HUText>
  const [wholePart, decimalPart] = price.toFixed(2).split(".")
  const symbol = currencySymbol(currency, language)
  return (
    <span>
      <HUText fontStyle="HS">{wholePart},</HUText>
      <HUText fontStyle="TS">
        {decimalPart}
        {symbol}
      </HUText>
    </span>
  )
}

export const BoxOfferFormSection = ({ title, icon, description, className }: BoxOfferFormSectionProps) => {
  const theme = useTheme()
  return (
    <div className={className ?? "mb-2"}>
      <div className="flex gap-2 align-items-center">
        {typeof icon === "string" ? <span className={`pi pi-${icon} text-2xl`} /> : icon}
        <HUText fontStyle="TM">{title}</HUText>
      </div>
      {description && (
        <HUText fontStyle="BS" color={theme.neutralColor700} className="flex mt-2">
          {description}
        </HUText>
      )}
    </div>
  )
}

export const BoxOfferInputCustomLabel = ({
  label,
  description,
  margin,
  isRequired = false,
}: BoxOfferInputCustomLabelProps) => {
  const theme = useTheme()
  return (
    <div className={`flex flex-column gap-2 ${margin ?? "mb-3"}`}>
      <HUText type="label" color={theme.neutralColor700} fontStyle="LS">
        {label.toUpperCase()}
        {isRequired && (
          <HUText fontStyle="LS" color={theme.necessaryInput}>
            {" "}
            *
          </HUText>
        )}
      </HUText>
      {description && (
        <HUText fontStyle="BS" color={theme.neutralColor700}>
          {description}
        </HUText>
      )}
    </div>
  )
}

export const BoxOfferRuleCard = styled.div<{
  $new?: boolean
  $borderSize?: number
  $pointer?: boolean
  $resume?: boolean
}>`
  cursor: ${({ $pointer }) => $pointer && "pointer"};
  display: flex;
  flex-direction: ${({ $new }) => ($new ? "column" : "row")};
  padding: 1.75rem 2rem;
  align-items: center;
  border-radius: 1.25rem;
  gap: 0.6rem;
  border: ${({ theme, $new, $borderSize }) =>
    `${$borderSize ?? 1}px ${$new ? `dashed ${theme.neutralColor400}` : `solid ${theme.primaryColor}`}`};
  max-width: ${({ $new, $resume }) => ($resume ? "100%" : $new ? "fit-content" : "12rem")};
  height: 6.75rem;
`

export const BoxOfferFormAlertCard = ({ title, description }: BoxOfferFormAlertCardProps) => {
  const theme = useTheme()
  return (
    <StyledAlertCard>
      <div>
        <InformationAlertIcon backgroundColor={theme.primaryColor100} color={theme.primaryColor600} />
      </div>
      <div className="flex flex-column">
        <HUText fontStyle="BL">{title}</HUText>
        {description && <HUText fontStyle="BM">{description}</HUText>}
      </div>
    </StyledAlertCard>
  )
}

const StyledAlertCard = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.huCardAlertBackground};
  padding: 10px 24px;
  border-radius: 20px;
  gap: 1.125rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 5px;
    background-color: ${({ theme }) => theme.primaryColor};
  }
`
