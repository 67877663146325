import { MemberDetailsInfo } from "@modules/members/components/memberDetailsInfo"
import { Member } from "@modules/members/memberTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { SidebarNavigatorParams } from "@modules/sidebar/sidebarTypes"

export const useMemberSideBar = () => {
  const { sidebarNavigateTo } = useSidebar()

  const navigateToMemberDetailsInfo = (member: Member, args: SidebarNavigatorParams) => {
    sidebarNavigateTo({
      content: <MemberDetailsInfo member={member} />,
      ...args,
    })
  }

  return { navigateToMemberDetailsInfo }
}
