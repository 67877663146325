import ClearInputTextIcon from "@images/ui-icons/cross.svg"
import { word } from "@modules/core/utils/i18n"
import { InputText } from "primereact/inputtext"
import { ChangeEventHandler } from "react"
import styled from "styled-components"

export type MessageSearchInputProps = {
  onFocus: () => void
  search: {
    onChange: ChangeEventHandler<HTMLInputElement>
    value?: string
    loading?: boolean
    onReset: () => void
  }
}

export const MessageSearchInput: React.FC<MessageSearchInputProps> = ({ onFocus, search }) => {
  return (
    <div className="w-full flex justify-content-center mb-1">
      <StyledInputText className="block mt-2 md:mt-0 p-input-icon-left">
        <i className={search.loading ? "pi pi-spin pi-spinner" : "pi pi-search"} />
        <InputText
          type="search"
          onChange={search.onChange}
          placeholder={word("chat.search.placeholder")}
          className="w-17rem focus:outline-none focus:shadow-none"
          value={search.value}
          onFocus={onFocus}
        />
      </StyledInputText>
    </div>
  )
}

const StyledInputText = styled.div`
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-image: url(${ClearInputTextIcon});
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    :hover: {
      cursor: pointer;
    }
  }
`
