import { Service } from "@modules/core/services/serviceType"
import { ItemProgramInfiniteSession, ItemProgramOnOffSession } from "@modules/programSession/programSessionTypes"
import { ProgramInfiniteSessionApi } from "@modules/programSession/services/programInfiniteSessionApi"
import { ProgramOnOffSessionApi } from "@modules/programSession/services/programOnOffSessionApi"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramTypeOf } from "@modules/programs/programTypes"
import { toggleInArray } from "@modules/utils/objectUtils"
import { observable } from "micro-observables"

export class PlanningService implements Service {
  private _planningSessions = observable<ItemProgramInfiniteSession[] | ItemProgramOnOffSession[]>([])
  readonly planningSessions = this._planningSessions.readOnly()
  private _isLoadingSessions = observable<boolean>(false)
  readonly isLoadingSessions = this._isLoadingSessions.readOnly()

  private _filteredProgramPlanning = observable<string[]>([])
  readonly filteredProgramPlanning = this._filteredProgramPlanning.readOnly()

  constructor(
    private programInfiniteSessionApi: ProgramInfiniteSessionApi,
    private programOnOffSessionApi: ProgramOnOffSessionApi,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedOut(): Promise<void> {
    this._planningSessions.set([])
    this._filteredProgramPlanning.set([])
  }

  async updateProgramDatedSessions(
    startDate: string,
    endDate: string,
    programId?: string,
    programType?: ProgramTypeOf,
  ) {
    if (!programId) {
      this._planningSessions.set([])
      return
    }

    this._isLoadingSessions.set(true)
    if (programType === ProgramOnOffTypeOf) {
      const result = await this.programOnOffSessionApi.getSessions(startDate, endDate, programId)

      this._planningSessions.set(result)
    } else {
      const result = await this.programInfiniteSessionApi.getSessions(startDate, endDate, programId)

      this._planningSessions.set(result)
    }
    this._isLoadingSessions.set(false)
  }

  async updateProgramUnDatedSessions(startIndex: number, endIndex: number, programId: string) {
    this._isLoadingSessions.set(true)
    const result = await this.programOnOffSessionApi.getSessionsByIndex(startIndex, endIndex, programId)
    this._planningSessions.set(result)
    this._isLoadingSessions.set(false)
  }

  initFilteredPrograms(programIds: string[]) {
    if (this._filteredProgramPlanning.get().length === 0) {
      this._filteredProgramPlanning.set(programIds)
    }
  }

  addFilteredPrograms(programIds: string[]) {
    const existingFilters = this._filteredProgramPlanning.get()
    const filteredProgramsToAdd = programIds.filter((id) => !existingFilters.includes(id))

    this._filteredProgramPlanning.update((programs) => [...programs, ...filteredProgramsToAdd])
  }

  addNewFilteredProgram(programId: string) {
    this._filteredProgramPlanning.update((programs) => [...programs, programId])
  }

  removeFilteredProgram(programId: string) {
    this._filteredProgramPlanning.update((programs) => programs.filter((p) => p !== programId))
  }

  toggleSelectedProgram(programId: string) {
    const newArray = toggleInArray(this._filteredProgramPlanning.get(), (id) => id === programId, programId)
    this._filteredProgramPlanning.set([...newArray])
  }
}
