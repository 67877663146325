import { Currency } from "@modules/currency/currency"
import { Profile, ProgramAuthor } from "@modules/profile/profileTypes"
import { ItemProgramInfinite, ProgramInfinite, ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ItemProgramOnOff, ProgramOnOff, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { PromoCode } from "@modules/promoCode/types/promoCodeTypes"
import { HUImage } from "@modules/types/imageTypes"

export type ProgramTypeOf = typeof ProgramOnOffTypeOf | typeof ProgramInfiniteTypeOf

export const ProgramContentTypeOf = "PROGRAM"

export type ItemProgram = ItemProgramInfinite | ItemProgramOnOff

export type Program = ProgramInfinite | ProgramOnOff

export enum ProgramVisibility {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum ProgramType {
  VIRTUAL = "VIRTUAL",
  ONOFF = "ON_OFF",
}

export type ProgramPrice = {
  value: number
  currency: Currency
}

export enum ProgramSubscriptionStatus {
  PENDING_REQUEST = "PENDING_REQUEST",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  ACTIVE = "ACTIVE",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  INACTIVE = "INACTIVE",
  TRIALING = "TRIALING",
  TRIAL_PENDING_CANCELLATION = "TRIAL_PENDING_CANCELLATION",
}

export const allProgramFilter = ["OWNED", "SUBSCRIBED", "SHARED_COACH", "ALL"] as const
export type ProgramFilter = (typeof allProgramFilter)[number]

export enum ProgramParticipantSubscriptionStatus {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  PENDING_REQUEST = "PENDING_REQUEST",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
}

export type ProgramPayment = {
  amount: ProgramPrice
  promoCode?: PromoCode
}

export type LightProgramInfo = {
  id: string
  title: string
  image: HUImage
  price?: ProgramPrice
  _programType: ProgramTypeOf
}

export type LightProgram = {
  id: string
  name: string
  descripition: string
  image: HUImage
  owner: ProgramAuthor
  visibility: ProgramVisibility
  scoreboardVisibility: boolean
  type: ProgramType
}

export enum SharedCoachStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED",
}

export type SharedCoach = {
  role: string
  status: SharedCoachStatus
  user: Profile
}

export enum SharedCoachRequestAction {
  ACCEPT = "ACCEPT",
  REFUSE = "REFUSE",
  QUIT = "QUIT",
  REMOVE = "REMOVE",
}
