import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { HUText } from "@modules/ui/components/huText"
import { Link } from "react-router-dom"
import styled from "styled-components"

interface LoginBackProps {
  className?: string
  emailState?: string
}

export const LoginBack: React.FC<LoginBackProps> = ({ className, emailState }) => {
  return (
    <Container className={className}>
      <div>
        <AccountText>{word("auth.form.account")} </AccountText>
        <PressableButton to={Path.Login} state={emailState ? { email: emailState } : undefined}>
          <HUText fontWeight="semibold">{word("auth.form.signin")}</HUText>
        </PressableButton>
      </div>
    </Container>
  )
}

const Container = styled.div`
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const PressableButton = styled(Link)`
  span {
    text-decoration: underline;
    padding-left: 5px;
    padding-right: 5px;
  }
`

const AccountText = styled(HUText)`
  color: ${({ theme }) => theme.neutralColor};
`
