import { AddressDraftDto, AddressDto, convertAddressDraftToDto } from "@modules/address/addressDto"
import { convertImageFromDto, HUImageDto } from "@modules/dtos/imageDto"
import { BoxMemberNextBooking, Member, MemberDetailed, MemberDetailedDraft, MemberStatus } from "@modules/members/memberTypes"
import { convertPublicProfileFromDto, PublicProfileDtos } from "@modules/publicProfile/publicProfileDtos"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import { convertToEnum } from "@modules/utils/enum"
import dayjs from "dayjs"

// Change for API
export interface MemberDto {
  id: string
  avatar?: HUImageDto
  firstname: string
  lastname: string
  username: string
  inscriptionDate: string
  status: string
  note?: string
  lastVisited?: string
  noShowPastMonth?: number
  activeOfferTitles?: string[]
}

export interface MemberDetailedDto {
  id: string
  userId: string
  firstname: string
  lastname: string
  inscriptionDate: string
  status: string
  username: string
  birthDate: string
  phone: string
  email: string
  address: AddressDto
  emergencyInformation: string
  note?: string
  lastVisited?: string
  noShowPastMonth?: number
  activeOffersTitles?: string[]
  nextBookings: BoxMemberNextBookingDto[]
  publicProfile?: PublicProfileDtos
}


export interface MemberDetailedDraftDto {
  firstname: string
  lastname: string
  username?: string
  birthDate: string | null
  phone: string
  email: string
  address: AddressDraftDto
  emergencyInformation: string
  note: string
}


export interface BoxMemberNextBookingDto {
  slotId: string
  name: string
  date: string
  color: string
}

export function convertBoxMemberNextBookingFromDto(dto: BoxMemberNextBookingDto): BoxMemberNextBooking {
  return {
    ...dto,
    date: new Date(dto.date),
  }
}

export function convertMemberFromDto(dto: MemberDto): Member {
  return {
    ...dto,
    inscriptionDate: dto.inscriptionDate ? new Date(dto.inscriptionDate) : undefined,
    status: convertToEnum(MemberStatus, dto.status, MemberStatus.Terminated),
    avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
    lastVisited: dto.lastVisited ? new Date(dto.lastVisited) : undefined,
  }
}

export function convertDetailedMemberFromDto(dto: MemberDetailedDto): MemberDetailed {
  return {
    ...dto,
    birthdate: dto.birthDate ? new Date(dto.birthDate) : undefined,
    phoneNumber: dto.phone,
    status: convertToEnum(MemberStatus, dto.status, MemberStatus.Terminated),
    inscriptionDate: dto.inscriptionDate ? new Date(dto.birthDate) : undefined,
    lastVisited: dto.lastVisited ? new Date(dto.lastVisited) : undefined,
    publicProfile: dto.publicProfile ? convertPublicProfileFromDto(dto.publicProfile) : undefined,
    nextBookings: dto.nextBookings?.map(convertBoxMemberNextBookingFromDto),
    noShowPastMonth: dto.noShowPastMonth || 0,
  }
}

export function convertDetailedMemberDraftToDto(draft: MemberDetailedDraft): MemberDetailedDraftDto {
  return {
    email: draft.email,
    firstname: draft.firstname,
    lastname: draft.lastname,
    username: draft.username?.trim() || undefined,
    birthDate: draft.birthDate ? dayjs(draft.birthDate).format(defaultDateFormat) : null,
    phone: draft.phoneNumber,
    address: convertAddressDraftToDto(draft.address),
    emergencyInformation: draft.emergencyInformation,
    note: draft.note,
  }
}
