export function isValidEmail(value: string): boolean {
  // tslint:disable-next-line:max-line-length
  const pattern =
    /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(value.toLowerCase())
}

export function isValidPassword(value: string): boolean {
  const passw =
    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9\S$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
  if (value.match(passw)) {
    return true
  }
  return false
}
