import { TabataIcon } from "@images/svgIcons/tabataIcon"
import { word } from "@modules/core/utils/i18n"
import { RecapBloc } from "@modules/exercises/components/RecapDetailAllChronos/recapBloc"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useTheme } from "styled-components"

export const TabataRecap: React.FC = () => {
  const { formik, updatedExerciseIndex } = useSessionFormContext()
  const { navigateToChronoForm } = useSessionSidebar()
  const theme = useTheme()

  if (updatedExerciseIndex === null) return <></>

  const formikValue = formik.values.exercises[updatedExerciseIndex]
  const formikNameBase = `exercises[${updatedExerciseIndex}]`

  const onDeleteChronoClick = () => {
    formik.setFieldValue(`${formikNameBase}.chronoTabata`, undefined)
    formik.setFieldValue(`${formikNameBase}.chrono`, undefined)
    formik.setFieldValue(`${formikNameBase}.chronoType`, undefined)
  }

  const onEditChronoContentClick = () => {
    navigateToChronoForm()
  }

  return (
    <>
      <div className="flex ">
        <TabataIcon color={"white"} />
        <div className="flex flex-column justify-content-around	ml-4">
          <HUText fontStyle="LL">{word("chrono.title.tabata")}</HUText>
          <HUText fontStyle="BS" color={theme.neutralColor600}>
            {word("chrono.tabata.definition")}
          </HUText>
        </div>
      </div>
      {formikValue.chronoTabata?.trainingTime && (
        <div className="flex flex-column grid mt-2">
          <RecapBloc
            circleTimeIcon
            value={formikValue.chronoTabata.trainingTime}
            label={word("chrono.settings.label.training_time")}
          />
          <div className="flex justify-content-between mt-2">
            <RecapBloc
              circleBreakIcon
              value={formikValue.chronoTabata.restTime}
              label={word("chrono.settings.label.rest_time")}
            />

            <RecapBloc
              circleTimeIcon
              value={formikValue.chronoTabata.numberOfRounds}
              label={word("chrono.settings.label.rounds")}
            />
          </div>
          <div className="flex justify-content-between mt-2">
            <RecapBloc
              setsIcon
              value={formikValue.chronoTabata.numberOfSets}
              label={word("chrono.settings.label.number_sets")}
            />

            <RecapBloc
              circleBreakIcon
              value={formikValue.chronoTabata.restBetweenSetsTime}
              label={word("chrono.settings.label.rest_between_sets")}
            />
          </div>
        </div>
      )}
      <div className="flex justify-content-between mt-4">
        <HUButton
          type="Default"
          size="M"
          colorType="Quaternary"
          text={word("global.delete")}
          style={{ width: "100%", marginRight: "10px" }}
          onClick={onDeleteChronoClick}
        />
        <HUButton
          type="Default"
          size="M"
          colorType="Tertiary"
          text={word("program.details.modify.button")}
          style={{ width: "100%" }}
          onClick={onEditChronoContentClick}
        />
      </div>
    </>
  )
}
