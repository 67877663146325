import { AsyncObservable } from "@modules/asyncObservable/asyncObservable"
import { useMemoizedObservable } from "micro-observables"
import { useEffect, useState } from "react"

function useInternalAsyncObservable<T>(asyncObservable: AsyncObservable<T>, additionalDeps = []) {
  const result = useMemoizedObservable(() => asyncObservable.value, [...additionalDeps])
  const [loading, setLoading] = useState(!result)
  const [error, setError] = useState(null)

  const fetch = () => {
    setLoading(true)
    setError(null)
    asyncObservable
      .fetch()
      .catch((e) => setError(e))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetch()
  }, [...additionalDeps])

  return { loading, result, error, setError, fetch }
}

export function useAsyncObservable<T>(asyncObservable: AsyncObservable<T>, additionalDeps = []) {
  const { loading, result, error, fetch } = useInternalAsyncObservable(asyncObservable, additionalDeps)
  return { loading, result, error, fetch }
}

export function useRefreshAsyncObservable<T>(asyncObservable: AsyncObservable<T>, additionalDeps = []) {
  const { loading, result, error, setError, fetch } = useInternalAsyncObservable(asyncObservable, additionalDeps)

  const [refreshing, setRefreshing] = useState(false)

  const refresh = () => {
    setRefreshing(true)
    setError(null)
    asyncObservable
      .fetch()
      .catch((e) => setError(e))
      .finally(() => setRefreshing(false))
  }

  return { loading, result, error, fetch, refreshing, refresh }
}
