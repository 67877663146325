import { Profile } from "@modules/profile/profileTypes"
import { HUText } from "@modules/ui/components/huText"
import styled, { useTheme } from "styled-components"

type AvatarProps = {
  profile: Pick<Profile, "avatar" | "username">
  size?: number
  className?: string
  borderColor?: string
}
export const ProfileAvatar: React.FC<AvatarProps> = ({
  profile,
  size = 32,
  className,
  borderColor = "transparent",
}) => {
  const theme = useTheme()
  return profile.avatar?.path ? (
    <AvatarContainer className={className} $size={size} $borderColor={borderColor}>
      <img src={profile.avatar.path} alt="Profile" width={size} height={size} />
    </AvatarContainer>
  ) : (
    <FirstLetterContainer className={className} $size={size} $borderColor={borderColor}>
      <HUText className="pseudo" fontWeight="semibold" color={theme.neutralColor700}>
        {profile.username[0] && profile.username[0].toUpperCase()}
      </HUText>
    </FirstLetterContainer>
  )
}

const AvatarContainer = styled.div<{ $size: number; $borderColor: string }>`
  display: flex;
  justify-content: center;
  overflow: hidden;
  img {
    object-fit: cover;
    border-radius: ${({ $size }) => $size / 2}px;
    border: ${({ $borderColor }) => ($borderColor === "transparent" ? "none" : "3px solid " + $borderColor)};
  }
`
const FirstLetterContainer = styled.div<{ $size: number; $borderColor: string }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: ${({ $size }) => $size / 2}px;
  border: 3px solid ${({ $borderColor }) => $borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.neutralColor200};
  .pseudo {
    font-size: ${({ $size }) => $size / 2}px;
  }
`
