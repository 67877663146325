import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { HUImage } from "@modules/types/imageTypes"
import { MAX_SIZE, readFile, resizeImage } from "@modules/ui/components/fileUpload/fileUploadUtils"
import { HUButton } from "@modules/ui/components/huButton"
import { useToast } from "@modules/ui/components/huToast"
import { FileUploadProps, FileUpload as PRFileUpload } from "primereact/fileupload"
import { ProgressSpinner } from "primereact/progressspinner"
import { Dispatch, forwardRef, SetStateAction, useImperativeHandle, useRef, useState } from "react"
import styled, { useTheme } from "styled-components"

export type FileUploadRef = {
  click: () => void
}

type Props = {
  currentImage?: HUImage
  setImage: (image?: HUImage) => void
  prefixPath?: string
  showUploadButton?: boolean
  onStartUpload?: () => void
  onEndUpload?: () => void
  onTouch?: () => void
  setEdit: Dispatch<SetStateAction<boolean>>
  setEditedImage: Dispatch<SetStateAction<File | undefined>>
  cropper?: boolean
} & FileUploadProps

export const FileUpload = forwardRef<FileUploadRef, Props>(
  (
    {
      setImage,
      currentImage,
      name,
      prefixPath,
      showUploadButton = true,
      onStartUpload,
      onEndUpload,
      onTouch,
      setEdit,
      setEditedImage,
      cropper = false,
      ...props
    },
    fileUploadRef,
  ) => {
    const toast = useToast()
    const ref = useRef<PRFileUpload>(null)
    const { assetService } = useServicesContext()
    const [loading, setLoading] = useState(false)
    const theme = useTheme()
    useImperativeHandle(fileUploadRef, () => ({
      click: () => ref.current?.getInput().click(),
    }))

    return currentImage ? (
      <RemoveIcon
        type="Rounded"
        size="M"
        colorType="Tertiary"
        onClick={() => {
          setImage(undefined)
          setEdit(false)
          setEditedImage(undefined)
          onTouch?.()
        }}
        icon={{ iconView: <span className="pi pi-times" /> }}
        className="h-2rem w-2rem z-5"
        style={{ backgroundColor: theme.mainWhite }}
      />
    ) : (
      <PRFileUpload
        ref={ref}
        mode="basic"
        name={name}
        disabled={loading}
        className={`${showUploadButton ? `` : `hidden`}`}
        onSelect={async (e) => {
          try {
            setLoading(true)
            onStartUpload?.()
            const newImage = e.files[0]

            if (!props.accept?.split(", ").includes(newImage.type)) {
              toast.show({
                severity: "error",
                summary: word("global.error.label"),
                detail: word("file_upload.types.error"),
              })
              ref.current?.clear()
              return
            }

            const resizedImage = await resizeImage(newImage)
            if (resizedImage.size > MAX_SIZE) {
              toast.show({
                severity: "error",
                summary: word("global.error.label"),
                detail: word("file_upload.max_size.error"),
              })
              ref.current?.clear()
              return
            }
            const result = await readFile(resizedImage)
            if (result) {
              const asset = await assetService.uploadImage(result, prefixPath)
              setImage(asset)
              if (cropper) {
                setEditedImage(resizedImage)
              }
            } else {
              toast.show({
                severity: "error",
                summary: word("global.error.label"),
                detail: word("global.fileUpload.error"),
              })
            }
          } catch (e: any) {
            toast.show({
              severity: "error",
              summary: word("global.error.label"),
              detail: word("global.fileUpload.error"),
            })
            console.error(`[FileUpload] ${e.message ?? word("global.error.label")}`)
          } finally {
            setLoading(false)
            onEndUpload?.()
            onTouch?.()
          }
          ref.current?.clear()
        }}
        chooseLabel={word("global.form.addImage")}
        chooseOptions={
          loading
            ? {
                icon: <ProgressSpinnerStyled className="p-button-icon-left" strokeWidth="5" />,
              }
            : {}
        }
        {...props}
      />
    )
  },
)

export const ProgressSpinnerStyled = styled(ProgressSpinner)`
  width: 16px;
  height: 16px;
  stroke: var(--surface-a);

  .p-progress-spinner-circle {
    stroke: white;
    animation: none;
  }
`
const RemoveIcon = styled(HUButton)`
  position: absolute;
  top: 14px;
  right: 14px;
`
