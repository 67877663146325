import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const LockOutlinedIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="4" y="7" width="16" height="14" rx="4" stroke={props.color} strokeWidth="1.5" />
    <circle cx="12" cy="14" r="2" stroke={props.color} strokeWidth="1.5" />
    <path d="M16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7" stroke={props.color} strokeWidth="1.5" />
  </svg>
)
