import ClearInputTextIcon from "@images/ui-icons/cross.svg"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { minScreenWidth } from "@modules/ui/uiConstants"
import { InputText } from "primereact/inputtext"
import { ChangeEventHandler } from "react"
import styled, { useTheme } from "styled-components"

type ScreenHeaderProps = {
  title: string
  subTitle?: string
  rightView?: React.ReactNode
  leftButton?: React.ReactNode
  children?: React.ReactNode
}

export const ScreenHeader: React.FC<ScreenHeaderProps> = ({ title, subTitle, rightView, leftButton, children }) => {
  const theme = useTheme()
  return (
    <Container
      className="flex flex-column justify-content-between align-items-start gap-2 md:flex-row md:gap-3 md:align-items-center"
      style={{ minWidth: minScreenWidth }}
    >
      <HUStack gap={0} className="flex-1">
        <HURow>
          <HUText fontStyle="HL">{title}</HUText>
          {leftButton && <div>{leftButton}</div>}
        </HURow>
        {subTitle && (
          <HUText fontStyle="BM" color={theme.textSecondary}>
            {subTitle}
          </HUText>
        )}
        {children}
      </HUStack>
      {rightView && <div>{rightView}</div>}
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 1.75rem;
`

type ListHeaderProps = {
  title: string
  search?: {
    onChange: ChangeEventHandler<HTMLInputElement>
    placeholder: string
    value?: string
    loading?: boolean
  }
  rightView?: React.ReactNode
  className?: string
}

export const ListHeader: React.FC<ListHeaderProps> = ({ title, search, rightView, className = "" }) => {
  return (
    <div
      className={"flex flex-column align-items-center gap-2 md:flex-row md:gap-3 " + className}
      style={{ minWidth: minScreenWidth }}
    >
      <HUText fontStyle="HS" className="flex-grow-1">
        {title}
      </HUText>
      {search && (
        <StyledInputText className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onChange={search.onChange}
            placeholder={search.placeholder}
            className="w-17rem"
            defaultValue={search.value}
          />
        </StyledInputText>
      )}
      {rightView}
    </div>
  )
}

const StyledInputText = styled.div`
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-image: url(${ClearInputTextIcon});
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
  }
`

type CardHeaderProps = {
  title: string
  subtitle?: string
}

export const CardHeader: React.FC<CardHeaderProps> = ({ title, subtitle }) => {
  const theme = useTheme()
  return (
    <HUStack gap={4}>
      <HUText fontStyle="TL">{title}</HUText>
      <HUText color={theme.textSecondary} fontStyle="BM">
        {subtitle}
      </HUText>
    </HUStack>
  )
}
