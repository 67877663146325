import { BoxSlotSessionFormikType } from "@modules/boxSlot/boxSessionFormikTypes"
import {
  ProgramInfiniteSessionFormikType,
  ProgramOnOffSessionFormikType,
} from "@modules/programSession/programSessionFormikType"
import { HUText } from "@modules/ui/components/huText"
import { SimpleFormik } from "@src/typings/formik"
import { Slider } from "primereact/slider"
import React, { useMemo } from "react"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import styled, { useTheme } from "styled-components"

type RpeSliderProps = {
  isRpe: boolean
  formik: SimpleFormik<ProgramInfiniteSessionFormikType | ProgramOnOffSessionFormikType | BoxSlotSessionFormikType, any>
  index: number
}

const SLIDER_WIDTH = 486
const SLIDER_STEP = SLIDER_WIDTH / 9

export const RpeSlider: React.FC<RpeSliderProps> = ({ isRpe, formik, index }) => {
  const theme = useTheme()

  const fieldProps = formik.getFieldProps(`exercises[${index}].rpe`)

  const positionRound = useMemo(() => {
    if (fieldProps.value !== 0 && fieldProps.value !== null) {
      return (fieldProps.value - 1) * SLIDER_STEP - 16
    } else return -16
  }, [fieldProps.value])

  const handleDrag = (_: DraggableEvent, data: DraggableData) => {
    if (data.x !== undefined) {
      formik.setFieldValue(`exercises[${index}].rpe`, (data.x + 16) / SLIDER_STEP + 1)
    }
  }

  return (
    <div className="relative pt-4" style={{ width: SLIDER_WIDTH }}>
      <Draggable
        grid={[SLIDER_STEP, 0]}
        axis="x"
        position={{ x: positionRound, y: 20 }}
        onDrag={handleDrag}
        bounds={{ left: -16, right: 470 }}
      >
        <RoundContainer $positionRound={positionRound} $isRpe={isRpe}>
          <HUText type="label" fontStyle="BM" color={theme.neutralColor100} className="cursor-grab">
            {fieldProps.value ?? 5}
          </HUText>
        </RoundContainer>
      </Draggable>
      <div className="flex flex-column justify-content-center mt-4">
        <SliderCustom
          $isRpe={isRpe}
          value={fieldProps.value}
          onChange={(e) => {
            if (e.value !== undefined) {
              formik.setFieldValue(`exercises[${index}].rpe`, e.value)
            }
          }}
          step={1}
          min={1}
          max={10}
          className={`w-full`}
          disabled={!isRpe}
        />
        <div className="flex justify-content-between mt-2">
          <HUText type="label" fontStyle="BM" color={theme.neutralColor900}>
            1
          </HUText>
          <HUText type="label" fontStyle="BM" color={theme.neutralColor900}>
            10
          </HUText>
        </div>
      </div>
    </div>
  )
}

const SliderCustom = styled(Slider)<{ $isRpe: boolean }>`
  .p-slider-handle {
    height: 30px;
    width: 1px;
    top: 5px;
    background: #ffffff;
    border: ${({ theme }) => `1.5px solid ${theme.neutralColor900}!important`};
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  &.p-slider-horizontal {
    height: 20px !important;
    border-radius: 20px;
    background: ${({ $isRpe, theme }) =>
      $isRpe ? `linear-gradient(to right, #33ff00, #ccff00, #ffcc00, #ff6600, #ff0000)` : theme.neutralColor500};
    position: relative;
  }
  &.p-slider-horizontal .p-slider-handle {
    margin-left: 0px !important;
  }
  .p-slider-range {
    display: none;
  }
`

const RoundContainer = styled.div<{ $positionRound: number; $isRpe: boolean }>`
  background-color: ${({ $isRpe, theme }) => ($isRpe ? theme.neutralColor900 : theme.neutralColor700)};
  height: 34px;
  width: 34px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  cursor: grab;
  justify-content: center;
`
