import { Box, BoxDraft, ItemBox } from "@modules/box/boxTypes"
import {
  BoxDto,
  ItemBoxDto,
  convertBoxDraftToDto,
  convertBoxFromDto,
  convertItemBoxFromDto,
} from "@modules/box/services/boxDto"
import { ApiClient } from "@modules/core/api/client/apiClient"
import _ from "lodash"

export class BoxApi {
  private baseUrl = "/boxes"

  constructor(private api: ApiClient) {}

  async getBoxs(): Promise<ItemBox[]> {
    const resultDto = await this.api.get<ItemBoxDto[]>(`${this.baseUrl}`)
    return _.compact(resultDto.data.map((dto) => convertItemBoxFromDto(dto)))
  }

  async getBox(id: string): Promise<Box> {
    const resultDto = await this.api.get<BoxDto>(`${this.baseUrl}/${id}`)
    return convertBoxFromDto(resultDto.data)
  }

  async createBox(box: BoxDraft): Promise<Box> {
    const resultDto = await this.api.post<BoxDto>(`${this.baseUrl}`, convertBoxDraftToDto(box))
    return convertBoxFromDto(resultDto.data)
  }

  async updateBox(id: string, box: BoxDraft): Promise<Box> {
    const resultDto = await this.api.put<BoxDto>(`${this.baseUrl}/${id}`, convertBoxDraftToDto(box))
    return convertBoxFromDto(resultDto.data)
  }

  async deleteBox(id: string): Promise<void> {
    await this.api.delete<BoxDto>(`${this.baseUrl}/${id}`)
  }
}
