import { CommentIcon } from "@images/svgIcons/commentIcon"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { useScoreboardSidebar } from "@modules/scoreboard/hooks/useScoreboardSidebar"
import { ScoreboardMyRanking, ScoreboardRanking } from "@modules/scoreboard/scoreboardTypes"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { useGetDisplayedScore } from "@modules/utils/hooks/useUnit"
import styled, { useTheme } from "styled-components"

type Props = { userScore: ScoreboardRanking; myRanking: ScoreboardMyRanking | null }

export const ScoreboardDetailsCard: React.FC<Props> = ({ userScore, myRanking }) => {
  const theme = useTheme()
  const isMyRanking = userScore.userId === myRanking?.userId ? true : false

  const { navigateToScoreboardIndivView } = useScoreboardSidebar()

  const getScore = useGetDisplayedScore()
  return (
    <Card $isMyRanking={isMyRanking} onClick={() => navigateToScoreboardIndivView(userScore, isMyRanking)}>
      <div className="flex justify-content-center align-items-center">
        <div className="relative mb-1 mr-2 ">
          <ProfileAvatar profile={{ avatar: userScore.avatar, username: userScore.firstname || "" }} size={54} />
          <Position $isMyRanking={isMyRanking}>
            <HUText fontStyle="LM" color={theme.mainWhite}>
              {userScore.position}
            </HUText>
          </Position>
        </div>
        <HUStack gap={1}>
          <HUText
            fontWeight="semibold"
            color={isMyRanking ? theme.secondaryColor : theme.neutralColor900}
            fontStyle="LL"
          >
            {userScore.firstname + " " + userScore.lastname}
          </HUText>
          <HUText color={isMyRanking ? theme.secondaryColor : theme.neutralColor700} fontStyle="BS">
            {getScore(userScore.score.value, userScore.scoreType)}
          </HUText>
        </HUStack>
      </div>
      <div>
        {userScore.score.note && <CommentIcon color={isMyRanking ? theme.secondaryColor : theme.neutralColor900} />}
      </div>
    </Card>
  )
}

const Card = styled.div<{ $isMyRanking: boolean }>`
  display: flex;
  width: 100%;
  height: 86px;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 10px;
  background: ${({ theme, $isMyRanking }) =>
    $isMyRanking ? "linear-gradient(87deg, #E4F56C 0.65%, #CEE743 99.23%)" : theme.neutralColor100};

  &:hover {
    cursor: pointer;
  }
`

const Position = styled.div<{ $isMyRanking: boolean }>`
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  height: 26px;
  border-radius: 13px;
  border: 2px solid ${({ theme }) => theme.neutralColor100};
  background: ${({ theme, $isMyRanking }) => ($isMyRanking ? theme.secondaryColor : theme.neutralColor700)};
  display: flex;
  justify-content: center;
  align-items: center;
`
