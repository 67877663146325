import { Calendar } from "@modules/calendar/calendar"
import { DetailedSessionType } from "@modules/sessions/components/programSessionDetailsComponent"
import { TrainingCalendarEvent, TrainingCalendarEventType } from "@modules/training/components/trainingCalendarEvent"
import styled from "styled-components"

type TrainingCalendarProps = {
  sessions: TrainingCalendarEventType[]
  onClickOnSlot: (arg: DetailedSessionType) => void
  detailedSessionId?: string
  isLoadingSessions: boolean
}

export const TrainingCalendar: React.FC<TrainingCalendarProps> = ({
  sessions,
  onClickOnSlot,
  detailedSessionId,
  isLoadingSessions,
}) => {
  return (
    <Container className="flex flex-column align-content-center flex-grow-1">
      <Calendar
        calendarEvents={sessions}
        canAddEvents={false}
        loading={isLoadingSessions}
        customRenderEvent={(index, event) => (
          <TrainingCalendarEvent
            key={index}
            index={index}
            event={sessions.find((session) => session.id === event.id)}
            onEventClick={onClickOnSlot}
            isDetailsOpen={detailedSessionId === event.id}
          />
        )}
      />
    </Container>
  )
}

const Container = styled.div`
  tbody {
    background: ${({ theme }) => theme.mainWhite};
  }
`
