import { Service } from "@modules/core/services/serviceType"
import { PaymentApi } from "@modules/payment/services/paymentApi"
import { StripeCountryCode } from "@modules/payment/stripeTypes"
import { UserService } from "@modules/profile/services/userService"

export class PaymentService implements Service {
  constructor(
    private userService: UserService,
    private paymentApi: PaymentApi,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onLoggedIn(): Promise<void> {}

  async createAccountToken() {
    return this.paymentApi.createAccountToken()
  }

  async createBoxStripeAccount(accountToken: string, countryCode?: StripeCountryCode) {
    return this.paymentApi.createBoxStripeAccount(accountToken, countryCode)
  }

  async getUpdateBoxAccountLink() {
    return this.paymentApi.getUpdateBoxAccountLink()
  }
  async createUserStripeAccount(accountToken: string, countryCode: StripeCountryCode) {
    return this.paymentApi.createUserStripeAccount(accountToken, countryCode)
  }

  async getUpdateUserAccountLink() {
    return this.paymentApi.getUpdateUserAccountLink()
  }

  async createProgramPaymentSubscription(programId: string, successUrl: string, cancelUrl: string) {
    return this.paymentApi.createProgramPaymentSubscription(programId, successUrl, cancelUrl)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onLoggedOut(): Promise<void> {}
}
