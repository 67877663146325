import OnboardingPaymentDot from "@images/payment/onboardingPaymentDot.svg"
import OnboardingPaymentFlag from "@images/payment/onboardingPaymentFlag.svg"
import StripeLogo from "@images/payment/stripe-logo.svg"
import { CheckmarkIcon } from "@images/svgIcons/checkmarkIcon"
import { WarningIcon } from "@images/svgIcons/warningIcon"
import { word } from "@modules/core/utils/i18n"
import { useStripeAccount } from "@modules/finances/hooks/useStripeAccount"
import { continents, StripeCountryCode } from "@modules/payment/stripeTypes"
import { HUButton } from "@modules/ui/components/huButton"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { Carousel } from "primereact/carousel"
import { Dropdown } from "primereact/dropdown"
import { useState } from "react"
import styled, { useTheme } from "styled-components"

type CardItem = {
  index: number
  title: string
  children: React.ReactNode
}

export const OnBoardingStripeModal: React.FC<{ visible: boolean; onHide: () => void }> = ({ visible, onHide }) => {
  const cards = [
    { index: 0, children: <OnBoardingCard1 /> },
    { index: 1, children: <OnBoardingCard2 /> },
    { index: 2, children: <OnBoardingCard3 /> },
    { index: 3, children: <OnBoardingCard4 /> },
  ]

  const cardTemplate = (card: CardItem) => {
    return card.children
  }

  return (
    <StyledHUDialog header={null} onHide={onHide} visible={visible} style={{ width: 512 }}>
      <Carousel value={cards} itemTemplate={cardTemplate} showIndicators={true} showNavigators={true} />
    </StyledHUDialog>
  )
}

const OnBoardingCard1: React.FC = () => {
  const theme = useTheme()
  return (
    <HUStack className="align-items-center justify-content-center">
      <HUText fontStyle="TL" className="text-center mb-4">
        {word("program.stripe.onboarding.title")}
      </HUText>

      <HUText fontStyle="BM" className="text-center mb-4">
        {word("program.stripe.onboarding.introduction")}
      </HUText>

      <img src={OnboardingPaymentDot} width={250} height={250} />

      <InformationCard className="mt-4 py-2 px-4 align-items-center justify-content-between">
        <WarningIcon color={theme.secondaryColor} width={20} height={20} />
        <HUText fontStyle="BS" className="text-center ml-2">
          {word("program.stripe.onboarding.paymentDayInformation")}
        </HUText>
      </InformationCard>
    </HUStack>
  )
}

const OnBoardingCard2: React.FC = () => {
  return (
    <HUStack className="align-items-center justify-content-around">
      <HUText fontStyle="TL" className="text-center">
        {word("program.stripe.onboarding.title")}
      </HUText>

      <HUText fontStyle="BM" className="text-center" style={{ whiteSpace: "pre-line" }}>
        {word("program.stripe.onboarding.partnerAssociation")}
      </HUText>

      <img src={StripeLogo} width={260} />

      <HUText fontStyle="TS" className="text-center mb-8">
        {word("program.stripe.onboarding.partnerStripe")}
      </HUText>
    </HUStack>
  )
}

const OnBoardingCard3: React.FC = () => {
  const theme = useTheme()

  return (
    <HUStack className="align-items-center justify-content-around">
      <HUText fontStyle="TL" className="text-center mb-4">
        {word("program.stripe.onboarding.title")}
      </HUText>

      <RequiredInformationContainer gap={8} className="p-4 align-items-center">
        <Check>
          <CheckmarkIcon
            color={theme.textPrimary}
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </Check>
        <HUText fontStyle="LL" className="text-center mb-2">
          {word("program.stripe.onboarding.requiredInformation.title")}
        </HUText>
        <HUText fontStyle="BS" className="text-center">
          {word("program.stripe.onboarding.requiredInformation.name")}
        </HUText>
        <HUText fontStyle="BS" className="text-center">
          {word("program.stripe.onboarding.requiredInformation.birthday")}
        </HUText>
        <HUText fontStyle="BS" className="text-center">
          {word("program.stripe.onboarding.requiredInformation.email")}
        </HUText>
        <HUText fontStyle="BS" className="text-center">
          {word("program.stripe.onboarding.requiredInformation.phoneNumber")}
        </HUText>
        <HUText fontStyle="BS" className="text-center">
          {word("program.stripe.onboarding.requiredInformation.address")}
        </HUText>
        <HUText fontStyle="BS" className="text-center">
          {word("program.stripe.onboarding.requiredInformation.bankData")}
        </HUText>
        <HUText fontStyle="BS" className="text-center">
          {word("program.stripe.onboarding.requiredInformation.countryRelatedChanges")}
        </HUText>
      </RequiredInformationContainer>

      <InformationCard className="my-4 py-3 px-4 align-items-center justify-content-between">
        <div style={{ width: 20, height: 20 }}>
          <WarningIcon color={theme.secondaryColor} width={20} height={20} />
        </div>
        <HUText fontStyle="BS" className="text-center ml-2 px-2" style={{ whiteSpace: "pre-line" }}>
          {word("program.stripe.onboarding.requiredInformation.seriousNotice")}
        </HUText>
      </InformationCard>
    </HUStack>
  )
}

const OnBoardingCard4: React.FC = () => {
  const [selectedCountry, setSelectedCountry] = useState<StripeCountryCode | undefined>()
  const { createStripeAccount, loading } = useStripeAccount()
  const theme = useTheme()

  const countryOptions = continents.map((continent) => ({
    code: continent.id,
    label: word(`global.continent.${continent.id}`),
    items: continent.countries
      .map((country) => ({
        value: country,
        label: word(`global.country.${country}`),
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label)
      }),
  }))

  return (
    <HUStack className="align-items-center justify-content-around">
      <HUText fontStyle="TL" className="text-center mb-2">
        {word("program.stripe.onboarding.title")}
      </HUText>

      <img src={OnboardingPaymentFlag} width={250} height={250} />

      <HUText fontStyle="BM" className="text-center mt-2">
        {word("program.stripe.onboarding.countryHost")}
      </HUText>

      <HUStack className="w-full px-4" gap={4}>
        <HUText type="label" color={theme.neutralColor700} fontStyle="LS">
          {word("global.country.label").toUpperCase()}
          <HUText fontStyle="LL" color={theme.necessaryInput}>
            {" "}
            *
          </HUText>
        </HUText>
        <Dropdown
          value={selectedCountry}
          options={countryOptions}
          onChange={(e) => setSelectedCountry(e.value as StripeCountryCode)}
          optionGroupLabel="label"
          optionLabel="label"
          optionGroupChildren="items"
          scrollHeight="300px"
          style={{ width: "100%" }}
        />
      </HUStack>

      <StyledHUNavigateButton
        fontStyle="BM"
        className="align-self-end mb-4 underline cursor-pointer"
        to="mailto:contact@hustleup-app.com?subject=I%20can't%20find%20my%20country%20&body="
        label={word("program.stripe.onboarding.countryNotFound")}
        link
      />

      <HUButton
        type="Default"
        size="M"
        colorType="Primary"
        disabled={!selectedCountry}
        text={word("program.stripe.onboarding.start")}
        loading={loading}
        onClick={() => selectedCountry && createStripeAccount(selectedCountry)}
        className="w-full mb-3"
      />
    </HUStack>
  )
}

const StyledHUDialog = styled(HUDialog)`
  border-radius: 33px;
  > .p-dialog-content {
    padding: 0px 28px 28px 28px;
  }
  > .p-dialog-header {
    border-top-right-radius: 33px;
    border-top-left-radius: 33px;
  }
  > .p-dialog-content:last-of-type {
    border-bottom-right-radius: 33px;
    border-bottom-left-radius: 33px;
  }
  .p-carousel {
    width: 100%;
    justify-content: center;
  }
  .p-carousel-content {
    overflow: hidden;
    flex-grow: 1;
  }
  .p-carousel-container {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .p-carousel-items-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .p-carousel .p-carousel-content .p-carousel-prev {
    z-index: 10;
    margin: 0;
    position: absolute;
    bottom: -30px;
    left: 60px;
    &:not(.p-disabled) > svg {
      color: ${({ theme }) => theme.mainBlack};
    }
    > svg {
      width: 24px;
      height: 24px;
      color: ${({ theme }) => theme.neutralColor400};
    }
  }
  .p-carousel .p-carousel-content .p-carousel-next {
    z-index: 10;
    margin: 0;
    position: absolute;
    bottom: -30px;
    right: 60px;
    &:not(.p-disabled) > svg {
      color: ${({ theme }) => theme.mainBlack};
    }
    > svg {
      width: 24px;
      height: 24px;
      color: ${({ theme }) => theme.neutralColor400};
    }
  }
  .p-carousel-items-container {
    align-items: center;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
  }
  .p-carousel-item {
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .p-carousel-item-active {
    flex: 1 0 100%;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: ${({ theme }) => theme.neutralColor900};
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: ${({ theme }) => theme.neutralColor200};
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 38px;
  }
  .p-carousel .p-carousel-indicators {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-bottom: 0;
  }

  .p-dropdown {
    border-radius: 30px;
  }
`

const InformationCard = styled(HURow)`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.primaryColor};
  align-items: center;
`

const RequiredInformationContainer = styled(HUStack)`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.neutralColor100};
  align-items: center;
`

const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.mainWhite};
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-bottom: 10px;
`

const StyledHUNavigateButton = styled(HUNavigateButton)`
  .p-button-label {
    text-decoration: underline;
  }
`
