import { word } from "@modules/core/utils/i18n"
import { usePrograms } from "@modules/programs/hooks/usePrograms"
import { ProgramReductionItem } from "@modules/promoCode/components/programReductionItem"
import { PromoCodeType } from "@modules/promoCode/services/promoCodeDto"
import { HUCheckbox } from "@modules/ui/components/huCheckbox"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { useTheme } from "styled-components"

export const ProgramListSelection: React.FC<{
  selectedProgramIds: string[]
  onSelectProgram: (id: string) => void
  onSelectAllPrograms: (allIds: string[]) => void
  promoCodeValue?: number
  promoCodeType?: PromoCodeType
  promoCodeDuration?: number
  error?: JSX.Element
}> = ({
  selectedProgramIds,
  onSelectProgram,
  onSelectAllPrograms,
  promoCodeType,
  promoCodeValue,
  promoCodeDuration,
  error,
}) => {
  const { result: programs } = usePrograms({ programFilter: "OWNED", onlyWithPrice: true, size: 40 })
  const theme = useTheme()

  return (
    <HUStack>
      <HURow className="align-items-center justify-content-between mb-2">
        <HUText fontStyle="LL">{word("global.filters.selectAll")}</HUText>
        <HUCheckbox
          inputKey={""}
          name="option"
          checked={programs.length === selectedProgramIds.length}
          onChange={() => onSelectAllPrograms(programs.map((program) => program.id))}
          color={theme.primaryColor}
          checkIconColor={theme.neutralColor700}
        />
      </HURow>

      {programs.map((program) => (
        <HURow key={program.id} className="align-items-center justify-content-between">
          <ProgramReductionItem
            key={program.id}
            program={program}
            promoCodeType={promoCodeType}
            promoCodeValue={promoCodeValue}
            promoCodeDuration={promoCodeDuration}
          />
          <HUCheckbox
            inputKey={""}
            name="option"
            checked={!!selectedProgramIds.find((id) => id === program.id)}
            onChange={() => onSelectProgram(program.id)}
            color={theme.primaryColor}
            checkIconColor={theme.neutralColor700}
          />
        </HURow>
      ))}

      {error}
    </HUStack>
  )
}
