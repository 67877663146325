import { HUText } from "@modules/ui/components/huText"
import { word } from "@modules/core/utils/i18n"
import { InputNumber } from "primereact/inputnumber"
import { Dropdown } from "primereact/dropdown"
import { HUButton } from "@modules/ui/components/huButton"
import { CrossIcon } from "@images/svgIcons/crossIcon"
import { CheckmarkIcon } from "@images/svgIcons/checkmarkIcon"
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import styled, { useTheme } from "styled-components"
import { BoxOfferDraft, BoxOfferRule, BoxOfferRulePeriod, BoxOfferType } from "@modules/boxOffer/boxOfferTypes"
import { SimpleFormik } from "@src/typings/formik"
import { BoxOfferInputKey } from "@modules/boxOffer/boxOfferFormikTypes"
import { capitalize } from "@modules/utils/stringUtils"
import { BoxOfferInputCustomLabel, BoxOfferRuleCard } from "@modules/boxOffer/components/boxOfferFormSection"
import { classNames } from "primereact/utils"
import { TrashIcon } from "@images/svgIcons/trashIcon"

type BoxOfferFormRulesInputProps = {
  formik: SimpleFormik<BoxOfferDraft, BoxOfferInputKey>
  setDisabled: Dispatch<SetStateAction<boolean>>
  error?: JSX.Element
}
export const BoxOfferFormRulesInput = ({ formik, error, setDisabled }: BoxOfferFormRulesInputProps) => {
  const [displayNewRuleInput, setDisplayNewRuleInput] = useState(false)
  const [ruleToUpdate, setRuleToUpdate] = useState<BoxOfferRule>()
  const theme = useTheme()
  const rulesDescription = useMemo(() => {
    if (formik.values.type === BoxOfferType.Subscription)
      return word("box.offer.form.infos.restrictedPeriod.SUBSCRIPTION.details")
    if ([BoxOfferType.Trial, BoxOfferType.TicketsBook, BoxOfferType.Millenium].includes(formik.values.type))
      return word("box.offer.form.infos.restrictedPeriod.details")
  }, [formik.values.type])

  return (
    <>
      <BoxOfferInputCustomLabel
        label={word("box.offer.form.infos.restrictedPeriod.title")}
        description={rulesDescription}
        isRequired={[BoxOfferType.TicketsBook, BoxOfferType.Millenium].includes(formik.values.type)}
      />
      <div className="flex flex-wrap gap-2">
        {formik.values.rules &&
          formik.values.rules.length > 0 &&
          formik.values.rules.map((rule, index) => {
            return ruleToUpdate?.period === rule.period ? (
              <CustomRuleInput
                key={index}
                formik={formik}
                onClose={() => setRuleToUpdate(undefined)}
                ruleToUpdate={ruleToUpdate}
                setDisabled={setDisabled}
              />
            ) : (
              <BoxOfferRuleCard key={index} onClick={() => setRuleToUpdate(rule)} $pointer>
                <HUText fontStyle="TS" className="text-center">
                  {`${rule.value} ${word("offer.form.rules.sessions", { count: rule.value })} ${word(`offer.form.rules.period.per.${rule.period}`)}`}
                </HUText>
                <span className="pi pi-pencil text-2xl" />
              </BoxOfferRuleCard>
            )
          })}
        {(!formik.values.rules || formik.values.rules.length < 3) &&
          (!displayNewRuleInput ? (
            <BoxOfferRuleCard onClick={() => setDisplayNewRuleInput(true)} $new $pointer>
              <HUText fontStyle="TS" color={theme.neutralColor600}>
                {word("box.offer.form.infos.restrictedPeriod.addRule")}
              </HUText>
              <span className="pi pi-plus-circle text-2xl" />
            </BoxOfferRuleCard>
          ) : (
            <CustomRuleInput formik={formik} onClose={() => setDisplayNewRuleInput(false)} setDisabled={setDisabled} />
          ))}
      </div>
      {error}
    </>
  )
}

type CustomRuleInputProps = {
  formik: SimpleFormik<BoxOfferDraft, BoxOfferInputKey>
  onClose: () => void
  ruleToUpdate?: BoxOfferRule
  setDisabled: Dispatch<SetStateAction<boolean>>
}
const CustomRuleInput = ({ formik, onClose, ruleToUpdate, setDisabled }: CustomRuleInputProps) => {
  const theme = useTheme()
  const [isInvalid, setIsInvalid] = useState(false)
  const [error, setError] = useState<string>()
  const initialValue = useMemo(() => ({ period: BoxOfferRulePeriod.DAY }), [])
  const [newRule, setNewRule] = useState<BoxOfferRule>(ruleToUpdate ?? initialValue)
  const rulesPeriodOptions = Object.values(BoxOfferRulePeriod).map((period) => ({
    value: period,
    label: capitalize(word(`offer.form.rules.period.per.${period}`)),
  }))

  useEffect(() => {
    setDisabled(true)
  }, [setDisabled])

  const getCurrentRule = useCallback(
    (period: BoxOfferRulePeriod) => formik.values.rules?.find((rule) => rule.period === period),
    [formik.values.rules],
  )

  const isValidNewRule = useCallback(() => {
    if (!newRule.value) return false

    const existingRule = getCurrentRule(newRule.period)
    const dayRule = getCurrentRule(BoxOfferRulePeriod.DAY)
    const weekRule = getCurrentRule(BoxOfferRulePeriod.WEEK)
    const monthRule = getCurrentRule(BoxOfferRulePeriod.MONTH)

    if (existingRule && existingRule.period === newRule.period && newRule.value === existingRule.value) return true

    switch (newRule.period) {
      case BoxOfferRulePeriod.DAY:
        if (weekRule?.value) {
          const maxSessionsPerWeek = weekRule.value * 7
          const maxSessionsPerDay = Math.floor(maxSessionsPerWeek / 7)
          return (!existingRule || newRule.value !== existingRule.value) && newRule.value <= maxSessionsPerDay
        }
        return (
          (!existingRule || newRule.value !== existingRule.value) &&
          (!monthRule || (monthRule.value && newRule.value <= monthRule.value / 30))
        )
      case BoxOfferRulePeriod.WEEK:
        return (
          (!existingRule || newRule.value !== existingRule.value) &&
          (!dayRule || newRule.value <= (dayRule.value ?? 0) * 7) &&
          (!monthRule || (monthRule.value && newRule.value <= monthRule.value / 4))
        )
      case BoxOfferRulePeriod.MONTH:
        return (
          (!existingRule || newRule.value !== existingRule.value) &&
          (!dayRule || newRule.value <= (dayRule.value ?? 0) * 30) &&
          (!weekRule || (weekRule.value && newRule.value <= weekRule.value * 4))
        )
      default:
        return false
    }
  }, [newRule, getCurrentRule])

  const handleClose = useCallback(() => {
    setNewRule(initialValue)
    setError(undefined)
    setDisabled(false)
    onClose()
  }, [initialValue, onClose, setDisabled])

  const removeRules = useCallback(() => {
    if (newRule.value) {
      formik.setFieldValue("rules", [...(formik.values.rules?.filter((rule) => rule.period !== newRule.period) ?? [])])
    }
    handleClose()
  }, [formik, handleClose, newRule.period, newRule.value])

  const updateNewRules = useCallback(() => {
    setIsInvalid(!isValidNewRule())
    if (!isValidNewRule()) {
      setError(word("box.offer.form.infos.restrictedPeriod.error"))
      return
    }

    const filteredRules = formik.values.rules?.some((rule) => rule.period === newRule.period)
      ? formik.values.rules.map((rule) => (rule.period === newRule.period ? newRule : rule))
      : [...(formik.values.rules ?? []), newRule]

    formik.setFieldValue("rules", filteredRules, false).then(() => {
      if (formik.validateField) {
        formik.validateField("rules")
      }
      handleClose()
    })
  }, [formik, handleClose, isValidNewRule, newRule])

  return (
    <BoxOfferRulesContainer>
      <HUText color={theme.neutralColor700} fontStyle="LS" className={classNames({ "p-error": isInvalid })}>
        {word("box.offer.form.infos.restrictedPeriod.inputName").toUpperCase()}
      </HUText>
      <div className="flex gap-2">
        <InputNumber
          min={1}
          max={100}
          placeholder={word("offer.form.numberOfSessions.title")}
          className={`w-full ${isInvalid ? "p-invalid" : ""}`}
          value={newRule.value}
          onChange={(e) =>
            setNewRule((prev) => {
              return {
                ...prev,
                value: e.value ?? undefined,
              }
            })
          }
        />
        <Dropdown
          value={newRule.period}
          className="w-full align-items-center"
          onChange={(e) =>
            setNewRule((prev) => {
              return { ...prev, period: e.target.value }
            })
          }
          options={rulesPeriodOptions}
        />
        <HUButton
          type="Rounded"
          size="M"
          colorType="Quaternary"
          onClick={removeRules}
          icon={{
            iconView: formik.values.rules?.some((rule) => rule.period === newRule.period && newRule.value) ? (
              <TrashIcon color={theme.mainWhite} width={14} height={14} />
            ) : (
              <CrossIcon color={theme.mainWhite} />
            ),
          }}
        />
        <HUButton
          type="Rounded"
          size="M"
          colorType="Primary"
          onClick={() => updateNewRules()}
          icon={{
            iconView: <CheckmarkIcon color={theme.mainWhite} width={14} height={14} />,
          }}
        />
      </div>
      <span className="p-error" style={{ fontSize: "smaller" }}>
        {error}
      </span>
    </BoxOfferRulesContainer>
  )
}

const BoxOfferRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.25rem;
  border-radius: 0.75rem;
  margin-bottom: 1.75rem;
  border: ${({ theme }) => `1px solid ${theme.neutralColor400}`};
`
