import { PlusIcon } from "@images/svgIcons/plusIcon"
import { word } from "@modules/core/utils/i18n"
import { ChronoRecap } from "@modules/exercises/components/chronoRecap"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { getScoreTypesArray } from "@modules/scoreboard/scoreUtils"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch"
import { useEffect, useMemo, useState } from "react"
import styled, { useTheme } from "styled-components"

export const ChronoScoreBloc: React.FC = () => {
  const { navigateToChronoForm } = useSessionSidebar()
  const { formik, updatedExerciseIndex } = useSessionFormContext()
  const theme = useTheme()

  const formikNameBase = `exercises[${updatedExerciseIndex}]`
  const formikValue = updatedExerciseIndex !== null ? formik.values.exercises[updatedExerciseIndex] : null

  const [isScore, setIsScore] = useState(formikValue?.scoreType !== ScoreType.NO_SCORE)
  const scoreTypes = useMemo(() => getScoreTypesArray(), [])

  useEffect(() => {
    setIsScore(formikValue?.scoreType !== ScoreType.NO_SCORE)
  }, [formikValue?.scoreType])

  if (updatedExerciseIndex === null) return <></>

  const addChrono = () => {
    navigateToChronoForm()
  }

  return (
    <>
      <div className={`flex ${formikValue?.chronoType && formikValue.chronoType !== "NONE" ? "flex-column" : ""}`}>
        <div className="flex-1">
          <HUText type="label" color={theme.neutralColor700} fontStyle="LS" style={{ lineHeight: "21px" }}>
            {word("program.planning.create.exercise.addChrono.settings").toUpperCase()}
          </HUText>
          {formikValue?.chronoType && formikValue.chronoType !== "NONE" ? (
            <ChronoRecap />
          ) : (
            <ChronoContainer onClick={addChrono} className="mr-2">
              <PlusIconContainer>
                <PlusIcon color={theme.neutralColor700} />
              </PlusIconContainer>
              <HUText className="my-1" fontStyle="LL">
                {word("program.planning.create.exercise.addChrono.title")}
              </HUText>
              <HUText className="text-center my-1" fontStyle="BS" color={theme.neutralColor600}>
                {word("program.planning.create.exercise.addChrono.description")}
              </HUText>
            </ChronoContainer>
          )}
        </div>
        <div className="flex-1">
          <HUText
            type="label"
            color={theme.neutralColor700}
            fontStyle="LS"
            style={{ lineHeight: "21px", marginBottom: 10 }}
          >
            {word("program.planning.create.exercise.scoreboard").toUpperCase()}
          </HUText>
          <ScoreContainer>
            <div className="flex flex-column ">
              <div className="flex w-full justify-content-between mb-2">
                <HUText className="my-1" fontStyle="LL">
                  {word("program.planning.create.exercise.askScore.titleBloc")}
                </HUText>
                <InputSwitch
                  checked={isScore}
                  onChange={(e: InputSwitchChangeEvent) => {
                    setIsScore(e.value ?? false)
                  }}
                />
              </div>
              {isScore && (
                <FormikInput
                  formikStyle={{ flexGrow: 1 }}
                  getFieldProps={formik.getFieldProps}
                  name={`${formikNameBase}.scoreType`}
                  setFieldValue={formik.setFieldValue}
                  isInvalid={isFormFieldInValid(`${formikNameBase}.scoreType`, formik)}
                  error={getFormErrorMessage(`${formikNameBase}.scoreType`, formik)}
                  type="dropdown"
                  dropdownProps={{
                    value: formikValue?.scoreType,
                    options: scoreTypes,
                  }}
                />
              )}
              <HUText className="my-1" fontStyle="BS" color={theme.neutralColor600}>
                {word("program.planning.create.exercise.askScore.descriptionBloc")}
              </HUText>
            </div>
          </ScoreContainer>
        </div>
      </div>
    </>
  )
}

const PlusIconContainer = styled.div`
  background-color: ${({ theme }) => theme.sessionAddExerciseIconBgColor};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChronoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 160px;
  padding: 14px 20px;
  border-radius: 20px;
  margin-top: 7px;
  background-color: ${({ theme }) => theme.sessionAddExerciseBgColor};
  transition: background-color 1s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.sessionAddExerciseHoverBgColor};
    transition: background-color 1s ease;

    ${PlusIconContainer} {
      transition: background-color 1s ease;
      background-color: ${({ theme }) => theme.sessionAddExerciseIconHoverBgColor};
    }
  }
`
const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 160px;
  padding: 14px 20px;
  border-radius: 20px;
  margin-top: 7px;
  background-color: ${({ theme }) => theme.neutralColor100};
  transition: background-color 1s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.neutralColor200};
    transition: background-color 1s ease;
  }
`
