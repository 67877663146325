import { FetchStrategy } from "@betomorrow/micro-stores"
import { useCurrentBox } from "@modules/box/useBox"
import { useServicesContext } from "@modules/core/services/services.context"
import { useCompositeStore } from "@modules/store"
import { useObservable } from "micro-observables"

export const useTransactionTotals = (period: string) => {
  const { result: boxId } = useCurrentBox()
  const { boxFinanceService, userFinanceService } = useServicesContext()
  const financeService = boxId ? boxFinanceService : userFinanceService
  const refreshingCount = useObservable(financeService.refreshingCount)
  return useCompositeStore([period], financeService.transactionTotalsStore, FetchStrategy.Always, [refreshingCount])
}
