import LogoIncrustation from "@images/logo_incrustation.png"
import { HustleupSmallIcon } from "@images/svgIcons/hustleUpSmallIcon"
import { word } from "@modules/core/utils/i18n"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { ProfileInfoResume } from "@modules/profile/components/profileInfoResume"
import { ScoreboardRanking } from "@modules/scoreboard/scoreboardTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { HUText } from "@modules/ui/components/huText"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useGetDisplayedScore } from "@modules/utils/hooks/useUnit"
import styled, { useTheme } from "styled-components"
type ScoreboardIndivViewProps = { userScore: ScoreboardRanking; isMyRanking: boolean }
export const ScoreboardIndivView: React.FC<ScoreboardIndivViewProps> = ({ userScore, isMyRanking }) => {
  const theme = useTheme()
  const getScore = useGetDisplayedScore()
  const { sidebarNavigateTo } = useSidebar()

  return (
    <>
      {userScore ? (
        <>
          <Card
            $isMyRanking={isMyRanking}
            onClick={() => {
              if (!isMyRanking) {
                sidebarNavigateTo({
                  content: <ProfileInfoResume userId={userScore.userId} />,
                })
              }
            }}
          >
            <ProfileAvatar profile={{ avatar: userScore.avatar, username: userScore.firstname || "" }} size={115} />
            <HUText fontWeight="semibold" color={theme.secondaryColor} fontStyle="LL">
              {userScore.firstname + " " + userScore.lastname}
            </HUText>
            <HUText color={theme.secondaryColor} fontStyle="TL">
              {getScore(userScore.score.value, userScore.scoreType)}
            </HUText>
            {isMyRanking ? (
              <IncrustationLogo src={LogoIncrustation} />
            ) : (
              <HustleupSmallIconStyled color={theme.mainWhite} />
            )}
          </Card>
          {userScore.score.note && (
            <>
              <HUText color={theme.neutralColor600} fontStyle="LS" className="mb-2">
                {word("session.scoreboard.card.comment")}
              </HUText>
              <HUText color={theme.neutralColor900} fontStyle="BM" className="align-items-stretch	">
                {userScore.score.note}
              </HUText>
            </>
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

const Card = styled.div<{ $isMyRanking: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 11px;
  border-radius: 20px;
  padding: 16px 0px;
  background: ${({ theme, $isMyRanking }) =>
    $isMyRanking ? "linear-gradient(87deg, #E4F56C 0.65%, #CEE743 99.23%)" : theme.neutralColor100};
  margin-bottom: 34px;
  &:hover {
    cursor: ${({ $isMyRanking }) => ($isMyRanking ? "default" : "pointer")};
  }
`
const IncrustationLogo = styled.img`
  position: absolute;
  bottom: 2px;
  right: 3px;
  z-index: 1;
`
const HustleupSmallIconStyled = styled(HustleupSmallIcon)`
  position: absolute;
  bottom: 0;
  right: 0px;
  height: 74%;
  width: auto;
  opacity: 0.3;
  z-index: 1;
`
