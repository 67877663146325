import { word } from "@modules/core/utils/i18n"
import { DetailedMemberInputKey, MemberFormikType } from "@modules/members/memberFormikType"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import styled, { useTheme } from "styled-components"

type Props = {
  isFormFieldInValid: (name: DetailedMemberInputKey) => boolean
  getFormErrorMessage: (name: DetailedMemberInputKey, errorMessage?: string) => JSX.Element | undefined
  formik: SimpleFormik<MemberFormikType, DetailedMemberInputKey>
}

export const MemberInfoForm: React.FC<Props> = ({ isFormFieldInValid, getFormErrorMessage, formik }) => {
  const theme = useTheme()

  return (
    <>
      <CardContainer className="card">
        <HUStack gap={4}>
          <HUText fontStyle="HS">{word("member.form.title.personal_infos")}</HUText>
          <HUText fontStyle="BM" color={theme.neutralColor600}>
            {word("member.form.subtitle.personal_infos")}
          </HUText>
        </HUStack>
        <HURow gap={24}>
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.firstname")}
            isRequiredInput
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("firstname")}
            name="firstname"
            error={getFormErrorMessage("firstname")}
          />
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.lastname")}
            isRequiredInput
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("lastname")}
            name="lastname"
            error={getFormErrorMessage("lastname")}
          />
        </HURow>
        <HURow gap={24}>
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.username")}
            isRequiredInput
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("username")}
            name="username"
            disabled
            error={getFormErrorMessage("username")}
          />
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.birthdate")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("birthDate")}
            name="birthDate"
            error={getFormErrorMessage("birthDate")}
            type="calendar"
            calendarProps={{
              showIcon: true,
            }}
          />
        </HURow>
        <HUStack gap={2}>
          <HUText fontStyle="TM">{word("member.form.title.contact")}</HUText>
          <HUText fontStyle="BM" color={theme.neutralColor600}>
            {word("member.form.subtitle.contact")}
          </HUText>
        </HUStack>
        <HURow gap={24}>
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.email")}
            isRequiredInput
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("email")}
            name="email"
            disabled
            error={getFormErrorMessage("email")}
          />
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.phone.label")}
            isInvalid={isFormFieldInValid("phoneNumber")}
            getFieldProps={formik.getFieldProps}
            name="phoneNumber"
            error={getFormErrorMessage("phoneNumber")}
          />
        </HURow>
        <HUStack gap={2}>
          <HUText fontStyle="TM">{word("member.form.title.address")}</HUText>
          <HUText fontStyle="BM" color={theme.neutralColor600}>
            {word("member.form.subtitle.address")}
          </HUText>
        </HUStack>
        <HURow gap={24}>
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.address.address")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("address")}
            name="address"
            error={getFormErrorMessage("address")}
          />
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.address.additional_address")}
            isInvalid={isFormFieldInValid("additionalAddress")}
            getFieldProps={formik.getFieldProps}
            name="additionalAddress"
            error={getFormErrorMessage("additionalAddress")}
          />
        </HURow>
        <HURow gap={24}>
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.address.postalCode")}
            isInvalid={isFormFieldInValid("postalCode")}
            getFieldProps={formik.getFieldProps}
            name="postalCode"
            error={getFormErrorMessage("postalCode")}
          />
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.address.city")}
            isInvalid={isFormFieldInValid("city")}
            getFieldProps={formik.getFieldProps}
            name="city"
            error={getFormErrorMessage("city")}
          />
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("member.form.label.address.country")}
            isInvalid={isFormFieldInValid("country")}
            getFieldProps={formik.getFieldProps}
            name="country"
            error={getFormErrorMessage("country")}
          />
        </HURow>
      </CardContainer>
      <CardContainer className="card">
        <HUStack gap={4}>
          <HUText fontStyle="HS">{word("member.form.title.personToCallEmergency")}</HUText>
          <HUText fontStyle="BM" color={theme.neutralColor600}>
            {word("member.form.subtitle.personToCallEmergency")}
          </HUText>
        </HUStack>
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1 }}
          type="textarea"
          label={word("member.form.label.personToCallEmergency")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("emergencyInformation")}
          name="emergencyInformation"
          error={getFormErrorMessage("emergencyInformation")}
        />
      </CardContainer>
      <CardContainer className="card">
        <HUStack gap={4}>
          <HUText fontStyle="HS">{word("member.form.title.note")}</HUText>
          <HUText fontStyle="BM" color={theme.neutralColor600}>
            {word("member.form.subtitle.note")}
          </HUText>
        </HUStack>
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1 }}
          type="textarea"
          placeHolder={word("member.form.label.note")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("note")}
          name="note"
          error={getFormErrorMessage("note")}
        />
      </CardContainer>
    </>
  )
}

const CardContainer = styled(HUStack)`
  gap: 21px;
`
