import { SessionFormContext } from "@modules/sessions/context/sessionFormContext"
import { useContext } from "react"

export const useSessionFormContext = () => {
  const {
    formik,
    isSubmitting,
    program,
    updatedExerciseIndex,
    initialUpdatedExercise,
    isEditingExercise,
    editedSlot,
    setEditedSession,
    setEditedSlot,
    setDateSession,
    setProgram,
    setUpdatedExerciseIndex,
    setInitialUpdatedExercise,
    setIsEditingExercise,
    onHideSessionForm,
    onHideExerciseForm,
    onHideChronoForm,
  } = useContext(SessionFormContext)

  return {
    formik,
    isSubmitting,
    program,
    updatedExerciseIndex,
    initialUpdatedExercise,
    isEditingExercise,
    editedSlot,
    setEditedSession,
    setEditedSlot,
    setDateSession,
    setProgram,
    setUpdatedExerciseIndex,
    setInitialUpdatedExercise,
    setIsEditingExercise,
    onHideSessionForm,
    onHideExerciseForm,
    onHideChronoForm,
  }
}
