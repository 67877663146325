import { BoxSlotTypeOf } from "@modules/boxSlot/boxSlotTypes"
import { convertProfileFromDto } from "@modules/profile/profileDtos"
import { Profile } from "@modules/profile/profileTypes"
import { ParticipantStatus } from "@modules/profile/subscriber/subscriberTypes"
import {
  ProgramInfiniteSessionTypeOf,
  ProgramOnOffSessionTypeOf,
  SessionTypeOf,
} from "@modules/programSession/programSessionTypes"
import { ProgramType, ProgramVisibility } from "@modules/programs/programTypes"
import { TrainingCalendarEventType } from "@modules/training/components/trainingCalendarEvent"
import { TrainingItemBoxSlotDto, TrainingItemSessionDto } from "@modules/training/services/trainingDto"
import { DefaultTheme } from "styled-components"

export const TrainingContentTypes = [SessionTypeOf, BoxSlotTypeOf] as const
export type TrainingContentType = (typeof TrainingContentTypes)[number]

export const AvailableTrainingContentTypes: TrainingContentType[] = [SessionTypeOf, BoxSlotTypeOf]

export type TrainingProgramSession = {
  id: string
  _type: typeof ProgramInfiniteSessionTypeOf | typeof ProgramOnOffSessionTypeOf
  name: string
  programId: string
  programName: string
  date: string
  programVisibility: ProgramVisibility
  hideSessionContent: boolean
  cardioTag?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  author: Profile
  index?: number
  restDay?: boolean
  virtualProgramByDateIndex?: number
}

export type TrainingBoxSlot = {
  id: string
  _type: typeof BoxSlotTypeOf
  programmationId: string
  programmationName: string
  startDate: string
  endDate: string
  participantCount: number
  participantLimit?: number
  sessionId?: string
  participantStatus: ParticipantStatus
  author: Profile
  boxName: string
}

export type GlobalTrainingContent = {
  id: string
  programSession?: TrainingProgramSession
  boxSlot?: TrainingBoxSlot
}

export type TrainingProgramSessionContent = {
  id: string
  type: typeof ProgramInfiniteSessionTypeOf | typeof ProgramOnOffSessionTypeOf
  programSession: TrainingProgramSession
  boxSlot: undefined
} & GlobalTrainingContent

export type TrainingBoxSlotContent = {
  id: string
  type: typeof BoxSlotTypeOf
  programSession: undefined
  boxSlot: TrainingBoxSlot
} & GlobalTrainingContent

export type TrainingContent = TrainingProgramSessionContent | TrainingBoxSlotContent

export type TrainingContentItem = TrainingProgramSession | TrainingBoxSlot

export function convertToTrainingContent(content?: TrainingContentItem): TrainingContent | undefined {
  switch (content?._type) {
    case ProgramInfiniteSessionTypeOf:
      return {
        id: content.id,
        type: content._type,
        programSession: content,
        boxSlot: undefined,
      }
    case ProgramOnOffSessionTypeOf:
      return {
        id: content.id,
        type: content._type,
        programSession: content,
        boxSlot: undefined,
      }
    case BoxSlotTypeOf:
      return {
        id: content.id,
        type: content._type,
        programSession: undefined,
        boxSlot: content,
      }
    default:
      return undefined
  }
}

export function convertTrainingItemProgramSessionFromDto(dto: TrainingItemSessionDto): TrainingContentItem {
  return {
    _type: dto.programType === ProgramType.VIRTUAL ? ProgramInfiniteSessionTypeOf : ProgramOnOffSessionTypeOf,
    id: dto.id,
    name: dto.name,
    programId: dto.programId,
    programName: dto.programName,
    hideSessionContent: dto.hideSessionContent,
    date: dto.date,
    programVisibility: dto.programVisibility,
    cardioTag: dto.cardioTag,
    gymnasticTag: dto.gymnasticTag,
    weightliftingTag: dto.weightliftingTag,
    author: convertProfileFromDto(dto.owner),
    index: dto.index,
    restDay: dto.restDay,
  }
}

export function convertTrainingItemBoxSlotFromDto(dto: TrainingItemBoxSlotDto): TrainingBoxSlot {
  return {
    id: dto.id,
    _type: BoxSlotTypeOf,
    programmationId: dto.program.id,
    programmationName: dto.program.name,
    startDate: dto.startDate,
    endDate: dto.endDate,
    sessionId: dto.id,
    participantCount: dto.participantCount,
    participantStatus: dto.participantStatus.toLowerCase() as ParticipantStatus,
    participantLimit: dto.participantLimit,
    author: convertProfileFromDto(dto.owner),
    boxName: dto.box.name,
  }
}

export const convertItemTrainingSessionsToEvent = (
  trainingSessions: TrainingContent[],
  userId: string,
  theme: DefaultTheme,
): TrainingCalendarEventType[] => {
  return trainingSessions.map((session) => {
    const isProgramUser =
      (session.type === BoxSlotTypeOf && session.boxSlot.author.id === userId) ||
      session.programSession?.author.id === userId

    return {
      id: session.id,
      sessionType: session.type,
      title: session.type === BoxSlotTypeOf ? session.boxSlot.programmationName : session.programSession.name,
      date: session.programSession?.date ?? session.boxSlot?.startDate ?? "",
      programId: session.programSession?.programId ?? "",
      programName:
        session.type === BoxSlotTypeOf ? session.boxSlot.programmationName : session.programSession.programName,
      color: theme.mainWhite,
      textColor: theme.neutralColor900,
      editable: isProgramUser,
      virtualProgramByDateIndex: session.programSession?.virtualProgramByDateIndex,
      author: session.type === BoxSlotTypeOf ? session.boxSlot.author : session.programSession.author,
      boxName: session.boxSlot?.boxName,
      participantCount: session.boxSlot?.participantCount,
      participantStatus: session.boxSlot?.participantStatus,
      participantLimit: session.boxSlot?.participantLimit,
      isAuthor: isProgramUser,
      programVisibility: session.programSession?.programVisibility,
      hideSessionContent: session.programSession?.hideSessionContent || false,
    }
  })
}
