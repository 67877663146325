import { ExerciseDashboard } from "@modules/exercises/exerciseTypes"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import React, { useEffect, useState } from "react"
import styled, { useTheme } from "styled-components"

export const ChallengeProgression: React.FC<{ challengeResults: ExerciseDashboard; maxCal: number }> = ({
  challengeResults,
  maxCal,
}) => {
  const theme = useTheme()
  const [currentResult, setCurrentResult] = useState<number>(challengeResults.totalScore)

  useEffect(() => {
    if (currentResult !== challengeResults.totalScore) {
      setTimeout(() => {
        setCurrentResult(challengeResults.totalScore)
      }, 1000)
    }
  }, [challengeResults.totalScore])

  const resultPercentage = (currentResult * 100) / maxCal // percentage

  const captions = [20, 40, 60, 80, 100] // percentages;

  return (
    <HURow style={{ width: 250, marginTop: 80 }} gap={15}>
      <ProgressionGauge>
        <Progression $height={resultPercentage} $isFull={resultPercentage >= 100} />
      </ProgressionGauge>

      <Captions>
        {captions.map((caption, index) => (
          <Caption key={index} $value={caption}>
            <HUText fontStyle="HL" color={caption <= resultPercentage ? theme.mainWhite : theme.neutralColor}>
              {`${Math.floor((caption * maxCal) / 100)} cal`}
            </HUText>
            <Indicator $isCompleted={caption <= resultPercentage} />
          </Caption>
        ))}
      </Captions>
    </HURow>
  )
}

const ProgressionGauge = styled.div`
  background-color: ${({ theme }) => theme.neutralColor700};
  border-radius: 40px;
  width: 50px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
`

const Progression = styled.div<{ $height: number; $isFull: boolean }>`
  height: calc(${({ $height }) => `${$height}%`});
  width: 100%;
  background: linear-gradient(179deg, #ddf247 11.04%, #b0d717 97.38%);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  transition: height 1s ease;
  overflow: hidden;
  position: relative;
  clip-path: ${({ $isFull }) => ($isFull ? "none" : "polygon(50px 0px, 100% 0, 100% 100%, 0 100%, 0 50px)")};
`

const Captions = styled(HURow)`
  height: 100%;
  position: relative;
  width: 160px;
`

const Caption = styled.div<{ $value: number }>`
  position: absolute;
  top: calc(100% - ${({ $value }) => $value}% - 45px);
`

const Indicator = styled.div<{ $isCompleted: boolean }>`
  background-color: ${({ theme, $isCompleted }) => ($isCompleted ? "#ddf247" : theme.neutralColor)};
  width: 45px;
  height: 10px;
  border-radius: 15px;
`
