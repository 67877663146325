import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { MemberBoxOffersView } from "@modules/members/components/memberBoxOffersView"
import { MemberInfoForm } from "@modules/members/components/memberInfoForm"
import { MemberFormikType, convertMemberToFormik, getValidationSchema } from "@modules/members/memberFormikType"
import { MemberDetailed, convertMemberFromFormik, convertMemberToDraft } from "@modules/members/memberTypes"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { useToast } from "@modules/ui/components/huToast"
import { HURow } from "@modules/ui/components/layout"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useDraft } from "@modules/utils/useDraft"
import { useFormik } from "formik"
import { Button } from "primereact/button"
import styled from "styled-components"

type Props = {
  member: MemberDetailed
}

export const MemberForm: React.FC<Props> = ({ member }) => {
  const toast = useToast()
  const boxNavigate = useBoxNavigate()
  const { memberService } = useServicesContext()
  const { draft: memberDraft } = useDraft(() => convertMemberToDraft(member))

  const formik = useFormik<MemberFormikType>({
    initialValues: convertMemberToFormik(memberDraft),
    validationSchema: getValidationSchema(),
    onSubmit: async () => {
      try {
        await memberService.updateMember(member.id, { ...convertMemberFromFormik(formik.values) })
        toast.show({
          severity: "success",
          summary: word("member.editMember.success"),
        })
        boxNavigate(Path.Box.Members)
      } catch (e: any) {
        console.error(LoggerType.Box + "update member", e)
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      }
    },
  })

  return (
    <div>
      <FormContainer className="flex-column sm:flex-row">
        <div className="flex flex-1 flex-column">
          <MemberInfoForm
            isFormFieldInValid={(input) => isFormFieldInValid(input, formik)}
            getFormErrorMessage={(input, errorMessage) => getFormErrorMessage(input, formik, { errorMessage })}
            formik={{
              values: formik.values,
              getFieldProps: formik.getFieldProps,
              setFieldValue: formik.setFieldValue,
            }}
          />
          <MemberBoxOffersView id={member.id} />
        </div>
      </FormContainer>
      <HURow className="flex-column justify-content-end sm:flex sm:flex-row" gap={10}>
        <Button
          rounded
          label={word("global.cancel")}
          className="p-button-outlined p-button-secondary"
          onClick={() => boxNavigate(Path.Box.Members)}
          type="button"
        />
        <Button
          rounded
          label={word("global.save")}
          className="p-button-primary"
          onClick={() => formik.submitForm()}
          type="button"
        />
      </HURow>
    </div>
  )
}

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`
