import { Page } from "@betomorrow/micro-stores"
import { ActivityContentType } from "@modules/activity/activityType"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { ConfigService } from "@modules/core/services/configService"
import {
  AvailableExploreContentTypes,
  ExploreContent,
  ExploreContentType,
  ExploreHistoryContent,
} from "@modules/explore/exploreTypes"
import {
  ExploreContentDto,
  ExploreHistoryDto,
  convertExploreContentFromDto,
  convertExploreHistoryElementFromDto,
  convertExploreHistoryFromDto,
} from "@modules/explore/services/exploreDto"
import { Paginated, toPage } from "@modules/utils/paginated"

import _ from "lodash"

export class ExploreApi {
  private baseUrl = "/explore"
  private buildExploreUrl = () => `${ConfigService.config.BASE_URL.replace("/web", "")}/mobile${this.baseUrl}`
  constructor(private api: ApiClient) {}

  async searchExplore(
    query: string | undefined,
    options?: {
      filter?: ExploreContentType
      page?: number
      size?: number
    },
  ): Promise<Page<ExploreContent>> {
    const result = await this.api.get<Paginated<ExploreContentDto>>(`${this.baseUrl}`, {
      params: {
        query,
        page: options?.page ?? 0,
        size: options?.size ?? 24,
        filter: options?.filter,
      },
    })
    const pageResult = toPage(result.data)

    return {
      ...pageResult,
      content: _.compact(
        pageResult.content
          .filter((dto) => AvailableExploreContentTypes.includes(dto.contentType))
          .map((content) => convertExploreContentFromDto(content)),
      ),
    }
  }

  async getExploreHistory(): Promise<ExploreHistoryContent[]> {
    const result = await this.api.get<ExploreHistoryDto[]>(`${this.buildExploreUrl()}/recents`)
    return convertExploreHistoryFromDto(result.data)
  }

  async removeExploreHistory(id: string) {
    return this.api.delete(`${this.buildExploreUrl}/recents/${id}`, {})
  }

  async addExploreHistory(id: string, activityType: ActivityContentType): Promise<ExploreHistoryContent | undefined> {
    const resultDto = await this.api.post<ExploreHistoryDto>(`${this.buildExploreUrl()}/recents`, {
      activityId: id,
      activityType,
    })
    return convertExploreHistoryElementFromDto(resultDto.data)
  }
}
