import { PriceDto } from "@modules/boxOffer/services/boxOfferDto"
import { Currency } from "@modules/currency/currency"
import { HUImageDto, convertImageFromDto } from "@modules/dtos/imageDto"
import { ProfileDto, convertProfileFromDto } from "@modules/profile/profileDtos"
import {
  ItemProgram,
  LightProgram,
  Program,
  ProgramPayment,
  ProgramPrice,
  ProgramType,
  ProgramVisibility,
} from "@modules/programs/programTypes"
import {
  ItemProgramInfiniteDto,
  ProgramInfiniteDto,
  ProgramInfiniteProfileDto,
  convertItemProgramInfiniteFromDto,
} from "@modules/programs/services/programInfiniteDto"
import {
  ItemProgramOnOffDto,
  ProgramOnOffDto,
  ProgramOnOffProfileDto,
  convertItemProgramOnOffFromDto,
} from "@modules/programs/services/programOnOffDto"
import { PromoCodeDto, convertPromoCodeFromDto } from "@modules/promoCode/services/promoCodeDto"

export type ItemProgramDto = ItemProgramInfiniteDto | ItemProgramOnOffDto

export type ProgramProfileDto = ProgramInfiniteProfileDto | ProgramOnOffProfileDto

export type ProgramDto = ProgramInfiniteDto | ProgramOnOffDto

export type LightProgramInfoDto = {
  id: string
  name: string
  image: HUImageDto
  price?: PriceDto
  type: string
}

export type LightProgramDto = {
  id: string
  name: string
  descripition: string
  image: HUImageDto
  owner: ProfileDto
  visibility: ProgramVisibility
  scoreboardVisibility: boolean
  type: ProgramType
}

export function convertItemProgramFromDto(dto: ItemProgramDto): ItemProgram {
  if (dto.type === ProgramType.VIRTUAL) {
    return convertItemProgramInfiniteFromDto(dto)
  } else return convertItemProgramOnOffFromDto(dto as ItemProgramOnOffDto)
}

export function convertProgramFromDto(dto: ProgramDto): Program {
  return {
    ...convertItemProgramFromDto(dto),
    description: dto.description ?? dto.description,
  }
}

export type ProgramPriceDto = {
  currency: string
  value: number
}

export type ProgramPaymentDto = {
  amount: ProgramPriceDto
  promoCode: PromoCodeDto
}

export function convertProgramPriceFromDto(dto: ProgramPriceDto | undefined): ProgramPrice | undefined {
  return dto?.currency && dto.value
    ? {
        currency: dto.currency as Currency,
        value: dto.value,
      }
    : undefined
}

export function convertProgramPaymentFromDto(dto: ProgramPaymentDto | undefined): ProgramPayment | undefined {
  return dto?.amount
    ? {
        amount: {
          currency: dto.amount.currency as Currency,
          value: dto.amount.value,
        },
        promoCode: dto.promoCode ? convertPromoCodeFromDto(dto.promoCode) : undefined,
      }
    : undefined
}

export function convertLightProgramFromDto(dto: LightProgramDto): LightProgram {
  return {
    ...dto,
    image: convertImageFromDto(dto.image),
    owner: convertProfileFromDto(dto.owner),
  }
}
