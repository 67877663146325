import {
  BoxProgrammationDefaultSettingsFormikType,
  convertBoxProgrammationDefaultSettingsToFormik,
  getDefaultSettingsValidationSchema,
} from "@modules/boxProgrammation/boxProgrammationFormikTypes"
import { BoxProgrammationDefaultSettings } from "@modules/boxProgrammation/boxProgrammationTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { useFormik } from "formik"

export const useBoxProgrammationDefaultSettingsFormik = (defaultSettings?: BoxProgrammationDefaultSettings) => {
  const { localStorageService } = useServicesContext()
  const previousDefaultSettings = localStorageService.load(LocalStorageKeys.boxProgram.defaultSettings, {})

  const formik = useFormik<BoxProgrammationDefaultSettingsFormikType>({
    initialValues: convertBoxProgrammationDefaultSettingsToFormik(defaultSettings ?? previousDefaultSettings),
    validationSchema: getDefaultSettingsValidationSchema(),
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: async () => {},
  })

  return {
    formik,
  }
}
