import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PhoneIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 13.3398V11.9682C15 11.2867 14.5851 10.6738 13.9523 10.4207L12.2572 9.74268C11.4524 9.42076 10.5352 9.76949 10.1475 10.5448L10 10.8398C10 10.8398 7.91667 10.4231 6.25 8.75647C4.58333 7.0898 4.16667 5.00647 4.16667 5.00647L4.46168 4.85896C5.23698 4.47131 5.58571 3.55408 5.26379 2.74927L4.58574 1.05415C4.33263 0.421388 3.71979 0.00646973 3.03828 0.00646973H1.66667C0.746192 0.00646973 0 0.752662 0 1.67314C0 9.03693 5.96954 15.0065 13.3333 15.0065C14.2538 15.0065 15 14.2603 15 13.3398Z"
      fill={props.color}
    />
  </svg>
)
