import { useCurrentBox } from "@modules/box/useBox"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { BoxTransactionsTable } from "@modules/finances/components/boxTransactionsTable"
import { defaultMonthValue } from "@modules/finances/components/defaultMonth"
import { FinanceCard } from "@modules/finances/components/financeCard"
import { LastUpdateComponent } from "@modules/finances/components/lastUpdateInformation"
import { StripeStatusBanner } from "@modules/finances/components/stripeStatusBanner"
import { usePeriodParam } from "@modules/finances/hooks/usePeriod"
import { useAsync } from "@modules/hooks/useAsync"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { ScreenHeader } from "@modules/ui/components/header"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { delay } from "@modules/utils/utils"
import { useObservable } from "micro-observables"
import React from "react"

export const BoxFinancesPage: React.FC = () => {
  const { boxFinanceService } = useServicesContext()
  const language = useLanguage()

  const [period] = usePeriodParam()
  const { result: box } = useCurrentBox()
  const lastUpdateLoading = useObservable(boxFinanceService.lastUpdateLoading)
  const isStripeAccountComplete = box && box.stripeAccountStatus === "OK"

  const {
    value: stats,
    loading,
    refreshing,
    refresh,
  } = useAsync(async () => {
    if (isStripeAccountComplete) {
      const [result] = await Promise.all([boxFinanceService.getStats(period ?? defaultMonthValue), delay(1000)])
      return result
    }
  }, [period])

  const cardLoading = lastUpdateLoading || loading

  if (!isStripeAccountComplete)
    return (
      <div className="menu.finances w-full">
        <ScreenHeader title={word("menu.finances")} />
        <HUStack gap={28}>
          <StripeStatusBanner />
        </HUStack>
      </div>
    )
  else
    return (
      <div className="menu.finances flex flex-column flex-grow-1 w-full">
        <ScreenHeader title={word("menu.finances")}>
          <LastUpdateComponent refreshing={refreshing} onRefresh={refresh} financeService={boxFinanceService} />
        </ScreenHeader>
        <HUStack gap={28} className="flex-grow-1">
          <HURow gap={28}>
            <FinanceCard
              cardType="Primary"
              cardTitle={word("finances.page.card.ca.label")}
              value={
                stats ? formatCurrency(stats.totalTransactionsValue, stats.totalTransactionsCurrency, language) : "--"
              }
              loading={cardLoading}
            />
            <FinanceCard
              cardType="Secondary"
              cardTitle={word("finances.page.card.clients.label")}
              value={(stats?.activeMembersNumber ?? "--").toString()}
              loading={cardLoading}
            />
          </HURow>
          <BoxTransactionsTable />
        </HUStack>
      </div>
    )
}
