import { HustleupIcon } from "@images/hustleupIcon"
import { LogoHustleUpSmall } from "@images/logoHustleUpSmall"
import { LayoutContext } from "@modules/app/context/layoutcontext"
import React, { useContext } from "react"
import styled, { useTheme } from "styled-components"

export const AppMenuIcon: React.FC = () => {
  const { layoutState } = useContext(LayoutContext)
  const theme = useTheme()

  return (
    <div className={`sb-overflow-hidden logo-icon`}>
      <IconContainerGlobal>
        <IconContainerSmall $visible={layoutState.staticMenuDesktopInactive}>
          <LogoHustleUpSmall color={theme.textPrimary} />
        </IconContainerSmall>
        <IconContainer $visible={!layoutState.staticMenuDesktopInactive}>
          <HustleupIcon color={theme.textPrimary} />
          {/* <HustleupXcrossfitIcon /> */}
        </IconContainer>
      </IconContainerGlobal>
    </div>
  )
}
const IconContainerGlobal = styled.div`
  position: relative;
`

const IconContainerSmall = styled.div<{ $visible?: boolean }>`
  flex-direction: column;
  position: absolute;
  left: 75px;
  transition: opacity 1s ease;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
`

const IconContainer = styled.div<{ $visible?: boolean }>`
  transition: opacity 1s ease;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
`
