import { CardioIcon } from "@images/svgIcons/cardioIcon"
import { GymIcon } from "@images/svgIcons/gymIcon"
import { WeightliftingIcon } from "@images/svgIcons/weightliftingIcon"
import { word } from "@modules/core/utils/i18n"
import { AddExerciceBloc } from "@modules/exercises/components/addExerciceBloc"
import { ExerciseExistingBloc } from "@modules/exercises/components/exerciseExistingBloc"
import { GetNewExerciseDraft } from "@modules/exercises/exerciseUtils"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { usePrograms } from "@modules/programs/hooks/usePrograms"
import { ProgramOnOff } from "@modules/programs/programOnOffTypes"
import { ProgramOnOffSessionFormikType, ProgramSessionInputKey } from "@modules/programSession/programSessionFormikType"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import { InputSwitchChangeEvent } from "primereact/inputswitch"
import { useMemo } from "react"

type ProgramOnOffSessionForm = {
  formik: SimpleFormik<ProgramOnOffSessionFormikType, ProgramSessionInputKey>
  program: ProgramOnOff
}

export const ProgramOnOffSessionForm: React.FC<ProgramOnOffSessionForm> = ({ formik, program }) => {
  const { navigateToExerciseForm } = useSessionSidebar()
  const { result: ownedPrograms } = usePrograms({ programFilter: "OWNED" })

  const addExercise = () => {
    const updatedExercises = [...formik.values.exercises, GetNewExerciseDraft()]
    formik.setFieldValue("exercises", updatedExercises)
    navigateToExerciseForm(true, updatedExercises.length - 1)
  }

  const onRestDaySwitchChange = (event: InputSwitchChangeEvent) => {
    formik.setFieldValue("name", word("program.session.name.default"))
    formik.setFieldValue("description", "")
    formik.setFieldValue("exercises", [])
    formik.setFieldValue("hideSessionContent", false)
    formik.setFieldValue("gymnasticTag", false)
    formik.setFieldValue("weightliftingTag", false)
    formik.setFieldValue("cardioTag", false)
    return formik.setFieldValue("restDay", event.value)
  }

  const dropdownItemsPrograms = useMemo(
    () =>
      ownedPrograms.map((program) => {
        return { value: program.id, label: program.title }
      }),
    [ownedPrograms],
  )

  return (
    <HUStack>
      <FormikInput
        type="switchInline"
        textSwitch={word("program.onOff.rest.label")}
        label={word("program.onOff.rest.title")}
        description={word("program.onOff.rest.description")}
        name="restDay"
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("restDay", formik)}
        error={getFormErrorMessage("restDay", formik)}
        setFieldValue={formik.setFieldValue}
        switchProps={{
          checked: formik.values.restDay,
          onChange: onRestDaySwitchChange,
        }}
      />
      <FormikInput
        flex
        isRequiredInput
        disabled={true}
        formikStyle={{ display: "flex", flexGrow: 1 }}
        label={word("program.onOff.dayIndex.title")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("dayIndex", formik)}
        error={getFormErrorMessage("dayIndex", formik)}
        setFieldValue={formik.setFieldValue}
        name="dayIndex"
        type="number"
        numberProps={{
          placeholder: word("program.onOff.dayIndex.placeholder"),
          min: 0,
          max: (program && "duration" in program && program.duration) || 365,
          showButtons: true,
        }}
      />
      <FormikInput
        flex
        formikStyle={{ flexGrow: 1 }}
        isRequiredInput
        disabled={true}
        label={word("sessionForm.selectProgram.title")}
        placeHolder={word("sessionForm.selectProgram.placeholder")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("programId", formik)}
        name="programId"
        error={getFormErrorMessage("programId", formik)}
        setFieldValue={formik.setFieldValue}
        type="dropdown"
        dropdownProps={{
          value: formik.getFieldProps("programId").value,
          options: dropdownItemsPrograms,
        }}
      />

      <div className="flex flex-column sm:flex-row sm:gap-3">
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1, borderRadius: 30 }}
          isRequiredInput
          label={word("sessionForm.name.title")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("name", formik)}
          error={getFormErrorMessage("name", formik)}
          name="name"
        />
      </div>
      <div className="flex justify-content-evenly">
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          type="checkbox"
          name="gymnasticTag"
          textLabel={word("program.planning.create.gymnastique")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("gymnasticTag", formik)}
          error={getFormErrorMessage("gymnasticTag", formik)}
          setFieldValue={formik.setFieldValue}
          iconTag={<GymIcon color={"black"} className="mb-2" />}
        />
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          type="checkbox"
          name="weightliftingTag"
          textLabel={word("program.planning.create.weightlifting")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("weightliftingTag", formik)}
          error={getFormErrorMessage("weightliftingTag", formik)}
          setFieldValue={formik.setFieldValue}
          iconTag={<WeightliftingIcon color={"black"} className="mb-2" />}
        />
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          type="checkbox"
          name="cardioTag"
          textLabel={word("program.planning.create.cardio")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("cardioTag", formik)}
          error={getFormErrorMessage("cardioTag", formik)}
          setFieldValue={formik.setFieldValue}
          iconTag={<CardioIcon color={"black"} className="mb-2" />}
        />
      </div>

      {formik.values.exercises.map((exercise, i) => (
        <ExerciseExistingBloc
          exercise={exercise}
          key={i}
          onUpdateExercise={() => {
            navigateToExerciseForm(false, i, exercise)
          }}
          formik={formik}
          exerciseIndex={i}
        />
      ))}
      <AddExerciceBloc onAddExercise={addExercise} />

      <FormikInput
        flex
        formikStyle={{ display: "flex", flexGrow: 1, borderRadius: 20 }}
        label={word("sessionForm.coachNote.title")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("description", formik)}
        error={getFormErrorMessage("description", formik)}
        name="description"
        type="textarea"
        textareaProps={{ autoResize: true }}
      />
    </HUStack>
  )
}
