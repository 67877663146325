import { ApiClient } from "@modules/core/api/client/apiClient"
import {
  ItemProgramInfiniteSession,
  ProgramInfiniteSession,
  ProgramInfiniteSessionDraft,
} from "@modules/programSession/programSessionTypes"
import {
  ItemProgramInfiniteSessionDto,
  PlanningSessionDto,
  ProgramInfiniteSessionDto,
  convertItemProgramInfiniteSessionFromDto,
  convertProgramInfiniteSessionDraftToDto,
  convertProgramInfiniteSessionFromDto,
} from "@modules/programSession/services/programSessionDto"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"

export class ProgramInfiniteSessionApi {
  private sessionBaseUrl = "/sessions"

  constructor(private api: ApiClient) {}

  async getDetailedSession(id: string): Promise<ProgramInfiniteSession> {
    const resultDto = await this.api.get<ProgramInfiniteSessionDto>(`${this.sessionBaseUrl}/${id}`)
    return convertProgramInfiniteSessionFromDto(resultDto.data)
  }

  async getSessions(startDate: string, endDate: string, programId: string): Promise<ItemProgramInfiniteSession[]> {
    const result = await this.api.get<PlanningSessionDto>(`${this.sessionBaseUrl}`, {
      params: {
        startDate: dayjs(startDate).format(defaultDateFormat),
        endDate: dayjs(endDate).format(defaultDateFormat),
        programId,
      },
    })

    return result.data.sessions.map((sessionDto) =>
      convertItemProgramInfiniteSessionFromDto(sessionDto as ItemProgramInfiniteSessionDto),
    )
  }

  async createSession(sessionDraft: ProgramInfiniteSessionDraft) {
    const resultDto = await this.api.post<ProgramInfiniteSessionDto>(
      `${this.sessionBaseUrl}`,
      convertProgramInfiniteSessionDraftToDto(sessionDraft),
    )
    return convertProgramInfiniteSessionFromDto(resultDto.data)
  }

  async updateSession(id: string, sessionDraft: ProgramInfiniteSessionDraft) {
    const resultDto = await this.api.put<ProgramInfiniteSessionDto>(
      `${this.sessionBaseUrl}/${id}`,
      convertProgramInfiniteSessionDraftToDto(sessionDraft),
    )
    return convertProgramInfiniteSessionFromDto(resultDto.data)
  }

  async deleteSession(id: string) {
    await this.api.delete(`${this.sessionBaseUrl}/${id}`)
  }

  async changeSessionIndex(id: string, position: number) {
    await this.api.put(`${this.sessionBaseUrl}/${id}/reorder`, {
      sessionNewIndex: position,
    })
  }
}
