import { FetchStrategy } from "@betomorrow/micro-stores"
import { WarningHexagonalIcon } from "@images/svgIcons/warningIcon"
import { word } from "@modules/core/utils/i18n"
import { ProgramPriceCard } from "@modules/programs/components/programPriceCard"
import { ProgramSharedCoachForm } from "@modules/programs/components/programSharedCoachForm"
import { useProgram } from "@modules/programs/hooks/usePrograms"
import { ProgramFormikType, ProgramInfiniteInputKey } from "@modules/programs/programFormikType"
import { ProgramInfinite, ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffFormikType, ProgramOnOffInputKey } from "@modules/programs/programOnOffFormikType"
import { ProgramOnOffStatus, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import { useTheme } from "styled-components"

type ProgramInfoFormPros = {
  programId: string | undefined
  isEditForm: boolean
  isOnOffProgram?: boolean
  isFormFieldInValid: (name: ProgramInfiniteInputKey | ProgramOnOffInputKey) => boolean
  getFormErrorMessage: (
    name: ProgramInfiniteInputKey | ProgramOnOffInputKey,
    errorMessage?: string,
  ) => JSX.Element | undefined
  formik: SimpleFormik<ProgramFormikType | ProgramOnOffFormikType, ProgramInfiniteInputKey | ProgramOnOffInputKey>
}

export const ProgramInfoForm: React.FC<ProgramInfoFormPros> = ({
  programId,
  isEditForm,
  isOnOffProgram,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
}) => {
  const theme = useTheme()
  const { result: program } = useProgram(
    programId,
    isOnOffProgram ? ProgramOnOffTypeOf : ProgramInfiniteTypeOf,
    FetchStrategy.First,
  )

  return (
    <div className="p-fluid col-12 gap-2">
      <div className="flex flex-column sm:flex-row sm:gap-3">
        <FormikInput
          flex
          formikStyle={{ display: "flex", flexGrow: 1 }}
          label={word("program.form.title.label")}
          name="title"
          isRequiredInput
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("title")}
          setFieldValue={formik.setFieldValue}
          error={getFormErrorMessage("title")}
          placeHolder={word("program.form.title.placeholder")}
        />
      </div>
      <FormikInput
        type="textarea"
        label={word("program.form.description.label")}
        name="description"
        isRequiredInput
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("description")}
        setFieldValue={formik.setFieldValue}
        error={getFormErrorMessage("description")}
        placeHolder={word("program.form.description.placeholder")}
        textareaProps={{ autoResize: true, rows: 10 }}
      />

      {isOnOffProgram && (
        <>
          <FormikInput
            type="number"
            label={word("program.onOff.formik.label")}
            name="duration"
            isRequiredInput
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("duration")}
            setFieldValue={formik.setFieldValue}
            error={getFormErrorMessage("duration")}
            rightButton={word("global.day.label", { count: 2 })}
            numberProps={{
              maxLength: 365,
              placeholder: word("program.onOff.formik.placeholder"),
            }}
            disabled={(program && "status" in program && program.status === ProgramOnOffStatus.PUBLISHED) ?? false}
          />
          <HURow className="px-3 mb-3 gap-2 align-items-center">
            <WarningHexagonalIcon color={theme.neutralColor600} width={24} height={24} />
            <HUText fontStyle="BS">
              {program && "status" in program && program.status === ProgramOnOffStatus.PUBLISHED
                ? word("program.onOff.durationEditPublished")
                : word("program.onOff.durationEditWarning")}
            </HUText>
          </HURow>
        </>
      )}

      {isEditForm && program && (
        <>
          <div style={{ marginBottom: 7 }}>
            <HUText type="label" color={theme.neutralColor700} fontStyle="LS">
              {word("program.price.label").toUpperCase()}
            </HUText>
          </div>
          <ProgramPriceCard program={program as ProgramInfinite} editionAllowed={true} />
        </>
      )}

      {isEditForm && "visibility" in formik.values && (
        <FormikInput
          type="switchInline"
          textSwitch={word("program.form.visibility.textSwitch")}
          label={word("program.form.visibility.label")}
          description={word("program.form.visibility.description")}
          name="visibility"
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("visibility")}
          error={getFormErrorMessage("visibility")}
          setFieldValue={formik.setFieldValue}
        />
      )}
      {!isOnOffProgram && "defaultHideSessionContent" in formik.values && (
        <FormikInput
          type="switchInline"
          label={word("program.form.hideSessionContent.label")}
          textSwitch={word("program.form.hideSessionContent.textSwitch")}
          description={word("program.form.hideSessionContent.description")}
          name="defaultHideSessionContent"
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("defaultHideSessionContent")}
          error={getFormErrorMessage("defaultHideSessionContent")}
          setFieldValue={formik.setFieldValue}
        />
      )}
      {"entranceCheckEnabled" in formik.values && (
        <FormikInput
          type="switchInline"
          textSwitch={word("program.form.sasActivation.textSwitch")}
          label={word("program.form.sasActivation.label")}
          description={word("program.form.sasActivation.description")}
          name="entranceCheckEnabled"
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("entranceCheckEnabled")}
          error={getFormErrorMessage("entranceCheckEnabled")}
          setFieldValue={formik.setFieldValue}
        />
      )}
      {!isOnOffProgram && "hideScoreboard" in formik.values && (
        <FormikInput
          type="switchInline"
          label={word("program.form.hideLeaderboard.label")}
          textSwitch={word("program.form.hideLeaderboard.textSwitch")}
          description={word("program.form.hideLeaderboard.description")}
          name="hideScoreboard"
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("hideScoreboard")}
          error={getFormErrorMessage("hideScoreboard")}
          setFieldValue={formik.setFieldValue}
        />
      )}
      {"sharedCoaches" in formik.values && (
        <ProgramSharedCoachForm setFieldValue={formik.setFieldValue} getFieldProps={formik.getFieldProps} />
      )}
    </div>
  )
}
