import { ApiClient } from "@modules/core/api/client/apiClient"
import {
  ItemProgramOnOffSession,
  ProgramOnOffSession,
  ProgramOnOffSessionDraft,
} from "@modules/programSession/programSessionTypes"
import {
  PlanningOnOffSessionDto,
  ProgramOnOffSessionDto,
  convertItemProgramOnOffSessionFromDto,
  convertProgramOnOffSessionDraftToDto,
  convertProgramOnOffSessionFromDto,
} from "@modules/programSession/services/programSessionDto"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"

export class ProgramOnOffSessionApi {
  private buildProgramOnOffSessionsUrl = (programId: string) => `programs/on-off/${programId}/sessions`
  private buildProgramOnOffSessionsByDateUrl = (programId: string) => `programs/on-off/${programId}/sessionsByDate`
  private buildProgramOnOffSessionsByIndexUrl = (programId: string) => `programs/on-off/${programId}/sessionsByIndex`

  constructor(private api: ApiClient) {}

  async getDetailedSession(id: string, programId: string, sessionDate?: string): Promise<ProgramOnOffSession> {
    const url = sessionDate
      ? `${this.buildProgramOnOffSessionsByDateUrl(programId)}/${id}`
      : `${this.buildProgramOnOffSessionsByIndexUrl(programId)}/${id}`

    const resultDto = await this.api.get<ProgramOnOffSessionDto>(url, {
      params: {
        date: sessionDate,
      },
    })

    return convertProgramOnOffSessionFromDto(resultDto.data)
  }

  async getSessions(startDate: string, endDate: string, programId: string): Promise<ItemProgramOnOffSession[]> {
    const result = await this.api.get<PlanningOnOffSessionDto>(this.buildProgramOnOffSessionsByDateUrl(programId), {
      params: {
        minDate: dayjs(startDate).format(defaultDateFormat),
        maxDate: dayjs(endDate).format(defaultDateFormat),
      },
    })

    return result.data.map((sessionDto: any) => convertItemProgramOnOffSessionFromDto(sessionDto))
  }

  async getSessionsByIndex(
    startIndex: number,
    endIndex: number,
    programId: string,
  ): Promise<ItemProgramOnOffSession[]> {
    const result = await this.api.get<PlanningOnOffSessionDto>(
      `${this.buildProgramOnOffSessionsByIndexUrl(programId)}`,
      {
        params: {
          indexMin: startIndex,
          indexMax: endIndex,
        },
      },
    )

    return result.data.map((sessionDto) => convertItemProgramOnOffSessionFromDto(sessionDto))
  }

  async createSession(sessionDraft: ProgramOnOffSessionDraft) {
    const resultDto = await this.api.post<ProgramOnOffSessionDto>(
      this.buildProgramOnOffSessionsUrl(sessionDraft.programId),
      convertProgramOnOffSessionDraftToDto(sessionDraft),
    )
    return convertProgramOnOffSessionFromDto(resultDto.data)
  }

  async updateSession(id: string, sessionDraft: ProgramOnOffSessionDraft) {
    const resultDto = await this.api.put<ProgramOnOffSessionDto>(
      `${this.buildProgramOnOffSessionsUrl(sessionDraft.programId)}/${id}`,
      convertProgramOnOffSessionDraftToDto(sessionDraft),
    )
    return convertProgramOnOffSessionFromDto(resultDto.data)
  }

  async deleteSession(id: string, programId: string) {
    await this.api.delete(`${this.buildProgramOnOffSessionsUrl(programId)}/${id}`)
  }
}
