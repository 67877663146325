export const AUTH_SPECIAL_CHARACTERS = [
  "^",
  "$",
  "*",
  ".",
  "[",
  "]",
  "{",
  "}",
  "(",
  ")",
  "?",
  "-",
  '"',
  "!",
  "@",
  "#",
  "%",
  "&",
  "/",
  "\\",
  ",",
  ">",
  "<",
  "'",
  ":",
  ";",
  "|",
  "_",
  "~",
  "`",
  "+",
  "=",
]
