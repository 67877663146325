import { word } from "@modules/core/utils/i18n"
import { useThemeChanger } from "@modules/profile/useColorTheme"
import { SessionFormProvider } from "@modules/sessions/context/sessionFormContext"
import {
  HUSidebarContextType,
  SidebarCustomIconType,
  SidebarNavigatorParams,
  SidebarPositionType,
} from "@modules/sidebar/sidebarTypes"
import { HUButton } from "@modules/ui/components/huButton"
import { SIDEBAR_DEFAULT_POSITION, SIDEBAR_DEFAULT_WIDTH } from "@modules/ui/uiConstants"
import { Sidebar } from "primereact/sidebar"
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const HUSidebarContext = createContext({} as HUSidebarContextType)

export const HUSidebarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false)
  const [isSidebarReduced, setIsSidebarReduced] = useState<boolean>(false)
  const [sidebarPosition, setSidebarPosition] = useState<SidebarPositionType>(SIDEBAR_DEFAULT_POSITION)
  const [sidebarWidth, setSidebarWidth] = useState<number>(SIDEBAR_DEFAULT_WIDTH)
  const [navigator, setNavigator] = useState<
    {
      content: React.ReactNode
      customHeaderIcons?: SidebarCustomIconType[]
      sidebarLeftButtonText?: string
      sidebarLeftButtonColor?: string
      sidebarLeftButtonClick?: () => Promise<boolean>
      allowSidebarReduction?: boolean
      onHide?: () => Promise<boolean>
    }[]
  >([])

  const currentContent = useMemo(
    () => (navigator.length > 0 ? navigator[navigator.length - 1] : undefined),
    [navigator],
  )
  const ref = useRef<Sidebar>(null)
  const theme = useTheme()
  const location = useLocation()
  const changingTheme = useThemeChanger()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onHideSidebar = useRef<(arg?: boolean) => void>(() => {})

  useEffect(() => {
    setIsSidebarVisible(false)
    setNavigator([])
  }, [changingTheme])

  useEffect(() => {
    setIsSidebarVisible(false)
    setNavigator([])
  }, [location])

  useEffect(() => {
    onHideSidebar.current = async (runOnHideCallback = true) => {
      const result = await cleanNavigator(runOnHideCallback)
      if (result) {
        setIsSidebarVisible(false)
      }
    }
  }, [navigator])

  const switchSidebarSide = useCallback(() => {
    setSidebarPosition(sidebarPosition === "left" ? "right" : "left")
  }, [sidebarPosition])

  const toggleSidebarReduced = useCallback(() => {
    setIsSidebarReduced((v) => !v)
  }, [sidebarWidth])

  const cleanNavigator = useCallback(
    async (runOnHideCallback = true) => {
      let navIndex = navigator.length

      while (navIndex > 0) {
        const content = navigator[navIndex - 1]

        if (typeof content.onHide !== "undefined" && runOnHideCallback) {
          const result = await content.onHide()

          if (result) {
            navIndex--
          } else {
            break
          }
        } else {
          navIndex--
        }

        setNavigator((previousNavigatorState) => {
          return previousNavigatorState.slice(0, navIndex)
        })
      }
      return navIndex === 0
    },
    [navigator],
  )

  const onSidebarNavigate = async ({
    width,
    position,
    leftButtonText,
    leftButtonColor,
    leftButtonAction,
    replaceLastComponent = false,
    replaceAllComponents = false,
    allowSidebarReduction = false,
    onHide,
    content,
  }: SidebarNavigatorParams) => {
    setIsSidebarVisible(true)
    if (replaceAllComponents) {
      const result = await cleanNavigator()
      if (result) {
        if (width) setSidebarWidth(width)
        else setSidebarWidth(SIDEBAR_DEFAULT_WIDTH)

        if (position) setSidebarPosition(position)
        setNavigator([
          {
            content,
            sidebarLeftButtonText: leftButtonText,
            sidebarLeftButtonClick: leftButtonAction,
            sidebarLeftButtonColor: leftButtonColor,
            allowSidebarReduction,
            onHide,
          },
        ])
      }
      return result
    } else if (replaceLastComponent) {
      if (typeof currentContent?.onHide !== "undefined") {
        const result = await currentContent.onHide()

        if (result) {
          if (width) setSidebarWidth(width)
          else setSidebarWidth(SIDEBAR_DEFAULT_WIDTH)

          if (position) setSidebarPosition(position)

          setNavigator((previousNavigatorState) => {
            return [
              ...previousNavigatorState.slice(0, -1),
              {
                content,
                sidebarLeftButtonText: leftButtonText,
                sidebarLeftButtonColor: leftButtonColor,
                sidebarLeftButtonClick: leftButtonAction,
                allowSidebarReduction,
                onHide,
              },
            ]
          })
        }
        return result
      } else {
        if (width) setSidebarWidth(width)
        else setSidebarWidth(SIDEBAR_DEFAULT_WIDTH)

        if (position) setSidebarPosition(position)

        setNavigator((previousNavigatorState) => {
          return [
            ...previousNavigatorState.slice(0, -1),
            {
              content,
              sidebarLeftButtonText: leftButtonText,
              sidebarLeftButtonColor: leftButtonColor,
              sidebarLeftButtonClick: leftButtonAction,
              allowSidebarReduction,
              onHide,
            },
          ]
        })
        return true
      }
    } else {
      if (width) setSidebarWidth(width)
      else setSidebarWidth(SIDEBAR_DEFAULT_WIDTH)

      if (position) setSidebarPosition(position)

      setNavigator((previousNavigatorState) => [
        ...previousNavigatorState,
        {
          content,
          sidebarLeftButtonText: leftButtonText,
          sidebarLeftButtonColor: leftButtonColor,
          sidebarLeftButtonClick: leftButtonAction,
          allowSidebarReduction,
          onHide,
        },
      ])

      return true
    }
  }

  const onSidebarNavigateBack = async (runOnHideCallback = true) => {
    if (typeof currentContent?.onHide !== "undefined" && runOnHideCallback) {
      const result = await currentContent.onHide()

      if (result) {
        setNavigator((previousNavigatorState) => {
          return previousNavigatorState.slice(0, -1)
        })
      }
    } else {
      setNavigator((previousNavigatorState) => {
        return previousNavigatorState.slice(0, -1)
      })
    }
  }

  const onSidebarPopToTop = () => {
    setNavigator((previousNavigatorState) => {
      previousNavigatorState.forEach((content, index) => index > 0 && content.onHide?.())

      return [previousNavigatorState[0]]
    })
  }

  const setCustomHeaderIcons = (icons: SidebarCustomIconType[]) => {
    setNavigator((previousNavigatorState) => {
      return previousNavigatorState.map((state, stateIndex) => {
        if (stateIndex === previousNavigatorState.length - 1)
          return {
            ...state,
            customHeaderIcons: icons,
          }
        else return state
      })
    })
  }

  const value = {
    hideSidebar: onHideSidebar,
    sidebarNavigateTo: onSidebarNavigate,
    sidebarNavigateBack: onSidebarNavigateBack,
    sidebarPopToTop: onSidebarPopToTop,
    setCustomHeaderIcons,
    isSidebarVisible,
  }

  const onLeftButtonClick = async () => {
    if (typeof currentContent?.sidebarLeftButtonClick !== "undefined") {
      const result = await currentContent.sidebarLeftButtonClick()

      if (result) onSidebarNavigateBack()
    } else {
      onSidebarNavigateBack()
    }
  }

  const attachIdToContentClass = () => {
    if (document.getElementsByClassName("p-sidebar-content").length === 1)
      document.getElementsByClassName("p-sidebar-content")[0].setAttribute("id", "sidebar-content")
  }

  return (
    <HUSidebarContext.Provider value={value}>
      <SessionFormProvider>
        <SidebarContainer
          ref={ref}
          visible={isSidebarVisible}
          onShow={attachIdToContentClass}
          onHide={() => onHideSidebar.current()}
          position={sidebarPosition}
          showCloseIcon={false}
          dismissable={false}
          $width={isSidebarReduced ? 70 : sidebarWidth}
          modal={false}
        >
          {isSidebarReduced && (
            <div className="flex w-full align-items-center">
              <RightContainer className="flex w-full justify-content-end mr-0 z-5">
                <HUButton
                  type="Rounded"
                  colorType="Tertiary"
                  size="M"
                  onClick={toggleSidebarReduced}
                  icon={{ iconView: <span className="pi pi-fast-backward" /> }}
                  className="h-2rem w-2rem z-5"
                  style={{ backgroundColor: theme.sidebarHeaderButtonsColor }}
                  isTooltipDisabled={false}
                  tooltipContent={word("sidebar.button.expand")}
                  tooltipId={"expand"}
                />
              </RightContainer>
            </div>
          )}
          <div className={isSidebarReduced ? "hidden" : "block w-full"}>
            <div className="flex w-full align-items-center">
              {navigator.length > 1 && (
                <LeftContainer>
                  <HUButton
                    type="Left_Icon"
                    colorType="Secondary"
                    size="L"
                    icon={{
                      iconView: (
                        <span
                          className={`p-button-icon p-c pi pi-arrow-left`}
                          style={{ color: currentContent?.sidebarLeftButtonColor ?? theme.buttonSecondaryTextColor }}
                        />
                      ),
                    }}
                    className="white-space-nowrap"
                    onClick={onLeftButtonClick}
                    text={currentContent?.sidebarLeftButtonText || word("global.return")}
                    textColor={currentContent?.sidebarLeftButtonColor}
                  />
                </LeftContainer>
              )}

              <RightContainer className="flex w-full justify-content-end mr-1 z-5">
                {currentContent?.customHeaderIcons?.map((customIcon, iconIndex) =>
                  customIcon.iconView ? (
                    <HUButton
                      key={iconIndex}
                      type="Rounded"
                      colorType="Tertiary"
                      size="M"
                      onClick={customIcon.onClick}
                      icon={{ iconView: customIcon.iconView }}
                      className="h-2rem w-2rem mr-2 z-5"
                      style={{ backgroundColor: theme.sidebarHeaderButtonsColor }}
                      isTooltipDisabled={customIcon.tooltipOptions?.isTooltipDisabled}
                      tooltipContent={customIcon.tooltipOptions?.tooltipContent}
                      tooltipTitle={customIcon.tooltipOptions?.tooltipTitle}
                      tooltipId={customIcon.tooltipOptions?.tooltipId}
                    />
                  ) : customIcon.button ? (
                    customIcon.button
                  ) : (
                    <></>
                  ),
                )}
                {currentContent?.allowSidebarReduction && (
                  <HUButton
                    type="Rounded"
                    colorType="Tertiary"
                    size="M"
                    onClick={toggleSidebarReduced}
                    icon={{ iconView: <span className="pi pi-fast-forward" /> }}
                    className="h-2rem w-2rem mr-2 z-5"
                    style={{ backgroundColor: theme.sidebarHeaderButtonsColor }}
                    isTooltipDisabled={false}
                    tooltipContent={word("sidebar.button.reduce")}
                    tooltipId={"reduce"}
                  />
                )}
                <HUButton
                  type="Rounded"
                  colorType="Tertiary"
                  size="M"
                  onClick={switchSidebarSide}
                  icon={{ iconView: <span className="pi pi-arrow-right-arrow-left" /> }}
                  className="h-2rem w-2rem mr-2 z-5"
                  style={{ backgroundColor: theme.sidebarHeaderButtonsColor }}
                  isTooltipDisabled={false}
                  tooltipContent={word("sidebar.button.switchSide")}
                  tooltipId={"switch-side"}
                />
                <HUButton
                  type="Rounded"
                  colorType="Tertiary"
                  size="M"
                  onClick={() => onHideSidebar.current()}
                  icon={{ iconView: <span className="pi pi-times" /> }}
                  className="h-2rem w-2rem z-5"
                  style={{ backgroundColor: theme.sidebarHeaderButtonsColor }}
                />
              </RightContainer>
            </div>
            {currentContent && currentContent.content}
          </div>
        </SidebarContainer>

        {children}
      </SessionFormProvider>
    </HUSidebarContext.Provider>
  )
}

const SidebarContainer = styled(Sidebar)<{ $width: number }>`
  width: ${({ $width }) => $width}px !important;

  .p-sidebar-header {
    padding: 0 !important;
  }

  .p-sidebar-content {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    overflow: auto;
    overscroll-behavior: contain;
  }

  .p-button.p-button-outlined {
    color: black;
  }
`

const LeftContainer = styled.div`
  z-index: 100;

  > button {
    background-color: transparent !important;

    &:hover {
      transform: translateX(-13px);
      transition: transform 1s ease;
    }
  }
`
const RightContainer = styled.div`
  padding: 10px 0px;

  button {
    border: none;
    color: ${({ theme }) => theme.neutralColor600};
  }
`
