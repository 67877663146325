import { ApiClient } from "@modules/core/api/client/apiClient"
import { addBoxInterceptor } from "@modules/core/api/requestBoxInterceptor"
import { Service } from "@modules/core/services/serviceType"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"
import { observable } from "micro-observables"

export class CurrentSpaceManager implements Service {
  private _currentBox = observable<{ boxId: string | null }>({ boxId: null })
  readonly currentBox = this._currentBox.readOnly()

  constructor(private localStorage: LocalStorageService, private apiClient: ApiClient) {}

  async init() {
    this.apiClient.addRequestInterceptors([addBoxInterceptor(this)])
    const unsubscribe = this.localStorage.connect(this._currentBox, LocalStorageKeys.space.currentBox, {
      boxId: null,
    })
    return unsubscribe
  }

  async onLoggedOut(): Promise<void> {
    this._currentBox.set({ boxId: null })
  }

  get boxId() {
    return this.currentBox.get().boxId
  }

  set(boxId?: string) {
    if (this.boxId !== boxId) {
      this._currentBox.set({ boxId: boxId ?? null })
    }
  }
}
