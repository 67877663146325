import { HUText } from "@modules/ui/components/huText"
import { Avatar } from "stream-chat-react"
import styled from "styled-components"

type UserGridItemType = {
  id: string
  image?: string | null | undefined
  name?: string | undefined
  last_active?: string | undefined
}

type UserGridItemProps = {
  user: UserGridItemType
  removeButton?: boolean
  onRemoveUser: () => void
}

export const UserGridItem: React.FC<UserGridItemProps> = ({ user, removeButton = false, onRemoveUser }) => {
  return (
    <Container>
      <AvatarContainer>
        <Avatar image={user.image} name={user.name} />
        {removeButton && (
          <CloseIcon onClick={onRemoveUser}>
            <i className="pi pi-times" />
          </CloseIcon>
        )}
      </AvatarContainer>
      <HUText fontStyle="BS" type="label" className="mt-1 text-center" numberOfLine={2}>
        {user.name}
      </HUText>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  max-width: 10rem;
`
const AvatarContainer = styled.div`
  position: relative;
`
const CloseIcon = styled.div`
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: ${({ theme }) => theme.error};
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.background};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 0.6rem;
  }
`
