import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const SmallArrowIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.64383 13.1256C7.72604 13.1597 7.81423 13.1771 7.90323 13.1767C7.99224 13.1771 8.08043 13.1597 8.16263 13.1256C8.24484 13.0915 8.3194 13.0413 8.38194 12.9779L13.7845 7.57541C13.7903 7.57001 13.796 7.5645 13.8016 7.55889C13.9286 7.43184 14 7.25954 14 7.07988C14 6.90021 13.9286 6.72791 13.8016 6.60087C13.796 6.59526 13.7903 6.58976 13.7845 6.58438L8.38194 1.18181C8.25353 1.06215 8.08368 0.997009 7.90818 1.00011C7.73268 1.0032 7.56524 1.0743 7.44112 1.19841C7.31701 1.32253 7.24591 1.48997 7.24282 1.66547C7.23972 1.84097 7.30486 2.01082 7.42452 2.13923L11.6878 6.40246H0.677419C0.497757 6.40246 0.325452 6.47383 0.198411 6.60087C0.0713707 6.72791 0 6.90021 0 7.07988C0 7.25954 0.0713707 7.43184 0.198411 7.55889C0.325452 7.68593 0.497757 7.7573 0.677419 7.7573H11.6877L7.42452 12.0205C7.29767 12.1475 7.22641 12.3197 7.22641 12.4992C7.22641 12.6787 7.29767 12.8509 7.42452 12.9779C7.48707 13.0413 7.56163 13.0915 7.64383 13.1256Z"
      fill={props.color}
    />
  </svg>
)

type ArrowDirection = "left" | "right" | "top" | "bottom" | "topright"

const getArrowRotate = (arrowDirection: ArrowDirection) => {
  switch (arrowDirection) {
    case "left":
      return 90
    case "right":
      return -90
    case "top":
      return 180
    case "bottom":
      return 0
    case "topright":
      return -135
  }
}

export const ArrowIcon: React.FC<
  IconSvgProps & {
    arrowDirection: ArrowDirection
    short?: boolean
  }
> = ({ arrowDirection, short, ...restProps }) => {
  const arrowRotate = getArrowRotate(arrowDirection)
  const svgStyle = arrowRotate !== 0 ? { transform: `rotate(${arrowRotate}deg)` } : {}

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...restProps}
      style={{ ...restProps.style, ...svgStyle }}
    >
      {short ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.687 8.937a1.15 1.15 0 011.626 0L12 16.624l7.687-7.687a1.15 1.15 0 111.626 1.626l-8.5 8.5a1.15 1.15 0 01-1.626 0l-8.5-8.5a1.15 1.15 0 010-1.626z"
          fill={restProps.color}
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.74867 14.2322C2.52723 14.0107 2.40283 13.7104 2.40283 13.3972C2.40283 13.0841 2.52723 12.7837 2.74867 12.5623C2.97011 12.3409 3.27044 12.2165 3.5836 12.2165C3.89676 12.2165 4.1971 12.3409 4.41853 12.5623L10.8211 18.9664L10.8211 2.17437C10.8211 1.86152 10.9454 1.56149 11.1666 1.34028C11.3878 1.11906 11.6879 0.994783 12.0007 0.994783C12.3135 0.994783 12.6136 1.11906 12.8348 1.34027C13.056 1.56149 13.1803 1.86152 13.1803 2.17437L13.1803 18.9648L19.5813 12.5623C19.8027 12.3409 20.1031 12.2165 20.4162 12.2165C20.7294 12.2165 21.0297 12.3409 21.2512 12.5623C21.4726 12.7837 21.597 13.0841 21.597 13.3972C21.597 13.7104 21.4726 14.0107 21.2512 14.2322L12.8351 22.6482C12.7255 22.758 12.5953 22.8453 12.452 22.9047C12.3087 22.9642 12.1551 22.9948 11.9999 22.9948C11.8448 22.9948 11.6911 22.9642 11.5478 22.9047C11.4046 22.8453 11.2744 22.7581 11.1649 22.6483L2.74867 14.2322Z"
          fill={restProps.color}
        />
      )}
    </svg>
  )
}

export const LeftArrowIcon: React.FC<IconSvgProps> = (props) => <ArrowIcon arrowDirection="left" {...props} />
export const RightArrowIcon: React.FC<IconSvgProps> = (props) => <ArrowIcon arrowDirection="right" {...props} />
export const UpArrowIcon: React.FC<IconSvgProps> = (props) => <ArrowIcon arrowDirection="top" {...props} />
export const DownArrowIcon: React.FC<IconSvgProps> = (props) => <ArrowIcon arrowDirection="bottom" {...props} />
export const DiagonalArrowIcon: React.FC<IconSvgProps> = (props) => <ArrowIcon arrowDirection="topright" {...props} />

export const ShortDownArrowIcon: React.FC<IconSvgProps> = (props) => (
  <ArrowIcon arrowDirection="bottom" short {...props} />
)
export const ShortUpArrowIcon: React.FC<IconSvgProps> = (props) => <ArrowIcon arrowDirection="top" short {...props} />
export const ShortRightArrowIcon: React.FC<IconSvgProps> = (props) => (
  <ArrowIcon arrowDirection="right" short {...props} />
)
export const ShortLeftArrowIcon: React.FC<IconSvgProps> = (props) => (
  <ArrowIcon arrowDirection="left" short {...props} />
)
