import { TrophyIcon } from "@images/svgIcons/trophyIcon"
import { ChallengeParticipant } from "@modules/aladinChallenge/components/challengeParticipant"
import { ExerciseDashboard } from "@modules/exercises/exerciseTypes"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { Reorder } from "framer-motion"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import styled, { useTheme } from "styled-components"

export const ChallengeParticipants: React.FC<{ challengeResults: ExerciseDashboard }> = ({ challengeResults }) => {
  const theme = useTheme()
  const [podiumScores, setPodiumScores] = useState(challengeResults.topUsers)
  const [lastParticipants, setLastParticipants] = useState(challengeResults.lastRecordUsers)

  useEffect(() => {
    if (
      !_.isEqual(podiumScores, challengeResults.topUsers) ||
      !_.isEqual(lastParticipants, challengeResults.lastRecordUsers)
    ) {
      setTimeout(() => {
        setPodiumScores(challengeResults.topUsers)
        setLastParticipants(challengeResults.lastRecordUsers)
      }, 1000)
    }
  }, [challengeResults.topUsers, challengeResults.lastRecordUsers])

  return (
    <HUStack style={{ width: 315, height: "100%", marginLeft: 20 }} className="align-self-end">
      <Card>
        <HURow className="align-items-center justify-content-center">
          <TrophyIcon color={"#ddf247"} width={55} height={48} />
          <HUText color={"#ddf247"} fontStyle="HM" className="align-items-center" fontWeight="bold">
            PODIUM
          </HUText>
        </HURow>

        <Reorder.Group
          axis="y"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onReorder={() => {}}
          values={podiumScores.map((ps) => ps.user.id)}
          className={`flex flex-column align-items-start ${podiumScores.length > 4 ? "justify-content-around" : "justify-content-start"} h-full list-none`}
        >
          {podiumScores.map((podiumScore, index) => (
            <ChallengeParticipant key={podiumScore.user.id} item={podiumScore} index={index} isRanking={true} />
          ))}
        </Reorder.Group>
      </Card>

      <Card className="justify-content-between">
        <HUText color={theme.mainWhite} fontStyle="HM">
          Les derniers participants
        </HUText>

        <Reorder.Group
          axis="y"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onReorder={() => {}}
          values={lastParticipants.map((ps) => ps.user.id)}
          className={`flex flex-column align-items-start ${podiumScores.length > 4 ? "justify-content-around" : "justify-content-start"} h-full text-right list-none`}
        >
          {lastParticipants.map((lastParticipant, index) => (
            <ChallengeParticipant
              key={lastParticipant.user.id}
              item={lastParticipant}
              index={index}
              isRanking={false}
            />
          ))}
        </Reorder.Group>
      </Card>
    </HUStack>
  )
}

const Card = styled(HUStack)`
  background: linear-gradient(107deg, rgba(255, 255, 255, 0.1) 2.13%, rgba(230, 230, 230, 0.1) 96.41%);
  border-radius: 33px;
  box-shadow:
    -2px -2px 4px 0px rgba(0, 0, 0, 0.1) inset,
    2px 2px 8px 0px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(2px);
  padding: 20px;
  text-align: center;
  height: 49%;
`
