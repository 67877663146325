import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const LockIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4252 5.22501C5.4252 3.25059 7.02579 1.65 9.00021 1.65C10.9746 1.65 12.5752 3.25059 12.5752 5.22501V5.60001H5.4252V5.22501ZM3.7752 5.67723V5.22501C3.7752 2.33932 6.11452 0 9.00021 0C11.8859 0 14.2252 2.33932 14.2252 5.22501V5.67723C16.2609 6.06339 17.8002 7.85194 17.8002 10V16.6C17.8002 19.0301 15.8303 21 13.4002 21H4.6002C2.17015 21 0.200195 19.0301 0.200195 16.6V10C0.200195 7.85194 1.73949 6.06339 3.7752 5.67723ZM9.00021 11.375C9.45584 11.375 9.82521 11.7444 9.82521 12.2V14.4C9.82521 14.8557 9.45584 15.225 9.00021 15.225C8.54457 15.225 8.17521 14.8557 8.17521 14.4V12.2C8.17521 11.7444 8.54457 11.375 9.00021 11.375Z"
      fill={props.color}
    />
  </svg>
)

export const PadLockSolidIcon: React.FC<IconSvgProps> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.425 6.225a3.575 3.575 0 017.15 0V6.6h-7.15v-.375zm-1.65.452v-.452a5.225 5.225 0 0110.45 0v.452A4.401 4.401 0 0120.8 11v6.6a4.4 4.4 0 01-4.4 4.4H7.6a4.4 4.4 0 01-4.4-4.4V11a4.401 4.401 0 013.575-4.323zM12 12.375c.456 0 .825.37.825.825v2.2a.825.825 0 11-1.65 0v-2.2c0-.456.37-.825.825-.825z"
      fill={props.color}
    />
  </svg>
)

export const PadLockOutlineIcon: React.FC<IconSvgProps> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M16.1 6.9H7.9m8.2 0a4.1 4.1 0 014.1 4.1v6.15a4.1 4.1 0 01-4.1 4.1H7.9a4.1 4.1 0 01-4.1-4.1V11a4.1 4.1 0 014.1-4.1m8.2 0V5.85a4.1 4.1 0 10-8.2 0V6.9m4.1 8.2v-2.05"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
)
