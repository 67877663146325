import { useServicesContext } from "@modules/core/services/services.context"
import { useAsync } from "@modules/hooks/useAsync"

export const useBoxSlots = (startDate?: string, endDate?: string, programmationId?: string) => {
  const { boxPlanningService } = useServicesContext()

  return useAsync(async () => {
    if (programmationId && startDate && endDate) {
      return await boxPlanningService.getPlanningSlots(startDate, endDate, programmationId)
    }
    return new Promise<null>(() => null)
  }, [])
}
