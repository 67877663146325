export const Config = {
  ENV: import.meta.env.VITE_ENVIRONMENT as "dev" | "demo" | "prod",

  BASE_URL: import.meta.env.VITE_BASE_URL ?? "",

  WEB_URL: import.meta.env.VITE_WEB_URL ?? "",

  // Sentry
  SENTRY_DSN: "https://a7d10bd3d49e4a0ba97ad7518cff23ae@o4505362867486720.ingest.sentry.io/4505426181226496",
  SENTRY_ENV: import.meta.env.VITE_SENTRY_ENV,

  // Authentification
  COGNITO_USER_POOL_ID: import.meta.env.VITE_COGNITO_USER_POOL_ID ?? "",
  COGNITO_CLIENT_ID: import.meta.env.VITE_COGNITO_CLIENT_ID ?? "",

  // Analytics
  SEGMENT_ID: "",

  // Chat
  STREAM_CHAT_API_KEY: import.meta.env.VITE_STREAM_CHAT_API_KEY ?? "",
  STREAM_CHAT_PUSH_PROVIDER_NAME: "",

  // MAPBOX
  MAPBOX_API_KEY: import.meta.env.VITE_MAPBOX_API_KEY ?? "",

  // AWS
  AWS_REGION: import.meta.env.VITE_AWS_REGION ?? "",

  // Stripe
  STRIPE_PUBLIC_KEY: import.meta.env.VITE_STRIPE_PUBLIC_KEY ?? "",

  // Firebase
  FIREBASE_API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,

  // App banner
  IOS_APP_ID: import.meta.env.VITE_IOS_APP_ID,
  APP_BANNER_URL: import.meta.env.VITE_ANDROID_APP_BANNER_URL,

  // Sanity
  SANITY_PREVIEW_TOKEN: import.meta.env.SANITY_PREVIEW_TOKEN ?? "",

  //Email Adress
  CONTACT_EMAIL: import.meta.env.VITE_CONTACT_EMAIL ?? "",

  // CDN
  CDN_FLAG: import.meta.env.VITE_CDN_FLAG ?? "",
}
