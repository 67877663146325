import ScoreEmpty from "@images/empty/score_empty.svg"
import { useUser } from "@modules/auth/hooks/useUser"
import { word } from "@modules/core/utils/i18n"
import {
  Exercise,
  ExerciseScoreCategories,
  ExerciseScoreCategory,
  ExerciseScoreDifficulties,
  ExerciseScoreDifficulty,
} from "@modules/exercises/exerciseTypes"
import { getDisplayExerciseCategory } from "@modules/exercises/exerciseUtils"
import { ProgramInfiniteSession, ProgramOnOffSession } from "@modules/programSession/programSessionTypes"
import { HeaderPodium } from "@modules/scoreboard/components/headerPodium"
import { ScoreboardDetailsCard } from "@modules/scoreboard/components/scoreboardDetailsCard"
import { useMyRanking, useScoreboard } from "@modules/scoreboard/hooks/useScoreboard"
import { ScoreboardRanking } from "@modules/scoreboard/scoreboardTypes"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { LoadingSpinner } from "@modules/ui/components/loading"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import styled, { useTheme } from "styled-components"

type ScoreboardViewProps = {
  session: ProgramInfiniteSession | ProgramOnOffSession
  programId: string
  scoreExercicesRequested: Exercise[]
}
export const ScoreboardView: React.FC<ScoreboardViewProps> = ({ session, programId, scoreExercicesRequested }) => {
  const theme = useTheme()
  const [difficultySelected, setDifficultySelected] = useState<ExerciseScoreDifficulty>(ExerciseScoreDifficulties[0])
  const [categorySelected, setCategorySelected] = useState<ExerciseScoreCategory>(ExerciseScoreCategories[0])
  const [isFiltersActived, setIsFiltersActived] = useState<boolean>(false)
  const [exerciseId, setExerciseId] = useState<string>(
    scoreExercicesRequested.length > 0 ? scoreExercicesRequested[0].id : "",
  )
  const user = useUser()
  const referenceDate = useMemo(() => {
    return dayjs().toISOString()
  }, [])

  const {
    loading: scoreboardLoading,
    result: scoreboard,
    totalSize,
    listMore,
  } = useScoreboard(programId, session.id, exerciseId, difficultySelected, categorySelected, referenceDate)

  const { value: myRanking } = useMyRanking(
    programId,
    session.id,
    exerciseId,
    difficultySelected,
    categorySelected,
    referenceDate,
  )

  const updateUserScorePosition = (userScores: ScoreboardRanking[], myRankingId: string): ScoreboardRanking[] => {
    const targetUserIndex = userScores.findIndex((user) => user.userId === myRankingId)
    if (targetUserIndex !== -1) {
      const targetUser = userScores[targetUserIndex]
      userScores.splice(targetUserIndex, 1)
      userScores.unshift(targetUser)
    }
    return userScores
  }

  const filtersToggle = () => {
    setIsFiltersActived(!isFiltersActived)
  }
  const scoreboardOrder = useMemo(() => {
    if (myRanking?.position && myRanking.position > 4) {
      const updateScoreboard = updateUserScorePosition([...scoreboard] as ScoreboardRanking[], myRanking.userId)
      return updateScoreboard
    } else return scoreboard
  }, [scoreboard, myRanking])

  const selectedCategoryText = useMemo(() => {
    switch (categorySelected) {
      case ExerciseScoreCategories[0]:
        return word("scoreboard.filters.all")
      case ExerciseScoreCategories[1]:
        return word("scoreboard.filters.male")
      case ExerciseScoreCategories[2]:
        return word("scoreboard.filters.female")
      default:
        return ""
    }
  }, [categorySelected])

  return (
    <>
      <HeaderContainer>
        <HUText fontStyle="TL">{word("program.planning.create.exercise.scoreboard")}</HUText>
        <div className="flex justify-content-between">
          <ButtonsContainer>
            {scoreExercicesRequested.map((exercise, i) => {
              return (
                <HUButton
                  key={i}
                  type="Default"
                  size="S"
                  colorType={exerciseId === exercise.id ? "Black" : "Tertiary"}
                  onClick={() => {
                    setExerciseId(exercise.id)
                  }}
                  text={getDisplayExerciseCategory(exercise.category)}
                />
              )
            })}
          </ButtonsContainer>
          <div className="flex flex-column cursor-pointer  align-items-center" onClick={filtersToggle}>
            <i className="pi pi-filter" />
            <HUText fontStyle="LXS">{word("scoreboard.filters.filters")}</HUText>
          </div>
        </div>
        <Separator />
        <div className="flex justify-content-end">
          <HUText fontStyle="LM" color={theme.neutralColor600}>
            {`${difficultySelected} - ${selectedCategoryText}`}
          </HUText>
        </div>
        {scoreboard.length > 2 && <HeaderPodium podium={scoreboard.slice(0, 3)} />}
        {isFiltersActived && (
          <>
            <HUText fontStyle="LS" color={theme.neutralColor600}>
              {word("scoreboard.filters.division").toUpperCase()}
            </HUText>
            <ButtonsContainer className="">
              {ExerciseScoreDifficulties.map((difficulty, i) => {
                return (
                  <HUButton
                    key={i}
                    type="Default"
                    size="S"
                    onClick={() => setDifficultySelected(difficulty)}
                    className={difficultySelected === difficulty ? "selected" : ""}
                    colorType={difficultySelected === difficulty ? "Black" : "Tertiary"}
                    text={difficulty}
                  />
                )
              })}
            </ButtonsContainer>
            <HUText fontStyle="LS" color={theme.neutralColor600}>
              {word("scoreboard.filters.category").toUpperCase()}
            </HUText>
            <ButtonsContainer>
              <HUButton
                type="Default"
                size="S"
                onClick={() => setCategorySelected(ExerciseScoreCategories[0])}
                className={categorySelected === ExerciseScoreCategories[0] ? "selected" : ""}
                colorType={categorySelected === ExerciseScoreCategories[0] ? "Black" : "Tertiary"}
                text={word("scoreboard.filters.all")}
              />
              <HUButton
                type="Default"
                size="S"
                onClick={() => setCategorySelected(ExerciseScoreCategories[1])}
                className={categorySelected === ExerciseScoreCategories[1] ? "selected" : ""}
                colorType={categorySelected === ExerciseScoreCategories[1] ? "Black" : "Tertiary"}
                text={word("scoreboard.filters.male")}
              />
              <HUButton
                type="Default"
                size="S"
                onClick={() => setCategorySelected(ExerciseScoreCategories[2])}
                className={categorySelected === ExerciseScoreCategories[2] ? "selected" : ""}
                colorType={categorySelected === ExerciseScoreCategories[2] ? "Black" : "Tertiary"}
                text={word("scoreboard.filters.female")}
              />
            </ButtonsContainer>
            {user?.genderCategory?.value === null && (
              <HUText
                fontStyle="BS"
                color={theme.neutralColor600}
                className="mb-4"
                style={{ whiteSpace: "pre-line", marginTop: -8 }}
              >
                {word("scoreboard.filters.errorMessage.category")}
              </HUText>
            )}
          </>
        )}
        {scoreboard.length > 2 && <HeaderPodium podium={scoreboard.slice(0, 3)} />}
      </HeaderContainer>

      {scoreboardLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {scoreboard.length > 0 ? (
            <>
              <InfiniteScroll
                dataLength={scoreboard.length}
                next={listMore}
                hasMore={scoreboard.length < (totalSize || 0)}
                loader={<HUText fontStyle="BM">{word("global.loading")}</HUText>}
              >
                {scoreboardOrder.map((userScore, i) => {
                  return <ScoreboardDetailsCard key={i} userScore={userScore} myRanking={myRanking} />
                })}
              </InfiniteScroll>
            </>
          ) : (
            <EmptyView
              image={{ src: ScoreEmpty }}
              title={word("scoreboard.empty.title")}
              description={word("scoreboard.empty.description")}
              flexGrow={false}
              className="p-6"
              textAlign="center"
              disposition="column"
              style={{
                backgroundColor: theme.neutralColor100,
                display: "flex",
                justifyContent: "center",
              }}
            />
          )}
        </>
      )}
    </>
  )
}
const HeaderContainer = styled.div`
  margin-bottom: 10px;
`

const ButtonsContainer = styled.div`
  padding: 8px 0px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    padding: 9px 14px !important;
    border-radius: 44px !important;
    align-self: normal;
  }
  button span span {
    font-size: 0.8rem !important;
  }
`
const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin-bottom: 7px;
  background-color: ${({ theme }) => theme.neutralColor200};
`
