import { AuthErrorStrings } from "@aws-amplify/auth"
import { ConfigService } from "@modules/core/services/configService"
import { Service } from "@modules/core/services/serviceType"
import { HUError, HUErrorCode } from "@modules/error/huError"
import { LoggerType } from "@modules/utils/loggerUtils"
import { equalsInsensitive } from "@modules/utils/stringUtils"
import { CognitoUser } from "amazon-cognito-identity-js"
import { Amplify, Auth } from "aws-amplify"
import { observable } from "micro-observables"

const MINIMUM_TOKEN_EXPIRATION_IN_SECOND = 10

export class AuthService implements Service {
  private _cognitoUser = observable<CognitoUser | null>(null)
  readonly cognitoUser = this._cognitoUser.readOnly()

  async init(): Promise<void> {
    Amplify.configure({
      Auth: {
        region: ConfigService.config.AWS_REGION,
        userPoolId: ConfigService.config.COGNITO_USER_POOL_ID,
        userPoolWebClientId: ConfigService.config.COGNITO_CLIENT_ID,
      },
    })
  }

  async login(email: string, password: string): Promise<void> {
    const user: CognitoUser = await Auth.signIn(email, password)
    this._cognitoUser.set(user)
  }

  async signUp(email: string, password: string, locale: string): Promise<void> {
    const user = await Auth.signUp({
      username: email,
      password,
      attributes: {
        locale,
      },
      autoSignIn: {
        enabled: true,
      },
    })
    this._cognitoUser.set(user.user)
  }

  isCognitoUser(): boolean {
    if (this._cognitoUser !== null) {
      return true
    } else {
      return false
    }
  }

  async confirmationCodeSignUp(email: string, code: string): Promise<void> {
    await Auth.confirmSignUp(email, code)
  }

  async resendConfirmationCodeSignUp(email: string): Promise<void> {
    await Auth.resendSignUp(email)
  }

  async sendForgotPasswordConfirmationCode(email: string) {
    await Auth.forgotPassword(email)
  }

  async changePasswordWithCode(email: string, code: string, newPassword: string) {
    await Auth.forgotPasswordSubmit(email, code, newPassword)
  }

  async getUser(): Promise<CognitoUser | null> {
    // with: Auth.currentAuthenticatedUser, we are disconnected on launch app without network (because throw a "user is not authenticated" error )
    const user: CognitoUser | null = await Auth.currentUserPoolUser()
    if (!user) {
      throw new HUError(HUErrorCode.Unauthorised, "No user")
    }
    this._cognitoUser.set(user)
    return user
  }

  async getValidToken(forceRefresh = false): Promise<string> {
    try {
      const user = this._cognitoUser.get() ?? (await this.getUser())

      const session = user?.getSignInUserSession() || (await Auth.currentSession())

      if (
        !forceRefresh &&
        session.isValid() &&
        (session.getIdToken().getExpiration() - MINIMUM_TOKEN_EXPIRATION_IN_SECOND) * 1000 > Date.now()
      ) {
        return session.getIdToken().getJwtToken()
      }

      const expirationTimer = (session.getIdToken().getExpiration() * 1000 - Date.now()) / 1000
      console.log(LoggerType.Token + "expired, refreshing...", {
        forceRefresh,
        isValid: session.isValid(),
        expirationTime:
          (expirationTimer < 0 ? "-" : "") +
          Math.trunc(Math.abs(expirationTimer) / 60) +
          "min " +
          Math.trunc(Math.abs(expirationTimer) % 60) +
          "s",
      })

      await new Promise((accept, reject) => {
        user?.refreshSession(session.getRefreshToken(), (err) => {
          if (err) {
            if (err instanceof Error) {
              // 'reject' in the refreshSession means that we will be disconected, so we ignore 'network error' (no network)
              if (equalsInsensitive(err.message, AuthErrorStrings.NETWORK_ERROR)) {
                accept(false)
                return
              }
            }
            reject(err)
          }
          accept(true)
        })
      })

      return (user?.getSignInUserSession() || (await Auth.currentSession())).getIdToken().getJwtToken()
    } catch (e) {
      if (e instanceof Error) {
        if (equalsInsensitive(e.message, AuthErrorStrings.NETWORK_ERROR)) {
          throw e
        }
        if (e instanceof HUError) {
          throw e
        }
      }
      throw new HUError(HUErrorCode.Unauthorised, "No token")
    }
  }

  async logout(): Promise<void> {
    await Auth.signOut()
  }

  resetUser() {
    this._cognitoUser.get()?.signOut()
    this._cognitoUser.set(null)
  }
}
