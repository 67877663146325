import { whiten } from "@modules/utils/colorUtils"
import { Checkbox as CheckBox, CheckboxProps } from "primereact/checkbox"
import styled from "styled-components"

type Props = {
  color: string
  checkIconColor: string
  label?: string
  inputKey: string
  gap?: number
  styleLabel?: string
} & CheckboxProps

export const HUCheckbox: React.FC<Props> = ({ color, checkIconColor, label, inputKey, gap, styleLabel, ...props }) => {
  return (
    <Container
      className="field-checkbox"
      $color={color}
      $hoverColor={whiten(color, -0.1)}
      $focusColor={whiten(color, 0.1)}
      $checkIconColor={checkIconColor}
    >
      <CheckBox inputId={inputKey} {...props} />
      {label && (
        <Label
          htmlFor={inputKey}
          style={{
            marginLeft: gap,
          }}
          className={styleLabel}
        >
          {label}
        </Label>
      )}
    </Container>
  )
}

const Container = styled.div<{ $color: string; $hoverColor: string; $focusColor: string; $checkIconColor: string }>`
  &.field-checkbox {
    margin-bottom: 0px;
  }
  .p-highlight > .p-checkbox-box {
    border-color: ${({ $color }) => $color} !important;
    background: ${({ $color }) => $color} !important;
  }
  .p-checkbox-box.p-highlight:hover {
    background: ${({ $color }) => $color};
  }
  .p-checkbox-box:hover {
    border-color: ${({ $color }) => $color};
  }
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: ${({ $hoverColor }) => $hoverColor};
    background: ${({ $hoverColor }) => $hoverColor};
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: ${({ $hoverColor }) => $hoverColor};
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: ${({ $color }) => $color};
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: ${({ $hoverColor }) => $hoverColor};
    background: ${({ $hoverColor }) => $hoverColor};
  }

  .p-checkbox-icon {
    color: ${({ $checkIconColor }) => $checkIconColor} !important;
  }
`

const Label = styled.label`
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;

  &.tm-label {
    font-family: Sora;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 23px;
  }
`
