import { useUser } from "@modules/auth/hooks/useUser"
import { useCurrentBox } from "@modules/box/useBox"
import { useServicesContext } from "@modules/core/services/services.context"
import { StripeCountryCode, StripeErrorCode } from "@modules/payment/stripeTypes"
import { usePopup } from "@modules/ui/components/popup/huPopup"
import { LoggerType } from "@modules/utils/loggerUtils"
import * as Sentry from "@sentry/react"
import { useEffect, useRef, useState } from "react"

export const useStripeAccount = () => {
  const [loading, setLoading] = useState(false)
  const { paymentService, userService } = useServicesContext()
  const errorPopup = usePopup()
  const { result: boxId } = useCurrentBox()
  const user = useUser()

  const useStripeAccountStatus = () => {
    const [loading, setLoading] = useState(true)
    const [stripeAccountStatus, setStripeAccountStatus] = useState(user?.stripeAccountStatus)

    useEffect(() => {
      ;(async () => {
        try {
          const userResult = await userService.retrieveUser()
          setStripeAccountStatus(userResult.stripeAccountStatus)
        } catch (e) {
          console.error(LoggerType.Stripe + "retrieve user", e)
        } finally {
          setLoading(false)
        }
      })()
    }, [])

    return { loading, stripeAccountStatus }
  }

  const createStripeAccount = async (countryCode?: StripeCountryCode) => {
    try {
      setLoading(true)
      const accountToken = await paymentService.createAccountToken()
      if (accountToken.error || !accountToken.id) {
        console.error(LoggerType.Stripe + "create account token", accountToken.error?.code)
        throw Error("Stripe create account failure")
      }

      let result
      if (boxId) {
        result = await paymentService.createBoxStripeAccount(accountToken.id, countryCode)
      } else if (countryCode) {
        result = await paymentService.createUserStripeAccount(accountToken.id, countryCode)
      } else throw new Error("Missing country code")

      if (result && result.url) {
        window.open(result.url, "_self")
        return
      }
    } catch (e) {
      console.error(LoggerType.Stripe + "create account ", e)
      if ((e as any)?.code === StripeErrorCode.STRIPE_ACCOUNT_ALREADY_EXISTS) {
        updateStripeAccount()
        return
      }
      errorPopup.showDefaultError()
      Sentry.captureException(JSON.stringify(e))
    } finally {
      setLoading(false)
    }
  }

  const updateStripeAccount = async () => {
    try {
      setLoading(true)
      const result = boxId
        ? await paymentService.getUpdateBoxAccountLink()
        : await paymentService.getUpdateUserAccountLink()
      if (result.url) {
        window.open(result.url, "_self")
        return
      }
    } catch (e) {
      console.error(LoggerType.Stripe + "update account ", e)
      errorPopup.showDefaultError()
      Sentry.captureException(JSON.stringify(e))
    } finally {
      setLoading(false)
    }
  }

  return { loading, createStripeAccount, updateStripeAccount, useStripeAccountStatus }
}

export const useStripePayment = () => {
  const stripeWebviewPromise = useRef<{ resolve: (url: string) => void; reject: (reason?: any) => void } | undefined>()

  useEffect(() => {
    // const listener = HUEventEmiter.addListener({
    //   key: StripeEmiterKey,
    //   callback: async ({ result }) => {
    //     if (stripeWebviewPromise.current) {
    //       if (result) {
    //         stripeWebviewPromise.current.resolve(result)
    //       } else {
    //         stripeWebviewPromise.current.reject(CANCELED_BY_USER)
    //       }
    //     }
    //   },
    // })
    // return () => listener.remove() // TODO ?
  }, [])

  const stripePayment = async (url: string, options?: { stripeWebviewStartCallback?: () => void }) => {
    try {
      const resultUrl = await new Promise<string>((resolve, reject) => {
        stripeWebviewPromise.current = { resolve, reject }
        window.open(url, "_self") // TODO return url
        options?.stripeWebviewStartCallback?.()
      })

      if (resultUrl.endsWith("success")) {
        return true
      } else {
        if (!resultUrl.endsWith("cancel")) {
          throw Error("Stripe webview finish with wrong url")
        }
        return false
      }
    } finally {
      stripeWebviewPromise.current = undefined
    }
  }

  return { stripePayment }
}
