import worldImage from "@images/svgIcons/worldIcon.svg"
import { ProfileFormikType, ProfileInputKey } from "@modules/auth/profileFormikTypes"
import { word } from "@modules/core/utils/i18n"
import { countriesListArray } from "@modules/language/utils/countries"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import dayjs from "dayjs"
import styled, { useTheme } from "styled-components"

export type RegisterFormThreePageProps = {
  handleSubmit: () => void
  onPrevious: () => void
  formik: SimpleFormik<ProfileFormikType, ProfileInputKey>
  isFormFieldInValid: (name: ProfileInputKey) => boolean
  getFormErrorMessage: (name: ProfileInputKey, errorMessage?: string) => JSX.Element | undefined
  disabled: boolean
}
export const RegisterFormThreePage = ({
  handleSubmit,
  onPrevious,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  disabled,
}: RegisterFormThreePageProps) => {
  const theme = useTheme()
  const availableCountries = countriesListArray()

  return (
    <Container>
      <HUStack gap={20}>
        <HUButton
          type="Left_Icon"
          size="XS"
          colorType="Tertiary"
          text={word("global.previous")}
          className=""
          onClick={onPrevious}
          icon={{
            iconView: <span className={`pi pi-arrow-left`} style={{ color: theme.textPrimary }} />,
          }}
        />
        <WorldImage src={worldImage} width={100} />
        <HUText fontStyle="TM">3/4</HUText>
        <HUText fontStyle="TM">{word("auth.signup.form.page.3.title")}</HUText>
        <HUText fontStyle="BS" color={theme.neutralColor700}>
          {word("auth.signup.form.page.3.description")}
        </HUText>
        <FormikInput
          flex
          isInvalid={isFormFieldInValid("birthdate")}
          getFieldProps={formik.getFieldProps}
          name="birthdate"
          error={getFormErrorMessage("birthdate")}
          placeHolder={word("auth.signup.form.dateOfBirth")}
          type="calendar"
          calendarProps={{
            maxDate: dayjs().toDate(),
            showIcon: true,
            onBlur: (e) => {
              if (formik.handleBlur) formik.handleBlur(e, "birthdate")
            },
          }}
          formikStyle={{ flexGrow: 1 }}
        />
        <FormikInput
          flex
          formikStyle={{ flex: 1 }}
          name="nationality"
          type="dropdown"
          setFieldValue={formik.setFieldValue}
          isInvalid={isFormFieldInValid("nationality")}
          getFieldProps={formik.getFieldProps}
          error={getFormErrorMessage("nationality")}
          placeHolder={word("global.country.label")}
          dropdownProps={{
            options: availableCountries.map((countryOption) => countryOption.native),
            value: availableCountries.find((countryOption) => countryOption.code === formik.values.nationality)?.native,
            onChange: (e) => {
              const foundCountry = availableCountries.find((countryOption) => countryOption.native === e.target.value)
              formik.setFieldValue("nationality", foundCountry?.code)
            },
            onBlur: (e) => {
              if (formik.handleBlur) formik.handleBlur(e, "nationality")
            },
          }}
        />
      </HUStack>
      <HUButton
        type="Default"
        size="M"
        colorType="Primary"
        text={word("global.next")}
        style={{ width: "100%" }}
        onClick={handleSubmit}
        disabled={disabled}
        submit
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`
const WorldImage = styled.img`
  position: absolute;
  top: -12%;
  right: 0px;
`
