import { useServicesContext } from "@modules/core/services/services.context"
import { useAsync } from "@modules/hooks/useAsync"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"

export const useExerciseDashboard = (
  programId?: string | null,
  programType?: typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf | null,
  sessionId?: string | null,
  exerciseId?: string | null,
) => {
  const { sessionService } = useServicesContext()
  return useAsync(async () => {
    if (programId && sessionId && exerciseId && programType === ProgramInfiniteTypeOf) {
      return await sessionService.getProgramExerciseDashboard(programId, programType, sessionId, exerciseId)
    }
    return Promise.resolve(null)
  }, [programId, programType, sessionId, exerciseId])
}
