import {
  endDateTimeMaxDate,
  endDateTimeMinDate,
  startDateTimeMaxDate,
  startDateTimeMinDate,
} from "@modules/form/formUtils"
import dayjs from "dayjs"

export const useDateTimeCalendarProps = () => {
  const validateStartDateTime = (
    newStartDateTime: number,
    endDateTime: Date | null,
    set: (val: Date) => void,
    minDurationInMinutes?: number,
  ) => {
    const newStartDays = dayjs(newStartDateTime)
    if (!newStartDays.isBefore(endDateTime)) {
      if (newStartDays.hour() === 23) {
        set(newStartDays.set("minute", 59).toDate())
      } else {
        if (typeof minDurationInMinutes !== "undefined") set(newStartDays.add(minDurationInMinutes, "minute").toDate())
        else set(newStartDays.add(1, "hour").toDate())
      }
    }
  }

  const validateEndDateTime = (
    newEndDateTime: number,
    startDateTime: Date | null,
    set: (val: Date | null) => void,
    avoidNull: boolean = false,
    minDurationInMinutes?: number,
  ) => {
    const endDateTimeDaysjs = dayjs(newEndDateTime)
    if (
      !endDateTimeDaysjs.isAfter(startDateTime) ||
      endDateTimeDaysjs.format("HH:mm:ss ZZ") > dayjs(endDateTimeMaxDate).format("HH:mm:ss ZZ") ||
      endDateTimeDaysjs.format("HH:mm:ss ZZ") < dayjs(endDateTimeMinDate).format("HH:mm:ss ZZ")
    ) {
      if (avoidNull) {
        if (endDateTimeDaysjs.hour() === 0) {
          set(endDateTimeDaysjs.set("minute", 0).toDate())
        } else {
          if (typeof minDurationInMinutes !== "undefined")
            set(endDateTimeDaysjs.add(-minDurationInMinutes, "minute").toDate())
          else set(endDateTimeDaysjs.add(-1, "hour").toDate())
        }
      } else {
        set(null)
      }
    }
  }

  return {
    startDateTimeMinDate,
    startDateTimeMaxDate,
    endDateTimeMinDate,
    endDateTimeMaxDate,
    dateTimeFormat: "HH:mm",
    validateStartDateTime,
    validateEndDateTime,
  }
}
