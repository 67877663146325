import { ImageUploadIcon } from "@images/svgIcons/imageUploadIcon"
import { HUImage } from "@modules/types/imageTypes"
import { FileUpload, FileUploadRef } from "@modules/ui/components/fileUpload/fileUpload"
import { HUImageView } from "@modules/ui/components/huImageView"
import { HUText } from "@modules/ui/components/huText"
import { Button } from "primereact/button"
import { ProgressSpinner } from "primereact/progressspinner"
import { CSSProperties, useRef, useState } from "react"
import styled, { useTheme } from "styled-components"
import Cropper, { Area } from "react-easy-crop"
import getCroppedImg from "@modules/utils/cropImage"
import { HUButton } from "@modules/ui/components/huButton"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useServicesContext } from "@modules/core/services/services.context"

export type HUImageFormProps = {
  type: "logo" | "cover"
  image?: HUImage
  setImage?: (i?: HUImage) => void
  prefixPath?: string
  placeHolder?: {
    message: string
    style?: CSSProperties
  }
  showPreviewAsCover?: boolean
  onStartUpload?: () => void
  onEndUpload?: () => void
  setIsImageUploading?: (isImageUploading: boolean) => void
  onTouch?: () => void
  hideButton?: boolean
  size?: string
  acceptedTypes?: string
  resizer?: boolean
}

export const HUImageForm: React.FC<HUImageFormProps> = ({
  type,
  image,
  setImage,
  prefixPath,
  placeHolder,
  showPreviewAsCover = false,
  onStartUpload,
  setIsImageUploading,
  onEndUpload,
  onTouch,
  hideButton = false,
  size,
  acceptedTypes = "image/jpeg, image/png, image/bmp, image/webp, image/gif",
  resizer = false,
}) => {
  const theme = useTheme()
  const { assetService } = useServicesContext()
  const fileUploadRef = useRef<FileUploadRef>(null)
  const [loadingImage, setLoadingImage] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editedFile, setEditedFile] = useState<File>()
  const [rotation] = useState(0)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  })

  const setCroppedImage = async () => {
    try {
      if (editedFile) {
        const croppedImage = await getCroppedImg(editedFile, croppedAreaPixels)
        if (croppedImage && setImage) {
          const asset = await assetService.uploadImage(croppedImage.base64, prefixPath)
          setImage(asset)
          setEditedFile(croppedImage.newFile)
        }
        setEdit(false)
      }
    } catch (e) {
      console.error(LoggerType.UI, "Error during cropping image", e)
    }
  }

  return (
    <div className="flex flex-1 flex-column align-items-center mt-2 mb-2 relative">
      <LogoImageContainer className={`${type}-container`} $size={size}>
        {image ? (
          showPreviewAsCover ? (
            <div
              style={{
                width: "100%",
                height: "200px",
                overflow: "hidden",
                position: "relative",
                backgroundImage: `url(${image.path})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          ) : resizer && edit ? (
            <Cropper
              image={image.path}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              objectFit="cover"
              aspect={type === "logo" ? 4 / 4 : 4 / 3}
              cropShape={type === "logo" ? "round" : "rect"}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={(_: Area, croppedAreaPixels: Area) => {
                setCroppedAreaPixels(croppedAreaPixels)
              }}
            />
          ) : (
            <HUImageView image={image} className={type} />
          )
        ) : (
          <PlaceHolderContainer
            className={type}
            onClick={() => fileUploadRef.current?.click()}
            style={placeHolder?.style}
          >
            {loadingImage ? (
              <ProgressSpinnerStyled
                className="p-button-icon-left"
                strokeWidth="5"
                style={{ width: 16, height: 16, stroke: "var(--surface-a)" }}
              />
            ) : (
              <>
                <ImageUploadIcon color={theme.textTertiary} />
                {placeHolder && (
                  <div className="mt-2" style={{ textAlign: "center" }}>
                    <HUText fontStyle="LL">{` ${placeHolder.message}`}</HUText>
                  </div>
                )}
                {type === "logo" && !hideButton && (
                  <StyledLogoAddButton
                    type="button"
                    rounded
                    icon="pi pi-plus"
                    onClick={(e) => {
                      e.stopPropagation()
                      fileUploadRef.current?.click()
                    }}
                  />
                )}
              </>
            )}
          </PlaceHolderContainer>
        )}
      </LogoImageContainer>
      {resizer && image?.path && (
        <HUButton
          type="Rounded"
          size="M"
          colorType="Tertiary"
          onClick={() => (edit ? setCroppedImage() : setEdit(true))}
          icon={{ iconView: <span className={`pi pi-${edit ? "check" : "pencil"}`} /> }}
          className="h-2rem w-2rem"
          style={{
            backgroundColor: theme.mainWhite,
            position: "absolute",
            right: "-20px",
            top: "14px",
          }}
        />
      )}
      <FileUpload
        ref={fileUploadRef}
        showUploadButton={false}
        name={type}
        setImage={(i) => setImage?.(i)}
        currentImage={image}
        prefixPath={prefixPath}
        onStartUpload={() => {
          onStartUpload?.()
          setLoadingImage(true)
          setIsImageUploading?.(true)
        }}
        onEndUpload={() => {
          onEndUpload?.()
          setLoadingImage(false)
          setIsImageUploading?.(false)
        }}
        onTouch={onTouch}
        accept={acceptedTypes}
        setEdit={setEdit}
        setEditedImage={setEditedFile}
        cropper={resizer}
      />
    </div>
  )
}

const LogoImageContainer = styled.div<{ $size?: string }>`
  margin-bottom: 10px;

  &.logo-container {
    width: ${({ $size }) => $size ?? "150px"};
    height: ${({ $size }) => $size ?? "150px"};
    max-width: 100%;

    .logo {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }

  &.cover-container {
    width: ${({ $size }) => $size ?? "100%"};
    height: ${({ $size }) => $size ?? "200px"};

    & div {
      border-radius: 20px;
    }
  }

  .cover {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  img.logo {
    object-fit: cover;
  }

  img.cover {
    object-fit: contain;
  }

  div.logo,
  div.cover {
    background-color: var(--surface-overlay);
  }
`

const PlaceHolderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ProgressSpinnerStyled = styled(ProgressSpinner)`
  .p-progress-spinner-circle {
    stroke: ${({ theme }) => theme.textTertiary};
    animation: none;
  }
`

const StyledLogoAddButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
  border: 3px solid ${({ theme }) => theme.mainWhite} !important;

  &:focus {
    box-shadow: none;
  }
`
