import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const LanguageIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M15.8995 3.25119C15.3987 3.62232 15.0741 4.2177 15.0741 4.8889C15.0741 6.01392 14.162 6.92594 13.037 6.92594C11.912 6.92594 11 7.83795 11 8.96297C11 10.088 11.912 11 13.037 11C14.162 11 15.0741 11.912 15.0741 13.037C15.0741 14.1621 15.9861 15.0741 17.1111 15.0741H19.2138M15.8995 3.25119C14.4823 2.35323 12.8019 1.83334 11 1.83334C6.28164 1.83334 2.39592 5.39821 1.88926 9.98149M15.8995 3.25119C18.4641 4.87618 20.1666 7.73927 20.1666 11C20.1666 16.0626 16.0626 20.1667 11 20.1667C8.81699 20.1667 6.81223 19.4036 5.23788 18.1296M5.23788 18.1296L7.94442 18.1296C9.06945 18.1296 9.98146 17.2176 9.98146 16.0926C9.98146 14.9676 9.06945 14.0556 7.94442 14.0556C6.8194 14.0556 5.90739 13.1436 5.90739 12.0185C5.90739 10.8935 4.99538 9.98149 3.87035 9.98149H1.88926M5.23788 18.1296C3.16112 16.4491 1.83331 13.8796 1.83331 11C1.83331 10.6557 1.85229 10.3159 1.88926 9.98149"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
