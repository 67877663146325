import BackgroundImage from "@images/aladin-challenge/background-image.svg"
import { ChallengeDescription } from "@modules/aladinChallenge/components/challengeDescription"
import { ChallengeInformation } from "@modules/aladinChallenge/components/challengeInformation"
import { ChallengeParticipants } from "@modules/aladinChallenge/components/challengeParticipants"
import { ChallengeProgression } from "@modules/aladinChallenge/components/challengeProgression"
import { ChallengeTotals } from "@modules/aladinChallenge/components/challengeTotals"
import { ExerciseDashboard } from "@modules/exercises/exerciseTypes"
import { useExerciseDashboard } from "@modules/exercises/hooks/useExercises"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import styled from "styled-components"

export const AladinChallengePage: React.FC = () => {
  const [challengeResults, setChallengeResults] = useState<ExerciseDashboard>()
  const [searchParams] = useSearchParams()

  const programId = searchParams.get("programId")
  const programType = searchParams.get("programType")
  const sessionId = searchParams.get("sessionId")
  const exerciseId = searchParams.get("exerciseId")

  const maxCal = useMemo(() => {
    searchParams.get("maxCal")
    const urlMaxCal = searchParams.get("maxCal")
    return urlMaxCal && !isNaN(parseInt(urlMaxCal)) ? parseInt(urlMaxCal) : 10000
  }, [])

  const maxAmount = useMemo(() => {
    searchParams.get("maxAmount")
    const urlMaxAmount = searchParams.get("maxAmount")
    return urlMaxAmount && !isNaN(parseInt(urlMaxAmount)) ? parseInt(urlMaxAmount) : 1500
  }, [])

  const { value, loading, refresh, refreshing } = useExerciseDashboard(
    programId,
    programType as typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf,
    sessionId,
    exerciseId,
  )

  useEffect(() => {
    const interval = setInterval(() => {
      refresh()
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!loading && !refreshing && value) {
      // Prevents a re-render if value gets null while refreshing
      setChallengeResults(value)
    }
  }, [value])

  return (
    <PageContainer gap={30}>
      {!challengeResults ? (
        <LoadingSpinner />
      ) : (
        <>
          <ChallengeTotals challengeResults={challengeResults} maxCal={maxCal} maxAmount={maxAmount} />
          <HURow className="justify-content-around flex-grow-1">
            <ChallengeProgression challengeResults={challengeResults} maxCal={maxCal} />
            <HUStack>
              <ChallengeDescription />
              <HURow className="justify-content-around flex-grow-1 mt-4">
                <ChallengeParticipants challengeResults={challengeResults} />
                <ChallengeInformation maxCal={maxCal} />
              </HURow>
            </HUStack>
          </HURow>
        </>
      )}
    </PageContainer>
  )
}

export const PageContainer = styled(HUStack)`
  background-color: ${({ theme }) => theme.mainBlack};
  min-width: 100vw;
  min-height: 100vh;
  background-image: url(${BackgroundImage});
  background-position: bottom 0px right 0px;
  background-repeat: no-repeat;
  padding: 30px 30px 50px 30px;
  overflow: hidden;
`
