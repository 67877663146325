import { BenchmarkTypeOf, ItemBenchmark } from "@modules/benchmark/benchmarkTypes"
import { BoxTypeOf, ItemBox } from "@modules/box/boxTypes"
import { Profile, ProfileTypeOf } from "@modules/profile/profileTypes"
import { ItemProgram, ProgramContentTypeOf } from "@modules/programs/programTypes"

export const ExploreContentTypes = [BenchmarkTypeOf, ProgramContentTypeOf, BoxTypeOf, ProfileTypeOf] as const
export type ExploreContentType = (typeof ExploreContentTypes)[number]
export enum ExploreFilter {
  DEFAULT = "DEFAULT",
  BENCHMARK = "BENCHMARK",
  BOX = "BOX",
  PROGRAM = "PROGRAM",
  PROFILE = "USER_PROFILE",
}

export const AvailableExploreContentTypes: ExploreContentType[] = [
  ProgramContentTypeOf,
  BenchmarkTypeOf,
  BoxTypeOf,
  ProfileTypeOf,
]

export type GlobalExploreContent = {
  id: string
  type: ExploreContentType
  benchmark?: ItemBenchmark
  program?: ItemProgram
  box?: ItemBox
}

export type ExploreProgramContent = {
  id: string
  type: typeof ProgramContentTypeOf
  benchmark: undefined
  program: ItemProgram
  box: undefined
  profile: undefined
} & GlobalExploreContent

export type ExploreBenchmarkContent = {
  id: string
  type: typeof BenchmarkTypeOf
  benchmark: ItemBenchmark
  program: undefined
  box: undefined
  profile: undefined
} & GlobalExploreContent

export type ExploreBoxContent = {
  id: string
  type: typeof BoxTypeOf
  benchmark: undefined
  program: undefined
  box: ItemBox
  profile: undefined
} & GlobalExploreContent

type NewType = {
  id: string
  type: typeof ProfileTypeOf
  benchmark: undefined
  program: undefined
  box: undefined
  profile: Profile
}

export type ExploreProfileContent = NewType & GlobalExploreContent

export type ExploreContent = ExploreBenchmarkContent | ExploreProgramContent | ExploreBoxContent | ExploreProfileContent

export type ExploreContentActivity = ItemProgram | ItemBox | ItemBenchmark | Profile

export function convertToExploreContent(content?: ExploreContentActivity): ExploreContent | undefined {
  if (!content) return undefined

  switch (content && content._type) {
    case BenchmarkTypeOf:
      return {
        id: content.id,
        type: content._type,
        benchmark: content,
      } as ExploreBenchmarkContent
    case ProgramContentTypeOf:
      return {
        id: content.id,
        type: content._type,
        program: content,
      } as ExploreProgramContent
    case BoxTypeOf:
      return {
        id: content.id,
        type: content._type,
        box: content,
      } as ExploreBoxContent
    case ProfileTypeOf:
      return {
        id: content.id,
        type: content._type,
        profile: content,
      } as ExploreProfileContent
    default:
      return undefined
  }
}

export function convertFromExploreContent(exploreContent: ExploreContent): ExploreContentActivity | undefined {
  switch (exploreContent.type) {
    case BenchmarkTypeOf:
      return exploreContent.benchmark
    case BoxTypeOf:
      return exploreContent.box
    case ProgramContentTypeOf:
      return exploreContent.program
    case ProfileTypeOf:
      return exploreContent.profile
    default:
      return undefined
  }
}

export type GlobalExploreHistoryContent = {
  id: string
  contentType: ExploreContentType
  content: ExploreContentActivity
}

export type ExploreHistoryProgramContent = {
  id: string
  contentType: typeof ProgramContentTypeOf
  content: ItemProgram
} & GlobalExploreHistoryContent

export type ExploreHistoryBenchmarkContent = {
  id: string
  contentType: typeof BenchmarkTypeOf
  content: ItemBenchmark
} & GlobalExploreHistoryContent

export type ExploreHistoryBoxContent = {
  id: string
  contentType: typeof BoxTypeOf
  content: ItemBox
} & GlobalExploreHistoryContent

export type ExploreHistoryProfileContent = {
  id: string
  contentType: typeof ProfileTypeOf
  content: Profile
} & GlobalExploreHistoryContent

export type ExploreHistoryContent =
  | ExploreHistoryBenchmarkContent
  | ExploreHistoryProgramContent
  | ExploreHistoryBoxContent
  | ExploreHistoryProfileContent
