import { CalendarContext } from "@modules/calendar/context/calendarContext"
import { useContext } from "react"

export const useCalendar = () => {
  const {
    selectedDate,
    setSelectedDate,
    currentWeekTitle,
    currentWeekdays,
    displayDetailedEvents,
    setDisplayDetailedEvents,
    currentView,
    setCurrentView,
    refreshCalendarEvents,
    isUndated,
    isWeekTemplate,
    hasHours,
    maxDayIndex,
    draggingEvent,
    setDraggingEvent,
    isDragging,
    setIsDragging,
  } = useContext(CalendarContext)

  const next = () => {
    setSelectedDate((date) => {
      if (isUndated && typeof date === "number") return date + 7
      else return typeof date !== "number" ? date?.add(7, "days") : undefined
    })
  }

  const prev = () => {
    setSelectedDate((date) => {
      if (isUndated && typeof date === "number") return date - 7
      else return typeof date !== "number" ? date?.add(-7, "days") : undefined
    })
  }

  return {
    selectedDate,
    setSelectedDate,
    next,
    prev,
    currentWeekTitle,
    displayDetailedEvents,
    setDisplayDetailedEvents,
    currentWeekdays,
    currentView,
    setCurrentView,
    refreshCalendarEvents,
    isUndated,
    isWeekTemplate,
    hasHours,
    maxDayIndex,
    draggingEvent,
    setDraggingEvent,
    isDragging,
    setIsDragging,
  }
}
