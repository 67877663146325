import {
  AddressFormikType,
  AddressInputKey,
  convertAddressToFormik,
  getAddressValidationSchema,
} from "@modules/address/addressFormikType"
import { word } from "@modules/core/utils/i18n"
import { phoneRegExp, requiredInput } from "@modules/form/formUtils"
import { MemberDetailedDraft } from "@modules/members/memberTypes"
import * as Yup from "yup"

export type DetailedMemberInputKey =
  | "firstname"
  | "lastname"
  | "username"
  | "birthDate"
  | "email"
  | "phoneNumber"
  | "subscriptionId"
  | "emergencyInformation"
  | "note"
  | AddressInputKey

export type MemberInputKey = "firstname" | "lastname" | "name" | "present" | "inscriptionDate" | "status" | "activeOffer" | "lastVisit" | "assignedCoach" | "note" | "noshow"

export function getValidationSchema() {
  return Yup.object({
    firstname: requiredInput(Yup.string().max(64, word("form.maximum.characters", { count: 64 }))),
    lastname: requiredInput(Yup.string()).max(64, word("form.maximum.characters", { count: 64 })),
    username: Yup.string()
      .required()
      .max(64, word("form.maximum.characters", { count: 64 })),
    email: requiredInput(
      Yup.string()
        .max(255, word("form.maximum.characters", { count: 255 }))
        .email(word("box.form.email.error")),
    ),
    birthDate: Yup.date().nullable(),
    phoneNumber: Yup.string().matches(phoneRegExp, word("box.form.phone.error")),
    emergencyInformation: Yup.string(),
    note: Yup.string(),
    subscriptionId: Yup.string(),
  }).concat(getAddressValidationSchema())
}

export const convertMemberToFormik = (member: MemberDetailedDraft): MemberFormikType => {
  return {
    ...member,
    birthDate: member.birthDate,
    ...convertAddressToFormik(member.address),
  }
}

export type MemberFormikType = {
  firstname: string
  lastname: string
  username?: string
  birthDate?: Date
  phoneNumber: string
  email: string
  emergencyInformation: string
  note: string
  subscriptionId?: string
} & AddressFormikType
