import { BoxProgrammationVisibilityPrivateIcon } from "@images/svgIcons/boxProgrammationVisibilityPrivate"
import { BoxProgrammationVisibilityPublicIcon } from "@images/svgIcons/boxProgrammationVisibilityPublic"
import { BoxProgrammationVisibilityStandardIcon } from "@images/svgIcons/boxProgrammationVisibilityStandard"
import { BoxProgrammationVisibilityVector } from "@images/svgIcons/boxProgrammationVisibilityVector"
import { BoxProgrammationVisibility } from "@modules/boxProgrammation/boxProgrammationTypes"
import { getBoxProgrammationVisibilityName } from "@modules/boxProgrammation/boxProgrammationUtils"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { useTheme } from "styled-components"

export const BoxProgrammationPrivacyIcon: React.FC<{ visibility: BoxProgrammationVisibility; isSelected: boolean }> = ({
  visibility,
  isSelected,
}) => {
  const theme = useTheme()

  return (
    <HUStack gap={6} className="align-items-center">
      <HUText fontStyle="TS">{getBoxProgrammationVisibilityName(visibility)}</HUText>

      <div className="relative w-max">
        <BoxProgrammationVisibilityVector color={isSelected ? theme.primaryColor : theme.neutralColor300} />
        {visibility === BoxProgrammationVisibility.MEMBERS ? (
          <BoxProgrammationVisibilityStandardIcon
            color={theme.neutral300}
            className="absolute top-50 left-50"
            style={{ transform: "translate(-50%, -70%)" }}
          />
        ) : visibility === BoxProgrammationVisibility.PRIVATE ? (
          <BoxProgrammationVisibilityPrivateIcon
            color={theme.neutral300}
            className="absolute top-50 left-50"
            style={{ transform: "translate(-50%, -70%)" }}
          />
        ) : (
          <BoxProgrammationVisibilityPublicIcon
            color={theme.neutral300}
            className="absolute top-50 left-50"
            style={{ transform: "translate(-50%, -70%)" }}
          />
        )}
      </div>
    </HUStack>
  )
}
