import { word } from "@modules/core/utils/i18n"
import { HistoryDetails } from "@modules/finances/components/historyDetails"
import { InvoiceDetails } from "@modules/finances/components/invoiceDetails"
import { InvoiceDetailsTable } from "@modules/finances/components/invoiceDetailsTable"
import { UserTransaction } from "@modules/finances/types/transactionType"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { useToast } from "@modules/ui/components/huToast"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { TabMenu } from "primereact/tabmenu"
import { useMemo, useState } from "react"

type TabObject = {
  label: string
  command: () => void
  filter: "ALL"
}

type TransactionsDetailsProps = { transactionDetails: UserTransaction }

export const TransactionsDetailsContainer: React.FC<TransactionsDetailsProps> = ({ transactionDetails }) => {
  const [isDownloadingInvoiceFile, setIsDownloadingInvoiceFile] = useState(false)

  const toast = useToast()
  const selectedTab = "ALL"

  enum FinanceTabs {
    INVOICE = "INVOICE",
    HISTORY = "HISTORY",
  }
  const [transactionTab, setTransactionTab] = useState<string>(FinanceTabs.INVOICE)

  const language = useLanguage()
  const transactionsOptions = useMemo<TabObject[]>(
    () => [
      {
        label: word("finances.invoice"),
        command: () => {
          setTransactionTab(FinanceTabs.INVOICE)
        },
        filter: "ALL",
      },
      {
        label: word("finances.history"),
        command: () => {
          setTransactionTab(FinanceTabs.HISTORY)
        },
        filter: "ALL",
      },
    ],
    [language],
  )
  const activeIndex = transactionsOptions.findIndex((p) => p.filter === selectedTab)

  const fetchInvoice = () => {
    if (transactionDetails.invoice?.invoicePdfUrl) {
      try {
        setIsDownloadingInvoiceFile(true)
        const link = document.createElement("a")
        link.download = transactionDetails.invoice.number + ".pdf"

        link.href = transactionDetails.invoice.invoicePdfUrl

        link.click()
      } catch (e) {
        toast.show({
          severity: "error",
          summary: word("global.error.label"),
        })
      } finally {
        setTimeout(() => setIsDownloadingInvoiceFile(false), 1000) // Simulate loading hack
      }
    }
  }

  return (
    <HUStack gap={0}>
      <TabMenu model={transactionsOptions} activeIndex={activeIndex} />
      {transactionTab === FinanceTabs.INVOICE && (
        <>
          <InvoiceDetails transactionDetails={transactionDetails} />
          <InvoiceDetailsTable transactionDetails={transactionDetails} />
          {transactionDetails.invoice?.invoicePdfUrl && (
            <HURow className="mt-4 justify-content-end">
              <HUNavigateButton
                link={false}
                to={transactionDetails.invoice.invoicePdfUrl}
                label={word("finances.array.invoice.download")}
                icon={{
                  iconView: (
                    <span
                      className={`p-button-icon p-c ${
                        isDownloadingInvoiceFile ? "pi pi-spin pi-spinner" : "pi pi-download"
                      }`}
                    />
                  ),
                }}
                iconPosition="right"
                onPress={fetchInvoice}
              />
            </HURow>
          )}
        </>
      )}
      {transactionTab === FinanceTabs.HISTORY && <HistoryDetails transactionDetails={transactionDetails} />}
    </HUStack>
  )
}
