import { AuthService } from "@modules/auth/services/authService"
import { Lock } from "@modules/core/Lock"
import { ApiInterceptor } from "@modules/core/api/interceptors/apiInterceptor"
import { AxiosInstance, AxiosResponse, isAxiosError } from "axios"
import _ from "lodash"

export const responseUnauthorizeErrorFallbackInterceptor = (
  axios: Readonly<AxiosInstance>,
  authService: AuthService,
  tokenRefreshingLock: Lock,
): ApiInterceptor<AxiosResponse> => ({
  onRejected: async (error) => {
    if (isAxiosError(error) && error.response?.status === 401 && !_.get(error, "config.__isRetry")) {
      if (tokenRefreshingLock.exist()) {
        await tokenRefreshingLock.isReleased()
        return await axios.request(error.response.config)
      } else {
        tokenRefreshingLock.create()
        try {
          _.set(error, "config.__isRetry", true)
          await authService.getValidToken(true)
          // keep this request first -> do request before release lock
          const result = axios.request(error.response.config)
          tokenRefreshingLock.release()
          return await result
        } catch (e) {
          tokenRefreshingLock.release(e)
          return Promise.reject(e)
        }
      }
    }

    return Promise.reject(error)
  },
})
