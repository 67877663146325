import {
  BoxSlotTemplateGroupFormikType,
  BoxSlotTemplateGroupInputKey,
} from "@modules/boxSlotTemplate/boxSlotTemplateGroupFormikTypes"
import { word } from "@modules/core/utils/i18n"
import { convertTimeStringToTimeValue } from "@modules/duration/timeConverter"
import { useDateTimeCalendarProps } from "@modules/form/hooks/useDateTimeCalendarProps"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HURow } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import styled, { useTheme } from "styled-components"

export const SlotTemplateRange: React.FC<{
  formik: SimpleFormik<BoxSlotTemplateGroupFormikType, BoxSlotTemplateGroupInputKey>
  removeRange?: (arg: number) => void
  index?: number
  addNewRange?: () => void
  displayAddRangeButton?: boolean
  displayLabels?: boolean
}> = ({ index, formik, addNewRange, displayAddRangeButton, removeRange, displayLabels }) => {
  const theme = useTheme()
  const { startDateTimeMinDate, endDateTimeMinDate, dateTimeFormat, validateStartDateTime, validateEndDateTime } =
    useDateTimeCalendarProps()

  const currentSlotTemplateRange =
    typeof index !== "undefined" ? formik.values.slotTemplatesRanges.find((range) => range.index === index) : null
  const isPlaceholder = typeof index === "undefined" || !currentSlotTemplateRange
  const itemIndex = formik.values.slotTemplatesRanges.findIndex((range) => range.index === index)

  if (!currentSlotTemplateRange && !isPlaceholder) return null

  return (
    <HURow gap={20}>
      <FormikInput
        flex
        disabled={isPlaceholder}
        formikStyle={{ display: "flex", flexGrow: 1 }}
        label={displayLabels ? word("programmation.slotTemplates.form.hour.startTime") : ""}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid(`slotTemplatesRanges[${itemIndex}].startTime`, formik)}
        error={getFormErrorMessage(`slotTemplatesRanges[${itemIndex}].startTime`, formik)}
        name={`slotTemplatesRanges[${itemIndex}].startTime`}
        type="calendarTime"
        calendarProps={{
          minDate: startDateTimeMinDate,
          datetimeformat: dateTimeFormat,
          placeholder: "08:00",
          value: !isPlaceholder && currentSlotTemplateRange.startTime ? currentSlotTemplateRange.startTime : null,
          onChange: (event) => {
            const newValue = event.value?.valueOf()
            if (typeof newValue === "number" && typeof itemIndex !== "undefined" && currentSlotTemplateRange) {
              validateStartDateTime(
                newValue,
                currentSlotTemplateRange.endTime,
                (val) => formik.setFieldValue(`slotTemplatesRanges[${itemIndex}].endTime`, val),
                convertTimeStringToTimeValue("HH:mm", formik.values.slotDuration) ?? undefined,
              )
            }
          },
        }}
      />
      <FormikInput
        flex
        disabled={isPlaceholder}
        formikStyle={{ display: "flex", flexGrow: 1 }}
        label={displayLabels ? word("programmation.slotTemplates.form.hour.endTime") : ""}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid(`slotTemplatesRanges[${itemIndex}].endTime`, formik)}
        error={getFormErrorMessage(`slotTemplatesRanges[${itemIndex}].endTime`, formik, {
          errorMessage: word("sessionForm.error.date"),
        })}
        name={`slotTemplatesRanges[${itemIndex}].endTime`}
        type="calendarTime"
        calendarProps={{
          minDate: endDateTimeMinDate,
          datetimeformat: dateTimeFormat,
          value: !isPlaceholder && currentSlotTemplateRange.endTime ? currentSlotTemplateRange.endTime : null,
          placeholder: "19:00",
          onChange: (event) => {
            const newValue = event.value?.valueOf()
            if (typeof newValue === "number" && typeof itemIndex !== "undefined" && currentSlotTemplateRange) {
              validateEndDateTime(
                newValue,
                currentSlotTemplateRange.startTime,
                (val) => formik.setFieldValue(`slotTemplatesRanges[${itemIndex}].startTime`, val),
                true,
                convertTimeStringToTimeValue("HH:mm", formik.values.slotDuration) ?? undefined,
              )
            }
          },
        }}
      />
      {!isPlaceholder && (
        <ButtonsContainer $marginBottom={displayAddRangeButton ? "1rem" : "1.8rem"}>
          {removeRange && (
            <TemplateRangeButton color={theme.mainWhite} onClick={() => removeRange(index)} className="pi pi-minus" />
          )}
          {displayAddRangeButton && (
            <TemplateRangeButton color={theme.mainWhite} onClick={addNewRange} className="pi pi-plus" />
          )}
        </ButtonsContainer>
      )}
    </HURow>
  )
}

const ButtonsContainer = styled.div<{ $marginBottom: string }>`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 5px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
`

const TemplateRangeButton = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  font-size: 14px;
  padding: 2px;
  border: 1px solid ${({ theme }) => theme.mainWhite};
  cursor: pointer;
  align-self: end;
  transition: all 0.2s ease;
  &:hover {
    border: 1px solid ${({ theme }) => theme.neutralColor};
  }
`
