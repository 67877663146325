import slotImage from "@images/box/illustration_slot.svg"
import templateImage from "@images/box/illustration_template.png"
import { ArrowToTheTopIcon } from "@images/svgIcons/arrowToTheTopIcon"
import { TypicalWeekIcon } from "@images/svgIcons/typicalWeekIcon"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import React from "react"
import styled, { useTheme } from "styled-components"

export type BoxSlotTemplateToolProps = {
  hideTemplateExplanation: () => void
}
export const BoxSlotTemplateToolComponent: React.FC<BoxSlotTemplateToolProps> = ({ hideTemplateExplanation }) => {
  const theme = useTheme()
  return (
    <Container>
      <Title fontStyle="HL">{word("box.activity.inform.complementayTools")}</Title>
      <ContainerBlocs>
        <Bloc>
          <HUText fontStyle="BS">{word("box.activity.inform.singleSlot")}</HUText>
          <img src={slotImage} />
        </Bloc>
        <Bloc>
          <HURow gap={10}>
            <TypicalWeekIcon color={theme.mainWhite} />
            <HUText fontStyle="LM">{word("box.programmation.activity.typicalWeek")}</HUText>
          </HURow>
          <HUText fontStyle="BS">{word("box.activity.inform.template")}</HUText>
          <img src={templateImage} className="h-7rem" />
        </Bloc>
      </ContainerBlocs>
      <Arrow color={theme.neutralColor300} />
      <HUButton
        colorType="Primary"
        size="S"
        type="Default"
        onClick={() => hideTemplateExplanation()}
        text={word("global.understood")}
      />
    </Container>
  )
}

const Title = styled(HUText)`
  text-align: center;
`

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 44px 48px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  background-color: ${({ theme }) => theme.surfaceCard};
  min-height: calc(100vh - 300px);
  border-radius: 33px;
  min-width: 500px;
`

const ContainerBlocs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  align-self: stretch;
  margin-top: 15px;
`

const Bloc = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
  border: ${({ theme }) => `1px solid ${theme.neutralColor200}`};
  align-items: center;
  width: 340px;
  height: 240px;
  gap: 10px;
`

const Arrow = styled(ArrowToTheTopIcon)`
  position: absolute;
  right: 27px;
  bottom: 120px;
  width: 82px;
  height: 440px;
`
