import { BenchmarkTypeOf } from "@modules/benchmark/benchmarkTypes"
import { ItemBenchmarkDto, convertItemBenchmarkFromDto } from "@modules/benchmark/services/benchmarkDto"
import { BoxTypeOf } from "@modules/box/boxTypes"
import { ItemBoxDto, convertItemBoxFromDto } from "@modules/box/services/boxDto"
import {
  ExploreContent,
  ExploreContentType,
  ExploreHistoryContent,
  convertToExploreContent,
} from "@modules/explore/exploreTypes"
import { ProfileDto, convertProfileFromDto } from "@modules/profile/profileDtos"
import { ProfileTypeOf } from "@modules/profile/profileTypes"
import { ProgramContentTypeOf } from "@modules/programs/programTypes"
import { ItemProgramDto, convertItemProgramFromDto } from "@modules/programs/services/programDto"
import _ from "lodash"

export type ItemExploreContentTypesDto = ItemBenchmarkDto | ItemProgramDto | ItemBoxDto | ProfileDto

type ExploreBaseContentDto = {
  contentType: string
  content: ItemExploreContentTypesDto
}

export type ExploreBenchmarkContentDto = {
  contentType: typeof BenchmarkTypeOf
  content: ItemBenchmarkDto
} & ExploreBaseContentDto

export type ExploreProgamContentDto = {
  contentType: typeof ProgramContentTypeOf
  content: ItemProgramDto
} & ExploreBaseContentDto

export type BoxContentDto = {
  contentType: typeof BoxTypeOf
  content: ItemBoxDto
} & ExploreBaseContentDto

export type ProfileContentDto = {
  contentType: typeof ProfileTypeOf
  content: ProfileDto
} & ExploreBaseContentDto

export type ExploreContentDto = ExploreBenchmarkContentDto | ExploreProgamContentDto | BoxContentDto | ProfileContentDto

export function convertExploreContentFromDto(dto: ExploreContentDto): ExploreContent | undefined {
  if (dto.contentType === BenchmarkTypeOf) {
    return convertToExploreContent(convertItemBenchmarkFromDto(dto.content))
  } else if (dto.contentType === ProgramContentTypeOf) {
    return convertToExploreContent(convertItemProgramFromDto(dto.content))
  } else if (dto.contentType === BoxTypeOf) {
    return convertToExploreContent(convertItemBoxFromDto(dto.content))
  } else if (dto.contentType === ProfileTypeOf) {
    return convertToExploreContent(convertProfileFromDto(dto.content))
  }

  return undefined
}

export type ExploreHistoryGeneralDto = {
  id: string
  contentType: ExploreContentType
  content: ItemExploreContentTypesDto
}

export type ExploreHistoryBenchmarkDto = {
  contentType: typeof BenchmarkTypeOf
  content: ItemBenchmarkDto
} & ExploreHistoryGeneralDto

export type ExploreHistoryProgramDto = {
  contentType: typeof ProgramContentTypeOf
  content: ItemProgramDto
} & ExploreHistoryGeneralDto

export type ExploreHistoryBoxDto = {
  contentType: typeof BoxTypeOf
  content: ItemBoxDto
} & ExploreHistoryGeneralDto

export type ExploreHistoryProfileDto = {
  contentType: typeof ProfileTypeOf
  content: ProfileDto
} & ExploreHistoryGeneralDto

export type ExploreHistoryDto =
  | ExploreHistoryProgramDto
  | ExploreHistoryBenchmarkDto
  | ExploreHistoryBoxDto
  | ExploreHistoryProfileDto

export function convertExploreHistoryFromDto(exploreHistoryDto: readonly ExploreHistoryDto[]): ExploreHistoryContent[] {
  return _.compact(exploreHistoryDto.map((dto) => convertExploreHistoryElementFromDto(dto)))
}

export function convertExploreHistoryElementFromDto(
  exploreHistoryDto: ExploreHistoryDto,
): ExploreHistoryContent | undefined {
  if (exploreHistoryDto.contentType === BenchmarkTypeOf) {
    return {
      id: exploreHistoryDto.id,
      contentType: exploreHistoryDto.contentType,
      content: convertItemBenchmarkFromDto(exploreHistoryDto.content),
    }
  } else if (exploreHistoryDto.contentType === ProgramContentTypeOf) {
    const programContent = convertItemProgramFromDto(exploreHistoryDto.content)
    return (
      programContent && {
        id: exploreHistoryDto.id,
        contentType: exploreHistoryDto.contentType,
        content: programContent,
      }
    )
  } else if (exploreHistoryDto.contentType === BoxTypeOf) {
    return {
      id: exploreHistoryDto.id,
      contentType: exploreHistoryDto.contentType,
      content: convertItemBoxFromDto(exploreHistoryDto.content),
    }
  } else if (exploreHistoryDto.contentType === ProfileTypeOf) {
    return {
      id: exploreHistoryDto.id,
      contentType: exploreHistoryDto.contentType,
      content: convertProfileFromDto(exploreHistoryDto.content),
    }
  } else {
    return undefined
  }
}
