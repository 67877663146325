import { AuthService } from "@modules/auth/services/authService"
import { Lock } from "@modules/core/Lock"
import { ApiInterceptor } from "@modules/core/api/interceptors/apiInterceptor"
import { LoggerType } from "@modules/utils/loggerUtils"
import { InternalAxiosRequestConfig } from "axios"

export const requestAuthorizationInterceptor: (
  authService: AuthService,
  jwtTokenRefreshingLock: Lock,
) => ApiInterceptor<InternalAxiosRequestConfig> = (authService, jwtTokenRefreshingLock) => ({
  onFulfilled: async (config) => {
    if (jwtTokenRefreshingLock.exist()) {
      console.log(LoggerType.Token + "waiting refreshing token")
      await jwtTokenRefreshingLock.isReleased()
    }

    const token = await authService.getValidToken()
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
})
