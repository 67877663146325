import { getAuthError } from "@modules/auth/authError"
import { AUTH_SPECIAL_CHARACTERS } from "@modules/auth/authUtils"
import {
  AuthContent,
  AuthForm,
  AuthFormError,
  AuthHeader,
  AuthSubmitButton,
} from "@modules/auth/components/authComponents"
import { LoginBack } from "@modules/auth/components/loginBack"
import { AuthPage } from "@modules/auth/page/authPage"
import { useServicesContext } from "@modules/core/services/services.context"
import { i18n, word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { HUCheckbox } from "@modules/ui/components/huCheckbox"
import { HUInput } from "@modules/ui/components/huInput"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoggerType } from "@modules/utils/loggerUtils"
import { isValidEmail, isValidPassword } from "@modules/utils/valid"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const SignUpPage: React.FC = () => {
  const { userService } = useServicesContext()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isTandCChecked, setIsTandCChecked] = useState<boolean>(false)
  const [isCharterChecked, setIsCharterChecked] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const navigate = useNavigate()
  const CGUUrl = Path.Eula
  const UseCharter = Path.UseCharter

  const theme = useTheme()
  const signUp = async () => {
    try {
      setLoading(true)
      if (!isValidEmail(email)) {
        setError(word("auth.signup.form.mail_invalid"))
      } else if (!isValidPassword(password)) {
        if (!AUTH_SPECIAL_CHARACTERS.some((c) => password.includes(c))) {
          setError(
            `${word("auth.signup.form.password_invalid")}. ${word("auth.signup.form.pasword_special_character_list")}: ${AUTH_SPECIAL_CHARACTERS.join(" ")}`,
          )
        } else setError(word("auth.signup.form.password_invalid"))
      } else {
        await userService.signUp(email, password, i18n.locale)
        navigate({ pathname: Path.ConfirmEmail }, { state: { email } })
      }
    } catch (e) {
      console.error(LoggerType.SignUp, e)
      setError(getAuthError(e))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setError("")
  }, [email, password])

  return (
    <AuthPage>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault()
          signUp()
        }}
      >
        <AuthHeader title={word("auth.signup.form.title")} subtitle={word("auth.signup.form.subtitle")} />
        <AuthContent>
          <Stack>
            <HUInput
              placeholder={word("auth.form.placeholder.email")}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              hasError={!!error}
            />
            <HUInput
              placeholder={word("auth.signup.form.placeholder.mdp")}
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              hasError={!!error}
              password
            />
            <HUText fontStyle="BS" color={theme.neutralColor600}>
              {word("auth.signup.form.tickBoxesMessage")}
            </HUText>
            <HUStack className="pl-4" gap={10}>
              <HURow className="align-items-center" gap={13}>
                <RoundCheckbox
                  inputKey={"TandC"}
                  name="option"
                  checked={isTandCChecked}
                  onChange={() => setIsTandCChecked(!isTandCChecked)}
                  color={theme.primaryColor}
                  checkIconColor={theme.neutralColor700}
                />
                <HUText fontStyle="LL">{word("auth.signup.form.termsAndConditions")}</HUText>
                <ReadText fontStyle="BS">
                  <a href={CGUUrl} target="_blank" rel="noreferrer">
                    {word("global.read")}
                  </a>
                </ReadText>
              </HURow>
              <HURow className="align-items-center" gap={13}>
                <RoundCheckbox
                  inputKey={"useCharter"}
                  name="option"
                  checked={isCharterChecked}
                  onChange={() => setIsCharterChecked(!isCharterChecked)}
                  color={theme.primaryColor}
                  checkIconColor={theme.neutralColor700}
                />
                <HUText fontStyle="LL">{word("auth.signup.form.useCharter")}</HUText>
                <ReadText fontStyle="BS">
                  <a href={UseCharter} target="_blank" rel="noreferrer">
                    {word("global.read")}
                  </a>
                </ReadText>
              </HURow>
            </HUStack>
            <AuthSubmitButton
              loading={loading}
              disabled={!email || !password || !!error || !isTandCChecked || !isCharterChecked}
            >
              {word("auth.signup.form.button.signup")}
            </AuthSubmitButton>
            <AuthFormError>{error}</AuthFormError>
            <AuthFormError>
              {!isValidPassword(password) && !!password && word("auth.signup.form.info_valid_password")}
            </AuthFormError>
          </Stack>
          <LoginBack />
        </AuthContent>
      </AuthForm>
    </AuthPage>
  )
}

const Stack = styled(HUStack)`
  margin-top: 20px;
`

const ReadText = styled(HUText)`
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.textPrimary};
    &:hover {
      color: var(--primary-500);
    }
  }
`

const RoundCheckbox = styled(HUCheckbox)`
  .p-checkbox-box {
    border-radius: 30px;
    border-color: ${({ theme }) => theme.checkboxSecondaryColor};
  }

  &:not(.p-disabled):hover > .p-checkbox-box {
    border-color: ${({ theme }) => theme.checkboxSecondaryColor}!important;
  }

  &.p-highlight > .p-checkbox-box {
    border-color: ${({ theme }) => theme.checkboxSecondaryColor}!important;
    background: ${({ theme }) => theme.checkboxSecondaryColor}!important;
  }
  .p-checkbox-icon {
    color: white !important;
  }
`
