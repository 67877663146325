import { AddressInputKey } from "@modules/address/addressFormikType"
import { UserDraft } from "@modules/auth/user"
import { word } from "@modules/core/utils/i18n"
import { HUImage } from "@modules/types/imageTypes"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import { nameRegExp, usernameRegExp } from "@modules/utils/regexp"
import dayjs from "dayjs"
import * as Yup from "yup"

export type ProfileFormikType = {
  firstname: string
  lastname: string
  username: string
  birthdate?: string
  birthdateVisibility?: boolean
  genderCategory?: "MALE" | "FEMALE" | null
  genderCategoryVisibility?: boolean
  nationality?: string
  nationalityVisibility?: boolean
  avatar?: HUImage
}

export type ProfileInputKey =
  | "firstname"
  | "lastname"
  | "username"
  | "avatar"
  | "birthdate"
  | "genderCategory"
  | "nationality"
  | AddressInputKey

export function convertProfileToFormik(userDraft: UserDraft): ProfileFormikType {
  return {
    firstname: userDraft.firstname,
    lastname: userDraft.lastname,
    username: userDraft.username,
    birthdate: userDraft.birthdate?.value,
    birthdateVisibility: userDraft.birthdate?.visibility.all,
    genderCategory: userDraft.genderCategory?.value,
    genderCategoryVisibility: userDraft.genderCategory?.visibility.all,
    nationality: userDraft.nationality?.value,
    nationalityVisibility: userDraft.nationality?.visibility.all,
  }
}

export function convertProfileFormFormik(formik: ProfileFormikType): UserDraft {
  return {
    firstname: formik.firstname,
    lastname: formik.lastname,
    username: formik.username,
    birthdate: formik.birthdate
      ? {
          value: dayjs(formik.birthdate).format(defaultDateFormat),
          visibility: { all: formik.birthdateVisibility ?? true },
        }
      : undefined,
    genderCategory: formik.genderCategory
      ? {
          value: formik.genderCategory ?? null,
          visibility: {
            all: formik.genderCategoryVisibility ?? true,
          },
        }
      : undefined,
    nationality: formik.nationality
      ? {
          value: formik.nationality ?? null,
          visibility: {
            all: formik.nationalityVisibility ?? true,
          },
        }
      : undefined,
  }
}

const firstPageValidation = () =>
  Yup.object({
    firstname: Yup.string()
      .max(64, word("form.maximum.characters", { count: 64 }))
      .matches(nameRegExp, word("form.invalid.firstname"))
      .required(word("form.required.firstname")),
    lastname: Yup.string()
      .max(64, word("form.maximum.characters", { count: 64 }))
      .matches(nameRegExp, word("form.invalid.lastname"))
      .required(word("form.required.lastname")),
  })

const secondPageValidation = () =>
  Yup.object({
    genderCategory: Yup.string().required(),
  })

const thirdPageValidation = () =>
  Yup.object({
    birthdate: Yup.string().required(word("form.required.birthDate")),
    nationality: Yup.string().required(word("form.required.nationality")),
  })

const fourthPageValidation = () =>
  Yup.object({
    username: Yup.string()
      .max(64, word("form.maximum.characters", { count: 64 }))
      .matches(usernameRegExp, word("form.invalid.username"))
      .required(word("form.required.username")),
  })

export function getValidationSchema(pageNumber?: number) {
  switch (pageNumber) {
    case 1:
      return firstPageValidation()
    case 2:
      return secondPageValidation()
    case 3:
      return thirdPageValidation()
    case 4:
      return fourthPageValidation()
    default:
      return firstPageValidation().concat(secondPageValidation())
  }
}
