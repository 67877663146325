import React, { CSSProperties } from "react"

export type IconSvgProps = React.SVGProps<SVGSVGElement> & {
  color: string
  size?: number
}

export type HUButtonIconProps =
  | {
      icon: React.FC<IconSvgProps>
      iconProps?: Partial<IconSvgProps>
      iconStyle?: CSSProperties
      iconSize?: number
    }
  | {
      iconView: React.ReactElement
    }

interface HUButtonIconViewProps {
  iconColor: string
  defaultIconSize: number
  icon: HUButtonIconProps
}

export const HUButtonIcon: React.FC<HUButtonIconViewProps> = ({ icon, iconColor, defaultIconSize }) => {
  return "icon" in icon
    ? React.createElement(icon.icon, {
        width: icon.iconSize ?? defaultIconSize,
        height: icon.iconSize ?? defaultIconSize,
        color: iconColor,
        ...icon.iconProps,
        style: icon.iconStyle,
      })
    : icon.iconView
      ? icon.iconView
      : null
}
