import React from "react"
import { IconSvgProps } from "@modules/ui/components/huButtonIcon"

export const SixIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="82" height="101" viewBox="0 0 82 101" fill="none" {...props}>
    <path
      d="M41.4248 100.184C34.3008 100.184 28.1003 99.0407 22.8232 96.7539C17.6341 94.3793 13.3245 91.0811 9.89446 86.8595C6.55233 82.6378 4.04573 77.7566 2.37467 72.2157C0.791557 66.5868 0 60.5622 0 54.1418V48.8648C0 32.5939 4.17766 20.4127 12.533 12.3212C20.8883 4.22976 33.2894 0.184021 49.7361 0.184021C52.9903 0.184021 56.3325 0.271973 59.7625 0.447878C63.1926 0.623777 66.4028 0.887628 69.3931 1.23943V21.8199C66.4908 21.5561 63.2366 21.3362 59.6306 21.1603C56.0246 20.8964 52.4626 20.7645 48.9446 20.7645C42.1724 20.7645 36.8514 21.8199 32.9815 23.9307C29.1996 26.0415 26.5172 29.2517 24.934 33.5613C23.4389 37.8709 22.6913 43.3239 22.6913 49.9202V61.2658L17.4142 61.5297C17.5022 55.0213 18.8215 49.6123 21.372 45.3028C24.0106 40.9052 27.6165 37.6071 32.19 35.4083C36.7634 33.2095 42.0405 32.1101 48.0211 32.1101C54.7933 32.1101 60.642 33.4734 65.5673 36.1999C70.5805 38.8384 74.4503 42.5763 77.1768 47.4136C79.9033 52.2509 81.2665 58.0116 81.2665 64.6959C81.2665 71.9079 79.5515 78.1963 76.1214 83.5613C72.7792 88.8384 68.1179 92.9281 62.1372 95.8305C56.1566 98.7328 49.2524 100.184 41.4248 100.184ZM40.6332 79.8674C44.1513 79.8674 47.1416 79.2517 49.6042 78.0204C52.1548 76.7891 54.0897 75.0741 55.409 72.8753C56.8162 70.6766 57.5198 68.126 57.5198 65.2236C57.5198 62.2333 56.8602 59.6827 55.5409 57.5719C54.2216 55.3731 52.3307 53.6581 49.8681 52.4268C47.4055 51.1955 44.4591 50.5798 41.029 50.5798C37.9507 50.5798 35.0923 51.1075 32.4538 52.1629C29.8153 53.2183 27.7045 54.8454 26.1214 57.0442C24.5383 59.155 23.7467 61.8815 23.7467 65.2236C23.7467 68.126 24.4063 70.6766 25.7256 72.8753C27.0449 75.0741 28.9358 76.7891 31.3984 78.0204C33.949 79.2517 37.0273 79.8674 40.6332 79.8674Z"
      fill={props.color}
    />
  </svg>
)
