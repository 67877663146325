import { useUser } from "@modules/auth/hooks/useUser"
import { BoxSlotTypeOf } from "@modules/boxSlot/boxSlotTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { convertItemProgramSessionsToEvent } from "@modules/programSession/programSessionTypes"
import { ItemProgram } from "@modules/programs/programTypes"
import { convertItemTrainingSessionsToEvent } from "@modules/training/trainingTypes"
import { useObservable } from "micro-observables"
import { DefaultTheme, useTheme } from "styled-components"

export const useProgramSelected = (programId: string) => {
  const { planningService } = useServicesContext()
  const programs = useObservable(planningService.filteredProgramPlanning)
  return programs.includes(programId)
}
export const useAllProgramsSelected = (listPrograms: ItemProgram[]) => {
  const { planningService } = useServicesContext()
  const programs = useObservable(planningService.filteredProgramPlanning)
  return listPrograms.every((program) => programs.includes(program.id))
}

export const useProgramSessionEvents = (programId: string | undefined) => {
  const theme = useTheme()
  const user = useUser()
  const { planningService } = useServicesContext()
  const planningSessions = useObservable(planningService.planningSessions)
  const filteredSessions = planningSessions.filter((session) => session.programId === programId)

  return {
    isLoading: useObservable(planningService.isLoadingSessions),
    sessions: convertItemProgramSessionsToEvent(filteredSessions, user?.id ?? "", theme as DefaultTheme),
  }
}

export const usePlanningSessionEvents = () => {
  const user = useUser()
  const theme = useTheme()
  const { trainingService, planningService } = useServicesContext()
  const selectedPrograms = useObservable(planningService.filteredProgramPlanning)
  const trainingSessions = useObservable(trainingService.trainingSessions)
  const filteredSessions = trainingSessions.filter(
    (session) => session.type === BoxSlotTypeOf || selectedPrograms.includes(session.programSession.programId || ""),
  )

  return {
    isLoading: useObservable(trainingService.isLoadingSessions),
    sessions: convertItemTrainingSessionsToEvent(filteredSessions, user?.id ?? "", theme as DefaultTheme),
  }
}
