import { PaginatedDataResult } from "@betomorrow/micro-stores"
import { word } from "@modules/core/utils/i18n"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { UserCell } from "@modules/members/components/userCell"
import { useMemberSideBar } from "@modules/members/hooks/useMemberSidebar"
import { MemberInputKey } from "@modules/members/memberFormikType"
import { Member } from "@modules/members/memberTypes"
import { convertLastVisitedToColor } from "@modules/members/memberUtils"
import { DataTable, renderTableColumns } from "@modules/ui/components/dataTables/datatable"
import { HuTextUnderline } from "@modules/ui/components/huLastVisited"
import { HuNoshow } from "@modules/ui/components/huNoshow"
import { HuNote } from "@modules/ui/components/huNote"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { formatDateWithHyphen } from "@modules/utils/dateUtils"
import { shortenText } from "@modules/utils/stringUtils"
import { Column, ColumnProps } from "primereact/column"
import { DataTableStateEvent, DataTableValue } from "primereact/datatable"
import { useState } from "react"
import { DefaultTheme, useTheme } from "styled-components"

type Props<T> = {
  paginatedStore: PaginatedDataResult<T>
  hasFilter?: boolean
  diplayedInfoMember: MemberInputKey[]
  headerRightView?: JSX.Element
  columns?: ColumnProps[]
  page: number
  setPage: (p: number) => void
  size: number
  setSize: (s: number) => void
  hideHeaderOnEmptyLoading?: boolean
  empty: {
    full?: boolean
    view: React.ReactNode
  }
} & React.HTMLAttributes<HTMLDivElement>

export const DEFAULT_PAGE_SIZE = 25

export function MemberTable<T>({
  paginatedStore,
  hasFilter,
  diplayedInfoMember,
  columns,
  page,
  setPage,
  size,
  setSize,
  empty,
}: Props<T>) {
  const { result: members, loading, totalSize } = paginatedStore
  const theme = useTheme()
  const language = useLanguage()
  const [selectedMember, setSelectedMember] = useState<DataTableValue>()
  const { navigateToMemberDetailsInfo } = useMemberSideBar()

  const headerColumn = (key: MemberInputKey) => {
    switch (key) {
      case "name":
        return word("member.label.name")
      case "activeOffer":
        return word("box.member.table.activeOffer.label", { count: 1 })
      case "lastVisit":
        return word("box.member.table.lastVisit")
      case "assignedCoach":
        return word("box.member.table.assignedCoach.label")
      case "note":
        return word("box.member.table.note")
      case "noshow":
        return word("box.member.table.noShow", { count: 0 })
    }
  }

  const valueColumn = (key: MemberInputKey, member: Member) => {
    switch (key) {
      case "name":
        return (
          <UserCell
            member={{
              avatar: member.avatar,
              username: member.username,
              firstname: member.firstname,
              lastname: member.lastname,
            }}
            subtitle={member.username}
            subtitleColor={theme.neutralColor500}
          />
        )
      case "activeOffer":
        const texte = member.activeOfferTitles?.map((offer) => shortenText(offer, 20)).join(", ")
        return <HUText>{texte}</HUText>
      case "lastVisit":
        return member.lastVisited ? (
          <HuTextUnderline
            fontStyle="TS"
            color={theme.mainWhite}
            text={formatDateWithHyphen(member.lastVisited.toLocaleDateString())}
            colorLine={convertLastVisitedToColor(theme, member.lastVisited)}
          />
        ) : (
          <HuTextUnderline fontStyle="TS" color={theme.mainWhite} text={word("box.member.table.noVisitYet")} />
        )
      case "assignedCoach":
        return "Martin Voegel"
      case "note":
        return <HuNote id={member.id} index={member.id} note={member.note ? member.note : ""} />
      case "noshow":
        return <HuNoshow number={member.noShowPastMonth || 0} />
      default:
        return <HUText>{word("global.error.label")}</HUText>
    }
  }

  const onPage = (event: DataTableStateEvent) => {
    setPage(event.page ?? 0)
    setSize(event.rows)
  }

  const onSelectedMember = (e: DataTableValue) => {
    navigateToMemberDetailsInfo(e as Member, {
      onHide: () => {
        setSelectedMember(undefined)
        return Promise.resolve(true)
      },
      replaceAllComponents: true,
    })
    setSelectedMember(e)
  }

  return (
    <HUStack gap={34} className="px-4" flexGrow>
      {empty.full && !loading && members.length === 0 && !hasFilter ? (
        empty.view
      ) : (
        <DataTable
          value={members as DataTableValue[]}
          dataKey="id"
          paginator
          lazy
          onPage={onPage}
          first={size * page}
          totalRecords={totalSize}
          rows={size}
          currentPageReportTemplate={""}
          rowsPerPageOptions={[25, 50, 100]}
          emptyMessage={empty.view}
          loading={loading}
          selectionMode="single"
          selection={selectedMember}
          onSelectionChange={(e) => onSelectedMember(e.value)}
          unstyled={undefined}
          metaKeySelection={true}
          // size="small" // TODO CHANGE SIZE FOR SCREEN SIZE
        >
          {renderTableColumns(diplayedInfoMember, headerColumn, valueColumn, theme as DefaultTheme, language)}
          {columns?.map((columnProps, index) => <Column key={"more-column-" + index} {...columnProps} />)}
        </DataTable>
      )}
    </HUStack>
  )
}
