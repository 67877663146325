import ProgramInfiniteEmpty from "@images/empty/program_infinite_empty.svg"
import ProgramOnOffEmpty from "@images/empty/program_on_off_empty.svg"
import { word } from "@modules/core/utils/i18n"
import { ProgramForm } from "@modules/programs/components/programForm"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { useState } from "react"
import styled, { useTheme } from "styled-components"

export const ProgramSelectView: React.FC = () => {
  const theme = useTheme()
  const { sidebarNavigateTo } = useSidebar()

  const onCreateProgramInfiniteClick = () => {
    sidebarNavigateTo({
      content: <ProgramForm programType={ProgramInfiniteTypeOf} />,
    })
  }
  const onCreateProgramOnOffClick = () => {
    sidebarNavigateTo({
      content: <ProgramForm programType={ProgramOnOffTypeOf} />,
    })
  }

  return (
    <>
      <HUStack gap={0}>
        <HUText fontStyle="TL" className="mb-2">
          {word("program.form.addProgram.title")}
        </HUText>
        <HUText fontStyle="BS" className="mb-3" color={theme.neutralColor600}>
          {word("program.form.addProgram.description")}
        </HUText>
      </HUStack>
      <ProgramCard
        imageSrc={ProgramInfiniteEmpty}
        title={word("program.create.infinite.title")}
        description={word("program.create.infinite.description")}
        onButtonClick={onCreateProgramInfiniteClick}
        size={"50%"}
      />
      <ProgramCard
        imageSrc={ProgramOnOffEmpty}
        title={word("program.create.on_off.title")}
        description={word("program.create.on_off.description")}
        onButtonClick={onCreateProgramOnOffClick}
        size={"40%"}
      />
    </>
  )
}

type ProgramCardProps = {
  imageSrc: string
  title: string
  description: string
  onButtonClick: () => void
  size: string
}
const ProgramCard: React.FC<ProgramCardProps> = ({ imageSrc, title, description, onButtonClick, size }) => {
  const [isHovered, setIsHovered] = useState(false)
  const theme = useTheme()

  return (
    <CardContainer
      onClick={onButtonClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={imageSrc} style={{ width: size }} alt="Card Image" />
      <HUText fontStyle="TL" className={`mb-2 ${isHovered ? "underline" : ""}`}>
        <span className={`p-button-icon p-c pi pi-plus mr-2`} />
        {title}
      </HUText>
      <HUText fontStyle="BS" color={theme.neutralColor700} className="mb-2 text-center">
        {description}
      </HUText>
    </CardContainer>
  )
}

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.neutralColor100};
  border-radius: 33px;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px;
  margin-bottom: 28px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.mainWhite};
    border: ${({ theme }) => `1px solid ${theme.neutralColor300}`};
  }
  .underline {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
`
