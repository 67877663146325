import { GlobeIcon } from "@images/svgIcons/globeIcon"
import { InfinityIcon } from "@images/svgIcons/infinityIcon"
import { LockIcon } from "@images/svgIcons/lockIcon"
import { OnOffIcon } from "@images/svgIcons/onOffIcon"
import { SubscribersIcon } from "@images/svgIcons/subscribersIcon"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { ProgramInfinite, ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff, ProgramOnOffStatus, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramVisibility } from "@modules/programs/programTypes"
import {
  getDisplayStatus,
  getDisplayVisibility,
  getDurationText,
  getSubscriptionCountText,
} from "@modules/programs/programUtils"
import { HUButton } from "@modules/ui/components/huButton"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import styled, { useTheme } from "styled-components"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"

export const ProgramInformation: React.FC<{
  program: ProgramInfinite | ProgramOnOff
}> = ({ program }) => {
  const theme = useTheme()
  const { isAuthor, isAcceptedSharedCoach } = useProgramRights(program)
  const programSubscribersText = getSubscriptionCountText(
    program.subscriptionCount,
    isAuthor,
    isAcceptedSharedCoach,
    true,
    program._programType,
  )
  const programDurationText = "duration" in program && getDurationText(program.duration)
  const mySpaceNavigate = useMySpaceNavigate()
  const { navigateToProgramView } = useProgramSidebar()

  const onViewDetailsProgramClick = () => {
    navigateToProgramView(program.id, program._programType)
  }

  return (
    <>
      <HUStack className="pl-3 pr-3 col-6" gap={2}>
        <HURow className="align-items-center gap-2">
          {program._programType === ProgramInfiniteTypeOf ? (
            <InfinityIcon color={theme.mainWhite} width={24} height={24} />
          ) : (
            <OnOffIcon color={theme.mainWhite} width={24} height={24} />
          )}
          <HUText color={theme.mainWhite} fontStyle="TL" numberOfLine={1}>
            {program.title}
          </HUText>
        </HURow>

        <div className="flex align-items-center">
          {program.visibility === ProgramVisibility.PUBLIC ? (
            <GlobeIcon color={theme.primaryColor} width={18} height={18} />
          ) : (
            <LockIcon color={theme.secondaryColor200} width={18} height={18} />
          )}
          <HUText color={theme.secondaryColor200} fontStyle="BM" className="ml-2">
            {getDisplayVisibility(program.visibility)}{" "}
            {program._programType === ProgramOnOffTypeOf &&
              program.status === ProgramOnOffStatus.DRAFT &&
              `(${getDisplayStatus(program.status).toLowerCase()})`}
          </HUText>
        </div>

        {program._programType === ProgramOnOffTypeOf && (
          <div className="flex align-items-center">
            <span className="pi pi-calendar" style={{ color: theme.secondaryColor200 }} />
            <HUText color={theme.secondaryColor200} fontStyle="BM" className="ml-2">
              {programDurationText}
            </HUText>
          </div>
        )}

        <HURow className="align-items-center">
          <SubscribersIcon color={theme.secondaryColor200} width={18} height={18} />
          <HUText color={theme.secondaryColor200} fontStyle="BM" className="ml-2">
            {programSubscribersText}
          </HUText>
        </HURow>

        <HURow className="align-items-center">
          <i
            style={{ fontSize: "1rem", color: theme.secondaryColor200 }}
            className={`${program.defaultHideSessionContent ? "pi pi-eye-slash" : "pi pi-eye"}`}
          />
          <HUText color={theme.secondaryColor200} fontStyle="BM" className="ml-2">
            {word("program.details.hideSessionContent", {
              contentHidden: program.defaultHideSessionContent
                ? word("program.details.sessionContent.hidden")
                : word("program.details.sessionContent.apparent"),
            })}
          </HUText>
        </HURow>

        <ReturnToTrainingButton
          label={word("program.returnToTraining")}
          link
          to={Path.MySpace.Training}
          onPress={() => mySpaceNavigate(Path.MySpace.Training)}
          type="button"
          color={theme.secondaryColor200}
        />
      </HUStack>
      <HUStack
        className="pl-3 col-6 justify-content-between"
        gap={10}
        style={{ borderLeft: "1px solid " + theme.secondaryColor300 }}
      >
        <HUText numberOfLine={3} color={theme.secondaryColor200} style={{ whiteSpace: "pre-line" }} fontStyle="BM">
          {program.description}
        </HUText>
        <HUButton
          text={word("program.seeInformation")}
          onClick={onViewDetailsProgramClick}
          type="Default"
          size="M"
          colorType="Quaternary"
          textColor={theme.mainWhite}
          className="pt-2 pb-2 pl-3 pr-3 align-self-end mt-1"
        />
      </HUStack>
    </>
  )
}

const ReturnToTrainingButton = styled(HUNavigateButton)`
  max-width: fit-content;
  margin-top: auto;

  & > div {
    border-radius: 0;
    padding: 0 !important;
  }
`
