import { Area } from "react-easy-crop"

export const createImage = (file: File): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const imageUrl = URL.createObjectURL(file)
    const image = new Image()
    image.onload = () => resolve(image)
    image.onerror = () => reject
    image.crossOrigin = "anonymous"
    image.src = imageUrl
  })
}

export default async function getCroppedImg(
  file: File,
  crop: Area,
): Promise<
  | {
      base64: string
      newFile: File
    }
  | undefined
> {
  const image = await createImage(file)
  const canvas = document.createElement("canvas")
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    return undefined
  }

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  )

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const result = event.target?.result
          if (result && typeof result === "string") {
            const [, content] = result.split(",")
            const newFile = new File([blob], file.name, { type: "image/jpeg" })
            resolve({ base64: content, newFile })
          } else {
            resolve(undefined)
          }
        }

        reader.onerror = reject
        reader.readAsDataURL(blob)
      }
    }, "image/jpeg")
  })
}
