import { BoxPlanningHeader } from "@modules/boxPlanning/components/boxPlanningHeader/boxPlanningHeader"
import { BoxProgrammationTemplateCalendar } from "@modules/boxProgrammation/components/boxProgrammationTemplateCalendar"
import { useBoxProgrammation } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { CalendarProvider } from "@modules/calendar/context/calendarContext"
import { minScreenWidth } from "@modules/ui/uiConstants"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"
import styled from "styled-components"

export const BoxProgrammationTemplatePage: React.FC = () => {
  const { boxProgrammationId } = useParams()
  const { result: boxProgrammation } = useBoxProgrammation(boxProgrammationId)

  return (
    <CalendarProvider
      initialDate={dayjs().startOf("day")}
      defaultDisplayDetailedEvents={false}
      defaultView="dayGridWeek"
      isWeekTemplate={true}
      hasHours={true}
    >
      <Container className="grid flex-grow-1">
        <div className="flex-grow-1 flex flex-column" style={{ minWidth: minScreenWidth }}>
          <BoxPlanningHeader isTemplate={true} boxProgrammation={boxProgrammation} />
          <BoxProgrammationTemplateCalendar boxProgrammation={boxProgrammation} />
        </div>
      </Container>
    </CalendarProvider>
  )
}

const Container = styled.div`
  min-width: 980px;
`
