import { word } from "@modules/core/utils/i18n"
import { requiredInput } from "@modules/form/formUtils"
import * as Yup from "yup"

export function getValidationSchema() {
  return Yup.object({
    email: requiredInput(
      Yup.string()
        .max(255, word("form.maximum.characters", { count: 255 }))
        .email(word("global.input.email.error")),
    ),
    role: requiredInput(Yup.string()).max(64, word("form.maximum.characters", { count: 64 })),
  })
}
