import { Service } from "@modules/core/services/serviceType"
import { LoggerType } from "@modules/utils/loggerUtils"

// Manage Hot-reload / Fast-refresh => reset previous instance services
let activeInstance: ServiceManager | null = null

export class ServiceManager {
  protected _services: Service[] = []
  private _unsubscribeServices: (() => void)[] = []
  private _onLogging = false

  constructor(private isDev: boolean) {}

  setActiveInstance(newActiveIntance: ServiceManager) {
    if (activeInstance) {
      activeInstance.reset()
    }
    activeInstance = newActiveIntance
  }

  register<T extends Service>(service: T): T {
    this._services.push(service)
    return service
  }

  async init(): Promise<void> {
    this.reset()

    console.log(LoggerType.Service + "init started")

    try {
      for (const service of this._services) {
        if (service.init) {
          if (this.isDev) {
            console.log(LoggerType.Service + "init", service.constructor.name)
          }
          const unsubscribeService = await service.init()
          if (unsubscribeService) {
            this._unsubscribeServices.push(unsubscribeService)
          }
        }
      }
      console.log(LoggerType.Service + "init done")
    } catch (e) {
      console.error(LoggerType.Service + "init failure", e)
      throw e
    }
    await this.onAfterInit()
  }

  async onAfterInit(): Promise<void> {
    await Promise.all(
      this._services
        .filter((it) => it.onAfterInit)
        .map((it) => {
          if (this.isDev) {
            console.log(LoggerType.Service + "after-init", it.constructor.name)
          }
          return it.onAfterInit?.().catch((e) => {
            if (this.isDev) {
              console.error(LoggerType.Service + "after-init failure: " + it.constructor.name, e)
            } else {
              console.error(LoggerType.Service + "after-init failure:", e)
            }
          })
        }),
    )
    console.log(LoggerType.Service + "after-init done")
  }

  async onLoggedIn(): Promise<void> {
    if (!this._onLogging) {
      this._onLogging = true

      await Promise.all(
        this._services
          .filter((it) => it.onLoggedIn)
          .map((it) => {
            if (this.isDev) {
              console.log(LoggerType.Service + "logged-in", it.constructor.name)
            }
            return it.onLoggedIn?.().catch((e) => {
              if (this.isDev) {
                console.error(LoggerType.Service + "logged-in failure: " + it.constructor.name, e)
              } else {
                console.error(LoggerType.Service + "logged-in failure:", e)
              }
            })
          }),
      )
      console.log(LoggerType.Service + "logged-in done")
      this._onLogging = false
    }
  }

  async onLoggedOut() {
    await Promise.all(
      this._services
        .filter((it) => it.onLoggedOut)
        .map((it) => {
          if (this.isDev) {
            console.log(LoggerType.Service + "clear", it.constructor.name)
          }
          return it.onLoggedOut?.().catch((e) => {
            if (this.isDev) {
              console.error(LoggerType.Service + "clear failure: " + it.constructor.name, e)
            } else {
              console.error(LoggerType.Service + "clear failure:", e)
            }
          })
        }),
    )

    console.log(LoggerType.Service + "clear done")
  }

  private async reset() {
    for (const unsubscribeService of this._unsubscribeServices) {
      unsubscribeService()
    }
    this._unsubscribeServices = []
  }
}
