import { IconSvgProps } from "@modules/ui/components/huButtonIcon"

export type Tutorial = {
  id: TutorialId
  title: string
  subTitle: string
  linkVideoFr: string
  linkVideoEn: string
  linkVideoBr: string
  icon?: React.FC<IconSvgProps>
  textButton?: string
}

export enum TutorialId {
  create_infinite_program = "create_infinite_program",
  create_onOff_program = "create_onOff_program",
  dynamic_link = "dynamic_link",
  trial_period = "trial_period",
  promotion_code = "promotion_code",
}
