import { PasteIcon } from "@images/svgIcons/pasteIcon"
import { BoxSlotSessionFormikType } from "@modules/boxSlot/boxSessionFormikTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { convertExerciseDraftToFormik } from "@modules/exercises/exerciseFormikTypes"
import { ProgramBoxSessionForm } from "@modules/programSession/components/programBoxSessionForm"
import { ProgramInfiniteSessionForm } from "@modules/programSession/components/programInfiniteSessionForm"
import { ProgramOnOffSessionForm } from "@modules/programSession/components/programOnOffSessionForm"
import {
  ProgramInfiniteSessionFormikType,
  ProgramOnOffSessionFormikType,
  ProgramSessionInputKey,
} from "@modules/programSession/programSessionFormikType"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { EllipsisMenu } from "@modules/ui/components/ellipsisMenu"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import { MenuItem } from "primereact/menuitem"
import styled, { useTheme } from "styled-components"

type ProgramSessionFormViewProps = {
  isCreation: boolean
  onFinish: () => void
}

export const ProgramSessionFormView: React.FC<ProgramSessionFormViewProps> = ({ isCreation, onFinish }) => {
  const { formik, isSubmitting, program } = useSessionFormContext()
  const toast = useToast()
  const theme = useTheme()
  const { programSessionService } = useServicesContext()
  const copySession = programSessionService.getCopySession()

  const onSubmitSessionForm = async () => {
    if (
      formik.values.exercises.length < 1 &&
      (("restDay" in formik.values && !formik.values.restDay) || !("restDay" in formik.values))
    ) {
      toast.show({ detail: word("program.onOff.exerciseRequiredError"), severity: "error" })
      return
    }

    await formik.submitForm()
    onFinish()
  }

  const onSessionPaste = () => {
    try {
      if (copySession) {
        if ("restDay" in copySession) formik.setFieldValue("restDay", copySession.restDay)
        if ("dayIndex" in copySession) formik.setFieldValue("dayIndex", copySession.dayIndex)

        formik.setFieldValue("name", copySession.name)
        formik.setFieldValue("description", copySession.description)
        formik.setFieldValue("gymnasticTag", copySession.gymnasticTag)
        formik.setFieldValue("weightliftingTag", copySession.weightliftingTag)
        formik.setFieldValue("cardioTag", copySession.cardioTag)

        const exercises = copySession.exercises.map((exercice) => {
          const formikCopySessionExercice = convertExerciseDraftToFormik(exercice)

          return {
            category: exercice.category,
            chrono: exercice.chrono,
            chronoAmrap: formikCopySessionExercice.chronoAmrap,
            chronoEmom: formikCopySessionExercice.chronoEmom,
            chronoForTime: formikCopySessionExercice.chronoForTime,
            chronoTabata: formikCopySessionExercice.chronoTabata,
            chronoType: exercice.chrono?.type,
            description: exercice.description,
            id: null,
            scoreType: exercice.scoreType,
            rpe: exercice.rpe,
            title: "",
          }
        })
        formik.setFieldValue("exercises", exercises)
      }

      toast.show({
        severity: "success",
        summary: word("program.session.paste.success"),
      })
    } catch (e: any) {
      toast.show({
        severity: "error",
        summary: word("program.session.copy.error"),
      })
    }
  }

  const ellipsisItems: MenuItem[] = [
    {
      items: [
        {
          label: word("global.paste"),
          icon: <PasteIcon color={theme.neutralColor900} />,
          command: () => {
            onSessionPaste()
          },
        },
      ],
    },
  ]

  return (
    <>
      <HUStack gap={10}>
        <div className="flex align-items-center">
          <HUStackContainer gap={3}>
            <HUText fontStyle="HS">
              {isCreation ? word("sessionForm.create.title") : word("sessionForm.edit.title")}
            </HUText>
            <HUText color={theme.neutralColor600}>
              {isCreation ? word("sessionForm.create.subtitle") : word("sessionForm.edit.description.subtitle")}
            </HUText>
          </HUStackContainer>
          <div className="flex flex-1 justify-content-end gap-2">
            <EllipsisMenu items={ellipsisItems} disabled={!copySession} />
          </div>
        </div>

        {!program ? (
          <></>
        ) : program._programType === ProgramInfiniteTypeOf ? (
          <ProgramInfiniteSessionForm
            formik={formik as SimpleFormik<ProgramInfiniteSessionFormikType, ProgramSessionInputKey>}
          />
        ) : program._programType === ProgramOnOffTypeOf ? (
          <ProgramOnOffSessionForm
            formik={formik as SimpleFormik<ProgramOnOffSessionFormikType, ProgramSessionInputKey>}
            program={program}
          />
        ) : (
          <ProgramBoxSessionForm formik={formik as SimpleFormik<BoxSlotSessionFormikType, ProgramSessionInputKey>} />
        )}

        <HUButton
          type="Default"
          colorType="Primary"
          size="M"
          text={word("box.slot.form.submit.button")}
          className="w-full"
          onClick={onSubmitSessionForm}
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </HUStack>
    </>
  )
}

const HUStackContainer = styled(HUStack)`
  max-width: 90%;
`
