import { convertBoxSessionToDraft } from "@modules/boxSlot/boxSlotTypes"
import { BoxProgrammationSession, BoxProgrammationSessionDraft } from "@modules/boxSlot/services/boxSlotDto"
import {
  convertExerciseDraftToFormik,
  convertFormikToExerciseDraft,
  ExerciseFormikType,
  getExerciseValidationSchema,
} from "@modules/exercises/exerciseFormikTypes"
import { requiredInput } from "@modules/form/formUtils"
import * as Yup from "yup"

export type BoxSlotSessionInputKey = "name" | "description" | "exercises"

export type BoxSlotSessionFormikType = {
  name: string
  description: string
  date?: Date
  exercises: ExerciseFormikType[]
  gymnasticTag: boolean
  weightliftingTag: boolean
  cardioTag: boolean
  hideSessionContent: boolean
}

export function getBoxSessionValidationSchema() {
  return Yup.object({
    name: requiredInput(Yup.string()),
    description: Yup.string(),
    exercises: Yup.array().of(getExerciseValidationSchema()),
  })
}

export const convertBoxSlotSessionToFormik = (
  session: BoxProgrammationSession | undefined | null,
): BoxSlotSessionFormikType => {
  const sessionDraft = convertBoxSessionToDraft(session)
  return {
    name: sessionDraft.name,
    description: sessionDraft.description,
    exercises: sessionDraft.exercises.map((exerciseDraft) => convertExerciseDraftToFormik(exerciseDraft)),
    gymnasticTag: sessionDraft.gymnasticTag ?? false,
    weightliftingTag: sessionDraft.weightliftingTag ?? false,
    cardioTag: sessionDraft.cardioTag ?? false,
    hideSessionContent: sessionDraft.hideSessionContent ?? false,
    date: sessionDraft.date,
  }
}

export const convertFormikToBoxSessionDraft = (
  formik: BoxSlotSessionFormikType,
  programmationId: string,
): BoxProgrammationSessionDraft => {
  return {
    ...formik,
    exercises: formik.exercises.map((exerciseFormik) => convertFormikToExerciseDraft(exerciseFormik)),
    programmationId,
  }
}
