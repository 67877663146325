import { word } from "@modules/core/utils/i18n"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { RequestsSASProfile } from "@modules/profile/profileTypes"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { useAcceptSASRequests, useProgramSASRequests } from "@modules/programs/hooks/usePrograms"
import { Program } from "@modules/programs/programTypes"
import { HUButton } from "@modules/ui/components/huButton"
import { HUCheckbox } from "@modules/ui/components/huCheckbox"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import dayjs from "dayjs"
import { useState } from "react"
import { useTheme } from "styled-components"

export const ProgramSasPaymentChoices: React.FC<{
  program: Program
}> = ({ program }) => {
  const {
    result: sasRequests,
    loading: loadingSasRequest,
    list: refreshSasRequests,
  } = useProgramSASRequests({
    programId: program.id,
    originDateRequest: dayjs().toDate(),
  })
  const [selectedItems, setSelectedItems] = useState<RequestsSASProfile[]>([])

  const theme = useTheme()
  const { navigateToProgramPriceEdition } = useProgramSidebar()
  const { loading: acceptLoading, onAccept } = useAcceptSASRequests(program.id, program._programType, () => {
    refreshSasRequests()
    navigateToPriceForm()
  })

  const toggleSelection = () => {
    setSelectedItems(selectedItems.length !== sasRequests.length ? [...sasRequests] : [])
  }
  const onSelect = (sasRequest: RequestsSASProfile) => {
    let newItems: RequestsSASProfile[] = []

    if (selectedItems.findIndex((item) => item.subscriptionId === sasRequest.subscriptionId) >= 0) {
      newItems = selectedItems.filter((item) => item.subscriptionId !== sasRequest.subscriptionId)
    } else {
      newItems = [...selectedItems, sasRequest]
    }
    setSelectedItems(newItems)
  }

  const onValidate = () => {
    if (selectedItems.length > 0) {
      onAccept(
        selectedItems.map((item) => item.subscriptionId),
        selectedItems.length === sasRequests.length,
      )
    } else {
      navigateToPriceForm()
    }
  }

  const navigateToPriceForm = () => {
    navigateToProgramPriceEdition(program, true)
  }

  return (
    <HUStack gap={15}>
      <HUText fontStyle="TL">{word("program.price.membershipPrice")}</HUText>
      <HUText fontStyle="BS">{word("program.price.membershipPriceDescription")}</HUText>

      <HUStack>
        <HUText fontStyle="TS" className="text-center my-3">
          {word("program.sasChoice.free.title")}
        </HUText>

        <HURow className="justify-content-between align-items-center">
          <HUText fontStyle="BXS">{`${sasRequests.length} ${word("program.sasChoice.request", { count: sasRequests.length }).toUpperCase()}`}</HUText>
          <HUButton
            type="Default"
            size="M"
            colorType="Secondary"
            className="py-2 px-3"
            onClick={toggleSelection}
            text={
              selectedItems.length !== sasRequests.length
                ? word("program.sasChoice.selectAll")
                : word("program.sasChoice.unselectAll")
            }
          />
        </HURow>

        {!loadingSasRequest &&
          sasRequests.map((sasRequest) => (
            <HURow key={sasRequest.subscriptionId} className="justify-content-between align-items-center">
              <HURow className="align-items-center" gap={10}>
                <ProfileAvatar profile={sasRequest.profile} size={46} />
                <HUStack className="justify-content-center" gap={2}>
                  <HUText fontStyle="LL">{`${sasRequest.profile.firstname} ${sasRequest.profile.lastname}`}</HUText>
                  <HUText fontStyle="BS">{`@${sasRequest.profile.username}`}</HUText>
                </HUStack>
              </HURow>

              <HUCheckbox
                inputKey={`user-${sasRequest.profile.id}`}
                name="option"
                value={sasRequest.profile.id}
                checked={!!selectedItems.find((selectedItem) => selectedItem.profile.id === sasRequest.profile.id)}
                onChange={() => onSelect(sasRequest)}
                label={""}
                color={theme.primaryColor}
                checkIconColor={theme.neutralColor700}
              />
            </HURow>
          ))}
      </HUStack>

      <HUButton
        className="w-full mt-5"
        type="Default"
        colorType="Primary"
        size="M"
        onClick={onValidate}
        text={word("global.check")}
        loading={acceptLoading}
      />
    </HUStack>
  )
}
