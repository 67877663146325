import { Benchmark, BenchmarkCategories, BenchmarkTypeOf, ItemBenchmark } from "@modules/benchmark/benchmarkTypes"
import { ChronoDto, convertChronoFromDto } from "@modules/chrono/services/chronoDtos"
import { convertToType } from "@modules/utils/enum"

export type ItemBenchmarkDto = {
  id: string
  title: string
  category: string
  chrono?: ChronoDto
  isFavorite: boolean
}

export type BenchmarkDto = {
  description: string
} & ItemBenchmarkDto

export function convertItemBenchmarkFromDto(dto: ItemBenchmarkDto): ItemBenchmark {
  return {
    id: dto.id,
    _type: BenchmarkTypeOf,
    title: dto.title,
    category: convertToType(BenchmarkCategories, dto.category, BenchmarkCategories[0]),
    chrono: dto.chrono ? convertChronoFromDto(dto.chrono) : undefined,
    isFavorite: dto.isFavorite,
  }
}

export function convertBenchmarkFromDto(dto: BenchmarkDto): Benchmark {
  return {
    id: dto.id,
    _type: BenchmarkTypeOf,
    title: dto.title,
    description: dto.description,
    category: convertToType(BenchmarkCategories, dto.category, BenchmarkCategories[0]),
    chrono: dto.chrono ? convertChronoFromDto(dto.chrono) : undefined,
    isFavorite: dto.isFavorite,
  }
}
