import SlotEmptySVG from "@images/empty/slot_empty.svg"
import { PlusIcon } from "@images/svgIcons/plusIcon"
import { BoxSlotSession } from "@modules/boxSlot/boxSlotTypes"
import {
  BoxSessionAddSubscriberModal,
  BoxSessionAddSubscriberModalRef,
} from "@modules/boxSlot/components/boxSessionAddSubscriberModal"
import BoxSessionSubscriber from "@modules/boxSlot/components/boxSessionSubscriber"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ParticipantStatus, Subscriber } from "@modules/profile/subscriber/subscriberTypes"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import Line from "@modules/ui/components/line"
import { useEffect, useRef, useState } from "react"
import { DefaultTheme, useTheme } from "styled-components"

export const BoxSlotRegistrationInfo: React.FC<{
  boxSlot: BoxSlotSession
}> = ({ boxSlot }) => {
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false)
  const [slotSubscribers, setSlotSubscribers] = useState<Subscriber[]>([])
  const [slotPendingSubscribers, setSlotPendingSubscribers] = useState<Subscriber[]>([])
  const [renderedContent, setRenderedContent] = useState<JSX.Element | null>(null)
  const [countSubscribers, setCountSubscribers] = useState<number>(0)

  const theme = useTheme()
  const { boxSlotService } = useServicesContext()

  const addSubscriberModalRef = useRef<BoxSessionAddSubscriberModalRef>(null)

  // Fetching subscribers and pending subscribers
  const fetchSlotSubscribers = async () => {
    try {
      setIsDataFetched(false)
      const [subscribers, pendingSubscribers] = await Promise.all([
        boxSlotService.getSlotSubscribers(boxSlot.id),
        boxSlotService.getSlotSubscribers(boxSlot.id, undefined, undefined, undefined, "pending"),
      ])
      setSlotSubscribers(subscribers.content)
      setSlotPendingSubscribers(pendingSubscribers.content)
      setCountSubscribers(subscribers.content.length + pendingSubscribers.content.length)
      setIsDataFetched(true)
    } catch (error) {
      // console.error("Failed to fetch slot subscribers", error)
    }
  }

  // Fetching subscribers and pending subscribers
  useEffect(() => {
    void fetchSlotSubscribers()
  }, [boxSlot.id])

  // Rendering the subscribers list
  useEffect(() => {
    render()
  }, [slotSubscribers, slotPendingSubscribers])

  const render = () => {
    if (slotSubscribers.length === 0 && slotPendingSubscribers.length === 0 && isDataFetched) {
      setRenderedContent(emptyTemplate(theme as DefaultTheme))
    } else if (slotPendingSubscribers.length > 0) {
      setRenderedContent(
        <div>
          {slotSubscriberTemplate(
            theme as DefaultTheme,
            slotSubscribers,
            setSlotSubscribers,
            setSlotPendingSubscribers,
            boxSlot,
            "registered",
          )}
          {slotSubscriberTemplate(
            theme as DefaultTheme,
            slotPendingSubscribers,
            setSlotSubscribers,
            setSlotPendingSubscribers,
            boxSlot,
            "pending",
          )}
        </div>,
      )
    } else {
      setRenderedContent(
        <div>
          {slotSubscriberTemplate(
            theme as DefaultTheme,
            slotSubscribers,
            setSlotSubscribers,
            setSlotPendingSubscribers,
            boxSlot,
            "registered",
          )}
        </div>,
      )
    }
    setCountSubscribers(slotSubscribers.length)
  }

  return (
    <div className="flex flex-column">
      <HUText className="mt-2" fontStyle="BS" color={theme.neutralColor400}>
        {`${countSubscribers} / ${boxSlot.participantLimit} ${word("box.slotTemplateGroup.form.participantLimit.unit", {
          count: countSubscribers ?? 0,
        })}`}
      </HUText>
      <div className="flex gap-4 ml-1 mt-4 mb-3">
        <HUButton
          className=""
          type="Rounded"
          size="M"
          colorType="Secondary"
          icon={{ iconView: <PlusIcon color={theme.neutralColor700} width={14} height={14} /> }}
          onClick={() => addSubscriberModalRef.current?.open()}
        />
        <HUText className="text-center align-content-center ml-1" fontStyle="LL" color={theme.mainWhite}>
          {word("boxSlotRegistrationInfo.button.inviteAthlete")}
        </HUText>
      </div>
      <Line color={`${theme.neutralColor400}`} strokeWidth={1} className="m-0 mb-2" />
      <div>{renderedContent}</div>
      <BoxSessionAddSubscriberModal
        ref={addSubscriberModalRef}
        slotId={boxSlot.id}
        setSlotSubscribers={setSlotSubscribers}
      />
    </div>
  )
}

const slotSubscriberTemplate = (
  theme: DefaultTheme,
  slotSubscribers: Subscriber[],
  setSlotSubscribers: React.Dispatch<React.SetStateAction<Subscriber[]>>,
  setSlotPendingSubscribers: React.Dispatch<React.SetStateAction<Subscriber[]>>,
  boxSlot: BoxSlotSession,
  participantStatus: ParticipantStatus,
) => {
  return (
    <div className={`${participantStatus === "pending" ? "" : "mb-5"}`}>
      {participantStatus === "pending" ? (
        <div>
          <Line color={`${theme.mainWhite}`} strokeWidth={1} className="m-0 mb-2" />
          <HUText fontStyle="BS" color={theme.neutralColor600}>
            {word("boxSlotRegistrationInfo.slotSubscriberTemplate.waitingList")}
          </HUText>
        </div>
      ) : null}
      <div className={`${participantStatus === "pending" ? "mt-3" : ""}`}>
        {slotSubscribers.map((subscriber) => (
          <BoxSessionSubscriber
            slotId={boxSlot.id}
            boxSlot={boxSlot}
            subscriber={subscriber}
            participantStatus={participantStatus}
            setSlotSubscribers={setSlotSubscribers}
            setSlotPendingSubscribers={setSlotPendingSubscribers}
            key={subscriber.id}
          />
        ))}
      </div>
      {participantStatus === "pending" ? (
        <Line color={`${theme.mainWhite}`} strokeWidth={1} className="m-0 mt-5" />
      ) : null}
    </div>
  )
}

const emptyTemplate = (theme: DefaultTheme) => {
  return (
    <div className="flex flex-column h-15rem mt-7">
      <div className=" justify-content-center">
        <HUText fontStyle="TL" color={theme.neutralColor700} className="flex text-left w-22rem mx-auto">
          {word("boxSlotRegistrationInfo.emptyTemplate.title")}
        </HUText>
        <HUText fontStyle="BM" color={theme.neutralColor700} className="flex text-left mt-3 w-22rem mx-auto">
          {word("boxSlotRegistrationInfo.emptyTemplate.subtitle")}
        </HUText>
      </div>

      <div className="flex flex-1 justify-content-center text-center align-items-center mt-2">
        <img src={SlotEmptySVG} alt="SlotEmptySVG" width={300} />
      </div>
    </div>
  )
}
