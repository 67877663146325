import { AsyncResult, FetchStrategy, PaginatedDataResult, Store, useStore } from "@betomorrow/micro-stores"
import { useState } from "react"

// Composite Store

export function useCompositeStore<T, KeyArray extends readonly (string | undefined | null)[]>(
  keyArray: [...KeyArray],
  store: CompositeStore<T, KeyArray>,
  fetchStrategy?: FetchStrategy,
  additionalDeps?: unknown[],
) {
  return useStore(validkeyArrayToCompositeKey(keyArray), store, fetchStrategy, additionalDeps)
}

export function keyArrayToCompositeKey<KeyArray extends (string | undefined | null)[]>(keyArray: KeyArray): string {
  return keyArray.join(CompositeKeySeparator)
}

function validkeyArrayToCompositeKey<KeyArray extends (string | undefined | null)[]>(
  keyArray: KeyArray,
): string | undefined {
  if (keyArray.some((key) => !key)) {
    return undefined
  }
  return keyArrayToCompositeKey(keyArray)
}

export interface CompositeItem {
  compositeKey: string
}

export const CompositeKeySeparator = "***"
export type CompositeStore<T, KeyArray extends readonly (string | undefined | null)[]> = Store<
  T & CompositeItem,
  "compositeKey"
> & {
  keyArray?: KeyArray
}

export function createCompositeStore<KeyArray extends string[], T = { id: string }>(
  fetch: (...ids: KeyArray) => Promise<T>,
): CompositeStore<T, KeyArray> {
  return new Store<T & CompositeItem, "compositeKey">(async (compositeKey) => {
    const args = compositeKey.split(CompositeKeySeparator) as KeyArray
    return { ...(await fetch(...args)), compositeKey }
  }, "compositeKey")
}

// Add refresh

export type RefreshAsyncResult<V> = AsyncResult<V> & { refresh: () => void; refreshing: boolean }
export type RefreshPaginatedResult<V> = PaginatedDataResult<V> & { refresh: () => void; refreshing: boolean }

export const useRefreshStore = <T1 extends { [k in T2]: string }, T2 extends string>(
  id: string | undefined,
  store: Store<T1, T2>,
  fetchStrategy?: FetchStrategy,
  deps: unknown[] = [],
): RefreshAsyncResult<T1> => {
  const [refreshing, setRefreshing] = useState(false)

  const result = useStore(id, store, fetchStrategy, deps)

  const refresh = async () => {
    try {
      if (id) {
        setRefreshing(true)
        await store.fetch(id)
      }
    } finally {
      setRefreshing(false)
    }
  }

  return {
    ...result,
    loading: result.loading && !refreshing,
    refreshing: result.loading && refreshing,
    refresh,
  }
}

export const useRefreshPaginatedStore = <T>(paginated: PaginatedDataResult<T>): RefreshPaginatedResult<T> => {
  const [refreshing, setRefreshing] = useState(false)

  const refresh = async () => {
    try {
      setRefreshing(true)
      await paginated.list()
    } finally {
      setRefreshing(false)
    }
  }

  return {
    ...paginated,
    loading: paginated.loading && !refreshing,
    refreshing: paginated.loading && refreshing,
    refresh,
  }
}

export function useRefreshCompositeStore<T, KeyArray extends readonly (string | undefined | null)[]>(
  keyArray: [...KeyArray],
  store: CompositeStore<T, KeyArray>,
  fetchStrategy?: FetchStrategy,
  deps?: unknown[],
): RefreshAsyncResult<T> {
  return useRefreshStore(validkeyArrayToCompositeKey(keyArray), store, fetchStrategy, deps)
}
