import { ChatApi } from "@modules/chat/services/chatApi"
import { ConfigService } from "@modules/core/services/configService"
import { Service } from "@modules/core/services/serviceType"
import { UserService } from "@modules/profile/services/userService"
import { LoggerType } from "@modules/utils/loggerUtils"
import { observable } from "micro-observables"
import { DefaultGenerics, MessageResponse, StreamChat } from "stream-chat"

export class ChatService implements Service {
  private token: string | null = null
  private _client = observable<StreamChat<DefaultGenerics> | null>(null)
  private _unreadCount = observable<number | undefined>(undefined)
  private _lastMessage = observable<MessageResponse<DefaultGenerics> | undefined>(undefined)

  private _unsubscribeClient: (() => void) | undefined
  private _unsubscribeTokenRefresh: (() => void) | undefined

  readonly client = this._client.readOnly()
  readonly unreadCount = this._unreadCount.readOnly()
  readonly lastMessage = this._lastMessage.readOnly()

  chatConnectionId = observable<string | undefined>(undefined)

  constructor(
    private userService: UserService,
    private chatApi: ChatApi,
  ) {}

  async init() {
    this.initClient()
    const unregister = this.userService.user.subscribe(async (user) => {
      const chatUser = this._client.get()?.user
      if (chatUser && user && chatUser.id && chatUser.name !== user.username) {
        try {
          await this._client.get()?.upsertUser({ id: chatUser.id, name: user.username })
        } catch (e) {
          console.error(LoggerType.Chat + "upsert user", e)
        }
      }
    })
    return () => {
      unregister()
      this._client.get()?.disconnectUser()
      this._unsubscribeClient?.()
      this._unsubscribeClient = undefined
      this._unsubscribeTokenRefresh?.()
      this._unsubscribeTokenRefresh = undefined
    }
  }

  initClient = () => {
    if (this._client.get() === null) {
      try {
        const client = StreamChat.getInstance<DefaultGenerics>(ConfigService.config.STREAM_CHAT_API_KEY, {
          timeout: 6000,
        })

        this._client.set(client)
      } catch (e) {
        console.error(LoggerType.Chat + "getInstance", e)
      }
    }
    return this._client.get()
  }

  async onLoggedIn(): Promise<void> {
    await this.loginClientUser()
  }

  loginClientUser = async () => {
    const client = this.initClient()
    const userClient = await this.getClientUser()

    if (client && userClient) {
      try {
        this.clear()

        const connectedUser = await client.connectUser(userClient.user, userClient.token)

        this.chatConnectionId.set(client._getConnectionID())
        const initialUnreadCount = connectedUser?.me?.total_unread_count
        this._unreadCount.set(initialUnreadCount)
        this._lastMessage.set(undefined)

        this._unsubscribeClient = client.on((e) => {
          if ((e.type && e.type === "notification.message_new") || e.type === "message.new") {
            if (!client.mutedChannels.some((mute) => mute.channel?.id === e.channel_id))
              this._lastMessage.set(e.message)
          }
          // update notification count
          const unreadMessages = e.total_unread_count ?? e.me?.total_unread_count
          if (unreadMessages !== undefined) {
            this._unreadCount.set(unreadMessages)
          }
        }).unsubscribe

        console.log(LoggerType.Chat + "connect with user-id " + client.userID)
      } catch (e) {
        console.error(LoggerType.Chat + "login", e)
      }
    } else {
      console.warn(LoggerType.Chat + "no chat client to login")
    }
  }

  private async getClientUser() {
    try {
      const user = this.userService.user.get()
      if (!user) {
        return null
      }
      if (!this.token) {
        this.token = await this.chatApi.getChatToken()
      }
      return {
        user: { id: user.id, name: user.username, image: user.avatar?.path },
        token: this.token,
      }
    } catch (e) {
      console.warn(LoggerType.Chat + "get client user", e)
    }

    return null
  }

  clear() {
    this._unsubscribeClient?.()
    this._unsubscribeClient = undefined
    this._unsubscribeTokenRefresh?.()
    this._unsubscribeTokenRefresh = undefined
    this._unreadCount.set(undefined)
    this._lastMessage.set(undefined)
  }

  async onLoggedOut() {
    try {
      await this._client.get()?.disconnectUser()
    } catch (e) {
      console.warn(LoggerType.Chat + "disconnect user", e)
    }
    this.clear()
    this.chatConnectionId.set(undefined)
    this._client.set(null)
    this.token = null
  }
}
