import { ApiInterceptor } from "@modules/core/api/interceptors/apiInterceptor"
import { ConfigService } from "@modules/core/services/configService"
import { LoggerType } from "@modules/utils/loggerUtils"
import { InternalAxiosRequestConfig } from "axios"

export const requestLoggerInterceptor = (): ApiInterceptor<InternalAxiosRequestConfig> => ({
  onFulfilled: async (config) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    config.metadata = { startTime: Date.now() }
    console.info(
      LoggerType.ApiSuccess +
        `executing ${config.method?.toUpperCase()} ${config.url} ${config.params ? JSON.stringify(config.params) : ""}`,
    )
    if (config.data) {
      if (ConfigService.config.ENV !== "prod") {
        console.debug(LoggerType.ApiSuccess + "[DEV] with body", JSON.stringify(config.data))
      } else {
        console.debug(LoggerType.ApiSuccess + "with body", config.data)
      }
    }
    return config
  },
})
