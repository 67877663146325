import ObjectivesBackground from "@images/objectivesBackground.jpeg"
import { PencilIcon } from "@images/svgIcons/pencil"
import { PlusIcon } from "@images/svgIcons/plusIcon"
import { word } from "@modules/core/utils/i18n"
import { ObjectivesPopup } from "@modules/dashboard/components/objectivesPopup"
import { useObjectivePrimary } from "@modules/objectives/hooks/useObjectives"
import { HUText } from "@modules/ui/components/huText"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import dayjs from "dayjs"
import { useState } from "react"
import styled, { useTheme } from "styled-components"

export const ObjectivesResume: React.FC = () => {
  const [isObjectivesPopup, setIsObjectivesPopup] = useState<boolean>(false)
  const theme = useTheme()

  const { result: objectivePrimary, refresh } = useObjectivePrimary()

  const showObjectivesPopup = () => {
    setIsObjectivesPopup(true)
  }

  const hideObjectivesPopup = () => {
    setIsObjectivesPopup(false)
    refresh()
  }
  return (
    <>
      <Container>
        <div className="flex justify-content-between">
          <div>
            <div className="flex align-items-center mb-2">
              <i className="pi pi-compass mr-2" style={{ color: theme.mainWhite }} />
              <HUText fontStyle="TL" color={theme.mainWhite}>
                {word("dashboard.objectives.title")}
              </HUText>
            </div>
            <HUText fontStyle="BS" color={theme.mainWhite}>
              {word("dashboard.objectives.description")}
            </HUText>
          </div>
          {objectivePrimary?.objective && (
            <div className="h-3rem w-3rem">
              <Round onClick={showObjectivesPopup}>
                <PencilIcon color={theme.neutralColor900} />
              </Round>
            </div>
          )}
        </div>
        {objectivePrimary?.objective ? (
          <ObjectivesContainer>
            <ObjectiveText fontStyle="TL" color={theme.mainWhite} className="text-align-center">
              {objectivePrimary.objective}
            </ObjectiveText>
            <div className="flex align-items-center mt-2">
              <HUText fontStyle="BS" color={theme.mainWhite}>
                {`${word("dashboard.objectives.beforeThe")} ${dayjs(objectivePrimary.targetDate).format("dddd D MMMM YYYY")}`}
              </HUText>
            </div>
          </ObjectivesContainer>
        ) : (
          <ObjectivesContainer onClick={showObjectivesPopup} className="add_exercise">
            <PlusIconContainer>
              <PlusIcon color={theme.neutralColor700} width={14} height={14} />
            </PlusIconContainer>
            <HUText className="my-2" fontStyle="LL" color={theme.mainWhite}>
              {word("dashboard.objectives.addGoal")}
            </HUText>
          </ObjectivesContainer>
        )}
      </Container>
      <HUDialog
        visible={isObjectivesPopup}
        header={word("dashboard.objectives.addGoal")}
        headerSeparator
        onHide={hideObjectivesPopup}
      >
        <ObjectivesPopup
          hideObjectivesPopup={hideObjectivesPopup}
          objectivePrimary={objectivePrimary ? objectivePrimary : null}
        />
      </HUDialog>
    </>
  )
}
const Container = styled.div`
  position: relative;
  padding: 28px 20px;
  min-height: 250px;
  background:
    linear-gradient(180deg, #121212 0%, rgba(18, 18, 18, 0.5) 100%),
    url(${ObjectivesBackground}),
    lightgray 50% / cover no-repeat;

  overflow: hidden;
  background-position: right;
  background-size: cover;
  border-radius: 33px;
  height: 100%;
  margin-top: 15px;
  width: 100%;
`
const PlusIconContainer = styled.div`
  background-color: ${({ theme }) => theme.neutralColor200};
  transition: background-color 1s ease;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`

const ObjectivesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 0 0 0px;
  min-height: 55%;
  transition: transform 200ms ease;

  &:hover.add_exercise {
    transform: scale(1.05);
    cursor: pointer;
  }
`
const Round = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 28px;
  border: 1px solid ${({ theme }) => theme.neutraColor100};
  background-color: ${({ theme }) => theme.neutralColor200};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  &:hover {
    cursor: pointer;
  }
`
const ObjectiveText = styled(HUText)`
  word-break: break-word;
  text-align: center;
`
