import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const QuadBoxIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.876465" y="0.875" width="8.76925" height="8.76926" rx="2" fill={props.color} />
    <rect x="0.876465" y="11.1055" width="8.76925" height="8.76926" rx="2" fill={props.color} />
    <rect x="11.1055" y="0.875" width="8.76925" height="8.76926" rx="2" fill={props.color} />
    <rect
      x="12.1055"
      y="12.1055"
      width="6.76925"
      height="6.76926"
      rx="1"
      stroke={props.color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)
