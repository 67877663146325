
import { ExerciseDashboardScore } from "@modules/exercises/exerciseTypes"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { Profile } from "@modules/profile/profileTypes"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { useGetDisplayedScore } from "@modules/utils/hooks/useUnit"
import { shortenText } from "@modules/utils/stringUtils"
import { Reorder, useMotionValue } from "framer-motion"
import { useTheme } from "styled-components"

export const ChallengeParticipant: React.FC<{ item: ExerciseDashboardScore; index: number; isRanking: boolean }> = ({
  item,
  index,
  isRanking,
}) => {
  const y = useMotionValue(0)
  const theme = useTheme()
  const getScore = useGetDisplayedScore()

  return (
    <Reorder.Item
      value={item.user.id}
      id={item.user.id}
      style={{ y }}
      initial={{ opacity: 0, x: 150 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 150 }}
      transition={{ duration: 0.5 }}
      className="align-items-start mb-2 w-full"
    >
      {isRanking && false ? (
        <>
          <HUText color={"#ddf247"} fontStyle="TM" className="flex">
            {index + 1}/
            <HUText color={theme.mainWhite} fontStyle="TM" className="ml-2">
              {getScore(item.score.value, item.score.type)}
            </HUText>
          </HUText>
          <User user={item.user} />
        </>
      ) : (
        <HUStack gap={0}>
          <HURow className="justify-content-between align-items-center" gap={0}>
            <HURow className="justify-content-start align-items-center" gap={10}>
              {isRanking && (
                <HUText color={"#ddf247"} fontStyle="TM" className="flex flex-row">
                  {index + 1}/
                </HUText>
              )}
              <User user={item.user} />
            </HURow>
            <HUText color={theme.mainWhite} fontStyle="TM" className="text-align-center">
              {getScore(item.score.value, item.score.type)}
            </HUText>
          </HURow>
        </HUStack>
      )}
    </Reorder.Item>
  )
}

const User: React.FC<{ user: Profile }> = ({ user }) => {
  const theme = useTheme()

  return (
    <HURow gap={6} className="align-items-center">
      <ProfileAvatar profile={user} />
      <HUStack gap={0}>
        <HUText color={theme.mainWhite} fontStyle="BM" className="text-left">
          {shortenText(`${user.firstname} ${user.lastname}`, 20)}
        </HUText>
        <HUText
          color={theme.mainWhite}
          fontStyle="BS"
          className="text-left"
        >{`@${shortenText(user.username, 19)}`}</HUText>
      </HUStack>
    </HURow>
  )
}
