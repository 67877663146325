import { ChannelType } from "@modules/chat/channelTypes"
import { ChatAvatar } from "@modules/chat/components/chatAvatar"
import { NewChatGroupNameForm } from "@modules/chat/components/newChatGroupNameForm"
import { useChatPageContext } from "@modules/chat/contexts/chatPageContext"
import { useChatClient } from "@modules/chat/hooks/useChatClient"
import { CHANNEL_LIST_SHOWN_WINDOW_WIDTH, DETAILS_OVERLAY_SHOWN_WINDOW_WIDTH } from "@modules/chat/utils/chatConstants"
import { getChannelCreatorId } from "@modules/chat/utils/getChannelCreatorId"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { HUSwitch } from "@modules/ui/components/huSwitch"
import { HUText } from "@modules/ui/components/huText"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { useWindowSize } from "@modules/utils/hooks/useWindowsSize"
import { Button } from "primereact/button"
import { InputSwitchChangeEvent } from "primereact/inputswitch"
import { useEffect, useState } from "react"
import { useChannelPreviewInfo, useChannelStateContext, useChatContext } from "stream-chat-react"
import { getDisplayTitle } from "stream-chat-react/dist/components/ChannelPreview/utils"
import { useTheme } from "styled-components"

type MemberType = {
  id: string
  name: string
  online: boolean
  avatar?: string
  banned: boolean
  role?: string
}

export const ChannelDetailsOverlay: React.FC = () => {
  const { chatClient } = useChatClient()
  const { setActiveChannel } = useChatContext()
  const { channel, channelCapabilities } = useChannelStateContext()
  const { displayImage } = useChannelPreviewInfo({ channel })
  const {
    displayChatDetailsOverlay,
    toggleDisplayChatDetailsOverlay,
    updateIsAddingMembersToChannel,
    updateIsCreatingGroup,
  } = useChatPageContext()
  const { width } = useWindowSize()
  const confirmPopup = useConfirmPopup()
  const theme = useTheme()

  const [muted, setMuted] = useState<boolean>(channel.muteStatus().muted)
  const [channelUsers, setChannelUsers] = useState<Array<MemberType>>([])
  const [displayGroupNameInput, setDisplayGroupNameInput] = useState<boolean>(false)

  useEffect(() => {
    // workaround to deal with the real time changes issue in stream-chat contexts
    const updateUsers = (action?: "ban" | "unban" | "remove", userId?: string) => {
      const isUpdatedUserBanned = (defaultValue: boolean) => {
        switch (action) {
          case "ban":
            return true
          case "remove":
            return true
          case "unban":
            return false
          default:
            return defaultValue
        }
      }

      setChannelUsers(
        Object.values(channel.state.members).map((user) => {
          const is_banned = user.user_id === userId ? isUpdatedUserBanned(user.banned || false) : user.banned || false

          return {
            id: user.user_id!,
            name: user.user!.name ?? user.user_id!,
            avatar: user.user!.image,
            online: !!user.user!.online,
            banned: is_banned,
            role: user.role,
          }
        }),
      )
    }

    updateUsers()

    const handleBanUser = (e: any) => {
      updateUsers("ban", e.user.id)
    }

    const handleUnbanUser = (e: any) => {
      updateUsers("unban", e.user.id)
    }

    const handleRemoveUser = (e: any) => {
      updateUsers("remove", e.user.id)
    }

    if (chatClient) {
      chatClient.on("user.banned", handleBanUser)
      chatClient.on("user.unbanned", handleUnbanUser)
      chatClient.on("member.removed", handleRemoveUser)
    }

    return () => {
      if (chatClient) {
        chatClient.off("user.banned", handleBanUser)
        chatClient.off("user.unbanned", handleUnbanUser)
        chatClient.off("member.removed", handleRemoveUser)
      }
    }
  }, [chatClient, channel])

  if (!channel || !chatClient) return null

  const isOneOnOneConversation =
    channel && Object.values(channel.state.members).length === 2 && channel.id?.indexOf("!members-") === 0
  const channelCreatorId = getChannelCreatorId(channel)
  const isProgramGroup = channel.type === ChannelType.PROGRAM
  const isBoxGroup = channel.type === ChannelType.BOX
  const isCurrentUserGroupCreator = channelCreatorId === chatClient.user?.id
  const isProgramGroupMember = isProgramGroup && !isCurrentUserGroupCreator
  const isBoxGroupMember = channel.type === ChannelType.BOX && !isCurrentUserGroupCreator
  const canUserEditChannelName = !isOneOnOneConversation && !isProgramGroup && !isBoxGroup && isCurrentUserGroupCreator
  const canUserSeeMemberList = isOneOnOneConversation || (!isProgramGroupMember && !isBoxGroupMember)
  const canUserEditMemberList = !isOneOnOneConversation && !isProgramGroupMember && !isBoxGroupMember

  const toggleMuted = async () => {
    if (muted) {
      await channel.unmute()
    } else {
      await channel.mute()
    }

    setMuted((previousState) => !previousState)
  }

  const onMuteChange = async (event: InputSwitchChangeEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (event.value) {
      await channel.unmute()
    } else {
      await channel.mute()
    }

    setMuted(event.value)
  }

  const confirmAction = (action: "leave" | "kick" | "delete" | "ban", member?: MemberType): void => {
    const getConfirmPopupContent = () => {
      switch (action) {
        case "delete":
          return {
            title: word("chat.delete_conversation.title"),
            message: word("chat.delete_conversation.message"),
          }
        case "leave":
          return {
            title: word("chat.leave_group.title"),
            message: word("chat.leave_group.message"),
          }
        case "kick":
          return {
            title: word("chat.kick_from_group.title"),
            message: word("chat.kick_from_group.message", { username: member?.name }),
          }
        case "ban":
          return {
            title: word("chat.mute_user.title"),
            message: word("chat.mute_user.message"),
          }
        default:
          return {
            title: word("global.error.label"),
            message: word("global.error.default"),
          }
      }
    }

    const { title, message } = getConfirmPopupContent()

    confirmPopup.show({
      title,
      message,
      accept: async () => {
        if (action === "delete") await deleteChannel()
        else if (action === "leave") await leaveGroup()
        else if (action === "kick" && member) await kickMember(member)
        else if (action === "ban" && member) await banMember(member)
        if (action === "delete" || action === "leave") selectFirstChannel()
      },
      footerProps: {
        align: true,
      },
    })
  }

  const leaveGroup = async () => {
    if (chatClient.user?.id) {
      await channel.removeMembers([chatClient.user.id])
    }
  }

  const kickMember = async (member: MemberType) => {
    await channel.removeMembers([member.id])
  }

  const banMember = async (member: MemberType) => {
    await channel.banUser(member.id, {
      banned_by_id: chatClient.user?.id,
    })
  }

  const unbanMember = async (member: MemberType) => {
    await channel.unbanUser(member.id)
  }

  const deleteChannel = async () => {
    await channel.hide(undefined, true)
  }

  const selectFirstChannel = () => {
    if (chatClient.activeChannels)
      setActiveChannel(chatClient.activeChannels[Object.keys(chatClient.activeChannels)[0]])
  }

  const updateGroupName = async (newGroupName: string) => {
    await channel.updatePartial({ set: { name: newGroupName } })
    setDisplayGroupNameInput(false)
  }

  const addMemberToChannel = () => {
    updateIsCreatingGroup(false)
    updateIsAddingMembersToChannel(channel)
  }

  return (
    <div
      className={
        "channel-details-overlay transition-all transition-duration-150 transition-linear bordertopright12 border-1 surface-border " +
        (width > CHANNEL_LIST_SHOWN_WINDOW_WIDTH ? "ml-3 " : "") +
        (displayChatDetailsOverlay ? "max-w-full" : "max-w-0 min-w-0")
      }
    >
      <div className="channel-details-container relative">
        {width <= DETAILS_OVERLAY_SHOWN_WINDOW_WIDTH && (
          <HUButton
            icon={{ iconView: <span className="pi pi-times" style={{ color: theme.textPrimary }} /> }}
            className=" absolute right-0 top-0 mt-2 mr-2"
            onClick={toggleDisplayChatDetailsOverlay}
            size="XS"
            colorType="Quaternary"
            type="Rounded"
          />
        )}

        <ChatAvatar image={displayImage} name={channel.data?.name} shape="circle" size={60} />

        <div className="chat-title flex flex-row align-items-center justify-content-center">
          <HUText fontStyle="LL">{getDisplayTitle(channel, chatClient.user)}</HUText>
          {canUserEditChannelName && (
            <HUButton
              icon={{ iconView: <span className="pi pi-pencil" style={{ color: theme.textPrimary }} /> }}
              className="ml-2 focus:outline-none focus:shadow-none"
              onClick={() => setDisplayGroupNameInput(true)}
              size="XS"
              colorType="Tertiary"
              type="Rounded"
            />
          )}
        </div>

        {canUserSeeMemberList && (
          <>
            <div className="separator" />

            <div className="users-list">
              {channelUsers.map((member) => {
                const isMemberGroupCreator = channelCreatorId === member.id
                return (
                  <div key={member.id} className="channel-user">
                    <div className="flex flex-row gap-1">
                      <ChatAvatar
                        image={member.avatar}
                        name={member.name}
                        shape="circle"
                        size={20}
                        isOnline={member.online}
                      />

                      <HUText fontStyle="BM" type="span" numberOfLine={2}>
                        {member.name}
                      </HUText>
                    </div>

                    <div className="flex flex-fow pr-2">
                      {isMemberGroupCreator && (
                        <HUText fontStyle="BS" type="span">
                          {word("chat.owner")}
                        </HUText>
                      )}
                      {channelCapabilities["ban-channel-members"] && !isMemberGroupCreator && (
                        <Button
                          text
                          icon={member.banned ? "pi pi-volume-off" : "pi pi-volume-up"}
                          size="small"
                          severity="secondary"
                          style={{ padding: 5, width: "2rem" }}
                          onClick={() => (member.banned ? unbanMember(member) : confirmAction("ban", member))}
                          className="cursor-pointer focus:outline-none focus:shadow-none"
                        />
                      )}
                      {channelCapabilities["update-channel"] &&
                        channel.type === ChannelType.PRIVATE &&
                        !isMemberGroupCreator && (
                          <Button
                            text
                            icon="pi pi-times"
                            size="small"
                            severity="secondary"
                            style={{ padding: 5, width: "2rem" }}
                            onClick={() => confirmAction("kick", member)}
                            className="cursor-pointer focus:outline-none focus:shadow-none"
                          />
                        )}
                    </div>
                  </div>
                )
              })}

              {channel.type === ChannelType.PRIVATE && canUserEditMemberList && (
                <Button
                  text
                  icon="pi pi-users"
                  severity="secondary"
                  className="gap-2 focus:outline-none focus:shadow-none"
                  size="small"
                  onClick={addMemberToChannel}
                >
                  {word("chat.add_group_members")}
                </Button>
              )}
            </div>
          </>
        )}

        <div className="separator mt-auto" />

        <div className="channel-action-list">
          <Button
            text
            severity="secondary"
            className="gap-2 channel-switch cursor-pointer flex flex-row align-items-center justify-content-center focus:outline-none focus:shadow-none"
            size="small"
            onClick={toggleMuted}
          >
            {word("chat.mute_group.button")}
            <HUSwitch name={"Switch"} type={"switch"} checked={muted} onChange={onMuteChange} />
          </Button>

          {isOneOnOneConversation && !channel.data?.hidden && (
            <Button
              text
              severity="secondary"
              className="gap-2 focus:outline-none focus:shadow-none"
              size="small"
              onClick={() => confirmAction("delete")}
            >
              {word("chat.delete_conversation.button")}
            </Button>
          )}
          {!isOneOnOneConversation && !isProgramGroup && !isBoxGroup && (
            <Button
              text
              severity="secondary"
              className="gap-2 focus:outline-none focus:shadow-none"
              size="small"
              onClick={() => confirmAction("leave")}
            >
              {word("chat.leave_group.button")}
            </Button>
          )}
        </div>
      </div>

      <HUDialog
        style={{ width: "500px" }}
        visible={displayGroupNameInput}
        header={word("chat.update_group_name")}
        onHide={() => setDisplayGroupNameInput(false)}
      >
        <NewChatGroupNameForm
          onValidate={updateGroupName}
          onCancel={() => setDisplayGroupNameInput(false)}
          defaultGroupName={channel.data?.name}
        />
      </HUDialog>
    </div>
  )
}
