import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ProfileIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.8611 14.6323C13.5784 12.1493 10.9874 10.4519 7.99993 10.4519C5.01249 10.4519 2.4215 12.1493 1.13879 14.6323"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11.1088 4.79219C11.1088 6.50915 9.71692 7.90103 7.99995 7.90103C6.28299 7.90103 4.89111 6.50915 4.89111 4.79219C4.89111 3.07522 6.28299 1.68335 7.99995 1.68335C9.71692 1.68335 11.1088 3.07522 11.1088 4.79219Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
