import { convertImageFromDto, HUImageDto } from "@modules/dtos/imageDto"
import { convertProgramFromDto, ProgramDto } from "@modules/programs/services/programDto"
import { Badge, PersonalRecord, PublicProfile, UserBadge } from "@modules/publicProfile/publicProfileTypes"

export interface PublicProfileDtos {
  userId: string
  username: string
  firstname?: string
  lastname?: string
  biography?: string
  avatar?: HUImageDto
  birthdate?: string
  crossfitStartDate?: string
  genderCategory?: string
  job?: string
  nationality?: string
  height?: number
  weight?: number
  // personalRecords: PersonalRecordDto[]
  ownedPrograms: ProgramDto[]
  followedPrograms: ProgramDto[]
  sharedCoachPrograms: ProgramDto[]
  badges: UserBadgeDto[]
}


export interface PersonalRecordDto {
  movementId:string
  value:number
  repetitionMax?:number
  date:string
  comment?:string
}

export interface UserBadgeDto {
    userBadgeId: string
    badge: BadgeDto
    additionalInformation?: string
    date: string
}

export interface BadgeDto {
    id: string
    name: string
    obtainable: boolean
    image: HUImageDto
}

export function convertUserBadgeFromDto(dto: UserBadgeDto): UserBadge {
  return {
    ...dto,
    badge: convertBadgeFromDto(dto.badge),
  }
}

export function convertBadgeFromDto(dto: BadgeDto): Badge {
  return {
    ...dto,
    image: convertImageFromDto(dto.image)
  }
}

export function convertPublicProfileFromDto(dto: PublicProfileDtos): PublicProfile {
  return {
    ...dto,
    birthDate: dto.birthdate ? new Date(dto.birthdate) : undefined,
    avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
    // personalRecords: dto.personalRecords.map(convertPersonalRecordDtoDto),
    followedPrograms: dto.followedPrograms.map(convertProgramFromDto),
    ownedPrograms: dto.ownedPrograms.map(convertProgramFromDto),
    sharedCoachPrograms: dto.sharedCoachPrograms.map(convertProgramFromDto),
    badges: dto.badges.map(convertUserBadgeFromDto)
  }
}

export function convertPersonalRecordDtoDto(dto: PersonalRecordDto): PersonalRecord {
  return {
    ...dto,
    date: new Date(dto.date),
  }
}



