import { HUImageDraft } from "@modules/dtos/imageDto"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { HUImage } from "@modules/types/imageTypes"
import { FileUploadRef } from "@modules/ui/components/fileUpload/fileUpload"
import { FileUploadAvatar } from "@modules/ui/components/fileUpload/fileUploadAvatar"
import { useMemo, useRef } from "react"
import styled from "styled-components"

type HUAvatarImageFormProps = {
  image?: HUImageDraft
  setImage?: (i?: HUImageDraft) => void
  showUploadButton?: boolean
  onStartUpload?: () => void
  onEndUpload?: () => void
}

export type HUAvatarFormProps = {
  username?: string
  setLoadingAvatar?: (loading: boolean) => void
} & HUAvatarImageFormProps

const AVATAR_SIZE = 140

export const HUAvatarForm: React.FC<HUAvatarFormProps> = ({
  username,
  image,
  setImage,
  showUploadButton = true,
  setLoadingAvatar,
  onStartUpload,
  onEndUpload,
}) => {
  const fileUploadRef = useRef<FileUploadRef>(null)

  const avatarImage: HUImage | undefined = useMemo(() => {
    const path = image?.localPath ?? image?.serverPath ?? undefined
    return path ? { path, blurHash: image?.localBase64 ?? "" } : undefined
  }, [image])

  return (
    <div className="flex flex-1 flex-column align-items-center mt-2 mb-2">
      <div style={{ position: "relative" }}>
        <AvatarContainer className="container">
          <ProfileAvatar size={AVATAR_SIZE} profile={{ avatar: avatarImage, username: username ?? "" }} />
          <FileUploadAvatar
            style={{ position: "absolute", right: 0, bottom: 0 }}
            ref={fileUploadRef}
            setImage={(i) => setImage?.(i)}
            showUploadButton={showUploadButton}
            onStartUpload={() => {
              setLoadingAvatar?.(true)
              onStartUpload?.()
            }}
            onEndUpload={() => {
              setLoadingAvatar?.(false)
              onEndUpload?.()
            }}
          />
        </AvatarContainer>
      </div>
    </div>
  )
}

const AvatarContainer = styled.div`
  &.container {
    height: ${AVATAR_SIZE}px;
    width: ${AVATAR_SIZE}px;
    background-color: ${({ theme }) => theme.neutralColor300};
    border-radius: ${AVATAR_SIZE}px;
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      width: 100%;
      height: 100%;
      border-radius: ${AVATAR_SIZE}px;
      object-fit: cover;
    }
  }
`
