import { SharedUserService } from "@modules/auth/services/sharedUserService"
import { ApiInterceptor } from "@modules/core/api/interceptors/apiInterceptor"
import { HUError, HUErrorCode } from "@modules/error/huError"
import { AxiosResponse, isAxiosError } from "axios"

export const responseUnauthorizeErrorInterceptor = (userService: SharedUserService): ApiInterceptor<AxiosResponse> => ({
  onRejected: (error) => {
    if (isAxiosError(error) && error.response?.status === 401) {
      userService.logout()
    } else if (
      error instanceof HUError &&
      (error.code === HUErrorCode.Unauthorised || error.code === HUErrorCode.TokenExpired)
    ) {
      userService.logout()
    }

    return Promise.reject((isAxiosError(error) && error.response?.data) || error)
  },
})
