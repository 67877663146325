import { useServicesContext } from "@modules/core/services/services.context"
import { useAsync } from "@modules/hooks/useAsync"

export const useB2BPrograms = () => {
  const { boxB2BProgramService } = useServicesContext()

  return useAsync(async () => {
    return await boxB2BProgramService.getBoxB2BPrograms()
  }, [])
}
