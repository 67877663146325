import AthletesAwaitingModeration from "@images/payment/athletesAwaitingModeration.svg"
import { word } from "@modules/core/utils/i18n"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { Program } from "@modules/programs/programTypes"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"

export const ProgramSasWarning: React.FC<{ program: Program }> = ({ program }) => {
  const { navigateToProgramSasPaymentChoices } = useProgramSidebar()

  const onValidate = () => {
    navigateToProgramSasPaymentChoices(program)
  }

  return (
    <HUStack gap={15}>
      <HUText fontStyle="TL">{word("program.price.membershipPrice")}</HUText>
      <HUText fontStyle="BS">{word("program.price.membershipPriceDescription")}</HUText>

      <HUStack className="px-8 my-4">
        <HUText fontStyle="TL" className="text-center">
          {word("program.sasWarning.title")}
        </HUText>
        <HUText fontStyle="BS" className="text-center">
          {word("program.sasWarning.description")}
        </HUText>
      </HUStack>

      <HUStack className="card justify-content-center align-items-center p-7">
        <img src={AthletesAwaitingModeration} alt={word("program.sasWarning.title")} />
      </HUStack>

      <HUButton
        className="w-full"
        type="Default"
        colorType="Primary"
        size="M"
        onClick={onValidate}
        text={word("program.sasWarning.button")}
      />
    </HUStack>
  )
}
