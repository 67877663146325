import { BoxPurchaseStatus } from "@modules/boxOffer/boxOfferTypes"
import { word } from "@modules/core/utils/i18n"
import { Currency } from "@modules/currency/currency"
import { colors } from "@modules/ui/styles/colors"
import dayjs from "dayjs"
import { DefaultTheme } from "styled-components"

export interface MemberPurchaseInfos {
  name: string
  price?: number
  currency?: Currency
  date: string
  status: BoxPurchaseStatus
  count?: number
  initialCount?: number
}

const LAST_VISITED_YELLOW = 8  // 8 days
const LAST_VISITED_RED = 15 // 15 days

/**
 * Converts the last visited date to a number and the number of days since the last visit.
 * The number it's used to define the color of the camembert. Rf: HuCambert
 * @param {date} lastVisited The last visited date.
 * @returns {number, number} An object containing the converted number and the number of days since the last visit.
 */
export const convertLasVisitedToNumberAndDays = (lastVisited: Date) => {
  const today = dayjs()
  const lastVisitedDate = dayjs(lastVisited)
  const daysSinceLastVisited = today.diff(lastVisitedDate, "day")

  if (daysSinceLastVisited > LAST_VISITED_RED) {
    return { number: 1, daysSinceLastVisited }
  } else if (daysSinceLastVisited > LAST_VISITED_YELLOW) {
    return { number: 2, daysSinceLastVisited }
  } else {
    return { number: 3, daysSinceLastVisited }
  }
}


/**
 * Converts the last visited date to a corresponding color based on the number of days since the last visit.
 * @param lastVisited The last visited date.
 * @returns The color corresponding to the number of days since the last visit.
 */
export const convertLastVisitedToColor = (theme:DefaultTheme, lastVisited?: Date ) => {
  if (lastVisited == null) {
    return ""
  }
  const today = dayjs()
  const lastVisitedDate = dayjs(lastVisited)
  const daysSinceLastVisited = today.diff(lastVisitedDate, "day")

  if (daysSinceLastVisited > LAST_VISITED_RED) {
    return theme.error
  } else if (daysSinceLastVisited > LAST_VISITED_YELLOW) {
    return theme.warning
  } else {
    return theme.sucess
  }
}

export const getOfferStatusInfos = (data: MemberPurchaseInfos) => {
  const status = data.status
  switch (status) {
    case BoxPurchaseStatus.ACTIVE:
      return { color: colors.green500, label: word("member.form.memberships.status.active.label") }
    case BoxPurchaseStatus.INACTIVE:
      return {
        color: colors.red500,
        label: word("member.form.memberships.status.inactive.label"),
      }
    case BoxPurchaseStatus.PENDING_CANCELLATION:
      return { color: colors.yellow500, label: word("member.form.memberships.status.pending_cancellation.label") }
    case BoxPurchaseStatus.PENDING_PAYMENT:
      return { color: colors.yellow500, label: word("member.form.memberships.status.pending_payment.label") }
    case BoxPurchaseStatus.PAID:
      if (data.count != null) {
        if (data.count < 1) {
          return {
            color: colors.red500,
            label: word("member.form.memberships.status.sold_out.label"),
          }
        } else {
          return {
            color: colors.green500,
            label: `${word("member.form.memberships.balance.label", { count: data.count ?? 1 })}`,
          }
        }
      }

      return {
        color: colors.green500,
        label: word("member.form.memberships.status.active.label"),
      }
  }
}
