import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { getFormatedDateTime } from "@modules/language/languageUtils"
import { ProgramPrice } from "@modules/programs/programTypes"
import { PromoCodeType } from "@modules/promoCode/services/promoCodeDto"
import { PromoCode } from "@modules/promoCode/types/promoCodeTypes"

export const getFormattedPromoCodeReduction = (promoCode: PromoCode, locale: string): string => {
  if (promoCode.type === PromoCodeType.DIRECT) {
    return `- ${formatCurrency(promoCode.value, "EUR", locale)}` // TODO set locale currency
  } else if (promoCode.type === PromoCodeType.PERCENT) {
    return `- ${promoCode.value}%`
  } else return ``
}

export const getFormattedPromoCodeExpiration = (promoCode: PromoCode): string => {
  return promoCode.expirationDate
    ? getFormatedDateTime(promoCode.expirationDate, { mode: "date" })
    : word("promoCode.view.noExpirationDate")
}

export const getFormattedReductedProgramPrice = (
  locale: string,
  programPrice?: ProgramPrice,
  promoCodeValue?: number,
  promoCodeType?: PromoCodeType,
): string => {
  if (programPrice && promoCodeType && promoCodeValue) {
    if (promoCodeType === PromoCodeType.PERCENT)
      return `${formatCurrency((programPrice.value * (100 - promoCodeValue)) / 100, programPrice.currency, locale)}`
    else if (promoCodeType === PromoCodeType.DIRECT)
      if (promoCodeValue >= programPrice.value) return formatCurrency(0, programPrice.currency, locale)
      else return `${formatCurrency(programPrice.value - promoCodeValue, programPrice.currency, locale)}`
  }

  return `-- €` // TODO set locale currency
}
