import {
  BoxSlotsTemplateWeek,
  BoxSlotsTemplateWeekPartial,
  BoxSlotTemplate,
  BoxSlotTemplateDraft,
} from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import {
  BoxSlotTemplateDto,
  BoxSlotTemplateWeekDto,
  convertAllBoxSlotTemplateWeekFromDto,
  convertBoxSlotsTemplateListFromDto,
  convertBoxSlotTemplateFromDto,
  convertBoxSlotTemplateWeekFromDto,
  convertPartialBoxSlotTemplateDraftToDto,
} from "@modules/boxSlotTemplate/services/boxSlotTemplateDto"
import { ApiClient } from "@modules/core/api/client/apiClient"

export class BoxSlotTemplateApi {
  private baseUrl = "/box/programs"

  private buildBoxSessionUrl = (programId: string) => `${this.baseUrl}/${programId}/slot-templates`
  private buildBoxSlotListUrl = (programId: string) => `${this.baseUrl}/${programId}/slot-templates/add-slots`

  constructor(private api: ApiClient) {}

  async getBoxSlotTemplate(boxProgrammationId: string, id: string): Promise<BoxSlotTemplate> {
    const resultDto = await this.api.get<BoxSlotTemplateDto>(`${this.buildBoxSessionUrl(boxProgrammationId)}/${id}`)
    return convertBoxSlotTemplateFromDto(boxProgrammationId, resultDto.data)
  }

  async getAllBoxSlotsTemplateWeek(): Promise<BoxSlotsTemplateWeekPartial> {
    const resultDto = await this.api.get<BoxSlotTemplateWeekDto>(`${this.baseUrl}/all-slot-templates`)
    return convertAllBoxSlotTemplateWeekFromDto(resultDto.data)
  }

  async getBoxSlotsTemplateWeek(boxProgrammationId: string): Promise<BoxSlotsTemplateWeek> {
    const resultDto = await this.api.get<BoxSlotTemplateWeekDto>(`${this.buildBoxSessionUrl(boxProgrammationId)}`)

    return convertBoxSlotTemplateWeekFromDto(boxProgrammationId, resultDto.data)
  }

  async createBoxSlotTemplate(
    boxProgrammationId: string,
    boxSlotTemplate: BoxSlotTemplateDraft,
  ): Promise<BoxSlotTemplate> {
    const resultDto = await this.api.post<BoxSlotTemplateDto>(
      this.buildBoxSessionUrl(boxProgrammationId),
      boxSlotTemplate,
    )
    return convertBoxSlotTemplateFromDto(boxProgrammationId, resultDto.data)
  }

  async updateBoxSlotTemplate(
    boxProgrammationId: string,
    id: string,
    boxSlotTemplate: BoxSlotTemplateDraft,
  ): Promise<BoxSlotTemplate> {
    const resultDto = await this.api.put<BoxSlotTemplateDto>(
      `${this.buildBoxSessionUrl(boxProgrammationId)}/${id}`,
      convertPartialBoxSlotTemplateDraftToDto(boxSlotTemplate),
    )
    return convertBoxSlotTemplateFromDto(boxProgrammationId, resultDto.data)
  }

  async deleteBoxSlotTemplate(boxProgrammationId: string, id: string): Promise<void> {
    await this.api.delete<BoxSlotTemplateDto>(`${this.buildBoxSessionUrl(boxProgrammationId)}/${id}`)
  }

  async createBoxSlotsTemplateList(
    boxProgrammationId: string,
    boxSlotTemplates: BoxSlotTemplateDraft[],
  ): Promise<BoxSlotTemplate[]> {
    const resultDto = await this.api.post<BoxSlotTemplateDto[]>(this.buildBoxSlotListUrl(boxProgrammationId), {
      slotsTemplate: boxSlotTemplates.map((boxSlotTemplate) => boxSlotTemplate),
    })
    return convertBoxSlotsTemplateListFromDto(boxProgrammationId, resultDto.data)
  }
}
