import { word } from "@modules/core/utils/i18n"
import { useTransactionUser } from "@modules/finances/hooks/useTransactions"
import { MemberTransaction, TransactionMemberAddress, UserTransaction } from "@modules/finances/types/transactionType"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import dayjs from "dayjs"
import { useTheme } from "styled-components"

type InvoiceDetailsProps = { transactionDetails: UserTransaction | MemberTransaction }
export const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({ transactionDetails }) => {
  const theme = useTheme()
  const user = useTransactionUser(transactionDetails)

  const invoiceRow = (fieldLabel: string, fieldValue?: string | React.ReactNode) =>
    fieldValue && (
      <HURow className="mb-2 flex align-items-start align-items-baseline">
        <HUText color={theme.neutralColor700} fontStyle="TM" className="mr-1">
          {fieldLabel}
        </HUText>
        <HUText color={theme.neutralColor600} fontStyle="BM" style={{ lineHeight: "23px" }}>
          {fieldValue}
        </HUText>
      </HURow>
    )

  const formatAddress = (address?: TransactionMemberAddress) => {
    if (address?.line1 && address.postalCode && address.city)
      return (
        <>
          {address.line1 + " " + (address.line2 || "")}
          <br />
          {address.postalCode + " " + address.city}
        </>
      )
  }

  return (
    <div className="mb-4">
      {invoiceRow(`${word("finances.page.table.header.invoice")} :`, transactionDetails.invoice?.number)}
      {invoiceRow(`${word("finances.details.issue_date")} :`, dayjs(transactionDetails.invoice?.date).format("L"))}
      {invoiceRow(`${word("finances.details.time")} :`, dayjs(transactionDetails.invoice?.date).format("HH:mm"))}
      {user?.boxMemberNumber &&
        invoiceRow(`${word("finances.details.membership_number")} :`, `${user.boxMemberNumber}`)}
      {invoiceRow(`${word("finances.details.user")} :`, `${user?.firstname} ${user?.lastname}`)}
      {invoiceRow(`${word("box.form.address.address.label")} :`, formatAddress(user?.address))}
    </div>
  )
}
