import { ConfigService } from "@modules/core/services/configService"
import { Service } from "@modules/core/services/serviceType"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { ILocalStorage } from "@modules/storage/services/ILocalStorage"
import { LoggerType } from "@modules/utils/loggerUtils"
import _ from "lodash"
import { Unsubscriber, WritableObservable } from "micro-observables"

export interface LocalStorageConvertOptions<T extends object | null> {
  convert?: {
    toStorage: (from: T) => string
    fromStorage: (to: string) => T
  }
}

export type LocalStorageOptions<T extends object | null> = LocalStorageConvertOptions<T> & {
  version?: number
}

export class LocalStorageService implements Service {
  constructor(private _storage: ILocalStorage) {
    // Only on DEV, assert if there are a duplicated keys(to be sure to not use an existing key used to another thing)
    if (ConfigService.devMode) {
      const allKeys = _.flatMapDeep(LocalStorageKeys, (it) => (_.isObject(it) ? Object.values(it) : it))
      const duplicatedValues = allKeys.filter((value, index, acc) => acc.indexOf(value) !== index)
      if (duplicatedValues.length > 0) {
        console.error(LoggerType.LocalStorage + `duplicated storage keys: '${duplicatedValues}'`)
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  connect<T extends object | null>(
    observale: WritableObservable<T>,
    key: string,
    defaultValue: T | (() => T),
    options?: LocalStorageOptions<T>,
  ): Unsubscriber {
    observale.set(this.load(key, defaultValue, options))
    return observale.subscribe((value) => this.save(key, value, options))
  }

  load<T extends object | null>(key: string, defaultValue: T | (() => T), options?: LocalStorageOptions<T>): T {
    const getDefaultValue = () => {
      return _.isFunction(defaultValue) ? defaultValue() : defaultValue
    }

    function getConvertedValue(value: string): T {
      if (options?.convert) {
        return options.convert.fromStorage(value)
      } else {
        return JSON.parse(value)
      }
    }

    const debugInfo: { [key: string]: string | number | boolean } = {}
    try {
      const result = this._storage.load(key)

      debugInfo.hasDefaultValueFunc = _.isFunction(defaultValue)
      debugInfo.defaultValue = JSON.stringify(getDefaultValue())
      debugInfo.options = JSON.stringify(options)
      debugInfo.result = JSON.stringify(result)
      debugInfo.currentVersion = options?.version ?? 1

      if (result === null || result === undefined) {
        debugInfo.state = "null or undefined"
        return getDefaultValue()
      } else if (!_.isObject(result) || !("_version" in result) || !("data" in result)) {
        debugInfo.state = "bad format"
        if (ConfigService.devMode) {
          console.warn(LoggerType.LocalStorage + `load ${key} with bad format ${JSON.stringify(result)}`)
        } else {
          console.warn(LoggerType.LocalStorage + `load ${key} with bad format`)
        }
        return getDefaultValue()
      } else if (result._version !== (options?.version ?? 1)) {
        debugInfo.state = "migration"
        console.log(
          LoggerType.LocalStorage +
            `load ${key} with reset migration from ${result._version} to ${options?.version ?? 1}`,
        )
        return getDefaultValue()
      } else {
        debugInfo.state = "normal"
        return getConvertedValue(result.data)
      }
    } catch (e) {
      if (ConfigService.devMode) {
        console.error(LoggerType.LocalStorage + `load ${key} failed ${JSON.stringify(debugInfo)}`, e)
      } else {
        console.error(LoggerType.LocalStorage + `load ${key} failed`, e)
      }
      return getDefaultValue()
    }
  }

  save<T extends object | null>(key: string, value: T, options?: LocalStorageOptions<T>): void {
    try {
      const convertedValue = options?.convert ? options.convert.toStorage(value) : JSON.stringify(value)

      this._storage.save(key, {
        _version: options?.version ?? 1,
        data: convertedValue,
      })
    } catch (e) {
      if (ConfigService.config.ENV !== "prod") {
        console.error(LoggerType.LocalStorage + `save ${key} failed ${JSON.stringify(value)}`, e)
      } else {
        console.error(LoggerType.LocalStorage + `save ${key} failed`, e)
      }
    }
  }

  async remove(key: string) {
    this._storage.remove(key)
  }
}
