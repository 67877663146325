import { PauseIcon } from "@images/svgIcons/pauseIcon"
import { PlayIcon } from "@images/svgIcons/playIcon"
import { ResumeIcon } from "@images/svgIcons/resumeIcon"
import { TrophyIcon } from "@images/svgIcons/trophyIcon"
import { word } from "@modules/core/utils/i18n"
import { getFormatedDateTime } from "@modules/language/languageUtils"
import { useProgramOnOffProgression, useProgramOnOffProgressionActions } from "@modules/programs/hooks/useProgramsOnOff"
import {
  ProgramOnOff,
  ProgramOnOffStatus,
  ProgramOnOffTypeOf,
  ProgressionStatusProgramOnOff,
} from "@modules/programs/programOnOffTypes"
import { ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import { Circles } from "@modules/ui/components/circles"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

type progressionRateProps = {
  program: ProgramOnOff
  editionAllowed: boolean
}

export const ProgressionRate: React.FC<progressionRateProps> = ({ program, editionAllowed }) => {
  const { result: progression } = useProgramOnOffProgression(
    program.id,
    program._programType !== ProgramOnOffTypeOf ||
      !(
        program.subscriptionStatus === ProgramSubscriptionStatus.ACTIVE ||
        program.subscriptionStatus === ProgramSubscriptionStatus.PENDING_CANCELLATION
      ),
  )
  const { isUpdating, startProgram, stopProgram, restartProgram } = useProgramOnOffProgressionActions(program.id)
  const theme = useTheme()

  const weekNumber = Math.ceil(program.duration / 7)
  const fillRatePoints = useMemo(() => {
    if (!progression) return 0

    const fillRate = (progression.currentIndex / progression.duration) * 100
    const fillRatePointsValue = Math.round(fillRate / 10)

    return Math.min(10, Math.max(0, fillRatePointsValue))
  }, [progression])

  const isPayed =
    program.subscriptionStatus === ProgramSubscriptionStatus.ACTIVE ||
    program.subscriptionStatus === ProgramSubscriptionStatus.PENDING_CANCELLATION
  const isPaused = progression?.status === ProgressionStatusProgramOnOff.STOPPED
  const isNotStarted = progression?.status === ProgressionStatusProgramOnOff.NOT_STARTED
  const isStarted = progression?.status === ProgressionStatusProgramOnOff.STARTED
  const isCompleted = progression?.status === ProgressionStatusProgramOnOff.COMPLETED
  const mainTextColor = isCompleted ? theme.mainWhite : isPaused ? theme.neutralColor600 : theme.neutralColor900
  const secondaryTextColor = isCompleted
    ? theme.secondaryColor200
    : isPaused
      ? theme.neutralColor600
      : theme.neutralColor700

  return (
    <>
      <div style={{ marginBottom: 7 }}>
        <HUText type="label" color={theme.neutralColor700} fontStyle="LS">
          {word("program.onOff.formik.label").toUpperCase()}
        </HUText>
      </div>
      <CardContainer $stopped={isPaused} $completed={isCompleted}>
        <div className="flex align-items-center mb-2 ">
          <HUText fontStyle="DS" className="mr-2" color={mainTextColor}>
            {`${program.duration}`} {word("global.day.label", { count: program.duration })}
          </HUText>
          <HUText
            fontStyle="BM"
            color={secondaryTextColor}
          >{`( ${weekNumber} ${word("global.week.label", { count: weekNumber })} )`}</HUText>
        </div>
        {isPayed ? (
          <>
            <div className="flex justify-content-between mb-3">
              <div className="flex flex-column align-items-center w-6">
                <HUText fontStyle="LS" color={secondaryTextColor}>
                  {word("program.details.progression.progress")}
                </HUText>
                {isCompleted ? (
                  <HURow className="mb-1 align-items-center gap-1">
                    <TrophyIcon color={mainTextColor} />
                    <HUText fontStyle="BM" color={mainTextColor}>
                      {word("program.details.progression.progress_completed")}
                    </HUText>
                  </HURow>
                ) : (
                  <HUText fontStyle="BM" className="mb-1" color={mainTextColor}>
                    {`${progression?.currentIndex} / ${progression?.duration} ${word("global.day.label", { count: program.sessionCount })}`}
                  </HUText>
                )}
                <Circles count={10} filled={fillRatePoints} progressionstyle="circle" color={mainTextColor} />
              </div>
              {progression?.startedAt && (
                <div className="flex flex-column align-items-center w-6 justify-content-start">
                  <HUText fontStyle="LS" color={secondaryTextColor}>
                    {word("program.details.progression.start_date")}
                  </HUText>
                  <HUText fontStyle="BM" color={mainTextColor}>
                    {getFormatedDateTime(progression.startedAt)}
                  </HUText>
                </div>
              )}
            </div>

            {(isNotStarted || isPaused) && editionAllowed && (
              <HUButton
                type="Left_Icon"
                colorType="Primary"
                size="M"
                style={{ width: "100%", padding: 10 }}
                icon={{ icon: PlayIcon }}
                text={word(isPaused ? "program.button.resume" : "program.button.start")}
                onClick={startProgram}
                disabled={isUpdating}
              />
            )}

            {isPaused && progression.stoppedAt && editionAllowed && (
              <HUText fontStyle="BS" className="m-auto mt-2">
                {word("program.details.progression.resume", { date: getFormatedDateTime(progression.stoppedAt) })}
              </HUText>
            )}

            {program.status === ProgramOnOffStatus.PUBLISHED && isStarted && editionAllowed && (
              <HUButton
                type="Left_Icon"
                colorType="Primary"
                size="M"
                style={{ width: "100%", padding: 10 }}
                icon={{ icon: PauseIcon }}
                text={word("program.button.pause")}
                onClick={stopProgram}
                disabled={isUpdating}
              />
            )}

            {(isPaused || isCompleted || isStarted) && editionAllowed && (
              <HUButton
                type="Left_Icon"
                size="S"
                colorType="Tertiary"
                icon={{ icon: ResumeIcon }}
                style={{ width: "100%", marginTop: 20, padding: 10 }}
                text={word("program.button.restart")}
                onClick={restartProgram}
                disabled={isUpdating}
              />
            )}
          </>
        ) : (
          <ViewProgramBeforePayment>
            <HUText fontStyle="BM">{word("program.onOff.progressionRate.description")}</HUText>
          </ViewProgramBeforePayment>
        )}
      </CardContainer>
    </>
  )
}

const CardContainer = styled.div<{ $stopped: boolean; $completed: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme, $stopped, $completed }) =>
    $completed
      ? `linear-gradient(90deg, ${theme.secondaryColor800} 0%, ${theme.secondaryColor600} 100%)`
      : $stopped
        ? theme.neutralColor300
        : theme.neutralColor100};
  padding: 22px;
  border-radius: 33px;
  margin-bottom: 20px;
`

const ViewProgramBeforePayment = styled.div``
