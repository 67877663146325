import { Address, AddressDraft } from "@modules/address/addressTypes"
import { EmptyAddress } from "@modules/address/addressUtils"
import { BirthDate, GenderCategory, Nationality, User } from "@modules/auth/user"
import { HUImageDraft } from "@modules/dtos/imageDto"
import { HUImage } from "@modules/types/imageTypes"

export type ProgramAuthor = Profile
export const ProfileTypeOf = "USER_PROFILE"

export type Profile = {
  id: string
  _type: typeof ProfileTypeOf
  firstname: string
  lastname: string
  username: string
  avatar?: HUImage
  genderCategory?: GenderCategory
  birthdate?: BirthDate
  address?: Address
  nationality?: Nationality
}

export type ProfileDraft = {
  firstname: string
  lastname: string
  username: string
  avatar?: HUImageDraft
  genderCategory?: GenderCategory
  birthdate?: BirthDate
  address?: AddressDraft
  nationality?: Nationality
}

export function convertProfileToDraft(profile?: Profile): ProfileDraft {
  return {
    firstname: profile?.firstname ?? "",
    lastname: profile?.lastname ?? "",
    username: profile?.username ?? "",
    avatar: profile?.avatar
      ? {
          serverBlurHash: profile.avatar.blurHash ?? "",
          serverPath: profile.avatar.path ?? "",
          localPath: undefined,
          localBase64: undefined,
        }
      : undefined,
    genderCategory: profile?.genderCategory,
    birthdate: profile?.birthdate,
    address: profile?.address ? profile.address : EmptyAddress,
    nationality: profile?.nationality,
  }
}

export function convertUserToProfile(user: User): Profile {
  return {
    id: user.id,
    _type: ProfileTypeOf,
    firstname: user.firstname,
    lastname: user.lastname,
    username: user.username,
    avatar: user.avatar,
    genderCategory: user.genderCategory,
  }
}
export type RequestsSASProfile = {
  subscriptionId: string
  subscriptionDate: Date
  profile: Profile
}
