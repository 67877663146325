export enum RpeColor {
  Color1 = "#3FFF01",
  Color2 = "#8EFF01",
  Color3 = "#C6FF00",
  Color4 = "#E1EB03",
  Color5 = "#F8D502",
  Color6 = "#FFAF04",
  Color7 = "#FF8203",
  Color8 = "#FF5902",
  Color9 = "#FF3701",
  Color10 = "#FF0801",
}

export const getRpeColor = (rpe: number | null): string => {
  if (rpe === null) {
    return "#FFFFFF"
  } else {
    const key = `Color${rpe}` as keyof typeof RpeColor
    return RpeColor[key]
  }
}
