import { ICountry, TCountryCode, countries } from "countries-list"

export const countriesEntries = Object.entries(countries) as [TCountryCode, ICountry][]

export type CountryItem = {
  label: string
  code: string
  capital: string
  continent: string
  currency: string[]
  languages: string[]
  native: string
  phone: number[]
}
export const countriesListArray = (): CountryItem[] => {
  return Object.entries(countries)
    .map(([key, value]) => {
      return { label: value.name, code: key, ...value }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
}
