import DownEmoji from "@modules/chat/icons/reactions/downEmoji.svg"
import FistEmoji from "@modules/chat/icons/reactions/fistEmoji.svg"
import MdrEmoji from "@modules/chat/icons/reactions/mdrEmoji.svg"
import StrongEmoji from "@modules/chat/icons/reactions/strongEmoji.svg"
import UpEmoji from "@modules/chat/icons/reactions/upEmoji.svg"
import { ReactionType } from "@modules/chat/reactionTypes"

export const customReactions: any[] = [
  {
    id: ReactionType.FIST,
    name: ReactionType.FIST,
    imageUrl: FistEmoji,
  },
  {
    id: ReactionType.STRONG,
    name: ReactionType.STRONG,
    imageUrl: StrongEmoji,
  },
  {
    id: ReactionType.UP,
    name: ReactionType.UP,
    imageUrl: UpEmoji,
  },
  {
    id: ReactionType.DOWN,
    name: ReactionType.DOWN,
    imageUrl: DownEmoji,
  },
  {
    id: ReactionType.HAHA,
    name: ReactionType.HAHA,
    imageUrl: MdrEmoji,
  },
]
