import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { HUFontStyle } from "@modules/ui/styles/font"
import styled from "styled-components"

type HUCardAlertProps = {
  title: string
  description?: string
  icon: React.ReactElement<React.SVGProps<SVGSVGElement>>
  titleFontStyle?: HUFontStyle
  descriptionFontStyle?: HUFontStyle
  className?: string
}

export const HUCardAlert: React.FC<HUCardAlertProps> = ({
  title,
  description,
  icon,
  titleFontStyle = "BL",
  descriptionFontStyle = "BM",
  className,
}) => {
  return (
    <AlertContainer gap={18} className={className}>
      {icon}
      <HUStack gap={4} className="justify-content-evenly">
        <HUText fontStyle={titleFontStyle}>{title}</HUText>
        {description && <HUText fontStyle={descriptionFontStyle}>{description}</HUText>}
      </HUStack>
    </AlertContainer>
  )
}

const AlertContainer = styled(HURow)`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.huCardAlertBackground};
  border-left: solid 5px ${({ theme }) => theme.primaryColor};
  padding: 24px 24px 24px 18px;
  border-radius: 6px;
`
