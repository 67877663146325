import {
  BoxFormikType,
  convertBoxFormFormik,
  convertBoxToFormik,
  getValidationSchema,
} from "@modules/box/boxFormikType"
import { Box, convertBoxToDraft } from "@modules/box/boxTypes"
import { BoxSecondaryInfoForm } from "@modules/box/components/boxSecondaryInfo"
import { BoxMainInfoForm } from "@modules/box/components/mainBoxInfoForm"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { RouteNavigate } from "@modules/navigation/useBaseNavigate"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { useToast } from "@modules/ui/components/huToast"
import { minScreenWidth } from "@modules/ui/uiConstants"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useFormik } from "formik"
import { Button } from "primereact/button"
import React, { useState } from "react"
import styled from "styled-components"

type Props = {
  box?: Box
  onSave?: () => void
} & React.HTMLAttributes<HTMLDivElement>

export const BoxForm: React.FC<Props> = ({ box, onSave }) => {
  const { boxService } = useServicesContext()
  const [loading, setLoading] = useState(false)
  const boxNavigate = useBoxNavigate()
  const toast = useToast()
  const [isImageUploading, setIsImageUploading] = useState(false)

  const formik = useFormik<BoxFormikType>({
    initialValues: convertBoxToFormik(convertBoxToDraft(box)),
    validationSchema: getValidationSchema(),
    onSubmit: async (values) => {
      try {
        setLoading(true)
        if (box) {
          await boxService.updateBox(box.id, convertBoxFormFormik(values))
        } else {
          const box = await boxService.createBox(convertBoxFormFormik(values))
          //TODO: temporary navigation fix
          setTimeout(() => boxNavigate(RouteNavigate.Root, { newBoxId: box.id }), 500)
        }
        onSave?.()
        toast.show({
          severity: "success",
          summary: word("box.form.update.success"),
        })
      } catch (e) {
        console.error(LoggerType.Box + "update", e)
        toast.show({
          severity: "error",
          summary: word("global.error.label"),
        })
      } finally {
        setLoading(false)
      }
    },
  })

  const isCreation = !box
  return (
    <>
      <div style={{ minWidth: minScreenWidth }}>
        {isCreation && <h1 className="text-center"> {word("box.creation.title")}</h1>}
        <div className="flex justify-content-center ">
          <form onSubmit={formik.handleSubmit} className="col-12 flex flex-column">
            <FormContainer className="flex-column md:flex-row">
              <BoxMainInfoForm
                isFormFieldInValid={(input) => isFormFieldInValid(input, formik)}
                getFormErrorMessage={(input) => getFormErrorMessage(input, formik)}
                formik={{
                  values: formik.values,
                  getFieldProps: formik.getFieldProps,
                  setFieldValue: formik.setFieldValue,
                  handleChange: async (e: React.ChangeEvent, fieldName: string) => {
                    await formik.handleChange(e)
                    formik.validateField(fieldName)
                  },
                  handleBlur: async (e: React.FocusEvent, fieldName: string) => {
                    await formik.handleBlur(e)
                    formik.validateField(fieldName)
                  },
                }}
              />
              <BoxSecondaryInfoForm formik={formik} setIsImageUploading={setIsImageUploading} />
            </FormContainer>
            <ButtonsContainer>
              <Button
                type="submit"
                disabled={isImageUploading}
                rounded
                label={box ? word("global.save") : word("global.create")}
                loading={loading}
              />
              {/*
						Remove delete box, not yet managed correctly
						{box ? (
							<Button
								rounded
								outlined
								className="p-button-secondary"
								label={word("box.settings.page.delete.button")}
								onClick={() => {
									confirmPopup.show({
										title: "",
										message: (
											<HUStack gap={10} align className="mb-3" style={{ borderRadius: 12 }}>
												<DeletionAlertIcon />
												<HUText fontStyle="TL">{word("box.settings.page.delete.popup.title")}</HUText>
												<HUText color={colors.neutral600} className="text-center mr-3 ml-3" fontStyle="BM">
													{word("box.settings.page.delete.popup.description")}
												</HUText>
											</HUStack>
										),
										accept: async () => {
											await boxService.deleteBox(box.id);
											mySpaceNavigate(RouteNavigate.Root);
										},
										acceptValidated: word("box.delete.deteled_toast"),
										headerStyle: {
											paddingBottom: 0,
											position: "absolute",
											right: 0,
											top: -16,
											borderRadius: 12,
											width: "inherit",
										},
										footerProps: {
											confirmText: word("global.delete"),
											cancelText: word("global.cancel"),
											confirmClassName: "p-button-primary p-button-danger flex-1 m-0",
											cancelClassName: "p-button-secondary flex-1 m-0",
											align: true,
											hideCancelIcon: true,
											hideConfirmIcon: true,
										},
									});
								}}
							/>
						) : null} */}
            </ButtonsContainer>
          </form>
        </div>
      </div>
    </>
  )
}

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-bottom: 20px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`
