import {
  BoxProgrammationFormView,
  BoxProgrammationFormViewRef,
} from "@modules/boxProgrammation/components/boxProgrammationFormView"
import { BoxProgrammationInfoResume } from "@modules/boxProgrammation/components/boxProgrammationInfoResume"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { useRef } from "react"

export const useBoxProgrammationSidebar = () => {
  const { sidebarNavigateTo } = useSidebar()
  const boxProgrammationFormViewRef = useRef<BoxProgrammationFormViewRef>(null)

  const navigateToProgrammationView = (programmationId: string) => {
    sidebarNavigateTo({
      content: <BoxProgrammationInfoResume programmationId={programmationId} />,
      replaceAllComponents: true,
    })
  }

  const navigateToBoxProgrammationCreation = () => {
    sidebarNavigateTo({
      content: <BoxProgrammationFormView ref={boxProgrammationFormViewRef} />,
      onHide: () => {
        return boxProgrammationFormViewRef && boxProgrammationFormViewRef.current
          ? boxProgrammationFormViewRef.current.onClose()
          : Promise.resolve(true)
      },
    })
  }

  const navigateToProgrammationEdition = (boxProgrammationId: string) => {
    sidebarNavigateTo({
      content: <BoxProgrammationFormView ref={boxProgrammationFormViewRef} boxProgrammationId={boxProgrammationId} />,
      onHide: () => {
        return boxProgrammationFormViewRef && boxProgrammationFormViewRef.current
          ? boxProgrammationFormViewRef.current.onClose()
          : Promise.resolve(true)
      },
    })
  }

  return {
    navigateToProgrammationView,
    navigateToBoxProgrammationCreation,
    navigateToProgrammationEdition,
  }
}
