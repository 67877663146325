import { AddressDraftDto, AddressDto } from "@modules/address/addressDto"
import { Box, BoxDraft, BoxTypeOf, ItemBox, PhoneNumberWithPrefix } from "@modules/box/boxTypes"
import { HUImageDto, convertImageFromDto } from "@modules/dtos/imageDto"
import { StripeAccountStatus } from "@modules/payment/stripeTypes"
import { ProfileDto, convertProfileFromDto } from "@modules/profile/profileDtos"

export type ItemBoxDto = {
  _type: typeof BoxTypeOf
  id: string
  logo: HUImageDto
  cover: HUImageDto
  name: string
  address: AddressDto
  phoneNumberWithPrefix: PhoneNumberWithPrefix
  email: string
}

export type BoxDto = {
  id: string
  name: string
  address: AddressDto
  phoneNumberWithPrefix: PhoneNumberWithPrefix
  email: string
  owner: ProfileDto
  logo: HUImageDto
  cover: HUImageDto
  photos: HUImageDto[]
  siren: string
  description: string
  isSubscribed: boolean
  stripeAccountStatus?: StripeAccountStatus
}

export type BoxDraftDto = {
  name: string
  address: AddressDraftDto
  phoneNumberWithPrefix: PhoneNumberWithPrefix
  email: string
  logo?: string
  cover?: string
  photos: string[]
  siren: string
  description: string
}

export type InfosSubscriptionDto = {
  subscriptionDate: string
  minimumCommitmentPeriod: boolean
  subscriptionEndDate: string
}

export function convertItemBoxFromDto(dto: ItemBoxDto): ItemBox {
  return {
    ...dto,
    _type: BoxTypeOf,
    logo: convertImageFromDto(dto.logo),
    cover: convertImageFromDto(dto.cover),
  }
}

export function convertBoxFromDto(dto: BoxDto): Box {
  return {
    ...dto,
    _type: BoxTypeOf,
    author: convertProfileFromDto(dto.owner),
    logo: convertImageFromDto(dto.logo),
    cover: convertImageFromDto(dto.cover),
    boxPhotos: dto.photos ? dto.photos.map((image) => convertImageFromDto(image)) : [],
  }
}

export function convertBoxDraftToDto(draft: BoxDraft): BoxDraftDto {
  return {
    name: draft.name,
    address: draft.address,
    phoneNumberWithPrefix: draft.phoneNumberWithPrefix,
    email: draft.email,
    logo: draft.logo?.path,
    cover: draft.cover?.path,
    photos: draft.boxPhotos.map((image) => image.path),
    siren: draft.siren,
    description: draft.description,
  }
}

export function convertPartialBoxDraftToDto(draft: Partial<BoxDraft>): Partial<BoxDraftDto> {
  return {
    name: draft.name ?? undefined,
    address: draft.address ?? undefined,
    phoneNumberWithPrefix: draft.phoneNumberWithPrefix ?? undefined,
    email: draft.email ?? undefined,
    siren: draft.siren ?? undefined,
    description: draft.description ?? undefined,
    logo: draft.logo?.path,
    cover: draft.cover?.path,
  }
}
