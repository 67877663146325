import SessionEmpty from "@images/empty/session_empty.svg"
import SessionHideEmpty from "@images/empty/session_hide_empty.svg"
import { CardioIcon } from "@images/svgIcons/cardioIcon"
import { CopyIcon } from "@images/svgIcons/copyIcon"
import { GlobeIcon } from "@images/svgIcons/globeIcon"
import { GymIcon } from "@images/svgIcons/gymIcon"
import { LockIcon } from "@images/svgIcons/lockIcon"
import RestDayIcon from "@images/svgIcons/restDayIcon.svg"
import { TrashIcon } from "@images/svgIcons/trashIcon"
import { WeightliftingIcon } from "@images/svgIcons/weightliftingIcon"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ExerciseDetail } from "@modules/exercises/components/exerciseDetail"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { ProgramInfiniteSession, ProgramOnOffSession } from "@modules/programSession/programSessionTypes"
import { useProgramOnOffProgression } from "@modules/programs/hooks/useProgramsOnOff"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf, ProgressionStatusProgramOnOff } from "@modules/programs/programOnOffTypes"
import { Program, ProgramSubscriptionStatus, ProgramVisibility } from "@modules/programs/programTypes"
import { ScoreboardCard } from "@modules/scoreboard/components/scoreboardCard"
import { useScoreboardSidebar } from "@modules/scoreboard/hooks/useScoreboardSidebar"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { EllipsisMenu } from "@modules/ui/components/ellipsisMenu"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { EditIcon } from "@modules/ui/icons/editIcon"
import { displayedFullDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { capitalize } from "lodash"
import { MenuItem } from "primereact/menuitem"
import { Tag } from "primereact/tag"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import styled, { useTheme } from "styled-components"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"

type SessionDetailsContentProps = {
  session: ProgramInfiniteSession | ProgramOnOffSession
  program: Program
  programPath: string
  allowEditContent: boolean
  onEdit: () => void
  onDeleteSessionConfirmationPopup: () => void
  refreshSession: () => void
}

export const SessionDetailsContent: React.FC<SessionDetailsContentProps> = ({
  session,
  program,
  programPath,
  allowEditContent,
  onEdit,
  onDeleteSessionConfirmationPopup,
  refreshSession,
}) => {
  const { programSessionService } = useServicesContext()
  const language = useLanguage()
  const toast = useToast()
  const theme = useTheme()
  const { hideSidebar } = useSidebar()
  const { isAuthor, isAcceptedSharedCoach } = useProgramRights(program)

  const { navigateToScoreboardView } = useScoreboardSidebar()
  const { result: progression } = useProgramOnOffProgression(
    program.id,
    program._programType !== ProgramOnOffTypeOf ||
      !(
        program.subscriptionStatus === ProgramSubscriptionStatus.ACTIVE ||
        program.subscriptionStatus === ProgramSubscriptionStatus.PENDING_CANCELLATION
      ),
  )

  const sessionDate = useMemo(
    () =>
      session.date
        ? capitalize(dayjs(session.date).format(displayedFullDateFormat)) +
          ("index" in session ? ` - ${capitalize(word("global.day.label", { count: 1 }))} ${session.index + 1}` : "")
        : "index" in session
          ? `${capitalize(word("global.day.label", { count: 1 }))} ${session.index + 1}`
          : null,
    [session.date, language],
  )

  const scoreExercicesRequested = useMemo(() => {
    const listScoreExercicesRequest = session.exercises.filter((exercise) => exercise.scoreType !== ScoreType.NO_SCORE)
    return listScoreExercicesRequest
  }, [session])

  const hideSessionContent = useMemo(() => {
    const sessionHasStarted = session.date && new Date() > new Date(session.date)

    if (sessionHasStarted) {
      return false
    }

    return session.hideSessionContent
  }, [session])

  const isAllScoresCompleted = useMemo(
    () =>
      scoreExercicesRequested.every((exercise) => {
        return exercise.score?.value
      }),
    [scoreExercicesRequested],
  )

  const canUserAccessToScoreboard = useMemo(() => {
    if (program._programType === ProgramInfiniteTypeOf) {
      if (!program.hideScoreboard || (program.hideScoreboard && isAllScoresCompleted)) {
        return true
      }
      return false
    }

    return false
  }, [program, isAllScoresCompleted])

  const canUserSeeSessionContent = isAuthor || isAcceptedSharedCoach || !hideSessionContent
  const canUserEditSession = (isAuthor || isAcceptedSharedCoach) && allowEditContent
  const isSubscriptionActive =
    ProgramSubscriptionStatus.ACTIVE ||
    ProgramSubscriptionStatus.PENDING_CANCELLATION ||
    ProgramSubscriptionStatus.TRIALING ||
    ProgramSubscriptionStatus.TRIAL_PENDING_CANCELLATION
  const canUserCompleteExercises =
    ((isSubscriptionActive || isAuthor || isAcceptedSharedCoach) &&
      (program._programType !== ProgramOnOffTypeOf ||
        ("index" in session &&
          progression &&
          (progression.status === ProgressionStatusProgramOnOff.STARTED ||
            progression.currentIndex > session.index)))) ??
    false

  const ellipsisItems: MenuItem[] = [
    {
      items: [
        {
          label: word("global.edit"),
          icon: <EditIcon color={theme.neutralColor900} />,
          command: () => {
            onEdit()
          },
        },
        {
          label: word("program.session.copy.label"),
          icon: <CopyIcon color={theme.neutralColor900} width={20} height={20} />,
          command: () => {
            onSessionCopy()
          },
        },
        {
          label: word("global.delete"),
          icon: <TrashIcon color={theme.neutralColor900} width={20} height={20} />,
          command: () => {
            onDeleteSessionConfirmationPopup()
          },
          disabled: program._programType === ProgramOnOffTypeOf,
        },
      ].filter((item) => !item.disabled),
    },
  ]

  const onSessionCopy = () => {
    try {
      programSessionService.setCopySession(session)

      toast.show({
        severity: "success",
        summary: word("program.session.copy.success"),
      })
    } catch (e: any) {
      toast.show({
        severity: "error",
        summary: word("program.session.copy.error"),
      })
    }
  }

  const onClickOnScoreboard = () => {
    if (isAuthor || isAcceptedSharedCoach || canUserAccessToScoreboard) {
      navigateToScoreboardView(session, program.id, scoreExercicesRequested)
    } else {
      toast.show({
        severity: "error",
        summary: word("session.scoreboard.card.errorMessage"),
      })
    }
  }
  return (
    <>
      <div className="flex align-items-center">
        <Link to={programPath}>
          <ProgramTitle rounded>
            <div className="flex align-items-center">
              {program.visibility === ProgramVisibility.PUBLIC ? (
                <GlobeIcon color={theme.primaryColor} />
              ) : (
                <LockIcon color={theme.secondaryColor200} />
              )}
              <HUText fontStyle="LM" color={theme.mainWhite} className="flex align-items-center ml-2">
                {program.title}
              </HUText>
            </div>
          </ProgramTitle>
        </Link>
        <div className="flex flex-1 justify-content-end gap-2">
          {canUserEditSession && <EllipsisMenu items={ellipsisItems} />}
        </div>
      </div>
      <HUStack className="pt-3" gap={21}>
        <div className="flex flex-column justify-content-between">
          <div>
            <HUText fontStyle="HM" color={theme.neutralColor900}>
              {"restDay" in session && session.restDay ? word("program.onOff.restDay") : session.name}
            </HUText>
            <HUStack gap={14}>
              <HUText fontStyle="BM" color={theme.neutralColor700}>
                {sessionDate}
              </HUText>
            </HUStack>
          </div>
          {canUserSeeSessionContent && (
            <div className="flex mt-2">
              {session.gymnasticTag && (
                <div className="flex flex-column align-items-center">
                  <GymIcon color={"black"} className="mr-2 mb-1" width={30} height={30} />
                </div>
              )}
              {session.weightliftingTag && (
                <div className="flex flex-column align-items-center">
                  <WeightliftingIcon color={"black"} className="mr-2 mb-1" width={30} height={30} />
                </div>
              )}
              {session.cardioTag && (
                <div className="flex flex-column align-items-center">
                  <CardioIcon color={"black"} className="mb-1" width={30} height={30} />
                </div>
              )}
            </div>
          )}
          {session.description && (
            <HUText fontStyle="BM" style={{ whiteSpace: "pre-line", marginTop: "10px" }}>
              {session.description}
            </HUText>
          )}
        </div>
        <ContainerAvatar>
          <ProfileAvatar profile={{ avatar: session.author.avatar, username: session.author.username }} size={38} />
          <HUText
            color={theme.neutralColor900}
            fontStyle="LM"
          >{`${session.author.firstname} ${session.author.lastname}`}</HUText>
        </ContainerAvatar>
        {scoreExercicesRequested.length > 0 &&
          program._programType !== ProgramOnOffTypeOf &&
          (!hideSessionContent || isAuthor || isAcceptedSharedCoach) && (
            <ScoreboardCard
              onClick={() => onClickOnScoreboard()}
              program={program}
              canUserAccessToScoreboard={canUserAccessToScoreboard}
            />
          )}
        {canUserSeeSessionContent && session.exercises.length > 0 ? (
          <HUStack gap={1} className="pt-2">
            <HUText fontStyle="LS" color={theme.neutralColor700}>{`(${session.exercises.length}) ${
              session.exercises.length > 1
                ? word("program.planning.create.exercise.blocks").toUpperCase()
                : word("program.planning.create.exercise.block").toUpperCase()
            } `}</HUText>
            {session.exercises.map((exercise) => {
              return (
                <div key={exercise.id}>
                  <ExerciseDetail
                    exercise={exercise}
                    parentSessionOrigin={{
                      parentType: session._type,
                      programId: program.id,
                      sessionId: session.id,
                    }}
                    canUserCompleteExercise={canUserCompleteExercises}
                    refreshSession={refreshSession}
                  />
                </div>
              )
            })}
          </HUStack>
        ) : canUserSeeSessionContent && session.exercises.length === 0 ? (
          program._programType === ProgramOnOffTypeOf && "restDay" in session && session.restDay ? (
            <RestDayEmptyView>
              <img src={RestDayIcon} alt="Rest day" width={150} className="mb-2" />

              <HUText fontStyle="TL">{word("program.onOff.restDay")}</HUText>
            </RestDayEmptyView>
          ) : isAuthor ? (
            <EmptyView
              image={{ src: SessionEmpty }}
              title={word("session.empty.title.noExerciseAuthor")}
              description={word("session.empty.description.noExerciseAuthor")}
              flexGrow={false}
              className="p-6"
              style={{ backgroundColor: theme.neutralColor100 }}
              button={
                canUserEditSession && (
                  <HUButton type="Default" size="M" colorType="Primary" text={word("global.add")} onClick={onEdit} />
                )
              }
              paddingType="In_table"
            />
          ) : (
            <EmptyView
              image={{ src: SessionEmpty }}
              title={word("session.empty.title.noExercise")}
              description={word("session.empty.description.noExercise")}
              flexGrow={false}
              className="p-6"
              style={{ backgroundColor: theme.neutralColor100 }}
              button={
                <HUButton
                  type="Default"
                  size="M"
                  colorType="Primary"
                  text={word("global.button.wait")}
                  onClick={() => hideSidebar()}
                />
              }
              paddingType="In_table"
            />
          )
        ) : (
          <EmptyView
            image={{ src: SessionHideEmpty }}
            title={word("session.empty.title.hideSession")}
            description={word("session.empty.description.hideSession")}
            flexGrow={false}
            className="p-6"
            style={{ backgroundColor: theme.neutralColor100 }}
            button={
              <HUButton
                type="Default"
                size="M"
                colorType="Primary"
                text={word("global.button.wait")}
                onClick={() => hideSidebar()}
              />
            }
            paddingType="In_table"
          />
        )}
      </HUStack>
    </>
  )
}

const ProgramTitle = styled(Tag)`
  background-color: ${({ theme }) => theme.secondaryColor800};
  border: 1px solid var(--surface-border);
  padding: 10px 14px;
  display: flex;
`
const ContainerAvatar = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--surface-border);
  padding: 2px 14px 2px 2px;
  gap: 7px;
  border-radius: 27px;
  align-self: flex-start;
`
const RestDayEmptyView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 22px;
  background-color: ${({ theme }) => theme.neutralColor100};
  border-radius: 22px;
  height: 516px;
`
