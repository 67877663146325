import { Config } from "@modules/config"
import { SanityClient, createClient } from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"

const SANITY_CLIENT_CONFIGS = {
  dev: {
    projectId: "9w5rklaw",
    dataset: "production",
    useCdn: false,
    apiVersion: "2024-01-10",
    perspective: "previewDrafts" as const,
    token: Config.SANITY_PREVIEW_TOKEN,
  },
  demo: {
    projectId: "9w5rklaw",
    dataset: "production",
    useCdn: false,
    apiVersion: "2024-01-10",
    perspective: "previewDrafts" as const,
    token: Config.SANITY_PREVIEW_TOKEN,
  },
  prod: {
    projectId: "9w5rklaw",
    dataset: "production",
    useCdn: true,
    apiVersion: "2024-01-10", // use current date (YYYY-MM-DD) to target the latest API version
    perspective: "published" as const,
  },
}

export class SanityService {
  static client: SanityClient = createClient(SANITY_CLIENT_CONFIGS[Config.ENV])
  static imageBuilder: ReturnType<typeof imageUrlBuilder> = imageUrlBuilder(this.client)
}
