import {
  ExerciseDraftDto,
  ExerciseDto,
  convertExerciseFromDto,
  convertExerciseToDto,
} from "@modules/exercises/services/exercisesDto"
import { ProfileDto, convertProfileFromDto } from "@modules/profile/profileDtos"
import {
  ItemProgramInfiniteSession,
  ItemProgramOnOffSession,
  ProgramInfiniteSession,
  ProgramInfiniteSessionDraft,
  ProgramInfiniteSessionTypeOf,
  ProgramOnOffSession,
  ProgramOnOffSessionDraft,
  ProgramOnOffSessionTypeOf,
} from "@modules/programSession/programSessionTypes"
import { ProgramVisibility } from "@modules/programs/programTypes"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"

type ItemSessionBaseDto = {
  id: string
  name: string
  owner: ProfileDto
  programId: string
  cardioTag?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  hideSessionContent: boolean
}

export type ItemProgramInfiniteSessionDto = {
  date: string
  programVisibility: ProgramVisibility
  exercises: ExerciseDto[]
  virtualProgramByDateIndex: number
} & ItemSessionBaseDto

export type ItemProgramOnOffSessionDto = {
  date?: string
  index: number
  restDay: boolean
  programVisibility: ProgramVisibility
  exercises: ExerciseDto[]
} & ItemSessionBaseDto

type SessionBaseDto = {
  exercises?: ExerciseDto[]
  description: string
  hideSessionContent: boolean
} & ItemSessionBaseDto

export type ProgramInfiniteSessionDto = {
  date: string
  programVisibility: ProgramVisibility
  virtualProgramByDateIndex?: number
} & SessionBaseDto

export type ProgramOnOffSessionDto = {
  date?: string
  restDay: boolean
  index: number
  programVisibility: ProgramVisibility
} & SessionBaseDto

type SessionBaseDraftDto = {
  name: string
  description: string
  exercises: ExerciseDraftDto[]
  hideSessionContent: boolean
  cardioTag?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
}

export type ProgramInfiniteSessionDraftDto = {
  programId: string
  date: string
  virtualProgramByDateIndex?: number
} & SessionBaseDraftDto

export type ProgramOnOffSessionDraftDto = {
  programId: string
  date?: string
  index: number
  restDay: boolean
} & SessionBaseDraftDto

export type PlanningSessionDto = {
  sessions: ItemProgramInfiniteSessionDto[]
}

export type PlanningOnOffSessionDto = ItemProgramOnOffSessionDto[]

function convertItemSessionBaseFromDto(dto: ItemSessionBaseDto) {
  return {
    id: dto.id,
    name: dto.name,
    author: convertProfileFromDto(dto.owner),
  }
}

export function convertItemProgramInfiniteSessionFromDto(
  dto: ItemProgramInfiniteSessionDto,
): ItemProgramInfiniteSession {
  return {
    _type: ProgramInfiniteSessionTypeOf,
    ...convertItemSessionBaseFromDto(dto),
    programId: dto.programId,
    date: dto.date,
    virtualProgramByDateIndex: dto.virtualProgramByDateIndex,
    programVisibility: dto.programVisibility,
    exercises: dto.exercises.map((exercise) => convertExerciseFromDto(exercise)) ?? [],
    cardioTag: dto.cardioTag,
    gymnasticTag: dto.gymnasticTag,
    weightliftingTag: dto.weightliftingTag,
    hideSessionContent: dto.hideSessionContent,
  }
}

export function convertItemProgramOnOffSessionFromDto(dto: ItemProgramOnOffSessionDto): ItemProgramOnOffSession {
  return {
    _type: ProgramOnOffSessionTypeOf,
    ...convertItemSessionBaseFromDto(dto),
    programId: dto.programId,
    date: dto.date,
    restDay: dto.restDay,
    index: dto.index,
    programVisibility: dto.programVisibility,
    exercises: dto.exercises.map((exercise) => convertExerciseFromDto(exercise)) ?? [],
    cardioTag: dto.cardioTag,
    gymnasticTag: dto.gymnasticTag,
    weightliftingTag: dto.weightliftingTag,
    hideSessionContent: dto.hideSessionContent,
  }
}

function convertSessionBaseFromDto(dto: SessionBaseDto) {
  return {
    id: dto.id,
    name: dto.name,
    author: convertProfileFromDto(dto.owner),
    description: dto.description,
    exercises: dto.exercises?.map((exercise) => convertExerciseFromDto(exercise)) ?? [],
    cardioTag: dto.cardioTag,
    gymnasticTag: dto.gymnasticTag,
    weightliftingTag: dto.weightliftingTag,
  }
}

export function convertProgramInfiniteSessionFromDto(dto: ProgramInfiniteSessionDto): ProgramInfiniteSession {
  return {
    ...convertSessionBaseFromDto(dto),
    programId: dto.programId,
    date: dto.date,
    programVisibility: dto.programVisibility,
    _type: ProgramInfiniteSessionTypeOf,
    hideSessionContent: dto.hideSessionContent,
    virtualProgramByDateIndex: dto.virtualProgramByDateIndex,
    cardioTag: dto.cardioTag,
    gymnasticTag: dto.gymnasticTag,
    weightliftingTag: dto.weightliftingTag,
  }
}

export function convertProgramOnOffSessionFromDto(dto: ProgramOnOffSessionDto): ProgramOnOffSession {
  return {
    ...convertSessionBaseFromDto(dto),
    programId: dto.programId,
    date: dto.date,
    restDay: dto.restDay,
    index: dto.index,
    programVisibility: dto.programVisibility,
    _type: ProgramOnOffSessionTypeOf,
    hideSessionContent: dto.hideSessionContent,
    cardioTag: dto.cardioTag,
    gymnasticTag: dto.gymnasticTag,
    weightliftingTag: dto.weightliftingTag,
  }
}

export function convertProgramInfiniteSessionDraftToDto(
  item: ProgramInfiniteSessionDraft,
): ProgramInfiniteSessionDraftDto {
  return {
    name: item.name,
    programId: item.programId,
    date: dayjs(item.date).format(defaultDateFormat),
    description: item.description,
    exercises: item.exercises.map((exercise) => convertExerciseToDto(exercise)),
    hideSessionContent: item.hideSessionContent,
    cardioTag: item.cardioTag,
    gymnasticTag: item.gymnasticTag,
    weightliftingTag: item.weightliftingTag,
    virtualProgramByDateIndex: item.virtualProgramByDateIndex,
  }
}
export function convertProgramOnOffSessionDraftToDto(item: ProgramOnOffSessionDraft): ProgramOnOffSessionDraftDto {
  return {
    name: item.name,
    programId: item.programId,
    restDay: item.restDay ?? false,
    index: item.dayIndex ?? 0,
    description: item.description,
    exercises: item.exercises.map((exercise) => convertExerciseToDto(exercise)),
    hideSessionContent: item.hideSessionContent,
    cardioTag: item.cardioTag,
    gymnasticTag: item.gymnasticTag,
    weightliftingTag: item.weightliftingTag,
  }
}
