import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BoxIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.20538 3.03083C0.666236 4.03061 0.666164 5.35398 0.66602 8.0007C0.665876 10.6467 0.665804 11.9696 1.20531 12.9698C1.62217 13.7426 2.2565 14.377 3.02929 14.7939C4.02944 15.3335 5.35279 15.3335 7.99949 15.3335H7.9995C10.646 15.3335 11.9692 15.3335 12.9691 14.7944C13.7426 14.3773 14.3771 13.7428 14.7942 12.9693C15.3333 11.9694 15.3333 10.6463 15.3333 8.00017C15.3333 5.35394 15.3333 4.03082 14.7942 3.03098C14.3771 2.25745 13.7426 1.62298 12.9691 1.20588C12.4342 0.917441 11.8067 0.783319 10.9336 0.720953V6.22969C10.9336 6.88302 10.1437 7.21021 9.68173 6.74823L8.51882 5.58532C8.23244 5.29893 7.76811 5.29893 7.48173 5.58532L6.31882 6.74823C5.85684 7.21021 5.06693 6.88302 5.06693 6.22969V0.720882C4.1933 0.783214 3.56557 0.917319 3.03047 1.20584C2.25694 1.62293 1.62251 2.25732 1.20538 3.03083ZM7.75771 0.666764H8.24208C8.16261 0.666748 8.08189 0.666748 7.99991 0.666748C7.91791 0.666748 7.83719 0.666748 7.75771 0.666764Z"
      fill={props.color}
    />
    <path
      opacity="0.6"
      d="M5.06702 0.666748H10.9337V6.22967C10.9337 6.883 10.1438 7.21019 9.68182 6.74822L8.5189 5.5853C8.23252 5.29892 7.7682 5.29892 7.48181 5.5853L6.3189 6.74822C5.85692 7.21019 5.06702 6.883 5.06702 6.22967V0.666748Z"
      fill={props.color}
    />
  </svg>
)
