import styled from "styled-components"

type YouTubeVideoProps = {
  link: string
  width?: number
  height?: number
}

export const YouTubeVideo: React.FC<YouTubeVideoProps> = ({ link, width, height }) => {
  return (
    <div>
      <IFrameContainer
        $width={width}
        $height={height}
        src={`${link}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; autoplay; modestbranding;"
        allowFullScreen={true}
      ></IFrameContainer>
    </div>
  )
}

const IFrameContainer = styled.iframe<{ $width?: number; $height?: number }>`
  width: ${({ $width }) => ($width ? $width + "px" : "560px")};
  height: ${({ $height }) => ($height ? $height + "px" : "315px")};

  @media screen and (max-width: 660px) {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
`
