import {
  AddressFormikType,
  AddressInputKey,
  convertAddressFromFormik,
  convertAddressToFormik,
} from "@modules/address/addressFormikType"
import { BoxDraft } from "@modules/box/boxTypes"
import { word } from "@modules/core/utils/i18n"
import { phoneRegExp } from "@modules/form/formUtils"
import { HUImage } from "@modules/types/imageTypes"
import * as Yup from "yup"

export type BoxInputKey =
  | "name"
  | "email"
  | "phoneNumberCountryCode"
  | "phoneNumber"
  | "siren"
  | "description"
  | "logo"
  | "cover"
  | "boxPhotos"
  | AddressInputKey

export type BoxFormikType = {
  name: string
  description: string
  phoneNumberCountryCode: string
  phoneNumber: string
  email: string
  siren: string
  logo?: HUImage
  cover?: HUImage
  boxPhotos: HUImage[]
} & AddressFormikType

export function convertBoxToFormik(boxDraft: BoxDraft): BoxFormikType {
  return {
    name: boxDraft.name,
    description: boxDraft.description,
    ...convertAddressToFormik(boxDraft.address),
    phoneNumber: boxDraft.phoneNumberWithPrefix.number,
    phoneNumberCountryCode: boxDraft.phoneNumberWithPrefix.countryCode,
    email: boxDraft.email,
    siren: boxDraft.siren,
    logo: boxDraft.logo,
    cover: boxDraft.cover,
    boxPhotos: boxDraft.boxPhotos,
  }
}

export function convertBoxFormFormik(formik: BoxFormikType): BoxDraft {
  return {
    name: formik.name,
    description: formik.description,
    address: convertAddressFromFormik(formik),
    phoneNumberWithPrefix: {
      number: formik.phoneNumber,
      countryCode: formik.phoneNumberCountryCode,
    },
    email: formik.email,
    siren: formik.siren,
    logo: formik.logo,
    cover: formik.cover,
    boxPhotos: formik.boxPhotos,
  }
}

const firstPageValidation = () =>
  Yup.object({
    name: Yup.string()
      .max(64, word("form.maximum.characters", { count: 64 }))
      .required(word("global.form.required")),
  })

const secondPageValidation = () =>
  Yup.object({
    address: Yup.string()
      .max(255, word("form.maximum.characters", { count: 255 }))
      .required(word("global.form.required")),
    additionalAddress: Yup.string().max(255, word("form.maximum.characters", { count: 255 })),
    postalCode: Yup.string()
      .required(word("global.form.required"))
      .max(10, word("form.maximum.characters", { count: 10 })),
    city: Yup.string()
      .max(64, word("form.maximum.characters", { count: 64 }))
      .required(word("global.form.required")),
    country: Yup.string()
      .max(255, word("form.maximum.characters", { count: 255 }))
      .required(word("global.form.required")),
  })

const thirdPageValidation = () =>
  Yup.object({
    email: Yup.string()
      .required(word("global.form.required"))
      .max(64, word("form.maximum.characters", { count: 64 }))
      .email(word("box.form.email.error")),
    phoneNumberCountryCode: Yup.string()
      .required(word("global.form.required"))
      .max(2, word("form.maximum.characters", { count: 2 })),
    phoneNumber: Yup.string()
      .required(word("global.form.required"))
      .max(64, word("form.maximum.characters", { count: 64 }))
      .matches(phoneRegExp, word("box.form.phone.error")),
  })

const fourthPageValidation = () =>
  Yup.object({
    logo: Yup.mixed().required(word("box.form.logo.error")),
    cover: Yup.mixed().required(word("box.form.cover.error")),
  })

const fifthPageValidation = () => Yup.object({ description: Yup.string().required(word("global.form.required")) })

const sixthPageValidation = () =>
  Yup.object({
    siren: Yup.string()
      .max(16, word("form.maximum.characters", { count: 16 }))
      .required(word("global.form.required")),
  })

export function getValidationSchema(pageNumber?: number) {
  switch (pageNumber) {
    case 1:
      return firstPageValidation()
    case 2:
      return secondPageValidation()
    case 3:
      return thirdPageValidation()
    case 4:
      return fourthPageValidation()
    case 5:
      return fifthPageValidation()
    case 6:
      return sixthPageValidation()
    default:
      return firstPageValidation()
        .concat(secondPageValidation())
        .concat(thirdPageValidation())
        .concat(fourthPageValidation())
        .concat(fifthPageValidation())
        .concat(sixthPageValidation())
  }
}
