import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxSlotTemplateFormView } from "@modules/boxSlotTemplate/components/boxSlotTemplateFormView"
import { BoxSlotTemplateGroupForm } from "@modules/boxSlotTemplate/components/boxSlotTemplateGroupForm"
import { word } from "@modules/core/utils/i18n"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { Dayjs } from "dayjs"
import { useParams } from "react-router-dom"

export const useBoxSlotTemplateSidebar = () => {
  const { sidebarNavigateTo } = useSidebar()
  const { boxProgrammationId } = useParams()
  const confirmPopup = useConfirmPopup()

  const navigateToBoxSlotTemplateCreation = (boxProgrammation: BoxProgrammation, date: Dayjs) => {
    sidebarNavigateTo({
      content: <BoxSlotTemplateFormView boxProgrammation={boxProgrammation} dateToCreateSlotTemplateOn={date} />,
    })
  }

  const navigateToBoxSlotTemplateEdition = (boxProgrammation: BoxProgrammation, boxSlotTemplateId: string) => {
    sidebarNavigateTo({
      content: <BoxSlotTemplateFormView boxSlotTemplateId={boxSlotTemplateId} boxProgrammation={boxProgrammation} />,
    })
  }

  const navigateToBoxSlotTemplateGroupCreation = () => {
    if (boxProgrammationId) {
      sidebarNavigateTo({
        content: <BoxSlotTemplateGroupForm boxProgrammationId={boxProgrammationId} />,
        allowSidebarReduction: true,
        replaceAllComponents: true,
        onHide: async () =>
          await new Promise<boolean>((resolve) => {
            confirmPopup.show({
              title: word("box.slotTemplateGroup.form.cancel.title"),
              message: word("box.slotTemplateGroup.form.cancel.message"),
              accept: async () => resolve(true),
              cancel: async () => resolve(false),
              footerProps: {
                align: true,
                confirmClassName: "flex-1",
                cancelClassName: "flex-1",
                footerClassName: "flex flex-row justify-content-around w-full",
              },
            })
          }),
      })
    }
  }

  return {
    navigateToBoxSlotTemplateCreation,
    navigateToBoxSlotTemplateEdition,
    navigateToBoxSlotTemplateGroupCreation,
  }
}
