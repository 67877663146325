import { CSSProperties, RuleSet, css } from "styled-components"

export const allHUFontWeight = ["regular", "medium", "semibold", "bold"] as const
export type HUFontWeight = (typeof allHUFontWeight)[number]

export const allHUFontStyle = [
  "DXL",
  "DL",
  "DM",
  "DS",
  "HL",
  "HM",
  "HS",
  "TL",
  "TM",
  "TS",
  "BL",
  "BM",
  "BS",
  "BXS",
  "LL",
  "LM",
  "LS",
  "LXS",
] as const
export type HUFontStyle = (typeof allHUFontStyle)[number]

export const AllFontFamily = ["Sora", "Trenda"] as const
export type HUFontFamily = (typeof AllFontFamily)[number]

export const FontStyleToFamily: Record<HUFontStyle, HUFontFamily> = {
  DXL: "Trenda",
  DL: "Trenda",
  DM: "Sora",
  DS: "Sora",
  HL: "Sora",
  HM: "Trenda",
  HS: "Trenda",
  TL: "Trenda",
  TM: "Trenda",
  TS: "Trenda",
  BL: "Trenda",
  BM: "Trenda",
  BS: "Trenda",
  BXS: "Trenda",
  LL: "Trenda",
  LM: "Trenda",
  LS: "Trenda",
  LXS: "Trenda",
}

export type HUFontWeightNumber = 400 | 500 | 600 | 700
export type HUFontWeightNumberString = "400" | "500" | "600" | "700"

interface HUTextStyle extends CSSProperties {
  fontWeight: HUFontWeightNumberString
  fontSize: string
  lineHeight: string
  fontFamily: string
}

// display
const DXL: HUTextStyle = {
  fontWeight: "400",
  fontSize: "9.375rem", // 150px
  lineHeight: "189px",
  fontFamily: FontStyleToFamily.DXL,
}
const DL: HUTextStyle = {
  fontWeight: "400",
  fontSize: "7.188rem", // 115px
  lineHeight: "144.9px",
  fontFamily: FontStyleToFamily.DL,
}
const DM: HUTextStyle = {
  fontWeight: "400",
  fontSize: "4.625rem", // 74px,
  lineHeight: "93.24px",
  fontFamily: FontStyleToFamily.DM,
}
const DS: HUTextStyle = {
  fontWeight: "600",
  fontSize: "3rem", // 48px
  lineHeight: "60.48px",
  fontFamily: FontStyleToFamily.DS,
}
// headline
const HL: HUTextStyle = {
  fontWeight: "500",
  fontSize: "2rem", // 32px
  lineHeight: "40.32px",
  fontFamily: FontStyleToFamily.HL,
}
const HM: HUTextStyle = {
  fontWeight: "600",
  fontSize: "1.75rem", // 28px
  lineHeight: "35.28px",
  fontFamily: FontStyleToFamily.HM,
}
const HS: HUTextStyle = {
  fontWeight: "600",
  fontSize: "1.563rem", // 25px
  lineHeight: "31.5px",
  fontFamily: FontStyleToFamily.HS,
}
// title
const TL: HUTextStyle = {
  fontWeight: "600",
  fontSize: "1.375rem", // 22px
  lineHeight: "27.72px",
  fontFamily: FontStyleToFamily.TL,
}
const TM: HUTextStyle = {
  fontWeight: "700",
  fontSize: "1.125rem", // 18px
  lineHeight: "22.68px",
  fontFamily: FontStyleToFamily.TM,
}
const TS: HUTextStyle = {
  fontWeight: "700",
  fontSize: "1rem", // 16px
  lineHeight: "20.16px",
  fontFamily: FontStyleToFamily.TS,
}
// body
const BL: HUTextStyle = {
  fontWeight: "600",
  fontSize: "1.125rem", // 18px
  lineHeight: "30px",
  fontFamily: FontStyleToFamily.BL,
}
const BM: HUTextStyle = {
  fontWeight: "400",
  fontSize: "1rem", // 16px
  lineHeight: "22px",
  fontFamily: FontStyleToFamily.BM,
}

const BS: HUTextStyle = {
  fontWeight: "400",
  fontSize: "0.813rem", // 13px
  lineHeight: "15.34px",
  fontFamily: FontStyleToFamily.BS,
}
const BXS: HUTextStyle = {
  fontWeight: "400",
  fontSize: "0.625rem", // 10px
  lineHeight: "11.8px",
  letterSpacing: "1px",
  fontFamily: FontStyleToFamily.BXS,
}
// label
const LL: HUTextStyle = {
  fontWeight: "600",
  fontSize: "1rem", // 16px
  lineHeight: "19.2px",
  fontFamily: FontStyleToFamily.LL,
}
const LM: HUTextStyle = {
  fontWeight: "600",
  fontSize: "0.813rem", // 13px
  lineHeight: "15.6px",
  fontFamily: FontStyleToFamily.LM,
}
const LS: HUTextStyle = {
  fontWeight: "600",
  fontSize: "0.688rem", // 11px
  lineHeight: "13.2px",
  fontFamily: FontStyleToFamily.LS,
}
const LXS: HUTextStyle = {
  fontWeight: "600",
  fontSize: "0.5625rem", // 9px
  lineHeight: "15.8px",
  fontFamily: FontStyleToFamily.LXS,
}

export const primaryFonts: Record<HUFontStyle, HUTextStyle> = {
  DXL,
  DL,
  DM,
  DS,
  HL,
  HM,
  HS,
  TL,
  TM,
  TS,
  BL,
  BM,
  BS,
  BXS,
  LL,
  LM,
  LS,
  LXS,
} as const

export const getFontWeight = (weight: HUFontWeight): HUFontWeightNumberString => {
  switch (weight) {
    case "regular":
      return "400"
    case "medium":
      return "500"
    case "semibold":
      return "600"
    case "bold":
      return "700"
  }
}

export const convertToStyledComponent = (textStyle: HUTextStyle): RuleSet => {
  return css`
    font-weight: ${textStyle.fontWeight};
    font-size: ${textStyle.fontSize};
    line-height: ${textStyle.lineHeight};
    font-family: ${textStyle.fontFamily};
    letter-spacing: ${textStyle.letterSpacing === undefined ? "undefined" : textStyle.letterSpacing};
  `
}

export const primaryFontsAsCss = Object.fromEntries(
  Object.entries(primaryFonts).map(([key, font]) => [key, convertToStyledComponent(font)]),
) as Record<HUFontStyle, RuleSet>
