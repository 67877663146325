import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const GymIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="1" width="60" height="60" rx="18" fill="#5AF2A6" />
    <rect x="1" y="1" width="60" height="60" rx="18" stroke="url(#paint0_linear_470_97557)" strokeWidth="1.11776" />
    <circle cx="22.4033" cy="41.2021" r="6.5" stroke="white" strokeWidth="2" />
    <rect x="21.4033" y="13.2979" width="2" height="24.4102" rx="1" fill="white" fillOpacity="0.68" />
    <circle cx="39.5972" cy="41.2021" r="6.5" stroke="white" strokeWidth="2" />
    <rect x="38.5972" y="13.2979" width="2" height="24.4102" rx="1" fill="white" fillOpacity="0.68" />
    <defs>
      <linearGradient
        id="paint0_linear_470_97557"
        x1="3.15569"
        y1="2"
        x2="43.3973"
        y2="67.9826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.04" />
        <stop offset="0.188137" stopColor="white" stopOpacity="0" />
        <stop offset="0.708289" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.06" />
      </linearGradient>
    </defs>
  </svg>
)
