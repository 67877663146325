import React from "react"

export const HustleupSmallIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="200" height="172" viewBox="0 0 200 172" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.7528 7.39681L58.9456 64.6886H162.269L199.431 -6.74031C201.151 -10.047 198.752 -14 195.025 -14H124.004C109.183 -14 95.5935 -5.75148 88.7528 7.39681ZM40.864 107.312L3.70052 178.743C1.98064 182.048 4.3793 186 8.10568 186H79.1283C93.9496 186 107.539 177.752 114.38 164.603L144.187 107.312H40.864Z"
      fill={props.color || "url(#paint0_linear_6487_5501)"}
    />
    <defs>
      <linearGradient
        id="paint0_linear_6487_5501"
        x1="0.20066"
        y1="137.862"
        x2="231.452"
        y2="61.0005"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3125" stopColor="white" stopOpacity="0.15" />
        <stop offset="0.557292" stopColor="white" stopOpacity="0.33" />
        <stop offset="0.807292" stopColor="white" stopOpacity="0.25" />
      </linearGradient>
    </defs>
  </svg>
)
