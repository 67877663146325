import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ReturnIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.08026 1.44922L1.16235 4.36713M1.16235 4.36713L4.08026 7.28504M1.16235 4.36713C4.44019 4.36713 6.68243 4.36713 9.37853 4.36713C11.2869 4.36713 12.834 5.91417 12.834 7.82255V7.82255C12.834 9.73092 11.2869 11.278 9.37856 11.278H7.17368"
      stroke={props.color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
