import CompletedVector1 from "@images/aladin-challenge/completed-vector1.svg"
import CompletedVector2 from "@images/aladin-challenge/completed-vector2.svg"
import { ExerciseDashboard } from "@modules/exercises/exerciseTypes"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import React, { useEffect, useMemo, useState } from "react"
import styled, { useTheme } from "styled-components"

export const ChallengeTotals: React.FC<{
  challengeResults: ExerciseDashboard
  maxCal: number
  maxAmount: number
}> = ({ challengeResults, maxCal, maxAmount }) => {
  const [currentTotalScore, setCurrentTotalScore] = useState<number>(challengeResults.totalScore)
  const [isScoreHidden, setIsScoreHidden] = useState<boolean>(false)
  const theme = useTheme()
  const language = useLanguage()

  const ratio = useMemo(() => {
    return maxCal / maxAmount
  }, [maxCal, maxAmount])

  const isChallengeCompleted = useMemo(() => {
    return currentTotalScore >= maxCal
  }, [currentTotalScore, maxCal])

  const paidAmountText = useMemo(() => {
    return `${Math.floor(currentTotalScore / ratio).toLocaleString(language)} €`
  }, [ratio, currentTotalScore, language])

  useEffect(() => {
    if (currentTotalScore !== challengeResults.totalScore) {
      setIsScoreHidden(true)
      setTimeout(() => {
        setCurrentTotalScore(challengeResults.totalScore)
      }, 400)
      setTimeout(() => {
        setIsScoreHidden(false)
      }, 1000)
    }
  }, [challengeResults.totalScore])

  return (
    <HUStack className="align-items-center mb-6">
      <TotalsContainer gap={0}>
        <div className="amount align-self-end relative mb-0 col-6 flex flex-row justify-content-end">
          {isChallengeCompleted ? (
            <HUStack gap={0}>
              <HUText fontStyle="DS" fontWeight="bold" color={theme.mainBlack} className="completed-line-1 pl-6">
                Un rêve
              </HUText>
              <HUText
                fontStyle="DS"
                fontWeight="bold"
                color={theme.mainBlack}
                className="completed-line-2 mb-4 pl-5 ml-3"
              >
                gagné
              </HUText>
            </HUStack>
          ) : (
            <HUText fontStyle="DM" fontWeight="bold" color={theme.mainWhite}>
              {paidAmountText}
            </HUText>
          )}
          {!isChallengeCompleted && (
            <Separator1Container $positionX={isScoreHidden ? 0 : -293} $positionY={15}>
              <Separator1 />
              <Separator1BlackBlock />
            </Separator1Container>
          )}
        </div>

        <div className="calories align-self-end relative mt-6 col-6">
          <Separator2Container $positionX={isScoreHidden ? 0 : -310} $positionY={60}>
            <Separator2BlackBlock />
            <Separator2 />
          </Separator2Container>
          <HUText fontStyle="DS" color={theme.mainWhite} fontWeight="bold">
            {`${currentTotalScore} cal`}
          </HUText>
        </div>
      </TotalsContainer>
    </HUStack>
  )
}

const Separator1Container = styled(HURow)<{ $positionX: number; $positionY: number }>`
  position: absolute;
  right: ${({ $positionX }) => $positionX}px;
  top: ${({ $positionY }) => $positionY}px;
  transition: right 0.5s ease;
`

const Separator1BlackBlock = styled.div`
  background-color: ${({ theme }) => theme.mainBlack};
  width: 300px;
  height: 4rem;
  border-top-left-radius: 85px;
  position: relative;
  margin-top: 10px;
`
const Separator1 = styled.div`
  width: 180px;
  background-color: #ddf247;
  border-radius: 14px;
  height: 6px;
  transform: rotate(-45deg);
  margin-right: -115px;
  margin-top: 30px;
  z-index: 5;
`
const Separator2Container = styled(HURow)<{ $positionX: number; $positionY: number }>`
  position: absolute;
  left: ${({ $positionX }) => $positionX}px;
  top: ${({ $positionY }) => $positionY}px;
  transition: left 0.5s ease;
`

const Separator2BlackBlock = styled.div`
  background-color: ${({ theme }) => theme.mainBlack};
  width: 300px;
  height: 4rem;
  border-bottom-right-radius: 85px;
`

const Separator2 = styled.div`
  width: 180px;
  background-color: #ddf247;
  border-radius: 14px;
  height: 6px;
  margin-left: -90px;
  transform: rotate(-45deg);
  z-index: 5;
`

const TotalsContainer = styled(HURow)`
  width: 100%;

  .amount {
    line-height: 4.625rem;
    padding-bottom: 50px;
    flex-grow: 1;
  }

  .calories {
    line-height: 4.625rem;
    flex-grow: 1;
    padding-top: 50px;
  }

  .completed-line-1 {
    background-image: url(${CompletedVector1});
    width: 370px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .completed-line-2 {
    background-image: url(${CompletedVector2});
    width: 250px;
    background-repeat: no-repeat;
    background-position: center;
  }
`
