import LogoCrossiftGames from "@images/crossfit/logo_crossfit_games.png"
import { word } from "@modules/core/utils/i18n"
import { getFormatedDateTime } from "@modules/language/languageUtils"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import dayjs from "dayjs"
import { Carousel } from "primereact/carousel"
import styled, { useTheme } from "styled-components"

export const TrainingWhatsNext: React.FC<{}> = () => {
  const theme = useTheme()
  type CardInfo = {
    title: string
    date: string
    link: string
    formattedDate?: string
  }
  const infosCards = [
    { title: "The open", date: ["2024-02-29", "2024-03-18"], link: "https://games.crossfit.com/open/overview" },
    {
      title: "Quarterfinals",
      date: ["2024-04-03", "2024-04-22"],
      link: "https://games.crossfit.com/quarterfinals/overview",
    },
    { title: "Semifinals", date: ["2024-05-08", "2024-06-02"], link: "https://games.crossfit.com/semifinals/overview" },
    {
      title: "The Crossfit games",
      date: ["2024-08-08", "2024-08-11"],
      link: "https://games.crossfit.com/finals/overview",
    },
  ].filter((infoCard, index, array) => index === array.length - 1 || dayjs(infoCard.date[1]).isAfter())

  const formattedInfosCards = infosCards.map((card) => {
    const formattedStartDate = getFormatedDateTime(card.date[0], { format: "LL" })
    const formattedEndDate = getFormatedDateTime(card.date[1], { format: "LL" })

    return {
      ...card,
      formattedDate: `${formattedStartDate} - ${formattedEndDate}`,
    }
  })

  const infoCardTemplate = (card: CardInfo) => {
    return (
      <div className="flex flex-column justify-content-center h-full">
        <HUText fontStyle="HM" color={theme.mainWhite} style={{ lineHeight: "24px" }}>
          {card.title}
        </HUText>
        <HUText fontStyle="BS" color={theme.secondaryColor200} className="my-2">
          {card.formattedDate}
        </HUText>
        <SeeMoreButton
          to={card.link}
          link={true}
          linkTarget="_blank"
          color={theme.secondaryColor200}
          label={word("global.learnMore")}
          fontStyle="BM"
          icon={{
            iconView: <span className={`p-button-icon p-c pi pi-external-link`} />,
          }}
          iconPosition="right"
        />
      </div>
    )
  }

  return (
    <Container className=" mb-5 col-3 justify-content-center relative">
      <Carousel value={formattedInfosCards} itemTemplate={infoCardTemplate} showIndicators={false} page={0} />
      <CrossfitLogo />
    </Container>
  )
}

const Container = styled(HUStack)`
  border-radius: 33px;
  height: 200px;
  background: linear-gradient(to right, #052026, #133c40);
  color: ${({ theme }) => theme.mainWhite};
  border-width: 0px;
  .p-carousel {
    height: 200px;
    justify-content: center;
  }
  .p-carousel-content {
    overflow: hidden;
    flex-grow: 1;
  }
  .p-carousel-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .p-carousel-items-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100% !important;
  }
  .p-carousel-items-container {
    align-items: center;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
  }
  .p-carousel-item {
    height: 100%;
  }
`
const SeeMoreButton = styled(HUNavigateButton)`
  position: absolute;
  bottom: 15px;
  width: 100%;
  .p-button-link {
    padding: 0px !important;
    border-radius: 0px;
  }
  .p-button-label span {
    text-decoration: underline;
  }
  .pi {
    font-size: 0.8rem !important;
  }
`

const CrossfitLogo = styled.div`
  position: absolute;
  top: 9%;
  left: 55%;
  background-image: url(${LogoCrossiftGames});
  background-size: contain;
  background-position: center;
  height: 18%;
  width: 50%;
  background-repeat: no-repeat;
`
