import { useServicesContext } from "@modules/core/services/services.context"
import { useObservable } from "micro-observables"

export type HUTheme = "athlete" | "owner"

export const useColorTheme = (): HUTheme => {
  const { profileService } = useServicesContext()
  return useObservable(profileService.profileTheme.select((profileTheme) => profileTheme.theme))
}

export const useThemeChanger = (): {
  previousTheme?: HUTheme | "loggingout"
  nextTheme?: HUTheme | "loggingout"
} | null => {
  const { profileService } = useServicesContext()
  return useObservable(profileService.changingTheme)
}
