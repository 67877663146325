import SearchEmpty from "@images/empty/search_empty.svg"
import { CrossIcon } from "@images/svgIcons/crossIcon"
import { PlusIcon } from "@images/svgIcons/plusIcon"
import { SearchIcon } from "@images/svgIcons/searchIcon"
import { useUser } from "@modules/auth/hooks/useUser"
import { word } from "@modules/core/utils/i18n"
import { ExploreFilter } from "@modules/explore/exploreTypes"
import { useExploreSearchContents } from "@modules/explore/hooks/useExplore"
import { Profile } from "@modules/profile/profileTypes"
import { ProgramFormikType, ProgramInfiniteInputKey } from "@modules/programs/programFormikType"
import { ProgramOnOffFormikType, ProgramOnOffInputKey } from "@modules/programs/programOnOffFormikType"
import { SharedCoach, SharedCoachStatus } from "@modules/programs/programTypes"
import { SharedCoachView } from "@modules/sharedCoach/components/sharedCoachView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { FieldValueChange } from "@src/typings/formik"
import { FieldInputProps } from "formik"
import { InputText } from "primereact/inputtext"
import { useCallback, useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useTheme } from "styled-components"

type SharedCoachPopUpProps = {
  setFieldValue: FieldValueChange<ProgramFormikType | ProgramOnOffFormikType>
  getFieldProps: (key: ProgramInfiniteInputKey | ProgramOnOffInputKey) => FieldInputProps<SharedCoach[] | undefined>
}

export const ProgramSharedCoachForm = ({ setFieldValue, getFieldProps }: SharedCoachPopUpProps) => {
  const [searchTerms, setSearchTerms] = useState<string>("")
  const {
    result,
    lastPage,
    listMore,
    setSearchTerms: setSearchContentTerms,
  } = useExploreSearchContents(ExploreFilter.PROFILE)
  const user = useUser()
  const confirmPopup = useConfirmPopup()
  const theme = useTheme()
  const [dialogIsVisible, setDialogIsVisible] = useState(false)

  const debouncedSetSearchContentTerms = useCallback(
    (terms: string) => {
      const debounceTimer = setTimeout(() => {
        setSearchContentTerms(terms)
      }, 500)
      return () => clearTimeout(debounceTimer)
    },
    [setSearchContentTerms],
  )

  useEffect(() => {
    if (searchTerms.length > 0) {
      debouncedSetSearchContentTerms(searchTerms)
    }
  }, [searchTerms, debouncedSetSearchContentTerms])

  const onHide = useCallback(() => {
    setSearchTerms("")
    setSearchContentTerms("")
    setDialogIsVisible(false)
  }, [setDialogIsVisible, setSearchContentTerms])

  const addSharedCoach = useCallback(
    (profile: Profile) => {
      setFieldValue(
        "sharedCoaches",
        [
          ...(getFieldProps("sharedCoaches").value ?? []),
          {
            user: profile,
            role: "STANDARD",
            status: SharedCoachStatus.PENDING,
          },
        ],
        false,
      ).then(onHide)
    },
    [getFieldProps, onHide, setFieldValue],
  )

  const filteredResult = useMemo(() => {
    if (!searchTerms) {
      return []
    }
    return result
      .filter(
        (content) =>
          content.profile &&
          !getFieldProps("sharedCoaches")
            .value?.map((coach) => coach.user.id)
            .includes(content.profile.id) &&
          user?.id !== content.profile.id,
      )
      .map((content) => content.profile as Profile)
  }, [getFieldProps, result, searchTerms, user?.id])

  const removeSharedCoach = useCallback(
    async (profile: Profile) => {
      await setFieldValue(
        "sharedCoaches",
        [...(getFieldProps("sharedCoaches").value?.filter((coach: SharedCoach) => coach.user.id !== profile.id) ?? [])],
        false,
      )
    },
    [getFieldProps, setFieldValue],
  )

  const removeSharedCoachDialog = useCallback(
    (profile: Profile) => {
      confirmPopup.show({
        title: word("program.sharedCoach.form.remove"),
        message: (
          <HUStack gap={20}>
            <HUText fontStyle="BM" color={theme.neutralColor700} className="m-auto">
              {word("program.sharedCoach.form.removeDetails")}
            </HUText>
            <SharedCoachView profile={profile} />
          </HUStack>
        ),
        accept: () => removeSharedCoach(profile),
        footerProps: {
          confirmText: word("global.delete"),
          confirmClassName: "flex-1",
          cancelClassName: "flex-1",
          footerClassName: "flex flex-row justify-content-around w-full",
        },
        width: "418px",
      })
    },
    [confirmPopup, removeSharedCoach, theme.neutralColor700],
  )

  const clearSearch = () => {
    setSearchTerms("")
  }

  return (
    <HUStack>
      <HUText type="label" color={theme.neutralColor700} fontStyle="LS" style={{ marginBottom: 7 }}>
        {word("program.sharedCoach.title").toUpperCase()}
      </HUText>
      <div style={{ gap: 12, display: "flex", flexDirection: "column" }}>
        <HUButton
          type="Right_Icon"
          colorType="Quaternary"
          text={word("program.sharedCoach.form.add")}
          onClick={() => setDialogIsVisible(!dialogIsVisible)}
          className="w-full"
          icon={{ iconView: <PlusIcon color={theme.mainBlack} />, iconSize: 14 }}
          size="S"
        />
        {getFieldProps("sharedCoaches").value?.map((coach: SharedCoach) => (
          <SharedCoachView
            key={coach.user.id}
            profile={coach.user}
            status={coach.status}
            isAuthor
            isEditing
            onRemove={() => removeSharedCoachDialog(coach.user)}
          />
        ))}
        <HUText fontStyle="BS" color={theme.neutralColor600}>
          {word("program.sharedCoach.form.description")}
        </HUText>
      </div>
      <HUDialog
        visible={dialogIsVisible}
        header={word("program.sharedCoach.form.add")}
        onHide={onHide}
        style={{ height: 650, width: 650 }}
        headerStyle={{ textAlign: "center", paddingBottom: "34px" }}
        contentStyle={{ padding: "0px 1.5rem" }}
      >
        <div className="flex w-full mb-5">
          <div className=" flex align-items-center w-full h-3rem rounded-full border-1 border-neutral-300">
            <div className="flex align-items-center justify-content-center w-3rem h-full ">
              <SearchIcon color={theme.neutralColor700} width={14} height={14} />
            </div>
            <InputText
              className="w-full h-full p-0 m-0 border-none outline-none"
              placeholder={word("boxSessionAddSubscriberModal.dialog.inputText")}
              value={searchTerms}
              style={{ borderRadius: "30px" }}
              onChange={(e) => setSearchTerms(e.currentTarget.value)}
            />
            {searchTerms && (
              <button onClick={clearSearch} className="flex align-items-center justify-content-center w-3rem h-full ">
                <CrossIcon color={theme.neutralColor700} width={14} height={14} />
              </button>
            )}
          </div>
        </div>
        {filteredResult.length > 0 ? (
          <InfiniteScroll next={listMore} dataLength={filteredResult.length} hasMore={!lastPage} loader={" "}>
            {filteredResult.map((profile) => (
              <SharedCoachView
                key={profile.id}
                profile={profile}
                onClick={() => addSharedCoach(profile)}
                className="mb-4"
              />
            ))}
          </InfiniteScroll>
        ) : (
          <div className="flex justify-content-center flex-column mt-8">
            <HUText fontStyle="LL" color={theme.neutralColor700} className="flex justify-content-center text-center">
              {word("program.sharedCoach.emptyView.description")}
            </HUText>
            <div className="flex justify-content-center text-center align-items-center mt-4">
              <img src={SearchEmpty} alt="SessionEmptySVG" />
            </div>
          </div>
        )}
      </HUDialog>
    </HUStack>
  )
}
