import { FormikErrors, FormikTouched, FormikValues } from "formik"
import _ from "lodash"

export function isFormFieldInValid<T extends string, Values extends FormikValues = FormikValues>(
  name: T,
  formik: { touched?: FormikTouched<Values>; errors?: FormikErrors<Values> },
) {
  const touchedValue = _.get(formik.touched, name)
  const errorValue = _.get(formik.errors, name)
  return !!(touchedValue && errorValue)
}

export function getFormErrorMessage<T extends string, Values extends FormikValues = FormikValues>(
  name: T,
  formik: { touched?: FormikTouched<Values>; errors?: FormikErrors<Values> },
  options?: {
    errorMessage?: string
    showAlways?: boolean
  },
) {
  const error = options?.errorMessage ?? formik.errors?.[name]
  return isFormFieldInValid(name, formik) || (options?.showAlways && !!error) ? (
    <p style={{ fontSize: "smaller" }} className="p-error">
      <>{error}</>
    </p>
  ) : undefined
}
