import { UnionSmallIcon } from "@images/svgIcons/unionSmallIcon"
import { HUText } from "@modules/ui/components/huText"
import { useTheme } from "styled-components"
import React from "react"

type BoxFormHeaderProps = {
  title: string
  subtitle?: string
  description?: string
  classname?: string
  children?: React.ReactNode
}
export const BoxFormHeader = ({ title, subtitle, description, classname, children }: BoxFormHeaderProps) => {
  const theme = useTheme()
  return (
    <div
      className={`flex flex-column align-items-center text-center gap-2 ${classname ?? ""}`}
      style={{ maxWidth: "45rem" }}
    >
      <UnionSmallIcon color={theme.mainWhite} />
      <HUText fontStyle="HL" className="mt-5">
        {title}
      </HUText>
      {subtitle && <HUText fontStyle="HL">{subtitle}</HUText>}
      {description && (
        <HUText fontStyle="BL" color={theme.neutralColor600} style={{ whiteSpace: "pre-line" }}>
          {description}
        </HUText>
      )}
      {children}
    </div>
  )
}
