import { defaultMonthValue } from "@modules/finances/components/defaultMonth"
import { useQueryParam } from "@modules/utils/navigation/useQueryParam"
import dayjs from "dayjs"
import { useEffect } from "react"

export const usePeriodParam = () => {
  const [period, setPeriod] = useQueryParam("period")
  useEffect(() => {
    if (!!period && !dayjs(period).isValid()) {
      setPeriod(defaultMonthValue)
    }
  }, [period])

  const validPeriod = !!period && dayjs(period).isValid() ? period : defaultMonthValue
  return [validPeriod, setPeriod] as const
}
