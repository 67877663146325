import { BoxFormPageProps } from "@modules/box/components/form/boxFormFirstPage"
import { BoxFormTemplatePage } from "@modules/box/components/form/boxFormTemplatePage"
import { word } from "@modules/core/utils/i18n"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { useTheme } from "styled-components"

export const BoxFormFourthPage = ({
  handleSubmit,
  onPrevious,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  disabled,
}: BoxFormPageProps) => {
  const theme = useTheme()
  const acceptedTypes = "image/jpeg, image/png, image/webp"

  return (
    <BoxFormTemplatePage
      onPrevious={onPrevious}
      handleSubmit={handleSubmit}
      disabled={disabled}
      pageNumber={4}
      header={{
        title: word("box.creation.page.4.title"),
        description: word("box.creation.page.4.description"),
      }}
    >
      <div className="flex gap-8 align-items-center md:flex-row flex-column">
        <div className="flex flex-column align-items-center" style={{ maxWidth: "13.75rem" }}>
          <FormikInput
            isRequiredInput
            type="image"
            imageProps={{
              type: "logo",
              placeHolder: {
                message: word("program.form.uploadImage.text1"),
                style: { paddingLeft: "2.28rem", paddingRight: "2.28rem" },
              },
              hideButton: true,
              size: "220px",
              acceptedTypes,
              resizer: true,
            }}
            name="logo"
            label={word("box.form.logo.label")}
            setFieldValue={formik.setFieldValue}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("logo")}
            error={getFormErrorMessage("logo")}
          />
          <HUText fontStyle="BS" color={theme.neutralColor300} className="text-center">
            {word("box.form.uploadImage.logo.restrictions")}
          </HUText>
        </div>
        <div className="flex flex-column align-items-center" style={{ maxWidth: "13.75rem" }}>
          <FormikInput
            isRequiredInput
            formikStyle={{ paddingLeft: "76px" }}
            type="image"
            imageProps={{
              type: "cover",
              placeHolder: {
                message: word("program.form.uploadImage.text1"),
                style: { paddingLeft: "4.75rem", paddingRight: "4.75rem" },
              },
              size: "220px",
              acceptedTypes,
              resizer: true,
            }}
            name="cover"
            label={word("box.form.cover.label")}
            setFieldValue={formik.setFieldValue}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("cover")}
            error={getFormErrorMessage("cover")}
          />
          <HUText fontStyle="BS" color={theme.neutralColor300} className="text-center">
            {word("box.form.uploadImage.cover.restrictions")}
          </HUText>
        </div>
      </div>
    </BoxFormTemplatePage>
  )
}
