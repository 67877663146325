import { CheckmarkIcon } from "@images/svgIcons/checkmarkIcon"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { toggleInArray } from "@modules/utils/objectUtils"
import { Divider } from "primereact/divider"
import styled, { CSSProperties, useTheme } from "styled-components"
import React, { ReactNode, useState } from "react"
import { HUButton } from "@modules/ui/components/huButton"

type Item<T> = {
  value: T
  label: string
  leftSide?: ReactNode
}

export type HUMultiSelectProps<T> = {
  values: T[]
  options: Item<T>[]
  emptyMessage?: string
  onChange?: (values: T[]) => void
  valueTemplate?: (item: Item<T>) => React.ReactNode
  className?: string
  style?: CSSProperties
  displayTotalSelectedOptions?: boolean
  hideIcon?: boolean
  viewMore?: string
}

export function HUMultiSelect<T>({
  values,
  options,
  emptyMessage,
  onChange,
  valueTemplate,
  className,
  style,
  displayTotalSelectedOptions,
  hideIcon,
  viewMore,
}: HUMultiSelectProps<T>) {
  const theme = useTheme()

  const onChangeItem = (item: Item<T>) => {
    const newArray = toggleInArray(values, (value) => value === item.value, item.value)
    onChange?.(newArray as T[])
  }

  const [viewMoreClicked, setViewMoreClicked] = useState<boolean>(false)

  const itemOptionTemplate = (item: Item<T>, index: number) => {
    const isItemSelected = values.includes(item.value)
    return (
      <HUButton
        key={index}
        text={item.label}
        type="Right_Icon"
        size="S"
        colorType={isItemSelected ? "White" : "Quaternary"}
        onClick={() => onChangeItem(item)}
        icon={
          isItemSelected && !hideIcon
            ? {
                iconView: <CheckmarkIcon color={theme.neutralColor100} width={14} />,
              }
            : undefined
        }
      />
    )
  }

  return (
    <HUStack gap={8} style={style} align className={className}>
      {options.length ? (
        <>
          <OptionsContainer gap={10} $viewMore={viewMoreClicked}>
            {options.map((option, index) => valueTemplate?.(option) ?? itemOptionTemplate(option, index))}
          </OptionsContainer>
          {displayTotalSelectedOptions && (
            <HUStack gap={8}>
              <Divider />
              <HUText fontStyle="LL">{`${values.length}/${options.length} ${word("global.selected", {
                count: values.length === 0 ? 1 : values.length,
              })}`}</HUText>
            </HUStack>
          )}
          {options.length > 5 && viewMore && !viewMoreClicked && (
            <div
              className="flex flex-column align-items-center cursor-pointer"
              onClick={() => setViewMoreClicked(true)}
            >
              <HUText fontStyle="BS" className="mb-1">
                {viewMore}
              </HUText>
              <span className="pi pi-chevron-down text-2xl" />
            </div>
          )}
        </>
      ) : (
        <HUText>{emptyMessage ?? word("multiselect.empty.message")}</HUText>
      )}
    </HUStack>
  )
}

const OptionsContainer = styled(HURow)<{ $viewMore?: boolean }>`
  flex-wrap: wrap;
  max-height: ${({ $viewMore }) => ($viewMore ? "none" : "90px")};
  overflow: ${({ $viewMore }) => ($viewMore ? "visible" : "hidden")};
  align-self: normal;
`
