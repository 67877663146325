import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const EmailIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill={props.color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.08447 8.4639C7.76167 8.67909 7.38667 8.79288 7.00004 8.79288C6.61344 8.79288 6.23843 8.67909 5.91564 8.4639L0.656904 4.958C0.639281 4.94625 0.621945 4.93422 0.604858 4.92195L0.604858 10.6243C0.604858 11.271 1.12961 11.7842 1.76477 11.7842L12.2353 11.7842C12.8819 11.7842 13.3952 11.2595 13.3952 10.6243V4.92194C13.3781 4.93425 13.3607 4.94632 13.343 4.9581L8.08447 8.4639ZM8.08447 8.4639L13.343 4.95811L8.08447 8.4639Z"
      fill={props.color}
      stroke="white"
      strokeWidth="0.0429686"
    />
    <path
      d="M1.08581 4.3145L6.34454 7.82043C6.54361 7.95315 6.77175 8.0195 6.99989 8.0195C7.22806 8.0195 7.45623 7.95312 7.6553 7.82043L12.914 4.3145C13.2287 4.10484 13.4166 3.75393 13.4166 3.3752C13.4166 2.72399 12.8868 2.19421 12.2356 2.19421L1.76424 2.19421C1.11305 2.19424 0.583252 2.72401 0.583252 3.37582C0.583252 3.75393 0.77114 4.10484 1.08581 4.3145Z"
      fill={props.color}
    />
  </svg>
)
