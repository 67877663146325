import { Config } from "@modules/config"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { addRequestInterceptor, addResponseInterceptor } from "@modules/core/api/interceptors/apiInterceptor"
import { requestLoggerInterceptor } from "@modules/core/api/interceptors/requestLoggerInterceptor"
import { responseLoggerInterceptor } from "@modules/core/api/interceptors/responseLoggerInterceptor"
import { StripeCountryCode } from "@modules/payment/stripeTypes"
import axios, { AxiosInstance } from "axios"

export class PaymentApi {
  private baseUrl = "stripe"
  private programBaseUrl = "programs"
  private axiosStripe: AxiosInstance

  constructor(private api: ApiClient) {
    this.axiosStripe = axios.create()
    addRequestInterceptor(this.axiosStripe, requestLoggerInterceptor())
    addResponseInterceptor(this.axiosStripe, responseLoggerInterceptor())
  }

  async createBoxStripeAccount(accountToken: string, countryCode?: StripeCountryCode): Promise<{ url: string }> {
    const result = await this.api.post<{ url: string }>(`/box/${this.baseUrl}/create-account`, {
      accountToken,
      countryCode,
      bankAccountToken: null,
      businessUrl: "",
    })
    return result.data
  }
  async createUserStripeAccount(accountToken: string, countryCode: StripeCountryCode): Promise<{ url: string }> {
    const result = await this.api.post<{ url: string }>(`${this.baseUrl}/create-account`, {
      accountToken,
      countryCode,
      bankAccountToken: null,
      businessUrl: "",
    })
    return result.data
  }

  async createAccountToken() {
    const urlSearchParams = new URLSearchParams()
    urlSearchParams.append("account[tos_shown_and_accepted]", "true")

    const result = await this.axiosStripe.post<{ id: string | undefined; error: { code: string } | undefined }>(
      "https://api.stripe.com/v1/tokens",
      urlSearchParams,
      {
        headers: {
          Authorization: `Bearer ${Config.STRIPE_PUBLIC_KEY}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    )

    return result.data
  }

  async getUpdateBoxAccountLink() {
    const result = await this.api.post<{ url: string }>(`/box/${this.baseUrl}/update-account-link`)
    return result.data
  }

  async getUpdateUserAccountLink() {
    const result = await this.api.post<{ url: string }>(`${this.baseUrl}/update-account-link`)
    return result.data
  }

  async createProgramPaymentSubscription(programId: string, successUrl: string, cancelUrl: string) {
    const result = await this.api.post<{ url: string }>(`${this.programBaseUrl}/${programId}/payment/checkout`, {
      successUrl,
      cancelUrl,
    })
    return result.data
  }
}
