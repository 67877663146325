import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { Button, ButtonProps } from "primereact/button"
import styled from "styled-components"

export const AuthForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 85%;
`

export const AuthContent = styled(HUStack)`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 70%;
  height: 100%;
  margin-top: 10px;

  @media (max-width: 600px) {
    width: 75vw;
  }
`

export const AuthFormError = styled(HUText)<{ color?: string }>`
  text-align: center;
  color: ${({ color, theme }) => color ?? theme.textWarning};
`

type AuthSubmitButtonProps = {
  children?: React.ReactNode
} & ButtonProps

export const AuthSubmitButton: React.FC<AuthSubmitButtonProps> = ({ className, children, ...props }) => {
  return (
    <AuthButton rounded className={(className ?? "") + "mt-6 justify-content-center"} type="submit" {...props}>
      {children}
    </AuthButton>
  )
}

const AuthButton = styled(Button)`
  svg {
    margin-right: 0.5rem;
  }
`

export const AuthHeader = ({ title, subtitle }: { title: string; subtitle?: string }) => {
  return (
    <HUStack gap={5} align className="mb-2 ">
      <HUText fontStyle="HL">{title}</HUText>
      {subtitle && <HUText fontStyle="BM">{subtitle}</HUText>}
    </HUStack>
  )
}
