import { word } from "@modules/core/utils/i18n"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import _ from "lodash"
import { Button } from "primereact/button"
import React, { createContext, useContext, useImperativeHandle, useMemo, useRef, useState } from "react"

interface HUPopupProps {
  title?: string
  message?: string
  piIcon?: string
  displayOkButton?: boolean
}

interface HUPopupContextAction {
  show: (props: HUPopupProps) => void
  showDefaultError: () => void
}

export const HUPopupContext = createContext<HUPopupContextAction>({
  show: _.noop,
  showDefaultError: _.noop,
})

const InternalPopup = React.forwardRef<HUPopupContextAction>(({}, ref) => {
  const [visiblePopup, setVisiblePopup] = useState<HUPopupProps | null>(null)

  useImperativeHandle(
    ref,
    () => {
      return {
        show: setVisiblePopup,
        showDefaultError: () =>
          setVisiblePopup({
            title: word("global.error.label"),
            piIcon: "pi-info-circle",
            message: word("global.error.default"),
          }),
      }
    },
    [],
  )

  const footer = useMemo(
    () => (
      <div>
        <Button
          type="button"
          label={word("global.ok")}
          icon="pi pi-check"
          className="p-button-text"
          onClick={() => setVisiblePopup(null)}
        />
      </div>
    ),
    [],
  )

  return (
    <HUDialog
      visible={visiblePopup !== null}
      header={visiblePopup?.title}
      onHide={() => setVisiblePopup(null)}
      footer={visiblePopup?.displayOkButton ? footer : undefined}
    >
      <div className="flex align-items-center justify-content-center">
        {visiblePopup?.piIcon && <i className={`pi ${visiblePopup?.piIcon} mr-3`} style={{ fontSize: "2rem" }} />}
        {visiblePopup?.message && <span>{visiblePopup.message}</span>}
      </div>
    </HUDialog>
  )
})

export const HUPopup: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const internalRef = useRef<HUPopupContextAction>(null)

  return (
    <HUPopupContext.Provider
      value={{
        show: (props) => internalRef.current?.show(props),
        showDefaultError: () => internalRef.current?.showDefaultError(),
      }}
    >
      <InternalPopup ref={internalRef} />
      {children}
    </HUPopupContext.Provider>
  )
}

export const usePopup = () => useContext(HUPopupContext)
