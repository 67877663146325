import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const CalendarIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.77539 8.925H21.2254M7.9004 1.75V4.825M16.1004 1.75V4.825M6.87539 22.25H17.1254C19.3898 22.25 21.2254 20.4144 21.2254 18.15V7.3875C21.2254 5.12313 19.3898 3.2875 17.1254 3.2875H6.87539C4.61102 3.2875 2.77539 5.12313 2.77539 7.3875V18.15C2.77539 20.4144 4.61102 22.25 6.87539 22.25Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx="12.0006" cy="15.075" rx="1.025" ry="1.025" fill={props.color} />
    <ellipse cx="16.1002" cy="15.075" rx="1.025" ry="1.025" fill={props.color} />
    <ellipse cx="7.9" cy="15.075" rx="1.025" ry="1.025" fill={props.color} />
  </svg>
)
