import { ConfigService } from "@modules/core/services/configService"

const DEFAULT_CDN_SUFFIX = "svg"
// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397

interface EmojiProps extends React.HTMLAttributes<HTMLSpanElement> {
  cdnSuffix?: string
  countryCode: string
  style?: React.CSSProperties
  svg?: false
}

interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  cdnSuffix?: string
  countryCode: string
  style?: React.CSSProperties
  svg?: true
}

export type HuFlagProps = EmojiProps | ImgProps

/**
 * Display a flag from a country code - Flag are store on the CDN
 * @component
 * @param {object} props - Component props
 * @param {string} props.countryCode - The country code to display the flag
 * @param {boolean} props.svg  - If false, we use the Emoji flag like 🇫🇷
 * @param {string} props.cdnSuffix - The suffix of the flag image by default is svg
 * @param {React.ImgHTMLAttributes<HTMLImageElement> | React.HTMLAttributes<HTMLSpanElement>} props.props - Additional attributes to apply to the div element.
 * @returns {ReactElement}
 */
export const HuFlag: React.FC<HuFlagProps> = ({
  countryCode,
  svg = true,
  cdnSuffix = DEFAULT_CDN_SUFFIX,
  style,
  ...props
}) => {
  if (svg) {
    const flagUrl = `${ConfigService.config.CDN_FLAG}${countryCode.toLowerCase()}.${cdnSuffix}`

    return (
      <img
        {...props}
        src={flagUrl}
        style={{
          display: "inline-block",
          width: "1rem",
          height: "1rem",
          verticalAlign: "middle",
          ...style,
        }}
      />
    )
  }

  const emoji = countryCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + OFFSET))

  return (
    <span
      role="img"
      {...props}
      style={{
        display: "inline-block",
        fontSize: "1rem",
        lineHeight: "1rem",
        verticalAlign: "middle",
        ...style,
      }}
    >
      {emoji}
    </span>
  )
}

HuFlag.displayName = "HuFlag"
