import { BoxSlotSessionInputKey } from "@modules/boxSlot/boxSessionFormikTypes"
import { BoxSlotDraft, BoxSlotTypeOf } from "@modules/boxSlot/boxSlotTypes"
import { word } from "@modules/core/utils/i18n"
import { requiredInput } from "@modules/form/formUtils"
import { defaultDateTimeFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import * as Yup from "yup"

export type BoxSlotInputKey =
  | "date"
  | "startDateTime"
  | "endDateTime"
  | "programmationId"
  | "participantLimit"
  | "contentEarlyAccess"
  | "limitStart"
  | "limitEnd"
  | "limitCancel"
  | "location"
  | BoxSlotSessionInputKey

export type BoxSlotFormikType = {
  _type: typeof BoxSlotTypeOf
  date: Date | null
  programmationId: string
  startDateTime: Date | null
  endDateTime: Date | null
  participantLimit: number
  contentEarlyAccess: boolean
  limitStart?: number
  limitEnd?: number
  limitCancel?: number
  location?: string
}

export function getBoxSlotValidationSchema() {
  return Yup.object({
    date: Yup.date().required(),
    programmationId: requiredInput(Yup.string()),
    startDateTime: Yup.date().required(word("global.form.required")),
    endDateTime: Yup.date().required(word("global.form.required")),
    participantLimit: Yup.number().min(1).required("global.form.required"),
    location: Yup.string()
      .trim()
      .nullable()
      .max(40, word("form.maximum.characters", { count: 40 })),
    limitStart: Yup.number()
      .nullable()
      .min(1, word("global.form.minimumValue", { minValue: 1 })),
    limitEnd: Yup.number()
      .nullable()
      .min(1, word("global.form.minimumValue", { minValue: 1 })),
    limitCancel: Yup.number()
      .nullable()
      .min(1, word("global.form.minimumValue", { minValue: 1 }))
      .required(word("global.form.required")),
  })
}

export const convertBoxSlotToFormik = (slot: BoxSlotDraft & { date: string | null }): BoxSlotFormikType => {
  return {
    _type: BoxSlotTypeOf,
    date: slot.date ? new Date(slot.date) : null,
    programmationId: slot.programmationId,
    startDateTime: new Date(slot.startDateTime ?? ""),
    endDateTime: new Date(slot.endDateTime),
    participantLimit: slot.participantLimit,
    contentEarlyAccess: slot.contentEarlyAccess,
    location: slot.location ?? "",
    limitStart: slot.participation?.limitStart,
    limitEnd: slot.participation?.limitEnd,
    limitCancel: slot.participation?.limitCancel,
  }
}

export const convertFormikToBoxSlotDraft = (formik: BoxSlotFormikType): BoxSlotDraft => {
  const newDate = dayjs(formik.date as Date)
  const startDateTime = dayjs(formik.startDateTime)
    .set("year", newDate.year())
    .set("month", newDate.month())
    .set("date", newDate.date())
  const endDateTime = dayjs(formik.endDateTime)
    .set("year", newDate.year())
    .set("month", newDate.month())
    .set("date", newDate.date())
  return {
    ...formik,
    startDateTime: dayjs(startDateTime).format(defaultDateTimeFormat),
    endDateTime: dayjs(endDateTime).format(defaultDateTimeFormat),
    participantLimit: formik.participantLimit,
    contentEarlyAccess: formik.contentEarlyAccess,
    participation: {
      limitStart: formik.limitStart,
      limitEnd: formik.limitEnd,
      limitCancel: formik.limitCancel,
    },
    location: formik.location,
  }
}
