import { ReloadIcon } from "@images/svgIcons/reloadIcon"
import { word } from "@modules/core/utils/i18n"
import { defaultMonthValue } from "@modules/finances/components/defaultMonth"
import { usePeriodParam } from "@modules/finances/hooks/usePeriod"
import { BoxFinanceService } from "@modules/finances/services/boxFinanceService"
import { UserFinanceService } from "@modules/finances/services/userFinanceService"
import { useAsync } from "@modules/hooks/useAsync"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HURow } from "@modules/ui/components/layout"
import { MonthPicker, MonthPickerChangeEvent } from "@modules/ui/components/monthPicker"
import { rotate } from "@modules/ui/styles/animation"
import { monthYearDateFormat } from "@modules/utils/dateUtils"
import { LoggerType } from "@modules/utils/loggerUtils"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import { useState } from "react"
import styled, { css, useTheme } from "styled-components"

type Props = {
  refreshing: boolean
  onRefresh: () => void
  financeService: BoxFinanceService | UserFinanceService
} & React.HTMLAttributes<HTMLDivElement>

export const LastUpdateComponent: React.FC<Props> = ({ refreshing, onRefresh, financeService, ...props }) => {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [period, setPeriod] = usePeriodParam()

  const { value: firstPeriod } = useAsync(() => financeService.getFirstPeriod(), [])

  const {
    value: lastUpdate,
    loading: lastUpdateDateLoading,
    refresh: refreshLastUpdate,
  } = useAsync(() => financeService.getLastUpdate(), [])

  const toast = useToast()

  const update = async () => {
    try {
      setLoading(true)
      await financeService.updateStats()
      refreshLastUpdate()
      onRefresh()
    } catch (e) {
      Sentry.captureException(`${LoggerType.Financial} refresh data: ${JSON.stringify(e)}`)
      toast.show({ severity: "error", summary: "Error", detail: word("global.error.default") })
    } finally {
      setLoading(false)
    }
  }

  const emptyText = "  --  "

  const onDatePick = (e: MonthPickerChangeEvent) => {
    const value = e.value
    if (value) {
      setPeriod(dayjs(value.toISOString()).format(monthYearDateFormat))
    }
  }

  const displayLastUpdate = !period || defaultMonthValue === period
  const noData = !lastUpdateDateLoading && !lastUpdate
  return (
    <HURow gap={8} justifySpaceBetween align {...props}>
      <HURow gap={16} align>
        {displayLastUpdate && (
          <>
            <div>
              <HUText fontStyle="BM" color={theme.neutralColor600}>
                {noData
                  ? word("finances.page.no_data")
                  : word("finances.page.last_update", {
                      date: lastUpdate?.format("DD/MM") ?? emptyText,
                      time: lastUpdate?.format("HH:mm") ?? emptyText,
                    })}
              </HUText>
            </div>
            <StyledHUButton
              colorType="Underline"
              type="Left_Icon"
              size="XS"
              text={word("global.update")}
              onClick={update}
              icon={{ icon: ReloadIcon }}
              disabled={loading || !!refreshing}
              $loading={loading || !!refreshing}
            />
          </>
        )}
      </HURow>
      <MonthPicker onDatePick={onDatePick} defaultDate={period ?? undefined} startDate={firstPeriod} />
    </HURow>
  )
}

const StyledHUButton = styled(HUButton)<{ $loading: boolean }>`
  ${({ $loading }) =>
    $loading &&
    css`
      svg {
        animation: ${rotate} 0.8s infinite linear;
      }
    `}
`
