import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PrivateIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.1009 6.9H7.90082M16.1009 6.9C18.3653 6.9 20.2009 8.73563 20.2009 11V17.15C20.2009 19.4144 18.3653 21.25 16.1009 21.25H7.90082C5.63643 21.25 3.80078 19.4144 3.80078 17.15V11C3.80078 8.73563 5.63643 6.9 7.90082 6.9M16.1009 6.9V5.85C16.1009 3.58563 14.2652 1.75 12.0009 1.75C9.73647 1.75 7.90082 3.58563 7.90082 5.85V6.9M12.0009 15.1V13.05"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
