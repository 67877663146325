import { BoxOfferItem } from "@modules/boxOffer/boxOfferTypes"
import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { HUTag } from "@modules/ui/components/huTag"
import { HUText } from "@modules/ui/components/huText"
import React from "react"
import { useTheme } from "styled-components"

type RestrictionTagProps = {
  restriction: BoxOfferItem
  isSelected: boolean
  onSelectRestriction?: () => void
}

export const BoxRestrictionTag: React.FC<RestrictionTagProps> = ({ restriction, isSelected, onSelectRestriction }) => {
  const language = useLanguage()
  const theme = useTheme()
  return (
    <HUTag
      onClick={onSelectRestriction}
      className={`border-1
        ${!!onSelectRestriction ? "cursor-pointer" : "cursor-default"} ${isSelected ? "bg-white surface-border" : "surface-ground border-50 hover:border-500"}`}
    >
      <HUText
        fontStyle="LM"
        color={isSelected ? theme.neutralColor50 : theme.textPrimary}
        className="flex align-items-center hu-text"
      >
        {`${restriction.name} - ${restriction.price ? formatCurrency(restriction.price, restriction.currency, language) : word("program.price.free")}`}
      </HUText>
    </HUTag>
  )
}
