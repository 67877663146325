import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const CircleBreakIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.98838 2.29243C4.84233 2.29243 2.29194 4.84281 2.29194 7.98887C2.29194 11.1349 4.84233 13.6853 7.98838 13.6853C11.1344 13.6853 13.6848 11.1349 13.6848 7.98887C13.6848 7.55862 14.0336 7.20984 14.4638 7.20984C14.8941 7.20984 15.2429 7.55862 15.2429 7.98887C15.2429 11.9954 11.9949 15.2434 7.98838 15.2434C3.98183 15.2434 0.733887 11.9954 0.733887 7.98887C0.733887 3.98232 3.98183 0.734375 7.98838 0.734375C8.41863 0.734375 8.76741 1.08316 8.76741 1.5134C8.76741 1.94365 8.41863 2.29243 7.98838 2.29243ZM10.032 1.793C10.2148 1.40354 10.6788 1.23605 11.0682 1.41891C12.5383 2.10908 13.738 3.27729 14.4682 4.72387C14.6621 5.10796 14.5079 5.57649 14.1238 5.77037C13.7397 5.96424 13.2711 5.81004 13.0773 5.42595C12.5037 4.28968 11.5602 3.37114 10.4061 2.82925C10.0166 2.6464 9.84914 2.18245 10.032 1.793Z"
      fill={props.color}
    />
    <rect
      width="1.59658"
      height="4.96713"
      rx="0.473464"
      transform="matrix(1 0 -0.148327 0.988938 6.29712 5.51562)"
      fill={props.color}
    />
    <rect
      width="1.59658"
      height="4.96713"
      rx="0.473464"
      transform="matrix(1 0 -0.148327 0.988938 8.79785 5.51562)"
      fill={props.color}
    />
  </svg>
)
