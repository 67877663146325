import { word } from "@modules/core/utils/i18n"

import { AuthError } from "@modules/auth/authErrorTypes"

export const getAuthError = (e: any, props?: { onlyEmail?: boolean }) => {
  const defaultError = word("global.error.default")
  const code = e.code
  if (code) {
    switch (code) {
      case AuthError.UserNotConfirmedException:
        return word("auth.error.account_not_confirmed")
      case AuthError.UsernameExistsException:
        return word("auth.error.user_exists")
      case AuthError.UserNotFoundException:
        return props?.onlyEmail ? word("auth.signup.form.mail_invalid") : word("auth.form.error")
      case AuthError.NotAuthorizedException:
        return word("auth.form.error")
      case AuthError.CodeMismatchException:
        return word("auth.error.invalid_code")
      case AuthError.LimitExceededException:
        return word("auth.error.attempt_limit")
      default:
        return defaultError
    }
  }
  return defaultError
}
