import React from "react"

export const CheckmarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <g id="checkmark">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2197 4.05126C18.5661 3.65698 19.1678 3.61702 19.5636 3.96202C19.9595 4.30701 19.9996 4.90631 19.6532 5.30059L8.5421 17.9488C8.36853 18.1464 8.1205 18.2635 7.85706 18.2722C7.59362 18.281 7.33832 18.1806 7.15193 17.9949L2.39003 13.2518C2.0181 12.8814 2.0181 12.2807 2.39003 11.9103C2.76196 11.5398 3.36497 11.5398 3.7369 11.9103L7.77898 15.9364L18.2197 4.05126Z"
        fill={props.color}
      />
    </g>
  </svg>
)
