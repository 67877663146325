import { ReturnIcon } from "@images/svgIcons/returnIcon"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import dayjs from "dayjs"
import styled, { useTheme } from "styled-components"

export const BoxPlanningHeaderLeftView: React.FC = () => {
  const { currentWeekTitle, prev, next, currentView, selectedDate, setSelectedDate } = useCalendar()
  const theme = useTheme()
  return (
    <Container gap={10} className="p-1 md:p-2 xl:p-4 mb-5 justify-content-center">
      <HURow className="justify-content-around align-items-center text-center">
        <i style={{ fontSize: "0.813rem" }} className="pi pi-chevron-left cursor-pointer col-2" onClick={prev} />
        <HUText fontStyle={currentView === "dayGridMonth" ? "TM" : "TS"} className="col-8">
          {currentWeekTitle}
        </HUText>
        <i style={{ fontSize: "0.813rem" }} className="pi pi-chevron-right cursor-pointer col-2" onClick={next} />
      </HURow>

      <HUButton
        type="Right_Icon"
        size="XS"
        colorType="Underline"
        className="capitalize p-2 mx-auto"
        onClick={() => setSelectedDate(dayjs())}
        icon={{
          icon: ReturnIcon,
          iconProps: { color: dayjs().isSame(selectedDate, "day") ? theme.neutralColor : theme.textPrimary },
        }}
        disabled={dayjs().isSame(selectedDate, "day")}
        text={word("global.today.label")}
      />
    </Container>
  )
}

const Container = styled(HUStack)`
  border-radius: 33px;
  height: 200px;
  max-height: 200px;
  background-color: ${({ theme }) => theme.surfaceCard};
`
