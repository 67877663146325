import { ApiClient } from "@modules/core/api/client/apiClient"
import { FinanceStatisticsDto, convertFinanceStatisticsFromDto } from "@modules/finances/services/financeStatisticsDto"
import {
  BoxItemTransactionDto,
  MemberTransactionDto,
  convertBoxItemTransactionFromDto,
  convertMemberTransactionFromDto,
} from "@modules/finances/services/transactionDto"
import { CustomerHistoryDto, convertItemCustomerHistoryFromDto } from "@modules/finances/services/transactionHistoryDto"
import { TransactionTotalsDto, convertTransactionTotalsFromDto } from "@modules/finances/services/transactionTotalsDto"
import { FinanceStatistics, TransactionStatus } from "@modules/finances/types/financesType"
import { TransactionTotals } from "@modules/finances/types/transactionTotalsType"
import { MemberTransaction } from "@modules/finances/types/transactionType"
// import { BoxTransaction } from "@modules/finances/types/transactionType"
import { PaginatedCursor } from "@modules/utils/paginated"

export class BoxFinanceApi {
  private boxFinanceBaseUrl = "/box/financial"

  constructor(private api: ApiClient) {}

  async getTransactions(filter: TransactionStatus | undefined, period: string, page?: string, size = 10) {
    const result = await this.api.get<PaginatedCursor<BoxItemTransactionDto>>(
      `${this.boxFinanceBaseUrl}/transactions`,
      {
        params: {
          page,
          pageSize: size,
          period,
          status: filter,
        },
      },
    )

    const pageData = result.data

    return {
      ...pageData,
      data: pageData.data.map((transaction) => convertBoxItemTransactionFromDto(transaction)),
    }
  }
  async getBoxTransactionsHistory(stripeCustomerId: string, page?: string, size = 10) {
    const result = await this.api.get<PaginatedCursor<CustomerHistoryDto>>(
      `${this.boxFinanceBaseUrl}/customer/${stripeCustomerId}/history`,
      {
        params: {
          page,
          pageSize: size,
        },
      },
    )
    const pageData = result.data

    return {
      ...pageData,
      data: pageData.data.map((transaction) => convertItemCustomerHistoryFromDto(transaction)),
    }
  }

  async getBoxTransaction(id: string): Promise<MemberTransaction> {
    const resultDto = await this.api.get<MemberTransactionDto>(`${this.boxFinanceBaseUrl}/transactions/${id}`)
    return convertMemberTransactionFromDto(resultDto.data)
  }

  async getStats(period: string): Promise<FinanceStatistics> {
    const result = await this.api.get<FinanceStatisticsDto>(`${this.boxFinanceBaseUrl}/stats`, {
      params: {
        period,
      },
    })
    return convertFinanceStatisticsFromDto(result.data)
  }

  async getTransactionTotals(period: string): Promise<TransactionTotals> {
    const result = await this.api.get<TransactionTotalsDto>(`${this.boxFinanceBaseUrl}/transaction-status-totals`, {
      params: {
        period,
      },
    })
    return convertTransactionTotalsFromDto(result.data)
  }

  async getLastUpdate(): Promise<string> {
    const result = await this.api.get<{ lastUpdate: string }>(`${this.boxFinanceBaseUrl}/last-update`)
    return result.data.lastUpdate
  }

  async getFirstPeriod(): Promise<string> {
    const result = await this.api.get<{ firstPeriod: string }>(`${this.boxFinanceBaseUrl}/first-period`)
    return result.data.firstPeriod
  }

  async updateStats(): Promise<void> {
    await this.api.post(`${this.boxFinanceBaseUrl}/update-stats`)
  }

  async exportTransactions(startDate: string, endDate: string): Promise<string> {
    const result = await this.api.post<string>(
      `${this.boxFinanceBaseUrl}/export-transactions?startDate=${startDate}&endDate=${endDate}`,
    )
    return result.data
  }
}
