import { CurrentSpaceManager } from "@modules/core/services/currentSpaceManager"
import { Service } from "@modules/core/services/serviceType"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"
import { DEFAULT_UNIT, HUUnit } from "@modules/utils/unitType"
import { observable } from "micro-observables"

export type HUTheme = "athlete" | "owner"

export class MyProfileService implements Service {
  private _profileTheme = observable<{ theme: HUTheme }>({ theme: "owner" })
  readonly profileTheme = this._profileTheme.readOnly()
  private _profileUnit = observable<{ unit: HUUnit }>({ unit: DEFAULT_UNIT })
  readonly profileUnit = this._profileUnit.readOnly()
  private _changingTheme = observable<{
    previousTheme?: HUTheme | "loggingout"
    nextTheme?: HUTheme | "loggingout"
  } | null>(null)
  readonly changingTheme = this._changingTheme.readOnly()

  constructor(
    private currentSpaceManager: CurrentSpaceManager,
    private localStorage: LocalStorageService,
  ) {}

  async init() {
    const unsubscribe = this.localStorage.connect(this._profileUnit, LocalStorageKeys.profile.unit, {
      unit: DEFAULT_UNIT,
    })

    this._changingTheme.set({ previousTheme: !this.currentSpaceManager.currentBox.get().boxId ? "athlete" : "owner" })

    this.currentSpaceManager.currentBox.subscribe((currentBox) => {
      this.setTheme(!currentBox.boxId ? "athlete" : "owner")
    })

    return unsubscribe
  }

  setTheme(themeColor: HUTheme) {
    this._profileTheme.update((_) => ({ theme: themeColor }))
  }

  setNewTheme(nextTheme: HUTheme | "loggingout") {
    const currentTheme = this._changingTheme.get()
    this._changingTheme.set({ previousTheme: currentTheme?.nextTheme, nextTheme })
  }

  setUnit(unit: HUUnit) {
    this._profileUnit.update((_) => ({ unit }))
  }
}
