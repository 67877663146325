import { convertImageFromDto } from "@modules/dtos/imageDto"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramPrice, ProgramType } from "@modules/programs/programTypes"
import { LightProgramInfoDto } from "@modules/programs/services/programDto"
import { PromoCode } from "@modules/promoCode/types/promoCodeTypes"

export enum PromoCodeType {
  PERCENT = "PERCENT",
  DIRECT = "DIRECT",
}

export enum PromoCodeArchivedStatus {
  DELETED = "DELETED",
  MAX_ATTENDEES = "MAX_ATTENDEES",
  EXPIRED = "EXPIRED",
}

export type PromoCodeDto = {
  id: string
  title: string
  code: string
  type: PromoCodeType
  value: number
  createdAt: string
  expirationDate?: string
  durationInMonths?: number
  maxAttendees: number
  onlyNewCustomer: boolean
  archived: boolean
  archivedStatus: PromoCodeArchivedStatus
  programs: LightProgramInfoDto[]
}

export const convertPromoCodeFromDto = (dto: PromoCodeDto): PromoCode => {
  return {
    ...dto,
    programs: dto.programs.map((program) => ({
      ...program,
      title: program.name,
      image: convertImageFromDto(program.image),
      price: program.price as ProgramPrice,
      _programType: program.type === ProgramType.VIRTUAL ? ProgramInfiniteTypeOf : ProgramOnOffTypeOf,
    })),
  }
}
