import AladinLogo from "@images/aladin-challenge/logo-aladin.svg"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import styled, { useTheme } from "styled-components"

export const ChallengeDescription = () => {
  const theme = useTheme()

  return (
    <Container className="ml-6">
      <HURow className="align-items-center" gap={20}>
        <AladinLogoContainer className="align-items-center justify-content-center">
          <img src={AladinLogo} width={115} height={90} />
        </AladinLogoContainer>
        <HUText color={theme.mainWhite} fontStyle="HL">
          Association Aladin
        </HUText>
      </HURow>
      <HUText color={theme.mainWhite} fontStyle="TL">
        L‘Association Aladin s’est fixé une double mission
      </HUText>
      <HURow gap={25}>
        <HUStack gap={8}>
          <HUText color={theme.mainWhite} fontStyle="BL" className="challenge-description-title" fontWeight="bold">
            Soutenir les enfants gravement ou longuement malades
          </HUText>
          <HUText color={theme.mainWhite} fontStyle="BM">
            Par ailleurs, il est important pour notre association de réunir la famille proche autour du projet chaque
            fois que cela est possible.
          </HUText>
        </HUStack>
        <HUStack gap={5}>
          <HUText color={theme.mainWhite} fontStyle="BL" className="challenge-description-title" fontWeight="bold">
            Participer à l’amélioration des conditions de séjour à l’hôpital
          </HUText>
          <HUText color={theme.mainWhite} fontStyle="BM">
            L’Association Aladin oeuvre en collaboration étroite avec le personnel soignant afin de connaître les
            besoins des enfants.
          </HUText>
        </HUStack>
      </HURow>
    </Container>
  )
}

const Container = styled(HUStack)`
  .challenge-description-title {
    line-height: 23px;
  }
`

const AladinLogoContainer = styled(HUStack)`
  width: 140px;
  height: 110px;
  background: linear-gradient(107deg, rgba(255, 255, 255, 0.1) 2.13%, rgba(230, 230, 230, 0.1) 96.41%);
  border-radius: 33px;
  box-shadow:
    -2px -2px 4px 0px rgba(0, 0, 0, 0.1) inset,
    2px 2px 8px 0px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(2px);
`
