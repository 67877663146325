import { PaginatedStore, Store } from "@betomorrow/micro-stores"
import { Service } from "@modules/core/services/serviceType"
import { RequestsSASProfile } from "@modules/profile/profileTypes"
import {
  ItemProgramInfinite,
  ProgramInfinite,
  ProgramInfiniteDraft,
  ProgramInfiniteProfile,
  ProgramInfiniteStripeSubscriptionInfos,
} from "@modules/programs/programInfiniteTypes"
import { ProgramFilter, ProgramPrice, ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import { ProgramApi } from "@modules/programs/services/programApi"
import { ProgramInfiniteApi } from "@modules/programs/services/programInfiniteApi"
import {
  ProgramInfiniteDto,
  convertProgramInfiniteFromDto,
  convertProgramInfiniteProfileFromDto,
  convertProgramInfiniteStripeSubscriptionInfosFromDto,
} from "@modules/programs/services/programInfiniteDto"
import { PlanningService } from "@modules/training/services/planningService"
import { ListOrder } from "@modules/utils/types"

export class ProgramInfiniteService implements Service {
  programInfiniteStore = new Store<ProgramInfinite>((id) => this.api.getProgramInfinite(id))
  paginatedAllprogramInfiniteStore = new PaginatedStore<ItemProgramInfinite, ProgramInfinite, "id">((page: number) =>
    this.getInfinitePrograms("SUBSCRIPTION_DATE", "ALL", page),
  ).present(this.programInfiniteStore)
  paginatedOwnedprogramInfiniteStore = new PaginatedStore<ItemProgramInfinite, ProgramInfinite, "id">((page: number) =>
    this.getInfinitePrograms("SUBSCRIPTION_DATE", "OWNED", page),
  ).present(this.programInfiniteStore)
  paginatedSubscribedprogramInfiniteStore = new PaginatedStore<ItemProgramInfinite, ProgramInfinite, "id">(
    (page: number) => this.getInfinitePrograms("SUBSCRIPTION_DATE", "SUBSCRIBED", page),
  ).present(this.programInfiniteStore)
  paginatedProgramInfiniteSASRequestsStore = new Map<
    string,
    PaginatedStore<RequestsSASProfile, RequestsSASProfile, "subscriptionId">
  >()
  paginatedProgramInfiniteSubscribersStore = new Map<
    string,
    PaginatedStore<ProgramInfiniteProfile, ProgramInfiniteProfile, "id">
  >()
  programInfiniteStripeSubscriptionInfosStore = new Store<ProgramInfiniteStripeSubscriptionInfos>((id) =>
    this.getProgramInfiniteStripeSubscriptionInfos(id),
  )

  constructor(
    private api: ProgramInfiniteApi,
    private planningService: PlanningService,
    private programApi: ProgramApi,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedIn(): Promise<void> {
    const programs = await this.getInfinitePrograms("SUBSCRIPTION_DATE", "ALL")
    this.planningService.addFilteredPrograms(programs.content.map((p) => p.id))
  }

  async onLoggedOut(): Promise<void> {
    this.programInfiniteStore.clear()
    this.paginatedAllprogramInfiniteStore.clear()
    this.paginatedOwnedprogramInfiniteStore.clear()
    this.paginatedSubscribedprogramInfiniteStore.clear()
    this.paginatedProgramInfiniteSASRequestsStore.clear()
    this.paginatedProgramInfiniteSubscribersStore.clear()
  }

  async getProgramInfinite(programId: string) {
    return this.api.getProgramInfinite(programId)
  }

  async createProgramInfinite(programDraft: ProgramInfiniteDraft) {
    const program = await this.api.createProgramInfinite(programDraft)
    this.programInfiniteStore.save(program)
    return program
  }

  async updateProgramInfinite(id: string, programDraft: Partial<ProgramInfiniteDraft>) {
    const updatedProgram = await this.api.updateProgramInfinite(id, programDraft)
    this.programInfiniteStore.update(id, () => updatedProgram)
    return updatedProgram
  }

  async deleteProgramInfinite(id: string) {
    await this.api.deleteProgramInfinite(id)
    this.programInfiniteStore.remove(id)
  }

  async getInfinitePrograms(order: ListOrder, filter: ProgramFilter = "ALL", page?: number, size?: number) {
    return this.api.getInfinitePrograms(filter, order, page, size)
  }

  async updateProgramInfinitePrice(programId: string, price: ProgramPrice, trialDuration?: number) {
    const programDto = await this.programApi.updateProgramPrice(programId, price, trialDuration)
    this.programInfiniteStore.update(programId, () => convertProgramInfiniteFromDto(programDto as ProgramInfiniteDto))
  }

  async removeProgramInfinitePrice(programId: string) {
    const programDto = await this.programApi.removeProgramPrice(programId)
    this.programInfiniteStore.update(programId, () => convertProgramInfiniteFromDto(programDto as ProgramInfiniteDto))
  }

  async getProgramInfiniteSubscribers(id: string, page?: number, size?: number) {
    const result = await this.api.getProgramInfiniteSubscribers(id, page, size)

    return {
      ...result,
      content: result.content.map((profile) => convertProgramInfiniteProfileFromDto(profile)),
    }
  }
  getPaginatedProgramInfiniteSubscribersStore(id: string) {
    let paginated = this.paginatedProgramInfiniteSubscribersStore.get(id)
    if (!paginated) {
      paginated = new PaginatedStore<ProgramInfiniteProfile, ProgramInfiniteProfile, "id">((page) =>
        this.getProgramInfiniteSubscribers(id, page),
      )
      this.paginatedProgramInfiniteSubscribersStore.set(id, paginated)
    }
    return paginated
  }

  async subscribeProgramInfinite(id: string): Promise<ProgramSubscriptionStatus> {
    const status = await this.api.subscribeProgramInfinite(id)

    this.programInfiniteStripeSubscriptionInfosStore.fetch(id)

    this.programInfiniteStore.update(id, (program) => {
      return {
        ...program,
        subscriptionStatus: status,
      }
    })

    this.planningService.addNewFilteredProgram(id)

    return status
  }

  async unsubscribeProgramInfinite(id: string): Promise<ProgramSubscriptionStatus> {
    const status = await this.api.unsubscribeProgramInfinite(id)

    this.programInfiniteStripeSubscriptionInfosStore.fetch(id)

    this.programInfiniteStore.update(id, (program) => {
      return {
        ...program,
        subscriptionStatus: status,
      }
    })

    this.planningService.removeFilteredProgram(id)

    return status
  }

  async getProgramInfiniteStripeSubscriptionInfos(id: string) {
    const result = await this.api.getProgramInfiniteStripeSubscriptionInfos(id)

    return convertProgramInfiniteStripeSubscriptionInfosFromDto(id, result)
  }
}
