import { FetchStrategy, usePaginatedStore, useStore } from "@betomorrow/micro-stores"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ProgramFilter } from "@modules/programs/programTypes"
import { useToast } from "@modules/ui/components/huToast"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useMemo, useState } from "react"

export const useProgramOnOff = (id?: string, fetchStrategy?: FetchStrategy) => {
  const { programOnOffService } = useServicesContext()
  return useStore(id, programOnOffService.programOnOffStore, fetchStrategy)
}

export const useProgramsOnOff = (options: {
  programFilter?: ProgramFilter
  page?: number
  size?: number
  sort?: "SUBSCRIPTION_DATE" | "CREATION_DATE"
  fetchStrategy?: FetchStrategy
}) => {
  const { programOnOffService } = useServicesContext()

  const paginatedProgramsStore = useMemo(
    () =>
      options.programFilter === "OWNED"
        ? programOnOffService.paginatedOwnedProgramOnOffStore
        : options.programFilter === "SUBSCRIBED"
          ? programOnOffService.paginatedSubscribedProgramOnOffStore
          : programOnOffService.paginatedAllProgramOnOffStore,
    [options.programFilter, options.page, options.size],
  )

  return usePaginatedStore(paginatedProgramsStore, options.fetchStrategy)
}

export const useProgramOnOffProgression = (programId?: string, disabled?: boolean, fetchStrategy?: FetchStrategy) => {
  const { programOnOffService } = useServicesContext()

  return useStore(!disabled && programId, programOnOffService.programOnOffProgressionStore, fetchStrategy)
}

export const useProgramOnOffProgressionActions = (programId: string) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const { programOnOffService } = useServicesContext()
  const toast = useToast()
  const confirmPopup = useConfirmPopup()

  const startProgram = async (): Promise<void> => {
    try {
      setIsUpdating(true)
      await programOnOffService.startProgramOnOff(programId)
    } catch (e) {
      console.error(LoggerType.Program + "startProgram", e)
      toast.show({ detail: word("program.details.start.error.toast"), severity: "error" })
    } finally {
      setIsUpdating(false)
    }
  }
  const stopProgram = async (): Promise<void> => {
    try {
      setIsUpdating(true)
      await programOnOffService.stopProgramOnOff(programId)
    } catch (e) {
      console.error(LoggerType.Program + "stopProgram", e)
      toast.show({ detail: word("program.details.pause.error.toast"), severity: "error" })
    } finally {
      setIsUpdating(false)
    }
  }
  const restartProgram = async (): Promise<void> => {
    try {
      const processRestart = async () => {
        setIsUpdating(true)
        await programOnOffService.restartProgramOnOff(programId)
        setIsUpdating(false)
      }

      confirmPopup.show({
        title: word("program.restart.warning.title"),
        message: word("program.restart.warning.description"),
        accept: async () => {
          await processRestart()
        },
        acceptValidated: word("program.restart.success.toast"),
        footerProps: {
          align: true,
        },
      })
    } catch (e) {
      console.error(LoggerType.Program + "restartProgram", e)
      toast.show({ detail: word("program.details.restart.error.toast"), severity: "error" })
    } finally {
      setIsUpdating(false)
    }
  }

  return { isUpdating, startProgram, stopProgram, restartProgram }
}
