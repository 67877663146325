import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ChronoIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" {...props}>
    <path
      d="M5.57702 2.58428C6.18732 2.41167 6.83183 2.31913 7.49883 2.31913C8.16583 2.31913 8.81034 2.41167 9.42064 2.58428C9.70297 2.66414 9.99657 2.5 10.0764 2.21767C10.1563 1.93535 9.99214 1.64174 9.70981 1.56189C9.00635 1.36293 8.26456 1.25663 7.49883 1.25663C6.7331 1.25663 5.99131 1.36293 5.28785 1.56189C5.00552 1.64174 4.84139 1.93535 4.92124 2.21767C5.00109 2.5 5.29469 2.66414 5.57702 2.58428ZM13.3533 9.0202C13.3533 12.2537 10.732 14.875 7.49847 14.875C4.26496 14.875 1.64368 12.2537 1.64368 9.0202C1.64368 5.78668 4.26496 3.16541 7.49847 3.16541C10.732 3.16541 13.3533 5.78668 13.3533 9.0202ZM8.01452 5.92041C8.01452 5.6351 7.78323 5.40381 7.49792 5.40381C7.21261 5.40381 6.98132 5.6351 6.98132 5.92041V8.46956C6.67263 8.64823 6.46497 8.98206 6.46497 9.36439C6.46497 9.93501 6.92754 10.3976 7.49816 10.3976C8.06878 10.3976 8.53136 9.93501 8.53136 9.36439C8.53136 8.98186 8.32348 8.64789 8.01452 8.46928V5.92041Z"
      fill={props.color}
    />
  </svg>
)
