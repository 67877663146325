import { WarningHexagonalIcon } from "@images/svgIcons/warningIcon"
import {
  BoxProgrammationFormikType,
  BoxProgrammationInputKey,
} from "@modules/boxProgrammation/boxProgrammationFormikTypes"
import { BoxProgrammationColorSelection } from "@modules/boxProgrammation/components/boxProgrammationColorSelection"
import { BoxProgrammationOfferForm } from "@modules/boxProgrammation/components/boxProgrammationOfferForm"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import { Dispatch, SetStateAction } from "react"
import { useTheme } from "styled-components"

export const BoxProgrammationInfoForm: React.FC<{
  setLoadingImageUpload: Dispatch<SetStateAction<boolean>>
  isEditing: boolean
  formik: SimpleFormik<BoxProgrammationFormikType, BoxProgrammationInputKey>
}> = ({ setLoadingImageUpload, isEditing, formik }) => {
  const theme = useTheme()

  return (
    <HUStack gap={25}>
      <FormikInput
        flex
        formikStyle={{ display: "flex", flexGrow: 1 }}
        label={word("box.programmation.form.title.label")}
        name="title"
        isRequiredInput
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("title", formik)}
        setFieldValue={formik.setFieldValue}
        error={getFormErrorMessage("title", formik)}
        placeHolder={word("box.programmation.form.title.placeholder")}
      />

      <HUStack gap={0}>
        <HUText
          type="label"
          color={theme.neutralColor700}
          fontStyle="LS"
          style={{ lineHeight: "21px", marginBottom: 7 }}
        >
          {word("program.form.image.label").toUpperCase()}
        </HUText>
        <HURow className="flex align-items-start">
          <WarningHexagonalIcon color={theme.neutralColor600} width={24} height={24} />
          <HUText fontStyle="BS" className="mb-3 ml-2">
            {word("picture.warning.message")}
          </HUText>
        </HURow>
        <div className="border-round-lg">
          <FormikInput
            isRequiredInput={isEditing}
            type="image"
            imageProps={{
              type: "cover",
              placeHolder: {
                message: word("program.form.uploadImage.text1"),
              },
              showPreviewAsCover: true,
              prefixPath: "/programs",
              onStartUpload: () => setLoadingImageUpload(true),
              onEndUpload: () => setLoadingImageUpload(false),
            }}
            name="image"
            getFieldProps={formik.getFieldProps}
            setFieldValue={formik.setFieldValue}
            isInvalid={isFormFieldInValid("image", formik)}
            error={getFormErrorMessage("image", formik, { showAlways: true })}
          />
        </div>
      </HUStack>

      <FormikInput
        type="textarea"
        label={word("box.programmation.form.description.label")}
        name="description"
        isRequiredInput
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("description", formik)}
        error={getFormErrorMessage("description", formik)}
        setFieldValue={formik.setFieldValue}
        placeHolder={word("box.programmation.form.description.placeholder")}
        textareaProps={{ autoResize: true, rows: 10, style: { flexGrow: 1 } }}
      />

      <HUStack gap={0} className="mb-3">
        <HUText
          type="label"
          color={theme.neutralColor700}
          fontStyle="LS"
          style={{ lineHeight: "21px", marginBottom: 7 }}
        >
          {word("box.programmation.form.label.color").toUpperCase()}
          <HUText fontStyle="LL" color={theme.necessaryInput}>
            {" *"}
          </HUText>
        </HUText>
        <BoxProgrammationColorSelection
          selectedColor={formik.getFieldProps("color").value}
          onChangeSelectedColor={(color) => formik.setFieldValue("color", color)}
        />
      </HUStack>

      {/* <FormikInput
        type="switchInline"
        label={word("box.programmation.form.createDiscussionChannel.label")}
        textSwitch={word("box.programmation.form.createDiscussionChannel.textSwitch")}
        name="createDiscussionChannel"
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("createDiscussionChannel", formik)}
        error={getFormErrorMessage("createDiscussionChannel", formik)}
        setFieldValue={formik.setFieldValue}
      /> */}

      <FormikInput
        type="switchInline"
        label={word("box.programmation.form.hideSessionContent.label")}
        textSwitch={word("box.programmation.form.hideSessionContent.textSwitch")}
        description={word("box.programmation.form.hideSessionContent.description")}
        name="defaultHideSessionContent"
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("defaultHideSessionContent", formik)}
        error={getFormErrorMessage("defaultHideSessionContent", formik)}
        setFieldValue={formik.setFieldValue}
      />

      <BoxProgrammationOfferForm formik={formik} isEditing={isEditing} />
    </HUStack>
  )
}
