import { PromoCodeFormView } from "@modules/promoCode/components/promoCodeFormView"
import { PromoCodeInfoResume } from "@modules/promoCode/components/promoCodeInfoResume"
import { PromoCodeListView } from "@modules/promoCode/components/promoCodeListView"
import { PromoCode } from "@modules/promoCode/types/promoCodeTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { useTheme } from "styled-components"

export const usePromoCodeSidebar = () => {
  const { sidebarNavigateTo } = useSidebar()
  const theme = useTheme()

  const navigateToPromoCodeListView = () => {
    sidebarNavigateTo({
      content: <PromoCodeListView />,
      replaceAllComponents: true,
    })
  }

  const navigateToPromoCodeDetailsView = (promoCode: PromoCode, replaceLastComponent: boolean = false) => {
    sidebarNavigateTo({
      content: <PromoCodeInfoResume promoCode={promoCode} />,
      leftButtonColor: theme.mainWhite,
      replaceLastComponent,
    })
  }

  const navigateToPromoCodeFormView = (promoCode?: PromoCode) => {
    sidebarNavigateTo({
      content: <PromoCodeFormView updatedPromoCode={promoCode} />,
    })
  }

  return {
    navigateToPromoCodeListView,
    navigateToPromoCodeDetailsView,
    navigateToPromoCodeFormView,
  }
}
