import { AppBreadcrumb } from "@modules/app/components/appBreadCrumb"
import { AppConfig } from "@modules/app/components/appConfig"
import { AppMenu } from "@modules/app/components/menu/appMenu"
import { LayoutContext } from "@modules/app/context/layoutcontext"
import { MenuButtonsRef } from "@modules/app/types/layout"
import { ChildContainerProps } from "@modules/app/types/types"
import { AppBanner } from "@modules/appBanner/components/appBanner"
import { useEventListener, useUnmountEffect } from "primereact/hooks"
import { classNames } from "primereact/utils"
import { useCallback, useContext, useEffect, useRef } from "react"
import styled from "styled-components"

type Props = {
  ownerMenu?: boolean
} & ChildContainerProps

export const AppLayout: React.FC<Props> = ({ ownerMenu, children }) => {
  const { layoutState, setLayoutState, isDesktop } = useContext(LayoutContext)
  const topBarRef = useRef<MenuButtonsRef>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const SIDE_BAR_WIDTH = !layoutState.staticMenuDesktopInactive ? 240 : 75

  const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] = useEventListener({
    type: "click",
    listener: (event) => {
      const isOutsideClicked = !(
        sidebarRef.current?.isSameNode(event.target as Node) ||
        sidebarRef.current?.contains(event.target as Node) ||
        topBarRef.current?.menuBurgerButton?.isSameNode(event.target as Node) ||
        topBarRef.current?.menuBurgerButton?.contains(event.target as Node)
      )

      if (isOutsideClicked) {
        hideMenu()
      }
    },
  })

  const hideMenu = useCallback(() => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      overlayMenuActive: false,
      overlaySubmenuActive: false,
      staticMenuMobileActive: false,
    }))
  }, [isDesktop, setLayoutState])

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll")
    } else {
      document.body.className += " blocked-scroll"
    }
  }

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll")
    } else {
      document.body.className = document.body.className.replace(
        new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"),
        " ",
      )
    }
  }

  useEffect(() => {
    if (layoutState.overlayMenuActive || layoutState.staticMenuMobileActive || layoutState.overlaySubmenuActive) {
      bindMenuOutsideClickListener()
    }

    if (layoutState.staticMenuMobileActive) {
      blockBodyScroll()
    }

    return () => {
      unbindMenuOutsideClickListener()
      unblockBodyScroll()
    }
  }, [layoutState.overlayMenuActive, layoutState.staticMenuMobileActive, layoutState.overlaySubmenuActive])

  useUnmountEffect(() => {
    unbindMenuOutsideClickListener()
  })

  const containerClass = classNames({
    "layout-static-inactive": layoutState.staticMenuDesktopInactive,
    "layout-mobile-active": layoutState.staticMenuMobileActive,
  })

  return (
    <>
      <Container className={classNames("layout-container", "layout-static", containerClass)}>
        <div ref={sidebarRef} className="layout-sidebar" style={{ width: SIDE_BAR_WIDTH }}>
          <AppMenu ownerMenu={ownerMenu} />
        </div>
        <div className="layout-content-wrapper" style={{ padding: "unset" }}>
          <AppBanner />
        </div>
        <MainContainer className="layout-content-wrapper">
          <AppBreadcrumb topBarRef={topBarRef} />
          <Content className="layout-content">{children}</Content>
        </MainContainer>
        <AppConfig />
        {/* Overlay when menu appears with overlay */}
        <div className="layout-mask" />
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
