import { AladinChallengePage } from "@modules/aladinChallenge/page/aladinChallengePage"
import { AppLayout } from "@modules/app/appLayout"
import { useIsAuthenticated, useUser } from "@modules/auth/hooks/useUser"
import { ConfirmEmailPage } from "@modules/auth/page/confirmEmailPage"
import { LoginPage } from "@modules/auth/page/loginPage"
import { RegisterPage } from "@modules/auth/page/registerPage"
import { ResetPasswordPage } from "@modules/auth/page/resetPasswordPage"
import { SendCodePage } from "@modules/auth/page/sendCodePage"
import { SignUpPage } from "@modules/auth/page/signUpPage"
import { WelcomePage } from "@modules/auth/page/welcomePage"
import { BoxFormCreation } from "@modules/box/components/form/boxFormCreation"
import { useBoxs, useSelectedBoxId } from "@modules/box/useBox"
import { BoxOfferPage } from "@modules/boxOffer/pages/boxOfferPage"
import { BoxPlanningPage } from "@modules/boxPlanning/page/boxPlanningPage"
import { BoxProgrammationTemplatePage } from "@modules/boxProgrammation/page/boxProgrammationTemplatePage"
import { BoxProgrammationSessionsPage } from "@modules/boxProgrammationSessions/page/boxProgrammationSessionsPage"
import { ChatPage } from "@modules/chat/pages/ChatPage"
import { ChatAppWrapper } from "@modules/chat/services/chatAppWrapper"
import { EndUserLicenseAgreementPage } from "@modules/consumerAgreements/pages/endUserLicenseAgreementPage"
import { PrivacyPolicyPage } from "@modules/consumerAgreements/pages/privacyPolicyPage"
import { UseCharterPage } from "@modules/consumerAgreements/pages/useCharterPage"
import { useServicesContext } from "@modules/core/services/services.context"
import { DashboardPage } from "@modules/dashboard/page/DashboardPage"
import { ExplorePage } from "@modules/explore/page/explorePage"
import { BoxFinancesPage } from "@modules/finances/page/boxFinancesPage"
import { UserFinancesPage } from "@modules/finances/page/userFinancesPage"
import { MemberFormPage } from "@modules/members/pages/memberFormPage"
import { MembersPage } from "@modules/members/pages/membersPage"
import { Path } from "@modules/navigation/routes"
import { RouteNavigate } from "@modules/navigation/useBaseNavigate"
import { getBoxPath } from "@modules/navigation/useBoxNavigate"
import { getMySpacePath } from "@modules/navigation/useMySpaceNavigate"
import { BoxOnboardingStripe } from "@modules/owner/boxSettings/page/boxOnboardingStripe"
import { BoxOnboardingStripeReturn } from "@modules/owner/boxSettings/page/boxOnboardingStripeReturn"
import { BoxSettingsPage } from "@modules/owner/boxSettings/page/boxSettingsPage"
import { ProfilePage } from "@modules/profile/pages/profilePage"
import { ProgramDetailsPage } from "@modules/programs/page/programDetailsPage"
import { ProgramType } from "@modules/programs/programTypes"
import { HUSidebarProvider } from "@modules/sidebar/context/sidebarContext"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { TrainingPage } from "@modules/training/page/trainingPage"
import { TutorialsPage } from "@modules/tutorials/page/tutorialsPage"
import { useEffect, useState } from "react"
import { BrowserRouter, Navigate, Outlet, Route, Routes, useParams, useSearchParams } from "react-router-dom"

export const WebNavigation = () => {
  const isAuthenticated = useIsAuthenticated()
  const { serviceManager } = useServicesContext()
  useEffect(() => {
    if (isAuthenticated) {
      serviceManager.onLoggedIn()
    }
  }, [isAuthenticated])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/index.html" element={<Navigate to="/" replace />} />
        <Route path="/" element={<SpaceRedirection />} />
        <Route element={<AuthWrapper loggedOut />}>
          <Route path={Path.ConfirmEmail} element={<ConfirmEmailPage />} />
          <Route path={Path.Welcome} element={<WelcomePage />} />
          <Route path={Path.Login} element={<LoginPage />} />
          <Route path={Path.SignUp} element={<SignUpPage />} />
          <Route path={Path.Register} element={<RegisterPage />} />
          <Route path={Path.SendCode} element={<SendCodePage />} />
          <Route path={Path.ResetPassword} element={<ResetPasswordPage />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path={Path.MySpace.Root} element={<SpaceWrapper />}>
            <Route path="" element={<DashboardPage />} />
            <Route path={Path.MySpace.Finances} element={<UserFinancesPage />} />
            <Route path={Path.MySpace.Explore} element={<ExplorePage />} />
            <Route path={Path.MySpace.Profile} element={<ProfilePage />} />
            <Route path={Path.MySpace.Training} element={<TrainingPage />} />
            <Route path={Path.MySpace.ProgramDetails(":programId")} element={<ProgramDetailsPage />} />
            <Route path={Path.MySpace.ProgramOnOffDetails(":programId")} element={<ProgramDetailsPage />} />
            <Route path={Path.MySpace.Programs} element={<ProgramDetailsPage />} />
            <Route path={Path.MySpace.Tutorials} element={<TutorialsPage />} />
            <Route path={Path.Chat.Root} element={<ChatPage />} />
          </Route>
          <Route path={Path.Box.Root(":boxId")} element={<SpaceWrapper ownerMenu />}>
            <Route path="" element={<Navigate to={Path.Box.Planning} replace />} />
            <Route path={Path.Box.Finances} element={<BoxFinancesPage />} />
            <Route path={Path.Box.Profile} element={<ProfilePage />} />
            <Route path={Path.Box.Members} element={<MembersPage />} />
            <Route path={Path.Box.MemberForm(":id")} element={<MemberFormPage />} />
            <Route path={Path.Box.Offers} element={<BoxOfferPage />} />
            <Route path={Path.Box.Admin} element={<BoxSettingsPage />} />
            <Route path={Path.Chat.Root} element={<ChatPage />} />
            <Route path={Path.Box.Return} element={<BoxOnboardingStripeReturn />} />
            <Route path={Path.Box.Refresh} element={<OnboardingRedirection />} />
            <Route path={Path.Box.Planning} element={<BoxPlanningPage />} />
            <Route path={Path.Box.OnboardingStripe} element={<BoxOnboardingStripe />} />
            <Route path={Path.Box.ProgrammationDetails(":boxProgrammationId")} element={<BoxPlanningPage />} />
            <Route
              path={Path.Box.ProgrammationTemplate(":boxProgrammationId")}
              element={<BoxProgrammationTemplatePage />}
            />
            <Route
              path={Path.Box.ProgrammationSessions(":boxProgrammationId")}
              element={<BoxProgrammationSessionsPage />}
            />
          </Route>
          <Route path={Path.CreateBox} element={<BoxFormCreation />} />
        </Route>
        <Route path={Path.ProgramInvitation} element={<ProgramInvitationRedirection />} />
        <Route path={Path.BoxParticipation} element={<SpaceRedirection />} />
        <Route element={<ConsumerAgreements />}>
          <Route path={Path.PrivacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={Path.Eula} element={<EndUserLicenseAgreementPage />} />
          <Route path={Path.UseCharter} element={<UseCharterPage />} />
        </Route>
        <Route path={Path.AladinChallenge} element={<AladinChallengePage />} />
        <Route path="*" element={<SpaceRedirection />} />
      </Routes>
    </BrowserRouter>
  )
}

const AuthWrapper: React.FC<{ loggedOut?: boolean }> = ({ loggedOut }) => {
  const { profileService } = useServicesContext()
  const user = useUser()
  const isValid = loggedOut ? !user : !!user

  useEffect(() => {
    if (!user) profileService.setTheme("athlete")
  }, [loggedOut, user])

  return isValid ? <Outlet /> : <Navigate to={loggedOut ? Path.Root : Path.Welcome} />
}

const SpaceWrapper: React.FC<{ ownerMenu?: boolean }> = ({ ownerMenu }) => {
  const [rdy, setRdy] = useState(false)
  const params = useParams()
  const { currentSpaceManager, profileService } = useServicesContext()
  const { result: boxs, loading } = useBoxs()
  const isUserBox = !!boxs?.find((box) => box.id === params.boxId)

  useEffect(() => {
    if (!loading) {
      setRdy(true)
      if (params.boxId && isUserBox) {
        currentSpaceManager.set(params.boxId)
      } else {
        currentSpaceManager.set(undefined)
      }
    }
  }, [params.boxId, isUserBox, loading])

  useEffect(() => {
    profileService.setTheme(params.boxId ? "owner" : "athlete")
  }, [])

  if (!rdy) return null

  if (!loading && params.boxId && !isUserBox) {
    return <Navigate to={getMySpacePath(RouteNavigate.Root)} replace />
  }

  return (
    <AppLayout ownerMenu={ownerMenu}>
      <ChatAppWrapper>
        <HUSidebarProvider>
          <Outlet />
        </HUSidebarProvider>
      </ChatAppWrapper>
    </AppLayout>
  )
}

const SpaceRedirection = () => {
  const currentBoxId = useSelectedBoxId()
  if (currentBoxId) {
    return <Navigate to={getBoxPath(RouteNavigate.Root, { newBoxId: currentBoxId })} replace />
  }
  const programId = localStorage.getItem(LocalStorageKeys.space.redirectionProgramId) ?? ""
  const programType = localStorage.getItem(LocalStorageKeys.space.redirectionProgramType) ?? ""

  if (programId !== "") {
    localStorage.removeItem(LocalStorageKeys.space.redirectionProgramId)
    localStorage.removeItem(LocalStorageKeys.space.redirectionProgramType)

    const targetPath =
      programType === ProgramType.ONOFF
        ? Path.MySpace.ProgramOnOffDetails(programId)
        : Path.MySpace.ProgramDetails(programId)

    return <Navigate to={getMySpacePath(targetPath)} />
  }

  return <Navigate to={getMySpacePath(RouteNavigate.Root)} replace />
}

const OnboardingRedirection = () => {
  const params = useParams()
  if (params.boxId) {
    return <Navigate to={getBoxPath(Path.Box.Admin, { newBoxId: params.boxId })}></Navigate>
  }
  return <SpaceRedirection />
}

const ProgramInvitationRedirection = () => {
  const [searchParams] = useSearchParams()
  const programId = searchParams.get("programId") ?? ""
  const programType = searchParams.get("programType") ?? ""

  // Store the programId and programType in localStorage to redirect for the dynamic link
  localStorage.setItem(LocalStorageKeys.space.redirectionProgramId, programId)
  localStorage.setItem(LocalStorageKeys.space.redirectionProgramType, programType)

  if (programId != "" && programType === ProgramType.ONOFF) {
    return <Navigate to={getMySpacePath(Path.MySpace.ProgramOnOffDetails(programId))}></Navigate>
  } else if (programId != "" && (programType === ProgramType.VIRTUAL || !programType)) {
    return <Navigate to={getMySpacePath(Path.MySpace.ProgramDetails(programId))}></Navigate>
  }
  return <SpaceRedirection />
}

const ConsumerAgreements: React.FC = () => {
  const user = useUser()

  return user ? <SpaceWrapper /> : <Outlet />
}
