import { AuthContent } from "@modules/auth/components/authComponents"
import { AuthPage } from "@modules/auth/page/authPage"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { HUText } from "@modules/ui/components/huText"
import styled from "styled-components"

export const WelcomePage: React.FC = () => {
  return (
    <AuthPage>
      <AuthContent gap={15} className="text-center justify-content-center">
        <HUText fontStyle="TM">{word("auth.welcomeBack")}</HUText>
        <HUText fontStyle="BM">{word("auth.welcome.description")}</HUText>
        <HUNavigateButton
          to={Path.SignUp}
          label={word("auth.login.form.create_account")}
          buttonStyle={{ width: "100%" }}
        />
        <LoginButton to={Path.Login} label={word("auth.login.form.button.login")} buttonStyle={{ width: "100%" }} />
      </AuthContent>
    </AuthPage>
  )
}

const LoginButton = styled(HUNavigateButton)`
  .p-button {
    width: 100%;
    background-color: ${({ theme }) => theme.neutralColor200};
    border: none;
  }
  .p-button:not(button):not(a):not(.p-disabled):hover {
    background-color: ${({ theme }) => theme.neutralColor300}!important;
  }
`
