export const LocalStorageKeys = {
  // Commons
  auth: {
    user: "@auth/user",
  },
  featureToggle: {
    featureToggles: "@featureToggle/featureToggles",
  },
  language: {
    languageCode: "@profile/languageCode",
  },

  // Mobile only
  explore: { history: "@explore/history" },
  profile: {
    theme: "@profile/theme",
    unit: "@profile/unit",
  },
  notification: "@profile/notification",
  messaging: {
    config: "@messagin/config",
  },

  // Web only
  space: {
    currentBox: "@space/currentBox",
    redirectionProgramId: "@space/redirection/programId",
    redirectionProgramType: "@space/redirection/programType",
  },
  appBanner: {
    visibility: "@appBanner/visibility",
  },
  templatePopup: {
    checked: "@templatePopup/checked",
  },
  training: {
    training: "@training/training",
  },
  boxPlanning: {
    planning: "@boxPlanning/planning",
  },
  boxProgram: {
    defaultSettings: "@boxProgram/defaultSettings",
  },
  boxSlotTemplate: {
    dontShowOnBoardingAnymore: "@boxSlotTemplate/dontShowOnBoardingAnymore",
  },
}
