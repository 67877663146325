import { HUText } from "@modules/ui/components/huText"
import { word } from "@modules/core/utils/i18n"
import { StackedCards } from "@modules/ui/components/stackedCards"
import styled, { useTheme } from "styled-components"
import { HUButton } from "@modules/ui/components/huButton"
import { PlusIcon } from "@images/svgIcons/plusIcon"
import { BoxOfferTicketIcon } from "@images/svgIcons/boxOfferTicketIcon"
import { UnionIcon } from "@images/svgIcons/unionIcon"
import { CheckmarkIcon } from "@images/svgIcons/checkmarkIcon"
import { BoxOfferType } from "@modules/boxOffer/boxOfferTypes"
import { ReactNode } from "react"
import { BoxOfferPlanIcon } from "@images/svgIcons/boxOfferPlanIcon"
import { BoxOfferTrialIcon } from "@images/svgIcons/boxOfferTrialIcon"
import { BoxOfferDropInIcon } from "@images/svgIcons/boxOfferDropInIcon"
import { BoxOfferMillenniumIcon } from "@images/svgIcons/boxOfferMillenniumIcon"
import { useBoxOfferSidebar } from "@modules/boxOffer/hooks/useBoxOfferSidebar"

type BoxOfferCardsViewProps = {
  selectedType?: BoxOfferType
}

export const BoxOfferCardsView = ({ selectedType }: BoxOfferCardsViewProps) => {
  const { navigateToBoxOfferForm } = useBoxOfferSidebar()

  const cards = boxOffersInformation(selectedType).map((offer) => (
    <BoxOfferCard key={offer.type} {...offer} onClick={() => navigateToBoxOfferForm(offer.type)} />
  ))

  return (
    <div>
      <HUText fontStyle="TL">{word("box.offer.add.title")}</HUText>
      <StackedCards cards={cards} className="ml-4 mt-5" />
    </div>
  )
}

type BoxOfferCardConstructor = {
  advantages: string[]
  description: string
  onClick: () => void
  icon: ReactNode
  type: BoxOfferType
}

const BoxOfferCard = ({ icon, description, advantages, onClick, type }: BoxOfferCardConstructor) => {
  const theme = useTheme()

  return (
    <div className="flex flex-column align-items-center p-5 h-full gap-5">
      <div className="flex flex-column flex-auto align-items-center">
        {icon}
        <HUText fontStyle="TM">{word(`box.offer.form.types.${type}`)}</HUText>
        <HUText fontStyle="BM" color={theme.neutralColor700} className="mt-2 text-center">
          {description}
        </HUText>
      </div>
      <div className="flex flex-column align-items-center gap-2">
        <HUText fontStyle="TS" color={theme.neutralColor700}>
          {word("offer.form.advantages.subtitle")}
        </HUText>
        {advantages.map((advantage, index) => (
          <div key={`${index}-${advantage}`} className="flex align-items-center mt-2 gap-2 w-full">
            <CheckmarkIcon color={theme.mainWhite} />
            <HUText fontStyle="TS" color={theme.mainWhite}>
              {advantage}
            </HUText>
          </div>
        ))}
      </div>
      <HUButton
        type="Left_Icon"
        size="S"
        colorType="Primary"
        text={word(`offer.form.${type}.create`)}
        icon={{ icon: PlusIcon, iconSize: 14 }}
        onClick={onClick}
        className="w-full"
      />
      <BackgroundUnionIcon color={theme.mainWhite} />
    </div>
  )
}

const BackgroundUnionIcon = styled(UnionIcon)`
  position: absolute;
  left: 2.5rem;
  bottom: 0;
  z-index: -1;
  opacity: 0.15;
`

export const boxOffersInformation = (selectedType?: BoxOfferType) => {
  const offers = [
    {
      type: BoxOfferType.Subscription,
      advantages: [
        word("offer.form.advantages.subscription.advantage1"),
        word("offer.form.advantages.subscription.advantage2"),
        word("offer.form.advantages.subscription.advantage3"),
      ],
      description: word("offer.form.advantages.subscription.description"),
      icon: <BoxOfferPlanIcon color={""} />,
    },
    {
      type: BoxOfferType.TicketsBook,
      advantages: [
        word("offer.form.advantages.ticketsBook.advantage1"),
        word("offer.form.advantages.ticketsBook.advantage2"),
        word("offer.form.advantages.ticketsBook.advantage3"),
      ],
      description: word("offer.form.advantages.ticketsBook.description"),
      icon: <BoxOfferTicketIcon color={""} />,
    },
    {
      type: BoxOfferType.Trial,
      advantages: [
        word("offer.form.advantages.trial.advantage1"),
        word("offer.form.advantages.trial.advantage2"),
        word("offer.form.advantages.trial.advantage3"),
      ],
      description: word("offer.form.advantages.trial.description"),
      icon: <BoxOfferTrialIcon color={""} />,
    },
    {
      type: BoxOfferType.DropIn,
      advantages: [
        word("offer.form.advantages.dropIn.advantage1"),
        word("offer.form.advantages.dropIn.advantage2"),
        word("offer.form.advantages.dropIn.advantage3"),
      ],
      description: word("offer.form.advantages.dropIn.description"),
      icon: <BoxOfferDropInIcon color={""} />,
    },
    {
      type: BoxOfferType.Millenium,
      advantages: [
        word("offer.form.advantages.millenium.advantage1"),
        word("offer.form.advantages.millenium.advantage2"),
        word("offer.form.advantages.millenium.advantage3"),
      ],
      description: word("offer.form.advantages.millenium.description"),
      icon: <BoxOfferMillenniumIcon color={""} />,
    },
  ]

  if (!selectedType) {
    return offers
  }
  const selectedOffers = offers.filter((offer) => offer.type === selectedType)
  const otherOffers = offers.filter((offer) => offer.type !== selectedType)

  return selectedOffers.concat(otherOffers)
}
