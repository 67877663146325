import { CardioIcon } from "@images/svgIcons/cardioIcon"
import { ChronoIcon } from "@images/svgIcons/chronoIcon"
import { GymIcon } from "@images/svgIcons/gymIcon"
import { WeightliftingIcon } from "@images/svgIcons/weightliftingIcon"
import { ProgramCalendarEventType } from "@modules/calendar/calendarTypes"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { displayTotalTime, getTrainingChronoTitle } from "@modules/chrono/chronoUtils"
import { getDisplayExerciseCategory } from "@modules/exercises/exerciseUtils"
import { ProgramCalendarEventRestDay } from "@modules/programs/components/programCalendarEventRestDay"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"
import { Program } from "@modules/programs/programTypes"
import { DetailedSessionType } from "@modules/sessions/components/programSessionDetailsComponent"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { Linkify } from "@modules/ui/components/linkifyText"
import { useEffect, useMemo, useRef } from "react"
import styled, { useTheme } from "styled-components"

type ProgramCalendarEventProps = {
  index: number
  event: ProgramCalendarEventType | undefined
  displayDetailedEvents?: boolean
  onEventClick?: (arg: DetailedSessionType) => void
  isUpdating?: boolean
  isDetailsOpen?: boolean
  program: Program
}

export const ProgramCalendarEvent: React.FC<ProgramCalendarEventProps> = ({
  displayDetailedEvents = false,
  event,
  onEventClick,
  isUpdating = false,
  isDetailsOpen = false,
  program,
}) => {
  const { setDraggingEvent, setIsDragging } = useCalendar()
  const theme = useTheme()
  const programCalendarEventRef = useRef<HTMLDivElement>(null)
  const { isAuthor, isAcceptedSharedCoach } = useProgramRights(program)

  useEffect(() => {
    const programCalendarEvent = programCalendarEventRef.current

    const dragstart = () => {
      setIsDragging(true)
      setDraggingEvent(event)
    }

    programCalendarEvent?.addEventListener("dragstart", dragstart)
    return () => {
      programCalendarEvent?.removeEventListener("dragstart", dragstart)
    }
  }, [event, setDraggingEvent])

  const sessionHasStarted = useMemo(
    () => event && "date" in event && event.date && new Date() > new Date(event.date),
    [event],
  )

  const hideSessionContent = useMemo(() => {
    if (sessionHasStarted) {
      return false
    }

    return event?.hideSessionContent
  }, [event, sessionHasStarted])

  if (!event) return <></>

  const { id, title, exercises, cardioTag, gymnasticTag, weightliftingTag } = event
  const canUserSeeSessionContent = !hideSessionContent || isAuthor || isAcceptedSharedCoach
  const shouldDisplayTags = (cardioTag || gymnasticTag || weightliftingTag) && canUserSeeSessionContent

  return (
    <Container
      key={id}
      id={id}
      className={"border-bottom-1 p-2 gap-2" + (!!onEventClick ? " cursor-pointer" : "")}
      onClick={() =>
        !!onEventClick &&
        onEventClick({
          id,
          programId: program.id,
          programType: program._programType,
          date: "date" in event ? event.date : undefined,
        })
      }
      ref={programCalendarEventRef}
      $isDetailsOpen={isDetailsOpen}
      $isRestDay={"restDay" in event && event.restDay}
    >
      {"restDay" in event && event.restDay ? (
        <ProgramCalendarEventRestDay
          programName={program.title}
          dayIndex={event.dayIndex}
          duration={"duration" in program ? program.duration : 0}
        />
      ) : (
        <>
          <HURow className="justify-content-between align-items-center">
            <ProgramName
              fontStyle="TS"
              className="event-title"
              $isAuthor={isAuthor}
              $isAcceptedSharedCoach={isAcceptedSharedCoach}
            >
              {title}
            </ProgramName>
            {isUpdating && <i className="pi pi-spin pi-spinner w-1 h-1" />}
            {hideSessionContent && (
              <i style={{ fontSize: "1rem", color: theme.secondaryColor300 }} className={"pi pi-eye-slash"} />
            )}
          </HURow>

          {shouldDisplayTags && (
            <HURow className="justify-content-start align-items-center gap-2 mt-1">
              {gymnasticTag && <GymIcon color={"black"} width={30} height={"30"} />}
              {weightliftingTag && <WeightliftingIcon color={"black"} width={30} height={"30"} />}
              {cardioTag && <CardioIcon color={"black"} width={30} height={"30"} />}
            </HURow>
          )}

          {exercises.length > 0 && (
            <ExerciceStack $blur={!canUserSeeSessionContent}>
              {!canUserSeeSessionContent && (
                <div className="hidden-session-icon">
                  <i className={"pi pi-eye-slash"} />
                </div>
              )}
              {exercises.map((exercise, exerciseIndex) => (
                <HUStack gap={8} key={exerciseIndex}>
                  <HUText fontStyle="TS" className="event-category">
                    {getDisplayExerciseCategory(exercise.category)}
                  </HUText>
                  {exercise.chrono?.type && (
                    <div className="flex align-items-center">
                      <ChronoIcon color={theme.neutralColor700} width={14} height={14} />
                      <div className="flex flex-column">
                        <HUText fontStyle="BXS" className="event-content ml-2" color={theme.neutralColor700}>
                          {getTrainingChronoTitle(exercise.chrono.type)}
                        </HUText>
                        <HUText fontStyle="BXS" className="event-content ml-2" color={theme.neutralColor700}>
                          {displayTotalTime(exercise.chrono)}
                        </HUText>
                      </div>
                    </div>
                  )}
                  <HUText
                    fontStyle="BS"
                    numberOfLine={displayDetailedEvents ? undefined : 3}
                    style={{ whiteSpace: "break-spaces", textWrap: "pretty" }}
                    className="event-content"
                  >
                    <Linkify content={exercise.description} youtubeProps={{ allowEmbed: true }} />
                  </HUText>
                </HUStack>
              ))}
            </ExerciceStack>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div<{ $isDetailsOpen: boolean; $isRestDay: boolean }>`
  background-color: ${({ theme, $isDetailsOpen, $isRestDay }) =>
    $isDetailsOpen ? theme.primaryColor100 : $isRestDay ? theme.neutralColor100 : "transparent"};

  &:hover {
    .event-title {
      font-size: 1.05rem;
    }

    .hidden-session-icon i {
      color: ${({ theme }) => theme.secondaryColor300};
    }
  }
`

const ProgramName = styled(HUText)<{ $isAuthor: boolean; $isAcceptedSharedCoach: boolean }>`
  background-color: ${({ $isAuthor, $isAcceptedSharedCoach, theme }) =>
    $isAuthor || $isAcceptedSharedCoach ? theme.neutralColor100 : theme.secondaryColor};
  color: ${({ $isAuthor, $isAcceptedSharedCoach, theme }) =>
    $isAuthor || $isAcceptedSharedCoach ? theme.textPrimary : theme.mainWhite};
  padding: 3px 8px;
  border-radius: 15px;
  line-height: 1rem;
`

const ExerciceStack = styled(HUStack)<{ $blur: boolean }>`
  position: relative;
  margin-top: 0.5rem;

  .hidden-session-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & > i {
      color: ${({ theme }) => theme.secondaryColor200};
      font-size: 2rem;
    }
  }

  &::before {
    display: ${({ $blur }) => ($blur ? "block" : "none")};
    content: "";
    position: absolute;
    border-radius: 20px;
    margin: -5px;
    top: 0;
    left: 0;
    width: 105%;
    height: 105%;
    background: rgba(255, 255, 255, 0.23);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
`
