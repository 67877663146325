import { ChronoType } from "@modules/chrono/chronoTypes"
import { word } from "@modules/core/utils/i18n"
import { convertTimeStringToTimeValue, convertTimeValueToTimeString } from "@modules/duration/timeConverter"
import { ExerciseCategory, ExerciseDraft } from "@modules/exercises/exerciseTypes"
import { requiredInput } from "@modules/form/formUtils"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import * as Yup from "yup"

export type ChronoTypeFormik = ChronoType | "NONE"

export type ExerciseInputKey =
  | "id"
  | "title"
  | "category"
  | "description"
  | "chrono"
  | "scoreType"
  | "chronoType"
  | "chronoForTime"
  | "chronoTabata"
  | "chronoEmom"
  | "chronoAmrap"
  | "rpe"

export type ExerciseFormikType = {
  id: string | null
  title: string
  description: string
  category: ExerciseCategory
  chrono?: ChronoFormik
  chronoType: ChronoTypeFormik
  chronoForTime?: ForTimeChronoFormik
  chronoTabata?: TabataChronoFormik
  chronoEmom?: EmomChronoFormik
  chronoAmrap?: AmrapChronoFormik
  scoreType: ScoreType
  rpe: number | null
}

export function getExerciseValidationSchema() {
  return Yup.object().shape({
    description: requiredInput(Yup.string()),
    chronoForTime: Yup.object().shape({
      noLimit: Yup.boolean(),
      trainingTime: Yup.string().when("noLimit", (noLimit, schema) => {
        return noLimit[0] ? schema.nullable() : schema.notOneOf(["00min 00sec"], word("global.form.required"))
      }),
    }),
    chronoEmom: Yup.object().shape({
      trainingTime: Yup.string().notOneOf(["00min 00sec"], word("global.form.required")),
    }),
    chronoTabata: Yup.object().shape({
      trainingTime: Yup.string().notOneOf(["00min 00sec"], word("global.form.required")),
    }),
    chronoAmrap: Yup.object().shape({
      trainingTime: Yup.array().of(Yup.string().notOneOf(["00min 00sec"], word("global.form.required"))),
    }),
    rpe: Yup.number().nullable().min(1).max(10),
  })
}

export const convertExerciseDraftToFormik = (exerciseDraft: ExerciseDraft): ExerciseFormikType => {
  const chrono =
    exerciseDraft.chrono?.type === ChronoType.FOR_TIME
      ? {
          ...exerciseDraft.chrono,
          restTime: convertTimeValueToTimeString(exerciseDraft.chrono.restTime),
          trainingTime: convertTimeValueToTimeString(exerciseDraft.chrono.trainingTime),
          noLimit: exerciseDraft.chrono.noLimit,
        }
      : exerciseDraft.chrono?.type === ChronoType.AMRAP
        ? {
            ...exerciseDraft.chrono,
            restTime: exerciseDraft.chrono.restTime.map((restTime) => convertTimeValueToTimeString(restTime)),
            trainingTime: exerciseDraft.chrono.trainingTime.map((trainingTime) =>
              convertTimeValueToTimeString(trainingTime),
            ),
          }
        : exerciseDraft.chrono?.type === ChronoType.EMOM
          ? {
              ...exerciseDraft.chrono,
              restBetweenSetsTime: convertTimeValueToTimeString(exerciseDraft.chrono.restBetweenSetsTime),
              trainingTime: convertTimeValueToTimeString(exerciseDraft.chrono.trainingTime),
            }
          : exerciseDraft.chrono?.type === ChronoType.TABATA
            ? {
                ...exerciseDraft.chrono,
                restTime: convertTimeValueToTimeString(exerciseDraft.chrono.restTime),
                restBetweenSetsTime: convertTimeValueToTimeString(exerciseDraft.chrono.restBetweenSetsTime),
                trainingTime: convertTimeValueToTimeString(exerciseDraft.chrono.trainingTime),
              }
            : undefined

  return {
    ...exerciseDraft,
    chronoType: exerciseDraft.chrono?.type ?? "NONE",
    chrono,
    chronoForTime:
      exerciseDraft.chrono?.type === ChronoType.FOR_TIME
        ? {
            ...exerciseDraft.chrono,
            restTime: convertTimeValueToTimeString(exerciseDraft.chrono.restTime),
            trainingTime: convertTimeValueToTimeString(exerciseDraft.chrono.trainingTime),
            noLimit: exerciseDraft.chrono.noLimit,
            type: ChronoType.FOR_TIME,
          }
        : undefined,
    chronoAmrap:
      exerciseDraft.chrono?.type === ChronoType.AMRAP
        ? {
            ...exerciseDraft.chrono,
            restTime: exerciseDraft.chrono.restTime.map((restTime) => convertTimeValueToTimeString(restTime)),
            trainingTime: exerciseDraft.chrono.trainingTime.map((trainingTime) =>
              convertTimeValueToTimeString(trainingTime),
            ),
            type: ChronoType.AMRAP,
          }
        : undefined,
    chronoEmom:
      exerciseDraft.chrono?.type === ChronoType.EMOM
        ? {
            ...exerciseDraft.chrono,
            restBetweenSetsTime: convertTimeValueToTimeString(exerciseDraft.chrono.restBetweenSetsTime),
            trainingTime: convertTimeValueToTimeString(exerciseDraft.chrono.trainingTime),
            type: ChronoType.EMOM,
          }
        : undefined,
    chronoTabata:
      exerciseDraft.chrono?.type === ChronoType.TABATA
        ? {
            ...exerciseDraft.chrono,
            restTime: convertTimeValueToTimeString(exerciseDraft.chrono.restTime),
            restBetweenSetsTime: convertTimeValueToTimeString(exerciseDraft.chrono.restBetweenSetsTime),
            trainingTime: convertTimeValueToTimeString(exerciseDraft.chrono.trainingTime),
            type: ChronoType.TABATA,
          }
        : undefined,
  }
}

export const convertFormikToExerciseDraft = (exerciseFormik: ExerciseFormikType): ExerciseDraft => {
  return {
    ...exerciseFormik,
    chrono: convertChronoFormikToChronoDraft(exerciseFormik),
  }
}

export const convertChronoFormikToChronoDraft = (exerciseFormik: ExerciseFormikType) => {
  switch (exerciseFormik.chronoType) {
    case ChronoType.FOR_TIME:
      return exerciseFormik.chronoForTime
        ? {
            ...exerciseFormik.chronoForTime,
            restTime: convertTimeStringToTimeValue("mm:ss", exerciseFormik.chronoForTime.restTime) ?? 0,
            trainingTime: convertTimeStringToTimeValue("mm:ss", exerciseFormik.chronoForTime.trainingTime) ?? 0,
          }
        : undefined
    case ChronoType.AMRAP:
      return exerciseFormik.chronoAmrap
        ? {
            ...exerciseFormik.chronoAmrap,
            restTime: exerciseFormik.chronoAmrap.restTime.map(
              (restTime) => convertTimeStringToTimeValue("mm:ss", restTime) ?? 0,
            ),
            trainingTime: exerciseFormik.chronoAmrap.trainingTime.map(
              (trainingTime) => convertTimeStringToTimeValue("mm:ss", trainingTime) ?? 0,
            ),
          }
        : undefined
    case ChronoType.EMOM:
      return exerciseFormik.chronoEmom
        ? {
            ...exerciseFormik.chronoEmom,
            restBetweenSetsTime:
              convertTimeStringToTimeValue("mm:ss", exerciseFormik.chronoEmom.restBetweenSetsTime) ?? 0,
            trainingTime: convertTimeStringToTimeValue("mm:ss", exerciseFormik.chronoEmom.trainingTime) ?? 0,
          }
        : undefined
    case ChronoType.TABATA:
      return exerciseFormik.chronoTabata
        ? {
            ...exerciseFormik.chronoTabata,
            restTime: convertTimeStringToTimeValue("mm:ss", exerciseFormik.chronoTabata.restTime) ?? 0,
            restBetweenSetsTime:
              convertTimeStringToTimeValue("mm:ss", exerciseFormik.chronoTabata.restBetweenSetsTime) ?? 0,
            trainingTime: convertTimeStringToTimeValue("mm:ss", exerciseFormik.chronoTabata.trainingTime) ?? 0,
          }
        : undefined
    default:
      return undefined
  }
}

export type AmrapRoundAndRepScoreType = {
  round: number
  rep: number
}

export type ScoreFormikType = {
  value: string | number | AmrapRoundAndRepScoreType
  difficulty: "RX" | "SCALED"
  note: string
}

type BasicProps = {
  type: ChronoType
  trainingTime: string | string[]
  numberOfSets: number
}

export type ChronoFormik = ForTimeChronoFormik | AmrapChronoFormik | EmomChronoFormik | TabataChronoFormik

export type ForTimeChronoFormik = {
  type: ChronoType.FOR_TIME
  restTime: string
  trainingTime: string
  noLimit: boolean
} & BasicProps

export type AmrapChronoFormik = {
  type: ChronoType.AMRAP
  restTime: string[]
  trainingTime: string[]
} & BasicProps

export type EmomChronoFormik = {
  type: ChronoType.EMOM
  numberOfRounds: number
  restBetweenSetsTime: string
  trainingTime: string
} & BasicProps

export type TabataChronoFormik = {
  type: ChronoType.TABATA
  restTime: string
  restBetweenSetsTime: string
  numberOfRounds: number
  trainingTime: string
} & BasicProps
