import { HustleupSmallIcon } from "@images/svgIcons/hustleUpSmallIcon"
import { ItemBoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxProgrammationInformation } from "@modules/boxProgrammation/components/boxProgrammationInformation"
import { HURow } from "@modules/ui/components/layout"
import styled from "styled-components"

export const BoxPlanningHeaderRightView: React.FC<{
  selectedProgrammation: ItemBoxProgrammation | null
}> = ({ selectedProgrammation }) => {
  return (
    <Container
      className="mb-2 p-1 md:p-2 xl:p-4 mb-5  justify-content-evenly"
      $bgImage={selectedProgrammation?.image.path}
    >
      {selectedProgrammation ? (
        <BoxProgrammationInformation programmation={selectedProgrammation} />
      ) : (
        <div className="flex-grow-1"></div>
      )}
      {!selectedProgrammation && <HustleupSmallIconStyled />}
    </Container>
  )
}

const Container = styled(HURow)<{ $bgImage?: string }>`
  border-radius: 33px;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.secondaryColor900} 0%, ${theme.secondaryColor700} 100%)`};
  opacity: 1;
  overflow: hidden;
  position: relative;
  padding: 18px;
  &::before {
    background-image: ${({ $bgImage }) => `url(${$bgImage})`};
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(1) blur(1px);
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    border-radius: 33px;
  }
  border-width: 0px;
  color: ${({ theme }) => theme.mainWhite};
  height: 200px;
  max-height: 200px;
  > * {
    z-index: 1;
  }
`

const HustleupSmallIconStyled = styled(HustleupSmallIcon)`
  position: absolute;
  height: 120%;
  top: -10%;
  right: 0;
  opacity: ${({ theme }) => theme.financeCardBackgroundIconOpacity};
`
