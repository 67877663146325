import { Address, AddressDraft } from "@modules/address/addressTypes"
import { EmptyAddress } from "@modules/address/addressUtils"
import { StripeAccountStatus } from "@modules/payment/stripeTypes"
import { Profile } from "@modules/profile/profileTypes"
import { HUImage } from "@modules/types/imageTypes"
import { v4 as uuidv4 } from "uuid"

export const BoxTypeOf = "BOX"

export type PhoneNumberWithPrefix = {
  number: string
  countryCode: string
}

export type ItemBox = {
  _type: typeof BoxTypeOf
  id: string
  logo: HUImage
  cover: HUImage
  name: string
  address: Address
  phoneNumberWithPrefix: PhoneNumberWithPrefix
  email: string
}

export type Box = {
  _type: typeof BoxTypeOf
  id: string
  logo: HUImage
  boxPhotos: HUImage[]
  cover: HUImage
  name: string
  description: string
  address: Address
  phoneNumberWithPrefix: PhoneNumberWithPrefix
  email: string
  author: Profile
  siren: string
  isSubscribed: boolean
  stripeAccountStatus?: StripeAccountStatus
}

export type BoxDraft = {
  name: string
  address: AddressDraft
  phoneNumberWithPrefix: PhoneNumberWithPrefix
  email: string
  logo?: HUImage
  cover?: HUImage
  boxPhotos: HUImage[]
  siren: string
  description: string
}

export function convertBoxToDraft(box?: Box): BoxDraft {
  return {
    name: box?.name ?? "",
    address: box?.address ? box.address : EmptyAddress,
    phoneNumberWithPrefix: {
      number: box?.phoneNumberWithPrefix.number ?? "",
      countryCode: box?.phoneNumberWithPrefix.countryCode ?? "",
    },
    email: box?.email ?? "",
    logo: box?.logo,
    cover: box?.cover,
    boxPhotos:
      box?.boxPhotos.map((image) => ({
        id: uuidv4(),
        ...image,
      })) ?? [],
    siren: box?.siren ?? "",
    description: box?.description ?? "",
  }
}
