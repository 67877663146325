import { BoxProgrammation, BoxProgrammationDefaultSettings } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BOX_SLOT_PARTICIPANTS_DEFAULT_VALUE } from "@modules/boxSlot/boxSlotTypes"
import {
  BoxSlotTemplateFormikType,
  convertBoxSlotTemplateDraftToFormik,
  convertBoxSlotTemplateFormFormik,
  getValidationSchema,
} from "@modules/boxSlotTemplate/boxSlotTemplateFormikType"
import { BoxSlotTemplate, Weekdays } from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { useToast } from "@modules/ui/components/huToast"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { Dayjs } from "dayjs"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

export const useBoxSlotTemplateFormik = (
  boxProgrammation: BoxProgrammation,
  boxSlotTemplate: BoxSlotTemplate | null,
  dateToCreateSlotTemplateOn?: Dayjs,
  defaultSettings?: BoxProgrammationDefaultSettings | null,
) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { boxSlotTemplateService } = useServicesContext()
  const { hideSidebar } = useSidebar()
  const toast = useToast()
  const confirmPopup = useConfirmPopup()
  const isEdition = !!boxSlotTemplate

  const confirmEditionBoxSlotTemplate = (boxSlotTemplate: BoxSlotTemplate) => {
    confirmPopup.show({
      title: word("programmation.slotTemplates.edition_confirmation_title"),
      message: word("programmation.slotTemplates.edition_confirmation_message"),
      accept: async () => {
        await boxSlotTemplateService.updateBoxSlotTemplate(
          boxProgrammation.id,
          boxSlotTemplate.id,
          convertBoxSlotTemplateFormFormik(formik.values),
        )
        hideSidebar()
      },
      footerProps: {
        align: true,
      },
    })
  }

  const formik = useFormik<BoxSlotTemplateFormikType>({
    initialValues: convertBoxSlotTemplateDraftToFormik(
      boxSlotTemplate
        ? boxSlotTemplate
        : dateToCreateSlotTemplateOn
          ? {
              startTime: dateToCreateSlotTemplateOn.format("HH:mm:ss"),
              endTime: dateToCreateSlotTemplateOn.add(defaultSettings?.duration ?? 60, "minute").format("HH:mm:ss"),
              weekDay: Weekdays[dateToCreateSlotTemplateOn.day()],
              participantLimit: defaultSettings?.participantLimit ?? BOX_SLOT_PARTICIPANTS_DEFAULT_VALUE,
              location: defaultSettings?.location,
              participation: {
                limitStart: defaultSettings?.participation?.limitStart,
                limitEnd: defaultSettings?.participation?.limitEnd,
                limitCancel: defaultSettings?.participation?.limitCancel,
              },
            }
          : null,
      boxProgrammation,
    ),
    validationSchema: getValidationSchema(),
    enableReinitialize: true,
    onSubmit: async () => {
      setIsSubmitting(true)
      try {
        if (isEdition && boxSlotTemplate) {
          confirmEditionBoxSlotTemplate(boxSlotTemplate)
        } else {
          await boxSlotTemplateService.createBoxSlotTemplate(
            boxProgrammation.id,
            convertBoxSlotTemplateFormFormik(formik.values),
          )
          toast.show({ severity: "success", detail: word("box.slotTemplate.form.toast.success") })
          hideSidebar()
        }
      } catch (e: any) {
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      } finally {
        setIsSubmitting(false)
      }
    },
  })

  useEffect(() => {
    // add the created slots (overlayed) to the calendar in background
    boxSlotTemplateService.updateBoxSlotTemplatesBeingCreated([
      { id: boxSlotTemplate?.id, ...convertBoxSlotTemplateFormFormik(formik.values) },
    ])
    return () => {
      // clear the overlayed slots in calendar
      boxSlotTemplateService.updateBoxSlotTemplatesBeingCreated([])
    }
  }, [formik.values.startDateTime, formik.values.endDateTime])

  return {
    formik,
    isSubmitting,
  }
}
