import { ILocalStorage, LocalStorageData } from "@modules/storage/services/ILocalStorage"

export class WebStorage implements ILocalStorage {
  private storage = localStorage

  load(key: string): LocalStorageData<string> | null {
    const serialized = this.storage.getItem(key)
    return serialized ? (JSON.parse(serialized) as LocalStorageData<string>) : null
  }

  save(key: string, value: LocalStorageData<string>) {
    return this.storage.setItem(key, JSON.stringify(value))
  }

  remove(key: string) {
    return this.storage.removeItem(key)
  }

  multiRemove(keys: string[]) {
    for (const key of keys) {
      this.remove(key)
    }
  }
}
