import { word } from "@modules/core/utils/i18n"
import { ModelChrono } from "@modules/exercises/components/modelChrono"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import styled, { useTheme } from "styled-components"

export const ChronoDetail: React.FC = () => {
  const theme = useTheme()

  return (
    <>
      <HUStack gap={10}>
        <HUStackContainer gap={4} className="mb-4">
          <HUText fontStyle="HS">{word("program.planning.create.exercise.addChrono.titleHeader")}</HUText>
          <HUText fontStyle="BS" color={theme.neutralColor600}>
            {word("program.planning.create.exercise.addChrono.descriptionHeader")}
          </HUText>
        </HUStackContainer>
      </HUStack>
      <ModelChrono />
    </>
  )
}

const HUStackContainer = styled(HUStack)`
  max-width: 90%;
`
