import { word } from "@modules/core/utils/i18n"
import { MAX_SIZE, readFile, resizeImage } from "@modules/ui/components/fileUpload/fileUploadUtils"
import { useToast } from "@modules/ui/components/huToast"
import { CameraIcon } from "@modules/ui/icons/cameraIcon"
import { FileUploadProps, FileUpload as PRFileUpload } from "primereact/fileupload"
import { ProgressSpinner } from "primereact/progressspinner"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import styled from "styled-components"

export type FileUploadRef = {
  click: () => void
}

type Props = {
  setImage: (v: { localBase64: string; localPath: string }) => void
  showUploadButton?: boolean
  onStartUpload?: () => void
  onEndUpload?: () => void
} & FileUploadProps

export const FileUploadAvatar = forwardRef<FileUploadRef, Props>(
  (
    { setImage, name, showUploadButton = true, onStartUpload, onEndUpload, ...props },
    fileUploadRef, /// <reference path="" />
  ) => {
    const toast = useToast()
    const ref = useRef<PRFileUpload>(null)
    const [loading, setLoading] = useState(false)

    useImperativeHandle(fileUploadRef, () => ({
      click: () => ref.current?.getInput().click(),
    }))

    return (
      <PRFileUpload
        ref={ref}
        mode="basic"
        name={name}
        accept="image/JPEG, image/PNG, image/BMP, image/WEBMP, image/GIF"
        disabled={loading}
        className={`${showUploadButton ? `` : `hidden`}`}
        onSelect={async (e) => {
          try {
            setLoading(true)
            onStartUpload?.()
            const newImage = e.files[0]
            const resizedImage = await resizeImage(newImage)

            if (resizedImage.size > MAX_SIZE) {
              toast.show({
                severity: "error",
                summary: word("global.error.label"),
                detail: word("file_upload.max_size.error"),
              })
              ref.current?.clear()
              return
            }
            const result = await readFile(resizedImage)
            if (result) {
              setImage({ localPath: URL.createObjectURL(resizedImage), localBase64: result })
            } else {
              toast.show({
                severity: "error",
                summary: word("global.error.label"),
                detail: word("global.fileUpload.error"),
              })
            }
          } catch (e: any) {
            toast.show({
              severity: "error",
              summary: word("global.error.label"),
              detail: word("global.fileUpload.error"),
            })
            console.error(`[FileUploadAvatar] ${e.message ?? word("global.error.label")}`)
          } finally {
            setLoading(false)
            onEndUpload?.()
          }
          ref.current?.clear()
        }}
        chooseOptions={{
          className: "p-button-avatar p-button-icon-only p-button-rounded",
          icon: loading ? (
            <ProgressSpinnerStyled className="p-button-icon-left" strokeWidth="5" />
          ) : (
            <CameraIcon color="white" />
          ),
          style: { height: 42, width: 42 },
        }}
        {...props}
      />
    )
  },
)

export const ProgressSpinnerStyled = styled(ProgressSpinner)`
  width: 16px;
  height: 16px;
  stroke: var(--surface-a);
  .p-progress-spinner-circle {
    stroke: white;
    animation: none;
  }
`
