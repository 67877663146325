import _ from "lodash"

export class Lock {
  private promise: Promise<void> | null = null

  private resolve?: (value: void | PromiseLike<void>) => void
  private reject?: (reason?: any) => void

  create() {
    if (this.promise !== null) {
      throw Error("cannot create lock: lock already exists")
    }
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  exist() {
    return this.promise !== null
  }

  isReleased(): Promise<void> {
    if (this.promise) {
      return this.promise
    } else {
      return Promise.resolve()
    }
  }

  release(error: any = null): void {
    // Forward error to the next promise for tracking
    if (!_.isNull(error)) {
      this.reject?.(error)
    } else {
      this.resolve?.()
    }
    this.promise = null
  }
}
