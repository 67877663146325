import { PercentageIcon } from "@images/svgIcons/percentageIcon"
import { word } from "@modules/core/utils/i18n"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { usePromoCodeSidebar } from "@modules/promoCode/hooks/usePromoCodeSidebar"
import { PromoCode } from "@modules/promoCode/types/promoCodeTypes"
import {
  getFormattedPromoCodeExpiration,
  getFormattedPromoCodeReduction,
} from "@modules/promoCode/utils/promoCodeUtils"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import { shortenText } from "@modules/utils/stringUtils"
import dayjs from "dayjs"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

export const PromoCodeListItem: React.FC<{ promoCode: PromoCode }> = ({ promoCode }) => {
  const { navigateToPromoCodeDetailsView } = usePromoCodeSidebar()
  const language = useLanguage()
  const theme = useTheme()

  const promoCodeDescription = useMemo(() => {
    return `${getFormattedPromoCodeReduction(promoCode, language)} / ${getFormattedPromoCodeExpiration(promoCode)}`
  }, [promoCode])

  const isPast = useMemo(() => {
    return (
      promoCode.archived ||
      (promoCode.expirationDate ? dayjs(promoCode.expirationDate).isBefore(dayjs().format(defaultDateFormat)) : false)
    )
  }, [promoCode.expirationDate])

  return (
    <Container onClick={() => navigateToPromoCodeDetailsView(promoCode)}>
      <HURow gap={10} className="align-items-center">
        <PercentageIconContainer $isPast={isPast}>
          <PercentageIcon color={isPast ? theme.neutralColor : theme.secondaryColor100} />
        </PercentageIconContainer>
        <HUStack gap={0}>
          <HUText fontStyle="LL">{shortenText(promoCode.title, 18)}</HUText>
          <HUText fontStyle="BS">{promoCodeDescription}</HUText>
        </HUStack>
      </HURow>
      <HUStack gap={0} className="align-items-end">
        <HUText fontStyle="LM">{promoCode.code}</HUText>
        {promoCode.archived && promoCode.archivedStatus ? (
          <HUText color={theme.neutralColor600} fontStyle="BS">
            {word(`promoCode.archivedStatus.${promoCode.archivedStatus}`)}
          </HUText>
        ) : (
          isPast && (
            <HUText color={theme.neutralColor600} fontStyle="BS">
              {word(`promoCode.archivedStatus.EXPIRED`)}
            </HUText>
          )
        )}
      </HUStack>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 7px;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${({ theme }) => theme.neutralColor100};
  }
`

const PercentageIconContainer = styled.div<{ $isPast: boolean }>`
  min-width: 46px;
  min-height: 46px;
  background-color: ${({ $isPast, theme }) => ($isPast ? theme.neutralColor200 : theme.svgDefaultColor)};
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`
