import { useBoxSlotSidebar } from "@modules/boxSlot/hooks/useBoxSlotSidebar"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ChronoDetail } from "@modules/exercises/components/chronoDetail"
import { ExerciseFormInfos } from "@modules/exercises/components/exercisesForms"
import { ExerciseFormikType } from "@modules/exercises/exerciseFormikTypes"
import { ProgramSessionFormView } from "@modules/programSession/components/programSessionFormView"
import {
  DetailedSessionType,
  ProgramSessionDetailsComponent,
} from "@modules/sessions/components/programSessionDetailsComponent"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"

export const useSessionSidebar = () => {
  const { sidebarNavigateTo, hideSidebar, isSidebarVisible, sidebarNavigateBack } = useSidebar()
  const { navigateToBoxSlotView } = useBoxSlotSidebar()
  const { boxSlotService } = useServicesContext()
  const {
    setInitialUpdatedExercise,
    setUpdatedExerciseIndex,
    setIsEditingExercise,
    onHideSessionForm,
    onHideExerciseForm,
    onHideChronoForm,
    setEditedSession,
    setEditedSlot,
    setDateSession,
  } = useSessionFormContext()

  const navigateToSessionView = (
    session: DetailedSessionType,
    refreshCalendarEvents?: () => void,
    onHide?: () => Promise<boolean>,
  ) => {
    return sidebarNavigateTo({
      content: (
        <ProgramSessionDetailsComponent
          editedSession={session}
          allowEditContent={true}
          refreshTraining={refreshCalendarEvents}
        />
      ),
      replaceAllComponents: true,
      onHide,
    })
  }

  const navigateToSessionForm = (
    isCreation: boolean = true,
    replaceAllComponents: boolean = true,
    refreshCalendarEvents?: () => void,
    boxProgrammationId?: string,
    slotId?: string,
  ) => {
    return sidebarNavigateTo({
      content: (
        <ProgramSessionFormView
          isCreation={isCreation}
          onFinish={() => {
            setEditedSession(undefined)
            setDateSession(undefined)
            hideSidebar(false)
            setEditedSlot(undefined)
            refreshCalendarEvents && refreshCalendarEvents()
            boxSlotService.updateBoxSlotsBeingViewed([])
          }}
        />
      ),
      leftButtonText: word("global.return"),
      leftButtonAction: () => {
        if (slotId && boxProgrammationId && refreshCalendarEvents)
          navigateToBoxSlotView(boxProgrammationId, slotId, refreshCalendarEvents, "session")
        return Promise.resolve(false)
      },
      replaceAllComponents,
      onHide: () => {
        return onHideSessionForm.current()
      },
    })
  }

  const navigateToExerciseForm = (
    isCreation: boolean,
    updatedExerciseIndex: number | null,
    initialUpdatedExercise?: ExerciseFormikType,
  ) => {
    setIsEditingExercise(!isCreation)
    if (initialUpdatedExercise) setInitialUpdatedExercise(initialUpdatedExercise)
    if (typeof updatedExerciseIndex !== "undefined") setUpdatedExerciseIndex(updatedExerciseIndex)

    sidebarNavigateTo({
      content: <ExerciseFormInfos />,
      leftButtonText: word("program.planning.create.exercise.returnSession"),
      onHide: () => {
        return onHideExerciseForm.current()
      },
    })
  }

  const navigateToChronoForm = () => {
    sidebarNavigateTo({
      content: <ChronoDetail />,
      leftButtonText: word("program.planning.create.exercise.returnExercise"),
      onHide: () => {
        return onHideChronoForm.current()
      },
    })
  }

  return {
    navigateToSessionView,
    navigateToSessionForm,
    navigateToExerciseForm,
    navigateToChronoForm,
    sidebarNavigateBack,
    isSidebarVisible,
  }
}
