export const getCurrencySymbol = (currency: Currency, locale: string): string => {
  return (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim()
}

export const formatCurrency = (value: number, currency: Currency, locale: string): string => {
  return value.toLocaleString(locale, { style: "currency", currency })
}

export const currencySymbol = (currency?: Currency, locale?: string): string => {
  const number = 0
  return number.toLocaleString(locale, { style: "currency", currency }).replace(/\d./g, "").trim()
}

export const allCurrencies = ["EUR" /* "USD" */] as const // La gestion de plusieurs subscriptions de devises différentes n'est pas géré par stripe

export type Currency = (typeof allCurrencies)[number]
