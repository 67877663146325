import SessionHideEmpty from "@images/empty/session_hide_empty.svg"
import { CardioIcon } from "@images/svgIcons/cardioIcon"
import { CopyIcon } from "@images/svgIcons/copyIcon"
import { GymIcon } from "@images/svgIcons/gymIcon"
import { TrashIcon } from "@images/svgIcons/trashIcon"
import { WeightliftingIcon } from "@images/svgIcons/weightliftingIcon"
import { useUser } from "@modules/auth/hooks/useUser"
import { useCurrentBox } from "@modules/box/useBox"
import { BoxSlotSession, BoxSlotTypeOf } from "@modules/boxSlot/boxSlotTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ExerciseDetail } from "@modules/exercises/components/exerciseDetail"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { EllipsisMenu } from "@modules/ui/components/ellipsisMenu"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { EditIcon } from "@modules/ui/icons/editIcon"
import { MenuItem } from "primereact/menuitem"
import React from "react"
import { useTheme } from "styled-components"

type BoxSlotSessionContentDetailsProps = {
  boxSlot: BoxSlotSession
  onEditSessionContent: () => void
}

export const BoxSlotSessionContentDetails: React.FC<BoxSlotSessionContentDetailsProps> = ({
  boxSlot,
  onEditSessionContent,
}) => {
  const { result: box } = useCurrentBox()
  const { programSessionService, boxSlotService } = useServicesContext()
  const theme = useTheme()
  const { hideSidebar } = useSidebar()
  const user = useUser()
  const toast = useToast()
  const confirmPopup = useConfirmPopup()

  const boxSession = boxSlot.session

  if (!boxSession) return

  // const scoreExercicesRequested = useMemo(() => {
  //   const listScoreExercicesRequest = boxSession.exercises.filter(
  //     (exercise) => exercise.scoreType !== ScoreType.NO_SCORE,
  //   )
  //   return listScoreExercicesRequest
  // }, [boxSession])

  // const hideSessionContent = useMemo(() => {
  //   const sessionHasStarted = boxSlot.startDateTime && new Date() > new Date(boxSlot.startDateTime)

  //   if (sessionHasStarted) {
  //     return false
  //   }

  //   return boxSession.hideSessionContent
  // }, [boxSlot])

  // const isAllScoresCompleted = useMemo(
  //   () =>
  //     scoreExercicesRequested.every((exercise) => {
  //       return exercise.score?.value
  //     }),
  //   [scoreExercicesRequested],
  // )

  const onSessionCopy = () => {
    try {
      programSessionService.setCopySession(boxSession)

      toast.show({
        severity: "success",
        summary: word("program.session.copy.success"),
      })
    } catch (e: any) {
      toast.show({
        severity: "error",
        summary: word("program.session.copy.error"),
      })
    }
  }

  const onDeleteSessionConfirmationPopup = () => {
    confirmPopup.show({
      title: word("sessionDetailed.deleteSession.popup.title"),
      message: word("boxSessionDetailed.deleteSession.popup.message", {
        sessionName: boxSession.name,
      }),
      accept: async () => {
        boxSession && (await boxSlotService.deleteSession(boxSession.id))
        hideSidebar()
        // if (refreshTraining) refreshTraining()
      },
      footerProps: {
        align: true,
      },
    })
  }

  const ellipsisItems: MenuItem[] = [
    {
      items: [
        {
          label: word("global.edit"),
          icon: <EditIcon color={theme.neutralColor900} />,
          command: () => {
            onEditSessionContent()
          },
        },
        {
          label: word("program.session.copy.label"),
          icon: <CopyIcon color={theme.neutralColor900} width={20} height={20} />,
          command: () => {
            onSessionCopy()
          },
        },
        {
          label: word("global.delete"),
          icon: <TrashIcon color={theme.neutralColor900} width={20} height={20} />,
          command: () => {
            onDeleteSessionConfirmationPopup()
          },
        },
      ],
    },
  ]

  const isAuthor = user?.id === boxSession.owner.id
  const canUserEditSession = isAuthor
  const canUserSeeSessionContent = true // TODO manage permissions

  return (
    <div className="mt-5">
      <div className="flex flex-1 justify-content-end gap-2 mb-2">
        {canUserEditSession && <EllipsisMenu items={ellipsisItems} />}
      </div>
      {!canUserSeeSessionContent ? (
        <EmptyView
          image={{ src: SessionHideEmpty }}
          title={word("session.empty.title.hideSession")}
          description={word("session.empty.description.hideSession")}
          flexGrow={false}
          className="p-6"
          style={{ backgroundColor: theme.neutralColor100 }}
          button={
            <HUButton
              type="Default"
              size="M"
              colorType="Primary"
              text={word("global.button.wait")}
              onClick={() => hideSidebar()}
            />
          }
          paddingType="In_table"
        />
      ) : (
        <HUStack>
          <div className="flex mt-2">
            {boxSession.gymnasticTag && (
              <div className="flex flex-column align-items-center">
                <GymIcon color={"black"} className="mr-2 mb-1" width={30} height={30} />
              </div>
            )}
            {boxSession.weightliftingTag && (
              <div className="flex flex-column align-items-center">
                <WeightliftingIcon color={"black"} className="mr-2 mb-1" width={30} height={30} />
              </div>
            )}
            {boxSession.cardioTag && (
              <div className="flex flex-column align-items-center">
                <CardioIcon color={"black"} className="mb-1" width={30} height={30} />
              </div>
            )}
          </div>
          {boxSession.description && (
            <HUText fontStyle="BM" style={{ whiteSpace: "pre-line", marginTop: "10px" }}>
              {boxSession.description}
            </HUText>
          )}
          {/* {scoreExercicesRequested.length > 0 && (!hideSessionContent || isAuthor) && (
            <ScoreboardCard
              onClick={() => onClickOnScoreboard()}
              program={program}
              isAuthor={isAuthor}
              canUserAccessToScoreboard={isAllScoresCompleted}
            />
          )} */}

          {canUserSeeSessionContent &&
            boxSession.exercises.map((exercise, index) => {
              return (
                <div key={exercise.id} className={index > 0 ? "border-top-1 surface-border " : ""}>
                  <ExerciseDetail
                    exercise={exercise}
                    parentSessionOrigin={{
                      parentType: BoxSlotTypeOf,
                      boxId: box?.id || "",
                      slotId: boxSlot.id,
                      programmationId: boxSlot.programmationId,
                      sessionId: boxSession.id,
                    }}
                  />
                </div>
              )
            })}
        </HUStack>
      )}
    </div>
  )
}
