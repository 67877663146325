import { BoxFormPageProps } from "@modules/box/components/form/boxFormFirstPage"
import { BoxFormTemplatePage } from "@modules/box/components/form/boxFormTemplatePage"
import { word } from "@modules/core/utils/i18n"
import { FormikInput } from "@modules/ui/components/formikInput"
import { useEffect } from "react"

export const BoxFormThirdPage = ({
  handleSubmit,
  onPrevious,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  disabled,
}: BoxFormPageProps) => {
  useEffect(() => {
    if (formik.values.country && !formik.values.phoneNumberCountryCode)
      formik.setFieldValue("phoneNumberCountryCode", formik.values.country)
  }, [formik.values.country])

  return (
    <BoxFormTemplatePage
      onPrevious={onPrevious}
      handleSubmit={handleSubmit}
      disabled={disabled}
      pageNumber={3}
      header={{ title: word("box.creation.page.3.title") }}
    >
      <FormikInput
        isRequiredInput
        label={word("box.form.phone.label")}
        isInvalid={isFormFieldInValid("phoneNumber")}
        getFieldProps={formik.getFieldProps}
        name="phoneNumber"
        error={getFormErrorMessage("phoneNumber")}
        type="phoneNumber"
        setFieldValue={formik.setFieldValue}
        placeHolder={word("box.form.phone.placeholder")}
        phoneNumberProps={{
          phoneNumber: formik.getFieldProps("phoneNumber").value,
          countryCode: formik.getFieldProps("phoneNumberCountryCode").value,
          onPhoneNumberBlur: (e) => {
            if (formik.handleBlur) formik.handleBlur(e, "phoneNumber")
          },
          onPhoneNumberChange: (e) => {
            if (formik.handleChange) formik.handleChange(e, "phoneNumber")
          },
          onPhoneCodeChange: (e) => {
            formik.setFieldValue("phoneNumberCountryCode", e.target.value)
          },
        }}
      />
      <FormikInput
        isRequiredInput
        flex
        label={word("box.form.email.label")}
        isInvalid={isFormFieldInValid("email")}
        getFieldProps={formik.getFieldProps}
        name="email"
        error={getFormErrorMessage("email")}
        textProps={{
          className: "w-full",
          onBlur: (e) => {
            if (formik.handleBlur) formik.handleBlur(e, "email")
          },
          onChange: (e) => {
            if (formik.handleChange) formik.handleChange(e, "email")
          },
        }}
      />
    </BoxFormTemplatePage>
  )
}
