import { WarningIcon } from "@images/svgIcons/warningIcon"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ProgramOnOff } from "@modules/programs/programOnOffTypes"
import { Circles } from "@modules/ui/components/circles"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { useState } from "react"
import styled, { useTheme } from "styled-components"

type FillRateProps = {
  program: ProgramOnOff
  editionAllowed: boolean
}

export const FillRate: React.FC<FillRateProps> = ({ program, editionAllowed }) => {
  const [isPublishing, setIsPublishing] = useState(false)

  const { programOnOffService } = useServicesContext()
  const theme = useTheme()
  const toast = useToast()

  const publishProgram = async () => {
    try {
      setIsPublishing(true)

      await programOnOffService.publishProgramOnOff(program.id)

      toast.show({
        severity: "success",
        summary: word("program.onOff.publish.success"),
      })
    } catch (e: any) {
      toast.show({ severity: "error", summary: "Error", detail: e.message })
    } finally {
      setIsPublishing(false)
    }
  }

  const calculFillRatePoints = () => {
    const fillRate = (program.sessionCount / program.duration) * 100
    const fillRatePoints = Math.round(fillRate / 10)

    return Math.min(10, Math.max(0, fillRatePoints))
  }

  return (
    <CardContainer>
      <div className="flex align-items-center">
        <WarningIcon color={theme.neutralColor900} width={24} height={24} className="mr-2" />
        <HUText fontStyle="TM">{word("program.onOff.fillRate.title")}</HUText>
      </div>

      <HUText fontStyle="BM">{word("program.onOff.fillRate.description")}</HUText>
      <HUText fontStyle="BM">
        {`${program.sessionCount}/${program.duration}`} {word("global.day.label", { count: program.duration })}
      </HUText>
      <Circles count={10} filled={calculFillRatePoints()} progressionstyle="line" />
      {editionAllowed && (
        <HUButton
          type="Default"
          size="M"
          colorType="Primary"
          disabled={program.sessionCount !== program.duration}
          style={{ width: "100%" }}
          text={word("program.button.publish")}
          onClick={publishProgram}
          loading={isPublishing}
        />
      )}
    </CardContainer>
  )
}

const CardContainer = styled(HUStack)`
  align-items: flex-start;
  padding: 22px;
  gap: 18px;
  align-self: stretch;
  border-radius: 33px;
  border: ${({ theme }) => `1px solid ${theme.neutralColor200}`};
  margin-bottom: 20px;
`
