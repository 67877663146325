import { word } from "@modules/core/utils/i18n"
import * as Yup from "yup"

export enum ScoreType {
  REPETITIONS = "REPS",
  ROUND_AND_REPETITIONS = "ROUNDS_AND_REPS",
  WEIGHT = "WEIGHT",
  TIME = "TIME",
  CALORIES = "CALORIES",
  DISTANCE = "DISTANCE",
  NO_SCORE = "NO_SCORE",
}

export const allScoreTypes = Object.values(ScoreType)

export function getScoreValidationSchema(scoreType: ScoreType) {
  switch (scoreType) {
    case ScoreType.TIME:
      return Yup.object({
        value: Yup.string()
          .required(word("global.form.required"))
          .notOneOf(["00min 00sec"], word("global.form.required")),
      })
    case ScoreType.CALORIES:
    case ScoreType.REPETITIONS:
      return Yup.object({
        value: Yup.number().required(word("global.form.required")).integer().positive(word("global.form.positive")),
      })
    case ScoreType.ROUND_AND_REPETITIONS:
      return Yup.object({
        value: Yup.object({
          round: Yup.number().required(word("global.form.required")).integer().positive(word("global.form.positive")),
          rep: Yup.number().required(word("global.form.required")).integer().positive(word("global.form.positive")),
        }),
      })
    case ScoreType.DISTANCE:
    case ScoreType.WEIGHT:
      return Yup.object({
        value: Yup.number().positive(word("global.form.positive")).required(word("global.form.required")),
      })
  }
}
