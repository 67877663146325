import { HUButtonIcon, HUButtonIconProps } from "@modules/ui/components/huButtonIcon"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { LoadingPoints } from "@modules/ui/components/loading"
import { Tooltip } from "primereact/tooltip"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

type HUButtonType = "Default" | "Left_Icon" | "Right_Icon" | "Rounded"
type HUButtonColorType = "Primary" | "Secondary" | "Tertiary" | "Quaternary" | "Underline" | "Black" | "White"
type HUButtonSize = "XS" | "S" | "M" | "L"

type HUButtonProps = {
  type: HUButtonType
  colorType: HUButtonColorType
  size: HUButtonSize
  text?: string
  icon?: HUButtonIconProps
  loading?: boolean
  textColor?: string
  isTooltipDisabled?: boolean
  tooltipTitle?: string
  tooltipContent?: string
  tooltipId?: string
  fullWidth?: boolean
  submit?: boolean
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children" | "type">

type ButtonDefaultXS = {
  type: "Default"
  size: "XS"
  colorType: "Primary" | "Secondary" | "Tertiary"
} & HUButtonProps

type ButtonDefaultS = {
  type: "Default"
  size: "S"
  colorType: "Primary" | "Tertiary" | "Quaternary" | "Black"
} & HUButtonProps

type ButtonDefaultM = {
  type: "Default"
  size: "M"
  colorType: "Primary" | "Secondary" | "Tertiary" | "Quaternary" | "Underline" | "White"
} & HUButtonProps

type ButtonDefaultL = {
  type: "Default"
  size: "L"
  colorType: "Primary" | "Secondary"
} & HUButtonProps

type ButtonLeftIconXS = {
  type: "Left_Icon"
  size: "XS"
  colorType: "Primary" | "Secondary" | "Tertiary" | "Quaternary" | "Black" | "Underline"
} & HUButtonProps

type ButtonLeftIconS = {
  type: "Left_Icon"
  size: "S"
  colorType: "Primary" | "Secondary" | "Tertiary" | "Quaternary" | "Black"
} & HUButtonProps

type ButtonLeftIconM = {
  type: "Left_Icon"
  size: "M"
  colorType: "Primary" | "Secondary" | "Quaternary"
} & HUButtonProps

type ButtonLeftIconL = {
  type: "Left_Icon"
  size: "L"
  colorType: "Primary" | "Secondary" | "Tertiary"
} & HUButtonProps

type ButtonRightIconXS = {
  type: "Right_Icon"
  size: "XS"
  colorType: "Secondary" | "Quaternary" | "Black" | "Tertiary" | "White" | "Underline"
} & HUButtonProps

type ButtonRightIconS = {
  type: "Right_Icon"
  size: "S"
  colorType: "Secondary" | "Quaternary" | "Black" | "Tertiary" | "White"
} & HUButtonProps

type ButtonRightIconM = {
  type: "Right_Icon"
  size: "M"
  colorType: "Primary" | "Secondary" | "Underline" | "Quaternary"
} & HUButtonProps

type ButtonRightIconL = {
  type: "Right_Icon"
  size: "L"
  colorType: "Primary" | "Secondary" | "Quaternary"
} & HUButtonProps

type ButtonRoundedXS = {
  type: "Rounded"
  size: "XS"
  colorType: "Primary" | "Secondary" | "Tertiary" | "Quaternary"
} & HUButtonProps

type ButtonRoundedS = {
  type: "Rounded"
  size: "S"
  colorType: "Secondary"
} & HUButtonProps

type ButtonRoundedM = {
  type: "Rounded"
  size: "M"
  colorType: "Primary" | "Secondary" | "Tertiary" | "Quaternary"
} & HUButtonProps

type ButtonRoundedL = {
  type: "Rounded"
  size: "L"
  colorType: "Primary"
} & HUButtonProps

export const HUButton: React.FC<
  | ButtonDefaultXS
  | ButtonDefaultS
  | ButtonDefaultM
  | ButtonDefaultL
  | ButtonLeftIconXS
  | ButtonLeftIconS
  | ButtonLeftIconM
  | ButtonLeftIconL
  | ButtonRightIconXS
  | ButtonRightIconS
  | ButtonRightIconM
  | ButtonRightIconL
  | ButtonRoundedXS
  | ButtonRoundedS
  | ButtonRoundedM
  | ButtonRoundedL
> = ({
  type,
  size,
  colorType,
  disabled = false,
  text,
  icon,
  loading,
  className = "",
  isTooltipDisabled = true,
  tooltipTitle,
  tooltipContent,
  tooltipId,
  textColor,
  fullWidth,
  submit = false,
  ...props
}) => {
  const theme = useTheme()

  const mainTextColor: string =
    textColor ?? theme[disabled ? `button${colorType}DisabledTextColor` : `button${colorType}TextColor`]

  const textFontStyle = useMemo(() => {
    if (size === "XS") return "LS"
    if (size === "S") return "LM"
    if (size === "M" || size === "L") return "LL"
  }, [])

  const padding = useMemo(() => {
    const hasBorder = colorType === "Tertiary"
    const hasText = !!text
    const hasIcon = !!icon && ("icon" in icon || "iconView" in icon)
    const extraPadding = hasBorder ? -1 : 0
    const xPadding = size === "XS" ? 8 : size === "S" ? 14 : 20
    const yPadding = size === "XS" ? 8 : size === "S" ? 10 : 14

    return `${(hasText ? yPadding : hasIcon ? (size === "XS" ? 8 : 16.5) : 0) + extraPadding}px ${(hasText ? xPadding : hasIcon ? (size === "XS" ? 8 : 16.5) : 0) + extraPadding}px`
  }, [colorType])

  return (
    <StyledButton
      {...props}
      className={className + ` tooltip${tooltipId}`}
      disabled={disabled}
      data-pr-showdelay={200}
      data-pr-position="bottom"
      data-pr-disabled={isTooltipDisabled}
      $buttonType={type}
      $colorType={colorType}
      $disabled={disabled}
      $textColor={mainTextColor}
      $padding={padding}
      $fullWidth={fullWidth}
      type={submit ? "submit" : "button"}
    >
      <Tooltip target={`.tooltip${tooltipId}`} className="tooltip-container-button">
        {tooltipTitle && <HUText fontStyle="BL">{tooltipTitle}</HUText>}
        <div className="tooltip-content-button text-justify">
          <HUText type="label" fontStyle="BM">
            {tooltipContent}
          </HUText>
        </div>
      </Tooltip>

      {loading && <LoadingPoints />}

      <HURow gap={8} align>
        {icon && (type === "Left_Icon" || type === "Rounded") && (
          <HUButtonIcon icon={icon} iconColor={mainTextColor} defaultIconSize={size === "XS" ? 15 : 20} />
        )}
        {text ? (
          <HUText fontStyle={textFontStyle} color={mainTextColor}>
            {text}
          </HUText>
        ) : null}
        {icon && type === "Right_Icon" && (
          <HUButtonIcon icon={icon} iconColor={mainTextColor} defaultIconSize={size === "XS" ? 15 : 20} />
        )}
      </HURow>
    </StyledButton>
  )
}

const StyledButton = styled.button<{
  $buttonType: HUButtonType
  $colorType: HUButtonColorType
  $disabled: boolean
  $padding: string
  $textColor: string
  $fullWidth?: boolean
}>`
  display: flex;
  border-radius: 48px;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s linear;
  background-color: ${({ theme, $colorType }) => theme[`button${$colorType}BgColor`]};
  padding: ${({ $padding }) => $padding};
  border: ${({ theme, $colorType }) => theme[`button${$colorType}Border`]};
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "fit-content")};
  text-decoration: ${({ $colorType, $textColor }) =>
    $colorType === "Underline" ? `underline solid ${$textColor} 1px` : "none"};
  text-underline-offset: 3px;

  &:hover {
    background-color: ${({ theme, $colorType }) => theme[`button${$colorType}HoverBgColor`]};
    border: ${({ theme, $colorType }) => theme[`button${$colorType}HoverBorder`]} !important;
    text-decoration: ${({ $colorType, $textColor }) =>
      $colorType === "Underline" ? `underline solid ${$textColor} 2px` : "none"};
  }

  &:disabled {
    background-color: ${({ theme, $colorType }) => theme[`button${$colorType}DisabledBgColor`]};
    pointer-events: none;
    cursor: initial;
  }
`
