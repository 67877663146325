import { CheckmarkIcon } from "@images/svgIcons/checkmarkIcon"
import { ClockOutlineIcon } from "@images/svgIcons/clockOutlineIcon"
import { word } from "@modules/core/utils/i18n"
import { getFormatedDateTime } from "@modules/language/languageUtils"
import { useProgramAthleteSubscription } from "@modules/programs/hooks/useProgramSubscription"
import { ProgramInfinite, ProgramSubscriptionTrial } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import styled, { useTheme } from "styled-components"

export const ProgramSubscriptionActions: React.FC<{
  program: ProgramInfinite | ProgramOnOff
}> = ({ program }) => {
  const theme = useTheme()
  const {
    isUpdating: subscriptionLoading,
    subscribe,
    unsubscribe,
    programStripeSubscriptionInfos,
  } = useProgramAthleteSubscription(program)

  switch (program.subscriptionStatus) {
    case ProgramSubscriptionStatus.PENDING_REQUEST:
      return <FakeSubscriptionButton text={word("program.subscription.pending.label")} />
    case ProgramSubscriptionStatus.PENDING_PAYMENT:
      return (
        <HUStack>
          <HURow className="border-1 border-round-2xl p-2">
            <div className="col-5">
              <HURow className="p-2 align-items-center justify-content-center gap-2 surface-900 border-round-2xl">
                <span className={`pi pi-credit-card text-white`} />
                <HUText fontStyle="LS" color={theme.mainWhite}>
                  {word("program.subscription.pending.payment.title")}
                </HUText>
              </HURow>
            </div>
            <HUText fontStyle="BS" color={theme.neutralColor700} className="col-7">
              {word("program.subscription.pending.payment.information")}
            </HUText>
          </HURow>
          <HURow className="justify-content-between w-full">
            <HUButton
              type="Default"
              size="M"
              colorType="Primary"
              text={word("program.subscription.paying.proceed.title")}
              loading={subscriptionLoading}
              onClick={() => subscribe()}
              style={{ paddingLeft: 38, paddingRight: 38 }}
            />
            <HUButton
              type="Default"
              size="M"
              colorType="Underline"
              text={word("program.subscription.paying.cancel.title")}
              onClick={() => unsubscribe()}
            />
          </HURow>
        </HUStack>
      )
    case ProgramSubscriptionStatus.TRIALING:
      return (
        <HUStack>
          {programStripeSubscriptionInfos &&
            "trial" in programStripeSubscriptionInfos &&
            (programStripeSubscriptionInfos.trial as ProgramSubscriptionTrial) && (
              <HUText>
                {word("program.subscription.trialing.progression", {
                  trialEndDate: getFormatedDateTime(
                    (programStripeSubscriptionInfos.trial as ProgramSubscriptionTrial).endDate,
                  ),
                })}
              </HUText>
            )}
          <HUButton
            type="Default"
            colorType="Tertiary"
            size="S"
            className="w-full"
            text={word("program.subscription.trialing.cancel.button")}
            onClick={() => unsubscribe()}
            loading={subscriptionLoading}
          />
        </HUStack>
      )
    case ProgramSubscriptionStatus.ACTIVE:
      if (
        program._programType === ProgramOnOffTypeOf &&
        programStripeSubscriptionInfos &&
        "paidDate" in programStripeSubscriptionInfos &&
        typeof programStripeSubscriptionInfos.paidDate === "string" &&
        programStripeSubscriptionInfos.paidPrice?.value
      )
        return (
          <HUText
            className="align-self-start flex flex-row align-items-center gap-2"
            color={theme.neutralColor700}
            fontStyle="TS"
          >
            <StyledCheckmarkIcon width={24} height={24} />
            {word("program.onOff.paidOn", {
              paymentDate: getFormatedDateTime(programStripeSubscriptionInfos.paidDate),
            })}
          </HUText>
        )
      else
        return (
          <HUButton
            type="Default"
            size="S"
            colorType="Tertiary"
            text={
              program._programType === ProgramOnOffTypeOf
                ? word("program.onOff.subscription.remove.title")
                : word("program.subscription.paying.unsubscribe.confirm.button")
            }
            onClick={() => unsubscribe()}
            loading={subscriptionLoading}
            className="px-4"
          />
        )
    case ProgramSubscriptionStatus.PENDING_CANCELLATION: // only for paying subscription
      return <FakeSubscriptionButton text={word("program.subscription.paying.unsubscription_in_progress.title")} />
    case ProgramSubscriptionStatus.INACTIVE:
      return (
        <HUButton
          size="S"
          type="Default"
          colorType="Primary"
          text={
            program._programType === ProgramOnOffTypeOf
              ? program.price
                ? word("program.onOff.subscription.buy.title")
                : word("program.onOff.subscription.buy.free.title")
              : program.price
                ? word("program.subscription.paying.subscribe.title")
                : word("program.subscription.free.subscribe.title")
          }
          loading={subscriptionLoading}
          onClick={() => subscribe()}
          className="px-4"
        />
      )
  }
}

const FakeSubscriptionButton: React.FC<{ text: string }> = ({ text }) => {
  const theme = useTheme()
  return (
    <StyledFakeButton
      type="Left_Icon"
      size="S"
      colorType="Tertiary"
      icon={{ icon: ClockOutlineIcon, iconProps: { color: theme.neutralColor700 } }}
      text={text}
      disabled
    />
  )
}

const StyledFakeButton = styled(HUButton)`
  align-self: flex-start;
  border-color: ${({ theme }) => theme.neutralColor400};
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  fill: ${({ theme }) => theme.neutralColor700};
`
