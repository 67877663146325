import { ApiInterceptor } from "@modules/core/api/interceptors/apiInterceptor"
import { HUError } from "@modules/error/huError"
import { AxiosError, AxiosResponse } from "axios"

export const responseDebugGenerateErrorInterceptor = (
  getConsumeGeneratedError: () => HUError | null,
): ApiInterceptor<AxiosResponse> => ({
  onRejected: (error) => {
    const generatedError = getConsumeGeneratedError()
    if (generatedError) {
      return Promise.reject(generatedError)
    }

    return Promise.reject(error)
  },
  onFulfilled: (response) => {
    const generatedError = getConsumeGeneratedError()
    if (generatedError) {
      if (generatedError.status) {
        throw new AxiosError(
          generatedError.message,
          generatedError.status.toString(),
          response.config,
          response.request,
          {
            ...response,
            ...{ status: generatedError.status },
          },
        )
      } else {
        throw generatedError
      }
    }
    return response
  },
})
