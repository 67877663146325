import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const CardioIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="1" width="60" height="60" rx="18" fill="#F27735" />
    <rect x="1" y="1" width="60" height="60" rx="18" stroke="url(#paint0_linear_470_97559)" strokeWidth="1.11776" />
    <rect x="7" y="22" width="2" height="48" rx="1" transform="rotate(-90 7 22)" fill="white" />
    <rect x="54.5186" y="33.333" width="2" height="24" rx="1" transform="rotate(90 54.5186 33.333)" fill="white" />
    <rect
      opacity="0.4"
      x="54.5186"
      y="26.6665"
      width="2"
      height="36"
      rx="1"
      transform="rotate(90 54.5186 26.6665)"
      fill="white"
    />
    <rect
      opacity="0.4"
      x="54.5186"
      y="39.9995"
      width="2"
      height="12"
      rx="1"
      transform="rotate(90 54.5186 39.9995)"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_470_97559"
        x1="3.15569"
        y1="2"
        x2="43.3973"
        y2="67.9826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.04" />
        <stop offset="0.188137" stopColor="white" stopOpacity="0" />
        <stop offset="0.708289" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.06" />
      </linearGradient>
    </defs>
  </svg>
)
