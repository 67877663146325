import { Store } from "@betomorrow/micro-stores"
import { Service } from "@modules/core/services/serviceType"
import { Profile } from "@modules/profile/profileTypes"
import { UserApi } from "@modules/profile/services/userApi"

export class UserProfileService implements Service {
  userProfileStore = new Store<Profile>((id) => this.getProfile(id))

  constructor(private userApi: UserApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedOut(): Promise<void> {
    this.userProfileStore.clear()
  }

  async getProfile(profileId: string) {
    return this.userApi.getProfile(profileId)
  }
}
