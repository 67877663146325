import { word } from "@modules/core/utils/i18n"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
}
export const ErrorCard: React.FC<Props> = ({ title }) => {
  return (
    <div className="card" style={{ gap: 20, flexDirection: "column", display: "flex" }}>
      <h2 className="align-items-center justify-content-center">{title ?? word("global.error.default")}</h2>
    </div>
  )
}
