import { User, UserDraft } from "@modules/auth/user"
import { UserDto, convertUserFromDto, convertUserToDto } from "@modules/auth/userDto"
import { ApiClient } from "@modules/core/api/client/apiClient"

export class SharedUserApi {
  baseUrl = "/users"

  constructor(protected readonly api: ApiClient) {}

  async getUser(): Promise<User> {
    const result = await this.api.get<UserDto>(`${this.baseUrl}/me?createOrUpdate=false`)
    return convertUserFromDto(result.data)
  }

  async register(draft: UserDraft): Promise<User> {
    const result = await this.api.post<UserDto>(`${this.baseUrl}`, convertUserToDto(draft))
    return convertUserFromDto(result.data)
  }

  async update(user: Partial<UserDraft>): Promise<User> {
    const result = await this.api.patch<UserDto>(`${this.baseUrl}/me`, convertUserToDto(user))
    return convertUserFromDto(result.data)
  }

  async checkUsername(username: string) {
    const result = await this.api.get<{ availableUsername: boolean }>(
      `${this.baseUrl}/check-username?username=${username}`,
    )
    return result.data.availableUsername
  }
}
