import ChatEmpty from "@images/empty/chat_empty.svg"
import { word } from "@modules/core/utils/i18n"
import { DEFAULT_PAGE_SIZE, MemberTable } from "@modules/members/components/memberTable"
import { MemberInputKey } from "@modules/members/memberFormikType"
import { allMemberFilters } from "@modules/members/memberTypes"
import { useMembers } from "@modules/members/useMember"
import { EmptyView } from "@modules/ui/components/emptyView"
import { ScreenHeader } from "@modules/ui/components/header"
import { HUStack } from "@modules/ui/components/layout"
import { usePersistedDatatable } from "@modules/utils/hooks/useDataTable"
import { isInArray } from "@modules/utils/isInArray"
import { useQueryParam } from "@modules/utils/navigation/useQueryParam"
import { TabMenu } from "primereact/tabmenu"
import React, { useEffect, useMemo, useState } from "react"
import { Handshake, DoorOpen, DoorClosed } from "lucide-react"
import styled, { useTheme } from "styled-components"
import { MenuItem } from "primereact/menuitem"
import { InputText } from "primereact/inputtext"
import ClearInputTextIcon from "@images/ui-icons/crossWhite.svg"
import { Search } from "lucide-react"
import _ from "lodash"

export const MembersPage: React.FC = () => {
  const { page, setPage, size, setSize } = usePersistedDatatable(DEFAULT_PAGE_SIZE)
  const [nameFilter, setNameFilter] = useState("")
  const [memberTab, setMemberTab] = useQueryParam("tab")
  const [countTab, setCountTab] = useState(0)
  const selectedTab = isInArray(allMemberFilters, memberTab) ? memberTab : "active"
  const theme = useTheme()
  const diplayedInfoMember: MemberInputKey[] = ["name", "activeOffer", "lastVisit", "note","noshow"]

  const membersOptions: MenuItem[] = [
    {
      icon: <Handshake size={16} color={theme.mainWhite} className="mr-2" />,
      label: word("member.filter.active"),
      data: "active",
      template: (item) => itemRenderer(item, 0),
    },
    {
      icon: <DoorOpen size={16} color={theme.mainWhite} className="mr-2" />,
      label: word("member.filter.prospect"),
      data: "prospect",
      template: (item) => itemRenderer(item, 1),
    },
    // {
    //   icon: <DropinIcon size={16} color={theme.mainWhite} className="mr-2" />,
    //   label: word("member.filter.dropIn"),
    //   data: "dropin",
    //   template: (item) => itemRenderer(item, 2),
    // },
    // {
    //   icon: <BriefcaseBusiness size={16} color={theme.mainWhite} className="mr-2" />,
    //   label: word("member.filter.staff"),
    //   data: "staff",
    //   template: (item) => itemRenderer(item, 3),
    // },
    {
      icon: <DoorClosed size={16} color={theme.mainWhite} className="mr-2" />,
      label: word("member.filter.terminated"),
      data: "terminated",
      template: (item) => itemRenderer(item, 2),
    },
  ]

  const [activeIndex, setActiveIndex] = useState(membersOptions.findIndex((o) => o.data === selectedTab))
  const itemRenderer = (item: MenuItem, index: number) => (
    <div className="flex flex-column">
      <a className="p-menuitem-link flex align-items-center gap-2" onClick={() => handleTabChange(item.data, index)}>
        <span>{item.icon}</span>
        <span className="font-bold">{item.label}</span>
      </a>
      <span className="flex justify-content-center mt-3" style={{ color: `${theme.mainWhite}` }}>
        {item.data === selectedTab  ? `(${countTab})` : ""}
      </span>
    </div>
  )

  const handleTabChange = (data: string, index: number) => {
    setMemberTab(data)
    setActiveIndex(index)
  }

  const handleSearchField = useMemo(
    () =>
      _.debounce(
        (userName) => {
          setNameFilter(userName)
        },
        300,
        { leading: false, trailing: true },
      ),
    [],
  )

  const content = useMembers(page, size, selectedTab, nameFilter)
  useEffect(() => {
    if (content.totalSize) {
      setCountTab(content.totalSize)
    } else
      setCountTab(0)
  }, [content])

  return (
    <>
      {content.result ? (
        <HUStack gap={0} flexGrow style={{ width: "100%", minWidth: "980px"}}>
          {/* HEADER PAGE */}
          <ScreenHeader title={word("member.page.title")} subTitle={word("member.page.subtitle")} />
          <HUStack gap={0} className="card pt-2" flexGrow>
            {/* HEADER TABLE (Menu & Search) */}
            <div className="flex flex-row px-2">
              <TabMenu
                model={membersOptions}
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
                className="flex-1 border-none"
              />
              <StyledInputText className="block pt-2 p-input-icon-left ">
                <Search
                  strokeWidth={1}
                  size={24}
                  color={theme.mainWhite}
                  className="inline-height-1"
                  style={{ top: "15%" }}
                />
                <InputText
                  type="search"
                  onChange={(e) => handleSearchField(e.target.value)}
                  placeholder={"Recherche"}
                  className="w-17rem"
                  defaultValue={nameFilter}
                />
              </StyledInputText>

            </div>
            {/* TABLE */}
            <MemberTable
              paginatedStore={content}
              diplayedInfoMember={diplayedInfoMember}
              page={page}
              setPage={setPage}
              size={size}
              setSize={setSize}
              empty={{
                full: true,
                view: (
                  <EmptyView
                    image={{ src: ChatEmpty }}
                    title={word("member.list.empty.title")}
                    description={word("member.list.empty.description")}
                    paddingType="In_table"
                  />
                ),
              }}
            />
          </HUStack>
        </HUStack>
      ) : (
        content.error && <h2 className="align-items-center justify-content-center">{word("global.error.default")}</h2>
      )}
    </>
  )
}

const StyledInputText = styled.div`
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-image: url("${ClearInputTextIcon}");
    width: 13px;
    height: 13px;
    background-size: 13px 13px;
  }
`