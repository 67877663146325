import {
  BoxOffer,
  BoxOfferDraft,
  BoxOfferItem,
  BoxOfferPriceType,
  BoxOfferRule,
  BoxOfferType,
  BoxOfferWeeklyParticipationType,
  BoxPurchase,
  BoxPurchaseStatus,
} from "@modules/boxOffer/boxOfferTypes"
import { Currency } from "@modules/currency/currency"
import { convertToEnum } from "@modules/utils/enum"
import dayjs from "dayjs"
import _ from "lodash"

export type BoxOfferDraftDto = {
  name: string
  type: string
  description: string
  isFree?: boolean
  price?: number
  currency?: string
  /**
   * minimumCommitmentPeriod: ISO duration
   */
  minimumCommitmentPeriod?: string
  count?: number
  programs: string[]
  maxWeeklyParticipation: number | null
  rules?: BoxOfferRule[]
  validityPeriodMonths?: number
  renewable?: boolean
}

export type BoxOfferDto = {
  id: string
} & BoxOfferDraftDto

export type BoxOfferItemDto = {
  id: string
  type: string
  name: string
  description?: string
  price: number
  minimumCommitmentPeriod?: string
  count?: number
  currency?: string
  isFree: boolean
  validityPeriodMonths?: number
  renewable?: boolean
}

export type PriceDto = {
  value: number | undefined
  currency: Currency | undefined
}

export type BoxPurchaseDto = {
  id: string
  date: string
  status: string
  offer: { id: string; name: string; type: BoxOfferType }
  count?: number
  initialCount?: number
  price: PriceDto
  purchaseDate: string
}

export function convertAllBoxPurchasesFromDto(dto: BoxPurchaseDto[]): BoxPurchase[] {
  return _.compact(dto.map((purchaseDto) => convertBoxPurchaseFromDto(purchaseDto)))
}

export function convertBoxPurchaseFromDto(dto: BoxPurchaseDto): BoxPurchase | null {
  const type = convertToEnum(BoxOfferType, dto.offer.type)

  if (!type) {
    return null
  }

  return {
    ...dto,
    status: convertToEnum(BoxPurchaseStatus, dto.status, BoxPurchaseStatus.INACTIVE),
    offer: { id: dto.id, name: dto.offer.name, type: dto.offer.type },
    id: dto.id,
    purchaseDate: dto.purchaseDate,
    count: dto.count,
    initialCount: dto.initialCount,
    price: { value: dto.price.value, currency: dto.price.currency as Currency },
  }
}

export function convertBoxOfferFromDto(dto?: BoxOfferDto): BoxOffer | null {
  const type = convertToEnum(BoxOfferType, dto?.type)

  if (!type || !dto) {
    return null
  }

  const base = {
    id: dto.id,
    name: dto.name,
    description: dto.description,
    price: dto.price,
    currency: dto.currency as Currency,
    programs: dto.programs,
    maxWeeklyParticipation: dto.maxWeeklyParticipation ?? undefined,
    rules: dto.rules,
  }

  switch (type) {
    case BoxOfferType.Subscription:
      return {
        ...base,
        isFree: !!dto.isFree,
        type,
        minimumMonthlyDuration: dayjs.duration(dto.minimumCommitmentPeriod!).months(),
      }
    case BoxOfferType.DropIn:
      return { ...base, isFree: !!dto.isFree, type, validityPeriodMonths: dto.validityPeriodMonths }
    case BoxOfferType.Trial:
      return {
        ...base,
        isFree: !!dto.isFree,
        type,
        count: dto.count ?? 0,
        renewable: !!dto.renewable,
        validityPeriodMonths: dto.validityPeriodMonths,
      }
    case BoxOfferType.TicketsBook:
      if (!dto.count) {
        return null
      }
      return {
        ...base,
        isFree: !!dto.isFree,
        type,
        count: dto.count,
        validityPeriodMonths: dto.validityPeriodMonths,
      }
    case BoxOfferType.Millenium:
      return {
        ...base,
        isFree: true,
        type,
      }
  }
}

export function convertItemBoxOfferFromDto(item: BoxOfferItemDto): BoxOfferItem {
  return {
    ...item,
    type: item.type as BoxOfferType,
    currency: item.currency as Currency,
  }
}

export function convertBoxOfferDraftToDto(draft: BoxOfferDraft): BoxOfferDraftDto {
  return {
    type: draft.type,
    name: draft.name,
    description: draft.description,
    price: draft.priceSwitch === BoxOfferPriceType.Paid ? draft.price! : undefined,
    currency: draft.priceSwitch === BoxOfferPriceType.Paid ? draft.currency : undefined,
    programs: draft.programs,
    count: draft.type === BoxOfferType.TicketsBook && draft.count ? draft.count : undefined,
    minimumCommitmentPeriod:
      draft.type === BoxOfferType.Subscription
        ? dayjs.duration({ months: draft.minimumMonthlyDuration }).toISOString()
        : undefined,
    maxWeeklyParticipation:
      draft.weeklyParticipationSwitch === BoxOfferWeeklyParticipationType.Restricted
        ? draft.maxWeeklyParticipation!
        : null,
    rules: draft.rules,
    renewable: draft.renewable,
    validityPeriodMonths: draft.validityPeriodMonths,
  }
}
