import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ScoreboardIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2.46631" y="15.3477" width="6.44214" height="6.55859" rx="1" stroke="white" strokeWidth="2.5" />
    <rect x="8.90845" y="12.9414" width="6.44214" height="8.96484" rx="1" stroke="white" strokeWidth="2.5" />
    <rect x="15.3506" y="17.9473" width="6.44214" height="3.95898" rx="1" stroke="white" strokeWidth="2.5" />
    <path
      d="M14.2811 7.23194L14.5208 9.78907C14.5211 9.793 14.517 9.79579 14.5135 9.79397L12.1963 8.59641C12.1949 8.59569 12.1932 8.59566 12.1918 8.59633L9.65358 9.79436C9.65004 9.79603 9.64605 9.79318 9.64647 9.7893L9.92621 7.23178C9.92635 7.23052 9.926 7.22925 9.92524 7.22823L8.36593 5.15004C8.36376 5.14715 8.36528 5.14299 8.3688 5.14217L10.948 4.54239C10.9493 4.54209 10.9504 4.54129 10.9511 4.54016L12.1898 2.54199C12.1918 2.53884 12.1964 2.53883 12.1983 2.54198L13.5574 4.72072C13.5581 4.72185 13.5593 4.72266 13.5606 4.72295L15.9783 5.26182C15.982 5.26265 15.9835 5.26712 15.981 5.26998L14.2823 7.2282C14.2814 7.22923 14.281 7.23058 14.2811 7.23194Z"
      stroke="white"
      strokeWidth="2.5"
    />
  </svg>
)
