import { BoxPlanningCalendarEvent } from "@modules/boxPlanning/components/boxPlanningCalendarEvent"
import {
  useBoxPlanningSlotEvents,
  useBoxSlotsBeingCreated,
  useBoxSlotsBeingViewed,
} from "@modules/boxPlanning/hooks/useBoxPlanningSessionEvent"
import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxSlotTemplateToolComponent } from "@modules/boxProgrammation/components/boxSlotTemplateToolComponent"
import { useBoxSlotSidebar } from "@modules/boxSlot/hooks/useBoxSlotSidebar"
import { Calendar } from "@modules/calendar/calendar"
import { CalendarEventTypeWithHour } from "@modules/calendar/calendarTypes"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { Dayjs } from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"

export const BoxPlanningCalendar: React.FC<{
  boxProgrammation: BoxProgrammation | null
  isLoadingSessions: boolean
}> = ({ boxProgrammation, isLoadingSessions }) => {
  const [showTemplateExplanation, setShowTemplateExplanation] = useState<boolean>(false)

  const { localStorageService } = useServicesContext()
  const { boxProgrammationId } = useParams()
  const { slots, slotEvents, isLoadingSlots } = useBoxPlanningSlotEvents()
  const { navigateToBoxSlotView, navigateToBoxSlotCreation } = useBoxSlotSidebar()
  const { refreshCalendarEvents } = useCalendar()
  const boxSlotsBeingViewed = useBoxSlotsBeingViewed()
  const slotsBegingCreated = useBoxSlotsBeingCreated()

  const slotIds = useMemo(() => slotEvents.map((slot) => slot.id), [slotEvents])

  const filteredSlots = useMemo(() => {
    return slotEvents.filter(
      (slotEvent) => slotsBegingCreated.findIndex((slotBegingCreated) => slotBegingCreated.id === slotEvent.id) < 0,
    )
  }, [JSON.stringify(slotIds), boxProgrammationId, slotsBegingCreated])

  useEffect(() => {
    if (!isLoadingSlots) {
      const dontShowOnBoardingAnymore = localStorageService.load(
        LocalStorageKeys.boxSlotTemplate.dontShowOnBoardingAnymore,
        { value: slotIds.length > 0 },
      )
      setShowTemplateExplanation(!dontShowOnBoardingAnymore.value)
    }
  }, [JSON.stringify(slotIds), boxProgrammationId, isLoadingSlots])

  const isEventFromCurrentProgrammation = useCallback(
    (event: CalendarEventTypeWithHour) => {
      return event.programmationId && boxProgrammationId ? event.programmationId === boxProgrammationId : true
    },
    [boxProgrammationId],
  )

  const isEventOverlayed = useCallback(
    (event: CalendarEventTypeWithHour) => {
      return slotsBegingCreated.length > 0
        ? slotsBegingCreated.findIndex((slotBegingCreated) => slotBegingCreated.id === event.id) >= 0
        : boxSlotsBeingViewed.length > 0
          ? boxSlotsBeingViewed.findIndex((boxSlotBeingViewed) => boxSlotBeingViewed.id === event.id) >= 0
          : isEventFromCurrentProgrammation(event)
    },
    [slotsBegingCreated, boxSlotsBeingViewed],
  )

  const onAddEventClick = async (value?: Dayjs | number) => {
    if (value && typeof value === "object" && boxProgrammation)
      navigateToBoxSlotCreation(boxProgrammation, refreshCalendarEvents, value.toDate())
  }

  const relatedSlot = useCallback(
    (eventId: string) => {
      return slots.find((slot) => slot.id === eventId)
    },
    [slots],
  )

  const hideTemplateExplanation = () => {
    setShowTemplateExplanation(false)
    localStorageService.save(LocalStorageKeys.boxSlotTemplate.dontShowOnBoardingAnymore, { value: true })
  }

  return (
    <Container className="flex flex-column align-content-center flex-grow-1">
      {showTemplateExplanation ? (
        <BoxSlotTemplateToolComponent hideTemplateExplanation={hideTemplateExplanation} />
      ) : (
        <Calendar
          calendarEvents={[...filteredSlots, ...slotsBegingCreated]}
          canAddEvents={!!boxProgrammationId}
          onAddEventClick={onAddEventClick}
          addEventSubtitle={word("planning.addSlot.button")}
          loading={isLoadingSessions}
          customRenderEvent={(_, event) => (
            <BoxPlanningCalendarEvent
              event={event as CalendarEventTypeWithHour}
              key={event.id}
              slot={relatedSlot(event.id)}
              onClickSlot={() =>
                "programmationId" in event &&
                navigateToBoxSlotView(event.programmationId, event.id, refreshCalendarEvents)
              }
              canBeClicked={
                isEventFromCurrentProgrammation(event as CalendarEventTypeWithHour) &&
                slotsBegingCreated.findIndex((slot) => slot.id === event.id) < 0
              }
              isOverlayed={isEventOverlayed(event as CalendarEventTypeWithHour)}
            />
          )}
        />
      )}
    </Container>
  )
}

const Container = styled.div``
