import { getWeekdayValue, Weekday } from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import dayjs from "dayjs"

// 2000-01-02 is a Sunday day. It's used as a start reference to construct the box slot weekly template.
const INITIAL_BOX_SLOT_DATE = "2000-01-02"

export const initializeDateUtils = (initialBaseDate: string = INITIAL_BOX_SLOT_DATE) => {
  const startWeekdayVal = dayjs.localeData().firstDayOfWeek()
  const computedInitialSlotWeekday = dayjs(initialBaseDate).add(startWeekdayVal, "day").toDate()

  const getDateTimeByAddingWeekday = (time: string, weekDay: Weekday) => {
    const baseDateTime = dayjs(`${initialBaseDate}T${time}`)
    let resultDateTime = baseDateTime.add(getWeekdayValue(weekDay), "day")
    if (weekDay === "SUNDAY" && startWeekdayVal === 1) {
      resultDateTime = resultDateTime.add(7, "days")
    }
    return resultDateTime.toDate()
  }
  return {
    localStartWeekDay: computedInitialSlotWeekday,
    getDateTimeByAddingWeekday,
  }
}
