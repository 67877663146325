import {
  BoxProgrammationDefaultSettings,
  BoxProgrammationDraft,
  BoxProgrammationTypeOf,
  BoxProgrammationVisibility,
} from "@modules/boxProgrammation/boxProgrammationTypes"
import {
  DEFAULT_BOX_PROGRAMMATION_COLOR,
  getBoxProgrammationColorTypeWithHex,
} from "@modules/boxProgrammation/boxProgrammationUtils"
import {
  BoxProgrammationColorType,
  boxProgrammationColors,
} from "@modules/boxProgrammation/components/boxProgrammationColors"
import { BOX_SLOT_PARTICIPANTS_LIMIT_MAX, BOX_SLOT_PARTICIPANTS_LIMIT_MIN } from "@modules/boxSlot/boxSlotTypes"
import { word } from "@modules/core/utils/i18n"
import { convertTimeStringToTimeValue, convertTimeValueToTimeString } from "@modules/duration/timeConverter"
import { requiredInput } from "@modules/form/formUtils"
import { HUImage } from "@modules/types/imageTypes"
import * as Yup from "yup"

export type BoxProgrammationInputKey =
  | "image"
  | "title"
  | "description"
  | "color"
  | "defaultHideSessionContent"
  | "repetition"
  | "createDiscussionChannel"
  | "accessibility"
  | "offers"

export function getValidationSchema(options?: { imageRequired: boolean }) {
  return Yup.object({
    image: options?.imageRequired ? Yup.mixed().required(word("program.form.uploadImage.requiredText")) : Yup.object(),
    title: requiredInput(Yup.string().trim()),
    description: requiredInput(Yup.string().trim()),
  })
}

export const convertBoxProgrammationDraftToFormik = (
  program: BoxProgrammationDraft | undefined,
): BoxProgrammationFormikType => {
  return {
    image: program?.image,
    title: program?.name ?? "",
    description: program?.description ?? "",
    color: getBoxProgrammationColorTypeWithHex(program?.color ?? DEFAULT_BOX_PROGRAMMATION_COLOR),
    visibility: program?.visibility ?? BoxProgrammationVisibility.MEMBERS,
    offerIds: program?.restrictions ?? [],
    defaultHideSessionContent: program?.defaultHideSessionContent ?? false,
  }
}

export const convertBoxProgrammationFormFormik = (formik: BoxProgrammationFormikType): BoxProgrammationDraft => {
  return {
    _type: BoxProgrammationTypeOf,
    _programType: BoxProgrammationTypeOf,
    image: formik.image,
    name: formik.title,
    description: formik.description,
    color: boxProgrammationColors[formik.color ?? DEFAULT_BOX_PROGRAMMATION_COLOR].background,
    visibility: formik.visibility,
    defaultHideSessionContent: formik.defaultHideSessionContent,
    restrictions: formik.offerIds,
  }
}

export type BoxProgrammationFormikType = {
  image?: HUImage
  title: string
  description: string
  color: BoxProgrammationColorType
  offerIds: string[]
  visibility: BoxProgrammationVisibility
  defaultHideSessionContent: boolean
}

export type BoxProgrammationDefaultSettingsInputKey =
  | "duration"
  | "participantLimit"
  | "location"
  | "limitStart"
  | "limitEnd"
  | "limitCancel"

export type BoxProgrammationDefaultSettingsFormikType = {
  duration?: string
  participantLimit?: number
  location?: string
  limitStart?: number
  limitEnd?: number
  limitCancel?: number
}

export const convertBoxProgrammationDefaultSettingsToFormik = (
  defaultSettings: BoxProgrammationDefaultSettings | undefined,
): BoxProgrammationDefaultSettingsFormikType => {
  return {
    duration: defaultSettings?.duration ? convertTimeValueToTimeString(defaultSettings.duration) : "01h 00min",
    participantLimit: defaultSettings?.participantLimit,
    location: defaultSettings?.location ?? "",
    limitStart: defaultSettings?.participation?.limitStart,
    limitEnd: defaultSettings?.participation?.limitEnd,
    limitCancel: defaultSettings?.participation?.limitCancel,
  }
}

export function getDefaultSettingsValidationSchema() {
  return Yup.object({
    duration: Yup.string().required(word("global.form.required")),
    participantLimit: Yup.number()
      .required(word("global.form.required"))
      .min(
        BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
        word("global.form.minimumValue", { minValue: BOX_SLOT_PARTICIPANTS_LIMIT_MIN }),
      )
      .max(
        BOX_SLOT_PARTICIPANTS_LIMIT_MAX,
        word("global.form.maximumValue", { maxValue: BOX_SLOT_PARTICIPANTS_LIMIT_MAX }),
      ),
    location: Yup.string()
      .trim()
      .nullable()
      .max(40, word("form.maximum.characters", { count: 40 })),
    limitStart: Yup.number()
      .min(1, word("global.form.minimumValue", { minValue: 1 }))
      .required(word("global.form.required")),
    limitEnd: Yup.number()
      .min(1, word("global.form.minimumValue", { minValue: 1 }))
      .required(word("global.form.required")),
    limitCancel: Yup.number()
      .min(1, word("global.form.minimumValue", { minValue: 1 }))
      .required(word("global.form.required")),
  })
}

export const convertBoxProgrammationDefaultValuesFormFormik = (
  formik: BoxProgrammationDefaultSettingsFormikType,
): BoxProgrammationDefaultSettings => {
  return {
    duration: convertTimeStringToTimeValue("HH:mm", formik.duration) ?? 0,
    participantLimit: formik.participantLimit,
    location: formik.location,
    participation: {
      limitStart: formik.limitStart,
      limitEnd: formik.limitEnd,
      limitCancel: formik.limitCancel,
    },
  }
}
