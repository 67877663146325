import dateOptions from "@dateUtils/dateOptions.json"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { addLanguageInterceptor } from "@modules/core/api/interceptors/requestLanguageInterceptor"
import { Service } from "@modules/core/services/serviceType"
import { i18n } from "@modules/core/utils/i18n"
import { FeatureKeys } from "@modules/featureToggle/featureToggleTypes"
import { FeatureToggleService } from "@modules/featureToggle/services/featureToggleService"
import { getDefaultLanguage } from "@modules/language/languageUtils"
import { ILanguageService } from "@modules/language/services/ILanguageService"
import { Language, allLanguages, getAvailableLanguages } from "@modules/language/utils/languageUtils"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"
import dayjs from "dayjs"
import _ from "lodash"
import { observable } from "micro-observables"
import { addLocale as addPrimeReactLocale, locale as primeReactLocale } from "primereact/api"

interface LanguageProps {
  languageCode?: Language
}

export class LanguageService implements ILanguageService, Service {
  private _language = observable<LanguageProps>({})
  readonly language = this._language.readOnly()

  private timeZone = ""

  constructor(
    private localStorage: LocalStorageService,
    private apiClient: ApiClient,
    private featureToggleService: FeatureToggleService,
  ) {
    /* url date options : https://github.com/primefaces/primelocale.git */
    // fallback for 'el' & 'is' because not found
    allLanguages.forEach((locale) => {
      const dateOption = _.get(dateOptions, locale) ?? dateOptions.en
      addPrimeReactLocale(locale, JSON.parse(JSON.stringify(dateOption)))
    })
  }

  async init(): Promise<() => void> {
    const unsubscribe = this.localStorage.connect(this._language, LocalStorageKeys.language.languageCode, {})
    const allLanguagesAvailable = this.featureToggleService.hasFeature(FeatureKeys.AllLanguages)
    const local = this.language.get().languageCode ?? getDefaultLanguage(allLanguagesAvailable)
    this.setLocaleLanguage(getAvailableLanguages(allLanguagesAvailable).includes(local) ? local : Language.ENGLISH_US)
    this.timeZone = dayjs.tz.guess()

    this.apiClient.addRequestInterceptors([addLanguageInterceptor(this)])

    return unsubscribe
  }

  setUserLanguage(language: Language) {
    this.setLocaleLanguage(language)
    this._language.update((p) => ({ ...p, languageCode: language }))
    document.documentElement.lang = language
  }

  private setLocaleLanguage(language: Language) {
    primeReactLocale(language)
    i18n.defaultLocale = language
    i18n.locale = language
  }

  getLocalLanguage() {
    return i18n.locale
  }

  getTimeZone() {
    return this.timeZone
  }

  getDateInLocalEn(date?: dayjs.ConfigType) {
    return dayjs(date).locale(Language.ENGLISH_US)
  }
}
