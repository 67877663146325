import { BoxOfferType } from "@modules/boxOffer/boxOfferTypes"
import { Currency, allCurrencies } from "@modules/currency/currency"
import { HUImageDto, convertImageFromDto } from "@modules/dtos/imageDto"
import {
  InvoiceStatus,
  TransactionStatus,
  allInvoiceStatus,
  allTransactionsStatus,
} from "@modules/finances/types/financesType"
import {
  BoxItemTransaction,
  BoxOfferTransaction,
  MemberTransaction,
  TransactionInvoice,
  TransactionMember,
  UserItemTransaction,
  UserTransaction,
} from "@modules/finances/types/transactionType"
import { PromoCodeType } from "@modules/promoCode/services/promoCodeDto"
import { convertToType } from "@modules/utils/enum"
import { isInArray } from "@modules/utils/isInArray"
import { LoggerType } from "@modules/utils/loggerUtils"

type BaseItemTransactionDto = {
  id: string
  date: string
  amount: number
  fee: number | null
  currency: string
  status: string
  disputed: boolean
  invoiceNumber?: string
}

export type BoxItemTransactionDto = BaseItemTransactionDto & {
  member?: TransactionMemberDto
  offer?: BoxOfferTransactionDto
}
export type TransactionProgramDto = {
  id: string
  name: string
}
export type UserItemTransactionDto = BaseItemTransactionDto & {
  program?: TransactionProgramDto
  user?: TransactionMemberDto
}

export type TransactionMemberDto = {
  id: string
  firstname: string
  lastname: string
  email: string
  avatar?: HUImageDto
  boxMemberId?: string
  userId: string
  stripeCustomerId?: string
  username?: string
  phone?: string
  address?: TransactionMemberAddressDto
}

export type UserTransactionDto = {
  id: string
  user?: TransactionMemberDto
  invoice?: TransactionInvoiceDto
}

export type MemberTransactionDto = {
  id: string
  member?: TransactionMemberDto
  invoice?: TransactionInvoiceDto
}

type TransactionMemberAddressDto = {
  city: string
  country: string
  line1: string
  line2: string
  postalCode: string
  state: string
}

type TransactionInvoiceDto = {
  number: string
  amountDue: number
  amountPaid: number
  totalVAT: number
  currency: string
  fee: number
  status: string
  program: TransactionProgramDto
  date: string
  lines: {
    description: string
    amount: number
    amountExcludingTax: number
    currency: string
    periodStart: string
    periodEnd: string
  }[]
  invoicePdfUrl: string
  promoCode?: {
    amount: number
    type: PromoCodeType
    name: string
  }
}

export type BoxOfferTransactionDto = {
  id: string
  name: string
  type: BoxOfferType
}

export function convertBoxItemTransactionFromDto(dto: BoxItemTransactionDto): BoxItemTransaction {
  if (!isInArray(allCurrencies, dto.currency)) {
    console.log(LoggerType.Financial, "Currency transactions not supported")
  }
  if (!isInArray(allTransactionsStatus, dto.status)) {
    console.log(LoggerType.Financial, "Status transactions not supported (" + dto.status + ")")
  }
  return {
    id: dto.id,
    date: dto.date,
    member: dto.member ? convertTransactionMemberFromDto(dto.member) : undefined,
    offer: dto.offer ? convertBoxOfferTransactionFromDto(dto.offer) : undefined,
    fee: dto.fee,
    currency: convertToType(allCurrencies, dto.currency as Currency, "EUR"),
    status: dto.status as TransactionStatus,
    disputed: dto.disputed,
    invoiceNumber: dto.invoiceNumber,
    amount: dto.amount,
  }
}

export function convertUserItemTransactionFromDto(dto: UserItemTransactionDto): UserItemTransaction {
  if (!isInArray(allCurrencies, dto.currency)) {
    console.log(LoggerType.Financial, "Currency transactions not supported")
  }
  if (!isInArray(allTransactionsStatus, dto.status)) {
    console.log(LoggerType.Financial, "Status transactions not supported (" + dto.status + ")")
  }
  return {
    id: dto.id,
    date: dto.date,
    user: dto.user ? convertTransactionMemberFromDto(dto.user) : undefined,
    amount: dto.amount,
    fee: dto.fee,
    currency: convertToType(allCurrencies, dto.currency as Currency, "EUR"),
    status: dto.status as TransactionStatus,
    disputed: dto.disputed,
    invoiceNumber: dto.invoiceNumber,
    program: dto.program,
  }
}

export function convertUserTransactionFromDto(dto: UserTransactionDto): UserTransaction {
  return {
    id: dto.id,
    user: dto.user ? convertTransactionMemberFromDto(dto.user) : undefined,
    invoice: dto.invoice ? convertTransactionInvoiceFromDto(dto.invoice) : undefined,
  }
}
export function convertMemberTransactionFromDto(dto: MemberTransactionDto): MemberTransaction {
  return {
    id: dto.id,
    member: dto.member ? convertTransactionMemberFromDto(dto.member) : undefined,
    invoice: dto.invoice ? convertTransactionInvoiceFromDto(dto.invoice) : undefined,
  }
}

export function convertTransactionMemberFromDto(dto: TransactionMemberDto): TransactionMember {
  return {
    id: dto.userId,
    firstname: dto.firstname,
    lastname: dto.lastname,
    email: dto.email,
    stripeCustomerId: dto.stripeCustomerId,
    avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
    username: dto.username,
    phone: dto.phone,
    address: dto.address,
  }
}
export function convertBoxOfferTransactionFromDto(dto: BoxOfferTransactionDto): BoxOfferTransaction {
  return {
    id: dto.id,
    name: dto.name,
    type: dto.type,
  }
}

export function convertTransactionInvoiceFromDto(dto: TransactionInvoiceDto): TransactionInvoice {
  if (!isInArray(allInvoiceStatus, dto.status)) {
    console.log(LoggerType.Financial, "Status transactions not supported (" + dto.status + ")")
  }

  return {
    number: dto.number,
    amountDue: dto.amountDue,
    amountPaid: dto.amountPaid,
    totalVAT: dto.totalVAT,
    currency: convertInvoiceCurrencyFromDto(dto.currency),
    fee: dto.fee,
    status: dto.status as InvoiceStatus,
    program: dto.program,
    date: dto.date,
    lines: dto.lines.map((line) => ({
      description: line.description,
      amount: line.amount,
      amountExcludingTax: line.amountExcludingTax,
      VATAmount: line.amount - line.amountExcludingTax,
      VATPercent: findVATPercentFromAmounts(line.amount, line.amountExcludingTax),
      currency: convertInvoiceCurrencyFromDto(line.currency),
      periodStart: line.periodStart,
      periodEnd: line.periodEnd,
    })),
    invoicePdfUrl: dto.invoicePdfUrl,
    promoCode: dto.promoCode,
  }
}

function convertInvoiceCurrencyFromDto(dto: string): Currency {
  if (!isInArray(allCurrencies, dto)) {
    console.log(LoggerType.Financial, "Currency transactions not supported")
  }
  return convertToType(allCurrencies, dto as Currency, "EUR")
}

function findVATPercentFromAmounts(amount: number, amountExcludingTax: number): string {
  const VATPercent = ((amount - amountExcludingTax) / amount) * 100

  return `${VATPercent}%`
}
