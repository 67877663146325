import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ArrowToTheTopIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="82" height="343" viewBox="0 0 82 343" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6927_373766)">
      <path
        d="M80.4976 81.0093C78.3475 63.1317 73.3512 45.4589 65.5571 28.3861C64.1995 25.4106 57.5424 26.6837 58.9138 29.6837C72.476 59.3806 77.4516 90.8579 73.4408 121.94C69.6919 151.018 57.7974 179.544 38.915 205.494C28.3988 219.948 15.76 233.611 1.1848 246.176C-1.60621 248.586 3.23842 252.054 6.05699 249.625C32.3889 226.923 52.6426 200.875 65.5295 172.949C79.1537 143.418 84.2189 111.96 80.4976 81.0093Z"
        fill={props.color}
      />
      <path
        d="M78.5199 13.4134C67.9003 9.11319 57.2807 4.81298 46.6611 0.512761C44.5455 -0.341768 42.2851 1.80834 42.2989 3.8344C42.3747 16.4663 43.3877 29.0292 45.2208 41.5233C45.8617 45.8924 52.505 44.0387 51.8641 39.6902C50.3687 29.5048 49.5279 19.2435 49.273 8.95469C58.4109 12.6554 67.5489 16.356 76.6868 20.0567C80.7941 21.7175 82.5858 15.0604 78.5199 13.4134Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_6927_373766">
        <rect width="81.3319" height="342.494" fill="white" transform="translate(0.344238 0.320312)" />
      </clipPath>
    </defs>
  </svg>
)
