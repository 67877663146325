import { convertToEnum } from "@modules/utils/enum"

export enum Language {
  ENGLISH_US = "en",
  FRENCH = "fr",
  GERMAN = "de",
  ITALIAN = "it",
  SPANISH = "es",
  PORTUGUESE = "pt",
  GREEK = "el",
  ICELANDIC = "is",
  DANISH = "da",
  DUTCH = "nl",
  BRAZILIAN_PORTUGUESE = "br",
  KOREAN = "ko",
}

export const allLanguages = Object.values(Language)

export function getFormatedLocaleNumber(number: string | number, language: Language) {
  let useComma = true
  switch (language) {
    case Language.ENGLISH_US:
      useComma = false
      break
    default:
      useComma = true
      break
  }
  return useComma ? number.toString().replace(".", ",") : number
}

export function getAvailableLanguages(all: boolean): Language[] {
  const available = [
    Language.FRENCH,
    Language.ENGLISH_US,
    Language.GERMAN,
    Language.ITALIAN,
    Language.SPANISH,
    Language.PORTUGUESE,
    Language.BRAZILIAN_PORTUGUESE,
    Language.KOREAN,
    Language.GREEK,
    Language.DANISH,
    Language.DUTCH,
  ]
  if (all) {
    return available.concat([Language.ICELANDIC])
  } else {
    return available
  }
}

export function getDefaultLanguage(localLanguage: string | undefined, options?: { all: boolean }): Language {
  const best = convertToEnum(Language, localLanguage, Language.ENGLISH_US)
  const languages = getAvailableLanguages(options?.all ?? false)
  return languages.includes(best) ? best : Language.ENGLISH_US
}
