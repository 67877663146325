import { addEvents, DNDPlugin, parents } from "@formkit/drag-and-drop"
import { useDragAndDrop } from "@formkit/drag-and-drop/react"
import { EventPerDay } from "@modules/calendar/calendar"
import { EventType } from "@modules/calendar/calendarTypes"
import { AddEventButton } from "@modules/calendar/components/addEventButton"
import { DefaultCalendarEvent } from "@modules/calendar/components/defaultCalendarEvent"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { dateSeparator, defaultDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { useEffect, useMemo } from "react"
import styled, { useTheme } from "styled-components"

export const CalendarDayColumn: React.FC<{
  eventsPerDay: EventPerDay
  customRenderEvent?: (index: number, event: EventType, displayDetailedEvents: boolean) => React.ReactNode
  onAddEventClick?: (arg0?: dayjs.Dayjs | number) => void
  addEventTitle?: string
  addEventSubtitle?: string
  index: number
  canAddEvents: boolean
  eventLimitPerDay: number
  onEventDragStop?: (dateDest?: dayjs.Dayjs, position?: number) => Promise<void>
  isDragAndDropActive: boolean
}> = ({
  eventsPerDay,
  customRenderEvent,
  onAddEventClick,
  addEventTitle,
  addEventSubtitle,
  canAddEvents,
  eventLimitPerDay,
  onEventDragStop,
  isDragAndDropActive,
}) => {
  const theme = useTheme()
  const { displayDetailedEvents, isUndated, maxDayIndex, draggingEvent, setIsDragging, isDragging } = useCalendar()

  const dragStatusPlugin: DNDPlugin = (parent) => {
    const parentData = parents.get(parent)
    if (!parentData) return

    return {
      setupNode(data) {
        data.nodeData.abortControllers.customPlugin = addEvents(data.node, {
          dragend: () => setIsDragging(false),
        })
      },
      tearDownNode(data) {
        if (data.nodeData?.abortControllers.customPlugin) {
          data.nodeData.abortControllers.customPlugin.abort()
        }
      },
    }
  }

  const canAddEventOnDay = useMemo(() => {
    return (
      canAddEvents &&
      !eventsPerDay.class.includes("past") &&
      eventsPerDay.events.length < eventLimitPerDay &&
      ((isUndated &&
        typeof eventsPerDay.dayIndex !== "undefined" &&
        maxDayIndex &&
        eventsPerDay.dayIndex < maxDayIndex) ||
        !isUndated)
    )
  }, [eventsPerDay, canAddEvents, eventLimitPerDay, isUndated, maxDayIndex])

  const [parentRef, items] = useDragAndDrop<HTMLDivElement, EventType>(eventsPerDay.events, {
    group: "calendarEvents",
    plugins: [dragStatusPlugin],
    disabled: !isDragAndDropActive || eventsPerDay.class.includes("past"),
    dropZone: !eventsPerDay.class.includes("past"),
  })

  useEffect(() => {
    if (!isDragging && draggingEvent && items.findIndex((item) => item.id === draggingEvent.id) >= 0) {
      const position = items.findIndex((item) => item.id === draggingEvent.id)
      onEventDragStop?.(eventsPerDay.date, position)
    }
  }, [isDragging, draggingEvent])

  return (
    <Container
      id={`${dateSeparator}${eventsPerDay.date ? dayjs(eventsPerDay.date).format(defaultDateFormat) : eventsPerDay.dayIndex}`}
      className={"pt-2 pl-0 pr-0 lg:pl-1 lg:pr-1 dayCell " + (isDragging ? " dragging " : "") + eventsPerDay.class}
      style={{
        backgroundColor:
          isUndated && eventsPerDay.dayIndex && maxDayIndex && eventsPerDay.dayIndex >= maxDayIndex
            ? theme.neutralColor50
            : "transparent",
      }}
    >
      <div ref={parentRef} style={{ minHeight: isDragging ? 460 : "auto" }}>
        {items.map((event, eventIndex) =>
          typeof customRenderEvent !== "undefined" ? (
            customRenderEvent(eventIndex, event, displayDetailedEvents)
          ) : (
            <DefaultCalendarEvent
              key={event.id}
              index={eventIndex}
              displayDetailedEvents={displayDetailedEvents}
              event={event}
            />
          ),
        )}
      </div>
      {canAddEventOnDay && onAddEventClick && !isDragging && (
        <AddEventButton
          onClick={() => {
            if (eventsPerDay.date) onAddEventClick(eventsPerDay.date)
            if (typeof eventsPerDay.dayIndex !== "undefined") onAddEventClick(eventsPerDay.dayIndex)
          }}
          displayText={items.length === 0 && !!addEventTitle}
          addEventTitle={addEventTitle}
          addEventSubtitle={addEventSubtitle}
        />
      )}
    </Container>
  )
}

const Container = styled.td`
  vertical-align: top;
  text-align: left;
  word-break: break-word;
  &.past {
    cursor: not-allowed;
  }
  &.past .add {
    display: none;
  }
  > .add {
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;
    margin-top: 20px;
    gap: 10px;
  }
  &:not(.past):not(.dragging):hover .add {
    opacity: 1;
  }
`
