import { Program, SharedCoach, SharedCoachStatus } from "@modules/programs/programTypes"
import { useUser } from "@modules/auth/hooks/useUser"
import { useEffect, useState } from "react"

export const useProgramRights = (program?: Program) => {
  const user = useUser()
  const [sharedCoach, setSharedCoach] = useState<SharedCoach>()
  const [isAcceptedSharedCoach, setIsAcceptedSharedCoach] = useState(false)
  const [isAuthor, setIsAuthor] = useState(false)

  useEffect(() => {
    if (user && program && user.id === program.author.id) {
      setIsAuthor(true)
    }
    if (user && program?.sharedCoaches && program.sharedCoaches.length > 0) {
      setSharedCoach(program.sharedCoaches.find((coach) => coach.user.id === user.id))
      setIsAcceptedSharedCoach(sharedCoach?.status === SharedCoachStatus.ACCEPTED)
    }
  }, [program, sharedCoach?.status, user])

  return { sharedCoach, isAuthor, isAcceptedSharedCoach }
}
