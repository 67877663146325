import { AsyncResult, FetchStrategy, usePaginatedStore, useStore } from "@betomorrow/micro-stores"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { RequestsSASProfile } from "@modules/profile/profileTypes"
import { ProgramInfinite, ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramFilter, ProgramTypeOf } from "@modules/programs/programTypes"
import { useToast } from "@modules/ui/components/huToast"
import { LoggerType } from "@modules/utils/loggerUtils"
import dayjs from "dayjs"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"

export const useProgram = (
  id?: string,
  programType?: typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf,
  fetchStrategy: FetchStrategy = FetchStrategy.First,
): AsyncResult<ProgramOnOff | ProgramInfinite> => {
  const { programInfiniteService, programOnOffService } = useServicesContext()
  const store =
    programType === ProgramOnOffTypeOf
      ? programOnOffService.programOnOffStore
      : programInfiniteService.programInfiniteStore
  // @ts-expect-error - Store from micro-stores cannot handle the union type
  return useStore(id, store, fetchStrategy)
}

export const usePrograms = (options: {
  programFilter?: ProgramFilter
  onlyWithPrice?: boolean
  page?: number
  size?: number
  sort?: "SUBSCRIPTION_DATE" | "CREATION_DATE"
  fetchStrategy?: FetchStrategy
}) => {
  const { programService } = useServicesContext()

  const paginatedProgramsStore = useMemo(() => {
    switch (options.programFilter) {
      case "OWNED":
        return options.onlyWithPrice
          ? programService.paginatedOwnedprogramWithPriceStore
          : programService.paginatedOwnedprogramStore
      case "SUBSCRIBED":
        return programService.paginatedSubscribedprogramStore
      case "SHARED_COACH":
        return programService.paginatedSharedCoachProgramStore
      default:
        return programService.paginatedAllprogramStore
    }
  }, [options.programFilter, options.onlyWithPrice, options.page, options.size])

  return usePaginatedStore(paginatedProgramsStore, options.fetchStrategy)
}

export const useProgramStripeSubscriptionInfos = (
  id?: string,
  programType?: ProgramTypeOf,
  fetchStrategy?: FetchStrategy,
) => {
  const { programInfiniteService, programOnOffService } = useServicesContext()

  const store =
    programType === ProgramOnOffTypeOf
      ? programOnOffService.programOnOffStripeSubscriptionInfosStore
      : programInfiniteService.programInfiniteStripeSubscriptionInfosStore

  return useStore(id, store, fetchStrategy)
}

export const useAcceptSASRequests = (
  programId: string,
  programType?: typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf,
  onEndRequestOperation?: () => void,
) => {
  const { programService } = useServicesContext()
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const onAccept = async (requestIds: string[], acceptAll: boolean) => {
    try {
      setLoading(true)
      if (programType === ProgramInfiniteTypeOf || programType === ProgramOnOffTypeOf)
        await programService.acceptProgramMultipleSASRequests(programId, requestIds, acceptAll)
      else throw new Error("Unknown program type")
      onEndRequestOperation?.()
    } catch (e) {
      toast.show({ severity: "error", detail: word("program.sas.error.text") })
      console.error(LoggerType.Program + "accepting sas request", e)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    onAccept,
  }
}

export const useProgramSASRequests = (options: {
  programId: string
  programType?: typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf
  originDateRequest?: Date
  options?: {
    showError?: boolean
    fetchStrategy?: FetchStrategy
  }
}) => {
  const { programService } = useServicesContext()
  const toast = useToast()
  const [internalOriginDateRequest, setInternalOriginDateRequest] = useState(
    options.originDateRequest ?? dayjs().toDate(),
  )
  const [allRequests, setAllRequests] = useState<readonly RequestsSASProfile[]>([])
  const [totalRequests, setTotalRequests] = useState<number | undefined>(undefined)
  const [refreshing, setRefreshing] = useState(false)

  const programInfiniteSASRequestsStore = useMemo(
    () => programService.getPaginatedProgramSASRequestsStore(options.programId, internalOriginDateRequest),
    [options.programId, internalOriginDateRequest],
  )

  const resultWithCurrentOrigin = usePaginatedStore(programInfiniteSASRequestsStore, options.options?.fetchStrategy)

  useEffect(() => {
    const newResult = _.uniqBy(allRequests.concat(resultWithCurrentOrigin.result), "subscriptionId")
    if (newResult.length !== allRequests.length) {
      setAllRequests(newResult)
    }
    setTotalRequests(totalRequests ?? resultWithCurrentOrigin.totalSize)
  }, [resultWithCurrentOrigin.result])

  const setNewOrignRequest = (requestProfile: RequestsSASProfile) => {
    setAllRequests(allRequests.filter((request) => request.subscriptionId !== requestProfile.subscriptionId))
    if (totalRequests) {
      setTotalRequests(totalRequests - 1)
    }
    setInternalOriginDateRequest(requestProfile.subscriptionDate)
  }

  useEffect(() => {
    if (resultWithCurrentOrigin.error && options.options?.showError) {
      toast.show({ severity: "error", detail: word("program.sas.error.text") })
    }
  }, [resultWithCurrentOrigin.error, options])

  const list = async () => {
    setTotalRequests(undefined)
    setAllRequests([])
    setInternalOriginDateRequest(new Date())
    await resultWithCurrentOrigin.list()
  }

  const refresh = async () => {
    try {
      setRefreshing(true)
      await list()
    } finally {
      setRefreshing(false)
    }
  }

  return {
    ...resultWithCurrentOrigin,
    list,
    result: allRequests,
    totalSize: totalRequests,
    setNewOrignRequest,
    refresh,
    refreshing,
    currentDateRequest: internalOriginDateRequest,
  }
}
