export enum HUErrorCode {
  Unauthorised,
  TokenExpired,
}

export class HUError extends Error {
  readonly code: HUErrorCode
  readonly status: number | undefined

  constructor(_code: HUErrorCode, message: string, status?: number) {
    super(message)
    this.code = _code
    this.status = status
  }
}
