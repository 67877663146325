import { HUFontStyle, HUFontWeight, getFontWeight, primaryFontsAsCss } from "@modules/ui/styles/font"
import styled, { css } from "styled-components"

type TypeText = "label" | "span"

export type commonTextProps = {
  fontStyle?: HUFontStyle
  fontWeight?: HUFontWeight
  color?: string
  numberOfLine?: number
  type?: TypeText
  textStart?: boolean
  textProps?: React.LabelHTMLAttributes<HTMLLabelElement | HTMLSpanElement>
  className?: string
}

type labelTextProps = {
  type: "label"
  textProps?: React.LabelHTMLAttributes<HTMLLabelElement>
} & React.HTMLAttributes<HTMLLabelElement> &
  commonTextProps

type spanTextProps = {
  type?: "span"
  textProps?: React.HTMLAttributes<HTMLSpanElement>
} & React.HTMLAttributes<HTMLSpanElement> &
  commonTextProps

export const HUText: React.FC<labelTextProps | spanTextProps> = ({
  children,
  fontStyle,
  fontWeight,
  color,
  numberOfLine,
  type = "span",
  textStart,
  textProps,
  className,
  ...rest
}) => {
  const Component = type === "label" ? LabelText : SpanText
  if (!Component) return null

  return (
    <Component
      $color={color}
      className={className}
      $fontStyle={fontStyle ?? "BM"}
      $numberOfLine={numberOfLine}
      $fontWeight={fontWeight}
      $textStart={textStart}
      {...textProps}
      {...rest}
    >
      {typeof children === "string" ? <span>{children}</span> : children}
    </Component>
  )
}

const SpanText = styled.span<{
  $fontStyle: HUFontStyle
  $color?: string
  $numberOfLine?: number
  $fontWeight?: HUFontWeight
  $textStart?: boolean
}>`
  color: ${({ $color, theme }) => $color ?? theme.textPrimary};

  ${({ $fontStyle }) => css`
    ${primaryFontsAsCss[$fontStyle]}
  `}
  ${({ $fontWeight }) =>
    $fontWeight &&
    css`
      font-weight: ${getFontWeight($fontWeight)};
    `}
  ${({ $numberOfLine }) =>
    $numberOfLine &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${$numberOfLine};
      overflow: hidden;
    `}
  ${({ $textStart }) =>
    $textStart &&
    css`
      text-align: start;
    `}
`

const LabelText = styled.label<{
  $fontStyle: HUFontStyle
  $color?: string
  $numberOfLine?: number
  $fontWeight?: HUFontWeight
  $textStart?: boolean
}>`
  color: ${({ $color, theme }) => $color ?? theme.textPrimary};
  ${({ $fontStyle }) => css`
    ${primaryFontsAsCss[$fontStyle]}
  `}
  ${({ $fontWeight }) =>
    $fontWeight &&
    css`
      font-weight: ${getFontWeight($fontWeight)};
    `}
  ${({ $numberOfLine }) =>
    $numberOfLine &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${$numberOfLine};
      overflow: hidden;
    `}
  ${({ $textStart }) =>
    $textStart &&
    css`
      text-align: start;
    `}
`
