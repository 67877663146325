import { useServicesContext } from "@modules/core/services/services.context"
import { PromoCode } from "@modules/promoCode/types/promoCodeTypes"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useEffect, useState } from "react"

export const usePromoCodeList = (expired: boolean) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([])
  const [error, setError] = useState("")
  const { promoCodeService } = useServicesContext()

  const fetch = async (): Promise<PromoCode[] | undefined> => {
    let result
    try {
      setLoading(true)
      result = await promoCodeService.getPromoCodes(expired)
      setPromoCodes([...result])
    } catch (e) {
      console.log(LoggerType.Financial, "get promo codes ", JSON.stringify(e))
      setError(JSON.stringify(e))
    } finally {
      setLoading(false)
      return result ? [...result] : undefined
    }
  }

  useEffect(() => {
    fetch()
  }, [expired])

  return { fetch, loading, error, value: promoCodes }
}
