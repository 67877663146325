import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PercentIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g id="percentage">
      <path d="M7.05,17.7a.74.74,0,0,1-.53-.22.75.75,0,0,1,0-1.06l9.9-9.9a.75.75,0,1,1,1.06,1.06l-9.9,9.9A.74.74,0,0,1,7.05,17.7Z" />
      <path d="M8.5,10.75A2.25,2.25,0,1,1,10.75,8.5,2.25,2.25,0,0,1,8.5,10.75Zm0-3a.75.75,0,1,0,.75.75A.76.76,0,0,0,8.5,7.75Z" />
      <path d="M15.5,17.75a2.25,2.25,0,1,1,2.25-2.25A2.25,2.25,0,0,1,15.5,17.75Zm0-3a.75.75,0,1,0,.75.75A.76.76,0,0,0,15.5,14.75Z" />
    </g>
  </svg>
)
