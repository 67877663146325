import { HUImageDto, convertImageFromDto } from "@modules/dtos/imageDto"
import { ExerciseScoreDto, convertExerciseScoreFromDto } from "@modules/exercises/services/exercisesDto"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { ScoreboardMyRanking, ScoreboardRanking } from "@modules/scoreboard/scoreboardTypes"
import { convertToEnum } from "@modules/utils/enum"

import { isEmpty } from "lodash"

export type ScoreboardRankingDto = {
  position: number
  userId: string
  firstname: string
  lastname: string
  score: ExerciseScoreDto
  scoreType: string
  difficulty?: string
  avatar: HUImageDto | null
}

export type ScoreboardMyRankingDto = {
  position: number | null
} & Omit<ScoreboardRankingDto, "position">

export function convertRankingFromDto(dto: ScoreboardRankingDto): ScoreboardRanking {
  return {
    ...dto,
    score: convertExerciseScoreFromDto(dto.score),
    scoreType: convertToEnum(ScoreType, dto.scoreType, ScoreType.NO_SCORE),
    avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
  }
}

export function convertMyRankingFromDto(dto: ScoreboardMyRankingDto | null, id: string): ScoreboardMyRanking | null {
  if (isEmpty(dto)) {
    return null
  } else {
    return {
      ...dto,
      score: convertExerciseScoreFromDto(dto.score),
      scoreType: convertToEnum(ScoreType, dto.scoreType, ScoreType.NO_SCORE),
      avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
      id_difficulty: id,
    }
  }
}
