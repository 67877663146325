import { BoxFormPageProps } from "@modules/box/components/form/boxFormFirstPage"
import { BoxFormTemplatePage } from "@modules/box/components/form/boxFormTemplatePage"
import { word } from "@modules/core/utils/i18n"
import { FormikInput } from "@modules/ui/components/formikInput"

export const BoxFormFifthPage = ({
  handleSubmit,
  onPrevious,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  disabled,
}: BoxFormPageProps) => {
  return (
    <BoxFormTemplatePage
      onPrevious={onPrevious}
      handleSubmit={handleSubmit}
      disabled={disabled}
      pageNumber={5}
      header={{
        title: word("box.creation.page.5.title"),
        description: `${word("box.creation.page.5.description.1")} \n ${word("box.creation.page.5.description.2")}`,
      }}
    >
      <FormikInput
        isRequiredInput
        label={word("box.form.description.label")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("description")}
        name="description"
        error={getFormErrorMessage("description")}
        type="textarea"
        textareaProps={{
          className: "w-full",
          autoResize: true,
          onBlur: (e) => {
            if (formik.handleBlur) formik.handleBlur(e, "description")
          },
          onChange: (e) => {
            if (formik.handleChange) formik.handleChange(e, "description")
          },
        }}
        formikStyle={{ minHeight: "300px" }}
      />
    </BoxFormTemplatePage>
  )
}
