import { ChronoDto, convertChronoFromDto, convertChronoToDto } from "@modules/chrono/services/chronoDtos"
import {
  Exercise,
  ExerciseCategories,
  ExerciseDashboard,
  ExerciseDraft,
  ExerciseScore,
  ExerciseScoreDifficulties,
  ProgramExerciseTypeOf,
  UserExerciseTypeOf,
} from "@modules/exercises/exerciseTypes"
import { ProfileDto, convertProfileFromDto } from "@modules/profile/profileDtos"
import { DashboardScoreDto, ScoreDto } from "@modules/scoreboard/scoreDto"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { convertToEnum, convertToType } from "@modules/utils/enum"

export type ExerciseScoreDto = {
  value: number
  difficulty: string
  note?: string
}

export type ExerciseScoreInputDto = {
  score: number
  difficulty: string
  note?: string
}

type ItemCommonExerciseDto = {
  id: string
  title: string
  category: string
  chrono?: ChronoDto
  isFavorite: boolean
  type: typeof ProgramExerciseTypeOf | typeof UserExerciseTypeOf
  owner?: ProfileDto
  rpe: number | null
}

type CommonExerciseDto = {
  description: string
} & ItemCommonExerciseDto

export type ItemExerciseDto = {
  type: typeof ProgramExerciseTypeOf
} & ItemCommonExerciseDto

export type ExerciseDto = {
  type: typeof ProgramExerciseTypeOf
  isCompleted: boolean
  scoreType?: string
  score: ScoreDto
} & CommonExerciseDto

type CommonExerciseDraftDto = {
  id: string | null
  title: string
  description: string
  category: string
  chrono?: ChronoDto
}

export type ExerciseDraftDto = {
  scoreType: string
} & CommonExerciseDraftDto

export type ExerciseDashboardDto = {
  totalScore: number
  topUsers: {
    score: DashboardScoreDto
    user: ProfileDto
  }[]
  lastRecordUsers: {
    score: DashboardScoreDto
    user: ProfileDto
  }[]
}

function convertItemCommonExerciseFromDto(dto: ItemExerciseDto) {
  return {
    id: dto.id,
    _type: dto.type,
    title: dto.title,
    category: convertToType(ExerciseCategories, dto.category, "OTHER"),
    chrono: dto.chrono ? convertChronoFromDto(dto.chrono) : undefined,
    isFavorite: dto.isFavorite,
    creator: dto.owner ? convertProfileFromDto(dto.owner) : undefined,
    rpe: dto.rpe,
  }
}

function convertCommonExerciseFromDto(dto: ExerciseDto) {
  return {
    ...convertItemCommonExerciseFromDto(dto),
    description: dto.description,
  }
}

export function convertExerciseFromDto(dto: ExerciseDto): Exercise {
  return {
    ...convertCommonExerciseFromDto(dto),
    _type: ProgramExerciseTypeOf,
    isCompleted: dto.isCompleted ?? false,
    scoreType: convertToEnum(ScoreType, dto.scoreType, ScoreType.NO_SCORE),
    score: dto.score,
  }
}

function convertCommonExerciseToDto(draft: ExerciseDraft) {
  return {
    id: draft.id,
    title: draft.title,
    description: draft.description,
    category: draft.category,
    chrono: draft.chrono ? convertChronoToDto(draft.chrono) : undefined,
    rpe: draft.rpe,
  }
}

export function convertExerciseToDto(draft: ExerciseDraft): ExerciseDraftDto {
  return { ...convertCommonExerciseToDto(draft), scoreType: draft.scoreType ?? ScoreType.NO_SCORE }
}

export function convertExerciseScoreFromDto(dto: ExerciseScoreDto): ExerciseScore {
  return {
    value: dto.value,
    difficulty: convertToType(ExerciseScoreDifficulties, dto.difficulty, "RX"),
    note: dto.note || undefined,
  }
}

export function convertExerciseDashboardFromDto(dto: ExerciseDashboardDto): ExerciseDashboard {
  return {
    ...dto,
    topUsers: dto.topUsers.map((topUser) => ({
      user: convertProfileFromDto(topUser.user),
      score: {
        value: topUser.score.value,
        type: topUser.score.type as ScoreType,
      },
    })),
    lastRecordUsers: dto.lastRecordUsers.map((lastRecordUser) => ({
      ...lastRecordUser,
      user: convertProfileFromDto(lastRecordUser.user),
      score: {
        value: lastRecordUser.score.value,
        type: lastRecordUser.score.type as ScoreType,
      },
    })),
  }
}
