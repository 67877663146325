type Props = {
  color: string
  width?: number
  height?: number
}

export const CameraIcon: React.FC<Props> = ({ color, width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 19" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 1.5H9L6.5 4.5H3.5C2.96957 4.5 2.46086 4.71071 2.08579 5.08579C1.71071 5.46086 1.5 5.96957 1.5 6.5V15.5C1.5 16.0304 1.71071 16.5391 2.08579 16.9142C2.46086 17.2893 2.96957 17.5 3.5 17.5H19.5C20.0304 17.5 20.5391 17.2893 20.9142 16.9142C21.2893 16.5391 21.5 16.0304 21.5 15.5V6.5C21.5 5.96957 21.2893 5.46086 20.9142 5.08579C20.5391 4.71071 20.0304 4.5 19.5 4.5H16.5L14 1.5Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 13.5C13.1569 13.5 14.5 12.1569 14.5 10.5C14.5 8.84315 13.1569 7.5 11.5 7.5C9.84315 7.5 8.5 8.84315 8.5 10.5C8.5 12.1569 9.84315 13.5 11.5 13.5Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
