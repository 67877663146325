import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { useObservable } from "micro-observables"
import { InputSwitch } from "primereact/inputswitch"
import styled from "styled-components"

type Props = {
  visible: boolean
  setVisible: (b: boolean) => void
}

export const DebugDialog: React.FC<Props> = ({ visible, setVisible }) => {
  const { featureToggleService } = useServicesContext()
  const featureToggles = useObservable(featureToggleService.featureToggles)
  return (
    <HUDialog
      visible={visible}
      header="Activer les features"
      headerSeparator
      footer={
        <>
          <HUButton
            type="Left_Icon"
            colorType="Primary"
            size="L"
            text={word("global.check")}
            icon={{ iconView: <span className="pi pi-check" /> }}
            onClick={() => setVisible(false)}
            className="align-self-end"
          />
        </>
      }
      onHide={() => setVisible(false)}
    >
      <Content>
        {featureToggles.map((feature) => (
          <FeatureItem key={feature.key}>
            <label>{feature.key}</label>
            <InputSwitch
              checked={feature.isEnabled}
              onChange={(e) => featureToggleService.updateFeatureToggle({ key: feature.key, isEnabled: !!e.value })}
            />
          </FeatureItem>
        ))}
        <HUButton
          type="Default"
          size="M"
          className="w-4 mt-2"
          colorType="Secondary"
          text="Simulate crash"
          onClick={() => {
            throw new Error("Sentry Test Error")
          }}
        />
      </Content>
    </HUDialog>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
`

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
