import { word } from "@modules/core/utils/i18n"
import { convertTimeStringToTimeValue, convertTimeValueToTimeString } from "@modules/duration/timeConverter"
import { AmrapRoundAndRepScoreType } from "@modules/exercises/exerciseFormikTypes"
import { Language } from "@modules/language/utils/languageUtils"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { convertTimeChrono } from "@modules/utils/chronoConvertUtils"
import { convertDistanceFromMeter, convertDistanceFromMeterText } from "@modules/utils/distanceConversionUtils"
import { HUUnit } from "@modules/utils/unitType"
import {
  convertWeightFromGram,
  convertWeightFromGramText,
  convertWeightFromInput,
} from "@modules/utils/weightConvertionUtils"
import convert from "convert-units"

export function getScoreTypesArray(): { label: string; value: ScoreType }[] {
  return [
    { label: word("global.score.no_score"), value: ScoreType.NO_SCORE },
    { label: word("global.score.time.label"), value: ScoreType.TIME },
    {
      label: word("global.score.round_and_repetitions.label"),
      value: ScoreType.ROUND_AND_REPETITIONS,
    },
    {
      label: word("global.score.repetitions.label"),
      value: ScoreType.REPETITIONS,
    },
    {
      label: word("global.score.weight.label"),
      value: ScoreType.WEIGHT,
    },
    {
      label: word("global.score.calories.label"),
      value: ScoreType.CALORIES,
    },
    {
      label: word("global.score.distance.label"),
      value: ScoreType.DISTANCE,
    },
  ]
}

export const getDisplayedScore = (score: number, scoreType: ScoreType, unit: HUUnit, language: Language) => {
  switch (scoreType) {
    case ScoreType.TIME:
      return convertTimeChrono(score)
    case ScoreType.CALORIES:
      return `${score} cal`
    case ScoreType.DISTANCE: {
      return convertDistanceFromMeterText(score, unit, language)
    }
    case ScoreType.REPETITIONS:
      return `${score} ${word("global.score.repetition.unit", {
        count: score,
      })}`
    case ScoreType.ROUND_AND_REPETITIONS: {
      const { roundLabel, repLabel } = getRoundRepLabel(score)

      if (roundLabel && repLabel) {
        return `${roundLabel} ${word("global.score.round_and_repetitions.separator")} ${repLabel}`
      } else {
        return `${roundLabel ?? repLabel}`
      }
    }
    case ScoreType.WEIGHT:
      return convertWeightFromGramText(score, unit, language)
    case ScoreType.NO_SCORE:
      return ""
  }
}

export const getRoundRepLabel = (score: number) => {
  const { round, rep } = extractRoundAndRepOnScoreValue(score)
  const roundLabel = round
    ? `${round} ${word("global.score.round.unit", {
        count: round,
      })}`
    : undefined
  const repLabel = rep ? `${rep} ${word("global.score.repetition.unit", { count: rep })}` : undefined

  return {
    roundLabel,
    repLabel,
  }
}

export const extractRoundAndRepOnScoreValue = (value: number): { round: number; rep: number } => {
  return {
    round: Math.trunc(value / 1000),
    rep: Math.trunc(value % 1000),
  }
}

export const getScoreUnit = (type: ScoreType.WEIGHT | ScoreType.DISTANCE, unit: HUUnit) => {
  switch (type) {
    case ScoreType.WEIGHT:
      return unit === "IMPERIAL" ? "lb" : "kg"
    case ScoreType.DISTANCE:
      return unit === "IMPERIAL" ? "mi" : "m"
  }
}
export const mergeRoundAndRepOnScoreValue = (round: number, rep: number): number => {
  return round * 1000 + rep
}

export const convertScoreFromInput = (
  input: string | number | AmrapRoundAndRepScoreType,
  scoreType: ScoreType,
  unit: HUUnit,
): number => {
  if (typeof input === "object") {
    if (scoreType === ScoreType.ROUND_AND_REPETITIONS) return mergeRoundAndRepOnScoreValue(input.round, input.rep)
    else return 0
  } else {
    switch (scoreType) {
      case ScoreType.TIME:
        return typeof input === "string" ? convertTimeStringToTimeValue("mm:ss", input) ?? 0 : input
      case ScoreType.CALORIES:
      case ScoreType.REPETITIONS:
        return typeof input === "number" ? input : 0
      case ScoreType.DISTANCE:
        return convert(typeof input === "number" ? input : undefined)
          .from(unit === "IMPERIAL" ? "mi" : "m")
          .to("mm")
      case ScoreType.WEIGHT:
        return convertWeightFromInput(typeof input === "number" ? input : 0, unit)
      case ScoreType.NO_SCORE:
      case ScoreType.ROUND_AND_REPETITIONS:
        return 0
    }
  }
}

export const convertScoreToInputValue = (score: number | undefined, scoreType: ScoreType, unit: HUUnit) => {
  switch (scoreType) {
    case ScoreType.TIME:
      return convertTimeValueToTimeString(score ?? 0) ?? 0
    case ScoreType.CALORIES:
    case ScoreType.REPETITIONS:
      return score ?? 0
    case ScoreType.ROUND_AND_REPETITIONS:
      return score ? extractRoundAndRepOnScoreValue(score) : { round: 0, rep: 0 }
    case ScoreType.DISTANCE:
      return score ? convertDistanceFromMeter(score, unit) : 0
    case ScoreType.WEIGHT:
      return score ? convertWeightFromGram(score, unit) : 0
    case ScoreType.NO_SCORE:
      return 0
    default:
      return 0
  }
}
