import { useWindowSize } from "@modules/utils/hooks/useWindowsSize"
import linkifyit from "linkify-it"
import { Dialog } from "primereact/dialog"
import React, { useState } from "react"
import styled from "styled-components"
const linkify = linkifyit()

export type LinkifyTextProps = {
  content: string
  youtubeProps?: { allowEmbed: boolean; keepUrl?: boolean }
}

export const Linkify: React.FC<LinkifyTextProps> = ({ content, youtubeProps }) => {
  const [shownVideoUrl, setShownVideoUrl] = useState<string>()

  const isYoutubeUrl = (word: string) => {
    const urlPattern =
      /^((?:https?:)?\/\/)?((?:www)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm
    return word.match(urlPattern)
  }

  const getYouTubeVideoIdFromUrl = (url: string) => {
    // Our regex pattern to look for a youTube ID
    const regExp = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm
    //Match the url with the regex
    const match = regExp.exec(url)
    //Return the result
    return match && match[3].length === 11 ? match[3] : undefined
  }

  const elements = []
  let _lastIndex = 0

  linkify.match(content)?.forEach(({ index, lastIndex, text, url }) => {
    const nonLinkedText = content.substring(_lastIndex, index)
    nonLinkedText && elements.push(nonLinkedText)
    _lastIndex = lastIndex

    if (isYoutubeUrl(url) && youtubeProps?.allowEmbed) {
      elements.push(
        <StyledYoutubeLink
          key={url + index}
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShownVideoUrl(url)
          }}
        >
          {youtubeProps.keepUrl ? url : "youtube"}
        </StyledYoutubeLink>,
      )
    } else
      elements.push(
        <a key={url + index} target="_blank" href={url} rel="noreferrer">
          {isYoutubeUrl(url) ? "youtube" : text}
        </a>,
      )
  })

  elements.push(content.substring(_lastIndex, content.length))

  const { width } = useWindowSize()
  const videoWidth = width * 0.8
  const videoHeight = (videoWidth * 9) / 16

  return (
    <>
      {!!shownVideoUrl && (
        <Dialog
          className="min-w-0"
          modal
          blockScroll
          onShow={() => {
            document.body.classList.add("p-overflow-hidden")
          }}
          onHide={() => {
            document.body.classList.remove("p-overflow-hidden")
            setShownVideoUrl(undefined)
          }}
          style={{ width: videoWidth, height: videoHeight, padding: 0 }}
          contentStyle={{ minWidth: videoWidth, height: videoHeight, padding: 0, overflow: "hidden", borderRadius: 5 }}
          visible={true}
          showHeader={false}
          dismissableMask={true}
        >
          <iframe
            src={`https://www.youtube.com/embed/${getYouTubeVideoIdFromUrl(shownVideoUrl)}?rel=0?version=3&autoplay=1&autopause=0&muted=1&controls=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            width="100%"
            height="100%"
            style={{ border: 0 }}
          />
        </Dialog>
      )}
      {elements}
    </>
  )
}

const StyledYoutubeLink = styled.span`
  color: ${({ theme }) => theme.textLink};
  cursor: pointer;
`
