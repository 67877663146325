import { Page } from "@betomorrow/micro-stores"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { RequestsSASProfileDto } from "@modules/profile/profileDtos"
import { ItemProgram, Program, ProgramFilter, ProgramPrice } from "@modules/programs/programTypes"
import {
  convertItemProgramFromDto,
  convertProgramFromDto,
  ItemProgramDto,
  ProgramDto,
} from "@modules/programs/services/programDto"
import { ProgramInfiniteDto } from "@modules/programs/services/programInfiniteDto"
import { ProgramOnOffDto } from "@modules/programs/services/programOnOffDto"
import { Paginated, toPage } from "@modules/utils/paginated"
import { ListOrder } from "@modules/utils/types"
import dayjs from "dayjs"

export class ProgramApi {
  private baseUrl = "/programs/user-programs"
  private programBaseUrl = "programs"
  private buildSASRequestUrl = (programId: string) => `${this.programBaseUrl}/${programId}/requests`

  constructor(private api: ApiClient) {}

  async getProgram(id: string): Promise<Program> {
    const resultDto = await this.api.get<ProgramDto>(`${this.baseUrl}/${id}`)

    return convertProgramFromDto(resultDto.data)
  }

  async getPrograms(
    filter: ProgramFilter,
    order: ListOrder,
    page = 0,
    size = 100,
    onlyWithPrice = false,
  ): Promise<Page<ItemProgram>> {
    const result = await this.api.get<Paginated<ItemProgramDto>>(`${this.baseUrl}`, {
      params: {
        page,
        size,
        onlyWithPrice,
        filter,
        order,
      },
    })
    const pageResult = toPage(result.data)

    return {
      ...pageResult,
      content: pageResult.content.map((program) => convertItemProgramFromDto(program)),
    }
  }

  async getProgramSASRequests(id: string, startDate?: Date, page = 0, size = 12) {
    const result = await this.api.get<Paginated<RequestsSASProfileDto>>(this.buildSASRequestUrl(id), {
      params: {
        startDate: dayjs(startDate).toISOString(),
        page,
        size,
      },
    })
    const pageResult = toPage(result.data)

    return pageResult
  }

  async updateProgramPrice(programId: string, price: ProgramPrice, trialDuration?: number) {
    const result = await this.api.post<ProgramInfiniteDto | ProgramOnOffDto>(
      `${this.programBaseUrl}/${programId}/price`,
      { price, trialDuration },
    )

    return result.data
  }

  async removeProgramPrice(programId: string) {
    const result = await this.api.delete<ProgramInfiniteDto | ProgramOnOffDto>(
      `${this.programBaseUrl}/${programId}/price`,
    )
    return result.data
  }

  async acceptProgramMultipleSASRequests(programId: string, requestIds: string[], acceptAll: boolean) {
    await this.api.post<{ status: string }>(
      `${this.buildSASRequestUrl(programId)}/accept-multiple`,
      !acceptAll ? { requestIds } : {},
    )
  }

  async acceptProgramSASRequest(programId: string, requestId: string) {
    await this.api.post<string>(`${this.buildSASRequestUrl(programId)}/${requestId}/accept`, { requestId })
  }

  async rejectProgramSASRequest(programId: string, requestId: string) {
    await this.api.post<string>(`${this.buildSASRequestUrl(programId)}/${requestId}/reject`, { requestId })
  }

  async acceptSharedCoach(programId: string) {
    await this.api.post(`${this.programBaseUrl}/${programId}/shared-coach/accept`)
  }

  async refuseSharedCoach(programId: string) {
    await this.api.post(`${this.programBaseUrl}/${programId}/shared-coach/refuse`)
  }

  async quitSharedCoach(programId: string) {
    await this.api.post(`${this.programBaseUrl}/${programId}/shared-coach/quit`)
  }

  async removeSharedCoach(programId: string, coachId: string) {
    await this.api.delete(`${this.programBaseUrl}/${programId}/shared-coach/${coachId}`)
  }
}
