import { ClockOutlineIcon } from "@images/svgIcons/clockOutlineIcon"
import { EmailIcon } from "@images/svgIcons/emailIcon"
import { PhoneIcon } from "@images/svgIcons/phoneIcon"
import { LocationBox } from "@modules/box/components/locationBox"
import { useBox } from "@modules/box/useBox"
import { word } from "@modules/core/utils/i18n"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { formatPhoneNumber } from "@modules/utils/numberUtils"
import { CountryCode } from "libphonenumber-js"
import styled, { useTheme } from "styled-components"
type BoxInfoResumeProps = {
  boxId: string
}

export const BoxInfoResume: React.FC<BoxInfoResumeProps> = ({ boxId }) => {
  const theme = useTheme()
  const { result: box } = useBox(boxId)
  return (
    <>
      {box ? (
        <HeaderContainer>
          <div className="relative flex flex-column justify-content-center">
            <ImageContainer
              $bgImage={box.cover.path}
              className="p-5 mb-2 flex-row justify-content-center align-items-center overflow-hidden gap-2"
            >
              <GradientOverlay />
            </ImageContainer>
            <BoxAvatarStyled className="" profile={{ avatar: box.logo, username: box.name || "" }} size={98} />
          </div>
          <TitleContainer>
            <HUText fontStyle="HS" className="mt-4">
              {box.name}
            </HUText>
            <div className="flex align-items-center mt-2">
              <HUText fontStyle="BM">{word("global.box.name")}</HUText>
              <HUText fontStyle="LL" className="mx-2">
                {` · ${box.address.city}`}
              </HUText>
              <HUText fontStyle="BM">{box.address.postalCode}</HUText>
            </div>
            <div className="mt-2 mb-2">
              <HUText fontStyle="BM">{box.description}</HUText>
            </div>
            <HUButton
              size="L"
              type="Left_Icon"
              colorType="Primary"
              icon={{ icon: ClockOutlineIcon, iconProps: { color: theme.neutralColor400 } }}
              text={word("menu.coming_soon")}
              disabled={true}
              style={{ width: "100%" }}
            />
            {/* <HUButton type="button" colorType="Primary" text={word("box.offers.page.title")} className="mt-4 w-full" /> */}
            <Separator />
          </TitleContainer>
          <ContactContainer>
            <HUText fontStyle="TS" className="mb-2">
              {word("box.form.contact.title")}
            </HUText>
            <div className="flex align-items-center mb-2">
              <Round>
                <EmailIcon color={theme.neutralColor900} width={15} height={15} />
              </Round>
              <HUText fontStyle="BM">{box.email}</HUText>
            </div>
            <div className="flex align-items-center ">
              <Round>
                <PhoneIcon color={theme.neutralColor900} />
              </Round>
              <HUText fontStyle="BM">
                {formatPhoneNumber(
                  box.phoneNumberWithPrefix.number,
                  box.phoneNumberWithPrefix.countryCode as CountryCode,
                )}
              </HUText>
            </div>
          </ContactContainer>
          <LocationContainer>
            <HUText fontStyle="TS" className="mb-3">
              {word("box.where.address.title")}
            </HUText>
            <LocationBox box={box} />
          </LocationContainer>
        </HeaderContainer>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

const HeaderContainer = styled.div``

const Round = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.neutralColor200};
  margin-right: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 14px;
  margin-right: 14px;
`
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 20px;
`
const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  margin-right: 14px;
`

const ImageContainer = styled(HURow)<{ $bgImage?: string }>`
  position: relative;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  border-width: 0px;
  height: 290px;
  width: 100%;
  background-image: ${({ $bgImage }) => `url(${$bgImage})`};
  background-size: cover;
  background-position: center;
`
const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.8) 99.9%);
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  border-width: 0px;
  height: 290px;
`
const BoxAvatarStyled = styled(ProfileAvatar)`
  position: absolute;
  top: 234px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 30px;
  text-align: center;
  img {
    border: 4px solid white !important;
    background-color: ${({ theme }) => theme.mainWhite};
  }
`
const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.neutralColor200};
`
