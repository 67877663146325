import { NoteEmptyIcon } from "@images/svgIcons/essentials/noteEmptyIcon"
import { NoteIcon } from "@images/svgIcons/essentials/noteIcon"
import { HUText } from "@modules/ui/components/huText"
import { shortenText } from "@modules/utils/stringUtils"
import { Tooltip } from "primereact/tooltip"
import { useTheme } from "styled-components"

export type HuNoteProps = {
  index: string
  note: string
  className?: string
} & React.HTMLAttributes<HTMLDivElement>

/**
 * HuNote component - Display a note with a tooltip
 * @param {HuNoteProps} props - Component props
 * @param {string} props.index - The index for link Tooltip to icon
 * @param {string} props.note - The note to display, if note is empty, display empty note icon
 * @param {string} props.className - The class name to apply on main div
 * @returns {React.JSX.Element} - The HuNote component
 */
const HuNote: React.FC<HuNoteProps> = ({ index, note = "", className, ...props }) => {
  const theme = useTheme()
  return (
    <div className={`${className}`} {...props}>
      {note ? (
        <>
          <HUText
            className={`tooltip${index}`}
            data-pr-showdelay={100}
            data-pr-hidedelay={100}
            data-pr-position="bottom"
            color="white"
            fontStyle="TS"
          >
            <Tooltip className="tooltip-container" target={`.tooltip${index}`}>
              <div className="tooltip-content text-justify">
                <HUText type="label" fontStyle="BM">
                  {shortenText(note, 250)}
                </HUText>
              </div>
            </Tooltip>
            <NoteIcon size={16} color={theme.textPrimary} data-pr-tooltip={note} />
          </HUText>
        </>
      ) : (
        <NoteEmptyIcon size={16} color={theme.textPrimary} />
      )}
    </div>
  )
}

HuNote.displayName = "HuNote"
export { HuNote }
