import { useUser } from "@modules/auth/hooks/useUser"
import { Config } from "@modules/config"
import { word } from "@modules/core/utils/i18n"
import { DebugDialog } from "@modules/debug/debugDialog"
import { useDebug } from "@modules/debug/useDebug"
import { ProfileForm } from "@modules/profile/components/profileForm"
import { ErrorCard } from "@modules/ui/components/error/errorCard"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { useDraft } from "@modules/utils/useDraft"
import { useTheme } from "styled-components"

export const ProfilePage: React.FC = () => {
  const theme = useTheme()
  const user = useUser()
  const { draft: userDraft } = useDraft(() => user)
  const { visible, setVisible, onDebugClick } = useDebug()

  return (
    <HUStack gap={34} style={{ width: "100%" }}>
      <HUStack gap={6}>
        <HUText fontStyle="HL">{word("profile.page.title")}</HUText>
        <HUText fontStyle="BM" color={theme.neutralColor600}>
          {word("profile.page.subtitle")}
        </HUText>
      </HUStack>
      <>{userDraft ? <ProfileForm user={userDraft} /> : <ErrorCard />}</>
      <div className="flex flex-column mx-auto md:mx-0 align-items-center mt-6" onClick={onDebugClick}>
        {Config.ENV !== "prod" ? Config.ENV + "-" : ""}
        {__VERSION__}-{__GIT_HASH__}
      </div>
      <DebugDialog visible={visible} setVisible={setVisible} />
    </HUStack>
  )
}
