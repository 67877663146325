import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const CircleTimeIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_466_91511)">
      <path
        d="M14.5543 7.42237C15.0512 7.35636 15.4432 6.88181 15.3119 6.39809C15.1506 5.80367 14.9198 5.22637 14.6194 4.70097C14.3668 4.25912 13.7846 4.18793 13.3774 4.49331C12.961 4.80557 12.8867 5.39242 13.1266 5.85424C13.2542 6.0999 13.3617 6.35418 13.4463 6.61709C13.607 7.11645 14.0343 7.49145 14.5543 7.42237Z"
        fill={props.color}
      />
      <path
        d="M11.9657 3.16781C12.278 2.77749 12.2166 2.20309 11.7895 1.94335C11.2594 1.62096 10.676 1.35774 10.0752 1.17759C9.59508 1.03365 9.12006 1.42519 9.05405 1.92203C8.98497 2.44204 9.35997 2.86938 9.85933 3.03007C10.1428 3.12129 10.4163 3.23907 10.6797 3.38009C11.1107 3.61079 11.6603 3.54954 11.9657 3.16781Z"
        fill={props.color}
      />
      <path
        d="M8.0771 13.9661C5.0153 13.9661 2.51018 11.461 2.51018 8.39914C2.51018 5.93951 4.1765 3.76968 6.42081 3.042C6.91233 2.88263 7.27176 2.41164 7.19511 1.90064C7.1189 1.39254 6.64111 1.03449 6.147 1.17531C2.98194 2.07731 0.654541 5.00549 0.654541 8.39914C0.654541 12.4816 3.99469 15.8217 8.0771 15.8217C11.4847 15.8217 14.423 13.5533 15.2401 10.3093C15.3631 9.8211 15.0096 9.36016 14.5117 9.28547C13.9885 9.20699 13.513 9.58588 13.3592 10.0921C12.6829 12.3179 10.5233 13.9661 8.0771 13.9661Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_466_91511">
        <rect width="15.9769" height="15.9769" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
