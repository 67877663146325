import {
  BOX_OFFER_MINIMUM_PRICE,
  BOX_OFFER_TICKET_BOOKS_MAX,
  BOX_OFFER_TICKET_BOOKS_MIN,
  BOX_OFFER_VALIDITY_MIN,
  BOX_OFFER_WEEKLY_PARTICIPATION_MAX,
  BOX_OFFER_WEEKLY_PARTICIPATION_MIN,
  BoxOfferDraft,
  BoxOfferPriceType,
  BoxOfferType,
  BoxOfferValidity,
  BoxOfferWeeklyParticipationType,
} from "@modules/boxOffer/boxOfferTypes"
import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { requiredInput } from "@modules/form/formUtils"
import * as Yup from "yup"

export type BoxOfferInputKey = keyof BoxOfferDraft

export function getValidationSchema(language: string, pageNumber: number) {
  return pageNumber === 1 ? firstPageValidationSchema(language) : secondPageValidationSchema()
}

const firstPageValidationSchema = (language: string) => {
  const countErrorMessage = word("offer.form.count.error", {
    min: BOX_OFFER_TICKET_BOOKS_MIN,
    max: BOX_OFFER_TICKET_BOOKS_MAX,
  })
  return Yup.object({
    type: Yup.string().oneOf(Object.values(BoxOfferType)),
    name: requiredInput(
      Yup.string()
        .max(64, word("form.maximum.characters", { count: 64 }))
        .label(word("offer.form.name.label")),
    ),
    description: requiredInput(Yup.string().label(word("offer.form.description.label"))),
    priceSwitch: Yup.string().required(),
    price: Yup.number()
      .nullable()
      .test((value, context) =>
        (value && value >= BOX_OFFER_MINIMUM_PRICE) || context.parent.priceSwitch === BoxOfferPriceType.Free
          ? true
          : new Yup.ValidationError(
              word("offer.form.price.error", {
                minPrice: formatCurrency(BOX_OFFER_MINIMUM_PRICE, context.parent.currency, language),
              }),
              value,
              context.path,
            ),
      ),
    count: Yup.number().when("type", (type, schema) => {
      return type[0] === BoxOfferType.TicketsBook
        ? schema
            .required(word("global.form.required"))
            .min(BOX_OFFER_TICKET_BOOKS_MIN, countErrorMessage)
            .max(BOX_OFFER_TICKET_BOOKS_MAX, countErrorMessage)
        : schema.nullable()
    }),
    currency: Yup.string().required(),
    minimumMonthlyDuration: Yup.number().when("type", {
      is: (value: BoxOfferType) => [BoxOfferType.Subscription].includes(value),
      then: (schema) => schema.required(),
    }),
    weeklyParticipationSwitch: Yup.string().required(),
    maxWeeklyParticipation: Yup.number()
      .min(BOX_OFFER_WEEKLY_PARTICIPATION_MIN)
      .max(BOX_OFFER_WEEKLY_PARTICIPATION_MAX)
      .nullable()
      .test(
        (value, context) =>
          !!value || context.parent.weeklyParticipationSwitch === BoxOfferWeeklyParticipationType.Unlimited,
      ),
    validitySwitch: Yup.string().required(),
    validityPeriodMonths: Yup.number()
      .nullable()
      .test((value, context) =>
        (value && value >= BOX_OFFER_VALIDITY_MIN) || context.parent.validitySwitch === BoxOfferValidity.UNLIMITED
          ? true
          : new Yup.ValidationError(word("global.form.required"), value, context.path),
      ),
  })
}

const secondPageValidationSchema = () => {
  return Yup.object({
    programs: Yup.array().of(Yup.string()).min(1, word("box.offer.error.programs")).required(),
    rules: Yup.array().when("type", {
      is: (value: BoxOfferType) => [BoxOfferType.TicketsBook, BoxOfferType.Millenium].includes(value),
      then: (schema) => schema.min(1, word("global.form.required")).required(word("global.form.required")),
    }),
    count: Yup.number().when("type", {
      is: BoxOfferType.Trial,
      then: (schema) => schema.required(word("global.form.required")),
    }),
    renewable: Yup.boolean().when("type", {
      is: BoxOfferType.Trial,
      then: (schema) => schema.required(),
    }),
  })
}
