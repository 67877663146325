import { LayoutProvider } from "@modules/app/context/layoutcontext"
import { Config } from "@modules/config"
import { useServicesContext } from "@modules/core/services/services.context"
import { useDebug } from "@modules/debug/useDebug"
import { FeatureKeys } from "@modules/featureToggle/featureToggleTypes"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { WebNavigation } from "@modules/navigation/navigation"
import { useColorTheme, useThemeChanger } from "@modules/profile/useColorTheme"
import { HUToast } from "@modules/ui/components/huToast"
import { HUConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { HUPopup } from "@modules/ui/components/popup/huPopup"
import SplashScreen from "@modules/ui/components/splashScreen"
import { athleteTheme, ownerTheme } from "@modules/ui/styles/theme"
import { LoggerType } from "@modules/utils/loggerUtils"
import { PrimeReactProvider } from "primereact/api"
import { useEffect, useState } from "react"
import { ThemeProvider } from "styled-components"

export function App() {
  useLanguage()
  const [init, setInit] = useState(false)
  const [displaySplashScreen, setDisplaySplashScreen] = useState<boolean>(false)

  const colorScheme = useColorTheme()
  const changingTheme = useThemeChanger()
  const { featureToggleService, serviceManager } = useServicesContext()

  const initApp = async () => {
    setInit(false)
    console.log(LoggerType.Config + `use ${JSON.stringify(Config, null, "\t")}`)
    await serviceManager.init()
    setInit(true)
  }

  const { canUseDebug } = useDebug()

  /* Tmp: enable box features by default */
  useEffect(() => {
    if (canUseDebug) {
      if (!featureToggleService.getLocalStorageValue().find((e) => e.key === FeatureKeys.Box)) {
        featureToggleService.updateFeatureToggle({ key: FeatureKeys.Box, isEnabled: true })
      }
    }
  }, [canUseDebug])

  useEffect(() => {
    initApp().catch((e) => console.error(LoggerType.Error + "[Boot] init app", e))
  }, [])

  useEffect(() => {
    setDisplaySplashScreen(true)
    setTimeout(
      () => {
        setDisplaySplashScreen(false)
      },
      changingTheme?.nextTheme === "loggingout" ? 2000 : 1000,
    )
  }, [changingTheme?.nextTheme])

  const theme = colorScheme === "athlete" ? athleteTheme : ownerTheme

  if (!init) return null

  return (
    <ThemeProvider theme={theme}>
      <PrimeReactProvider value={{ ripple: false }}>
        <LayoutProvider>
          <HUConfirmPopup>
            <HUToast>
              <HUPopup>
                {displaySplashScreen && changingTheme?.nextTheme && (
                  <SplashScreen nextTheme={changingTheme.nextTheme} />
                )}
                <WebNavigation />
              </HUPopup>
            </HUToast>
          </HUConfirmPopup>
        </LayoutProvider>
      </PrimeReactProvider>
    </ThemeProvider>
  )
}
