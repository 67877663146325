import { BoxB2BProgramApi } from "@modules/boxB2BProgram/services/boxB2BProgramApi"
import { Service } from "@modules/core/services/serviceType"

export class BoxB2BProgramService implements Service {
  constructor(private api: BoxB2BProgramApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async getBoxB2BPrograms() {
    return this.api.getBoxB2BPrograms()
  }

  async duplicateB2BProgramSessions(
    boxProgrammationId: string,
    programIdToDuplicateFrom: string,
    startDate: string,
    endDate: string,
  ) {
    return this.api.duplicateB2BProgramSessions(boxProgrammationId, programIdToDuplicateFrom, startDate, endDate)
  }
}
