import { ProgramAuthor } from "@modules/profile/profileTypes"
import {
  ProgramContentTypeOf,
  ProgramPayment,
  ProgramPrice,
  ProgramSubscriptionStatus,
  ProgramVisibility,
  SharedCoach,
} from "@modules/programs/programTypes"
import { HUImage } from "@modules/types/imageTypes"

export enum ProgressionStatusProgramOnOff {
  NOT_STARTED = "NOT_STARTED",
  STARTED = "STARTED",
  STOPPED = "STOPPED",
  COMPLETED = "COMPLETED",
}

export enum ProgramOnOffStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export const ProgramOnOffTypeOf = "PROGRAM_ON_OFF"

export type ItemProgramOnOff = {
  id: string
  _programType: typeof ProgramOnOffTypeOf
  title: string
  visibility: ProgramVisibility
  image: HUImage
  author: ProgramAuthor
  subscribers?: number
  subscriptionCount: number
  defaultHideSessionContent: boolean
  entranceCheckEnabled: boolean
  price?: ProgramPrice
  duration: number
  status: ProgramOnOffStatus
  subscribed?: boolean
  sessionCount: number
  sessionIndexes: number[]
  subscriptionStatus: ProgramSubscriptionStatus
  invitationLink: string
  _type: typeof ProgramContentTypeOf
  sharedCoaches?: SharedCoach[]
}

export type ProgramOnOff = {
  description: string
} & ItemProgramOnOff

export type ProgramOnOffDraft = {
  _programType: typeof ProgramOnOffTypeOf
  name: string
  visibility: ProgramVisibility
  description: string
  image?: HUImage
  defaultHideSessionContent: boolean
  entranceCheckEnabled: boolean
  price?: ProgramPrice
  duration?: number
  status?: string
  sharedCoaches?: SharedCoach[]
}

export type ProgramOnOffProgression = {
  id: string
  programId: string
  userId: string
  currentIndex: number
  duration: number
  startedAt: string
  stoppedAt: string
  status: ProgressionStatusProgramOnOff
}

export function convertProgramOnOffToDraft(program?: ProgramOnOff): ProgramOnOffDraft {
  return {
    _programType: ProgramOnOffTypeOf,
    name: program?.title ?? "",
    visibility: program?.visibility ?? ProgramVisibility.PUBLIC,
    description: program?.description ?? "",
    image: program?.image,
    defaultHideSessionContent: program?.defaultHideSessionContent ?? false,
    entranceCheckEnabled: program?.entranceCheckEnabled ?? false,
    price: program?.price,
    duration: program?.duration,
    status: program?.status,
    sharedCoaches: program?.sharedCoaches,
  }
}

export type ProgramOnOffStripeSubscriptionInfos = {
  id: string
  subscriptionStatus: ProgramSubscriptionStatus
  paidDate?: string
  paidPrice?: ProgramPrice
  payment?: ProgramPayment
}
