import { EmailIcon } from "@images/svgIcons/emailIcon"
import { PhoneIcon } from "@images/svgIcons/phoneIcon"
import { BoxFormPageProps } from "@modules/box/components/form/boxFormFirstPage"
import { BoxFormHeader } from "@modules/box/components/form/boxFormHeader"
import { LocationBox } from "@modules/box/components/locationBox"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { formatPhoneNumber } from "@modules/utils/numberUtils"
import { CountryCode } from "libphonenumber-js"
import styled, { useTheme } from "styled-components"

export const BoxFormResumePage = ({ handleSubmit, onPrevious, formik, isSubmitting }: BoxFormPageProps) => {
  const theme = useTheme()
  return (
    <>
      <BoxFormHeader title={word("box.creation.page.resume.title")} classname="mb-5" />
      <div style={{ maxWidth: "36rem" }} className="flex flex-column gap-5 w-full">
        <div className="flex h-14rem border-round-2xl" style={{ backgroundColor: theme.neutralColor50 }}>
          <div className="flex flex-column align-items-center m-auto">
            <div style={{ width: "100px", height: "100px" }}>
              <img
                src={formik.values.logo?.path}
                className="w-full h-full border-circle"
                style={{ objectFit: "cover" }}
              />
            </div>
            <HUText fontStyle="HS" className="mt-2">
              {formik.values.name}
            </HUText>
            <HUText fontStyle="LL">
              {formik.values.city} <HUText fontStyle="BM">{formik.values.postalCode}</HUText>
            </HUText>
          </div>
          <BoxFormResumeHeader className="border-round-top-2xl">
            <img src={formik.values.cover?.path} style={{ objectFit: "cover" }} className="w-full h-full" />
          </BoxFormResumeHeader>
        </div>
        <div>
          <HUText fontStyle="TS">{word("box.form.contact.title")}</HUText>
          <div className="flex align-items-center mt-2 mb-2 gap-2">
            <div
              className="flex align-items-center justify-content-center border-circle"
              style={{ backgroundColor: theme.neutralColor100, width: "39px", height: "39px" }}
            >
              <EmailIcon color={theme.neutralColor900} width={15} height={15} />
            </div>
            <HUText fontStyle="BM">{formik.values.email}</HUText>
          </div>
          <div className="flex align-items-center gap-2">
            <div
              className="flex align-items-center justify-content-center border-circle"
              style={{ backgroundColor: theme.neutralColor100, width: "39px", height: "39px" }}
            >
              <PhoneIcon color={theme.neutralColor900} />
            </div>
            <HUText fontStyle="BM">
              {formatPhoneNumber(formik.values.phoneNumber, formik.values.phoneNumberCountryCode as CountryCode)}
            </HUText>
          </div>
        </div>
        <div className="flex flex-column gap-2">
          <HUText fontStyle="TS">{word("box.where.address.title")}</HUText>
          <LocationBox
            box={{
              address: {
                address: formik.values.address,
                additionalInformation: formik.values.additionalAddress,
                postalCode: formik.values.postalCode,
                city: formik.values.city,
                country: formik.values.country,
              },
              name: formik.values.name,
            }}
            bgColor={{ container: theme.neutralColor50, icon: "#242424" }}
          />
        </div>
        <HUText
          fontStyle="BM"
          color={theme.neutralColor700}
          style={{ whiteSpace: "pre-line", overflowWrap: "break-word" }}
        >
          {formik.values.description}
        </HUText>
        <div className="flex flex-column gap-2">
          <HUText fontStyle="TS">{word("box.creation.page.legal.files")}</HUText>
          <HUText fontStyle="LS" color={theme.neutralColor600}>
            {word("box.creation.page.legal.label").toUpperCase()}
          </HUText>
          <div>
            <HUText fontStyle="TS">{formik.values.siren}</HUText>
          </div>
        </div>
        <div className="flex gap-2 justify-content-center">
          <HUButton
            type="Left_Icon"
            size="M"
            colorType="Quaternary"
            text={word("global.previous")}
            className="mb-5"
            onClick={onPrevious}
            icon={{
              iconView: <span className={`pi pi-arrow-left`} style={{ color: theme.mainWhite }} />,
            }}
          />
          <HUButton
            type="Right_Icon"
            size="M"
            colorType="Primary"
            text={word("box.creation.saveAndCreate")}
            className="mb-5"
            onClick={handleSubmit}
            disabled={isSubmitting}
            loading={isSubmitting}
            submit
            icon={{
              iconView: <span className={`pi pi-check`} style={{ color: theme.mainWhite }} />,
            }}
          />
        </div>
      </div>
    </>
  )
}

const BoxFormResumeHeader = styled.div`
  background-color: ${({ theme }) => theme.primaryColor700};
  overflow: hidden;
  clip-path: polygon(32% 0%, 100% 0%, 100% 100%, 0% 100%);
  border-bottom-right-radius: 1rem;
  width: 50%;
`
