import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const AutomaticDuplicationIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.67356 8.37074L11.9883 3.19856L22.303 8.37074L11.9883 13.5429L1.67356 8.37074ZM22.3963 8.41752C22.3963 8.41752 22.3963 8.41752 22.3963 8.41751L22.3963 8.41752ZM1.58027 8.32396C1.58027 8.32396 1.58027 8.32396 1.58028 8.32396L1.58027 8.32396Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M22.1821 12.6938L12.3083 17.7899C12.1075 17.8935 11.869 17.8935 11.6683 17.7899L1.79443 12.6938"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M22.1821 16.6079L12.3083 21.704C12.1075 21.8076 11.869 21.8076 11.6683 21.704L1.79443 16.6079"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
