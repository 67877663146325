import { Page } from "@betomorrow/micro-stores"
import { Service } from "@modules/core/services/serviceType"
import { ExerciseScoreCategory, ExerciseScoreDifficulty } from "@modules/exercises/exerciseTypes"
import { ScoreboardRanking } from "@modules/scoreboard/scoreboardTypes"
import { ScoreboardApi } from "@modules/scoreboard/services/scoreboardApi"

export class ScoreboardService implements Service {
  constructor(private api: ScoreboardApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async getScoreboard(
    programId: string,
    sessionId: string,
    exerciseId: string,
    difficulty: ExerciseScoreDifficulty,
    category: ExerciseScoreCategory,
    referenceDate: string,
    page?: number,
  ): Promise<Page<ScoreboardRanking>> {
    return this.api.getResult(programId, sessionId, exerciseId, difficulty, category, referenceDate, page)
  }

  async getRanking(
    programId: string,
    sessionId: string,
    exerciseId: string,
    difficulty: ExerciseScoreDifficulty,
    category: ExerciseScoreCategory,
    referenceDate: string,
  ) {
    return this.api.getRanking(programId, sessionId, exerciseId, difficulty, category, referenceDate)
  }
}
