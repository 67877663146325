import { InputSwitch, InputSwitchProps } from "primereact/inputswitch"
import styled from "styled-components"

export const HUSwitch: React.FC<InputSwitchProps> = (props) => {
  return <InputSwitchStyled {...props} />
}

const InputSwitchStyled = styled(InputSwitch)`
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`
