import { fadeIn } from "@modules/ui/styles/animation"
import styled from "styled-components"

export const LoadingPoints = ({ className }: { className?: string }) => (
  <Content className={className}>
    <span>&bull;</span>
    <span>&bull;</span>
    <span>&bull;</span>
  </Content>
)

export const LoadingSpinner = ({ color }: { color?: string }) => (
  <div className="flex align-items-center justify-content-center h-full">
    <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem", color }}></i>
  </div>
)

const Content = styled.span`
  span {
    animation: ${fadeIn} 1.2s infinite;
    margin: 0 2px;
  }
  span:nth-child(1) {
    animation-delay: -0.8s;
  }
  span:nth-child(2) {
    animation-delay: -0.4s;
  }
  span:nth-child(3) {
    animation-delay: -0s;
  }
`
