import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const UnionIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="278" height="284" viewBox="0 0 278 284" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.559 28.7033L93.0017 125.452H254.757L313.987 9.81848C316.209 5.48064 313.059 0.327637 308.185 0.327637H188.975C169.404 0.327637 151.481 11.2847 142.559 28.7033ZM64.6945 193.228L5.46283 308.864C3.24154 313.2 6.39081 318.352 11.2632 318.352H130.477C150.047 318.352 167.971 307.395 176.893 289.976L226.45 193.228H64.6945Z"
        fill={props.color}
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_7237_606801"
        x1="0.119943"
        y1="241.806"
        x2="364.945"
        y2="121.735"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3125" stopColor="white" stopOpacity="0.15" />
        <stop offset="0.557292" stopColor="white" stopOpacity="0.33" />
        <stop offset="0.807292" stopColor="white" stopOpacity="0.25" />
      </linearGradient>
    </defs>
  </svg>
)
