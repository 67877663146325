import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { StatusCircle } from "@modules/members/components/memberBoxOffersView"
import { MemberPurchaseInfos, getOfferStatusInfos } from "@modules/members/memberUtils"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { formatDateWithHyphen } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { ColumnProps } from "primereact/column"
import { DefaultTheme } from "styled-components"

export const memberBoxOffersColumns = ["date", "subscription_name", "price", "status"] as const
export type MemberBoxOffersColumnsType = (typeof memberBoxOffersColumns)[number]

export const headerColumn = (key: MemberBoxOffersColumnsType) => {
  switch (key) {
    case "date":
      return word("member.form.memberships.date.label")
    case "subscription_name":
      return word("member.form.memberships.name.label")
    case "price":
      return word("member.form.memberships.price.label")
    case "status":
      return word("member.form.memberships.status.label")
  }
}

export const valueColumn = (
  key: MemberBoxOffersColumnsType,
  purchaseInfos: MemberPurchaseInfos,
  theme: DefaultTheme,
  language: string,
) => {
  switch (key) {
    case "date":
      return <HUText fontStyle="TS">{formatDateWithHyphen(dayjs(purchaseInfos.date).format("L"))}</HUText>
    case "subscription_name":
      return <HUText fontStyle="BM">{purchaseInfos.name}</HUText>
    case "price":
      return (
        <HUText fontStyle="BM">
          {purchaseInfos.price != null && !!purchaseInfos.currency
            ? formatCurrency(purchaseInfos.price, purchaseInfos.currency, language)
            : "-"}
        </HUText>
      )
    case "status":
      const { color, label } = getOfferStatusInfos(purchaseInfos)
      return (
        <HURow align gap={7}>
          <StatusCircle style={{ backgroundColor: color }} />
          <HUText fontStyle="LL">{label}</HUText>
        </HURow>
      )
  }
}

export function propsColumn(key: MemberBoxOffersColumnsType): ColumnProps {
  switch (key) {
    case "date":
      return { headerStyle: { minWidth: "10rem" } }
    case "subscription_name":
      return { headerStyle: { minWidth: "10rem" } }
    case "price":
      return { headerStyle: { minWidth: "10rem" } }
    case "status":
      return {}
  }
}
