import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useTheme } from "styled-components"

export const AddEventButton: React.FC<{
  onClick: () => void
  displayText: boolean
  addEventTitle?: string
  addEventSubtitle?: string
}> = ({ onClick, displayText, addEventTitle, addEventSubtitle }) => {
  const theme = useTheme()
  return (
    <div className="add flex justify-content-center flex-column text-center pointer" onClick={onClick}>
      {displayText && (addEventTitle || addEventSubtitle) && (
        <>
          {addEventTitle && <HUText fontStyle="TL">{addEventTitle}</HUText>}
          {addEventSubtitle && <HUText fontStyle="BS">{addEventSubtitle}</HUText>}
        </>
      )}
      <HUButton
        type="Left_Icon"
        size="S"
        colorType="Quaternary"
        icon={{ iconView: <span className="pi pi-plus" style={{ color: theme.textPrimary }} /> }}
        className="p-2 align-self-center"
      />
    </div>
  )
}
