import { HUText } from "@modules/ui/components/huText"
import { Avatar } from "primereact/avatar"
import styled, { useTheme } from "styled-components"

type ChatToastProps = {
  image?: string
  authorName: string
  message: string
  onClick: () => void
}

export const ChatToast: React.FC<ChatToastProps> = ({ authorName, message, image, onClick }) => {
  const theme = useTheme()

  return (
    <Container onClick={onClick}>
      {image ? (
        <Avatar image={image} shape="circle" size="normal" />
      ) : (
        <Avatar
          label={authorName.charAt(0).toUpperCase()}
          shape="circle"
          size="normal"
          style={{ backgroundColor: theme.primaryColor800, color: theme.mainWhite }}
        />
      )}
      <RightContainer>
        <HUText fontStyle="BL">{authorName}</HUText>
        {message && (
          <HUText fontStyle="BS" numberOfLine={2}>
            {message}
          </HUText>
        )}
      </RightContainer>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
