export const HustleupIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="184" height="33" viewBox="0 0 184 33" fill="none" {...props}>
      <path
        d="M56.3244 26.14V0.541575H52.0014V11.1203H40.1042V0.541575H35.8169V26.14H40.1042V15.2002H52.0014V26.14H56.3244Z"
        fill={props.color}
      />
      <path
        d="M71.2278 24.1904C71.2278 24.9125 71.2992 25.7429 71.3707 26.14H75.3364C75.265 25.4902 75.1578 24.3709 75.1578 22.9267V8.55689H71.0134V18.9551C71.0134 21.2659 69.763 22.8906 67.3692 22.8906C64.8683 22.8906 63.8679 21.0492 63.8679 18.8829V8.55689H59.7235V19.6411C59.7235 23.5405 62.153 26.6455 66.2974 26.6455C68.3339 26.6455 70.3703 25.779 71.2278 24.1904Z"
        fill={props.color}
      />
      <path
        d="M76.8423 21.3742C77.0567 23.36 78.9859 26.6816 84.0235 26.6816C88.4537 26.6816 90.5973 23.721 90.5973 21.0492C90.5973 18.4497 88.8824 16.4278 85.5955 15.7057L82.9517 15.1641C81.8799 14.9475 81.201 14.2615 81.201 13.3228C81.201 12.2396 82.2371 11.3009 83.702 11.3009C86.0242 11.3009 86.7745 12.9256 86.9174 13.9365L90.4187 12.9256C90.1329 11.1926 88.5609 8.01532 83.702 8.01532C80.0935 8.01532 77.3067 10.6149 77.3067 13.6838C77.3067 16.1028 78.9145 18.1247 81.987 18.8107L84.5594 19.3884C85.9528 19.6772 86.5959 20.3993 86.5959 21.3381C86.5959 22.4212 85.7027 23.36 83.9878 23.36C81.7727 23.36 80.5937 21.9519 80.4508 20.3632L76.8423 21.3742Z"
        fill={props.color}
      />
      <path
        d="M97.8154 3.17724H94.064V5.81291C94.064 7.36543 93.2423 8.55689 91.3845 8.55689H90.4913V12.2757H93.7067V21.1214C93.7067 24.407 95.7075 26.3567 98.923 26.3567C100.424 26.3567 101.21 26.0678 101.46 25.9595V22.4934C101.281 22.5295 100.602 22.6379 100.031 22.6379C98.4942 22.6379 97.8154 21.988 97.8154 20.4354V12.2757H101.424V8.55689H97.8154V3.17724Z"
        fill={props.color}
      />
      <path d="M107.194 26.14V0H103.05V26.14H107.194Z" fill={props.color} />
      <path
        d="M113.29 15.4169C113.433 13.5394 114.934 11.5175 117.542 11.5175C120.4 11.5175 121.758 13.3589 121.829 15.4169H113.29ZM122.294 20.0022C121.722 21.7352 120.436 23.0711 117.935 23.0711C115.363 23.0711 113.29 21.1576 113.183 18.4858H125.902C125.938 18.4136 126.009 17.8359 126.009 17.1499C126.009 11.5897 122.865 8.01532 117.506 8.01532C113.112 8.01532 109.039 11.698 109.039 17.2582C109.039 23.2155 113.219 26.6816 117.935 26.6816C122.115 26.6816 124.866 24.1904 125.795 21.1214L122.294 20.0022Z"
        fill={props.color}
      />
      <path
        d="M142.619 26.6816C147.942 26.6816 152.087 23.3961 152.087 17.2221V0.541575H147.799V16.9694C147.799 20.5799 145.87 22.5656 142.619 22.5656C139.404 22.5656 137.438 20.5799 137.438 16.9694V0.541575H133.187V17.2221C133.187 23.3961 137.331 26.6816 142.619 26.6816Z"
        fill={props.color}
      />
      <path
        d="M159.294 33V24.1543C160.187 25.4902 162.081 26.5733 164.582 26.5733C169.584 26.5733 172.763 22.6018 172.763 17.3304C172.763 12.1313 169.87 8.15974 164.725 8.15974C162.045 8.15974 160.044 9.42341 159.187 10.9398V8.55689H155.186V33H159.294ZM168.619 17.3304C168.619 20.6882 166.69 22.8545 163.939 22.8545C161.223 22.8545 159.258 20.6882 159.258 17.3304C159.258 14.0449 161.223 11.8786 163.939 11.8786C166.725 11.8786 168.619 14.0449 168.619 17.3304Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9954 3.48146L7.16733 10.9233H20.4369L25.2096 1.64514C25.4305 1.21562 25.1224 0.702148 24.6437 0.702148H15.5227C13.6192 0.702148 11.8739 1.77358 10.9954 3.48146ZM4.84555 16.4597L0.072868 25.7379C-0.148077 26.1674 0.160067 26.6809 0.638779 26.6809H9.75975C11.6632 26.6809 13.4085 25.6094 14.287 23.9016L18.1151 16.4597H4.84555Z"
        fill={props.color}
      />
      <path
        d="M179.67 7.38998V4.47527H181.061C181.29 4.47527 181.486 4.51608 181.65 4.5977C181.813 4.67933 181.937 4.79386 182.021 4.94131C182.108 5.08875 182.151 5.26253 182.151 5.46264C182.151 5.66538 182.106 5.83784 182.017 5.98002C181.927 6.1222 181.8 6.23147 181.634 6.30782C181.468 6.38155 181.269 6.41841 181.037 6.41841H180.121V5.81809H180.903C181.029 5.81809 181.125 5.78781 181.191 5.72725C181.257 5.66669 181.29 5.57849 181.29 5.46264C181.29 5.33889 181.257 5.24673 181.191 5.18617C181.125 5.12298 181.029 5.09139 180.903 5.09139H180.508V7.38998H179.67ZM181.598 6.06296L182.222 7.38998H181.306L180.745 6.06296H181.598ZM180.84 9.12774C180.403 9.12774 179.993 9.04612 179.611 8.88288C179.229 8.71963 178.893 8.4932 178.604 8.20357C178.314 7.91394 178.088 7.57824 177.924 7.19645C177.761 6.81467 177.679 6.40524 177.679 5.96817C177.679 5.53109 177.761 5.12167 177.924 4.73988C178.088 4.3581 178.314 4.0224 178.604 3.73277C178.893 3.44314 179.229 3.21671 179.611 3.05346C179.993 2.89022 180.403 2.80859 180.84 2.80859C181.277 2.80859 181.686 2.89022 182.068 3.05346C182.45 3.21671 182.786 3.44314 183.076 3.73277C183.365 4.0224 183.592 4.3581 183.755 4.73988C183.918 5.12167 184 5.53109 184 5.96817C184 6.40524 183.918 6.81467 183.755 7.19645C183.592 7.57824 183.365 7.91394 183.076 8.20357C182.786 8.4932 182.45 8.71963 182.068 8.88288C181.686 9.04612 181.277 9.12774 180.84 9.12774ZM180.84 8.24306C181.261 8.24306 181.643 8.14169 181.985 7.93895C182.33 7.73358 182.604 7.45843 182.807 7.11351C183.012 6.76859 183.115 6.38681 183.115 5.96817C183.115 5.54952 183.012 5.16774 182.807 4.82282C182.604 4.4779 182.33 4.20407 181.985 4.00133C181.64 3.79596 181.258 3.69327 180.84 3.69327C180.421 3.69327 180.039 3.79596 179.694 4.00133C179.349 4.20407 179.074 4.4779 178.868 4.82282C178.666 5.16774 178.564 5.54952 178.564 5.96817C178.564 6.38681 178.666 6.76859 178.868 7.11351C179.074 7.4558 179.349 7.72963 179.694 7.935C180.039 8.14038 180.421 8.24306 180.84 8.24306Z"
        fill={props.color}
      />
    </svg>
  )
}
