import { AuthService } from "@modules/auth/services/authService"
import { SharedUserService } from "@modules/auth/services/sharedUserService"
import { Lock } from "@modules/core/Lock"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { requestAuthorizationInterceptor } from "@modules/core/api/interceptors/requestAuthorizationInterceptor"
import { requestLoggerInterceptor } from "@modules/core/api/interceptors/requestLoggerInterceptor"
import { responseDebugGenerateErrorInterceptor } from "@modules/core/api/interceptors/responseDebugGenerateErrorInterceptor"
import { responseLoggerInterceptor } from "@modules/core/api/interceptors/responseLoggerInterceptor"
import { responseUnauthorizeErrorFallbackInterceptor } from "@modules/core/api/interceptors/responseUnauthorizeErrorFallbackInterceptor"
import { responseUnauthorizeErrorInterceptor } from "@modules/core/api/interceptors/responseUnauthorizeErrorInterceptor"
import { ConfigService } from "@modules/core/services/configService"
import { Service } from "@modules/core/services/serviceType"
import { HUError } from "@modules/error/huError"
import _ from "lodash"

export class ApiService implements Service {
  private _jwtTokenRefreshingLock: Lock = new Lock()
  private _debugNextErrorGenerated: HUError | null = null

  constructor(private apiClient: ApiClient, private userService: SharedUserService, private authService: AuthService) {}

  async init(): Promise<() => void> {
    this.apiClient.addRequestInterceptors([
      requestAuthorizationInterceptor(this.authService, this._jwtTokenRefreshingLock),
      requestLoggerInterceptor(),
    ])
    this.apiClient.addResponseInterceptors(
      _.compact([
        ConfigService.config.ENV !== "prod" &&
          responseDebugGenerateErrorInterceptor(() => {
            const error = this._debugNextErrorGenerated
            this._debugNextErrorGenerated = null
            return error
          }),
        responseUnauthorizeErrorFallbackInterceptor(
          this.apiClient.axios,
          this.authService,
          this._jwtTokenRefreshingLock,
        ),
        responseUnauthorizeErrorInterceptor(this.userService),
        responseLoggerInterceptor(),
      ]),
    )

    return () => {
      this.apiClient.clearInterceptors()
    }
  }

  setDebugNextErrorGenerated(error: HUError) {
    this._debugNextErrorGenerated = error
  }
}
