import { Language, getFormatedLocaleNumber } from "@modules/language/utils/languageUtils"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { getScoreUnit } from "@modules/scoreboard/scoreUtils"
import { HUUnit } from "@modules/utils/unitType"
import convert from "convert-units"

const commonWeightValueFromKg: { [key: number]: number } = {
  [0.5]: 1.25,
  [1]: 2.5,
  [2]: 4.5,
  [4]: 9,
  [5]: 11,
  [6]: 13,
  [7.5]: 17,
  [8]: 18,
  [10]: 22,
  [12]: 26,
  [15]: 33,
  [16]: 35,
  [20]: 44,
  [22.5]: 50,
  [24]: 53,
  [25]: 55,
  [28]: 62,
  [32]: 70,
  [40]: 88,
}

const commonWeightValueFromLb = Object.fromEntries(
  Object.entries(commonWeightValueFromKg).map(([key, value]) => [value, key as unknown as number]),
) as { [key: number]: number }

export const convertWeightFromInput = (input: number, unit: HUUnit) => {
  if (unit === "IMPERIAL") {
    const commonValue = commonWeightValueFromLb[input]
    if (commonValue) {
      return commonValue * 1000000
    }
  }

  return convert(input)
    .from(unit === "IMPERIAL" ? "lb" : "kg")
    .to("mg")
}

const getBestConvertedValue = (v: number, u: HUUnit) => {
  if (u === "IMPERIAL") {
    const kilogram = convert(v).from("mg").to("kg")
    const commonValue = commonWeightValueFromKg[kilogram]
    if (commonValue) {
      return { val: commonValue, unit: getScoreUnit(ScoreType.WEIGHT, u) }
    }
  }

  const localeValue = convertWeightFromGram(v, u)
  const unitTo = u === "IMPERIAL" ? "lb" : "kg"
  const best = convert(localeValue)
    .from(unitTo)
    .toBest({ cutOffNumber: 1, exclude: ["oz"] })
  return { val: best.val, unit: best.unit === "mt" ? "t" : best.unit }
}

const getBestRoundedValue = (bestLocaleValue: { val: number; unit: string }, unit: HUUnit) => {
  return unit === "IMPERIAL"
    ? bestLocaleValue.unit === "lb"
      ? bestLocaleValue.val < 2
        ? { fixedValue: 2, minValue: 0.01 }
        : bestLocaleValue.val < 5
          ? { fixedValue: 1, minValue: 0.1 }
          : { fixedValue: 0, minValue: 1 }
      : { fixedValue: 1, minValue: 0.1 }
    : { fixedValue: 1, minValue: 0.1 }
}

export const convertWeightFromGram = (value: number, unit: HUUnit): number => {
  const unitTo = unit === "IMPERIAL" ? "lb" : "kg"
  const localeValue = convert(value).from("mg").to(unitTo)
  return localeValue
}

export const convertWeightFromGramText = (value: number, unit: HUUnit, language: Language): string => {
  const bestLocaleValue = getBestConvertedValue(value, unit)
  const { fixedValue, minValue } = getBestRoundedValue(bestLocaleValue, unit)

  return (
    getFormatedLocaleNumber(Number(Math.max(bestLocaleValue.val, minValue).toFixed(fixedValue)), language) +
    " " +
    bestLocaleValue.unit
  )
}
