import { ForTimeIcon } from "@images/svgIcons/forTimeIcon"
import { word } from "@modules/core/utils/i18n"
import { RecapBloc } from "@modules/exercises/components/RecapDetailAllChronos/recapBloc"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useTheme } from "styled-components"

export const FortimeRecap: React.FC = () => {
  const { formik, updatedExerciseIndex } = useSessionFormContext()
  const { navigateToChronoForm } = useSessionSidebar()
  const theme = useTheme()

  if (updatedExerciseIndex === null) return <></>

  const formikValue = formik.values.exercises[updatedExerciseIndex]
  const formikNameBase = `exercises[${updatedExerciseIndex}]`

  const onDeleteChronoClick = () => {
    formik.setFieldValue(`${formikNameBase}.chronoForTime`, undefined)
    formik.setFieldValue(`${formikNameBase}.chrono`, undefined)
    formik.setFieldValue(`${formikNameBase}.chronoType`, undefined)
  }

  const onEditChronoContentClick = () => {
    navigateToChronoForm()
  }

  return (
    <>
      <div className="flex ">
        <ForTimeIcon color={"white"} />
        <div className="flex flex-column justify-content-around	ml-4">
          <HUText fontStyle="LL">{word("chrono.title.for_time")}</HUText>
          <HUText fontStyle="BS" color={theme.neutralColor600}>
            {word("chrono.forTime.definition")}
          </HUText>
        </div>
      </div>
      {formikValue.chronoForTime?.trainingTime && (
        <>
          <div className="grid mt-2">
            <RecapBloc
              circleTimeIcon
              infinityIcon={formikValue.chronoForTime.noLimit}
              value={formikValue.chronoForTime.noLimit ? "" : formikValue.chronoForTime.trainingTime}
              label={word("chrono.settings.label.training_time")}
            />
          </div>
        </>
      )}
      <div className="flex justify-content-between mt-4">
        <HUButton
          type="Default"
          size="M"
          colorType="Quaternary"
          text={word("global.delete")}
          style={{ width: "100%", marginRight: "10px" }}
          onClick={onDeleteChronoClick}
        />
        <HUButton
          type="Default"
          size="M"
          colorType="Tertiary"
          text={word("program.details.modify.button")}
          style={{ width: "100%" }}
          onClick={onEditChronoContentClick}
        />
      </div>
    </>
  )
}
