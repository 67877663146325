import { HUImageDto, convertImageFromDto } from "@modules/dtos/imageDto"
import { Subscriber } from "@modules/profile/subscriber/subscriberTypes"

export type SubscriberDto = {
  memberId: string
  userId: string
  firstname: string
  lastname: string
  username: string
  avatar?: HUImageDto
  absent: boolean
}

export function convertSubscriberFromDto(dto: SubscriberDto): Subscriber {
  return {
    id: dto.userId,
    memberId: dto.memberId,
    firstname: dto.firstname,
    lastname: dto.lastname,
    username: dto.username,
    avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
    absent: dto.absent,
  }
}

export type ParticipantStatusDto = "REGISTERED" | "PENDING"
