import { Objective, ObjectiveDraft } from "@modules/objectives/objectivesTypes"

export type ObjectiveDto = {
  id: string
  objective: string
  targetDate: string
  archived: boolean
}
export type ObjectiveDraftDto = {
  objective: string
  targetDate: string
}

export function convertObjectiveFromDto(dto: ObjectiveDto): Objective {
  return {
    id: dto.id,
    objective: dto.objective,
    targetDate: dto.targetDate,
    archived: dto.archived,
  }
}

export function convertObjectiveDraftToDto(draft: ObjectiveDraft): ObjectiveDraftDto {
  return {
    objective: draft.objective,
    targetDate: draft.targetDate,
  }
}
