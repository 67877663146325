import {
  BoxProgrammationFormikType,
  BoxProgrammationInputKey,
} from "@modules/boxProgrammation/boxProgrammationFormikTypes"
import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxProgrammationInfoForm } from "@modules/boxProgrammation/components/boxProgrammationInfoForm"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { SimpleFormik } from "@src/typings/formik"
import { useState } from "react"
import styled from "styled-components"

type ProgramFormProps = {
  boxProgrammation?: BoxProgrammation
  formik: SimpleFormik<BoxProgrammationFormikType, BoxProgrammationInputKey>
  onValidate: () => void
}

export const BoxProgrammationForm: React.FC<ProgramFormProps> = ({ boxProgrammation, formik, onValidate }) => {
  const [loadingImageUpload, setLoadingImageUpload] = useState(false)

  const { boxProgrammationService } = useServicesContext()
  const toast = useToast()
  const confirmPopup = useConfirmPopup()
  const boxNavigate = useBoxNavigate()
  const { hideSidebar } = useSidebar()

  const confirmDeleteProgram = (program: BoxProgrammation) => {
    confirmPopup.show({
      title: word("global.delete"),
      message: word("programmation.form.delete.confirmation.subtitle", {
        programmationName: program.name,
      }),
      accept: async () => {
        try {
          await boxProgrammationService.deleteBoxProgrammation(program.id)
          toast.show({ severity: "success", detail: word("programmation.delete.confirm.message") })
          setTimeout(() => boxNavigate(Path.Box.Planning), 500)
        } catch (e: any) {
          toast.show({
            severity: "error",
            detail:
              e.code === "OFFER_LINKED_TO_PROGRAM" ? word("programmation.delete.error.linked_to_offer") : e.message,
          })
        }
      },
      acceptValidated: word("programmation.delete.confirm.message"),
      footerProps: {
        align: true,
      },
    })
  }

  return (
    <div className="flex flex-column justify-content-center">
      <HUText fontStyle="HS" className="mb-3">
        {boxProgrammation ? word("box.programmation.form.update.title") : word("box.programmation.form.add.title")}
      </HUText>

      <HUText fontStyle="BS" className="mb-3">
        {word("box.programmation.form.add.description")}
      </HUText>

      <div className="flex flex-column">
        <FormContainer className="flex-column">
          <BoxProgrammationInfoForm
            isEditing={!!boxProgrammation}
            formik={formik}
            setLoadingImageUpload={setLoadingImageUpload}
          />
          <div className="flex justify-content-end mt-5">
            <div className="flex column gap-3 sm:flex-row flex-grow-1">
              {boxProgrammation ? (
                <HUButton
                  type="Default"
                  size="M"
                  colorType="Quaternary"
                  text={word("global.delete")}
                  onClick={() => boxProgrammation && confirmDeleteProgram(boxProgrammation)}
                  className="w-full"
                />
              ) : (
                <HUButton
                  type="Default"
                  size="M"
                  colorType="Quaternary"
                  text={word("global.cancel")}
                  onClick={() => {
                    hideSidebar()
                  }}
                  className="w-full"
                />
              )}
              <HUButton
                type="Default"
                size="M"
                colorType="Primary"
                text={word("global.next")}
                className="mr-2 w-full"
                disabled={loadingImageUpload}
                onClick={onValidate}
              />
            </div>
          </div>
        </FormContainer>
      </div>
    </div>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
