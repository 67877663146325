import { Address, AddressDraft } from "@modules/address/addressTypes"
import { EmptyAddress } from "@modules/address/addressUtils"

export type AddressDto = {
  address: string
  additionalInformation?: string
  postalCode: string
  city: string
  country: string
}

export type AddressDraftDto = AddressDto

export function convertAddressDraftToDto(draft: AddressDraft | undefined): AddressDraftDto {
  const d = draft || EmptyAddress
  return {
    address: d.address,
    additionalInformation: d.additionalInformation ?? "",
    postalCode: d.postalCode,
    city: d.city,
    country: d.country,
  }
}

export function convertAddressFromDto(dto: AddressDto): Address {
  return {
    address: dto.address,
    additionalInformation: dto.additionalInformation ?? "",
    postalCode: dto.postalCode,
    city: dto.city,
    country: dto.country,
  }
}
