import React from "react"
import { IconSvgProps } from "@modules/ui/components/huButtonIcon"

export const FourIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="85" height="101" viewBox="0 0 85 101" fill="none" {...props}>
    <path
      d="M0 83.0298V57.8968L35.6383 0.184021L55.1862 12.551L25.7979 60.4234L27.7926 63.3489H84.3085V83.0298H0ZM50.9308 100.184V29.8383H73.8032V100.184H50.9308Z"
      fill={props.color}
    />
  </svg>
)
