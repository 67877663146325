import { useCurrentBox } from "@modules/box/useBox"
import { useServicesContext } from "@modules/core/services/services.context"
import { CustomerHistory } from "@modules/finances/services/transactionHistoryDto"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useEffect, useState } from "react"

export const useTransactionsHistory = (size: number, stripeCustomerId?: string) => {
  const { result: boxId } = useCurrentBox()
  const { boxFinanceService, userFinanceService } = useServicesContext()
  const financeService = boxId ? boxFinanceService : userFinanceService
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [forceRefresh, setForceRefresh] = useState(0)

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [allTransactions, setAllTransactions] = useState<CustomerHistory[][]>([])
  const [nextPageToFetch, setNextPageToFetch] = useState<string | undefined>(undefined)

  const fetch = async () => {
    if (!allTransactions[currentPageIndex] && !loading && stripeCustomerId) {
      try {
        setLoading(true)
        const result = await financeService.getTransactionsHistory(stripeCustomerId, nextPageToFetch, size)
        const newArray = allTransactions
        newArray[currentPageIndex] = result.data
        setAllTransactions([...newArray])
        setNextPageToFetch(result.nextPage)
      } catch (e) {
        console.log(LoggerType.Financial, "get transactions history", JSON.stringify(e))
        setError(JSON.stringify(e))
      } finally {
        setLoading(false)
      }
    }
  }

  const cleanData = () => {
    setAllTransactions([])
    setNextPageToFetch(undefined)
    setCurrentPageIndex(0)
    setForceRefresh((v) => v + 1)
  }

  useEffect(() => {
    cleanData()
  }, [stripeCustomerId, size])

  useEffect(() => {
    fetch()
  }, [currentPageIndex, forceRefresh])

  const currentData = allTransactions[currentPageIndex] ?? []

  return {
    loading,
    error,
    value: currentData,
    previous: currentPageIndex > 0 ? () => setCurrentPageIndex((v) => v - 1) : undefined,
    next:
      currentPageIndex < allTransactions.length - 1 || nextPageToFetch
        ? () => setCurrentPageIndex((v) => v + 1)
        : undefined,
  }
}
