import { ApiInterceptor } from "@modules/core/api/interceptors/apiInterceptor"
import { CurrentSpaceManager } from "@modules/core/services/currentSpaceManager"
import { InternalAxiosRequestConfig } from "axios"

export const addBoxInterceptor: (
  currentSpaceManager: CurrentSpaceManager,
) => ApiInterceptor<InternalAxiosRequestConfig> = (currentSpaceManager) => ({
  onFulfilled: async (config) => {
    const currentBoxId = currentSpaceManager.boxId
    return {
      ...config,
      headers: currentBoxId ? { ...config.headers, "x-box-id": currentBoxId } : config.headers,
    } as InternalAxiosRequestConfig
  },
})
