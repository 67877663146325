import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ProductsIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M20.75 7.0058L12.75 10.5614L4.75 7.0058"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3746 5.50559L14.3746 3.28336C13.3403 2.82369 12.1597 2.82369 11.1254 3.28337L6.12545 5.50559C4.68093 6.14759 3.75 7.58008 3.75 9.16083V15.9618C3.75 17.5426 4.68094 18.9751 6.12545 19.6171L11.1254 21.8393C12.1597 22.299 13.3403 22.299 14.3746 21.8393L19.3746 19.6171C20.8191 18.9751 21.75 17.5426 21.75 15.9618V9.16083C21.75 7.58008 20.8191 6.14759 19.3746 5.50559Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 10.5613V21.5613"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
