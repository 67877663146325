import { User, UserDraft } from "@modules/auth/user"
import { word } from "@modules/core/utils/i18n"
import { HUImageDraft } from "@modules/dtos/imageDto"
import { requiredInput } from "@modules/form/formUtils"
import { HUUnit } from "@modules/utils/unitType"
import * as Yup from "yup"

export type UserInputKey = "firstname" | "lastname" | "username" | "email" | "avatar" | "unit"

export function getValidationSchema() {
  return Yup.object({
    firstname: requiredInput(Yup.string()),
    lastname: requiredInput(Yup.string()),
    username: requiredInput(Yup.string()),
    email: Yup.string().email(word("box.form.email.error")),
    avatar: Yup.mixed(),
  })
}

export const convertUserToFormik = (user: User, unit: HUUnit): UserFormikType => {
  return {
    ...user,
    avatar: {
      serverBlurHash: user.avatar?.blurHash ?? "",
      serverPath: user.avatar?.path ?? "",
      localPath: undefined,
      localBase64: undefined,
    },
    unit,
  }
}

export function convertUserFromFormikToUserDraft(formik: UserFormikType): UserDraft {
  return {
    firstname: formik.firstname ?? "",
    lastname: formik.lastname ?? "",
    username: formik.username ?? "",
    avatar: formik.avatar ?? undefined,
  }
}

export type UserFormikType = {
  firstname: string
  lastname: string
  username: string
  email?: string
  avatar?: HUImageDraft
  unit: HUUnit
}
