import SubscribersEmpty from "@images/empty/subscribers_empty.svg"
import { ProfilRequestIcon } from "@images/svgIcons/profilRequestIcon"
import { word } from "@modules/core/utils/i18n"
import { ProgramSubscriber } from "@modules/programs/components/programSubscriber"
import { SASRequestsToAcceptView } from "@modules/programs/components/sasRequestsToAcceptView"
import { useProgramSASRequests } from "@modules/programs/hooks/usePrograms"
import { useProgramInfiniteSubscribers } from "@modules/programs/hooks/useProgramsInfinite"
import { ProgramInfinite } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { getSubscriptionCountText } from "@modules/programs/programUtils"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUText } from "@modules/ui/components/huText"
import { LoadingSpinner } from "@modules/ui/components/loading"
import dayjs from "dayjs"
import { Badge } from "primereact/badge"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import styled, { useTheme } from "styled-components"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"

type ProgramInfoSubscribersProps = {
  program: ProgramInfinite | ProgramOnOff
}

export const ProgramInfoSubscribers: React.FC<ProgramInfoSubscribersProps> = ({ program }) => {
  const {
    result: subscribers,
    refresh,
    loading: loadingSubscribers,
    listMore,
    totalSize,
  } = useProgramInfiniteSubscribers(program.id)
  const { isAuthor, isAcceptedSharedCoach } = useProgramRights(program)
  const theme = useTheme()

  const {
    result: sasRequests,
    list,
    loading: loadingSasRequests,
    setNewOrignRequest,
  } = useProgramSASRequests({
    programId: program.id,
    programType: program._programType,
    originDateRequest: dayjs().toDate(),
  })

  const [isSASRequestToAcceptViewDisplayed, setIsSASRequestToAcceptViewDisplayed] = useState(false)

  const hasSASRequests = program.entranceCheckEnabled && sasRequests.length > 0

  const toggleIsSASRequestToAcceptView = () => {
    setIsSASRequestToAcceptViewDisplayed(!isSASRequestToAcceptViewDisplayed)
  }

  useEffect(() => {
    refresh()
  }, [isSASRequestToAcceptViewDisplayed])

  return (
    <ContentContainer>
      {loadingSubscribers || loadingSasRequests ? (
        <div className="flex-grow-1">
          <LoadingSpinner />
        </div>
      ) : !isSASRequestToAcceptViewDisplayed ? (
        <>
          {hasSASRequests && (
            <SASContainer
              className="flex align-items-center h-2rem justify-content-between"
              onClick={() => setIsSASRequestToAcceptViewDisplayed(true)}
            >
              <div className="flex align-items-center">
                <ProfilRequestIcon color={"black"} />
                <div className="flex flex-column ml-2">
                  <HUText fontStyle="TS" className="mb-1">
                    {word("program.sas.list.header.title")}
                  </HUText>
                  <HUText fontStyle="BS">{word("program.sas.list.title", { count: sasRequests.length })}</HUText>
                </div>
              </div>
              <div className="mr-4 flex align-items-center">
                <BadgeContainer>
                  <Badge severity="danger" className="mr-2" size="normal"></Badge>
                </BadgeContainer>
                <i className={"pi pi-angle-right"} style={{ fontSize: "1.2rem" }} />
              </div>
            </SASContainer>
          )}
          <HUText fontStyle="BXS" color={theme.neutralColor900} className="mb-1">
            {getSubscriptionCountText(
              totalSize ?? 0,
              isAuthor,
              isAcceptedSharedCoach,
              true,
              program._programType,
            ).toLowerCase()}
          </HUText>
          {subscribers.length > 0 ? (
            <InfiniteScroll
              dataLength={subscribers.length}
              next={listMore}
              hasMore={subscribers.length < (totalSize || 0)}
              scrollableTarget={"sidebar-content"}
              loader={<HUText fontStyle="BM">{word("global.loading")}</HUText>}
              style={{ paddingBottom: 40 }}
            >
              {subscribers.map((subscriber) => (
                <ProgramSubscriber subscriber={subscriber} key={subscriber.id} />
              ))}
            </InfiniteScroll>
          ) : (
            <EmptyView
              image={{ src: SubscribersEmpty }}
              title={
                program._programType === ProgramOnOffTypeOf
                  ? word("program.onOff.subscribers.title.empty")
                  : word("program.subscribers.title.empty")
              }
              description={word("program.subscribers.description.empty")}
              flexGrow={false}
              className="p-6"
              style={{ backgroundColor: theme.mainWhite }}
            />
          )}
        </>
      ) : (
        <SASRequestsToAcceptView
          sasRequests={sasRequests}
          programId={program.id}
          toggleIsSASRequestToAcceptView={toggleIsSASRequestToAcceptView}
          hideIsSASRequestToAcceptView={() => setIsSASRequestToAcceptViewDisplayed(false)}
          onEndRequestOperation={setNewOrignRequest}
          list={list}
        />
      )}
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const SASContainer = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
`

const BadgeContainer = styled.div`
  .p-badge {
    width: 0.6rem;
    min-width: 0.6rem;
    height: 0.6rem;
  }
`
