import { DurationUnit, convertDurationToDate } from "@modules/duration/timeConverter"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { HUAvatarForm, HUAvatarFormProps } from "@modules/ui/components/huAvatarForm"
import { HUImageForm, HUImageFormProps } from "@modules/ui/components/huImageForm"
import { HUInput, HUInputProps } from "@modules/ui/components/huInput"
import { HUPhoneInput, HUPhoneInputProps } from "@modules/ui/components/huPhoneInput"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { LoadingPage } from "@modules/ui/components/loadingPage"
import { HUMultiSelect, HUMultiSelectProps } from "@modules/ui/huMultiSelect"
import { FieldValueChange } from "@src/typings/formik"
import dayjs from "dayjs"
import { FieldInputProps } from "formik"
import { Calendar, CalendarPropsRange, CalendarPropsSingle } from "primereact/calendar"
import { Checkbox } from "primereact/checkbox"
import { Dropdown, DropdownProps } from "primereact/dropdown"
import { InputMask, InputMaskProps } from "primereact/inputmask"
import { InputNumber, InputNumberProps } from "primereact/inputnumber"
import { InputSwitch, InputSwitchProps } from "primereact/inputswitch"
import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea"
import { RadioButton, RadioButtonProps } from "primereact/radiobutton"
import { classNames } from "primereact/utils"
import React, { ReactElement } from "react"

import styled, { CSSProperties, css, useTheme } from "styled-components"

export type FormikInputType =
  | "text"
  | "textarea"
  | "radio"
  | "number"
  | "numberChrono"
  | "switch"
  | "switchInline"
  | "explainedSwitchInline"
  | "dropdown"
  | "calendar"
  | "calendarRange"
  | "maskedInput"
  | "calendarTime"
  | "image"
  | "multiselect"
  | "avatar"
  | "checkbox"
  | "phoneNumber"

type FormikProps<T, K> = {
  name: T
  label?: string
  isRequiredInput?: boolean
  flex?: boolean
  rightButton?: React.ReactNode
  formikStyle?: CSSProperties
  loading?: boolean
  labelDisabled?: boolean
  disabled?: boolean
  isInvalid: boolean
  error?: JSX.Element
  getFieldProps: (nameOrOptions: T) => FieldInputProps<any>
  setFieldValue?: FieldValueChange<K>
  type?: FormikInputType
  textProps?: Omit<HUInputProps, "value" | "flex">
  calendarProps?: CalendarPropsSingle & { durationUnits?: DurationUnit; datetimeformat?: string }
  calendarRangeProps?: CalendarPropsRange
  maskedInputProps?: InputMaskProps
  textareaProps?: InputTextareaProps
  radioProps?: RadioButtonProps & { options: string[]; labels: string[] }
  dropdownProps?: DropdownProps
  multiSelectProps?: HUMultiSelectProps<string>
  numberProps?: InputNumberProps
  switchProps?: InputSwitchProps
  imageProps?: HUImageFormProps
  avatarProps?: HUAvatarFormProps
  phoneNumberProps?: HUPhoneInputProps
  timeFormat?: "HH:mm" | "mm:ss"
  placeHolder?: string
  avatarName?: string
  description?: string
  textLabel?: string
  textSwitch?: string
  switchExplanations?: {
    switchOffLabel: string
    switchOffDescription: string
    switchOnLabel: string
    switchOnDescription: string
  }
  rowNumber?: number
  iconTag?: ReactElement
  labelInside?: boolean
}

type FormikNumberProps<T, K> = {
  type: "number"
  setFieldValue: FieldValueChange<K>
} & FormikProps<T, K>

type FormikAvatarProps<T, K> = {
  type: "avatar"
  avatarProps: HUAvatarFormProps
} & FormikProps<T, K>

type FormikDropdownProps<T, K> = {
  type: "dropdown"
  setFieldValue: FieldValueChange<K>
} & FormikProps<T, K>

type FormikMultiSelectProps<T, K> = {
  type: "multiselect"
  setFieldValue: FieldValueChange<K>
} & FormikProps<T, K>

type FormikPhoneNumberProps<T, K> = {
  type: "phoneNumber"
  phoneNumberProps: HUPhoneInputProps
} & FormikProps<T, K>

type FormikInputProps<T, K> = {
  type?:
    | "text"
    | "textarea"
    | "numberChrono"
    | "radio"
    | "switch"
    | "switchInline"
    | "explainedSwitchInline"
    | "calendar"
    | "calendarRange"
    | "maskedInput"
    | "calendarTime"
    | "image"
    | "avatar"
    | "multiselect"
    | "checkbox"
    | "dropdown"
    | "phoneNumber"
} & FormikProps<T, K>

export function FormikInput<T extends string, K>({
  label,
  textLabel,
  isRequiredInput,
  isInvalid,
  flex = false,
  rightButton,
  formikStyle,
  loading,
  name,
  error,
  getFieldProps,
  type = "text",
  labelDisabled = false,
  disabled = false,
  textProps,
  textareaProps,
  radioProps,
  phoneNumberProps,
  dropdownProps,
  multiSelectProps,
  numberProps,
  switchProps,
  calendarProps,
  calendarRangeProps,
  maskedInputProps,
  imageProps,
  avatarProps,
  placeHolder,
  description,
  iconTag,
  textSwitch,
  switchExplanations,
  rowNumber = 5,
  labelInside,
  ...props
}:
  | FormikNumberProps<T, K>
  | FormikDropdownProps<T, K>
  | FormikMultiSelectProps<T, K>
  | FormikInputProps<T, K>
  | FormikPhoneNumberProps<T, K>
  | FormikAvatarProps<T, K>) {
  const theme = useTheme()
  const language = useLanguage()
  return (
    <Container className="field" $type={type} $flex={flex}>
      {label && (
        <HUText
          type="label"
          color={theme.inputLabelColor}
          fontStyle="LS"
          className={classNames({ "p-error": isInvalid })}
          textProps={{ htmlFor: name }}
          style={{ lineHeight: "21px", opacity: labelDisabled ? 0.6 : 1, marginBottom: 7 }}
        >
          {label.toUpperCase()}
          {isRequiredInput && (
            <HUText fontStyle="LL" color={theme.necessaryInput}>
              {" "}
              *
            </HUText>
          )}
        </HUText>
      )}
      {(() => {
        if (loading) {
          return <LoadingPage />
        } else {
          switch (type) {
            case "text":
              return (
                <FormikContainer>
                  <HUInput
                    id={name}
                    style={formikStyle}
                    disabled={disabled}
                    placeholder={placeHolder ?? label}
                    {...getFieldProps(name)}
                    {...textProps}
                    flex={flex}
                    hasError={isInvalid}
                  />
                  {rightButton}
                </FormikContainer>
              )
            case "textarea":
              return (
                <FormikContainer>
                  <InputTextarea
                    style={formikStyle}
                    className={isInvalid ? "p-invalid" : ""}
                    placeholder={placeHolder ?? label}
                    rows={rowNumber}
                    cols={30}
                    disabled={disabled}
                    {...getFieldProps(name)}
                    {...textareaProps}
                  />
                  {rightButton}
                </FormikContainer>
              )
            case "radio":
              return (
                <div className="grid">
                  {radioProps?.options.map((it, index) => {
                    return (
                      <div key={it} className="col-12 md:col-4">
                        <div className="field-radiobutton">
                          <RadioButton
                            inputId={it}
                            name={name}
                            value={it}
                            onChange={(e) => getFieldProps(name).onChange(e)}
                            checked={it === getFieldProps(name).value}
                            disabled={disabled}
                            {...radioProps}
                          />
                          <label htmlFor={it}>{radioProps.labels[index] ?? it}</label>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            case "number":
              return (
                <>
                  {!!description && (
                    <HUText fontStyle="BS" color={theme.neutralColor700} className="mb-2">
                      {description}
                    </HUText>
                  )}
                  <FormikContainer className="relative">
                    {labelInside && (
                      <LabelContainerNumber
                        type="label"
                        color={theme.neutralColor700}
                        fontStyle="BM"
                        className={classNames({ "p-error": isInvalid })}
                        textProps={{ htmlFor: name }}
                      >
                        {textLabel}
                      </LabelContainerNumber>
                    )}
                    <InputNumber
                      style={formikStyle}
                      {...getFieldProps(name)}
                      {...numberProps}
                      className={`${numberProps?.showButtons ? "p-showbuttons" : ""} ${isInvalid ? "p-invalid" : ""} ${rightButton ? "mr-2" : ""}`}
                      onChange={(e) => {
                        props.setFieldValue?.(name, e.value)
                        numberProps?.onChange?.(e)
                      }}
                      onValueChange={(e) => {
                        props.setFieldValue?.(name, e.value)
                        numberProps?.onValueChange?.(e)
                      }}
                      // value={getFieldProps(name).value ? parseInt(getFieldProps(name).value) : 0}
                      disabled={disabled}
                    />
                    <strong>{rightButton}</strong>
                  </FormikContainer>
                </>
              )
            case "phoneNumber":
              return (
                <FormikContainer>
                  <HUPhoneInput
                    id={name}
                    disabled={disabled}
                    placeholder={placeHolder ?? label}
                    {...getFieldProps(name)}
                    hasError={isInvalid}
                    {...phoneNumberProps}
                  />
                </FormikContainer>
              )
            case "numberChrono":
              return (
                <FormikCalendarChrono>
                  <LabelContainer
                    type="label"
                    color={theme.neutralColor700}
                    fontStyle="BM"
                    className={classNames({ "p-error": isInvalid })}
                    textProps={{ htmlFor: name }}
                  >
                    {textLabel}
                  </LabelContainer>
                  <InputNumber
                    style={formikStyle}
                    {...getFieldProps(name)}
                    {...numberProps}
                    className={isInvalid ? "p-invalid" : ""}
                    onChange={(e) => {
                      props.setFieldValue?.(name, e.value)
                      numberProps?.onChange?.(e)
                    }}
                    disabled={disabled}
                  />
                </FormikCalendarChrono>
              )
            case "switch":
              return (
                <FormikContainer style={{ gap: 12, marginBottom: 7 }}>
                  {!!description && (
                    <DescriptionText
                      style={{ flex: 1, textAlign: "justify", lineHeight: "20px", marginTop: 0 }}
                      fontStyle="BM"
                    >
                      {description}
                    </DescriptionText>
                  )}
                  <InputSwitchStyled
                    style={formikStyle}
                    {...getFieldProps(name)}
                    checked={getFieldProps(name).value === true}
                    disabled={disabled}
                    {...switchProps}
                  />
                  {rightButton}
                </FormikContainer>
              )
            case "switchInline":
              return (
                <FormikContainer style={{ gap: 12, marginBottom: 7, display: "flex", flexDirection: "column" }}>
                  <LineGreyContainer>
                    <HUText fontStyle="LL">{textSwitch}</HUText>
                    <InputSwitch
                      style={formikStyle}
                      {...getFieldProps(name)}
                      checked={getFieldProps(name).value === true}
                      disabled={disabled}
                      {...switchProps}
                    />
                  </LineGreyContainer>
                  {!!description && (
                    <DescriptionText
                      style={{ flex: 1, textAlign: "justify", lineHeight: "20px", marginTop: 0 }}
                      fontStyle="BS"
                    >
                      {description}
                    </DescriptionText>
                  )}
                </FormikContainer>
              )
            case "explainedSwitchInline":
              return (
                <FormikContainer style={{ gap: 12, marginBottom: 7, display: "flex", flexDirection: "column" }}>
                  <LineGreyContainer>
                    <HUStack className="col-5 text-center px-4" style={{ wordBreak: "break-word" }}>
                      <HUText
                        fontStyle="LL"
                        color={getFieldProps(name).value ? theme.textSecondary : theme.textPrimary}
                      >
                        {!getFieldProps(name).value && <span className="pi pi-check mr-2" />}
                        {switchExplanations?.switchOffLabel}
                      </HUText>
                      <HUText
                        fontStyle="BS"
                        color={getFieldProps(name).value ? theme.textSecondary : theme.textPrimary}
                      >
                        {switchExplanations?.switchOffDescription}
                      </HUText>
                    </HUStack>

                    <InputSwitch
                      style={formikStyle}
                      {...getFieldProps(name)}
                      checked={getFieldProps(name).value === true}
                      disabled={disabled}
                      {...switchProps}
                    />
                    <HUStack className="col-5 text-center px-4" style={{ wordBreak: "break-word" }}>
                      <HUText
                        fontStyle="LL"
                        color={getFieldProps(name).value ? theme.textPrimary : theme.textSecondary}
                      >
                        {getFieldProps(name).value && <span className="pi pi-check mr-2" />}
                        {switchExplanations?.switchOnLabel}
                      </HUText>
                      <HUText
                        fontStyle="BS"
                        color={getFieldProps(name).value ? theme.textPrimary : theme.textSecondary}
                      >
                        {switchExplanations?.switchOnDescription}
                      </HUText>
                    </HUStack>
                  </LineGreyContainer>
                  {!!description && (
                    <DescriptionText
                      style={{ flex: 1, textAlign: "justify", lineHeight: "20px", marginTop: 0 }}
                      fontStyle="BS"
                    >
                      {description}
                    </DescriptionText>
                  )}
                </FormikContainer>
              )
            case "dropdown":
              return (
                <>
                  {dropdownProps && (
                    <>
                      <FormikContainer>
                        <Dropdown
                          style={formikStyle}
                          className={classNames({ "p-invalid": isInvalid })}
                          {...dropdownProps}
                          value={dropdownProps.value}
                          disabled={disabled}
                          onChange={(e) => {
                            props.setFieldValue?.(name, e.target.value)
                            dropdownProps.onChange?.(e)
                          }}
                          options={dropdownProps.options}
                          placeholder={placeHolder}
                        />
                        {rightButton}
                      </FormikContainer>
                      {!!description && <DescriptionText fontStyle="BM">{description}</DescriptionText>}
                    </>
                  )}
                </>
              )
            case "multiselect":
              return (
                <>
                  {multiSelectProps && (
                    <FormikContainer>
                      <HUMultiSelect
                        style={formikStyle}
                        {...multiSelectProps}
                        values={multiSelectProps.values}
                        onChange={(value) => {
                          props.setFieldValue?.(name, value)
                          multiSelectProps.onChange?.(value)
                        }}
                        options={multiSelectProps.options}
                      />
                      {rightButton}
                    </FormikContainer>
                  )}
                </>
              )
            case "checkbox":
              return (
                <>
                  <FormikContainer>
                    <div className="flex flex-column align-items-center">
                      {iconTag}
                      <HUText fontStyle="LL" color={theme.neutralColor600} className="mb-2">
                        {textLabel}
                      </HUText>
                      <Checkbox
                        style={formikStyle}
                        checked={getFieldProps(name).value}
                        className={classNames({ "p-invalid": isInvalid })}
                        {...getFieldProps(name)}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onBlur={() => {}} // prevent missing event errors, primereact doesn't provide it to formik
                        disabled={disabled}
                      ></Checkbox>
                    </div>
                  </FormikContainer>
                </>
              )
            case "calendar":
              return (
                <FormikContainer>
                  <CalendarContainer
                    key={language}
                    locale={language}
                    formatDateTime={(date) =>
                      dayjs(date).format(calendarProps?.dateFormat ?? (calendarProps?.timeOnly ? "HH:mm:ss" : "L"))
                    }
                    id={name}
                    placeholder={placeHolder ?? ""}
                    style={formikStyle}
                    disabled={disabled}
                    className={"p-input-calendar " + (isInvalid ? "p-invalid" : "")}
                    {...calendarProps}
                    value={
                      calendarProps?.timeOnly
                        ? convertDurationToDate(
                            getFieldProps(name).value ?? 0,
                            calendarProps.durationUnits ?? "seconds",
                          )
                        : getFieldProps(name).value
                    }
                    name={getFieldProps(name).name}
                    onFocus={(e) => {
                      //add here because if used onBlur => double click to select a date
                      getFieldProps(name).onBlur(e)
                    }}
                    onChange={(e) => {
                      getFieldProps(name).onChange(e)
                      calendarProps?.onChange?.(e)
                    }}
                  />
                  {rightButton}
                </FormikContainer>
              )
            case "calendarRange":
              return (
                <FormikContainer>
                  <CalendarContainer
                    key={language}
                    locale={language}
                    formatDateTime={(date) =>
                      dayjs(date).format(
                        calendarRangeProps?.dateFormat ?? (calendarRangeProps?.timeOnly ? "HH:mm:ss" : "L"),
                      )
                    }
                    id={name}
                    style={formikStyle}
                    disabled={disabled}
                    className={"p-input-calendar " + (isInvalid ? "p-invalid" : "")}
                    selectionMode="range"
                    {...calendarRangeProps}
                    value={getFieldProps(name).value}
                    name={getFieldProps(name).name}
                    onFocus={(e) => {
                      //add here because if used onBlur => double click to select a date
                      getFieldProps(name).onBlur(e)
                    }}
                    onChange={(e) => {
                      getFieldProps(name).onChange(e)
                      calendarRangeProps?.onChange?.(e)
                    }}
                  />
                  {rightButton}
                </FormikContainer>
              )
            case "maskedInput":
              return (
                <FormikCalendarChrono>
                  <LabelContainer
                    type="label"
                    color={theme.neutralColor700}
                    fontStyle="BM"
                    className={classNames({ "p-error": isInvalid })}
                    textProps={{ htmlFor: name }}
                  >
                    {textLabel}
                  </LabelContainer>
                  <InputMask
                    key={language}
                    id={name}
                    style={formikStyle}
                    disabled={disabled}
                    className={isInvalid ? "p-invalid" : ""}
                    {...maskedInputProps}
                    value={getFieldProps(name).value}
                  />
                </FormikCalendarChrono>
              )
            case "calendarTime":
              return (
                <FormikContainer>
                  <Calendar
                    key={language}
                    locale={language}
                    formatDateTime={(date) => dayjs(date).format(calendarProps?.datetimeformat ?? "HH:mm:ss")}
                    showIcon
                    showButtonBar={false}
                    timeOnly
                    id={name}
                    style={formikStyle}
                    disabled={disabled}
                    className={isInvalid ? "p-invalid" : ""}
                    {...getFieldProps(name)}
                    {...calendarProps}
                    onChange={(e) => {
                      if (e.target.value !== null) {
                        getFieldProps(name).onChange(e)
                        calendarProps?.onChange?.(e)
                      }
                    }}
                  />
                </FormikContainer>
              )

            case "image":
              return (
                <FormikContainer>
                  <HUImageForm
                    type="logo"
                    {...imageProps}
                    setImage={(e) => {
                      props.setFieldValue?.(name, e)
                      imageProps?.setImage?.(e)
                    }}
                    image={getFieldProps(name).value}
                    onTouch={imageProps?.onTouch}
                  />
                </FormikContainer>
              )
            case "avatar":
              return (
                <FormikContainer>
                  <HUAvatarForm
                    setImage={(e) => {
                      props.setFieldValue?.(name, e)
                      avatarProps?.setImage?.(e)
                    }}
                    image={getFieldProps(name).value}
                    {...avatarProps}
                  />
                </FormikContainer>
              )
          }
        }
      })()}
      {error}
    </Container>
  )
}

const Container = styled.div<{ $type: FormikInputType; $flex: boolean }>`
  display: flex;
  flex-direction: column;

  textarea {
    resize: none;
  }

  ${({ $flex }) =>
    $flex &&
    css`
      flex: 1;
    `}
`

const DescriptionText = styled(HUText)`
  color: ${({ theme }) => theme.neutralColor600};
  margin-top: 7px;
  padding: 0 20px;
`

const InputSwitchStyled = styled(InputSwitch)`
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`

const FormikContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .p-dropdown {
    border-radius: 30px;
  }

  .p-inputtextarea {
    border-radius: 20px;
  }

  .p-inputtext {
    border-radius: 20px;
  }

  .p-checkbox-box .p-checkbox-icon {
    color: ${({ theme }) => theme.mainBlack};
  }

  .p-showbuttons > input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right-width: 0px;
  }

  .p-inputnumber-button {
    border-radius: 20px;
  }
`

const CalendarContainer = styled(Calendar)`
  &:focus-within > .p-datepicker-trigger {
    background-color: ${({ theme }) => theme.primaryColor} !important;
    border: 1px solid ${({ theme }) => theme.primaryColor} !important;
  }

  &:hover > .p-datepicker-trigger {
    background-color: ${({ theme }) => theme.primaryColor} !important;
    border: 1px solid ${({ theme }) => theme.primaryColor} !important;
  }

  .p-datepicker-trigger {
    background-color: transparent;
    color: ${({ theme }) => theme.neutralColor600};
    font-weight: 700;
    border-width: 1px;
    // border-color: ${({ theme }) => theme.neutralColor300};
    border-left-width: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .p-inputtext {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  &.p-input-calendar > input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right-width: 0px;
  }
`

const FormikCalendarChrono = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;

  .p-dropdown {
    border-radius: 30px;
  }

  .p-inputtextarea {
    border-radius: 20px;
  }

  .p-inputtext {
    text-align: right;
    border-radius: 30px;
    font-weight: 600;
    font-size: 1rem;
    padding-right: 20px;
  }

  .p-inputnumber {
    border-radius: 30px;
  }

  .p-calendar {
    border-radius: 30px;
  }
`
const LabelContainer = styled(HUText)`
  position: absolute;
  top: 0.7rem;
  left: 1rem;
  z-index: 1001;
`
const LabelContainerNumber = styled(HUText)`
  position: absolute;
  right: 20px;
  z-index: 1001;
`
const LineGreyContainer = styled.div`
  background-color: ${({ theme }) => theme.neutralColor100};
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 14px 20px;
  border-radius: 20px;
`
