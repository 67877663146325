import { Page } from "@betomorrow/micro-stores"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { ConfigService } from "@modules/core/services/configService"
import { ExerciseScoreCategory, ExerciseScoreDifficulty } from "@modules/exercises/exerciseTypes"
import { ScoreboardMyRanking, ScoreboardRanking } from "@modules/scoreboard/scoreboardTypes"
import {
  ScoreboardRankingDto,
  convertMyRankingFromDto,
  convertRankingFromDto,
} from "@modules/scoreboard/services/scoreboardDto"
import { Paginated, toPage } from "@modules/utils/paginated"

export class ScoreboardApi {
  private baseUrl = "programs"

  private buildExerciseUrl = (programId: string, sessionId: string, exerciseId: string) =>
    `${ConfigService.config.BASE_URL.replace("/web", "")}/mobile/${this.baseUrl}/${programId}/sessions/${sessionId}/exercises/${exerciseId}`

  constructor(private api: ApiClient) {}

  async getResult(
    programId: string,
    sessionId: string,
    exerciseId: string,
    difficulty: ExerciseScoreDifficulty,
    filter: ExerciseScoreCategory,
    referenceDate: string,
    page?: number,
    size = 10,
  ): Promise<Page<ScoreboardRanking>> {
    const result = await this.api.get<Paginated<ScoreboardRankingDto>>(
      `${this.buildExerciseUrl(programId, sessionId, exerciseId)}/scoreboard`,
      {
        params: {
          difficulty,
          filter,
          referenceDate,
          page,
          size,
        },
      },
    )
    const pageResult = toPage(result.data)
    return {
      ...pageResult,
      content: pageResult.content.map((ranking) => convertRankingFromDto(ranking)),
    }
  }

  async getRanking(
    programId: string,
    sessionId: string,
    exerciseId: string,
    difficulty: ExerciseScoreDifficulty,
    filter: ExerciseScoreCategory,
    referenceDate: string,
  ): Promise<ScoreboardMyRanking | null> {
    const result = await this.api.get<ScoreboardRankingDto | null>(
      `${this.buildExerciseUrl(programId, sessionId, exerciseId)}/ranking`,
      {
        params: {
          difficulty,
          filter,
          referenceDate,
        },
      },
    )
    return convertMyRankingFromDto(result.data, exerciseId + "-" + difficulty)
  }
}
