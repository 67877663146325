import ProgramEmpty from "@images/empty/program_empty.svg"
import { PlusIcon } from "@images/svgIcons/plusIcon"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { usePrograms } from "@modules/programs/hooks/usePrograms"
import { TrainingProgramsFilters } from "@modules/training/components/trainingProgramsFilter"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"

import styled, { useTheme } from "styled-components"

type TrainingProgramsFiltersViewProps = {
  allowProgramSelection: boolean
}

export const TrainingProgramsFiltersView: React.FC<TrainingProgramsFiltersViewProps> = ({ allowProgramSelection }) => {
  const theme = useTheme()
  const userProgramsAsync = usePrograms({ programFilter: "OWNED" })
  const subscribedProgramsAsync = usePrograms({ programFilter: "SUBSCRIBED" })
  const sharedCoachProgramsAsync = usePrograms({ programFilter: "SHARED_COACH" })
  const { navigateToProgramCreation } = useProgramSidebar()
  const mySpaceNavigate = useMySpaceNavigate()

  const onCreateProgramClick = () => {
    navigateToProgramCreation()
  }
  const onDiscoverClick = () => {
    mySpaceNavigate(Path.MySpace.Explore)
  }

  return (
    <>
      <HeaderContainer>
        <HUText fontStyle="HM">{word("global.programs")}</HUText>
        {(userProgramsAsync.result.length > 0 || subscribedProgramsAsync.result.length > 0) && (
          <NewButton
            type="Left_Icon"
            colorType="Primary"
            size="L"
            text={word("global.new")}
            icon={{ icon: PlusIcon }}
            className="mr-2"
            onClick={onCreateProgramClick}
          />
        )}
      </HeaderContainer>

      {userProgramsAsync.result.length === 0 &&
      subscribedProgramsAsync.result.length === 0 &&
      sharedCoachProgramsAsync.result.length === 0 ? (
        <div className="flex justify-content-center align-items-center flex-grow-1">
          <EmptyView
            image={{ src: ProgramEmpty }}
            title={word("programsSubscribed.empty.title")}
            description={word("programs.empty.description")}
            flexGrow={false}
            className="p-6"
            style={{ backgroundColor: theme.mainWhite }}
            button={
              <HUStack className="align-items-center">
                <HUButton
                  type="Default"
                  size="M"
                  colorType="Primary"
                  icon={{ iconView: <span className={`p-button-icon p-c pi pi-search`} /> }}
                  onClick={onDiscoverClick}
                  text={word("programs.discover")}
                />
                <HUText fontStyle="LL">{word("global.or")}</HUText>
                <HUButton
                  type="Default"
                  size="M"
                  colorType="Secondary"
                  icon={{ iconView: <span className={`p-button-icon p-c pi pi-plus`} /> }}
                  onClick={onCreateProgramClick}
                  text={word("program.form.addProgram.title")}
                />
              </HUStack>
            }
            paddingType="In_table"
          />
        </div>
      ) : (
        <>
          <TrainingProgramsFilters
            getProgramsAsync={userProgramsAsync}
            allowProgramSelection={allowProgramSelection}
            title={word("programs.filter.owned")}
            emptyTitle={word("programs.empty.title")}
            emptyDescription={word("program.empty.description.noProgram")}
            emptyButtonLabel={word("program.form.addProgram.title")}
            emptyButtonAction={onCreateProgramClick}
            emptyButtonIconClassName={`p-button-icon p-c pi pi-plus`}
            tag={{ color: theme.neutralColor900, backgroundColor: theme.neutralColor100 }}
          />
          <TrainingProgramsFilters
            getProgramsAsync={subscribedProgramsAsync}
            allowProgramSelection={allowProgramSelection}
            title={word("programs.filter.subscribed")}
            emptyTitle={word("programsSubscribed.empty.title")}
            emptyDescription={word("programsSubscribed.empty.description")}
            emptyButtonLabel={word("programs.discover")}
            emptyButtonAction={onDiscoverClick}
            emptyButtonIconClassName={`p-button-icon p-c pi pi-search`}
            tag={{ color: theme.mainWhite, backgroundColor: theme.secondaryColor }}
          />
          <TrainingProgramsFilters
            getProgramsAsync={sharedCoachProgramsAsync}
            allowProgramSelection={allowProgramSelection}
            title={word("programs.filter.sharedCoach")}
            tag={{ color: theme.neutralColor900 }}
          />
        </>
      )}
    </>
  )
}

const HeaderContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const NewButton = styled(HUButton)`
  padding: 10.5px 17.5px 10.5px 17.5px;
`
