import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const CloseIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="1.25098"
      y="2.99988"
      width="2.18"
      height="28.2846"
      rx="1.09"
      transform="rotate(-45 1.25098 2.99988)"
      fill={props.color}
    />
    <rect
      x="21.25"
      y="0.999878"
      width="2.18"
      height="28.2846"
      rx="1.09"
      transform="rotate(45 21.25 0.999878)"
      fill={props.color}
    />
  </svg>
)
