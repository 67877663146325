import { BoxPlanningContent } from "@modules/boxPlanning/components/boxPlanningContent"
import { CalendarProvider } from "@modules/calendar/context/calendarContext"
import { useServicesContext } from "@modules/core/services/services.context"
import dayjs from "dayjs"
import { useCallback } from "react"
import { useLocation } from "react-router-dom"

export const BoxPlanningPage: React.FC = () => {
  const { boxPlanningService } = useServicesContext()
  const location = useLocation() as { state?: { selectedDate?: string } }

  const onDateSet = useCallback(async (startDate: number | dayjs.Dayjs, endDate: number | dayjs.Dayjs) => {
    await boxPlanningService.updatePlanningSlots(
      dayjs(startDate).startOf("day").toString(),
      dayjs(endDate).endOf("day").toString(),
    )
  }, [])

  return (
    <CalendarProvider
      initialDate={dayjs(location.state?.selectedDate).startOf("day")}
      defaultDisplayDetailedEvents={false}
      defaultView="dayGridWeek"
      isUndated={false}
      isWeekTemplate={false}
      hasHours={true}
      refreshEvents={onDateSet}
      onDateSet={onDateSet}
    >
      <BoxPlanningContent />
    </CalendarProvider>
  )
}
