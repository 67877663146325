import {
  convertBoxSlotTemplateDraftToEvent,
  convertItemBoxProgrammationSlotToEvent,
} from "@modules/boxPlanning/boxPlanningTypes"
import { useBoxProgrammation } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { ItemBoxSlot } from "@modules/boxSlot/boxSlotTypes"
import { CalendarEventTypeWithHour } from "@modules/calendar/calendarTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { useObservable } from "micro-observables"
import { useParams } from "react-router-dom"
import { DefaultTheme, useTheme } from "styled-components"

export const useBoxPlanningSlots = (): ItemBoxSlot[] => {
  const { boxPlanningService } = useServicesContext()
  const planningSlots = useObservable(boxPlanningService.planningSlots)
  return planningSlots
}

export const useBoxPlanningSlotEvents = (): {
  slotEvents: CalendarEventTypeWithHour[]
  slots: ItemBoxSlot[]
  isLoadingSlots: boolean
} => {
  const planningSlots = useBoxPlanningSlots()
  const { boxPlanningService } = useServicesContext()
  const theme = useTheme()

  return {
    slots: planningSlots,
    slotEvents: planningSlots.map((slot) => convertItemBoxProgrammationSlotToEvent(slot, theme as DefaultTheme)),
    isLoadingSlots: useObservable(boxPlanningService.isLoadingSlots),
  }
}

export const useBoxSlotsBeingCreated = (): CalendarEventTypeWithHour[] => {
  const { boxSlotService } = useServicesContext()
  const { boxProgrammationId } = useParams()
  const theme = useTheme()
  const { result: boxProgrammation } = useBoxProgrammation(boxProgrammationId)

  const boxSlotTemplatesBeingCreated = useObservable(boxSlotService.boxSlotsBeingCreated)

  return boxProgrammation
    ? boxSlotTemplatesBeingCreated.map((slot) =>
        convertBoxSlotTemplateDraftToEvent(slot, boxProgrammation, theme as DefaultTheme),
      )
    : []
}

export const useBoxSlotsBeingViewed = (): CalendarEventTypeWithHour[] => {
  const { boxSlotService } = useServicesContext()
  const theme = useTheme()

  const boxSlotsBeingViewed = useObservable(boxSlotService.boxSlotsBeingViewed)
  return boxSlotsBeingViewed && boxSlotsBeingViewed.length > 0
    ? boxSlotsBeingViewed.map((boxSlotBeingViewed) =>
        convertItemBoxProgrammationSlotToEvent(boxSlotBeingViewed, theme as DefaultTheme),
      )
    : []
}
