import { MappedStore, Store } from "@betomorrow/micro-stores"
import { BoxPurchase } from "@modules/boxOffer/boxOfferTypes"
import { WebService } from "@modules/core/services/webService"
import { MemberDetailed, MemberDetailedDraft, MemberFilter } from "@modules/members/memberTypes"
import { MemberApi } from "@modules/members/services/memberApi"

export class MemberService implements WebService {
  membersStore = new Store<MemberDetailed>((id) => this.getMember(id))
  memberStripeBoxPurchasesStore = new MappedStore<BoxPurchase, string, BoxPurchase, "id">(
    async (memberId) => await this.getAllMemberPurchases(memberId),
  )

  constructor(private api: MemberApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init() {}

  async onSpaceChange(): Promise<void> {
    this.membersStore.clear()
  }

  async getAllMemberPurchases(memberId: string, page?: number, size?: number) {
    return this.api.getAllMemberPurchases(memberId, page, size)
  }

  async getMembers(page?: number, size?: number, filter?: MemberFilter, nameFilter?: string) {
    return this.api.getMembers(page, size, filter ?? "all", nameFilter)
  }

  async getMember(memberId: string) {
    return this.api.getMember(memberId)
  }

  async createMember(member: MemberDetailedDraft) {
    const result = await this.api.createMember(member)
    this.membersStore.save(result)
  }

  async updateMember(idMember: string, member: MemberDetailedDraft) {
    const updatedResult = await this.api.updateMember(idMember, member)
    this.membersStore.update(idMember, () => updatedResult)
  }
}
