import type {
  Breadcrumb,
  ChildContainerProps,
  LayoutConfig,
  LayoutContextProps,
  LayoutState,
} from "@modules/app/types/types"
import { useColorTheme } from "@modules/profile/useColorTheme"
import { PrimeReactContext } from "primereact/api"
import React, { useContext, useEffect, useState } from "react"

export const LayoutContext = React.createContext({} as LayoutContextProps)

export const LayoutProvider = (props: ChildContainerProps) => {
  const [breadcrumb, setBreadcrumb] = useState<Breadcrumb | undefined>()
  const [layoutConfig, setLayoutConfig] = useState<LayoutConfig>({
    ripple: false,
    inputStyle: "outlined",
    colorScheme: "light",
    theme: "owner",
    scale: 16,
  })

  const { changeTheme } = useContext(PrimeReactContext)

  const colorTheme = useColorTheme()

  useEffect(() => {
    if (layoutConfig.theme !== colorTheme) {
      changeTheme?.(layoutConfig.theme, colorTheme, "theme-link", () => {
        setLayoutConfig((prevState) => ({
          ...prevState,
          theme: colorTheme,
        }))
      })
    }
  }, [colorTheme])

  const [layoutState, setLayoutState] = useState<LayoutState>({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    overlaySubmenuActive: false,
    staticMenuMobileActive: false,
  })

  const onMenuToggle = () => {
    if (isDesktop()) {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive,
      }))
    } else {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive,
      }))
    }
  }

  const isDesktop = () => {
    return window.innerWidth > 991
  }

  const value = {
    layoutConfig,
    setLayoutConfig,
    layoutState,
    setLayoutState,
    onMenuToggle,
    isDesktop,
    breadcrumb,
    setBreadcrumb,
  }

  return <LayoutContext.Provider value={value}>{props.children}</LayoutContext.Provider>
}
