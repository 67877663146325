import { PriceDto } from "@modules/boxOffer/services/boxOfferDto"
import { convertImageFromDto, HUImageDto } from "@modules/dtos/imageDto"
import { convertProfileFromDto, ProfileDto } from "@modules/profile/profileDtos"
import {
  ItemProgramInfinite,
  ProgramInfinite,
  ProgramInfiniteDraft,
  ProgramInfiniteProfile,
  ProgramInfiniteStripeSubscriptionInfos,
  ProgramInfiniteTypeOf,
} from "@modules/programs/programInfiniteTypes"
import {
  ProgramContentTypeOf,
  ProgramParticipantSubscriptionStatus,
  ProgramPrice,
  ProgramSubscriptionStatus,
  ProgramVisibility,
} from "@modules/programs/programTypes"
import {
  convertProgramPaymentFromDto,
  convertProgramPriceFromDto,
  ProgramPaymentDto,
  ProgramPriceDto,
} from "@modules/programs/services/programDto"
import { ProgramInfiniteSessionDto } from "@modules/programSession/services/programSessionDto"
import { SharedCoachDto } from "@modules/sharedCoach/sharedCoachDtos"
import { convertToEnum } from "@modules/utils/enum"

export type ItemProgramInfiniteDto = {
  id: string
  name: string
  visibility: ProgramVisibility
  subscribed: boolean
  image: HUImageDto
  owner: ProfileDto
  sessions?: ProgramInfiniteSessionDto[]
  subscriptionCount: number
  defaultHideSessionContent: boolean
  scoreboardVisibility: boolean
  entranceCheckEnabled: boolean
  price?: PriceDto
  trialDuration: number
  type: string
  subscriptionStatus: ProgramSubscriptionStatus
  invitationLink: string
  isEligibleToTrial: boolean
  sharedCoaches?: SharedCoachDto[]
}

export type ProgramInfiniteProfileDto = {
  subscriptionStatus: string
} & ProfileDto

export function convertProgramInfiniteProfileFromDto(dto: ProgramInfiniteProfileDto): ProgramInfiniteProfile {
  return {
    subscriptionStatus: convertToEnum(
      ProgramParticipantSubscriptionStatus,
      dto.subscriptionStatus,
      ProgramParticipantSubscriptionStatus.INACTIVE,
    ),
    ...convertProfileFromDto(dto),
  }
}

export type ProgramInfiniteDto = {
  description: string
} & ItemProgramInfiniteDto

export type ProgramInfiniteDraftDto = {
  name: string
  description: string
  visibility: ProgramVisibility
  image?: string
  defaultHideSessionContent: boolean
  scoreboardVisibility?: boolean
  entranceCheckEnabled: boolean
  sharedCoaches?: string[]
}

export function convertItemProgramInfiniteFromDto(dto: ItemProgramInfiniteDto): ItemProgramInfinite {
  return {
    _programType: ProgramInfiniteTypeOf,
    id: dto.id,
    title: dto.name,
    visibility: dto.visibility,
    image: convertImageFromDto(dto.image),
    author: convertProfileFromDto(dto.owner),
    subscriptionCount: dto.subscriptionCount,
    defaultHideSessionContent: dto.defaultHideSessionContent,
    hideScoreboard: !dto.scoreboardVisibility,
    entranceCheckEnabled: dto.entranceCheckEnabled,
    price: dto.price as ProgramPrice,
    subscriptionStatus: dto.subscriptionStatus,
    subscribed: dto.subscribed,
    invitationLink: dto.invitationLink,
    trialDuration: dto.trialDuration,
    _type: ProgramContentTypeOf,
    isEligibleToTrial: dto.isEligibleToTrial,
    sharedCoaches: dto.sharedCoaches?.map((sharedCoach) => ({
      ...sharedCoach,
      user: convertProfileFromDto(sharedCoach.user),
    })),
  }
}

export function convertProgramInfiniteFromDto(dto: ProgramInfiniteDto): ProgramInfinite {
  return {
    ...convertItemProgramInfiniteFromDto(dto),
    description: dto.description,
  }
}

export function convertProgramInfiniteDraftToDto(draft: ProgramInfiniteDraft): ProgramInfiniteDraftDto {
  return {
    name: draft.name,
    description: draft.description,
    visibility: draft.visibility,
    image: draft.image?.path,
    defaultHideSessionContent: draft.defaultHideSessionContent,
    scoreboardVisibility: !draft.hideScoreboard,
    entranceCheckEnabled: draft.entranceCheckEnabled,
    sharedCoaches: draft.sharedCoaches?.map((coach) => coach.user.id),
  }
}

export function convertPartialProgramInfiniteDraftToDto(
  draft: Partial<ProgramInfiniteDraft>,
): Partial<ProgramInfiniteDraftDto> {
  return {
    name: draft.name ?? undefined,
    description: draft.description ?? undefined,
    visibility: draft.visibility ?? undefined,
    image: draft.image?.path,
    defaultHideSessionContent: draft.defaultHideSessionContent ?? undefined,
    scoreboardVisibility: !draft.hideScoreboard ?? undefined,
    entranceCheckEnabled: draft.entranceCheckEnabled ?? undefined,
    sharedCoaches: draft.sharedCoaches?.map((coach) => coach.user.id),
  }
}

export type ProgramTrialSubscriptionDto = {
  startDate: string
  endDate: string
}

export type ProgramInfiniteStripeSubscriptionInfosDto = {
  subscriptionStatus: string
  periodEndDate?: string
  paidPrice?: ProgramPriceDto
  trial?: ProgramTrialSubscriptionDto
  payment?: ProgramPaymentDto
}

export function convertProgramInfiniteStripeSubscriptionInfosFromDto(
  id: string,
  dto: ProgramInfiniteStripeSubscriptionInfosDto,
): ProgramInfiniteStripeSubscriptionInfos {
  return {
    id,
    subscriptionStatus: convertToEnum(
      ProgramSubscriptionStatus,
      dto.subscriptionStatus,
      ProgramSubscriptionStatus.INACTIVE,
    ),
    paidPrice: convertProgramPriceFromDto(dto.paidPrice),
    payment: convertProgramPaymentFromDto(dto.payment),
    endPeriodDate: dto.periodEndDate,
    trial: dto.trial,
  }
}
