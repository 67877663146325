import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxProgrammationPrivacyIcon } from "@modules/boxProgrammation/components/boxProgrammationPrivacyIcon"
import { BoxRestrictionTag } from "@modules/boxProgrammation/components/boxRestrictionTag"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import React from "react"
import styled from "styled-components"

type BoxRestrictionsType = {
  programmation: BoxProgrammation
}

export const BoxRestrictionsComponent: React.FC<BoxRestrictionsType> = ({ programmation }) => {
  const restrictions = programmation.restrictions ?? []
  return (
    <>
      {programmation.visibility && (
        <HUStack className={`border-round p-3 border-2 border-primary mt-4`} gap={20}>
          <HURow gap={20}>
            <BoxProgrammationPrivacyIcon visibility={programmation.visibility} isSelected />
            <HUStack className="justify-content-between w-full">
              <HUText fontStyle="BS">{word("box.programmation.activity.explanation1")}</HUText>
              <RestrictionsContainer>
                {restrictions.map((restriction) => {
                  return <BoxRestrictionTag restriction={restriction} key={restriction.id} isSelected />
                })}
              </RestrictionsContainer>
            </HUStack>
          </HURow>
        </HUStack>
      )}
    </>
  )
}

const RestrictionsContainer = styled.div`
  display: flex;
  padding: 3px 2px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;
`
