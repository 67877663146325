import {
  BoxProgrammation,
  BoxProgrammationDefaultSettings,
  BoxProgrammationLight,
} from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxProgrammationTemplateCalendarEvent } from "@modules/boxProgrammation/components/boxProgrammationTemplateCalendarEvent"
import { BOX_SLOT_PARTICIPANTS_LIMIT_MAX, BOX_SLOT_PARTICIPANTS_LIMIT_MIN } from "@modules/boxSlot/boxSlotTypes"
import { BoxSlotTemplate, Weekday } from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import {
  getCancellationBeginningOptions,
  getRegistrationBeginningOptions,
  getRegistrationEndOptions,
} from "@modules/boxSlotTemplate/boxSlotTemplateUtils"
import { useBoxSlotTemplateFormik } from "@modules/boxSlotTemplate/hooks/useBoxSlotTemplateFormik"
import { convertBoxSlotTemplateToEvent } from "@modules/boxSlotTemplate/hooks/useBoxSlotTemplates"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { useDateTimeCalendarProps } from "@modules/form/hooks/useDateTimeCalendarProps"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { useLocaleWeekDays } from "@modules/utils/hooks/useWeekdays"
import dayjs, { Dayjs } from "dayjs"
import { useMountEffect } from "primereact/hooks"
import { DefaultTheme, useTheme } from "styled-components"

export const BoxSlotTemplateForm: React.FC<{
  boxProgrammation: BoxProgrammation
  boxSlotTemplate: BoxSlotTemplate | null
  dateToCreateSlotTemplateOn?: Dayjs
  defaultSettings?: BoxProgrammationDefaultSettings | null
}> = ({ boxProgrammation, boxSlotTemplate, dateToCreateSlotTemplateOn, defaultSettings }) => {
  const { boxSlotTemplateService } = useServicesContext()
  const confirmPopup = useConfirmPopup()
  const { hideSidebar } = useSidebar()
  const theme = useTheme()
  const weekDaysOptions = useLocaleWeekDays()
  const { formik, isSubmitting } = useBoxSlotTemplateFormik(
    boxProgrammation,
    boxSlotTemplate,
    dateToCreateSlotTemplateOn,
    defaultSettings,
  )

  const isEdition = !!boxSlotTemplate

  const { startDateTimeMinDate, endDateTimeMinDate, dateTimeFormat, validateStartDateTime, validateEndDateTime } =
    useDateTimeCalendarProps()

  const registrationBeginningOptions = getRegistrationBeginningOptions()
  const registrationEndOptions = getRegistrationEndOptions()
  const cancellationBeginningOptions = getCancellationBeginningOptions()

  const confirmDeleteBoxSlotTemplate = (boxSlotTemplate: BoxSlotTemplate) => {
    confirmPopup.show({
      title: word("global.delete"),
      message: word("programmation.slotTemplates.delete_confirmation_message"),
      accept: async () => {
        await boxSlotTemplateService.deleteBoxSlotTemplate(boxProgrammation.id, boxSlotTemplate.id)
        hideSidebar()
      },
      footerProps: {
        align: true,
      },
    })
  }

  // Make sure we use valid end-date
  useMountEffect(() => {
    validateEndDateTime(
      formik.getFieldProps("endDateTime").value,
      formik.getFieldProps("startDateTime").value,
      (val) => {
        formik.setFieldValue("endDateTime", val)
      },
    )
  })

  return (
    <HUStack>
      <BoxProgrammationTemplateCalendarEvent
        event={convertBoxSlotTemplateToEvent(
          {
            id: boxSlotTemplate?.id ?? "",
            program: boxProgrammation as BoxProgrammationLight,
            startTime: dayjs(formik.values.startDateTime).format("HH:mm:ss"),
            endTime: dayjs(formik.values.endDateTime).format("HH:mm:ss"),
            weekDay: (formik.values.day !== "" ? formik.values.day : "MONDAY") as Weekday,
            participantLimit: formik.values.participantLimit,
          },
          theme as DefaultTheme,
          (formik.values.day !== "" ? formik.values.day : "MONDAY") as Weekday,
        )}
        key={boxSlotTemplate?.id ?? ""}
        canBeClicked={false}
        isOverlayed={true}
        relativePosition
      />

      <FormikInput
        flex
        formikStyle={{ flexGrow: 1 }}
        isRequiredInput
        label={word("programmation.slotTemplates.form.weekday.label")}
        placeHolder={word("programmation.slotTemplates.form.weekday.placeHolder")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("day", formik)}
        name="day"
        error={getFormErrorMessage("day", formik)}
        setFieldValue={formik.setFieldValue}
        type="dropdown"
        dropdownProps={{
          value: formik.getFieldProps("day").value,
          options: weekDaysOptions,
        }}
      />

      <HURow gap={20}>
        <FormikInput
          flex
          isRequiredInput
          formikStyle={{ display: "flex", flexGrow: 1 }}
          label={word("programmation.slotTemplates.form.hour.startTime")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid(`startDateTime`, formik)}
          error={getFormErrorMessage(`startDateTime`, formik)}
          name={`startDateTime`}
          type="calendarTime"
          calendarProps={{
            minDate: startDateTimeMinDate,
            datetimeformat: dateTimeFormat,
            placeholder: "08:00",
            value: formik.values.startDateTime,
            onChange: (event) => {
              const newValue = event.value?.valueOf()
              if (typeof newValue === "number") {
                validateStartDateTime(newValue, formik.values.endDateTime, (val) =>
                  formik.setFieldValue(`endDateTime`, val),
                )
              }
            },
          }}
        />
        <FormikInput
          flex
          isRequiredInput
          formikStyle={{ display: "flex", flexGrow: 1 }}
          label={word("programmation.slotTemplates.form.hour.endTime")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid(`endDateTime`, formik)}
          error={getFormErrorMessage(`endDateTime`, formik, {
            errorMessage: word("sessionForm.error.date"),
          })}
          name={`endDateTime`}
          type="calendarTime"
          calendarProps={{
            minDate: endDateTimeMinDate,
            datetimeformat: dateTimeFormat,
            value: formik.values.endDateTime,
            placeholder: "19:00",
            onChange: (event) => {
              const newValue = event.value?.valueOf()
              if (typeof newValue === "number") {
                validateEndDateTime(
                  newValue,
                  formik.values.startDateTime,
                  (val) => formik.setFieldValue(`startDateTime`, val),
                  true,
                )
              }
            },
          }}
        />
      </HURow>

      <FormikInput
        flex
        formikStyle={{ flexGrow: 1 }}
        isRequiredInput
        label={word("programmation.slotTemplates.form.participantLimit.label")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("participantLimit", formik)}
        name="participantLimit"
        error={getFormErrorMessage("participantLimit", formik, { errorMessage: word("global.form.required") })}
        setFieldValue={formik.setFieldValue}
        type="number"
        numberProps={{
          min: BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
          max: BOX_SLOT_PARTICIPANTS_LIMIT_MAX,
          showButtons: true,
        }}
      />

      <HUStack gap={10}>
        <HUText type="label" fontStyle="LS" className="uppercase">
          {word("box.slotTemplateGroup.form.registration.label")}
        </HUText>
        <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.registration.description")}</HUText>
        <HURow gap={10}>
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("box.slotTemplateGroup.form.registration.beginning")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("limitStart", formik)}
            name="limitStart"
            error={getFormErrorMessage("limitStart", formik)}
            setFieldValue={formik.setFieldValue}
            type="dropdown"
            dropdownProps={{
              value: formik.getFieldProps("limitStart").value,
              options: registrationBeginningOptions,
            }}
          />
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("box.slotTemplateGroup.form.registration.end")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("limitEnd", formik)}
            name="limitEnd"
            error={getFormErrorMessage("limitEnd", formik)}
            setFieldValue={formik.setFieldValue}
            type="dropdown"
            dropdownProps={{
              value: formik.getFieldProps("limitEnd").value,
              options: registrationEndOptions,
            }}
          />
        </HURow>
      </HUStack>
      <HUStack gap={10}>
        <HUText type="label" fontStyle="LS" className="uppercase">
          {word("box.slotTemplateGroup.form.cancellation.label")}
          <HUText fontStyle="LL" color={theme.necessaryInput}>
            {" *"}
          </HUText>
        </HUText>
        <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.cancellation.description")}</HUText>
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1 }}
          label={word("box.slotTemplateGroup.form.cancellation.beginning")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("limitCancel", formik)}
          name="limitCancel"
          error={getFormErrorMessage("limitCancel", formik)}
          setFieldValue={formik.setFieldValue}
          type="dropdown"
          dropdownProps={{
            value: formik.getFieldProps("limitCancel").value,
            options: cancellationBeginningOptions,
          }}
        />
      </HUStack>
      <HUStack gap={10}>
        <HUText type="label" fontStyle="LS" className="uppercase">
          {word("box.slotTemplateGroup.form.area.title")}
        </HUText>
        <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.area.subtitle")}</HUText>
        <FormikInput
          flex
          formikStyle={{ display: "flex", flexGrow: 1 }}
          name="location"
          isRequiredInput
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("location", formik)}
          setFieldValue={formik.setFieldValue}
          error={getFormErrorMessage("location", formik)}
          placeHolder={word("box.slotTemplateGroup.form.area.placeholder")}
        />
      </HUStack>

      <div className="flex justify-content-end mt-3">
        <div className="flex column gap-3 sm:flex-row flex-grow-1">
          {isEdition ? (
            <HUButton
              type="Default"
              size="M"
              colorType="Quaternary"
              text={word("global.delete")}
              onClick={() => confirmDeleteBoxSlotTemplate(boxSlotTemplate)}
              className="w-full"
            />
          ) : (
            <HUButton
              type="Default"
              size="M"
              colorType="Quaternary"
              text={word("global.cancel")}
              onClick={() => hideSidebar()}
              className="w-full"
            />
          )}
          <HUButton
            type="Default"
            size="M"
            colorType="Primary"
            text={word("box.slot.form.submit.button")}
            className="mr-2 w-full"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={formik.submitForm}
          />
        </div>
      </div>
    </HUStack>
  )
}
