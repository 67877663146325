import { WarningHexagonalIcon } from "@images/svgIcons/warningIcon"
import { BoxFormPageProps } from "@modules/box/components/form/boxFormFirstPage"
import { BoxFormTemplatePage } from "@modules/box/components/form/boxFormTemplatePage"
import { word } from "@modules/core/utils/i18n"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUCardAlert } from "@modules/ui/components/huCardAlert"
import { useTheme } from "styled-components"

export const BoxFormLegalDocumentsPage = ({
  handleSubmit,
  onPrevious,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  disabled,
}: BoxFormPageProps) => {
  const theme = useTheme()
  return (
    <BoxFormTemplatePage
      onPrevious={onPrevious}
      handleSubmit={handleSubmit}
      disabled={disabled}
      header={{
        title: word("box.creation.page.legal.title"),
        description: word("box.creation.page.legal.description"),
        children: (
          <HUCardAlert
            title={word("box.creation.page.legal.alert")}
            icon={<WarningHexagonalIcon color={theme.mainWhite} />}
            className="py-2 border-round-2xl align-items-center"
          />
        ),
      }}
    >
      <FormikInput
        flex
        isRequiredInput
        label={word("box.creation.page.legal.label")}
        placeHolder={word("box.creation.page.legal.placeholder")}
        isInvalid={isFormFieldInValid("siren")}
        getFieldProps={formik.getFieldProps}
        name="siren"
        error={getFormErrorMessage("siren")}
        textProps={{
          onBlur: (e) => {
            if (formik.handleBlur) formik.handleBlur(e, "siren")
          },
          onChange: (e) => {
            if (formik.handleChange) formik.handleChange(e, "siren")
          },
        }}
      />
    </BoxFormTemplatePage>
  )
}
