import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { LightProgramInfo } from "@modules/programs/programTypes"
import { PromoCodeType } from "@modules/promoCode/services/promoCodeDto"
import { getFormattedReductedProgramPrice } from "@modules/promoCode/utils/promoCodeUtils"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import styled from "styled-components"

export const ProgramReductionItem: React.FC<{
  program: LightProgramInfo
  promoCodeValue?: number
  promoCodeType?: PromoCodeType
  promoCodeDuration?: number
}> = ({ program, promoCodeValue, promoCodeType, promoCodeDuration }) => {
  const language = useLanguage()

  return (
    <HURow gap={10}>
      <ProgramImageContainer $size={45}>
        <img src={program.image.path} alt="Program image" width={45} height={45} />
      </ProgramImageContainer>
      <HUStack gap={2}>
        <HUText fontStyle="LL">{program.title}</HUText>
        <HUText fontStyle="BS">
          {`${formatCurrency(program.price?.value || 0, program.price?.currency || "EUR", language)} ${program._programType === ProgramInfiniteTypeOf ? word("program.price.priceSlashPerMonth") : ""}`}
          <span className={`pi pi-arrow-right text-xs px-2`} />
          {`${getFormattedReductedProgramPrice(language, program.price, promoCodeValue, promoCodeType)} ${program._programType === ProgramInfiniteTypeOf ? word("program.price.priceSlashPerMonth") : ""} ${!!promoCodeDuration ? word("promoCode.duringXMonths", { count: promoCodeDuration }) : ""}`}
        </HUText>
      </HUStack>
    </HURow>
  )
}

const ProgramImageContainer = styled.div<{ $size: number }>`
  display: flex;
  justify-content: center;
  overflow: hidden;
  img {
    object-fit: cover;
    border-radius: ${({ $size }) => $size / 2}px;
  }
`
