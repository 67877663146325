import { FetchStrategy } from "@betomorrow/micro-stores"
import { PlusIcon } from "@images/svgIcons/plusIcon"
import { useAllBoxProgrammations } from "@modules/boxPlanning/hooks/useBoxPlanningProgrammations"
import { useBoxProgrammationSidebar } from "@modules/boxProgrammation/hooks/useBoxProgrammationSidebar"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useWindowSize } from "@modules/utils/hooks/useWindowsSize"
import { useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import styled, { useTheme } from "styled-components"

type BoxPlanningHeaderCenterViewprops = {
  boxProgrammationId?: string
}

export const BoxPlanningHeaderCenterView: React.FC<BoxPlanningHeaderCenterViewprops> = ({ boxProgrammationId }) => {
  const theme = useTheme()
  const { result: programmationsAsync, loading } = useAllBoxProgrammations(FetchStrategy.Once)
  const { navigateToBoxProgrammationCreation } = useBoxProgrammationSidebar()
  const [areActivitiesExpanded, setAreActivitiesExpanded] = useState(false)
  const toggleActivityList = () => setAreActivitiesExpanded((value) => !value)
  const listRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const boxNavigate = useBoxNavigate()
  const location = useLocation()
  const { selectedDate } = useCalendar()
  const { width } = useWindowSize()

  const navigateToActivity = (activityId: string) => {
    if (boxProgrammationId === activityId) {
      boxNavigate(Path.Box.Planning, {
        state: { selectedDate: selectedDate?.toString() ?? "" },
      })
      return
    } else {
      if (boxProgrammationId) {
        if (location.pathname.includes(Path.Box.ProgrammationTemplate(boxProgrammationId))) {
          boxNavigate(Path.Box.ProgrammationTemplate(activityId))
          return
        } else if (location.pathname.includes(Path.Box.ProgrammationSessions(boxProgrammationId))) {
          boxNavigate(Path.Box.ProgrammationSessions(activityId), {
            state: { selectedDate: selectedDate?.toString() ?? "" },
          })
          return
        }
      }
    }

    boxNavigate(Path.Box.ProgrammationDetails(activityId), {
      state: { selectedDate: selectedDate?.toString() ?? "" },
    })
  }

  // Computes the "See more" position related to its container, as the overflow is changing
  const seeMoreBottomPosition = useMemo(() => {
    return areActivitiesExpanded && containerRef.current
      ? containerRef.current.clientHeight - containerRef.current.scrollHeight
      : 0
  }, [areActivitiesExpanded, containerRef.current?.scrollHeight, containerRef.current?.clientHeight, width])

  const displayShowMore = useMemo(() => {
    return listRef.current && listRef.current.scrollHeight > 118 // 118px is the height of 2 rows + gap
  }, [listRef.current?.scrollHeight, width])

  useEffect(() => {
    if (areActivitiesExpanded) setAreActivitiesExpanded(false)
  }, [width])

  return (
    <Container className="flex flex-column mb-5" $expanded={areActivitiesExpanded} ref={containerRef}>
      <HURow className="justify-content-between align-items-center ml-4 mt-4">
        <HUText fontStyle="TM">{word("box.planning.filter.activities.title")}</HUText>
      </HURow>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <ActivitiesContainer ref={listRef}>
          {programmationsAsync.map((activity) => {
            return (
              <StyledHUButton
                key={activity.id}
                type="Default"
                size="S"
                colorType="Quaternary"
                $isSelected={boxProgrammationId === activity.id}
                onClick={() => navigateToActivity(activity.id)}
                text={activity.name}
              />
            )
          })}
          <HUButton
            type="Left_Icon"
            colorType="Underline"
            size="XS"
            icon={{
              icon: PlusIcon,
              iconProps: { color: theme.textPrimary },
            }}
            className="align-self-center"
            onClick={navigateToBoxProgrammationCreation}
            text={word("box.planning.empty.create_activity")}
          />
        </ActivitiesContainer>
      )}
      {displayShowMore && (
        <HURow
          onClick={toggleActivityList}
          className="align-items-center justify-content-center mt-2 w-full cursor-pointer absolute pb-1 z-5 gap-2"
          style={{
            bottom: seeMoreBottomPosition,
          }}
        >
          <HUText fontStyle="BS">
            {areActivitiesExpanded ? word("box.planning.seeLess") : word("box.planning.seeMore")}
          </HUText>
          <span className={areActivitiesExpanded ? "pi pi-chevron-up" : "pi pi-chevron-down"} />
        </HURow>
      )}
      <GradientOverlay $bottom={seeMoreBottomPosition} />
    </Container>
  )
}

const Container = styled.div<{ $expanded: boolean }>`
  height: 200px;
  width: 100%;
  gap: 20px;
  background-color: ${({ theme }) => theme.surfaceCard};
  border-radius: 33px;
  z-index: 100;
  padding: 0;
  overflow: ${({ $expanded }) => ($expanded ? "visible" : "hidden")};
  position: relative;
`

const ActivitiesContainer = styled.div`
  padding: 0 18px 18px 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 5px;
  border-radius: 33px;
  background-color: ${({ theme }) => theme.surfaceCard};
  z-index: 3;
`
const StyledHUButton = styled(HUButton)<{ $isSelected: boolean }>`
  height: 40px;
  border: ${({ $isSelected, theme }) => `3px solid ${$isSelected ? theme.primaryColor : "auto"} !important`};
  margin: ${({ $isSelected }) => `${$isSelected ? "0px" : "2px"}`};
  &:hover {
    border: ${({ $isSelected, theme }) => `3px solid ${$isSelected ? theme.primaryColor : "auto"} !important`};
  }
`
const GradientOverlay = styled.div<{ $bottom: number }>`
  background: linear-gradient(180deg, rgba(28, 28, 28, 0) 30%, #1c1c1c 83%);
  border-width: 0px;
  height: 36px;
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 33px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${({ $bottom }) => `${$bottom}px`};
  pointer-events: none;
  z-index: 4;
`
