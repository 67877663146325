export enum AuthError {
  UserNotConfirmedException = "UserNotConfirmedException",
  UsernameExistsException = "UsernameExistsException",
  UserNotFoundException = "UserNotFoundException",
  NotAuthorizedException = "NotAuthorizedException",
  CodeMismatchException = "CodeMismatchException",
  PasswordResetRequiredException = "PasswordResetRequiredException",
  LimitExceededException = "LimitExceededException",
}

export enum UserErrorExceptions {
  EntityNotFoundException = "com.betomorrow.server.spring.exceptions.NotFoundException",
  ConflictException = "com.betomorrow.server.spring.exceptions.ConflictException",
}

export enum UserErrorCodes {
  UserNotExistsCode = "USER_NOT_EXISTS",
  UsernameAlreadyExists = "USERNAME_ALREADY_EXISTS",
}
