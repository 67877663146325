import { CalendarEventStyle, SlotTemplateEventType } from "@modules/calendar/calendarTypes"
import { CALENDAR_EVENT_MIN_HEIGHT, DEFAULT_CALENDAR_HOUR_HEIGHT } from "@modules/calendar/utils/calendarConstants"
import { getFormatedDateTime } from "@modules/language/languageUtils"
import { HUText } from "@modules/ui/components/huText"
import { useState } from "react"
import styled from "styled-components"

export const BoxProgrammationTemplateCalendarEvent: React.FC<{
  event: SlotTemplateEventType
  onClickEvent?: () => void
  canBeClicked: boolean
  isOverlayed: boolean
  relativePosition?: boolean
}> = ({ event, onClickEvent, canBeClicked, isOverlayed, relativePosition = false }) => {
  const [zIndex, setZIndex] = useState<number>(1)
  const onHover = () => {
    setZIndex(2)
  }

  const onLeave = () => {
    setTimeout(() => setZIndex(1), 50)
  }

  return (
    <Container
      $relativePosition={relativePosition}
      $style={event.style}
      $backgroundColor={event.color}
      $textColor={event.textColor}
      $minHeight={CALENDAR_EVENT_MIN_HEIGHT}
      $minHeightOnHover={DEFAULT_CALENDAR_HOUR_HEIGHT}
      $zIndex={zIndex}
      $isOverlayed={isOverlayed || (zIndex === 2 && canBeClicked)}
      $canBeClicked={canBeClicked}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      $borderColor={event.borderColor}
      $borderStyle={event.borderStyle}
      onClick={() => (canBeClicked && typeof onClickEvent !== "undefined" ? onClickEvent() : undefined)}
    >
      <Line $borderColor={event.leftBorderColor} />
      <div className="flex flex-column justify-content-between h-full py-2">
        <div className="flex flex-column gap-1">
          <HUText fontStyle="LXS" color={event.textColor}>
            {`${getFormatedDateTime(event.startDate, { mode: "time" })} - ${getFormatedDateTime(event.endDate, {
              mode: "time",
            })}`}
          </HUText>
          <HUText fontStyle="LM" fontWeight="bold" color={event.textColor}>
            {event.title}
          </HUText>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div<{
  $relativePosition?: boolean
  $minHeight: number
  $minHeightOnHover: number
  $zIndex: number
  $style: CalendarEventStyle | undefined
  $backgroundColor?: string
  $textColor?: string
  $isOverlayed: boolean
  $canBeClicked: boolean
  $borderColor?: string
  $borderStyle?: string
}>`
  position: ${({ $relativePosition }) => ($relativePosition ? "relative" : "absolute")};
  top: ${({ $style }) => $style?.top ?? 0}px;
  left: ${({ $style }) => $style?.left ?? "0px"};
  width: ${({ $style }) => $style?.width ?? "130px"};
  height: calc(${({ $style }) => $style?.height ?? 90}px - 2px);
  border: ${({ $borderColor, $borderStyle }) => `1px ${$borderStyle} ${$borderColor}`};
  border-left-width: 0px;
  color: ${({ $textColor }) => $textColor};
  display: flex;
  flex-direction: column;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  text-align: left;
  padding-left: 10px;
  opacity: ${({ $isOverlayed }) => ($isOverlayed ? 1 : 0.3)};
  transition:
    min-height 0.2s ease,
    width 0.2s ease;
  min-height: ${({ $minHeight }) => $minHeight}px;
  z-index: ${({ $zIndex }) => $zIndex};
  white-space: nowrap;
  cursor: ${({ $canBeClicked }) => ($canBeClicked ? "pointer" : "auto")};
  &:hover {
    width: ${({ $canBeClicked }) => ($canBeClicked ? "95%" : "none")};
    min-height: ${({ $minHeightOnHover, $canBeClicked }) => ($canBeClicked ? $minHeightOnHover + "px" : "none")};
  }
`

const Line = styled.div<{ $borderColor?: string }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background-color: ${({ $borderColor }) => $borderColor};
`
