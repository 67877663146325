import { CopyIcon } from "@images/svgIcons/copyIcon"
import { BOX_SLOT_PARTICIPANTS_DEFAULT_VALUE } from "@modules/boxSlot/boxSlotTypes"
import {
  BoxSlotTemplateGroupFormikType,
  BoxSlotTemplateGroupInputKey,
} from "@modules/boxSlotTemplate/boxSlotTemplateGroupFormikTypes"
import { Weekday } from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import { SlotTemplateRange } from "@modules/boxSlotTemplate/components/slotTemplateRange"
import { word } from "@modules/core/utils/i18n"
import { convertTimeStringToTimeValue } from "@modules/duration/timeConverter"
import { HUCheckbox } from "@modules/ui/components/huCheckbox"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import dayjs from "dayjs"
import { Tooltip } from "primereact/tooltip"
import { useMemo } from "react"
import { useTheme } from "styled-components"

export const DailySlotTemplates: React.FC<{
  weekday: Weekday
  formik: SimpleFormik<BoxSlotTemplateGroupFormikType, BoxSlotTemplateGroupInputKey>
  setDisplayCopyPasteDialog: (value: boolean) => void
  setSelectedDay: (value: Weekday | null) => void
}> = ({ weekday, formik, setDisplayCopyPasteDialog, setSelectedDay }) => {
  const theme = useTheme()

  const currentTemplatesOfTheDay = useMemo(() => {
    return formik.values.slotTemplatesRanges.filter((slotTemplatesRange) => slotTemplatesRange.weekDay === weekday)
  }, [formik.values.slotTemplatesRanges, weekday])

  const toggleCheckbox = () => {
    if (currentTemplatesOfTheDay.length > 0) {
      formik.setFieldValue("slotTemplatesRanges", [
        ...formik.values.slotTemplatesRanges.filter((slotTemplatesRange) => slotTemplatesRange.weekDay !== weekday),
      ])
    } else {
      addNewRange()
    }
  }

  const addNewRange = () => {
    const lastRangeOfTheDay = currentTemplatesOfTheDay.sort((templateA, templateB) =>
      dayjs(templateA.endTime).isAfter(templateB.endTime) ? -1 : 1,
    )[0]
    const currentMaxIndex = formik.values.slotTemplatesRanges.reduce(
      (max, range) => (range.index > max ? range.index : max),
      -1,
    )

    formik.setFieldValue("slotTemplatesRanges", [
      ...formik.values.slotTemplatesRanges,
      {
        index: currentMaxIndex + 1,
        weekDay: weekday,
        startTime: lastRangeOfTheDay
          ? dayjs(lastRangeOfTheDay.endTime).toDate()
          : dayjs().startOf("day").set("hour", 8).toDate(),
        endTime: lastRangeOfTheDay
          ? dayjs(lastRangeOfTheDay.endTime)
              .add(convertTimeStringToTimeValue("HH:mm", formik.values.slotDuration) ?? 60, "minute")
              .toDate()
          : dayjs().startOf("day").set("hour", 19).toDate(),
        participantLimit: BOX_SLOT_PARTICIPANTS_DEFAULT_VALUE,
      },
    ])
  }

  const removeRange = (rangeIndex: number) => {
    formik.setFieldValue("slotTemplatesRanges", [
      ...formik.values.slotTemplatesRanges.filter((item) => item.index !== rangeIndex),
    ])
  }

  const handleCopy = () => {
    setSelectedDay(weekday)
    setDisplayCopyPasteDialog(true)
  }

  return (
    <HUStack gap={5}>
      <HURow gap={20} className="align-items-center">
        <HUText fontStyle="TS" className="capitalize">
          {word(`global.day.${weekday}`)}
        </HUText>
        <Tooltip target={`.tooltip-copy-past`} className="tooltip-container-button" position="bottom">
          <div className="tooltip-content-button text-justify">
            <HUText fontStyle="LM" className="text-center">
              {word("programmation.slotTemplates.form.copy.tooltip")}
            </HUText>
          </div>
        </Tooltip>
        <button onClick={handleCopy} className="tooltip-copy-past">
          <CopyIcon color={theme.mainWhite} width={20} height={20} />
        </button>
      </HURow>

      <HURow gap={10} className="align-items-start">
        <HUCheckbox
          inputKey={""}
          name="option"
          value={currentTemplatesOfTheDay.length > 0}
          checked={currentTemplatesOfTheDay.length > 0}
          onChange={toggleCheckbox}
          color={theme.primaryColor}
          checkIconColor={theme.neutralColor700}
          style={{ marginTop: 40 }}
        />
        <HUStack gap={0}>
          {currentTemplatesOfTheDay.length > 0 ? (
            currentTemplatesOfTheDay.map((template, templateOfTheDayIndex) => (
              <SlotTemplateRange
                index={template.index}
                key={template.index}
                formik={formik}
                addNewRange={addNewRange}
                removeRange={removeRange}
                displayAddRangeButton={templateOfTheDayIndex === currentTemplatesOfTheDay.length - 1}
                displayLabels={templateOfTheDayIndex === 0}
              />
            ))
          ) : (
            <SlotTemplateRange formik={formik} displayLabels />
          )}
        </HUStack>
      </HURow>
    </HUStack>
  )
}
