import appIcon from "@images/appIcon.svg"
import { UseCharterType, useCharterData } from "@modules/consumerAgreements/datas/useCharter.data"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { HUFontStyle } from "@modules/ui/styles/font"
import styled from "styled-components"

export const UseCharterPage: React.FC = () => {
  const language = useLanguage() === "fr" ? "fr" : "en"

  const formatTerms = (terms: UseCharterType[], parentIndex: string = "0") => {
    return terms.map((term, termIndex) => (
      <HUStack key={termIndex} className="align-items-flex-start">
        <HURow className="gap-2">
          {term.index && (
            <HUText fontStyle="LL">{`${parentIndex === "0" ? "" : parentIndex + "."}${term.index}.`}</HUText>
          )}
          <HUStack>
            {term.title && (
              <HUText fontStyle="LL" className={parentIndex === "0" ? "underline" : ""}>
                {term.title}
              </HUText>
            )}
            {term.content && formatTextIndentation(term.content, "BM")}
            {term.items &&
              term.items.length > 0 &&
              formatTerms(term.items, `${parentIndex === "0" ? "" : parentIndex + "."}${term.index}`)}
          </HUStack>
        </HURow>
      </HUStack>
    ))
  }

  const formatTextIndentation = (text: string, fontStyle: HUFontStyle) => {
    return text.split("\n").map((line, lineIndex) => (
      <HUText key={lineIndex} fontStyle={fontStyle} className="text-justify">
        <div dangerouslySetInnerHTML={{ __html: line }} />
      </HUText>
    ))
  }

  return (
    <Container className="align-items-center">
      <HUStack>
        <HUStack className="align-items-center gap-2 underline">
          {formatTextIndentation(useCharterData[language].title, "HM")}
          <AppIcon src={appIcon} />
        </HUStack>
        <HURow className="align-items-center gap-3">
          {formatTextIndentation(useCharterData[language].effectiveDateLabel, "LL")}
          {formatTextIndentation(useCharterData[language].effectiveDateValue, "BM")}
        </HURow>
        {formatTerms(useCharterData[language].terms)}
      </HUStack>
    </Container>
  )
}

const Container = styled(HUStack)`
  max-width: 960px;
  margin: auto;
  padding: 50px 30px;
`

const AppIcon = styled.img`
  margin: 0 14px;
  width: 100px;
  height: 100px;
  margin: 10px;
`
