import React from "react"
import { MemberDetailed } from "@modules/members/memberTypes"
import { HUText } from "@modules/ui/components/huText"
import { useTheme } from "styled-components"
import { Mail, Phone, House, Ambulance, Dumbbell } from "lucide-react"
import Comingsoon from "@images/comingsoon.png"
import { word } from "@modules/core/utils/i18n"
import { HuBioCard } from "@modules/ui/components/huBioCard"
import { ItemProgram, ProgramTypeOf } from "@modules/programs/programTypes"
import { ItemBox } from "@modules/box/boxTypes"
import { Benchmark, ItemBenchmark } from "@modules/benchmark/benchmarkTypes"
import { Profile } from "@modules/profile/profileTypes"
import { Carousel } from "primereact/carousel"
import dayjs from "dayjs"
import { ExploreFilter } from "@modules/explore/exploreTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { ProgramInfoResume } from "@modules/programs/components/programInfoResume"
import { BoxInfoResume } from "@modules/box/components/boxInfoResume"
import { BenchmarkInfoResume } from "@modules/benchmark/components/benchmarkInfoResume"
import { ProfileInfoResume } from "@modules/profile/components/profileInfoResume"
import { HuProgramMiniCards } from "@modules/ui/components/huProgramMiniCard"

type Props = {
  memberDetailed: MemberDetailed
} & React.HTMLAttributes<HTMLDivElement>
export const MemberTabProfile: React.FC<Props> = ({ memberDetailed, ...props }) => {
  const theme = useTheme()
  const { sidebarNavigateTo } = useSidebar()

  const onViewDetailsClick = (itemId: string, itemTypeOf: string, programType?: ProgramTypeOf) => {
    switch (itemTypeOf) {
      case ExploreFilter.PROGRAM:
        if (programType)
          sidebarNavigateTo({
            content: <ProgramInfoResume programId={itemId} programType={programType} editionAllowed={false} />,
            replaceAllComponents: true,
            onHide: () => {
              return Promise.resolve(true)
            },
          })
        break
      case ExploreFilter.BOX:
        sidebarNavigateTo({
          content: <BoxInfoResume boxId={itemId} />,
          replaceAllComponents: true,
          onHide: () => {
            return Promise.resolve(true)
          },
        })
        break
      case ExploreFilter.BENCHMARK:
        sidebarNavigateTo({
          content: <BenchmarkInfoResume benchmarkId={itemId} />,
          replaceAllComponents: true,
          onHide: () => {
            return Promise.resolve(true)
          },
        })
        break
      case ExploreFilter.PROFILE:
        sidebarNavigateTo({
          content: <ProfileInfoResume userId={itemId} />,
          replaceAllComponents: true,
          onHide: () => {
            return Promise.resolve(true)
          },
        })
        break
    }
  }

  const programCardCarrouselTemplate = (program: ItemProgram | ItemBox | ItemBenchmark | Benchmark | Profile) => {
    return (
      <HuProgramMiniCards
        item={program}
        onViewDetailsClick={() => {
          if (program._type) {
            const programType = "_programType" in program ? program._programType : undefined
            onViewDetailsClick(program.id, program._type, programType)
          }
        }}
        isSelected={false}
      />
    )
  }

  return (
    <div className="flex flex-column w-full gap-6" {...props}>
      {/* CONTACT */}
      <div className="flex flex-column mt-2 gap-3">
        <HUText fontStyle="TM" color={theme.mainWhite}>
          {word("box.member.table.profile.contact")}
        </HUText>
        <div className="flex">
          <div className="flex flex-1 gap-2">
            <Mail strokeWidth="1" />
            <HUText fontStyle="TS" color={theme.mainWhite}>
              {memberDetailed.email}
            </HUText>
          </div>
          {memberDetailed.phoneNumber && (
            <div className="flex gap-2">
              <Phone strokeWidth="1" />
              <HUText fontStyle="TS" color={theme.mainWhite}>
                {memberDetailed.phoneNumber}
              </HUText>
            </div>
          )}
        </div>
        {/* ADDRESS */}
        <div className="flex flex-row gap-2">
          <House strokeWidth="1" />

          <div className="flex flex-1 flex-column gap-2">
            <HUText className="uppercase" fontStyle="BS" color={theme.neutralColor400}>
              {word("box.member.table.profile.address.label")}
            </HUText>
            <HUText fontStyle="TS" color={theme.mainWhite}>
              {memberDetailed.address?.address ? memberDetailed.address.address : word("global.notProvided")}
            </HUText>

            {memberDetailed.address?.additionalInformation && (
              <>
                <HUText className="uppercase mt-2" fontStyle="BS" color={theme.neutralColor400}>
                  {word("box.member.table.profile.address.supplement")}
                </HUText>
                <HUText fontStyle="TS" color={theme.mainWhite}>
                  {memberDetailed.address.additionalInformation}
                </HUText>
              </>
            )}

            <div className="flex justify-content-between">
              <div className="flex flex-column gap-1">
                <HUText className="uppercase mt-2" fontStyle="BS" color={theme.neutralColor400}>
                  {word("box.member.table.profile.address.zipCode")}
                </HUText>
                <HUText fontStyle="TS" color={theme.mainWhite}>
                  {memberDetailed.address?.postalCode ? memberDetailed.address.postalCode : word("global.notProvided")}
                </HUText>
              </div>

              <div className="flex flex-column gap-1">
                <HUText className="uppercase mt-2" fontStyle="BS" color={theme.neutralColor400}>
                  {word("box.member.table.profile.address.city")}
                </HUText>
                <HUText fontStyle="TS" color={theme.mainWhite}>
                  {memberDetailed.address?.city ? memberDetailed.address.city : word("global.notProvided")}
                </HUText>
              </div>

              <div className="flex flex-column gap-1">
                <HUText className="uppercase mt-2" fontStyle="BS" color={theme.neutralColor400}>
                  {word("box.member.table.profile.address.country")}
                </HUText>
                <HUText fontStyle="TS" color={theme.mainWhite}>
                  {memberDetailed.address?.country ? memberDetailed.address.country : word("global.notProvided")}
                </HUText>
              </div>
            </div>
          </div>
        </div>

        {/* EMERGENCY CONTACT */}
        <div className="flex flex-row gap-2">
          <Ambulance strokeWidth="1" />
          <div className="flex flex-column gap-1">
            <HUText className="uppercase" fontStyle="BS" color={theme.neutralColor400}>
              {word("box.member.table.profile.emergencyContact")}
            </HUText>
            {memberDetailed.emergencyInformation ? (
              <HUText fontStyle="TS" color={theme.mainWhite}>
                {memberDetailed.emergencyInformation}
              </HUText>
            ) : (
              <HUText fontStyle="TS" color={theme.mainWhite}>
                {word("box.member.table.profile.emergencyContactNotProvided")}
              </HUText>
            )}
          </div>
        </div>
        {memberDetailed.publicProfile?.crossfitStartDate && (
          <div className="flex flex-row gap-2">
            <Dumbbell strokeWidth="1" />
            <div className="flex flex-column gap-1">
              <HUText className="uppercase" fontStyle="BS" color={theme.neutralColor400}>
                {word("box.member.table.profile.startDate")}
              </HUText>
              <HUText fontStyle="TS" color={theme.mainWhite}>
                {memberDetailed.publicProfile?.crossfitStartDate}
              </HUText>
            </div>
          </div>
        )}
      </div>

      {/* BIO */}
      <HuBioCard
        description={memberDetailed.publicProfile?.biography}
        age={dayjs().diff(memberDetailed.publicProfile?.birthDate, "year")}
        weight={memberDetailed.publicProfile?.weight}
        height={memberDetailed.publicProfile?.height}
        category={memberDetailed.publicProfile?.genderCategory}
        job={memberDetailed.publicProfile?.job}
      />
      {/* BADGES */}
      {memberDetailed.publicProfile?.badges && memberDetailed.publicProfile?.badges.length > 0  && (
        <div>
          <HUText fontStyle="TM" color={theme.mainWhite}>
            {word("box.member.table.profile.badges")}
          </HUText>
         <div className="flex flex-wrap mt-2 gap-2">
          {memberDetailed.publicProfile.badges.map((badge, index) => (
            <img key={index} src={badge.badge.image.path} alt={badge.badge.name} />
          ))}
          </div>
        </div>
      )}
      {/* PERSONAL RECORDS */}
      <div>
        <HUText fontStyle="TM" color={theme.mainWhite}>
          {word("box.member.table.profile.personalRecords")}
        </HUText>
        <HUText
          className="flex align-items-center justify-content-center bg-cover bg-no-repeat overflow-hidden mt-2"
          fontStyle="HM"
          color={theme.mainWhite}
          style={{ backgroundImage: `url(${Comingsoon})`, height: "173px", width: "537px" }}
        >
          {word("box.member.table.profile.comingSoon")}
        </HUText>
      </div>
      {/* PROGRAMS */}
      {memberDetailed.publicProfile?.ownedPrograms && memberDetailed.publicProfile?.ownedPrograms.length > 0 && (
        <div>
          <HUText fontStyle="TM" color={theme.mainWhite}>
            {word("box.member.table.profile.memberPersonalPrograms")}
          </HUText>
          <div className="flex flex-wrap gap-2 mt-2">
            <Carousel
              className="w-full"
              showIndicators={false}
              showNavigators={true}
              numVisible={2}
              value={memberDetailed.publicProfile?.ownedPrograms}
              itemTemplate={programCardCarrouselTemplate}
            />
          </div>
        </div>
      )}
      {/* FOLLOWED PROGRAMS */}
      {memberDetailed.publicProfile?.followedPrograms && memberDetailed.publicProfile?.ownedPrograms.length > 0 && (
      <div>
        <HUText fontStyle="TM" color={theme.mainWhite}>
          {word("box.member.table.profile.followedPrograms")}
        </HUText>
        <div className="flex flex-wrap gap-2 mt-2">
          <Carousel
            className="w-full"
            showIndicators={false}
            showNavigators={true}
            numVisible={2}
            value={memberDetailed.publicProfile?.followedPrograms}
            itemTemplate={programCardCarrouselTemplate}
          />
        </div>
      </div>
       )}
      {/* GYM */}
      {/* <div>
        <HUText fontStyle="TM" color={theme.mainWhite}>
          {word("box.member.table.profile.memberGym")}
        </HUText>
         TODO: Add my gym
      </div>*/}
    </div>
  )
}
