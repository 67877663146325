import { ProgramForm } from "@modules/programs/components/programForm"
import { ProgramSelectView } from "@modules/programs/components/programHeader/programSelectView"
import { ProgramInfoResume } from "@modules/programs/components/programInfoResume"
import { ProgramPriceForm } from "@modules/programs/components/programPriceForm"
import { ProgramSasPaymentChoices } from "@modules/programs/components/programSasPaymentChoices"
import { ProgramSasWarning } from "@modules/programs/components/programSasWarning"
import { Program, ProgramTypeOf } from "@modules/programs/programTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"

export const useProgramSidebar = () => {
  const { sidebarNavigateTo } = useSidebar()

  const navigateToProgramView = (programId: string, programType: ProgramTypeOf) => {
    sidebarNavigateTo({
      content: <ProgramInfoResume programId={programId} programType={programType} />,
      replaceAllComponents: true,
    })
  }

  const navigateToProgramCreation = () => {
    sidebarNavigateTo({
      content: <ProgramSelectView />,
      replaceAllComponents: true,
    })
  }

  const navigateToProgramEdition = (program: Program) => {
    sidebarNavigateTo({
      content: <ProgramForm program={program} programType={program._programType} />,
    })
  }

  const navigateToProgramPriceEdition = (program: Program, replaceLastComponent: boolean = false) => {
    sidebarNavigateTo({
      content: <ProgramPriceForm program={program} />,
      replaceLastComponent,
    })
  }

  const navigateToProgramPriceSasWarning = (program: Program) => {
    sidebarNavigateTo({
      content: <ProgramSasWarning program={program} />,
    })
  }

  const navigateToProgramSasPaymentChoices = (program: Program) => {
    sidebarNavigateTo({
      content: <ProgramSasPaymentChoices program={program} />,
      replaceLastComponent: true,
    })
  }

  return {
    navigateToProgramView,
    navigateToProgramCreation,
    navigateToProgramEdition,
    navigateToProgramPriceEdition,
    navigateToProgramPriceSasWarning,
    navigateToProgramSasPaymentChoices,
  }
}
