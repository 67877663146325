import { Profile, ProgramAuthor } from "@modules/profile/profileTypes"
import {
  ProgramContentTypeOf,
  ProgramParticipantSubscriptionStatus,
  ProgramPayment,
  ProgramPrice,
  ProgramSubscriptionStatus,
  ProgramVisibility,
  SharedCoach,
} from "@modules/programs/programTypes"
import { HUImage } from "@modules/types/imageTypes"

export const ProgramInfiniteTypeOf = "PROGRAM_INFINITE"

export type ItemProgramInfinite = {
  id: string
  _programType: typeof ProgramInfiniteTypeOf
  title: string
  visibility: ProgramVisibility
  image: HUImage
  author: ProgramAuthor
  subscribers?: number
  subscriptionCount: number
  defaultHideSessionContent: boolean
  hideScoreboard: boolean
  entranceCheckEnabled: boolean
  price?: ProgramPrice
  trialDuration: number
  subscriptionStatus: ProgramSubscriptionStatus
  subscribed?: boolean
  invitationLink: string
  _type: typeof ProgramContentTypeOf
  isEligibleToTrial: boolean
  sharedCoaches?: SharedCoach[]
}

export type ProgramInfinite = {
  description: string
} & ItemProgramInfinite

export type ProgramInfiniteDraft = {
  _programType: typeof ProgramInfiniteTypeOf
  name: string
  visibility: ProgramVisibility
  description: string
  image?: HUImage
  defaultHideSessionContent: boolean
  hideScoreboard: boolean
  entranceCheckEnabled: boolean
  price?: ProgramPrice
  sharedCoaches?: SharedCoach[]
}

export function convertProgramToDraft(program?: ProgramInfinite): ProgramInfiniteDraft {
  return {
    _programType: ProgramInfiniteTypeOf,
    name: program?.title ?? "",
    visibility: program?.visibility ?? ProgramVisibility.PRIVATE,
    description: program?.description ?? "",
    image: program?.image,
    defaultHideSessionContent: program?.defaultHideSessionContent ?? false,
    hideScoreboard: program?.hideScoreboard ?? false,
    entranceCheckEnabled: program?.entranceCheckEnabled ?? false,
    price: program?.price,
    sharedCoaches: program?.sharedCoaches,
  }
}

export type ProgramInfiniteProfile = {
  subscriptionStatus: ProgramParticipantSubscriptionStatus
} & Profile

export type ProgramSubscriptionTrial = {
  startDate: string
  endDate: string
}

export type ProgramInfiniteStripeSubscriptionInfos = {
  id: string
  subscriptionStatus: ProgramSubscriptionStatus
  endPeriodDate?: string
  paidPrice?: ProgramPrice
  trial?: ProgramSubscriptionTrial
  payment?: ProgramPayment
}
