import { StaffMember, displayBoxRole } from "@modules/boxStaff/boxStaffTypes"
import { word } from "@modules/core/utils/i18n"
import { UserCell } from "@modules/members/components/userCell"
import { HUText } from "@modules/ui/components/huText"

export const staffColumns = ["name", "email", "role"] as const
export type StaffColumnsType = (typeof staffColumns)[number]

export const headerColumn = (key: StaffColumnsType): string => {
  switch (key) {
    case "name":
      return word("global.name")
    case "email":
      return word("global.email")
    case "role":
      return word("global.role")
  }
}

export const valueColumn = (columnType: StaffColumnsType, staffMember: StaffMember): JSX.Element => {
  switch (columnType) {
    case "name":
      return (
        <UserCell
          member={{
            avatar: staffMember.avatar,
            username: staffMember.firstname,
            firstname: staffMember.firstname,
            lastname: staffMember.lastname,
          }}
        />
      )
    case "email":
      return <HUText>{staffMember.email}</HUText>
    case "role":
      return <HUText>{displayBoxRole[staffMember.role]}</HUText>
  }
}
