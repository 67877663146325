type Config = {
  ENV: string
  BASE_URL: string
  WEB_URL: string
  ANDROID_STORE_URL?: string
  IOS_STORE_URL?: string
  SENTRY_DSN: string
  SENTRY_ENV: string
  COGNITO_USER_POOL_ID: string
  COGNITO_CLIENT_ID: string
  SEGMENT_ID: string
  STREAM_CHAT_API_KEY: string
  MAPBOX_API_KEY: string
  STREAM_CHAT_PUSH_PROVIDER_NAME: string
  AWS_REGION: string
  HIDE_BODY_REQUEST_LOG?: string
  SANITY_PREVIEW_TOKEN: string
  CONTACT_EMAIL: string
  CDN_FLAG: string
}

export class ConfigService {
  static config: Config
  static devMode: boolean

  static setConfig(config: Config) {
    this.config = config
  }

  static setDevMode(devMode: boolean) {
    this.devMode = devMode
  }
}
