import { AutomaticDuplicationIcon } from "@images/svgIcons/AutomaticDuplicationIcon"
import { CardioIcon } from "@images/svgIcons/cardioIcon"
import { GymIcon } from "@images/svgIcons/gymIcon"
import { WeightliftingIcon } from "@images/svgIcons/weightliftingIcon"
import { BoxSlotSessionFormikType } from "@modules/boxSlot/boxSessionFormikTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { AddExerciceBloc } from "@modules/exercises/components/addExerciceBloc"
import { ExerciseExistingBloc } from "@modules/exercises/components/exerciseExistingBloc"
import { GetNewExerciseDraft } from "@modules/exercises/exerciseUtils"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { ProgramSessionInputKey } from "@modules/programSession/programSessionFormikType"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import { useEffect } from "react"
import styled, { useTheme } from "styled-components"

type ProgramBoxSessionForm = {
  formik: SimpleFormik<BoxSlotSessionFormikType, ProgramSessionInputKey>
}

export const ProgramBoxSessionForm: React.FC<ProgramBoxSessionForm> = ({ formik }) => {
  const { navigateToExerciseForm } = useSessionSidebar()
  const { boxSlotService } = useServicesContext()
  const theme = useTheme()
  const { editedSlot } = useSessionFormContext()

  useEffect(() => {
    if (editedSlot) boxSlotService.updateBoxSlotsBeingViewed([editedSlot])
  }, [editedSlot])

  const addExercise = () => {
    const updatedExercises = [...formik.values.exercises, GetNewExerciseDraft()]
    formik.setFieldValue("exercises", updatedExercises)
    navigateToExerciseForm(true, updatedExercises.length - 1)
  }
  return (
    <HUStack>
      <div className="flex flex-column sm:flex-row sm:gap-3">
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1, borderRadius: 30 }}
          isRequiredInput
          label={word("sessionForm.name.title")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("name", formik)}
          error={getFormErrorMessage("name", formik)}
          name="name"
        />
      </div>
      <div className="flex justify-content-evenly">
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          type="checkbox"
          name="gymnasticTag"
          textLabel={word("program.planning.create.gymnastique")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("gymnasticTag", formik)}
          error={getFormErrorMessage("gymnasticTag", formik)}
          setFieldValue={formik.setFieldValue}
          iconTag={<GymIcon color={"black"} className="mb-2" />}
        />
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          type="checkbox"
          name="weightliftingTag"
          textLabel={word("program.planning.create.weightlifting")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("weightliftingTag", formik)}
          error={getFormErrorMessage("weightliftingTag", formik)}
          setFieldValue={formik.setFieldValue}
          iconTag={<WeightliftingIcon color={"black"} className="mb-2" />}
        />
        <FormikInput
          formikStyle={{ flexGrow: 1 }}
          type="checkbox"
          name="cardioTag"
          textLabel={word("program.planning.create.cardio")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("cardioTag", formik)}
          error={getFormErrorMessage("cardioTag", formik)}
          setFieldValue={formik.setFieldValue}
          iconTag={<CardioIcon color={"black"} className="mb-2" />}
        />
      </div>

      {formik.values.exercises.map((exercise, i) => (
        <ExerciseExistingBloc
          exercise={exercise}
          key={i}
          onUpdateExercise={() => {
            navigateToExerciseForm(false, i, exercise)
          }}
          formik={formik}
          exerciseIndex={i}
        />
      ))}
      <AddExerciceBloc onAddExercise={addExercise} />
      <HURow gap={18} className="mb-3">
        <div className="w-max flex align-items-center mr-2">
          <Round>
            <AutomaticDuplicationIcon color={theme.primaryColor300} />
          </Round>
        </div>

        <HUStack gap={5}>
          <HUText fontStyle="BL">{word("box.session.form.automaticDuplication.title")}</HUText>
          <HUText fontStyle="BS">{word("box.session.form.automaticDuplication.subTitle")}</HUText>
        </HUStack>
      </HURow>
      <FormikInput
        type="switchInline"
        label={word("session.form.hideSessionContent.label")}
        textSwitch={word("session.form.hideSessionContent.textSwitch")}
        description={word("boxSession.form.hideSessionContent.description")}
        name="hideSessionContent"
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("hideSessionContent", formik)}
        error={getFormErrorMessage("hideSessionContent", formik)}
        setFieldValue={formik.setFieldValue}
        switchProps={{
          checked: formik.values.hideSessionContent,
        }}
      />
      <FormikInput
        flex
        formikStyle={{ display: "flex", flexGrow: 1, borderRadius: 20 }}
        label={word("sessionForm.coachNote.title")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("description", formik)}
        error={getFormErrorMessage("description", formik)}
        name="description"
        type="textarea"
        textareaProps={{ autoResize: true }}
      />
    </HUStack>
  )
}
const Round = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryColor800};
  border-radius: 50px;
  height: 50px;
  width: 50px;
`
