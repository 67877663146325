import { AddressAutofill } from "@mapbox/search-js-react"
import { BoxFormikType, BoxInputKey } from "@modules/box/boxFormikType"
import { Config } from "@modules/config"
import { word } from "@modules/core/utils/i18n"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUInput } from "@modules/ui/components/huInput"
import { HUText } from "@modules/ui/components/huText"
import { SimpleFormik } from "@src/typings/formik"
import { countries } from "countries-list"
import { classNames } from "primereact/utils"
import styled, { useTheme } from "styled-components"

type Props = {
  isFormFieldInValid: (name: BoxInputKey) => boolean
  getFormErrorMessage: (name: BoxInputKey) => JSX.Element | undefined
  formik: SimpleFormik<BoxFormikType, BoxInputKey>
}

export const BoxMainInfoForm: React.FC<Props> = ({ formik, isFormFieldInValid, getFormErrorMessage }) => {
  const theme = useTheme()

  const countriesOptions = Object.entries(countries).map(([key, value]) => {
    return { label: value.native, code: key, ...value }
  })

  return (
    <div className="flex flex-column card p-fluid col-12 md:col-7 mb-0 p-5">
      <HeaderTitleContainer>
        <HUText fontStyle="HS">{word("box.form.header")}</HUText>
        <HUText fontStyle="BM" color={theme.neutralColor600}>
          {word("box.form.subtitle")}
        </HUText>
      </HeaderTitleContainer>
      <FormikInput
        isRequiredInput
        label={word("box.form.description.label")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("description")}
        name="description"
        error={getFormErrorMessage("description")}
        type="textarea"
      />
      <AddressAutofill
        accessToken={Config.MAPBOX_API_KEY}
        onRetrieve={(res) => {
          const foundCountry = countriesOptions.find(
            (countryOption) => countryOption.code === res.features[0].properties.country_code?.toUpperCase(),
          )
          if (foundCountry) formik.setFieldValue("country", foundCountry.code)
        }}
        theme={{
          variables: {
            unitHeader: "28px",
            fontFamily: '"Trenda", sans-serif',
            border: "2px solid #424242",
            borderRadius: "0",
            boxShadow: "0 0 1px 1px rgba(0,0,0,0.1)",
            colorText: theme.mainWhite,
            colorPrimary: theme.primaryColor,
            colorSecondary: theme.secondaryColor,
            colorBackgroundHover: theme.neutralColor300,
            colorBackground: theme.neutralColor100,
          },
          cssText: `
            .Results {
                z-index: 100000;
                border-radius: 20px;
            }
            .ResultsAttribution {
                  display: none;
                }
        `,
        }}
        popoverOptions={{ offset: 0 }}
      >
        <SectionTitleContainer>
          <HUText fontStyle="TM">{word("box.form.address.title")}</HUText>
          <HUText fontStyle="BM" color={theme.neutralColor600} className="mb-3">
            {word("box.form.address.subtitle")}
          </HUText>

          <HUText
            type="label"
            color={theme.inputLabelColor}
            fontStyle="LS"
            className={classNames({ "p-error": isFormFieldInValid("address") })}
            textProps={{ htmlFor: "address" }}
          >
            {word("box.form.address.address.label").toUpperCase()}
            <HUText fontStyle="LL" color={theme.necessaryInput}>
              {" "}
              *
            </HUText>
          </HUText>
          <HUInput
            value={formik.values.address}
            onChange={(event) => {
              formik.setFieldValue("address", event.target.value)
              if (formik.handleChange) formik.handleChange(event, "address")
            }}
            autoComplete="address-line1"
            required={true}
            hasError={isFormFieldInValid("address")}
            name="address"
            className="mb-3 mt-2"
            flex
            placeholder={word("box.form.address.address.label")}
            onBlur={(e) => {
              if (formik.handleBlur) formik.handleBlur(e, "address")
            }}
          />
          <FormikInput
            flex
            isInvalid={isFormFieldInValid("additionalAddress")}
            getFieldProps={formik.getFieldProps}
            name="additionalAddress"
            error={getFormErrorMessage("additionalAddress")}
            placeHolder={word("box.form.address.additional_address.placeholder")}
            textProps={{
              autoComplete: "address-line2",
            }}
          />
          <FormikInput
            isRequiredInput
            flex
            label={word("box.form.address.postalCode.label")}
            isInvalid={isFormFieldInValid("postalCode")}
            getFieldProps={formik.getFieldProps}
            name="postalCode"
            error={getFormErrorMessage("postalCode")}
            textProps={{
              autoComplete: "postal-code",
            }}
          />
          <FormikInput
            isRequiredInput
            flex
            label={word("box.form.address.city.label")}
            isInvalid={isFormFieldInValid("city")}
            getFieldProps={formik.getFieldProps}
            name="city"
            error={getFormErrorMessage("city")}
            textProps={{
              autoComplete: "address-level2",
            }}
          />
          <FormikInput
            flex
            isRequiredInput
            type="dropdown"
            label={word("box.form.address.country.label")}
            isInvalid={isFormFieldInValid("country")}
            getFieldProps={formik.getFieldProps}
            setFieldValue={formik.setFieldValue}
            name="country"
            error={getFormErrorMessage("country")}
            placeHolder={word("box.form.address.country.placeholder")}
            dropdownProps={{
              className: "w-full",
              options: countriesOptions.map((countryOption) => countryOption.native),
              value: countriesOptions.find((countryOption) => countryOption.code === formik.values.country)?.native,
              onChange: (e) => {
                const foundCountry = countriesOptions.find((countryOption) => countryOption.native === e.target.value)
                formik.setFieldValue("country", foundCountry?.code)
              },
              onBlur: (e) => {
                if (formik.handleBlur) formik.handleBlur(e, "country")
              },
            }}
          />
        </SectionTitleContainer>
      </AddressAutofill>
      <SectionTitleContainer>
        <HUText fontStyle="TM">{word("box.form.contact.title")}</HUText>
        <HUText fontStyle="BM" color={theme.neutralColor600} className="mb-3">
          {word("box.form.contact.subtitle")}
        </HUText>
        <FormikInput
          isRequiredInput
          label={word("box.form.phone.label")}
          isInvalid={isFormFieldInValid("phoneNumber")}
          getFieldProps={formik.getFieldProps}
          name="phoneNumber"
          error={getFormErrorMessage("phoneNumber")}
          type="phoneNumber"
          setFieldValue={formik.setFieldValue}
          placeHolder={word("box.form.phone.placeholder")}
          phoneNumberProps={{
            phoneNumber: formik.getFieldProps("phoneNumber").value,
            countryCode: formik.getFieldProps("phoneNumberCountryCode").value,
            onPhoneNumberBlur: (e) => {
              if (formik.handleBlur) formik.handleBlur(e, "phoneNumber")
            },
            onPhoneNumberChange: (e) => {
              if (formik.handleChange) formik.handleChange(e, "phoneNumber")
            },
            onPhoneCodeChange: (e) => {
              formik.setFieldValue("phoneNumberCountryCode", e.target.value)
            },
          }}
        />
        <FormikInput
          isRequiredInput
          flex
          label={word("box.form.email.label")}
          isInvalid={isFormFieldInValid("email")}
          getFieldProps={formik.getFieldProps}
          name="email"
          error={getFormErrorMessage("email")}
          textProps={{ className: "w-full" }}
        />
      </SectionTitleContainer>
      <FieldsSideBySide></FieldsSideBySide>

      <SectionTitleContainer>
        <HUText fontStyle="TM">{word("box.form.company.title")}</HUText>
        <HUText fontStyle="BM" color={theme.neutralColor600}>
          {word("box.creation.page.legal.description")}
          <br />
          {word("box.creation.page.legal.alert")}
        </HUText>
      </SectionTitleContainer>
      <FormikInput
        flex
        isRequiredInput
        label={word("box.creation.page.legal.label")}
        isInvalid={isFormFieldInValid("siren")}
        getFieldProps={formik.getFieldProps}
        name="siren"
        error={getFormErrorMessage("siren")}
      />
    </div>
  )
}

const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 21px;
  margin-bottom: 28px;
`

const HeaderTitleContainer = styled(SectionTitleContainer)`
  margin-top: 0;
`

const FieldsSideBySide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  @media screen and (min-width: 1500px) {
    flex-direction: row;
    gap: 24px;
  }
`
