import { MarkerIcon } from "@images/svgIcons/markerIcon"
import { BoxSlotTypeOf } from "@modules/boxSlot/boxSlotTypes"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramInfiniteSessionTypeOf, ProgramOnOffSessionTypeOf } from "@modules/programSession/programSessionTypes"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { TrainingBoxSlot, TrainingProgramSession } from "@modules/training/trainingTypes"
import { HUText } from "@modules/ui/components/huText"
import dayjs from "dayjs"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

type SessionCardProps = {
  session: TrainingProgramSession | TrainingBoxSlot
}

export const SessionCard: React.FC<SessionCardProps> = ({ session }) => {
  const theme = useTheme()
  const mySpaceNavigate = useMySpaceNavigate()
  const { navigateToSessionView } = useSessionSidebar()

  const sessionName = useMemo(() => {
    switch (session._type) {
      case BoxSlotTypeOf:
        return session.programmationName
      case ProgramInfiniteSessionTypeOf:
        return session.name
    }
  }, [session])

  const progOrBoxName = useMemo(() => {
    switch (session._type) {
      case BoxSlotTypeOf:
        return session.boxName
      case ProgramInfiniteSessionTypeOf:
        return session.programName
    }
  }, [session])

  const onSelectedSession = (session: TrainingProgramSession | TrainingBoxSlot) => {
    switch (session._type) {
      case ProgramInfiniteSessionTypeOf:
        mySpaceNavigate(Path.MySpace.Training)
        setTimeout(() => navigateToSessionView({ ...session, programType: ProgramInfiniteTypeOf }), 100)
        break
      case ProgramOnOffSessionTypeOf:
        mySpaceNavigate(Path.MySpace.Training)
        setTimeout(() => navigateToSessionView({ ...session, programType: ProgramOnOffTypeOf }), 100)
        break
      case BoxSlotTypeOf:
        // TO DO
        break
    }
  }

  return (
    <Card onClick={() => onSelectedSession(session)}>
      <div className="flex flex-column w-full">
        <div className="flex justify-content-between">
          <HUText fontStyle="TM" color={theme.mainWhite}>
            {sessionName}
          </HUText>
          {session._type === BoxSlotTypeOf && (
            <HUText fontStyle="BM" color={theme.mainWhite}>
              {dayjs(session.startDate).format("H:mm")}
            </HUText>
          )}
        </div>
        <div className="flex align-items-center mt-1">
          {session._type === BoxSlotTypeOf && (
            <MarkerIcon color={theme.primaryColor} className="mr-1" width={15} height={15} />
          )}
          <HUText fontStyle="BM" color={theme.secondaryColor200}>
            {progOrBoxName}
          </HUText>
        </div>
        <ContainerAvatarProfile className="flex align-items-center  justify-content-between flex-initial mt-2">
          <div className="flex">
            <ProfileAvatar
              className="mr-2 "
              profile={{ avatar: session.author.avatar, username: session.author.username || "" }}
              size={43}
            />
            <div className="flex flex-column">
              <HUText fontStyle="LL" className="mb-1" color={theme.mainWhite}>
                {`${session.author.firstname} ${session.author.lastname}`}
              </HUText>
              <HUText fontStyle="BS" className="mb-1" color={theme.secondaryColor200}>
                {word("program.label.author")}
              </HUText>
            </div>
          </div>
          <div>
            {session._type === BoxSlotTypeOf && (
              <div className="relative">
                <Subscribers fontStyle="LM">
                  {`${session.participantCount}/${session.participantLimit} ${word("slot.participants", { count: session.participantCount })}`}
                </Subscribers>
                <Going fontStyle="LM">{word("programs.going")}</Going>
              </div>
            )}
          </div>
        </ContainerAvatarProfile>
      </div>
    </Card>
  )
}

{
}
const Card = styled.div`
  padding: 24px 22px 22px 22px;
  background: linear-gradient(106.74deg, #01171c 2.13%, #0f3033 96.41%);
  border-radius: 33px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }
`
const ContainerAvatarProfile = styled.div`
  display: flex;
  align-items: center;
  border-radius: 27px;
  padding: 5px 0px 5px 0px;
  background-color: transparent;
  margin-top: 5px;
`
const Subscribers = styled(HUText)`
  background-color: ${({ theme }) => theme.secondaryColor800};
  color: ${({ theme }) => theme.secondaryColor200};
  padding: 10px 14px;
  border-radius: 18px;
`
const Going = styled(HUText)`
  background-color: ${({ theme }) => theme.secondaryColor};
  padding: 3px 8px;
  border-radius: 10px;
  color: ${({ theme }) => theme.mainWhite};
  transform: rotate(-10deg);
  position: absolute;
  left: 9px;
  bottom: 24px;
`
