import SessionEmpty from "@images/empty/session_empty.svg"
import { word } from "@modules/core/utils/i18n"
import { SessionCard } from "@modules/dashboard/components/cards/sessionCard"
import { useTrainingSessionsToday } from "@modules/dashboard/hooks/useTraining"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { TrainingContent } from "@modules/training/trainingTypes"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { HUText } from "@modules/ui/components/huText"
import { useCallback } from "react"
import styled from "styled-components"

export const SessionsResume: React.FC = () => {
  const trainingsToday = useTrainingSessionsToday()
  const mySpaceNavigate = useMySpaceNavigate()

  const returnContent = useCallback(
    (content: TrainingContent, index: number) => {
      let itemContent
      if (content.programSession) {
        itemContent = content.programSession
      } else if (content.boxSlot) {
        itemContent = content.boxSlot
      } else {
        throw new Error("No Explore Content find")
      }
      return <SessionCard key={index} session={itemContent} />
    },
    [trainingsToday],
  )

  return (
    <Container>
      <div className="flex justify-content-between align-items-center w-full">
        <HUText fontStyle="TL" className="mb-2">
          {word("home.screen.sessions.title")}
        </HUText>
        {trainingsToday.length > 1 && (
          <SeeAllButton
            label={word("global.button.seeAll")}
            to={Path.MySpace.Training}
            onPress={() => mySpaceNavigate(Path.MySpace.Training)}
            link={true}
            fontStyle="LL"
            icon={{
              iconView: <span className={`p-button-icon p-c pi pi-arrow-right`} />,
            }}
            iconPosition="right"
          />
        )}
      </div>
      {trainingsToday.length > 0 ? (
        trainingsToday.slice(0, 2).map((session, index) => returnContent(session, index))
      ) : (
        <>
          <EmptyViewStyled
            image={{ src: SessionEmpty }}
            title={word("dashboard.planning.empty.title")}
            description={word("dashboard.planning.empty.description")}
            flexGrow={false}
            className="p-2 mt-2"
            textAlign="center"
            disposition="column-reverse"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          />
          <DiscoverProgramButton
            label={word("programs.discover")}
            to={Path.MySpace.Explore}
            onPress={() => mySpaceNavigate(Path.MySpace.Explore)}
            fontStyle="LL"
          />
        </>
      )}
      {trainingsToday.length === 1 && (
        <div>
          <div className="my-4 flex flex-column align-items-center text-center">
            <HUText fontStyle="TM" className="mb-2">
              {word("home.screen.sessions.empty.title")}
            </HUText>
            <HUText fontStyle="BM">{word("home.screen.sessions.empty.description")}</HUText>
          </div>
          <DiscoverProgramButton
            label={word("programs.discover")}
            to={Path.MySpace.Explore}
            onPress={() => mySpaceNavigate(Path.MySpace.Explore)}
            fontStyle="LL"
          />
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  padding: 28px 20px;
  background-color: ${({ theme }) => theme.neutralColor100};
  border-radius: 33px;
`

const SeeAllButton = styled(HUNavigateButton)`
  .p-button-link {
    padding: 0px !important;
    border-radius: 0px;
  }
  .p-button-label span {
    text-decoration: underline;
  }
  .pi {
    font-size: 0.8rem !important;
  }
`
const DiscoverProgramButton = styled(HUNavigateButton)`
  .p-button {
    width: 100%;
    background-color: ${({ theme }) => theme.mainWhite};
    border: none;
  }
  .p-button:not(button):not(a):not(.p-disabled):hover {
    background-color: ${({ theme }) => theme.neutralColor300}!important;
  }
`
const EmptyViewStyled = styled(EmptyView)`
  & > div > div:nth-child(2) > span:nth-child(1) {
    font-size: 1.125rem;
  }
`
