import { CamembertIcon, CamembertIconProps } from "@images/svgIcons/camembertIcon"
import { useTheme } from "styled-components"

export type HuCamembertProps = {
  number: number
} & React.HTMLAttributes<HTMLDivElement>

/**
 * @param {HuCamembertProps} props
 * @param {number} props.number - The number define the color : 0 grey by default, 1 red, 2 yellow, 3 green
 * @returns {React.JSX.Element}
 */
export const HuCamembert: React.FC<HuCamembertProps> = ({ number, ...props }) => {
  const theme = useTheme()

  const params: CamembertIconProps[] = [
    {
      color1: theme.neutralColor50,
      color2: theme.neutralColor50,
      color3: theme.neutralColor50,
      strokeColor1: theme.neutralColor300,
      strokeColor2: theme.neutralColor300,
      strokeColor3: theme.neutralColor300,
    },
    {
      color1: theme.neutralColor50,
      color2: theme.error,
      color3: theme.neutralColor50,
      strokeColor1: theme.neutralColor300,
      strokeColor2: theme.blackAtheleWhiteOwner,
      strokeColor3: theme.neutralColor300,
    },
    {
      color1: theme.warning,
      color2: theme.warning,
      color3: theme.neutralColor50,
      strokeColor1: theme.blackAtheleWhiteOwner,
      strokeColor2: theme.blackAtheleWhiteOwner,
      strokeColor3: theme.neutralColor300,
    },
    {
      color1: theme.sucess,
      color2: theme.sucess,
      color3: theme.sucess,
      strokeColor1: theme.blackAtheleWhiteOwner,
      strokeColor2: theme.blackAtheleWhiteOwner,
      strokeColor3: theme.blackAtheleWhiteOwner,
    },
  ]

  if (number < 0 || number > 3) {
    number = 0
  }

  return <div {...props}>{params[number] && <CamembertIcon {...params[number]} />}</div>
}
