import { FetchStrategy, PaginatedStore, useMappedStore, usePaginatedStore, useStore } from "@betomorrow/micro-stores"
import { useServicesContext } from "@modules/core/services/services.context"
import { Member, MemberFilter } from "@modules/members/memberTypes"
import { useMemo } from "react"

export const useMembers = (page?: number, size?: number, filter?: MemberFilter, nameFilter?: string) => {
  const { memberService } = useServicesContext()

  const store = useMemo(
    () =>
      new PaginatedStore<Member, Member, "id">(() => memberService.getMembers(page, size, filter, nameFilter)),
    [page, size, filter, nameFilter],
  )

  return usePaginatedStore(store)
}

export const useAllMemberPurchases = (memberId: string, fetchStrategy?: FetchStrategy) => {
  const { memberService } = useServicesContext()
  return useMappedStore(memberId, memberService.memberStripeBoxPurchasesStore, fetchStrategy)
}

export const useMember = (id?: string) => {
  const { memberService } = useServicesContext()
  return useStore(id, memberService.membersStore)
}
