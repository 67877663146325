import { ApiClient } from "@modules/core/api/client/apiClient"
import { FinanceStatisticsDto, convertFinanceStatisticsFromDto } from "@modules/finances/services/financeStatisticsDto"
import {
  UserItemTransactionDto,
  UserTransactionDto,
  convertUserItemTransactionFromDto,
  convertUserTransactionFromDto,
} from "@modules/finances/services/transactionDto"
import { CustomerHistoryDto, convertItemCustomerHistoryFromDto } from "@modules/finances/services/transactionHistoryDto"
import { TransactionTotalsDto, convertTransactionTotalsFromDto } from "@modules/finances/services/transactionTotalsDto"
import { FinanceStatistics, TransactionStatus } from "@modules/finances/types/financesType"
import { TransactionTotals } from "@modules/finances/types/transactionTotalsType"
import { UserTransaction } from "@modules/finances/types/transactionType"
import { PaginatedCursor } from "@modules/utils/paginated"

export class UserFinanceApi {
  private userFinanceBaseUrl = "/financial"

  constructor(private api: ApiClient) {}

  async getTransaction(id: string): Promise<UserTransaction> {
    const resultDto = await this.api.get<UserTransactionDto>(`${this.userFinanceBaseUrl}/transactions/${id}`)
    return convertUserTransactionFromDto(resultDto.data)
  }

  async getTransactions(filter: TransactionStatus | undefined, period: string, page?: string, size = 10) {
    const result = await this.api.get<PaginatedCursor<UserItemTransactionDto>>(
      `${this.userFinanceBaseUrl}/transactions`,
      {
        params: {
          page,
          pageSize: size,
          period,
          status: filter,
        },
      },
    )

    const pageData = result.data
    return {
      ...pageData,
      data: pageData.data.map((transaction) => convertUserItemTransactionFromDto(transaction)),
    }
  }

  async getTransactionsHistory(stripeCustomerId: string, page?: string, size = 10) {
    const result = await this.api.get<PaginatedCursor<CustomerHistoryDto>>(
      `${this.userFinanceBaseUrl}/customer/${stripeCustomerId}/history`,
      {
        params: {
          page,
          pageSize: size,
        },
      },
    )
    const pageData = result.data
    return {
      ...pageData,
      data: pageData.data.map((transaction) => convertItemCustomerHistoryFromDto(transaction)),
    }
  }

  async getStats(period: string): Promise<FinanceStatistics> {
    const result = await this.api.get<FinanceStatisticsDto>(`${this.userFinanceBaseUrl}/stats`, {
      params: {
        period,
      },
    })
    return convertFinanceStatisticsFromDto(result.data)
  }

  async getTransactionTotals(period: string): Promise<TransactionTotals> {
    const result = await this.api.get<TransactionTotalsDto>(`${this.userFinanceBaseUrl}/transaction-status-totals`, {
      params: {
        period,
      },
    })
    return convertTransactionTotalsFromDto(result.data)
  }

  async getLastUpdate(): Promise<string> {
    const result = await this.api.get<{ lastUpdate: string }>(`${this.userFinanceBaseUrl}/last-update`)
    return result.data.lastUpdate
  }

  async getFirstPeriod(): Promise<string> {
    const result = await this.api.get<{ firstPeriod: string }>(`${this.userFinanceBaseUrl}/first-period`)
    return result.data.firstPeriod
  }

  async updateStats(): Promise<void> {
    await this.api.post(`${this.userFinanceBaseUrl}/update-stats`)
  }

  async exportTransactions(startDate: string, endDate: string): Promise<string> {
    const result = await this.api.post<string>(
      `${this.userFinanceBaseUrl}/export-transactions?startDate=${startDate}&endDate=${endDate}`,
    )
    return result.data
  }
}
