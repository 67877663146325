import { AmrapRecap } from "@modules/exercises/components/RecapDetailAllChronos/amrapRecap"
import { EmomRecap } from "@modules/exercises/components/RecapDetailAllChronos/emomRecap"
import { FortimeRecap } from "@modules/exercises/components/RecapDetailAllChronos/fortimeRecap"
import { TabataRecap } from "@modules/exercises/components/RecapDetailAllChronos/tabataRecap"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"

import styled from "styled-components"

export const ChronoRecap: React.FC = () => {
  const { formik, updatedExerciseIndex } = useSessionFormContext()

  if (updatedExerciseIndex === null) return <></>

  const formikValue = formik.values.exercises[updatedExerciseIndex]

  return (
    <ChronoContainer>
      {formikValue.chronoAmrap && <AmrapRecap />}
      {formikValue.chronoEmom && <EmomRecap />}
      {formikValue.chronoTabata && <TabataRecap />}
      {formikValue.chronoForTime && <FortimeRecap />}
    </ChronoContainer>
  )
}

const ChronoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 526px;
  padding: 14px 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.neutralColor100};
  margin-bottom: 20px;
`
