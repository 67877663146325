import { Store } from "@betomorrow/micro-stores"
import { BoxSlot, BoxSlotDraft, BoxSlotSession } from "@modules/boxSlot/boxSlotTypes"
import { BoxSlotApi } from "@modules/boxSlot/services/boxSlotApi"
import { BoxProgrammationSession, BoxProgrammationSessionDraft } from "@modules/boxSlot/services/boxSlotDto"
import { WebService } from "@modules/core/services/webService"
import { ParticipantStatus, Subscriber } from "@modules/profile/subscriber/subscriberTypes"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { observable } from "micro-observables"

export class BoxSlotService implements WebService {
  slotStore = new Store<BoxSlotSession>((slotId) => this.api.getSlotSessionDetails(slotId))
  private _scheduledSessionsInRange = observable<BoxProgrammationSession[]>([])
  readonly scheduledSessionsInRange = this._scheduledSessionsInRange.readOnly()
  private _isLoadingScheduledSessions = observable<boolean>(false)
  readonly isLoadingScheduledSessions = this._isLoadingScheduledSessions.readOnly()
  private _boxSlotsBeingCreated = observable<BoxSlotDraft[]>([])
  readonly boxSlotsBeingCreated = this._boxSlotsBeingCreated.readOnly()
  private _boxSlotsBeingViewed = observable<BoxSlot[]>([])
  readonly boxSlotsBeingViewed = this._boxSlotsBeingViewed.readOnly()
  /***
   * WARNING
   * use only for bind paginatedStore */
  slotSubscribers = new Store<Subscriber>(() => ({}) as Subscriber)

  constructor(private api: BoxSlotApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedOut(): Promise<void> {
    this._scheduledSessionsInRange.set([])
  }

  async getSessionDetails(id: string) {
    return this.api.getSessionDetails(id)
  }

  async createSessionAndSlot(
    sessionDraft: BoxProgrammationSessionDraft,
    slotDraft: BoxSlotDraft,
  ): Promise<string | undefined> {
    const session = await this.api.createSession(sessionDraft)
    const slot = await this.api.createSlot(slotDraft, session ? session.id : null)
    return slot.id
  }

  async updateSessionAndCreateSlot(
    sessionId: string,
    sessionDraft: BoxProgrammationSessionDraft,
    slotDraft: BoxSlotDraft,
  ): Promise<string | undefined> {
    const session = await this.api.updateSession(sessionId, sessionDraft)
    const slot = await this.api.createSlot(slotDraft, session ? session.id : "")
    return slot.id
  }

  async updateSessionAndSlot(
    sessionId: string,
    sessionDraft: BoxProgrammationSessionDraft,
    slotId: string,
    slotDraft: BoxSlotDraft,
  ): Promise<string | undefined> {
    await this.api.updateSession(sessionId, sessionDraft)
    await this.api.updateSlot(slotId, slotDraft, sessionId)
    return slotId
  }

  async createSessionAndUpdateSlot(
    sessionDraft: BoxProgrammationSessionDraft,
    slotId: string,
    slotDraft: BoxSlotDraft,
  ): Promise<string | undefined> {
    const session = await this.api.createSession(sessionDraft)
    await this.api.updateSlot(slotId, slotDraft, session ? session.id : "")
    return slotId
  }

  async createSlot(slotDraft: BoxSlotDraft, sessionId: string | null): Promise<string | undefined> {
    const slot = await this.api.createSlot(slotDraft, sessionId)
    return slot.id
  }

  async updateSlot(slotId: string, slotDraft: BoxSlotDraft, sessionId: string | null): Promise<string | undefined> {
    const updatedSlot = await this.api.updateSlot(slotId, slotDraft, sessionId)
    this.slotStore.update(slotId, (current) => ({ ...current, ...updatedSlot }))
    return slotId
  }

  async updateSession(sessionId: string, sessionDraft: BoxProgrammationSessionDraft) {
    const session = await this.api.updateSession(sessionId, sessionDraft)
    return session
  }

  async deleteSession(sessionId: string) {
    await this.api.deleteSession(sessionId)
  }

  async deleteSlot(id: string) {
    await this.api.deleteSlot(id)
  }

  async getSlotSubscribers(
    slotId: string,
    page?: number,
    size?: number,
    name?: string,
    participantStatus?: ParticipantStatus,
  ) {
    const result = await this.api.getSlotSubscribers(slotId, page, size, name, participantStatus)
    this.slotStore.update(slotId, (current) => ({
      ...current,
      registeredParticipantCount:
        participantStatus === "registered" ? result.totalSize : current.registeredParticipantCount,
      pendingParticipantCount: participantStatus === "pending" ? result.totalSize : current.pendingParticipantCount,
    }))
    return result
  }

  async getSlotNonParticipants(slotId: string, name?: string) {
    return this.api.getSlotNonParticipants(slotId, name)
  }

  async getScheduledSession(programId: string, date: Date) {
    return this.api.getScheduledSession(programId, dayjs(date).format(defaultDateFormat))
  }

  async addParticipantToSlot(slotId: string, memberId: string) {
    await this.api.addParticipantToSlot(slotId, memberId)
    this.slotStore.update(slotId, (current) => ({
      ...current,
      registeredParticipantCount: current.registeredParticipantCount + 1,
    }))
  }

  async removeParticipantToSlot(slotId: string, memberId: string, status: ParticipantStatus) {
    await this.api.removeParticipantToSlot(slotId, memberId)
    this.slotStore.update(slotId, (current) => ({
      ...current,
      pendingParticipantCount:
        status === "registered" ? Math.max(0, current.pendingParticipantCount - 1) : current.pendingParticipantCount,
    }))
  }

  /**
   * Flags a participant as absent or present for a given slot.
   *
   * @param slotId - The ID of the slot.
   * @param memberId - The ID of the member.
   * @param absent - A boolean indicating whether the participant is absent (true) or present (false).
   * @returns A promise that resolves when the participant is flagged successfully.
   * @throws If an error occurs while flagging the participant.
   */
  async flagParticipant(slotId: string, memberId: string, absent: boolean) {
    const currentSubscriber = this.slotSubscribers.items.get().get(memberId)
    try {
      this.slotSubscribers.update(memberId, (subscriber) => ({
        ...subscriber,
        absent,
      }))
      return this.api.flagParticipant(slotId, memberId, absent)
    } catch (e) {
      currentSubscriber && this.slotSubscribers.update(memberId, () => ({ ...currentSubscriber }))
      throw e
    }
  }

  async updateScheduledSessions(programId: string, startDate: string, endDate: string) {
    this._isLoadingScheduledSessions.set(true)
    const result = await this.api.getScheduledSessions(programId, startDate, endDate)

    this._scheduledSessionsInRange.set(result)
    this._isLoadingScheduledSessions.set(false)
  }

  async updateBoxSlotsBeingCreated(boxSlotDrafts: BoxSlotDraft[]) {
    this._boxSlotsBeingCreated.set(boxSlotDrafts)
  }

  async updateBoxSlotsBeingViewed(boxSlots: BoxSlot[]) {
    this._boxSlotsBeingViewed.set(boxSlots)
  }
}
