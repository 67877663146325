import { MenuToggle } from "@modules/app/components/MenuToggle"
import { AppMenuIcon } from "@modules/app/components/menu/appMenuIcon"
import { AppMenuItem } from "@modules/app/components/menu/appMenuItem"
import { AppMenuProfile } from "@modules/app/components/menu/appMenuProfile"
import { LayoutContext } from "@modules/app/context/layoutcontext"
import { MenuProvider } from "@modules/app/context/menucontext"
import { useMenuModels } from "@modules/app/hooks/useMenuModel"
import { MenuButtonsRef } from "@modules/app/types/layout"
import { MenuModel } from "@modules/app/types/types"
import { useUser } from "@modules/auth/hooks/useUser"
import { useBoxs, useSelectedBoxId } from "@modules/box/useBox"
import { word } from "@modules/core/utils/i18n"
import { FeatureKeys } from "@modules/featureToggle/featureToggleTypes"
import { useFeatureToggle } from "@modules/featureToggle/hooks/useFeatureToggle"
import { Path } from "@modules/navigation/routes"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { RoundLinkIcon } from "@modules/ui/components/roundLinkIcon"
import { colorWithAlpha } from "@modules/ui/styles/colors"
import { useContext, useRef } from "react"
import styled, { useTheme } from "styled-components"

type Props = {
  ownerMenu: boolean | undefined
}

type AppSubMenuProps = {
  menuModels: MenuModel[]
}

export const AppMenu: React.FC<Props> = ({ ownerMenu }) => {
  const menuModels: MenuModel[] = useMenuModels(ownerMenu)
  const menuToggleRef = useRef<MenuButtonsRef>(null)
  return (
    <>
      <AppMenuIcon />
      <MenuToggle ref={menuToggleRef} />
      <MenuContainer>
        <MenuProvider>
          <AppSubMenu menuModels={menuModels} />
        </MenuProvider>
        <OwnerMenu />
      </MenuContainer>
      <MenuFooter>
        <AppMenuProfile />
      </MenuFooter>
    </>
  )
}

const AppSubMenu: React.FC<AppSubMenuProps> = ({ menuModels }) => {
  const user = useUser()
  //remove transaction from Menu if user don't have a stripe account or if the account is not ok
  if (!user?.stripeAccountId || user.stripeAccountStatus !== "OK")
    menuModels[0].items = menuModels[0].items?.filter((e) => e.label !== "Transactions")

  return (
    <MenuProvider>
      <ul className="layout-menu ">
        {menuModels.map((item, i) => {
          return !item.seperator ? (
            <AppMenuItem item={item} root index={i} key={item.label} />
          ) : (
            <li className="menu-separator"></li>
          )
        })}
      </ul>
    </MenuProvider>
  )
}

const OwnerMenu: React.FC = () => {
  const { layoutState } = useContext(LayoutContext)
  const boxId = useSelectedBoxId()
  const { result: boxs } = useBoxs()
  const hasBoxs = !!boxs && boxs.length > 0
  const canCreateBox = useFeatureToggle(FeatureKeys.Box)
  const theme = useTheme()

  return canCreateBox && !boxId && !hasBoxs && !layoutState.staticMenuDesktopInactive ? (
    <>
      <div className="flex flex-1" />
      <OwnerMenuContainer>
        <HUStack gap={[1, 5]} align>
          <HUText fontStyle="BL" className="text-center">
            {word("menu.owner.title")}
          </HUText>
          <HUText fontStyle="BM" color={theme.neutralColor600} className="text-center">
            {word("menu.owner.description")}
          </HUText>
          <RoundLinkIconStyled to={Path.CreateBox} piIcon="pi-plus" style={{ backgroundColor: theme.textLink }} />
        </HUStack>
      </OwnerMenuContainer>
    </>
  ) : null
}

const MenuContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const OwnerMenuContainer = styled.div`
  margin: 5px 24px 10px;
  padding: 10px 16px;
  border-radius: 21px;
  background: ${({ theme }) =>
    `linear-gradient(142deg, ${theme.neutralColor100} 3%, ${colorWithAlpha(theme.neutralColor100, 0)} 96%)`};
`

const MenuFooter = styled.div`
  border-top: 1px solid var(--surface-border);
`
const RoundLinkIconStyled = styled(RoundLinkIcon)`
  i {
    color: ${({ theme }) => theme.mainWhite} !important;
  }
`
