import { LanguageIcon } from "@images/svgIcons/languageIcon"
import { LogoutIcon } from "@images/svgIcons/logoutIcon"
import { PlusIcon } from "@images/svgIcons/plusIcon"
import { ProfileIcon } from "@images/svgIcons/profileIcon"
import { LanguagePopup, LanguagePopupRef } from "@modules/app/components/menu/profile/languagePopup"
import { ProfileCurrentItem, ProfileItem, ProfileOption } from "@modules/app/components/menu/profile/profileItem"
import { useUser } from "@modules/auth/hooks/useUser"
import { useBoxs, useSelectedBoxId } from "@modules/box/useBox"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { FeatureKeys } from "@modules/featureToggle/featureToggleTypes"
import { useFeatureToggle } from "@modules/featureToggle/hooks/useFeatureToggle"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { Path } from "@modules/navigation/routes"
import { RouteNavigate } from "@modules/navigation/useBaseNavigate"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { HUStack } from "@modules/ui/components/layout"
import { LoggerType } from "@modules/utils/loggerUtils"
import { delay } from "@modules/utils/utils"
import { Divider } from "primereact/divider"
import { OverlayPanel } from "primereact/overlaypanel"
import React, { useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const AppMenuProfile: React.FC = () => {
  const { userService, profileService } = useServicesContext()
  const { result: boxs } = useBoxs()
  const mySpaceNavigate = useMySpaceNavigate()
  const boxNavigate = useBoxNavigate()
  const navigate = useNavigate()
  const user = useUser()
  const boxId = useSelectedBoxId()
  const canCreateBox = useFeatureToggle(FeatureKeys.Box)
  const language = useLanguage()
  const theme = useTheme()

  const overlayRef = useRef<OverlayPanel>(null)
  const languagePopupRef = useRef<LanguagePopupRef | null>(null)
  const [logoutLoading, setLogoutLoading] = useState(false)
  const logout = async () => {
    try {
      setLogoutLoading(true)
      profileService.setNewTheme("loggingout")
      await userService.logout()
    } catch (e) {
      console.error(LoggerType.Profile + "logout", e)
    } finally {
      setLogoutLoading(false)
    }
  }

  const options = useMemo(() => {
    const mySpaceOption: ProfileOption = {
      icon: <ProfileAvatar profile={{ avatar: user?.avatar, username: user?.username ?? "" }} size={32} />,
      title: user?.firstname + " " + user?.lastname,
      subTitle: word("menu.profile.mySpace.type"),
      selected: boxId === null,
      onPress: async () => {
        profileService.setNewTheme("athlete")
        await delay(100)
        mySpaceNavigate(RouteNavigate.Root)
        overlayRef.current?.hide()
      },
    }
    const boxOptions: ProfileOption[] =
      boxs?.map((box) => ({
        icon: <ProfileAvatar profile={{ avatar: box.logo, username: box.name }} size={32} />,
        title: box.name,
        subTitle: word("menu.profile.box.type"),
        selected: boxId === box.id,
        onPress: async () => {
          profileService.setNewTheme("owner")
          await delay(100)
          boxNavigate(RouteNavigate.Root, { newBoxId: box.id })
          overlayRef.current?.hide()
        },
      })) ?? []
    const addBoxOption: ProfileOption = {
      icon: <PlusIcon color={theme.neutralColor700} />,
      withIconBackground: true,
      title: word("menu.profile.add_box.label"),
      onPress: async () => {
        profileService.setNewTheme("owner")
        await delay(100)
        navigate(Path.CreateBox)
        overlayRef.current?.hide()
      },
    }
    const settingOptions: ProfileOption[] = [
      {
        icon: <ProfileIcon color={theme.buttonPrimaryTextColor} />,
        title: word("menu.profile.profile.label"),
        onPress: () => {
          boxId !== null ? boxNavigate(Path.Box.Profile) : mySpaceNavigate(Path.MySpace.Profile)
          overlayRef.current?.hide()
        },
      },
      {
        icon: <LanguageIcon color={theme.buttonPrimaryTextColor} />,
        title: word("menu.profile.language.label"),
        onPress: () => {
          languagePopupRef.current?.show()
          overlayRef.current?.hide()
        },
      },
    ]
    const logoutOption: ProfileOption = {
      icon: <LogoutIcon color={theme.buttonPrimaryTextColor} />,
      title: word("menu.profile.logout.label"),
      onPress: () => {
        logout()
        overlayRef.current?.hide()
      },
    }

    return { mySpaceOption, boxOptions, addBoxOption, settingOptions, logoutOption }
  }, [user, boxId, boxs, language])

  const currentSpace = boxId
    ? options.boxOptions.find((it) => it.selected === true) ?? { title: undefined }
    : options.mySpaceOption

  return (
    <>
      <ProfileCurrentItem {...currentSpace} onPress={(e) => overlayRef.current?.toggle(e, undefined)} />
      <OverlayPannelStyle
        ref={overlayRef}
        onShow={() => {
          document.body.classList.add("scroll-lock")
        }}
        onHide={() => {
          document.body.classList.remove("scroll-lock")
        }}
      >
        <div style={{ maxHeight: 500, overflowY: "auto" }}>
          <HUStack gap={0}>
            <HUStack gap={0} style={{ paddingTop: 6, paddingBottom: 6 }}>
              <ProfileItem {...options.mySpaceOption} />
              {(options.boxOptions.length > 0 || canCreateBox) && <Divider style={{ marginTop: 6, marginBottom: 6 }} />}
              {options.boxOptions.map((boxOption, index) => (
                <ProfileItem key={index} {...boxOption} />
              ))}
              {canCreateBox && <ProfileItem {...options.addBoxOption} />}
            </HUStack>
            <Divider style={{ margin: 0 }} />
            {options.settingOptions.map((option, index) => (
              <ProfileItem key={index} {...option} />
            ))}
            <Divider style={{ margin: 0 }} />
            <ProfileItem {...options.logoutOption} loading={logoutLoading} />
          </HUStack>
        </div>
      </OverlayPannelStyle>
      <LanguagePopup ref={languagePopupRef} />
    </>
  )
}

const OverlayPannelStyle = styled(OverlayPanel)`
  margin-left: 18px;
  margin-top: -2px;
  min-width: 300px;
  max-width: 400px;
  overflow: hidden;
`
