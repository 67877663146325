import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { capitalizeAndLowercase } from "@modules/utils/stringUtils"
import { useTheme } from "styled-components"

export type HuBioCardProps = {
  description?: string
  job?: string
  category?: string
  age?: number
  height?: number
  weight?: number
} & React.HTMLAttributes<HTMLDivElement>

export const HuBioCard: React.FC<HuBioCardProps> = ({ description="", job="", category="", age=null, height=null, weight=null, ...props }) => {
  const theme = useTheme()

  if (!description && !job && !category && !age && !height && !weight) {
    return <></>;
  }

  return (
    <div className="flex flex-column p-5 border-round-3xl bg-gradient-forestGreen gap-4" {...props}>
      <HUText fontStyle="TM" color={theme.mainWhite}>
        {word("huBioCard.bio")}
      </HUText>

      {description && (
        <div className="flex flex-column">
          <HUText className="uppercase" fontStyle="BS" color={theme.mainWhite}>
            {word("huBioCard.description")}
          </HUText>
          <HUText fontStyle="BS" color={theme.mainWhite}>
            {description}
          </HUText>
        </div>
      )}

      <div className="flex justify-content-between">
        {job && (
          <div className="flex flex-column">
            <HUText className="uppercase" fontStyle="BS" color={theme.mainWhite}>
              {word("huBioCard.job")}
            </HUText>
            <HUText fontStyle="TS" color={theme.mainWhite}>
              {job}
            </HUText>
          </div>
        )}
        {category && (
          <div className="flex flex-column">
            <HUText className="uppercase" fontStyle="BS" color={theme.mainWhite}>
              {word("huBioCard.category")}
            </HUText>
            <HUText fontStyle="TS" color={theme.mainWhite}>
              {capitalizeAndLowercase(category)}
            </HUText>
          </div>
        )}
      </div>

      <div className="flex justify-content-between">
        {age && (
          <div className="flex flex-column">
            <HUText className="uppercase" fontStyle="BS" color={theme.mainWhite}>
              {word("huBioCard.age")}
            </HUText>
            <HUText fontStyle="TS" color={theme.mainWhite}>
              {age}
            </HUText>
          </div>
        )}
        {height && (
          <div className="flex flex-column">
            <HUText className="uppercase" fontStyle="BS" color={theme.mainWhite}>
              {word("huBioCard.height")}
            </HUText>
            <HUText fontStyle="TS" color={theme.mainWhite}>
              {height} cm
            </HUText>
          </div>
        )}
        {weight && (
          <div className="flex flex-column">
            <HUText className="uppercase" fontStyle="BS" color={theme.mainWhite}>
              {word("huBioCard.weight")}
            </HUText>
            <HUText fontStyle="TS" color={theme.mainWhite}>
              {weight} kg
            </HUText>
          </div>
        )}
      </div>
    </div>
  )
}
