import { WebService } from "@modules/core/services/webService"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"
import { observable } from "micro-observables"

const DEFAULT_VALUE = { checked: false }
export type TemplateVisibility = typeof DEFAULT_VALUE

export class BoxProgrammationTemplatePopUpService implements WebService {
  private _showPopup = observable<TemplateVisibility>(DEFAULT_VALUE)
  readonly showPopup = this._showPopup.readOnly()

  constructor(private localStorage: LocalStorageService) {}

  async init(): Promise<() => void> {
    const unsubscribeTemplate = this.localStorage.connect(
      this._showPopup,
      LocalStorageKeys.templatePopup.checked,
      DEFAULT_VALUE,
    )

    return () => {
      unsubscribeTemplate()
    }
  }

  hideTemplatePopup(): void {
    this._showPopup.set({ checked: true })
  }

  async onLoggedOut(): Promise<void> {
    this._showPopup.set(DEFAULT_VALUE)
  }
}
