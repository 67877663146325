import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

type Props = {
  backgroundColor: string
} & IconSvgProps

export const InformationAlertIcon: React.FC<Props> = ({ color, backgroundColor, ...props }) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" rx="25" fill={backgroundColor} />

    <circle cx="25" cy="20.00012" r="1" fill={color} />

    <path
      d="M24 23.0004H25V30.0004M35 25.0004C35 30.5232 30.5219 35.0004 25 35.0004C19.4762 35.0004 15 30.5232 15 25.0004C15 19.4775 19.4762 15.0004 25 15.0004C30.5219 15.0004 35 19.4775 35 25.0004Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
