import { BoxSlotTemplateGroupScheduleForm } from "@modules/boxSlotTemplate/components/boxSlotTemplateGroupScheduleForm"
import { BoxSlotTemplateGroupSettingsForm } from "@modules/boxSlotTemplate/components/boxSlotTemplateGroupSettingsForm"
import { useBoxSlotTemplateGroupFormik } from "@modules/boxSlotTemplate/hooks/useBoxSlotTemplateGroupFormik"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useEffect, useState } from "react"

type Step = "Settings" | "Schedule"

export const BoxSlotTemplateGroupForm: React.FC<{
  boxProgrammationId: string
}> = ({ boxProgrammationId }) => {
  const [formStep, setFormStep] = useState<Step>("Settings")

  const { formik, isLoading, isSubmitting } = useBoxSlotTemplateGroupFormik(boxProgrammationId)

  useEffect(() => {
    document.getElementById("sidebar-content")?.scrollTo({ top: 0 })
  }, [formStep])

  const moveToScheduleStep = async () => {
    const firstStepInputNames = [
      "limitStart",
      "limitEnd",
      "limitCancel",
      "slotDuration",
      "participantLimit",
      "location",
    ]
    firstStepInputNames.forEach((inputName) => formik.setFieldTouched(inputName))

    const errors = await formik.validateForm()

    if (!Object.keys(errors).some((errorKey) => firstStepInputNames.indexOf(errorKey) >= 0)) setFormStep("Schedule")
  }

  return (
    <HUStack gap={25}>
      <HUStack gap={10}>
        <HUText fontStyle="TL">{word("box.slotTemplateGroup.form.title")}</HUText>
        <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.subtitle")}</HUText>
      </HUStack>
      {isLoading ? (
        <LoadingSpinner />
      ) : formStep === "Settings" ? (
        <BoxSlotTemplateGroupSettingsForm formik={formik} moveToScheduleStep={moveToScheduleStep} />
      ) : (
        <BoxSlotTemplateGroupScheduleForm
          formik={formik}
          moveToSettingsStep={() => setFormStep("Settings")}
          submitForm={formik.submitForm}
          isSubmitting={isSubmitting}
        />
      )}
    </HUStack>
  )
}
