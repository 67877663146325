import { Observable, observable, WritableObservable } from "micro-observables"

export class AsyncObservable<T> {
  private _value: WritableObservable<T | null>
  value: Observable<T | null>

  constructor(
    private readonly _fetch: () => Promise<T>,
    initialValue: T | null = null,
  ) {
    this._value = observable(initialValue)
    this.value = this._value.readOnly()
  }

  async fetch() {
    const result = await this._fetch()
    this._value.set(result)
    return result
  }

  async set(value: T | null) {
    this._value.set(value)
  }

  getObservable() {
    return this._value
  }

  get() {
    return this._value.get()
  }

  update(callback: (value: T | null) => T | null) {
    this.set(callback(this.get()))
  }
}
