import { MenuModel } from "@modules/app/types/types"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { RouteNavigate } from "@modules/navigation/useBaseNavigate"
import { getBoxPath } from "@modules/navigation/useBoxNavigate"
import { getMySpacePath } from "@modules/navigation/useMySpaceNavigate"
import { useObservable } from "micro-observables"

export const useMenuModels = (ownerMenu?: boolean) => {
  const { chatService, currentSpaceManager } = useServicesContext()
  const hasUnreadMessage = !!useObservable(chatService.unreadCount)

  const menuModels: MenuModel[] = [
    {
      label: "Hustleup",
      icon: "pi pi-home",
      items: ownerMenu
        ? [
            {
              label: word("menu.planning"),
              icon: "hup-planning",
              to: getBoxPath(Path.Box.Planning, { newBoxId: currentSpaceManager.boxId }),
            },
            {
              label: word("menu.offers"),
              icon: "hup-products",
              to: getBoxPath(Path.Box.Offers, { newBoxId: currentSpaceManager.boxId }),
            },
            {
              label: word("menu.members"),
              icon: "hup-members",
              to: getBoxPath(Path.Box.Members, { newBoxId: currentSpaceManager.boxId }),
            },
            {
              label: word("menu.chat"),
              icon: "pi pi-comments",
              to: getBoxPath(Path.Chat.Root, { newBoxId: currentSpaceManager.boxId }),
              badge: hasUnreadMessage ? "new" : undefined,
            },
            {
              label: word("menu.finances"),
              icon: "hup-finances",
              to: getBoxPath(Path.Box.Finances, { newBoxId: currentSpaceManager.boxId }),
            },
            {
              label: word("menu.admin"),
              icon: "hup-admin",
              to: getBoxPath(Path.Box.Admin, { newBoxId: currentSpaceManager.boxId }),
            },
          ]
        : [
            {
              label: word("menu.dashboard"),
              icon: "hup-dashboard",
              to: getMySpacePath(RouteNavigate.Root),
            },
            {
              label: word("menu.explorer"),
              icon: "hup-explorer",
              to: getMySpacePath(Path.MySpace.Explore),
            },
            {
              label: word("menu.training"),
              icon: "hup-training",
              to: getMySpacePath(Path.MySpace.Training),
            },
            {
              label: word("menu.chat"),
              icon: "pi pi-comments",
              to: getMySpacePath(Path.Chat.Root),
              badge: hasUnreadMessage ? "new" : undefined,
            },
            {
              label: word("menu.transactions"),
              icon: "hup-finances",
              to: getMySpacePath(Path.MySpace.Finances),
            },
            {
              label: word("menu.tutorials"),
              icon: "hup-tutorials",
              to: getMySpacePath(Path.MySpace.Tutorials),
            },
            {
              label: word("menu.box"),
              icon: "hup-box",
              isDisable: true,
            },
          ],
    },
  ]

  return menuModels
}
