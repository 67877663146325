import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PlusIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <path
      d="M7.67742 6.82258V1.17742C7.67742 0.997757 7.60605 0.825452 7.47901 0.698411C7.35197 0.571371 7.17966 0.5 7 0.5C6.82034 0.5 6.64803 0.571371 6.52099 0.698411C6.39395 0.825452 6.32258 0.997757 6.32258 1.17742V6.82258H0.677419C0.497757 6.82258 0.325452 6.89395 0.198411 7.02099C0.0713707 7.14803 0 7.32034 0 7.5C0 7.67966 0.0713707 7.85197 0.198411 7.97901C0.325452 8.10605 0.497757 8.17742 0.677419 8.17742H6.32258V13.8226C6.32492 14.0015 6.39704 14.1725 6.52358 14.299C6.65012 14.4255 6.82106 14.4977 7 14.5C7.17966 14.5 7.35197 14.4286 7.47901 14.3016C7.60605 14.1745 7.67742 14.0022 7.67742 13.8226V8.17742H13.3226C13.5022 8.17742 13.6745 8.10605 13.8016 7.97901C13.9286 7.85197 14 7.67966 14 7.5C13.9977 7.32106 13.9255 7.15012 13.799 7.02358C13.6725 6.89704 13.5015 6.82492 13.3226 6.82258H7.67742Z"
      fill={props.color}
    />
  </svg>
)
