import { FetchStrategy, usePaginatedStore } from "@betomorrow/micro-stores"
import { useSelectedBoxId } from "@modules/box/useBox"
import { useServicesContext } from "@modules/core/services/services.context"

export const useAllBoxProgrammations = (fetchStrategy?: FetchStrategy) => {
  const { boxProgrammationService } = useServicesContext()

  const boxId = useSelectedBoxId()

  const {
    error: storeError,
    loading: storeLoading,
    result: storeResult,
  } = usePaginatedStore(boxProgrammationService.paginatedBoxProgrammationStore, fetchStrategy, [boxId])

  return { error: storeError, loading: storeLoading, result: storeResult }
}
