import { Currency } from "@modules/currency/currency"

export const allInvoiceStatus = ["DRAFT", "OPEN", "PAID", "UNCOLLECTIBLE", "VOID"] as const
export type InvoiceStatus = (typeof allInvoiceStatus)[number]
export const allTransactionsStatus = ["SUCCEEDED", "PENDING", "FAILED", "DISPUTED"] as const
export type TransactionStatus = (typeof allTransactionsStatus)[number]

export type FinanceStatistics = {
  totalTransactionsValue: number
  totalTransactionsCurrency: Currency
  activeMembersNumber: number
}
