import { HighlightingIcon } from "@images/svgIcons/highlightingIcon"
import { HustleupSmallIcon } from "@images/svgIcons/hustleUpSmallIcon"
import { InformationAlertIcon } from "@images/svgIcons/informationAlertIcon"
import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { ProgramSubscriptionActions } from "@modules/programs/components/programSubscriptionActions"
import { useProgramAthleteSubscription } from "@modules/programs/hooks/useProgramSubscription"
import { ProgramInfinite, ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff, ProgramOnOffStatus } from "@modules/programs/programOnOffTypes"
import { ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import { getFormattedPromoCodeReduction } from "@modules/promoCode/utils/promoCodeUtils"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import styled, { useTheme } from "styled-components"

type ProgramSubscriptionCardProps = {
  program: ProgramInfinite | ProgramOnOff
}

export const ProgramSubscriptionCard: React.FC<ProgramSubscriptionCardProps> = ({ program }) => {
  const theme = useTheme()
  const language = useLanguage()

  const { programStripeSubscriptionInfos, loading: isLoadingSubscription } = useProgramAthleteSubscription(program)

  const initialPrice = program.price
  const displayedPrice =
    programStripeSubscriptionInfos?.payment?.amount || programStripeSubscriptionInfos?.paidPrice || initialPrice
  const appliedPromoCode = programStripeSubscriptionInfos?.payment?.promoCode
  const canUserSubscribe =
    program && (("status" in program && program.status !== ProgramOnOffStatus.DRAFT) || !("status" in program))

  return (
    <>
      <div style={{ marginBottom: 7 }}>
        <HUText type="label" color={theme.neutralColor700} fontStyle="LS">
          {program._programType === ProgramInfiniteTypeOf
            ? word("program.subscription.label").toUpperCase()
            : word("program.price.label").toUpperCase()}
        </HUText>
      </div>

      {"trialDuration" in program &&
        program.trialDuration &&
        canUserSubscribe &&
        !program.subscribed &&
        program.subscriptionStatus !== ProgramSubscriptionStatus.TRIALING &&
        program.isEligibleToTrial && (
          <CardContainer className="card align-items-start relative overflow-hidden mb-3">
            <StyledHighlightingIcon />
            <HURow gap={15} className="align-items-center">
              <HUText className="z-2" fontStyle="TM">
                {word("program.price.trial.tryBeforeSubscribe")}
              </HUText>
              <InformationAlertIcon color={theme.mainBlack} backgroundColor="transparent" />
            </HURow>
            {"trialDuration" in program && program.trialDuration > 0 && (
              <HUText className="z-2" fontStyle="BM">
                {`${word("program.price.trial.description")} ${program.trialDuration} ${word("global.day.label", { count: program.trialDuration })}`}
              </HUText>
            )}
          </CardContainer>
        )}
      {program.subscriptionStatus === ProgramSubscriptionStatus.TRIAL_PENDING_CANCELLATION && (
        <CardContainer className="card align-items-start relative overflow-hidden mb-3">
          <StyledHighlightingIcon />
          <HURow gap={15} className="align-items-center">
            <HUText className="z-2" fontStyle="TM">
              {word("program.price.trial.subscriptionCanceled")}
            </HUText>
            <InformationAlertIcon color={theme.mainBlack} backgroundColor="transparent" className="z-5" />
          </HURow>
          <HUText className="z-2" fontStyle="BM">
            {word("program.price.trial.subscriptionCanceledDescription")}
          </HUText>
        </CardContainer>
      )}
      {program._programType === ProgramInfiniteTypeOf &&
        !program.isEligibleToTrial &&
        program.trialDuration !== null && (
          <CardContainer className="card align-items-start relative overflow-hidden mb-3">
            <StyledHighlightingIcon />
            <HURow gap={15} className="align-items-center">
              <HUText className="z-2" fontStyle="TM">
                {word("program.price.trial.trialPeriodUsedTitle")}
              </HUText>
              <InformationAlertIcon color={theme.mainBlack} backgroundColor="transparent" className="z-5" />
            </HURow>
            <HUText className="z-2" fontStyle="BM">
              {word("program.price.trial.trialPeriodUsedDescription")}
            </HUText>
          </CardContainer>
        )}

      <CardContainer className="card align-items-start relative overflow-hidden">
        {isLoadingSubscription ? (
          <HUStack className="w-full z-2 p-6">
            <LoadingSpinner />
          </HUStack>
        ) : displayedPrice?.value ? (
          <HUStack className="w-full z-2" gap={14}>
            {program._programType === ProgramInfiniteTypeOf ? (
              <>
                <HUText fontStyle="TM">{word("program.price.membership")}</HUText>
                <HURow>
                  <HUText fontStyle="DS">
                    {formatCurrency(displayedPrice.value, displayedPrice.currency, language)}
                  </HUText>
                  <HUStack gap={2} className="ml-2">
                    <HUText fontStyle="LL" color={theme.neutralColor700}>
                      {word("program.price.pricePerMonth")}
                    </HUText>
                    <HUText fontStyle="LL" color={theme.neutralColor700}>
                      {word("program.price.pricePerMember")}
                    </HUText>
                  </HUStack>
                </HURow>
              </>
            ) : (
              <>
                <HUText fontStyle="TM">{word("program.price.singlePurchase")}</HUText>
                <HURow>
                  <HUText fontStyle="DS">
                    {formatCurrency(displayedPrice.value, displayedPrice.currency, language)}
                  </HUText>
                  <HUStack gap={2} className="ml-2">
                    <HUText fontStyle="LL" color={theme.neutralColor700}>
                      {word("program.price.onlyOnce")}
                    </HUText>
                  </HUStack>
                </HURow>
              </>
            )}

            {program.subscribed && initialPrice && displayedPrice.value !== initialPrice.value && (
              <HUStack gap={4}>
                <HUText fontStyle="BS">{`${formatCurrency(initialPrice.value, initialPrice.currency, language)} (${word("program.price.initial")})`}</HUText>
                {appliedPromoCode && (
                  <HUText fontStyle="BS">{`${getFormattedPromoCodeReduction(appliedPromoCode, language)} (${word("program.price.reduction")})`}</HUText>
                )}
              </HUStack>
            )}

            <HUText fontStyle="BM" color={theme.neutralColor700}>
              {word("program.price.payingForAll")}
            </HUText>
          </HUStack>
        ) : (
          <HUStack className="w-full z-2">
            <HUText fontStyle="TM">{word("program.price.free")}</HUText>
            <HUText fontStyle="BM" color={theme.neutralColor700}>
              {word("program.price.freeForAll")}
            </HUText>
          </HUStack>
        )}

        {canUserSubscribe && (
          <HUStack className="z-2 w-full">
            <ProgramSubscriptionActions program={program} />
          </HUStack>
        )}

        <HustleupSmallIconStyled color={theme.mainWhite} />
      </CardContainer>
    </>
  )
}

const CardContainer = styled(HUStack)`
  background-color: ${({ theme }) => theme.neutralColor100};
  padding: 24px 28px;
`

const HustleupSmallIconStyled = styled(HustleupSmallIcon)`
  position: absolute;
  top: 0;
  right: -60px;
  height: 100%;
  width: auto;
  opacity: 0.3;
  z-index: 1;
`

const StyledHighlightingIcon = styled(HighlightingIcon)`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
`
