import Vector from "@images/background/vector.svg"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { useStripeAccount } from "@modules/finances/hooks/useStripeAccount"
import { Path } from "@modules/navigation/routes"
import { OnboardingWrapper } from "@modules/owner/boxSettings/components/onboardingWrapper"
import { BoxOnboardingStripeFirstPage } from "@modules/owner/boxSettings/page/boxOnboardingStripeFirstPage"
import { BoxOnboardingStripeSecondPage } from "@modules/owner/boxSettings/page/boxOnboardingStripeSecondPage"
import { continents, StripeCountryCode } from "@modules/payment/stripeTypes"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"

/**
 * This page is used for Stripe onboarding
 * so that the owners can create their Stripe account
 * @returns {React.ReactNode} - Wrapper & Header & BoxOnboardingStripeFirstPage | BoxOnboardingStripeSecondPage
 */
export const BoxOnboardingStripe: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState<StripeCountryCode | undefined>()

  const theme = useTheme()
  const { profileService } = useServicesContext()
  const navigate = useNavigate()
  const popup = useConfirmPopup()
  const { createStripeAccount, loading } = useStripeAccount()
  const countryOptions = continents.map((continent) => ({
    code: continent.id,
    label: word(`global.continent.${continent.id}`),
    items: continent.countries
      .map((country) => ({
        value: country,
        label: word(`global.country.${country}`),
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label)
      }),
  }))

  const handleClose = () =>
    popup.show({
      title: word("box.creation.form.cancel.title"),
      message: word("box.creation.form.cancel.description"),
      accept: async () => {
        profileService.setNewTheme("athlete")
        setTimeout(() => navigate(Path.Root), 100)
      },
      footerProps: {
        align: true,
        confirmClassName: "flex-1",
        cancelClassName: "flex-1",
        footerClassName: "flex flex-row justify-content-around w-full",
      },
    })

  const handleButtonNavigation = (onNext: boolean) => {
    setIsVisible(onNext)
  }

  return (
    <OnboardingWrapper backgroundImage={Vector} handleClose={handleClose}>
      {isVisible
        ? BoxOnboardingStripeFirstPage(theme, handleButtonNavigation)
        : BoxOnboardingStripeSecondPage(
            theme,
            countryOptions,
            selectedCountry,
            setSelectedCountry,
            createStripeAccount,
            handleButtonNavigation,
            loading,
          )}
    </OnboardingWrapper>
  )
}
