import appIcon from "@images/appIcon.svg"
import { privacyPolicyData, PrivacyPolicyTermsType } from "@modules/consumerAgreements/datas/privacyPolicy.data"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { HUFontStyle } from "@modules/ui/styles/font"
import styled from "styled-components"

export const PrivacyPolicyPage: React.FC = () => {
  const language = useLanguage() === "fr" ? "fr" : "en"

  const formatTerms = (terms: PrivacyPolicyTermsType[], parentIndex: string = "0") => {
    return terms.map((term, termIndex) => (
      <HUStack key={termIndex} className="align-items-flex-start">
        <HURow className="gap-2">
          <HUText fontStyle="LL">{`${parentIndex === "0" ? "" : parentIndex + "."}${term.index}.`}</HUText>
          <HUStack>
            {term.title && (
              <HUText fontStyle="LL" className={parentIndex === "0" ? "underline" : ""}>
                {term.title}
              </HUText>
            )}
            {term.content && formatTextIndentation(term.content, "BM")}
          </HUStack>
        </HURow>
        {term.table && (
          <table>
            <thead>
              <tr>
                {term.table.headers.map((header, headerIndex) => (
                  <th key={"header-" + headerIndex}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {term.table.rows.map((row, rowIndex) => (
                <tr key={"row-" + rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={"cell-" + cellIndex}>
                      {cell.split("\n").map((cellLine, cellLineIndex) => (
                        <span key={cellLineIndex}>
                          <HUText fontStyle="BM">{cellLine}</HUText>
                          <br />
                        </span>
                      ))}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </HUStack>
    ))
  }

  const formatTextIndentation = (text: string, fontStyle: HUFontStyle) => {
    return text.split("\n").map((line, lineIndex) => (
      <HUText key={lineIndex} fontStyle={fontStyle} className="text-justify">
        <div dangerouslySetInnerHTML={{ __html: line }} />
      </HUText>
    ))
  }

  return (
    <Container>
      <HUStack>
        <HUStack className="align-items-center gap-2 underline">
          {formatTextIndentation(privacyPolicyData[language].title, "HM")}
          <AppIcon src={appIcon} />
        </HUStack>
        {formatTerms(privacyPolicyData[language].terms)}
        <HURow className="align-items-center gap-3">
          {formatTextIndentation(privacyPolicyData[language].entryIntoForceLabel, "LL")}
          {formatTextIndentation(privacyPolicyData[language].entryIntoForceValue, "BM")}
        </HURow>
      </HUStack>
    </Container>
  )
}

const Container = styled(HUStack)`
  max-width: 960px;
  margin: auto;
  padding: 50px 30px;
  table {
    table-layout: fixed;
    width: 100%;
  }
  td {
    width: 33%;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
  }
`

const AppIcon = styled.img`
  margin: 0 14px;
  width: 100px;
  height: 100px;
  margin: 10px;
`
