import { ChatAvatar } from "@modules/chat/components/chatAvatar"
import { word } from "@modules/core/utils/i18n"
import { HUCheckbox } from "@modules/ui/components/huCheckbox"
import { HUText } from "@modules/ui/components/huText"
import dayjs from "dayjs"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { DefaultGenerics, UserResponse } from "stream-chat"
import styled, { useTheme } from "styled-components"

type UserSearchListProps = {
  users: UserResponse<DefaultGenerics>[]
  isCreatingGroup: boolean
  onSelect: (user: UserResponse<DefaultGenerics>) => void
  selectedUsers: UserResponse<DefaultGenerics>[]
  onUserClick?: (userId: string) => void
  searchText: string
}

export const UserSearchList: React.FC<UserSearchListProps> = ({
  users,
  isCreatingGroup,
  onSelect,
  selectedUsers,
  onUserClick,
  searchText,
}) => {
  const theme = useTheme()

  const lastActiveTemplate = (user: UserResponse<DefaultGenerics>) => dayjs(user.last_active).calendar()

  const nameTemplate = (user: UserResponse<DefaultGenerics>) => {
    return (
      <Container onClick={() => onUserClick?.(user.id)} className={!!onUserClick ? "cursor-pointer" : ""}>
        <ChatAvatar image={user.image as string} name={user.name} isOnline={user.online} />
        <HUText fontStyle="LL">{user.name}</HUText>
      </Container>
    )
  }

  const checkboxTemplate = (user: UserResponse<DefaultGenerics>) => {
    return isCreatingGroup ? (
      <HUCheckbox
        inputKey={`user-${user.id}`}
        name="option"
        value={user.id}
        checked={!!selectedUsers.find((selectedUser) => selectedUser.id === user.id)}
        onChange={() => onSelect(user)}
        label={""}
        color={theme.primaryColor}
        checkIconColor={theme.neutralColor700}
      />
    ) : null
  }

  return (
    <StyledDataTable
      value={users}
      dataKey="id"
      size="small"
      emptyMessage={searchText.length > 0 ? word("chat.no_result.title") : word("chat.type_name")}
    >
      <Column field="checkbox" body={checkboxTemplate} />
      <Column field="name" header={word("chat.name")} body={nameTemplate} headerStyle={{ minWidth: "15rem" }} />
      <Column
        field="last_active"
        header={word("chat.last_online")}
        body={lastActiveTemplate}
        headerStyle={{ minWidth: "15rem" }}
      />
    </StyledDataTable>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const StyledDataTable = styled(DataTable)`
  overflow-y: auto;
  flex: 1;
  .field-checkbox {
    margin-bottom: 0px;
  }
  .p-datatable-thead {
    display: none;
  }
`
