import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { word } from "@modules/core/utils/i18n"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { ProgramInfinite } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { HUButton } from "@modules/ui/components/huButton"
import { HUSwitch } from "@modules/ui/components/huSwitch"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { Dayjs } from "dayjs"
import styled, { useTheme } from "styled-components"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"

type ProgramHeaderRightViewProps = {
  program?: ProgramInfinite | ProgramOnOff
  onAddEventClick: (date?: Dayjs) => void
}

export const ProgramHeaderRightView: React.FC<ProgramHeaderRightViewProps> = ({ program, onAddEventClick }) => {
  const { displayDetailedEvents, setDisplayDetailedEvents } = useCalendar()
  const theme = useTheme()
  const { isAuthor, isAcceptedSharedCoach } = useProgramRights(program)

  return (
    <Container className="p-1 md:p-2 xl:p-4 mb-5 col-3 justify-content-evenly">
      {(isAuthor || isAcceptedSharedCoach) && program && program._programType !== ProgramOnOffTypeOf ? (
        <HUButton
          icon={{
            iconView: <span className={`p-button-icon pi pi-plus`} style={{ color: theme.buttonPrimaryTextColor }} />,
          }}
          text={word("program.createSession.button")}
          type="Left_Icon"
          colorType="Primary"
          size="S"
          className="py-2 px-3 mt-1 mx-auto"
          onClick={() => onAddEventClick()}
          disabled={!(program && (isAuthor || isAcceptedSharedCoach))}
        />
      ) : (
        program && (
          <HURow className="align-items-center justify-content-center gap-2">
            <ProfileAvatar profile={{ avatar: program.author.avatar, username: program.author.firstname }} size={60} />
            <HUStack gap={0} className="text-align-left">
              <HUText
                fontStyle="BL"
                numberOfLine={2}
              >{`${program.author.firstname} ${program.author.lastname}`}</HUText>
              <HUText fontStyle="BS">@{program.author.username}</HUText>
            </HUStack>
          </HURow>
        )
      )}
      <HURow className="justify-content-center align-items-center text-center p-0">
        <HUText className="p-0 max-w-4rem" fontStyle="LM">
          {word("program.planning.view.global")}
        </HUText>
        <HUSwitch
          style={{ minWidth: "3rem" }}
          className="m-0"
          name={"Switch"}
          type={"switch"}
          checked={displayDetailedEvents}
          onChange={() => setDisplayDetailedEvents(!displayDetailedEvents)}
        />
        <HUText className="p-0 max-w-4rem" fontStyle="LM">
          {word("program.planning.view.detailed")}
        </HUText>
      </HURow>
    </Container>
  )
}

const Container = styled(HUStack)`
  border-radius: 33px;
  background: ${({ theme }) => theme.surfaceCard};
`
