import { Page } from "@betomorrow/micro-stores"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { ConfigService } from "@modules/core/services/configService"
import { ItemProgramInfinite, ProgramInfinite, ProgramInfiniteDraft } from "@modules/programs/programInfiniteTypes"
import { ProgramFilter, ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import {
  ItemProgramInfiniteDto,
  ProgramInfiniteDto,
  ProgramInfiniteProfileDto,
  ProgramInfiniteStripeSubscriptionInfosDto,
  convertItemProgramInfiniteFromDto,
  convertPartialProgramInfiniteDraftToDto,
  convertProgramInfiniteDraftToDto,
  convertProgramInfiniteFromDto,
} from "@modules/programs/services/programInfiniteDto"
import { convertToEnum } from "@modules/utils/enum"
import { Paginated, toPage } from "@modules/utils/paginated"
import { ListOrder } from "@modules/utils/types"

export class ProgramInfiniteApi {
  private baseUrl = "/programs"

  private buildSubscribedUrl = (programId: string) =>
    `${ConfigService.config.BASE_URL.replace("/web", "")}/mobile${this.baseUrl}/${programId}/subscribers`
  constructor(private api: ApiClient) {}

  async getProgramInfinite(id: string): Promise<ProgramInfinite> {
    const resultDto = await this.api.get<ProgramInfiniteDto>(
      // `${this.baseUrl}/${id}`
      `${ConfigService.config.BASE_URL.replace("/web", "")}/mobile${this.baseUrl}/${id}`,
    )
    return convertProgramInfiniteFromDto(resultDto.data)
  }
  async createProgramInfinite(program: ProgramInfiniteDraft): Promise<ProgramInfinite> {
    const resultDto = await this.api.post<ProgramInfiniteDto>(
      `${this.baseUrl}`,
      convertProgramInfiniteDraftToDto(program),
    )
    return convertProgramInfiniteFromDto(resultDto.data)
  }

  async updateProgramInfinite(id: string, program: Partial<ProgramInfiniteDraft>): Promise<ProgramInfinite> {
    const resultDto = await this.api.put<ProgramInfiniteDto>(
      `${this.baseUrl}/${id}`,
      convertPartialProgramInfiniteDraftToDto(program),
    )
    return convertProgramInfiniteFromDto(resultDto.data)
  }

  async deleteProgramInfinite(id: string): Promise<void> {
    await this.api.delete<ProgramInfiniteDto>(`${this.baseUrl}/${id}`)
  }

  async getInfinitePrograms(
    filter: ProgramFilter,
    order: ListOrder,
    page = 0,
    size = 100,
  ): Promise<Page<ItemProgramInfinite>> {
    const result = await this.api.get<Paginated<ItemProgramInfiniteDto>>(`${this.baseUrl}`, {
      params: {
        page,
        size,
        filter,
        order,
      },
    })
    const pageResult = toPage(result.data)
    return { ...pageResult, content: pageResult.content.map((program) => convertItemProgramInfiniteFromDto(program)) }
  }

  async getProgramInfiniteSubscribers(id: string, page = 0, size = 12) {
    const result = await this.api.get<Paginated<ProgramInfiniteProfileDto>>(this.buildSubscribedUrl(id), {
      params: {
        page,
        size,
      },
    })
    const pageResult = toPage(result.data)

    return pageResult
  }

  async subscribeProgramInfinite(id: string) {
    const result = await this.api.post<{ status: string }>(`${this.baseUrl}/${id}/subscribe`)
    return convertToEnum(ProgramSubscriptionStatus, result.data.status, ProgramSubscriptionStatus.INACTIVE)
  }

  async unsubscribeProgramInfinite(id: string) {
    const result = await this.api.post<{ status: string }>(`${this.baseUrl}/${id}/unsubscribe`)
    return convertToEnum(ProgramSubscriptionStatus, result.data.status, ProgramSubscriptionStatus.INACTIVE)
  }

  async getProgramInfiniteStripeSubscriptionInfos(id: string) {
    const resultDto = await this.api.get<ProgramInfiniteStripeSubscriptionInfosDto>(
      `${this.baseUrl}/${id}/subscription`,
    )
    return resultDto.data
  }
}
