import { EmailIcon } from "@images/svgIcons/emailIcon"
import { useCurrentBox } from "@modules/box/useBox"
import { word } from "@modules/core/utils/i18n"
import { TransactionsDetailsContainer } from "@modules/finances/components/transactionsDetailsContainer"
import { useTransaction, useTransactionUser } from "@modules/finances/hooks/useTransactions"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { HUButton } from "@modules/ui/components/huButton"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import styled, { useTheme } from "styled-components"

type TransactionsDetailsProps = {
  transactionId: string
}
export const TransactionsDetailsPage: React.FC<TransactionsDetailsProps> = ({ transactionId }) => {
  const { loading: loadingTransactionDetails, value: transactionDetails } = useTransaction(transactionId)
  const theme = useTheme()
  const user = useTransactionUser(transactionDetails)
  const { result: box } = useCurrentBox()
  const navigate = useMySpaceNavigate()
  const boxNavigate = useBoxNavigate()
  const { hideSidebar } = useSidebar()

  const openChatWithUser = async () => {
    if (user) {
      if (box) {
        hideSidebar()
        boxNavigate(Path.Chat.Root, { state: { userToChatTo: user.id } })
      } else {
        hideSidebar()
        navigate(Path.Chat.Root, { state: { userToChatTo: user.id } })
      }
    }
  }

  return (
    <Container $loading={loadingTransactionDetails}>
      {loadingTransactionDetails ? (
        <LoadingSpinner />
      ) : (
        <>
          <AvatarContainer className="p-5 mb-5 flex-row justify-content-center align-items-center overflow-hidden gap-2 border-noround-top">
            <ProfileAvatar
              className="mb-1"
              profile={{ avatar: user?.avatar, username: user?.firstname || "" }}
              size={90}
            />
            <HUStack gap={1} className="text-center">
              <HUText fontWeight="semibold" color={theme.neutralColor700} fontStyle="HS">
                {user?.firstname + " " + user?.lastname}
              </HUText>
              {user?.username && (
                <HUText fontWeight="regular" color={theme.neutralColor700}>
                  {"@" + user.username}
                </HUText>
              )}
            </HUStack>
          </AvatarContainer>

          <InformationsContainer>
            <div className="flex justify-content-center mt-4 align-items-baseline">
              <HUText color={theme.neutralColor700} fontStyle="TM" className="mr-1">
                {`${word("global.email")} :`}
              </HUText>
              <HUText color={theme.neutralColor600} fontStyle="BM" style={{ lineHeight: "23px" }}>
                {`${user?.email}`}
              </HUText>
            </div>
            {user?.phone && (
              <div className="flex  justify-content-center mt-4">
                <HUText color={theme.neutralColor700} fontStyle="TM" className="mr-1">
                  {`${word("global.phoneNumber")} :`}
                </HUText>
                <HUText color={theme.neutralColor600} fontStyle="BM" style={{ lineHeight: "23px" }}>
                  {`${user.phone}`}
                </HUText>
              </div>
            )}
            <div className="flex justify-content-center mt-4 mb-2 gap-2">
              <HUButton
                type="Left_Icon"
                size="S"
                colorType="Black"
                icon={{ iconView: <span className="pi pi-comments text-white" /> }}
                text={word("finances.send_message")}
                onClick={openChatWithUser}
              />
              <HUNavigateButton
                to={`mailto:${user?.email}`}
                type="button"
                isBlack={true}
                icon={{ icon: EmailIcon }}
                color={theme.textPrimary}
                label={word("finances.send_email")}
              />
            </div>
          </InformationsContainer>
          {transactionDetails && <TransactionsDetailsContainer transactionDetails={transactionDetails} />}
        </>
      )}
    </Container>
  )
}

const Container = styled.div<{ $loading: boolean }>`
  height: 100%;
  margin: -52px ${({ $loading }) => ($loading ? "-1.25rem!important" : "0")};
`

const AvatarContainer = styled(HURow)`
  border-radius: 33px;
  background-color: ${({ theme }) => theme.neutralColor100};
  border-width: 0px;
  > :first-child {
    min-width: 90px;
    min-height: 90px;
    border-radius: 45px;
  }
`

const InformationsContainer = styled.div``
