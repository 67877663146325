import { DefaultTheme, css, keyframes } from "styled-components"

export const fadeIn = keyframes`
from{
	opacity:0;
} to {
	opacity: 1;
}`

export const rotate = keyframes`
from{
	transform:rotate(0deg);
} to {
	transform:rotate(180deg);
}`

const loadingAnimation = keyframes`
	0%{background-position:0% 0%}
	100%{background-position:89% 89%}
`

export const animatedSkeletonStyle = (theme: DefaultTheme, color1?: string, color2?: string) => css`
  background: linear-gradient(
    313deg,
    ${color1 || theme.neutralColor100},
    ${color2 || theme.neutralColor200},
    ${color1 || theme.neutralColor100},
    ${color2 || theme.neutralColor200}
  );
  background-position: 100% 100%;
  background-size: 400% 400%;
  animation: ${loadingAnimation} ease-in-out infinite 1s;
`
