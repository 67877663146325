import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { Carousel } from "primereact/carousel"
import styled, { useTheme } from "styled-components"
import { BenchmarkHeroesCard } from "@modules/dashboard/components/cards/benchmarkHeroesCard"
import { dataHeroesBenchmark } from "@modules/dashboard/mockHeroesBenchmark"

export const RightNowResume: React.FC = () => {
  const theme = useTheme()
  return (
    <Container>
      <div className="flex justify-content-between align-items-center w-full mb-2">
        <HUText fontStyle="TL">{word("home.screen.right_now.title")}</HUText>
      </div>
      <HUStack>
        <HUText fontStyle="BS" color={theme.neutralColor700}>
          {word("home.screen.right_now.subtitle")}
        </HUText>
        <HUStack align>
          <Carousel
            value={dataHeroesBenchmark}
            itemTemplate={(dataHeroesBenchmark) => <BenchmarkHeroesCard heroesBenchmark={dataHeroesBenchmark} />}
            showIndicators={true}
            showNavigators={true}
          />
        </HUStack>
      </HUStack>
    </Container>
  )
}
const Container = styled.div`
  padding: 28px 20px;
  border-radius: 33px;
  background-color: ${({ theme }) => theme.neutralColor100};
  .p-carousel {
    width: 100%;
    justify-content: center;
  }
  .p-carousel-content {
    overflow: hidden;
    flex-grow: 1;
  }
  .p-carousel-container {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .p-carousel-items-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .p-carousel .p-carousel-content .p-carousel-prev {
    z-index: 10;
    margin: 0;
  }
  .p-carousel .p-carousel-content .p-carousel-next {
    z-index: 10;
    margin: 0;
  }
  .p-carousel-items-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
  }
  .p-carousel-item {
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .p-carousel-item-active {
    flex: 1 0 100%;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: ${({ theme }) => theme.neutralColor900};
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: ${({ theme }) => theme.neutralColor200};
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 0.3rem;
  }
  .p-carousel .p-carousel-indicators {
    padding: 15px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1015px) {
    margin-bottom: 15px;
    margin-top: 15px;
  }
`
