import AdminIcon from "@images/sidebar-icons/admin.svg"
import BoxIcon from "@images/sidebar-icons/box.svg"
import DashBoardIcon from "@images/sidebar-icons/dashboard.svg"
import ExplorerIcon from "@images/sidebar-icons/explorer.svg"
import MembersIcon from "@images/sidebar-icons/members.svg"
import PaymentIcon from "@images/sidebar-icons/payment.svg"
import PlanningIcon from "@images/sidebar-icons/planning.svg"
import ProductsIcon from "@images/sidebar-icons/products.svg"
import ProgrammationsIcon from "@images/sidebar-icons/programmations.svg"
import ProgramsIcon from "@images/sidebar-icons/programs.svg"
import TrainingIcon from "@images/sidebar-icons/training.svg"
import TutorialsIcon from "@images/sidebar-icons/tutorials.svg"
import { css } from "styled-components"

export const CustomIcons = css`
  .layout-menuitem-icon-container {
    margin-right: 12px !important;
    position: relative;
  }

  .layout-menuitem-icon {
    display: block;
  }

  .layout-menuitem-icon.hup-box {
    background-color: var(--text-color);
    -webkit-mask-image: url(${BoxIcon});
    mast-image: url(${BoxIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-dashboard {
    background-color: var(--text-color);
    -webkit-mask-image: url(${DashBoardIcon});
    mast-image: url(${DashBoardIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-explorer {
    background-color: var(--text-color);
    -webkit-mask-image: url(${ExplorerIcon});
    mast-image: url(${ExplorerIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-members {
    background-color: var(--text-color);
    -webkit-mask-image: url(${MembersIcon});
    mast-image: url(${MembersIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-finances {
    background-color: var(--text-color);
    -webkit-mask-image: url(${PaymentIcon});
    mast-image: url(${PaymentIcon});
    width: 18px;
    height: 19px;
  }
  .layout-menuitem-icon.hup-tutorials {
    background-color: var(--text-color);
    -webkit-mask-image: url(${TutorialsIcon});
    mast-image: url(${TutorialsIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-planning {
    background-color: var(--text-color);
    -webkit-mask-image: url(${PlanningIcon});
    mast-image: url(${PlanningIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-programmations {
    background-color: var(--text-color);
    -webkit-mask-image: url(${ProgrammationsIcon});
    mast-image: url(${ProgrammationsIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-programs {
    background-color: var(--text-color);
    -webkit-mask-image: url(${ProgramsIcon});
    mast-image: url(${ProgramsIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-admin {
    background-color: var(--text-color);
    -webkit-mask-image: url(${AdminIcon});
    mast-image: url(${AdminIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-training {
    background-color: var(--text-color);
    -webkit-mask-image: url(${TrainingIcon});
    mast-image: url(${TrainingIcon});
    width: 18px;
    height: 19px;
  }

  .layout-menuitem-icon.hup-products {
    background-color: var(--text-color);
    -webkit-mask-image: url(${ProductsIcon});
    mast-image: url(${ProductsIcon});
    width: 18px;
    height: 19px;
  }
`
