import { Chrono, ChronoType } from "@modules/chrono/chronoTypes"

export type ChronoDto = ChronoTabataDto | ChronoAmrapDto | ChronoEnomDto | ChronoForTimeDto

export interface ChronoForTimeDto {
  type: "FOR_TIME"
  duration: number
  setsNumber: number
  restBetweenSets: number
  isTimeLimitless: boolean
}

export interface ChronoAmrapDto {
  type: "AMRAP"
  duration: number
  additionalSets: {
    rest: number
    duration: number
  }[]
}

export interface ChronoEnomDto {
  type: "EMOM"
  duration: number
  rounds: number
  setsNumber: number
  restBetweenSets: number
}

export interface ChronoTabataDto {
  type: "TABATA"
  rounds: number
  setsNumber: number
  restBetweenSets: number
  duration: number
  rest: number
}

export function convertChronoFromDto(dto: ChronoDto): Chrono | undefined {
  if (dto.type === ChronoType.FOR_TIME) {
    return {
      type: ChronoType.FOR_TIME,
      trainingTime: dto.duration,
      numberOfSets: dto.setsNumber,
      restTime: dto.restBetweenSets,
      noLimit: dto.isTimeLimitless,
    }
  } else if (dto.type === ChronoType.AMRAP) {
    return {
      type: ChronoType.AMRAP,
      trainingTime: [dto.duration, ...dto.additionalSets.map((s) => s.duration)],
      numberOfSets: dto.additionalSets.length + 1,
      restTime: [0, ...dto.additionalSets.map((s) => s.rest)],
    }
  } else if (dto.type === ChronoType.EMOM) {
    return {
      type: ChronoType.EMOM,
      trainingTime: dto.duration,
      numberOfRounds: dto.rounds,
      numberOfSets: dto.setsNumber,
      restBetweenSetsTime: dto.restBetweenSets,
    }
  } else if (dto.type === ChronoType.TABATA) {
    return {
      type: ChronoType.TABATA,
      trainingTime: dto.duration,
      numberOfRounds: dto.rounds,
      numberOfSets: dto.setsNumber,
      restBetweenSetsTime: dto.restBetweenSets,
      restTime: dto.rest,
    }
  }

  return undefined
}

export function convertChronoToDto(chrono: Chrono): ChronoDto | undefined {
  if (chrono.type === ChronoType.FOR_TIME) {
    return {
      type: ChronoType.FOR_TIME,
      duration: chrono.trainingTime,
      setsNumber: chrono.numberOfSets,
      restBetweenSets: chrono.restTime,
      isTimeLimitless: chrono.noLimit,
    }
  } else if (chrono.type === ChronoType.AMRAP) {
    const duration = chrono.trainingTime[0]
    const additionalDurationSets = chrono.trainingTime.slice(1)
    const additionalRest = chrono.restTime.slice(1)
    const additionalSets = additionalDurationSets.map((duration, index) => {
      const rest = additionalRest[index]
      return { rest, duration }
    })
    return {
      type: ChronoType.AMRAP,
      duration,
      additionalSets,
    }
  } else if (chrono.type === ChronoType.EMOM) {
    return {
      type: ChronoType.EMOM,
      duration: chrono.trainingTime,
      rounds: chrono.numberOfRounds,
      setsNumber: chrono.numberOfSets,
      restBetweenSets: chrono.restBetweenSetsTime,
    }
  } else if (chrono.type === ChronoType.TABATA) {
    return {
      type: ChronoType.TABATA,
      duration: chrono.trainingTime,
      rounds: chrono.numberOfRounds,
      setsNumber: chrono.numberOfSets,
      restBetweenSets: chrono.restBetweenSetsTime,
      rest: chrono.restTime,
    }
  }

  return undefined
}
