import RestDayIcon from "@images/svgIcons/restDayIcon.svg"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"

export const ProgramCalendarEventRestDay: React.FC<{ programName: string; dayIndex: number; duration: number }> = ({
  programName,
  dayIndex,
  duration,
}) => {
  return (
    <HUStack className="align-items-center text-center py-2" gap={15}>
      <img src={RestDayIcon} width={60} alt="Rest day" />
      <HUStack gap={7}>
        <HUText fontStyle="TM">{word("program.onOff.restDay")}</HUText>
        <HUText fontStyle="BS">{`${programName} (${dayIndex + 1}/${duration})`}</HUText>
      </HUStack>
    </HUStack>
  )
}
