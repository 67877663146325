import { useServicesContext } from "@modules/core/services/services.context"
import { useAsync } from "@modules/hooks/useAsync"
import { useObservable } from "micro-observables"

export const useBoxSlotSessionScheduled = (programmationId: string, date: Date | null) => {
  const { boxSlotService } = useServicesContext()

  return useAsync(async () => {
    if (programmationId && date) {
      return await boxSlotService.getScheduledSession(programmationId, date)
    }
    return new Promise<null>(() => null)
  }, [date?.toString()])
}

export const useBoxScheduledSessionsInRange = () => {
  const { boxSlotService } = useServicesContext()

  const scheduledSessions = useObservable(boxSlotService.scheduledSessionsInRange)

  return {
    isLoading: useObservable(boxSlotService.isLoadingScheduledSessions),
    sessions: scheduledSessions,
  }
}
