import { Service } from "@modules/core/services/serviceType"
import { translations } from "@modules/core/utils/i18n"
import { FeatureKeys } from "@modules/featureToggle/featureToggleTypes"
import { FeatureToggleService } from "@modules/featureToggle/services/featureToggleService"
import { getDefaultLanguage } from "@modules/language/languageUtils"
import { LanguageService } from "@modules/language/services/languageService"
import { allLanguages } from "@modules/language/utils/languageUtils"
import dayjs from "dayjs"
import "dayjs/locale/da"
import "dayjs/locale/de"
import "dayjs/locale/el"
import "dayjs/locale/en"
import "dayjs/locale/es"
import "dayjs/locale/fr"
import "dayjs/locale/is"
import "dayjs/locale/it"
import "dayjs/locale/nl"
import "dayjs/locale/pt"
import calendar from "dayjs/plugin/calendar"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import localeData from "dayjs/plugin/localeData"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import updateLocale from "dayjs/plugin/updateLocale"
import weekday from "dayjs/plugin/weekday"

dayjs.extend(updateLocale)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(LocalizedFormat)
dayjs.extend(localeData)
dayjs.extend(customParseFormat)
dayjs.extend(calendar)
dayjs.extend(timezone)
dayjs.extend(weekday)

export class DateService implements Service {
  constructor(private languageService: LanguageService, private featureToggleService: FeatureToggleService) {
    const language = this.languageService.language.get()
    this.updateLanguage(language.languageCode)
  }

  async init() {
    const language = this.languageService.language.get()
    this.updateLanguage(language.languageCode)

    allLanguages.forEach((lang) => {
      dayjs.updateLocale(lang, {
        calendar: {
          lastDay: translations[lang]?.global.date.lastDay ?? translations.en.global.date.lastDay,
          sameDay: translations[lang]?.global.date.sameDay ?? translations.en.global.date.sameDay,
          nextDay: translations[lang]?.global.date.nextDay ?? translations.en.global.date.nextDay,
          lastWeek: translations[lang]?.global.date.lastWeek ?? translations.en.global.date.lastWeek,
          nextWeek: translations[lang]?.global.date.nextWeek ?? translations.en.global.date.nextWeek,
          sameElse: translations[lang]?.global.date.sameElse ?? translations.en.global.date.sameElse,
        },
      })
    })

    return this.languageService.language.subscribe((lang) => {
      this.updateLanguage(lang.languageCode)
    })
  }

  private updateLanguage(languageCode: string | undefined) {
    const allLanguagesAvailable = this.featureToggleService.hasFeature(FeatureKeys.AllLanguages)

    dayjs.locale(languageCode || getDefaultLanguage(allLanguagesAvailable))
  }
}
