import { FetchStrategy, useStore } from "@betomorrow/micro-stores"
import { useServicesContext } from "@modules/core/services/services.context"
import { useAsyncObservable } from "@modules/hooks/useAsyncObservable"
import { useObservable } from "micro-observables"

export const useBoxs = () => {
  const { boxService } = useServicesContext()
  return useAsyncObservable(boxService.boxs)
}

export const useSelectedBoxId = () => {
  const { currentSpaceManager } = useServicesContext()
  return useObservable(currentSpaceManager.currentBox).boxId
}

export const useBox = (id: string, fetchStrategy?: FetchStrategy) => {
  const { boxService } = useServicesContext()
  return useStore(id, boxService.boxStore, fetchStrategy)
}

export const useCurrentBox = () => {
  const { boxService } = useServicesContext()
  const id = useSelectedBoxId()
  return useStore(id, boxService.boxStore)
}
