import { PlusIcon } from "@images/svgIcons/essentials/plusIcon"
import { MessagesIcon } from "@images/svgIcons/messagesIcon"
import { TrashIcon } from "@images/svgIcons/trashIcon"
import { BoxSlotSession } from "@modules/boxSlot/boxSlotTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { ParticipantStatus, Subscriber } from "@modules/profile/subscriber/subscriberTypes"
import { EllipsisMenu } from "@modules/ui/components/ellipsisMenu"
import { HUCheckbox } from "@modules/ui/components/huCheckbox"
import { HUText } from "@modules/ui/components/huText"
import Line from "@modules/ui/components/line"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { MenuItem } from "primereact/menuitem"
import { useState } from "react"
import { DefaultTheme, useTheme } from "styled-components"

const BoxSessionSubscriber: React.FC<{
  slotId: string
  boxSlot: BoxSlotSession
  subscriber: Subscriber
  participantStatus: ParticipantStatus
  setSlotSubscribers: React.Dispatch<React.SetStateAction<Subscriber[]>>
  setSlotPendingSubscribers: React.Dispatch<React.SetStateAction<Subscriber[]>>
}> = ({ slotId, boxSlot, subscriber, participantStatus, setSlotSubscribers, setSlotPendingSubscribers }) => {
  const theme = useTheme()
  const { boxSlotService } = useServicesContext()
  const boxNavigate = useBoxNavigate()
  const [isSelected, setIsSelected] = useState(subscriber.absent ?? false)
  const confirmPopup = useConfirmPopup()

  const fetchSlotSubscribers = async () => {
    const subscribers = await boxSlotService.getSlotSubscribers(boxSlot.id)
    const pendingSubscribers = await boxSlotService.getSlotSubscribers(
      boxSlot.id,
      undefined,
      undefined,
      undefined,
      "pending",
    )
    setSlotSubscribers(subscribers.content)
    setSlotPendingSubscribers(pendingSubscribers.content)
  }

  const noShowToggle = async () => {
    setIsSelected(!isSelected)
    await boxSlotService.flagParticipant(slotId, subscriber.memberId, !isSelected)
  }

  const atendeesToggle = async (subscriber: Subscriber) => {
    await boxSlotService.addParticipantToSlot(slotId, subscriber.memberId)
    void fetchSlotSubscribers()
  }

  const sendMessage = () => {
    boxNavigate(Path.Chat.Root, { state: { userToChatTo: subscriber.id } })
  }

  const bodyDialog = (
    <div className="flex flex-column align-items-center align-content-center justify-content-center">
      <HUText fontStyle="TL" color={theme.mainWhite} className="flex mb-3">
        {word("boxSessionSubscriber.bodyDialog.title")}
      </HUText>
      <HUText fontStyle="BM" color={theme.neutralColor700} className="flex w-22rem text-center">
        {word("boxSessionSubscriber.bodyDialog.body")}
      </HUText>
    </div>
  )

  const removeFromSlot = () => {
    confirmPopup.show({
      width: "418px",
      title: "",
      message: bodyDialog,
      accept: async () => {
        await boxSlotService.removeParticipantToSlot(slotId, subscriber.memberId, participantStatus)
        void fetchSlotSubscribers()
      },
      footerProps: {
        align: true,
        cancelText: word("global.cancel"),
        confirmText: word("global.remove.yes"),
        confirmClassName: "flex-1",
        cancelClassName: "flex-1",
        footerClassName: "flex flex-row justify-content-around w-full",
      },
    })
  }

  const ellipsisItems: MenuItem[] = [
    {
      items: [
        {
          label: word("chat.send_message"),
          icon: <MessagesIcon color={theme.textPrimary} width={25} height={25} />,
          command: () => sendMessage(),
        },
        {
          label: word("boxSessionSubscriber.ellipsisItems.removeList"),
          icon: <TrashIcon color={theme.textPrimary} width={25} height={25} />,
          command: () => removeFromSlot(),
        },
      ],
    },
  ]

  return (
    <div className="flex flex-column w-full">
      <div className="flex flex-row w-full my-2">
        {participantStatus == "registered"
          ? buttonRegister(theme as DefaultTheme, noShowToggle, subscriber, isSelected)
          : buttonPending(theme as DefaultTheme, atendeesToggle, subscriber)}
        <div className="flex flex-1 ml-3 font-bold border-round">
          <ProfileAvatar
            profile={{ avatar: subscriber.avatar, username: subscriber.firstname || "" }}
            size={60}
            className="ml-1"
          />
          <div className="flex flex-column ml-2 flex-wrap gap-1 justify-content-center">
            <HUText fontStyle="LL" color={theme.mainWhite}>
              {`${subscriber.firstname} ${subscriber.lastname}`}
            </HUText>
            <HUText fontStyle="BS" color={theme.neutralColor600}>
              {` ${subscriber.username}`}
            </HUText>
          </div>
        </div>
        <div className="flex flex-none w-2 align-items-center justify-content-center font-bold border-round">
          <EllipsisMenu
            items={ellipsisItems}
            style={{ color: theme.mainWhite, borderColor: theme.mainBlack }} // hack for none border borderColor: theme.mainBlack
            buttonClassName="border-none-hover"
            width={306}
          />
        </div>
      </div>
      <Line color={`${theme.neutralColor400}`} strokeWidth={1} className="m-0" />
    </div>
  )
}

const buttonPending = (theme: DefaultTheme, onClick: (subscriber: Subscriber) => void, subscriber: Subscriber) => {
  return (
    <>
      <div className="flex flex-none mt-1 align-items-center justify-content-center font-bold border-round">
        <button className="flex flex-column align-items-center w-3rem mx-1" onClick={() => onClick(subscriber)}>
          <div className="flex justify-content-center w-full">
            <PlusIcon color={theme.neutralColor700} width={24} height={24} />
          </div>
          <div className="mt-1 text-center line-height-1">
            <HUText fontStyle="BS" color={theme.neutralColor700}>
              {word("boxSessionSubscriber.button.addToAtendees")}
            </HUText>
          </div>
        </button>
      </div>
    </>
  )
}

const buttonRegister = (theme: DefaultTheme, onClick: () => void, subscriber: Subscriber, isSelected: boolean) => {
  return (
    <>
      <div className="flex flex-none  mt-1 align-items-center justify-content-center font-bold border-round">
        <div className="flex flex-column w-3rem mx-1">
          <HUCheckbox
            inputKey={subscriber.id}
            name="option"
            value=""
            checked={isSelected}
            onChange={onClick}
            color={theme.primaryColor}
            checkIconColor={theme.neutralColor700}
            className="flex justify-content-center w-full"
          />
          <div className="mt-1 text-center">
            <HUText fontStyle="BS" color={theme.neutralColor700}>
              {word("boxSessionSubscriber.checkbox.subtitle")}
            </HUText>
          </div>
        </div>
      </div>
    </>
  )
}

export default BoxSessionSubscriber
