import femaleIcon from "@images/svgIcons/femaleIcon.svg"
import maleIcon from "@images/svgIcons/maleIcon.svg"
import { ProfileFormikType, ProfileInputKey } from "@modules/auth/profileFormikTypes"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import React from "react"
import styled, { useTheme } from "styled-components"

export type RegisterFormTwoPageProps = {
  handleSubmit: () => void
  onPrevious: () => void
  formik: SimpleFormik<ProfileFormikType, ProfileInputKey>
  isFormFieldInValid: (name: ProfileInputKey) => boolean
  getFormErrorMessage: (name: ProfileInputKey, errorMessage?: string) => JSX.Element | undefined
  disabled: boolean
}

export const RegisterFormTwoPage: React.FC<RegisterFormTwoPageProps> = ({
  handleSubmit,
  onPrevious,
  formik,
  disabled,
}: RegisterFormTwoPageProps) => {
  const theme = useTheme()
  const handleSelectGender = (gender: "MALE" | "FEMALE") => {
    formik.setFieldValue("genderCategory", gender)
  }

  return (
    <Container>
      <HUStack gap={20} className="mb-4">
        <HUButton
          type="Left_Icon"
          size="XS"
          colorType="Tertiary"
          text={word("global.previous")}
          className=""
          onClick={onPrevious}
          icon={{
            iconView: <span className={`pi pi-arrow-left`} style={{ color: theme.textPrimary }} />,
          }}
        />
        <HUText fontStyle="TM">2/4</HUText>
        <HUText fontStyle="TM">{word("auth.signup.form.page.2.title")}</HUText>
        <HUText fontStyle="BS" color={theme.neutralColor700}>
          {word("auth.signup.form.page.2.description")}
        </HUText>
        <HURow className="justify-content-center">
          <GenderContainer>
            <GenderOption
              onClick={() => handleSelectGender("FEMALE")}
              selected={formik.getFieldProps("genderCategory").value === "FEMALE"}
              className="mr-2 flex justify-content-center align-items-center "
            >
              <HUStack className="justify-content-center align-items-center">
                <ContainerImage>
                  <img src={femaleIcon} />
                </ContainerImage>
                <HUText>{word("scoreboard.filters.female")}</HUText>
              </HUStack>
            </GenderOption>
            <GenderOption
              onClick={() => handleSelectGender("MALE")}
              selected={formik.getFieldProps("genderCategory").value === "MALE"}
              className="ml-2 flex justify-content-center align-items-center "
            >
              <HUStack className="justify-content-center align-items-center">
                <ContainerImage>
                  <img src={maleIcon} className="mt-2" />
                </ContainerImage>
                <HUText>{word("scoreboard.filters.male")}</HUText>
              </HUStack>
            </GenderOption>
          </GenderContainer>
        </HURow>
      </HUStack>
      <HUButton
        type="Default"
        size="M"
        colorType="Primary"
        text={word("global.next")}
        className="mt-2"
        style={{ width: "100%" }}
        onClick={handleSubmit}
        disabled={disabled}
        submit
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`
const GenderContainer = styled(HURow)`
  width: 285px;
  justify-content: space-between;
`

const ContainerImage = styled.div`
  height: 45px;
`

const GenderOption = styled.div<{ selected?: boolean }>`
  background-color: ${({ selected, theme }) => (selected ? theme.primaryColor : theme.neutralColor200)};
  color: ${({ selected, theme }) => (selected ? theme.neutralColor100 : theme.textPrimary)};
  padding: 16px;
  border-radius: 8px;
  width: 132px;
  height: 191px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b0d717;
    color: ${({ theme }) => theme.neutralColor100};
  }
`
