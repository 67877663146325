import { word } from "@modules/core/utils/i18n"
import { Currency } from "@modules/currency/currency"
import { PromoCodeType } from "@modules/promoCode/services/promoCodeDto"
import { PromoCodeDraft } from "@modules/promoCode/types/promoCodeTypes"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import * as Yup from "yup"

export type PromoCodeFormikType = {
  id?: string
  title: string
  code: string
  value?: number
  currency: Currency
  type: PromoCodeType
  maxAttendeesLimited: boolean
  maxAttendees?: number
  noExpirationDate: boolean
  expirationDate?: Date
  noDurationLimit: boolean
  durationInMonths?: number
  onlyNewCustomer: boolean
  programs: string[]
}

export function getPromoCodesValidationSchema() {
  return Yup.object({
    title: Yup.string()
      .required(word("global.form.required"))
      .max(40, word("form.maximum.characters", { count: 40 })),
    code: Yup.string()
      .required(word("global.form.required"))
      .matches(/^[A-Za-z0-9]+$/, word("promoCode.form.input.code.regexError"))
      .max(30, word("form.maximum.characters", { count: 30 })),
    type: Yup.mixed<PromoCodeType>().oneOf(Object.values(PromoCodeType)),
    value: Yup.number().when("type", {
      is: (val: PromoCodeType) => val === PromoCodeType.PERCENT,
      then: () =>
        Yup.number()
          .min(0.01, word("global.form.minimumValue", { minValue: 0.01 }))
          .max(100, word("global.form.maximumValue", { maxValue: 100 }))
          .required(word("global.form.required")),
      otherwise: () =>
        Yup.number()
          .min(0.01, word("global.form.minimumValue", { minValue: 0.01 }))
          .max(1e5, word("global.form.maximumValue", { maxValue: 1e5 }))
          .required(word("global.form.required")),
    }),
    maxAttendeesLimited: Yup.boolean(),
    maxAttendees: Yup.number().when("maxAttendeesLimited", {
      is: (val: boolean) => val,
      then: () =>
        Yup.number()
          .min(1, word("global.form.minimumValue", { minValue: 1 }))
          .max(1e5, word("global.form.maximumValue", { maxValue: 1e5 }))
          .required(word("global.form.required")),
      otherwise: () => Yup.number().nullable(),
    }),
    noExpirationDate: Yup.boolean(),
    expirationDate: Yup.date().when("noExpirationDate", {
      is: (val: boolean) => val,
      then: () => Yup.date(),
      otherwise: () => Yup.date().required(word("global.form.required")),
    }),
    noDurationLimit: Yup.boolean(),
    durationInMonths: Yup.number().when("noDurationLimit", {
      is: (val: boolean) => val,
      then: () => Yup.number().nullable(),
      otherwise: () =>
        Yup.number()
          .min(1, word("global.form.minimumValue", { minValue: 1 }))
          .max(120, word("global.form.maximumValue", { maxValue: 120 }))
          .required(word("global.form.required")),
    }),
    onlyNewCustomer: Yup.boolean(),
    programs: Yup.array().of(Yup.string()).min(1, word("promoCode.form.input.relatedProgramsError")).required(),
  })
}

export const convertFormikToPromoCodeDraft = (formik: PromoCodeFormikType): PromoCodeDraft => {
  return {
    title: formik.title,
    code: formik.code,
    maxAttendees: formik.maxAttendees,
    onlyNewCustomer: formik.onlyNewCustomer,
    selectedProgramIds: formik.programs,
    type: formik.type,
    value: formik.value ?? 0,
    createdAt: dayjs().toISOString(),
    expirationDate: formik.expirationDate ? dayjs(formik.expirationDate).format(defaultDateFormat) : undefined,
    durationInMonths: formik.durationInMonths,
  }
}
