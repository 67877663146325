import { PaginatedStore, usePaginatedStore } from "@betomorrow/micro-stores"
import { useServicesContext } from "@modules/core/services/services.context"
import { ExerciseScoreCategory, ExerciseScoreDifficulty } from "@modules/exercises/exerciseTypes"
import { useAsync } from "@modules/hooks/useAsync"
import { useMemo } from "react"

export const useScoreboard = (
  programId: string,
  sessionId: string,
  exerciseId: string,
  difficulty: ExerciseScoreDifficulty,
  category: ExerciseScoreCategory,
  referenceDate: string,
) => {
  const { scoreboardService } = useServicesContext()

  const store = useMemo(
    () =>
      new PaginatedStore((page) =>
        scoreboardService.getScoreboard(programId, sessionId, exerciseId, difficulty, category, referenceDate, page),
      ),
    [programId, sessionId, exerciseId, difficulty, category, referenceDate],
  )
  return usePaginatedStore(store)
}

export const useMyRanking = (
  programId: string,
  sessionId: string,
  exerciseId: string,
  difficulty: ExerciseScoreDifficulty,
  category: ExerciseScoreCategory,
  referenceDate: string,
) => {
  const { scoreboardService } = useServicesContext()

  return useAsync(
    () => scoreboardService.getRanking(programId, sessionId, exerciseId, difficulty, category, referenceDate),
    [programId, sessionId, exerciseId, difficulty, category, referenceDate],
  )
}
