import { word } from "@modules/core/utils/i18n"
import { UserFormikType, UserInputKey } from "@modules/profile/userFormikType"
import { FormikInput } from "@modules/ui/components/formikInput"
import { nameRegExp, usernameRegExp } from "@modules/utils/regexp"
import { Units } from "@modules/utils/unitType"
import { getDisplayUnits } from "@modules/utils/unitUtils"
import { SimpleFormik } from "@src/typings/formik"
import { useMemo } from "react"
import styled from "styled-components"

type Props = {
  setLoadingAvatar: (loadingAvatar: boolean) => void
  isFormFieldInValid: (name: UserInputKey) => boolean
  getFormErrorMessage: (name: UserInputKey, errorMessage?: string) => JSX.Element | undefined
  formik: SimpleFormik<UserFormikType, UserInputKey>
}

export const ProfileFormikForm: React.FC<Props> = ({
  isFormFieldInValid,
  setLoadingAvatar,
  getFormErrorMessage,
  formik,
}) => {
  const unitOptions = useMemo(
    () =>
      Units.map((unit) => ({
        value: unit,
        label: getDisplayUnits(unit),
      })),
    [],
  )

  return (
    <div>
      <AvatarCard className="card">
        <FormikInput
          name="avatar"
          type="avatar"
          isRequiredInput
          avatarProps={{ username: formik.values.username, setLoadingAvatar }}
          getFieldProps={formik.getFieldProps}
          setFieldValue={formik.setFieldValue}
          isInvalid={isFormFieldInValid("avatar")}
          error={getFormErrorMessage("avatar")}
        />
      </AvatarCard>
      <FormikFieldContainer>
        <FormikInput
          flex
          textProps={{ inputRegex: nameRegExp }}
          label={word("profile.form.firstname.label")}
          isRequiredInput
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("firstname")}
          name="firstname"
          error={getFormErrorMessage("firstname")}
        />
        <FormikInput
          flex
          label={word("profile.form.lastname.label")}
          textProps={{ inputRegex: nameRegExp }}
          isRequiredInput
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("lastname")}
          name="lastname"
          error={getFormErrorMessage("lastname")}
        />
      </FormikFieldContainer>
      <FormikFieldContainer>
        <FormikInput
          flex
          label={word("profile.form.username.label")}
          isRequiredInput
          textProps={{ inputRegex: usernameRegExp }}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("username")}
          name="username"
          error={getFormErrorMessage("username")}
        />
        <FormikInput
          flex
          disabled
          label={word("profile.form.mail.label")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("email")}
          name="email"
          error={getFormErrorMessage("email")}
        />
      </FormikFieldContainer>
      <FormikInput
        flex
        isRequiredInput
        label={word("profile.unit.options.title")}
        getFieldProps={formik.getFieldProps}
        isInvalid={false}
        name="unit"
        type="dropdown"
        dropdownProps={{
          options: unitOptions,
          value: formik.values.unit,
        }}
        setFieldValue={formik.setFieldValue}
      />
    </div>
  )
}

const AvatarCard = styled.div`
  background-color: ${({ theme }) => theme.neutralColor100};
  padding: 87px 20px;
`

const FormikFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;

  @media screen and (min-width: 700px) {
    flex-direction: row;
    gap: 24px;
  }
`
