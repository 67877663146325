import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ForTimeIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="62" height="63" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="1.5" width="60" height="60" rx="18" fill="#F9BA40" />
    <rect x="1" y="1.5" width="60" height="60" rx="18" stroke="url(#paint0_linear_408_84730)" strokeWidth="1.11776" />
    <rect x="13" y="32.4453" width="2" height="36" rx="1" transform="rotate(-90 13 32.4453)" fill="white" />
    <rect
      opacity="0.4"
      x="26.4619"
      y="26.5"
      width="2"
      height="12.4615"
      rx="1"
      transform="rotate(90 26.4619 26.5)"
      fill="white"
    />
    <rect
      opacity="0.4"
      x="48.4619"
      y="34.5"
      width="2"
      height="12.4615"
      rx="1"
      transform="rotate(90 48.4619 34.5)"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_408_84730"
        x1="3.15569"
        y1="2.5"
        x2="43.3973"
        y2="68.4826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.04" />
        <stop offset="0.188137" stopColor="white" stopOpacity="0" />
        <stop offset="0.708289" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.06" />
      </linearGradient>
    </defs>
  </svg>
)
