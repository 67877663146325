import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { headerColumn, historyTransactionsColumns, valueColumn } from "@modules/finances/components/historyColumns"
import { TransactionsDetailsPage } from "@modules/finances/components/transactionsDetailsPage"
import { useTransactionUser } from "@modules/finances/hooks/useTransactions"
import { useTransactionsHistory } from "@modules/finances/hooks/useTransactionsHistory"
import { MemberTransaction, UserTransaction } from "@modules/finances/types/transactionType"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { renderTableColumns } from "@modules/ui/components/dataTables/datatable"
import { HUButton } from "@modules/ui/components/huButton"
import { HUDropdown } from "@modules/ui/components/huDropdown"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useObservable } from "micro-observables"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable, DataTableValue } from "primereact/datatable"
import { useState } from "react"
import styled, { DefaultTheme, useTheme } from "styled-components"

type HistoryDetailsProps = { transactionDetails: UserTransaction | MemberTransaction }

export const HistoryDetails: React.FC<HistoryDetailsProps> = ({ transactionDetails }) => {
  const [size, setSize] = useState(10)
  const user = useTransactionUser(transactionDetails)
  const { boxFinanceService } = useServicesContext()
  const lastUpdateLoading = useObservable(boxFinanceService.lastUpdateLoading)
  const theme = useTheme()

  const { value: transactionHistory, loading, next, previous } = useTransactionsHistory(size, user?.stripeCustomerId)
  const { sidebarNavigateTo } = useSidebar()
  const language = useLanguage()

  const tableLoading = lastUpdateLoading || loading
  const sizeOptions = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
  ]

  const onSelectedTransaction = (e: DataTableValue) => {
    sidebarNavigateTo({
      content: <TransactionsDetailsPage transactionId={e.stripeChargeId} />,
      replaceAllComponents: true,
    })
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex flex-column flex-grow-1" style={{ gap: 28 }}>
          <StyledDatatable
            value={[...transactionHistory]}
            dataKey="stripeChargeId"
            paginator
            lazy
            currentPageReportTemplate={""}
            rows={size}
            totalRecords={size}
            onPage={(event) => setSize(event.rows)}
            paginatorTemplate=""
            emptyMessage={word("global.no_result")}
            onSelectionChange={(e) => onSelectedTransaction(e.value)}
            loading={tableLoading}
            footer={
              (next || previous) && (
                <FooterRow align gap={14}>
                  <HURow gap={8}>
                    <HUButton
                      colorType="Tertiary"
                      type="Default"
                      size="S"
                      onClick={() => previous?.()}
                      disabled={!previous}
                      text={word("global.previous")}
                    />
                    <HUButton
                      type="Default"
                      size="S"
                      colorType="Tertiary"
                      onClick={() => next?.()}
                      disabled={!next}
                      text={word("global.next")}
                    />
                  </HURow>
                  <Separator />
                  <SearchSize align gap={8}>
                    <HUText fontStyle="LL" color={theme.neutralColor600}>
                      {word("finances.page.table.footer.label")}
                    </HUText>
                    <HUDropdown value={size} options={sizeOptions} onChange={(e) => setSize(e)} itemFontSize="0.9rem" />
                  </SearchSize>
                </FooterRow>
              )
            }
          >
            {renderTableColumns(
              [...historyTransactionsColumns],
              headerColumn,
              valueColumn,
              theme as DefaultTheme,
              language,
            )}
            <Column
              body={(transaction) => (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <EyeButton
                    icon="pi pi-eye"
                    outlined
                    className="p-button-rounded mr-2"
                    onClick={() => {
                      onSelectedTransaction(transaction)
                    }}
                  />
                </div>
              )}
            />
          </StyledDatatable>
        </div>
      )}
    </>
  )
}

const StyledDatatable = styled(DataTable)`
  .p-paginator-bottom {
    border: none;
  }
  .p-datatable-thead > tr {
    display: none;
  }
`

const FooterRow = styled(HURow)`
  justify-content: center;

  button {
    min-width: 110px;
  }

  .p-inputtext {
    font-size: 0.9rem;
  }
`

const Separator = styled.div`
  display: flex;
  width: 1px;
  height: 42px;
  background-color: ${({ theme }) => theme.neutralColor200};
`

const SearchSize = styled(HURow)``

const EyeButton = styled(Button)`
  &.p-button.p-button-outlined {
    color: ${({ theme }) => theme.textPrimary};
  }
`
