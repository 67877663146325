import { Page } from "@betomorrow/micro-stores"
import {
  BoxProgrammation,
  BoxProgrammationDefaultSettings,
  BoxProgrammationDraft,
  ItemBoxProgrammation,
} from "@modules/boxProgrammation/boxProgrammationTypes"
import {
  BoxProgrammationDefaultSettingsDto,
  BoxProgrammationDto,
  convertBoxProgrammationDefaultValuesFromDto,
  convertBoxProgrammationDefaultValuesToDto,
  convertBoxProgrammationDraftToDto,
  convertBoxProgrammationFromDto,
  convertPartialBoxProgrammationDraftToDto,
} from "@modules/boxProgrammation/services/boxProgrammationDto"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { Paginated, toPage } from "@modules/utils/paginated"

export class BoxProgrammationApi {
  private programBaseUrl = "/box/programs"

  constructor(private api: ApiClient) {}

  async getBoxProgrammation(id: string): Promise<BoxProgrammation> {
    const resultDto = await this.api.get<BoxProgrammationDto>(`${this.programBaseUrl}/${id}`)
    return convertBoxProgrammationFromDto(resultDto.data)
  }

  async createBoxProgrammation(boxProgrammation: BoxProgrammationDraft): Promise<BoxProgrammation> {
    const resultDto = await this.api.post<BoxProgrammationDto>(
      `${this.programBaseUrl}`,
      convertBoxProgrammationDraftToDto(boxProgrammation),
    )
    return convertBoxProgrammationFromDto(resultDto.data)
  }

  async updateBoxProgrammation(id: string, programmation: Partial<BoxProgrammationDraft>): Promise<BoxProgrammation> {
    const resultDto = await this.api.put<BoxProgrammationDto>(
      `${this.programBaseUrl}/${id}`,
      convertPartialBoxProgrammationDraftToDto(programmation),
    )
    return convertBoxProgrammationFromDto(resultDto.data)
  }

  async deleteBoxProgrammation(id: string): Promise<void> {
    await this.api.delete<BoxProgrammationDto>(`${this.programBaseUrl}/${id}`)
  }

  async getBoxProgrammations(page = 0, size = 50): Promise<Page<ItemBoxProgrammation>> {
    const result = await this.api.get<Paginated<BoxProgrammationDto>>(`${this.programBaseUrl}`, {
      params: {
        page,
        size,
        filter: "ALL",
        order: "SUBSCRIPTION_DATE",
      },
    })
    const pageResult = toPage(result.data)
    return { ...pageResult, content: pageResult.content.map((program) => convertBoxProgrammationFromDto(program)) }
  }

  async getBoxProgrammationDefaultValues(id: string): Promise<BoxProgrammationDefaultSettings | undefined> {
    const resultDto = await this.api.get<BoxProgrammationDefaultSettingsDto | undefined>(
      `${this.programBaseUrl}/${id}/slots/defaultValues`,
    )
    return resultDto.data ? convertBoxProgrammationDefaultValuesFromDto(resultDto.data) : undefined
  }

  async createBoxProgrammationDefaultValues(
    id: string,
    defaultValues: BoxProgrammationDefaultSettings,
  ): Promise<BoxProgrammationDefaultSettings> {
    const resultDto = await this.api.post<BoxProgrammationDefaultSettingsDto>(
      `${this.programBaseUrl}/${id}/slots/defaultValues`,
      convertBoxProgrammationDefaultValuesToDto(defaultValues),
    )
    return convertBoxProgrammationDefaultValuesFromDto(resultDto.data)
  }

  async updateBoxProgrammationDefaultValues(
    id: string,
    defaultValues: BoxProgrammationDefaultSettings,
  ): Promise<BoxProgrammationDefaultSettings> {
    const resultDto = await this.api.put<BoxProgrammationDefaultSettingsDto>(
      `${this.programBaseUrl}/${id}/slots/defaultValues`,
      convertBoxProgrammationDefaultValuesToDto(defaultValues),
    )
    return convertBoxProgrammationDefaultValuesFromDto(resultDto.data)
  }
}
