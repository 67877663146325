const Line = ({ color = "black", strokeWidth = 2, className = "" }) => {
  // Use m-0 to remove margin because hr use m-1 by default
  const style = {
    backgroundColor: color,
    height: strokeWidth,
    border: "none",
  }

  return <hr className={className} style={style} />
}

export default Line
