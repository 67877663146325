import TrainingComingSoon from "@images/coming-soon/training_comingsoon.png"
import { GriboullisIcon } from "@images/svgIcons/griboullisIcon"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import styled, { useTheme } from "styled-components"

export const TrainingInformation: React.FC<{}> = () => {
  const theme = useTheme()

  return (
    <Container className="mb-5 col-6">
      <div>
        <HUText fontStyle="HM" color={theme.mainWhite}>
          {word("menu.coming_soon")}
        </HUText>
        <GriboullisIcon color={theme.primaryColor} />
      </div>
    </Container>
  )
}

const Container = styled(HURow)`
  border-radius: 33px;
  border-width: 0px;
  padding: 0px;
  background-image: url(${TrainingComingSoon});
  background-position: center;
  background-size: cover;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center !important;
`
