import { Dropdown } from "primereact/dropdown"
import styled from "styled-components"

interface Item<T> {
  value: T
  label: string
  leftSide?: JSX.Element
}

interface DropdownProps<T> {
  value: T
  options: Item<T>[]
  onChange: (value: T) => void
  valueTemplate?: (item: Item<T>) => React.ReactNode
  className?: string
  itemFontSize?: string
}

export function HUDropdown<T>({ value, options, onChange, valueTemplate, itemFontSize, className }: DropdownProps<T>) {
  const selectedOption = options.find((item) => item.value === value)

  const itemOptionTemplate = (item: Item<T>) => {
    return (
      <div className="flex align-items-center">
        {item.leftSide && <Deco>{item.leftSide}</Deco>}
        <div
          className="overflow-hidden text-overflow-ellipsis"
          style={{ maxWidth: 400, fontSize: itemFontSize ?? "1rem" }}
        >
          {item.label}
        </div>
      </div>
    )
  }
  return (
    <StyledDropdown
      className={className}
      value={value}
      options={options}
      onChange={(e) => onChange(e.value)}
      optionLabel="label"
      valueTemplate={() => selectedOption && (valueTemplate ?? itemOptionTemplate)(selectedOption)}
      itemTemplate={itemOptionTemplate}
    />
  )
}

const StyledDropdown = styled(Dropdown)`
  padding: 0.625rem;

  .p-dropdown-label {
    padding: 0;
    margin-right: 10px;
  }
  .p-dropdown-trigger {
    width: initial;
  }
`

const Deco = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  border-radius: 5px;
  background-color: var(--surface-border);
`
