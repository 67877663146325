import { QuadBoxIcon } from "@images/svgIcons/quadBoxIcon"
import { BoxRestrictionsComponent } from "@modules/boxProgrammation/components/boxRestrictionsComponent"
import { useBoxProgrammation } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { hexToRgbString } from "@modules/utils/colorUtils"
import { LoadingIndicator } from "stream-chat-react"
import styled from "styled-components"

export const BoxProgrammationInfoResume: React.FC<{ programmationId: string }> = ({ programmationId }) => {
  const { result: programmation, loading } = useBoxProgrammation(programmationId)

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : programmation ? (
        <>
          <div className="flex flex-column flex-grow-1">
            <HeaderContainer>
              <TitleContainer>
                <div className="w-max ml-4">
                  <QuadBoxIcon color={programmation.color} />
                </div>
                <HUText fontStyle="HS">{programmation.name}</HUText>
              </TitleContainer>
              <ImageContainer $bgImage={programmation.image.path}>
                <GradientOverlay $color={programmation.color} />
              </ImageContainer>
            </HeaderContainer>
            <ContentContainer>
              <HUText fontStyle="BM">{programmation.description}</HUText>
              <BoxRestrictionsComponent programmation={programmation} />
            </ContentContainer>
          </div>
        </>
      ) : (
        <HUText fontStyle="TS">{word("global.error.default")}</HUText>
      )}
    </>
  )
}

const HeaderContainer = styled.div`
  margin: -85px -20px;
  display: flex;
  padding: 1px 0px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  border-bottom-right-radius: 33px;
`

const ImageContainer = styled(HURow)<{ $bgImage?: string }>`
  position: relative;
  border-radius: 33px;
  border-width: 0px;
  height: 290px;
  width: 50%;
  background-image: ${({ $bgImage }) => `url(${$bgImage})`};
  background-size: cover;
  background-position: center;
  display: flex;
  width: 299px;
  height: 230px;
  justify-content: flex-end;
  align-items: flex-start;
  flex-shrink: 0;
  clip-path: polygon(41% 0, 100% 0, 100% 1000%, 0% 125%);
`
const GradientOverlay = styled.div<{ $color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ $color }) => `rgba(${hexToRgbString($color)}, 0.7)`};
  border-bottom-right-radius: 33px;
  border-width: 0px;
  height: 230px;
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ContentContainer = styled.div`
  margin-top: 115px;
  margin-bottom: 20px;
`
