import { AddressAutofill } from "@mapbox/search-js-react"
import { BoxFormPageProps } from "@modules/box/components/form/boxFormFirstPage"
import { BoxFormTemplatePage } from "@modules/box/components/form/boxFormTemplatePage"
import { Config } from "@modules/config"
import { word } from "@modules/core/utils/i18n"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUInput } from "@modules/ui/components/huInput"
import { HUText } from "@modules/ui/components/huText"
import { countries } from "countries-list"
import { classNames } from "primereact/utils"
import { useTheme } from "styled-components"

export const BoxFormSecondPage = ({
  handleSubmit,
  onPrevious,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  disabled,
}: BoxFormPageProps) => {
  const theme = useTheme()
  const countriesOptions = Object.entries(countries).map(([key, value]) => {
    return { label: value.native, code: key, ...value }
  })

  return (
    <BoxFormTemplatePage
      onPrevious={onPrevious}
      handleSubmit={handleSubmit}
      disabled={disabled}
      pageNumber={2}
      header={{ title: word("box.creation.page.2.title") }}
    >
      <AddressAutofill
        accessToken={Config.MAPBOX_API_KEY}
        onRetrieve={(res) => {
          const foundCountry = countriesOptions.find(
            (countryOption) => countryOption.code === res.features[0].properties.country_code?.toUpperCase(),
          )
          if (foundCountry) formik.setFieldValue("country", foundCountry.code)
        }}
        theme={{
          variables: {
            unitHeader: "28px",
            fontFamily: '"Trenda", sans-serif',
            border: "2px solid #424242",
            borderRadius: "0",
            boxShadow: "0 0 1px 1px rgba(0,0,0,0.1)",
            colorText: theme.mainWhite,
            colorPrimary: theme.primaryColor,
            colorSecondary: theme.secondaryColor,
            colorBackgroundHover: theme.neutralColor300,
            colorBackground: theme.neutralColor100,
          },
          cssText: `
            .Results {
                z-index: 100000;
                border-radius: 20px;
            }
            .ResultsAttribution {
                  display: none;
                }
        `,
        }}
        popoverOptions={{ offset: 0 }}
      >
        <>
          <HUText
            type="label"
            color={theme.inputLabelColor}
            fontStyle="LS"
            className={classNames({ "p-error": isFormFieldInValid("address") })}
            textProps={{ htmlFor: "address" }}
          >
            {word("box.form.address.address.label").toUpperCase()}
            <HUText fontStyle="LL" color={theme.necessaryInput}>
              {" "}
              *
            </HUText>
          </HUText>
          <HUInput
            value={formik.values.address}
            onChange={(event) => {
              formik.setFieldValue("address", event.target.value)
              if (formik.handleChange) formik.handleChange(event, "address")
            }}
            autoComplete="address-line1"
            required={true}
            hasError={isFormFieldInValid("address")}
            name="address"
            className="mb-3 mt-2"
            placeholder={word("box.form.address.address.label")}
            onBlur={(e) => {
              if (formik.handleBlur) formik.handleBlur(e, "address")
            }}
          />
        </>
      </AddressAutofill>
      <FormikInput
        flex
        isInvalid={isFormFieldInValid("additionalAddress")}
        getFieldProps={formik.getFieldProps}
        name="additionalAddress"
        error={getFormErrorMessage("additionalAddress")}
        placeHolder={word("box.form.address.additional_address.placeholder")}
        textProps={{ autoComplete: "address-line2" }}
      />
      <FormikInput
        isRequiredInput
        flex
        label={word("box.form.address.postalCode.label")}
        isInvalid={isFormFieldInValid("postalCode")}
        getFieldProps={formik.getFieldProps}
        name="postalCode"
        error={getFormErrorMessage("postalCode")}
        textProps={{
          autoComplete: "postal-code",
          onBlur: (e) => {
            if (formik.handleBlur) formik.handleBlur(e, "postalCode")
          },
          onChange: (e) => {
            if (formik.handleChange) formik.handleChange(e, "postalCode")
          },
        }}
      />
      <FormikInput
        isRequiredInput
        flex
        label={word("box.form.address.city.label")}
        isInvalid={isFormFieldInValid("city")}
        getFieldProps={formik.getFieldProps}
        name="city"
        error={getFormErrorMessage("city")}
        textProps={{
          autoComplete: "address-level2",
          onBlur: (e) => {
            if (formik.handleBlur) formik.handleBlur(e, "city")
          },
          onChange: (e) => {
            if (formik.handleChange) formik.handleChange(e, "city")
          },
        }}
      />
      <FormikInput
        flex
        isRequiredInput
        type="dropdown"
        label={word("box.form.address.country.label")}
        isInvalid={isFormFieldInValid("country")}
        getFieldProps={formik.getFieldProps}
        setFieldValue={formik.setFieldValue}
        name="country"
        error={getFormErrorMessage("country")}
        placeHolder={word("box.form.address.country.placeholder")}
        dropdownProps={{
          className: "w-full",
          options: countriesOptions.map((countryOption) => countryOption.native),
          value: countriesOptions.find((countryOption) => countryOption.code === formik.values.country)?.native,
          onChange: (e) => {
            const foundCountry = countriesOptions.find((countryOption) => countryOption.native === e.target.value)
            formik.setFieldValue("country", foundCountry?.code)
          },
          onBlur: (e) => {
            if (formik.handleBlur) formik.handleBlur(e, "country")
          },
        }}
      />
    </BoxFormTemplatePage>
  )
}
