import { ApiInterceptor } from "@modules/core/api/interceptors/apiInterceptor"
import { ConfigService } from "@modules/core/services/configService"
import { LoggerType } from "@modules/utils/loggerUtils"
import { AxiosResponse, isAxiosError } from "axios"

export const responseLoggerInterceptor = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRootPath?: (data: any) => any,
): ApiInterceptor<AxiosResponse> => ({
  onRejected: (error) => {
    if (isAxiosError(error)) {
      console.warn(
        LoggerType.ApiError + `request ${error.config?.method?.toUpperCase()} ${error.config?.url} failed with error`,
        error.response?.data?.error?.message ?? error,
      )
    } else {
      console.warn(LoggerType.ApiError + "request failed with error", error)
    }

    return Promise.reject(error)
  },
  onFulfilled: (response) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const startRequestTime = response.config.metadata?.startTime
    console.info(
      LoggerType.ApiSuccess +
        `request ${response.config.method?.toUpperCase()} ${response.config.url} succeeded ${getDuration(
          startRequestTime,
          Date.now(),
        )}`,
    )

    ConfigService.config.HIDE_BODY_REQUEST_LOG !== "true" &&
      console.debug(
        LoggerType.ApiSuccess + "with response",
        JSON.stringify(getRootPath ? getRootPath(response.data) : response.data),
      )
    return response
  },
})

const getDuration = (start: number | undefined, end: number) => {
  if (start) {
    return `(${end - start} ms)`
  }
}
