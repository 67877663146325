import { ChronoIcon } from "@images/svgIcons/chronoIcon"
import { ItemBenchmark } from "@modules/benchmark/benchmarkTypes"
import { displayTotalTime, getTrainingChronoTitle } from "@modules/chrono/chronoUtils"
import { HUText } from "@modules/ui/components/huText"

import styled, { useTheme } from "styled-components"

type ExploreCardFooterChronoProps = {
  item: ItemBenchmark
}

export const ExploreCardFooterChrono: React.FC<ExploreCardFooterChronoProps> = ({ item }) => {
  const theme = useTheme()

  return (
    <ContainerChrono className="flex align-items-center flex-initial">
      <ChronoIcon color={theme.secondaryColor} className="mr-2" />
      {item.chrono && (
        <HUText fontStyle="LM">{`${getTrainingChronoTitle(item.chrono.type)} - ${displayTotalTime(item.chrono)}`}</HUText>
      )}
    </ContainerChrono>
  )
}

const ContainerChrono = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 12px 8px 12px;
  margin-top: 5px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 200;
  border-radius: 44px;
  background: rgba(28, 28, 28, 0.1);
  backdrop-filter: blur(5px);
`
