import { ChevronIcon } from "@images/svgIcons/chevronIcon"
import { LayoutContext } from "@modules/app/context/layoutcontext"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingPoints } from "@modules/ui/components/loading"
import React, { ReactElement, useContext, useEffect, useRef, useState } from "react"
import { useTheme } from "styled-components"

export type ProfileOption = {
  icon?: ReactElement
  withIconBackground?: boolean
  title: string | undefined
  subTitle?: string
  selected?: boolean
  onPress: (e: React.SyntheticEvent) => void
}

type ProfileItemProps = ProfileOption & {
  loading?: boolean
}

export const ProfileItem: React.FC<ProfileItemProps> = ({
  icon,
  withIconBackground,
  title,
  subTitle,
  selected,
  onPress,
  loading,
}) => {
  const theme = useTheme()
  return (
    <button onClick={onPress} className="cursor-pointer hover:surface-hover transition-colors transition-duration-150">
      <HURow align style={{ paddingRight: 2, height: 50 }}>
        <div className="flex justify-content-center" style={{ minWidth: 52 }}>
          <div
            style={{
              borderRadius: 50,
              padding: 2,
              backgroundColor: selected ? theme.primaryColor400 : "transparent",
            }}
          >
            <div style={{ borderRadius: 50, padding: 2, backgroundColor: selected ? theme.background : "transparent" }}>
              <div
                className="flex align-items-center justify-content-center border-circle"
                style={{
                  width: 32,
                  height: 32,
                  backgroundColor: withIconBackground ? theme.neutralColor100 : "transparent",
                }}
              >
                {icon}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <LoadingPoints />
        ) : (
          <HUStack gap={0} flexGrow>
            <HUText fontStyle={!!subTitle ? "LL" : "BM"} numberOfLine={1} textStart>
              {title}
            </HUText>
            {subTitle && (
              <HUText fontStyle="BS" numberOfLine={1} color={theme.neutralColor600} textStart>
                {subTitle}
              </HUText>
            )}
          </HUStack>
        )}
      </HURow>
    </button>
  )
}

export const ProfileCurrentItem: React.FC<ProfileOption> = ({ icon, title, subTitle, onPress }) => {
  const { layoutState } = useContext(LayoutContext)
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()
  const profileRef = useRef<HTMLButtonElement>(null)
  const profileStyle = !layoutState.staticMenuDesktopInactive
    ? { paddingRight: 20, paddingTop: 16, paddingBottom: 16 }
    : { paddingTop: 16, paddingBottom: 16, justifyContent: "center", marginLeft: 36, transition: "margin-left 3s ease" }
  const textContainerStyle = {
    opacity: !layoutState.staticMenuDesktopInactive ? 1 : 0, // 0 to hide, 1 to show
    transition: "opacity 0.5s ease",
  }

  const openProfileOptions = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleClickOutside)

    return () => {
      window.removeEventListener("click", handleClickOutside)
    }
  }, [])

  return (
    <button
      onClick={(e) => {
        onPress(e)
        openProfileOptions()
      }}
      className="cursor-pointer hover:surface-hover transition-colors transition-duration-150 w-full sb-overflow-hidden"
      style={{ height: 68 }}
      ref={profileRef}
    >
      <HURow align style={profileStyle}>
        <div className="flex justify-content-center" style={{ minWidth: 52 }}>
          {icon}
        </div>
        <div className="flex align-items-center justify-content-between w-full" style={textContainerStyle}>
          <HUStack gap={0} flexGrow>
            <HUText fontStyle="LL" numberOfLine={1} textStart>
              {title}
            </HUText>
            <HUText fontStyle="BS" numberOfLine={1} color={theme.neutralColor600} textStart>
              {subTitle}
            </HUText>
          </HUStack>
          <div style={{ marginLeft: 10 }}>
            <ChevronIcon
              color={theme.neutralColor900}
              width={10}
              height={10}
              transform={isOpen ? "rotate(180)" : "rotate(0)"}
            />
          </div>
        </div>
      </HURow>
    </button>
  )
}
