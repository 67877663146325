import { ApiClient } from "@modules/core/api/client/apiClient"
import { ConfigService } from "@modules/core/services/configService"
import { Exercise, ExerciseDashboard } from "@modules/exercises/exerciseTypes"
import {
  ExerciseDashboardDto,
  ExerciseDto,
  ExerciseScoreInputDto,
  convertExerciseDashboardFromDto,
  convertExerciseFromDto,
} from "@modules/exercises/services/exercisesDto"

export class ExerciseApi {
  private programBaseUrl = "/programs"
  private exerciseBaseUrl = "/exercises"

  private buildExerciseUrl = (programId: string, sessionId: string, exerciseId: string) =>
    `${ConfigService.config.BASE_URL.replace("/web", "")}/mobile${this.programBaseUrl}/${programId}/sessions/${sessionId}/exercises/${exerciseId}`

  constructor(private api: ApiClient) {}

  async getProgramExercise(id: string): Promise<Exercise> {
    const resultDto = await this.api.get<ExerciseDto>(`${this.exerciseBaseUrl}/${id}`)
    return convertExerciseFromDto(resultDto.data)
  }

  async getBoxSlotExercise(id: string): Promise<Exercise> {
    const resultDto = await this.api.get<ExerciseDto>(`${this.exerciseBaseUrl}/${id}`)
    return convertExerciseFromDto(resultDto.data)
  }

  async markExerciseAsComplete(programId: string, sessionId: string, exerciseId: string): Promise<void> {
    await this.api.put(`${this.buildExerciseUrl(programId, sessionId, exerciseId)}/complete`)
  }

  async addScoreToExercice(
    programId: string,
    sessionId: string,
    exerciseId: string,
    score: ExerciseScoreInputDto,
  ): Promise<void> {
    await this.api.put(`${this.buildExerciseUrl(programId, sessionId, exerciseId)}/score`, score)
  }

  async getProgramExerciseDashboard(
    programId: string,
    sessionId: string,
    exerciseId: string,
  ): Promise<ExerciseDashboard> {
    const resultDto = await this.api.get<ExerciseDashboardDto>(
      `${this.programBaseUrl}/${programId}/sessions/${sessionId}/exercises/${exerciseId}/dashboard`,
      {
        params: { numberOfTopUsers: 5, numberOfLastParticipants: 5 },
      },
    )
    return convertExerciseDashboardFromDto(resultDto.data)
  }
}
