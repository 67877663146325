import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { UserTransaction } from "@modules/finances/types/transactionType"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { PromoCodeType } from "@modules/promoCode/services/promoCodeDto"
import { DataTable } from "@modules/ui/components/dataTables/datatable"
import { LoadingPage } from "@modules/ui/components/loadingPage"
import { shortenText } from "@modules/utils/stringUtils"
import dayjs from "dayjs"
import { Column } from "primereact/column"
import { ColumnGroup } from "primereact/columngroup"
import { Row } from "primereact/row"
import styled from "styled-components"

type InvoiceDetailsTableProps = { transactionDetails: UserTransaction }

export const InvoiceDetailsTable: React.FC<InvoiceDetailsTableProps> = ({ transactionDetails }) => {
  const language = useLanguage()

  if (!transactionDetails.invoice) return <LoadingPage />

  const footerGroup = (
    <ColumnGroup>
      {transactionDetails.invoice.promoCode && (
        <Row>
          <Column
            className="font-normal p-1 pt-4 text-right pr-4"
            footer={word("promoCode.short", { code: shortenText(transactionDetails.invoice.promoCode.name, 10) })}
            colSpan={3}
          />
          <Column
            className="font-normal p-1 pt-4 text-right"
            footer={formatCurrency(
              transactionDetails.invoice.promoCode.type === PromoCodeType.DIRECT
                ? -transactionDetails.invoice.promoCode.amount
                : transactionDetails.invoice.promoCode.amount < 1
                  ? transactionDetails.invoice.amountPaid -
                    transactionDetails.invoice.amountPaid / (1 - transactionDetails.invoice.promoCode.amount)
                  : -transactionDetails.invoice.amountPaid,
              transactionDetails.invoice.currency,
              language,
            )}
          />
        </Row>
      )}
      <Row>
        <Column
          className={
            "font-normal p-1 text-right pr-4" +
            (transactionDetails.invoice.promoCode ? " border-top-none pt-2" : " pt-4")
          }
          footer={word("finances.array.invoice.excludingVATTotal")}
          colSpan={3}
        />
        <Column
          className={
            "font-normal p-1 text-right" + (transactionDetails.invoice.promoCode ? " border-top-none pt-2" : " pt-4")
          }
          footer={formatCurrency(
            (transactionDetails.invoice.amountPaid || 0) - (transactionDetails.invoice.totalVAT || 0),
            transactionDetails.invoice.currency,
            language,
          )}
        />
      </Row>
      <Row>
        <Column
          className="border-top-none font-normal p-1 pt-2 text-right pr-4"
          footer={word("finances.array.invoice.VATTotal")}
          colSpan={3}
        />
        <Column
          className="border-top-none font-normal p-1 pt-2 text-right"
          footer={formatCurrency(
            transactionDetails.invoice.totalVAT || 0,
            transactionDetails.invoice.currency,
            language,
          )}
        />
      </Row>
      <Row>
        <Column
          className="border-top-none p-1 pt-2 text-right pr-4"
          footer={word("finances.array.invoice.includingVATTotal")}
          colSpan={3}
        />
        <Column
          className="border-top-none p-1 pt-2 text-right"
          footer={formatCurrency(
            transactionDetails.invoice.amountPaid || 0,
            transactionDetails.invoice.currency,
            language,
          )}
        />
      </Row>
    </ColumnGroup>
  )

  return (
    <>
      <StyledInvoiceDetailsTable
        value={transactionDetails.invoice.lines}
        dataKey="description"
        emptyMessage={word("global.no_result")}
        footerColumnGroup={footerGroup}
      >
        <Column
          header={word("box.form.description.label")}
          body={(data) =>
            `${data.description} (${dayjs(data.periodStart).format("L")} - ${dayjs(data.periodEnd).format("L")})`
          }
        />
        <Column
          header={word("finances.array.invoice.net_price")}
          body={(data) => formatCurrency(data.amountExcludingTax, data.currency, language)}
        />
        <Column
          header={word("finances.array.invoice.taxe_price_percent")}
          field="VATPercent"
          body={(data) => data.VATPercent}
        />
        <Column
          header={word("finances.array.invoice.total")}
          body={(data) => formatCurrency(data.amount, data.currency, language)}
        />
      </StyledInvoiceDetailsTable>
    </>
  )
}

const StyledInvoiceDetailsTable = styled(DataTable)`
  .p-datatable-thead > tr > th {
    background-color: transparent;
    color: ${(props) => props.theme.textPrimary};
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    padding: 10px 5px;
    border-bottom: 1px solid ${(props) => props.theme.neutralColor300};
  }

  .p-datatable-tbody > tr {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    line-height: 15.34px;
    color: ${(props) => props.theme.neutralColor600};
  }
  .p-datatable-tbody > tr > td {
    padding: 10px 5px !important;
  }
  .p-datatable-tfoot > tr > td {
    padding: 10px 5px;
    background-color: transparent;
    color: ${(props) => props.theme.textPrimary};
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    border-top: 1px solid ${(props) => props.theme.neutralColor300};
    border-bottom: none;
  }
`
