import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const UnionSmallIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="51" height="58" viewBox="0 0 51 58" fill="none">
    <g filter="url(#filter0_b_8345_119977)">
      <path
        d="M14.6722 22.4745L22.2433 6.11122C23.9809 2.35589 27.4326 0 31.1973 0H49.2366C50.1834 0 50.7929 1.12903 50.3559 2.07347L40.9165 22.4745H14.6722Z"
        fill={props.color}
      />
      <path
        d="M0.6447 55.0408L10.0802 34.6482H36.3245L28.7534 51.0115C27.0158 54.7668 23.5641 57.1227 19.7994 57.1227H1.76847C0.817856 57.1227 0.205952 55.9891 0.6447 55.0408Z"
        fill={props.color}
      />
    </g>
    <defs>
      <filter
        id="filter0_b_8345_119977"
        x="-0.85717"
        y="-1.35717"
        width="52.7143"
        height="59.837"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.678585" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8345_119977" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8345_119977" result="shape" />
      </filter>
    </defs>
  </svg>
)
