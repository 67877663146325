import { FormikInput } from "@modules/ui/components/formikInput"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { FormEvent } from "primereact/ts-helpers"
import React, { useMemo } from "react"
import styled from "styled-components"

export type MonthPickerChangeEvent = {} & FormEvent<Date>

type Props = {
  onDatePick: (e: MonthPickerChangeEvent) => void
  defaultDate?: string
  startDate?: string | null
} & React.HTMLAttributes<HTMLDivElement>
export const MonthPicker: React.FC<Props> = ({ onDatePick, defaultDate, startDate, ...props }) => {
  const formik = useFormik<{ date: Date }>({
    initialValues: { date: new Date(defaultDate ?? dayjs().toDate()) },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const maxDate = useMemo(() => new Date(dayjs().endOf("month").toDate()), [])
  const minDate = useMemo(() => (startDate ? new Date(startDate) : undefined), [startDate])

  return (
    <Container {...props}>
      <FormikInput
        flex
        formikStyle={{ flexGrow: 1 }}
        getFieldProps={formik.getFieldProps}
        isInvalid={false}
        name="date"
        type="calendar"
        calendarProps={{
          view: "month",
          dateFormat: "MM/YYYY",
          readOnlyInput: true,
          showButtonBar: false,
          showIcon: true,
          maxDate,
          minDate,
          onChange: (e) => onDatePick(e as unknown as MonthPickerChangeEvent),
        }}
        isRequiredInput
      />
    </Container>
  )
}

const Container = styled.div`
  .field {
    margin-bottom: 0;
  }
`
