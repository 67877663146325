import { BoxProgrammationLight } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxSlotParticipation } from "@modules/boxSlot/boxSlotTypes"

export type Weekday = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY"
export const Weekdays = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]

export const getWeekdayValue = (weekDay: Weekday) => {
  switch (weekDay) {
    case "SUNDAY":
      return 0
    case "MONDAY":
      return 1
    case "TUESDAY":
      return 2
    case "WEDNESDAY":
      return 3
    case "THURSDAY":
      return 4
    case "FRIDAY":
      return 5
    case "SATURDAY":
      return 6
  }
}

type ItemBoxSlotTemplateBase = {
  id?: string
  weekDay: string
  startTime: string
  endTime: string
  participantLimit: number | null
  location?: string
  participation?: BoxSlotParticipation
  program: BoxProgrammationLight
}

export type ItemBoxSlotTemplate = {
  id: string
} & ItemBoxSlotTemplateBase

export type BoxSlotTemplate = {
  id: string
  boxProgrammationId: string
} & ItemBoxSlotTemplateBase

export type BoxSlotTemplateDraft = Omit<ItemBoxSlotTemplateBase, "program">

export type BoxSlotsTemplateWeekPartial = {
  week: {
    MONDAY: ItemBoxSlotTemplate[]
    TUESDAY: ItemBoxSlotTemplate[]
    WEDNESDAY: ItemBoxSlotTemplate[]
    THURSDAY: ItemBoxSlotTemplate[]
    FRIDAY: ItemBoxSlotTemplate[]
    SATURDAY: ItemBoxSlotTemplate[]
    SUNDAY: ItemBoxSlotTemplate[]
  }
}

export type BoxSlotsTemplateWeek = {
  id: string
} & BoxSlotsTemplateWeekPartial
