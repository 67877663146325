import { BoxRole, StaffMember } from "@modules/boxStaff/boxStaffTypes"
import { StaffMemberDto, convertStaffMemberFromDto } from "@modules/boxStaff/services/boxStaffDto"
import { ApiClient } from "@modules/core/api/client/apiClient"

export class BoxStaffApi {
  private baseUrl = "/box/staff"

  constructor(private api: ApiClient) {}

  async getMembers(): Promise<StaffMember[]> {
    const resultDto = await this.api.get<StaffMemberDto[]>(`${this.baseUrl}`)

    return resultDto.data.map((dto) => convertStaffMemberFromDto(dto))
  }

  async addMember(email: string, role: BoxRole): Promise<void> {
    await this.api.post(`${this.baseUrl}`, {
      userEmail: email,
      role,
    })
  }
}
