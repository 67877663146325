import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BoxProgrammationVisibilityVector: React.FC<IconSvgProps> = ({ color, ...props }) => (
  <svg width="52" height="58" viewBox="0 0 52 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.5522 46.7537C36.1997 50.2182 29.7879 53.5268 26.7116 54.987C26.2567 55.2029 25.7433 55.2029 25.2884 54.987C22.2121 53.5267 15.8002 50.218 12.4477 46.7534C8.76444 42.947 7.20515 40.2969 5.08861 35.3173C3.46803 31.5046 2.89762 26.3775 2.86358 21.3561C2.82986 16.3808 3.32153 11.7226 3.68891 8.96744C3.78477 8.24855 4.32866 7.63645 5.11565 7.45189L24.8583 2.82198C25.6092 2.64589 26.3907 2.6459 27.1416 2.822L46.8843 7.45211C47.6713 7.63667 48.2152 8.24878 48.311 8.96766C48.6784 11.7228 49.1701 16.381 49.1364 21.3563C49.1023 26.3777 48.5319 31.5048 46.9113 35.3176C44.7948 40.2972 43.2355 42.9472 39.5522 46.7537Z"
      fill="#1C1C1C"
      stroke={color}
      strokeWidth="4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6859_362825"
        x1="26"
        y1="0.5"
        x2="26"
        y2="57.5136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color} />
        <stop offset="1" stopColor={color} />
      </linearGradient>
    </defs>
  </svg>
)
