import LogoCrossfit from "@images/crossfit/logoCFWhite.svg"
import { useB2BPrograms } from "@modules/boxB2BProgram/hooks/useB2BPrograms"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { LightProgram } from "@modules/programs/programTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { ErrorCard } from "@modules/ui/components/error/errorCard"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useState } from "react"
import styled, { useTheme } from "styled-components"

export const BoxProgrammationImportProgramSelection: React.FC<{
  programmationId: string
  startDate: string
  endDate: string
}> = ({ programmationId, startDate, endDate }) => {
  const [selectedProgramId, setSelectedProgramId] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { boxB2BProgramService, boxSlotService } = useServicesContext()
  const { hideSidebar } = useSidebar()
  const { value: programs, loading } = useB2BPrograms()
  const toast = useToast()

  const onValidate = async () => {
    if (selectedProgramId) {
      try {
        setIsSubmitting(true)
        const response = await boxB2BProgramService.duplicateB2BProgramSessions(
          programmationId,
          selectedProgramId,
          startDate,
          endDate,
        )

        /*if (response.status === 204) {
          toast.show({
            severity: "warn",
            summary: word("box.programmation.activity.importTemplateForm.error.noContent"),
          })
        } else */ if (response.status === 200 || response.status === 204) {
          toast.show({
            severity: "success",
            summary: word("box.programmation.activity.importTemplateForm.success"),
          })
          hideSidebar()
          boxSlotService.updateScheduledSessions(programmationId, startDate.toString(), endDate.toString())
        }
      } catch (e: any) {
        toast.show({
          severity: "error",
          summary: "Error",
          detail: e.message,
        })
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <HUStack className="mt-5 gap-10 flex-grow-1">
      <HUStack gap={10}>
        <HUText fontStyle="TL">{word("box.programmation.activity.importTemplateForm.title")}</HUText>
        <HUText fontStyle="BM">{word("box.programmation.activity.importTemplateForm.explanation")}</HUText>
      </HUStack>

      {programs ? (
        <HUStack className="mt-4 align-items-center justify-content-center flex-grow-1">
          <ProgramItemTemplate
            key={programs.cap.id}
            program={programs.cap}
            isSelected={selectedProgramId ? selectedProgramId === programs.cap.id : false}
            onSelect={() => setSelectedProgramId(programs.cap.id)}
            isCAP
          />
          <HUText fontStyle="BM" className="capitalize">
            {word("global.or")}
          </HUText>
          {programs.subscribedPrograms.map((program) => (
            <ProgramItemTemplate
              key={program.id}
              program={program}
              isSelected={selectedProgramId ? selectedProgramId === program.id : false}
              onSelect={() => setSelectedProgramId(program.id)}
            />
          ))}
        </HUStack>
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <ErrorCard />
      )}

      <HURow className="mt-auto gap-6">
        <HUButton
          type="Default"
          colorType="Quaternary"
          size="M"
          text={word("global.cancel")}
          className="flex-1"
          onClick={() => hideSidebar()}
          disabled={isSubmitting}
        />
        <HUButton
          type="Default"
          colorType="Primary"
          size="M"
          text={word("box.programmation.activity.importTemplateForm.import")}
          className="flex-1"
          onClick={onValidate}
          disabled={!selectedProgramId || isSubmitting}
          loading={isSubmitting}
        />
      </HURow>
    </HUStack>
  )
}

const ProgramItemTemplate: React.FC<{
  program: LightProgram
  isSelected: boolean
  onSelect: () => void
  isCAP?: boolean
}> = ({ program, isSelected, onSelect, isCAP = false }) => {
  const theme = useTheme()

  return (
    <HUStack
      className={
        "border-1 border-solid border-700 border-round py-4 align-items-center cursor-pointer w-full bg-neutral-900 " +
        (isSelected ? "border-primary " : "hover:border-500 ") +
        (isCAP ? "py-5" : "")
      }
      onClick={onSelect}
    >
      <HUText fontStyle="BM">{`${word("box.programmation.activity.importTemplateForm.choose")} ${program.name}`}</HUText>
      {isCAP ? (
        <img src={LogoCrossfit} />
      ) : (
        <>
          <ContainerAvatarProfile>
            <ProfileAvatar
              profile={{ avatar: program.owner.avatar, username: program.owner.username || "" }}
              size={46}
            />

            <div className="flex flex-column">
              <HUText fontStyle="LM" className="mb-1" color={theme.neutralColor900}>
                {`${program.owner.firstname} ${program.owner.lastname}`}
              </HUText>
            </div>
          </ContainerAvatarProfile>
          <HUText fontStyle="BS" color={theme.neutralColor700}>
            {word("box.programmation.activity.importTemplateForm.incomingFeatures")}
          </HUText>
        </>
      )}
    </HUStack>
  )
}

const ContainerAvatarProfile = styled.div.attrs({
  className: "rounded-full bg-black gap-2 flex align-items-center",
})`
  padding: 5px 14px 5px 5px;
`
