import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BoxOfferDropInIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="51" viewBox="0 0 50 51" fill="none" {...props}>
    <path
      d="M17.564 19.6045L24.2519 38.6079M24.2519 38.6079L33.4682 20.0123M24.2519 38.6079L29.798 19.6045M24.2519 38.6079L20.337 19.6045"
      stroke="white"
    />
    <mask
      id="mask0_7983_96336"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="19"
      y="5"
      width="12"
      height="17"
    >
      <path
        d="M20.5171 20.0236C18.5264 14.3259 21.6111 6.56908 25.1722 5.6875C28.6611 6.82442 31.5978 14.4652 29.58 20.0575C29.58 20.0612 29.58 20.0649 29.58 20.0686C28.3411 21.1981 26.7754 21.8725 25.073 21.8725C23.3481 21.8725 21.7636 21.1802 20.5171 20.0236Z"
        fill="#3B3184"
      />
    </mask>
    <g mask="url(#mask0_7983_96336)">
      <path
        d="M20.5171 20.0236C18.5264 14.3259 21.6111 6.56908 25.1722 5.6875C28.6611 6.82442 31.5978 14.4652 29.58 20.0575C29.58 20.0612 29.58 20.0649 29.58 20.0686C28.3411 21.1981 26.7754 21.8725 25.073 21.8725C23.3481 21.8725 21.7636 21.1802 20.5171 20.0236Z"
        fill="#3B3184"
      />
      <ellipse cx="22.149" cy="13.7798" rx="6.49811" ry="9.27057" fill="#6450F7" />
    </g>
    <mask
      id="mask1_7983_96336"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="14"
      y="5"
      width="12"
      height="16"
    >
      <path
        d="M14.4487 18.9921C14.4465 18.9001 14.4453 18.8079 14.4453 18.7154C14.4453 11.5081 18.7835 5.57881 25.8684 5.57881C25.8151 5.57835 25.9216 5.57881 25.8684 5.57881C22.0839 5.66515 18.4474 13.9309 20.4884 19.9414C19.7631 20.3566 18.9424 20.5907 18.0732 20.5907C16.6741 20.5907 15.4007 19.9843 14.4487 18.9921Z"
        fill="#3B3184"
      />
    </mask>
    <g mask="url(#mask1_7983_96336)">
      <path
        d="M14.4487 18.9921C14.4465 18.9001 14.4453 18.8079 14.4453 18.7154C14.4453 11.5081 18.7835 5.57881 25.8684 5.57881C25.8151 5.57835 25.9216 5.57881 25.8684 5.57881C22.0839 5.66515 18.4474 13.9309 20.4884 19.9414C19.7631 20.3566 18.9424 20.5907 18.0732 20.5907C16.6741 20.5907 15.4007 19.9843 14.4487 18.9921Z"
        fill="#6450F7"
      />
      <ellipse
        cx="24.3841"
        cy="16.4009"
        rx="6.26436"
        ry="10.7674"
        transform="rotate(13.8054 24.3841 16.4009)"
        fill="#3B3184"
      />
    </g>
    <mask
      id="mask2_7983_96336"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="24"
      y="5"
      width="12"
      height="16"
    >
      <path
        d="M35.6166 18.9912C35.6189 18.8992 35.62 18.807 35.62 18.7145C35.62 11.5072 31.5126 5.63936 24.4277 5.63936C24.3745 5.63936 24.481 5.63891 24.4277 5.63936C28.2122 5.72571 31.6179 13.93 29.577 19.9405C30.3022 20.3557 31.123 20.5898 31.9922 20.5898C33.3912 20.5898 34.6646 19.9834 35.6166 18.9912Z"
        fill="#4B3DB2"
      />
    </mask>
    <g mask="url(#mask2_7983_96336)">
      <path
        d="M35.6166 18.9912C35.6189 18.8992 35.62 18.807 35.62 18.7145C35.62 11.5072 31.5126 5.63936 24.4277 5.63936C24.3745 5.63936 24.481 5.63891 24.4277 5.63936C28.2122 5.72571 31.6179 13.93 29.577 19.9405C30.3022 20.3557 31.123 20.5898 31.9922 20.5898C33.3912 20.5898 34.6646 19.9834 35.6166 18.9912Z"
        fill="#3B3184"
      />
      <ellipse cx="26.6779" cy="15.6709" rx="7.1752" ry="9.98335" fill="#6450F7" />
    </g>
    <mask
      id="mask3_7983_96336"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="5"
      width="16"
      height="15"
    >
      <path
        d="M9.3818 17.2757C9.3815 17.2459 9.38135 17.2161 9.38135 17.1862C9.38135 10.857 16.2617 5.74557 24.6776 5.6372C18.3503 6.27986 14.6321 11.873 14.6321 18.6534C14.6321 18.6604 14.6321 18.6673 14.6322 18.6742C14.0869 18.9316 13.4899 19.0736 12.864 19.0736C11.4794 19.0736 10.2357 18.3788 9.3818 17.2757Z"
        fill="#A89DFB"
      />
    </mask>
    <g mask="url(#mask3_7983_96336)">
      <path
        d="M9.3818 17.2757C9.3815 17.2459 9.38135 17.2161 9.38135 17.1862C9.38135 10.857 16.2617 5.74557 24.6776 5.6372C18.3503 6.27986 14.6321 11.873 14.6321 18.6534C14.6321 18.6604 14.6321 18.6673 14.6322 18.6742C14.0869 18.9316 13.4899 19.0736 12.864 19.0736C11.4794 19.0736 10.2357 18.3788 9.3818 17.2757Z"
        fill="#6450F7"
      />
      <ellipse
        cx="21.6351"
        cy="15.3112"
        rx="7.1752"
        ry="9.98335"
        transform="rotate(24.5878 21.6351 15.3112)"
        fill="#3B3184"
      />
    </g>
    <mask
      id="mask4_7983_96336"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="25"
      y="5"
      width="16"
      height="15"
    >
      <path
        d="M40.6182 17.2754C40.6185 17.2456 40.6186 17.2158 40.6186 17.1859C40.6186 10.8567 33.453 5.73239 25.0371 5.62402C31.3644 6.26668 35.3678 11.8727 35.3678 18.6532C35.3678 18.6601 35.3678 18.667 35.3678 18.674C35.9131 18.9314 36.5101 19.0733 37.1359 19.0733C38.5206 19.0733 39.7643 18.3785 40.6182 17.2754Z"
        fill="#3B3184"
      />
    </mask>
    <g mask="url(#mask4_7983_96336)">
      <path
        d="M40.6182 17.2754C40.6185 17.2456 40.6186 17.2158 40.6186 17.1859C40.6186 10.8567 33.453 5.73239 25.0371 5.62402C31.3644 6.26668 35.3678 11.8727 35.3678 18.6532C35.3678 18.6601 35.3678 18.667 35.3678 18.674C35.9131 18.9314 36.5101 19.0733 37.1359 19.0733C38.5206 19.0733 39.7643 18.3785 40.6182 17.2754Z"
        fill="#3B3184"
      />
    </g>
    <mask
      id="mask5_7983_96336"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="21"
      y="39"
      width="7"
      height="6"
    >
      <circle cx="24.4124" cy="41.9114" r="2.77274" fill="#282828" />
    </mask>
    <g mask="url(#mask5_7983_96336)">
      <circle cx="24.4124" cy="41.9114" r="2.77274" fill="#454643" />
      <circle cx="23.9103" cy="41.4093" r="1.81754" fill="#6D6E6B" />
    </g>
  </svg>
)
