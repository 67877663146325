import { useChatClient } from "@modules/chat/hooks/useChatClient"
import { DEFAULT_PAGINATION_LIMIT } from "@modules/chat/utils/chatConstants"
import _ from "lodash"
import { useRef, useState } from "react"
import type { DefaultGenerics, UserFilters, UserResponse } from "stream-chat"

export type PaginatedUsers = {
  clearText: () => void
  loading: boolean
  loadMore: () => void
  loadingMore: boolean
  onChangeSearchText: (newText: string) => void
  setSelectedUserIds: (arg0: string[]) => void
  setSelectedUsers: (arg0: UserResponse<DefaultGenerics>[]) => void
  removeUser: (index: number) => void
  reset: () => void
  results: UserResponse<DefaultGenerics>[]
  searchText: string
  selectedUserIds: string[]
  selectedUsers: UserResponse<DefaultGenerics>[]
  toggleUser: (user: UserResponse<DefaultGenerics>) => void
}

export const usePaginatedUsers = (): PaginatedUsers => {
  const { chatClient } = useChatClient()

  const [initialResults, setInitialResults] = useState<UserResponse<DefaultGenerics>[] | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [results, setResults] = useState<UserResponse<DefaultGenerics>[]>([])
  const [searchText, setSearchText] = useState("")
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<UserResponse<DefaultGenerics>[]>([])

  const searchTextRef = useRef("")
  const hasMoreResults = useRef(true)
  const offset = useRef(0)
  const queryInProgress = useRef(false)

  const clearText = () => {
    setLoading(false)
    setLoadingMore(false)
    setResults(initialResults || [])
    setSearchText("")
    searchTextRef.current = ""
    hasMoreResults.current = true
    offset.current = 0
    queryInProgress.current = false
  }

  const reset = () => {
    clearText()
    setSelectedUserIds([])
    setSelectedUsers([])
  }

  const addUser = (user: UserResponse<DefaultGenerics>) => {
    setSelectedUsers([...selectedUsers, user])
    setSelectedUserIds((prevSelectedUserIds) => [...prevSelectedUserIds, user.id])
  }

  const removeUser = (index: number) => {
    if (index < 0) {
      return
    }

    setSelectedUserIds((prevSelectedUserIds) => {
      const newSelectedUserIds = prevSelectedUserIds.slice()
      newSelectedUserIds.splice(index, 1)
      return newSelectedUserIds
    })

    setSelectedUsers((prevSelectedUsers) => {
      const newSelectedUsers = prevSelectedUsers.slice()
      newSelectedUsers.splice(index, 1)
      return newSelectedUsers
    })
  }

  const toggleUser = (user: UserResponse<DefaultGenerics>) => {
    if (!user.id) {
      return
    }

    const existingIndex = selectedUserIds.indexOf(user.id)

    if (existingIndex > -1) {
      removeUser(existingIndex)
    } else {
      addUser(user)
    }
  }

  const onChangeSearchText = (newText: string) => {
    setSearchText(newText)
    searchTextRef.current = newText
    if (!newText) {
      setResults(initialResults || [])
      setLoading(false)
    } else {
      fetchUsers(newText)
    }
  }

  const fetchUsers = async (newQuery = "") => {
    if (queryInProgress.current || !chatClient?.userID) {
      setLoading(false)
      return
    }

    setLoading(true)

    try {
      queryInProgress.current = true

      const filter: UserFilters = {
        id: {
          $nin: [chatClient.userID],
        },
        role: "user",
      }

      if (newQuery) {
        filter.name = { $autocomplete: newQuery }
        offset.current = 0
        hasMoreResults.current = true
      } else if (searchTextRef.current) {
        filter.name = { $autocomplete: searchTextRef.current }
      }

      if (!hasMoreResults.current) {
        queryInProgress.current = false
        setLoading(false)
        return
      }

      const res = await chatClient.queryUsers(
        filter,
        { name: 1 },
        {
          limit: DEFAULT_PAGINATION_LIMIT,
          offset: offset.current,
          presence: true,
        },
      )

      if (newQuery && searchTextRef.current !== newQuery) {
        queryInProgress.current = false
        await fetchUsers()
        return
      }

      const newUsers = res.users ?? []
      if (!searchTextRef.current && offset.current === 0 && (!initialResults || initialResults.length === 0)) {
        setInitialResults(newUsers)
      }

      if (offset.current === 0) {
        setResults(newUsers)
      } else {
        setResults(_.uniqBy(_.concat(results, newUsers), (it) => it.id))
      }
      offset.current = offset.current + newUsers.length

      if (newUsers.length < DEFAULT_PAGINATION_LIMIT) {
        hasMoreResults.current = false
      }
    } catch (e) {
      // do nothing;
    }
    queryInProgress.current = false
    setLoading(false)
  }

  const loadMore = async () => {
    setLoadingMore(true)
    await fetchUsers()
    setLoadingMore(false)
  }

  return {
    clearText: () => {
      setLoading(false)
      setLoadingMore(false)
      setResults(initialResults || [])
      setSearchText("")
      searchTextRef.current = ""
      hasMoreResults.current = true
      offset.current = 0
      queryInProgress.current = false
    },
    loading,
    loadMore,
    loadingMore,
    onChangeSearchText,
    setSelectedUserIds,
    setSelectedUsers,
    removeUser,
    reset,
    results,
    searchText,
    selectedUserIds,
    selectedUsers,
    toggleUser,
  }
}
