import type { CalendarContextProps } from "@modules/app/types/types"
import { EventType } from "@modules/calendar/calendarTypes"
import { word } from "@modules/core/utils/i18n"
import { weekdayDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { capitalize } from "lodash"
import React, { createContext, useEffect, useState } from "react"

export const CalendarContext = createContext({} as CalendarContextProps)

export type AvailableViews = "dayGridWeek"

type CalendarProviderProps = {
  isUndated?: boolean
  isWeekTemplate?: boolean
  hasHours?: boolean
  maxDayIndex?: number
  currentDayIndex?: number
  initialDate: dayjs.Dayjs | undefined
  onDateSet?: (startDate: dayjs.Dayjs | number, endDate: dayjs.Dayjs | number) => void
  refreshEvents?: (startDate: dayjs.Dayjs | number, endDate: dayjs.Dayjs | number) => void
  defaultDisplayDetailedEvents: boolean
  defaultView: AvailableViews
  children: React.ReactNode
}

export const CalendarProvider: React.FC<CalendarProviderProps> = ({
  isUndated = true,
  isWeekTemplate = false,
  hasHours = false,
  initialDate,
  maxDayIndex,
  currentDayIndex,
  onDateSet,
  refreshEvents,
  defaultDisplayDetailedEvents = false,
  defaultView,
  children,
}) => {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | number | undefined>()
  const [displayDetailedEvents, setDisplayDetailedEvents] = useState<boolean>(defaultDisplayDetailedEvents)
  const [currentWeekTitle, setCurrentWeekTitle] = useState<string>("")
  const [draggingEvent, setDraggingEvent] = useState<EventType>()
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [currentWeekdays, setCurrentWeekdays] = useState<
    | {
        dayIndex?: number
        weekdayIndex: number
        date?: dayjs.Dayjs
        label: string
        isToday: boolean
      }[]
    | undefined
  >()
  const [currentView, setCurrentView] = useState<AvailableViews>(defaultView)

  const refreshCalendarEvents = () => {
    if (refreshEvents) {
      if (isUndated && typeof selectedDate === "number") {
        refreshEvents(selectedDate, selectedDate + 7)
      } else {
        const firstDayOfWeek = dayjs(selectedDate).weekday(0)
        const lastDayOfWeek = dayjs(selectedDate).weekday(6)
        refreshEvents(firstDayOfWeek, lastDayOfWeek)
      }
    }
  }

  useEffect(() => {
    setSelectedDate(initialDate || (isUndated ? 0 : dayjs()))
  }, [initialDate])

  useEffect(() => {
    if (isWeekTemplate) {
      setCurrentWeekTitle(`${capitalize(word("global.week.label", { count: 1 }))}`)

      const weekdays = []
      for (let i = 0; i < 7; i++) {
        weekdays.push({
          dayIndex: i,
          weekdayIndex: i,
          isToday: currentDayIndex === i,
          label: `${dayjs(selectedDate).weekday(i).format("dddd")}`,
        })
      }
      setCurrentWeekdays(weekdays)
    } else if (isUndated && typeof selectedDate === "number") {
      onDateSet?.(selectedDate, selectedDate + 7)
      setCurrentWeekTitle(`${capitalize(word("global.week.label", { count: 1 }))} ${selectedDate / 7 + 1}`)

      const weekdays = []
      for (let i = 0; i < 7; i++) {
        weekdays.push({
          dayIndex: selectedDate + i,
          weekdayIndex: i,
          isToday: currentDayIndex === selectedDate + i,
          label: `${capitalize(word("global.day.label", { count: 1 }))} ${selectedDate + i + 1}`,
        })
      }
      setCurrentWeekdays(weekdays)
    } else if (selectedDate) {
      const firstDayOfWeek = dayjs(selectedDate).weekday(0)
      const lastDayOfWeek = dayjs(selectedDate).weekday(6)

      onDateSet?.(firstDayOfWeek, lastDayOfWeek)

      if (firstDayOfWeek.isSame(lastDayOfWeek, "month"))
        setCurrentWeekTitle(
          `${dayjs(selectedDate).weekday(0).format("DD")} - ${dayjs(selectedDate).weekday(6).format("DD MMM YYYY")}`,
        )
      else
        setCurrentWeekTitle(
          `${dayjs(selectedDate).weekday(0).format("DD MMM")} - ${dayjs(selectedDate).weekday(6).format("DD MMM YYYY")}`,
        )

      const weekdays = []
      for (let i = 0; i < 7; i++) {
        weekdays.push({
          weekdayIndex: i,
          date: dayjs(selectedDate).weekday(i),
          isToday: dayjs(selectedDate).weekday(i).isSame(dayjs(), "day"),
          label: dayjs(selectedDate).weekday(i).format(weekdayDateFormat),
        })
      }
      setCurrentWeekdays(weekdays)
    }
  }, [selectedDate?.valueOf(), onDateSet, dayjs.locale()])

  const value = {
    selectedDate,
    setSelectedDate,
    currentWeekTitle,
    displayDetailedEvents,
    setDisplayDetailedEvents,
    currentWeekdays,
    currentView,
    setCurrentView,
    refreshCalendarEvents,
    isUndated,
    isWeekTemplate,
    hasHours,
    maxDayIndex,
    draggingEvent,
    setDraggingEvent,
    isDragging,
    setIsDragging,
  }

  return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>
}
