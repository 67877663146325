import { useServicesContext } from "@modules/core/services/services.context"
import { useObservable } from "micro-observables"

export const useChatClient = () => {
  const { chatService } = useServicesContext()
  const chatClient = useObservable(chatService.client)
  return { chatClient }
}

export const useChatClientIsConnected = () => {
  const { chatService } = useServicesContext()

  const chatClientConnectionId = useObservable(chatService.chatConnectionId)

  return !!chatClientConnectionId
}

export const useChatClientLastMessage = () => {
  const { chatService } = useServicesContext()

  const lastMessage = useObservable(chatService.lastMessage)

  return { lastMessage }
}
