import { useBoxProgrammation } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { BoxSlotFormView } from "@modules/boxSlot/components/boxSlotFormView"
import { BoxSlotRegistrationInfo } from "@modules/boxSlot/components/boxSlotRegistrationInfo"
import { BoxSlotSessionContentInfo } from "@modules/boxSlot/components/boxSlotSessionContentInfo"
import { useBoxSlotSessionDetails } from "@modules/boxSlot/hooks/useBoxSlotDetails"
import { word } from "@modules/core/utils/i18n"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { MenuItem } from "primereact/menuitem"
import { useEffect, useState } from "react"

import { FetchStrategy } from "@betomorrow/micro-stores"
import { BoxSlotListSlider } from "@modules/boxSlot/components/boxSlotListSlider"
import { useServicesContext } from "@modules/core/services/services.context"
import { TabMenu } from "primereact/tabmenu"
import "swiper/css"

export const BoxSlotDetailsInfo: React.FC<{
  boxProgrammationId: string
  boxSlotId: string
  refreshCalendarEvents: () => void
  destinationView?: "session"
}> = ({ boxProgrammationId, boxSlotId, refreshCalendarEvents, destinationView }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeSlotId, setActiveSlotId] = useState<string>(boxSlotId)

  const { boxSlotService } = useServicesContext()

  const { result: boxProgrammation, loading: loadingProgrammation } = useBoxProgrammation(
    boxProgrammationId,
    FetchStrategy.First,
  )
  const { result: boxSlotSession, loading: loadingSlot } = useBoxSlotSessionDetails(activeSlotId, FetchStrategy.First)

  const items: MenuItem[] = [
    { label: word("box.activity.registration") },
    { label: word("box.activity.session") },
    { label: word("box.activity.editSlot") },
  ]

  useEffect(() => {
    if (destinationView === "session") setActiveIndex(1)

    return () => {
      // clear the overlayed slots in calendar
      boxSlotService.updateBoxSlotsBeingViewed([])
    }
  }, [])

  useEffect(() => {
    if (boxSlotSession) {
      // add the selected slot in overlay in the calendar
      boxSlotService.updateBoxSlotsBeingViewed([boxSlotSession])
    }
  }, [boxSlotSession])

  return (
    <>
      <BoxSlotListSlider setActiveSlotId={setActiveSlotId} activeSlotId={activeSlotId} initialBoxSlotId={boxSlotId} />
      {loadingSlot || loadingProgrammation ? (
        <LoadingSpinner />
      ) : (
        boxSlotSession &&
        boxProgrammation && (
          <>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
            {activeIndex === 0 && <BoxSlotRegistrationInfo boxSlot={boxSlotSession} />}
            {activeIndex === 1 && (
              <BoxSlotSessionContentInfo
                date={boxSlotSession.startDateTime}
                session={boxSlotSession.session}
                boxProgrammation={boxProgrammation}
                refreshCalendarEvents={refreshCalendarEvents}
              />
            )}
            {activeIndex === 2 && (
              <BoxSlotFormView
                boxSlot={boxSlotSession}
                boxProgrammation={boxProgrammation}
                hideTitle={true}
                refreshCalendarEvents={refreshCalendarEvents}
              />
            )}
          </>
        )
      )}
    </>
  )
}
