import { PlusIcon } from "@images/svgIcons/plusIcon"
import { useCurrentBox } from "@modules/box/useBox"
import { BoxOffer, BoxOfferType } from "@modules/boxOffer/boxOfferTypes"
import { BoxOfferFormattedPrice } from "@modules/boxOffer/components/boxOfferFormSection"
import { useBoxOffers } from "@modules/boxOffer/hooks/useBoxOffer"
import { useBoxOfferSidebar } from "@modules/boxOffer/hooks/useBoxOfferSidebar"
import { useBoxProgrammations } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { ScreenHeader } from "@modules/ui/components/header"
import { HUButton } from "@modules/ui/components/huButton"
import { HUTag } from "@modules/ui/components/huTag"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { InputText } from "primereact/inputtext"
import React, { useCallback, useMemo, useState } from "react"
import styled from "styled-components"

export const BoxOfferPage: React.FC = () => {
  const { offers } = useBoxOffers()
  const { navigateToBoxOfferCards, isSidebarVisible } = useBoxOfferSidebar()
  const toast = useToast()
  const confirmPopup = useConfirmPopup()
  const boxNavigate = useBoxNavigate()
  const { result: box } = useCurrentBox()
  const { result: programs } = useBoxProgrammations({ size: 50 })
  const canCreateOffer = box && box.stripeAccountStatus === "OK"
  const [filter, setFilter] = useState<BoxOfferType | "ALL">("ALL")
  const [globalFilter, setGlobalFilter] = useState("")

  const openStripeModal = () => {
    confirmPopup.show({
      title: word("offer_list.add_without_stripe.confirm.header"),
      message: word("offer_list.add_without_stripe.confirm.message"),
      accept: async () => {
        boxNavigate(Path.Box.Admin + "?tab=banking")
      },
      footerProps: {
        align: true,
      },
    })
  }

  const filteredOffers = useMemo(() => {
    if (globalFilter !== "") {
      return offers.filter(
        (offer) =>
          offer.name.toLowerCase().indexOf(globalFilter.toLowerCase()) >= 0 ||
          (offer.description && offer.description.toLowerCase().indexOf(globalFilter.toLowerCase()) >= 0),
      )
    } else {
      return filter === "ALL" ? offers : offers.filter((offer) => offer.type === filter)
    }
  }, [filter, globalFilter, offers])

  const onAdd = useCallback(() => {
    if (canCreateOffer) {
      if (programs && programs.length > 0)
        return isSidebarVisible ? undefined : navigateToBoxOfferCards(filter !== "ALL" ? filter : undefined)
      else return toast.show({ severity: "info", detail: word("box.offer.form.error.createProgramFirst") })
    }
    return openStripeModal()
  }, [canCreateOffer, isSidebarVisible, filter, programs])

  return (
    <HUStack gap={0} flex style={{ width: "100%" }}>
      <ScreenHeader title={word("menu.offers")} subTitle={word("offer.header.subtitle")} />
      <div className="flex mb-5 gap-2 flex-wrap">
        <HUButton
          type="Default"
          size="M"
          colorType={filter === "ALL" ? "White" : "Quaternary"}
          text={word("global.filters.all")}
          onClick={() => setFilter("ALL")}
        />
        {Object.values(BoxOfferType).map((type, index) => (
          <HUButton
            key={`${index}-${type}`}
            type="Default"
            size="M"
            colorType={filter === type ? "White" : "Quaternary"}
            text={word(`box.offer.form.types.${type}`)}
            onClick={() => setFilter(type)}
          />
        ))}
        <div className="p-input-icon-left md:ml-auto align-content-center">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={word("global.search.placeholder")}
            className="w-15rem"
            value={globalFilter}
          />
        </div>
      </div>
      <BoxOfferGrid>
        <BoxOfferCard $new>
          <HUText fontStyle="TM">
            {filter === "ALL" ? word("box.offer.add.title") : word(`offer.form.${filter}.create`)}
          </HUText>
          <HUButton
            type="Rounded"
            size="M"
            colorType="Primary"
            text={word("global.new")}
            icon={{ icon: PlusIcon }}
            onClick={onAdd}
          />
        </BoxOfferCard>
        {filteredOffers.length > 0 && filteredOffers.map((offer) => <BoxOfferCardDetails key={offer.id} {...offer} />)}
      </BoxOfferGrid>
    </HUStack>
  )
}

const BoxOfferCardDetails = (offer: BoxOffer) => {
  const { navigateToBoxOfferResume, isSidebarVisible } = useBoxOfferSidebar()

  return (
    <BoxOfferCard onClick={() => (!isSidebarVisible ? navigateToBoxOfferResume(offer.id) : undefined)}>
      <div className="flex flex-column justify-content-between">
        <HUText fontStyle="TM">{offer.name}</HUText>
        <HUText fontStyle="BS">{offer.description}</HUText>
      </div>
      <div className="flex flex-column justify-content-between align-items-end">
        <HUTag className="bg-primary-700">
          <HUText fontStyle="LL">{word(`box.offer.form.types.${offer.type}`)}</HUText>
        </HUTag>
        <BoxOfferFormattedPrice price={offer.price} currency={offer.currency} />
      </div>
    </BoxOfferCard>
  )
}

const BoxOfferGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  column-gap: 1.75rem;
`

const BoxOfferCard = styled.div<{ $new?: boolean }>`
  width: 100%;
  height: 9.25rem;
  padding: 1.25rem;
  border-radius: 0.75rem;
  border: ${({ theme, $new }) => `1px ${$new ? `dashed ${theme.primaryColor}` : `solid ${theme.neutralColor300}`}`};
  display: flex;
  flex-direction: ${({ $new }) => ($new ? "column" : "row")};
  justify-content: space-between;
  cursor: ${({ $new }) => !$new && "pointer"};
`
