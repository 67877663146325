import { word } from "@modules/core/utils/i18n"
import { HUImage } from "@modules/types/imageTypes"
import { HUImageForm } from "@modules/ui/components/huImageForm"
import { HUText } from "@modules/ui/components/huText"
import { removeInArray } from "@modules/utils/objectUtils"
import { Button } from "primereact/button"
import { Carousel } from "primereact/carousel"
import { useState } from "react"
import styled from "styled-components"

type Props = {
  boxPhotos: HUImage[]
  setBoxPhotos: (photos: HUImage[]) => void
  setIsImageUploading?: (isImageUploading: boolean) => void
} & React.HTMLAttributes<HTMLDivElement>

export const BoxPhotosForm: React.FC<Props> = ({ boxPhotos, setBoxPhotos, setIsImageUploading }) => {
  const [page, setPage] = useState(0)

  const carouselItemTemplate = (image: HUImage) => {
    return (
      <div className="border-1 surface-border border-round m-1 text-center py-5">
        <div className="mb-3">
          <img src={image.path} alt="" className="w-6 shadow-2" />
        </div>
        <div>
          <div className="car-buttons mt-5">
            <Button
              onClick={() => {
                const index = boxPhotos.findIndex((i) => i.path === image.path)
                setBoxPhotos([...removeInArray(boxPhotos, index)])
              }}
              type="button"
              className="p-button-rounded p-button-warning"
              icon="pi pi-trash"
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <HUText fontStyle="LL">{word("box.form.additionalImage.label")}</HUText>
      <div className="flex flex-column pt-3">
        {!!boxPhotos.length && (
          <CarouselStyled
            page={page}
            value={boxPhotos}
            numVisible={1}
            numScroll={1}
            itemTemplate={carouselItemTemplate}
            prevIcon={undefined}
            nextIcon={undefined}
          />
        )}
        {boxPhotos.length < 5 && (
          <HUImageForm
            type="cover"
            placeHolder={{
              message: word("box.form.uploadImage.text1"),
            }}
            setIsImageUploading={setIsImageUploading}
            setImage={(image?: HUImage) => {
              if (image) {
                setBoxPhotos([...boxPhotos, image])
                setPage(boxPhotos.length)
              }
            }}
          />
        )}
      </div>
    </>
  )
}

const CarouselStyled = styled(Carousel)`
  .p-carousel-item {
    display: flex;

    > div {
      flex: 1;
    }

    img {
      width: 100%;
      max-height: 250px;
      object-fit: contain;
      box-shadow: none !important;
    }
  }
`
