import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { ProgramPriceCard } from "@modules/programs/components/programPriceCard"
import { ProgramSubscriptionCard } from "@modules/programs/components/programSubscriptionCard"
import { ProgressionRate } from "@modules/programs/components/progressionRate"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { ProgramOnOffStatus, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { Program, ProgramSubscriptionStatus, SharedCoachStatus } from "@modules/programs/programTypes"
import { SharedCoachRequest } from "@modules/sharedCoach/components/sharedCoachRequest"
import { SharedCoachView } from "@modules/sharedCoach/components/sharedCoachView"
import { FillRate } from "@modules/ui/components/fillRate"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { Linkify } from "@modules/ui/components/linkifyText"
import React from "react"
import styled, { useTheme } from "styled-components"

type ProgramInfoDetailsProps = {
  program: Program
  editionAllowed: boolean
}

export const ProgramInfoDetails: React.FC<ProgramInfoDetailsProps> = ({ program, editionAllowed }) => {
  const theme = useTheme()
  const { navigateToProgramEdition } = useProgramSidebar()
  const mySpaceNavigate = useMySpaceNavigate()
  const { isAuthor, isAcceptedSharedCoach, sharedCoach } = useProgramRights(program)

  const shouldDisplayFillRate =
    program._programType === ProgramOnOffTypeOf && isAuthor && program.status === ProgramOnOffStatus.DRAFT
  const shouldDisplayProgressionRate =
    program._programType === ProgramOnOffTypeOf && !isAuthor && program.status === ProgramOnOffStatus.PUBLISHED
  const isProgramSubscribed = program.subscriptionStatus === ProgramSubscriptionStatus.ACTIVE

  const onViewProgramDetailsClick = () => {
    mySpaceNavigate(
      program._programType === ProgramOnOffTypeOf
        ? Path.MySpace.ProgramOnOffDetails(program.id)
        : Path.MySpace.ProgramDetails(program.id),
    )
  }

  const onEditProgramClick = () => {
    navigateToProgramEdition(program)
  }

  return (
    <ContentContainer $isAuthor={isAuthor} $isAcceptedSharedCoach={isAcceptedSharedCoach}>
      <SharedCoachRequest program={program} currentSharedCoach={sharedCoach} />
      <Description fontStyle="BM" style={{ whiteSpace: "pre-line" }}>
        <Linkify youtubeProps={{ allowEmbed: true, keepUrl: true }} content={program.description} />
      </Description>
      {shouldDisplayFillRate && <FillRate program={program} editionAllowed={editionAllowed} />}
      {program && isAuthor && <ProgramPriceCard program={program} editionAllowed={false} />}
      {program && !isAuthor && !isProgramSubscribed && <ProgramSubscriptionCard program={program} />}
      {shouldDisplayProgressionRate && <ProgressionRate program={program} editionAllowed={editionAllowed} />}
      {program && !isAuthor && isProgramSubscribed && <ProgramSubscriptionCard program={program} />}
      <HUText fontStyle="TS" className="mb-2">
        {word("program.sharedCoach.headCoach")}
      </HUText>
      <AvatarContainer className="p-4 mb-4 flex-row justify-content-center align-items-center overflow-hidden gap-2">
        <ProfileAvatar
          className="mb-1"
          profile={{ avatar: program.author.avatar, username: program.author.firstname || "" }}
          size={60}
        />
        <HUStack gap={1} className="text-center">
          <HUText fontWeight="semibold" color={theme.neutralColor700} fontStyle="TM">
            {program.author.firstname + " " + program.author.lastname}
          </HUText>
          {program.author.username && (
            <HUText fontWeight="regular" color={theme.neutralColor700}>
              {"@" + program.author.username}
            </HUText>
          )}
        </HUStack>
      </AvatarContainer>
      {!sharedCoach &&
        program.sharedCoaches &&
        program.sharedCoaches.length > 0 &&
        (program.sharedCoaches.some((coach) => coach.status === SharedCoachStatus.ACCEPTED) || isAuthor) && (
          <>
            <HUText fontStyle="TS" className="mb-2">
              {word("program.sharedCoach.title")}
            </HUText>
            <SharedCoachContainer>
              {program.sharedCoaches.map((coach, index) => (
                <SharedCoachView key={index} profile={coach.user} status={coach.status} isAuthor={isAuthor} />
              ))}
            </SharedCoachContainer>
          </>
        )}
      {isAuthor && editionAllowed && (
        <HUButton
          text={word("program.details.modify.button")}
          onClick={onEditProgramClick}
          type="Default"
          size="M"
          colorType="Primary"
          className="w-full"
        />
      )}
      {!editionAllowed && (isProgramSubscribed || isAuthor || isAcceptedSharedCoach) && (
        <HUButton
          text={word("planning.seeProgram")}
          onClick={onViewProgramDetailsClick}
          type="Default"
          size="M"
          colorType="Secondary"
          className="w-full"
        />
      )}
    </ContentContainer>
  )
}
const AvatarContainer = styled(HURow)`
  border-radius: 33px;
  background-color: ${({ theme }) => theme.neutralColor100};
  border-width: 0;
`
const SharedCoachContainer = styled(HUStack)`
  border-radius: 33px;
  background-color: ${({ theme }) => theme.neutralColor100};
  border-width: 0;
  padding: 22px;
  margin-bottom: 24px;
`
const Description = styled(HUText)`
  margin-bottom: 20px;
`
const ContentContainer = styled.div<{ $isAuthor: boolean; $isAcceptedSharedCoach: boolean }>`
  margin-top: ${({ $isAuthor, $isAcceptedSharedCoach }) => ($isAuthor || $isAcceptedSharedCoach ? "115px" : "80px")};
  display: flex;
  flex-direction: column;
`
