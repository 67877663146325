import { BoxOfferItem } from "@modules/boxOffer/boxOfferTypes"
import { useBoxOffers } from "@modules/boxOffer/hooks/useBoxOffer"
import {
  BoxProgrammationFormikType,
  BoxProgrammationInputKey,
} from "@modules/boxProgrammation/boxProgrammationFormikTypes"
import { BoxProgrammationVisibility } from "@modules/boxProgrammation/boxProgrammationTypes"
import { getBoxProgrammationVisibilityExplanation } from "@modules/boxProgrammation/boxProgrammationUtils"
import { BoxProgrammationPrivacyIcon } from "@modules/boxProgrammation/components/boxProgrammationPrivacyIcon"
import { BoxRestrictionTag } from "@modules/boxProgrammation/components/boxRestrictionTag"
import { word } from "@modules/core/utils/i18n"
import { ErrorCard } from "@modules/ui/components/error/errorCard"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { SimpleFormik } from "@src/typings/formik"
import React, { useEffect, useState } from "react"
import { useTheme } from "styled-components"

export const BoxProgrammationOfferForm: React.FC<{
  formik: SimpleFormik<BoxProgrammationFormikType, BoxProgrammationInputKey>
  isEditing: boolean
}> = ({ formik, isEditing }) => {
  const [areOffersExpanded, setAreOffersExpanded] = useState(false)
  const theme = useTheme()
  const { offers: boxOffers, loading, error } = useBoxOffers()

  useEffect(() => {
    if (!isEditing && !loading) {
      formik.setFieldValue(
        "offerIds",
        boxOffers.map((boxOffer) => boxOffer.id),
      )
    }
  }, [loading])

  // const onChangeVisibility = (newVisibility: BoxProgrammationVisibility) => {
  //   formik.setFieldValue("visibility", newVisibility)

  //   if (newVisibility === BoxProgrammationVisibility.MEMBERS) {
  //     formik.setFieldValue(
  //       "offerIds",
  //       boxOffers.map((boxOffer) => boxOffer.id),
  //     )
  //   } else {
  //     formik.setFieldValue("offerIds", [])
  //   }
  // }

  const toggleOffersList = () => setAreOffersExpanded((value) => !value)

  const onToggleOffer = (boxOfferId: string) => {
    const boxOfferIndex = formik.values.offerIds.indexOf(boxOfferId)
    if (boxOfferIndex >= 0) {
      formik.setFieldValue(
        "offerIds",
        formik.values.offerIds.filter((id: string) => id !== boxOfferId),
      )
    } else {
      formik.setFieldValue("offerIds", [...formik.values.offerIds, boxOfferId])
    }
  }

  return (
    <HUStack gap={10}>
      <HUText type="label" fontStyle="LS" className="uppercase">
        {word("box.programmation.form.access.label")}
        <HUText fontStyle="LL" color={theme.necessaryInput}>
          {" *"}
        </HUText>
      </HUText>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorCard title={error} />
      ) : (
        <HUStack gap={20}>
          {/* Commenting out the other visibility options */}
          {/* Object.values(BoxProgrammationVisibility).map((availableVisibility) => {
            const isSelectedVisibility = availableVisibility === formik.values.visibility
            return (
              <HUStack
                key={availableVisibility}
                className={`border-round p-3 cursor-pointer transition-duration-100 transition-all  ${isSelectedVisibility ? "border-2 border-primary" : "border-2 surface-border hover:border-primary"}`}
                gap={20}
                onClick={() => !isSelectedVisibility && onChangeVisibility(availableVisibility)}
              >
                <HURow gap={20}>
                  <BoxProgrammationPrivacyIcon visibility={availableVisibility} isSelected={isSelectedVisibility} />
                  {getBoxProgrammationVisibilityExplanation(availableVisibility)}
                </HURow>
                {availableVisibility === BoxProgrammationVisibility.MEMBERS && (
                  <>
                    <HURow className="mt-2 flex-wrap" gap={10}>
                      {boxOffers
                        .filter((_, index) => (areOffersExpanded ? true : index < 4))
                        .map((boxOffer) => {
                          const isSelectedOffer = formik.values.offerIds.indexOf(boxOffer.id) >= 0
                          return (
                            <BoxRestrictionTag
                              key={boxOffer.id}
                              restriction={boxOffer as BoxOfferItem}
                              isSelected={isSelectedOffer}
                              onSelectRestriction={() => onToggleOffer(boxOffer.id)}
                            />
                          )
                        })}
                    </HURow>
                    {boxOffers.length > 4 && (
                      <HUStack onClick={toggleOffersList} className="align-items-center w-full" gap={4}>
                        <HUText fontStyle="BS">
                          {areOffersExpanded
                            ? word("box.programmation.form.offers.showLess")
                            : word("box.programmation.form.offers.showMore")}
                        </HUText>
                        <span className={areOffersExpanded ? "pi pi-chevron-up" : "pi pi-chevron-down"} />
                      </HUStack>
                    )}
                  </>
                )}
              </HUStack>
            )
          }) */}

          {/* Only showing the MEMBERS visibility option */}
          <HUStack
            className={`border-round p-3 cursor-pointer transition-duration-100 transition-all  border-2 border-primary`}
            gap={20}
          >
            <HURow gap={20}>
              <BoxProgrammationPrivacyIcon visibility={BoxProgrammationVisibility.MEMBERS} isSelected={true} />
              {getBoxProgrammationVisibilityExplanation(BoxProgrammationVisibility.MEMBERS)}
            </HURow>
            <HURow className="mt-2 flex-wrap" gap={10}>
              {boxOffers
                .filter((_, index) => (areOffersExpanded ? true : index < 4))
                .map((boxOffer) => {
                  const isSelectedOffer = formik.values.offerIds.indexOf(boxOffer.id) >= 0
                  return (
                    <BoxRestrictionTag
                      key={boxOffer.id}
                      restriction={boxOffer as BoxOfferItem}
                      isSelected={isSelectedOffer}
                      onSelectRestriction={() => onToggleOffer(boxOffer.id)}
                    />
                  )
                })}
            </HURow>
            {boxOffers.length > 4 && (
              <HUStack onClick={toggleOffersList} className="align-items-center w-full" gap={4}>
                <HUText fontStyle="BS">
                  {areOffersExpanded
                    ? word("box.programmation.form.offers.showLess")
                    : word("box.programmation.form.offers.showMore")}
                </HUText>
                <span className={areOffersExpanded ? "pi pi-chevron-up" : "pi pi-chevron-down"} />
              </HUStack>
            )}
          </HUStack>
        </HUStack>
      )}
    </HUStack>
  )
}
