import { AssetApi } from "@modules/services/assetApi"
import { HUImage } from "@modules/types/imageTypes"

export class AssetService {
  constructor(private readonly api: AssetApi) {}

  uploadImage(content: string, prefix?: string): Promise<HUImage> {
    return this.api.uploadImage(content, prefix, "base64")
  }
}
