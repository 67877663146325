import { CalendarEventTypeWithHour, EventType } from "@modules/calendar/calendarTypes"
import { CalendarDayColumn } from "@modules/calendar/components/calendarDayCol"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { CALENDAR_OPENING_HOURS, DEFAULT_CALENDAR_HOUR_HEIGHT } from "@modules/calendar/utils/calendarConstants"
import { calculateEventsPosition } from "@modules/calendar/utils/eventsHelpers"
import { word } from "@modules/core/utils/i18n"
import { getFormatedDateTime } from "@modules/language/languageUtils"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { getScrollbarWidth } from "@modules/utils/utils"
import dayjs, { Dayjs } from "dayjs"
import React, { useCallback, useEffect, useMemo, useState } from "react"

type CalendarProps = {
  calendarEvents: EventType[]
  canAddEvents: boolean
  eventLimitPerDay?: number
  onAddEventClick?: (arg0?: dayjs.Dayjs | number) => void
  addEventTitle?: string
  addEventSubtitle?: string
  customRenderEvent?: (index: number, event: EventType, displayDetailedEvents?: boolean) => React.ReactNode
  onEventDragStop?: (dateDest?: dayjs.Dayjs, position?: number) => Promise<void>
  showNoAccessOverlay?: boolean
  noAccessText?: string
  noAccessButton?: { text: string; onClick: () => void }
  loading?: boolean
  isDragAndDropActive?: boolean
}

export type EventPerDay = {
  dayIndex?: number
  label: string
  date?: dayjs.Dayjs
  events: EventType[]
  class: string
}

export const Calendar: React.FC<CalendarProps> = ({
  calendarEvents,
  canAddEvents,
  eventLimitPerDay = 10,
  onAddEventClick,
  addEventTitle,
  addEventSubtitle,
  customRenderEvent,
  showNoAccessOverlay,
  noAccessText,
  noAccessButton,
  loading = false,
  onEventDragStop,
  isDragAndDropActive = false,
}) => {
  const [hasEventOnHoveredHour, setHasEventOnHoveredHour] = useState<{
    hour: number
    date: Dayjs | number
    hasEvents: boolean
  }>()
  const { currentWeekdays, isUndated, hasHours } = useCalendar()

  // Create an array containing the days of the week + their events
  const eventsPerDays: EventPerDay[] | undefined = useMemo(() => {
    return currentWeekdays?.map(({ date, isToday, dayIndex, label }) => ({
      dayIndex,
      label,
      date,
      events: hasHours
        ? calculateEventsPosition(
            calendarEvents.filter((event) =>
              "date" in event && event.date
                ? dayjs(event.date).isSame(date, "date")
                : "dayIndex" in event && event.dayIndex === dayIndex,
            ) as CalendarEventTypeWithHour[],
            canAddEvents ? 85 : 95,
          )
        : calendarEvents.filter((event) =>
            "date" in event && event.date
              ? dayjs(event.date).isSame(date, "date")
              : "dayIndex" in event && event.dayIndex === dayIndex,
          ),
      class: isToday ? "today" : date?.isAfter() || isUndated ? "future" : "past",
    }))
  }, [currentWeekdays, calendarEvents, hasHours])

  // Check if the user can create an event on a given day & hour
  const canUserCreateEvent = useCallback(
    (hour: number, date?: Dayjs, dayIndex?: number) => {
      return (
        canAddEvents &&
        onAddEventClick &&
        typeof hasEventOnHoveredHour !== "undefined" &&
        hasEventOnHoveredHour.hour === hour &&
        (typeof hasEventOnHoveredHour.date === "number"
          ? hasEventOnHoveredHour.date === dayIndex
          : dayjs(date).isSame(hasEventOnHoveredHour.date, "date"))
      )
    },
    [canAddEvents, onAddEventClick, hasEventOnHoveredHour],
  )

  // Create an event on a given day & hour
  const createEventOnDayAndHour = (hour: number, date?: Dayjs, dayIndex?: number) => {
    if (onAddEventClick) {
      if (dayIndex !== undefined) {
        onAddEventClick(
          dayjs()
            .weekday(dayIndex ?? 0)
            .hour(hour)
            .minute(0)
            .second(0)
            .millisecond(0),
        )
      } else {
        onAddEventClick(dayjs(date).hour(hour).minute(0).second(0).millisecond(0))
      }
    }
  }

  // Function to check if there's events on a given hour (the hovered one)
  const checkIfHasEventOnHoveredHour = useCallback(
    (hour: number, date: Dayjs | number) => {
      const result = calendarEvents
        .filter((event) =>
          typeof date !== "number"
            ? "date" in event && dayjs(date).isSame(event.date, "date")
            : "dayIndex" in event && date === event.dayIndex,
        )
        .filter((event) => "startDate" in event)
        .some((event) => {
          if (!("endDate" in event) || !("startDate" in event)) return false
          const eventBeginningHour = dayjs(event.startDate).hour()
          const eventEndingHour = dayjs(event.endDate).hour()
          const eventEndingMinute = dayjs(event.endDate).minute()

          return (
            eventBeginningHour === hour ||
            (eventEndingHour === hour && eventEndingMinute > 0) ||
            (eventBeginningHour < hour && eventEndingHour > hour)
          )
        })
      setHasEventOnHoveredHour({ hour, date, hasEvents: result ?? false })
    },
    [calendarEvents],
  )

  // Check if we should change the scroll to fit the earliest event of the week
  useEffect(() => {
    if (hasHours) {
      let earliestEventOfTheWeekPosition: number | null = null
      const shouldCheckOverlay = eventsPerDays?.some(
        (eventPerDays) => eventPerDays.events.findIndex((event) => "isOverlayed" in event && event.isOverlayed) >= 0,
      )

      eventsPerDays?.forEach((eventPerDays) => {
        eventPerDays.events.forEach((event) => {
          if (
            "style" in event &&
            typeof event.style?.top !== "undefined" &&
            ((shouldCheckOverlay && "isOverlayed" in event && event.isOverlayed) || !shouldCheckOverlay) &&
            (earliestEventOfTheWeekPosition === null || event.style.top < earliestEventOfTheWeekPosition)
          )
            earliestEventOfTheWeekPosition = event.style.top
        })
      })

      if (earliestEventOfTheWeekPosition)
        document.getElementById("scrollable-view")?.scrollTo({ top: earliestEventOfTheWeekPosition - 10 })
    }
  }, [hasHours, eventsPerDays])

  return (
    <div className="calendar relative">
      {hasHours ? (
        <div>
          {/* Displays days of the week */}
          <div className="flex flex-column" style={{ marginLeft: 30, marginRight: getScrollbarWidth() }}>
            <div className="flex flex-row">
              {currentWeekdays?.map((weekday) => (
                <div
                  className={`weekday-label weekday-label-${weekday.weekdayIndex} flex-1 flex flex-column justify-content-center ${weekday.isToday ? "today" : ""}`}
                  key={weekday.weekdayIndex}
                >
                  {weekday.label}
                </div>
              ))}
            </div>
          </div>

          <div id="scrollable-view" className="flex flex-row w-full overflow-y-scroll" style={{ height: 600 }}>
            {/* Displays hours of the day */}
            <div className="flex flex-column">
              {CALENDAR_OPENING_HOURS.map((hour, hourIndex) => (
                <HUText
                  fontStyle="LXS"
                  key={hourIndex}
                  className="opening-hour"
                  style={{ minHeight: DEFAULT_CALENDAR_HOUR_HEIGHT, width: 30 }}
                >
                  {getFormatedDateTime(dayjs().set("hour", hour).set("minute", 0), { mode: "time" })}
                </HUText>
              ))}
            </div>

            {/* Map on each day of the week */}
            {eventsPerDays?.map((eventsPerDay, eventsPerDayIndex) => (
              <div className="flex flex-column w-full relative" key={eventsPerDayIndex}>
                {/* Map on each hour of the day */}
                {CALENDAR_OPENING_HOURS.map((hour, hourIndex) => (
                  <div
                    key={hourIndex}
                    className={"timeslot-container " + "timeslot-" + eventsPerDayIndex}
                    style={{ minHeight: DEFAULT_CALENDAR_HOUR_HEIGHT }}
                    onMouseEnter={() => {
                      if (typeof eventsPerDay.date !== "undefined")
                        checkIfHasEventOnHoveredHour(hour, eventsPerDay.date)
                      else if (typeof eventsPerDay.dayIndex !== "undefined")
                        checkIfHasEventOnHoveredHour(hour, eventsPerDay.dayIndex)
                    }}
                    onMouseLeave={() => setHasEventOnHoveredHour(undefined)}
                  >
                    {/* Render the "Add Event" button */}
                    {hasEventOnHoveredHour &&
                      canUserCreateEvent(hour, eventsPerDay.date, eventsPerDay.dayIndex) &&
                      (hasEventOnHoveredHour.hasEvents ? (
                        <div
                          className="add-button-small"
                          onClick={() => createEventOnDayAndHour(hour, eventsPerDay.date, eventsPerDay.dayIndex)}
                        >
                          <span className="pi pi-plus" />
                        </div>
                      ) : (
                        <div
                          className="flex flex-row align-items-center justify-content-center gap-2 px-3 py-2 cursor-pointer"
                          onClick={() => createEventOnDayAndHour(hour, eventsPerDay.date, eventsPerDay.dayIndex)}
                        >
                          <HUText fontStyle="LM">
                            {typeof eventsPerDay.date !== "undefined"
                              ? word("programmation.slot.create")
                              : word("programmation.slotTemplates.create_slotTemplate")}
                          </HUText>
                          <HUButton
                            size="XS"
                            type="Rounded"
                            colorType="Tertiary"
                            icon={{ iconView: <span className="pi pi-plus" style={{ fontSize: 10 }} /> }}
                          />
                        </div>
                      ))}
                  </div>
                ))}

                {/* Map and render the events of current day */}
                {eventsPerDay.events.map(
                  (event, eventIndex) =>
                    "style" in event &&
                    typeof customRenderEvent !== "undefined" &&
                    customRenderEvent(eventIndex, event),
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        /* Calendar with no hour section */
        <table className="calendar-table">
          {/* Displays days of the week */}
          <thead>
            <tr>
              {currentWeekdays?.map((weekday) => (
                <th
                  className={`weekday-label weekday-label-${weekday.weekdayIndex} ${weekday.isToday ? "today" : ""}`}
                  key={weekday.weekdayIndex}
                >
                  {weekday.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* Map on each day of the week and render each related events */}
              {!loading &&
                eventsPerDays?.map((eventsPerDay, index) => (
                  <CalendarDayColumn
                    key={index}
                    index={index}
                    eventsPerDay={eventsPerDay}
                    customRenderEvent={customRenderEvent}
                    onAddEventClick={onAddEventClick}
                    addEventTitle={addEventTitle}
                    addEventSubtitle={addEventSubtitle}
                    canAddEvents={canAddEvents}
                    eventLimitPerDay={eventLimitPerDay}
                    isDragAndDropActive={isDragAndDropActive}
                    onEventDragStop={onEventDragStop}
                  />
                ))}
            </tr>
          </tbody>
        </table>
      )}
      {/* Loading state */}
      {loading && (
        <div className="no-access-overlay">
          <LoadingSpinner />
        </div>
      )}
      {/* No access overlay section */}
      {!loading && showNoAccessOverlay && noAccessText && (
        <div className="no-access-overlay">
          <i className={"pi pi-eye-slash text-4xl"} />
          <HUText fontStyle="TM">{noAccessText}</HUText>
          {noAccessButton && (
            <HUButton
              type="Default"
              size="S"
              text={noAccessButton.text}
              className="mt-2"
              colorType="Primary"
              onClick={() => noAccessButton.onClick()}
            />
          )}
        </div>
      )}
    </div>
  )
}
