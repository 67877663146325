import { getAuthError } from "@modules/auth/authError"
import { AUTH_SPECIAL_CHARACTERS } from "@modules/auth/authUtils"
import {
  AuthContent,
  AuthForm,
  AuthFormError,
  AuthHeader,
  AuthSubmitButton,
} from "@modules/auth/components/authComponents"
import { LoginBack } from "@modules/auth/components/loginBack"
import { AuthPage } from "@modules/auth/page/authPage"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { HUInput } from "@modules/ui/components/huInput"
import { HUText } from "@modules/ui/components/huText"
import { LoggerType } from "@modules/utils/loggerUtils"
import { isValidPassword } from "@modules/utils/valid"
import { useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import styled, { useTheme } from "styled-components"

const CONFIRM_CODE_LENGTH = 6

export const ResetPasswordPage: React.FC = () => {
  const theme = useTheme()
  const { userService } = useServicesContext()
  const location = useLocation() as { state: { email?: string; isRequiredPassword?: boolean } }
  const email = location.state.email
  const isRequiredPassword = location.state.isRequiredPassword
  const [code, setCode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [infoCodeResend, setInfoCodeResend] = useState("")

  useEffect(() => {
    setInfoCodeResend("")
    setError("")
  }, [code, newPassword])

  const navigate = useNavigate()

  if (!email) {
    return <Navigate to={Path.Login} />
  }

  const changePassword = async () => {
    try {
      setLoading(true)
      setInfoCodeResend("")
      if (!isValidPassword(newPassword)) {
        if (!AUTH_SPECIAL_CHARACTERS.some((c) => newPassword.includes(c))) {
          setError(
            `${word("auth.signup.form.password_invalid")}. ${word("auth.signup.form.pasword_special_character_list")}: ${AUTH_SPECIAL_CHARACTERS.join(" ")}`,
          )
        } else setError(word("auth.signup.form.password_invalid"))
      } else {
        await userService.changePasswordWithCode(email, code, newPassword)
        navigate({ pathname: Path.Login }, { state: { email } })
      }
    } catch (e) {
      console.error(LoggerType.Login + "change password", e)
      setError(getAuthError(e))
    } finally {
      setLoading(false)
    }
  }

  const resendConfirmationCodeForgotPassword = async () => {
    try {
      await userService.sendForgotPasswordConfirmationCode(email)
      setInfoCodeResend(word("auth.check_signup.form.notif_code_resend"))
    } catch (e) {
      console.error(LoggerType.Login + "resend confimation code password", e)
      setError((e as any).message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthPage>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault()
          changePassword()
        }}
      >
        <AuthHeader
          title={
            isRequiredPassword
              ? word("auth.form.title.reset_password_required")
              : word("auth.form.title.reset_password")
          }
          subtitle={word("auth.reset_password.form.subtitle", { email })}
        />

        <AuthContent>
          <HUInput
            value={code}
            autoComplete="false"
            hasError={!!error}
            placeholder={word("auth.check_signup.form.code")}
            onChange={(e) => setCode(e.currentTarget.value)}
          />
          <HUInput
            placeholder={word("auth.form.new_password")}
            autoComplete="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.currentTarget.value)}
            hasError={!!error}
            password
          />
          <Container>
            <ResendCodeButton type="button" onClick={resendConfirmationCodeForgotPassword}>
              <HUText>{word("auth.check_signup.form.resend_code")}</HUText>
            </ResendCodeButton>
          </Container>
          <AuthSubmitButton loading={loading} disabled={code.length !== CONFIRM_CODE_LENGTH || !newPassword}>
            {word("global.check")}
          </AuthSubmitButton>
          <AuthFormError color={infoCodeResend === "" ? theme.textWarning : theme.textEmphasized}>
            {infoCodeResend || error}
          </AuthFormError>
          <LoginBack />
        </AuthContent>
      </AuthForm>
    </AuthPage>
  )
}

const ResendCodeButton = styled.button`
  display: contents;
  span {
    text-decoration: underline;
  }
`

const Container = styled.div`
  text-align: right;
`
