import { CalendarProvider } from "@modules/calendar/context/calendarContext"
import { useServicesContext } from "@modules/core/services/services.context"
import dayjs from "dayjs"
import React from "react"
import { TrainingContent } from "../components/trainingContent"

export const TrainingPage: React.FC = () => {
  const { trainingService } = useServicesContext()

  return (
    <CalendarProvider
      initialDate={dayjs().startOf("day")}
      onDateSet={(startDate, endDate) => {
        trainingService.getTrainingContents(startDate.toString(), endDate.toString())
      }}
      defaultDisplayDetailedEvents={false}
      defaultView="dayGridWeek"
      refreshEvents={async (startDate, endDate) => {
        await trainingService.getTrainingContents(startDate.toString() ?? "", endDate.toString() ?? "")
      }}
    >
      <TrainingContent />
    </CalendarProvider>
  )
}
