import { AsyncObservable } from "@modules/asyncObservable/asyncObservable"
import { BoxRole, StaffMember } from "@modules/boxStaff/boxStaffTypes"
import { BoxStaffApi } from "@modules/boxStaff/services/boxStaffApi"
import { Service } from "@modules/core/services/serviceType"

export class BoxStaffService implements Service {
  allStaff = new AsyncObservable<StaffMember[]>(() => this.api.getMembers())

  constructor(private api: BoxStaffApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init() {}

  async addMember(email: string, role: BoxRole) {
    await this.api.addMember(email, role)
    this.allStaff.fetch()
  }
}
