import { PencilIcon } from "@images/svgIcons/pencil"
import { PlusIcon } from "@images/svgIcons/plusIcon"
import { QuadBoxIcon } from "@images/svgIcons/quadBoxIcon"
import { ShowcaseIcon } from "@images/svgIcons/showcaseIcon"
import { TypicalWeekIcon } from "@images/svgIcons/typicalWeekIcon"
import { ViewProgrammationIcon } from "@images/svgIcons/viewProgrammationIcon"
import { BoxProgrammation, ItemBoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { useBoxProgrammationSidebar } from "@modules/boxProgrammation/hooks/useBoxProgrammationSidebar"
import { useBoxSlotSidebar } from "@modules/boxSlot/hooks/useBoxSlotSidebar"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { EllipsisMenu } from "@modules/ui/components/ellipsisMenu"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { hexToRgbString } from "@modules/utils/colorUtils"
import { MenuItem } from "primereact/menuitem"
import styled, { useTheme } from "styled-components"

export const BoxProgrammationInformation: React.FC<{ programmation: BoxProgrammation | ItemBoxProgrammation }> = ({
  programmation,
}) => {
  const theme = useTheme()
  const { navigateToProgrammationView, navigateToProgrammationEdition } = useBoxProgrammationSidebar()
  const boxNavigate = useBoxNavigate()
  const { navigateToBoxSlotCreation } = useBoxSlotSidebar()
  const { refreshCalendarEvents, selectedDate } = useCalendar()

  const ellipsisItems: MenuItem[] = [
    {
      items: [
        {
          label: word("box.programmation.activity.activityShowcase"),
          icon: <ShowcaseIcon color={theme.neutralColor900} width={20} />,
          command: () => {
            navigateToProgrammationView(programmation.id)
          },
        },
        {
          label: word("global.edit"),
          icon: <PencilIcon color={theme.neutralColor900} width={20} />,
          command: () => {
            navigateToProgrammationEdition(programmation.id)
          },
        },
      ],
    },
  ]

  const onAddEventClick = () => {
    navigateToBoxSlotCreation(programmation as BoxProgrammation, refreshCalendarEvents)
  }

  return (
    <HUStack className="justify-content-between flex-grow-1">
      <HURow gap={10} className="align-items-center justify-content-between">
        <HURow className="align-items-center">
          <div className="w-max mr-2">
            <QuadBoxIcon color={programmation.color} />
          </div>
          <HUText fontStyle="TM">{programmation.name}</HUText>
        </HURow>
        <EllipsisMenu items={ellipsisItems} />
      </HURow>
      <HUStack>
        <HURow className=" justify-content-between gap-2">
          <ActionButton onClick={() => onAddEventClick()}>
            <Round>
              <PlusIcon color={theme.textPrimary} width={20} />
            </Round>
            <HUText fontStyle="LM">{word("box.programmation.activity.newSlot")}</HUText>
          </ActionButton>
          <ActionButton onClick={() => boxNavigate(Path.Box.ProgrammationTemplate(programmation.id))}>
            <Round>
              <TypicalWeekIcon color={theme.textPrimary} width={20} />
            </Round>
            <HUText fontStyle="LM">{word("box.programmation.activity.typicalWeek")}</HUText>
          </ActionButton>
        </HURow>
        <HURow className="justify-content-between gap-2">
          <ActionButton
            onClick={() =>
              boxNavigate(Path.Box.ProgrammationSessions(programmation.id), {
                state: { selectedDate: selectedDate?.toString() ?? "" },
              })
            }
          >
            <Round>
              <ViewProgrammationIcon color={theme.textPrimary} width={20} />
            </Round>
            <HUText fontStyle="LM">{word("box.programmation.activity.viewProgrammation")}</HUText>
          </ActionButton>
          {/* <ActionButton>
            <Round>
              <MessageIcon color={theme.textPrimary} width={20} />
            </Round>
            <HUText fontStyle="LM">{word("chat.button.chat")}</HUText>
          </ActionButton> */}
        </HURow>
      </HUStack>
    </HUStack>
  )
}

const Round = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  padding: 5px;
  align-items: center;
  gap: 11px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.primaryColor};
`

const ActionButton = styled.div`
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background: ${({ theme }) => `rgba(${hexToRgbString(theme.textPrimary)}, 0.3)`};
    border-radius: 25px;
    padding: 5px;
  }
`
