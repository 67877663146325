import { Service } from "@modules/core/services/serviceType"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"
import { LoggerType } from "@modules/utils/loggerUtils"
import { observable, Observable } from "micro-observables"

export interface FeatureToggle<T> {
  key: T
  isEnabled: boolean
}

export class FeatureToggleService<T extends string> implements Service {
  private readonly _defaultFeatureToggles: FeatureToggle<T>[] = []
  private _featureToggles = observable<FeatureToggle<T>[]>(this._defaultFeatureToggles)
  readonly featureToggles = this._featureToggles.readOnly()

  constructor(protected localStorage: LocalStorageService, protected defaultFeatures: FeatureToggle<T>[]) {
    this._defaultFeatureToggles = this.defaultFeatures
  }

  async init(): Promise<() => void> {
    this._featureToggles.set(this.load())
    const featureTogglesUnsubscribe = this._featureToggles.subscribe((it) => this.save(it))

    return () => {
      featureTogglesUnsubscribe()
    }
  }

  async onLoggedOut(): Promise<void> {
    // On logout, reset features toggles to be a normal user
    this.reset()
  }

  reset() {
    this._featureToggles.set(this._defaultFeatureToggles)
  }

  async updateFeatureToggle(featureToggle: FeatureToggle<T>) {
    this._featureToggles.update((it) => this.mergeData(it, [featureToggle]))
  }

  hasObservableFeature(key: T): Observable<boolean> {
    return this._featureToggles.select((it) => it.find((feature) => feature.key === key)?.isEnabled ?? false)
  }

  hasFeature(key: T): boolean {
    return this.hasObservableFeature(key).get()
  }

  getAllActivatedFeatures(): string[] {
    return this.featureToggles
      .get()
      .filter((f) => f.isEnabled)
      .map<string>((f) => f.key)
  }

  private load(): FeatureToggle<T>[] {
    const featureToggles = this.mergeData(
      this._defaultFeatureToggles,
      this.localStorage.load<FeatureToggle<T>[]>(LocalStorageKeys.featureToggle.featureToggles, []),
    )
    console.log(LoggerType.FeatureToggle + `init with ${JSON.stringify(featureToggles)}`)
    return featureToggles
  }

  private save(featureToggle: FeatureToggle<T>[]) {
    console.info(LoggerType.FeatureToggle + `update with ${JSON.stringify(featureToggle)}`)
    return this.localStorage.save<FeatureToggle<T>[]>(LocalStorageKeys.featureToggle.featureToggles, featureToggle)
  }

  private mergeData(
    existFeatureToggles: FeatureToggle<T>[],
    newFeatureToggles: FeatureToggle<T>[],
  ): FeatureToggle<T>[] {
    return existFeatureToggles.map((existIt) => newFeatureToggles.find((newIt) => newIt.key === existIt.key) ?? existIt)
  }
}
