import { QuadBoxIcon } from "@images/svgIcons/quadBoxIcon"
import {
  BoxProgrammationDefaultSettingsFormikType,
  BoxProgrammationDefaultSettingsInputKey,
} from "@modules/boxProgrammation/boxProgrammationFormikTypes"
import { BoxProgrammationDefaultSettings } from "@modules/boxProgrammation/boxProgrammationTypes"
import {
  boxProgrammationColors,
  BoxProgrammationColorType,
} from "@modules/boxProgrammation/components/boxProgrammationColors"
import { BoxProgrammationDefaultSettingsForm } from "@modules/boxProgrammation/components/boxProgrammationDefaultSettingsForm"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { SimpleFormik } from "@src/typings/formik"
import styled, { useTheme } from "styled-components"

export const BoxProgrammationDefaultSettingsFormView: React.FC<{
  boxProgrammationName: string
  boxProgrammationColor: BoxProgrammationColorType
  defaultSettings?: BoxProgrammationDefaultSettings
  formik: SimpleFormik<BoxProgrammationDefaultSettingsFormikType, BoxProgrammationDefaultSettingsInputKey>
  loadingDefaultSettings: boolean
  moveToPreviousFormStep: () => void
  onValidate: () => void
  isSubmitting: boolean
}> = ({
  boxProgrammationName,
  boxProgrammationColor,
  formik,
  loadingDefaultSettings,
  onValidate,
  isSubmitting,
  moveToPreviousFormStep,
}) => {
  const theme = useTheme()

  return (
    <HUStack gap={25}>
      <HURow gap={10} className="align-items-center">
        <div className="w-max ml-4">
          <QuadBoxIcon color={theme.mainWhite} />
        </div>
        <HUText fontStyle="HS">{boxProgrammationName}</HUText>
        <div className="w-max">
          <ProgrammationColor $color={boxProgrammationColors[boxProgrammationColor].background} />
        </div>
      </HURow>
      {loadingDefaultSettings ? (
        <LoadingSpinner />
      ) : (
        <BoxProgrammationDefaultSettingsForm
          formik={formik}
          onValidate={onValidate}
          isSubmitting={isSubmitting}
          moveToPreviousFormStep={moveToPreviousFormStep}
        />
      )}
    </HUStack>
  )
}

const ProgrammationColor = styled.div<{ $color: string }>`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${({ $color }) => $color};
`
