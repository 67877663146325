import { PaginatedUsers, usePaginatedUsers } from "@modules/chat/hooks/usePaginatedUsers"
import { CHANNEL_LIST_SHOWN_WINDOW_WIDTH, DETAILS_OVERLAY_SHOWN_WINDOW_WIDTH } from "@modules/chat/utils/chatConstants"
import { useWindowSize } from "@modules/utils/hooks/useWindowsSize"
import React, { useContext, useEffect, useState } from "react"
import { Channel as ChannelType, DefaultGenerics } from "stream-chat"

export type ChatPageContextValue = PaginatedUsers & {
  isCreatingGroup: boolean
  updateIsCreatingGroup: (arg0: boolean) => void
  isCreatingNewChannel: boolean
  updateIsCreatingNewChannel: (arg0: boolean) => void
  isAddingMembersToChannel: ChannelType<DefaultGenerics> | undefined
  updateIsAddingMembersToChannel: (arg0: ChannelType<DefaultGenerics> | undefined) => void
  displayChannelList: boolean
  toggleDisplayChannelList: () => void
  displayChatDetailsOverlay: boolean
  toggleDisplayChatDetailsOverlay: () => void
  messageToJumpTo: string | null
  setJumpToMessage: (arg0: string | null) => void
  resetMessageToJumpTo: () => void
}

export const ChatPageContext = React.createContext({} as ChatPageContextValue)

export const ChatPageProvider = (props: React.PropsWithChildren<{ value?: ChatPageContextValue }>) => {
  const [isCreatingNewChannel, updateIsCreatingNewChannel] = useState<boolean>(false)
  const [isAddingMembersToChannel, updateIsAddingMembersToChannel] = useState<ChannelType<DefaultGenerics> | undefined>(
    undefined,
  )
  const [isCreatingGroup, updateIsCreatingGroup] = useState<boolean>(false)
  const [displayChannelList, updateDisplayChannelList] = useState<boolean>(true)
  const [displayChatDetailsOverlay, updateDisplayDetailsOverlay] = useState<boolean>(false)
  const [messageToJumpTo, updateMessageToJumpTo] = useState<string | null>()

  const { children, value } = props
  const paginatedUsers = usePaginatedUsers()
  const { width } = useWindowSize()

  const toggleDisplayChannelList = () => updateDisplayChannelList(!displayChannelList)

  const toggleDisplayChatDetailsOverlay = () => updateDisplayDetailsOverlay(!displayChatDetailsOverlay)

  const setJumpToMessage = (messageId: string): void => {
    updateMessageToJumpTo(messageId)
  }
  const resetMessageToJumpTo = (): void => updateMessageToJumpTo(null)

  useEffect(() => {
    updateDisplayChannelList(width >= CHANNEL_LIST_SHOWN_WINDOW_WIDTH)
    updateDisplayDetailsOverlay(width >= DETAILS_OVERLAY_SHOWN_WINDOW_WIDTH)
  }, [width])

  useEffect(() => {
    if (displayChannelList && displayChatDetailsOverlay && width < CHANNEL_LIST_SHOWN_WINDOW_WIDTH) {
      toggleDisplayChatDetailsOverlay()
    }
  }, [displayChannelList])

  useEffect(() => {
    if (displayChannelList && displayChatDetailsOverlay && width < CHANNEL_LIST_SHOWN_WINDOW_WIDTH) {
      toggleDisplayChannelList()
    }
  }, [displayChatDetailsOverlay])

  useEffect(() => {
    if (!isCreatingNewChannel && width < CHANNEL_LIST_SHOWN_WINDOW_WIDTH) {
      updateDisplayChannelList(false)
    }
  }, [isCreatingNewChannel])

  useEffect(() => {
    paginatedUsers.setSelectedUsers([])
    paginatedUsers.setSelectedUserIds([])
  }, [isCreatingGroup])

  const chatContext = {
    ...paginatedUsers,
    ...value,
    isCreatingGroup,
    updateIsCreatingGroup,
    isCreatingNewChannel,
    updateIsCreatingNewChannel,
    isAddingMembersToChannel,
    updateIsAddingMembersToChannel,
    displayChannelList,
    toggleDisplayChannelList,
    displayChatDetailsOverlay,
    toggleDisplayChatDetailsOverlay,
    messageToJumpTo,
    setJumpToMessage,
    resetMessageToJumpTo,
  }

  return <ChatPageContext.Provider value={chatContext as ChatPageContextValue}>{children}</ChatPageContext.Provider>
}

export const useChatPageContext = () => useContext(ChatPageContext) as unknown as ChatPageContextValue
