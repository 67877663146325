import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { useBoxProgrammationDefaultSettings } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { BoxSlotTemplateForm } from "@modules/boxSlotTemplate/components/boxSlotTemplateForm"
import { useBoxSlotTemplate } from "@modules/boxSlotTemplate/hooks/useBoxSlotTemplates"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { Dayjs } from "dayjs"

export const BoxSlotTemplateFormView: React.FC<{
  boxProgrammation: BoxProgrammation
  boxSlotTemplateId?: string
  dateToCreateSlotTemplateOn?: Dayjs
}> = ({ boxProgrammation, boxSlotTemplateId, dateToCreateSlotTemplateOn }) => {
  const { value: boxSlotTemplate, loading, error } = useBoxSlotTemplate(boxProgrammation, boxSlotTemplateId)
  const { value: defaultSettings, loading: loadingDefaultSettings } = useBoxProgrammationDefaultSettings(
    boxProgrammation.id,
  )

  return (
    <>
      {boxSlotTemplateId && (loading || loadingDefaultSettings) ? (
        <LoadingSpinner />
      ) : error ? (
        <HUStack>
          <HUText fontStyle="TL">{error.message}</HUText>
        </HUStack>
      ) : boxProgrammation ? (
        <HUStack>
          <HUText fontStyle="TL">
            {boxSlotTemplate
              ? word("programmation.slotTemplates.update_slotTemplate")
              : word("programmation.slotTemplates.create_slotTemplate")}
          </HUText>
          <HUText fontStyle="BS">{word("programmation.slotTemplates.form.explanation")}</HUText>

          <BoxSlotTemplateForm
            boxProgrammation={boxProgrammation}
            boxSlotTemplate={boxSlotTemplate}
            dateToCreateSlotTemplateOn={dateToCreateSlotTemplateOn}
            defaultSettings={defaultSettings}
          />
        </HUStack>
      ) : (
        <></>
      )}
    </>
  )
}
