import { Chrono } from "@modules/chrono/chronoTypes"
import { AmrapRoundAndRepScoreType } from "@modules/exercises/exerciseFormikTypes"
import { Profile } from "@modules/profile/profileTypes"
import { DashboardScore, Score } from "@modules/scoreboard/score"
import { ScoreType } from "@modules/scoreboard/scoreTypes"

export const UserExerciseTypeOf = "USER_EXERCISE"
export const ProgramExerciseTypeOf = "SESSION_EXERCISE"
export const BoxSlotExerciseTypeOf = "SLOT_EXERCISE"
export const allExerciseType = [UserExerciseTypeOf, ProgramExerciseTypeOf, BoxSlotExerciseTypeOf]

export const ExerciseCategories = [
  "WARM_UP",
  "SKILLS",
  "SPECIFIC_WOD",
  "WOD",
  "BODY_BUILDING",
  "MOBILITY",
  "OTHER",
] as const

export type ExerciseCategory = (typeof ExerciseCategories)[number]

export const ExerciseScoreDifficulties = ["RX", "SCALED"] as const
export type ExerciseScoreDifficulty = (typeof ExerciseScoreDifficulties)[number]
export const ExerciseScoreCategories = ["ALL", "MALE", "FEMALE"] as const
export type ExerciseScoreCategory = (typeof ExerciseScoreCategories)[number]

export type ExerciseScore = {
  value: number
  difficulty: ExerciseScoreDifficulty
  note?: string
}

export type ExerciseScoreDraft = {
  value: string | number | AmrapRoundAndRepScoreType
  difficulty: ExerciseScoreDifficulty
  note?: string
}

type ItemCommonExercise = {
  id: string
  title: string
  _type: typeof ProgramExerciseTypeOf
  category: ExerciseCategory
  chrono?: Chrono
  isFavorite: boolean
  creator?: Profile
  rpe: number | null
}

type CommonExercise = {
  description: string
} & ItemCommonExercise

export type ItemExercise = {
  _type: typeof ProgramExerciseTypeOf
} & ItemCommonExercise
export type Exercise = {
  _type: typeof ProgramExerciseTypeOf
  isCompleted: boolean
  scoreType: ScoreType
  score?: Score
} & CommonExercise

export type ExerciseDraft = {
  id: string | null
  title: string
  description: string
  category: ExerciseCategory
  chrono?: Chrono
  scoreType: ScoreType
  rpe: number | null
}

export type ExerciseDashboardScore = {
  score: DashboardScore
  user: Profile
}

export type ExerciseDashboard = {
  totalScore: number
  topUsers: ExerciseDashboardScore[]
  lastRecordUsers: ExerciseDashboardScore[]
}
