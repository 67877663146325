import { MarkerIcon } from "@images/svgIcons/markerIcon"
import { word } from "@modules/core/utils/i18n"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { HUText } from "@modules/ui/components/huText"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"
import { Address } from "@modules/address/addressTypes"

type LocationBoxProps = {
  box: {
    address: Address
    name: string
  }
  bgColor?: {
    container?: string
    icon?: string
  }
}

export const LocationBox: React.FC<LocationBoxProps> = ({ box, bgColor }) => {
  const theme = useTheme()

  const mapsAddress = "http://maps.google.com/?q="

  const boxAddress = useMemo(() => {
    let address = ""
    if (box.address.additionalInformation) {
      address = `${box.address.address},${box.address.additionalInformation}, ${box.address.postalCode} ${box.address.city}`
    } else {
      address = `${box.address.address}, ${box.address.postalCode} ${box.address.city}`
    }
    return address
  }, [box.address])

  return (
    <Container $bgColor={bgColor?.container}>
      <div className="flex align-items-center mb-2">
        <div>
          <Round $bgColor={bgColor?.icon}>
            <MarkerIcon color={theme.primaryColor} />
          </Round>
        </div>
        <div className="flex flex-column">
          <HUText fontStyle="TS" color={theme.mainWhite}>
            {box.name}
          </HUText>
          <HUText fontStyle="BM" color={theme.secondaryColor200}>
            {boxAddress}
          </HUText>
        </div>
      </div>
      <SeeOnTheCardButton
        type="button"
        label={word("box.where.button")}
        color={theme.neutralColor100}
        linkTarget="_blank"
        className="mr-2 mt-4 w-full"
        to={`${mapsAddress}${box.name} ${boxAddress}`}
      />
    </Container>
  )
}

const Container = styled.div<{ $bgColor?: string }>`
  background-color: ${({ theme, $bgColor }) => $bgColor ?? theme.secondaryColor700};
  padding: 22px;
  border-radius: 33px;
`

const Round = styled.div<{ $bgColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: ${({ theme, $bgColor }) => $bgColor ?? theme.secondaryColor600};
  margin-right: 10px;
`

const SeeOnTheCardButton = styled(HUNavigateButton)`
  .p-button {
    width: 100%;
    padding: 12px 32px 12px 32px !important;
    background-color: ${({ theme }) => theme.mainWhite};
    border-radius: 60px;
    border: none;
  }

  .p-button:hover {
    background-color: ${({ theme }) => theme.neutralColor300} !important;
  }

  .p-button-label {
    color: ${({ theme }) => theme.mainBlack} !important;
  }
`
