import { BoxOffer, BoxOfferDraft } from "@modules/boxOffer/boxOfferTypes"
import { BoxOfferDto, convertBoxOfferDraftToDto, convertBoxOfferFromDto } from "@modules/boxOffer/services/boxOfferDto"
import { ApiClient } from "@modules/core/api/client/apiClient"
import _ from "lodash"

export class OfferApi {
  private baseUrl = "/box/offers"

  constructor(private api: ApiClient) {}

  async getBoxOffers(): Promise<BoxOffer[]> {
    const resultDto = await this.api.get<BoxOfferDto[]>(`${this.baseUrl}`)
    return _.compact(resultDto.data.map((dto) => convertBoxOfferFromDto(dto)))
  }

  async getBoxOffer(id: string): Promise<BoxOffer> {
    const resultDto = await this.api.get<BoxOfferDto>(`${this.baseUrl}/${id}`)
    const offer = convertBoxOfferFromDto(resultDto.data)
    if (offer) {
      return offer
    } else {
      throw new Error("Offer received by the backend does not correspond to DTO")
    }
  }

  async createBoxOffer(offerDraft: BoxOfferDraft): Promise<BoxOffer> {
    const resultDto = await this.api.post<BoxOfferDto>(`${this.baseUrl}`, convertBoxOfferDraftToDto(offerDraft))
    const offer = convertBoxOfferFromDto(resultDto.data)
    if (offer) {
      return offer
    } else {
      throw new Error("Offer received by the backend does not correspond to DTO")
    }
  }

  async update(id: string, offerDraft: BoxOfferDraft): Promise<BoxOffer> {
    const resultDto = await this.api.put<BoxOfferDto>(`${this.baseUrl}/${id}`, convertBoxOfferDraftToDto(offerDraft))
    const offer = convertBoxOfferFromDto(resultDto.data)
    if (offer) {
      return offer
    } else {
      throw new Error("Offer received by the backend does not correspond to DTO")
    }
  }

  async delete(id: string): Promise<void> {
    await this.api.post<BoxOfferDto>(`${this.baseUrl}/${id}/archive`)
  }
}
