import { HeroesBenchmarkDataType } from "@modules/dashboard/components/heroesBenchmarkDataType"
import backgroundCardBuriak from "@images/crossfit/heroes/backgroundCardBuriak.png"
import backgroundCardGale from "@images/crossfit/heroes/backgroundCardGale.png"
import backgroundCardGoose from "@images/crossfit/heroes/backgroundCardGoose.png"
import backgroundCardNorthup from "@images/crossfit/heroes/backgroundCardNorthup.png"
import backgroundCardOda from "@images/crossfit/heroes/backgroundCardOda.png"
import backgroundCardPikey from "@images/crossfit/heroes/backgroundCardPikey.png"

export const dataHeroesBenchmark: HeroesBenchmarkDataType[] = [
  {
    // Buriak
    id: "f195f50075d4485783f78b4052913629",
    image: backgroundCardBuriak,
  },
  {
    // ODA 7313
    id: "4ea80b811fc546ab86a04267d196af6b",
    image: backgroundCardOda,
  },
  {
    // Goose
    id: "952304a3130349d2a0f3faba548f5afe",
    image: backgroundCardGoose,
  },
  {
    // PIKEY
    id: "9ea91e8ba4ef4fbab7e44f6e6194ff57",
    image: backgroundCardPikey,
  },
  {
    // Northrup
    id: "fa243f738a3a43f28df02b8fd7c6d913",
    image: backgroundCardNorthup,
  },
  {
    // Gale Force
    id: "a3aa02a18cd94d0987b6b317cb134178",
    image: backgroundCardGale,
  },
]
