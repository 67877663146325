import { ClockOutlineIcon } from "@images/svgIcons/clockOutlineIcon"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { ProgramInfiniteProfile } from "@modules/programs/programInfiniteTypes"
import { ProgramParticipantSubscriptionStatus } from "@modules/programs/programTypes"
import { EllipsisMenu } from "@modules/ui/components/ellipsisMenu"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import styled, { useTheme } from "styled-components"

export const ProgramSubscriber: React.FC<{ subscriber: ProgramInfiniteProfile }> = ({ subscriber }) => {
  const navigate = useMySpaceNavigate()
  const theme = useTheme()

  const openChatWithUser = async (userId: string) => {
    if (userId) {
      navigate(Path.Chat.Root, { state: { userToChatTo: userId } })
    }
  }

  return (
    <HURow key={subscriber.id} className="mb-3 align-items-center justify-content-between">
      <HURow>
        <ProfileAvatar
          className="mr-2"
          profile={{ avatar: subscriber.avatar, username: subscriber.firstname || "" }}
          size={55}
        />
        <HUStack className="justify-content-center" gap={0}>
          <HUText fontStyle="LL" className="mb-1">{`${subscriber.firstname} ${subscriber.lastname}`}</HUText>
          {subscriber.subscriptionStatus === ProgramParticipantSubscriptionStatus.PENDING_PAYMENT ? (
            <PendingPaymentTag gap={4}>
              <ClockOutlineIcon color={theme.neutralColor800} width={12} height={12} />
              <HUText fontStyle="LM">{word("program.subscribers.tag.pending_payment")}</HUText>
            </PendingPaymentTag>
          ) : (
            <HUText fontStyle="BS" color={theme.neutralColor600}>
              {`@${subscriber.username}`}
            </HUText>
          )}
        </HUStack>
      </HURow>

      <div className="flex justify-content-end">
        <EllipsisMenu
          width={220}
          items={[
            {
              items: [
                {
                  label: word("finances.send_message"),
                  icon: <span className="pi pi-comments" />,
                  command: () => {
                    openChatWithUser(subscriber.id)
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </HURow>
  )
}
const PendingPaymentTag = styled(HURow)`
  padding: 4px 10px 4px 8px;
  align-items: center !important;
  background-color: ${({ theme }) => theme.neutralColor100};
  border-radius: 100px;
`
