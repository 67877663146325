import ProgramEmpty from "@images/empty/program_empty.svg"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { word } from "@modules/core/utils/i18n"
import { ProgramCalendar } from "@modules/programs/components/programCalendar"
import { ProgramHeader } from "@modules/programs/components/programHeader/programHeader"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { useProgramsInfinite } from "@modules/programs/hooks/useProgramsInfinite"
import { Program } from "@modules/programs/programTypes"
import { DetailedSessionType } from "@modules/sessions/components/programSessionDetailsComponent"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { minScreenWidth } from "@modules/ui/uiConstants"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import styled, { useTheme } from "styled-components"

type ProgramDetailContentProps = {
  program?: Program
  loading?: boolean
}

export const ProgramDetailsContent = ({ program, loading = false }: ProgramDetailContentProps) => {
  const [openedSession, setOpenedSession] = useState<DetailedSessionType>()

  const { refreshCalendarEvents } = useCalendar()
  const { result: ownedPrograms, loading: loadingOwnedPrograms } = useProgramsInfinite({ programFilter: "OWNED" })
  const theme = useTheme()
  const { navigateToProgramCreation } = useProgramSidebar()
  const { navigateToSessionView, navigateToSessionForm } = useSessionSidebar()
  const { setDateSession, setProgram } = useSessionFormContext()

  useEffect(() => {
    if (program) setProgram(program)
  }, [program])

  const showEmptyView = !loadingOwnedPrograms && !(ownedPrograms.length > 0 || program)

  const onAddEventClick = async (value?: Dayjs | number) => {
    if (program) {
      const result = await navigateToSessionForm(true, true, refreshCalendarEvents)

      if (result) {
        if (typeof value === "number") setDateSession(value)
        else if (value) setDateSession(value.format(defaultDateFormat))
      }
    }
  }

  const onClickOnSlot = async (slot: DetailedSessionType) => {
    const result = await navigateToSessionView(slot, refreshCalendarEvents, () => {
      setOpenedSession(undefined)
      return Promise.resolve(true)
    })
    if (result) setOpenedSession(slot)
  }

  const onCreateProgramClick = () => {
    navigateToProgramCreation()
  }

  return (
    <Container className="grid flex-grow-1">
      <div className="col-12 flex-grow-1 flex flex-column" style={{ minWidth: minScreenWidth }}>
        <ProgramHeader program={program} onAddEventClick={onAddEventClick} />
        {showEmptyView ? (
          <div
            className="flex justify-content-center align-items-center flex-grow-1"
            style={{ backgroundColor: theme.neutralColor100, borderRadius: 33 }}
          >
            <EmptyView
              image={{ src: ProgramEmpty }}
              title={word("program.title.empty")}
              description={word("programs.empty.description")}
              flexGrow={false}
              className="p-6"
              style={{ backgroundColor: theme.mainWhite }}
              button={
                <HUButton
                  type="Left_Icon"
                  colorType="Primary"
                  size="M"
                  icon={{ iconView: <span className={`p-button-icon p-c pi pi-plus`} /> }}
                  onClick={onCreateProgramClick}
                  text={word("program.form.addProgram.title")}
                />
              }
              paddingType="In_table"
            />
          </div>
        ) : (
          <ProgramCalendar
            program={program}
            loading={loading}
            onClickOnSlot={onClickOnSlot}
            onAddEventClick={onAddEventClick}
            detailedSessionId={openedSession?.id}
          />
        )}
      </div>
    </Container>
  )
}

const Container = styled.div`
  min-width: 980px;
`
