import { word } from "@modules/core/utils/i18n"
import { ExerciseCategory, ExerciseDraft, ExerciseScoreDraft } from "@modules/exercises/exerciseTypes"
import { ExerciseScoreInputDto } from "@modules/exercises/services/exercisesDto"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { convertScoreFromInput } from "@modules/scoreboard/scoreUtils"
import { HUUnit } from "@modules/utils/unitType"

export const getDisplayExerciseCategory = (category: ExerciseCategory): string => {
  switch (category) {
    case "WARM_UP":
      return word("exercise.category.warmup.name")
    case "SKILLS":
      return word("exercise.category.skills.name")
    case "SPECIFIC_WOD":
      return word("exercise.category.specificWod.name")
    case "WOD":
      return word("exercise.category.wod.name")
    case "BODY_BUILDING":
      return word("exercise.category.bodyBuilding.name")
    case "OTHER":
      return word("exercise.category.other.name")
    case "MOBILITY":
      return word("exercise.category.mobility.name")
  }
}

export const convertScoreFromDraft = (
  draft: ExerciseScoreDraft,
  scoreType: ScoreType,
  unit: HUUnit,
): ExerciseScoreInputDto => {
  return {
    difficulty: draft.difficulty,
    score: convertScoreFromInput(draft.value, scoreType, unit),
    note: draft.note,
  }
}

export const GetNewExerciseDraft = (): ExerciseDraft => {
  return {
    category: "WARM_UP",
    description: "",
    id: null,
    scoreType: ScoreType.NO_SCORE,
    rpe: null,
    title: "",
    chrono: undefined,
  }
}
