import { StaffMember, allBoxRoles } from "@modules/boxStaff/boxStaffTypes"
import { HUImageDto, convertImageFromDto } from "@modules/dtos/imageDto"
import { convertToType } from "@modules/utils/enum"

export interface StaffMemberDto {
  id: string
  userId: string
  avatar?: HUImageDto
  email: string
  firstname: string
  lastname: string
  role: string
}

export function convertStaffMemberFromDto(dto: StaffMemberDto): StaffMember {
  return {
    id: dto.id,
    userId: dto.userId,
    avatar: dto.avatar ? convertImageFromDto(dto.avatar) : undefined,
    firstname: dto.firstname,
    lastname: dto.lastname,
    email: dto.email,
    role: convertToType(allBoxRoles, dto.role, "CO_OWNER"),
  }
}
