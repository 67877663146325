import { word } from "@modules/core/utils/i18n"
import { ChronoScoreBloc } from "@modules/exercises/components/chronoScoreBloc"
import { ExerciseDraftForm } from "@modules/exercises/components/exerciseDraftForm"
import { RpeBloc } from "@modules/exercises/components/rpeBloc"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { useMemo, useState } from "react"
import styled, { useTheme } from "styled-components"

export const ExerciseFormInfos: React.FC = () => {
  const theme = useTheme()
  const { sidebarNavigateBack } = useSessionSidebar()
  const { formik, setInitialUpdatedExercise, updatedExerciseIndex } = useSessionFormContext()

  const currentExercise = useMemo(() => {
    return updatedExerciseIndex !== null && formik.values.exercises[updatedExerciseIndex]
  }, [updatedExerciseIndex, formik])

  const [isRpe, setIsRpe] = useState<boolean>(() => {
    if (typeof currentExercise === "object" && currentExercise.rpe !== null && "rpe" in currentExercise) {
      return true
    } else {
      return false
    }
  })

  return (
    <>
      <HUStack gap={10}>
        {currentExercise && updatedExerciseIndex !== null && (
          <div>
            <HUStack gap={10}>
              <HUText fontStyle="LS" color={theme.neutralColor700}>{`(${updatedExerciseIndex + 1}) ${
                updatedExerciseIndex > 1
                  ? word("program.planning.create.exercise.blocks").toUpperCase()
                  : word("program.planning.create.exercise.block").toUpperCase()
              } `}</HUText>
              <ExerciseDraftForm />
            </HUStack>
            <ChronoScoreBloc />
            <RpeBloc isRpe={isRpe} setIsRpe={setIsRpe} formik={formik} index={updatedExerciseIndex} />
          </div>
        )}

        <Footer>
          <HUButton
            type="Default"
            colorType="Primary"
            size="L"
            text={word("global.check")}
            className="p-button-primary w-full"
            onClick={async () => {
              formik.values.exercises.map((exercice, index) => {
                formik.setFieldTouched(`exercises[${index}].description`)
              })
              if (formik.validateField) {
                await formik.validateField("exercises")
                if ((formik.errors && !formik.errors.hasOwnProperty("exercises")) || !formik.errors) {
                  setInitialUpdatedExercise(undefined)
                  sidebarNavigateBack(false)
                }
              }
            }}
          />
        </Footer>
      </HUStack>
    </>
  )
}

const Footer = styled.div`
  padding-top: 20px;
  flex-direction: row;
  display: flex;
`
