import parsePhoneNumber, { CountryCode } from "libphonenumber-js"

export const normalizeNumberString = (
  value: number,
  options?: { fractionDigits?: number; unitSpacing?: boolean; unit?: string },
) => {
  const validValue = isNaN(value) ? 0 : value

  const defaultLookup = { value: 1, symbol: "" }
  const lookup = [
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
  ]
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/
  const foundItem =
    lookup
      .slice()
      .reverse()
      .find((item) => validValue >= item.value) ?? defaultLookup

  let normalize = ""
  if (options?.fractionDigits !== undefined) {
    normalize = (validValue / foundItem.value).toFixed(options.fractionDigits)
  } else {
    normalize = (validValue / foundItem.value).toString()
  }
  normalize = normalize.replace(regex, "$1") + (options?.unitSpacing ? " " : "") + foundItem.symbol
  normalize = normalize.concat(options?.unit ?? "")
  return normalize
}

export const approximateNumber = (value: number) => {
  const validValue = isNaN(value) ? 0 : value
  if (validValue == 0) {
    return 5
  }
  if (validValue <= 10) {
    return 10
  } else {
    const magnitude = Math.pow(10, Math.floor(Math.log10(validValue)))
    return Math.floor(validValue / magnitude) * magnitude
  }
}

export const getApproximatedFormattedNumber = (value: number) => {
  const approximatedNumber = approximateNumber(value)
  const approximatedFormattedNumber =
    value >= 0 ? (value > 10 ? normalizeNumberString(approximatedNumber) + "+" : value > 5 ? "< 10" : "< 5") : "0"
  return { approximatedFormattedNumber, approximatedNumber }
}

export const formatPhoneNumber = (phoneNumber: string, countryCode?: CountryCode) => {
  if (countryCode === "FR") {
    // Supprime tous les caractères non numériques du numéro de téléphone
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "")

    // Ajoute un espace tous les deux caractères
    const formattedPhoneNumber = numericPhoneNumber.replace(/(\d{2})(?=\d)/g, "$1 ")

    return formattedPhoneNumber
  }
  return parsePhoneNumber(phoneNumber, countryCode)?.formatInternational()
}
