import { NavigateOptions, useNavigate } from "react-router-dom"

export enum RouteNavigate {
  Back = -1,
  Root = 0,
}
export type RoutePath = string | RouteNavigate

export const useBaseNavigate = () => {
  const navigate = useNavigate()

  return (path: RoutePath, getFullPath: (path: RoutePath) => string, options?: NavigateOptions) => {
    if (path === RouteNavigate.Back) {
      navigate(RouteNavigate.Back)
    } else {
      const fullPath = getFullPath(path)
      if (fullPath) {
        navigate(fullPath, options)
      }
    }
  }
}
