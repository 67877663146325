import { ChatAvatar } from "@modules/chat/components/chatAvatar"
import { useChatPageContext } from "@modules/chat/contexts/chatPageContext"
import { useChatClient } from "@modules/chat/hooks/useChatClient"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { LoadingPoints } from "@modules/ui/components/loading"
import { useCallback, useEffect, useState } from "react"
import { Channel, ChannelResponse, DefaultGenerics, MessageResponse } from "stream-chat"
import { useChatContext } from "stream-chat-react"
import { getDisplayImage, getDisplayTitle } from "stream-chat-react/dist/components/ChannelPreview/utils"

export type MessageSearchListProps = {
  loading: boolean
  loadMore: () => void
  loadingMore?: boolean
  messages: MessageResponse<DefaultGenerics>[] | undefined
  refreshing?: boolean
  refreshList?: () => void
  showResultCount?: boolean
}

export const MessageSearchList: React.FC<MessageSearchListProps> = ({ loading, messages, refreshing }) => {
  const { setActiveChannel } = useChatContext()
  const { chatClient } = useChatClient()
  const [messageChannels, updateMessageChannels] = useState<Channel<DefaultGenerics>[]>([])
  const { setJumpToMessage } = useChatPageContext()
  const [activeSearchResult, setActiveSearchResult] = useState<MessageResponse<DefaultGenerics> | undefined>()

  useEffect(() => {
    if (messages && messages.length > 0) {
      const channelIds: string[] = []
      messages.forEach((message) => {
        if (message.channel?.id && !channelIds.includes(message.channel.id)) channelIds.push(message.channel.id)
      })

      chatClient?.queryChannels({ id: { $in: channelIds } }).then((channels) => {
        updateMessageChannels(channels)
      })
    }
  }, [messages])

  const onSelectSearchResult = async (message: MessageResponse<DefaultGenerics> | undefined) => {
    setActiveSearchResult(message)

    if (message?.channel?.id) {
      const channels = await chatClient?.queryChannels({ cid: message.channel.cid })
      if (channels?.length === 1) {
        await setActiveChannel(channels[0])
        setJumpToMessage(message.id)
      }
    }
  }

  const getChannelImage = useCallback(
    (channel: ChannelResponse<DefaultGenerics> | undefined): string => {
      const foundChannel = messageChannels.find((messageChannel) => messageChannel.id === channel?.id)

      if (foundChannel) return getDisplayImage(foundChannel, chatClient?.user) || ""
      else return ""
    },
    [messageChannels, chatClient?.user],
  )

  const getChannelTitle = useCallback(
    (channel: ChannelResponse<DefaultGenerics> | undefined) => {
      const foundChannel = messageChannels.find((messageChannel) => messageChannel.id === channel?.id)

      if (foundChannel) return getDisplayTitle(foundChannel, chatClient?.user) || ""
      else return ""
    },
    [messageChannels, chatClient?.user],
  )

  return (
    <div className="str-chat messaging light str-chat-channel-list str-chat__channel-list str-chat__channel-list-react">
      <div className="str-chat__channel-list-messenger str-chat__channel-list-messenger-react">
        <div className="str-chat__channel-list-messenger__main str-chat__channel-list-messenger-react__main">
          {!refreshing &&
            (!messages || messages.length === 0) &&
            (loading ? (
              <div className="flex justify-content-center mt-4">
                <LoadingPoints />
              </div>
            ) : (
              <div className="mt-5 flex justify-content-center text-center">
                <HUText fontStyle="LL">{word("chat.no_result.title")}</HUText>
              </div>
            ))}

          {messages?.map((message, index) => (
            <button
              key={index}
              className={
                "str-chat__channel-preview-messenger str-chat__channel-preview str-chat__channel-preview-messenger" +
                (message.id === activeSearchResult?.id
                  ? " str-chat__channel-preview--active str-chat__channel-preview-messenger--active"
                  : "")
              }
              onClick={() => onSelectSearchResult(message)}
            >
              <div className="str-chat__channel-preview-messenger--left">
                <ChatAvatar
                  image={getChannelImage(message.channel)}
                  name={getChannelTitle(message.channel)}
                  shape="circle"
                  size={40}
                />
              </div>
              <div className="str-chat__channel-preview-messenger--right str-chat__channel-preview-end">
                <div className="str-chat__channel-preview-end-first-row">
                  <div className="str-chat__channel-preview-messenger--name">
                    <HUText fontStyle="LL">{getChannelTitle(message.channel)}</HUText>
                  </div>
                </div>

                <div className="str-chat__channel-preview-messenger--last-message">
                  <HUText fontStyle="LM">{message.text}</HUText>
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
