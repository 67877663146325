import { Benchmark } from "@modules/benchmark/benchmarkTypes"
import { BenchmarkDto, convertBenchmarkFromDto } from "@modules/benchmark/services/benchmarkDto"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { ConfigService } from "@modules/core/services/configService"

export class BenchmarkApi {
  private baseUrl = "/benchmarks"
  private buildExploreUrl = () => `${ConfigService.config.BASE_URL.replace("/web", "")}/mobile${this.baseUrl}`

  constructor(private api: ApiClient) {}

  async getBenchmark(id: string): Promise<Benchmark> {
    const resultDto = await this.api.get<BenchmarkDto>(`${this.buildExploreUrl()}/${id}`)
    return convertBenchmarkFromDto(resultDto.data)
  }
}
