import { Config } from "@modules/config"
import { FeatureToggleService as FeatureService, FeatureToggle } from "@modules/featureToggle/featureToggleService"
import {
  DEFAULT_ACTIVATED_FEATURES,
  DEV_DEFAULT_ACTIVATED_FEATURES,
  FeatureKeys,
} from "@modules/featureToggle/featureToggleTypes"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"

export class FeatureToggleService extends FeatureService<FeatureKeys> {
  constructor(protected localStorage: LocalStorageService) {
    const defaultFeatures = Object.values(FeatureKeys).map((key) => {
      return {
        key,
        isEnabled:
          Config.ENV === "dev"
            ? DEV_DEFAULT_ACTIVATED_FEATURES.includes(key)
            : DEFAULT_ACTIVATED_FEATURES.includes(key),
      }
    })
    super(localStorage, defaultFeatures)
  }

  getLocalStorageValue() {
    return this.localStorage.load<FeatureToggle<FeatureKeys>[]>(LocalStorageKeys.featureToggle.featureToggles, [])
  }
}
