import { getTransactionStatus } from "@modules/finances/components/transactionsColumns"
import { usePeriodParam } from "@modules/finances/hooks/usePeriod"
import { useTransactionTotals } from "@modules/finances/hooks/useTransactionTotals"
import { TransactionStatus } from "@modules/finances/types/financesType"
import { TransactionTotals } from "@modules/finances/types/transactionTotalsType"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { whiten } from "@modules/utils/colorUtils"
import { monthYearDateFormat } from "@modules/utils/dateUtils"
import { useQueryParam } from "@modules/utils/navigation/useQueryParam"
import dayjs from "dayjs"
import { useMemo } from "react"
import styled, { css } from "styled-components"

type Props = {
  filters: (TransactionStatus | "ALL")[]
} & React.HTMLAttributes<HTMLDivElement>

export const TransactionTableFilter: React.FC<Props> = ({ filters, ...props }) => {
  const [transactionsTab, setTransactionsTab] = useQueryParam("tab")
  const defaultValue = useMemo(() => dayjs().format(monthYearDateFormat), [])
  const [period] = usePeriodParam()
  const { result: transactionTotals } = useTransactionTotals(period ?? defaultValue)

  return (
    <HURow {...props} gap={4}>
      {filters.map((filter) => (
        <button onClick={() => setTransactionsTab(filter)} key={filter}>
          <FilterRow $selected={transactionsTab === filter || (!transactionsTab && filter === "ALL")} gap={5}>
            <HUText fontStyle="LL">{getTransactionStatus({ status: filter, plural: true })}</HUText>
            {!!transactionTotals && <HUText fontStyle="BM">({getTransactionTotals(filter, transactionTotals)})</HUText>}
          </FilterRow>
        </button>
      ))}
    </HURow>
  )
}

export const getTransactionTotals = (
  filter: TransactionStatus | "ALL",
  transactionTotals: TransactionTotals,
): number => {
  const totals: { [key in TransactionStatus]: number } = {
    SUCCEEDED: transactionTotals.succeededTransactionsNumber,
    PENDING: transactionTotals.pendingTransactionsNumber,
    FAILED: transactionTotals.failedTransactionsNumber,
    DISPUTED: transactionTotals.disputedTransactionsNumber,
  }
  return filter === "ALL" ? transactionTotals.totalTransactionsNumber : totals[filter]
}

const FilterRow = styled(HURow)<{ $selected?: boolean }>`
  padding: 12px 18px;
  border-radius: 76px;
  ${({ $selected, theme }) =>
    $selected &&
    css`
      background-color: ${theme.neutralColor100};
    `}

  transition: background-color 0.2s linear;
  will-change: background-color;

  &:hover {
    background-color: ${({ theme }) => whiten(theme.neutralColor100, 0.01)};
  }
`
