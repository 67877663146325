import { word } from "@modules/core/utils/i18n"
import { HUInput } from "@modules/ui/components/huInput"
import { Button } from "primereact/button"
import { useState } from "react"

type NewChatGroupNameFormProps = {
  onValidate: (groupName: string) => void
  onCancel: () => void
  defaultGroupName?: string
}

export const NewChatGroupNameForm: React.FC<NewChatGroupNameFormProps> = ({
  onValidate,
  onCancel,
  defaultGroupName = "",
}) => {
  const [groupName, updateGroupName] = useState<string>(defaultGroupName)

  return (
    <div>
      <HUInput
        name={word("chat.group_chat_name")}
        placeholder={word("chat.group_chat_name")}
        type="text"
        value={groupName}
        onChange={(e) => updateGroupName(e.currentTarget.value)}
      />

      <div className="flex justify-content-end mt-3">
        <div className="flex column gap-3 sm:flex-row">
          <Button
            label={word("chat.cancel")}
            outlined
            className="p-button-secondary"
            onClick={onCancel}
            type="button"
          />
          <Button
            label={word("chat.confirm")}
            className="p-button p-button-primary mr-2"
            onClick={() => onValidate(groupName)}
            disabled={!(groupName && groupName.length > 0)}
          />
        </div>
      </div>
    </div>
  )
}
