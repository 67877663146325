import { BoxOfferType } from "@modules/boxOffer/boxOfferTypes"
import { useBoxProgrammations } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { word } from "@modules/core/utils/i18n"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import styled, { useTheme } from "styled-components"
import { HUButton } from "@modules/ui/components/huButton"
import { LockOutlinedIcon } from "@images/svgIcons/lockOutlinedIcon"
import { BoxOfferFormRulesInput } from "@modules/boxOffer/components/boxOfferFormRulesInput"
import { BoxOfferFormPageProps } from "@modules/boxOffer/components/boxOfferFormFirstPage"
import { BoxOfferFormSection, BoxOfferInputCustomLabel } from "@modules/boxOffer/components/boxOfferFormSection"
import { useState } from "react"

export const BoxOfferFormSecondPage = ({
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  handleSubmit,
  setPageNumber,
}: BoxOfferFormPageProps) => {
  const theme = useTheme()
  const [disabled, setDisabled] = useState(false)
  const { result: programs, loading } = useBoxProgrammations({ size: 50 })

  return (
    <div className="flex flex-column gap-6">
      <div className="flex flex-column gap-2">
        <HUText fontStyle="TL">{`${word(`offer.form.${formik.values.type}.create`)} (2/2)`}</HUText>
        <HUText fontStyle="BM" color={theme.neutralColor700}>
          {word(`offer.form.${formik.values.type}.subtitle`)}
        </HUText>
      </div>
      <div className="gap-2">
        <BoxOfferFormSection
          title={word("box.offer.form.infos.activitiesAccess.title")}
          icon={<LockOutlinedIcon color={theme.mainWhite} />}
          description={
            [BoxOfferType.Subscription, BoxOfferType.Trial].includes(formik.values.type)
              ? word("box.offer.form.infos.activitiesAccess.details")
              : undefined
          }
          className={
            [BoxOfferType.Subscription, BoxOfferType.Millenium, BoxOfferType.TicketsBook].includes(formik.values.type)
              ? "mb-5"
              : undefined
          }
        />
        {formik.values.type === BoxOfferType.Trial && (
          <div className="mt-5">
            <FormikInput
              formikStyle={{ flex: 1 }}
              label={word("offer.form.numberOfSessions.title")}
              description={word("offer.form.numberOfSessions.TRIAL.description")}
              isRequiredInput
              isInvalid={isFormFieldInValid("count")}
              getFieldProps={formik.getFieldProps}
              name="count"
              error={getFormErrorMessage("count")}
              type="number"
              numberProps={{
                placeholder: word("offer.form.numberOfSessions.title"),
              }}
              setFieldValue={formik.setFieldValue}
            />
          </div>
        )}
        {formik.values.type !== BoxOfferType.DropIn && (
          <BoxOfferFormRulesInput formik={formik} error={getFormErrorMessage("rules")} setDisabled={setDisabled} />
        )}
        {formik.values.type === BoxOfferType.Trial && (
          <div className="my-6">
            <BoxOfferInputCustomLabel
              label={word("box.offer.form.infos.renewal.title")}
              description={word("box.offer.form.infos.renewal.subtitle.TRIAL")}
              isRequired
              margin="mb-5"
            />
            <FormikInput
              isInvalid={isFormFieldInValid("renewable")}
              getFieldProps={formik.getFieldProps}
              name="renewable"
              type="switchInline"
              setFieldValue={formik.setFieldValue}
              textSwitch={word("box.offer.form.infos.renewal.description")}
            />
          </div>
        )}
        {programs.length > 0 && (
          <div className="my-5">
            <BoxOfferInputCustomLabel
              label={word("box.offer.form.infos.activitiesIncluded.title")}
              description={word("box.offer.form.infos.activitiesIncluded.details")}
              isRequired
            />
            <ActivitiesContainer>
              <HUStack gap={21}>
                <div className="flex align-items-center justify-content-between">
                  <HUText fontStyle="TS">{word("box.offer.form.infos.myActivities.title")}</HUText>
                  <HUText fontStyle="BS">{`${formik.values.programs.length}/${programs.length} ${word(
                    "global.selected",
                    {
                      count: formik.values.programs.length === 0 ? 1 : formik.values.programs.length,
                    },
                  )}`}</HUText>
                </div>
                <FormikInput
                  formikStyle={{ flexGrow: 1 }}
                  type="multiselect"
                  name="programs"
                  getFieldProps={formik.getFieldProps}
                  isInvalid={isFormFieldInValid("programs")}
                  error={getFormErrorMessage("programs")}
                  loading={loading}
                  multiSelectProps={{
                    options: programs.map((program) => {
                      return {
                        label: program.name,
                        value: program.id,
                      }
                    }),
                    values: formik.values.programs,
                    displayTotalSelectedOptions: false,
                    hideIcon: true,
                    viewMore: word("box.offer.form.infos.myActivities.showMore"),
                  }}
                  setFieldValue={formik.setFieldValue}
                />
              </HUStack>
            </ActivitiesContainer>
          </div>
        )}
      </div>
      <div className="flex gap-5">
        <HUButton
          text={word("global.previous")}
          onClick={() => setPageNumber((prev) => prev - 1)}
          type="Rounded"
          size="M"
          colorType="Quaternary"
          fullWidth
          icon={{
            iconView: <span className={`pi pi-arrow-left`} style={{ color: theme.mainWhite }} />,
          }}
        />
        <HUButton
          text={word("global.next")}
          onClick={handleSubmit}
          type="Right_Icon"
          size="M"
          colorType="Primary"
          fullWidth
          disabled={disabled}
          icon={{
            iconView: (
              <span
                className={`pi pi-arrow-right`}
                style={{ color: disabled ? theme.neutralColor300 : theme.mainWhite }}
              />
            ),
          }}
        />
      </div>
    </div>
  )
}

const ActivitiesContainer = styled.div`
  background-color: ${({ theme }) => theme.neutralColor50};
  padding: 1.25rem;
  border-radius: 0.75rem;
  border: ${({ theme }) => `2px solid ${theme.neutralColor300}`};
`
