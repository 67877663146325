import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const AmrapIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="62" height="63" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="1.5" width="60" height="60" rx="18" fill="#DDF247" />
    <rect x="1" y="1.5" width="60" height="60" rx="18" stroke="url(#paint0_linear_408_84707)" strokeWidth="1.11776" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.469 20.5C19.469 19.9477 19.9168 19.5 20.469 19.5C21.0213 19.5 21.469 19.9477 21.469 20.5V36.0759L26.4688 33.0751V20.5C26.4688 19.9477 26.9165 19.5 27.4688 19.5C28.0211 19.5 28.4688 19.9477 28.4688 20.5V31.8747L33.4703 28.8728V20.5C33.4703 19.9477 33.918 19.5 34.4703 19.5C35.0226 19.5 35.4703 19.9477 35.4703 20.5V27.6724L40.4703 24.6714V20.5C40.4703 19.9477 40.918 19.5 41.4703 19.5C42.0226 19.5 42.4703 19.9477 42.4703 20.5V23.471L43.6924 22.7375C44.1576 22.4583 44.7783 22.5982 45.0788 23.05C45.3793 23.5017 45.2458 24.0943 44.7806 24.3735L42.4703 25.7601V42.5C42.4703 43.0523 42.0226 43.5 41.4703 43.5C40.918 43.5 40.4703 43.0523 40.4703 42.5V26.9605L35.4703 29.9615V42.5C35.4703 43.0523 35.0226 43.5 34.4703 43.5C33.918 43.5 33.4703 43.0523 33.4703 42.5V31.1619L28.4688 34.1637V42.5C28.4688 43.0523 28.0211 43.5 27.4688 43.5C26.9165 43.5 26.4688 43.0523 26.4688 42.5V35.3641L21.469 38.365V42.5C21.469 43.0523 21.0213 43.5 20.469 43.5C19.9168 43.5 19.469 43.0523 19.469 42.5V39.5654L18.3076 40.2625C17.8424 40.5417 17.2217 40.4018 16.9212 39.95C16.6207 39.4983 16.7542 38.9057 17.2194 38.6265L19.469 37.2763V20.5Z"
      fill={props.color}
    />
    <defs>
      <linearGradient
        id="paint0_linear_408_84707"
        x1="3.15569"
        y1="2.5"
        x2="43.3973"
        y2="68.4826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.04" />
        <stop offset="0.188137" stopColor="white" stopOpacity="0" />
        <stop offset="0.708289" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.06" />
      </linearGradient>
    </defs>
  </svg>
)
