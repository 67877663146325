import backgroundBenchmark from "@images/backgroundBenchmark.png"
import backgroundProfile from "@images/backgroundProfile.png"
import { Benchmark, ItemBenchmark } from "@modules/benchmark/benchmarkTypes"
import { ItemBox } from "@modules/box/boxTypes"
import { ExploreCardFooterAvatar } from "@modules/explore/components/exploreCard/exploreCardFooterAvatar"
import { ExploreCardFooterChrono } from "@modules/explore/components/exploreCard/exploreCardFooterChrono"
import { ExploreCardHeader } from "@modules/explore/components/exploreCard/exploreCardHeader"
import { ExploreCardTitle } from "@modules/explore/components/exploreCard/exploreCardTitle"
import { ExploreContentType, ExploreFilter } from "@modules/explore/exploreTypes"
import { Profile } from "@modules/profile/profileTypes"
import { ItemProgram, ProgramTypeOf } from "@modules/programs/programTypes"
import { useMemo, useState } from "react"
import styled from "styled-components"
type ExploreCardProps = {
  item: ItemProgram | ItemBox | ItemBenchmark | Benchmark | Profile
  onViewDetailsClick: (itemId: string, typeOf: ExploreContentType, programType?: ProgramTypeOf) => void
  isSelected: boolean
  resizable?: boolean
  backgroundImage?: string
}

export const ExploreCard: React.FC<ExploreCardProps> = ({
  item,
  onViewDetailsClick,
  isSelected,
  resizable = false,
  backgroundImage = "",
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const itemImage = useMemo(() => {
    if (backgroundImage !== "") {
      return backgroundImage
    }

    switch (item._type) {
      case ExploreFilter.PROGRAM:
        return item.image.path
      case ExploreFilter.BOX:
        return item.cover.path
      case ExploreFilter.BENCHMARK:
        return backgroundBenchmark
      case ExploreFilter.PROFILE:
        return backgroundProfile
    }
  }, [item])

  const onHoverColor = useMemo(() => {
    if (isHovered) {
      switch (item._type) {
        case ExploreFilter.PROGRAM:
        case ExploreFilter.BOX:
          return "green-gradiant"
        case ExploreFilter.BENCHMARK:
          return "blur-benchmark"
        case ExploreFilter.PROFILE:
          return "blur-profile"
        default:
          return ""
      }
    } else {
      switch (item._type) {
        case ExploreFilter.PROGRAM:
        case ExploreFilter.BOX:
          return "black-gradiant"
        case ExploreFilter.BENCHMARK:
        case ExploreFilter.PROFILE:
          return ""
        default:
          return ""
      }
    }
  }, [item, isHovered])

  return (
    <CardContainer
      $bgImage={itemImage}
      $resizable={resizable}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (item._type) {
          const programType = "_programType" in item ? item._programType : undefined
          onViewDetailsClick(item.id, item._type, programType)
        }
      }}
      className={isSelected ? "selected" : ""}
    >
      <ExploreCardHeader item={item} />
      {item._type !== ExploreFilter.PROFILE && <ExploreCardTitle item={item} />}
      {item._type !== ExploreFilter.BENCHMARK ? (
        <ExploreCardFooterAvatar item={item} />
      ) : (
        <ExploreCardFooterChrono item={item} />
      )}
      <GradientOverlay className={onHoverColor} />
    </CardContainer>
  )
}

const CardContainer = styled.div<{ $bgImage?: string; $resizable?: boolean }>`
  height: 300px;
  width: ${({ $resizable }) => ($resizable ? "100%" : "320px")};
  max-width: 320px;
  border-radius: 33px;
  padding: 0px 0px 28px 0px;
  position: relative;
  overflow: hidden;
  background-image: ${({ $bgImage }) => `url(${$bgImage})`};
  background-size: cover;
  background-position: center;
  &:hover {
    cursor: pointer !important;
  }
  &.selected {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: 33px;
      border: 5px solid white;
      z-index: 2;
    }

    border-radius: 33px;
    border: ${({ theme }) => `2px solid ${theme.neutralColor600}`};
  }
`
const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 33px;
  border-width: 0px;
  height: 300px;
  width: 320px;
  z-index: 1;

  &.black-gradiant {
    background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.8) 99.9%);
  }
  &.green-gradiant {
    background: linear-gradient(180deg, rgba(19, 60, 64, 0) 0%, #133c40 99.9%);
  }

  &.blur-benchmark {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(221, 242, 71, 0) 0.01%,
      rgba(221, 242, 71, 0.76) 99.9%
    );
    backdrop-filter: blur(2px);
  }
  &.blur-profile {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(230, 230, 230, 0) 0.01%,
      rgba(230, 230, 230, 0.76) 99.9%
    );
    backdrop-filter: blur(2px);
  }
`
