import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BenchmarkIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill={props.color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.0174 1.33432C8.47158 0.735241 7.52883 0.735241 6.98302 1.33432C6.62094 1.73173 6.06057 1.88188 5.54829 1.71875C4.77607 1.47284 3.95962 1.94422 3.78647 2.73594C3.67161 3.26115 3.26139 3.67136 2.73618 3.78623C1.94446 3.95938 1.47309 4.77582 1.71899 5.54805C1.88212 6.06033 1.73197 6.62069 1.33456 6.98277C0.735485 7.52859 0.735485 8.47133 1.33456 9.01715C1.73197 9.37923 1.88212 9.9396 1.71899 10.4519C1.47309 11.2241 1.94446 12.0405 2.73618 12.2137C3.26139 12.3286 3.67161 12.7388 3.78647 13.264C3.95962 14.0557 4.77607 14.5271 5.54829 14.2812C6.06057 14.118 6.62094 14.2682 6.98302 14.6656C7.52883 15.2647 8.47158 15.2647 9.0174 14.6656C9.37947 14.2682 9.93984 14.118 10.4521 14.2812C11.2243 14.5271 12.0408 14.0557 12.2139 13.264C12.3288 12.7388 12.739 12.3286 13.2642 12.2137C14.056 12.0405 14.5273 11.2241 14.2814 10.4519C14.1183 9.9396 14.2684 9.37923 14.6658 9.01715C15.2649 8.47133 15.2649 7.52859 14.6658 6.98277C14.2684 6.62069 14.1183 6.06033 14.2814 5.54805C14.5273 4.77582 14.056 3.95938 13.2642 3.78623C12.739 3.67136 12.3288 3.26115 12.2139 2.73594C12.0408 1.94422 11.2243 1.47284 10.4521 1.71875C9.93984 1.88188 9.37947 1.73173 9.0174 1.33432ZM8.65254 5.16823C8.43112 4.55864 7.56899 4.55864 7.34756 5.16823L7.03938 6.01664C6.94266 6.28293 6.69382 6.46372 6.41067 6.47343L5.50855 6.50435C4.86037 6.52656 4.59396 7.34649 5.10529 7.74546L5.81695 8.30073C6.04031 8.47501 6.13536 8.76753 6.05709 9.03982L5.80773 9.90735C5.62856 10.5307 6.32604 11.0374 6.86348 10.6744L7.61149 10.1692C7.84626 10.0106 8.15384 10.0106 8.38862 10.1692L9.13663 10.6744C9.67407 11.0374 10.3715 10.5307 10.1924 9.90735L9.94301 9.03982C9.86475 8.76753 9.95979 8.47501 10.1832 8.30073L10.8948 7.74546C11.4061 7.34649 11.1397 6.52656 10.4916 6.50435L9.58943 6.47343C9.30629 6.46372 9.05745 6.28293 8.96072 6.01664L8.65254 5.16823Z"
      fill={props.color}
    />
  </svg>
)
