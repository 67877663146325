import { PlusIcon } from "@images/svgIcons/plusIcon"
import { ReturnIcon } from "@images/svgIcons/returnIcon"
import { useBoxSlotTemplateSidebar } from "@modules/boxSlotTemplate/hooks/useBoxSlotTemplateSidebar"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import styled, { useTheme } from "styled-components"

export const BoxTemplateHeaderRightView: React.FC<{ boxProgrammationId?: string }> = ({ boxProgrammationId }) => {
  const theme = useTheme()
  const boxNavigate = useBoxNavigate()
  const { selectedDate } = useCalendar()
  const { navigateToBoxSlotTemplateGroupCreation } = useBoxSlotTemplateSidebar()

  return (
    <Container className="mb-2 p-1 md:p-2 xl:p-4 mb-5  justify-content-evenly">
      <HUText fontStyle="TM" className="ml-2">
        {word("box.programmation.activity.typicalWeek")}
      </HUText>
      <HUStack gap={2} className="align-items-center justify-content-center mt-2">
        <HUButton
          type="Rounded"
          colorType="Primary"
          size="XS"
          onClick={() => navigateToBoxSlotTemplateGroupCreation()}
          icon={{ iconView: <PlusIcon color={theme.textPrimary} /> }}
        />
        <HUText fontStyle="LM" className="text-center">
          {word("box.programmation.activity.newsSlots")}
        </HUText>
      </HUStack>
      <HUButton
        type="Left_Icon"
        colorType="Underline"
        size="XS"
        onClick={() =>
          boxProgrammationId &&
          boxNavigate(Path.Box.ProgrammationDetails(boxProgrammationId), {
            state: { selectedDate: selectedDate?.toString() ?? "" },
          })
        }
        icon={{ icon: ReturnIcon, iconProps: { color: theme.textPrimary } }}
        text={word("box.backToTimetable")}
      />
    </Container>
  )
}

const Container = styled(HUStack)`
  border-radius: 33px;
  background: ${({ theme }) => theme.neutralColor50};
  position: relative;
  padding: 18px;

  color: ${({ theme }) => theme.mainWhite};
  height: 200px;
  > * {
    z-index: 1;
  }
`
