import { Page } from "@betomorrow/micro-stores"
import { ApiClient } from "@modules/core/api/client/apiClient"
import {
  ItemProgramOnOff,
  ProgramOnOff,
  ProgramOnOffDraft,
  ProgramOnOffProgression,
} from "@modules/programs/programOnOffTypes"
import { ProgramFilter, ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import {
  ItemProgramOnOffDto,
  ProgramOnOffDto,
  ProgramOnOffProgressionDto,
  ProgramOnOffStripeSubscriptionInfosDto,
  convertItemProgramOnOffFromDto,
  convertPartialProgramOnOffDraftToDto,
  convertProgramOnOffDraftToDto,
  convertProgramOnOffFromDto,
  convertProgramOnOffProgressionFromDto,
} from "@modules/programs/services/programOnOffDto"
import { convertToEnum } from "@modules/utils/enum"
import { Paginated, toPage } from "@modules/utils/paginated"
import { ListOrder } from "@modules/utils/types"

export class ProgramOnOffApi {
  private baseUrl = "/programs/on-off"

  constructor(private api: ApiClient) {}

  async getProgramOnOff(id: string): Promise<ProgramOnOff> {
    const resultDto = await this.api.get<ProgramOnOffDto>(`${this.baseUrl}/${id}`)
    return convertProgramOnOffFromDto(resultDto.data)
  }
  async createOnOffProgram(program: ProgramOnOffDraft): Promise<ProgramOnOff> {
    const resultDto = await this.api.post<ProgramOnOffDto>(`${this.baseUrl}`, convertProgramOnOffDraftToDto(program))
    return convertProgramOnOffFromDto(resultDto.data)
  }
  async publishProgramOnOff(id: string) {
    const result = await this.api.post<ProgramOnOffDto>(`${this.baseUrl}/${id}/publish`)
    return result.data
  }

  async updateProgramOnOff(id: string, program: Partial<ProgramOnOffDraft>): Promise<ProgramOnOff> {
    const resultDto = await this.api.patch<ProgramOnOffDto>(
      `${this.baseUrl}/${id}`,
      convertPartialProgramOnOffDraftToDto(program),
    )
    return convertProgramOnOffFromDto(resultDto.data)
  }

  async deleteProgramOnOff(id: string): Promise<void> {
    await this.api.delete<ProgramOnOffDto>(`${this.baseUrl}/${id}`)
  }

  async getOnOffPrograms(
    filter: ProgramFilter,
    order: ListOrder,
    page = 0,
    size = 100,
  ): Promise<Page<ItemProgramOnOff>> {
    const result = await this.api.get<Paginated<ItemProgramOnOffDto>>(`${this.baseUrl}`, {
      params: {
        page,
        size,
        filter,
        order,
      },
    })
    const pageResult = toPage(result.data)
    return { ...pageResult, content: pageResult.content.map((program) => convertItemProgramOnOffFromDto(program)) }
  }

  async getProgressionsProgramOnOff(id: string): Promise<ProgramOnOffProgression> {
    const resultDto = await this.api.get<ProgramOnOffProgressionDto>(`${this.baseUrl}/${id}/progressions`)
    return convertProgramOnOffProgressionFromDto(resultDto.data)
  }

  async startProgramOnOff(programId: string) {
    const resultDto = await this.api.post<ProgramOnOffProgressionDto>(`${this.baseUrl}/${programId}/start`, {
      programId,
    })
    return convertProgramOnOffProgressionFromDto(resultDto.data)
  }
  async stopProgramOnOff(programId: string) {
    const resultDto = await this.api.post<ProgramOnOffProgressionDto>(`${this.baseUrl}/${programId}/stop`, {
      programId,
    })
    return convertProgramOnOffProgressionFromDto(resultDto.data)
  }
  async restartProgramOnOff(programId: string) {
    const resultDto = await this.api.post<ProgramOnOffProgressionDto>(`${this.baseUrl}/${programId}/restart`, {
      programId,
    })
    return convertProgramOnOffProgressionFromDto(resultDto.data)
  }

  async subscribeProgramOnOff(id: string) {
    const result = await this.api.post<{ status: string }>(`${this.baseUrl}/${id}/subscribe`)
    return convertToEnum(ProgramSubscriptionStatus, result.data.status, ProgramSubscriptionStatus.INACTIVE)
  }

  async unsubscribeProgramOnOff(id: string) {
    const result = await this.api.post<{ status: string }>(`${this.baseUrl}/${id}/unsubscribe`)
    return convertToEnum(ProgramSubscriptionStatus, result.data.status, ProgramSubscriptionStatus.INACTIVE)
  }

  async getProgramOnOffStripeSubscriptionInfos(id: string) {
    const resultDto = await this.api.get<ProgramOnOffStripeSubscriptionInfosDto>(`${this.baseUrl}/${id}/subscription`)
    return resultDto.data
  }
}
