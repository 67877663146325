import { InformationAlertIcon } from "@images/svgIcons/informationAlertIcon"
import { BOX_SLOT_PARTICIPANTS_LIMIT_MAX, BOX_SLOT_PARTICIPANTS_LIMIT_MIN } from "@modules/boxSlot/boxSlotTypes"
import {
  BoxSlotTemplateGroupFormikType,
  BoxSlotTemplateGroupInputKey,
} from "@modules/boxSlotTemplate/boxSlotTemplateGroupFormikTypes"
import {
  getCancellationBeginningOptions,
  getRegistrationBeginningOptions,
  getRegistrationEndOptions,
  getSlotDurationOptions,
} from "@modules/boxSlotTemplate/boxSlotTemplateUtils"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUCardAlert } from "@modules/ui/components/huCardAlert"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import { DropdownChangeEvent } from "primereact/dropdown"
import { useEffect, useState } from "react"
import { useTheme } from "styled-components"

export const BoxSlotTemplateGroupSettingsForm: React.FC<{
  formik: SimpleFormik<BoxSlotTemplateGroupFormikType, BoxSlotTemplateGroupInputKey>
  moveToScheduleStep: () => void
}> = ({ formik, moveToScheduleStep }) => {
  const theme = useTheme()
  const { hideSidebar } = useSidebar()
  const [isCustomizableDuration, setIsCustomizableDuration] = useState<boolean>(false)

  const durationOptions = getSlotDurationOptions()
  const registrationBeginningOptions = getRegistrationBeginningOptions()
  const registrationEndOptions = getRegistrationEndOptions()
  const cancellationBeginningOptions = getCancellationBeginningOptions()

  useEffect(() => {
    if (durationOptions.findIndex((durationOption) => durationOption.value === formik.values.slotDuration) < 0)
      setIsCustomizableDuration(true)
  }, [formik.values.slotDuration])

  return (
    <>
      <HUStack gap={10}>
        <HUText fontStyle="TM">{word("box.slotTemplateGroup.form.settings")}</HUText>
        <HUCardAlert
          title={""}
          description={word("box.slotTemplateGroup.form.defaultValuesExplanation")}
          icon={<InformationAlertIcon backgroundColor={theme.primaryColor100} color={theme.primaryColor600} />}
        />
      </HUStack>

      {isCustomizableDuration ? (
        <FormikInput
          flex
          formikStyle={{ display: "flex", flexGrow: 1 }}
          label={word("box.slotTemplateGroup.form.slotDuration.label")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid(`slotDuration`, formik)}
          error={getFormErrorMessage(`slotDuration`, formik)}
          name={"slotDuration"}
          type="maskedInput"
          timeFormat={"HH:mm"}
          maskedInputProps={{
            placeholder: "00h 00min",
            slotChar: "00h 00min",
            mask: "99h 99min",
            onChange: (e) => {
              formik.setFieldValue(`slotDuration`, e.target.value)
            },
          }}
        />
      ) : (
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1 }}
          isRequiredInput
          label={word("box.slotTemplateGroup.form.slotDuration.label")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("slotDuration", formik)}
          name="slotDuration"
          error={getFormErrorMessage("slotDuration", formik)}
          setFieldValue={formik.setFieldValue}
          type="dropdown"
          dropdownProps={{
            value: formik.getFieldProps("slotDuration").value,
            options: durationOptions,
            onChange: (event: DropdownChangeEvent) => {
              if (!event.value) {
                setIsCustomizableDuration(true)
              }
            },
          }}
        />
      )}

      <FormikInput
        flex
        formikStyle={{ flexGrow: 1 }}
        isRequiredInput
        label={word("box.slotTemplateGroup.form.participantLimit.label")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("participantLimit", formik)}
        name="participantLimit"
        placeHolder={word("box.slotTemplateGroup.form.participantLimit.placeholder")}
        error={getFormErrorMessage("participantLimit", formik, { errorMessage: word("global.form.required") })}
        setFieldValue={formik.setFieldValue}
        type="number"
        rightButton={word("box.slotTemplateGroup.form.participantLimit.unit", {
          count: formik.values.participantLimit ?? 0,
        })}
        numberProps={{
          min: BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
          max: BOX_SLOT_PARTICIPANTS_LIMIT_MAX,
        }}
      />

      <HUStack gap={10}>
        <HUText type="label" fontStyle="LS" className="uppercase">
          {word("box.slotTemplateGroup.form.registration.label")}
        </HUText>
        <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.registration.description")}</HUText>
        <HURow gap={10}>
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("box.slotTemplateGroup.form.registration.beginning")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("limitStart", formik)}
            name="limitStart"
            error={getFormErrorMessage("limitStart", formik)}
            setFieldValue={formik.setFieldValue}
            type="dropdown"
            dropdownProps={{
              value: formik.getFieldProps("limitStart").value,
              options: registrationBeginningOptions,
            }}
          />
          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            label={word("box.slotTemplateGroup.form.registration.end")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("limitEnd", formik)}
            name="limitEnd"
            error={getFormErrorMessage("limitEnd", formik)}
            setFieldValue={formik.setFieldValue}
            type="dropdown"
            dropdownProps={{
              value: formik.getFieldProps("limitEnd").value,
              options: registrationEndOptions,
            }}
          />
        </HURow>
      </HUStack>
      <HUStack gap={10}>
        <HUText type="label" fontStyle="LS" className="uppercase">
          {word("box.slotTemplateGroup.form.cancellation.label")}
          <HUText fontStyle="LL" color={theme.necessaryInput}>
            {" *"}
          </HUText>
        </HUText>
        <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.cancellation.description")}</HUText>
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1 }}
          label={word("box.slotTemplateGroup.form.cancellation.beginning")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("limitCancel", formik)}
          name="limitCancel"
          error={getFormErrorMessage("limitCancel", formik)}
          setFieldValue={formik.setFieldValue}
          type="dropdown"
          dropdownProps={{
            value: formik.getFieldProps("limitCancel").value,
            options: cancellationBeginningOptions,
          }}
        />
      </HUStack>
      <HUStack gap={10}>
        <HUText type="label" fontStyle="LS" className="uppercase">
          {word("box.slotTemplateGroup.form.area.title")}
        </HUText>
        <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.area.subtitle")}</HUText>
        <FormikInput
          flex
          formikStyle={{ display: "flex", flexGrow: 1 }}
          name="location"
          isRequiredInput
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("location", formik)}
          setFieldValue={formik.setFieldValue}
          error={getFormErrorMessage("location", formik)}
          placeHolder={word("box.slotTemplateGroup.form.area.placeholder")}
        />
      </HUStack>

      <div className="flex justify-content-end mt-3">
        <div className="flex column gap-3 sm:flex-row flex-grow-1">
          <HUButton
            type={"Default"}
            size="M"
            colorType="Quaternary"
            text={word("global.cancel")}
            onClick={() => hideSidebar()}
            className="w-full"
          />
          <HUButton
            type={"Right_Icon"}
            icon={{
              iconView: <span className={`p-button-icon p-c pi pi-arrow-right`} style={{ color: theme.mainWhite }} />,
            }}
            size="M"
            colorType="Primary"
            text={word("global.next")}
            className="mr-2 w-full"
            onClick={moveToScheduleStep}
          />
        </div>
      </div>
    </>
  )
}
