import { FetchStrategy } from "@betomorrow/micro-stores"
import { GlobeIcon } from "@images/svgIcons/globeIcon"
import { InfinityIcon } from "@images/svgIcons/infinityIcon"
import { LockIcon } from "@images/svgIcons/lockIcon"
import { OnOffIcon } from "@images/svgIcons/onOffIcon"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProgramInfoDetails } from "@modules/programs/components/programInfoDetails"
import { ProgramInfoSubscribers } from "@modules/programs/components/programInfoSusbcribers"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"
import { useProgram } from "@modules/programs/hooks/usePrograms"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffStatus, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramTypeOf, ProgramVisibility } from "@modules/programs/programTypes"
import { getDisplayVisibility, getSubscriptionCountText } from "@modules/programs/programUtils"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HURow } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { MenuItem } from "primereact/menuitem"
import { TabMenu } from "primereact/tabmenu"
import { useEffect, useState } from "react"
import styled, { useTheme } from "styled-components"

type ProgramInfoResumeProps = {
  programId: string
  programType: ProgramTypeOf
  editionAllowed?: boolean
}

export const ProgramInfoResume: React.FC<ProgramInfoResumeProps> = ({
  programId,
  programType,
  editionAllowed = true,
}) => {
  const theme = useTheme()
  const [activeIndex, setActiveIndex] = useState(0)
  const { result: program } = useProgram(programId, programType, FetchStrategy.Always)
  const { setCustomHeaderIcons } = useSidebar()
  const toast = useToast()
  const navigate = useMySpaceNavigate()
  const { isAuthor, isAcceptedSharedCoach } = useProgramRights(program ?? undefined)

  useEffect(() => {
    const customHeaderIcons = []

    const canUserChatWithMembers =
      program &&
      program._programType !== ProgramOnOffTypeOf &&
      (isAuthor || isAcceptedSharedCoach || program.subscribed)

    if (canUserChatWithMembers) {
      const openChatWithProgramGroup = () => {
        if (programId) {
          navigate(Path.Chat.Root, { state: { programToChat: programId } })
        }
      }

      customHeaderIcons.push({
        onClick: openChatWithProgramGroup,
        iconView: <span className="pi pi-comments" />,
        tooltipOptions: {
          isTooltipDisabled: false,
          tooltipId: "chat-with-members",
          tooltipContent: word("chat.button.goToProgramChat"),
        },
      })
    }

    const canUserShareInvitationLink =
      program &&
      program.invitationLink &&
      (program._programType === ProgramInfiniteTypeOf || program.status === ProgramOnOffStatus.PUBLISHED) &&
      (program.visibility === ProgramVisibility.PUBLIC ||
        (program.visibility === ProgramVisibility.PRIVATE && (isAuthor || isAcceptedSharedCoach)))

    if (canUserShareInvitationLink) {
      customHeaderIcons.push({
        onClick: () => {
          navigator.clipboard.writeText(program.invitationLink)
          toast.show({ severity: "info", detail: word("program.details.share.success") })
        },
        iconView: <span className="pi pi-link" />,
        tooltipOptions: {
          isTooltipDisabled: false,
          tooltipId: "dynamic-link",
          tooltipContent: word("sidebar.button.dynamicLink"),
        },
      })
    }

    if (customHeaderIcons.length > 0) {
      setCustomHeaderIcons(customHeaderIcons)
    }
  }, [isAuthor, isAcceptedSharedCoach, program?.invitationLink, program?.visibility, program?.subscribed])

  const items: MenuItem[] = [
    { label: word("global.details") },
    {
      label:
        program?._programType === ProgramOnOffTypeOf
          ? word("global.athlete", { count: 2 })
          : word("program.label.subscribers"),
    },
  ]

  return (
    <>
      {program ? (
        <div className="flex flex-column flex-grow-1">
          <HeaderContainer>
            <div className="relative flex justify-content-center">
              <ImageContainer
                $bgImage={program.image.path}
                className="p-5 mb-5 flex-row justify-content-center align-items-center overflow-hidden gap-2 border-noround-top"
              >
                <GradientOverlay />
                <TitleContainer>
                  <HURow className="align-items-center gap-2">
                    {program._programType === ProgramInfiniteTypeOf ? (
                      <InfinityIcon color={theme.mainWhite} width={24} height={24} />
                    ) : (
                      <OnOffIcon color={theme.mainWhite} width={24} height={24} />
                    )}
                    <HUText fontStyle="HS" color={theme.mainWhite}>
                      {program.title}
                    </HUText>
                  </HURow>
                  <div className="flex align-items-center">
                    {program.visibility === ProgramVisibility.PUBLIC ? (
                      <GlobeIcon color={theme.mainWhite} />
                    ) : (
                      <LockIcon color={theme.mainWhite} />
                    )}
                    <HUText color={theme.mainWhite} fontStyle="BM" className="ml-2">
                      {`${word("program.details.visibility", {
                        visibility: getDisplayVisibility(program.visibility),
                      })}
                  `}
                    </HUText>
                    <HUText fontStyle="HM" color={theme.mainWhite} className="ml-2">
                      ·
                    </HUText>
                    <HUText fontStyle="BS" color={theme.mainWhite} className="ml-2">
                      {getSubscriptionCountText(
                        program.subscriptionCount,
                        isAuthor,
                        isAcceptedSharedCoach,
                        true,
                        program._programType,
                      ).toLowerCase()}
                    </HUText>
                  </div>
                </TitleContainer>
              </ImageContainer>
              {(isAuthor || isAcceptedSharedCoach) && (
                <MenuContainer model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
              )}
            </div>
          </HeaderContainer>
          {activeIndex === 0 && <ProgramInfoDetails program={program} editionAllowed={editionAllowed} />}
          {activeIndex === 1 && <ProgramInfoSubscribers program={program} />}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

const HeaderContainer = styled.div`
  margin: -85px -20px;
`

const ImageContainer = styled(HURow)<{ $bgImage?: string }>`
  position: relative;
  border-radius: 33px;
  border-width: 0px;
  height: 290px;
  width: 100%;
  background-image: ${({ $bgImage }) => `url(${$bgImage})`};
  background-size: cover;
  background-position: center;
`
const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.8) 99.9%);
  border-radius: 33px;
  border-width: 0px;
  height: 290px;
`
const TitleContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 30px;
  text-align: center;
`
const MenuContainer = styled(TabMenu)`
  background-color: ${({ theme }) => theme.neutralColor100};
  position: absolute;
  bottom: -12px;
  width: 95%;
  border: 2px solid ${({ theme }) => theme.neutralColor100};
  border-radius: 50px;

  .p-tabmenu-nav {
    background-color: ${({ theme }) => theme.neutralColor100};
    border-radius: 50px;
    display: flex;
    border: none;
  }

  .p-tabmenuitem {
    flex: 1;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .p-tabmenu-ink-bar  {
    display: none;
  }

  .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background-color: ${({ theme }) => theme.neutralColor100};
    color: ${({ theme }) => theme.neutralColor900};
    border-radius: 50px;
    border: none;
    justify-content: center;
    font-weight: 400;
    height: 100%;
    padding: 0px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    border-radius: 50px;
    font-weight: 600;
    border: none;
    background-color: ${({ theme }) => theme.neutralColor900};
    color: ${({ theme }) => theme.mainWhite};
    width: 100%;
  }

  .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background-color: ${({ theme }) => theme.neutralColor100};
    color: ${({ theme }) => theme.neutralColor900};
    border-radius: 50px;
    border: none;
    justify-content: center;
  }
`
