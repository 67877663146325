import ChatEmpty from "@images/empty/chat_empty.svg"
import { useUser } from "@modules/auth/hooks/useUser"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { useUserProfile } from "@modules/profile/userProfile/hooks/useUserProfile"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import styled, { useTheme } from "styled-components"

type ProfileInfoResumeProps = {
  userId: string
}

export const ProfileInfoResume: React.FC<ProfileInfoResumeProps> = ({ userId }) => {
  const theme = useTheme()

  const { result: viewedUser } = useUserProfile(userId)
  const navigate = useMySpaceNavigate()
  const openChatWithUser = async () => {
    if (viewedUser) {
      navigate(Path.Chat.Root, { state: { userToChatTo: viewedUser.id } })
    }
  }

  const user = useUser()
  const isSelfUser = user?.id === viewedUser?.id

  return (
    <>
      {viewedUser ? (
        <Container>
          <AvatarContainer className="p-5 mb-5 flex-column justify-content-center align-items-center overflow-hidden gap-2 border-noround-top">
            <ProfileAvatar
              className="mb-1"
              profile={{ avatar: viewedUser.avatar, username: viewedUser.firstname || "" }}
              size={90}
            />
            <HUStack gap={1} className="text-center mb-2">
              <HUText fontWeight="semibold" color={theme.neutralColor700} fontStyle="HS">
                {viewedUser.firstname + " " + viewedUser.lastname}
              </HUText>
              {viewedUser.username && (
                <HUText fontWeight="regular" color={theme.neutralColor700}>
                  {"@" + viewedUser.username}
                </HUText>
              )}
            </HUStack>
            {!isSelfUser && (
              <HUButton
                type="Left_Icon"
                colorType="Tertiary"
                size="S"
                icon={{ iconView: <span className="pi pi-comments" /> }}
                text={word("finances.send_message")}
                onClick={openChatWithUser}
              />
            )}
          </AvatarContainer>
          {!isSelfUser && (
            <EmptyView
              image={{ src: ChatEmpty }}
              title={word("user_profile.empty.title")}
              description={word("user_profile.empty.description")}
              paddingType="In_table"
            />
          )}
        </Container>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}
const AvatarContainer = styled(HURow)`
  border-radius: 33px;
  background-color: ${({ theme }) => theme.neutralColor100};
  border-width: 0px;
  > :first-child {
    min-width: 90px;
    min-height: 90px;
    border-radius: 45px;
  }
  .quaternary-button > div > span {
    font-size: 0.8rem;
  }
`
const Container = styled.div`
  height: 100%;
  margin: -52px 0;
`
