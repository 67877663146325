import { ReactNode } from "react"
import { HUText } from "@modules/ui/components/huText"
import { Dialog, DialogProps } from "primereact/dialog"
import styled, { css } from "styled-components"

type HUDialogProps = {
  headerSeparator?: boolean
  header: ReactNode
} & DialogProps

export const HUDialog: React.FC<HUDialogProps> = ({ headerSeparator, footer, header, ...props }) => (
  <StyledDialog
    className="min-w-0"
    style={{ width: "650px" }}
    modal
    blockScroll
    focusOnShow={false}
    draggable={false}
    footer={footer}
    header={<HUText fontStyle="TL">{header}</HUText>}
    $headerSeparator={headerSeparator ?? false}
    {...props}
  />
)

/**
 * Hack : styled-components can't manage types with classes 💩
 */
const StyledDialog = styled(Dialog as unknown as React.FC<DialogProps>)<{
  $headerSeparator?: boolean
}>`
  ${({ $headerSeparator }) =>
    $headerSeparator &&
    css`
      .p-dialog-header {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: ${({ theme }) => theme.neutralColor200} !important;
      }
    `}
`
