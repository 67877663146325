import { HustleupIcon } from "@images/hustleupIcon"
import InstagramQRCode from "@images/instagram_qr.png"
import FacebookIcon from "@images/svgIcons/facebookIcon.svg"
import InstagramIcon from "@images/svgIcons/instagramIcon.svg"
import XIcon from "@images/svgIcons/xIcon.svg"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { normalizeNumberString } from "@modules/utils/numberUtils"
import axios from "axios"
import { AnimatePresence, motion } from "framer-motion"
import React, { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const ChallengeInformation: React.FC<{ maxCal: number }> = ({ maxCal }) => {
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const [followerCount, setFollowerCount] = useState<number>(0)

  const hideInsta = searchParams.get("hideInsta")

  useEffect(() => {
    refresh()
    const interval = setInterval(() => {
      if (!hideInsta) refresh()
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const refresh = useCallback(() => {
    axios
      .get("https://storage.elfsight.com/api/v2/data/e5ea05601205ad4cf96b2721d6173a5e")
      .then((response) => {
        if (response.data.data?.user?.followersCount && response.data.data.user.followersCount !== followerCount) {
          setFollowerCount(response.data.data.user.followersCount)
        }
      })
      .catch((e) => console.log("error", e))
  }, [])

  return (
    <HUStack style={{ width: 236, height: "100%" }} className="align-self-end">
      <Card>
        <HUText color={theme.mainWhite} fontStyle="HM" fontWeight="bold">
          Votre objectif
        </HUText>
        <HUText color={theme.mainWhite} fontStyle="TM">
          {normalizeNumberString(maxCal, { fractionDigits: 1, unitSpacing: true })} cal pour réaliser le rêve d’un
          enfant en soin au CHU de Bordeaux.
        </HUText>
      </Card>

      <Card className="h-full justify-content-center" gap={40}>
        <HUText color={theme.mainWhite} fontStyle="HM" fontWeight="bold">
          Association Aladin
        </HUText>
        <HUNavigateButton
          className="social-network-link"
          to="https://www.aladin33.com"
          linkTarget="_blank"
          label="www.aladin33.com"
          link
          color={theme.mainWhite}
          fontStyle="TM"
        />
        <HUStack gap={6}>
          <a href="https://twitter.com/asso_aladin33" target="_blank" rel="noreferrer">
            <img src={XIcon} className="aladin-social-icon" />
          </a>
          <HUNavigateButton
            className="social-network-link"
            to="https://twitter.com/asso_aladin33"
            linkTarget="_blank"
            label="@Asso_Aladin33"
            link
            color={theme.mainWhite}
            fontStyle="TM"
          />
        </HUStack>
        <HUStack gap={6}>
          <a href="https://www.facebook.com/p/Aladin33-100064875660815" target="_blank" rel="noreferrer">
            <img src={FacebookIcon} className="aladin-social-icon" />
          </a>
          <HUNavigateButton
            className="social-network-link"
            to="https://www.facebook.com/p/Aladin33-100064875660815"
            linkTarget="_blank"
            label="Aladin33"
            link
            color={theme.mainWhite}
            fontStyle="TM"
          />
        </HUStack>
      </Card>

      <Card>
        <HustleupIcon color={theme.mainWhite} width={160} style={{ margin: "auto" }} />
        <HUStack gap={6}>
          <a href="https://www.instagram.com/hustleup.app/?hl=fr" target="_blank" rel="noreferrer">
            <img src={InstagramIcon} className="hustleup-social-icon" />
          </a>
          <HUNavigateButton
            className="social-network-link"
            to="https://www.instagram.com/hustleup.app/?hl=fr"
            linkTarget="_blank"
            label="@hustleup.app"
            link
            color={theme.mainWhite}
            fontStyle="TM"
          />
        </HUStack>
        <HUStack gap={0}>
          {hideInsta ? (
            <img src={InstagramQRCode} width={80} height={80} style={{ margin: "auto", borderRadius: 5 }} />
          ) : (
            <>
              <HUText
                color={theme.mainWhite}
                fontStyle="DS"
                className="mb-0 relative"
                type="label"
                style={{ height: 60 }}
              >
                <AnimatePresence>
                  {followerCount
                    .toString()
                    .split("")
                    .map((char, index) => (
                      <motion.span
                        key={`${char}-${index}`}
                        style={{ width: 30, position: "absolute", left: index * 30 + (followerCount < 9999 ? 35 : 20) }}
                        animate={{ opacity: 1, y: 0 }}
                        initial={{ opacity: 0, y: 20 }}
                        exit={{ opacity: 0, y: -40 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                      >
                        {char}
                      </motion.span>
                    ))}
                </AnimatePresence>
              </HUText>
              <HUText color={theme.mainWhite} fontStyle="HL" className="mt-0">
                Followers
              </HUText>
            </>
          )}
        </HUStack>
      </Card>
    </HUStack>
  )
}

const Card = styled(HUStack)`
  background-color: ${({ theme }) => theme.neutralColor700};
  border-radius: 33px;
  padding: 40px 20px;
  text-align: center;
  .aladin-social-icon {
    max-width: 48px;
    margin: auto;
  }
  .hustleup-social-icon {
    max-width: 30px;
    margin: auto;
  }
  .social-network-link > .p-button {
    padding: 0px !important;
  }
`
