import { CheckmarkIcon } from "@images/svgIcons/checkmarkIcon"
import { ClockOutlineIcon } from "@images/svgIcons/clockOutlineIcon"
import { CrossIcon } from "@images/svgIcons/crossIcon"
import { useUser } from "@modules/auth/hooks/useUser"
import { word } from "@modules/core/utils/i18n"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { Profile } from "@modules/profile/profileTypes"
import { SharedCoachStatus } from "@modules/programs/programTypes"
import { HUText } from "@modules/ui/components/huText"
import React from "react"
import styled, { useTheme } from "styled-components"

type SharedCoachViewProps = {
  profile: Profile
  onClick?: () => void
  onRemove?: () => void
  status?: SharedCoachStatus
  isAuthor?: boolean
  isEditing?: boolean
} & React.HTMLAttributes<HTMLDivElement>
export const SharedCoachView = ({
  profile,
  onClick,
  onRemove,
  status,
  isAuthor = false,
  isEditing = false,
  ...props
}: SharedCoachViewProps) => {
  const theme = useTheme()
  const user = useUser()
  const isPending = status === SharedCoachStatus.PENDING
  const isCurrentUser = user?.id === profile.id
  if (!isCurrentUser && !isAuthor && isPending) return null

  return (
    <CoachItem key={profile.id} onClick={onClick} {...props}>
      <ProfileAvatar profile={profile} size={63} />
      <div>
        <div>
          <HUText fontStyle="LL">{`${profile.firstname} ${profile.lastname}`}</HUText>
        </div>
        {status ? (
          isPending && (
            <HUText fontStyle="BS" color={theme.neutralColor}>
              {word("program.sharedCoach.status.pending")}
            </HUText>
          )
        ) : (
          <HUText fontStyle="BS" color={theme.neutralColor600}>
            {word("program.label.author")}
          </HUText>
        )}
      </div>
      {onRemove && isEditing && (
        <CrossIcon onClick={onRemove} color={theme.neutralColor400} style={{ marginLeft: "auto", cursor: "pointer" }} />
      )}
      {!isEditing &&
        isAuthor &&
        (isPending ? (
          <ClockOutlineIcon color={theme.neutralColor} style={{ marginLeft: "auto" }} />
        ) : (
          status === SharedCoachStatus.ACCEPTED && (
            <CheckmarkIcon color={theme.neutralColor700} style={{ marginLeft: "auto" }} />
          )
        ))}
    </CoachItem>
  )
}

const CoachItem = styled.div<{ onClick?: () => void }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  border-radius: 64px;
`
