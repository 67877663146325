import { word } from "@modules/core/utils/i18n"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffStatus, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramTypeOf, ProgramVisibility } from "@modules/programs/programTypes"
import { getApproximatedFormattedNumber } from "@modules/utils/numberUtils"

export const getDisplayVisibility = (visibility: ProgramVisibility): string => {
  switch (visibility) {
    case ProgramVisibility.PRIVATE:
      return word("program.form.visibility.option.private")
    case ProgramVisibility.PUBLIC:
      return word("program.form.visibility.option.public")
  }
}
export const getDisplayStatus = (status: ProgramOnOffStatus): string => {
  switch (status) {
    case ProgramOnOffStatus.DRAFT:
      return word("program.onOff.status.draft")
    case ProgramOnOffStatus.PUBLISHED:
      return word("program.onOff.status.published")
  }
}

export const getSubscriptionCountText = (
  subscriptionCount: number,
  isAuthor: boolean,
  isAcceptedSharedCoach: boolean,
  showSubscribersText = true,
  programType: ProgramTypeOf = ProgramInfiniteTypeOf,
) => {
  const { approximatedFormattedNumber, approximatedNumber } = getApproximatedFormattedNumber(subscriptionCount)
  const subscriptionCountForWording = isAuthor || isAcceptedSharedCoach ? subscriptionCount : approximatedNumber

  const programSubscribersText = `${isAuthor || isAcceptedSharedCoach ? subscriptionCount : approximatedFormattedNumber} ${
    showSubscribersText
      ? word(programType === ProgramOnOffTypeOf ? "global.athlete" : "program.subcribers.name", {
          count: subscriptionCountForWording !== 0 ? subscriptionCountForWording : 1,
        })
      : ""
  }`

  return programSubscribersText
}

export const getDisplayHiddenContent = (isContentHidden: boolean): string => {
  return isContentHidden
    ? word("program.details.sessionContent.hidden")
    : word("program.details.sessionContent.apparent")
}

export const getDurationText = (programDuration: number) => {
  if (programDuration % 7 === 0)
    return `${programDuration / 7} ${word("global.week.label", { count: programDuration / 7 })}`
  else return `${programDuration} ${word("global.day.label", { count: programDuration })}`
}
