import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { ScoreboardRanking } from "@modules/scoreboard/scoreboardTypes"
import { HUText } from "@modules/ui/components/huText"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useGetDisplayedScore } from "@modules/utils/hooks/useUnit"
import styled, { useTheme } from "styled-components"

type Props = { podium: ScoreboardRanking[] }

export const HeaderPodium: React.FC<Props> = ({ podium }) => {
  return (
    <>
      {podium ? (
        <Container>
          <PlacementPodiumRound userScore={podium[1]} size={90} />
          <PlacementPodiumRound userScore={podium[0]} size={125} />
          <PlacementPodiumRound userScore={podium[2]} size={90} />
        </Container>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

type PlacementPodiumRoundProps = {
  userScore: ScoreboardRanking
  size: number
}
const PlacementPodiumRound: React.FC<PlacementPodiumRoundProps> = ({ userScore, size }) => {
  const theme = useTheme()
  const getScore = useGetDisplayedScore()

  return (
    <Card>
      <div className="flex flex-column justify-content-center relative mb-4">
        <ProfileAvatar
          className="mb-1 mr-2 "
          profile={{ avatar: userScore.avatar, username: userScore.firstname || "" }}
          size={size}
          borderColor={theme.neutralColor100}
        />
        <Position>
          <HUText fontStyle="LM" color={theme.mainWhite}>
            {userScore.position}
          </HUText>
        </Position>
      </div>
      <HUText color={theme.neutralColor900} fontStyle="BS">
        {getScore(userScore.score.value, userScore.scoreType)}
      </HUText>
    </Card>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 80px;
  margin-bottom: 15px;
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Position = styled.div`
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  border-radius: 13px;
  height: 26px;
  border: 2px solid ${({ theme }) => theme.neutralColor100};
  background: ${({ theme }) => theme.neutralColor900};
  display: flex;
  justify-content: center;
  align-items: center;
`
