import { Page } from "@betomorrow/micro-stores"
import { BoxPurchase } from "@modules/boxOffer/boxOfferTypes"
import { BoxPurchaseDto, convertAllBoxPurchasesFromDto } from "@modules/boxOffer/services/boxOfferDto"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { Member, MemberDetailed, MemberDetailedDraft, MemberFilter } from "@modules/members/memberTypes"
import {
  MemberDetailedDto,
  MemberDto,
  convertDetailedMemberDraftToDto,
  convertDetailedMemberFromDto,
  convertMemberFromDto,
} from "@modules/members/services/memberDto"
import { Paginated, toPage } from "@modules/utils/paginated"

export class MemberApi {
  private baseUrl = "/members"
  private boxesBaseUrl = "/box/members"

  constructor(private api: ApiClient) {}

  async getMembers(page = 0, size = 10, filter: MemberFilter, nameFilter?: string): Promise<Page<Member>> {
    const result = await this.api.get<Paginated<MemberDto>>(`${this.baseUrl}`, {
      params: { page, size, filter, name: nameFilter },
    })
    const pageResult = toPage(result.data)
    return { ...pageResult, content: pageResult.content.map((member) => convertMemberFromDto(member)) }
  }

  async getMember(memberId: string): Promise<MemberDetailed> {
    const resultDto = await this.api.get<MemberDetailedDto>(`${this.baseUrl}/${memberId}`)
    return convertDetailedMemberFromDto(resultDto.data)
  }

  async getAllMemberPurchases(memberId: string, page = 0, size = 10): Promise<Page<BoxPurchase>> {
    const result = await this.api.get<Paginated<BoxPurchaseDto>>(`${this.boxesBaseUrl}/${memberId}/purchases`, {
      params: { page, size },
    })
    const pageResult = toPage(result.data)

    return {
      ...pageResult,
      content: convertAllBoxPurchasesFromDto([...pageResult.content]),
    }
  }

  async createMember(member: MemberDetailedDraft): Promise<MemberDetailed> {
    const resultDto = await this.api.post<MemberDetailedDto>(`${this.baseUrl}`, convertDetailedMemberDraftToDto(member))
    return convertDetailedMemberFromDto(resultDto.data)
  }

  async updateMember(memberId: string, member: MemberDetailedDraft): Promise<MemberDetailed> {
    const resultDto = await this.api.put<MemberDetailedDto>(
      `${this.baseUrl}/${memberId}`,
      convertDetailedMemberDraftToDto(member),
    )
    return convertDetailedMemberFromDto(resultDto.data)
  }
}
