import _ from "lodash"
import { Toast, ToastMessage } from "primereact/toast"
import React, { createContext, useContext, useRef } from "react"
import styled from "styled-components"

type HUToastContextAction = {
  show: (message: ToastMessage | ToastMessage[]) => void
  clear: () => void
}

export const HUToastContext = createContext<HUToastContextAction>({ show: _.noop, clear: _.noop })

export const HUToast: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const ref = useRef<Toast>(null)
  return (
    <HUToastContext.Provider
      value={{
        show: (message) => {
          ref.current?.show ? ref.current.show(message) : _.noop()
        },
        clear: ref.current?.clear ?? _.noop,
      }}
    >
      <StyledToast ref={ref} position="bottom-right" />
      {children}
    </HUToastContext.Provider>
  )
}

export const useToast = () => useContext(HUToastContext)

const StyledToast = styled(Toast)`
  .p-toast-message .p-toast-message-content {
    align-items: center;
  }
`
