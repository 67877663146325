import { AddressDraft } from "@modules/address/addressTypes"
import { word } from "@modules/core/utils/i18n"
import { requiredInput } from "@modules/form/formUtils"
import * as Yup from "yup"

export type AddressInputKey = "address" | "additionalAddress" | "postalCode" | "city" | "country"

export type AddressFormikType = {
  address: string
  additionalAddress: string
  postalCode: string
  city: string
  country: string
}

export function getAddressValidationSchema(required?: boolean) {
  return Yup.object({
    address: required
      ? requiredInput(Yup.string().max(255, word("form.maximum.characters", { count: 255 })))
      : Yup.string().max(255, word("form.maximum.characters", { count: 255 })),
    additionalAddress: Yup.string().max(255, word("form.maximum.characters", { count: 255 })),
    postalCode: required
      ? requiredInput(Yup.string().max(64, word("form.maximum.characters", { count: 64 })))
      : Yup.string().max(64, word("form.maximum.characters", { count: 64 })),
    city: required
      ? requiredInput(Yup.string().max(64, word("form.maximum.characters", { count: 64 })))
      : Yup.string().max(64, word("form.maximum.characters", { count: 64 })),
    country: required
      ? requiredInput(Yup.string().max(255, word("form.maximum.characters", { count: 255 })))
      : Yup.string().max(255, word("form.maximum.characters", { count: 255 })),
  })
}

export const convertAddressToFormik = (address: AddressDraft): AddressFormikType => {
  return {
    address: address.address ?? "",
    additionalAddress: address.additionalInformation ?? "",
    postalCode: address.postalCode ?? "",
    city: address.city ?? "",
    country: address.country ?? "",
  }
}

export function convertAddressFromFormik(formik: AddressFormikType): AddressDraft {
  return {
    address: formik.address,
    additionalInformation: formik.additionalAddress,
    postalCode: formik.postalCode,
    city: formik.city,
    country: formik.country,
  }
}
