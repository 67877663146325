export function getProjectName() {
  return "HustleUp"
}

export function delay(duration: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, duration)
  })
}

export async function resolveWithDelay<T>(promise: Promise<T>, timeout: number) {
  await Promise.all([promise, delay(timeout)])
  return promise
}

export const getScrollbarWidth = () => {
  const container = document.createElement("div")
  document.body.appendChild(container)
  container.style.overflow = "scroll"
  const inner = document.createElement("div")
  container.appendChild(inner)
  const scrollbarWidth = container.offsetWidth - inner.offsetWidth
  document.body.removeChild(container)
  return scrollbarWidth
}
