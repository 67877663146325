import { MoneyIcon } from "@images/svgIcons/moneyIcon"
import { NotepadIcon } from "@images/svgIcons/notepadIcon"
import { BoxOfferInputKey } from "@modules/boxOffer/boxOfferFormikTypes"
import {
  BOX_OFFER_MINIMUM_PRICE,
  BOX_OFFER_TICKET_BOOKS_MAX,
  BOX_OFFER_TICKET_BOOKS_MIN,
  BOX_OFFER_VALIDITY_MAX,
  BOX_OFFER_VALIDITY_MIN,
  BoxOfferDraft,
  BoxOfferPriceType,
  BoxOfferType,
  BoxOfferValidity,
} from "@modules/boxOffer/boxOfferTypes"
import {
  BoxOfferFormAlertCard,
  BoxOfferFormSection,
  BoxOfferInputCustomLabel,
} from "@modules/boxOffer/components/boxOfferFormSection"
import { useBoxOfferSidebar } from "@modules/boxOffer/hooks/useBoxOfferSidebar"
import { word } from "@modules/core/utils/i18n"
import { currencySymbol, formatCurrency, getCurrencySymbol } from "@modules/currency/currency"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { capitalize } from "@modules/utils/stringUtils"
import { SimpleFormik } from "@src/typings/formik"
import { DropdownChangeEvent } from "primereact/dropdown"
import React, { useMemo } from "react"
import { useTheme } from "styled-components"

export type BoxOfferFormPageProps = {
  isFormFieldInValid: (name: BoxOfferInputKey) => boolean
  getFormErrorMessage: (name: BoxOfferInputKey, errorMessage?: string) => JSX.Element | undefined
  formik: SimpleFormik<BoxOfferDraft, BoxOfferInputKey>
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  handleSubmit: () => void
}

export const BoxOfferFormFirstPage = ({
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  handleSubmit,
}: BoxOfferFormPageProps) => {
  const theme = useTheme()
  const language = useLanguage()
  const { hideSidebar } = useBoxOfferSidebar()
  const priceOptions = [
    { value: BoxOfferPriceType.Free, label: word("offer.form.price_switch.free") },
    { value: BoxOfferPriceType.Paid, label: word("offer.form.price_switch.price") },
  ]

  const displayPrice = useMemo(
    () =>
      ![BoxOfferType.Trial, BoxOfferType.Millenium].includes(formik.values.type) ||
      (BoxOfferType.Trial === formik.values.type && formik.values.priceSwitch === BoxOfferPriceType.Paid),
    [formik.values.priceSwitch, formik.values.type],
  )

  const priceLabel = useMemo(() => {
    return formik.values.type === BoxOfferType.DropIn || formik.values.type === BoxOfferType.Trial
      ? word(`offer.form.${formik.values.type}.price.label`)
      : word("offer.form.price.label")
  }, [formik.values.type])

  return (
    <div className="flex flex-column gap-6">
      <div className="flex flex-column gap-2">
        <HUText fontStyle="TL">{`${word(`offer.form.${formik.values.type}.create`)} (1/2)`}</HUText>
        <HUText fontStyle="BM" color={theme.neutralColor700}>
          {word(`offer.form.${formik.values.type}.subtitle`)}
        </HUText>
      </div>
      <div>
        <BoxOfferFormSection
          title={word("box.offer.form.infos.generalInformation")}
          icon={<NotepadIcon color={theme.mainWhite} />}
          className="mb-5"
        />
        <FormikInput
          flex
          label={word("offer.form.title.label")}
          isRequiredInput
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("name")}
          name="name"
          placeHolder={word("offer.form.title.placeholder")}
          error={getFormErrorMessage("name")}
        />
        <FormikInput
          isRequiredInput
          label={word("offer.form.description.label")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("description")}
          name="description"
          error={getFormErrorMessage("description")}
          type="textarea"
          formikStyle={{ flex: 1 }}
          placeHolder={word("offer.form.description.placeholder")}
        />
      </div>
      {formik.values.type === BoxOfferType.TicketsBook && (
        <div>
          <BoxOfferFormSection
            title={word("box.offer.form.quantity")}
            icon="ticket"
            description={word("offer.form.numberOfSessions.TICKETS_BOOK.description")}
            className="mb-5"
          />
          <FormikInput
            formikStyle={{ flex: 1 }}
            label={word("offer.form.numberOfSessions.title")}
            isRequiredInput
            isInvalid={isFormFieldInValid("count")}
            getFieldProps={formik.getFieldProps}
            name="count"
            error={getFormErrorMessage("count")}
            type="number"
            numberProps={{
              placeholder: word("offer.form.numberOfSessions.title"),
              min: BOX_OFFER_TICKET_BOOKS_MIN,
              max: BOX_OFFER_TICKET_BOOKS_MAX,
            }}
            setFieldValue={formik.setFieldValue}
          />
        </div>
      )}
      <div className="flex flex-column gap-3">
        <BoxOfferFormSection
          title={word("box.offer.form.price.title")}
          icon={<MoneyIcon color={theme.mainWhite} />}
          className="mb-3"
          description={
            formik.values.type !== BoxOfferType.Millenium
              ? word(`box.offer.form.price.${formik.values.type}.subtitle`)
              : undefined
          }
        />
        {formik.values.type === BoxOfferType.Millenium && (
          <div className="flex flex-column gap-5">
            <div className="flex flex-column gap-2">
              <BoxOfferInputCustomLabel label={word("box.offer.form.price.title")} margin="" />
              <BoxOfferFormAlertCard
                title={word("box.offer.form.alert.free.title")}
                description={word("box.offer.form.alert.free.description")}
              />
            </div>
            <div className="flex flex-column gap-2">
              <BoxOfferInputCustomLabel label={word("box.offer.form.minimum_monthly_duration.label")} margin="" />
              <BoxOfferFormAlertCard
                title={word("box.offer.form.alert.commitment.title")}
                description={word("box.offer.form.alert.commitment.description")}
              />
            </div>
          </div>
        )}
        {formik.values.type === BoxOfferType.Trial && (
          <FormikInput
            formikStyle={{ flex: 1 }}
            type="dropdown"
            label={word(`offer.form.TRIAL.price.label`)}
            name="priceSwitch"
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("priceSwitch")}
            error={getFormErrorMessage("priceSwitch")}
            dropdownProps={{
              options: priceOptions,
              value: formik.values.priceSwitch,
              onChange(event: DropdownChangeEvent) {
                if (event.target.value === BoxOfferPriceType.Free) {
                  formik.setFieldValue("price", undefined, false)
                }
              },
            }}
            setFieldValue={formik.setFieldValue}
          />
        )}
        {displayPrice && (
          <FormikInput
            label={priceLabel}
            isRequiredInput
            isInvalid={isFormFieldInValid("price")}
            getFieldProps={formik.getFieldProps}
            name="price"
            error={getFormErrorMessage("price")}
            type="number"
            numberProps={{
              suffix: getCurrencySymbol(formik.values.currency, language),
              step: 0.1,
              min: 0,
              maxFractionDigits: 2,
              minFractionDigits: 2,
              placeholder: word("offer.form.price.placeholder", {
                minPrice: formatCurrency(BOX_OFFER_MINIMUM_PRICE, formik.values.currency, language),
              }),
            }}
            setFieldValue={formik.setFieldValue}
            formikStyle={{ flex: 1, maxWidth: "80%", marginBottom: 0 }}
            rightButton={
              <HUText fontStyle="TM">
                {`${currencySymbol(formik.values.currency, language)} / ${word("global.month.label", { count: 1 })}`}
              </HUText>
            }
          />
        )}
        {/* TODO: API not ready for this feature, we set 1 by default and hide input
        formik.values.type === BoxOfferType.Subscription && (
          <div className="flex flex-column gap-3">
            <FormikInput
              formikStyle={{ flex: 1, maxWidth: "80%" }}
              isRequiredInput
              label={word("box.offer.form.minimum_monthly_duration.label")}
              type="number"
              name="minimumMonthlyDuration"
              getFieldProps={formik.getFieldProps}
              isInvalid={isFormFieldInValid("minimumMonthlyDuration")}
              error={getFormErrorMessage("minimumMonthlyDuration", word("global.form.required"))}
              setFieldValue={formik.setFieldValue}
              numberProps={{
                min: 1,
                max: 24,
                placeholder: word("offer.form.minimumMonthlyDuration.placeholder"),
              }}
              rightButton={
                <HUText fontStyle="TM">
                  {capitalize(word("global.month.label", { count: formik.values.minimumMonthlyDuration ?? 1 }))}
                </HUText>
              }
            />
            <BoxOfferFormAlertCard
              title={word("offer.form.subscription.alert_title")}
              description={word("offer.form.subscription.alert_description")}
            />
          </div>
        )*/}
      </div>
      {(formik.values.type === BoxOfferType.Trial ||
        formik.values.type === BoxOfferType.TicketsBook ||
        formik.values.type === BoxOfferType.DropIn) && (
        <div>
          <BoxOfferFormSection
            title={word("box.offer.form.validity.title")}
            icon="calendar"
            description={word(`box.offer.form.validity.${formik.values.type}.description`)}
            className="mb-5"
          />
          <FormikInput
            formikStyle={{ flex: 1 }}
            label={word("box.offer.form.validity.title")}
            type="dropdown"
            name="validitySwitch"
            getFieldProps={formik.getFieldProps}
            setFieldValue={formik.setFieldValue}
            isInvalid={isFormFieldInValid("validitySwitch")}
            dropdownProps={{
              options: Object.values(BoxOfferValidity).map((val) => ({
                label: word(`box.offer.form.infos.validity.types.${val}`),
                value: val,
              })),
              value: formik.values.validitySwitch,
              onChange(event: DropdownChangeEvent) {
                if (event.target.value === BoxOfferValidity.UNLIMITED) {
                  formik.setFieldValue("validityPeriodMonths", undefined, false)
                }
              },
            }}
          />
          {formik.values.validitySwitch === BoxOfferValidity.DEFINED && (
            <FormikInput
              formikStyle={{ flex: 1 }}
              isInvalid={isFormFieldInValid("validityPeriodMonths")}
              getFieldProps={formik.getFieldProps}
              name="validityPeriodMonths"
              error={getFormErrorMessage("validityPeriodMonths")}
              type="number"
              numberProps={{
                min: BOX_OFFER_VALIDITY_MIN,
                max: BOX_OFFER_VALIDITY_MAX,
                placeholder: word("offer.form.minimumMonthlyDuration.placeholder"),
              }}
              setFieldValue={formik.setFieldValue}
              rightButton={
                <HUText fontStyle="TM">
                  {capitalize(word("global.month.label", { count: formik.values.validityPeriodMonths ?? 1 }))}
                </HUText>
              }
            />
          )}
        </div>
      )}
      <div className="flex gap-5 mt-5">
        <HUButton
          text={word("global.cancel")}
          onClick={() => hideSidebar()}
          type="Rounded"
          size="M"
          colorType="Quaternary"
          fullWidth
        />
        <HUButton
          text={word("global.next")}
          onClick={handleSubmit}
          type="Right_Icon"
          size="M"
          colorType="Primary"
          fullWidth
          icon={{
            iconView: <span className={`pi pi-arrow-right`} style={{ color: theme.mainWhite }} />,
          }}
        />
      </div>
    </div>
  )
}
