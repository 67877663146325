import { useTheme } from "styled-components"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { BoxFormPageNumber } from "@modules/box/components/form/boxFormPageNumber"
import { BoxFormHeader } from "@modules/box/components/form/boxFormHeader"
import { ReactNode } from "react"

type BoxFormTemplatePageProps = {
  onPrevious: () => void
  handleSubmit: () => void
  disabled: boolean
  pageNumber?: number
  children: ReactNode
  header: {
    title: string
    subtitle?: string
    description?: string
    children?: ReactNode
  }
}
export const BoxFormTemplatePage = ({
  onPrevious,
  handleSubmit,
  disabled,
  pageNumber,
  children,
  header,
}: BoxFormTemplatePageProps) => {
  const theme = useTheme()
  return (
    <>
      <BoxFormHeader title={header.title} subtitle={header.subtitle} description={header.description}>
        {header.children}
      </BoxFormHeader>
      <div style={{ maxWidth: "32.5rem" }} className="w-full">
        {children}
      </div>
      <div className="flex align-items-center w-full flex-column sm:flex-row row-gap-3">
        <div className="flex gap-2 w-full justify-content-center">
          <HUButton
            type="Left_Icon"
            size="M"
            colorType="Quaternary"
            text={word("global.previous")}
            onClick={onPrevious}
            icon={{
              iconView: <span className={`pi pi-arrow-left`} style={{ color: theme.mainWhite }} />,
            }}
          />
          <HUButton
            type="Right_Icon"
            size="M"
            colorType="Primary"
            text={word("global.continue")}
            onClick={handleSubmit}
            disabled={disabled}
            submit
            icon={{
              iconView: (
                <span
                  className={`pi pi-arrow-right`}
                  style={{ color: disabled ? theme.neutralColor300 : theme.mainWhite }}
                />
              ),
            }}
          />
        </div>
        {pageNumber && <BoxFormPageNumber pageNumber={pageNumber} disabled={disabled} />}
      </div>
    </>
  )
}
