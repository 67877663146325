import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { useToast } from "@modules/ui/components/huToast"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { minScreenWidth } from "@modules/ui/uiConstants"
import _ from "lodash"
import React, { createContext, useContext, useImperativeHandle, useMemo, useRef, useState } from "react"

type HUConfirmPopupProps = {
  accept: () => Promise<void>
  cancel?: () => Promise<void>
  handleErrorMessage?: (e: any) => string
  acceptValidated?: string
  title?: string | React.ReactNode
  message?: string | React.ReactNode
  headerStyle?: React.CSSProperties | undefined
  footerProps?: {
    confirmText?: string
    cancelText?: string
    confirmClassName?: string
    cancelClassName?: string
    footerClassName?: string
    hideCancelIcon?: boolean
    hideConfirmIcon?: boolean
    align?: boolean
  }
  width?: string
}

type HUConfirmPopupContextAction = {
  show: (props: HUConfirmPopupProps) => void
}

export const HUConfirmPopupContext = createContext<HUConfirmPopupContextAction>({
  show: _.noop,
})

const InternalConfirmPopup = React.forwardRef<HUConfirmPopupContextAction>(({}, ref) => {
  const toast = useToast()
  const [popupProps, setPopupProps] = useState<HUConfirmPopupProps | null>(null)
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      show: (props: HUConfirmPopupProps) => {
        setPopupProps(props)
        setVisible(true)
      },
    }
  }, [])
  const confirmText = popupProps?.footerProps?.confirmText ?? word("global.yes")
  const cancelText = popupProps?.footerProps?.cancelText ?? word("global.cancel")
  const confirmClassName = popupProps?.footerProps?.confirmClassName ?? "p-button-text"
  const cancelClassName = popupProps?.footerProps?.cancelClassName ?? "p-button-text"
  const footerClassName =
    popupProps?.footerProps?.footerClassName ??
    (popupProps?.footerProps?.align ? "flex flex-row justify-content-around" : undefined)
  const width = popupProps?.width ?? minScreenWidth

  const footer = useMemo(
    () => (
      <div className={footerClassName}>
        {!popupProps?.footerProps?.hideCancelIcon && (
          <HUButton
            type="Default"
            size="M"
            colorType="Quaternary"
            text={cancelText}
            onClick={async () => {
              setVisible(false)
              if (popupProps?.cancel) await popupProps.cancel()
            }}
            className={cancelClassName}
          />
        )}
        <HUButton
          type="Default"
          size="M"
          colorType="Primary"
          className={`${confirmClassName}`}
          text={confirmText}
          autoFocus
          onClick={async () => {
            setLoading(true)
            try {
              await popupProps?.accept()
              {
                popupProps?.acceptValidated &&
                  toast.show({
                    severity: "info",
                    summary: word("global.confirmed"),
                    detail: popupProps.acceptValidated,
                  })
              }
            } catch (e: any) {
              toast.show({
                severity: "error",
                summary: popupProps?.handleErrorMessage?.(e) ?? e.message,
              })
            } finally {
              setVisible(false)
              setLoading(false)
            }
          }}
          disabled={loading}
          loading={loading}
        />
      </div>
    ),
    [popupProps, loading],
  )

  return (
    <HUDialog
      visible={visible}
      header={popupProps?.title ?? word("global.confirm.title")}
      headerStyle={popupProps?.headerStyle}
      footer={footer}
      onHide={async () => {
        if (popupProps?.cancel) await popupProps.cancel()
        setVisible(false)
      }}
      style={{ width }}
    >
      {typeof popupProps?.message === "string" || !popupProps?.message ? (
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-circle mr-3" style={{ fontSize: "2rem" }} />
          <span>{popupProps?.message ?? word("global.confirm.message")}</span>
        </div>
      ) : (
        popupProps.message
      )}
    </HUDialog>
  )
})

export const HUConfirmPopup: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const internalRef = useRef<HUConfirmPopupContextAction>(null)

  return (
    <HUConfirmPopupContext.Provider value={{ show: (props) => internalRef.current?.show(props) }}>
      <InternalConfirmPopup ref={internalRef} />
      {children}
    </HUConfirmPopupContext.Provider>
  )
}

export const useConfirmPopup = () => useContext(HUConfirmPopupContext)
