import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ClockOutlineIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="10.25" stroke={props.color} strokeWidth="1.5" />
    <path
      d="M12 8.25V11.9007C12 12.4276 12.2764 12.9159 12.7283 13.187L14.5 14.25"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
