import { BoxSlotSessionFormikType } from "@modules/boxSlot/boxSessionFormikTypes"
import { ChronoType } from "@modules/chrono/chronoTypes"
import { GetChronoDefaults, MAX_NUMBER_INPUT, MIN_NUMBER_INPUT } from "@modules/chrono/chronoUtils"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import {
  ProgramInfiniteSessionFormikType,
  ProgramOnOffSessionFormikType,
} from "@modules/programSession/programSessionFormikType"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import { useEffect } from "react"
import styled from "styled-components"

type ChronoSettingsEMOMFormProps = {
  index: number
  formik: SimpleFormik<ProgramInfiniteSessionFormikType | ProgramOnOffSessionFormikType | BoxSlotSessionFormikType, any>
}

export const ChronoSettingsEMOMForm: React.FC<ChronoSettingsEMOMFormProps> = ({ index, formik }) => {
  const formikNameBase = `exercises[${index}].chronoEmom`
  const formikValue = formik.values.exercises[index].chronoEmom

  useEffect(() => {
    if (!formikValue) {
      formik.setFieldValue(formikNameBase, GetChronoDefaults(ChronoType.EMOM))
    }
  }, [])

  if (index === null) return <></>

  return (
    <HUStack>
      <EMOMContainer>
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1 }}
          textLabel={word("chrono.settings.label.training_time")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid(`${formikNameBase}.trainingTime`, formik)}
          error={getFormErrorMessage(`${formikNameBase}.trainingTime`, formik)}
          name={`${formikNameBase}.trainingTime`}
          setFieldValue={formik.setFieldValue}
          type="maskedInput"
          maskedInputProps={{
            placeholder: "00min 00sec",
            slotChar: "00min 00sec",
            mask: "99min 99sec",
            onChange: (e) => {
              formik.setFieldValue(`${formikNameBase}.trainingTime`, e.target.value)
            },
          }}
        />
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1 }}
          textLabel={word("chrono.settings.label.rounds")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid(`${formikNameBase}.numberOfRounds`, formik)}
          error={getFormErrorMessage(`${formikNameBase}.numberOfRounds`, formik)}
          setFieldValue={formik.setFieldValue}
          name={`${formikNameBase}.numberOfRounds`}
          type="numberChrono"
          numberProps={{
            max: MAX_NUMBER_INPUT,
            min: MIN_NUMBER_INPUT,
            showButtons: false,
          }}
        />
        {formik.getFieldProps(`${formikNameBase}.numberOfRounds`).value >= 1 && (
          <>
            <FormikInput
              flex
              formikStyle={{ display: "flex", flexGrow: 1 }}
              textLabel={word("chrono.settings.label.rest_between_sets")}
              getFieldProps={formik.getFieldProps}
              isInvalid={isFormFieldInValid(`${formikNameBase}.restBetweenSetsTime`, formik)}
              error={getFormErrorMessage(`${formikNameBase}.restBetweenSetsTime`, formik)}
              name={`${formikNameBase}.restBetweenSetsTime`}
              type="maskedInput"
              maskedInputProps={{
                placeholder: "00min 00sec",
                slotChar: "00min 00sec",
                mask: "99min 99sec",
                onChange: (e) => {
                  formik.setFieldValue(`${formikNameBase}.restBetweenSetsTime`, e.target.value)
                },
              }}
            />
            <FormikInput
              flex
              formikStyle={{ display: "flex", flexGrow: 1 }}
              textLabel={word("chrono.settings.label.number_sets")}
              getFieldProps={formik.getFieldProps}
              isInvalid={isFormFieldInValid(`${formikNameBase}.numberOfSets`, formik)}
              error={getFormErrorMessage(`${formikNameBase}.numberOfSets`, formik)}
              setFieldValue={formik.setFieldValue}
              name={`${formikNameBase}.numberOfSets`}
              type="numberChrono"
              numberProps={{
                max: MAX_NUMBER_INPUT,
                min: MIN_NUMBER_INPUT,
                showButtons: false,
              }}
            />
          </>
        )}
      </EMOMContainer>
    </HUStack>
  )
}

const EMOMContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
`
