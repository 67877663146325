import Vector from "@images/background/vector.svg"
import CongratCard from "@images/box/congratCard.svg"
import { LogoHustleUpSmall } from "@images/logoHustleUpSmall"
import { SmallArrowIcon } from "@images/svgIcons/arrowIcon"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { OnboardingWrapper } from "@modules/owner/boxSettings/components/onboardingWrapper"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"

/**
 * This page is used when the owner has successfully registered on Stripe
 * Stripe calls this page as a callback
 * @returns {React.ReactNode} - Wrapper & Header & content & button
 */
export const BoxOnboardingStripeReturn: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const handleClose = () => {
    navigate(Path.Box.Admin)
  }

  return (
    <OnboardingWrapper backgroundImage={Vector} handleClose={handleClose}>
      <div className="w-full">
        {/* HEARDER */}
        <div className="flex align-items-center flex-column w-full mt-5">
          <div>
            <LogoHustleUpSmall color={theme.mainWhite} />
          </div>
          <HUText fontStyle="HL" color={theme.mainWhite} className="mt-3">
            {word("box.stripe.onboarding.return.title")}
          </HUText>
          <HUText fontStyle="BL" color={theme.mainWhite} className="mt-1">
            {word("box.stripe.onboarding.return.subtitle1")}
          </HUText>
          <HUText fontStyle="BL" color={theme.mainWhite}>
            {word("box.stripe.onboarding.return.subtitle2")}
          </HUText>
          {/* <HUText fontStyle="BL" color={theme.mainWhite}>
            {word("box.stripe.onboarding.return.subtitle3")}
          </HUText> */}
        </div>
        {/* CONTENT */}
        <div className="flex justify-content-center mt-7 mb-8 gap-3">
          <img src={CongratCard} alt="OnboardingBoxStripe" width={180} height={180} />
        </div>
        {/* BUTTON */}
        <div className="flex flex-column align-items-center">
          <HUButton
            type="Right_Icon"
            size="M"
            colorType="Primary"
            icon={{ icon: () => <SmallArrowIcon color={theme.neutralColor900} width={14} height={14} /> }}
            text={word("box.stripe.onboarding.return.button")}
            onClick={() => {
              navigate(Path.Box.Admin)
            }}
          />
        </div>
      </div>
    </OnboardingWrapper>
  )
}
