export const equalsInsensitive = (value1: string, value2: string) => {
  return (value1 || "").localeCompare(value2 || "", undefined, { sensitivity: "base" }) === 0
}

export const pointString = " • "

export const concatWithSpace = (values: string[]) => {
  return values.reduce<string>((acc, value) => {
    if (value) {
      return acc.concat(!!acc ? " " : "", value)
    }
    return acc
  }, "")
}

export const capitalize = (value: string) => value.replace(/[a-zA-Z]/, (char) => char.toUpperCase())

export const capitalizeCityName = (value: string) =>
  value
    .toLowerCase()
    .split("-")
    .map((i) => i.replace(/[a-zA-Z]/, (char) => char.toUpperCase()))
    .join("-")

/**
 * Capitalize the first letter of a string and lower case the rest Ex: "hello" => "Hello" or "MALE" => "Male"
 * @param {string} text
 * @returns {string}
 */
export const capitalizeAndLowercase = (text: string) => {
  return `${text[0].toUpperCase()}${text.slice(1).toLocaleLowerCase()}`
}

export const shortenText = (text: string, length: number): string => {
  return text ? (text.length > length ? text.substring(0, length) + "..." : text) : ""
}

