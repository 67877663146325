import { getDurationFromSecond } from "@modules/utils/dateUtils"
import dayjs from "dayjs"

export type DurationUnit = "seconds" | "minutes" | "hours"

export function isAHour(durationInS: number) {
  return durationInS >= 3600
}

export function isAMinute(durationInS: number) {
  return durationInS >= 60
}

export function convertDuration4Digits(duration: number, options?: { unitMinutes?: string }) {
  const hasHour = isAHour(duration)
  return convertDurationToString(duration, { ...options, hideSeconds: hasHour, unitHours: "h", noPadStart: hasHour })
}
/***
 * durationInS:seconds
 */
export const convertDurationToString = (
  durationInS: number,
  options?: {
    hideHours?: boolean
    hideMinutes?: boolean
    hideSeconds?: boolean
    unitHours?: string
    unitMinutes?: string
    unitSeconds?: string
    noPadStart?: boolean
  },
): string => {
  if (durationInS >= 3600 * 101) {
    return "∞"
  }

  const hasHour = isAHour(durationInS)
  const hasMinutes = isAMinute(durationInS)

  let format = ""
  if (hasHour && !options?.hideHours) {
    if (options?.noPadStart) {
      format += "H"
    } else {
      format += "HH"
    }
    if (!(options?.hideMinutes && options.hideSeconds) || options.unitHours) {
      format += `[${options?.unitHours ?? ":"}]`
    }
  }

  if (!options?.hideMinutes) {
    if (!hasHour && options?.noPadStart) {
      format += "m"
    } else {
      format += "mm"
    }
    if (!options?.hideSeconds || options.unitMinutes) {
      format += `[${options?.unitMinutes ?? ":"}]`
    }
  }

  if (!options?.hideSeconds) {
    if (!hasMinutes && options?.hideMinutes && options.noPadStart) {
      format += "s"
    } else {
      format += "ss"
    }
    format += options?.unitSeconds ? `[${options.unitSeconds}]` : ""
  }

  const duration = getDurationFromSecond(durationInS)
  const hours = Math.trunc(duration.asHours())

  if (hours >= 24) {
    const dateString = duration.format(format)
    const regexHours = new RegExp(`[0-9]*${options?.unitHours ?? ":"}`)
    return dateString.replace(regexHours, `${hours}${options?.unitHours ?? ":"}`)
  }

  return duration.format(format)
}

export const convertDurationToDate = (duration: number, format: DurationUnit) =>
  dayjs().startOf("day").add(duration, format).toDate()

export const convertDateToDuration = (date: Date, format: DurationUnit) => {
  const now = dayjs().startOf("day")
  const givenTime = dayjs(date)
  const duration = givenTime.diff(now, format)
  return isNaN(duration) ? 0 : duration
}

const formatDigitNumber = (value: number): string => {
  if (value < 10) return `0${value}`
  else return value.toString()
}

export const convertTimeValueToTimeString = (value: number) => {
  return `${formatDigitNumber(Math.floor(value / 60))}min ${formatDigitNumber(value % 60)}sec`
}

export const convertTimeStringToTimeValue = (format: "HH:mm" | "mm:ss", value?: string | null) => {
  if (typeof value === "undefined" || value === null) return null

  if (value === "") return 0

  // Split the input string by space
  const parts = value.split(" ")

  // Extract minutes and seconds parts
  if (format === "mm:ss") {
    const minutes = parseInt(parts[0], 10)
    const seconds = parseInt(parts[1], 10)

    // Calculate total seconds
    return minutes * 60 + seconds
  } else {
    const hours = parseInt(parts[0], 10)
    const minutes = parseInt(parts[1], 10)

    // Calculate total minutes
    return hours * 60 + minutes
  }

  return 0
}
