import { User } from "@modules/auth/user"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { ProfileFormikForm } from "@modules/profile/components/profileFormikForm"
import {
  UserFormikType,
  convertUserFromFormikToUserDraft,
  convertUserToFormik,
  getValidationSchema,
} from "@modules/profile/userFormikType"
import { HUButton } from "@modules/ui/components/huButton"
import { useToast } from "@modules/ui/components/huToast"
import { useUnit } from "@modules/utils/hooks/useUnit"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useDraft } from "@modules/utils/useDraft"
import { useFormik } from "formik"
import { useState } from "react"
import styled from "styled-components"

type Props = {
  user: User
}

export const ProfileForm: React.FC<Props> = ({ user }) => {
  const { draft: userDraft } = useDraft(() => user)
  const { userService, profileService } = useServicesContext()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const unit = useUnit()

  const formik = useFormik<UserFormikType>({
    initialValues: convertUserToFormik(userDraft, unit),
    validationSchema: getValidationSchema(),
    onSubmit: async () => {
      try {
        setLoading(true)
        profileService.setUnit(formik.values.unit)
        await userService.update(convertUserFromFormikToUserDraft(formik.values))
        toast.show({
          severity: "success",
          summary: word("profile.page.toast.success"),
        })
      } catch (e: any) {
        console.error(LoggerType.Profile + "update profile", e)
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <ProfileFormContainer>
      <form className="flex flex-column flex-1">
        <ProfileFormikForm
          setLoadingAvatar={setLoadingAvatar}
          isFormFieldInValid={(input) => isFormFieldInValid(input, formik)}
          getFormErrorMessage={(input, errorMessage) => getFormErrorMessage(input, formik, { errorMessage })}
          formik={{
            values: formik.values,
            getFieldProps: formik.getFieldProps,
            setFieldValue: formik.setFieldValue,
          }}
        />
        <ButtonContainer>
          <HUButton
            loading={loading}
            disabled={loadingAvatar}
            text={word("global.save")}
            onClick={() => formik.submitForm()}
            colorType="Primary"
            type="Default"
            size="M"
          />
        </ButtonContainer>
      </form>
    </ProfileFormContainer>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ProfileFormContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 350px;
`
