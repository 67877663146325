import { BoxProgrammationImportProgramSelection } from "@modules/boxB2BProgram/components/boxProgrammationImportProgramSelection"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"

export const useProgrammationSessionImportSidebar = () => {
  const { sidebarNavigateTo } = useSidebar()

  const navigateToImportProgramSelection = (programmationId: string, startDate: string, endDate: string) => {
    sidebarNavigateTo({
      content: (
        <BoxProgrammationImportProgramSelection
          programmationId={programmationId}
          startDate={startDate}
          endDate={endDate}
        />
      ),
      replaceAllComponents: true,
    })
  }

  return { navigateToImportProgramSelection }
}
