import { word } from "@modules/core/utils/i18n"
import { Currency } from "@modules/currency/currency"
import { requiredInput } from "@modules/form/formUtils"
import { ProgramInfinite, ProgramInfiniteDraft, ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff } from "@modules/programs/programOnOffTypes"
import { ProgramVisibility, SharedCoach } from "@modules/programs/programTypes"
import { HUImage } from "@modules/types/imageTypes"
import * as Yup from "yup"

export type ProgramInfiniteInputKey =
  | "image"
  | "title"
  | "visibility"
  | "description"
  | "defaultHideSessionContent"
  | "entranceCheckEnabled"
  | "hideScoreboard"
  | "sharedCoaches"

export function getValidationSchema(options?: { imageRequired: boolean }) {
  return Yup.object({
    image: options?.imageRequired ? Yup.mixed().required(word("program.form.uploadImage.requiredText")) : Yup.object(),
    title: requiredInput(
      Yup.string()
        .max(255, word("form.maximum.characters", { count: 255 }))
        .trim(),
    ),
    description: requiredInput(Yup.string().trim()),
  })
}

export const convertProgramDraftToFormik = (program: ProgramInfiniteDraft | undefined): ProgramFormikType => {
  return {
    image: program?.image,
    title: program?.name ?? "",
    description: program?.description ?? "",
    visibility: program?.visibility === ProgramVisibility.PUBLIC,
    defaultHideSessionContent: program?.defaultHideSessionContent ?? false,
    hideScoreboard: program?.hideScoreboard ?? false,
    entranceCheckEnabled: program?.entranceCheckEnabled ?? false,
    sharedCoaches: program?.sharedCoaches,
  }
}

export function getProgramPriceValidationSchema() {
  return Yup.object({
    value: Yup.number().required(word("global.form.required")).positive(word("global.form.positive")),
    currency: Yup.string().required(),
    trialDuration: Yup.number().when("addFreeTrial", {
      is: (val: boolean) => val,
      then: () =>
        Yup.number()
          .min(1, word("program.duration.minimum.message"))
          .max(365, word("program.duration.maximum.message"))
          .required(word("global.form.required"))
          .integer(),
      otherwise: () => Yup.number().nullable(),
    }),
  })
}

export const convertProgramToPriceFormik = (
  program: ProgramInfinite | ProgramOnOff | undefined,
): ProgramPriceFormikType => {
  return program?._programType === ProgramInfiniteTypeOf
    ? {
        value: program.price?.value,
        currency: program.price?.currency || "EUR",
        addFreeTrial: !!program.trialDuration,
        trialDuration: program.trialDuration,
      }
    : {
        value: program?.price?.value,
        currency: program?.price?.currency || "EUR",
        addFreeTrial: false,
      }
}

export const convertProgramFormFormik = (formik: ProgramFormikType): ProgramInfiniteDraft => {
  return {
    _programType: ProgramInfiniteTypeOf,
    image: formik.image,
    name: formik.title,
    visibility: formik.visibility ? ProgramVisibility.PUBLIC : ProgramVisibility.PRIVATE,
    description: formik.description,
    defaultHideSessionContent: formik.defaultHideSessionContent,
    hideScoreboard: formik.hideScoreboard,
    entranceCheckEnabled: formik.entranceCheckEnabled,
    sharedCoaches: formik.sharedCoaches,
  }
}

export type ProgramFormikType = {
  image?: HUImage
  title: string
  description: string
  visibility: boolean
  defaultHideSessionContent: boolean
  hideScoreboard: boolean
  entranceCheckEnabled: boolean
  sharedCoaches?: SharedCoach[]
}

export type ProgramPriceFormikType = {
  value?: number
  currency: Currency
  addFreeTrial: boolean
  trialDuration?: number
}
