import { FetchStrategy } from "@betomorrow/micro-stores"
import BoxProgrammationEmpty from "@images/empty/programmations.svg"
import BoxPlanningVectors from "@images/svgIcons/boxPlanningVectors.svg"
import { CalendarIcon } from "@images/svgIcons/calendarIcon"
import { GroupIcon } from "@images/svgIcons/groupIcon"
import { PrivateIcon } from "@images/svgIcons/privateIcon"
import { ScoreboardIcon } from "@images/svgIcons/scoreboardIcon"
import { TimeTableIcon } from "@images/svgIcons/timeTableIcon"
import { BoxPlanningCalendar } from "@modules/boxPlanning/components/boxPlanningCalendar"
import { BoxPlanningHeader } from "@modules/boxPlanning/components/boxPlanningHeader/boxPlanningHeader"
import { useAllBoxProgrammations } from "@modules/boxPlanning/hooks/useBoxPlanningProgrammations"
import { useBoxProgrammation } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { useBoxProgrammationSidebar } from "@modules/boxProgrammation/hooks/useBoxProgrammationSidebar"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { minScreenWidth } from "@modules/ui/uiConstants"
import { useParams } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const BoxPlanningContent: React.FC = () => {
  const theme = useTheme()
  const programmationsAsync = useAllBoxProgrammations(FetchStrategy.Once)
  const { boxProgrammationId } = useParams()
  const showEmptyView = !programmationsAsync.loading && programmationsAsync.result.length === 0

  const { result: boxProgrammation } = useBoxProgrammation(boxProgrammationId)

  const { navigateToBoxProgrammationCreation } = useBoxProgrammationSidebar()

  return (
    <>
      {showEmptyView ? (
        <div className="card flex-grow-1 flex flex-column justify-content-around px-8" style={{ borderRadius: 33 }}>
          <HUStack>
            <HUText fontStyle="HL">{word("box.planning.empty.title")}</HUText>
            <HUText fontStyle="BM">{word("box.planning.empty.subtitle")}</HUText>
          </HUStack>

          <HUStack className="justify-content-center align-items-center xl:flex-row ">
            <HUStack className="flex-grow-1">
              <HUText fontStyle="TM" className="mt-5 xl:mt-3">
                {word("box.planning.empty.possibilities")}
              </HUText>
              <EmptyViewPossibility
                label={word("box.planning.empty.single_or_repeated")}
                icon={<CalendarIcon color={theme.mainWhite} height={30} width={30} />}
              />
              <EmptyViewPossibility
                label={word("box.planning.empty.community")}
                icon={<GroupIcon color={theme.mainWhite} height={30} width={30} />}
              />
              <EmptyViewPossibility
                label={word("box.planning.empty.limited_access")}
                icon={<PrivateIcon color={theme.mainWhite} height={30} width={30} />}
              />
              <EmptyViewPossibility
                label={word("box.planning.empty.timetable")}
                icon={<TimeTableIcon color={theme.mainWhite} height={30} width={30} />}
              />
              <EmptyViewPossibility
                label={word("box.planning.empty.scoreboard")}
                icon={<ScoreboardIcon color={theme.mainWhite} height={30} width={30} />}
              />
            </HUStack>
            <div className="flex-grow-1">
              <img src={BoxProgrammationEmpty} width={400} height={300} />
            </div>
          </HUStack>
          <HUButton
            type="Left_Icon"
            colorType="Primary"
            size="L"
            icon={{
              iconView: <span className={`p-button-icon p-c pi pi-plus`} style={{ color: theme.mainWhite }} />,
            }}
            className="align-self-center"
            onClick={navigateToBoxProgrammationCreation}
            text={word("box.planning.empty.create_activity")}
          />
        </div>
      ) : (
        <Container className="grid flex-grow-1">
          <div className="flex-grow-1 flex flex-column" style={{ minWidth: minScreenWidth }}>
            <BoxPlanningHeader boxProgrammation={boxProgrammation} />

            <BoxPlanningCalendar boxProgrammation={boxProgrammation} isLoadingSessions={programmationsAsync.loading} />
          </div>
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  min-width: 980px;
`

const EmptyViewPossibility: React.FC<{ icon: React.ReactElement; label: string }> = ({ label, icon }) => {
  return (
    <HURow className="align-items-center" gap={10}>
      <EmptyViewPossibilityImage>{icon}</EmptyViewPossibilityImage>
      <HUText fontStyle="BM">{label}</HUText>
    </HURow>
  )
}

const EmptyViewPossibilityImage = styled.div`
  width: 36px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${BoxPlanningVectors});
  background-size: cover;
  background-position: center;
`
