import { ApiClient } from "@modules/core/api/client/apiClient"
import { ConfigService } from "@modules/core/services/configService"
import { TrainingContentDto, convertTrainingContentFromDto } from "@modules/training/services/trainingDto"
import { AvailableTrainingContentTypes, TrainingContent } from "@modules/training/trainingTypes"
import { defaultDateFormat } from "@modules/utils/dateUtils"

import dayjs from "dayjs"
import _ from "lodash"

export class TrainingApi {
  private baseUrl = "/trainings"

  constructor(private api: ApiClient) {}

  async getTrainingContents(startDate: string, endDate: string): Promise<TrainingContent[]> {
    const numberOfDays = dayjs(endDate).diff(dayjs(startDate), "day")
    const result: any = []

    for (let i = 0; i <= numberOfDays; i++) {
      const dailyResult = await this.api.get<TrainingContentDto[]>(
        `${ConfigService.config.BASE_URL.replace("/web", "")}/mobile${this.baseUrl}`,
        {
          params: {
            date: dayjs(startDate).add(i, "day").format(defaultDateFormat),
          },
        },
      )

      _.compact(
        dailyResult.data
          .filter((d: any) => AvailableTrainingContentTypes.includes(d.type))
          .map((trainingContent: any) => convertTrainingContentFromDto(trainingContent)),
      ).forEach((t: any) => {
        result.push(t)
      })
    }

    return result

    // const result = await this.api.get<TrainingContentDto[]>(this.baseUrl, {
    //   params: {
    //     date: dayjs(startDate).format(defaultDateFormat),
    //     endDate: dayjs(endDate).format(defaultDateFormat),
    //   },
    // })

    // return result.data
    //   .filter((sessionDto: any) => AvailableTrainingContentTypes.includes(sessionDto.type))
    //   .map((sessionDto: any) => convertTrainingContentFromDto(sessionDto))
    //   .filter((sessionDto) => typeof sessionDto !== "undefined")
  }

  async getTrainingContentDate(date: string): Promise<TrainingContent[]> {
    const result = await this.api.get<TrainingContentDto[]>(
      `${ConfigService.config.BASE_URL.replace("/web", "")}/mobile${this.baseUrl}`,
      {
        params: {
          date: dayjs(date).format(defaultDateFormat),
        },
      },
    )
    return _.compact(
      result.data
        .filter((d) => AvailableTrainingContentTypes.includes(d.type))
        .map((trainingContent) => convertTrainingContentFromDto(trainingContent)),
    )
  }
}
