import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxProgrammationTemplateCalendarEvent } from "@modules/boxProgrammation/components/boxProgrammationTemplateCalendarEvent"
import {
  useBoxSlotTemplatesBeingCreated,
  useBoxSlotTemplatesEvents,
} from "@modules/boxSlotTemplate/hooks/useBoxSlotTemplates"
import { useBoxSlotTemplateSidebar } from "@modules/boxSlotTemplate/hooks/useBoxSlotTemplateSidebar"
import { Calendar } from "@modules/calendar/calendar"
import { SlotTemplateEventType } from "@modules/calendar/calendarTypes"
import { word } from "@modules/core/utils/i18n"
import { Dayjs } from "dayjs"
import { useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"

export const BoxProgrammationTemplateCalendar: React.FC<{
  boxProgrammation: BoxProgrammation | null
}> = ({ boxProgrammation }) => {
  const { boxProgrammationId } = useParams()
  const { boxSlotTemplatesEvents, loading: isLoadingSlotTemplates } = useBoxSlotTemplatesEvents()

  const { navigateToBoxSlotTemplateEdition, navigateToBoxSlotTemplateCreation } = useBoxSlotTemplateSidebar()
  const boxSlotTemplatesBeingCreated = useBoxSlotTemplatesBeingCreated()

  const slotTemplateIds = useMemo(
    () => boxSlotTemplatesEvents.map((slotTemplate) => slotTemplate.id),
    [boxSlotTemplatesEvents],
  )

  const filteredSlotTemplates = useMemo(() => {
    return boxSlotTemplatesEvents.filter(
      (slotTemplateEvent) =>
        boxSlotTemplatesBeingCreated.findIndex(
          (boxSlotTemplateBeingCreated) => boxSlotTemplateBeingCreated.id === slotTemplateEvent.id,
        ) < 0,
    )
  }, [JSON.stringify(slotTemplateIds), boxProgrammationId, boxSlotTemplatesBeingCreated])

  const onAddEventClick = async (value?: Dayjs | number) => {
    if (value && typeof value === "object" && boxProgrammation)
      navigateToBoxSlotTemplateCreation(boxProgrammation, value)
  }

  const isEventFromCurrentProgrammation = useCallback(
    (event: SlotTemplateEventType) => {
      return event.programmationId && boxProgrammation ? event.programmationId === boxProgrammation.id : false
    },
    [boxProgrammation],
  )

  const isEventOverlayed = useCallback(
    (event: SlotTemplateEventType) => {
      return boxSlotTemplatesBeingCreated.length > 0
        ? boxSlotTemplatesBeingCreated.findIndex(
            (boxSlotTemplateBeingCreated) => boxSlotTemplateBeingCreated.id === event.id,
          ) >= 0
        : isEventFromCurrentProgrammation(event)
    },
    [boxSlotTemplatesBeingCreated],
  )

  return (
    <Container className="flex flex-column align-content-center flex-grow-1">
      <Calendar
        calendarEvents={[...filteredSlotTemplates, ...boxSlotTemplatesBeingCreated]}
        canAddEvents={true}
        onAddEventClick={onAddEventClick}
        addEventSubtitle={word("planning.addSlot.button")}
        loading={isLoadingSlotTemplates}
        customRenderEvent={(_, event) => {
          return (
            <BoxProgrammationTemplateCalendarEvent
              event={event as SlotTemplateEventType}
              key={event.id}
              onClickEvent={() => boxProgrammation && navigateToBoxSlotTemplateEdition(boxProgrammation, event.id)}
              canBeClicked={
                isEventFromCurrentProgrammation(event as SlotTemplateEventType) &&
                boxSlotTemplatesBeingCreated.findIndex((slotTemplate) => slotTemplate.id === event.id) < 0
              }
              isOverlayed={isEventOverlayed(event as SlotTemplateEventType)}
            />
          )
        }}
      />
    </Container>
  )
}

const Container = styled.div``
