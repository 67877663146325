import { useSearchParams } from "react-router-dom"

export function useQueryParam(key: string) {
  const [searchParams, setSearchParams] = useSearchParams()
  const value = searchParams.get(key)

  const setValue = (v: string) => {
    const params = new URLSearchParams(searchParams)
    params.set(key, v)
    setSearchParams(params, { replace: true })
  }

  return [value, setValue] as const
}

export function useSetMultiParams() {
  const [searchParams, setSearchParams] = useSearchParams()

  const setParams = (args: { key: string; value: string }[]) => {
    const params = new URLSearchParams(searchParams)
    args.forEach((arg) => {
      params.set(arg.key, arg.value)
    })
    setSearchParams(params, { replace: true })
  }
  return setParams
}
