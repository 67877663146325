import { ApiClient } from "@modules/core/api/client/apiClient"
import { convertPromoCodeFromDto, PromoCodeDto } from "@modules/promoCode/services/promoCodeDto"
import { PromoCode, PromoCodeDraft } from "@modules/promoCode/types/promoCodeTypes"

export class PromoCodeApi {
  private baseUrl = "/promo-codes"

  constructor(private api: ApiClient) {}

  async getPromoCodes(expired: boolean): Promise<PromoCode[]> {
    const result = await this.api.get<PromoCodeDto[]>(`${this.baseUrl}`, { params: { expired } })
    return result.data.map((item) => convertPromoCodeFromDto(item))
  }

  async createPromoCode(promoCodeDraft: PromoCodeDraft) {
    const resultDto = await this.api.post<PromoCodeDto>(`${this.baseUrl}`, promoCodeDraft)

    return convertPromoCodeFromDto(resultDto.data)
  }

  async updatePromoCode(id: string, promoCodeDraft: PromoCodeDraft) {
    await this.api.put(`${this.baseUrl}/${id}`, promoCodeDraft)
  }

  async deletePromoCode(id: string) {
    await this.api.delete(`${this.baseUrl}/${id}`)
  }
}
