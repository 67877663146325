import { ChannelType } from "@modules/chat/channelTypes"
import { useChatClient } from "@modules/chat/hooks/useChatClient"
import { generateRandomId, useChatContext } from "stream-chat-react"

export const useCreateChannel = () => {
  const { chatClient } = useChatClient()
  const { setActiveChannel } = useChatContext()

  const createOneToOneChannel = async (userId: string) => {
    if (!chatClient?.user?.id) {
      return
    }

    const members = [chatClient.user.id, userId]

    // Check if the channel already exists.
    const channels = await chatClient.queryChannels({
      distinct: true,
      members,
    })

    const newChannel =
      channels.length === 1
        ? channels[0]
        : chatClient.channel(ChannelType.PRIVATE, {
            members,
          })

    if (!newChannel.initialized) {
      await newChannel.watch()
    }

    setActiveChannel(newChannel)
    return newChannel
  }

  const createGroupChannel = async (groupName: string, selectedUserIds: string[]) => {
    if (!chatClient?.user || !selectedUserIds || selectedUserIds.length === 0 || !groupName) {
      return
    }

    const newChannel = chatClient.channel(ChannelType.PRIVATE, generateRandomId(), {
      members: [...selectedUserIds, chatClient.user.id],
      name: groupName,
    })

    if (!newChannel.initialized) {
      await newChannel.watch()
    }

    setActiveChannel(newChannel)
  }

  return { createOneToOneChannel, createGroupChannel }
}
