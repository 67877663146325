import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PlayIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.6866 11.015C23.4925 11.4804 23.4925 12.6437 22.6866 13.109L6.06343 22.7064C5.25746 23.1717 4.25 22.59 4.25 21.6594L4.25 2.46464C4.25 1.53398 5.25746 0.952326 6.06343 1.41765L22.6866 11.015Z"
      fill={props.color}
    />
  </svg>
)
