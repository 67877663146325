import { LogoHustleUpSmall } from "@images/logoHustleUpSmall"
import { word } from "@modules/core/utils/i18n"
import { StripeCountryCode } from "@modules/payment/stripeTypes"
import { HUText } from "@modules/ui/components/huText"
import { Dispatch, SetStateAction } from "react"
import styled, { DefaultTheme } from "styled-components"
import StripeLogo from "@images/payment/stripe-logo.svg"
import { HUStack } from "@modules/ui/components/layout"
import { Dropdown } from "primereact/dropdown"
import { HUButton } from "@modules/ui/components/huButton"
import { SmallArrowIcon } from "@images/svgIcons/arrowIcon"
import { HUNavigateButton } from "@modules/ui/components/huNavigateButton"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"

export const BoxOnboardingStripeSecondPage = (
  theme: DefaultTheme,
  countryOptions: any,
  selectedCountry: StripeCountryCode | undefined,
  setSelectedCountry: Dispatch<SetStateAction<StripeCountryCode | undefined>>,
  createStripeAccount: (country: StripeCountryCode) => void,
  handleButtonNavigation: (onNext: boolean) => void,
  loading: boolean,
) => {
  const popup = useConfirmPopup()

  const handleSave = (selectedCountry: StripeCountryCode) =>
    popup.show({
      title: word("box.stripe.onboarding.screen2.modal.title"),
      message: word("box.stripe.onboarding.screen2.modal.content"),
      accept: async () => createStripeAccount(selectedCountry),
      footerProps: {
        align: true,
        confirmText: word("global.continue"),
        confirmClassName: "flex-1",
        cancelClassName: "flex-1",
        footerClassName: "flex flex-row justify-content-around w-full",
      },
    })

  return (
    <div className="relative w-full">
      {/* HEARDER */}
      <div className="flex align-items-center flex-column w-full mt-5 px-3 text-center">
        <div>
          <LogoHustleUpSmall color={theme.mainWhite} />
        </div>
        <HUText fontStyle="HL" color={theme.mainWhite} className="mt-3">
          {word("box.stripe.onboarding.title")}
        </HUText>
        {/* TEXT FOR DESKTOP & TABLET */}
        <HUText fontStyle="BL" color={theme.mainWhite} className="mt-1 hidden sm:flex">
          {word("box.stripe.onboarding.screen2.subtitle1")}
        </HUText>
        <HUText fontStyle="BL" color={theme.mainWhite} className="hidden sm:flex">
          {word("box.stripe.onboarding.screen2.subtitle2")}
        </HUText>
        {/* TEXT FOR SMALL DEVICE */}
        <HUText fontStyle="BL" color={theme.mainWhite} className="sm:hidden flex mt-1">
          {word("box.stripe.onboarding.screen2.subtitle1")} {word("box.stripe.onboarding.screen2.subtitle2")}
        </HUText>
      </div>
      {/* CONTENT */}
      <div className="flex flex-column align-items-center mt-6 mb-6 gap-3">
        <img src={StripeLogo} width={260} className="mt-1 mb-2" />
        <div style={{ width: "456px" }} className="flex flex-column">
          <HUText fontStyle="BM" color={theme.mainWhite} className="text-center">
            {word("box.stripe.onboarding.screen2.content")}
          </HUText>
          <HUStack className="mt-1 w-full px-4" gap={4}>
            <HUText type="label" color={theme.neutralColor700} fontStyle="LS">
              {word("global.country.label").toUpperCase()}
              <HUText fontStyle="LL" color={theme.necessaryInput}>
                {" "}
                *
              </HUText>
            </HUText>
            <Dropdown
              value={selectedCountry}
              options={countryOptions}
              onChange={(e) => setSelectedCountry(e.value as StripeCountryCode)}
              optionGroupLabel="label"
              optionLabel="label"
              optionGroupChildren="items"
              scrollHeight="300px"
              style={{ width: "100%" }}
            />
          </HUStack>
          <StyledHUNavigateButton
            fontStyle="BM"
            className="align-self-end underline cursor-pointer"
            to="mailto:contact@hustleup-app.com?subject=I%20can't%20find%20my%20country%20&body="
            label={word("program.stripe.onboarding.countryNotFound")}
            link
          />
        </div>
      </div>
      {/* BUTTON */}
      <div className="mb-4 flex flex-rows gap-2 align-items-center justify-content-center">
        <HUButton
          type="Left_Icon"
          size="M"
          colorType="Quaternary"
          text={word("global.previous")}
          onClick={() => handleButtonNavigation(true)}
          icon={{
            iconView: <span className={`pi pi-arrow-left`} style={{ color: theme.mainWhite }} />,
          }}
        />
        <HUButton
          type="Right_Icon"
          size="M"
          colorType="Primary"
          icon={{ icon: () => <SmallArrowIcon color={theme.neutralColor900} width={14} height={14} /> }}
          text={word("box.stripe.onboarding.screen2.button")}
          loading={loading}
          onClick={() => selectedCountry && handleSave(selectedCountry)}
          disabled={!selectedCountry}
        />
      </div>
    </div>
  )
}

const StyledHUNavigateButton = styled(HUNavigateButton)`
  .p-button-label {
    text-decoration: underline;
  }
`
