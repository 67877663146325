import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { getBoxProgrammationColorTypeWithHex } from "@modules/boxProgrammation/boxProgrammationUtils"
import { boxProgrammationColors } from "@modules/boxProgrammation/components/boxProgrammationColors"
import { BoxSlotDraft, ItemBoxSlot } from "@modules/boxSlot/boxSlotTypes"
import { CalendarEventTypeWithHour } from "@modules/calendar/calendarTypes"
import { DefaultTheme } from "styled-components"
import { v4 as uuidv4 } from "uuid"

export const convertItemBoxProgrammationSlotToEvent = (
  slot: ItemBoxSlot,
  theme: DefaultTheme,
): CalendarEventTypeWithHour => {
  const colorType = getBoxProgrammationColorTypeWithHex(slot.color)
  const programationColor = boxProgrammationColors[colorType]

  return {
    id: slot.id,
    programmationId: slot.programmationId,
    title: slot.programmationName,
    date: slot.startDateTime,
    startDate: slot.startDateTime,
    endDate: slot.endDateTime,
    editable: false,
    color: slot.sessionId ? theme.neutralColor50 : theme.background,
    leftBorderColor: programationColor.background,
    borderColor: theme.neutralColor200,
    borderStyle: slot.sessionId ? "solid" : "dashed",
    textColor: theme.neutralColor900,
  }
}

export const convertBoxSlotTemplateDraftToEvent = (
  slotDraft: BoxSlotDraft,
  boxProgrammation: BoxProgrammation,
  theme: DefaultTheme,
): CalendarEventTypeWithHour => {
  const colorType = getBoxProgrammationColorTypeWithHex(boxProgrammation.color)
  const programationColor = boxProgrammationColors[colorType]

  return {
    id: slotDraft.id ?? uuidv4(),
    programmationId: boxProgrammation.id,
    title: boxProgrammation.name,
    date: slotDraft.startDateTime ?? slotDraft.endDateTime,
    startDate: slotDraft.startDateTime ?? slotDraft.endDateTime,
    endDate: slotDraft.endDateTime,
    editable: false,
    color: theme.background,
    borderColor: programationColor.background,
    leftBorderColor: programationColor.background,
    borderStyle: "dashed",
    textColor: theme.neutralColor900,
  }
}
