import React, { useState } from "react"
import styled from "styled-components"

type SingleCardProps = {
  children: React.ReactNode
  onClick: () => void
  zIndex: number
  transform: string
  isCurrent: boolean
}

type AnimatedCardsProps = {
  cards: React.ReactNode[]
  className?: string
}

const SingleCardStyled = styled.div<{
  $zIndex: number
  $transform: string
  $isCurrent: boolean
}>`
  width: 320px;
  height: 576px;
  background-color: ${({ theme }) => theme.neutralColor50};
  transition: all 0.5s ease;
  z-index: ${({ $zIndex }) => $zIndex};
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  transform: ${({ $transform }) => $transform};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: ${({ $isCurrent }) => ($isCurrent ? 0 : 1)};
    transition: opacity 0.25s linear;
    border-radius: 20px;
    background: ${({ $isCurrent, theme }) =>
      $isCurrent ? "none" : `linear-gradient(250deg, rgba(18, 18, 18, 0.00) 0%, ${theme.mainBlack} 50%)`};
    pointer-events: none;
`

export const StackedCards: React.FC<AnimatedCardsProps> = ({ cards, className }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const handleCardClick = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % cards.length)
  }

  const getTransform = (index: number) => {
    const cardCount = cards.length
    const position = (index - selectedIndex + cardCount) % cardCount

    if (position === 0) {
      return "translateX(0px) scale(1)"
    }

    const perspectiveBase = 90
    const perspectiveOffset = perspectiveBase - position * perspectiveBase * 0.08
    const translateX = position * perspectiveOffset
    const scale = 1 - position * 0.15
    const translateY = position * -10

    return `translateX(${translateX}px) translateY(${translateY}px) scale(${scale})`
  }

  const getZIndex = (index: number) => {
    const cardCount = cards.length
    const position = (index - selectedIndex + cardCount) % cardCount

    return cardCount - position
  }

  return (
    <div className={className} style={{ position: "relative", width: "320px", height: "576px" }}>
      {cards.map((card, index) => (
        <SingleCard
          key={`stacked-card-${index}`}
          onClick={handleCardClick}
          transform={getTransform(index)}
          zIndex={getZIndex(index)}
          isCurrent={index === selectedIndex}
        >
          {card}
        </SingleCard>
      ))}
    </div>
  )
}

const SingleCard: React.FC<SingleCardProps> = ({ children, onClick, zIndex, transform, isCurrent }) => {
  return (
    <SingleCardStyled onClick={onClick} $zIndex={zIndex} $transform={transform} $isCurrent={isCurrent}>
      {children}
    </SingleCardStyled>
  )
}
