import { AmrapIcon } from "@images/svgIcons/amrapIcon"
import { word } from "@modules/core/utils/i18n"
import { RecapBloc } from "@modules/exercises/components/RecapDetailAllChronos/recapBloc"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useTheme } from "styled-components"

export const AmrapRecap: React.FC = () => {
  const theme = useTheme()
  const { formik, updatedExerciseIndex } = useSessionFormContext()
  const { navigateToChronoForm } = useSessionSidebar()

  if (updatedExerciseIndex === null) return <></>

  const formikValue = formik.values.exercises[updatedExerciseIndex]
  const formikNameBase = `exercises[${updatedExerciseIndex}]`

  const onDeleteChronoClick = () => {
    formik.setFieldValue(`${formikNameBase}.chronoAmrap`, undefined)
    formik.setFieldValue(`${formikNameBase}.chrono`, undefined)
    formik.setFieldValue(`${formikNameBase}.chronoType`, undefined)
  }

  const onEditChronoContentClick = () => {
    navigateToChronoForm()
  }

  return (
    <>
      <div className="flex ">
        <AmrapIcon color={"white"} />
        <div className="flex flex-column justify-content-around	ml-4">
          <HUText fontStyle="LL">{word("chrono.title.amrap")}</HUText>
          <HUText fontStyle="BS" color={theme.neutralColor600}>
            {word("chrono.amrap.definition")}
          </HUText>
        </div>
      </div>
      {formikValue.chronoAmrap?.trainingTime && (
        <>
          {formikValue.chronoAmrap.trainingTime.map((trainingTime, i) => (
            <div key={i}>
              {i !== 0 && (
                <div className="mt-2">
                  <HUText fontStyle="LM">{word("chrono.title.amrap") + " " + (i + 1)}</HUText>
                </div>
              )}
              <div className="grid">
                <RecapBloc circleTimeIcon value={trainingTime} label={word("chrono.settings.label.training_time")} />
                {formikValue.chronoAmrap?.restTime[i] && (
                  <RecapBloc
                    circleBreakIcon
                    value={formikValue.chronoAmrap.restTime[i] && formikValue.chronoAmrap.restTime[i]}
                    label={word("chrono.settings.label.rest_time")}
                  />
                )}
              </div>
            </div>
          ))}
        </>
      )}
      <div className="flex justify-content-between mt-4">
        <HUButton
          type="Default"
          size="M"
          colorType="Quaternary"
          text={word("global.delete")}
          style={{ width: "100%", marginRight: "10px" }}
          onClick={onDeleteChronoClick}
        />
        <HUButton
          type="Default"
          size="M"
          colorType="Tertiary"
          text={word("program.details.modify.button")}
          style={{ width: "100%" }}
          onClick={onEditChronoContentClick}
        />
      </div>
    </>
  )
}
