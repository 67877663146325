import { commonTextProps, HUText, } from "@modules/ui/components/huText"
import styled from "styled-components"

export type HuTextUnderlineProps = {
  text: string
  colorLine?: string
  className?: string
} & commonTextProps

export const HuTextUnderline: React.FC<HuTextUnderlineProps> = ({
  text,
  colorLine,
  className,
  ...props
}: HuTextUnderlineProps) => {

  return (
    <UnderlineStyle $colorLine={colorLine} >
      <HUText className={className} {...props}>
        {text}
      </HUText>
    </UnderlineStyle>
  )
}

const UnderlineStyle = styled.div<{ $colorLine: string | undefined }>`
  display: inline-block;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${(props) => props.$colorLine || ""};
    transition: width 0.5s ease-in-out;
  }
`

HuTextUnderline.displayName = "HuTextUnderline"
