import { LayoutContext } from "@modules/app/context/layoutcontext"
import { MenuButtonsRef } from "@modules/app/types/layout"
import { forwardRef, useContext, useImperativeHandle, useRef } from "react"

export const AppTopBar = forwardRef<MenuButtonsRef>((_, ref) => {
  const { onMenuToggle } = useContext(LayoutContext)
  const buttonBurgerRef = useRef(null)

  useImperativeHandle(ref, () => ({
    menuBurgerButton: buttonBurgerRef.current,
  }))

  return (
    <div className="layout-topbar">
      <div className="topbar-start">
        <button
          ref={buttonBurgerRef}
          type="button"
          className="topbar-menubutton p-link p-trigger"
          onClick={onMenuToggle}
        >
          <i className="pi pi-bars"></i>
        </button>
      </div>
    </div>
  )
})
