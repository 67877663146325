import { allCurrencies, Currency } from "@modules/currency/currency"

export enum BoxOfferType {
  Subscription = "SUBSCRIPTION",
  DropIn = "DROP_IN",
  Trial = "TRIAL",
  TicketsBook = "TICKETS_BOOK",
  Millenium = "MILLENIUM",
}

export enum BoxOfferPriceType {
  Free = "FREE",
  Paid = "PAID",
}

export enum BoxOfferWeeklyParticipationType {
  Unlimited = "UNLIMITED",
  Restricted = "RESTRICTED",
}

export type BoxOfferRule = {
  value?: number
  period: BoxOfferRulePeriod
}

export enum BoxOfferRulePeriod {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
}

export enum BoxOfferValidity {
  UNLIMITED = "UNLIMITED",
  DEFINED = "DEFINED",
}

type BoxOfferBase = {
  id: string
  type: BoxOfferType
  name: string
  description?: string
  isFree: boolean
  price: number | undefined
  currency: Currency | undefined
  maxWeeklyParticipation: number | undefined
  programs: string[]
  rules?: BoxOfferRule[]
  validityPeriodMonths?: number
}

export type BoxOfferItem = {
  id: string
  type: BoxOfferType
  name: string
  description?: string
  price: number
  minimumCommitmentPeriod?: string
  count?: number
  currency: Currency
  isFree: boolean
}

export type BoxSubscriptionOffer = {
  type: BoxOfferType.Subscription
  minimumMonthlyDuration: number
} & BoxOfferBase

export type DropInOffer = {
  type: BoxOfferType.DropIn
} & BoxOfferBase

export type TrialOffer = {
  type: BoxOfferType.Trial
  count: number
  renewable: boolean
} & BoxOfferBase

export type TicketsBookOffer = {
  type: BoxOfferType.TicketsBook
  count: number
} & BoxOfferBase

export type MilleniumOffer = {
  type: BoxOfferType.Millenium
} & BoxOfferBase

export type BoxOffer = BoxSubscriptionOffer | DropInOffer | TrialOffer | TicketsBookOffer | MilleniumOffer

export enum BoxPurchaseStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  PAID = "PAID",
}

type Price = {
  value: number | undefined
  currency: Currency | undefined
}

export type BoxPurchase = {
  id: string
  date: string
  status: BoxPurchaseStatus
  offer: { id: string; name: string; type: BoxOfferType }
  count?: number
  initialCount?: number
  price: Price
  purchaseDate: string
}

export const BOX_OFFER_MINIMUM_PRICE = 5
export const BOX_OFFER_TICKET_BOOKS_MIN = 1
export const BOX_OFFER_TICKET_BOOKS_MAX = 99
export const BOX_OFFER_WEEKLY_PARTICIPATION_MIN = 1
export const BOX_OFFER_WEEKLY_PARTICIPATION_MAX = 99
export const BOX_OFFER_VALIDITY_MIN = 1
export const BOX_OFFER_VALIDITY_MAX = 24

export type BoxOfferDraft = {
  type: BoxOfferType
  name: string
  description: string
  priceSwitch: BoxOfferPriceType
  price?: number
  count?: number
  currency: Currency
  weeklyParticipationSwitch: BoxOfferWeeklyParticipationType
  maxWeeklyParticipation: number | undefined
  minimumMonthlyDuration?: number
  programs: string[]
  rules?: BoxOfferRule[]
  renewable?: boolean
  validityPeriodMonths?: number
  validitySwitch: BoxOfferValidity
}

export function convertBoxOfferToDraft(boxOfferDraft?: BoxOffer, selectedBoxOfferType?: BoxOfferType): BoxOfferDraft {
  return {
    type: boxOfferDraft?.type ?? selectedBoxOfferType ?? BoxOfferType.Subscription,
    name: boxOfferDraft?.name ?? "",
    description: boxOfferDraft?.description ?? "",
    priceSwitch:
      boxOfferDraft?.isFree ||
      (selectedBoxOfferType && [BoxOfferType.Trial, BoxOfferType.Millenium].includes(selectedBoxOfferType))
        ? BoxOfferPriceType.Free
        : BoxOfferPriceType.Paid,
    price: boxOfferDraft?.price,
    programs: boxOfferDraft?.programs ?? [],
    currency: boxOfferDraft?.currency ?? allCurrencies[0],
    count:
      boxOfferDraft?.type === BoxOfferType.TicketsBook || boxOfferDraft?.type === BoxOfferType.Trial
        ? boxOfferDraft.count
        : undefined,
    minimumMonthlyDuration:
      boxOfferDraft?.type === BoxOfferType.Subscription
        ? boxOfferDraft.minimumMonthlyDuration
        : selectedBoxOfferType === BoxOfferType.Subscription
          ? 1
          : undefined,
    weeklyParticipationSwitch: boxOfferDraft?.maxWeeklyParticipation
      ? BoxOfferWeeklyParticipationType.Restricted
      : BoxOfferWeeklyParticipationType.Unlimited,
    maxWeeklyParticipation: boxOfferDraft?.maxWeeklyParticipation,
    rules: boxOfferDraft?.rules,
    renewable:
      boxOfferDraft?.type === BoxOfferType.Trial
        ? boxOfferDraft.renewable
        : selectedBoxOfferType === BoxOfferType.Trial
          ? false
          : undefined,
    validityPeriodMonths: boxOfferDraft?.validityPeriodMonths,
    validitySwitch: boxOfferDraft?.validityPeriodMonths ? BoxOfferValidity.DEFINED : BoxOfferValidity.UNLIMITED,
  }
}
