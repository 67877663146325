import {
  ApiInterceptor,
  addRequestInterceptor,
  addResponseInterceptor,
} from "@modules/core/api/interceptors/apiInterceptor"
import { ConfigService } from "@modules/core/services/configService"
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios"

export class ApiClient {
  readonly axios: AxiosInstance
  private _interceptorRequestIds: number[] = []
  private _interceptorResponseIds: number[] = []

  constructor() {
    this.axios = axios.create({
      headers: { "x-api-version": "1.1" },
      baseURL: ConfigService.config.BASE_URL,
    })
  }

  addRequestInterceptors(interceptors: ApiInterceptor<InternalAxiosRequestConfig>[]) {
    for (const interceptor of interceptors.reverse()) {
      this._interceptorRequestIds.push(addRequestInterceptor(this.axios, interceptor))
    }
  }

  addResponseInterceptors(interceptors: ApiInterceptor<AxiosResponse>[]) {
    for (const interceptor of interceptors) {
      this._interceptorResponseIds.push(addResponseInterceptor(this.axios, interceptor))
    }
  }

  clearInterceptors() {
    this._interceptorRequestIds.forEach((id) => this.axios.interceptors.request.eject(id))
    this._interceptorRequestIds = []
    this._interceptorResponseIds.forEach((id) => this.axios.interceptors.response.eject(id))
    this._interceptorResponseIds = []
  }

  get<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.get(url, config)
  }

  post<T = unknown, R = AxiosResponse<T>>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.post(url, data, config)
  }

  patch<T = unknown, R = AxiosResponse<T>>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.patch(url, data, config)
  }

  put<T = unknown, R = AxiosResponse<T>>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.put(url, data, config)
  }

  delete<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.delete(url, config)
  }
}
