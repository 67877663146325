import {
  BoxProgrammationDefaultSettingsFormikType,
  BoxProgrammationFormikType,
  convertBoxProgrammationDefaultValuesFormFormik,
  convertBoxProgrammationFormFormik,
} from "@modules/boxProgrammation/boxProgrammationFormikTypes"
import { BoxProgrammationDefaultSettingsFormView } from "@modules/boxProgrammation/components/boxProgrammationDefaultSettingsFormView"
import { BoxProgrammationForm } from "@modules/boxProgrammation/components/boxProgrammationForm"
import { useBoxProgrammationDefaultSettingsFormik } from "@modules/boxProgrammation/hooks/useBoxProgrammationDefaultSettingsFormik"
import { useBoxProgrammationFormik } from "@modules/boxProgrammation/hooks/useBoxProgrammationFormik"
import {
  useBoxProgrammation,
  useBoxProgrammationDefaultSettings,
} from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { useToast } from "@modules/ui/components/huToast"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"

type FormStep = "Information" | "DefaultSettings"

export type BoxProgrammationFormViewRef = {
  onClose: () => Promise<boolean>
}

export const BoxProgrammationFormView = forwardRef<BoxProgrammationFormViewRef, { boxProgrammationId?: string }>(
  ({ boxProgrammationId }, ref) => {
    const [formStep, setFormStep] = useState<FormStep>("Information")
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const confirmPopup = useConfirmPopup()
    const toast = useToast()
    const { hideSidebar } = useSidebar()
    const { boxProgrammationService, localStorageService } = useServicesContext()
    const { result: boxProgrammation, loading } = useBoxProgrammation(boxProgrammationId)
    const { value: defaultSettings, loading: loadingDefaultSettings } =
      useBoxProgrammationDefaultSettings(boxProgrammationId)
    const { formik: boxProgrammationFormik } = useBoxProgrammationFormik(boxProgrammation ?? undefined)
    const { formik: defaultSettingsFormik } = useBoxProgrammationDefaultSettingsFormik(defaultSettings ?? undefined)
    const boxNavigate = useBoxNavigate()

    useEffect(() => {
      document.getElementById("sidebar-content")?.scrollTo({ top: 0 })
    }, [formStep])

    useImperativeHandle(ref, () => ({
      onClose: async () => {
        if (
          Object.keys(boxProgrammationFormik.touched).length > 0 ||
          Object.keys(defaultSettingsFormik.touched).length > 0
        ) {
          const confirm = await new Promise<boolean>((resolve) => {
            confirmPopup.show({
              title: word("box.programmation.form.closeMessage.title"),
              message: word("box.programmation.form.closeMessage.message"),
              accept: async () => {
                resolve(true)
              },
              cancel: async () => {
                resolve(false)
              },
              footerProps: {
                align: true,
                confirmText: word("global.ok"),
              },
            })
          })

          return confirm
        }
        return Promise.resolve(true)
      },
    }))

    const moveToDefaultSettingsStep = async () => {
      Object.keys(boxProgrammationFormik.values).forEach((inputKey) => {
        boxProgrammationFormik.setFieldTouched(inputKey)
      })
      const errors = await boxProgrammationFormik.validateForm()

      if (Object.keys(errors).length === 0) setFormStep("DefaultSettings")
    }

    const onSubmitBoxProgrammation = async () => {
      try {
        if (boxProgrammation) {
          await boxProgrammationService.updateBoxProgrammation(
            boxProgrammation.id,
            convertBoxProgrammationFormFormik(boxProgrammationFormik.values as BoxProgrammationFormikType),
          )

          await onSubmitDefaultSettings(boxProgrammation.id)
        } else {
          const newProgrammation = await boxProgrammationService.createBoxProgrammation(
            convertBoxProgrammationFormFormik(boxProgrammationFormik.values as BoxProgrammationFormikType),
          )

          await onSubmitDefaultSettings(newProgrammation.id)

          boxNavigate(Path.Box.ProgrammationDetails(newProgrammation.id))
        }
      } catch (e: any) {
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      } finally {
        setIsSubmitting(false)
      }
    }

    const onSubmitDefaultSettings = async (boxProgrammationId: string) => {
      try {
        const newDefaultSettings = convertBoxProgrammationDefaultValuesFormFormik(
          defaultSettingsFormik.values as BoxProgrammationDefaultSettingsFormikType,
        )

        if (defaultSettings) {
          await boxProgrammationService.updateBoxProgrammationDefaultValues(boxProgrammationId, newDefaultSettings)
        } else {
          await boxProgrammationService.createBoxProgrammationDefaultValues(boxProgrammationId, newDefaultSettings)
        }

        localStorageService.save(LocalStorageKeys.boxProgram.defaultSettings, newDefaultSettings)

        toast.show({
          severity: "success",
          summary: !boxProgrammation
            ? word("programmation.form.create.success")
            : word("programmation.form.update.success"),
        })

        hideSidebar(false)
      } catch (e: any) {
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      } finally {
        setIsSubmitting(false)
      }
    }

    const onValidateDefaultSettings = async () => {
      setIsSubmitting(true)

      Object.keys(defaultSettingsFormik.values).forEach((inputKey) => {
        defaultSettingsFormik.setFieldTouched(inputKey)
      })
      const errors = await defaultSettingsFormik.validateForm()

      if (Object.keys(errors).length === 0) {
        await onSubmitBoxProgrammation()
      } else setIsSubmitting(false)
    }

    return (
      <>
        {boxProgrammationId && (loading || loadingDefaultSettings) ? (
          <LoadingSpinner />
        ) : formStep === "Information" ? (
          <BoxProgrammationForm
            boxProgrammation={boxProgrammation ?? undefined}
            formik={boxProgrammationFormik}
            onValidate={moveToDefaultSettingsStep}
          />
        ) : (
          <BoxProgrammationDefaultSettingsFormView
            boxProgrammationName={boxProgrammationFormik.values.title}
            boxProgrammationColor={boxProgrammationFormik.values.color}
            loadingDefaultSettings={!!boxProgrammation && loadingDefaultSettings}
            formik={defaultSettingsFormik}
            moveToPreviousFormStep={() => setFormStep("Information")}
            onValidate={onValidateDefaultSettings}
            isSubmitting={isSubmitting}
          />
        )}
      </>
    )
  },
)
