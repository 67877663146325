import { BoxSlotTypeOf } from "@modules/boxSlot/boxSlotTypes"
import { Service } from "@modules/core/services/serviceType"
import { Exercise } from "@modules/exercises/exerciseTypes"
import { ExerciseApi } from "@modules/exercises/services/exerciseApi"
import { ExerciseScoreInputDto } from "@modules/exercises/services/exercisesDto"
import { ParentSessionOrigin } from "@modules/exercises/sessionTypes"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramInfiniteSessionTypeOf, ProgramOnOffSessionTypeOf } from "@modules/programSession/programSessionTypes"

export class ExerciseService implements Service {
  private copyExercise: any

  constructor(private api: ExerciseApi) {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  setCopyExercise = (exerciseToCopy: any) => {
    this.copyExercise = exerciseToCopy
  }

  getCopyExercise = () => {
    return this.copyExercise
  }

  async markExerciseAsComplete(parentSessionOrigin: ParentSessionOrigin, exercise: Exercise) {
    if (!exercise.isCompleted) {
      if (
        parentSessionOrigin.parentType === ProgramInfiniteSessionTypeOf ||
        parentSessionOrigin.parentType === ProgramOnOffSessionTypeOf
      ) {
        await this.api.markExerciseAsComplete(parentSessionOrigin.programId, parentSessionOrigin.sessionId, exercise.id)
      } else if (parentSessionOrigin.parentType === BoxSlotTypeOf) {
        await this.api.markExerciseAsComplete(
          parentSessionOrigin.programmationId,
          parentSessionOrigin.sessionId,
          exercise.id,
        )
      }
    }
  }

  async addScoreToExercice(parentSessionOrigin: ParentSessionOrigin, exercise: Exercise, score: ExerciseScoreInputDto) {
    if (
      parentSessionOrigin.parentType === ProgramInfiniteSessionTypeOf ||
      parentSessionOrigin.parentType === ProgramOnOffSessionTypeOf
    ) {
      await this.api.addScoreToExercice(
        parentSessionOrigin.programId,
        parentSessionOrigin.sessionId,
        exercise.id,
        score,
      )
    } else if (parentSessionOrigin.parentType === BoxSlotTypeOf) {
      await this.api.addScoreToExercice(
        parentSessionOrigin.programmationId,
        parentSessionOrigin.sessionId,
        exercise.id,
        score,
      )
    }
  }

  getProgramExerciseDashboard(
    programId?: string | null,
    programType?: typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf | null,
    sessionId?: string | null,
    exerciseId?: string | null,
  ) {
    if (programId && sessionId && exerciseId && programType === ProgramInfiniteTypeOf) {
      return this.api.getProgramExerciseDashboard(programId, sessionId, exerciseId)
    }
  }
}
