import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const GlobeIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.26245 15.3438C9.38715 15.184 10.3731 14.0102 10.9935 12.2766C10.0967 12.0757 9.18141 11.9679 8.26245 11.9551V15.3438Z"
      fill={props.color}
    />
    <path
      d="M10.0591 15.0692C10.1256 15.0499 10.1918 15.0306 10.2575 15.0092C10.313 14.9914 10.3677 14.9722 10.4223 14.953C10.487 14.9307 10.5514 14.9077 10.6156 14.8833C10.6703 14.8625 10.7245 14.8405 10.7787 14.8184C10.8414 14.7921 10.9039 14.7659 10.9658 14.7396C11.0198 14.7158 11.0734 14.6909 11.1271 14.6659C11.1878 14.6373 11.2482 14.6082 11.3082 14.5779C11.3608 14.551 11.4133 14.5237 11.4659 14.4956C11.5248 14.464 11.5834 14.4318 11.6415 14.3987C11.6941 14.3689 11.7448 14.339 11.796 14.3082C11.853 14.2732 11.9097 14.2381 11.9661 14.2031C12.0165 14.171 12.0668 14.1384 12.1165 14.1048C12.172 14.0674 12.2266 14.0285 12.281 13.9897C12.3299 13.9546 12.3786 13.9197 12.4263 13.8845C12.479 13.844 12.5329 13.802 12.5856 13.7599C12.6325 13.7226 12.6792 13.6855 12.7253 13.6471C12.7777 13.6035 12.8281 13.5583 12.879 13.5131C12.9237 13.4735 12.9686 13.4342 13.0122 13.3935C13.0624 13.3466 13.1113 13.2981 13.1602 13.25C13.2026 13.2082 13.2454 13.167 13.2869 13.1241C13.2946 13.1161 13.3019 13.1075 13.3099 13.0993C12.7365 12.8032 12.1327 12.5708 11.5088 12.4062C11.1843 13.441 10.6069 14.3787 9.82886 15.1341C9.85016 15.1286 9.87147 15.1241 9.89277 15.1186C9.94899 15.1028 10.0042 15.0855 10.0591 15.0692Z"
      fill={props.color}
    />
    <path
      d="M15.3548 8.26758H12.2031C12.1911 9.49816 12.0126 10.7214 11.6724 11.9041C12.3662 12.0895 13.0358 12.3559 13.6673 12.698C14.704 11.4484 15.2975 9.89039 15.3548 8.26758Z"
      fill={props.color}
    />
    <path
      d="M8.26245 7.74157H11.6761C11.6627 6.55449 11.4886 5.37473 11.1586 4.23438C10.2081 4.45115 9.23724 4.56692 8.26245 4.57976V7.74157Z"
      fill={props.color}
    />
    <path
      d="M8.26245 0.666016V4.05475C9.18141 4.04179 10.0967 3.9341 10.9935 3.73324C10.3731 1.99966 9.38715 0.825807 8.26245 0.666016Z"
      fill={props.color}
    />
    <path
      d="M8.26245 11.4294C9.23724 11.4424 10.2081 11.5581 11.1586 11.7749C11.4886 10.6345 11.6627 9.45465 11.6761 8.26758H8.26245V11.4294Z"
      fill={props.color}
    />
    <path
      d="M13.6673 3.3125C13.0358 3.65454 12.3662 3.92099 11.6724 4.10632C12.0126 5.28904 12.1911 6.51231 12.2031 7.74289H15.3548C15.2974 6.12021 14.7039 4.56221 13.6673 3.3125Z"
      fill={props.color}
    />
    <path
      d="M13.3109 2.91307C13.3032 2.90524 13.2959 2.89677 13.2884 2.88868C13.2468 2.84582 13.2036 2.80449 13.1613 2.76278C13.1125 2.71465 13.0639 2.666 13.0136 2.61928C12.9701 2.57873 12.925 2.54035 12.8803 2.49992C12.8294 2.45474 12.7784 2.40918 12.7261 2.36554C12.6806 2.32717 12.634 2.29046 12.5878 2.25363C12.5352 2.21101 12.4813 2.16866 12.4272 2.12797C12.3797 2.09217 12.3315 2.05751 12.2831 2.02286C12.2282 1.98333 12.173 1.94393 12.1171 1.90632C12.0679 1.87321 12.018 1.84086 11.9681 1.80916C11.9112 1.77258 11.8545 1.73677 11.7966 1.70212C11.7459 1.6717 11.6947 1.64167 11.6433 1.61253C11.5846 1.57891 11.5256 1.54631 11.4662 1.51448C11.4136 1.48817 11.3611 1.45955 11.3085 1.43323C11.248 1.40269 11.1871 1.3733 11.1246 1.34442C11.0719 1.31939 11.0186 1.29501 10.9644 1.27126C10.9021 1.24367 10.8393 1.21877 10.7765 1.19246C10.7223 1.17038 10.6684 1.14856 10.6137 1.12777C10.55 1.10338 10.4853 1.08054 10.4206 1.05821C10.3659 1.03895 10.3112 1.01983 10.2558 1.00186C10.1901 0.980557 10.1244 0.961176 10.0569 0.941668C10.0022 0.925624 9.94724 0.909324 9.89193 0.894693C9.87075 0.888917 9.84919 0.884425 9.82788 0.878906C10.6059 1.63435 11.1832 2.57192 11.5078 3.60678C12.1323 3.44224 12.7371 3.20968 13.3109 2.91307Z"
      fill={props.color}
    />
    <path
      d="M0.64624 7.74289H3.79792C3.80985 6.51231 3.98838 5.28904 4.3285 4.10632C3.63479 3.92112 2.9652 3.65467 2.33374 3.3125C1.29696 4.56208 0.703483 6.12021 0.64624 7.74289Z"
      fill={props.color}
    />
    <path
      d="M7.73768 15.3438V11.9551C6.81872 11.968 5.90348 12.0757 5.00659 12.2766C5.62689 14.0102 6.61298 15.184 7.73768 15.3438Z"
      fill={props.color}
    />
    <path
      d="M7.73761 8.26758H4.32397C4.33732 9.45465 4.51136 10.6345 4.84147 11.7749C5.792 11.558 6.76269 11.4422 7.73761 11.4294V8.26758Z"
      fill={props.color}
    />
    <path
      d="M7.73768 0.666016C6.61298 0.825807 5.62689 1.99966 5.00659 3.73324C5.90348 3.93423 6.81872 4.04191 7.73768 4.05475V0.666016Z"
      fill={props.color}
    />
    <path
      d="M7.73761 4.57976C6.76281 4.56692 5.792 4.45115 4.84147 4.23438C4.51136 5.37473 4.33732 6.55449 4.32397 7.74157H7.73761V4.57976Z"
      fill={props.color}
    />
    <path
      d="M6.1697 0.878906C6.14839 0.884425 6.12709 0.888917 6.10578 0.894436C6.05008 0.909324 5.99515 0.925881 5.9397 0.941924C5.87373 0.961176 5.80827 0.980557 5.74205 1.00161C5.68622 1.0197 5.63128 1.03895 5.57635 1.05808C5.51205 1.0808 5.44762 1.10338 5.38396 1.12751C5.32903 1.14856 5.27487 1.17038 5.22045 1.19271C5.15794 1.21902 5.09531 1.24534 5.03357 1.27152C4.97941 1.29526 4.92589 1.32016 4.87224 1.34519C4.81153 1.37381 4.75108 1.40294 4.69115 1.43323C4.63852 1.46006 4.5859 1.48753 4.53341 1.5155C4.4745 1.54708 4.4161 1.57942 4.35809 1.61253C4.30546 1.64193 4.25374 1.67209 4.20304 1.70289C4.14593 1.73729 4.08946 1.77284 4.03324 1.80801C3.98267 1.84035 3.93249 1.87295 3.88282 1.90658C3.82737 1.94393 3.7727 1.98281 3.71828 2.0217C3.66938 2.05674 3.62074 2.09165 3.57286 2.12695C3.51909 2.16738 3.46775 2.20947 3.41358 2.25144C3.36687 2.28879 3.31976 2.32588 3.27407 2.36452C3.22145 2.4079 3.17152 2.45282 3.12083 2.49774C3.0759 2.53778 3.0306 2.57667 2.9867 2.61813C2.93678 2.66472 2.88813 2.71311 2.83936 2.76111C2.79701 2.80295 2.75388 2.84428 2.7123 2.88727C2.70473 2.89523 2.69741 2.90396 2.68945 2.91204C3.26265 3.20827 3.86665 3.4407 4.49054 3.60524C4.815 2.57103 5.39205 1.63397 6.1697 0.878906Z"
      fill={props.color}
    />
    <path
      d="M2.8379 13.2478C2.8868 13.2959 2.93544 13.3446 2.98563 13.3914C3.02926 13.4318 3.07419 13.4702 3.11898 13.5106C3.16993 13.556 3.22089 13.6014 3.27325 13.645C3.31869 13.6831 3.36502 13.72 3.41148 13.7569C3.4641 13.7996 3.5166 13.8419 3.5723 13.8828C3.61966 13.9184 3.66779 13.9528 3.71592 13.9881C3.77085 14.0275 3.8263 14.0669 3.88226 14.1048C3.93141 14.1379 3.98134 14.1702 4.03127 14.202C4.08812 14.2385 4.14485 14.2743 4.20274 14.309C4.25343 14.3395 4.30464 14.3694 4.35598 14.3987C4.41476 14.4323 4.47368 14.4649 4.5331 14.4966C4.58559 14.5229 4.63822 14.5515 4.69084 14.5778C4.75129 14.6084 4.81225 14.6378 4.87476 14.6668C4.92738 14.6916 4.98077 14.7159 5.03493 14.7398C5.09718 14.7674 5.15994 14.7923 5.22283 14.8186C5.277 14.8407 5.3309 14.8625 5.38558 14.8833C5.44937 14.9078 5.51405 14.9307 5.57874 14.953C5.63341 14.9721 5.68809 14.9914 5.74354 15.0092C5.80925 15.0305 5.87496 15.05 5.94247 15.0694C5.99715 15.0855 6.05208 15.1018 6.10727 15.1165C6.12858 15.1223 6.15014 15.1268 6.17144 15.1323C5.39341 14.3767 4.8161 13.4392 4.49152 12.4043C3.86762 12.569 3.26363 12.8014 2.69043 13.0978C2.69813 13.1056 2.70545 13.1141 2.71315 13.1221C2.75255 13.1647 2.79503 13.2061 2.8379 13.2478Z"
      fill={props.color}
    />
    <path
      d="M2.33374 12.698C2.9652 12.3559 3.63479 12.0895 4.3285 11.9041C3.98838 10.7214 3.80985 9.49816 3.79792 8.26758H0.64624C0.703611 9.89026 1.29696 11.4483 2.33374 12.698Z"
      fill={props.color}
    />
  </svg>
)
