import {
  BoxProgrammationSessionDraftDto,
  BoxProgrammationSessionDto,
} from "@modules/boxProgrammation/services/boxProgrammationDto"
import { BoxSlot, BoxSlotDraft, BoxSlotParticipation, BoxSlotTypeOf, ItemBoxSlot } from "@modules/boxSlot/boxSlotTypes"
import { Exercise, ExerciseDraft } from "@modules/exercises/exerciseTypes"
import { convertExerciseFromDto, convertExerciseToDto } from "@modules/exercises/services/exercisesDto"
import { ProfileDto, convertProfileFromDto } from "@modules/profile/profileDtos"
import { Profile } from "@modules/profile/profileTypes"
import dayjs from "dayjs"

export type ItemBoxSlotDto = {
  id: string
  startTime: string
  endTime: string
  programId: string
  programName: string
  color: string
  registeredParticipantCount: number
  pendingParticipantCount: number
  participantLimit: number
  sessionId?: string
  location?: string
  participation?: BoxSlotParticipation
}

export type BoxSlotDto = ItemBoxSlotDto & {
  owner?: ProfileDto
  contentEarlyAccess: boolean
}

export type BoxSlotDraftDto = {
  programId: string
  startTime: string
  endTime: string
  participantLimit: number
  sessionId: string | null
  contentEarlyAccess: boolean
  participation?: BoxSlotParticipation
  location?: string
}

export type BoxProgrammationSession = {
  id: string
  _type: typeof BoxSlotTypeOf
  name: string
  date: string
  programmationId: string
  description: string
  exercises: Exercise[]
  owner: Profile
  hideSessionContent?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  cardioTag?: boolean
}

export type BoxProgrammationSessionDraft = {
  name: string
  programmationId: string
  description: string
  date?: Date
  exercises: ExerciseDraft[]
  hideSessionContent?: boolean
  gymnasticTag?: boolean
  weightliftingTag?: boolean
  cardioTag?: boolean
}

export function convertItemBoxSlotFromDto(dto: ItemBoxSlotDto): ItemBoxSlot {
  return {
    id: dto.id,
    _type: BoxSlotTypeOf,
    programmationId: dto.programId,
    programmationName: dto.programName,
    color: dto.color,
    startDateTime: dto.startTime,
    endDateTime: dto.endTime,
    sessionId: dto.sessionId,
    registeredParticipantCount: dto.registeredParticipantCount,
    pendingParticipantCount: dto.pendingParticipantCount,
    participantLimit: dto.participantLimit,
    location: dto.location,
    participation: dto.participation,
  }
}

export function convertBoxSlotFromDto(dto: BoxSlotDto): BoxSlot {
  return {
    ...convertItemBoxSlotFromDto(dto),
    contentEarlyAccess: dto.contentEarlyAccess,
  }
}

export function convertBoxProgrammationSessionDraftToDraftDto(
  draft: BoxProgrammationSessionDraft,
): BoxProgrammationSessionDraftDto {
  return {
    name: draft.name,
    description: draft.description,
    exercises: draft.exercises.map((exercise) => convertExerciseToDto(exercise)),
    programId: draft.programmationId,
    hideSessionContent: draft.hideSessionContent,
    gymnasticTag: draft.gymnasticTag,
    weightliftingTag: draft.weightliftingTag,
    cardioTag: draft.cardioTag,
  }
}

export function convertBoxSlotDraftToDraftDto(draft: BoxSlotDraft, sessionId: string | null): BoxSlotDraftDto {
  return {
    programId: draft.programmationId,
    startTime: dayjs(draft.startDateTime).toISOString(),
    endTime: dayjs(draft.endDateTime).toISOString(),
    participantLimit: draft.participantLimit,
    sessionId,
    contentEarlyAccess: draft.contentEarlyAccess,
    participation: draft.participation,
    location: draft.location,
  }
}

export function convertBoxProgrammationSessionFromDto(dto: BoxProgrammationSessionDto): BoxProgrammationSession {
  return {
    id: dto.sessionId,
    _type: BoxSlotTypeOf,
    name: dto.name,
    programmationId: dto.programId,
    description: dto.description,
    date: dto.date,
    owner: convertProfileFromDto(dto.owner),
    exercises: dto.exercises?.map((exercise) => convertExerciseFromDto(exercise)) ?? [],
    hideSessionContent: dto.hideSessionContent,
    gymnasticTag: dto.gymnasticTag,
    weightliftingTag: dto.weightliftingTag,
    cardioTag: dto.cardioTag,
  }
}
