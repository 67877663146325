import { getAuthError } from "@modules/auth/authError"
import { AuthError, UserErrorCodes } from "@modules/auth/authErrorTypes"
import {
  AuthContent,
  AuthForm,
  AuthFormError,
  AuthHeader,
  AuthSubmitButton,
} from "@modules/auth/components/authComponents"
import { AuthPage } from "@modules/auth/page/authPage"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { HUInput } from "@modules/ui/components/huInput"
import { HUText } from "@modules/ui/components/huText"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"

export const LoginPage: React.FC = () => {
  const { userService } = useServicesContext()
  const location = useLocation() as { state?: { email?: string } }
  const [email, setEmail] = useState(location.state?.email ?? "")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const navigate = useNavigate()

  const sendForgotPasswordConfirmationCode = async () => {
    try {
      await userService.sendForgotPasswordConfirmationCode(email)
    } catch (e) {
      console.error(LoggerType.Login + "send confimation code password", e)
      setError(getAuthError(e))
    } finally {
      setLoading(false)
    }
  }

  const login = async () => {
    try {
      setLoading(true)
      await userService.login(email, password)
    } catch (e) {
      console.error(LoggerType.Login + "login", e)
      const loginError = e as any
      setError(getAuthError(e))
      const err = getAuthError(e)
      //account not confirmed
      if (loginError.code === AuthError.UserNotConfirmedException) {
        navigate({ pathname: Path.ConfirmEmail }, { state: { email } })
      }
      if (loginError.status === 404 && loginError.code === UserErrorCodes.UserNotExistsCode) {
        navigate(Path.Register)
      }
      if (loginError.code === AuthError.PasswordResetRequiredException) {
        await sendForgotPasswordConfirmationCode()
        navigate({ pathname: Path.ResetPassword }, { state: { email, isRequiredPassword: true } })
      }
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setError("")
  }, [email, password])

  return (
    <AuthPage>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault()
          login()
        }}
      >
        <AuthHeader title={word("auth.welcomeBack")} subtitle={word("auth.login.form.subtitle")} />
        <AuthContent gap={10}>
          <HUInput
            placeholder={word("auth.form.placeholder.email")}
            value={email}
            onChange={(e) => {
              setEmail(e.currentTarget.value)
            }}
            hasError={!!error}
          />
          <HUInput
            placeholder="&bull;&bull;&bull;&bull;"
            value={password}
            onChange={(e) => {
              setPassword(e.currentTarget.value)
            }}
            hasError={!!error}
            password
            className="w-full"
          />
          <Container>
            <PressableButton to={Path.SendCode} state={{ email }}>
              <HUText fontWeight="semibold">{word("auth.signup.form.reset_password")}</HUText>
            </PressableButton>
          </Container>
          <AuthSubmitButton loading={loading} disabled={!email || !password}>
            {word("auth.login.form.button.login")}
          </AuthSubmitButton>
          <AuthFormError>{error}</AuthFormError>
          <CreateAccountWrapper>
            <div>
              <NoAccountText>{word("auth.login.form.not_account")}</NoAccountText>
              <PressableButton to={Path.SignUp}>
                <HUText fontWeight="semibold">{" " + word("auth.login.form.create_account")}</HUText>
              </PressableButton>
            </div>
          </CreateAccountWrapper>
        </AuthContent>
      </AuthForm>
    </AuthPage>
  )
}

const CreateAccountWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
`

const PressableButton = styled(Link)`
  span {
    text-decoration: underline;
    padding-left: 5px;
    padding-right: 5px;
  }
`

const Container = styled.div`
  text-align: right;
`

const NoAccountText = styled(HUText)`
  color: ${({ theme }) => theme.neutralColor};
`
