import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const CircleDashedIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.9576 2.18216C12.2126 1.93928 13.5026 1.93928 14.7576 2.18216M14.7576 21.8182C13.5026 22.061 12.2126 22.061 10.9576 21.8182M18.4666 3.72116C19.5281 4.44038 20.4413 5.35703 21.1566 6.42116M3.03958 13.9002C2.7967 12.6451 2.7967 11.3552 3.03958 10.1002M21.1366 17.6092C20.4174 18.6706 19.5007 19.5839 18.4366 20.2992M22.6756 10.1002C22.9185 11.3552 22.9185 12.6451 22.6756 13.9002M4.57858 6.39116C5.2978 5.32969 6.21445 4.41644 7.27858 3.70116M7.24858 20.2792C6.18711 19.5599 5.27386 18.6433 4.55858 17.5792"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
