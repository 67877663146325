import { word } from "@modules/core/utils/i18n"
import { HUUnit } from "@modules/utils/unitType"

export const getDisplayUnits = (unit: HUUnit): string => {
  switch (unit) {
    case "METRIC":
      return word("profile.unit.options.metric")
    case "IMPERIAL":
      return word("profile.unit.options.imperial")
  }
}
