import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { word } from "@modules/core/utils/i18n"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { TrainingProgramsFiltersView } from "@modules/training/components/trainingProgramsFilterView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import dayjs from "dayjs"
import styled, { useTheme } from "styled-components"

type ProgramHeaderLeftViewProps = {}

export const ProgramHeaderLeftView: React.FC<ProgramHeaderLeftViewProps> = () => {
  const { currentWeekTitle, prev, next, currentView, selectedDate, setSelectedDate, isUndated, maxDayIndex } =
    useCalendar()
  const theme = useTheme()
  const { sidebarNavigateTo } = useSidebar()

  const canClickPrevButton = isUndated ? typeof selectedDate === "number" && selectedDate >= 7 : true
  const canClickNextButton = isUndated
    ? typeof selectedDate === "number" && maxDayIndex && selectedDate < maxDayIndex - 7
    : true

  const onPrevClicked = () => {
    if (canClickPrevButton) prev()
  }
  const onNextClicked = () => {
    if (canClickNextButton) next()
  }
  const onOpenProgramListFilter = () => {
    sidebarNavigateTo({
      content: <TrainingProgramsFiltersView allowProgramSelection={false} />,
      replaceAllComponents: true,
    })
  }

  return (
    <Container gap={10} className="p-1 md:p-2 xl:p-4 mb-5 col-3 justify-content-center">
      <HURow className="justify-content-around align-items-center text-center">
        <i
          style={{ fontSize: "0.813rem", color: canClickPrevButton ? theme.neutralColor900 : theme.neutralColor300 }}
          className="pi pi-chevron-left cursor-pointer col-2"
          onClick={onPrevClicked}
        />
        <HUText fontStyle={currentView === "dayGridMonth" ? "TM" : "TS"} className="col-8">
          {currentWeekTitle}
        </HUText>
        <i
          style={{ fontSize: "0.813rem", color: canClickNextButton ? theme.neutralColor900 : theme.neutralColor300 }}
          className="pi pi-chevron-right cursor-pointer col-2"
          onClick={onNextClicked}
        />
      </HURow>

      {!isUndated && (
        <TodayButton
          type="Default"
          size="XS"
          colorType="Tertiary"
          className="capitalize p-2 mx-auto"
          onClick={() => setSelectedDate(dayjs())}
          disabled={dayjs().isSame(selectedDate, "day")}
          text={word("global.today.label")}
        />
      )}
      <HUButton
        type="Left_Icon"
        colorType="Primary"
        size="S"
        className="py-2 px-3 mx-auto"
        onClick={onOpenProgramListFilter}
        icon={{
          iconView: <span className={`p-button-icon p-c pi pi-list`} style={{ color: theme.buttonPrimaryTextColor }} />,
        }}
        text={word("planning.seePrograms")}
      />
    </Container>
  )
}

const Container = styled(HUStack)`
  border-radius: 33px;
  background-color: ${({ theme }) => theme.surfaceCard};
`
const TodayButton = styled(HUButton)`
  background-color: ${({ theme }) => theme.mainWhite};
  &:hover {
    background-color: ${({ theme }) => theme.neutralColor200}!important;
  }
`
