import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ChevronIcon: React.FC<IconSvgProps> = (props) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <path
        d="M5.01259 7.42715C4.93777 7.4275 4.86363 7.41291 4.79453 7.38422C4.72542 7.35554 4.66274 7.31334 4.61017 7.2601L0.813749 3.46369C0.73803 3.35291 0.70386 3.21895 0.717267 3.08544C0.730674 2.95193 0.790796 2.82744 0.88703 2.73393C0.983264 2.64042 1.10943 2.5839 1.24327 2.57433C1.37711 2.56476 1.51003 2.60276 1.61859 2.68163L5.01259 6.05284L8.40658 2.68163C8.51361 2.61223 8.64083 2.58078 8.76787 2.59232C8.8949 2.60385 9.01438 2.65769 9.10716 2.74522C9.19994 2.83275 9.26065 2.9489 9.27955 3.07504C9.29846 3.20119 9.27447 3.33003 9.21142 3.44091L5.41501 7.23733C5.36439 7.2947 5.30259 7.34113 5.23339 7.37377C5.16419 7.40641 5.08905 7.42457 5.01259 7.42715Z"
        fill={props.color}
      />
    </svg>
  )
}
