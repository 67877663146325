import { ProgramHeaderCenterView } from "@modules/programs/components/programHeader/programHeaderCenterView"
import { ProgramHeaderLeftView } from "@modules/programs/components/programHeader/programHeaderLeftView"
import { ProgramHeaderRightView } from "@modules/programs/components/programHeader/programHeaderRightView"
import { ProgramInfinite } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff } from "@modules/programs/programOnOffTypes"
import { HURow } from "@modules/ui/components/layout"
import { Dayjs } from "dayjs"

type ProgramHeaderProps = {
  program?: ProgramInfinite | ProgramOnOff
  onAddEventClick: (date?: Dayjs) => void
}
export const ProgramHeader: React.FC<ProgramHeaderProps> = ({ program, onAddEventClick }) => {
  return (
    <HURow className="justify-content-between gap-1 lg:gap-2 xl:gap-3">
      <ProgramHeaderLeftView />
      <ProgramHeaderCenterView program={program} />
      <ProgramHeaderRightView program={program} onAddEventClick={onAddEventClick} />
    </HURow>
  )
}
