import { requiredInput } from "@modules/form/formUtils"
import { ObjectiveDraft } from "@modules/objectives/objectivesTypes"
import dayjs from "dayjs"
import * as Yup from "yup"

export type ObjectiveFormikType = {
  id: string
  objective: string
  targetDate: Date
}

export function convertObjectiveFormFormik(formik: ObjectiveFormikType): ObjectiveDraft {
  const result = {
    id: formik.id,
    objective: formik.objective,
    targetDate: dayjs(formik.targetDate).format("YYYY-MM-DD"),
  }
  return result
}

export function getObjectivesValidationSchema() {
  return Yup.object({
    objective: requiredInput(Yup.string().trim()),
    targetDate: requiredInput(Yup.string().trim()),
  })
}
