import { LayoutContext } from "@modules/app/context/layoutcontext"
import { MenuContext } from "@modules/app/context/menucontext"
import { AppMenuItemProps } from "@modules/app/types/types"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { CustomIcons } from "@modules/ui/styles/customIcons"
import { Badge } from "primereact/badge"
import { Ripple } from "primereact/ripple"
import { classNames } from "primereact/utils"
import { useContext, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const AppMenuItem = (props: AppMenuItemProps) => {
  const { activeMenu, setActiveMenu } = useContext(MenuContext)
  const { isDesktop, layoutState, setLayoutState, layoutConfig, breadcrumb } = useContext(LayoutContext)
  const submenuRef = useRef<HTMLUListElement>(null)
  const menuitemRef = useRef<HTMLLIElement>(null)
  const item = props.item
  const key = props.parentKey ? props.parentKey + "-" + props.index : String(props.index)
  const isActiveRoute = item?.to && breadcrumb?.to === item.to
  const active = activeMenu === key || !!(activeMenu && activeMenu.startsWith(key + "-"))
  const theme = useTheme()
  const textContainerStyle = {
    opacity: !layoutState.staticMenuDesktopInactive ? 1 : 0, // 0 to hide, 1 to show
    transition: "opacity 1.5s ease",
  }

  useEffect(() => {
    if (isActiveRoute) {
      setActiveMenu(key)
    }
  }, [layoutConfig])

  const itemClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    //avoid processing disabled items
    if (item?.disabled) {
      event.preventDefault()
      return
    }

    //execute command
    if (item?.command) {
      item.command({ originalEvent: event, item })
    }

    // toggle active state
    if (item?.items) {
      setActiveMenu(active ? props.parentKey ?? "" : key)
    } else {
      if (!isDesktop()) {
        setLayoutState((prevLayoutState) => ({
          ...prevLayoutState,
          staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive,
        }))
      }

      setActiveMenu(key)
    }
  }

  const subMenu =
    item?.items && item.visible !== false ? (
      <ul ref={submenuRef}>
        {item.items.map((child, i) => {
          return (
            <AppMenuItemContainer key={i}>
              <AppMenuItem
                item={child}
                index={i}
                className={child.badgeClass}
                parentKey={key}
                key={child.label}
                disabled={child.isDisable}
              />
            </AppMenuItemContainer>
          )
        })}
      </ul>
    ) : null

  if (item?.isHidden) return

  return !item?.isDisable ? (
    <li
      ref={menuitemRef}
      className={classNames({
        "layout-root-menuitem": props.root,
        "active-menuitem": active,
      })}
    >
      {(!item?.to || item.items) && item?.visible !== false ? (
        <>
          <a
            href={item?.url}
            onClick={(e) => itemClick(e)}
            className={classNames(item?.class, "p-ripple tooltip-target")}
            target={item?.target}
            data-pr-tooltip={item?.label}
            tabIndex={0}
          >
            <div className="layout-menuitem-icon-container">
              <i className={classNames("layout-menuitem-icon", item?.icon)} style={{ marginTop: -1 }} />
            </div>
            <>
              <HUText fontStyle="LL">{item?.label}</HUText>
              {item?.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler" />}
            </>
            <Ripple />
          </a>
        </>
      ) : null}

      {item?.to && !item.items && item.visible !== false ? (
        <>
          <Link
            to={item.to}
            replace={item.replaceUrl}
            onClick={(e) => itemClick(e)}
            className={classNames(item.class, "p-ripple", {
              "active-route": isActiveRoute,
            })}
            tabIndex={0}
          >
            <div className="layout-menuitem-icon-container">
              <i className={classNames("layout-menuitem-icon", item.icon)} style={{ marginTop: -1 }} />
              {item.badge === "new" && <StyledBadge severity="danger" />}
            </div>
            <div style={textContainerStyle}>
              <HUText
                fontStyle={isActiveRoute ? "LL" : "BM"}
                style={{ height: "1.313rem", lineHeight: "22px" }}
                className="sb-overflow-hidden"
              >
                {item.label}
              </HUText>
              {item.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler" />}
            </div>
            <Ripple />
          </Link>
        </>
      ) : null}
      {subMenu}
    </li>
  ) : (
    <li className="layout-menu-item-disabled">
      <a tabIndex={-1} className={classNames(item.class, "p-ripple")}>
        <div className="flex layout-menuitem-icon-container">
          <i className={classNames("layout-menuitem-icon", item.icon)} style={{ marginTop: -1 }} />
        </div>
        <div className="flex align-items-center sb-overflow-hidden" style={textContainerStyle}>
          <HUText color={theme.mainBlack} fontStyle="BM">
            {item.label}
          </HUText>
          <ComingSoonText fontStyle="LXS" numberOfLine={1}>
            {word("menu.coming_soon")}
          </ComingSoonText>
        </div>
      </a>
    </li>
  )
}

const AppMenuItemContainer = styled.div`
  ${CustomIcons};
  padding-top: 6px;
  .layout-menu-item-disabled {
    opacity: 0.5;
    > a {
      cursor: default !important;
      &:hover {
        transition: none !important;
        background-color: unset !important;
      }
      margin-right: 0px !important;
    }
  }
  a.p-ripple {
    padding: 0.75rem !important;
    margin: 0 1rem;
    border-radius: 6px;
    &:focus {
      background-color: var(--menuitem-hover-bg-color);
    }
  }
`

const ComingSoonText = styled(HUText)`
  color: ${({ theme }) => theme.secondaryColor};
  background-color: ${({ theme }) => theme.neutralColor100};
  margin-left: 10px;
  border-radius: 44px;
  padding: 2px 6px;
`

const StyledBadge = styled(Badge)`
  position: absolute;
  top: -4px;
  right: -4px;
  height: 0.5rem;
  width: 0.5rem;
  min-width: 0.5rem;
`
