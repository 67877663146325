import { NewChatGroupNameForm } from "@modules/chat/components/newChatGroupNameForm"
import { UserSearchList } from "@modules/chat/components/userSearchList"
import { UserSearchSelection } from "@modules/chat/components/userSearchSelection"
import { useChatPageContext } from "@modules/chat/contexts/chatPageContext"
import { useCreateChannel } from "@modules/chat/hooks/useCreateChat"
import { CHANNEL_LIST_SHOWN_WINDOW_WIDTH } from "@modules/chat/utils/chatConstants"
import { word } from "@modules/core/utils/i18n"
import { ListHeader } from "@modules/ui/components/header"
import { LoadingPoints } from "@modules/ui/components/loading"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { useWindowSize } from "@modules/utils/hooks/useWindowsSize"
import { debounce } from "lodash"
import { Button } from "primereact/button"
import React, { useMemo, useState } from "react"
import styled from "styled-components"

export const NewChatChannelForm: React.FC = () => {
  const {
    onChangeSearchText,
    results,
    loading,
    searchText,
    selectedUsers,
    toggleUser,
    isCreatingGroup,
    updateIsCreatingGroup,
    removeUser,
    reset: resetSearchForm,
    updateIsCreatingNewChannel,
    toggleDisplayChannelList,
    isAddingMembersToChannel,
    updateIsAddingMembersToChannel,
  } = useChatPageContext()

  const { createOneToOneChannel, createGroupChannel } = useCreateChannel()
  const { width } = useWindowSize()
  const [displayGroupNameInput, updateDisplayGroupNameInput] = useState<boolean>(false)

  const changeUserSearchField = useMemo(
    () =>
      debounce(
        (userName) => {
          onChangeSearchText(userName)
        },
        300,
        { leading: false, trailing: true },
      ),
    [],
  )

  const onUserClick = async (userId: string) => {
    if (isAddingMembersToChannel) {
      await isAddingMembersToChannel.addMembers([userId])
      updateIsAddingMembersToChannel(undefined)
      resetSearchForm()
    } else onCreateOneToOneChannel(userId)
  }

  const onCreateOneToOneChannel = async (userId: string) => {
    await createOneToOneChannel(userId)
    resetSearchForm()
    updateIsCreatingNewChannel(false)
    updateIsCreatingGroup(false)
  }

  const showGroupNameDialog = () => {
    updateDisplayGroupNameInput(true)
  }

  const validateGroupCreation = async (groupName: string) => {
    await createGroupChannel(
      groupName,
      selectedUsers.map((u) => u.id),
    )
    resetSearchForm()
    updateIsCreatingNewChannel(false)
    updateIsCreatingGroup(false)
  }

  return (
    <Container className="chat-group-creation str-chat bordertopright12">
      <div className="p-2 pl-3 mb-1 flex flex-row align-items-center justify-content-between">
        {width <= CHANNEL_LIST_SHOWN_WINDOW_WIDTH && (
          <button
            aria-label="Menu"
            className="block str-chat-button line-height-1 w-2rem h-2rem border-circle transition-colors transition-duration-150"
            onClick={toggleDisplayChannelList}
          >
            <i className="pi pi-bars" />
          </button>
        )}

        <ListHeader
          title={
            width > CHANNEL_LIST_SHOWN_WINDOW_WIDTH
              ? isCreatingGroup
                ? word("chat.create_group.title")
                : word("chat.search.title")
              : ""
          }
          className="flex-1 justify-content-between"
          search={{
            onChange: (e) => changeUserSearchField(e.target.value),
            placeholder: word("chat.search.placeholder"),
            value: searchText,
          }}
          rightView={
            <div className="flex">
              {isCreatingGroup && (
                <Button
                  className="p-button-rounded p-button-primary mr-2 mt-3 mb-3"
                  onClick={showGroupNameDialog}
                  label={width > 1440 ? word("global.check") : ""}
                  disabled={!(selectedUsers.length > 0)}
                  icon={width <= 1440 && "pi pi-check"}
                  size="small"
                />
              )}
            </div>
          }
        />
      </div>
      {selectedUsers.length > 0 && <UserSearchSelection users={selectedUsers} onRemoveUser={removeUser} />}
      {loading ? (
        <LoadingPoints />
      ) : (
        <UserSearchList
          users={results}
          isCreatingGroup={isCreatingGroup}
          onSelect={toggleUser}
          selectedUsers={selectedUsers}
          onUserClick={isCreatingGroup ? undefined : onUserClick}
          searchText={searchText}
        />
      )}

      <HUDialog
        style={{ width: "500px" }}
        visible={displayGroupNameInput}
        header={word("chat.add_group_name")}
        onHide={() => updateDisplayGroupNameInput(false)}
      >
        <NewChatGroupNameForm onValidate={validateGroupCreation} onCancel={() => updateDisplayGroupNameInput(false)} />
      </HUDialog>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
`
