import { PlusIcon } from "@images/svgIcons/plusIcon"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import styled, { useTheme } from "styled-components"

type AddExerciceBlocProps = {
  onAddExercise: () => void
}

export const AddExerciceBloc: React.FC<AddExerciceBlocProps> = ({ onAddExercise }) => {
  const theme = useTheme()

  return (
    <AddExerciseContainer onClick={onAddExercise}>
      <PlusIconContainer>
        <PlusIcon color={theme.neutralColor700} width={14} height={14} />
      </PlusIconContainer>
      <HUText className="my-1" fontStyle="TL">
        {word("sessionForm.exercises.add_exo.button")}
      </HUText>
      <HUText className="text-center my-1" color={theme.neutralColor600}>
        {word("sessionForm.create.subtitle")}
      </HUText>
    </AddExerciseContainer>
  )
}

const PlusIconContainer = styled.div`
  background-color: ${({ theme }) => theme.sessionAddExerciseIconBgColor};
  transition: background-color 1s ease;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AddExerciseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 14px 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.sessionAddExerciseBgColor};
  transition: background-color 1s ease;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.sessionAddExerciseHoverBgColor};
    transition: background-color 1s ease;
    ${PlusIconContainer} {
      background-color: ${({ theme }) => theme.sessionAddExerciseIconHoverBgColor};
      transition: background-color 1s ease;
    }
  }
`
