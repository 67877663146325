import { useBoxPlanningSlots } from "@modules/boxPlanning/hooks/useBoxPlanningSessionEvent"
import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxProgrammationSessionsCalendarDayColumn } from "@modules/boxProgrammationSessions/components/boxProgrammationSessionsCalendarDayColumn"
import { useBoxSlotSessionDetails } from "@modules/boxSlot/hooks/useBoxSlotDetails"
import { useBoxScheduledSessionsInRange } from "@modules/boxSlot/hooks/useBoxSlotSessionScheduled"
import { useBoxSlotSidebar } from "@modules/boxSlot/hooks/useBoxSlotSidebar"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import dayjs, { Dayjs } from "dayjs"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

export const BoxProgrammationSessionsCalendar: React.FC<{
  boxProgrammation: BoxProgrammation
  loading: boolean
}> = ({ boxProgrammation, loading }) => {
  const [selectedSlotId, setSelectedSlotId] = useState<string>()

  const { sessions: scheduledSessions } = useBoxScheduledSessionsInRange()
  const slots = useBoxPlanningSlots()
  const { navigateToBoxSessionView } = useBoxSlotSidebar()
  const { refreshCalendarEvents, currentWeekdays } = useCalendar()
  const { navigateToSessionForm } = useSessionSidebar()
  const { setDateSession, setProgram, setEditedSlot } = useSessionFormContext()
  const { result: boxSlotSession } = useBoxSlotSessionDetails(selectedSlotId)

  useEffect(() => {
    if (boxProgrammation) setProgram(boxProgrammation)
  }, [boxProgrammation])

  useEffect(() => {
    if (boxSlotSession) setEditedSlot(boxSlotSession)
  }, [boxSlotSession])

  const getSlotsOnSelectedDay = useCallback(
    (value: Dayjs) => {
      return slots.filter(
        (slot) => slot.programmationId === boxProgrammation.id && dayjs(slot.startDateTime).isSame(dayjs(value), "day"),
      )
    },
    [slots],
  )

  const onAddEventClick = async (value: Dayjs) => {
    const slotsOnSelectedDay = getSlotsOnSelectedDay(value)
    if (value && typeof value !== "number") setDateSession(value.format(defaultDateFormat))

    if (slotsOnSelectedDay && slotsOnSelectedDay.length > 0) {
      setSelectedSlotId(slotsOnSelectedDay[0].id)
      navigateToSessionForm(true, false, refreshCalendarEvents)
    }
  }

  const onClickOnSession = (id: string) => {
    const editedSession = scheduledSessions.find((session) => session.id === id)
    if (editedSession) {
      navigateToBoxSessionView(boxProgrammation, editedSession, refreshCalendarEvents)
    }
  }
  return (
    <Container className="flex flex-column align-content-center flex-grow-1">
      <div className="calendar relative">
        <table className="calendar-table">
          <thead>
            <tr>
              {currentWeekdays?.map((weekday) => (
                <th
                  className={`weekday-label weekday-label-${weekday.weekdayIndex} ${weekday.isToday ? "today" : ""}`}
                  key={weekday.weekdayIndex}
                >
                  {weekday.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {!loading &&
                currentWeekdays?.map((weekday, index) => (
                  <BoxProgrammationSessionsCalendarDayColumn
                    key={index}
                    date={weekday.date}
                    sessions={scheduledSessions.filter((session) => dayjs(session.date).isSame(weekday.date, "date"))}
                    slots={weekday.date && getSlotsOnSelectedDay(weekday.date)}
                    onAddSessionClick={onAddEventClick}
                    onClickOnSession={onClickOnSession}
                  />
                ))}
            </tr>
          </tbody>
        </table>
        {loading && (
          <div className="no-access-overlay">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </Container>
  )
}

const Container = styled.div``
