import { UserGridItem } from "@modules/chat/components/userGridItem"
import { DefaultGenerics, UserResponse } from "stream-chat"
import styled from "styled-components"

type UserSearchSelectionProps = {
  users: UserResponse<DefaultGenerics>[]
  onRemoveUser: (index: number) => void
}

export const UserSearchSelection: React.FC<UserSearchSelectionProps> = ({ users, onRemoveUser }) => {
  return (
    <Container>
      {users.map((user, index) => (
        <UserGridItem key={index} user={user} onRemoveUser={() => onRemoveUser(index)} removeButton />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  overflow-x: auto;
  border-bottom: 1px solid ${({ theme }) => theme.neutralColor600};
`
