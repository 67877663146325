import { allCurrencies } from "@modules/currency/currency"
import { FinanceStatistics } from "@modules/finances/types/financesType"
import { convertToType } from "@modules/utils/enum"
import { isInArray } from "@modules/utils/isInArray"
import { LoggerType } from "@modules/utils/loggerUtils"

export interface FinanceStatisticsDto {
  totalTransactionsValue: number
  totalTransactionsCurrency: string
  activeMembersNumber: number
}

export function convertFinanceStatisticsFromDto(dto: FinanceStatisticsDto): FinanceStatistics {
  if (!isInArray(allCurrencies, dto.totalTransactionsCurrency)) {
    console.log(LoggerType.Financial, "Currency transactions not supported")
  }
  return {
    totalTransactionsValue: dto.totalTransactionsValue,
    totalTransactionsCurrency: convertToType(allCurrencies, dto.totalTransactionsCurrency, "EUR"),
    activeMembersNumber: dto.activeMembersNumber,
  }
}
