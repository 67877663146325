import { WarningHexagonalIcon } from "@images/svgIcons/warningIcon"
import { AuthFormError } from "@modules/auth/components/authComponents"
import { ProfileFormikType, ProfileInputKey } from "@modules/auth/profileFormikTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { useUsernameValidity } from "@modules/profile/useUsername"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { SimpleFormik } from "@src/typings/formik"
import styled, { useTheme } from "styled-components"

export type RegisterFormFourPageProps = {
  handleSubmit: () => void
  onPrevious: () => void
  formik: SimpleFormik<ProfileFormikType, ProfileInputKey>
  isFormFieldInValid: (name: ProfileInputKey) => boolean
  getFormErrorMessage: (name: ProfileInputKey, errorMessage?: string) => JSX.Element | undefined
  disabled: boolean
  error: string
  setError: (value: string) => void
}
export const RegisterFormFourPage = ({
  handleSubmit,
  onPrevious,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  disabled,
  error,
  setError,
}: RegisterFormFourPageProps) => {
  const theme = useTheme()
  const { userService } = useServicesContext()

  const checkUsername = async (v: string) => {
    return userService.checkUsernameAvailable(v)
  }
  const { isValid: isUsernameValid, onUsernameChange } = useUsernameValidity(checkUsername, true)

  const getUsernameError = () => {
    if (error) {
      return <AuthFormError>{error}</AuthFormError>
    }
    if (isUsernameValid === false) {
      return <AuthFormError>{word("auth.error.username_exists")}</AuthFormError>
    }
    if (!!getFormErrorMessage("username")) {
      return getFormErrorMessage("username")
    }
  }

  return (
    <HUStack gap={2}>
      <HUButton
        type="Left_Icon"
        size="XS"
        colorType="Tertiary"
        text={word("global.previous")}
        className=""
        onClick={onPrevious}
        icon={{
          iconView: <span className={`pi pi-arrow-left`} style={{ color: theme.textPrimary }} />,
        }}
      />
      <HUText fontStyle="TM">4/4</HUText>
      <HUText fontStyle="TM">{word("auth.signup.form.page.4.title")}</HUText>
      <HUText fontStyle="BS" color={theme.neutralColor700}>
        {word("auth.signup.form.page.4.description")}
      </HUText>
      <HUStack className="mt-2">
        <AvatarCard>
          <FormikInput
            name="avatar"
            type="avatar"
            isRequiredInput
            avatarProps={{ username: formik.values.username }}
            getFieldProps={formik.getFieldProps}
            setFieldValue={formik.setFieldValue}
            isInvalid={isFormFieldInValid("avatar")}
            error={getFormErrorMessage("avatar")}
          />
        </AvatarCard>
        <FormikInput
          flex
          isInvalid={isFormFieldInValid("username")}
          getFieldProps={formik.getFieldProps}
          name="username"
          error={getUsernameError()}
          placeHolder={word("auth.signup.form.username")}
          textProps={{
            onChange: (e) => {
              formik.setFieldValue("username", e.target.value)
              onUsernameChange(e.target.value)
              setError("")
            },
          }}
        />
      </HUStack>
      <HURow className="flex align-items-start">
        <WarningContainer>
          <WarningHexagonalIcon color={theme.neutralColor600} width={24} height={24} />
        </WarningContainer>
        <HUText fontStyle="BM" className="mb-3 ml-2">
          {word("auth.signup.form.warning.pseudoMessage")}
        </HUText>
      </HURow>
      <HUButton
        type="Default"
        size="M"
        colorType="Primary"
        text={word("global.letsgo")}
        className="mb-5"
        style={{ width: "100%" }}
        onClick={handleSubmit}
        disabled={disabled || isUsernameValid === false}
        submit
      />
    </HUStack>
  )
}
const AvatarCard = styled.div`
  background-color: ${({ theme }) => theme.neutralColor100};
  padding: 10px 10px;
  border-radius: 20px;
`
const WarningContainer = styled.div``
