import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const WinningCupIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="150" height="151" viewBox="0 0 150 151" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="150" height="150" transform="translate(0 0.5)" fill="white" />
    <mask
      id="mask0_1945_48229"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="58"
      y="85"
      width="33"
      height="30"
    >
      <path
        d="M72.8924 85.6821C72.8924 85.6821 73.7567 92.2549 69.8662 97.0839C68.7972 98.4108 67.3826 99.5465 65.9668 100.473C61.3392 101.784 58.2422 104.175 58.2422 106.909C58.2422 111.051 65.354 114.409 74.1269 114.409C82.8998 114.409 90.0116 111.051 90.0116 106.909C90.0116 105.629 89.3331 104.425 88.1366 103.371H88.1476C88.1476 103.371 88.1415 103.369 88.1294 103.364C86.7653 102.166 84.7306 101.162 82.2624 100.466C80.8347 99.5422 79.4163 98.4096 78.357 97.0839C74.4922 92.2471 75.3614 85.6821 75.3614 85.6821H72.8924Z"
        fill="#B0D717"
      />
    </mask>
    <g mask="url(#mask0_1945_48229)">
      <path
        d="M72.8924 85.6821C72.8924 85.6821 73.7567 92.2549 69.8662 97.0839C68.7972 98.4108 67.3826 99.5465 65.9668 100.473C61.3392 101.784 58.2422 104.175 58.2422 106.909C58.2422 111.051 65.354 114.409 74.1269 114.409C82.8998 114.409 90.0116 111.051 90.0116 106.909C90.0116 105.629 89.3331 104.425 88.1366 103.371H88.1476C88.1476 103.371 88.1415 103.369 88.1294 103.364C86.7653 102.166 84.7306 101.162 82.2624 100.466C80.8347 99.5422 79.4163 98.4096 78.357 97.0839C74.4922 92.2471 75.3614 85.6821 75.3614 85.6821H72.8924Z"
        fill="#DDF247"
      />
      <circle cx="99.7414" cy="91.0055" r="25.6164" fill="#B0D717" />
    </g>
    <mask
      id="mask1_1945_48229"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="51"
      y="40"
      width="14"
      height="26"
    >
      <path
        d="M51.915 43.3183C52.0267 41.2537 54.1445 39.9184 56.0556 40.7075L64.0194 43.9959C64.5341 45.0322 64.0754 46.2882 63.014 46.7488L62.9229 46.7884L55.6266 43.9471C54.971 43.6917 54.2637 44.1753 54.2637 44.8789L54.2637 50.3221C54.2637 50.9745 54.4763 51.6091 54.8694 52.1298L64.0393 64.2754C64.0393 64.2754 63.593 65.4877 62.923 65.7874C62.4068 66.0183 61.4757 65.8336 61.4757 65.8336L53.2286 55.2056C52.0488 53.6853 51.4567 51.7906 51.5606 49.869L51.915 43.3183Z"
        fill="#133C40"
      />
    </mask>
    <g mask="url(#mask1_1945_48229)">
      <path
        d="M50.5104 41.8479C51.2289 40.588 52.7458 40.0225 54.1137 40.5046L64.0204 43.9961C64.5351 45.0324 64.0764 46.2884 63.015 46.749L62.9239 46.7886L69.0936 50.6875C69.4925 50.9395 69.6609 51.436 69.4977 51.8787L66.2822 60.603C65.2431 63.4222 62.279 65.0381 59.3464 64.3841L55.9562 63.6281C55.9562 63.6281 55.2263 63.7485 54.5563 64.0482C54.0401 64.2791 53.109 64.0944 53.109 64.0944L47.6699 55.1454C46.1598 52.661 46.1165 49.5528 47.5568 47.0273L50.5104 41.8479Z"
        fill="#245453"
      />
      <path
        d="M68.5882 63.7268L60.2248 53.8568C59.2218 52.6732 58.7163 51.1471 58.8143 49.5988L59.1563 44.1986L55.3637 42.8406C54.9523 42.6933 54.501 42.7028 54.0962 42.8674C53.4269 43.1395 52.9891 43.79 52.9891 44.5126L52.9891 50.1974C52.9891 51.2131 53.3755 52.1908 54.07 52.9321L60.6466 59.9523L68.5882 63.7268Z"
        fill="#0F3033"
      />
    </g>
    <mask
      id="mask2_1945_48229"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="69"
      y="83"
      width="10"
      height="5"
    >
      <ellipse cx="74.1265" cy="85.4087" rx="4.23593" ry="2" fill="#133C40" />
    </mask>
    <g mask="url(#mask2_1945_48229)">
      <ellipse cx="74.1265" cy="85.4087" rx="4.23593" ry="2" fill="#0F3033" />
      <ellipse cx="71.5367" cy="85.1709" rx="4.23593" ry="2" fill="#245453" />
    </g>
    <mask
      id="mask3_1945_48229"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="58"
      y="45"
      width="33"
      height="41"
    >
      <path
        d="M58.2359 45.6069H90.0053C90.0053 45.6069 90.7702 85.1711 74.1206 85.1711C57.471 85.1711 58.2359 45.6069 58.2359 45.6069Z"
        fill="#DDF247"
      />
    </mask>
    <g mask="url(#mask3_1945_48229)">
      <path
        d="M58.2359 45.6069H90.0053C90.0053 45.6069 90.7702 85.1711 74.1206 85.1711C57.471 85.1711 58.2359 45.6069 58.2359 45.6069Z"
        fill="#B0D717"
      />
      <path
        d="M53.0992 45.418H84.8686C84.8686 45.418 85.6334 84.9821 68.9839 84.9821C52.3343 84.9821 53.0992 45.418 53.0992 45.418Z"
        fill="#DDF247"
      />
    </g>
    <ellipse cx="74.1269" cy="45.418" rx="15.8847" ry="7.5" fill="url(#paint0_linear_1945_48229)" />
    <mask
      id="mask4_1945_48229"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="84"
      y="49"
      width="18"
      height="24"
    >
      <path
        d="M101.05 56.0323C101.221 54.5919 100.336 53.236 98.9495 52.8114L88.9058 49.7363C87.8762 50.2643 87.4992 51.5472 88.0795 52.5482L88.1293 52.6341L97.3108 55.5318C97.7721 55.6774 98.0631 56.1324 98.0017 56.6123L97.4311 61.074C97.157 63.2166 95.7524 65.0466 93.7535 65.8652L84.6097 69.61C84.6097 69.61 84.2487 70.8504 84.6097 71.4894C84.8878 71.9818 85.7466 72.3862 85.7466 72.3862L95.4377 68.4173C98.1281 67.3155 100.009 64.8407 100.35 61.9535L101.05 56.0323Z"
        fill="#133C40"
      />
    </mask>
    <g mask="url(#mask4_1945_48229)">
      <path
        d="M101.05 56.0323C101.221 54.5919 100.336 53.236 98.9495 52.8114L88.9058 49.7363C87.8762 50.2643 87.4992 51.5472 88.0795 52.5482L88.1293 52.6341L97.3108 55.5318C97.7721 55.6774 98.0631 56.1324 98.0017 56.6123L97.4311 61.074C97.157 63.2166 95.7524 65.0466 93.7535 65.8652L84.6097 69.61C84.6097 69.61 84.2487 70.8504 84.6097 71.4894C84.8878 71.9818 85.7466 72.3862 85.7466 72.3862L95.4377 68.4173C98.1281 67.3155 100.009 64.8407 100.35 61.9535L101.05 56.0323Z"
        fill="#245453"
      />
      <path
        d="M82.5699 67.4513L89.013 65.9161L94.2377 67.4513L95.4591 66.9029C97.259 66.0948 98.5466 64.4534 98.9029 62.5128L100.698 52.7352L105.44 51.6647L85.6006 53.2362L89.013 49.411L102.459 53.2362L104.585 68.4292L86.6797 76.1319L82.5699 67.4513Z"
        fill="#0F3033"
      />
    </g>
    <rect
      x="115.023"
      y="78.6587"
      width="14.4966"
      height="2"
      transform="rotate(5.37759 115.023 78.6587)"
      fill="#D0CAFD"
    />
    <rect
      x="102.168"
      y="102.422"
      width="11.8951"
      height="2"
      transform="rotate(32.6548 102.168 102.422)"
      fill="#D0CAFD"
    />
    <rect
      x="29.0664"
      y="37.8779"
      width="12.3218"
      height="2"
      transform="rotate(24.5662 29.0664 37.8779)"
      fill="#D0CAFD"
    />
    <rect
      x="75.9062"
      y="28.8071"
      width="13.1066"
      height="2"
      transform="rotate(-86.8543 75.9062 28.8071)"
      fill="#D0CAFD"
    />
    <rect
      x="74.832"
      y="133.057"
      width="10.8477"
      height="2"
      transform="rotate(-94.9519 74.832 133.057)"
      fill="#D0CAFD"
    />
    <rect
      x="14.0195"
      y="83.939"
      width="18.8679"
      height="2"
      transform="rotate(-7.65176 14.0195 83.939)"
      fill="#D0CAFD"
    />
    <rect
      x="110.59"
      y="41.4453"
      width="19.8003"
      height="2"
      transform="rotate(-33.6625 110.59 41.4453)"
      fill="#D0CAFD"
    />
    <rect
      x="116.242"
      y="57.4004"
      width="4.81498"
      height="2"
      transform="rotate(-19.121 116.242 57.4004)"
      fill="#6450F7"
    />
    <rect
      x="48.3984"
      y="128.391"
      width="7.29178"
      height="2"
      transform="rotate(-66.9249 48.3984 128.391)"
      fill="#6450F7"
    />
    <rect x="24.5" y="101.326" width="4.03117" height="2" transform="rotate(-22.6968 24.5 101.326)" fill="#6450F7" />
    <rect
      x="27.2266"
      y="59.5073"
      width="6.43277"
      height="2"
      transform="rotate(13.9775 27.2266 59.5073)"
      fill="#6450F7"
    />
    <rect
      x="95.8047"
      y="31.8208"
      width="7.29178"
      height="2"
      transform="rotate(-65.5251 95.8047 31.8208)"
      fill="#6450F7"
    />
    <rect
      x="99.3984"
      y="125.428"
      width="7.29178"
      height="2"
      transform="rotate(-123.4 99.3984 125.428)"
      fill="#6450F7"
    />
    <rect
      x="48.4453"
      y="25.8008"
      width="7.29178"
      height="2"
      transform="rotate(-128.24 48.4453 25.8008)"
      fill="#6450F7"
    />
    <rect
      x="125.738"
      y="101.616"
      width="4.75348"
      height="2"
      transform="rotate(-141.728 125.738 101.616)"
      fill="#6450F7"
    />
    <rect
      x="34.4336"
      y="108.491"
      width="10.4064"
      height="2"
      transform="rotate(-34.3696 34.4336 108.491)"
      fill="#D0CAFD"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1945_48229"
        x1="69.7655"
        y1="39.1836"
        x2="74.1269"
        y2="52.918"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.353266" stopColor="#B0D717" />
        <stop offset="1" stopColor="#82A10A" />
      </linearGradient>
    </defs>
  </svg>
)
