import { AuthService } from "@modules/auth/services/authService"
import { SharedUserService } from "@modules/auth/services/sharedUserService"
import { ServiceManager } from "@modules/core/services/serviceManager"
import { UserApi } from "@modules/profile/services/userApi"
import { LocalStorageService } from "@modules/storage/services/localStorageService"

export class UserService extends SharedUserService {
  constructor(
    protected readonly authService: AuthService,
    protected readonly userApi: UserApi,
    protected localStorage: LocalStorageService,
    protected serviceManager: ServiceManager,
  ) {
    super(authService, userApi, localStorage, serviceManager)
  }
}
