import { BoxPlanningHeaderCenterView } from "@modules/boxPlanning/components/boxPlanningHeader/boxPlanningHeaderCenterView"
import { BoxPlanningHeaderLeftView } from "@modules/boxPlanning/components/boxPlanningHeader/boxPlanningHeaderLeftView"
import { BoxPlanningHeaderRightView } from "@modules/boxPlanning/components/boxPlanningHeader/boxPlanningHeaderRightView"
import { BoxProgrammingHeaderLeftView } from "@modules/boxPlanning/components/boxProgrammingHeader/boxProgrammingHeaderLeftView"
import { BoxProgrammingHeaderRightView } from "@modules/boxPlanning/components/boxProgrammingHeader/boxProgrammingHeaderRightView"
import { BoxTemplateHeaderRightView } from "@modules/boxPlanning/components/boxTemplateHeader/boxTemplateHeaderRightView"
import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { HURow } from "@modules/ui/components/layout"

export const BoxPlanningHeader: React.FC<{
  boxProgrammation: BoxProgrammation | null
  isProgramming?: boolean
  isTemplate?: boolean
}> = ({ boxProgrammation, isProgramming, isTemplate }) => {
  return (
    <HURow className="justify-content-between align-items-start">
      <div className="col-3">{isTemplate ? <BoxProgrammingHeaderLeftView /> : <BoxPlanningHeaderLeftView />}</div>
      <div className="col-6">
        <BoxPlanningHeaderCenterView boxProgrammationId={boxProgrammation?.id} />
      </div>
      <div className="col-3">
        {isProgramming ? (
          <BoxProgrammingHeaderRightView boxProgrammationId={boxProgrammation?.id} />
        ) : isTemplate ? (
          <BoxTemplateHeaderRightView boxProgrammationId={boxProgrammation?.id} />
        ) : (
          <BoxPlanningHeaderRightView selectedProgrammation={boxProgrammation} />
        )}{" "}
      </div>
    </HURow>
  )
}
