import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

type Props = {
  color: string
} & IconSvgProps

export const CrossIcon: React.FC<Props> = ({ color, ...props }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="0.72937"
      y="1.74994"
      width="1.27167"
      height="16.4994"
      rx="0.635833"
      transform="rotate(-45 0.72937 1.74994)"
      fill={color}
    />
    <rect
      x="12.396"
      y="0.583252"
      width="1.27167"
      height="16.4994"
      rx="0.635833"
      transform="rotate(45 12.396 0.583252)"
      fill={color}
    />
  </svg>
)
