import React, { PropsWithChildren, useContext } from "react"
import { getAllServices } from "./services"

type ContextServices = ReturnType<typeof getAllServices>

export const ServicesContext = React.createContext<ContextServices>({} as ContextServices)

export const ServicesContextProvider = ({ children }: PropsWithChildren) => {
  return <ServicesContext.Provider value={getAllServices()}>{children}</ServicesContext.Provider>
}

export const useServicesContext = () => useContext(ServicesContext)
