import { CloseIcon } from "@images/svgIcons/navigation/closeIcon"
import React from "react"
import { useTheme } from "styled-components"

/**
 * This wrapper is used to harmonize the background of the onboarding boxes and Stripe.
 * @component
 * @param {object} props - Component props
 * @param {React.ReactNode} props.children - The content to be wrapped.
 * @param {string} [props.className=""] - Additional CSS classes for styling.
 * @param {string} [props.backgroundImage=""] - URL for the background image.
 * @param {function} props.handleClose - Callback function for closing the wrapper.
 * @returns {React.ReactNode} - The wrapped content.
 */
export const OnboardingWrapper = ({
  children,
  className = "",
  backgroundImage = "",
  handleClose,
}: {
  children: React.ReactNode
  handleClose: () => void
  className?: string
  backgroundImage?: string
}): React.ReactNode => {
  const theme = useTheme()
  // Note: be careful when overriding the defaultClassName, some classes may conflict
  const defaultClassName = `fixed overflow-auto top-0 left-0 w-full h-screen bg-no-repeat bg-right-bottom bg-auto bg-black`
  className = className || defaultClassName
  return (
    <div className={`${className}`} style={{ backgroundImage: `url(${backgroundImage})`, zIndex: 1001 }}>
      {/* BUTTON CLOSE  */}
      <button className="absolute top-0 right-0 text-white mr-5 mt-5 z-1" onClick={handleClose}>
        <CloseIcon color={theme.neutralColor700} />
      </button>
      {children}
    </div>
  )
}
