import { formatCurrency } from "@modules/currency/currency"
import { CustomerHistory } from "@modules/finances/services/transactionHistoryDto"
import { HUText } from "@modules/ui/components/huText"
import { formatDateWithHyphen } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { DefaultTheme } from "styled-components"

export type TransactionsColumnsType = "date" | "name" | "amount"
export const historyTransactionsColumns: TransactionsColumnsType[] = ["date", "name", "amount"]

export const headerColumn = () => undefined

export const valueColumn = (
  columnType: TransactionsColumnsType,
  transaction: CustomerHistory,
  theme: DefaultTheme,
  language: string,
) => {
  switch (columnType) {
    case "date":
      return (
        <HUText fontStyle="TS" style={{ whiteSpace: "nowrap" }}>
          {formatDateWithHyphen(dayjs(transaction.date).format("L"))}
        </HUText>
      )
    case "name":
      return transaction.name ? <HUText fontStyle="BM">{`${transaction.name}`}</HUText> : <span />
    case "amount":
      return (
        <HUText fontStyle="BM" className="pl-1">{`${
          transaction.amount ? formatCurrency(transaction.amount, transaction.currency, language) : "--"
        }`}</HUText>
      )
  }
}
