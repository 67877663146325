import { ItemBox } from "@modules/box/boxTypes"
import { ExploreFilter } from "@modules/explore/exploreTypes"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { Profile } from "@modules/profile/profileTypes"
import { ItemProgram } from "@modules/programs/programTypes"

import { HUText } from "@modules/ui/components/huText"
import { capitalizeCityName } from "@modules/utils/stringUtils"
import { countries, getEmojiFlag, TCountryCode } from "countries-list"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

type ExploreCardFooterAvatarProps = {
  item: ItemProgram | ItemBox | Profile
}

export const ExploreCardFooterAvatar: React.FC<ExploreCardFooterAvatarProps> = ({ item }) => {
  const theme = useTheme()

  const itemAvatar = useMemo(() => {
    switch (item._type) {
      case ExploreFilter.PROGRAM:
        return { avatar: item.author.avatar, username: item.author.firstname || "" }
      case ExploreFilter.BOX:
        return { avatar: item.logo, username: item.name || "" }
      case ExploreFilter.PROFILE:
        return { avatar: item.avatar, username: item.username || "" }
    }
  }, [item])

  const country = useMemo(() => {
    if ("address" in item) {
      const foundCountry = Object.entries(countries).find(
        ([key, value]) =>
          key === item.address?.country ||
          value.name.toLowerCase() === item.address?.country.toLowerCase() ||
          value.native.toLowerCase() === item.address?.country.toLowerCase(),
      )
      if (foundCountry)
        return {
          code: foundCountry[0],
          ...foundCountry[1],
        }
    }
  }, [item])

  return (
    <>
      {"username" in item ? (
        <ContainerAvatarProfile className="flex align-items-center flex-initial">
          <ProfileAvatar
            className="mr-2 "
            profile={{ avatar: itemAvatar?.avatar, username: itemAvatar?.username || "" }}
            size={43}
          />

          <div className="flex flex-column">
            <HUText fontStyle="LL" className="mb-1" color={theme.neutralColor900}>
              {`${item.firstname} ${item.lastname}`}
            </HUText>
            <HUText fontStyle="BS" className="mb-1" color={theme.neutralColor600}>
              {`@${item.username}`}
            </HUText>
          </div>
        </ContainerAvatarProfile>
      ) : (
        <ContainerAvatar className="flex align-items-center flex-initial">
          <ProfileAvatar
            className="mr-2 "
            profile={{ avatar: itemAvatar?.avatar, username: itemAvatar?.username || "" }}
            size={43}
          />
          {item._type !== ExploreFilter.BOX && "author" in item && (
            <div className="flex flex-column">
              <HUText
                fontStyle="LM"
                className="mb-1"
                color={theme.mainWhite}
              >{`${item.author.firstname} ${item.author.lastname}`}</HUText>
            </div>
          )}
          {"address" in item && (
            <div className="flex flex-column">
              <HUText fontStyle="LM" className="mb-1" color={theme.mainWhite}>
                {capitalizeCityName(item.address.city)}
              </HUText>
              <HUText fontStyle="BS" className="mb-1" color={theme.mainWhite}>
                {country
                  ? getEmojiFlag(country.code.toUpperCase() as TCountryCode) + " " + country.name
                  : item.address.country}
              </HUText>
            </div>
          )}
        </ContainerAvatar>
      )}
    </>
  )
}

const ContainerAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 27px;
  padding: 5px 18px 5px 5px;
  background-color: rgba(28, 28, 28, 0.8);
  margin-top: 5px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 200;
`
const ContainerAvatarProfile = styled.div`
  display: flex;
  align-items: center;
  border-radius: 27px;
  padding: 5px 18px 5px 5px;
  background-color: transparent;
  margin-top: 5px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 200;
`
