import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BoxProgrammationVisibilityPrivateIcon: React.FC<IconSvgProps> = ({ color = "#D1D1D1", ...props }) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="4.50012" y="7.52344" width="16" height="14" rx="4" stroke={color} strokeWidth="1.5" />
    <circle cx="12.5001" cy="14.5234" r="2" stroke={color} strokeWidth="1.5" />
    <path
      d="M16.5001 7.52344C16.5001 5.3143 14.7093 3.52344 12.5001 3.52344C10.291 3.52344 8.50012 5.3143 8.50012 7.52344"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
)
