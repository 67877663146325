import { word } from "@modules/core/utils/i18n"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import {
  headerColumn,
  memberBoxOffersColumns,
  propsColumn,
  valueColumn,
} from "@modules/members/components/memberBoxOffersColumns"
import { MemberPurchaseInfos } from "@modules/members/memberUtils"
import { useAllMemberPurchases } from "@modules/members/useMember"
import { DataTable, renderTableColumns } from "@modules/ui/components/dataTables/datatable"
import { ListHeader } from "@modules/ui/components/header"
import styled, { DefaultTheme, useTheme } from "styled-components"

type Props = {
  id: string
}

export const MemberBoxOffersView: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const language = useLanguage()
  const { result: memberPurchases, loading } = useAllMemberPurchases(id)
  const memberPurchaseInfos: MemberPurchaseInfos[] = memberPurchases.map((memberPurchase) => {
    return {
      name: memberPurchase.offer.name,
      date: memberPurchase.date,
      price: memberPurchase.price.value,
      currency: memberPurchase.price.currency,
      status: memberPurchase.status,
      count: memberPurchase.count,
      initialCount: memberPurchase.initialCount,
    }
  })

  return (
    <div className="card flex flex-column" style={{ gap: 20 }}>
      <ListHeader title={word("member.form.title.memberships")} />
      <DataTable
        value={memberPurchaseInfos}
        dataKey="id"
        paginator
        rows={10}
        currentPageReportTemplate={""}
        emptyMessage={word("global.list.empty.message")}
        loading={loading}
      >
        {renderTableColumns(
          [...memberBoxOffersColumns],
          headerColumn,
          valueColumn,
          theme as DefaultTheme,
          language,
          propsColumn,
        )}
      </DataTable>
    </div>
  )
}

export const StatusCircle = styled.div`
  height: 11px;
  width: 11px;
  border-radius: 11px;
`
