export type CamembertIconProps = {
  color1: string
  color2: string
  color3: string
  strokeColor1: string
  strokeColor2: string
  strokeColor3: string
}

/**
 * A React functional component that renders a CamembertIcon.
 * @param {CamembertIconProps} props - The props for the CamembertIcon component.
 * @param {string} props.color1 - The color of the middle part of the camembert.
 * @param {string} props.color2 - The color of the left part of the camembert.
 * @param {string} props.color3 - The color of the right part of the camembert.
 * @param {string} props.strokeColor1 - The stroke color of the middle part of the camembert.
 * @param {string} props.strokeColor2 - The stroke color of the left part of the camembert.
 * @param {string} props.strokeColor3 - The stroke color of the right part of the camembert.
 * @param {React.HTMLAttributes<SVGElement>} props - The props for the SVG element.
 * @returns {React.JSX.Element} - The rendered CamembertIcon component.
 */
export const CamembertIcon: React.FC<CamembertIconProps> = ({
  color1,
  color2,
  color3,
  strokeColor1,
  strokeColor2,
  strokeColor3,
  ...props
}) => (
  <svg width="83" height="42" viewBox="0 0 83 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M45.6807 34.7751C44.5139 36.7074 41.7114 36.7074 40.5446 34.7752L25.3542 9.62013C24.4826 8.17691 24.9802 6.38988 26.3787 5.72457C31.4483 3.31267 37.1214 1.9624 43.1126 1.9624C49.1038 1.9624 54.7769 3.31268 59.8465 5.72457C61.245 6.38989 61.7425 8.17691 60.871 9.62012L45.6807 34.7751Z"
      fill={color1}
      stroke={strokeColor1}
      strokeWidth="2"
    />
    <path
      d="M33.7391 39.9712C36.1017 39.9712 37.5372 37.367 36.2757 35.3694L22.0672 12.8699C21.1354 11.3944 19.1812 11.0381 17.8736 12.0756C13.8542 15.2649 10.5741 18.7159 8.1425 23.9631C5.72758 29.1744 4.96649 33.0216 4.86249 37.172C4.82425 38.698 6.11223 39.9712 7.74236 39.9712L33.7391 39.9712Z"
      fill={color2}
      stroke={strokeColor2}
      strokeWidth="2"
    />
    <path
      d="M52.486 39.9712C50.1234 39.9712 48.6879 37.367 49.9494 35.3694L64.1579 12.8699C65.0897 11.3944 67.0439 11.0381 68.3515 12.0756C72.3709 15.2649 75.651 18.7159 78.0826 23.9631C80.4975 29.1744 81.2586 33.0216 81.3626 37.172C81.4009 38.698 80.1129 39.9712 78.4827 39.9712L52.486 39.9712Z"
      fill={color3}
      stroke={strokeColor3}
      strokeWidth="2"
    />
  </svg>
)
