import { WebService } from "@modules/core/services/webService"
import { PromoCodeApi } from "@modules/promoCode/services/promoCodeApi"
import { PromoCode, PromoCodeDraft } from "@modules/promoCode/types/promoCodeTypes"

export class PromoCodeService implements WebService {
  constructor(private api: PromoCodeApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  getPromoCodes(expired: boolean) {
    return this.api.getPromoCodes(expired)
  }

  async createPromoCode(promoCodeDraft: PromoCodeDraft): Promise<PromoCode> {
    return await this.api.createPromoCode(promoCodeDraft)
  }

  async updatePromoCode(id: string, promoCodeDraft: PromoCodeDraft) {
    await this.api.updatePromoCode(id, promoCodeDraft)
  }

  async deletePromoCode(id: string) {
    await this.api.deletePromoCode(id)
  }
}
