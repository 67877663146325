import { CheckmarkIcon } from "@images/svgIcons/checkmarkIcon"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getCurrencySymbol } from "@modules/currency/currency"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import {
  ProgramPriceFormikType,
  convertProgramToPriceFormik,
  getProgramPriceValidationSchema,
} from "@modules/programs/programFormikType"
import { ProgramInfinite, ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import styled, { useTheme } from "styled-components"

type ProgramPriceFormProps = {
  program: ProgramInfinite | ProgramOnOff
}

export const ProgramPriceForm: React.FC<ProgramPriceFormProps> = ({ program }) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const theme = useTheme()
  const language = useLanguage()
  const toast = useToast()
  const { programInfiniteService, programOnOffService } = useServicesContext()
  const { sidebarNavigateBack } = useSidebar()

  const programPriceFormik = useFormik<ProgramPriceFormikType>({
    initialValues: convertProgramToPriceFormik(program),
    validationSchema: getProgramPriceValidationSchema(),
    onSubmit: async () => {
      try {
        setIsUpdating(true)
        if (program && program._programType === ProgramInfiniteTypeOf) {
          await programInfiniteService.updateProgramInfinitePrice(
            program.id,
            {
              value: programPriceFormik.values.value ?? 0,
              currency: programPriceFormik.values.currency,
            },
            programPriceFormik.values.trialDuration,
          )
          toast.show({ severity: "success", detail: word("program.switchPrice.success") })
          sidebarNavigateBack()
        }
        if (program && program._programType === ProgramOnOffTypeOf) {
          await programOnOffService.updateProgramOnOffPrice(program.id, {
            value: programPriceFormik.values.value ?? 0,
            currency: programPriceFormik.values.currency,
          })
          toast.show({ severity: "success", detail: word("program.switchPrice.success") })
          sidebarNavigateBack()
        }
      } catch (e: any) {
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      } finally {
        setIsUpdating(false)
      }
    },
  })

  useEffect(() => {
    if (!programPriceFormik.values.addFreeTrial) programPriceFormik.setFieldValue("trialDuration", null)
  }, [programPriceFormik.values.addFreeTrial])

  return (
    <Container gap={20}>
      <HUText fontStyle="TL">
        {program._programType === ProgramInfiniteTypeOf
          ? word("program.price.membershipPrice")
          : word("program.onOff.price")}
      </HUText>
      <HUText fontStyle="BS">
        {program._programType === ProgramInfiniteTypeOf
          ? word("program.price.membershipPriceDescription")
          : word("program.onOff.priceDescription")}
      </HUText>

      <FormikInput
        label={word("program.price.label")}
        isRequiredInput
        isInvalid={isFormFieldInValid("value", programPriceFormik)}
        getFieldProps={programPriceFormik.getFieldProps}
        name="value"
        error={getFormErrorMessage("value", programPriceFormik)}
        type="number"
        numberProps={{
          suffix: getCurrencySymbol(programPriceFormik.values.currency, language),
          step: 0.1,
          min: 0,
          maxFractionDigits: 2,
          minFractionDigits: 2,
          placeholder: word("program.price.add"),
        }}
        setFieldValue={programPriceFormik.setFieldValue}
        rightButton={
          program._programType === ProgramInfiniteTypeOf && (
            <HUText fontStyle="TM" className="ml-1">
              / {word("global.month.label", { count: 1 })}
            </HUText>
          )
        }
      />
      {program._programType === ProgramInfiniteTypeOf && (
        <HUStack gap={4}>
          <HUText
            type="label"
            color={theme.neutralColor700}
            fontStyle="LS"
            style={{ lineHeight: "21px", marginBottom: 0 }}
          >
            {word("program.price.trial.label").toUpperCase()}
          </HUText>
          <HUStack gap={14} className="card align-items-center mt-0 w-full trial-container">
            <FormikInput
              type="switchInline"
              textSwitch={word("program.price.trial.inputLabel")}
              name="addFreeTrial"
              getFieldProps={programPriceFormik.getFieldProps}
              isInvalid={isFormFieldInValid("addFreeTrial", programPriceFormik)}
              error={getFormErrorMessage("addFreeTrial", programPriceFormik)}
              setFieldValue={programPriceFormik.setFieldValue}
            />

            <FormikInput
              type="number"
              name="trialDuration"
              getFieldProps={programPriceFormik.getFieldProps}
              isInvalid={isFormFieldInValid("trialDuration", programPriceFormik)}
              error={getFormErrorMessage("trialDuration", programPriceFormik)}
              setFieldValue={programPriceFormik.setFieldValue}
              disabled={!programPriceFormik.values.addFreeTrial}
              numberProps={{
                step: 1,
                min: 0,
                max: 365,
              }}
              rightButton={
                program._programType === ProgramInfiniteTypeOf && (
                  <HUText fontStyle="TM" className="ml-1">
                    {word("global.day.label", { count: 2 })}
                  </HUText>
                )
              }
            />
          </HUStack>
        </HUStack>
      )}
      <HUStack className="card align-items-center">
        <Check
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "15px",
          }}
        >
          <CheckmarkIcon
            color={theme.textPrimary}
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </Check>
        <HUText fontStyle="LL">{word("program.price.securedTransaction.title")}</HUText>
        <HUText fontStyle="BS" textProps={{ style: { textAlign: "center" } }}>
          {word("program.price.securedTransaction.description")}
        </HUText>
      </HUStack>
      <HUButton
        className="w-full"
        type="Default"
        colorType="Primary"
        size="M"
        text={program.price ? word("global.update") : word("global.create")}
        onClick={programPriceFormik.submitForm}
        loading={isUpdating}
      />
    </Container>
  )
}

const Container = styled(HUStack)`
  .field {
    flex-grow: 1;
    width: 100%;
  }
  .p-inputnumber {
    flex-grow: 1;
  }
  .trial-container {
    .field {
      margin-bottom: 0px;
    }
    .p-inputswitch {
      margin-bottom: 0px;
    }
    > div > div > div {
      padding: 0 !important;
    }
  }
`

const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.mainWhite};
`
