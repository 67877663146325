import { BoxProgrammationSession, BoxProgrammationSessionDraft } from "@modules/boxSlot/services/boxSlotDto"
import { word } from "@modules/core/utils/i18n"
import { Profile } from "@modules/profile/profileTypes"
import dayjs from "dayjs"

export const BoxSlotTypeOf = "SLOT"

export type ItemBoxSlot = {
  id: string
  _type: typeof BoxSlotTypeOf
  color: string
  programmationId: string
  programmationName: string
  startDateTime: string
  endDateTime: string
  registeredParticipantCount: number
  pendingParticipantCount: number
  participantLimit: number
  sessionId?: string
  location?: string
  participation?: BoxSlotParticipation
}

export type BoxSlot = ItemBoxSlot & {
  owner?: Profile
  contentEarlyAccess: boolean
}

export type BoxSlotSession = BoxSlot & {
  session: BoxProgrammationSession | null
}

export type BoxSlotDraft = {
  id?: string
  programmationId: string
  startDateTime: string | null
  endDateTime: string
  participantLimit: number
  contentEarlyAccess: boolean
  location?: string
  participation?: BoxSlotParticipation
}

export const BOX_SLOT_PARTICIPANTS_LIMIT_MIN = 1
export const BOX_SLOT_PARTICIPANTS_LIMIT_MAX = 9999
export const BOX_SLOT_PARTICIPANTS_DEFAULT_VALUE = 10

type SlotFormMode = "SlotWithoutSessionEdition" | "SlotWithSessionEdition" | "SlotCreation"

export type SlotFormLocationState = {
  date?: string
  endDate?: string
  programmationId?: string
  slotFormMode: SlotFormMode
}

export function convertBoxSessionToDraft(
  session: BoxProgrammationSession | undefined | null,
): BoxProgrammationSessionDraft {
  return {
    name: session?.name ?? word("program.session.name.default"),
    description: session?.description ?? "",
    exercises: session?.exercises ?? [],
    programmationId: session?.programmationId ?? "",
    gymnasticTag: session?.gymnasticTag ?? false,
    weightliftingTag: session?.weightliftingTag ?? false,
    cardioTag: session?.cardioTag ?? false,
    hideSessionContent: session?.hideSessionContent ?? false,
    date: session?.date ? dayjs(session.date).toDate() : dayjs().startOf("day").toDate(),
  }
}

export type BoxSlotParticipation = {
  limitStart?: number
  limitEnd?: number
  limitCancel?: number
}
