import { getAuthError } from "@modules/auth/authError"
import {
  AuthContent,
  AuthForm,
  AuthFormError,
  AuthHeader,
  AuthSubmitButton,
} from "@modules/auth/components/authComponents"
import { LoginBack } from "@modules/auth/components/loginBack"
import { AuthPage } from "@modules/auth/page/authPage"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { HUInput } from "@modules/ui/components/huInput"
import { LoggerType } from "@modules/utils/loggerUtils"
import { isValidEmail } from "@modules/utils/valid"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const SendCodePage: React.FC = () => {
  const { userService } = useServicesContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const navigate = useNavigate()
  const location = useLocation() as { state?: { email?: string } }
  const [email, setEmail] = useState(location.state?.email ?? "")

  const sendForgotPasswordConfirmationCode = async () => {
    try {
      setLoading(true)
      if (!isValidEmail(email)) {
        setError(word("auth.signup.form.mail_invalid"))
      } else {
        await userService.sendForgotPasswordConfirmationCode(email)
        navigate({ pathname: Path.ResetPassword }, { state: { email, isRequiredPassword: false } })
      }
    } catch (e) {
      console.error(LoggerType.Login + "send confimation code password", e)
      setError(getAuthError(e, { onlyEmail: true }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setError("")
  }, [email])

  return (
    <AuthPage>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault()
          sendForgotPasswordConfirmationCode()
        }}
      >
        <AuthHeader title={word("auth.signup.form.reset_password")} subtitle={word("auth.send_code.form.subtitle")} />
        <AuthContent>
          <HUInput
            type="text"
            placeholder={word("auth.form.placeholder.email")}
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            hasError={!!error}
          />

          <AuthSubmitButton loading={loading} disabled={!email}>
            {word("global.check")}
          </AuthSubmitButton>
          <AuthFormError>{error}</AuthFormError>
          <LoginBack emailState={email} />
        </AuthContent>
      </AuthForm>
    </AuthPage>
  )
}
