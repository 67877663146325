import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { TransactionStatus } from "@modules/finances/types/financesType"
import { BoxItemTransaction, UserItemTransaction } from "@modules/finances/types/transactionType"
import { UserCell } from "@modules/members/components/userCell"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { colors } from "@modules/ui/styles/colors"
import { formatDateWithHyphen } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { Tooltip } from "primereact/tooltip"
import styled, { DefaultTheme } from "styled-components"

export type TransactionsColumnsType = "date" | "name" | "email" | "product" | "price" | "fee" | "status"
export const boxTransactionsColumns: TransactionsColumnsType[] = ["date", "name", "product", "price", "fee"]
export const userTransactionsColumns: TransactionsColumnsType[] = ["date", "name", "product", "price", "fee"]

export const headerColumn = (key: TransactionsColumnsType) => {
  switch (key) {
    case "date":
      return word("finances.page.table.header.date")
    case "name":
      return word("finances.page.table.header.transactions")
    case "product":
      return word("finances.page.table.header.product")
    case "email":
      return word("finances.page.table.header.email")
    case "price":
      return word("finances.page.table.header.price")
    case "fee":
      return (
        <HUText
          className="tooltip flex flex-row align-items-center cursor-pointer"
          data-pr-showdelay={200}
          data-pr-hidedelay={200}
          data-pr-position="bottom"
          color="white"
          fontStyle="TS"
        >
          <Tooltip className="tooltip-container" target=".tooltip">
            <HUText fontStyle="BL" className="tooltip-title flex flex-row align-items-center">
              <TooltipIndicator className="mr-2 black">?</TooltipIndicator>
              {word("finances.page.table.header.fee")}
            </HUText>
            <div className="tooltip-content text-justify">
              <HUText type="label" fontStyle="BM">
                {word("finances.page.table.header.feeTooltip")}
              </HUText>
            </div>
          </Tooltip>
          {word("finances.page.table.header.fee")}
          <TooltipIndicator className="ml-2">?</TooltipIndicator>
        </HUText>
      )
    case "status":
      return word("finances.page.table.header.status")
  }
}

const getProductValue = (transaction: BoxItemTransaction | UserItemTransaction) => {
  if ("program" in transaction && transaction.program) {
    return <HUText fontStyle="BM">{`${transaction.program.name}`}</HUText>
  }
  if ("offer" in transaction && transaction.offer) {
    return <HUText fontStyle="BM">{`${transaction.offer.name}`}</HUText>
  } else {
    return (
      <HUText style={{ fontStyle: "italic" }} className="pl-1">
        {word("menu.coming_soon")}
      </HUText>
    )
  }
}

export const valueColumn = (
  columnType: TransactionsColumnsType,
  transaction: BoxItemTransaction | UserItemTransaction,
  theme: DefaultTheme,
  language: string,
) => {
  const user = getTransactionCustomer(transaction)
  switch (columnType) {
    case "date":
      return (
        <HUText fontStyle="TS" style={{ whiteSpace: "nowrap" }}>
          {formatDateWithHyphen(dayjs(transaction.date).format("L"))}
        </HUText>
      )
    case "name":
      return (
        <div>
          {user ? (
            <HUText
              className={`tooltip${transaction.id} flex flex-row align-items-center cursor-pointer`}
              data-pr-showdelay={200}
              data-pr-hidedelay={100}
              data-pr-position="bottom"
              color={theme.textPrimary}
              fontStyle="TS"
            >
              <Tooltip className="tooltip-container" target={`.tooltip${transaction.id}`}>
                <div className="tooltip-content text-justify">
                  <HUText type="label" fontStyle="BM">
                    {getTransactionStatus({ status: transaction.status })}
                  </HUText>
                </div>
              </Tooltip>

              <UserCell
                member={{
                  avatar: user.avatar,
                  username: user.firstname,
                  firstname: user.firstname,
                  lastname: user.lastname,
                }}
                borderColor={colorStatus[transaction.status]}
                subtitleColor={colorStatus[transaction.status]}
                subtitle={
                  transaction.status !== "SUCCEEDED" ? getTransactionStatus({ status: transaction.status }) : undefined
                }
              />
            </HUText>
          ) : (
            <span />
          )}
        </div>
      )
    case "email":
      return user ? <HUText fontStyle="BM">{`${user.email}`}</HUText> : <span />
    case "product":
      return getProductValue(transaction)
    case "price":
    case "fee":
      const value = columnType === "price" ? transaction.amount : transaction.fee
      return (
        <HUText fontStyle="BM" className="pl-1">{`${
          value ? formatCurrency(value, transaction.currency, language) : "--"
        }`}</HUText>
      )
    case "status":
      return transaction.disputed ? (
        <DisputedStatus align gap={7}>
          <CircleStatus $color={theme.error}></CircleStatus>
          <HUText fontStyle="LL" color={theme.error}>
            {getTransactionStatus({ status: "DISPUTED" })}
          </HUText>
        </DisputedStatus>
      ) : (
        <HURow align gap={7}>
          <CircleStatus $color={colorStatus[transaction.status]}></CircleStatus>
          <HUText fontStyle="LL">{getTransactionStatus({ status: transaction.status })}</HUText>
        </HURow>
      )
  }
}

export const getTransactionStatus = (param: { status: TransactionStatus | "ALL"; plural?: boolean }) => {
  const { status, plural } = param
  const wordStatus: { [key in TransactionStatus]: string } = {
    SUCCEEDED: word("finances.page.table.status.succeded", { count: plural ? 2 : 1 }),
    PENDING: word("finances.page.table.status.pending", { count: plural ? 2 : 1 }),
    FAILED: word("finances.page.table.status.failed", { count: plural ? 2 : 1 }),
    DISPUTED: word("finances.page.table.status.disputed", { count: plural ? 2 : 1 }),
  }

  return status === "ALL" ? word("finances.page.filter.all") : wordStatus[status]
}

export const getTransactionCustomer = (transaction: UserItemTransaction | BoxItemTransaction) => {
  if ("user" in transaction) return transaction.user
  else if ("member" in transaction) return transaction.member
}

const colorStatus: { [key in TransactionStatus]: string } = {
  SUCCEEDED: colors.green500,
  PENDING: colors.yellow500,
  FAILED: colors.red500,
  DISPUTED: colors.red500,
}

const CircleStatus = styled.div<{ $color: string }>`
  width: 11px;
  height: 11px;
  border-radius: 6px;
  background-color: ${({ $color }) => $color};
`

const DisputedStatus = styled(HURow)`
  background-color: ${({ theme }) => theme.error100};
  padding: 4px 10px;
  border-radius: 100px;
  width: max-content;
`

const TooltipIndicator = styled.div`
  border-width: 1.5px;
  border-style: solid;
  border-radius: 50%;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(.black) {
    border-color: ${({ theme }) => theme.neutralColor400};
    color: ${({ theme }) => theme.neutralColor400};
  }
`
