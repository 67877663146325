import styled from "styled-components"

type CirclesProps = {
  count: number
  filled: number
  progressionstyle: "line" | "circle"
  color?: any
}

export const Circles: React.FC<CirclesProps> = ({ count, filled, progressionstyle: progressionstyle, color }) => {
  const circles = []

  for (let i = 0; i < count; i++) {
    const angle = (i / count) * 360
    const position = polarToCartesian(40, 40, 40, angle)

    circles.push(
      <Circle
        key={i}
        $filled={filled >= i + 1 ? 0 : i + 1}
        $progressionstyle={progressionstyle}
        $position={position}
        $color={color}
      />,
    )
  }

  return <CircleContainer $progressionstyle={progressionstyle}>{circles}</CircleContainer>
}

const CircleContainer = styled.div<{ $progressionstyle: "line" | "circle" }>`
  display: ${({ $progressionstyle }) => ($progressionstyle === "line" ? "flex" : "block")};
  align-items: center;
  position: relative;
  width: ${({ $progressionstyle }) => ($progressionstyle === "line" ? "260px" : "100px")};
  height: ${({ $progressionstyle }) => ($progressionstyle === "line" ? "100%" : "100px")};
`

const Circle = styled.div<{
  $filled: number
  $progressionstyle: "line" | "circle"
  $position: { x: number; y: number }
  $color: any
}>`
  width: 16px;
  height: 16px;
  border: ${({ $color }) => `3px solid ${$color || "black"}`};
  border-radius: 50%;
  margin: ${({ $progressionstyle }) => ($progressionstyle === "line" ? "0 5px" : "0px")};
  background-color: ${({ $filled, $color }) => ($filled === 0 ? $color || "black" : "transparent")};
  position: ${({ $progressionstyle }) => ($progressionstyle === "circle" ? "absolute" : "static")};
  left: ${({ $progressionstyle, $position }) => ($progressionstyle === "circle" ? `${$position.x}%` : "auto")};
  top: ${({ $progressionstyle, $position }) => ($progressionstyle === "circle" ? `${$position.y}%` : "auto")};
`

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}
