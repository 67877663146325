import { BoxPlanningCalendarEvent } from "@modules/boxPlanning/components/boxPlanningCalendarEvent"
import { useBoxPlanningSlotEvents } from "@modules/boxPlanning/hooks/useBoxPlanningSessionEvent"
import { CalendarEventTypeWithHour } from "@modules/calendar/calendarTypes"
import { HUText } from "@modules/ui/components/huText"
import { weekdayDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { A11y, EffectCoverflow, Keyboard, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

export const BoxSlotListSlider: React.FC<{
  setActiveSlotId: (arg: string) => void
  activeSlotId: string
  initialBoxSlotId: string
}> = ({ setActiveSlotId, activeSlotId, initialBoxSlotId }) => {
  const { slots, slotEvents } = useBoxPlanningSlotEvents()
  const { boxProgrammationId } = useParams()

  const filteredSlots = useMemo(() => {
    if (boxProgrammationId) {
      return slotEvents.filter((slot) => slot.programmationId === boxProgrammationId)
    } else return slotEvents
  }, [slotEvents, boxProgrammationId])

  const getIndex = (boxSlotId: string) => {
    return filteredSlots.findIndex((slot) => slot.id === boxSlotId)
  }

  const getIdbyIndex = (index: number) => {
    return filteredSlots[index] ? filteredSlots[index].id : null
  }

  const onActiveIndexChange = (activeIndex: number) => {
    const id = getIdbyIndex(activeIndex)
    if (id && id !== activeSlotId) setActiveSlotId(id)
  }

  const relatedSlot = useCallback(
    (eventId: string) => {
      return slots.find((slot) => slot.id === eventId)
    },
    [slots],
  )

  return (
    <div className="mb-3">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={false}
        slidesPerView={3}
        shortSwipes={true}
        slideToClickedSlide={true}
        onInit={(swiperCore) => {
          swiperCore.slideTo(getIndex(initialBoxSlotId))
        }}
        onActiveIndexChange={(swiperCore) => {
          onActiveIndexChange(swiperCore.activeIndex)
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        navigation={{
          nextEl: ".custom-next-button",
          prevEl: ".custom-prev-button",
          enabled: true,
        }}
        keyboard={{
          enabled: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, A11y, Keyboard]}
      >
        {filteredSlots.map((slot) => (
          <SwiperSlide key={slot.id}>
            <HUText fontStyle="BXS" className="ml-5 capitalize">
              {activeSlotId === slot.id && dayjs(slot.date).format(weekdayDateFormat)}
            </HUText>
            <BoxPlanningCalendarEvent
              event={slot as CalendarEventTypeWithHour}
              slot={relatedSlot(slot.id)}
              key={slot.id}
              canBeClicked={false}
              isOverlayed={true}
              relativePosition={true}
            />
          </SwiperSlide>
        ))}
        <div className="flex w-full h-full arrow-container">
          <div className="arrow left-arrow custom-prev-button pi pi-chevron-left"></div>
          <div className="arrow right-arrow custom-next-button pi pi-chevron-right"></div>
        </div>
      </Swiper>
    </div>
  )
}
