const regexYoutubeLink =
  /^((?:https?:)?\/\/)?((?:www)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/|shorts\/)?)([\w-]+)(\S+)?$/gm

export function isYoutubeLink(url: string) {
  return !!url.match(regexYoutubeLink)
}

export function convertYoutubeLinkToString(url: string) {
  return isYoutubeLink(url) ? "youtube" : url
}

export function convertYoutubeLinkToEmbed(url: string) {
  const match = isYoutubeLink(url) // required to use exec
  const matches = regexYoutubeLink.exec(url)
  if (match && matches) {
    const videoId = matches[5]
    return `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&autoplay=0&controls=0`
  }
  return url
}
