import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { getBoxProgrammationColorTypeWithHex } from "@modules/boxProgrammation/boxProgrammationUtils"
import { boxProgrammationColors } from "@modules/boxProgrammation/components/boxProgrammationColors"
import { useBoxProgrammation } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import {
  BoxSlotsTemplateWeekPartial,
  BoxSlotTemplate,
  BoxSlotTemplateDraft,
  ItemBoxSlotTemplate,
  Weekday,
} from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import { initializeDateUtils } from "@modules/boxSlotTemplate/boxSlotUtils"
import { EventType, SlotTemplateEventType } from "@modules/calendar/calendarTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { useAsync } from "@modules/hooks/useAsync"
import { useAsyncObservable } from "@modules/hooks/useAsyncObservable"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import dayjs from "dayjs"
import { useObservable } from "micro-observables"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { DefaultTheme, useTheme } from "styled-components"
import { v4 as uuidv4 } from "uuid"

export const useBoxSlotTemplatesEvents = () => {
  const language = useLanguage()
  const theme = useTheme()
  const { boxSlotTemplateService } = useServicesContext()
  const {
    result: programBoxSlotTemplateWeek,
    loading: loadingBoxSlotTemplates,
    error,
  } = useAsyncObservable(boxSlotTemplateService.boxSlotTemplateWeekStore)
  const boxSlotTemplatesEvents: EventType[] = useMemo(
    () =>
      programBoxSlotTemplateWeek
        ? convertBoxSlotTemplatesWeekToEvent(programBoxSlotTemplateWeek, theme as DefaultTheme)
        : [],
    [language, programBoxSlotTemplateWeek],
  )

  return {
    boxSlotTemplatesEvents,
    loading: loadingBoxSlotTemplates,
    error,
  }
}

export const useBoxSlotTemplate = (boxProgrammation: BoxProgrammation | null, id?: string) => {
  const { boxSlotTemplateService } = useServicesContext()
  return useAsync(() => {
    if (id && boxProgrammation) {
      return boxSlotTemplateService.getBoxSlotTemplate(boxProgrammation.id, id)
    } else {
      return new Promise<null>(() => null)
    }
  }, [boxProgrammation, id])
}

const convertBoxSlotTemplatesWeekToEvent = (boxSlotTemplateWeek: BoxSlotsTemplateWeekPartial, theme: DefaultTheme) => {
  const week = boxSlotTemplateWeek.week
  let eventInputTotalList: SlotTemplateEventType[] = []
  for (const key in week) {
    const weekList: ItemBoxSlotTemplate[] = week[key as keyof typeof week]
    eventInputTotalList = [
      ...eventInputTotalList,
      ...convertBoxSlotTemplateListToEventList(key as Weekday, weekList, theme),
    ]
  }
  return eventInputTotalList
}

const convertBoxSlotTemplateListToEventList = (
  weekDay: Weekday,
  boxSlotTemplates: ItemBoxSlotTemplate[],
  theme: DefaultTheme,
): SlotTemplateEventType[] => {
  return boxSlotTemplates.map((boxSlotTemplate) => convertBoxSlotTemplateToEvent(boxSlotTemplate, theme, weekDay))
}

export const convertBoxSlotTemplateToEvent = (
  boxSlotTemplate: BoxSlotTemplate | ItemBoxSlotTemplate,
  theme: DefaultTheme,
  weekDay: Weekday,
): SlotTemplateEventType => {
  const colorType = getBoxProgrammationColorTypeWithHex(boxSlotTemplate.program.color)
  const { getDateTimeByAddingWeekday } = initializeDateUtils()

  const startDateTime = getDateTimeByAddingWeekday(boxSlotTemplate.startTime, weekDay ?? "MONDAY")
  const endDateTime = getDateTimeByAddingWeekday(boxSlotTemplate.endTime, weekDay ?? "MONDAY")
  return {
    id: boxSlotTemplate.id,
    title: boxSlotTemplate.program.name,
    programmationId: boxSlotTemplate.program.id,
    dayIndex: dayjs(startDateTime).weekday(),
    startDate: startDateTime.toString(),
    endDate: endDateTime.toString(),
    editable: false,
    color: theme.background,
    borderColor: theme.neutralColor200,
    borderStyle: "dashed",
    leftBorderColor: boxProgrammationColors[colorType].background,
    textColor: theme.neutralColor900,
  }
}

export const useBoxSlotTemplatesBeingCreated = (): SlotTemplateEventType[] => {
  const { boxSlotTemplateService } = useServicesContext()
  const { boxProgrammationId } = useParams()
  const theme = useTheme()
  const { result: boxProgrammation } = useBoxProgrammation(boxProgrammationId)

  const boxSlotTemplatesBeingCreated = useObservable(boxSlotTemplateService.boxSlotTemplatesBeingCreated)

  return boxProgrammation
    ? convertBoxSlotTemplateDraftToEventList(boxSlotTemplatesBeingCreated, boxProgrammation, theme as DefaultTheme)
    : []
}

const convertBoxSlotTemplateDraftToEventList = (
  slotTemplatesBeingCreated: BoxSlotTemplateDraft[],
  boxProgrammation: BoxProgrammation,
  theme: DefaultTheme,
): SlotTemplateEventType[] => {
  const colorType = getBoxProgrammationColorTypeWithHex(boxProgrammation.color)
  const { getDateTimeByAddingWeekday } = initializeDateUtils()

  return slotTemplatesBeingCreated.map((boxSlotTemplate) => {
    const startDateTime = getDateTimeByAddingWeekday(boxSlotTemplate.startTime, boxSlotTemplate.weekDay as Weekday)
    const endDateTime = getDateTimeByAddingWeekday(boxSlotTemplate.endTime, boxSlotTemplate.weekDay as Weekday)
    return {
      id: boxSlotTemplate.id ?? uuidv4(),
      title: boxProgrammation.name,
      editable: false,
      programmationId: boxProgrammation.id,
      color: theme.background,
      borderColor: boxProgrammationColors[colorType].background,
      borderStyle: "dashed",
      leftBorderColor: boxProgrammationColors[colorType].background,
      textColor: theme.neutralColor900,
      startDate: startDateTime.toString(),
      endDate: endDateTime.toString(),
      dayIndex: dayjs(startDateTime).weekday(),
    }
  })
}
