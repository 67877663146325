import { BulletPointIcon } from "@images/svgIcons/bulletPointIcon"
import { ChronoIcon } from "@images/svgIcons/chronoIcon"
import { CopyIcon } from "@images/svgIcons/copyIcon"
import { TrashIcon } from "@images/svgIcons/trashIcon"
import { BoxSlotSessionFormikType } from "@modules/boxSlot/boxSessionFormikTypes"
import { ChronoType } from "@modules/chrono/chronoTypes"
import { convertChronoDraftIntoChrono, displayTotalTime, getTrainingChronoTitle } from "@modules/chrono/chronoUtils"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { RpeTag } from "@modules/exercises/components/rpeTag"
import { convertChronoFormikToChronoDraft, ExerciseFormikType } from "@modules/exercises/exerciseFormikTypes"
import { getDisplayExerciseCategory } from "@modules/exercises/exerciseUtils"
import {
  ProgramInfiniteSessionFormikType,
  ProgramOnOffSessionFormikType,
} from "@modules/programSession/programSessionFormikType"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { getScoreTypesArray } from "@modules/scoreboard/scoreUtils"
import { EllipsisMenu } from "@modules/ui/components/ellipsisMenu"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { EditIcon } from "@modules/ui/icons/editIcon"
import { SimpleFormik } from "@src/typings/formik"
import { PrimeIcons } from "primereact/api"
import { MenuItem } from "primereact/menuitem"
import { useCallback, useMemo } from "react"
import styled, { useTheme } from "styled-components"

type exerciseExistingBlocProps = {
  exercise: ExerciseFormikType
  exerciseIndex: number
  onUpdateExercise: () => void
  formik: SimpleFormik<ProgramInfiniteSessionFormikType | ProgramOnOffSessionFormikType | BoxSlotSessionFormikType, any>
}

export const ExerciseExistingBloc: React.FC<exerciseExistingBlocProps> = ({
  exercise,
  exerciseIndex,
  onUpdateExercise,
  formik,
}) => {
  const theme = useTheme()
  const toast = useToast()
  const { sessionService } = useServicesContext()

  const scoreTypes = useMemo(() => getScoreTypesArray(), [])

  const handleDeleteExercise = useCallback(
    (exerciseDraft: ExerciseFormikType) => {
      const exercises = [...formik.values.exercises.filter((exerciseItem) => exerciseItem !== exerciseDraft)]
      formik.setFieldValue("exercises", exercises)
    },
    [formik],
  )

  const displayTime = useMemo(() => {
    const chrono = convertChronoDraftIntoChrono(exercise) || exercise.chrono

    if (exercise.chronoType && exercise.chronoType !== "NONE" && chrono) {
      const chronoDraft = convertChronoFormikToChronoDraft(exercise)
      return (
        getTrainingChronoTitle(exercise.chronoType as ChronoType) +
        " - " +
        (chrono && chronoDraft ? displayTotalTime(chronoDraft) : word("chrono.title.no_chrono"))
      )
    }
    if (chrono === undefined) {
      return word("chrono.title.no_chrono")
    }
    return chrono ? chrono.toString() : word("chrono.title.no_chrono")
  }, [exercise])

  const displayScoreType = useMemo(() => {
    const scoreTypeLabel = scoreTypes.find((scoreTypeLabel) => exercise.scoreType === scoreTypeLabel.value)
    return scoreTypeLabel?.label || ""
  }, [exercise])

  const moveExercise = (direction: "UP" | "DOWN") => {
    const directionShift = direction === "UP" ? -1 : 1
    if (exerciseIndex >= 0) {
      // if exercise index is find
      const newExerciseArray = [...formik.values.exercises] // copy the array
      const tmp = newExerciseArray[exerciseIndex] // save exercise at the index we ant to move
      const tmp2 = newExerciseArray[exerciseIndex + directionShift] //save exercise we shift
      newExerciseArray[exerciseIndex + directionShift] = tmp // save the exercise at his new position
      newExerciseArray[exerciseIndex] = tmp2 // save the shift exercise at his new position
      formik.setFieldValue("exercises", newExerciseArray, false).catch((e) => console.error("formik error", e)) //update formik 'exercises' value
    }
  }

  const ellipsisItems: MenuItem[] = [
    {
      items: [
        {
          label: word("program.exercise.move.up"),
          icon: PrimeIcons.ANGLE_DOUBLE_UP,
          disabled: exerciseIndex === 0 || formik.values.exercises.length === 1,
          command: () => moveExercise("UP"),
        },
        {
          label: word("program.exercise.move.down"),
          icon: PrimeIcons.ANGLE_DOUBLE_DOWN,
          disabled: exerciseIndex === formik.values.exercises.length - 1 || formik.values.exercises.length === 1,
          command: () => moveExercise("DOWN"),
        },
        {
          label: word("program.exercise.copy.label"),
          icon: <CopyIcon color={theme.neutralColor900} width={20} height={20} />,
          command: () => {
            try {
              sessionService.setCopyExercise(exercise)

              toast.show({
                severity: "success",
                summary: word("program.exercise.copy.success"),
              })
            } catch (e: any) {
              toast.show({
                severity: "error",
                summary: word("program.exercise.copy.error"),
              })
            }
          },
        },
        {
          label: word("sessionForm.exercise.remove_exo.button"),
          icon: <TrashIcon color={theme.neutralColor900} width={20} height={20} />,
          command: () => handleDeleteExercise(exercise),
        },
      ],
    },
  ]

  return (
    <>
      <GreyCardContainer>
        <HeaderCard>
          <BulletPointIcon color={theme.neutralColor900} />
          <HUText className="ml-2" fontStyle="TS">
            {getDisplayExerciseCategory(exercise.category)}
          </HUText>
          <div className="flex flex-1 justify-content-end gap-2">
            <HUButton
              type="Rounded"
              colorType="Quaternary"
              size="M"
              onClick={onUpdateExercise}
              aria-controls="popup_menu_right"
              aria-haspopup
              icon={{ icon: () => <EditIcon color={theme.neutralColor900} width={16} height={16} /> }}
              className="h-1rem w-1rem mr-2"
              style={{ backgroundColor: theme.sessionExerciseContentBgColor, borderColor: theme.neutralColor300 }}
            />
            <EllipsisMenu
              items={ellipsisItems}
              buttonClassName="h-1rem w-1rem mr-2"
              style={{ backgroundColor: theme.sessionExerciseContentBgColor, borderColor: theme.neutralColor300 }}
            />
          </div>
        </HeaderCard>
        <WhiteCardContainer>
          <div className="flex align-items-center mb-2">
            <ChronoIcon color={theme.buttonPrimaryTextColor} />
            <HUText className="ml-2" fontStyle="LM">
              {displayTime}
            </HUText>
          </div>
          {exercise.rpe !== null && exercise.rpe !== undefined && <RpeTag exercise={exercise} />}
          <DescriptionContainer fontStyle="BM">{exercise.description}</DescriptionContainer>
          {exercise.scoreType && exercise.scoreType !== ScoreType.NO_SCORE && (
            <div className="flex align-items-center mt-4 mb-2">
              <HUText fontStyle="LL">{word("program.session.score")}</HUText>
              <Separator />
              <HUText fontStyle="LL" className="mr-2">
                {displayScoreType}
              </HUText>
            </div>
          )}
        </WhiteCardContainer>
      </GreyCardContainer>
    </>
  )
}
const GreyCardContainer = styled.div`
  background-color: ${({ theme }) => theme.neutralColor200};
  display: flex;
  flex-direction: column;
  padding: 16px 7px 7px 7px;
  border-radius: 30px;
  margin-bottom: 20px;
`
const WhiteCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.sessionExerciseContentBgColor};
  display: flex;
  padding: 28px 22px 22px 22px;
  border-radius: 30px;
`

const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px 10px 16px;
`
const DescriptionContainer = styled(HUText)`
  white-space: pre-line;
`
const Separator = styled.div`
  display: flex;
  width: 115px;
  height: 1px;
  background-color: ${({ theme }) => theme.neutralColor200};
  margin-left: 15px;
  margin-right: 15px;
`
