import { LoggerType } from "@modules/utils/loggerUtils"
import { useEffect, useRef, useState } from "react"

type AsyncResult<V> = V & { loading: boolean; error: Error | null }
type RefreshableAsyncResult<V> = AsyncResult<V> & { refresh: () => void; refreshing: boolean }

export function useAsync<T>(asyncFn: () => Promise<T>, deps: unknown[]): RefreshableAsyncResult<{ value: T | null }> {
  const [result, setResult] = useState<AsyncResult<{ value: T | null }>>({ value: null, loading: true, error: null })
  const [refreshing, setRefreshing] = useState(false)
  const unmount = useRef(false)

  const update = (endCallback?: () => void) => {
    updateResult({ value: result.value, loading: true, error: null })

    ;(async () => {
      try {
        const value = await asyncFn()
        updateResult({ value, loading: false, error: null })
      } catch (e) {
        console.warn(LoggerType.Error + "[Async] error:", e)
        updateResult({ value: null, loading: false, error: e as Error })
      }
      endCallback?.()
    })()
  }

  const refresh = () => {
    updateRefreshing(true)
    update(() => {
      updateRefreshing(false)
    })
  }

  useEffect(() => {
    unmount.current = false
    return () => {
      unmount.current = true
    }
  }, [])

  const updateResult = (newResult: AsyncResult<{ value: T | null }>) => {
    if (!unmount.current) {
      setResult(newResult)
    }
  }

  const updateRefreshing = (isRefreshing: boolean) => {
    if (!unmount.current) {
      setRefreshing(isRefreshing)
    }
  }

  useEffect(() => {
    update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])

  return { ...result, refresh, refreshing }
}
