import React from "react"
import { IconSvgProps } from "@modules/ui/components/huButtonIcon"

export const ThreeIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="76" height="101" viewBox="0 0 76 101" fill="none" {...props}>
    <path
      d="M37.7333 100.561C30.0889 100.561 23.4222 99.1836 17.7333 96.428C12.0444 93.5836 7.64444 89.7613 4.53333 84.9613C1.51111 80.0725 0 74.5169 0 68.2947H24C24 71.7613 25.2 74.6058 27.6 76.828C30 78.9613 33.3778 80.028 37.7333 80.028C40.6667 80.028 43.1556 79.4947 45.2 78.428C47.2444 77.2725 48.8 75.7613 49.8667 73.8947C50.9333 71.9391 51.4667 69.7613 51.4667 67.3613C51.4667 63.7169 50.2667 60.6947 47.8667 58.2947C45.5556 55.8947 42.1778 54.6947 37.7333 54.6947C35.1556 54.6947 32.8444 55.0947 30.8 55.8947C28.8444 56.6947 27.1556 57.7169 25.7333 58.9613L14.6667 40.5613L41.6 24.028L40.4 21.8947H4.13333V0.56134H71.8667V21.7613L36.8 43.0947L25.6 38.9613C27.4667 38.428 29.4222 38.028 31.4667 37.7613C33.6 37.4947 35.7778 37.3613 38 37.3613C45.6444 37.3613 52.2667 38.5613 57.8667 40.9613C63.4667 43.3613 67.7778 46.828 70.8 51.3613C73.9111 55.8058 75.4667 61.1391 75.4667 67.3613V68.9613C75.4667 75.0947 73.9111 80.5613 70.8 85.3613C67.7778 90.0725 63.4222 93.8058 57.7333 96.5613C52.1333 99.228 45.4667 100.561 37.7333 100.561Z"
      fill={props.color}
    />
  </svg>
)
