import { Calendar } from "@modules/calendar/calendar"
import { word } from "@modules/core/utils/i18n"
import { ProgramCalendarEvent } from "@modules/programs/components/programCalendarEvent"
import dayjs, { Dayjs } from "dayjs"

import { EventType } from "@modules/calendar/calendarTypes"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { useServicesContext } from "@modules/core/services/services.context"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { Program, ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import { ProgramInfiniteSessionTypeOf } from "@modules/programSession/programSessionTypes"
import { DetailedSessionType } from "@modules/sessions/components/programSessionDetailsComponent"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { useProgramSessionEvents } from "@modules/training/useTraining"
import { useToast } from "@modules/ui/components/huToast"
import { useMemo, useState } from "react"
import styled from "styled-components"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"

type ProgramCalendarProps = {
  program?: Program
  loading?: boolean
  onClickOnSlot: (arg: DetailedSessionType) => void
  onAddEventClick: (arg0?: Dayjs | number) => void
  detailedSessionId?: string
}

export const ProgramCalendar: React.FC<ProgramCalendarProps> = ({
  program,
  loading,
  onClickOnSlot,
  onAddEventClick,
  detailedSessionId,
}) => {
  const [eventUpdating, setEventUpdating] = useState<EventType>()

  const { navigateToProgramView } = useProgramSidebar()
  const { programSessionService } = useServicesContext()
  const { refreshCalendarEvents, draggingEvent, setDraggingEvent } = useCalendar()
  const toast = useToast()
  const { isSidebarVisible } = useSidebar()
  const { isAuthor, isAcceptedSharedCoach } = useProgramRights(program)

  const canUserSeeSessions =
    isAuthor ||
    isAcceptedSharedCoach ||
    program?.subscriptionStatus === ProgramSubscriptionStatus.ACTIVE ||
    program?.subscriptionStatus === ProgramSubscriptionStatus.PENDING_CANCELLATION ||
    program?.subscriptionStatus === ProgramSubscriptionStatus.TRIALING

  const { sessions: programSessions, isLoading: isLoadingSessions } = useProgramSessionEvents(
    canUserSeeSessions ? program?.id : "",
  )

  const onEventDragStop = async (dateDest?: dayjs.Dayjs, position?: number) => {
    if (draggingEvent && program) {
      const shouldApplyChange =
        draggingEvent &&
        "date" in draggingEvent &&
        "virtualProgramByDateIndex" in draggingEvent &&
        ((dateDest && !dateDest.isSame(draggingEvent.date)) || draggingEvent.virtualProgramByDateIndex !== position)

      if (shouldApplyChange) {
        try {
          setEventUpdating(draggingEvent)
          const session = await programSessionService.getDetailedSession(
            draggingEvent.id,
            program.id,
            program._programType,
          )

          if (session?._type === ProgramInfiniteSessionTypeOf && dateDest) {
            await programSessionService.updateSession(draggingEvent.id, {
              ...session,
              date: dateDest.toString(),
              virtualProgramByDateIndex: position ?? session.virtualProgramByDateIndex ?? 0,
            })
          }
        } catch (e: any) {
          toast.show({ severity: "error", summary: "Error", detail: e.message })
        } finally {
          refreshCalendarEvents()
          setEventUpdating(undefined)
          setDraggingEvent(undefined)
        }
      }
    }
  }

  const onViewDetailsProgramClick = () => {
    if (program) navigateToProgramView(program.id, program._programType)
  }

  const getNoAccessText = useMemo(() => {
    return word(
      program?.subscriptionStatus === ProgramSubscriptionStatus.PENDING_REQUEST
        ? "program.session.noAccessUntilAccepted"
        : program?._programType === ProgramOnOffTypeOf
          ? "program.onOff.session.noAccessUntilBought"
          : "program.session.noAccessUntilSubscribed",
    )
  }, [program?.subscriptionStatus, program?._programType])

  return (
    <Container className="flex flex-column align-content-center flex-grow-1" $isSidebarVisible={isSidebarVisible}>
      <Calendar
        calendarEvents={programSessions}
        showNoAccessOverlay={!canUserSeeSessions}
        loading={loading || isLoadingSessions}
        noAccessText={getNoAccessText}
        noAccessButton={
          program?.subscriptionStatus !== ProgramSubscriptionStatus.PENDING_REQUEST
            ? {
                text: word("program.onOff.subscription.buy.free.title"),
                onClick: onViewDetailsProgramClick,
              }
            : undefined
        }
        canAddEvents={(isAuthor || isAcceptedSharedCoach) && !isSidebarVisible}
        onAddEventClick={onAddEventClick}
        eventLimitPerDay={program?._programType === ProgramOnOffTypeOf ? 1 : undefined}
        addEventTitle={word("sessionForm.create.title")}
        addEventSubtitle={word("sessionForm.create.subtitle")}
        isDragAndDropActive={program?._programType !== ProgramOnOffTypeOf}
        onEventDragStop={onEventDragStop}
        customRenderEvent={(index, event, displayDetailedEvents) =>
          program && (
            <ProgramCalendarEvent
              key={event.id}
              index={index}
              event={programSessions.find((session) => session.id === event.id)}
              displayDetailedEvents={displayDetailedEvents}
              onEventClick={onClickOnSlot}
              isUpdating={eventUpdating?.id === event.id}
              isDetailsOpen={detailedSessionId === event.id}
              program={program}
            />
          )
        }
      />
    </Container>
  )
}

const Container = styled.div<{ $isSidebarVisible?: boolean }>`
  tbody {
    background: ${({ theme }) => theme.mainWhite};
  }

  td {
    background-color: ${({ $isSidebarVisible, theme }) =>
      $isSidebarVisible ? theme.backgroundLightGreyColor + "!important" : ""};
  }
`
