import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const EmomIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="62" height="63" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="1.5" width="60" height="60" rx="18" fill="#53DDEB" />
    <rect x="1" y="1.5" width="60" height="60" rx="18" stroke="url(#paint0_linear_408_84714)" strokeWidth="1.11776" />
    <rect x="8" y="32.5" width="2" height="10" rx="1" transform="rotate(-90 8 32.5)" fill="white" />
    <rect x="20" y="32.5" width="2" height="10" rx="1" transform="rotate(-90 20 32.5)" fill="white" />
    <rect x="32" y="32.5" width="2" height="10" rx="1" transform="rotate(-90 32 32.5)" fill="white" />
    <rect x="44" y="32.5" width="2" height="10" rx="1" transform="rotate(-90 44 32.5)" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_408_84714"
        x1="3.15569"
        y1="2.5"
        x2="43.3973"
        y2="68.4826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.04" />
        <stop offset="0.188137" stopColor="white" stopOpacity="0" />
        <stop offset="0.708289" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.06" />
      </linearGradient>
    </defs>
  </svg>
)
