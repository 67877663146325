import { ChannelListHeader } from "@modules/chat/components/channelListHeader"
import { MessageSearchInput } from "@modules/chat/components/messageSearchInput"
import { MessageSearchList } from "@modules/chat/components/messageSearchList"
import { useChatPageContext } from "@modules/chat/contexts/chatPageContext"
import { useChatClient } from "@modules/chat/hooks/useChatClient"
import { useSearchedMessages } from "@modules/chat/hooks/usePaginatedSearchedMessages"
import { CHANNEL_LIST_SHOWN_WINDOW_WIDTH } from "@modules/chat/utils/chatConstants"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { useWindowSize } from "@modules/utils/hooks/useWindowsSize"
import debounce from "lodash/debounce"
import { useCallback, useState } from "react"
import type { ChannelFilters, ChannelSort, DefaultGenerics } from "stream-chat"
import {
  ChannelPreviewMessenger,
  ChannelPreviewUIComponentProps,
  ChannelList as DefaultChannelList,
  LoadMorePaginator,
} from "stream-chat-react"
import { getDisplayTitle } from "stream-chat-react/dist/components/ChannelPreview/utils"

const sort: ChannelSort<DefaultGenerics> = { last_message_at: -1 }
const options = {
  presence: true,
  state: true,
  watch: true,
}

export type ChannelListType = {
  filters: ChannelFilters<DefaultGenerics>
}

export const ChannelList: React.FC<ChannelListType> = ({ filters }) => {
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [inputValue, setInputValue] = useState<string>("")
  const { chatClient } = useChatClient()
  const { updateIsCreatingNewChannel, updateIsAddingMembersToChannel, displayChannelList, toggleDisplayChannelList } =
    useChatPageContext()
  const { loading, loadMore, loadingMore, messages, refreshing, refreshList, reset } = useSearchedMessages(searchQuery)
  const { width } = useWindowSize()

  const CustomListItem = (props: ChannelPreviewUIComponentProps<DefaultGenerics>) => {
    return (
      <ChannelPreviewMessenger
        onSelect={() => {
          updateIsCreatingNewChannel(false)
          updateIsAddingMembersToChannel(undefined)
          if (props.setActiveChannel) {
            props.setActiveChannel(props.channel, props.watchers)
            if (width <= CHANNEL_LIST_SHOWN_WINDOW_WIDTH) toggleDisplayChannelList()
          }
        }}
        {...props}
        displayTitle={getDisplayTitle(props.channel, chatClient?.user)}
      />
    )
  }

  const onInputChange = (text: string) => {
    setInputValue(text)
    scheduleSetSearchQuery(text)
  }

  const scheduleSetSearchQuery = useCallback(debounce(setSearchQuery, 300), [setSearchQuery])

  return (
    <div
      className={
        "channel-list-container transition-all transition-duration-150 transition-linear bordertopleft12 overflow-hidden border-1 surface-border " +
        (width > CHANNEL_LIST_SHOWN_WINDOW_WIDTH ? "mr-3 " : "") +
        (displayChannelList ? "max-w-full" : "max-w-0 min-w-0")
      }
    >
      <ChannelListHeader onResetSearch={() => onInputChange("")} />
      <MessageSearchInput
        search={{
          onChange: (event) => onInputChange(event.target.value),
          loading,
          value: inputValue,
          onReset: () => {
            reset()
            setSearchQuery("")
            setInputValue("")
          },
        }}
        onFocus={() => {
          updateIsCreatingNewChannel(false)
          updateIsAddingMembersToChannel(undefined)
        }}
      />
      {!!searchQuery ? (
        <MessageSearchList
          loading={loading}
          loadMore={loadMore}
          loadingMore={loadingMore}
          messages={messages}
          refreshing={refreshing}
          refreshList={refreshList}
        />
      ) : (
        <DefaultChannelList<DefaultGenerics>
          filters={filters}
          sort={sort}
          options={options}
          LoadingIndicator={() => <></>}
          EmptyStateIndicator={() => <></>}
          LoadingErrorIndicator={() => <></>}
          Preview={CustomListItem}
          Paginator={(props) => (
            <LoadMorePaginator
              {...props}
              LoadMoreButton={({ isLoading, onClick }) => (
                <HUButton
                  type="Default"
                  colorType="Secondary"
                  size="XS"
                  loading={isLoading}
                  onClick={onClick}
                  text={word("global.load_more")}
                  className="m-auto mt-1"
                />
              )}
            />
          )}
        />
      )}
    </div>
  )
}
