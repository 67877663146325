import { word } from "@modules/core/utils/i18n"
import {
  ExerciseFormikType,
  convertExerciseDraftToFormik,
  convertFormikToExerciseDraft,
  getExerciseValidationSchema,
} from "@modules/exercises/exerciseFormikTypes"
import { requiredInput } from "@modules/form/formUtils"
import {
  ProgramInfiniteSessionDraft,
  ProgramInfiniteSessionTypeOf,
  ProgramOnOffSessionDraft,
  ProgramOnOffSessionTypeOf,
} from "@modules/programSession/programSessionTypes"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import * as Yup from "yup"

export type ProgramSessionInputKey =
  | "name"
  | "date"
  | "programId"
  | "description"
  | "exercises"
  | "startDateTime"
  | "endDateTime"
  | "cardioTag"
  | "weightliftingTag"
  | "gymnasticTag"
  | "dayIndex"
  | "restDay"
  | "hideSessionContent"
  | `${string}.category`
  | `${string}.description`
  | `${string}.trainingTime[${number}]`
  | `${string}.restTime[${number}]`
  | `${string}.numberOfSets`
  | `${string}.numberOfRounds`
  | `${string}.restBetweenSetsTime`
  | `${string}.trainingTime`
  | `${string}.restTime`
  | `${string}.noLimit`
  | `${string}.scoreType`
  | `${string}.rpe`

export function getProgramInfiniteSessionValidationSchema() {
  return Yup.object({
    name: requiredInput(Yup.string().max(64, word("form.maximum.characters", { count: 64 }))),
    date: Yup.date().required(),
    description: Yup.string(),
    exercises: Yup.array().of(getExerciseValidationSchema()),
    programId: Yup.string().required(word("program.planning.errorMessage.SelectProgram")),
    hideSessionContent: Yup.boolean().required(),
  })
}

export function getProgramOnOffSessionValidationSchema() {
  return Yup.object({
    name: requiredInput(Yup.string().max(64, word("form.maximum.characters", { count: 64 }))),
    description: Yup.string(),
    exercises: Yup.array().of(getExerciseValidationSchema()),
    programId: Yup.string().required(word("program.planning.errorMessage.SelectProgram")),
    dayIndex: Yup.number().required(word("global.form.required")).positive(word("global.form.positive")),
    restDay: Yup.boolean(),
  })
}

export const convertProgramInfiniteSessionToFormik = (
  session: ProgramInfiniteSessionDraft,
): ProgramInfiniteSessionFormikType => {
  return {
    ...session,
    _type: ProgramInfiniteSessionTypeOf,
    exercises: session.exercises.map((exerciseDraft) => convertExerciseDraftToFormik(exerciseDraft)),
    date: session.date ? dayjs(session.date).toDate() : undefined,
    hideSessionContent: session.hideSessionContent,
    cardioTag: session.cardioTag ?? false,
    weightliftingTag: session.weightliftingTag ?? false,
    gymnasticTag: session.gymnasticTag ?? false,
  }
}

export const convertProgramOnOffSessionToFormik = (
  session: ProgramOnOffSessionDraft,
): ProgramOnOffSessionFormikType => {
  return {
    ...session,
    _type: ProgramOnOffSessionTypeOf,
    exercises: session.exercises.map((exerciseDraft) => convertExerciseDraftToFormik(exerciseDraft)),
    dayIndex: typeof session.dayIndex !== "undefined" ? session.dayIndex + 1 : undefined,
    restDay: session.restDay,
    hideSessionContent: session.hideSessionContent,
    cardioTag: session.cardioTag ?? false,
    weightliftingTag: session.weightliftingTag ?? false,
    gymnasticTag: session.gymnasticTag ?? false,
  }
}

export const convertFormikToProgramInfiniteSessionDraft = (
  formik: ProgramInfiniteSessionFormikType,
): ProgramInfiniteSessionDraft => {
  return {
    ...formik,
    date: dayjs(formik.date).format(defaultDateFormat),
    exercises: formik.exercises.map((exerciseFormik) => convertFormikToExerciseDraft(exerciseFormik)),
  }
}

export const convertFormikToProgramOnOffSessionDraft = (
  formik: ProgramOnOffSessionFormikType,
): ProgramOnOffSessionDraft => {
  return {
    ...formik,
    dayIndex: typeof formik.dayIndex !== "undefined" ? formik.dayIndex - 1 : undefined,
    exercises: formik.exercises.map((exerciseFormik) => convertFormikToExerciseDraft(exerciseFormik)),
  }
}

export type ProgramInfiniteSessionFormikType = {
  _type: typeof ProgramInfiniteSessionTypeOf
  name: string
  programId: string
  date?: Date
  description: string
  exercises: ExerciseFormikType[]
  hideSessionContent: boolean
  cardioTag: boolean
  weightliftingTag: boolean
  gymnasticTag: boolean
}

export type ProgramOnOffSessionFormikType = {
  _type: typeof ProgramOnOffSessionTypeOf
  name: string
  programId: string
  dayIndex?: number
  restDay: boolean
  description: string
  exercises: ExerciseFormikType[]
  hideSessionContent: boolean
  cardioTag: boolean
  weightliftingTag: boolean
  gymnasticTag: boolean
}
