import { WebService } from "@modules/core/services/webService"
import { UserFinanceApi } from "@modules/finances/services/userFinanceApi"
import { TransactionStatus } from "@modules/finances/types/financesType"
import { createCompositeStore } from "@modules/store"
import dayjs from "dayjs"
import { observable } from "micro-observables"

export class UserFinanceService implements WebService {
  transactionTotalsStore = createCompositeStore((period) => this.getTransactionTotals(period))
  private _refreshingCount = observable<number>(0)
  readonly refreshingCount = this._refreshingCount.readOnly()

  private _lastUpdateLoading = observable<boolean>(false)
  readonly lastUpdateLoading = this._lastUpdateLoading.readOnly()

  constructor(private api: UserFinanceApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async getTransaction(transactionId: string) {
    return this.api.getTransaction(transactionId)
  }

  getTransactions(filter: TransactionStatus | undefined, period: string, page?: string, size?: number) {
    return this.api.getTransactions(filter, period, page, size)
  }
  getTransactionsHistory(stripeCustomerId: string, page?: string, size?: number) {
    return this.api.getTransactionsHistory(stripeCustomerId, page, size)
  }

  /**
   *
   * @param period format YYYY-MM
   * @returns
   */
  getStats(period: string) {
    return this.api.getStats(period)
  }

  getTransactionTotals(period: string) {
    return this.api.getTransactionTotals(period)
  }

  async getLastUpdate() {
    const result = dayjs(await this.api.getLastUpdate())
    return result
  }

  getFirstPeriod() {
    return this.api.getFirstPeriod()
  }

  async updateStats(): Promise<void> {
    try {
      this._lastUpdateLoading.set(true)
      await this.api.updateStats()
      this._refreshingCount.set(this.refreshingCount.get() + 1)
    } catch (e) {
      throw e
    } finally {
      this._lastUpdateLoading.set(false)
    }
  }

  exportTransactions(startDate: string, endDate: string) {
    return this.api.exportTransactions(startDate, endDate)
  }
}
