import { useChatPageContext } from "@modules/chat/contexts/chatPageContext"
import { HUButton } from "@modules/ui/components/huButton"
import { useEffect } from "react"
import { useTheme } from "styled-components"

type ChannelListHeaderProps = {
  onResetSearch: () => void
}

export const ChannelListHeader: React.FC<ChannelListHeaderProps> = ({ onResetSearch }) => {
  const {
    updateIsCreatingNewChannel,
    updateIsAddingMembersToChannel,
    updateIsCreatingGroup,
    isCreatingNewChannel,
    isAddingMembersToChannel,
  } = useChatPageContext()
  const theme = useTheme()

  useEffect(() => {
    return () => {
      closeChannelCreation()
    }
  }, [])

  const openOneToOneChannelCreation = () => {
    updateIsCreatingGroup(false)
    updateIsCreatingNewChannel(true)
    onResetSearch()
  }
  const openGroupChannelCreation = () => {
    updateIsCreatingGroup(true)
    updateIsCreatingNewChannel(true)
    onResetSearch()
  }
  const closeChannelCreation = () => {
    updateIsAddingMembersToChannel(undefined)
    updateIsCreatingNewChannel(false)
    onResetSearch()
  }

  const isUserSearchOpen = isCreatingNewChannel || !!isAddingMembersToChannel

  return (
    <div className="new-chat-channel-button flex justify-content-center gap-3 align-items-center mb-2 pt-2 pl-3 pr-3 pb-1 overflow-hidden">
      {isUserSearchOpen ? (
        <HUButton
          colorType="Quaternary"
          size="XS"
          type="Rounded"
          icon={{ iconView: <span className="pi pi-times" style={{ color: theme.textPrimary }} /> }}
          onClick={closeChannelCreation}
          className="focus:outline-none focus:shadow-none"
        />
      ) : (
        <>
          <HUButton
            colorType="Quaternary"
            size="XS"
            type="Rounded"
            icon={{ iconView: <span className="pi pi-users" style={{ color: theme.textPrimary }} /> }}
            onClick={openGroupChannelCreation}
            className="focus:outline-none focus:shadow-none"
          />
          <HUButton
            colorType="Quaternary"
            size="XS"
            type="Rounded"
            icon={{ iconView: <span className="pi pi-user-plus" style={{ color: theme.textPrimary }} /> }}
            onClick={openOneToOneChannelCreation}
            className="focus:outline-none focus:shadow-none"
          />
        </>
      )}
    </div>
  )
}
