import { convertDurationToString } from "@modules/duration/timeConverter"

export const convertTimeChrono = (seconds: number) => {
  if (seconds < 60) {
    return convertDurationToString(seconds, {
      hideMinutes: true,
      noPadStart: true,
      unitSeconds: "s",
    })
  }
  return convertDurationToString(seconds, {
    noPadStart: true,
    unitHours: "h",
    unitMinutes: "min",
    unitSeconds: "s",
  })
}
