import { convertAddressFromFormik } from "@modules/address/addressFormikType"
import { Address, AddressDraft } from "@modules/address/addressTypes"
import { EmptyAddress } from "@modules/address/addressUtils"
import { MemberFormikType } from "@modules/members/memberFormikType"
import { PublicProfile } from "@modules/publicProfile/publicProfileTypes"
import { HUImage } from "@modules/types/imageTypes"

export const allMemberFilters = ["all", "prospect", "active", "terminated", "staff", "dropin"] as const
export type MemberFilter = (typeof allMemberFilters)[number]

export enum MemberStatus {
  Active = "ACTIVE",
  Prospect = "PROSPECT",
  Terminated = "TERMINATED",
}

export interface Member {
  id: string
  avatar?: HUImage
  firstname: string
  lastname: string
  username: string
  inscriptionDate?: Date
  status: MemberStatus
  absent?: boolean
  lastVisited?: Date
  noShowPastMonth?: number
  note?: string
  activeOfferTitles?: string[]
}

export interface MemberDetailed {
  id: string
  userId: string
  firstname: string
  lastname: string
  inscriptionDate?: Date
  username: string
  birthdate?: Date
  phoneNumber: string
  email: string
  address: Address
  emergencyInformation: string
  note?: string
  status: MemberStatus
  lastVisited?: Date
  noShowPastMonth: number
  activeOffersTitles?: string[]
  nextBookings: BoxMemberNextBooking[]
  publicProfile?: PublicProfile
}

export interface BoxMemberNextBooking {
  slotId: string
  name: string
  date: Date
  color: string
}

export interface MemberDetailedDraft {
  firstname: string
  lastname: string
  username?: string
  birthDate?: Date
  phoneNumber: string
  email: string
  address: AddressDraft
  emergencyInformation: string
  note: string
  activeOffersTitles?: string[]
}

export function convertMemberToDraft(member?: MemberDetailed): MemberDetailedDraft {
  return {
    firstname: member?.firstname ?? "",
    lastname: member?.lastname ?? "",
    username: member?.username?.trim() || undefined,
    birthDate: member?.birthdate ? member?.birthdate : undefined,
    phoneNumber: member?.phoneNumber ?? "",
    email: member?.email ?? "",
    address: member?.address ?? EmptyAddress,
    emergencyInformation: member?.emergencyInformation ?? "",
    note: member?.note ?? "",
    activeOffersTitles: member?.activeOffersTitles ?? [],
  }
}

export function convertMemberFromFormik(formik: MemberFormikType): MemberDetailedDraft {
  return {
    firstname: formik.firstname,
    lastname: formik.lastname,
    username: formik.username,
    phoneNumber: formik.phoneNumber,
    birthDate: formik.birthDate,
    email: formik.email,
    address: convertAddressFromFormik(formik),
    emergencyInformation: formik.emergencyInformation,
    note: formik.note,
  }
}
