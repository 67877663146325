import { useUser } from "@modules/auth/hooks/useUser"
import { useServicesContext } from "@modules/core/services/services.context"
import { useObservable } from "micro-observables"
import { useState } from "react"

const REACH_COUNT = 5

export function useDebug() {
  const user = useUser()
  const { remoteConfigService } = useServicesContext()
  const [nbClick, setNbClick] = useState(0)
  const [visible, setVisible] = useState(false)
  const emailAuthorisation = useObservable(remoteConfigService.debugAuthorisation)
  const regexEmailAuthorisation = new RegExp(`^${emailAuthorisation}$`)
  const canUseDebug = !!user?.email && regexEmailAuthorisation.test(user.email)

  const onDebugClick = () => {
    if (!(user?.email && regexEmailAuthorisation.test(user.email))) {
      return
    }
    if (nbClick >= REACH_COUNT) {
      setVisible(true)
    } else {
      setNbClick(nbClick + 1)
    }
  }

  return { visible, setVisible, onDebugClick, canUseDebug }
}
