import { ChannelDetailsOverlay } from "@modules/chat/components/channelDetailsOverlay"
import { ChannelHeader } from "@modules/chat/components/channelHeader"
import { ChannelList } from "@modules/chat/components/channelList"
import { EmptyChannel } from "@modules/chat/components/emptyChannel"
import { NewChatChannelForm } from "@modules/chat/components/newChatChannelForm"
import { useChatPageContext } from "@modules/chat/contexts/chatPageContext"
import { useChatClient, useChatClientIsConnected } from "@modules/chat/hooks/useChatClient"
import { useCreateChannel } from "@modules/chat/hooks/useCreateChat"
import { customReactions } from "@modules/chat/icons/reactions/reactions"
import { word } from "@modules/core/utils/i18n"
import { getFormatedDateTime } from "@modules/language/languageUtils"
import { ScreenHeader } from "@modules/ui/components/header"
import { HUStack } from "@modules/ui/components/layout"
import { LoadingPage } from "@modules/ui/components/loadingPage"
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import type { ChannelFilters, DefaultGenerics } from "stream-chat"
import {
  Channel,
  DateSeparator,
  MessageInput,
  MessageList,
  MessageTimestamp,
  ReactionSelector,
  ReactionSelectorProps,
  ReactionsList,
  ReactionsListProps,
  Window,
  useChatContext,
} from "stream-chat-react"

export const ChatPage: React.FC = () => {
  const { chatClient } = useChatClient()
  const { setActiveChannel } = useChatContext()
  const { createOneToOneChannel } = useCreateChannel()
  const connected = useChatClientIsConnected()
  const location = useLocation() as { state?: { userToChatTo?: string; programToChat?: string } }

  const { isCreatingNewChannel, isAddingMembersToChannel, displayChatDetailsOverlay } = useChatPageContext()
  const [isLoadingChannels, updateIsLoadingChannels] = useState<boolean>(true)

  const chatClientUserId = chatClient?.user?.id

  const filters: ChannelFilters<DefaultGenerics> = useMemo(() => {
    if (chatClientUserId) {
      return {
        members: {
          $in: [chatClientUserId],
        },
        hidden: false,
      }
    } else {
      return {}
    }
  }, [chatClientUserId])

  useEffect(() => {
    if (chatClient && chatClientUserId) {
      chatClient.queryChannels(filters, [], { limit: 200 }).then((channels) => {
        updateIsLoadingChannels(false)
        // timeout hack to set the active channel
        setTimeout(() => {
          if (location.state?.userToChatTo) {
            createOneToOneChannel(location.state.userToChatTo)
          }
          if (location.state?.programToChat) {
            const programChannel = channels.find(
              (channel) => location.state && channel.type === "program" && channel.id === location.state.programToChat,
            )

            if (programChannel) {
              setActiveChannel(programChannel)
            }
          }
        }, 300)
      })
    }
  }, [chatClient, chatClientUserId])

  const CustomReactionSelector = React.forwardRef(
    (props: ReactionSelectorProps<DefaultGenerics>, ref: React.ForwardedRef<HTMLDivElement | null>) => {
      return <ReactionSelector {...props} reactionOptions={customReactions} ref={ref} />
    },
  )

  const CustomReactionList = (props: ReactionsListProps<DefaultGenerics>) => (
    <ReactionsList {...props} reactionOptions={customReactions} />
  )
  return (
    <HUStack gap={0} flexGrow style={{ width: "100%" }}>
      <ScreenHeader title={word("chat.header.title")} subTitle={word("chat.header.subTitle")} />
      <div className="chat flex">
        {chatClient && chatClient.user && connected && !isLoadingChannels ? (
          <>
            <ChannelList filters={filters} />
            {!isCreatingNewChannel && !isAddingMembersToChannel ? (
              Object.keys(chatClient.activeChannels).length > 0 ? (
                <Channel<DefaultGenerics>
                  MessageTimestamp={() => (
                    <MessageTimestamp format="HH:mm" customClass="str-chat__message-simple-timestamp" />
                  )}
                  LoadingIndicator={() => <></>}
                  DateSeparator={({ date }) => (
                    <DateSeparator
                      date={date}
                      formatDate={(date: Date) => getFormatedDateTime(date)}
                      position="center"
                    />
                  )}
                  ReactionSelector={CustomReactionSelector}
                  ReactionsList={CustomReactionList}
                >
                  <Window>
                    <ChannelHeader />
                    <MessageList messageActions={["react", "quote", "delete"]} />
                    <MessageInput grow />
                  </Window>

                  {displayChatDetailsOverlay && <ChannelDetailsOverlay />}
                </Channel>
              ) : (
                <EmptyChannel />
              )
            ) : (
              <NewChatChannelForm />
            )}
          </>
        ) : (
          <LoadingPage />
        )}
      </div>
    </HUStack>
  )
}
