import ChatEmpty from "@images/empty/chat_empty.svg"
import { useChatPageContext } from "@modules/chat/contexts/chatPageContext"
import { word } from "@modules/core/utils/i18n"
import { EmptyView } from "@modules/ui/components/emptyView"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { useTheme } from "styled-components"

export const EmptyChannel: React.FC = () => {
  const theme = useTheme()
  const { updateIsCreatingNewChannel, updateIsCreatingGroup } = useChatPageContext()

  const openOneToOneChannelCreation = () => {
    updateIsCreatingGroup(false)
    updateIsCreatingNewChannel(true)
  }
  const openGroupChannelCreation = () => {
    updateIsCreatingGroup(true)
    updateIsCreatingNewChannel(true)
  }

  return (
    <Card className="flex-1 flex justify-content-center align-items-center gap-2">
      <EmptyView
        flexGrow={true}
        image={{ src: ChatEmpty, background: theme.neutralColor100 }}
        description={word("chat.channel.empty.description")}
      />

      <div className="new-chat-channel-button flex justify-content-center gap-3 align-items-center pt-2 pl-3 pr-3 pb-1 overflow-hidden">
        <Button
          severity="secondary"
          text
          rounded
          icon="pi pi-users"
          onClick={openGroupChannelCreation}
          className="focus:outline-none focus:shadow-none"
        />
        <Button
          severity="secondary"
          text
          rounded
          icon="pi pi-user-plus"
          onClick={openOneToOneChannelCreation}
          className="focus:outline-none focus:shadow-none"
        />
      </div>
    </Card>
  )
}
