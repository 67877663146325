import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import {
  ObjectiveFormikType,
  convertObjectiveFormFormik,
  getObjectivesValidationSchema,
} from "@modules/objectives/objectivesFormikType"
import { Objective } from "@modules/objectives/objectivesTypes"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { useToast } from "@modules/ui/components/huToast"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { displayedFullDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { useFormik } from "formik"
import React from "react"

type ObjectivesPopupProps = {
  hideObjectivesPopup: () => void
  objectivePrimary: Objective | null
}

export const ObjectivesPopup: React.FC<ObjectivesPopupProps> = ({ hideObjectivesPopup, objectivePrimary }) => {
  const toast = useToast()
  const { objectivesService } = useServicesContext()
  const confirmPopup = useConfirmPopup()
  const objectiveFormik = useFormik<ObjectiveFormikType>({
    initialValues: {
      id: objectivePrimary?.id || "",
      objective: objectivePrimary?.objective || "",
      targetDate: dayjs(objectivePrimary?.targetDate).toDate() || new Date(),
    },
    validationSchema: getObjectivesValidationSchema(),
    onSubmit: async (values) => {
      if (values.id) {
        try {
          await objectivesService.updateOjective(convertObjectiveFormFormik(values))
          toast.show({
            severity: "success",
            summary: word("dashboard.objectives.addGoalToast.success"),
          })
          hideObjectivesPopup()
        } catch (e: any) {
          console.error("Error on create objective", e)
          toast.show({ severity: "error", summary: "Error", detail: e.message })
        }
      } else {
        try {
          await objectivesService.createNewObjective(convertObjectiveFormFormik(values))
          toast.show({
            severity: "success",
            summary: word("dashboard.objectives.addGoalToast.success"),
          })
          hideObjectivesPopup()
        } catch (e: any) {
          console.error("Error on create objective", e)
          toast.show({ severity: "error", summary: "Error", detail: e.message })
        }
      }
    },
  })

  const deleteObjective = (id: string) => {
    confirmPopup.show({
      title: word("dashboard.objectives.removeGoal.popup.title"),
      message: word("dashboard.objectives.removeGoal.popup.message"),
      accept: async () => {
        await objectivesService.deleteObjective(id)
        hideObjectivesPopup()
        toast.show({
          severity: "success",
          summary: word("dashboard.objectives.removeGoalToast.success"),
        })
      },
      footerProps: {
        align: true,
      },
    })
  }
  return (
    <>
      <HUStack gap={20} style={{ paddingTop: 24, paddingBottom: 24 }}>
        <FormikInput
          flex
          isRequiredInput
          label={word("dashboard.objectives.deadline")}
          getFieldProps={objectiveFormik.getFieldProps}
          name="targetDate"
          type="calendar"
          isInvalid={isFormFieldInValid("targetDate", objectiveFormik)}
          error={getFormErrorMessage("targetDate", objectiveFormik)}
          calendarProps={{
            minDate: dayjs().toDate(),
            showIcon: true,
            dateFormat: displayedFullDateFormat,
            inputStyle: { textTransform: "capitalize" },
          }}
        />
        <FormikInput
          flex
          isRequiredInput
          label={word("dashboard.objectives.title")}
          placeHolder={word("dashboard.objectives.wish")}
          formikStyle={{ display: "flex", flexGrow: 1 }}
          getFieldProps={objectiveFormik.getFieldProps}
          isInvalid={isFormFieldInValid("objective", objectiveFormik)}
          error={getFormErrorMessage("objective", objectiveFormik)}
          name="objective"
          type="textarea"
          textareaProps={{ autoResize: true, rows: 2, maxLength: 100 }}
        />

        <HURow style={{ alignSelf: "end" }} gap={7}>
          <HUButton
            type="Default"
            size="S"
            colorType="Quaternary"
            text={word("global.delete")}
            onClick={() => objectivePrimary && deleteObjective(objectivePrimary.id)}
            disabled={!objectivePrimary}
          />
          <HUButton
            type="Default"
            size="S"
            colorType="Primary"
            text={word("global.save")}
            onClick={() => objectiveFormik.submitForm()}
            className="p-button p-button-primary"
          />
        </HURow>
      </HUStack>
    </>
  )
}
