import { useUser } from "@modules/auth/hooks/useUser"
import { CANCELED_BY_USER } from "@modules/common/types/errorTypes"
import { ConfigService } from "@modules/core/services/configService"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { useStripeAccount, useStripePayment } from "@modules/finances/hooks/useStripeAccount"
import { getFormatedDateTime } from "@modules/language/languageUtils"
import { Path } from "@modules/navigation/routes"
import { getMySpacePath, useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { useProgramSASRequests, useProgramStripeSubscriptionInfos } from "@modules/programs/hooks/usePrograms"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { Program, ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import { useToast } from "@modules/ui/components/huToast"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { LoggerType } from "@modules/utils/loggerUtils"
import dayjs from "dayjs"
import { useState } from "react"

type ProgramAuthorSubscriptionParam = {
  program?: Program
}

export const useProgramAuthorSubscription = ({ program }: ProgramAuthorSubscriptionParam) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const { programInfiniteService, programOnOffService } = useServicesContext()
  const toast = useToast()

  const { result: sasRequests, loading: loadingSasRequest } = useProgramSASRequests({
    programId: program?.id ?? "",
    programType: program?._programType,
    originDateRequest: dayjs().toDate(),
  })
  const { loading: actionLoading, useStripeAccountStatus } = useStripeAccount()
  const { loading: stripeAccountStatusLoading, stripeAccountStatus } = useStripeAccountStatus()
  const { navigateToProgramPriceEdition, navigateToProgramPriceSasWarning } = useProgramSidebar()

  const isStripeAccountComplete = stripeAccountStatus === "OK"
  const hasSASRequests = program?.entranceCheckEnabled && sasRequests.length > 0

  const switchToPaying = () => {
    if (program && isStripeAccountComplete) {
      if (hasSASRequests) {
        navigateToProgramPriceSasWarning(program)
      } else {
        navigateToProgramPriceEdition(program)
      }
    }
  }

  const switchToFree = async () => {
    try {
      if (program) {
        setIsUpdating(true)

        if (program._programType === ProgramInfiniteTypeOf)
          await programInfiniteService.removeProgramInfinitePrice(program.id)
        else if (program._programType === ProgramOnOffTypeOf)
          await programOnOffService.removeProgramOnOffPrice(program.id)

        toast.show({ severity: "success", detail: word("program.switchPrice.success") })
      }
    } catch (e: any) {
      toast.show({ severity: "error", summary: "Error", detail: e.message })
    } finally {
      setIsUpdating(false)
    }
  }

  return {
    isLoading: actionLoading || stripeAccountStatusLoading || loadingSasRequest,
    isUpdating,
    switchToFree,
    switchToPaying,
  }
}

export const useProgramAthleteSubscription = (program: Program) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const { programService } = useServicesContext()

  const confirmPopup = useConfirmPopup()
  const toast = useToast()
  const user = useUser()
  const mySpaceNavigate = useMySpaceNavigate()
  const { stripePayment } = useStripePayment()

  const isAuthor = user?.id === program.author.id
  const isOnOffProgram = program._programType === ProgramOnOffTypeOf

  const { result: programStripeSubscriptionInfos, loading } = useProgramStripeSubscriptionInfos(
    program.id,
    program._programType,
  )

  const subscribe = async (options?: { stripeWebviewStartCallback?: () => void }): Promise<boolean> => {
    const isInPendingPayment = program.subscriptionStatus === ProgramSubscriptionStatus.PENDING_PAYMENT
    const isInactive = program.subscriptionStatus === ProgramSubscriptionStatus.INACTIVE
    const canSubscribeToProgram = isInactive || isInPendingPayment
    const canRequestEntranceInSAS = program.entranceCheckEnabled && isInactive

    if (!program || !canSubscribeToProgram) {
      return false
    }
    if (!program.price || canRequestEntranceInSAS) {
      return await joinProgram()
    }
    if (isInPendingPayment || !program.entranceCheckEnabled) {
      return await joinProgramWithPayment(options)
    }
    return false
  }

  const joinProgram = async (): Promise<boolean> => {
    try {
      setIsUpdating(true)
      const status = await programService.subscribeProgram(program.id, program._programType)
      if (status === ProgramSubscriptionStatus.PENDING_REQUEST) {
        toast.show({ severity: "success", detail: word("program.subscription.success.toast") })
      }
      if (status === ProgramSubscriptionStatus.ACTIVE) {
        toast.show({
          severity: "success",
          detail: word(isOnOffProgram ? "program.onOff.buy.success.toast" : "program.subscribe.success.toast"),
        })

        setTimeout(() => {
          if (program._programType === ProgramInfiniteTypeOf) mySpaceNavigate(Path.MySpace.ProgramDetails(program.id))
          else if (program._programType === ProgramOnOffTypeOf)
            mySpaceNavigate(Path.MySpace.ProgramOnOffDetails(program.id))
        }, 1000)
      }
      return true
    } catch (e: any) {
      if (e.code === "PAID_PROGRAM_SUBSCRIPTION") {
        console.warn(LoggerType.Program + "try to free subscribe on paid program, refresh program ...")
        await programService.programStore.fetch(program.id)
      } else {
        console.error(LoggerType.Program + "subscribe", e)
        toast.show({
          severity: "error",
          detail: word(isOnOffProgram ? "program.onOff.buy.error.toast" : "program.subscribe.error.toast"),
        })
      }
      return false
    } finally {
      setIsUpdating(false)
    }
  }
  const joinProgramWithPayment = async (options?: { stripeWebviewStartCallback?: () => void }): Promise<boolean> => {
    try {
      setIsUpdating(true)
      const result = await programService.subscribeToPayingProgram(
        program.id,
        program._programType,
        (url) => stripePayment(url, options),
        getProgramPage(),
        window.location.href,
      )
      if (result) {
        toast.show({
          detail: word(isOnOffProgram ? "program.onOff.buy.success.toast" : "program.subscribe.success.toast"),
          severity: "success",
        })
      }
      return result
    } catch (e: any) {
      if (e !== CANCELED_BY_USER) {
        if (e.code === "PROGRAM_HAS_NO_PRICE") {
          console.warn(LoggerType.Program + "try to paid a free program, refresh program ...")
          await programService.programStore.fetch(program.id)
        } else {
          console.error(LoggerType.Stripe + "subscribe on paid program", e)
          toast.show({
            detail: word(isOnOffProgram ? "program.onOff.buy.error.toast" : "program.subscribe.error.toast"),
            severity: "error",
          })
        }
      }
      return false
    } finally {
      setIsUpdating(false)
    }
  }

  const unsubscribe = async (): Promise<void> => {
    const isActive = program.subscriptionStatus === ProgramSubscriptionStatus.ACTIVE
    const canUnsubscribeToProgram =
      isActive ||
      program.subscriptionStatus === ProgramSubscriptionStatus.PENDING_PAYMENT ||
      program.subscriptionStatus === ProgramSubscriptionStatus.TRIALING

    if (program && !isAuthor && canUnsubscribeToProgram) {
      try {
        const processUnsubscribe = async () => {
          setIsUpdating(true)
          await programService.unsubscribeProgram(program.id, program._programType)
          setIsUpdating(false)
        }

        if (
          program.price &&
          isActive &&
          programStripeSubscriptionInfos &&
          "endPeriodDate" in programStripeSubscriptionInfos &&
          typeof programStripeSubscriptionInfos.endPeriodDate === "string"
        ) {
          confirmPopup.show({
            title: word("program.subscription.paying.unsubscribe.confirm.title"),
            message: word("program.subscription.paying.unsubscribe.confirm.description", {
              endPeriodDate:
                (programStripeSubscriptionInfos.endPeriodDate &&
                  getFormatedDateTime(programStripeSubscriptionInfos.endPeriodDate)) ??
                word("global.missingInformation"),
            }),
            accept: async () => {
              await processUnsubscribe()
            },
            acceptValidated: word("program.unsubscribe.success.toast"),
            footerProps: {
              align: true,
            },
          })
        } else if (!isActive) {
          confirmPopup.show({
            title: word(
              program.subscriptionStatus === ProgramSubscriptionStatus.TRIALING
                ? "program.subscription.trialing.cancel.popup.title"
                : "program.details.pendingPayment.popup.title",
            ),
            message: word(
              program.subscriptionStatus === ProgramSubscriptionStatus.TRIALING
                ? "program.subscription.trialing.cancel.popup.description"
                : "program.details.pendingPayment.popup.description",
            ),
            accept: async () => {
              await processUnsubscribe()
            },
            acceptValidated: word("program.unsubscribe.success.toast"),
            footerProps: {
              align: true,
            },
          })
        } else {
          await processUnsubscribe()
        }
      } catch (e) {
        console.error(LoggerType.Program + "unsubscribe", e)
        toast.show({ detail: word("program.unsubscribe.error.toast"), severity: "error" })
      } finally {
        setIsUpdating(false)
      }
    }
  }

  const getProgramPage = () => {
    if (program._programType === ProgramInfiniteTypeOf) {
      return `${ConfigService.config.WEB_URL}${getMySpacePath(Path.MySpace.ProgramDetails(program.id))}`
    } else if (program._programType === ProgramOnOffTypeOf) {
      return `${ConfigService.config.WEB_URL}${getMySpacePath(Path.MySpace.ProgramOnOffDetails(program.id))}`
    }
    return ""
  }

  return { isUpdating, loading, subscribe, unsubscribe, programStripeSubscriptionInfos }
}
