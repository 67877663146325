import { ChannelType } from "@modules/chat/channelTypes"
import { ChatAvatar } from "@modules/chat/components/chatAvatar"
import { useChatPageContext } from "@modules/chat/contexts/chatPageContext"
import { useChatClient } from "@modules/chat/hooks/useChatClient"
import { CHANNEL_LIST_SHOWN_WINDOW_WIDTH, DETAILS_OVERLAY_SHOWN_WINDOW_WIDTH } from "@modules/chat/utils/chatConstants"
import { getChannelCreatorId } from "@modules/chat/utils/getChannelCreatorId"
import { word } from "@modules/core/utils/i18n"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useWindowSize } from "@modules/utils/hooks/useWindowsSize"
import { getApproximatedFormattedNumber } from "@modules/utils/numberUtils"
import { useEffect, useState } from "react"
import { useChannelActionContext, useChannelPreviewInfo, useChannelStateContext } from "stream-chat-react"
import { getDisplayTitle } from "stream-chat-react/dist/components/ChannelPreview/utils"
import { useTheme } from "styled-components"

export const ChannelHeader: React.FC = () => {
  const { chatClient } = useChatClient()
  const { channel, watcher_count } = useChannelStateContext()
  const theme = useTheme()
  const { displayImage } = useChannelPreviewInfo({ channel })
  const { subtitle } = channel.data || {}
  const {
    displayChatDetailsOverlay,
    toggleDisplayChatDetailsOverlay,
    displayChannelList,
    toggleDisplayChannelList,
    messageToJumpTo,
    resetMessageToJumpTo,
  } = useChatPageContext()
  const { width } = useWindowSize()
  const [upToDateChannel, updateUpToDateChannel] = useState<{ name: string; member_count: number }>({
    name: getDisplayTitle(channel, chatClient?.user) ?? "",
    member_count: channel.data?.member_count ?? 0,
  })
  const { jumpToMessage } = useChannelActionContext()

  useEffect(() => {
    // workaround to deal with the real time changes
    const handleChannelUpdate = (e: any) => {
      updateUpToDateChannel({
        name: getDisplayTitle(channel, chatClient?.user) || "",
        member_count: e.channel.member_count,
      })
    }
    chatClient?.on("channel.updated", handleChannelUpdate)

    return () => {
      chatClient?.off("channel.updated", handleChannelUpdate)
    }
  }, [chatClient, channel.data])

  useEffect(() => {
    if (messageToJumpTo) {
      jumpToMessage(messageToJumpTo).then(() => {
        resetMessageToJumpTo()
      })
    }
  }, [messageToJumpTo])

  if (!channel || !chatClient) return null

  const channelCreatorId = getChannelCreatorId(channel)
  const isCurrentUserGroupCreator = channelCreatorId === chatClient.user?.id
  const isProgramGroup = channel.type === ChannelType.PROGRAM
  const isProgramGroupMember = isProgramGroup && !isCurrentUserGroupCreator
  const isBoxGroupMember = channel.type === ChannelType.BOX && !isCurrentUserGroupCreator
  const isOneOnOneConversation =
    channel && Object.values(channel.state.members).length === 2 && channel.id?.indexOf("!members-") === 0
  const canUserSeeMemberList = isOneOnOneConversation || (!isProgramGroupMember && !isBoxGroupMember)
  const { approximatedFormattedNumber, approximatedNumber } = getApproximatedFormattedNumber(
    upToDateChannel.member_count,
  )

  return (
    <div
      className={
        "str-chat__header-livestream str-chat__channel-header " +
        (!displayChatDetailsOverlay ? "bordertopright12 " : " ") +
        (!displayChannelList ? "bordertopleft12 " : " ")
      }
    >
      {width <= CHANNEL_LIST_SHOWN_WINDOW_WIDTH && (
        <HUButton
          icon={{ iconView: <span className="pi pi-bars" style={{ color: theme.textPrimary }} /> }}
          className="str-chat-button"
          onClick={toggleDisplayChannelList}
          size="XS"
          colorType="Quaternary"
          type="Rounded"
        />
      )}

      <ChatAvatar image={displayImage} name={upToDateChannel.name} shape="rounded" size={40} />
      <div className="str-chat__header-livestream-left str-chat__channel-header-end">
        <p className="str-chat__header-livestream-left--title str-chat__channel-header-title">
          <HUText fontStyle="LL">{upToDateChannel.name}</HUText>
        </p>
        {subtitle && <p className="str-chat__header-livestream-left--subtitle">{subtitle}</p>}
        <p className="str-chat__header-livestream-left--members str-chat__channel-header-info">
          <HUText fontStyle="LM">
            {!!upToDateChannel.member_count && (
              <>
                {`${canUserSeeMemberList ? word("chat.members", { count: upToDateChannel.member_count }) : approximatedFormattedNumber + " " + word("global.member", { count: approximatedNumber })} ${
                  !!watcher_count ? " - " + watcher_count + " " + word("chat.online") : ""
                }`}
              </>
            )}
          </HUText>
        </p>
      </div>
      {width <= DETAILS_OVERLAY_SHOWN_WINDOW_WIDTH && !displayChatDetailsOverlay && (
        <HUButton
          colorType="Quaternary"
          size="XS"
          type="Rounded"
          icon={{ iconView: <span className="pi pi-info-circle" style={{ color: theme.textPrimary }} /> }}
          onClick={toggleDisplayChatDetailsOverlay}
        />
      )}
    </div>
  )
}
