import { word } from "@modules/core/utils/i18n"
import { BoxMemberNextBooking } from "@modules/members/memberTypes"
import { HUText } from "@modules/ui/components/huText"
import { colors } from "@modules/ui/styles/colors"
import dayjs from "dayjs"
import { useTheme } from "styled-components"

export type HuNextBookingCardProps = {
  nextBooking?: BoxMemberNextBooking
} & React.HTMLAttributes<HTMLDivElement>

export const HuNextBookingCard: React.FC<HuNextBookingCardProps> = ({ nextBooking, ...props }) => {
  const theme = useTheme()

  const nextB = nextBooking ? nextBooking : {
    slotId: "",
    name: word("huNextBookingCard.noBooking"),
    date: new Date(),
    color: colors.neutral300
  }
  const formattedDate = dayjs(nextB.date).format('MM-DD-YYYY h:mm a')

  return (
    <div className="relative flex bg-neutral-800 py-3 border-round-right-md w-max pr-4" {...props}>
      <div className="absolute top-0 left-0 h-full" style={{ width: "5px", backgroundColor: `${nextB.color}` }}></div>
      {nextBooking ? (
        <div className="flex flex-column gap-1 ml-3">
          <HUText fontStyle="BM" color={theme.mainWhite} >{nextB.name}</HUText>
          <HUText fontStyle="BS" color={theme.mainWhite} >{formattedDate}</HUText>
        </div>
      ): (
      <div className="ml-3">{nextB.name}</div>
      )}
    </div>
  )
}
