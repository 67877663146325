import { PaginatedStore, Store } from "@betomorrow/micro-stores"
import { ActivityContentType } from "@modules/activity/activityType"
import { AsyncObservable } from "@modules/asyncObservable/asyncObservable"
import { BenchmarkService } from "@modules/benchmark/services/benchmarkService"
import { BoxService } from "@modules/box/services/boxService"
import { Service } from "@modules/core/services/serviceType"
import { ExploreContent, ExploreFilter, ExploreHistoryContent } from "@modules/explore/exploreTypes"
import { ExploreApi } from "@modules/explore/services/exploreApi"
import { UserProfileService } from "@modules/profile/userProfile/services/userProfileService"
import { ProgramService } from "@modules/programs/services/programService"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"
import { moveInArray } from "@modules/utils/objectUtils"

import { Observable, observable } from "micro-observables"

export class ExploreService implements Service {
  private _exploreFilter = observable<ExploreFilter>(ExploreFilter.DEFAULT)
  readonly exploreFilter = this._exploreFilter.readOnly()

  exploreStore = new Store<ExploreContent>(() => ({}) as ExploreContent)

  exploresHistory = new AsyncObservable<ExploreHistoryContent[]>(() => this.getExploreHistory())

  paginatedExploreStore = new Map<ExploreFilter, PaginatedStore<ExploreContent, ExploreContent, "id">>(
    new Map([
      [
        ExploreFilter.DEFAULT,
        new PaginatedStore<ExploreContent, ExploreContent, "id">((page) =>
          this.search(undefined, ExploreFilter.DEFAULT, page),
        ).bind(this.exploreStore),
      ],
    ]),
  )

  constructor(
    private api: ExploreApi,
    private programService: ProgramService,
    private benchmarkService: BenchmarkService,
    private userProfileService: UserProfileService,
    private boxService: BoxService,
    private localStorage: LocalStorageService,
  ) {
    this.exploreStore.presentProperty("benchmark", this.benchmarkService.benchmarkStore)
    this.exploreStore.presentProperty("program", this.programService.programStore)
    this.exploreStore.presentProperty("profile", this.userProfileService.userProfileStore)
    this.exploreStore.presentProperty("box", this.boxService.boxStore)
  }

  getObservableExploreContent(id: string): Observable<ExploreContent | null> {
    return this.exploreStore.getObservable(id)
  }

  async init(): Promise<() => void> {
    const unsubscribe = this.localStorage.connect(
      this.exploresHistory.getObservable(),
      LocalStorageKeys.explore.history,
      [],
    )
    return unsubscribe
  }

  async onLoggedIn() {
    await this.paginatedExploreStore.get(ExploreFilter.DEFAULT)?.list()
  }

  async onLoggedOut(): Promise<void> {
    this.paginatedExploreStore.clear()
    this.exploreStore.clear()
  }

  async search(searchTerms: string | undefined, filter: ExploreFilter, page: number) {
    return this.api.searchExplore(searchTerms, {
      filter: filter === ExploreFilter.DEFAULT ? undefined : filter,
      page,
    })
  }

  setExploreFilter(filter: ExploreFilter) {
    this._exploreFilter.set(filter)
  }

  async getExploreHistory() {
    return this.api.getExploreHistory()
  }

  async addExploreHistory(id: string, activityType: ActivityContentType) {
    const newExploreHistory = await this.api.addExploreHistory(id, activityType)
    if (newExploreHistory) {
      const explores = this.exploresHistory.value.get() ?? []
      const foundIndex = explores.findIndex((explore) => explore.id === newExploreHistory.id)
      if (foundIndex > -1) {
        this.exploresHistory.set([...moveInArray(explores, foundIndex, 0)])
      } else {
        this.exploresHistory.set([newExploreHistory, ...explores])
      }
    }
  }

  async removeExploreHistory(id: string) {
    await this.api.removeExploreHistory(id)
    const explores = this.exploresHistory.value.get() ?? []
    this.exploresHistory.set(explores.filter((explore) => explore.id !== id))
  }

  getPaginatedExploreStore(exploreFilter: ExploreFilter) {
    let paginated = this.paginatedExploreStore.get(exploreFilter)
    if (!paginated) {
      paginated = new PaginatedStore<ExploreContent, ExploreContent, "id">((page) =>
        this.search(undefined, exploreFilter, page),
      ).bind(this.exploreStore)
      this.paginatedExploreStore.set(exploreFilter, paginated)
    }
    return paginated
  }
}
