import { CircleBreakIcon } from "@images/svgIcons/circleBreakIcon"
import { CircleTimeIcon } from "@images/svgIcons/circleTimeIcon"
import { InfinityIcon } from "@images/svgIcons/infinityIcon"
import { SetsIcon } from "@images/svgIcons/setsIcon"
import { HUText } from "@modules/ui/components/huText"
import { useTheme } from "styled-components"

type RecapBlocProps = {
  circleTimeIcon?: boolean
  circleBreakIcon?: boolean
  infinityIcon?: boolean
  setsIcon?: boolean
  value?: string | number
  label: string
}

export const RecapBloc: React.FC<RecapBlocProps> = ({
  circleTimeIcon,
  value,
  label,
  circleBreakIcon,
  setsIcon,
  infinityIcon,
}) => {
  const theme = useTheme()

  return (
    <div className="flex col-6">
      <div className="flex flex-column mt-2">
        <HUText fontStyle="BS" color={theme.neutralColor600}>
          {label}
        </HUText>
        {value !== "" && <HUText fontStyle="TS">{value}</HUText>}
        {infinityIcon && <InfinityIcon color={theme.neutralColor900} height={20} width={20} />}
      </div>
      <div className="mt-1 ml-3">
        {circleTimeIcon && <CircleTimeIcon color={theme.neutralColor900} />}
        {circleBreakIcon && <CircleBreakIcon color={theme.neutralColor900} />}
        {setsIcon && <SetsIcon color={theme.neutralColor900} />}
      </div>
    </div>
  )
}
