import { useServicesContext } from "@modules/core/services/services.context"
import { useAsyncObservable } from "@modules/hooks/useAsyncObservable"

export const useObjectivePrimary = () => {
  const { objectivesService } = useServicesContext()
  const { result, loading, error, fetch } = useAsyncObservable(objectivesService.objectivePrimary)

  const refresh = () => {
    fetch()
  }

  return { result, loading, error, refresh }
}
