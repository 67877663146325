import { BoxProgrammationLightDto } from "@modules/boxProgrammation/services/boxProgrammationDto"
import { BoxSlotParticipation } from "@modules/boxSlot/boxSlotTypes"
import {
  BoxSlotsTemplateWeek,
  BoxSlotsTemplateWeekPartial,
  BoxSlotTemplate,
  BoxSlotTemplateDraft,
  ItemBoxSlotTemplate,
  Weekday,
} from "@modules/boxSlotTemplate/boxSlotTemplateTypes"

type ItemBoxSlotBaseDto = {
  weekDay: string
  startTime: string
  endTime: string
  participantLimit: number | null
  location?: string
  participation?: BoxSlotParticipation
  program: BoxProgrammationLightDto
}

export type ItemBoxSlotTemplateDto = {
  id: string
} & ItemBoxSlotBaseDto

export type BoxSlotTemplateDto = {
  id: string
} & ItemBoxSlotBaseDto

export type BoxSlotTemplateWeekDto = {
  week: {
    MONDAY?: ItemBoxSlotTemplateDto[]
    TUESDAY?: ItemBoxSlotTemplateDto[]
    WEDNESDAY?: ItemBoxSlotTemplateDto[]
    THURSDAY?: ItemBoxSlotTemplateDto[]
    FRIDAY?: ItemBoxSlotTemplateDto[]
    SATURDAY?: ItemBoxSlotTemplateDto[]
    SUNDAY?: ItemBoxSlotTemplateDto[]
  }
}

export type BoxSlotTemplateDraftDto = ItemBoxSlotBaseDto

function convertItemBoxSlotBaseFromDto(dto: ItemBoxSlotBaseDto, weekDay?: Weekday): ItemBoxSlotBaseDto {
  return {
    weekDay: weekDay ?? dto.weekDay,
    program: dto.program,
    startTime: dto.startTime,
    endTime: dto.endTime,
    participantLimit: dto.participantLimit,
    location: dto.location,
    participation: {
      limitStart: dto.participation?.limitStart,
      limitEnd: dto.participation?.limitEnd,
      limitCancel: dto.participation?.limitCancel,
    },
  }
}

export function convertItemBoxSlotTemplateFromDto(dto: ItemBoxSlotTemplateDto, weekDay?: Weekday): ItemBoxSlotTemplate {
  return {
    ...convertItemBoxSlotBaseFromDto(dto, weekDay),
    id: dto.id,
  }
}

export function convertBoxSlotTemplateFromDto(boxProgrammationId: string, dto: BoxSlotTemplateDto): BoxSlotTemplate {
  return {
    ...convertItemBoxSlotBaseFromDto(dto),
    boxProgrammationId,
    id: dto.id,
  }
}

export function convertBoxSlotsTemplateListFromDto(
  boxProgrammationId: string,
  dto: BoxSlotTemplateDto[],
): BoxSlotTemplate[] {
  return dto.map((item) => ({
    ...convertItemBoxSlotBaseFromDto(item),
    boxProgrammationId,
    id: item.id,
  }))
}

export function convertPartialBoxSlotTemplateDraftToDto(
  draft: Partial<BoxSlotTemplateDraft>,
): Partial<BoxSlotTemplateDto> {
  return {
    weekDay: draft.weekDay,
    startTime: draft.startTime,
    endTime: draft.endTime,
    participantLimit: draft.participantLimit,
    location: draft.location,
    participation: {
      limitStart: draft.participation?.limitStart,
      limitEnd: draft.participation?.limitEnd,
      limitCancel: draft.participation?.limitCancel,
    },
  }
}

const GetBoxSlotTemplates = (
  weekDay: Weekday,
  items: ItemBoxSlotTemplateDto[],
  boxProgrammationId?: string,
): BoxSlotTemplate[] => {
  return items.map((boxSlotTemplateDto) => {
    const itemBoxSlotBase = convertItemBoxSlotTemplateFromDto(boxSlotTemplateDto, weekDay)
    if (boxProgrammationId) {
      const boxSlot: BoxSlotTemplate = {
        boxProgrammationId,
        ...itemBoxSlotBase,
      }
      return boxSlot
    } else
      return {
        boxProgrammationId: boxSlotTemplateDto.program.id,
        ...itemBoxSlotBase,
      }
  })
}

export function convertBoxSlotTemplateWeekFromDto(
  boxProgrammationId: string,
  dto: BoxSlotTemplateWeekDto,
): BoxSlotsTemplateWeek {
  return {
    id: boxProgrammationId,
    week: {
      MONDAY: GetBoxSlotTemplates("MONDAY", dto.week.MONDAY ?? [], boxProgrammationId),
      TUESDAY: GetBoxSlotTemplates("TUESDAY", dto.week.TUESDAY ?? [], boxProgrammationId),
      WEDNESDAY: GetBoxSlotTemplates("WEDNESDAY", dto.week.WEDNESDAY ?? [], boxProgrammationId),
      THURSDAY: GetBoxSlotTemplates("THURSDAY", dto.week.THURSDAY ?? [], boxProgrammationId),
      FRIDAY: GetBoxSlotTemplates("FRIDAY", dto.week.FRIDAY ?? [], boxProgrammationId),
      SATURDAY: GetBoxSlotTemplates("SATURDAY", dto.week.SATURDAY ?? [], boxProgrammationId),
      SUNDAY: GetBoxSlotTemplates("SUNDAY", dto.week.SUNDAY ?? [], boxProgrammationId),
    },
  }
}

export function convertAllBoxSlotTemplateWeekFromDto(dto: BoxSlotTemplateWeekDto): BoxSlotsTemplateWeekPartial {
  return {
    week: {
      MONDAY: GetBoxSlotTemplates("MONDAY", dto.week.MONDAY ?? []),
      TUESDAY: GetBoxSlotTemplates("TUESDAY", dto.week.TUESDAY ?? []),
      WEDNESDAY: GetBoxSlotTemplates("WEDNESDAY", dto.week.WEDNESDAY ?? []),
      THURSDAY: GetBoxSlotTemplates("THURSDAY", dto.week.THURSDAY ?? []),
      FRIDAY: GetBoxSlotTemplates("FRIDAY", dto.week.FRIDAY ?? []),
      SATURDAY: GetBoxSlotTemplates("SATURDAY", dto.week.SATURDAY ?? []),
      SUNDAY: GetBoxSlotTemplates("SUNDAY", dto.week.SUNDAY ?? []),
    },
  }
}
