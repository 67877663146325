import { InformationAlertIcon } from "@images/svgIcons/informationAlertIcon"
import {
  BoxSlotTemplateGroupFormikType,
  BoxSlotTemplateGroupInputKey,
} from "@modules/boxSlotTemplate/boxSlotTemplateGroupFormikTypes"
import { Weekday } from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import { DailySlotTemplates } from "@modules/boxSlotTemplate/components/dailySlotTemplates"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage } from "@modules/formik/formikUtils"
import { HUButton } from "@modules/ui/components/huButton"
import { HUCardAlert } from "@modules/ui/components/huCardAlert"
import { HUCheckbox } from "@modules/ui/components/huCheckbox"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { useLocaleWeekDays } from "@modules/utils/hooks/useWeekdays"
import { SimpleFormik } from "@src/typings/formik"
import { useState } from "react"
import { useTheme } from "styled-components"

export const BoxSlotTemplateGroupScheduleForm: React.FC<{
  formik: SimpleFormik<BoxSlotTemplateGroupFormikType, BoxSlotTemplateGroupInputKey>
  moveToSettingsStep: () => void
  submitForm: () => void
  isSubmitting: boolean
}> = ({ formik, moveToSettingsStep, submitForm, isSubmitting }) => {
  const theme = useTheme()
  const localeWeekdays = useLocaleWeekDays()
  const [displayCopyPasteDialog, setDisplayCopyPasteDialog] = useState<boolean>(false)
  const [isSubmittingDialog, setIsSubmittingDialog] = useState<boolean>(true)
  const [selectedDay, setSelectedDay] = useState<Weekday | null>(null)
  const daysOfWeek = localeWeekdays.map((weekdays) => ({
    name: weekdays.value,
    checked: false,
  }))

  const [days, setDays] = useState(daysOfWeek)

  const handleCheckboxCopyPaste = (index: number) => {
    const newDays = [...days]
    newDays[index].checked = !newDays[index].checked

    const atLeastOneChecked = newDays.some((day) => day.checked)
    setIsSubmittingDialog(!atLeastOneChecked)
    setDays(newDays)
  }

  const copyRange = () => {
    if (selectedDay === null) return

    const selectedSlotTemplates = formik.values.slotTemplatesRanges.filter((range) => range.weekDay === selectedDay)

    const currentMaxIndex = formik.values.slotTemplatesRanges.reduce(
      (max, range) => (range.index > max ? range.index : max),
      -1,
    )

    let newIndex = currentMaxIndex + 1
    const newSlotTemplates = selectedSlotTemplates.flatMap((template) =>
      days
        .filter((day) => day.checked)
        .map((day) => ({
          ...template,
          weekDay: day.name,
          index: newIndex++,
        })),
    )

    const updatedSlotTemplatesRanges = [...formik.values.slotTemplatesRanges, ...newSlotTemplates]
    const sortedSlotTemplatesRanges = updatedSlotTemplatesRanges.sort(
      (a, b) => Number(a.startTime) - Number(b.startTime),
    )
    formik.setFieldValue("slotTemplatesRanges", sortedSlotTemplatesRanges)
  }

  const saveDialogCopyPaste = (): void => {
    copyRange()
    onHideDialogCopyPaste()
  }

  const onHideDialogCopyPaste = (): void => {
    const resetDays = days.map((day) => ({ ...day, checked: false }))
    setDays(resetDays)
    setIsSubmittingDialog(true)
    setDisplayCopyPasteDialog(false)
  }

  return (
    <>
      <HUStack gap={10}>
        <HUText fontStyle="TM">{word("box.slotTemplateGroup.form.schedule")}</HUText>
        <HUCardAlert
          title={""}
          description={word("box.slotTemplateGroup.form.scheduleExplanation")}
          icon={<InformationAlertIcon backgroundColor={theme.primaryColor100} color={theme.primaryColor600} />}
        />
      </HUStack>

      <HUStack gap={10}>
        <HUText type="label" fontStyle="LS" style={{ lineHeight: "21px", marginBottom: 7 }}>
          {word("box.slotTemplate.form.recurrence").toUpperCase()}
          <HUText fontStyle="LL" color={theme.necessaryInput}>
            {" *"}
          </HUText>
        </HUText>

        {localeWeekdays.map((weekday, weekdayIndex) => (
          <DailySlotTemplates
            key={weekdayIndex}
            weekday={weekday.value as Weekday}
            formik={formik}
            setDisplayCopyPasteDialog={setDisplayCopyPasteDialog}
            setSelectedDay={setSelectedDay}
          />
        ))}
        {getFormErrorMessage("slotTemplatesRanges", formik)}
      </HUStack>

      <div className="flex justify-content-end mt-3">
        <div className="flex column gap-3 sm:flex-row flex-grow-1">
          <HUButton
            type={"Left_Icon"}
            icon={{
              iconView: <span className={`p-button-icon p-c pi pi-arrow-left`} style={{ color: theme.mainWhite }} />,
            }}
            size="M"
            colorType="Quaternary"
            text={word("global.previous")}
            onClick={moveToSettingsStep}
            className="w-full"
          />
          <HUButton
            type="Default"
            size="M"
            colorType="Primary"
            text={word("box.slot.form.submit.button")}
            className="mr-2 w-full"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={submitForm}
          />
        </div>
      </div>
      <HUDialog
        style={{ width: "500px" }}
        visible={displayCopyPasteDialog}
        header={word("box.slotTemplate.form.dialogTitle.copyPaste")}
        onHide={() => onHideDialogCopyPaste()}
      >
        <HUStack gap={10} className="ml-2 capitalize mb-4 mt-2">
          {localeWeekdays.map((weekday, weekdayIndex) => (
            <HUCheckbox
              key={weekdayIndex}
              inputKey={weekdayIndex.toString()}
              name="option"
              label={weekday.label}
              value={weekday.label}
              checked={weekday.value == selectedDay ? true : days[weekdayIndex].checked}
              onChange={() => handleCheckboxCopyPaste(weekdayIndex)}
              color={weekday.value == selectedDay ? theme.primaryColor300 : theme.primaryColor}
              checkIconColor={theme.neutralColor700}
              disabled={weekday.value === selectedDay ? true : false}
            />
          ))}
        </HUStack>
        <HUButton
          size="L"
          type="Left_Icon"
          colorType="Primary"
          text={word("global.check")}
          disabled={isSubmittingDialog}
          onClick={() => saveDialogCopyPaste()}
          style={{ width: "100%" }}
        />
      </HUDialog>
    </>
  )
}
