import OnboardingPayment from "@images/payment/onboardingPayment.svg"
import OnboardingSuccess from "@images/payment/onboardingSuccess.svg"
import { SmallArrowIcon } from "@images/svgIcons/arrowIcon"
import { Box } from "@modules/box/boxTypes"
import { word } from "@modules/core/utils/i18n"
import { useStripeAccount } from "@modules/finances/hooks/useStripeAccount"
import { StripeAccountStatus } from "@modules/payment/stripeTypes"
import { AdvantagesView } from "@modules/ui/components/advantagesView"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"

import { Button } from "primereact/button"
import React from "react"
import styled, { useTheme } from "styled-components"

type BoxBankingProps = {
  box: Box
}

export const BoxBanking: React.FC<BoxBankingProps> = ({ box }) => {
  const { createStripeAccount, updateStripeAccount, loading: actionLoading } = useStripeAccount()
  const theme = useTheme()

  const { title, description, textButton, iconButton } = getScreenWording(box.stripeAccountStatus)
  const advantages = [
    word("box.settings.informations.line_1"),
    word("box.settings.informations.line_2"),
    word("box.settings.informations.line_3"),
    word("box.settings.informations.line_4"),
  ]

  return (
    <div>
      <Content>
        <CardAccount>
          <HUStack gap={38} align>
            <img
              style={{ marginBottom: box.stripeAccountStatus === "OK" ? -28 : 0 }}
              src={box.stripeAccountStatus === "OK" ? OnboardingSuccess : OnboardingPayment}
              alt=""
            />
            <DescriptionStack gap={16} align>
              <HUText fontStyle="HL">{title}</HUText>
              <HUText fontStyle="BM">{description}</HUText>
            </DescriptionStack>

            <Button
              onClick={async () => {
                if (!box.stripeAccountStatus || box.stripeAccountStatus === "UNDEFINED") {
                  createStripeAccount()
                } else {
                  updateStripeAccount()
                }
              }}
              type="button"
              label={textButton}
              className="p-button-primary"
              loading={actionLoading}
            >
              {iconButton && <SmallArrowIcon className="ml-2" color={theme.mainWhite} />}
            </Button>
          </HUStack>
        </CardAccount>

        <AdvantagesViewStyled
          fontSizeBig
          title={word("box.settings.informations.title")}
          description={word("box.settings.informations.description")}
          subtitle={word("box.settings.informations.subtitle")}
          advantages={advantages}
        />
      </Content>
    </div>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
  flex: 1;
  border-radius: 12px;
`

const AdvantagesViewStyled = styled(AdvantagesView)`
  padding: 60px 40px;
  flex: 1;
  border-radius: 12px;
`

const CardAccount = styled(Card)`
  background-color: ${({ theme }) => theme.background};
  align-items: center;
  justify-content: center;
  > div {
    width: 100%;
  }
  img {
    max-width: 80%;
  }
`

const DescriptionStack = styled(HUStack)`
  max-width: 90%;
  text-align: center;
`

const getScreenWording = (
  status: StripeAccountStatus | undefined,
): { title: string; description: string; textButton: string; iconButton: boolean } => {
  if (!status || status === "UNDEFINED") {
    return {
      title: word("box.settings.account.undefined.title"),
      description: word("box.settings.account.undefined.description"),
      textButton: word("box.settings.button.start.label"),
      iconButton: true,
    }
  }
  if (status === "REQUIRE_ADDITIONAL_INFORMATION") {
    return {
      title: word("box.settings.account.need_information.title"),
      description: word("box.settings.account.need_information.description"),
      textButton: word("box.settings.button.start.label"),
      iconButton: true,
    }
  }
  if (status === "OK") {
    return {
      title: word("box.settings.account.ok.title"),
      description: word("box.settings.account.ok.description"),
      textButton: word("box.settings.button.update.label"),
      iconButton: false,
    }
  }
  return {
    title: "",
    description: "",
    textButton: "",
    iconButton: false,
  }
}
