import { AppTopBar } from "@modules/app/components/appTopBar"
import { LayoutContext } from "@modules/app/context/layoutcontext"
import { MenuButtonsRef } from "@modules/app/types/layout"
import { useCurrentBox } from "@modules/box/useBox"
import { word } from "@modules/core/utils/i18n"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { Path } from "@modules/navigation/routes"
import { RouteNavigate } from "@modules/navigation/useBaseNavigate"
import { getBoxPath } from "@modules/navigation/useBoxNavigate"
import { getMySpacePath } from "@modules/navigation/useMySpaceNavigate"
import React, { useContext, useEffect, useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"

type PathInfos = {
  label: string
  route: string
  isRoot?: boolean
  isChildOf?: string
}
type BreadCrumbProps = {
  topBarRef: React.RefObject<MenuButtonsRef>
}

export const AppBreadcrumb: React.FC<BreadCrumbProps> = ({ topBarRef }) => {
  const location = useLocation()
  const params = useParams()
  const { result: currentBox } = useCurrentBox()
  const language = useLanguage()

  const { setBreadcrumb } = useContext(LayoutContext)

  const allPaths: PathInfos[] = useMemo(
    () => [
      {
        label: word("menu.dashboard"),
        route: getMySpacePath(RouteNavigate.Root),
        isRoot: true,
      },
      {
        label: word("menu.training"),
        route: getMySpacePath(Path.MySpace.Training),
        isRoot: true,
      },
      {
        label: word("menu.programs"),
        route: getMySpacePath(Path.MySpace.Training),
      },
      {
        label: word("menu.explorer"),
        route: getMySpacePath(Path.MySpace.Explore),
        isRoot: true,
      },
      {
        label: word("menu.profile.profile.label"),
        route: getMySpacePath(Path.MySpace.Profile),
        isRoot: true,
      },
      {
        label: word("menu.program.detail"),
        route: getMySpacePath(Path.MySpace.ProgramDetails(":programId")),
        isChildOf: getMySpacePath(Path.MySpace.Training),
      },
      {
        label: word("menu.programOnOff"),
        route: getMySpacePath(Path.MySpace.ProgramOnOffDetails(":programId")),
        isChildOf: getMySpacePath(Path.MySpace.Training),
      },
      {
        label: word("menu.session.detail"),
        route: getMySpacePath(Path.MySpace.SessionDetails(":sessionId")),
      },
      {
        label: word("menu.session.creation"),
        route: getMySpacePath(Path.MySpace.SessionCreationForm),
      },
      {
        label: word("menu.session.edition"),
        route: getMySpacePath(Path.MySpace.SessionEditionForm(":sessionId")),
      },
      {
        label: word("menu.profile.add_box.label"),
        route: getMySpacePath(Path.CreateBox),
        isRoot: true,
      },
      {
        label: word("menu.chat"),
        route: getMySpacePath(Path.Chat.Root),
        isRoot: true,
      },
      {
        label: word("menu.transactions"),
        route: getMySpacePath(Path.MySpace.Finances),
        isRoot: true,
      },
      {
        label: word("menu.tutorials"),
        route: getMySpacePath(Path.MySpace.Tutorials),
        isRoot: true,
      },
      {
        label: word("menu.box"),
        route: getBoxPath(RouteNavigate.Root, { newBoxId: ":boxId" }),
        isRoot: true,
      },
      {
        label: word("menu.finances"),
        route: getBoxPath(Path.Box.Finances, { newBoxId: ":boxId" }),
        isRoot: true,
      },
      {
        label: word("menu.profile.profile.label"),
        route: getBoxPath(Path.Box.Profile, { newBoxId: ":boxId" }),
        isRoot: true,
      },
      {
        label: word("menu.members"),
        route: getBoxPath(Path.Box.Members, { newBoxId: ":boxId" }),
        isRoot: true,
      },
      {
        label: word("menu.member.detail"),
        route: getBoxPath(Path.Box.MemberForm(":id"), { newBoxId: ":boxId" }),
      },
      {
        label: word("menu.offers"),
        route: getBoxPath(Path.Box.Offers, { newBoxId: ":boxId" }),
        isRoot: true,
      },
      {
        label: word("menu.planning"),
        route: getBoxPath(Path.Box.Planning, { newBoxId: ":boxId" }),
        isRoot: true,
      },
      {
        label: word("menu.programmation.detail"),
        route: getBoxPath(Path.Box.ProgrammationDetails(":boxProgrammationId"), { newBoxId: ":boxId" }),
        isChildOf: getBoxPath(Path.Box.Planning, { newBoxId: currentBox?.id ?? ":boxId" }),
      },
      {
        label: word("box.programmation.activity.typicalWeek"),
        route: getBoxPath(Path.Box.ProgrammationTemplate(":boxProgrammationId"), { newBoxId: ":boxId" }),
        isChildOf: getBoxPath(Path.Box.Planning, { newBoxId: currentBox?.id ?? ":boxId" }),
      },
      {
        label: word("box.programmation.activity.viewProgrammation"),
        route: getBoxPath(Path.Box.ProgrammationSessions(":boxProgrammationId"), { newBoxId: ":boxId" }),
        isChildOf: getBoxPath(Path.Box.Planning, { newBoxId: currentBox?.id ?? ":boxId" }),
      },
      {
        label: word("menu.admin"),
        route: getBoxPath(Path.Box.Admin, { newBoxId: ":boxId" }),
        isRoot: true,
      },
      {
        label: word("menu.slot.detail"),
        route: getBoxPath(Path.Box.SlotDetails(":slotId"), { newBoxId: ":boxId" }),
      },
      {
        label: word("menu.slot.edition"),
        route: getBoxPath(Path.Box.SlotEditionForm(":slotId"), { newBoxId: ":boxId" }),
      },
      {
        label: word("menu.slot.creation"),
        route: getBoxPath(Path.Box.SlotCreationForm, { newBoxId: ":boxId" }),
      },
      {
        label: word("menu.profile.add_box.label"),
        route: getBoxPath(Path.CreateBox, { newBoxId: ":boxId" }),
        isRoot: true,
      },
      {
        label: word("menu.chat"),
        route: getBoxPath(Path.Chat.Root, { newBoxId: ":boxId" }),
        isRoot: true,
      },
    ],
    [currentBox?.id, language],
  )

  useEffect(() => {
    const buildRouteWithPath = (path: string) => {
      return Object.keys(params).reduce((accPathname, param) => {
        return accPathname.replace(`${params[param]}`, `:${param}`)
      }, path)
    }

    const currentRoute = buildRouteWithPath(location.pathname)
    const newPath = allPaths.find((path) => path.route === currentRoute)

    if (newPath) {
      if (newPath.isChildOf) {
        setBreadcrumb({ to: newPath.isChildOf })
      } else {
        setBreadcrumb({ to: location.pathname })
      }
    }
  }, [allPaths, location.pathname, params])

  return (
    <div className="topbar-breadcrumb">
      <nav className="layout-breadcrumb">
        <AppTopBar ref={topBarRef} />
      </nav>
    </div>
  )
}
