import { getValidationSchema } from "@modules/boxOffer/boxOfferFormikTypes"
import { BoxOffer, BoxOfferDraft, BoxOfferType, convertBoxOfferToDraft } from "@modules/boxOffer/boxOfferTypes"
import { BoxOfferFormFirstPage } from "@modules/boxOffer/components/boxOfferFormFirstPage"
import { BoxOfferFormSecondPage } from "@modules/boxOffer/components/boxOfferFormSecondPage"
import { BoxOfferResumeView } from "@modules/boxOffer/components/boxOfferResumeView"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { useToast } from "@modules/ui/components/huToast"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useDraft } from "@modules/utils/useDraft"
import { useFormik } from "formik"
import React, { useCallback, useEffect, useMemo, useState } from "react"

type Props = {
  offer?: BoxOffer
  selectedType?: BoxOfferType
} & React.HTMLAttributes<HTMLDivElement>

export const BoxOfferForm: React.FC<Props> = ({ offer, selectedType }) => {
  const { offerService } = useServicesContext()
  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const { draft: boxOfferDraft } = useDraft(() => convertBoxOfferToDraft(offer, selectedType))
  const toast = useToast()
  const boxNavigate = useBoxNavigate()
  const language = useLanguage()

  const formik = useFormik<BoxOfferDraft>({
    initialValues: boxOfferDraft,
    validationSchema: getValidationSchema(language, pageNumber),
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        if (offer) {
          await offerService.update(offer.id, values)
          toast.show({
            severity: "success",
            summary: word("offer.form.update.success"),
          })
        } else {
          await offerService.create(values)
          toast.show({
            severity: "success",
            summary: word("offer.form.create.success"),
          })
        }
        boxNavigate(Path.Box.Offers)
      } catch (e) {
        console.error(LoggerType.Box + "create/update offer", e)
        toast.show({ severity: "error", summary: word("global.error.label") })
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    if (pageNumber) {
      document.getElementById("sidebar-content")?.scrollTo({ top: 0 })
    }
  }, [pageNumber])

  //Workaround for this issue: https://github.com/jaredpalmer/formik/issues/3151
  const nextPage = useCallback(async () => {
    if ([1, 2].includes(pageNumber)) {
      await formik.validateForm().then((value) => {
        if (Object.values(value).length === 0) {
          setPageNumber((prevState) => prevState + 1)
        } else {
          Object.keys(value).map((key) => formik.setFieldTouched(key))
        }
      })
    }
  }, [formik, pageNumber])

  const formProps = useMemo(
    () => ({
      formik: {
        values: formik.values,
        getFieldProps: formik.getFieldProps,
        setFieldValue: formik.setFieldValue,
        validateField: formik.validateField,
      },
      handleSubmit: nextPage,
      setPageNumber,
      isFormFieldInValid: (input: keyof BoxOfferDraft) => isFormFieldInValid(input, formik),
      getFormErrorMessage: (input: keyof BoxOfferDraft, errorMessage?: string) =>
        getFormErrorMessage(input, formik, { errorMessage }),
    }),
    [formik, nextPage],
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      {pageNumber === 1 && <BoxOfferFormFirstPage {...formProps} />}
      {pageNumber === 2 && <BoxOfferFormSecondPage {...formProps} />}
      {pageNumber === 3 && (
        <BoxOfferResumeView
          formOffer={formik.values}
          leftButtonAction={() => setPageNumber((prevState) => prevState - 1)}
          rightButtonAction={formik.handleSubmit}
          loading={loading}
        />
      )}
    </form>
  )
}
