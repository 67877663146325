import { PayinIcon } from "@images/svgIcons/payinIcon"
import { PayoutIcon } from "@images/svgIcons/payoutIcon"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { defaultMonthValue } from "@modules/finances/components/defaultMonth"
import { FinanceCard } from "@modules/finances/components/financeCard"
import { LastUpdateComponent } from "@modules/finances/components/lastUpdateInformation"
import { StripeStatusBanner } from "@modules/finances/components/stripeStatusBanner"
import { UserTransactionsTable } from "@modules/finances/components/userTransactionsTable"
import { usePeriodParam } from "@modules/finances/hooks/usePeriod"
import { useStripeAccount } from "@modules/finances/hooks/useStripeAccount"
import { useAsync } from "@modules/hooks/useAsync"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { usePrograms } from "@modules/programs/hooks/usePrograms"
import { usePromoCodeSidebar } from "@modules/promoCode/hooks/usePromoCodeSidebar"
import { ScreenHeader } from "@modules/ui/components/header"
import { useToast } from "@modules/ui/components/huToast"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { isInArray } from "@modules/utils/isInArray"
import { useQueryParam } from "@modules/utils/navigation/useQueryParam"
import { delay } from "@modules/utils/utils"
import { useObservable } from "micro-observables"
import { TabMenu } from "primereact/tabmenu"
import React, { ReactElement } from "react"
import styled, { useTheme } from "styled-components"

const allUserFinanceFilters = ["incomings", "outgoings"] as const
type UserFinanceFilter = (typeof allUserFinanceFilters)[number]
type UserFinanceTab = {
  label: string
  command: () => void
  filter: UserFinanceFilter // TODO
  icon?: ReactElement
}

export const UserFinancesPage: React.FC = () => {
  const { userFinanceService } = useServicesContext()
  const [userFinanceTab, setUserFinanceTab] = useQueryParam("tab")
  const selectedTab = isInArray(allUserFinanceFilters, userFinanceTab) ? userFinanceTab : "incomings"
  const language = useLanguage()
  const theme = useTheme()
  const {
    createStripeAccount,
    updateStripeAccount,
    loading: actionLoading,
    useStripeAccountStatus,
  } = useStripeAccount()

  const [period] = usePeriodParam()
  const lastUpdateLoading = useObservable(userFinanceService.lastUpdateLoading)
  const { loading: stripeAccountStatusLoading, stripeAccountStatus } = useStripeAccountStatus()
  const { navigateToPromoCodeListView } = usePromoCodeSidebar()
  const { result: programs } = usePrograms({ programFilter: "OWNED", onlyWithPrice: true, size: 1 })
  const toast = useToast()

  const isStripeAccountComplete = stripeAccountStatus === "OK"
  const hasOutgoingTransactions = false // TODO

  const financeTabs: UserFinanceTab[] = [
    {
      label: word("finances.user.filter.incomings"),
      command: () => setUserFinanceTab("incomings"),
      filter: "incomings",
      icon: <PayinIcon color={selectedTab === "incomings" ? theme.textLink : theme.textTertiary} />,
    },
  ]
  if (hasOutgoingTransactions)
    financeTabs.push({
      label: word("finances.user.filter.outgoings"),
      command: () => setUserFinanceTab("outgoings"),
      filter: "outgoings",
      icon: <PayoutIcon color={selectedTab === "outgoings" ? theme.textLink : theme.textTertiary} />,
    })

  const {
    value: stats,
    loading,
    refreshing,
    refresh,
  } = useAsync(async () => {
    if (isStripeAccountComplete) {
      const [result] = await Promise.all([userFinanceService.getStats(period ?? defaultMonthValue), delay(1000)]) // TODO : ajouter le filtre de tab
      return result
    }
  }, [period])
  const cardLoading = lastUpdateLoading || loading || stripeAccountStatusLoading
  const activeIndex = financeTabs.findIndex((o) => o.filter === selectedTab)

  const openStripeAccount = () => {
    if (isStripeAccountComplete) updateStripeAccount()
    else createStripeAccount()
  }

  const openPromoCodeSidebar = () => {
    if (programs.length > 0) navigateToPromoCodeListView()
    else toast.show({ detail: word("promoCode.toast.missingProgram"), severity: "warn" })
  }

  if (!isStripeAccountComplete)
    return (
      <div className="menu.finances flex flex-column flex-grow-1">
        <ScreenHeader title={word("menu.finances")} />
        <HUStack gap={28}>
          <StripeStatusBanner />
        </HUStack>
      </div>
    )
  else
    return (
      <div className="menu.finances flex flex-column flex-grow-1" style={{ width: "100%" }}>
        <ScreenHeader title={word("menu.finances")}>
          <LastUpdateComponent refreshing={refreshing} onRefresh={refresh} financeService={userFinanceService} />
        </ScreenHeader>
        <HUStack gap={28} className="flex-grow-1 min-w-min">
          {financeTabs.length > 1 && <TabMenuStyled model={financeTabs} activeIndex={activeIndex} />}
          <HURow gap={28}>
            <FinanceCard
              cardType="Primary"
              cardTitle={word("finances.page.card.ca.label")}
              value={
                stats ? formatCurrency(stats.totalTransactionsValue, stats.totalTransactionsCurrency, language) : "--"
              }
              loading={cardLoading}
              style={{ width: "45%" }}
            />
            <FinanceCard
              cardType="Secondary"
              cardTitle={word("finances.page.card.promoCodes.label")}
              cardSubtitle={word("finances.page.card.promoCodes.description")}
              value={false}
              loading={cardLoading}
              buttonLabel={word("finances.page.card.promoCodes.button")}
              isButtonLoading={false}
              onButtonClick={openPromoCodeSidebar}
              style={{ gap: 20, flex: 1 }}
            />
            <FinanceCard
              cardType="Secondary"
              cardTitle={word("finances.page.card.myPaymentInformation")}
              loading={cardLoading}
              value={false}
              icon="pi pi-wallet"
              buttonLabel={word("finances.page.card.seeMyPaymentInformation")}
              buttonIcon="pi pi-arrow-right"
              isButtonLoading={actionLoading}
              onButtonClick={openStripeAccount}
              style={{ flex: 1, justifyContent: "space-between" }}
            />
          </HURow>
          <UserTransactionsTable />
        </HUStack>
      </div>
    )
}

const TabMenuStyled = styled(TabMenu)`
  .p-tabmenu-nav {
    background-color: transparent;
    & > a {
      flex-direction: row-reverse;
    }
  }
  .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background-color: transparent;
  }
  .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background-color: transparent;
  }
`
