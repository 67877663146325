import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const CopyIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.4063 4.12821L7.43604 0.157949C7.33515 0.0569387 7.19828 0.000125678 7.05552 0H5.28219C4.75856 0 4.25637 0.208012 3.88611 0.578276C3.51584 0.948541 3.30783 1.45073 3.30783 1.97436V2.87179H2.41039C1.88676 2.87179 1.38458 3.07981 1.01431 3.45007C0.644047 3.82034 0.436035 4.32252 0.436035 4.84615V12.0256C0.436035 12.5493 0.644047 13.0515 1.01431 13.4217C1.38458 13.792 1.88676 14 2.41039 14H7.43604C7.95967 14 8.46185 13.792 8.83212 13.4217C9.20238 13.0515 9.41039 12.5493 9.41039 12.0256V11.1282H9.58988C10.1135 11.1282 10.6157 10.9202 10.986 10.5499C11.3562 10.1797 11.5642 9.67748 11.5642 9.15385V4.48718C11.5586 4.35192 11.5022 4.22375 11.4063 4.12821ZM7.61552 1.83795L9.72629 3.94872H7.61552V1.83795ZM8.33347 12.0256C8.33347 12.2637 8.23892 12.4919 8.07062 12.6602C7.90232 12.8285 7.67405 12.9231 7.43604 12.9231H2.41039C2.17238 12.9231 1.94411 12.8285 1.77581 12.6602C1.60751 12.4919 1.51296 12.2637 1.51296 12.0256V4.84615C1.51296 4.60814 1.60751 4.37987 1.77581 4.21157C1.94411 4.04327 2.17238 3.94872 2.41039 3.94872H3.30783V9.15385C3.30783 9.67748 3.51584 10.1797 3.88611 10.5499C4.25637 10.9202 4.75856 11.1282 5.28219 11.1282H8.33347V12.0256ZM9.58988 10.0513H5.28219C5.04417 10.0513 4.81591 9.95673 4.64761 9.78843C4.4793 9.62013 4.38475 9.39186 4.38475 9.15385V1.97436C4.38475 1.73634 4.4793 1.50808 4.64761 1.33978C4.81591 1.17147 5.04417 1.07692 5.28219 1.07692H6.5386V4.48718C6.54046 4.62941 6.59779 4.76529 6.69837 4.86587C6.79895 4.96645 6.93483 5.02378 7.07706 5.02564H10.4873V9.15385C10.4873 9.39186 10.3928 9.62013 10.2245 9.78843C10.0562 9.95673 9.8279 10.0513 9.58988 10.0513Z"
      fill={props.color}
    />
  </svg>
)
