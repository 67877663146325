import { TransactionTotals } from "@modules/finances/types/transactionTotalsType"

export interface TransactionTotalsDto {
  succeededTransactionsNumber: number
  pendingTransactionsNumber: number
  failedTransactionsNumber: number
  disputedTransactionsNumber: number
}

export function convertTransactionTotalsFromDto(dto: TransactionTotalsDto): TransactionTotals {
  const total =
    dto.succeededTransactionsNumber +
    dto.pendingTransactionsNumber +
    dto.failedTransactionsNumber +
    dto.disputedTransactionsNumber
  return {
    totalTransactionsNumber: total,
    succeededTransactionsNumber: dto.succeededTransactionsNumber,
    pendingTransactionsNumber: dto.pendingTransactionsNumber,
    failedTransactionsNumber: dto.failedTransactionsNumber,
    disputedTransactionsNumber: dto.disputedTransactionsNumber,
  }
}
