import { Config } from "@modules/config"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/react"
import "@src/assets/fonts/font.css"
import { App } from "@src/webApp"
import ReactDOM from "react-dom/client"

import { ServicesContextProvider } from "@modules/core/services/services.context"
import "primeflex/primeflex.css"
import "primeicons/primeicons.css"
import { QueryClient, QueryClientProvider } from "react-query"

if (Config.ENV === "prod") {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.info = () => {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.warn = () => {}
}
export const queryClientForTesting = new QueryClient()

const env = Config.ENV
if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: Config.SENTRY_ENV,
    tracesSampleRate: env === "prod" ? 0.25 : 1.0,
    release: `${__VERSION__}-${__GIT_HASH__}-${env}`,
  })
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  /* <React.StrictMode>*/
  <ServicesContextProvider>
    <QueryClientProvider client={queryClientForTesting}>
      <App />
    </QueryClientProvider>
  </ServicesContextProvider>,
  /*  </React.StrictMode>,*/
)
