import { Store } from "@betomorrow/micro-stores"
import { Benchmark } from "@modules/benchmark/benchmarkTypes"
import { BenchmarkApi } from "@modules/benchmark/services/benchmarkApi"
import { Service } from "@modules/core/services/serviceType"

export class BenchmarkService implements Service {
  benchmarkStore = new Store<Benchmark>((id) => this.api.getBenchmark(id))

  constructor(private api: BenchmarkApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedOut(): Promise<void> {
    this.benchmarkStore.clear()
  }
}
