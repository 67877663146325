import SessionEmptySVG from "@images/empty/session_empty.svg"
import { CheckmarkIcon } from "@images/svgIcons/checkmarkIcon"
import { CrossIcon } from "@images/svgIcons/crossIcon"
import { SearchIcon } from "@images/svgIcons/searchIcon"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import useDebounce from "@modules/hooks/useDebounce"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { Profile } from "@modules/profile/profileTypes"
import { Subscriber } from "@modules/profile/subscriber/subscriberTypes"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import Line from "@modules/ui/components/line"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { InputText } from "primereact/inputtext"
import { ScrollPanel } from "primereact/scrollpanel"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react"
import { DefaultTheme, useTheme } from "styled-components"

export type BoxSessionAddSubscriberModalRef = {
  open: () => void
}

type Props = {
  slotId: string
  setSlotSubscribers: React.Dispatch<React.SetStateAction<Subscriber[]>>
}

export const BoxSessionAddSubscriberModal = forwardRef<BoxSessionAddSubscriberModalRef, Props>(
  ({ slotId, setSlotSubscribers }, ref) => {
    const theme = useTheme()
    const toast = useToast()
    const [showModal, setShowModal] = useState(false)
    const { boxSlotService } = useServicesContext()
    const [selectedMembers, setSelectedMembers] = useState<Profile[]>([])
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [nonParticipants, setNonParticipants] = useState<Profile[]>([])
    const debouncedSearchTerm = useDebounce(searchTerm, 300)

    const addMember = async () => {
      setIsSubmitting(true)
      try {
        for (const member of selectedMembers) {
          await boxSlotService.addParticipantToSlot(slotId, member.id)
        }
        const subscribers = await boxSlotService.getSlotSubscribers(slotId)
        setSlotSubscribers(subscribers.content)
        onHide()
      } catch (e: any) {
        toast.show({
          severity: "error",
          summary: "Error",
          detail: e.message,
        })
      } finally {
        setIsSubmitting(false)
      }
    }

    const getNonParticipants = async (slotId: string, username?: string) => {
      const result = await boxSlotService.getSlotNonParticipants(slotId, username)
      const filteredResult = result.content.filter(
        (nonParticipant) => !selectedMembers.some((selected) => selected.id === nonParticipant.id),
      )
      setNonParticipants(filteredResult)
    }

    const filteredResult = useMemo(() => {
      if (!searchTerm) {
        return nonParticipants
      }
      return nonParticipants.filter((profile) => {
        const searchTermLower = searchTerm.toLowerCase()
        return (
          profile.firstname.toLowerCase().includes(searchTermLower) ||
          profile.lastname.toLowerCase().includes(searchTermLower) ||
          profile.username.toLowerCase().includes(searchTermLower)
        )
      })
    }, [searchTerm, nonParticipants])

    useEffect(() => {
      if (debouncedSearchTerm) {
        void getNonParticipants(slotId, debouncedSearchTerm)
      }
    }, [debouncedSearchTerm])

    const onHide = useCallback(() => {
      setSearchTerm("")
      setShowModal(false)
    }, [])

    const handleMemberClick = (member: Profile) => {
      setNonParticipants((prev) => prev.filter((p) => p.id !== member.id))
      setSelectedMembers((prev) => [...prev, member])
      setSearchTerm("")
    }

    const handleSelectedMemberClick = (member: Profile) => {
      setSelectedMembers((prev) => prev.filter((p) => p.id !== member.id))
      setNonParticipants((prev) => [...prev, member])
    }

    const clearSearch = () => {
      setSearchTerm("")
    }

    useImperativeHandle(ref, () => ({
      open: () => {
        setShowModal(true)
        setNonParticipants([])
        setSelectedMembers([])
      },
    }))

    return (
      <HUDialog visible={showModal} header={""} onHide={onHide}>
        <HUStack className="flex-shrink-1">
          <div className="flex flex-column flex-wrap gap-2 justify-content-center align-content-center text-center	">
            <HUText fontStyle="TL" color={theme.mainWhite}>
              {word("boxSessionAddSubscriberModal.dialog.title")}
            </HUText>
            <HUText fontStyle="BM" color={theme.neutralColor600}>
              {word("boxSessionAddSubscriberModal.dialog.subtitle")}
            </HUText>
          </div>
          <div className="flex w-full mb-5">
            <div className=" flex align-items-center w-full h-3rem rounded-full border-1 border-neutral-300">
              <div className="flex align-items-center justify-content-center w-3rem h-full ">
                <SearchIcon color={theme.neutralColor700} width={14} height={14} />
              </div>
              <InputText
                className="w-full h-full p-0 m-0 border-none outline-none"
                placeholder={word("boxSessionAddSubscriberModal.dialog.inputText")}
                value={searchTerm}
                style={{ borderRadius: "30px" }}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
              />
              {searchTerm && (
                <button onClick={clearSearch} className="flex align-items-center justify-content-center w-3rem h-full ">
                  <CrossIcon color={theme.neutralColor700} width={14} height={14} />
                </button>
              )}
            </div>
          </div>
        </HUStack>
        <div className="flex-grow-1">
          {selectedMembers.length === 0 && searchTerm.length === 0 ? (
            emptyTemplate(theme as DefaultTheme)
          ) : searchTerm.length > 0 ? (
            <div>
              <HUText fontStyle="LS" color={theme.neutralColor700} className="flex mt-2 mb-3 uppercase">
                {word("boxSessionAddSubscriberModal.dialog.searchTerm")}
              </HUText>
              {/* IF filteredResult is empty : do empty screen*/}
              {filteredResult.length === 0 ? (
                noResult(theme as DefaultTheme)
              ) : (
                /* Else : do the list of filteredResult */
                <ScrollPanel style={{ height: "210px" }}>
                  {filteredResult.map((member) => itemTemplate(member, theme as DefaultTheme, handleMemberClick))}
                </ScrollPanel>
              )}
            </div>
          ) : (
            <div>
              <HUText fontStyle="LS" color={theme.neutralColor700} className="flex mt-2 mb-3 uppercase">
                {word("boxSessionAddSubscriberModal.dialog.selectedTerm")}
              </HUText>
              <ScrollPanel style={{ height: "210px" }}>
                {selectedMembers.map((member) =>
                  selectedTemplate(member, theme as DefaultTheme, handleSelectedMemberClick),
                )}
              </ScrollPanel>
            </div>
          )}
        </div>

        <div className="flex align-items-end gap-3 mt-4">
          <HUButton
            type="Default"
            size="M"
            colorType="Quaternary"
            text={word("global.cancel")}
            onClick={() => {
              onHide()
            }}
            className="flex-1 capitalize"
          />
          <HUButton
            type="Default"
            disabled={isSubmitting || selectedMembers.length === 0}
            loading={isSubmitting}
            size="M"
            colorType="Primary"
            text={word("boxSessionAddSubscriberModal.dialog.button.addToList")}
            onClick={() => {
              void addMember()
            }}
            className="flex-1"
          />
        </div>
      </HUDialog>
    )
  },
)

const noResult = (theme: DefaultTheme) => {
  return (
    <div className="flex justify-content-center flex-column" style={{ height: "210px" }}>
      <HUText fontStyle="LL" color={theme.neutralColor700} className="flex justify-content-center text-center">
        {word("boxSessionAddSubscriberModal.dialog.noResult")}
      </HUText>
      <div className="flex justify-content-center text-center align-items-center mt-4">
        <img src={SessionEmptySVG} alt="SessionEmptySVG" />
      </div>
    </div>
  )
}

const emptyTemplate = (theme: DefaultTheme) => {
  return (
    <div className="flex justify-content-center flex-column" style={{ height: "247px" }}>
      <HUText fontStyle="LL" color={theme.neutralColor700} className="flex justify-content-center text-center">
        {word("boxSessionAddSubscriberModal.dialog.empytemplate.body")}
      </HUText>
      <div className="flex justify-content-center text-center align-items-center mt-4">
        <img src={SessionEmptySVG} alt="SessionEmptySVG" />
      </div>
    </div>
  )
}

const itemTemplate = (member: Profile, theme: DefaultTheme, onClick: (member: Profile) => void) => {
  return (
    <div onClick={() => onClick(member)} key={member.id}>
      <div className="flex w-full cursor-pointer">
        <ProfileAvatar profile={{ avatar: member.avatar, username: member.firstname }} size={60} />
        <div className="flex flex-column ml-2 flex-wrap gap-1 justify-content-center">
          <HUText fontStyle="LL" color={theme.mainWhite}>
            {`${member.firstname} ${member.lastname}`}
          </HUText>
          <HUText fontStyle="BS" color={theme.neutralColor600}>
            {`${member.username}`}
          </HUText>
        </div>
      </div>
      <Line color={`${theme.neutralColor200}`} strokeWidth={1} />
    </div>
  )
}

const selectedTemplate = (member: Profile, theme: DefaultTheme, onClick: (member: Profile) => void) => {
  return (
    <div
      className="flex w-full pr-4 align-items-center justify-content-center"
      onClick={() => onClick(member)}
      key={member.id}
    >
      <div className={`flex w-full bg-gray-900 rounded-full mb-3 py-2 cursor-pointer`}>
        <div className="flex flex-colums flex-grow-1 ml-2">
          <ProfileAvatar profile={{ avatar: member.avatar, username: member.firstname }} size={60} />
          <div className="flex flex-column ml-2 flex-wrap gap-1 justify-content-center">
            <HUText fontStyle="LL" color={theme.mainWhite}>
              {`${member.firstname} ${member.lastname}`}
            </HUText>
            <HUText fontStyle="BS" color={theme.neutralColor600}>
              {`${member.username}`}
            </HUText>
          </div>
        </div>
        <div className="flex align-items-center	mr-3">
          <CheckmarkIcon
            color={theme.textPrimary}
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        </div>
      </div>
    </div>
  )
}
