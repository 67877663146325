import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const CommentIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_4088_11374)">
      <path
        d="M10.9055 1.16412C6.94151 1.16412 4.95954 1.16412 3.66923 2.20102C3.54176 2.30345 3.42107 2.41206 3.30725 2.52677C2.15501 3.68794 2.1548 5.47153 2.15439 9.0387C2.15433 9.51585 2.1543 9.993 2.1543 10.4701C2.15545 11.2951 2.47258 12.0945 3.05223 12.7335C3.57704 13.3121 4.288 13.7273 5.08418 13.924C5.2089 13.9548 5.27126 13.9702 5.30384 14.0087C5.33643 14.0471 5.33643 14.1021 5.33643 14.2119V16.198C5.3364 16.3276 5.37548 16.4548 5.44949 16.566C5.5235 16.6772 5.62966 16.7682 5.75665 16.8294C5.88364 16.8905 6.0267 16.9195 6.17055 16.9132C6.31441 16.9069 6.45367 16.8656 6.57348 16.7937L10.4471 14.4683C10.7403 14.2923 10.8869 14.2042 11.0483 14.1477C11.1246 14.121 11.2028 14.0996 11.2827 14.0837C11.4518 14.0501 11.6283 14.05 11.9813 14.0499C12.1987 14.0498 12.4161 14.0496 12.6334 14.0494C15.0075 14.0469 16.1946 14.0456 17.1506 13.6147C18.0133 13.2258 18.7457 12.5659 19.1767 11.789C19.6545 10.928 19.6545 9.83787 19.6544 7.65756C19.6544 5.40706 19.6544 4.28181 19.176 3.42029C18.7368 2.62964 18.0255 1.98952 17.147 1.59437C16.1897 1.16381 14.9395 1.1639 12.4392 1.16407C11.9279 1.1641 11.4167 1.16412 10.9055 1.16412Z"
        stroke={props.color}
        strokeWidth="1.12617"
      />
      <ellipse cx="7.20537" cy="7.62209" rx="0.925585" ry="0.833031" fill={props.color} />
      <ellipse cx="10.9051" cy="7.62209" rx="0.925584" ry="0.833031" fill={props.color} />
      <ellipse cx="14.6077" cy="7.62209" rx="0.925585" ry="0.833031" fill={props.color} />
    </g>
    <defs>
      <clipPath id="clip0_4088_11374">
        <rect width="20" height="18" fill="white" transform="translate(0.904785 0.0390625)" />
      </clipPath>
    </defs>
  </svg>
)
