import { FetchStrategy, useStore } from "@betomorrow/micro-stores"
import { useServicesContext } from "@modules/core/services/services.context"
import { useAsyncObservable } from "@modules/hooks/useAsyncObservable"

export const useBoxOffers = () => {
  const { offerService } = useServicesContext()
  const { result, ...other } = useAsyncObservable(offerService.offers)
  return { offers: result || [], ...other }
}

export const useBoxOffer = (id?: string, fetchStrategy?: FetchStrategy) => {
  const { offerService } = useServicesContext()
  return useStore(id, offerService.offerStore, fetchStrategy)
}
