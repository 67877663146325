import dayjs, { Dayjs } from "dayjs"
import dayjsDuration from "dayjs/plugin/duration"
dayjs.extend(dayjsDuration)
/**
 *
 * @param date in iso string format
 * @returns "YYYY-MM-DD"
 */
export const getCalendarDateString = (date: Date | Dayjs): string => {
  return date.toISOString().split("T")[0]
}

export const defaultDateFormat = "YYYY-MM-DD"
export const defaultDateTimeFormat = "YYYY-MM-DDTHH:mm:ssZ"
export const displayedDateFormat = "dddd DD MMM"
export const displayedFullDateFormat = "dddd, D MMMM YYYY"
export const monthYearDateFormat = "YYYY-MM"
export const weekdayDateFormat = "ddd DD/MM"

export const initialStartTime = "08:00"
export const initialEndTime = "09:00"

export const dateSeparator = "day-"

export const getMonthNumber = (date: Date) => {
  return date.getMonth() + 1
}

export const formatDateWithHyphen = (date: string) => {
  return date.replaceAll("/", "-")
}

export const getDuration = (timeA: Date | null, timeB: Date | null) => {
  const difference = Math.abs(dayjs(timeA).diff(dayjs(timeB)))
  return dayjs.duration(difference)
}

export const getDurationFromSecond = (durationInS: number) => {
  return dayjs.duration(durationInS, "s")
}

export const createDateTimeWithDateAndTime = (date: Date | null, dateTime: Date | null) => {
  return !!date && !!dateTime
    ? dayjs(date)
        .set("hours", dateTime.getHours())
        .set("minutes", dateTime.getMinutes())
        .set("milliseconds", dateTime.getMilliseconds())
        .toDate()
    : undefined
}
