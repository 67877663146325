export enum ChronoType {
  FOR_TIME = "FOR_TIME",
  AMRAP = "AMRAP",
  EMOM = "EMOM",
  TABATA = "TABATA",
}

interface BasicProps {
  type: ChronoType
  trainingTime: number | number[]
  numberOfSets: number
}

export type Chrono = ForTimeChrono | AmrapChrono | EmomChrono | TabataChrono

export interface ForTimeChrono extends BasicProps {
  type: ChronoType.FOR_TIME
  restTime: number
  trainingTime: number
  noLimit: boolean
}

export interface AmrapChrono extends BasicProps {
  type: ChronoType.AMRAP
  restTime: number[]
  trainingTime: number[]
}

export interface EmomChrono extends BasicProps {
  type: ChronoType.EMOM
  numberOfRounds: number
  restBetweenSetsTime: number
  trainingTime: number
}

export interface TabataChrono extends BasicProps {
  type: ChronoType.TABATA
  restTime: number
  restBetweenSetsTime: number
  numberOfRounds: number
  trainingTime: number
}

interface InfosBasicDescriptionChrono {
  type: ChronoType
  trainingTime: number
}

interface InfosForTimeDescription extends InfosBasicDescriptionChrono {
  type: ChronoType.FOR_TIME
  noLimit: boolean
}

interface InfosEmomDescription extends InfosBasicDescriptionChrono {
  type: ChronoType.EMOM
  numberOfRounds: number
}

interface InfosTabtaDescription extends InfosBasicDescriptionChrono {
  type: ChronoType.TABATA
  restTime: number
  numberOfRounds: number
}

export type InfosDescriptionChrono = InfosForTimeDescription | InfosEmomDescription | InfosTabtaDescription
