import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { RequestsSASProfile } from "@modules/profile/profileTypes"
import { useToast } from "@modules/ui/components/huToast"
import { useState } from "react"

export const useProgramSasRequests = (
  programId: string,
  sasRequests: readonly RequestsSASProfile[],
  onEndRequestOperation: (arg: RequestsSASProfile) => void,
  hideIsSASRequestToAcceptView: () => void,
  list: () => void,
) => {
  const [isRespondingRequestId, setIsRespondingRequestId] = useState<string | null>()
  const { programService } = useServicesContext()
  const toast = useToast()

  const acceptRequest = async (requestsSASProfile: RequestsSASProfile) => {
    if (!isRespondingRequestId) {
      try {
        setIsRespondingRequestId(requestsSASProfile.subscriptionId)
        await programService.acceptProgramSASRequest(programId, requestsSASProfile.subscriptionId)
        onEndRequestOperation(requestsSASProfile)

        if (sasRequests.length === 1) {
          hideIsSASRequestToAcceptView()
          list()
        }
      } catch (e) {
        toast.show({ severity: "error", summary: word("global.error.label") })
      } finally {
        setIsRespondingRequestId(null)
      }
    }
  }

  const rejectRequest = async (requestsSASProfile: RequestsSASProfile) => {
    if (!isRespondingRequestId) {
      try {
        setIsRespondingRequestId(requestsSASProfile.subscriptionId)
        await programService.rejectProgramSASRequest(programId, requestsSASProfile.subscriptionId)

        onEndRequestOperation(requestsSASProfile)

        if (sasRequests.length === 1) {
          hideIsSASRequestToAcceptView()
          list()
        }
      } catch (e) {
        toast.show({ severity: "error", summary: word("global.error.label") })
      } finally {
        setIsRespondingRequestId(null)
      }
    }
  }
  return {
    isRespondingRequestId,
    acceptRequest,
    rejectRequest,
  }
}
