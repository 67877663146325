import { BoxB2BPrograms } from "@modules/boxB2BProgram/boxB2BProgramTypes"
import { BoxB2BProgramsDto, convertBoxB2BProgramsFromDto } from "@modules/boxB2BProgram/services/boxB2BProgramDto"
import { ApiClient } from "@modules/core/api/client/apiClient"

export class BoxB2BProgramApi {
  private baseUrl = "/boxes/b2b-programs"
  private duplicateSessionsUrl = `/boxes/slots/duplicate-sessions`

  constructor(private api: ApiClient) {}

  async getBoxB2BPrograms(): Promise<BoxB2BPrograms | undefined> {
    const resultDto = await this.api.get<BoxB2BProgramsDto>(this.baseUrl)
    return convertBoxB2BProgramsFromDto(resultDto.data)
  }

  async duplicateB2BProgramSessions(
    boxProgrammationId: string,
    programIdToDuplicateFrom: string,
    startDate: string,
    endDate: string,
  ) {
    return await this.api.post(`${this.duplicateSessionsUrl}`, {
      programIdToDuplicateTo: boxProgrammationId,
      programIdToDuplicateFrom,
      startDate,
      endDate,
    })
  }
}
