import { PaginatedStore, Store } from "@betomorrow/micro-stores"
import {
  BoxProgrammation,
  BoxProgrammationDefaultSettings,
  BoxProgrammationDraft,
  ItemBoxProgrammation,
} from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxProgrammationApi } from "@modules/boxProgrammation/services/boxProgrammationApi"
import { CurrentSpaceManager } from "@modules/core/services/currentSpaceManager"
import { WebService } from "@modules/core/services/webService"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"

export class BoxProgrammationService implements WebService {
  boxProgrammationStore = new Store<BoxProgrammation>((id) => this.api.getBoxProgrammation(id), "id")
  paginatedBoxProgrammationStore = new PaginatedStore<ItemBoxProgrammation, BoxProgrammation, "id">((page: number) =>
    this.getBoxProgrammations({ size: 100, page }),
  ).present(this.boxProgrammationStore)

  constructor(
    private api: BoxProgrammationApi,
    private currentSpaceManager: CurrentSpaceManager,
    private localStorage: LocalStorageService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedIn(): Promise<void> {
    /* if (this.currentSpaceManager.boxId) {
      const programmations = await this.getBoxProgrammations()
      this.boxPlanningService.initFilteredPrograms(programmations.content.map((p) => p.id))
    }*/
  }

  async onSpaceChange(): Promise<void> {
    this.boxProgrammationStore.clear()
    this.localStorage.remove(LocalStorageKeys.boxProgram.defaultSettings)
  }

  async getBoxProgrammation(boxProgrammationId: string) {
    return this.api.getBoxProgrammation(boxProgrammationId)
  }

  async createBoxProgrammation(boxProgrammationDraft: BoxProgrammationDraft) {
    const boxProgrammation = await this.api.createBoxProgrammation(boxProgrammationDraft)
    this.boxProgrammationStore.save(boxProgrammation)
    this.paginatedBoxProgrammationStore.list()
    return boxProgrammation
  }

  async updateBoxProgrammation(id: string, boxProgrammationDraft: Partial<BoxProgrammationDraft>) {
    const updatedBoxProgrammation = await this.api.updateBoxProgrammation(id, boxProgrammationDraft)
    this.boxProgrammationStore.update(id, () => updatedBoxProgrammation)
    this.paginatedBoxProgrammationStore.list()
  }

  async deleteBoxProgrammation(id: string) {
    await this.api.deleteBoxProgrammation(id)
    this.boxProgrammationStore.remove(id)
  }

  async getBoxProgrammations(options?: { page?: number; size?: number }) {
    return this.api.getBoxProgrammations(options?.page, options?.size)
  }

  async getBoxProgrammationDefaultValues(id: string) {
    return this.api.getBoxProgrammationDefaultValues(id)
  }

  async createBoxProgrammationDefaultValues(id: string, defaultValues: BoxProgrammationDefaultSettings) {
    return this.api.createBoxProgrammationDefaultValues(id, defaultValues)
  }

  async updateBoxProgrammationDefaultValues(id: string, defaultValues: BoxProgrammationDefaultSettings) {
    return this.api.updateBoxProgrammationDefaultValues(id, defaultValues)
  }
}
