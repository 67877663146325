import { GlobeIcon } from "@images/svgIcons/globeIcon"
import { LockIcon } from "@images/svgIcons/lockIcon"
import { ItemBenchmark } from "@modules/benchmark/benchmarkTypes"
import { ItemBox } from "@modules/box/boxTypes"
import { ExploreFilter } from "@modules/explore/exploreTypes"
import { ItemProgram, ProgramVisibility } from "@modules/programs/programTypes"
import { getDisplayVisibility, getSubscriptionCountText } from "@modules/programs/programUtils"
import { HUText } from "@modules/ui/components/huText"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

type ExploreCardTitleProps = {
  item: ItemProgram | ItemBox | ItemBenchmark
}

export const ExploreCardTitle: React.FC<ExploreCardTitleProps> = ({ item }) => {
  const theme = useTheme()

  const itemTitle = useMemo(() => {
    switch (item._type) {
      case ExploreFilter.PROGRAM:
        return item.title
      case ExploreFilter.BOX:
        return item.name
      case ExploreFilter.BENCHMARK:
        return item.title
    }
  }, [item])

  return (
    <TitleContainer>
      {(item._type === ExploreFilter.BOX || item._type === ExploreFilter.PROGRAM) && (
        <>
          <HUText fontStyle="HS" color={theme.mainWhite}>
            {itemTitle}
          </HUText>
          {"subscriptionCount" in item && (
            <div className="flex align-items-center">
              <HUText fontStyle="LL" color={theme.mainWhite}>
                {`${getSubscriptionCountText(item.subscriptionCount, false, false, true, item._programType).toLowerCase()} - `}
              </HUText>
              {item.visibility === ProgramVisibility.PUBLIC ? (
                <GlobeIcon color={theme.mainWhite} className="ml-2" />
              ) : (
                <LockIcon color={theme.mainWhite} className="ml-2" />
              )}
              <HUText color={theme.mainWhite} fontStyle="BM" className="ml-2">
                {getDisplayVisibility(item.visibility)}
              </HUText>
            </div>
          )}
        </>
      )}
      {item._type === ExploreFilter.BENCHMARK && (
        <>
          <HUText fontStyle="LM" color={theme.neutralColor700}>
            {item.category}
          </HUText>
          <HUText fontStyle="HL" color={theme.neutralColor700}>
            {itemTitle}
          </HUText>
        </>
      )}
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
  position: absolute;
  bottom: 84px;
  left: 20px;
  z-index: 200;
  display: flex;
  flex-direction: column;
`
