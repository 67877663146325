import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { BenchmarkInfoResume } from "@modules/benchmark/components/benchmarkInfoResume"
import { useBenchmark } from "@modules/benchmark/hooks/useBenchmark"
import { ExploreCard } from "@modules/explore/components/exploreCard/exploreCard"
import { HeroesBenchmarkDataType } from "@modules/dashboard/components/heroesBenchmarkDataType"
import { LoadingSpinner } from "@modules/ui/components/loading"

type BenchmarkHereosCardProps = {
  heroesBenchmark: HeroesBenchmarkDataType
}

export const BenchmarkHeroesCard: React.FC<BenchmarkHereosCardProps> = ({ heroesBenchmark }) => {
  const { sidebarNavigateTo } = useSidebar()
  const { result: benchmark } = useBenchmark(heroesBenchmark.id)

  const onOpenBenchmarkHeroesResume = () => {
    sidebarNavigateTo({
      content: <BenchmarkInfoResume benchmarkId={heroesBenchmark.id} />,
      replaceAllComponents: true,
    })
  }

  return (
    <>
      {benchmark ? (
        <ExploreCard
          item={benchmark}
          key={benchmark.id}
          onViewDetailsClick={onOpenBenchmarkHeroesResume}
          isSelected={false}
          backgroundImage={heroesBenchmark.image}
        />
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}
