// Article Image
export type SanityReferenceSchema = {
  _ref: string
}

export type SanityAsset = {
  _id?: string
  url?: string
  path?: string
  assetId?: string
  extension?: string
}

export type SanityImageCropSchema = {
  _type?: string
  left: number
  bottom: number
  right: number
  top: number
}

export type SanityImageHotspotSchema = {
  _type?: string
  width: number
  height: number
  x: number
  y: number
}

export type SanityImageObjectSchema = {
  _key?: string
  asset: SanityReferenceSchema | SanityAsset
  crop?: SanityImageCropSchema
  hotspot?: SanityImageHotspotSchema
}

export type ArticleContentImageDto = {
  _type: "content_image"
  _key?: string
  alt_text?: string
}

export type ArticleThumbnailDto = {
  _type: "thumbnail"
  alt_text?: string
  asset: SanityAsset
}

// Portable Text Block
export type PortableTextBlockStyleSchema = "normal" | "blockquote" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | string

export type PortableTextLinkSchema = {
  _type: "link"
  _key?: string
  href: string
}

export type PortableTextListItemTypeSchema = "bullet" | "number"

export type PortableTextSpanSchema = {
  _type: "span"
  _key?: string
  text: string
  marks?: string[]
}

export type PortableTextBlockSchema = {
  _type: "block"
  _key?: string
  children: PortableTextSpanSchema[]
  markDefs: PortableTextLinkSchema[]
  style: PortableTextBlockStyleSchema
  listItem?: PortableTextListItemTypeSchema
  level?: number
}

// Article Video
export type ArticleVideoDto = {
  _type: "content_video"
  _key?: string
  video_url: string
  alt_text?: string
}

// Article Tags
export type ArticleTagDto = "interview" | "tips" | "educational" | "community" | "events"

// Article
export type ArticleDto = {
  _id: string
  _type: "article"
  _rev: string
  _createdAt: string
  _updatedAt: string
  title: string
  description: string
  author: string
  thumbnail: ArticleThumbnailDto
  tag: ArticleTagDto
  content: (PortableTextBlockSchema | ArticleContentImageDto | ArticleVideoDto)[]
  seo_id: {
    current: string
    _type: string
  }
}

export function convertArticleSchemaDtoToArticle(data: any): ArticleDto {
  if (!data) {
    throw new Error("Article data is required")
  }

  // Validate required fields
  const { _id, _type, _rev, _createdAt, _updatedAt, title, description, author, thumbnail, tag, content, seo_id } = data
  if (
    !_id ||
    !_type ||
    !_rev ||
    !_createdAt ||
    !_updatedAt ||
    !title ||
    !description ||
    !author ||
    !thumbnail ||
    !tag ||
    !content ||
    !seo_id
  ) {
    throw new Error("Invalid article data")
  }

  // Validate and convert thumbnail
  const thumbnailDto: ArticleThumbnailDto = {
    _type: thumbnail._type,
    alt_text: thumbnail.alt_text,
    asset: thumbnail.asset,
  }

  // Validate and convert content array
  const contentDto: (PortableTextBlockSchema | ArticleContentImageDto | ArticleVideoDto)[] = content.map(
    (item: any) => {
      if (item._type === "block") {
        return {
          _type: item._type,
          _key: item._key,
          children: item.children.map((child: any) => ({
            _key: child._key,
            _type: child._type,
            marks: child.marks,
            text: child.text,
          })),
          markDefs: item.markDefs.map((mark: any) => ({
            _type: mark._type,
            _key: mark._key,
            href: mark.href,
          })),
          style: item.style,
          listItem: item.listItem,
          level: item.level,
        }
      } else if (item._type === "content_image") {
        return {
          _type: item._type,
          alt_text: item.alt_text,
          asset: item.asset,
          // Add other properties as needed
        }
      } else if (item._type === "content_video") {
        return {
          _type: item._type,
          _key: item._key,
          video_url: item.video_url,
          alt_text: item.alt_text,
          // Add other properties as needed
        }
      } else {
        throw new Error(`Invalid content type: ${item._type}`)
      }
    },
  )

  // Validate and convert seo_id
  const seoIdDto = {
    current: seo_id.current,
    _type: seo_id._type,
  }

  // Construct and return the ArticleDto
  const articleDto: ArticleDto = {
    _id,
    _type,
    _rev,
    _createdAt,
    _updatedAt,
    title,
    description,
    author,
    thumbnail: thumbnailDto,
    tag,
    content: contentDto,
    seo_id: seoIdDto,
  }

  return articleDto
}

export type PortableTextBlockDto = {
  _type: "block"
  _key?: string
  children: PortableTextSpanDto[]
  markDefs: PortableTextLinkDto[]
  style: PortableTextBlockStyleSchema
  listItem?: PortableTextListItemTypeSchema
  level?: number
}

export type PortableTextSpanDto = {
  _type: "span"
  _key?: string
  text: string
  marks?: string[]
}

export type PortableTextLinkDto = {
  _type: "link"
}
