export const colors = {
  leGreen100: "#FAFBF1",
  leGreen200: "#F3FAC3",
  leGreen300: "#EBF791",
  leGreen400: "#E4F56C",
  leGreen500: "#DDF247",
  leGreen550: "#C9E432", // only on dark
  leGreen600: "#B0D717",
  leGreen700: "#82A10A",
  leGreen800: "#4A5C03",
  leGreen900: "#354200", // only on light

  forestGreen100: "#DEEAE9",
  forestGreen200: "#AEC3C2",
  forestGreen300: "#4A7875",
  forestGreen400: "#245453",
  forestGreen500: "#133C40",
  forestGreen600: "#0F3033",
  forestGreen700: "#052326",
  forestGreen800: "#01171C",
  forestGreen900: "#0A1414",

  electricPurpleStartGradient: "#B59FC3",

  electricPurple50: "#F7F7FE",
  electricPurple100: "#EFEDFE",
  electricPurple200: "#D0CAFD",
  electricPurple300: "#A89DFB",
  electricPurple400: "#8373F9",
  electricPurple500: "#6450F7",
  electricPurple600: "#4B3DB2",
  electricPurple700: "#3B3184",
  electricPurple800: "#2B2557",
  electricPurple900: "#211F36",

  cloudlyPurple100: "#DEDEEA",
  cloudlyPurple200: "#AEAEC3",
  cloudlyPurple300: "#5A5A78",
  cloudlyPurple400: "#383854",
  cloudlyPurple500: "#21213E",
  cloudlyPurple600: "#1E1E33",
  cloudlyPurple700: "#121226",
  cloudlyPurple800: "#0A0A1B",
  cloudlyPurple900: "#0A0A14",

  mediumSkyBlue100: "#CAF1FE",
  mediumSkyBlue200: "#A9EAFF",
  mediumSkyBlue300: "#8BE3FF",
  mediumSkyBlue400: "#6BDCFF",
  mediumSkyBlue500: "#57D7FF",
  mediumSkyBlue600: "#35B5DD",
  mediumSkyBlue700: "#249CC2",
  mediumSkyBlue800: "#0982A8",
  mediumSkyBlue900: "#005E7C",

  neutral50: "#F7F7F7",
  neutral100: "#F2F2F2",
  neutral200: "#E6E6E6",
  neutral300: "#D1D1D1",
  neutral400: "#B3B4B1",
  neutral500: "#959693",
  neutral600: "#6D6E6B",
  neutral700: "#454643",
  neutral800: "#282828",
  neutral900: "#1C1C1C",

  neutralDark100: "#1C1C1C",
  neutralDark200: "#242424",
  neutralDark300: "#2E2E2E",
  neutralDark400: "#454545",
  neutralDark500: "#696969",
  neutralDark600: "#B4B4B4",
  neutralDark700: "#DADADA",
  neutralDark800: "#EDEDED",
  neutralDark900: "#FAFAFA",

  ruby50: "#FFF4F2",
  ruby100: "#F8E8E7",
  ruby200: "#F8C0BE",
  ruby300: "#FE9B96",
  ruby400: "#FA726C",
  ruby500: "#F84B43", // Error color
  ruby600: "#D44842",
  ruby700: "#A44541",
  ruby800: "#713633",
  ruby900: "#4A2826",

  emerald100: "#D2F2EF",
  emerald200: "#BEF2EC",
  emerald300: "#94E5DC",
  emerald400: "#6CCFC4",
  emerald500: "#45B0A3", // Success color
  emerald600: "#2E8A7F",
  emerald700: "#1D524B",
  emerald800: "#11302D",
  emerald900: "#0A1B19",

  white: "#FFFFFF",
  black: "#121212",

  // custom
  purple500: "#8773F9",
  purple200: "#CCBCFF",
  blue500: "#78CBFA",
  blue200: "#C7EBFF",
  green500: "#62D893",
  green200: "#D4F2E1",
  yellow500: "#FFDC66", // Warning color
  yellow200: "#FFEEB7",
  orange500: "#FF7650",
  orange200: "#FBDFD7",
  charcoal900: "#211F36",
  charcoal200: "#E6E6E6",
  red500: "#FF6259",

  grey500: "#424242",
  defaultIconColor: "#424242",
}

export const colorWithAlpha = (color: string, alpha: number): string => {
  return `${color}${Math.round(alpha * 255)
    .toString(16)
    .padStart(2, "0")}`
}
