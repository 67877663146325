import { forwardRef } from "react"

/**
 * HUTag component for displaying tags.
 * @component
 * @param {object} props - Component props
 * @param {React.ReactNode} props.children - The content inside the tag.
 * @param {string} [props.className=""] - Additional CSS classes for styling.
 * @param {React.HTMLAttributes<HTMLDivElement>} props.props - Additional attributes to apply to the div element.
 * @param {React.Ref<HTMLDivElement>} ref - Reference object for accessing the DOM element.
 */
const HUTag = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => {

    const classNameTag = `flex align-items-center text-center gap-2 bg-neutral-300 rounded-full px-4 py-2`

    return (
      <div ref={ref} role="tag" className={`${classNameTag} ${className}`} {...props}>
        {children}
      </div>
    )
  },
)

HUTag.displayName = "HUTag"
export { HUTag }
