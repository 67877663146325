import { convertImageFromDto } from "@modules/dtos/imageDto"
import { convertProfileFromDto } from "@modules/profile/profileDtos"
import {
  ItemProgramOnOff,
  ProgramOnOff,
  ProgramOnOffDraft,
  ProgramOnOffProgression,
  ProgramOnOffStatus,
  ProgramOnOffStripeSubscriptionInfos,
  ProgramOnOffTypeOf,
  ProgressionStatusProgramOnOff,
} from "@modules/programs/programOnOffTypes"
import { ProgramContentTypeOf, ProgramPrice, ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import {
  convertProgramPaymentFromDto,
  convertProgramPriceFromDto,
  ProgramPaymentDto,
  ProgramPriceDto,
} from "@modules/programs/services/programDto"
import {
  ItemProgramInfiniteDto,
  ProgramInfiniteDraftDto,
  ProgramInfiniteDto,
  ProgramInfiniteProfileDto,
} from "@modules/programs/services/programInfiniteDto"
import { convertToEnum } from "@modules/utils/enum"

export type ItemProgramOnOffDto = {
  duration: number
  status: ProgramOnOffStatus
  sessionCount: number
  sessionIndexes: number[]
  invitationLink: string
} & ItemProgramInfiniteDto

export type ProgramOnOffProfileDto = {
  duration: number
  status: string
  sessionCount: number
  sessionIndexes: number[]
} & ProgramInfiniteProfileDto

export type ProgramOnOffDto = {
  duration: number
  status: ProgramOnOffStatus
  sessionCount: number
  sessionIndexes: number[]
  invitationLink: string
} & ProgramInfiniteDto

export type ProgramOnOffDraftDto = {
  duration?: number
  status?: string
} & ProgramInfiniteDraftDto

export type ProgramOnOffProgressionDto = {
  programId: string
  userId: string
  currentIndex: number
  duration: number
  startedAt: string
  stoppedAt: string
  status: ProgressionStatusProgramOnOff
}

export function convertItemProgramOnOffFromDto(dto: ItemProgramOnOffDto): ItemProgramOnOff {
  return {
    _programType: ProgramOnOffTypeOf,
    id: dto.id,
    title: dto.name,
    price: dto.price as ProgramPrice,
    visibility: dto.visibility,
    image: convertImageFromDto(dto.image),
    author: convertProfileFromDto(dto.owner),
    subscriptionCount: dto.subscriptionCount,
    defaultHideSessionContent: dto.defaultHideSessionContent,
    entranceCheckEnabled: dto.entranceCheckEnabled,
    duration: dto.duration,
    status: dto.status,
    subscribed: dto.subscribed,
    sessionCount: dto.sessionCount,
    sessionIndexes: dto.sessionIndexes,
    subscriptionStatus: dto.subscriptionStatus,
    invitationLink: dto.invitationLink,
    _type: ProgramContentTypeOf,
    sharedCoaches: dto.sharedCoaches?.map((sharedCoach) => ({
      ...sharedCoach,
      user: convertProfileFromDto(sharedCoach.user),
    })),
  }
}

export function convertProgramOnOffFromDto(dto: ProgramOnOffDto): ProgramOnOff {
  return {
    ...convertItemProgramOnOffFromDto(dto),
    description: dto.description,
  }
}

export function convertProgramOnOffDraftToDto(draft: ProgramOnOffDraft): ProgramOnOffDraftDto {
  return {
    name: draft.name,
    description: draft.description,
    visibility: draft.visibility,
    image: draft.image?.path,
    defaultHideSessionContent: draft.defaultHideSessionContent,
    entranceCheckEnabled: draft.entranceCheckEnabled,
    duration: draft.duration,
    status: draft.status,
    sharedCoaches: draft.sharedCoaches?.map((coach) => coach.user.id),
  }
}

export function convertPartialProgramOnOffDraftToDto(draft: Partial<ProgramOnOffDraft>): Partial<ProgramOnOffDraftDto> {
  return {
    name: draft.name ?? undefined,
    description: draft.description ?? undefined,
    visibility: draft.visibility ?? undefined,
    image: draft.image?.path,
    defaultHideSessionContent: draft.defaultHideSessionContent ?? undefined,
    entranceCheckEnabled: draft.entranceCheckEnabled ?? undefined,
    duration: draft.duration,
    status: draft.status,
    sharedCoaches: draft.sharedCoaches?.map((coach) => coach.user.id),
  }
}

export function convertProgramOnOffProgressionFromDto(dto: ProgramOnOffProgressionDto): ProgramOnOffProgression {
  return {
    id: dto.programId,
    programId: dto.programId,
    userId: dto.userId,
    currentIndex: dto.currentIndex,
    duration: dto.duration,
    startedAt: dto.startedAt,
    stoppedAt: dto.stoppedAt,
    status: dto.status,
  }
}

export type ProgramOnOffStripeSubscriptionInfosDto = {
  subscriptionStatus: string
  paidDate?: string
  paidPrice?: ProgramPriceDto
  payment?: ProgramPaymentDto
}

export function convertProgramOnOffStripeSubscriptionInfosFromDto(
  id: string,
  dto: ProgramOnOffStripeSubscriptionInfosDto,
): ProgramOnOffStripeSubscriptionInfos {
  return {
    id,
    subscriptionStatus: convertToEnum(
      ProgramSubscriptionStatus,
      dto.subscriptionStatus,
      ProgramSubscriptionStatus.INACTIVE,
    ),
    paidPrice: convertProgramPriceFromDto(dto.paidPrice),
    paidDate: dto.paidDate,
    payment: convertProgramPaymentFromDto(dto.payment),
  }
}
