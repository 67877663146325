import { BoxSlotSessionFormikType } from "@modules/boxSlot/boxSessionFormikTypes"
import { word } from "@modules/core/utils/i18n"
import { RpeSlider } from "@modules/exercises/components/rpeSlider"
import {
  ProgramInfiniteSessionFormikType,
  ProgramOnOffSessionFormikType,
} from "@modules/programSession/programSessionFormikType"
import { HUText } from "@modules/ui/components/huText"
import { SimpleFormik } from "@src/typings/formik"
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch"
import styled, { useTheme } from "styled-components"

type RpeBlocProps = {
  isRpe: boolean
  setIsRpe: (value: boolean) => void
  formik: SimpleFormik<ProgramInfiniteSessionFormikType | ProgramOnOffSessionFormikType | BoxSlotSessionFormikType, any>
  index: number
}
export const RpeBloc: React.FC<RpeBlocProps> = ({ isRpe, setIsRpe, formik, index }) => {
  const theme = useTheme()

  const onRpe = (e: InputSwitchChangeEvent) => {
    if (isRpe === true) {
      formik.setFieldValue(`exercises[${index}].rpe`, null)
    } else {
      formik.setFieldValue(`exercises[${index}].rpe`, 5)
    }
    setIsRpe(e.value ?? false)
  }

  return (
    <div className="flex-1 mt-2">
      <HUText type="label" color={theme.neutralColor700} fontStyle="LS" style={{ lineHeight: "21px", marginBottom: 7 }}>
        {word("sessionForm.exercise.rpe.label").toUpperCase()}
      </HUText>
      <RpeContainer $isRpe={isRpe}>
        <div className="flex w-full justify-content-between mb-2">
          <HUText type="label" className="my-1" color={theme.neutralColor700} fontStyle="LL">
            {word("sessionForm.exercise.askRpe")}
          </HUText>
          <InputSwitch
            checked={isRpe}
            onChange={(e: InputSwitchChangeEvent) => {
              onRpe(e)
            }}
          />
        </div>
        <HUText type="label" color={theme.neutralColor600} fontStyle="BS">
          {word("sessionForm.exercise.rpe.description")}
        </HUText>
        {isRpe && <RpeSlider isRpe={isRpe} formik={formik} index={index} />}
      </RpeContainer>
    </div>
  )
}

const RpeContainer = styled.div<{ $isRpe: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({ $isRpe }) => ($isRpe ? "230px" : "100px")};
  padding: 14px 20px;
  border-radius: 20px;
  margin-top: 7px;
  background-color: ${({ theme }) => theme.neutralColor100};
  transition: background-color 1s ease;
`
