import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { HUImage } from "@modules/types/imageTypes"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"

type Props = {
  member: { avatar?: HUImage; username: string; firstname: string; lastname: string }
  borderColor?: string
  subtitle?: string
  subtitleColor?: string
} & React.HTMLAttributes<HTMLDivElement>

/**
 * UserCell is a component that displays a user's avatar, name and subtitle
 * It's used in Datable components to display a user's information : avatar, name and username
 */
export const UserCell: React.FC<Props> = ({ member, borderColor, subtitle, subtitleColor, ...props }) => {
  return (
    <HURow align gap={10} {...props}>
      <div className="w-max">
        <ProfileAvatar profile={{ avatar: member.avatar, username: member.username }} borderColor={borderColor} />
      </div>
      <HUStack gap={2}>
        <HUText style={{ flex: 1 }} fontStyle="LL">
          {member.firstname + " " + member.lastname}
        </HUText>
        {subtitle && (
          <HUText style={{ flex: 1 }} fontStyle="LS" color={subtitleColor}>
            {subtitle}
          </HUText>
        )}
      </HUStack>
    </HURow>
  )
}
