import { FetchStrategy, PaginatedStore, usePaginatedStore } from "@betomorrow/micro-stores"
import { BenchmarkTypeOf } from "@modules/benchmark/benchmarkTypes"
import { BoxTypeOf } from "@modules/box/boxTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { ExploreContent, ExploreFilter } from "@modules/explore/exploreTypes"
import { useAsyncObservable } from "@modules/hooks/useAsyncObservable"
import { ProfileTypeOf } from "@modules/profile/profileTypes"
import { ProgramContentTypeOf, ProgramVisibility } from "@modules/programs/programTypes"
import { useRefreshPaginatedStore } from "@modules/store"
import { useMemo, useState } from "react"

const useValidExploreContent = (
  exploreSearchStore: PaginatedStore<ExploreContent, ExploreContent, "id">,
  fetchStrategy?: FetchStrategy,
) => {
  const { result, ...other } = usePaginatedStore(exploreSearchStore, fetchStrategy)

  return {
    // Remove null on deleted content (micro-store)
    result: result.filter((content) => content.program || content.benchmark || content.box || content.profile),
    ...other,
  }
}

export const useExploreContents = (filter: ExploreFilter, fetchStrategy?: FetchStrategy) => {
  const { exploreService } = useServicesContext()

  const exploreSearchStore = useMemo(() => {
    return exploreService.getPaginatedExploreStore(filter)
  }, [filter])
  return useRefreshPaginatedStore(useValidExploreContent(exploreSearchStore, fetchStrategy))
}

export const useExploreHistory = () => {
  const { exploreService } = useServicesContext()
  const { result, ...rest } = useAsyncObservable(exploreService.exploresHistory)
  const filteredResult = result?.filter(
    (activity) =>
      !(activity.contentType === ProgramContentTypeOf && activity.content.visibility === ProgramVisibility.PRIVATE),
  )
  return { result: filteredResult, ...rest }
}

export const useExploreSearchContents = (filter: ExploreFilter) => {
  const [searchTerms, setSearchTerms] = useState("")
  const { exploreService } = useServicesContext()
  const exploreSearchStore = useMemo(() => {
    return new PaginatedStore<ExploreContent, ExploreContent, "id">((page) =>
      exploreService.search(searchTerms, filter, page),
    ).bind(exploreService.exploreStore)
  }, [searchTerms, filter])

  return { setSearchTerms, ...useValidExploreContent(exploreSearchStore) }
}

export function getExploreContent(exploreContent: ExploreContent) {
  switch (exploreContent.type) {
    case BenchmarkTypeOf:
      return exploreContent.benchmark
    case ProgramContentTypeOf:
      return exploreContent.program
    case BoxTypeOf:
      return exploreContent.box
    case ProfileTypeOf:
      return exploreContent.profile
  }
}
