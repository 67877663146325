import Vector from "@images/background/vector.svg"
import { CongratulationIcon } from "@images/box/congratulationIcon"
import {
  BoxFormikType,
  BoxInputKey,
  convertBoxFormFormik,
  convertBoxToFormik,
  getValidationSchema,
} from "@modules/box/boxFormikType"
import { convertBoxToDraft } from "@modules/box/boxTypes"
import { BoxFormFifthPage } from "@modules/box/components/form/boxFormFifthPage"
import { BoxFormFirstPage } from "@modules/box/components/form/boxFormFirstPage"
import { BoxFormFourthPage } from "@modules/box/components/form/boxFormFourthPage"
import { BoxFormLegalDocumentsPage } from "@modules/box/components/form/boxFormLegalDocumentsPage"
import { BoxFormResumePage } from "@modules/box/components/form/boxFormResumePage"
import { BoxFormSecondPage } from "@modules/box/components/form/boxFormSecondPage"
import { BoxFormThirdPage } from "@modules/box/components/form/boxFormThirdPage"
import { BoxFormWelcomePage } from "@modules/box/components/form/boxFormWelcomePage"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { OnboardingWrapper } from "@modules/owner/boxSettings/components/onboardingWrapper"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useFormik } from "formik"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const BoxFormCreation = () => {
  const { boxService, profileService } = useServicesContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const boxNavigate = useBoxNavigate()
  const toast = useToast()

  const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page") ?? 1))
  const [displayWelcomePage, setDisplayWelcomePage] = useState(true)
  const popup = useConfirmPopup()
  const theme = useTheme()

  useEffect(() => {
    if (profileService.profileTheme.get().theme === "athlete") {
      profileService.setTheme("owner")
    }
  }, [])

  const navigateForm = (onNext: boolean) => {
    setPageNumber((prev) => (onNext ? prev + 1 : prev - 1))
  }

  useEffect(() => {
    if (searchParams.get("page")) {
      setPageNumber(Number(searchParams.get("page")))
    } else {
      setSearchParams({ page: pageNumber.toString() })
    }
  }, [searchParams])

  useEffect(() => {
    setSearchParams({ page: pageNumber.toString() })
  }, [pageNumber])

  const createdPopup = (newBoxId: string) =>
    popup.show({
      title: <CongratulationIcon color="" className="ml-5" />,
      headerStyle: { textAlign: "center" },
      message: (
        <div className="flex flex-column gap-2 align-items-center text-center">
          <HUText fontStyle="TL">{word("box.form.create.modal.title")}</HUText>
          <HUText fontStyle="BM" color={theme.neutralColor600}>
            {word("box.form.create.modal.description")}
          </HUText>
        </div>
      ),
      accept: async () => boxNavigate(Path.Box.OnboardingStripe, { newBoxId }),
      cancel: async () => boxNavigate(Path.Box.OnboardingStripe, { newBoxId }),
      footerProps: {
        hideCancelIcon: true,
        confirmText: word("box.form.create.modal.nextStep"),
        confirmClassName: "flex-1",
        footerClassName: "flex flex-row justify-content-around w-full",
      },
      width: "418px",
    })

  const validationSchema = useMemo(() => getValidationSchema(pageNumber), [pageNumber])

  const formik = useFormik<BoxFormikType>({
    initialValues: convertBoxToFormik(convertBoxToDraft()),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (pageNumber < 7) {
        navigateForm(true)
        return
      }
      try {
        const { id } = await boxService.createBox(convertBoxFormFormik(values))
        createdPopup(id)
      } catch (e) {
        console.error(LoggerType.Box + "create", e)
        toast.show({
          severity: "error",
          summary: word("global.error.label"),
        })
      }
    },
  })

  const handleClose = () => {
    popup.show({
      title: word("box.creation.form.cancel.title"),
      message: word("box.creation.form.cancel.description"),
      accept: async () => {
        profileService.setNewTheme("athlete")
        setTimeout(() => navigate(Path.Root), 100)
      },
      footerProps: {
        align: true,
        confirmClassName: "flex-1",
        cancelClassName: "flex-1",
        footerClassName: "flex flex-row justify-content-around w-full",
      },
    })
  }

  const FormPage = () => {
    const props = {
      handleSubmit: formik.handleSubmit,
      onPrevious: pageNumber === 1 ? () => setDisplayWelcomePage(true) : () => navigateForm(false),
      isFormFieldInValid: (input: BoxInputKey) => isFormFieldInValid(input, formik),
      getFormErrorMessage: (input: BoxInputKey) => getFormErrorMessage(input, formik),
      isSubmitting: formik.isSubmitting,
      disabled: !getValidationSchema(pageNumber).isValidSync(formik.values),
      formik: {
        values: formik.values,
        getFieldProps: formik.getFieldProps,
        setFieldValue: formik.setFieldValue,
        validateField: formik.validateField,
        handleBlur: async (e: React.FocusEvent, fieldName: string) => {
          await formik.handleBlur(e)
          formik.validateField(fieldName)
        },
        handleChange: async (e: React.ChangeEvent, fieldName: string) => {
          await formik.handleChange(e)
          formik.validateField(fieldName)
        },
      },
    }
    switch (pageNumber) {
      case 1:
        return <BoxFormFirstPage {...props} />
      case 2:
        return <BoxFormSecondPage {...props} />
      case 3:
        return <BoxFormThirdPage {...props} />
      case 4:
        return <BoxFormFourthPage {...props} />
      case 5:
        return <BoxFormFifthPage {...props} />
      case 6:
        return <BoxFormLegalDocumentsPage {...props} />
      case 7:
        return <BoxFormResumePage {...props} />
      default:
        return null
    }
  }

  return (
    <OnboardingWrapper backgroundImage={Vector} handleClose={handleClose}>
      <CustomForm
        onSubmit={formik.handleSubmit}
        className="flex flex-column align-items-center p-6 justify-content-between gap-3 h-full"
      >
        {displayWelcomePage ? <BoxFormWelcomePage onClick={() => setDisplayWelcomePage(false)} /> : FormPage()}
      </CustomForm>
    </OnboardingWrapper>
  )
}

const CustomForm = styled.form`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
`
