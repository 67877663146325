import { Service } from "@modules/core/services/serviceType"
import { TrainingApi } from "@modules/training/services/trainingApi"
import { TrainingContent } from "@modules/training/trainingTypes"
import dayjs from "dayjs"
import { observable } from "micro-observables"

export class TrainingService implements Service {
  private _trainingSessions = observable<TrainingContent[]>([])
  readonly trainingSessions = this._trainingSessions.readOnly()
  private _todaySessions = observable<TrainingContent[]>([])
  readonly todaySessions = this._todaySessions.readOnly()
  private _isLoadingSessions = observable<boolean>(false)
  readonly isLoadingSessions = this._isLoadingSessions.readOnly()

  constructor(private api: TrainingApi) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async getTrainingContents(startDate: string, endDate: string) {
    this._isLoadingSessions.set(true)
    const result = await this.api.getTrainingContents(startDate, endDate)
    this._trainingSessions.set(result)
    this._isLoadingSessions.set(false)
  }

  async getTrainingContentDate(date: string) {
    return await this.api.getTrainingContentDate(date)
  }

  async onLoggedIn() {
    const result = await this.getTrainingContentDate(dayjs().toString())
    this._todaySessions.set(result)
  }
}
