export const ForbiddenIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M19.5908 4.42583C17.5648 2.39893 14.8676 1.28229 11.9975 1.28229C6.07738 1.28229 1.26074 6.09893 1.26074 12.0191C1.26074 14.8891 2.37739 17.5864 4.40428 19.6123C6.43025 21.6393 9.12753 22.7559 11.9975 22.7559C17.9177 22.7559 22.7343 17.9392 22.7343 12.0191C22.7344 9.14912 21.6177 6.45184 19.5908 4.42583ZM2.19436 12.0191C2.19436 6.61333 6.59179 2.21591 11.9975 2.21591C14.4614 2.21591 16.7778 3.1262 18.5844 4.77126L4.74975 18.6059C3.10466 16.7993 2.19436 14.483 2.19436 12.0191ZM11.9975 21.8223C9.53366 21.8223 7.21731 20.912 5.41072 19.2669L19.2454 5.43231C20.8904 7.2389 21.8007 9.55525 21.8007 12.0191C21.8007 17.4248 17.4033 21.8223 11.9975 21.8223Z"
      fill={props.color ?? "#121212"}
      stroke={props.color ?? "#121212"}
      strokeWidth="1.26316"
    />
  </svg>
)
