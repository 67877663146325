import { HUText } from "@modules/ui/components/huText"
import { colors } from "@modules/ui/styles/colors"

const DEFAULT_BORDER_SIZE = "2px"

export type HuNoshowProps = {
  number: number
  color?: string
  className?: string
} & React.HTMLAttributes<HTMLDivElement>

/**
 * HuNoshow component - Display a number in a circle with a border color
 * @param {HuNoshowProps} props - Component props
 * @param {number} prop.number - The number of no show to display
 * @param {string} [props.color=""] - Force the color of the border, by default it will be yellow for 2 and red for more than 2
 * @param {string} [props.className] - The class name to apply on main div
 * @returns {React.FC<HuNoshowProps>}
 */
const HuNoshow: React.FC<HuNoshowProps> = ({ number = 0, color = "", className, ...props }: HuNoshowProps) => {
  let border = color ? DEFAULT_BORDER_SIZE : "0px"

if (number === 2 && color === "") {
    color = color ? color : colors.yellow500
    border = DEFAULT_BORDER_SIZE
  } else if (number > 2 && color === "") {
    color = color ?  color : colors.red500
    border = DEFAULT_BORDER_SIZE
  }

  const circleStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    border: `${border} solid ${color}`,
  }

  return <HUText className={className} style={circleStyle} {...props}>{number}</HUText>
}

HuNoshow.displayName = "HuNoshow"
export { HuNoshow }
