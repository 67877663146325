import TransactionEmpty from "@images/empty/transactions_empty.svg"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ExportModal } from "@modules/finances/components/exportModal"
import { TransactionTableFilter } from "@modules/finances/components/transactionTableFilter"
import { headerColumn, userTransactionsColumns, valueColumn } from "@modules/finances/components/transactionsColumns"
import { TransactionsDetailsPage } from "@modules/finances/components/transactionsDetailsPage"
import { useTransactions } from "@modules/finances/hooks/useTransactions"
import { allTransactionsStatus } from "@modules/finances/types/financesType"
import { useAsync } from "@modules/hooks/useAsync"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { DataTable, renderTableColumns } from "@modules/ui/components/dataTables/datatable"
import { EmptyView } from "@modules/ui/components/emptyView"
import { ListHeader } from "@modules/ui/components/header"
import { HUButton } from "@modules/ui/components/huButton"
import { HUDropdown } from "@modules/ui/components/huDropdown"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { usePersistedDatatable } from "@modules/utils/hooks/useDataTable"
import { useObservable } from "micro-observables"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTableValue } from "primereact/datatable"

import { useState } from "react"
import styled, { DefaultTheme, useTheme } from "styled-components"

export const UserTransactionsTable: React.FC = () => {
  const { size, setSize } = usePersistedDatatable(20, true)
  const { userFinanceService } = useServicesContext()
  const language = useLanguage()
  const [displayModal, setDisplayModal] = useState(false)
  const lastUpdateLoading = useObservable(userFinanceService.lastUpdateLoading)
  const { value, loading, next, previous } = useTransactions(size)
  const theme = useTheme()
  const { sidebarNavigateTo } = useSidebar()

  const [selectedTransaction, setSelectedTransaction] = useState<DataTableValue>()

  const tableLoading = lastUpdateLoading || loading
  const { value: firstPeriod } = useAsync(() => userFinanceService.getFirstPeriod(), [])
  const hasTransactions = !!firstPeriod

  const sizeOptions = [
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 50, label: "50" },
  ]

  const getRightView = () => {
    return (
      <>
        <TransactionTableFilter filters={["ALL", ...allTransactionsStatus]} />
        <HUButton
          icon={{ iconView: <span className={`p-button-icon p-c pi pi-reply`} /> }}
          type="Left_Icon"
          colorType="Primary"
          size="S"
          text={word("global.export")}
          onClick={() => setDisplayModal(true)}
        />
      </>
    )
  }

  const onSelectedTransaction = (e: DataTableValue) => {
    sidebarNavigateTo({
      content: <TransactionsDetailsPage transactionId={e.id} />,
      onHide: () => {
        setSelectedTransaction(undefined)
        return Promise.resolve(true)
      },
      replaceAllComponents: true,
    })
    setSelectedTransaction(e)
  }

  return (
    <div className="flex flex-column flex-grow-1" style={{ gap: 28 }}>
      {!tableLoading && value.length === 0 && !hasTransactions ? (
        <EmptyView
          image={{ src: TransactionEmpty, background: theme.neutralColor100, padding: "2rem" }}
          title={word("finances.transactions.empty.title")}
          description={word("finances.transactions.empty.message")}
          textAlign="left"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            gap: "2rem",
          }}
        />
      ) : (
        <>
          <ListHeader title={word("finances.page.table.title")} rightView={getRightView()} />
          <StyledDatatable
            value={[...value]}
            dataKey="id"
            paginator
            lazy
            currentPageReportTemplate={""}
            rows={size}
            totalRecords={size}
            onPage={(event) => setSize(event.rows)}
            paginatorTemplate=""
            emptyMessage={word("global.no_result")}
            loading={tableLoading}
            selectionMode="single"
            selection={selectedTransaction}
            onSelectionChange={(e) => onSelectedTransaction(e.value)}
            metaKeySelection={true}
            footer={
              (next || previous) && (
                <FooterRow align gap={14}>
                  <HURow gap={8}>
                    <HUButton
                      type="Default"
                      size="M"
                      colorType="Quaternary"
                      onClick={() => previous?.()}
                      disabled={!previous}
                      text={word("global.previous")}
                    />
                    <HUButton
                      type="Default"
                      size="M"
                      colorType="Quaternary"
                      onClick={() => next?.()}
                      disabled={!next}
                      text={word("global.next")}
                    />
                  </HURow>
                  <Separator />
                  <SearchSize align gap={8}>
                    <HUText fontStyle="LL" color={theme.neutralColor600}>
                      {word("finances.page.table.footer.label")}
                    </HUText>
                    <HUDropdown value={size} options={sizeOptions} onChange={(e) => setSize(e)} itemFontSize="0.9rem" />
                  </SearchSize>
                </FooterRow>
              )
            }
          >
            {renderTableColumns(
              [...userTransactionsColumns],
              headerColumn,
              valueColumn,
              theme as DefaultTheme,
              language,
            )}
            <Column
              body={(transaction) => (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    icon="pi pi-eye"
                    severity="secondary"
                    outlined
                    className="p-button-rounded mr-2"
                    onClick={() => {
                      onSelectedTransaction(transaction)
                    }}
                  />
                </div>
              )}
            />
          </StyledDatatable>
        </>
      )}
      <ExportModal onHide={() => setDisplayModal(false)} visible={displayModal} />
    </div>
  )
}

const StyledDatatable = styled(DataTable)`
  .p-paginator-bottom {
    border: none;
  }
`

const FooterRow = styled(HURow)`
  justify-content: center;

  button {
    min-width: 110px;
  }

  .p-inputtext {
    font-size: 0.9rem;
  }
`

const Separator = styled.div`
  display: flex;
  width: 1px;
  height: 42px;
  background-color: ${({ theme }) => theme.neutralColor200};
`

const SearchSize = styled(HURow)``
