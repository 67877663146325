import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const TabataIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="62" height="63" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="1.5" width="60" height="60" rx="18" fill="#6450F7" />
    <rect x="1" y="1.5" width="60" height="60" rx="18" stroke="url(#paint0_linear_408_84723)" strokeWidth="1.11776" />
    <rect x="12.9999" y="19.6211" width="2" height="24.923" rx="1" fill="white" />
    <rect opacity="0.4" x="19.8106" y="25.4297" width="2" height="12.4615" rx="1" fill="white" />
    <rect x="26.6211" y="19.1992" width="2" height="24.923" rx="1" fill="white" />
    <rect opacity="0.4" x="33.4324" y="25.4297" width="2" height="12.4615" rx="1" fill="white" />
    <rect x="40.2431" y="19.1992" width="2" height="24.923" rx="1" fill="white" />
    <rect opacity="0.4" x="47.0537" y="25.4297" width="2" height="12.4615" rx="1" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_408_84723"
        x1="3.15569"
        y1="2.5"
        x2="43.3973"
        y2="68.4826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.04" />
        <stop offset="0.188137" stopColor="white" stopOpacity="0" />
        <stop offset="0.708289" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.06" />
      </linearGradient>
    </defs>
  </svg>
)
