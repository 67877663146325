import { word } from "@modules/core/utils/i18n"
import { MemberForm } from "@modules/members/components/memberForm"
import { useMember } from "@modules/members/useMember"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { LoadingPage } from "@modules/ui/components/loadingPage"
import { useParams } from "react-router-dom"
import { useTheme } from "styled-components"

export const MemberFormPage: React.FC = () => {
  const { id } = useParams()
  const { result: memberToEdit, loading } = useMember(id)
  const theme = useTheme()

  return (
    <>
      {memberToEdit ? (
        <HUStack gap={30}>
          <HUStack className="col-12" gap={6}>
            <HUText fontStyle="HL">{word("member.form.title.page")}</HUText>
            <HUText fontStyle="BM" color={theme.neutralColor600}>
              {word("member.form.subtitle.page")}
            </HUText>
          </HUStack>
          <MemberForm member={memberToEdit} />
        </HUStack>
      ) : loading ? (
        <LoadingPage />
      ) : null}
    </>
  )
}
