import { HustleupIcon } from "@images/hustleupIcon"
import LoginBackground from "@images/loginBackground.png"
import { AppBanner } from "@modules/appBanner/components/appBanner"
import { useServicesContext } from "@modules/core/services/services.context"
import { LanguageDropdown } from "@modules/language/components/languageDropdown"
import styled, { useTheme } from "styled-components"

type AuthProps = React.HTMLAttributes<HTMLDivElement>

export const AuthPage: React.FC<AuthProps> = ({ children }) => {
  const theme = useTheme()
  const { languageService } = useServicesContext()

  return (
    <>
      <AppBanner />
      <Page>
        <LeftPanelContainer>
          <LeftPanel>
            <BackgroundImage src={LoginBackground} />
          </LeftPanel>
        </LeftPanelContainer>
        <Container>
          <Content>
            <DropdownContainer>
              <LanguageDropdown onChange={(language) => languageService.setUserLanguage(language)} />
            </DropdownContainer>
            <HustleupIcon width={500} height={170} style={{ width: "56%" }} color={theme.neutralColor900} />
            {children}
          </Content>
        </Container>
      </Page>
    </>
  )
}

const Page = styled.main`
  font-size: 16px;
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.mainWhite};
  max-height: 100vh;
`

const Container = styled.div`
  flex: 1;
  padding: 0 10px;
`

const LeftPanelContainer = styled.div`
  width: 55%;
  height: 100vh;
  padding: 18px;
  @media (max-width: 800px) {
    display: none;
  }
`

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 60px;
  overflow: hidden;
`

const BackgroundImage = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 50px 0 40px;
  max-width: 100%;
`

const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 24px;
  right: 24px;
`
