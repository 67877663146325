import { useServicesContext } from "@modules/core/services/services.context"
import { FeatureKeys } from "@modules/featureToggle/featureToggleTypes"
import { useFeatureToggle } from "@modules/featureToggle/hooks/useFeatureToggle"
import { getDefaultLanguage } from "@modules/language/languageUtils"
import { Language } from "@modules/language/utils/languageUtils"
import { useObservable } from "micro-observables"

export const useLanguage = (): Language => {
  const allLanguagesAvailable = useFeatureToggle(FeatureKeys.AllLanguages)
  const { languageService } = useServicesContext()
  return (
    useObservable(languageService.language.select((language) => language.languageCode)) ??
    getDefaultLanguage(allLanguagesAvailable)
  )
}
