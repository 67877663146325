import { ProgramInformation } from "@modules/programs/components/programHeader/programInformation"
import { ProgramInfinite } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOff } from "@modules/programs/programOnOffTypes"
import { HURow } from "@modules/ui/components/layout"
import styled from "styled-components"

export const ProgramHeaderCenterView: React.FC<{
  program?: ProgramInfinite | ProgramOnOff
}> = ({ program }) => {
  return (
    <>
      <Container className="mb-5 col-6 p-3" $bgImage={program?.image.path}>
        {program && <ProgramInformation program={program} />}
      </Container>
    </>
  )
}

const Container = styled(HURow)<{ $bgImage?: string }>`
  border-radius: 33px;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.secondaryColor900} 0%, ${theme.secondaryColor700} 100%)`};
  opacity: 1;
  position: relative;

  &::before {
    background-image: ${({ $bgImage }) => `url(${$bgImage})`};
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(1) blur(3px);
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    border-radius: 33px;
  }

  border-width: 0px;
  color: ${({ theme }) => theme.mainWhite};
  height: 200px;

  > * {
    z-index: 1;
  }
`
