import SessionEmpty from "@images/empty/session_empty.svg"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { usePrograms } from "@modules/programs/hooks/usePrograms"
import { DetailedSessionType } from "@modules/sessions/components/programSessionDetailsComponent"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { TrainingCalendar } from "@modules/training/components/trainingCalendar"
import { TrainingHeader } from "@modules/training/components/trainingHeader"
import { usePlanningSessionEvents } from "@modules/training/useTraining"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { minScreenWidth } from "@modules/ui/uiConstants"
import React, { useState } from "react"
import styled, { useTheme } from "styled-components"

export const TrainingContent: React.FC = () => {
  const [openedSession, setOpenedSession] = useState<DetailedSessionType>()

  const { result: ownedPrograms, loading: loadingOwnedPrograms } = usePrograms({ programFilter: "OWNED" })
  const { result: subscribedPrograms, loading: loadingSubscribedPrograms } = usePrograms({
    programFilter: "SUBSCRIBED",
  })

  const { sessions, isLoading: isLoadingSessions } = usePlanningSessionEvents()
  const { refreshCalendarEvents } = useCalendar()
  const theme = useTheme()
  const { navigateToProgramCreation } = useProgramSidebar()
  const { navigateToSessionView } = useSessionSidebar()
  const mySpaceNavigate = useMySpaceNavigate()

  const showEmptyView =
    !loadingOwnedPrograms && !loadingSubscribedPrograms && !(ownedPrograms.length > 0 || subscribedPrograms.length > 0)

  const onClickOnSlot = (slot: DetailedSessionType) => {
    navigateToSessionView(slot, refreshCalendarEvents, () => {
      setOpenedSession(undefined)
      return Promise.resolve(true)
    })
    setOpenedSession(slot)
  }

  const onCreateProgramClick = () => {
    navigateToProgramCreation()
  }

  const onDiscoverClick = () => {
    mySpaceNavigate(Path.MySpace.Explore)
  }

  return (
    <Container className="grid flex-grow-1">
      <div className="col-12 flex-grow-1 flex flex-column" style={{ minWidth: minScreenWidth }}>
        <TrainingHeader />
        {showEmptyView ? (
          <div
            className="flex justify-content-center align-items-center flex-grow-1"
            style={{ backgroundColor: theme.neutralColor100, borderRadius: 33 }}
          >
            <EmptyView
              image={{ src: SessionEmpty, padding: "0" }}
              title={word("planning.empty.title")}
              description={word("planning.empty.description")}
              flexGrow={false}
              className="p-6"
              disposition="row"
              style={{ backgroundColor: theme.mainWhite }}
              paddingType="In_table"
              imagePosition="after"
              textAlign="left"
            >
              <HURow className="align-items-center gap-3">
                <HUButton
                  type="Left_Icon"
                  colorType="Primary"
                  size="S"
                  icon={{ iconView: <span className={`p-button-icon p-c pi pi-search`} /> }}
                  onClick={onDiscoverClick}
                  text={word("programs.discover")}
                />
                <HUText fontStyle="LL">{word("global.or")}</HUText>
                <HUButton
                  type="Left_Icon"
                  size="S"
                  colorType="Secondary"
                  icon={{ iconView: <span className={`p-button-icon p-c pi pi-plus`} /> }}
                  onClick={onCreateProgramClick}
                  text={word("program.form.addProgram.title")}
                />
              </HURow>
            </EmptyView>
          </div>
        ) : (
          <TrainingCalendar
            sessions={sessions}
            onClickOnSlot={onClickOnSlot}
            detailedSessionId={openedSession?.id}
            isLoadingSessions={isLoadingSessions}
          />
        )}
      </div>
    </Container>
  )
}

const Container = styled.div`
  min-width: 980px;
`
