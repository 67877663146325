import { ArticleImage, ArticleVideo } from "@modules/article/entities/articleEntity"
import { YouTubeVideo } from "@modules/utils/components/youTubeVideo"
import { useEffect, useState } from "react"

export const ContentImage = (imageNode: { value: ArticleImage }) => {
  const articleImage = imageNode.value
  const [maxImageWidth, setMaxImageWidth] = useState(100)

  useEffect(() => {
    const splittedImgUrl = articleImage.uri.split("-")
    const dimensions = splittedImgUrl[splittedImgUrl.length - 1]

    if (dimensions.split("x").length === 2) {
      setMaxImageWidth(parseInt(dimensions.split("x")[0]))
    }
  }, [articleImage])

  return (
    <img
      src={articleImage.uri}
      alt={articleImage.alt_text}
      className="w-full h-full mx-auto my-4 block"
      style={{
        borderRadius: 33,
        maxWidth: maxImageWidth,
      }}
    />
  )
}

export const ContentVideo = (videoNode: { value: ArticleVideo }) => {
  const articleVideo = videoNode.value

  return (
    <div className="my-4">
      <YouTubeVideo link={articleVideo.uri} width={480} height={270} />
    </div>
  )
}
