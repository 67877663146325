import { WebService } from "@modules/core/services/webService"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"
import { observable } from "micro-observables"

const DEFAULT_VALUE = { hidden: false }
type Visibility = typeof DEFAULT_VALUE

export class AppBannerService implements WebService {
  private _visibility = observable<Visibility>(DEFAULT_VALUE)
  readonly visibility = this._visibility.readOnly()
  constructor(private localStorage: LocalStorageService) {}

  async init(): Promise<() => void> {
    const unsubscribe = this.localStorage.connect(
      this._visibility,
      LocalStorageKeys.appBanner.visibility,
      DEFAULT_VALUE,
    )
    return unsubscribe
  }

  hideBanner(): void {
    this._visibility.set({ hidden: true })
  }

  async onLoggedOut(): Promise<void> {
    this._visibility.set(DEFAULT_VALUE)
  }
}
