import { useQueryParam, useSetMultiParams } from "@modules/utils/navigation/useQueryParam"
import _ from "lodash"
import { useEffect, useState } from "react"

const ListPageSize = "pageSize"
const ListPage = "page"

export const usePersistedDatatable = (defaultPageSize?: number, hidePage = false) => {
  const [listPageSize] = useQueryParam(ListPageSize)
  const [listPage] = useQueryParam(ListPage)
  const setListParams = useSetMultiParams()

  const [page, setPage] = useState<number>(listPage && !isNaN(+listPage) ? Number(listPage) : 0)
  const [size, setSize] = useState<number>(
    listPageSize && !isNaN(+listPageSize) ? Number(listPageSize) : defaultPageSize ?? 10,
  )

  useEffect(() => {
    setListParams(
      _.compact([
        {
          key: ListPageSize,
          value: size.toString(),
        },
        !hidePage
          ? {
              key: ListPage,
              value: page.toString(),
            }
          : null,
      ]),
    )
  }, [page, size])

  return { page, setPage, size, setSize }
}
