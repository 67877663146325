import { BenchmarkIcon } from "@images/svgIcons/benchmarkIcon"
import { BenchmarkExercise } from "@modules/benchmark/components/benchmarkExercise"
import { useBenchmark } from "@modules/benchmark/hooks/useBenchmark"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { Tag } from "primereact/tag"
import styled, { useTheme } from "styled-components"
type BenchmarkInfoResumeProps = {
  benchmarkId: string
}

export const BenchmarkInfoResume: React.FC<BenchmarkInfoResumeProps> = ({ benchmarkId }) => {
  const theme = useTheme()
  const { result: benchmark } = useBenchmark(benchmarkId)

  return (
    <>
      {benchmark ? (
        <Container className="flex flex-column flex-grow-1 mt-2">
          <TypeContainer rounded icon={<BenchmarkIcon color={"white"} className="mr-2" />}>
            <HUText fontStyle="LM" color={theme.mainWhite}>
              {word("global.benchmark.name")}
            </HUText>
          </TypeContainer>
          <TitleContainer>
            <HUText fontStyle="HM" className="mb-2">
              {benchmark.title}
            </HUText>
            <HUText fontStyle="BM" color={theme.neutralColor700}>
              {benchmark.category}
            </HUText>
          </TitleContainer>
          <Separator />
          <HUText fontStyle="LS" color={theme.neutralColor700}>{`(1) ${word(
            "program.planning.create.exercise.block",
          ).toUpperCase()} `}</HUText>
          <BenchmarkExercise benchmark={benchmark} />
        </Container>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

const Container = styled.div`
  padding: 25px;
`
const TypeContainer = styled(Tag)`
  align-items: center !important;
  background-color: rgba(28, 28, 28, 0.8);

  &.p-tag {
    padding: 9px 14px 9px 10px;
    height: 34px;
    width: 115px;
    display: flex;
  }
`

const TitleContainer = styled.div`
  margin-top: 34px;
  display: flex;
  flex-direction: column;
`
const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 34px;
  background-color: ${({ theme }) => theme.neutralColor200};
`
