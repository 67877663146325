import { TrainingDateSelector } from "@modules/training/components/trainingDateSelector"
import { TrainingInformation } from "@modules/training/components/trainingInformation"
import { TrainingWhatsNext } from "@modules/training/components/trainingWhatsNext"
import { HURow } from "@modules/ui/components/layout"

export const TrainingHeader: React.FC = ({}) => {
  return (
    <HURow className="justify-content-between gap-1 lg:gap-2 xl:gap-3">
      <TrainingDateSelector />
      <TrainingInformation />
      <TrainingWhatsNext />
    </HURow>
  )
}
