import { useUser } from "@modules/auth/hooks/useUser"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { Program, SharedCoach, SharedCoachRequestAction, SharedCoachStatus } from "@modules/programs/programTypes"
import { SharedCoachView } from "@modules/sharedCoach/components/sharedCoachView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { useState } from "react"
import styled, { useTheme } from "styled-components"

type SharedCoachRequestProps = {
  program: Program
  currentSharedCoach?: SharedCoach
}

export const SharedCoachRequest = ({ program, currentSharedCoach }: SharedCoachRequestProps) => {
  const theme = useTheme()
  const user = useUser()
  const toast = useToast()
  const { programService } = useServicesContext()
  const mySpaceNavigate = useMySpaceNavigate()
  const [isRequest, setIsRequest] = useState(false)

  const navigateTo = (action: SharedCoachRequestAction) => {
    const mySpace = Path.MySpace
    switch (action) {
      case SharedCoachRequestAction.ACCEPT:
      case SharedCoachRequestAction.REMOVE:
        return program._programType === ProgramInfiniteTypeOf
          ? mySpace.ProgramDetails(program.id)
          : mySpace.ProgramOnOffDetails(program.id)
      case SharedCoachRequestAction.REFUSE:
      case SharedCoachRequestAction.QUIT:
        return mySpace.Training
    }
  }

  const requestSharedCoach = async (action: SharedCoachRequestAction) => {
    if (user?.id) {
      setIsRequest(true)
      await programService
        .requestSharedCoach(program.id, program._programType, action, user.id)
        .then(() => {
          toast.show({
            severity: "success",
            detail: word(`program.sharedCoach.action.request.${action}.success`),
          })
        })
        .catch(() => {
          toast.show({
            severity: "error",
            detail: word(`program.sharedCoach.action.request.error`),
          })
        })
        .finally(() => {
          mySpaceNavigate(navigateTo(action))
          setIsRequest(false)
        })
    }
  }

  if (currentSharedCoach?.status === SharedCoachStatus.PENDING) {
    return (
      <SharedCoachContainer>
        <HUText fontStyle="BL">{word("program.sharedCoach.action.title")}</HUText>
        <HUText fontStyle="BM" color={theme.neutralColor700}>
          {word("program.sharedCoach.requested", {
            firstname: program.author.firstname,
            lastname: program.author.lastname,
          })}
        </HUText>
        <HURow gap={10}>
          <HUButton
            type="Rounded"
            size="M"
            colorType="Tertiary"
            className="w-full"
            text={word("program.sharedCoach.action.refuse")}
            loading={isRequest}
            onClick={async () => await requestSharedCoach(SharedCoachRequestAction.REFUSE)}
          />
          <HUButton
            type="Rounded"
            size="M"
            colorType="Primary"
            className="p-button-primary w-full"
            text={word("program.sharedCoach.action.accept")}
            autoFocus
            loading={isRequest}
            onClick={async () => await requestSharedCoach(SharedCoachRequestAction.ACCEPT)}
          />
        </HURow>
      </SharedCoachContainer>
    )
  } else if (currentSharedCoach?.status === SharedCoachStatus.ACCEPTED) {
    return (
      <SharedCoachContainer>
        <HUText fontStyle="BL">{word("program.sharedCoach.action.title")}</HUText>
        <HUText fontStyle="BM" color={theme.neutralColor700}>
          {`${word("program.sharedCoach.isCurrent")} ${program.sharedCoaches && program.sharedCoaches.length > 1 ? `${word("global.with")} :` : ""}`}
        </HUText>
        {program.sharedCoaches
          ?.filter((coach) => coach.user.id !== user?.id && coach.status === SharedCoachStatus.ACCEPTED)
          .map((coach) => <SharedCoachView key={coach.user.id} profile={coach.user} status={coach.status} />)}
        <HUButton
          text={word("program.sharedCoach.action.quit")}
          colorType="Tertiary"
          size="M"
          onClick={async () => await requestSharedCoach(SharedCoachRequestAction.QUIT)}
          type="Rounded"
          className="w-full"
          loading={isRequest}
        />
      </SharedCoachContainer>
    )
  }
}

const SharedCoachContainer = styled(HUStack)`
  border-radius: 33px;
  background-color: ${({ theme }) => theme.neutralColor100};
  border-width: 0;
  padding: 22px;
  margin-bottom: 24px;
`
