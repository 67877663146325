import { ApiClient } from "@modules/core/api/client/apiClient"

export class ChatApi {
  private baseUrl = "/chats"

  constructor(private api: ApiClient) {}

  async getChatToken(): Promise<string> {
    const result = await this.api.post<{ token: string }>(`${this.baseUrl}/token`)
    return result.data.token
  }
}
