import { useMember } from "@modules/members/useMember"
import { TabMenu } from "primereact/tabmenu"
import { useState } from "react"
import { MenuItem } from "primereact/menuitem"
import { MemberTabDayToDay } from "@modules/members/components/memberTabDayToDay"
import { MemberTabProfile } from "@modules/members/components/memberTabProfile"
import { word } from "@modules/core/utils/i18n"
import { HuAvatarCardSidebar } from "@modules/ui/components/huAvatarCardSidebar"
import { Member } from "@modules/members/memberTypes"


export const MemberDetailsInfo: React.FC<{
  member: Member
}> = ({ member }) => {

  const { result: memberDetailed } = useMember(member.id)
  // const { result: purchases } = useAllMemberPurchases(memberId)
  // const { result: user } = useUserProfile(memberId)

  const [activeIndex, setActiveIndex] = useState(0)
  const items: MenuItem[] = [
    { label: word("box.member.detailsInfo.dayToDay") },
    { label: word("box.member.detailsInfo.profile") },
    // { label: word("box.member.detailsInfo.offers") },
  ]

  return (
    <>
      {/* HEADER */}
      {memberDetailed && <HuAvatarCardSidebar member={memberDetailed} />}
      {/* NAVIGATION TABS */}
      <div className="flex w-full mt-3">
        <TabMenu
          model={items}
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          className="w-full"
        />
      </div>
      {activeIndex === 0 && memberDetailed && <MemberTabDayToDay member={member} memberDetailed={memberDetailed} />}
      {activeIndex === 1 && memberDetailed && <MemberTabProfile memberDetailed={memberDetailed} />}
      {/* {activeIndex === 2 && member && <MemberTabOffers member={member} />} */}
    </>
  )
}
