import { Link, LinkProps } from "react-router-dom"
import styled, { useTheme } from "styled-components"

type Props = {
  piIcon: string
} & LinkProps

export const RoundLinkIcon: React.FC<Props> = ({ piIcon, ...other }) => {
  const theme = useTheme()
  return (
    <LinkStyled {...other}>
      <i className={`pi ${piIcon}`} style={{ color: theme.buttonPrimaryTextColor }} />
    </LinkStyled>
  )
}

const size = 38

const LinkStyled = styled(Link)`
  width: ${size}px;
  height: ${size}px;
  background-color: ${({ theme }) => theme.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${size}px;
`
