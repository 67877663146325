import { WinningCupIcon } from "@images/svgIcons/winningCupIcon"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { getMySpacePath, useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { useDetailedProgramSession } from "@modules/programSession/hooks/useProgramSessions"
import { useProgram } from "@modules/programs/hooks/usePrograms"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { SessionDetailsContent } from "@modules/sessions/components/sessionDetailsContent"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { minScreenWidth } from "@modules/ui/uiConstants"
import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"

export type DetailedSessionType = {
  id: string
  date?: string
  programId: string
  programType: typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf
}

type ProgramSessionDetailsComponentProps = {
  editedSession?: DetailedSessionType
  refreshTraining?: () => void
  allowEditContent: boolean
}
export const ProgramSessionDetailsComponent: React.FC<ProgramSessionDetailsComponentProps> = ({
  editedSession,
  refreshTraining,
  allowEditContent,
}) => {
  const [displayCongratulations, setDisplayCongratulations] = useState<boolean>(false)

  const {
    loading: loadingSession,
    value: session,
    refresh: refreshSession,
  } = useDetailedProgramSession(
    editedSession?.id ?? "",
    editedSession?.date,
    editedSession?.programId,
    editedSession?.programType,
  )

  const { result: program } = useProgram(editedSession?.programId, editedSession?.programType)
  const confirmPopup = useConfirmPopup()
  const { programSessionService } = useServicesContext()
  const mySpaceNavigate = useMySpaceNavigate()
  const { hideSidebar } = useSidebar()
  const { navigateToSessionForm } = useSessionSidebar()
  const { setEditedSession } = useSessionFormContext()

  const editSession = () => {
    if (session) {
      setEditedSession(session)
      navigateToSessionForm(false, false, refreshTraining)
    }
  }

  const deleteSessionConfirmationPopup = () => {
    confirmPopup.show({
      title: word("sessionDetailed.deleteSession.popup.title"),
      message: word("boxSessionDetailed.deleteSession.popup.message", {
        sessionName: session?.name,
      }),
      accept: async () => {
        session && program && (await programSessionService.deleteSession(session.id, program.id, program._programType))
        hideSidebar()
        if (refreshTraining) refreshTraining()
      },
      footerProps: {
        align: true,
      },
    })
  }

  const isLastOnOffProgramSession =
    program &&
    session &&
    program._programType === ProgramOnOffTypeOf &&
    "index" in session &&
    program.duration === session.index + 1

  const isSessionInitiallyCompleted = useMemo(
    () => session && session.exercises.length > 0 && session.exercises.every((exercise) => exercise.isCompleted),
    [session?.id],
  )
  const isSessionCompleted = useMemo(
    () => session && session.exercises.length > 0 && session.exercises.every((exercise) => exercise.isCompleted),
    [program, session?.exercises],
  )

  useEffect(() => {
    if (isLastOnOffProgramSession && !isSessionInitiallyCompleted && isSessionCompleted) {
      setDisplayCongratulations(true)
    }
  }, [isSessionCompleted])

  return (
    <div className="grid flex-grow-1">
      <div className="col-12 flex-grow-1" style={{ minWidth: minScreenWidth }}>
        {session && program ? (
          <SessionDetailsContent
            session={session}
            program={program}
            programPath={getMySpacePath(
              program._programType === ProgramOnOffTypeOf
                ? Path.MySpace.ProgramOnOffDetails(program.id)
                : Path.MySpace.ProgramDetails(program.id),
            )}
            allowEditContent={allowEditContent}
            onEdit={editSession}
            onDeleteSessionConfirmationPopup={deleteSessionConfirmationPopup}
            refreshSession={refreshSession}
          />
        ) : loadingSession ? (
          <LoadingSpinner />
        ) : (
          <ErrorContainer>
            <HUText>{word("global.error.default")}</HUText>
          </ErrorContainer>
        )}
      </div>

      <HUDialog
        visible={displayCongratulations}
        header={null}
        onHide={() => setDisplayCongratulations(false)}
        style={{ width: "420px", borderRadius: 33, overflow: "hidden" }}
        headerClassName="hidden"
      >
        <HUStack className="align-items-center mt-3">
          <WinningCupIcon width={150} height={150} color="" />
          <HUText fontStyle="TL">{word("program.onOff.completed.congratulations.title")}</HUText>
          <HUText fontStyle="BM" className="mx-3 text-center">
            {word("program.onOff.completed.congratulations.content")}
          </HUText>
          <HURow className="gap-2">
            <HUButton
              text={word("program.onOff.completed.congratulations.button.close")}
              type="Default"
              size="M"
              colorType="Underline"
              onClick={() => setDisplayCongratulations(false)}
            />
            <HUButton
              text={word("program.onOff.completed.congratulations.button.explore")}
              type="Default"
              size="M"
              colorType="Primary"
              onClick={() => mySpaceNavigate(Path.MySpace.Explore)}
            />
          </HURow>
        </HUStack>
      </HUDialog>
    </div>
  )
}

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
`
