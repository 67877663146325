import { ChatToast } from "@modules/chat/components/chatToast"
import { ChatPageProvider } from "@modules/chat/contexts/chatPageContext"
import { useChatClient, useChatClientLastMessage } from "@modules/chat/hooks/useChatClient"
import { InitStreami18n } from "@modules/chat/utils/streami18n"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { Path } from "@modules/navigation/routes"
import { useToast } from "@modules/ui/components/huToast"
import { PropsWithChildren, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { DefaultGenerics, TranslationLanguages } from "stream-chat"
import { Chat } from "stream-chat-react"

export const ChatAppWrapper = ({ children }: PropsWithChildren) => {
  const { chatClient } = useChatClient()
  const languageCode = useLanguage() as TranslationLanguages
  const streami18n = useMemo(() => InitStreami18n(languageCode), [languageCode])
  const { lastMessage } = useChatClientLastMessage()

  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const onMessageClick = () => {
    toast.clear()
    navigate(Path.Chat.Root)
  }

  useEffect(() => {
    if (lastMessage?.text && lastMessage.user?.name && !location.pathname.includes(Path.Chat.Root)) {
      toast.show({
        severity: "info",
        content: (
          <ChatToast
            authorName={lastMessage.user.name}
            image={lastMessage.user.image as string}
            message={lastMessage.text}
            onClick={onMessageClick}
          />
        ),
        life: 5000,
      })
    }
  }, [lastMessage])

  return (
    <>
      {chatClient ? (
        <Chat<DefaultGenerics> client={chatClient} i18nInstance={streami18n}>
          <ChatPageProvider>{children}</ChatPageProvider>
        </Chat>
      ) : (
        children
      )}
    </>
  )
}
