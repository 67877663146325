import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PasteIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.34229 9.1397H0.583451C0.264565 9.1397 0.00012207 8.87525 0.00012207 8.55637C0.00012207 8.23748 0.264565 7.97304 0.583451 7.97304H7.34229L6.00453 6.63527C5.77897 6.40971 5.77897 6.03638 6.00453 5.81083C6.23008 5.58528 6.60341 5.58528 6.82896 5.81083L9.16228 8.14415C9.21672 8.19859 9.25561 8.26081 9.28672 8.33081C9.34895 8.47081 9.34895 8.63414 9.28672 8.77414C9.25561 8.84414 9.21672 8.90636 9.16228 8.96081L6.82896 11.2941C6.7123 11.4108 6.56452 11.4652 6.41674 11.4652C6.26897 11.4652 6.12119 11.4108 6.00453 11.2941C5.77897 11.0686 5.77897 10.6952 6.00453 10.4697L7.34229 9.13192V9.1397ZM14 5.05639V11.6675C14 12.8497 13.0434 13.8063 11.8612 13.8063H6.02786C4.84564 13.8063 3.88898 12.8497 3.88898 11.6675V10.8897C3.88898 10.5708 4.15343 10.3064 4.47231 10.3064C4.7912 10.3064 5.05564 10.5708 5.05564 10.8897V11.6675C5.05564 12.2041 5.4912 12.6397 6.02786 12.6397H11.8612C12.3978 12.6397 12.8334 12.2041 12.8334 11.6675V5.63972H9.13895C8.82006 5.63972 8.55562 5.37528 8.55562 5.05639V1.36197H6.02786C5.4912 1.36197 5.05564 1.79752 5.05564 2.33419V6.22305C5.05564 6.54194 4.7912 6.80638 4.47231 6.80638C4.15343 6.80638 3.88898 6.54194 3.88898 6.22305V2.33419C3.88898 1.15197 4.84564 0.195312 6.02786 0.195312H9.13895C9.2945 0.195312 9.44228 0.257534 9.55117 0.366422L13.8289 4.64417C13.9378 4.75306 14 4.90084 14 5.05639ZM9.72228 4.47306H12.0089L9.72228 2.18641V4.47306Z"
      fill={props.color}
    />
  </svg>
)
