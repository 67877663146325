import Resizer from "react-image-file-resizer"

export const MAX_IMAGE_WIDTH = 1024
export const MAX_SIZE = 10 * MAX_IMAGE_WIDTH * MAX_IMAGE_WIDTH // 10Mo

export const readFile = (file: File) =>
  new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const result = event.target?.result
      if (result && typeof result === "string") {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [contentType, content] = result.split(",")
        resolve(content)
      } else {
        resolve(null)
      }
    }

    reader.onerror = reject
    reader.readAsDataURL(file)
  })

export const resizeImage = (file: File): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      MAX_IMAGE_WIDTH,
      MAX_IMAGE_WIDTH,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri as File)
      },
      "file",
    )
  })
