import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const SetsIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1.3313" y="0.664062" width="2.44091" height="14.6455" rx="1.22046" fill={props.color} />
    <rect x="5.32568" y="0.664062" width="2.44091" height="14.6455" rx="1.22046" fill={props.color} />
    <rect
      x="8.79053"
      y="1.21094"
      width="2.442"
      height="14.6446"
      rx="1.221"
      transform="rotate(-15 8.79053 1.21094)"
      fill={props.color}
    />
  </svg>
)
