import { UserErrorCodes } from "@modules/auth/authErrorTypes"
import { AuthContent, AuthForm, AuthHeader } from "@modules/auth/components/authComponents"
import { AuthPage } from "@modules/auth/page/authPage"
import { RegisterFormFourPage } from "@modules/auth/page/registerFormFourPage"
import { RegisterFormOnePage } from "@modules/auth/page/registerFormOnePage"
import { RegisterFormThreePage } from "@modules/auth/page/registerFormThreePage"
import { RegisterFormTwoPage } from "@modules/auth/page/registerFormTwoPage"
import {
  ProfileFormikType,
  ProfileInputKey,
  convertProfileFormFormik,
  convertProfileToFormik,
  getValidationSchema,
} from "@modules/auth/profileFormikTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { Path } from "@modules/navigation/routes"
import { convertProfileToDraft } from "@modules/profile/profileTypes"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

export const RegisterPage: React.FC = () => {
  const { userService } = useServicesContext()
  const [error, setError] = useState("")
  const [searchParams, setSearchParams] = useSearchParams()
  const popup = useConfirmPopup()
  const navigate = useNavigate()

  const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page") ?? 1))

  useEffect(() => {
    if (searchParams.get("page")) {
      setPageNumber(Number(searchParams.get("page")))
    } else {
      setSearchParams({ page: pageNumber.toString() })
    }
  }, [searchParams])

  useEffect(() => {
    setSearchParams({ page: pageNumber.toString() })
  }, [pageNumber])

  const navigateForm = (onNext: boolean) => {
    setPageNumber((prev) => {
      const newPage = onNext ? prev + 1 : prev - 1
      return newPage
    })
  }
  const formik = useFormik<ProfileFormikType>({
    initialValues: convertProfileToFormik(convertProfileToDraft()),
    validationSchema: getValidationSchema(pageNumber),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (pageNumber < 4) {
        navigateForm(true)
        return
      }
      try {
        await userService.register(convertProfileFormFormik(values))
      } catch (e) {
        const signUpError = e as any
        console.error(LoggerType.SignUp + "register", e)
        if (signUpError.code === UserErrorCodes.UsernameAlreadyExists) {
          setError(word("auth.error.username_exists"))
        } else {
          setError(word("global.error.default"))
        }
      }
    },
  })

  const handleClose = () =>
    popup.show({
      title: word("auth.signup.form.leaveSignUp.title"),
      message: word("auth.signup.form.leaveSignUp.description"),
      accept: async () => navigate(Path.Root),
      footerProps: {
        align: true,
        confirmClassName: "flex-1",
        cancelClassName: "flex-1",
        footerClassName: "flex flex-row justify-content-around w-full",
      },
    })

  const FormPage = () => {
    const props = {
      handleSubmit: formik.handleSubmit,
      onPrevious: pageNumber === 1 ? handleClose : () => navigateForm(false),
      isFormFieldInValid: (input: ProfileInputKey) => isFormFieldInValid(input, formik),
      getFormErrorMessage: (input: ProfileInputKey) => getFormErrorMessage(input, formik),
      disabled: !getValidationSchema(pageNumber).isValidSync(formik.values),
      formik: {
        values: formik.values,
        getFieldProps: formik.getFieldProps,
        setFieldValue: formik.setFieldValue,
        validateField: formik.validateField,
        handleBlur: async (e: React.FocusEvent, fieldName: string) => {
          await formik.handleBlur(e)
          formik.validateField(fieldName)
        },
        handleChange: async (e: React.ChangeEvent, fieldName: string) => {
          await formik.handleChange(e)
          formik.validateField(fieldName)
        },
      },
    }
    switch (pageNumber) {
      case 1:
        return <RegisterFormOnePage {...props} />
      case 2:
        return <RegisterFormTwoPage {...props} />
      case 3:
        return <RegisterFormThreePage {...props} />
      case 4:
        return <RegisterFormFourPage {...props} error={error} setError={setError} />
      default:
        return null
    }
  }
  return (
    <AuthPage>
      <AuthForm>
        <AuthHeader title={word("auth.signup.form.title")} subtitle={word("auth.signup.form.enteringCommunity")} />
        <AuthContent>{FormPage()}</AuthContent>
      </AuthForm>
    </AuthPage>
  )
}
