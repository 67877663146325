import { LayoutContext } from "@modules/app/context/layoutcontext"
import { useContext, useEffect } from "react"

export const AppConfig = () => {
  const { layoutConfig } = useContext(LayoutContext)

  const applyScale = () => {
    document.documentElement.style.fontSize = layoutConfig.scale + "px"
  }

  useEffect(() => {
    applyScale()
  }, [layoutConfig.scale])

  return <></>
}
