export type UseCharterType = {
  index?: number
  title?: string
  content?: string
  items?: UseCharterType[]
}

type UseCharterDataByLanguage = {
  title: string
  effectiveDateLabel: string
  effectiveDateValue: string
  terms: UseCharterType[]
}

type UseCharterData = {
  en: UseCharterDataByLanguage
  fr: UseCharterDataByLanguage
}

export const useCharterData: UseCharterData = {
  en: {
    title: `APPLICATION USER CHARTER`,
    effectiveDateLabel: "Effective date: ",
    effectiveDateValue: "June 11, 2024",
    terms: [
      {
        content: `Hustle-Up enables its members, whether professionals or private individuals, to sign up for sports lessons with sports coaches, to follow their sports activities, to create a training program accessible to other members or to manage the running of a gym, through the application available via the website https://sport.hustleup-app.com/login or on the stores, provided that it is used in compliance with the conditions of this charter of use.`,
      },
      {
        index: 1,
        title: "Main operating principles",
        items: [
          {
            content: `All the clauses, rights and obligations of the present charter of use are acknowledged as accepted by each member and user, by ticking the box “I accept the Charter of use”.`,
          },
          {
            index: 1,
            title: "Editing",
            content: `<ul><li>The application can in no way be held responsible for the comments and behavior of members;</li>
<li>Respect the debate, respect your interlocutor and other readers;</li>
<li>Do not send defamatory, abusive, violent or emotionally-charged messages on any subject. The physical absence of your interlocutor can increase misunderstandings and tensions. Even if you're alone in front of the application.</li>`,
          },
          {
            index: 2,
            title: "Moderation of message content",
            content: ` <ul><li>This exchange forum is designed to be simple and flexible for users. Institutional editorial codes are relaxed, but the rules of courtesy and politeness apply to everything you do.</li>
  <li>Insulting or aggressive comments have no place here: refrain from personal attacks.</li>
  <li>Accusations without proof, distortion of facts, lies, disinformation, extremist or excessively partisan polemics, propaganda (direct or indirect), as well as any form of proselytism or dogmatism, are not accepted.</li>
  <li>Racist, antisemitic, pornographic, revisionist, sexist comments and, more generally, any subject that is contrary to the law or defamatory in nature, will not be tolerated and may result in the closure of your user account.</li>
  <li>Check the source of the information you post to avoid misinformation and rumors.</li>
  <li>Take care not to waste your time engaging in debate with trolls.</li>
  <li>The application's moderators are authorized to delete any publication or comment that does not comply with the rules set out in this charter.</li></ul>`,
          },
        ],
      },
      {
        index: 2,
        title: "Rules on data protection",
        items: [
          {
            content: `The collection of your personal data is essential for the operation of the application. Your data is notably used to create your user account, publish content, consult your profile, organize and create a page. This processing is based on the data controller's legitimate interest in the organization of the company.
Information is accessible only to members of Health Tech & Co, SAS with share capital of 1,000 euros, registered with the Lyon Trade and Companies Register under no. 950 774 166, whose registered office is located at 22 BD DES DROITS DE L'HOMME, 69120 VAULX-EN-VELIN. 
Upon first request, user account data may be deleted. The remaining content will then be anonymized. 
You have the right to access, rectify, object to, limit the processing of and delete data concerning you. 
If you consider that your rights have been violated, you may lodge a complaint with the CNIL.`,
          },
        ],
      },
      {
        index: 3,
        title: "Registration and account management",
        content: `<ul><li>Access to the application requires a working Internet connection.</li>
  <li>At the end of the registration procedure, your user account will be created, and you will receive confirmation of your login details by e-mail.</li>
  <Il>You acknowledge that this user account is the equivalent of your identity card for the application. It is therefore your responsibility to update and keep up to date the information relating to your user account and your profile (job title, professional contact details, etc.).</li>
  <li>You are solely responsible for protecting the confidentiality of your account and password, and for any actions that may be taken in your name as a result of their use.</li>
  <li>You agree to take all necessary steps to maintain the confidentiality of your user account, and to immediately inform the application of any fraudulent use of this account by unauthorized third parties.</li>
  <li>You agree to change your password regularly.</li>
  <li>The application cannot be held responsible for any loss of data or damage of any kind resulting from a breach of these obligations.</li></ul>
        `,
      },
      {
        index: 4,
        title: "Professional know-how",
        content: `<ul><li>You agree to be truthful in the presentation of your qualifications (coach, trainer, etc.);</li>
  <li>It is forbidden to engage in any illicit professional activity via the application;</li>
  <li>You are responsible for implementing respectful commercial practices.</li></ul>
`,
      },
      {
        index: 5,
        title: "Uploading, editing and deleting content",
        content: `<ul><li>The application allows you to upload digital content (comments, texts, keywords, images, etc.), provided that such content is not illegal, nor likely to infringe public order or the rights of third parties.</li>
  <li>The application reserves the right, as of right and without prior justification, not to publish, to moderate or to delete content that violates these prohibitions, in particular content that it deems insulting to itself, defamatory of other members, that disparages products and services, or that could damage a brand image or reputation.</li>
  <li>The publication of content provided by users is in no way a right; the application remains entirely free to publish or not this content in accordance with its editorial responsibility.</li>
  <li>Notwithstanding this reservation, you understand and accept that the distribution of such content is public and not secret. Consequently, your content may be read by others without your knowledge. You must therefore be careful about the information you publish on the application (particularly in open groups), especially with regard to any personally identifying information about yourself or your family and friends.</li>
  <li>The application contains a number of hypertext links to other sites. However, the application is not in a position to check the content of sites visited in this way, and consequently accepts no liability in this respect.</li>
  <li>Information stored, exchanged and disseminated on the application's public groups must rigorously exclude that which is classified as confidential, and in general all information subject to a confidentiality undertaking is excluded from exchanges on the application.</li>
  <li>It is the responsibility of each member to assess the degree of sensitivity of the information he or she is initiating, and to ensure that it does not contravene any of the aforementioned provisions.</li>
</ul>
`,
      },
      {
        index: 6,
        title: "Publications",
        content: `The author of a publication, page or group is responsible for its content and the accuracy of the text and photos submitted.
Each page, group or publication may be checked by a moderator, but this does not relieve the author of responsibility.
Out of respect for the buyer (course participant), any technical characteristics of the services must be mentioned in the page or group.
The author agrees to allow moderators to edit the ad (e.g. to correct spelling mistakes or crop a photo if necessary).
It is permitted, but not recommended, to enter your personal details in the ad.
The site cannot be held responsible for any damage or injury caused by a member.
Unless authorized by the author, it is strictly forbidden to transfer/copy an advert or photos from the site to another social network or by email to people outside the site.
`,
      },
      {
        index: 7,
        title: "Restrictions on use, exclusions, abuse",
        content: `
        You acknowledge that you have understood the purpose of the application and agree to comply with its normal use.
In this context,

Vous vous interdisez de détourner l’application à des fins contraires à l’ordre public ou aux bonnes moeurs, à des fins personnelles ou qui portent atteinte aux droits des tiers, dans un but de propagande politique ou religieuse etc. De même, vous vous engagez à ne pas exploiter d’éventuelles failles techniques afin d’en tirer un avantage quelconque, ou de nuire à des tiers, en particulier à leur image de marque ou à leur réputation.
  À titre d'exemple, et sans que cela soit limitatif, vous vous engagez, en utilisant l’espace de dialogue, à ne pas :
    <ul><li>You agree not to misuse the application for purposes that are contrary to public order or morality, for personal purposes or that infringe the rights of third parties, for political or religious propaganda, etc. Similarly, you undertake not to exploit any technical flaws in order to gain any advantage whatsoever, or to harm third parties, in particular their brand image or reputation.</li></ul>
By way of example, and without limitation, you undertake, when using the chat area, not to:
      <><li>Defame, abuse, harass, deceive or threaten others, or violate the rights of third parties in any way (in particular the individual rights of any natural person, including the right to privacy).</li>
      <li>Distribute, publish or upload to any server any material or information that is inappropriate, disrespectful of political or religious beliefs, defamatory, obscene, unlawful or infringing of any right, including the intellectual property rights of the application.</li>
      <li>Assume the identity of a third party by pretending to be the latter in the chat area, following theft of the third party's identifiers or cookies.</li>
      <li>Upload files that contain software or any other material protected by intellectual property rights (or by personality rights, including the right to privacy), unless you own these rights or have received all necessary authorizations.</li>
      <li>Upload files that contain viruses, harmful software or similar programs that may damage the operation of the application or any other computer.</li>
      <li>Download files sent by another user when you know (or should know) that such distribution is illegal.</li> </ul>

If you become aware of any infringements or abuses in the use of the application, it is your responsibility to report them by e-mail to the address indicated in the GTC.`,
      },
      {
        index: 8,
        title: "Intellectual property",
        content: `All elements of the application (in particular trademarks, domain names, texts, graphics, logos, sounds, photos and animations as well as all graphic and audiovisual forms) are the property of Health Tech & Co, SAS with a share capital of 1,000 euros, registered in the Lyon Trade and Companies Register under no. 950 774 166, whose registered office is located at 22 BD DES DROITS DE L'HOMME, 69120 VAULX-EN-VELIN.
All reproduction rights are reserved, including for downloadable documents and textual, iconographic or photographic representations. The reproduction, imitation, modification, representation and/or redistribution of all or part of the site on any electronic medium whatsoever is strictly forbidden without the prior written consent of Health Tech & Co.
Failure to comply with these provisions may constitute an act of counterfeiting for which the offender may be held civilly and criminally liable. 
Similarly, the data and information contained in the application are protected under the law applicable to databases. Any extraction or attempted extraction, in whole or in part, of such data is therefore liable to incur civil and criminal liability.
The documents and information transmitted by users are their sole responsibility.
`,
      },
      {
        index: 9,
        title: "Modifications",
        content: `
We may modify this policy at any time, in particular in order to comply with any regulatory, legal, editorial or technical developments. These modifications will apply as of the effective date of the modified version. You are therefore invited to consult the latest version of this policy on a regular basis. Nevertheless, we will keep you informed of any significant changes to this policy.`,
      },
    ],
  },

  fr: {
    title: `CHARTE D’UTILISATION DE L’APPLICATION
`,
    effectiveDateLabel: "Date d’entrée en vigueur :",
    effectiveDateValue: "11 juin 2024",
    terms: [
      {
        content: `Hustle-Up permet à ses membres, professionnels ou particuliers, de s’inscrire à des cours de sport auprès de coach sportifs, de suivre leur pratique du sport, de créer un programme d’entrainement accessible aux autres membres ou encore de gérer l’exploitation d’une salle de sport, à travers l’application disponible via le site <a href="https://sport.hustleup-app.com/" target="_blank">https://sport.hustleup-app.com</a> ou sur les stores, sous réserve qu’elle soit utilisée dans le respect des conditions de la présente charte d’utilisation.`,
      },
      {
        index: 1,
        title: "Les grands principes de fonctionnement",
        items: [
          {
            content: `L’intégralité des clauses, droits et obligations de la présente charte d'utilisation sont reconnues acceptées par chacun des membres et utilisateurs, par le fait de cocher la case « j’accepte la Charte d’utilisation ».
      `,
          },
          {
            index: 1,
            title: "La rédaction",
            content: `<ul><li>L’application ne saurait enL’application ne saurait en aucun cas être tenu responsable des propos et du comportement des membres ;</li>
<li>Respectez le débat, respectez votre interlocuteur et les autres lecteurs ;</li>
<li>N'envoyez pas de messages diffamatoires, injurieux, violents ou trop chargés d’émotion quelque soit le sujet abordé. L'absence physique de votre interlocut aucun cas être tenu responsable des propos et du comportement des membres ;</li>
<li>Respectez le débat, respectez votre interlocuteur et les autres lecteurs ;</li>
<li>N'envoyez pas de messages diffamatoires, injurieux, violents ou trop chargés d’émotion quelque soit le sujet abordé. L'absence physique de votre interlocuteur peut accroître les incompréhensions et les tensions. Même si vous êtes seul devant l’application.</li></ul>`,
          },
          {
            index: 2,
            title: "Modération du contenu des messages",
            content: ` <ul><li>Ce lieu d’échanges se veut simple et souple pour les utilisateurs. Les codes rédactionnels institutionnels sont assouplis mais les règles de courtoisie et de politesse sont de rigueur dans toutes vos actions.</li>
  <li>Les commentaires injurieux ou agressifs n’ont pas leur place ici : abstenez-vous de toute attaque personnelle.</li>
  <li>Les accusations sans preuve, la déformation des faits, les mensonges, la désinformation, mais aussi les polémiques extrémistes ou excessivement partisanes, la propagande (directe ou indirecte), ainsi que toute forme de prosélytisme et de dogmatisme, ne sont pas acceptés.</li>
  <li>Les commentaires racistes, antisémites, pornographiques, révisionnistes, sexistes et, plus généralement, tout sujet contraire à la loi ou présentant un caractère diffamatoire, ne seront pas tolérés et peuvent entraîner la fermeture de votre compte utilisateur.</li>
  <li>Vérifiez la source des informations que vous postez afin d’éviter la désinformation et les rumeurs.</li>
  <li>Prenez garde à ne pas perdre votre temps à engager le débat avec des trolls.</li>
  <li>Les modérateurs de l’application sont autorisés à supprimer toute publication ou commentaire qui ne respecterait pas les règles posées par la présente Charte.</li></ul>`,
          },
        ],
      },
      {
        index: 2,
        title: "Règles sur la protection des données personnelles",
        items: [
          {
            content: `Le recueil de vos données personnelles est essentiel pour le fonctionnement de l’application. Vos données sont notamment destinées à la création de votre compte utilisateur, à la publication de contenus, à la consultation de votre profil, à l’organisation et la création d’une page. Ce traitement repose sur l’intérêt légitime du responsable de traitement à l’organisation de la société.
Les informations ne sont accessibles que pour les membres de la société Health Tech & Co, SAS au capital social de 1.000 euros, immatriculée au RCS de Lyon sous le n° 950 774 166, dont le siège social est situé 22 BD DES DROITS DE L’HOMME, 69120 VAULX-EN-VELIN. 
Sur première demande, les données issues d’un compte utilisateur pourront être supprimées. Le contenu restant sera ensuite anonymisé. 
Vous disposez d’un droit d’accès, de rectification, d’opposition, de limitation du traitement, de suppression des données qui vous concernent. 
Si vous estimez être victime d’une violation de vos droits, vous avez la possibilité d’introduire une réclamation auprès de la CNIL.
`,
          },
        ],
      },
      {
        index: 3,
        title: "Inscription et gestion du compte utilisateur",
        content: `<ul><li>L’accès à l’application nécessite que vous disposiez d’une connexion Internet opérationnelle.</li>
  <li>À l’issue de la procédure d’inscription, votre compte utilisateur sera créé, et vous recevrez confirmation par courriel de vos identifiants.</li>
  <Il>Vous reconnaissez que ce compte utilisateur est l’équivalent de votre carte d’identité vis à vis de l’application. Il vous appartient donc de mettre et de maintenir à jour les informations relatives à votre compte utilisateur et votre profil (fonction, intitulé du poste, coordonnées professionnelles…).</li>
  <li>Vous êtes seul responsable de la protection du caractère confidentiel de votre compte et de votre mot de passe, ainsi que de toutes actions qui pourront être faites en votre nom, suite à leur utilisation.</li>
  <li>Vous vous engagez à mettre en œuvre tous les moyens nécessaires pour préserver la confidentialité de votre compte utilisateur, et à informer immédiatement l’application de toute utilisation frauduleuse de ce compte par des tiers non autorisés.</li>
  <li>Vous vous engagez à modifier régulièrement votre mot de passe.</li>
  <li>L’application ne peut être tenue responsable de toute perte de données ni d'un quelconque dommage résultant d'un manquement à ces obligations.</li></ul>
        `,
      },
      {
        index: 4,
        title: "Mise en avant d’un savoir-faire professionnel ",
        content: `<ul><li>Vous vous engagez à faire preuve de sincérité sur la présentation de vos qualifications (coach, entraîneur etc.).</li>
  <li>Il est interdit d’exercer une activité professionnelle illicite via l’application.</li>
  <li>Il vous appartient de mettre en œuvre des pratiques commerciales respectueuses.</li></ul>
`,
      },
      {
        index: 5,
        title: "Mise en ligne, édition et suppression de contenus",
        content: `<ul><li>L’application vous permet de mettre en ligne des contenus numériques (commentaires, textes, mots-clés, images etc.), sous réserve que ces divers contenus ne soient pas illicites, ni susceptibles de porter atteinte à l’ordre public ou aux droits de tiers.</li>
  <li>L’application se réserve la possibilité, de plein droit et sans justification préalable, de ne pas publier, de modérer ou de supprimer les contenus enfreignant ces interdictions, en particulier ceux qu'elle jugerait insultants à son endroit, diffamatoires à l'égard des autres membres, qui dénigreraient des produits et services, ou qui pourraient porter atteinte à une image de marque ou à la réputation.</li>
  <li>La publication des contenus fournis par les utilisateurs n’est en aucun cas un droit ; l’application reste entièrement libre de publier ou non ces contenus conformément à sa responsabilité éditoriale.</li>
  <li>Nonobstant cette réserve, vous comprenez et acceptez que la diffusion de ces contenus présente un caractère public et non secret. Par conséquent, vos contenus peuvent être lus par d'autres personnes, sans que vous en soyez avertis. Vous devez donc faire preuve de vigilance quant aux informations que vous publiez sur l’application (notamment dans les groupes ouverts), notamment en ce qui concerne toute information personnelle d'identification vous concernant ou concernant votre entourage, notamment familial.</li>
  <li>L’application contient un certain nombre de liens hypertextes vers d’autres sites. Néanmoins, l’application n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</li>
  <li>Les informations stockées, échangées et diffusées sur les groupes publics de l’application doivent rigoureusement exclure celles qui sont classées confidentielles, et de manière générale toutes les informations soumises à un engagement de confidentialité sont exclues des échanges sur l’application.</li>
  <li>Il appartient à chaque membre d’évaluer le degré de sensibilité des informations dont il est l’initiateur et de s’assurer qu’elles ne contreviennent à aucune des dispositions précitées.</li></ul>
`,
      },
      {
        index: 6,
        title: "Publications",
        content: `L’auteur d’une publication, d’une page ou d’un groupe est responsable de son contenu et de la véracité du texte et des photos soumises.
Chaque page, groupe ou publication peut être vérifiée par un modérateur, ce qui ne dégage pas l’auteur de ses responsabilités.
Par respect pour l’acheteur (participant à un cours), les éventuelles caractéristiques techniques des services doivent être mentionnés dans la page ou le groupe.
L’auteur consent à ce que les modérateurs puissent modifier l’annonce (entre autres pour corriger quelques fautes d’orthographe ou recadrer une photo si besoin).
Il est autorisé, quoique non recommandé, de renseigner vos coordonnées personnelles dans l’annonce.
Le site ne saurait à ce titre être tenu responsable des dommages ou préjudices éventuels commis par un des membres.
Sauf autorisation de l’auteur, il est formellement interdit de transférer / copier une annonce ou des photos du site sur un autre réseau social ou par email à des personnes étrangères au site.
`,
      },
      {
        index: 7,
        title: "Restrictions d’usage, exclusions, abus",
        content: `
        Vous reconnaissez avoir compris la finalité de l’application et vous vous engagez à vous conformer à son usage normal.
Dans ce cadre,
Vous vous interdisez de détourner l’application à des fins contraires à l’ordre public ou aux bonnes moeurs, à des fins personnelles ou qui portent atteinte aux droits des tiers, dans un but de propagande politique ou religieuse etc. De même, vous vous engagez à ne pas exploiter d’éventuelles failles techniques afin d’en tirer un avantage quelconque, ou de nuire à des tiers, en particulier à leur image de marque ou à leur réputation.
  À titre d'exemple, et sans que cela soit limitatif, vous vous engagez, en utilisant l’espace de dialogue, à ne pas :
    <ul><li>Diffamer, injurier, harceler, tromper ou menacer autrui, ni violer les droits des tiers d'une quelconque manière (en particulier les droits individuels de toute personne physique, y compris le droit à la vie privée).</li>
      <li>Diffuser, publier, télécharger vers un serveur tout document ou information inapproprié, irrespectueux des croyances politiques ou religieuses, diffamatoire, obscène, illicite ou portant atteinte à tout droit, notamment les droits de propriété intellectuelle de l’application.</li>
      <li>Usurper l’identité d’un tiers en vous faisant passer pour celui-ci dans l’espace de dialogue, suite à un vol des identifiants ou des cookies de ce tiers.</li>
      <li>Télécharger vers un serveur des fichiers qui contiennent des logiciels ou tous autres éléments protégés par des droits de propriété intellectuelle (ou par des droits de la personnalité, y compris le droit à la vie privée), à moins que vous ne déteniez lesdits droits, ou que vous ayez reçu toutes les autorisations nécessaires.</li>
      <li>Télécharger vers un serveur des fichiers qui contiennent des virus, des logiciels nocifs ou tous programmes similaires susceptibles d'endommager le fonctionnement de l’application ou de tout autre ordinateur.</li>
      <li>Télécharger des fichiers envoyés par un autre utilisateur lorsque vous savez (ou devriez savoir) que cette distribution est illicite.</li> </ul>

Si vous constatez des infractions ou des abus dans l’utilisation de l’application il vous appartient de les signaler par courriel à l’adresse indiquée dans les CGV.`,
      },
      {
        index: 8,
        title: "Propriété intellectuelle",
        content: `
        Tous les éléments de l’application (notamment les marques déposées, les noms de domaine, les textes, graphismes, logos, sons, photos et animations ainsi que toutes formes graphiques et audiovisuelles) sont la propriété de Health Tech & Co, SAS au capital social de 1.000 euros, immatriculée au RCS de Lyon sous le n° 950 774 166, dont le siège social est situé 22 BD DES DROITS DE L’HOMME, 69120 VAULX-EN-VELIN.
Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations textuelles, iconographiques ou photographiques. La reproduction, l’imitation, la modification, la représentation et/ou la rediffusion de tout ou partie du site sur un support électronique quel qu'il soit est formellement interdit, sauf autorisation écrite préalable de Health Tech & Co.
Le non-respect de ces dispositions est susceptible de constituer un acte de contrefaçon engageant les responsabilités civile et pénale de tout contrevenant.
 De même, les données et informations contenues dans l’application sont protégées au titre du droit applicable aux bases de données. De sorte que toute extraction ou tentative d'extraction, fut-elle totale ou partielle de ces données, est susceptible d'engager la responsabilité civile et pénale de tout contrevenant.
Les documents et informations transmis par les utilisateurs le sont sous leur entière responsabilité.`,
      },
      {
        index: 9,
        title: "Modifications",
        content: `
Nous pouvons modifier à tout moment la présente politique, afin notamment de nous conformer à toutes évolutions réglementaires, jurisprudentielles, éditoriales ou techniques. Ces modifications s’appliqueront à la date d’entrée en vigueur de la version modifiée. Vous êtes donc invité à consulter régulièrement la dernière version de cette politique. Néanmoins, nous vous tiendrons informé(e) de toute modification significative de la présente politique de confidentialité.`,
      },
    ],
  },
}
