import { useEffect, useState } from "react"

export function useWindowSize(): {
  width: number
  height: number
} {
  const [size, setSize] = useState({ width: 0, height: 0 })
  useEffect(() => {
    const resizeListener = (): void => {
      setSize({ width: window.innerWidth, height: window.innerHeight })
    }
    resizeListener()
    window.addEventListener("resize", resizeListener)
    return () => window.removeEventListener("resize", resizeListener)
  }, [])
  return size
}
