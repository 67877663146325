import { AddStaffModal, AddStaffModalRef } from "@modules/boxStaff/components/addStaffModal"
import { headerColumn, staffColumns, valueColumn } from "@modules/boxStaff/components/staffColumn"
import { useStaff } from "@modules/boxStaff/useStaff"
import { word } from "@modules/core/utils/i18n"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { DataTable, renderTableColumns } from "@modules/ui/components/dataTables/datatable"
import { ListHeader } from "@modules/ui/components/header"
import { HUStack } from "@modules/ui/components/layout"
import { Button } from "primereact/button"
import { useRef } from "react"
import { DefaultTheme, useTheme } from "styled-components"

type Props = {} & React.HTMLAttributes<HTMLDivElement>

export const BoxStaff: React.FC<Props> = ({ ...props }) => {
  const theme = useTheme()
  const language = useLanguage()

  const { allStaff, loading } = useStaff()
  const addStaffModalRef = useRef<AddStaffModalRef>(null)

  return (
    <HUStack gap={34} className="card" flexGrow {...props}>
      <ListHeader
        title={word("staff.page.title")}
        rightView={
          <Button
            label={word("global.add")}
            icon="pi pi-plus"
            className="p-button-primary mr-2 w-auto"
            onClick={() => addStaffModalRef.current?.open()}
          />
        }
      />
      <DataTable
        value={allStaff}
        dataKey="id"
        rows={allStaff.length}
        className="datatable-responsive"
        emptyMessage={word("global.no_result")}
        loading={loading}
      >
        {renderTableColumns(staffColumns, headerColumn, valueColumn, theme as DefaultTheme, language)}
      </DataTable>
      <AddStaffModal ref={addStaffModalRef} />
    </HUStack>
  )
}
