import { CurrentSpaceManager } from "@modules/core/services/currentSpaceManager"
import { ServiceManager } from "@modules/core/services/serviceManager"
import { WebService } from "@modules/core/services/webService"
import { LoggerType } from "@modules/utils/loggerUtils"

export class WebServiceManager extends ServiceManager {
  constructor(isDev: boolean, private currentSpaceManager: CurrentSpaceManager) {
    super(isDev)

    currentSpaceManager.currentBox.subscribe(() => {
      this._services
        .filter((it) => (it as WebService)?.onSpaceChange)
        .map((it) => {
          if (isDev) {
            console.log(LoggerType.Program + "space-change", it.constructor.name)
          }
          return (it as WebService).onSpaceChange?.().catch((e) => {
            if (isDev) {
              console.error(LoggerType.Program + "space-change failure: " + it.constructor.name, e)
            } else {
              console.error(LoggerType.Program + "space-change failure:", e)
            }
          })
        })
    })
  }
}
