import React from "react"
import { IconSvgProps } from "@modules/ui/components/huButtonIcon"

export const FiveIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="101" viewBox="0 0 78 101" fill="none" {...props}>
    <path
      d="M38.2667 100.745C30.4444 100.745 23.6444 99.2787 17.8667 96.3454C12.1778 93.3231 7.77778 89.2787 4.66667 84.212C1.55556 79.1454 0 73.4565 0 67.1454H24C24 69.812 24.6222 72.1231 25.8667 74.0787C27.1111 76.0343 28.8444 77.5454 31.0667 78.612C33.2889 79.6787 35.7778 80.212 38.5333 80.212C41.7333 80.212 44.4 79.5454 46.5333 78.212C48.7556 76.8787 50.4 75.0565 51.4667 72.7454C52.6222 70.4343 53.2 67.812 53.2 64.8787C53.2 61.9454 52.6222 59.3231 51.4667 57.012C50.4 54.7009 48.8 52.8787 46.6667 51.5454C44.5333 50.212 41.9111 49.5454 38.8 49.5454C35.6889 49.5454 32.9778 50.2565 30.6667 51.6787C28.3556 53.012 26.6667 54.7009 25.6 56.7454H2.26667L7.2 0.745361H68.9333V22.0787H16.2667L26.4 16.0787L23.7333 45.5454L18.1333 44.212C20.8 40.8343 24 37.9454 27.7333 35.5454C31.5556 33.1454 36.8 31.9454 43.4667 31.9454C50.4 31.9454 56.3556 33.3676 61.3333 36.212C66.4 38.9676 70.3111 42.7898 73.0667 47.6787C75.8222 52.4787 77.2 57.9898 77.2 64.212V65.812C77.2 72.0343 75.6889 77.812 72.6667 83.1454C69.6444 88.3898 65.2444 92.6565 59.4667 95.9454C53.6889 99.1454 46.6222 100.745 38.2667 100.745Z"
      fill={props.color}
    />
  </svg>
)
