import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import {
  BOX_SLOT_PARTICIPANTS_LIMIT_MAX,
  BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
  BoxSlotSession,
} from "@modules/boxSlot/boxSlotTypes"
import { useBoxSlotFormik } from "@modules/boxSlot/hooks/useBoxSlotFormik"
import {
  getCancellationBeginningOptions,
  getRegistrationBeginningOptions,
  getRegistrationEndOptions,
} from "@modules/boxSlotTemplate/boxSlotTemplateUtils"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { useDateTimeCalendarProps } from "@modules/form/hooks/useDateTimeCalendarProps"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"
import dayjs from "dayjs"
import { useTheme } from "styled-components"

export const BoxSlotFormView: React.FC<{
  boxSlot?: BoxSlotSession
  boxProgrammation: BoxProgrammation
  startDate?: Date
  hideTitle: boolean
  refreshCalendarEvents: () => void
}> = ({ boxProgrammation, boxSlot, startDate, hideTitle, refreshCalendarEvents }) => {
  const isEdition = !!boxSlot

  const { boxSlotService } = useServicesContext()
  const { hideSidebar } = useSidebar()
  const confirmPopup = useConfirmPopup()
  const theme = useTheme()
  const { formik, isSubmitting, isLoading } = useBoxSlotFormik(
    boxProgrammation.id,
    refreshCalendarEvents,
    boxSlot,
    startDate,
  )

  const deleteSlotConfirmationPopup = () => {
    confirmPopup.show({
      title: word("sessionDetailed.deleteSession.popup.title"),
      message: word("boxSessionDetailed.deleteSession.popup.message", {
        sessionName: boxProgrammation.name,
      }),
      accept: async () => {
        boxSlot && (await boxSlotService.deleteSlot(boxSlot.id))
        refreshCalendarEvents()
        hideSidebar()
      },
      footerProps: {
        align: true,
      },
    })
  }

  const registrationBeginningOptions = getRegistrationBeginningOptions()
  const registrationEndOptions = getRegistrationEndOptions()
  const cancellationBeginningOptions = getCancellationBeginningOptions()

  const { startDateTimeMinDate, endDateTimeMinDate, dateTimeFormat, validateStartDateTime, validateEndDateTime } =
    useDateTimeCalendarProps()

  return (
    <HUStack className="mt-5">
      {!hideTitle && (
        <>
          <HUText fontStyle="TL">
            {boxSlot ? word("box.slot.form.update.title") : word("box.slot.form.create.title")}
          </HUText>
          <HUText fontStyle="BS">{word("box.slot.form.create.description")}</HUText>
        </>
      )}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FormikInput
            flex
            isRequiredInput
            formikStyle={{ display: "flex", flexGrow: 1 }}
            label={word("sessionForm.date.title")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("date", formik)}
            error={getFormErrorMessage("date", formik)}
            name="date"
            type="calendar"
            calendarProps={{ minDate: dayjs().toDate(), showIcon: true }}
            disabled={isEdition}
          />
          <HURow gap={20}>
            <FormikInput
              flex
              isRequiredInput
              formikStyle={{ display: "flex", flexGrow: 1 }}
              label={word("programmation.slotTemplates.form.hour.startTime")}
              getFieldProps={formik.getFieldProps}
              isInvalid={isFormFieldInValid(`startDateTime`, formik)}
              error={getFormErrorMessage(`startDateTime`, formik)}
              name={`startDateTime`}
              type="calendarTime"
              calendarProps={{
                minDate: startDateTimeMinDate,
                datetimeformat: dateTimeFormat,
                placeholder: "08:00",
                value: formik.values.startDateTime,
                onChange: (event) => {
                  const newValue = event.value?.valueOf()
                  if (typeof newValue === "number") {
                    validateStartDateTime(newValue, formik.values.endDateTime, (val) =>
                      formik.setFieldValue(`endDateTime`, val),
                    )
                  }
                },
              }}
            />
            <FormikInput
              flex
              isRequiredInput
              formikStyle={{ display: "flex", flexGrow: 1 }}
              label={word("programmation.slotTemplates.form.hour.endTime")}
              getFieldProps={formik.getFieldProps}
              isInvalid={isFormFieldInValid(`endDateTime`, formik)}
              error={getFormErrorMessage(`endDateTime`, formik, {
                errorMessage: word("sessionForm.error.date"),
              })}
              name={`endDateTime`}
              type="calendarTime"
              calendarProps={{
                minDate: endDateTimeMinDate,
                datetimeformat: dateTimeFormat,
                value: formik.values.endDateTime,
                placeholder: "19:00",
                onChange: (event) => {
                  const newValue = event.value?.valueOf()
                  if (typeof newValue === "number") {
                    validateEndDateTime(
                      newValue,
                      formik.values.startDateTime,
                      (val) => formik.setFieldValue(`startDateTime`, val),
                      true,
                    )
                  }
                },
              }}
            />
          </HURow>

          <FormikInput
            flex
            formikStyle={{ flexGrow: 1 }}
            isRequiredInput
            label={word("programmation.slotTemplates.form.participantLimit.label")}
            getFieldProps={formik.getFieldProps}
            isInvalid={isFormFieldInValid("participantLimit", formik)}
            name="participantLimit"
            error={getFormErrorMessage("participantLimit", formik, { errorMessage: word("global.form.required") })}
            setFieldValue={formik.setFieldValue}
            type="number"
            numberProps={{
              min: BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
              max: BOX_SLOT_PARTICIPANTS_LIMIT_MAX,
            }}
          />

          <HUStack gap={10}>
            <HUText type="label" fontStyle="LS" className="uppercase">
              {word("box.slotTemplateGroup.form.registration.label")}
            </HUText>
            <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.registration.description")}</HUText>
            <HURow gap={10}>
              <FormikInput
                flex
                formikStyle={{ flexGrow: 1 }}
                label={word("box.slotTemplateGroup.form.registration.beginning")}
                getFieldProps={formik.getFieldProps}
                isInvalid={isFormFieldInValid("limitStart", formik)}
                name="limitStart"
                error={getFormErrorMessage("limitStart", formik)}
                setFieldValue={formik.setFieldValue}
                type="dropdown"
                dropdownProps={{
                  value: formik.getFieldProps("limitStart").value,
                  options: registrationBeginningOptions,
                }}
              />
              <FormikInput
                flex
                formikStyle={{ flexGrow: 1 }}
                label={word("box.slotTemplateGroup.form.registration.end")}
                getFieldProps={formik.getFieldProps}
                isInvalid={isFormFieldInValid("limitEnd", formik)}
                name="limitEnd"
                error={getFormErrorMessage("limitEnd", formik)}
                setFieldValue={formik.setFieldValue}
                type="dropdown"
                dropdownProps={{
                  value: formik.getFieldProps("limitEnd").value,
                  options: registrationEndOptions,
                }}
              />
            </HURow>
          </HUStack>
          <HUStack gap={10}>
            <HUText type="label" fontStyle="LS" className="uppercase">
              {word("box.slotTemplateGroup.form.cancellation.label")}
              <HUText fontStyle="LL" color={theme.necessaryInput}>
                {" *"}
              </HUText>
            </HUText>
            <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.cancellation.description")}</HUText>
            <FormikInput
              flex
              formikStyle={{ flexGrow: 1 }}
              label={word("box.slotTemplateGroup.form.cancellation.beginning")}
              getFieldProps={formik.getFieldProps}
              isInvalid={isFormFieldInValid("limitCancel", formik)}
              name="limitCancel"
              error={getFormErrorMessage("limitCancel", formik)}
              setFieldValue={formik.setFieldValue}
              type="dropdown"
              dropdownProps={{
                value: formik.getFieldProps("limitCancel").value,
                options: cancellationBeginningOptions,
              }}
            />
          </HUStack>
          <HUStack gap={10}>
            <HUText type="label" fontStyle="LS" className="uppercase">
              {word("box.slotTemplateGroup.form.area.title")}
            </HUText>
            <HUText fontStyle="BS">{word("box.slotTemplateGroup.form.area.subtitle")}</HUText>
            <FormikInput
              flex
              formikStyle={{ display: "flex", flexGrow: 1 }}
              name="location"
              isRequiredInput
              getFieldProps={formik.getFieldProps}
              isInvalid={isFormFieldInValid("location", formik)}
              setFieldValue={formik.setFieldValue}
              error={getFormErrorMessage("location", formik)}
              placeHolder={word("box.slotTemplateGroup.form.area.placeholder")}
            />
          </HUStack>

          <div className="flex justify-content-end mt-3">
            <div className="flex column gap-3 sm:flex-row flex-grow-1">
              {isEdition ? (
                <HUButton
                  type="Default"
                  size="M"
                  colorType="Quaternary"
                  text={word("global.delete")}
                  onClick={deleteSlotConfirmationPopup}
                  className="w-full"
                />
              ) : (
                <HUButton
                  type="Default"
                  size="M"
                  colorType="Quaternary"
                  text={word("global.cancel")}
                  onClick={() => hideSidebar()}
                  className="w-full"
                />
              )}
              <HUButton
                type="Default"
                size="M"
                colorType="Primary"
                text={word("box.slot.form.submit.button")}
                className="mr-2 w-full"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={formik.submitForm}
              />
            </div>
          </div>
        </>
      )}
    </HUStack>
  )
}
