import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BoxProgrammationVisibilityStandardIcon: React.FC<IconSvgProps> = ({ color = "#D1D1D1", ...props }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C9.85285 22 8.34783 21.9984 7.18175 21.8875C6.038 21.7788 5.37616 21.5756 4.87346 21.2675C4.20464 20.8577 3.64232 20.2954 3.23247 19.6265C2.92441 19.1238 2.7212 18.462 2.61245 17.3182C2.50158 16.1522 2.5 14.6471 2.5 12.5C2.5 10.3529 2.50158 8.84783 2.61245 7.68175C2.7212 6.538 2.92441 5.87616 3.23247 5.37346C3.64232 4.70464 4.20464 4.14232 4.87346 3.73247C5.37616 3.42441 6.038 3.2212 7.18175 3.11245C8.34783 3.00158 9.85285 3 12 3C14.1471 3 15.6522 3.00158 16.8182 3.11245C17.962 3.2212 18.6238 3.42441 19.1265 3.73247C19.7954 4.14232 20.3577 4.70464 20.7675 5.37346C21.0756 5.87616 21.2788 6.538 21.3875 7.68175C21.4984 8.84783 21.5 10.3529 21.5 12.5C21.5 14.6471 21.4984 16.1522 21.3875 17.3182C21.2788 18.462 21.0756 19.1238 20.7675 19.6265C20.3577 20.2954 19.7954 20.8577 19.1265 21.2675C18.6238 21.5756 17.962 21.7788 16.8182 21.8875C15.6522 21.9984 14.1471 22 12 22ZM1 12.5C1 8.2638 1 6.14569 1.9535 4.58972C2.48704 3.71906 3.21906 2.98704 4.08972 2.4535C5.64569 1.5 7.7638 1.5 12 1.5C16.2362 1.5 18.3543 1.5 19.9103 2.4535C20.7809 2.98704 21.513 3.71906 22.0465 4.58972C23 6.14569 23 8.2638 23 12.5C23 16.7362 23 18.8543 22.0465 20.4103C21.513 21.2809 20.7809 22.013 19.9103 22.5465C18.3543 23.5 16.2362 23.5 12 23.5C7.7638 23.5 5.64569 23.5 4.08972 22.5465C3.21906 22.013 2.48704 21.2809 1.9535 20.4103C1 18.8543 1 16.7362 1 12.5ZM17.088 10.5603C17.3425 10.2335 17.2838 9.76226 16.957 9.50777C16.6302 9.25328 16.159 9.31191 15.9045 9.63873L13.9109 12.1988C13.7362 12.4232 13.4183 12.4752 13.1812 12.3182L11.7755 11.3875C10.8813 10.7954 9.68163 10.9951 9.02737 11.8448L6.84453 14.6798C6.59183 15.008 6.65304 15.479 6.98124 15.7317C7.30944 15.9844 7.78035 15.9232 8.03305 15.595L10.2159 12.7599C10.39 12.5337 10.7094 12.4806 10.9474 12.6382L12.3531 13.5689C13.2437 14.1586 14.4382 13.9632 15.0944 13.1204L17.088 10.5603Z"
      fill={color}
    />
  </svg>
)
