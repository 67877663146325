import { BoxSlotTypeOf } from "@modules/boxSlot/boxSlotTypes"
import { EventType } from "@modules/calendar/calendarTypes"
import { word } from "@modules/core/utils/i18n"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { Profile } from "@modules/profile/profileTypes"
import { ParticipantStatus } from "@modules/profile/subscriber/subscriberTypes"
import { ProgramInfiniteSessionTypeOf, ProgramOnOffSessionTypeOf } from "@modules/programSession/programSessionTypes"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramVisibility } from "@modules/programs/programTypes"
import { DetailedSessionType } from "@modules/sessions/components/programSessionDetailsComponent"

import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import dayjs from "dayjs"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

export type TrainingCalendarEventType = EventType & {
  sessionType: typeof BoxSlotTypeOf | typeof ProgramInfiniteSessionTypeOf | typeof ProgramOnOffSessionTypeOf
  author?: Profile
  boxName?: string
  participantCount?: number
  participantStatus?: ParticipantStatus
  participantLimit?: number
  programName: string
  programId: string
  isAuthor: boolean
  programVisibility?: ProgramVisibility
  hideSessionContent: boolean
}

type TrainingCalendarEventProps = {
  index: number
  event: TrainingCalendarEventType | undefined
  onEventClick: (arg: DetailedSessionType) => void
  isUpdating?: boolean
  isDetailsOpen?: boolean
}

export const TrainingCalendarEvent: React.FC<TrainingCalendarEventProps> = ({
  index,
  event,
  onEventClick,
  isDetailsOpen = false,
}) => {
  const theme = useTheme()
  const sessionHasStarted = useMemo(
    () => event && "date" in event && event.date && new Date() > new Date(event.date),
    [event],
  )

  const hideSessionContent = useMemo(() => {
    if (sessionHasStarted) {
      return false
    }

    return event?.hideSessionContent
  }, [event, sessionHasStarted])

  if (!event) return <></>

  const {
    title,
    sessionType,
    boxName,
    programName,
    isAuthor,
    author,
    participantCount,
    participantLimit,
    participantStatus,
  } = event

  return (
    <Container
      key={index}
      onClick={() => {
        sessionType !== BoxSlotTypeOf &&
          onEventClick({
            id: event.id,
            programId: event.programId,
            programType: sessionType === ProgramOnOffSessionTypeOf ? ProgramOnOffTypeOf : ProgramInfiniteTypeOf,
            date: "date" in event ? event.date : undefined,
          })
      }}
      className={"flex flex-column gap-1 border-bottom-1 p-2" + (!!onEventClick ? " cursor-pointer" : "")}
      $isDetailsOpen={isDetailsOpen}
    >
      <HURow className="justify-content-between mb-0 gap-2">
        <ProgramName fontStyle="TS" className="event-title" $isAuthor={isAuthor}>
          {title}
        </ProgramName>
        {sessionType === BoxSlotTypeOf && "date" in event ? (
          <HUText fontStyle="BM">{dayjs(event.date).format("H:mm")}</HUText>
        ) : (
          hideSessionContent && (
            <i style={{ fontSize: "1rem", color: theme.secondaryColor300 }} className={"pi pi-eye-slash"} />
          )
        )}
      </HURow>

      <HUText fontStyle="BS" className="mt-0">
        {sessionType === BoxSlotTypeOf ? (
          <>
            <i style={{ fontSize: "0.8rem", color: theme.secondaryColor300 }} className={"mr-1 pi pi-map-marker"} />
            {boxName}
          </>
        ) : (
          programName
        )}
      </HUText>

      {author && (
        <HURow className="align-items-center my-2">
          <div className="w-4">
            <ProfileAvatar profile={author} />
          </div>
          <HUStack gap={0} className="ml-2">
            <HUText fontStyle="LM">{`${author.firstname} ${author.lastname}`}</HUText>
            <HUText fontStyle="BXS">Coach</HUText>
          </HUStack>
        </HURow>
      )}

      {sessionType === BoxSlotTypeOf && (
        <HURow className="align-items-center mb-2">
          <Subscribers fontStyle="BXS">
            {`${participantCount}/${participantLimit} ${word("slot.participants", { count: participantCount })}`}
          </Subscribers>
          {participantStatus === "registered" && <Going fontStyle="LM">{word("programs.going")}</Going>}
        </HURow>
      )}
    </Container>
  )
}

const Container = styled.div<{ $isDetailsOpen: boolean }>`
  background-color: ${({ theme, $isDetailsOpen }) => ($isDetailsOpen ? theme.primaryColor100 : "transparent")};
  &.dragging {
    opacity: 0.5;
  }
  &:hover {
    .event-title {
      font-size: 1.05rem;
    }
  }
`

const ProgramName = styled(HUText)<{ $isAuthor: boolean }>`
  background-color: ${({ $isAuthor, theme }) => ($isAuthor ? theme.neutralColor100 : theme.secondaryColor)};
  color: ${({ $isAuthor, theme }) => ($isAuthor ? theme.textPrimary : theme.mainWhite)};
  padding: 3px 8px;
  border-radius: 15px;
  line-height: 1rem;
`

const Subscribers = styled(HUText)`
  background-color: ${({ theme }) => theme.neutralColor100};
  padding: 3px 8px;
  border-radius: 10px;
`

const Going = styled(HUText)`
  background-color: ${({ theme }) => theme.secondaryColor};
  padding: 3px 8px;
  border-radius: 10px;
  color: ${({ theme }) => theme.mainWhite};
  transform: rotate(-10deg);
`
