import { Article } from "@modules/article/entities/articleEntity"
import { ArticleDto } from "@modules/article/gateway/articleApiDto"
import { useServicesContext } from "@modules/core/services/services.context"
import { useMemo } from "react"
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query"

type ArticleQueryResult = {
  content: Article[]
  page: number
}

export const useArticle = (articleId?: string) => {
  const { articleApi } = useServicesContext()

  const fetchArticles = async ({ pageParam }: { pageParam?: number }) => {
    const result = await articleApi.getArticles({ page: pageParam ?? 1 })
    return {
      ...result,
      content: result.content.map((articleDto: ArticleDto) => new Article(articleDto)),
    }
  }

  const { data, fetchNextPage }: UseInfiniteQueryResult<ArticleQueryResult, unknown> = useInfiniteQuery(
    ["articles"],
    fetchArticles,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.content.length === 0) {
          return null
        }

        return lastPage.page + 1
      },
    },
  )
  const articleList = useMemo(() => data?.pages.flatMap((page) => page.content), [data])

  const articlesById = useMemo(
    () =>
      articleList?.reduce<Record<string, Article>>((acc, article) => {
        return {
          ...acc,
          [article._id]: article,
        }
      }, {}),
    [articleList],
  )

  const article = useMemo(() => (articleId ? articlesById?.[articleId] : undefined), [articlesById, articleId])

  return {
    articleList,
    article,
    fetchNextArticles: fetchNextPage,
  }
}
