import { useServicesContext } from "@modules/core/services/services.context"
import { useAsync } from "@modules/hooks/useAsync"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"

export const useDetailedProgramSession = (
  id: string,
  sessionDate?: string,
  programId?: string,
  programType?: typeof ProgramInfiniteTypeOf | typeof ProgramOnOffTypeOf,
) => {
  const { programSessionService } = useServicesContext()

  return useAsync(async () => {
    if (id && programId && programType) {
      return await programSessionService.getDetailedSession(id, programId, programType, sessionDate)
    }
    return Promise.resolve(null)
  }, [id, sessionDate, programId, programType])
}
