import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const PayinIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M6.95242 6.75C7.08113 6.75 7.20984 6.79742 7.31146 6.89903L9.00501 8.59258C9.20146 8.78903 9.20146 9.11419 9.00501 9.31065C8.80855 9.5071 8.48339 9.5071 8.28694 9.31065L6.95242 7.97613L5.61791 9.31065C5.42146 9.5071 5.09629 9.5071 4.89984 9.31065C4.70339 9.11419 4.70339 8.78903 4.89984 8.59258L6.59339 6.89903C6.695 6.79742 6.82371 6.75 6.95242 6.75Z"
      fill={props.color}
    />
    <path
      d="M6.95242 6.75C7.23017 6.75 7.46045 6.98032 7.46045 7.25807V16.7419C7.46045 17.0197 7.23013 17.25 6.95238 17.25C6.67464 17.25 6.44432 17.0197 6.44432 16.7419V7.25807C6.44432 6.98032 6.67468 6.75 6.95242 6.75Z"
      fill={props.color}
    />
    <path
      d="M13.9048 19.75C14.0948 19.75 14.2848 19.68 14.4348 19.53L16.9348 17.03C17.2248 16.74 17.2248 16.26 16.9348 15.97C16.6448 15.68 16.1648 15.68 15.8748 15.97L13.9048 17.94L11.9348 15.97C11.6448 15.68 11.1648 15.68 10.8748 15.97C10.5848 16.26 10.5848 16.74 10.8748 17.03L13.3748 19.53C13.5248 19.68 13.7148 19.75 13.9048 19.75Z"
      fill={props.color}
    />
    <path
      d="M13.9048 19.75C14.3148 19.75 14.6548 19.41 14.6548 19V5C14.6548 4.59 14.3148 4.25 13.9048 4.25C13.4948 4.25 13.1548 4.59 13.1548 5V19C13.1548 19.41 13.4948 19.75 13.9048 19.75Z"
      fill={props.color}
    />
  </svg>
)
