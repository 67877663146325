import { BoxPlanningHeader } from "@modules/boxPlanning/components/boxPlanningHeader/boxPlanningHeader"
import { useBoxProgrammation } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { BoxProgrammationSessionsCalendar } from "@modules/boxProgrammationSessions/components/boxProgrammationSessionsCalendar"
import { CalendarProvider } from "@modules/calendar/context/calendarContext"
import { useServicesContext } from "@modules/core/services/services.context"
import { minScreenWidth } from "@modules/ui/uiConstants"
import dayjs from "dayjs"
import { useCallback } from "react"
import { useLocation, useParams } from "react-router-dom"
import styled from "styled-components"

export const BoxProgrammationSessionsPage: React.FC = () => {
  const { boxSlotService, boxPlanningService } = useServicesContext()
  const { boxProgrammationId } = useParams()
  const { result: boxProgrammation, loading } = useBoxProgrammation(boxProgrammationId)
  const location = useLocation() as { state?: { selectedDate?: string } }

  const onDateSet = useCallback(
    async (startDate: number | dayjs.Dayjs, endDate: number | dayjs.Dayjs) => {
      await boxPlanningService.updatePlanningSlots(
        dayjs(startDate).startOf("day").toString(),
        dayjs(endDate).endOf("day").toString(),
      )

      if (boxProgrammation && !loading) {
        boxSlotService.updateScheduledSessions(boxProgrammation.id, startDate.toString(), endDate.toString())
      }
    },
    [boxProgrammation, loading],
  )

  return (
    <CalendarProvider
      initialDate={dayjs(location.state?.selectedDate).startOf("day")}
      defaultDisplayDetailedEvents={false}
      defaultView="dayGridWeek"
      isUndated={false}
      onDateSet={onDateSet}
      refreshEvents={onDateSet}
    >
      <Container className="grid flex-grow-1">
        <div className="flex-grow-1 flex flex-column" style={{ minWidth: minScreenWidth }}>
          <BoxPlanningHeader boxProgrammation={boxProgrammation} isProgramming={true} />
          {boxProgrammation && (
            <BoxProgrammationSessionsCalendar boxProgrammation={boxProgrammation} loading={loading ?? false} />
          )}
        </div>
      </Container>
    </CalendarProvider>
  )
}

const Container = styled.div`
  min-width: 980px;
`
