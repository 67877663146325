import { BoxRole, allBoxRolesSelectable, displayBoxRole } from "@modules/boxStaff/boxStaffTypes"
import { getValidationSchema } from "@modules/boxStaff/components/staffFormikType"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { HUDialog } from "@modules/ui/components/popup/huDialog"
import { useFormik } from "formik"
import { Button } from "primereact/button"
import { forwardRef, useImperativeHandle, useState } from "react"
import styled from "styled-components"

export type AddStaffModalRef = {
  open: () => void
}

type Props = {
  onAfterCloseModal?: () => void
}

export const AddStaffModal = forwardRef<AddStaffModalRef, Props>(({ onAfterCloseModal }, ref) => {
  const [showModal, setShowModal] = useState(false)
  const { boxStaffService } = useServicesContext()

  const [loading, setLoading] = useState(false)

  const toast = useToast()
  const staffFormik = useFormik<{ email: string; role: BoxRole }>({
    initialValues: { email: "", role: "CO_OWNER" },
    validationSchema: getValidationSchema(),
    enableReinitialize: true,
    onSubmit: async ({ email, role }) => {
      try {
        setLoading(true)
        await boxStaffService.addMember(email, role)
        closeModal()
        onAfterCloseModal?.()
      } catch (e) {
        if ((e as any).status === 404) {
          toast.show({
            severity: "error",
            summary: word("staff.add_member.toast.error"),
          })
        }
      } finally {
        setLoading(false)
      }
    },
  })

  const closeModal = () => {
    setShowModal(false)
    staffFormik.resetForm()
  }

  useImperativeHandle(ref, () => ({
    open: () => setShowModal(true),
  }))

  return (
    <HUDialog headerSeparator visible={showModal} header={word("staff.add_member.modal.title")} onHide={closeModal}>
      <div className="block md:mt-0">
        <HUStack gap={20} style={{ padding: "24px 4px" }}>
          <Description>
            <HUText fontStyle="BM">{word("staff.add_member.modal.description")}</HUText>
          </Description>

          <FormikInput
            flex
            isRequiredInput
            name="email"
            label={word("global.email")}
            setFieldValue={staffFormik.setFieldValue}
            getFieldProps={staffFormik.getFieldProps}
            isInvalid={isFormFieldInValid("email", staffFormik)}
            error={getFormErrorMessage("email", staffFormik)}
          />
          <FormikInput
            formikStyle={{ flexGrow: 1 }}
            name="role"
            type="dropdown"
            label={word("staff.add_member.modal.role.label")}
            setFieldValue={staffFormik.setFieldValue}
            getFieldProps={staffFormik.getFieldProps}
            isInvalid={isFormFieldInValid("role", staffFormik)}
            dropdownProps={{
              value: staffFormik.getFieldProps("role").value,
              options: allBoxRolesSelectable,
              itemTemplate: (role: BoxRole) => <HUText>{displayBoxRole[role]}</HUText>,
              valueTemplate: (role: BoxRole) => <HUText>{displayBoxRole[role]}</HUText>,
            }}
          />
        </HUStack>

        <div className="flex justify-content-end mt-3">
          <div className="flex column gap-3 sm:flex-row">
            <Button
              label={word("global.cancel")}
              outlined
              className="p-button-secondary"
              onClick={closeModal}
              type="button"
            />
            <Button
              label={word("global.add")}
              className="p-button p-button-primary mr-2"
              loading={loading}
              disabled={isFormFieldInValid("email", staffFormik)}
              onClick={() => staffFormik.handleSubmit()}
            />
          </div>
        </div>
      </div>
    </HUDialog>
  )
})

const Description = styled.div`
  padding: 14px 16px;
  background-color: ${({ theme }) => theme.primaryColor800};
  border-radius: 6px;
`
