import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { BoxOffer, BoxOfferType } from "@modules/boxOffer/boxOfferTypes"
import { BoxOfferCardsView } from "@modules/boxOffer/components/boxOfferCardsView"
import { BoxOfferResumeView } from "@modules/boxOffer/components/boxOfferResumeView"
import { BoxOfferForm } from "@modules/boxOffer/components/boxOfferForm"
import { word } from "@modules/core/utils/i18n"
import { useConfirmPopup } from "@modules/ui/components/popup/huConfirmPopup"

export const useBoxOfferSidebar = () => {
  const { sidebarNavigateTo, sidebarNavigateBack, hideSidebar, isSidebarVisible } = useSidebar()
  const confirmPopup = useConfirmPopup()

  const navigateToBoxOfferCards = (selectedType?: BoxOfferType) =>
    sidebarNavigateTo({
      content: <BoxOfferCardsView selectedType={selectedType} />,
      replaceAllComponents: true,
    })

  const navigateToBoxOfferForm = (boxOfferType?: BoxOfferType, boxOffer?: BoxOffer) =>
    sidebarNavigateTo({
      content: <BoxOfferForm selectedType={boxOfferType} offer={boxOffer} />,
      replaceAllComponents: true,
      onHide: async () =>
        await new Promise<boolean>((resolve) => {
          confirmPopup.show({
            title: word("box.offer.form.cancel.title"),
            message: word("box.offer.form.cancel.description"),
            accept: async () => resolve(true),
            cancel: async () => resolve(false),
            footerProps: {
              align: true,
              confirmClassName: "flex-1",
              cancelClassName: "flex-1",
              footerClassName: "flex flex-row justify-content-around w-full",
            },
          })
        }),
    })

  const navigateToBoxOfferResume = (offerId: string) =>
    sidebarNavigateTo({
      content: <BoxOfferResumeView offerId={offerId} />,
      replaceAllComponents: true,
    })

  return {
    navigateToBoxOfferCards,
    sidebarNavigateBack,
    hideSidebar,
    navigateToBoxOfferResume,
    navigateToBoxOfferForm,
    isSidebarVisible,
  }
}
