import { CalendarEventTypeWithHour } from "@modules/calendar/calendarTypes"
import { CALENDAR_OPENING_HOURS, DEFAULT_CALENDAR_HOUR_HEIGHT } from "@modules/calendar/utils/calendarConstants"
import dayjs from "dayjs"

type ComputingEventPositionType = {
  collidingEvents: ComputingEventPositionType[]
  columnIndex?: number
} & CalendarEventTypeWithHour

// This function will complete the calendar event array by assigning the style of each event
// It will add each event to a column where no collision was found
// Then group the events with other related one to manage the width and left position
// All the events of a group should have the same width
export function calculateEventsPosition(
  calendarEvents: CalendarEventTypeWithHour[],
  maxWidth: number = 95,
): CalendarEventTypeWithHour[] {
  const columns: ComputingEventPositionType[][] = [] // used to manage displayed columns
  const eventGroups: ComputingEventPositionType[][] = [] // used to manage events widths and positions

  const events: ComputingEventPositionType[] = calendarEvents
    .slice()
    .sort(sortEventsByTime)
    .map((event) => ({ ...event, collidingEvents: [] }))

  // This function will be called to calculate the event groups after the column of each event is found
  const computeEventGroups = (event: ComputingEventPositionType): number => {
    let eventGroupIndex: number

    // If the event has no collision, then create a group with it inside
    if (event.collidingEvents.length === 0) {
      eventGroupIndex = eventGroups.push([event]) - 1
    } else {
      const eventGroupIndexes: number[] = []

      // Search for all the groups of the colliding events
      event.collidingEvents.forEach((collidingEvent) => {
        const foundEventGroupIndex = eventGroups.findIndex((eventGroup) => eventGroup.indexOf(collidingEvent) >= 0)

        if (foundEventGroupIndex >= 0 && eventGroupIndexes.findIndex((c) => c === foundEventGroupIndex) < 0)
          eventGroupIndexes.push(foundEventGroupIndex)
      })

      // Merge all found event groups in the 1st one
      eventGroupIndex = eventGroupIndexes[0]
      eventGroups
        .filter((eventGroup, index) => eventGroupIndexes.indexOf(index) >= 0 && index !== eventGroupIndex)
        .forEach((eventGroup) => {
          eventGroups[eventGroupIndexes[0]] = eventGroups[eventGroupIndexes[0]].concat(eventGroup)
        })

      // Remove the other found event groups
      for (let i = eventGroupIndexes.length - 1; i > 0; i--) {
        eventGroups.splice(eventGroupIndexes[i], 1)
      }

      eventGroups[eventGroupIndexes[0]].push(event)
    }

    return eventGroupIndex
  }

  let columnIndex = 0

  // As long as all the events are not in a column, search for one they can go in
  while (events.filter((e) => typeof e.columnIndex === "undefined").length > 0) {
    columns[columnIndex] = []
    events
      .filter((e) => typeof e.columnIndex === "undefined")
      .forEach((event) => {
        // add the events to the current column if no collision was found
        if (!hasCollision(event, columns[columnIndex])) {
          columns[columnIndex].push(event)
          event.columnIndex = columnIndex

          // Search for the colliding events with current one - the colliding events can only be in a previous column
          event.collidingEvents = findCollidingEvents(
            event,
            events.filter((e) => typeof e.columnIndex !== "undefined" && e.columnIndex !== event.columnIndex),
          )

          // Find the event group the current events belongs to
          const eventgroupIndex = computeEventGroups(event)

          // Set the styling information of each event of the found group
          eventGroups[eventgroupIndex].forEach((eventInGroup) => {
            const width = `calc(${maxWidth}% / ${columnIndex + 1})`

            eventInGroup.style = {
              width,
              left:
                typeof eventInGroup.columnIndex !== "undefined"
                  ? `calc(${maxWidth}% / ${columnIndex + 1} * ${eventInGroup.columnIndex} + 4px)`
                  : 4,
              top:
                dayjs(eventInGroup.startDate).diff(
                  dayjs(eventInGroup.startDate).set("hour", CALENDAR_OPENING_HOURS[0]).set("minute", 0),
                  "hour",
                  true,
                ) * DEFAULT_CALENDAR_HOUR_HEIGHT,
              height:
                dayjs(eventInGroup.endDate).diff(eventInGroup.startDate, "hour", true) * DEFAULT_CALENDAR_HOUR_HEIGHT,
            }
          })
        }
      })

    columnIndex++
  }

  return events
}

// This function sorts the events based on their starting and ending times
function sortEventsByTime(eventA: CalendarEventTypeWithHour, eventB: CalendarEventTypeWithHour) {
  if (dayjs(eventA.startDate).isAfter(dayjs(eventB.startDate))) return 1
  if (dayjs(eventA.startDate).isBefore(dayjs(eventB.startDate))) return -1
  if (dayjs(eventA.endDate).isAfter(dayjs(eventB.endDate))) return 1
  if (dayjs(eventA.endDate).isBefore(dayjs(eventB.endDate))) return -1
  else return 0
}

// This function searches for all the collisions of an event in an array of events
function findCollidingEvents(
  item: ComputingEventPositionType,
  calendarEvents: ComputingEventPositionType[],
): ComputingEventPositionType[] {
  const collisions: ComputingEventPositionType[] = []
  const itemStartDate = dayjs(item.startDate).startOf("minute")
  const itemEndDate = dayjs(item.endDate).startOf("minute")

  calendarEvents.forEach((event) => {
    const eventStartDate = dayjs(event.startDate).startOf("minute")
    const eventEndDate = dayjs(event.endDate).startOf("minute")
    if (
      eventStartDate.isSame(itemStartDate) ||
      eventEndDate.isSame(itemEndDate) ||
      (eventStartDate.isAfter(itemStartDate) && eventStartDate.isBefore(itemEndDate)) ||
      (eventEndDate.isAfter(itemStartDate) && eventEndDate.isBefore(itemEndDate)) ||
      (eventStartDate.isBefore(itemStartDate) && eventEndDate.isAfter(itemStartDate)) ||
      (eventStartDate.isBefore(itemEndDate) && eventEndDate.isAfter(itemEndDate))
    )
      collisions.push(event)
  })

  return collisions
}

// This function simply says if the given event has some collisions in an array of events
function hasCollision(item: ComputingEventPositionType, calendarEvents: ComputingEventPositionType[]): boolean {
  const collisionNumber = findCollidingEvents(item, calendarEvents).length

  return collisionNumber > 0
}
