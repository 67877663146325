import { HUImage } from "@modules/types/imageTypes"

export type HUImageDto = {
  url: string
  hash: string
}

export type HUImageDraftDto = {
  content: string
  contentType: string
}

export type HUImageDraft = {
  serverPath?: string
  serverBlurHash?: string
  localPath?: string
  localBase64?: string
}

export function convertImageFromDto(dto: HUImageDto): HUImage {
  return {
    path: dto.url,
    blurHash: dto.hash,
  }
}

export function convertImageToDraftDto<T extends HUImageDraft | undefined>(
  dto: T,
): T extends undefined ? undefined : HUImageDraftDto {
  return (
    dto?.localBase64
      ? {
          content: dto.localBase64,
          contentType: "base64",
        }
      : undefined
  ) as T extends undefined ? undefined : HUImageDraftDto
}
