import { IconSvgProps } from "@modules/ui/components/huButtonIcon"

export const GriboullisIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.11298 10.2544C5.03021 8.9862 5.0163 7.73275 5.02409 6.46203C5.02901 5.66086 5.23665 4.85794 5.18479 4.06337"
      stroke={props.color}
      strokeWidth="2.82353"
      strokeLinecap="round"
    />
    <path
      d="M10.0898 13.5777C12.0533 11.6033 16.5665 8.46515 18.7677 6.76131"
      stroke={props.color}
      strokeWidth="2.82353"
      strokeLinecap="round"
    />
    <path d="M11.7998 19.2417L18.7352 19.676" stroke={props.color} strokeWidth="2.82353" strokeLinecap="round" />
  </svg>
)
