import { ArticleResume } from "@modules/article/components/articleResume"
import { Article } from "@modules/article/entities/articleEntity"
import { word } from "@modules/core/utils/i18n"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { HUText } from "@modules/ui/components/huText"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"
type ArticleCardProps = {
  article: Article
}

export const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
  const theme = useTheme()
  const { sidebarNavigateTo } = useSidebar()

  const itemImage = useMemo(() => {
    return article.thumbnail.uri
  }, [article])

  const onOpenArticle = () => {
    sidebarNavigateTo({
      content: <ArticleResume article={article} />,
      replaceAllComponents: true,
    })
  }

  return (
    <CardContainer $bgImage={itemImage} onClick={() => onOpenArticle()}>
      <CategoryTag>
        <HUText fontStyle="LL" color={theme.mainWhite}>
          {word(`article.tag.${article.tag}`)}
        </HUText>
      </CategoryTag>
      <DescriptionText>
        <HUText fontStyle="TL" color={theme.mainWhite}>
          {article.title}
        </HUText>
        <HUText fontStyle="BS" color={theme.mainWhite}>
          {article.description}
        </HUText>
      </DescriptionText>
      <GradientOverlay />
    </CardContainer>
  )
}

const CardContainer = styled.div<{ $bgImage?: string }>`
  height: 428px;
  width: 100%;
  max-width: 388px;
  border-radius: 33px;
  padding: 0px 0px 0px 0px;
  position: relative;
  overflow: hidden;
  background-image: ${({ $bgImage }) => `url(${$bgImage})`};
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer !important;
  }
  @media screen and (max-width: 1540px) {
    width: 100%;
    max-width: 320px;
    height: 300px;
  }
`
const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 33px;
  border-width: 0px;
  height: 428px;
  width: 388px;
  z-index: 1;
  background: linear-gradient(180deg, rgba(34, 34, 34, 0.4) 0%, #222 99.9%);

  @media screen and (max-width: 1540px) {
    width: 320px;
    height: 300px;
  }
`

const DescriptionText = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: calc(100% - 40px);
`

const CategoryTag = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  border-radius: 44px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(7px);
  padding: 9px 12px 8px 12px;
  z-index: 2;
  min-width: 80px;
  display: flex;
  justify-content: center;
`
