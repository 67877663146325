import DecorationImage from "@images/scoreboard/decoration.png"
import { DiagonalArrowIcon } from "@images/svgIcons/arrowIcon"
import { LockIcon } from "@images/svgIcons/lockIcon"
import { word } from "@modules/core/utils/i18n"
import { ProgramInfinite } from "@modules/programs/programInfiniteTypes"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { Tag } from "primereact/tag"
import styled, { useTheme } from "styled-components"
import { useProgramRights } from "@modules/programs/hooks/useProgramRights"

type Props = {
  program: ProgramInfinite
  style?: React.CSSProperties
  onClick: () => void
  canUserAccessToScoreboard: boolean
}

export const ScoreboardCard: React.FC<Props> = ({ style, onClick, program, canUserAccessToScoreboard }) => {
  const theme = useTheme()
  const { isAuthor, isAcceptedSharedCoach } = useProgramRights(program)

  return (
    <Card style={style} onClick={onClick}>
      <HURow>
        <Container gap={7}>
          <HUText color={theme.secondaryColor} fontStyle="TM">
            {word("session.scoreboard.card.title")}
          </HUText>
          <HUText color={theme.secondaryColor} fontStyle="BS">
            {!program.hideScoreboard || isAuthor || isAcceptedSharedCoach || canUserAccessToScoreboard
              ? word("session.scoreboard.card.description")
              : word("session.scoreboard.card.descriptionLock")}
          </HUText>
        </Container>
      </HURow>
      <div className="flex flex-column align-items-end">
        <div>
          {!program.hideScoreboard || isAuthor || isAcceptedSharedCoach || canUserAccessToScoreboard ? (
            <DiagonalArrowIcon width={20} height={20} color={theme.secondaryColor} />
          ) : (
            <LockIcon width={20} height={20} color={theme.secondaryColor} />
          )}
        </div>
        <div className="mt-2">
          <TagContainer>
            <HUText color={theme.secondaryColor} fontStyle="LL">
              {word("session.scoreboard.challenge.tag")}
            </HUText>
          </TagContainer>
        </div>
      </div>
      <Decoration src={DecorationImage} />
    </Card>
  )
}

const Card = styled.div`
  padding: 18px;
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`

const Container = styled(HUStack)`
  margin: 0x 4px 14px 0px;
  flex: 2;
`

const TagContainer = styled(Tag)`
  background-color: ${({ theme }) => theme.mainWhite};
  border-radius: 10px;
  transform: rotate(-4deg);
  z-index: 10;
  position: relative;
`

const Decoration = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 0;
`
