import { useState, useEffect } from "react"

/**
 * Hook personnalisé pour "debouncer" une valeur.
 *
 * @param value - La valeur à debounce.
 * @param delay - Le délai en millisecondes pour debounce.
 * @returns La valeur debounced.
 */
function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    // Crée un timeout pour mettre à jour la valeur debounced après le délai spécifié.
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Nettoie le timeout si la valeur ou le délai change ou si le composant est démonté.
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
