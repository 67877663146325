import { BoxProgrammationDefaultSettings } from "@modules/boxProgrammation/boxProgrammationTypes"
import { useBoxProgrammationDefaultSettings } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import { BOX_SLOT_PARTICIPANTS_LIMIT_MIN } from "@modules/boxSlot/boxSlotTypes"
import {
  BoxSlotTemplateGroupFormikType,
  convertBoxSlotTemplatesListFormToDraft,
  getValidationSchema,
} from "@modules/boxSlotTemplate/boxSlotTemplateGroupFormikTypes"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { convertTimeValueToTimeString } from "@modules/duration/timeConverter"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { useToast } from "@modules/ui/components/huToast"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

export const useBoxSlotTemplateGroupFormik = (boxProgrammationId: string) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { value: defaultSettings, loading: loadingDefaultSettings } =
    useBoxProgrammationDefaultSettings(boxProgrammationId)
  const { boxSlotTemplateService } = useServicesContext()
  const { hideSidebar } = useSidebar()
  const toast = useToast()

  const initialSlotFormikValues = (defaultSettings?: BoxProgrammationDefaultSettings | null) => {
    return {
      boxProgrammationId,
      slotDuration: defaultSettings?.duration ? convertTimeValueToTimeString(defaultSettings.duration) : "01h 00min",
      participantLimit: defaultSettings?.participantLimit ?? BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
      slotTemplatesRanges: [],
      location: defaultSettings?.location,
      limitStart: defaultSettings?.participation?.limitStart,
      limitEnd: defaultSettings?.participation?.limitEnd,
      limitCancel: defaultSettings?.participation?.limitCancel,
    }
  }

  const formik = useFormik<BoxSlotTemplateGroupFormikType>({
    initialValues: initialSlotFormikValues(defaultSettings),
    validationSchema: getValidationSchema(),
    enableReinitialize: true,
    onSubmit: async () => {
      if (!isSubmitting) {
        setIsSubmitting(true)
        try {
          await boxSlotTemplateService.createBoxSlotsTemplateList(
            boxProgrammationId,
            convertBoxSlotTemplatesListFormToDraft(formik.values),
          )

          toast.show({ severity: "success", detail: word("box.slotTemplateGroup.form.toast.success") })
          hideSidebar(false)
        } catch (e: any) {
          toast.show({ severity: "error", summary: "Error", detail: e.message })
        } finally {
          setIsSubmitting(false)
        }
      }
    },
  })

  useEffect(() => {
    // add the created slots (overlayed) to the calendar in background
    boxSlotTemplateService.updateBoxSlotTemplatesBeingCreated(convertBoxSlotTemplatesListFormToDraft(formik.values))
    return () => {
      // clear the overlayed slots in calendar
      boxSlotTemplateService.updateBoxSlotTemplatesBeingCreated([])
    }
  }, [formik.values.slotTemplatesRanges, formik.values.slotDuration])

  return { formik, isSubmitting, isLoading: loadingDefaultSettings }
}
