import { HustleupSmallIcon } from "@images/svgIcons/hustleUpSmallIcon"
import { word } from "@modules/core/utils/i18n"
import { getCurrencySymbol } from "@modules/currency/currency"
import { useStripeAccount } from "@modules/finances/hooks/useStripeAccount"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { OnBoardingStripeModal } from "@modules/programs/components/onBoardingStripeModal"
import { useProgramSidebar } from "@modules/programs/hooks/useProgramSidebar"
import { useProgramAuthorSubscription } from "@modules/programs/hooks/useProgramSubscription"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { Program } from "@modules/programs/programTypes"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { useState } from "react"
import styled, { useTheme } from "styled-components"

type ProgramPriceCardProps = {
  program?: Program | undefined
  editionAllowed: boolean
}

export const ProgramPriceCard: React.FC<ProgramPriceCardProps> = ({ program, editionAllowed }) => {
  const [isOnBoardingStripeModalVisible, setIsOnBoardingStripeModalVisible] = useState<boolean>(false)
  const theme = useTheme()
  const language = useLanguage()
  const { navigateToProgramPriceEdition } = useProgramSidebar()

  const { isLoading, switchToFree, switchToPaying, isUpdating } = useProgramAuthorSubscription({
    program,
  })
  const { useStripeAccountStatus } = useStripeAccount()
  const { stripeAccountStatus } = useStripeAccountStatus()

  const isStripeAccountComplete = stripeAccountStatus === "OK"

  const updatePrice = () => {
    if (program) navigateToProgramPriceEdition(program)
  }

  const onSwitchToPayingClick = () => {
    if (isStripeAccountComplete) switchToPaying()
    else setIsOnBoardingStripeModalVisible(true)
  }

  return (
    <>
      <CardContainer className="card align-items-start relative overflow-hidden">
        {program?.price?.value ? (
          <HUStack className="w-full z-2" gap={14}>
            {program._programType === ProgramInfiniteTypeOf ? (
              <>
                <HUText fontStyle="TM">{word("program.price.membership")}</HUText>
                <HURow>
                  <HUText fontStyle="DS">{`${program.price.value} ${getCurrencySymbol(program.price.currency, language)}`}</HUText>
                  <HUStack gap={2} className="ml-2">
                    <HUText fontStyle="LL">{word("program.price.pricePerMonth")}</HUText>
                    <HUText fontStyle="LL">{word("program.price.pricePerMember")}</HUText>
                  </HUStack>
                </HURow>

                {"trialDuration" in program && program.trialDuration > 0 && (
                  <HUText className="z-2" fontStyle="BM">
                    {`${word("program.price.trial.description")} ${program.trialDuration} ${word("global.day.label", { count: program.trialDuration })}`}
                  </HUText>
                )}
              </>
            ) : (
              <>
                <HUText fontStyle="TM">{word("program.price.singlePurchase")}</HUText>
                <HURow>
                  <HUText fontStyle="DS">{`${program.price.value} ${getCurrencySymbol(program.price.currency, language)}`}</HUText>
                  <HUStack gap={2} className="ml-2">
                    <HUText fontStyle="LL">{word("program.price.onlyOnce")}</HUText>
                  </HUStack>
                </HURow>
              </>
            )}

            <HUText fontStyle="BM">{word("program.price.payingForAll")}</HUText>

            {!isLoading && editionAllowed && (
              <HURow className="justify-content-between">
                <HUButton
                  type="Default"
                  colorType="Primary"
                  size="M"
                  style={{ alignSelf: "flex-start" }}
                  text={
                    program._programType === ProgramInfiniteTypeOf
                      ? word("program.price.modifyPrice")
                      : word("program.price.modifySinglePrice")
                  }
                  onClick={updatePrice}
                />
                <HUButton
                  type="Default"
                  size="M"
                  style={{ alignSelf: "flex-start" }}
                  text={word("program.price.switchToFree")}
                  onClick={switchToFree}
                  loading={isUpdating}
                  colorType="Underline"
                />
              </HURow>
            )}
          </HUStack>
        ) : (
          <HUStack className="w-full z-2">
            <HUText fontStyle="TM">{word("program.price.free")}</HUText>
            <HUText fontStyle="BM">{word("program.price.freeForAll")}</HUText>
            {!isLoading && editionAllowed && (
              <HUButton
                type="Default"
                colorType="Primary"
                size="M"
                style={{ alignSelf: "flex-start" }}
                text={word("program.price.switchToPaying")}
                onClick={onSwitchToPayingClick}
              />
            )}
          </HUStack>
        )}

        <HustleupSmallIconStyled color={theme.mainWhite} />

        <OnBoardingStripeModal
          visible={isOnBoardingStripeModalVisible}
          onHide={() => setIsOnBoardingStripeModalVisible(false)}
        />
      </CardContainer>
    </>
  )
}

const CardContainer = styled(HUStack)`
  border-radius: 33px;
`

const HustleupSmallIconStyled = styled(HustleupSmallIcon)`
  position: absolute;
  top: 0;
  right: -60px;
  height: 100%;
  width: auto;
  opacity: 0.3;
  z-index: 1;
`
