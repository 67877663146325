import { UserErrorCodes } from "@modules/auth/authErrorTypes"
import _ from "lodash"
import { useCallback, useMemo, useState } from "react"

export const useUsernameValidity = (checkValue: (n: string) => Promise<boolean>, defaultValidity?: boolean) => {
  const [isValid, setIsValid] = useState<boolean | undefined>(defaultValidity)
  const [isLoading, setIsLoading] = useState(false)

  const checkUsername = async (username: string) => {
    const available = await checkValue(username)
    setIsValid(available)
    setIsLoading(false)
  }

  const internalUsernameChange = useMemo(
    () =>
      _.debounce(
        async (username) => {
          await checkUsername(username)
        },
        500,
        { leading: false, trailing: true },
      ),
    [],
  )

  const onUsernameChange = useCallback((username: string) => {
    setIsLoading(true)
    setIsValid(undefined)
    internalUsernameChange(username)
  }, [])

  const internalOnUsernameExistError = (e: any, onError?: () => void) => {
    return onUsernameExistError(e, () => {
      setIsValid(false)
      onError?.()
    })
  }

  return { isValid, isLoading, onUsernameChange, onUsernameExistError: internalOnUsernameExistError }
}

export const onUsernameExistError = (e: any, onError?: () => void) => {
  const code = e.code
  if (code && code === UserErrorCodes.UsernameAlreadyExists) {
    onError?.()
    return true
  }
  return false
}
