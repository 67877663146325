import { ApiClient } from "@modules/core/api/client/apiClient"
import { convertImageFromDto, HUImageDraftDto, HUImageDto } from "@modules/dtos/imageDto"
import { HUImage } from "@modules/types/imageTypes"

export class AssetApi {
  private baseUrl = "/upload-image"

  constructor(private api: ApiClient) {}

  async uploadImage(content: string, prefixUrl = "/boxes", contentType: "base64"): Promise<HUImage> {
    const imageDraft: HUImageDraftDto = { content, contentType }
    const result = await this.api.post<HUImageDto>(prefixUrl + this.baseUrl, imageDraft)
    return convertImageFromDto(result.data)
  }
}
