import SessionEmpty from "@images/empty/session_empty.svg"
import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxSlotSessionContentDetails } from "@modules/boxSlot/components/boxSlotSessionContentDetails"
import { useBoxSlotSessionDetails } from "@modules/boxSlot/hooks/useBoxSlotDetails"
import { useBoxSlots } from "@modules/boxSlot/hooks/useBoxSlots"
import { BoxProgrammationSession } from "@modules/boxSlot/services/boxSlotDto"
import { word } from "@modules/core/utils/i18n"
import { useSessionFormContext } from "@modules/sessions/hooks/useSessionFormContext"
import { useSessionSidebar } from "@modules/sessions/hooks/useSessionSidebar"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUStack } from "@modules/ui/components/layout"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useTheme } from "styled-components"

export const BoxSlotSessionContentInfo: React.FC<{
  session: BoxProgrammationSession | null
  boxProgrammation: BoxProgrammation
  refreshCalendarEvents: () => void
  date: string
}> = ({ session, boxProgrammation, refreshCalendarEvents, date }) => {
  const { navigateToSessionForm } = useSessionSidebar()
  const { setEditedSession, setDateSession, setProgram, setEditedSlot } = useSessionFormContext()
  const { value: slotsOfTheDay } = useBoxSlots(
    dayjs(date).startOf("day").toISOString(),
    dayjs(date).endOf("day").toISOString(),
    boxProgrammation.id,
  )
  const { result: firstSlotOfTheDay } = useBoxSlotSessionDetails(slotsOfTheDay?.[0].id)

  const theme = useTheme()

  useEffect(() => {
    if (boxProgrammation) setProgram(boxProgrammation)
  }, [boxProgrammation])

  const editSession = () => {
    if (firstSlotOfTheDay) {
      setEditedSlot(firstSlotOfTheDay)
      if (session) {
        setEditedSession(session)
      } else {
        setDateSession(dayjs(firstSlotOfTheDay.startDateTime).format(defaultDateFormat))
      }
      navigateToSessionForm(
        !firstSlotOfTheDay.session,
        false,
        refreshCalendarEvents,
        boxProgrammation.id,
        firstSlotOfTheDay.id,
      )
    }
  }

  return (
    <HUStack>
      {session && session.exercises.length > 0 && firstSlotOfTheDay ? (
        <BoxSlotSessionContentDetails boxSlot={firstSlotOfTheDay} onEditSessionContent={editSession} />
      ) : (
        <EmptyView
          image={{ src: SessionEmpty }}
          title={word("box.slot.detail.exercise.empty.title")}
          description={word("box.slot.detail.exercise.empty.description")}
          button={
            <HUButton
              type="Left_Icon"
              colorType="Primary"
              size="M"
              text={word("box.slot.detail.subscriber.empty.button")}
              icon={{ iconView: <span className={`pi pi-plus`} style={{ color: theme.textPrimary }} /> }}
              onClick={editSession}
            />
          }
          paddingType="Default"
        />
      )}
    </HUStack>
  )
}
