import { Config } from "@modules/config"
import { Service } from "@modules/core/services/serviceType"
import { LoggerType } from "@modules/utils/loggerUtils"
import { getAnalytics, isSupported as isAnalyticsSupported } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import {
  RemoteConfig,
  fetchAndActivate,
  getRemoteConfig,
  getValue,
  isSupported as isRemoteConfigSupported,
} from "firebase/remote-config"
import { observable } from "micro-observables"

const DEBUG_AUTHORISATION = "debugAuthorisation"

export class RemoteConfigService implements Service {
  debugAuthorisation = observable<string>("")
  remoteConfig: RemoteConfig | null = null

  async init() {
    try {
      // Initialize Firebase
      const app = initializeApp(getFirebaseConfig())

      isRemoteConfigSupported().then(async () => {
        this.remoteConfig = getRemoteConfig(app)
        this.updateConfigSettings()
        await this.getDebugAuthorisation()
      })

      isAnalyticsSupported().then(() => {
        // Initialize Analytics and get a reference to the service
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const analytics = getAnalytics(app)
      })
    } catch (e) {
      console.error(LoggerType.Config + "init remote-config", e)
    }
  }

  private updateConfigSettings() {
    try {
      if (this.remoteConfig) {
        this.remoteConfig.settings.minimumFetchIntervalMillis = Config.ENV !== "prod" ? 30000 : 1800000 // 30min on prod. 30s on dev/demo
      }
    } catch (e) {
      console.error(LoggerType.Config + "init setConfigSettings", e)
    }
  }

  private async getDebugAuthorisation() {
    try {
      if (this.remoteConfig) {
        await fetchAndActivate(this.remoteConfig)
        const authorisation = getValue(this.remoteConfig, DEBUG_AUTHORISATION).asString()
        this.debugAuthorisation.set(authorisation)
      }
    } catch (e) {
      console.error(LoggerType.Config + "debug authorisation", e)
    }
  }
}

function getFirebaseConfig() {
  return {
    apiKey: Config.FIREBASE_API_KEY,
    authDomain: Config.FIREBASE_AUTH_DOMAIN,
    projectId: Config.FIREBASE_PROJECT_ID,
    storageBucket: Config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID,
    appId: Config.FIREBASE_APP_ID,
    measurementId: Config.FIREBASE_MEASUREMENT_ID,
  }
}
