import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ReloadIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g id="Reload">
      <path
        id="Vector"
        d="M9.90475 1.33337L10.6901 3.39388C9.9004 2.93164 8.98113 2.66671 7.99999 2.66671C5.05447 2.66671 2.66666 5.05452 2.66666 8.00004C2.66666 8.97147 2.92637 9.88225 3.38016 10.6667M6.09523 14.6667L5.30981 12.6062C6.09956 13.0684 7.01884 13.3334 7.99999 13.3334C10.9455 13.3334 13.3333 10.9456 13.3333 8.00004C13.3333 7.02861 13.0736 6.11784 12.6198 5.33337"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
