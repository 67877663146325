import { ChronoIcon } from "@images/svgIcons/chronoIcon"
import { displayTotalTime, getTrainingChronoTitle } from "@modules/chrono/chronoUtils"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { RpeTag } from "@modules/exercises/components/rpeTag"
import { ScoreEntry } from "@modules/exercises/components/scoreEntry"
import { Exercise } from "@modules/exercises/exerciseTypes"
import { getDisplayExerciseCategory } from "@modules/exercises/exerciseUtils"
import { ParentSessionOrigin } from "@modules/exercises/sessionTypes"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { getScoreTypesArray } from "@modules/scoreboard/scoreUtils"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { Linkify } from "@modules/ui/components/linkifyText"
import { useGetDisplayedScore } from "@modules/utils/hooks/useUnit"
import { LoggerType } from "@modules/utils/loggerUtils"
import { Checkbox } from "primereact/checkbox"
import { Tag } from "primereact/tag"
import { useMemo, useState } from "react"
import styled, { useTheme } from "styled-components"

type ExerciseDetailProps = {
  exercise: Exercise
  parentSessionOrigin: ParentSessionOrigin
  canUserCompleteExercise?: boolean
  refreshSession?: () => void
}

export const ExerciseDetail: React.FC<ExerciseDetailProps> = ({
  exercise,
  parentSessionOrigin,
  canUserCompleteExercise = false,
  refreshSession,
}) => {
  const theme = useTheme()
  const toast = useToast()
  const [isCompleteLoading, setIsCompleteLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(exercise.isCompleted)
  const { sessionService } = useServicesContext()
  const scoreTypes = useMemo(() => getScoreTypesArray(), [])
  const getScore = useGetDisplayedScore()
  const [isScoreShown, setIsScoreShown] = useState(false)

  const displayScoreType = useMemo(() => {
    const scoreTypeLabel = scoreTypes.find((scoreLabelType) => exercise.scoreType === scoreLabelType.value)
    return scoreTypeLabel?.label ?? ""
  }, [exercise])

  const onComplete = async () => {
    if (!isCompleteLoading && !isCompleted) {
      setIsCompleteLoading(true)
      try {
        await sessionService.markExerciseAsComplete(parentSessionOrigin, exercise)
        setIsCompleted(true)
        refreshSession && refreshSession()
      } catch (e) {
        console.error(LoggerType.Exercise, "complete", e)
        toast.show({ severity: "error", summary: word("global.error.label") })
      } finally {
        setIsCompleteLoading(false)
      }
    }
  }

  const renderButton = useMemo(() => {
    if (!isScoreShown && canUserCompleteExercise) {
      if (isCompleted) {
        if (exercise.scoreType !== ScoreType.NO_SCORE) {
          return (
            <HUButton
              text={
                exercise.score?.value
                  ? word("exercise.card.button.modify_score")
                  : word("exercise.card.button.add_score")
              }
              onClick={() => setIsScoreShown(true)}
              type="Default"
              size="M"
              colorType="Secondary"
              className="w-full mt-2"
            />
          )
        } else return
      } else {
        return (
          <HUButton
            text={word("global.check")}
            onClick={onComplete}
            type="Default"
            size="M"
            colorType="Primary"
            className="w-full mt-2"
          />
        )
      }
    }
  }, [isScoreShown, isCompleted, exercise.score, word, setIsScoreShown, onComplete])

  return (
    <BlackCardContainer>
      <HeaderCard>
        <CheckboxView checked={true} $checked={isCompleted} disabled={!canUserCompleteExercise} />
        <HUText className="ml-2" fontStyle="TS" color={theme.mainWhite}>
          {getDisplayExerciseCategory(exercise.category)}
        </HUText>
      </HeaderCard>
      <WhiteCardContainer>
        <div className="flex align-items-center mb-2">
          <ChronoContainer>
            <ChronoIcon color={theme.neutralColor900} width={15} height={15} />
            <HUText fontStyle="LL">
              {exercise.chrono?.type
                ? getTrainingChronoTitle(exercise.chrono.type) + " - " + displayTotalTime(exercise.chrono)
                : word("chrono.title.no_chrono")}
            </HUText>
          </ChronoContainer>
        </div>
        {exercise.rpe !== null && exercise.rpe !== undefined && <RpeTag exercise={exercise} />}
        <HUText style={{ whiteSpace: "pre-line" }} fontStyle="BM">
          <Linkify youtubeProps={{ allowEmbed: true }} content={exercise.description} />
        </HUText>
        {exercise.score && exercise.score.value !== 0 && (
          <div className="flex align-items-center mt-4 mb-2">
            <HUText fontStyle="LL">{word("program.session.score")}</HUText>
            <Separator />
            <HUText fontStyle="LL" className="mr-2">
              {getScore(exercise.score.value, exercise.scoreType)}
            </HUText>
            <LevelTag>{exercise.score.difficulty}</LevelTag>
          </div>
        )}
        {exercise.scoreType && exercise.scoreType !== ScoreType.NO_SCORE && !exercise.score?.value && (
          <div className="flex align-items-center mt-4 mb-2">
            <HUText fontStyle="LL">{word("program.session.score")}</HUText>
            <Separator />
            <HUText fontStyle="LL" className="mr-2">
              {displayScoreType}
            </HUText>
          </div>
        )}
        {renderButton}
        {isScoreShown && exercise.scoreType !== ScoreType.NO_SCORE && (
          <ScoreEntry
            exercise={exercise}
            parentSessionOrigin={parentSessionOrigin}
            setIsScoreShown={setIsScoreShown}
            refreshSession={refreshSession}
            isScoreShown={isScoreShown}
          />
        )}
      </WhiteCardContainer>
    </BlackCardContainer>
  )
}

const ChronoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`
const BlackCardContainer = styled.div`
  background-color: ${({ theme }) => theme.sessionExerciseContainerBgColor};
  display: flex;
  flex-direction: column;
  padding: 16px 7px 7px 7px;
  border-radius: 33px;
  margin-bottom: 10px;
  margin-top: 10px;
`
const WhiteCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.sessionExerciseContentBgColor};
  display: flex;
  padding: 28px 22px 22px 22px;
  border-radius: 28px;
`

const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px 10px 16px;
`
const CheckboxView = styled(Checkbox)<{ $checked: boolean }>`
  height: 28px;
  width: 28px;
  cursor: default !important;
  .p-checkbox-input {
    cursor: default !important;
  }
  .p-checkbox-box {
    color: ${({ theme }) => theme.mainWhite};
    border-radius: 50%;
    height: 28px;
    width: 28px;
    background: ${({ theme, $checked }) => ($checked ? theme.textLink : theme.mainWhite)} !important;
    border-color: ${({ theme, $checked }) => ($checked ? theme.textLink : theme.mainWhite)} !important;
  }
  .p-checkbox-box .p-checkbox-icon {
    color: ${({ theme, $checked }) => ($checked ? theme.mainWhite : theme.neutralColor)} !important;
  }
`
const Separator = styled.div`
  display: flex;
  width: 115px;
  height: 1px;
  background-color: ${({ theme }) => theme.neutralColor200};
  margin-left: 15px;
  margin-right: 15px;
`
const LevelTag = styled(Tag)`
  background-color: ${({ theme }) => theme.neutralColor200};
  padding: 4px 9px;
  color: ${({ theme }) => theme.neutralColor700};
  border-radius: 40px;
  font-style: italic;
`
