import { BoxOfferItem } from "@modules/boxOffer/boxOfferTypes"
import { BoxSlotParticipation } from "@modules/boxSlot/boxSlotTypes"
import { Profile, ProgramAuthor } from "@modules/profile/profileTypes"
import { HUImage } from "@modules/types/imageTypes"

export const BoxProgrammationTypeOf = "BOX_PROGRAMMATION"

export enum BoxProgrammationVisibility {
  MEMBERS = "MEMBERS",
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum BoxPopUp {
  TEMPLATE = "TEMPLATE",
  PROGRAM = "¨PROGRAM",
}

export type ItemBoxProgrammation = {
  id: string
  _type: typeof BoxProgrammationTypeOf
  _programType: typeof BoxProgrammationTypeOf
  name: string
  image: HUImage
  author: ProgramAuthor
  coachs: Profile[]
  subscriptionCount: number
  color: string
  visibility?: BoxProgrammationVisibility
}

export type BoxProgrammation = {
  description: string
  defaultHideSessionContent?: boolean
  restrictions?: BoxOfferItem[]
} & ItemBoxProgrammation

export type BoxProgrammationDraft = {
  _type: typeof BoxProgrammationTypeOf
  _programType: typeof BoxProgrammationTypeOf
  name: string
  description: string
  image?: HUImage
  color: string
  visibility?: BoxProgrammationVisibility
  defaultHideSessionContent: boolean
  restrictions?: string[]
}

export type BoxProgrammationLight = {
  id: string
  name: string
  color: string
  defaultHideSessionContent: boolean
  visibility?: BoxProgrammationVisibility
}

export function convertBoxProgrammationToDraft(program?: BoxProgrammation): BoxProgrammationDraft {
  return {
    _type: BoxProgrammationTypeOf,
    _programType: BoxProgrammationTypeOf,
    name: program?.name ?? "",
    description: program?.description ?? "",
    image: program?.image,
    color: program?.color ?? "",
    visibility: program?.visibility,
    defaultHideSessionContent: program?.defaultHideSessionContent ?? false,
    restrictions: program?.restrictions?.map((restriction) => restriction.id),
  }
}

export type BoxProgrammationDefaultSettings = {
  duration?: number
  participantLimit?: number
  location?: string
  participation?: BoxSlotParticipation
}
