import { word } from "@modules/core/utils/i18n"
import { HUImage } from "@modules/types/imageTypes"

export const allBoxRoles = ["OWNER", "CO_OWNER"] as const
export type BoxRole = (typeof allBoxRoles)[number]
export const allBoxRolesSelectable = allBoxRoles.filter((v) => v !== "OWNER")

export interface StaffMember {
  id: string
  userId: string
  email: string
  avatar?: HUImage
  firstname: string
  lastname: string
  role: BoxRole
}

export const displayBoxRole: {
  [key in BoxRole]: string
} = {
  OWNER: word("staff.role.owner"),
  CO_OWNER: word("staff.role.co_owner"),
}
