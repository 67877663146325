import { useServicesContext } from "@modules/core/services/services.context"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { getDisplayedScore } from "@modules/scoreboard/scoreUtils"
import { DEFAULT_UNIT, HUUnit } from "@modules/utils/unitType"
import { useObservable } from "micro-observables"

export const useUnit = (): HUUnit => {
  const { profileService } = useServicesContext()
  return useObservable(profileService.profileUnit.select((profileUnit) => profileUnit.unit)) ?? DEFAULT_UNIT
}

export const useGetDisplayedScore = () => {
  const unit = useUnit()
  const language = useLanguage()
  return (score: number, scoreType: ScoreType) => {
    return getDisplayedScore(score, scoreType, unit, language)
  }
}
