import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const ImportTemplateIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_8684_109670)">
      <path
        d="M2.83268 13.3327H10.9993C11.3088 13.3327 11.6055 13.2098 11.8243 12.991C12.0431 12.7722 12.166 12.4754 12.166 12.166V4.58268L9.24935 1.66602H3.99935C3.68993 1.66602 3.39318 1.78893 3.17439 2.00772C2.9556 2.22652 2.83268 2.52326 2.83268 2.83268V5.16602M8.66602 1.66602V3.99935C8.66602 4.30877 8.78893 4.60551 9.00773 4.82431C9.22652 5.0431 9.52326 5.16602 9.83268 5.16602H12.166M1.66602 9.24935H7.49935M7.49935 9.24935L5.74935 10.9993M7.49935 9.24935L5.74935 7.49935"
        stroke={props.color ?? "#D1D1D1"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8684_109670">
        <rect width="14" height="14" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
