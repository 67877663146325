import { word } from "@modules/core/utils/i18n"
import {
  Language,
  getAvailableLanguages,
  getDefaultLanguage as getSharedDefaultLanguage,
} from "@modules/language/utils/languageUtils"
import dayjs from "dayjs"

type LanguageInfos = {
  name: string
  value: Language
  country: {
    name: string
    shortName: string
  }
}

function getAllLanguageInfos(): Record<Language, LanguageInfos> {
  return {
    en: {
      name: word("global.language.english.label"),
      value: Language.ENGLISH_US,
      country: {
        name: word("global.language.english.country.long"),
        shortName: word("global.language.english.country.short"),
      },
    },
    fr: {
      name: word("global.language.french.label"),
      value: Language.FRENCH,
      country: {
        name: word("global.language.french.country.long"),
        shortName: word("global.language.french.country.short"),
      },
    },
    de: {
      name: word("global.language.german.label"),
      value: Language.GERMAN,
      country: {
        name: word("global.language.german.country.long"),
        shortName: word("global.language.german.country.short"),
      },
    },
    it: {
      name: word("global.language.italian.label"),
      value: Language.ITALIAN,
      country: {
        name: word("global.language.italian.country.long"),
        shortName: word("global.language.italian.country.short"),
      },
    },
    es: {
      name: word("global.language.spanish.label"),
      value: Language.SPANISH,
      country: {
        name: word("global.language.spanish.country.long"),
        shortName: word("global.language.spanish.country.short"),
      },
    },
    pt: {
      name: word("global.language.portuguese.label"),
      value: Language.PORTUGUESE,
      country: {
        name: word("global.language.portuguese.country.long"),
        shortName: word("global.language.portuguese.country.short"),
      },
    },
    el: {
      name: word("global.language.greek.label"),
      value: Language.GREEK,
      country: {
        name: word("global.language.greek.country.long"),
        shortName: word("global.language.greek.country.short"),
      },
    },
    is: {
      name: word("global.language.icelandic.label"),
      value: Language.ICELANDIC,
      country: {
        name: word("global.language.icelandic.country.long"),
        shortName: word("global.language.icelandic.country.short"),
      },
    },
    da: {
      name: word("global.language.danish.label"),
      value: Language.DANISH,
      country: {
        name: word("global.language.danish.country.long"),
        shortName: word("global.language.danish.country.short"),
      },
    },
    nl: {
      name: word("global.language.dutch.label"),
      value: Language.DUTCH,
      country: {
        name: word("global.language.dutch.country.long"),
        shortName: word("global.language.dutch.country.short"),
      },
    },
    br: {
      name: word("global.language.brazilian_portuguese.label"),
      value: Language.BRAZILIAN_PORTUGUESE,
      country: {
        name: word("global.language.brazilian_portuguese.country.long"),
        shortName: word("global.language.brazilian_portuguese.country.short"),
      },
    },
    ko: {
      name: word("global.language.korean.label"),
      value: Language.KOREAN,
      country: {
        name: word("global.language.korean.country.long"),
        shortName: word("global.language.korean.country.short"),
      },
    },
  }
}

export function getLanguageInfos(all: boolean): LanguageInfos[] {
  return Object.values(getAllLanguageInfos()).filter((l) => getAvailableLanguages(all).find((al) => al === l.value))
}

export function getFormatedDateTime(dateTime: dayjs.ConfigType, options?: { format?: string; mode?: "date" | "time" }) {
  return dayjs(dateTime).format(options?.format ?? (options?.mode === "time" ? "LT" : "L"))
}

export function getDefaultLanguage(all: boolean) {
  return getSharedDefaultLanguage(navigator.language.split("-")[0], { all })
}
