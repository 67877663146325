import ProgramEmpty from "@images/empty/program_empty.svg"
import { InfinityIcon } from "@images/svgIcons/infinityIcon"
import { OnOffIcon } from "@images/svgIcons/onOffIcon"
import { useUser } from "@modules/auth/hooks/useUser"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useMySpaceNavigate } from "@modules/navigation/useMySpaceNavigate"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffStatus, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ItemProgram, SharedCoach, SharedCoachStatus } from "@modules/programs/programTypes"
import { getDisplayStatus, getSubscriptionCountText } from "@modules/programs/programUtils"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { useAllProgramsSelected, useProgramSelected } from "@modules/training/useTraining"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUCheckbox } from "@modules/ui/components/huCheckbox"
import { HUText } from "@modules/ui/components/huText"
import { SIDEBAR_DEFAULT_WIDTH } from "@modules/ui/uiConstants"
import { useWindowSize } from "@modules/utils/hooks/useWindowsSize"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Tag } from "primereact/tag"

import { Tooltip } from "primereact/tooltip"
import styled, { useTheme } from "styled-components"

type TrainingProgramsFiltersProps = {
  getProgramsAsync?: { loading: boolean; result: readonly ItemProgram[] }
  allowProgramSelection: boolean
  title?: string
  tag?: {
    color?: string
    backgroundColor?: string
  }
  emptyTitle?: string
  emptyDescription?: string
  emptyButtonLabel?: string
  emptyButtonIconClassName?: string
  emptyButtonAction?: () => void
}

export const TrainingProgramsFilters: React.FC<TrainingProgramsFiltersProps> = ({
  getProgramsAsync,
  allowProgramSelection,
  title,
  tag,
  emptyTitle,
  emptyDescription,
  emptyButtonLabel,
  emptyButtonIconClassName,
  emptyButtonAction,
}) => {
  const theme = useTheme()
  const user = useUser()

  const fetchedPrograms = getProgramsAsync?.result
  const mySpaceNavigate = useMySpaceNavigate()
  const isAllSelected = useAllProgramsSelected(fetchedPrograms as ItemProgram[])
  const { planningService } = useServicesContext()
  const { hideSidebar } = useSidebar()
  const { width } = useWindowSize()

  const onSelectedProgram = (program: ItemProgram) => {
    hideSidebar()
    if (program._programType === ProgramInfiniteTypeOf) {
      mySpaceNavigate(Path.MySpace.ProgramDetails(program.id))
    } else if (program._programType === ProgramOnOffTypeOf) {
      mySpaceNavigate(Path.MySpace.ProgramOnOffDetails(program.id))
    }
  }

  const selectAllToggle = () => {
    if (isAllSelected) {
      deselectAll()
    } else {
      selectAll()
    }
  }

  // Check All Programs
  const selectAll = () => {
    fetchedPrograms?.forEach((program) => {
      planningService.addNewFilteredProgram(program.id)
    })
  }

  // Uncheck All Programs
  const deselectAll = () => {
    fetchedPrograms?.forEach((program) => {
      planningService.removeFilteredProgram(program.id)
    })
  }
  const useCheckboxTemplate = (data: ItemProgram) => {
    const isSelected = useProgramSelected(data.id)

    return (
      <div className="pl-2">
        <HUCheckbox
          inputKey={""}
          name="option"
          value={data.id}
          checked={isSelected}
          onChange={() => {
            planningService.toggleSelectedProgram(data.id)
          }}
          color={theme.primaryColor}
          checkIconColor={theme.neutralColor700}
        />
      </div>
    )
  }

  if (!emptyTitle && (!fetchedPrograms || fetchedPrograms.length === 0)) {
    return null
  }

  return (
    <>
      <div className="flex justify-content-between mb-4 mt-5">
        <TagTitle fontStyle="TS" $color={tag?.color} $backgroundColor={tag?.backgroundColor}>
          {title}
        </TagTitle>
        {allowProgramSelection && fetchedPrograms !== undefined && fetchedPrograms.length > 0 && (
          <HUCheckbox
            name="option"
            onChange={selectAllToggle}
            label={word("global.filters.selectAll")}
            gap={10}
            color={theme.primaryColor}
            checkIconColor={theme.neutralColor700}
            inputKey={""}
            checked={isAllSelected}
            styleLabel="tm-label"
          />
        )}
      </div>
      {fetchedPrograms !== undefined && fetchedPrograms.length > 0 ? (
        <StyledTrainingProgramsTable
          paginator={false}
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          value={fetchedPrograms as ItemProgram[]}
          dataKey="id"
          emptyMessage={word("global.no_result")}
        >
          {allowProgramSelection && <Column field="checkbox" body={useCheckboxTemplate} className="checkbox-col" />}
          <Column
            header={word("global.name")}
            field="title"
            className="title-col"
            body={(data) => (
              <TitleContainer
                className={`tooltip${data.id} cursor-pointer`}
                data-pr-showdelay={200}
                data-pr-position="bottom"
                color="black"
                onClick={() => {
                  onSelectedProgram(data)
                }}
              >
                {data.title.length > 19 && (
                  <Tooltip className="tooltip-container" target={`.tooltip${data.id}`}>
                    <div className="tooltip-content">
                      <HUText type="label" fontStyle="BM" style={{ textAlign: "left" }}>
                        {data.title}
                      </HUText>
                    </div>
                  </Tooltip>
                )}
                <HUText fontStyle="TS">
                  {data.title.length > 19 ? data.title.substring(0, 18) + "..." : data.title}{" "}
                </HUText>
                {data.status === ProgramOnOffStatus.DRAFT && (
                  <StatusTag rounded>
                    <HUText fontStyle="LM">{getDisplayStatus(data.status)}</HUText>
                  </StatusTag>
                )}
              </TitleContainer>
            )}
          />
          <Column
            header={word("programs.global.founder")}
            field="author"
            body={(data) => (
              <div
                onClick={() => {
                  onSelectedProgram(data)
                }}
                style={{ padding: "5px 0px" }}
              >
                {`${data.author.firstname} ${data.author.lastname}`}
              </div>
            )}
            className="founder-col"
          />
          {width >= SIDEBAR_DEFAULT_WIDTH && (
            <Column
              header={word("program.label.subscribers")}
              field="subscriptionCount"
              body={(data) => (
                <SubscribedContainer
                  onClick={() => {
                    onSelectedProgram(data)
                  }}
                >
                  {getSubscriptionCountText(
                    data.subscriptionCount,
                    data.author.id === user?.id,
                    data.sharedCoaches?.some(
                      (coach: SharedCoach) => coach.user.id === user?.id && coach.status === SharedCoachStatus.ACCEPTED,
                    ) ?? false,
                    true,
                    data._programType,
                  ).toLowerCase()}
                </SubscribedContainer>
              )}
            />
          )}
          {width >= SIDEBAR_DEFAULT_WIDTH && (
            <Column
              header={word("program.type")}
              field="visibility"
              body={(data) => (
                <div
                  className="centered-content"
                  onClick={() => {
                    onSelectedProgram(data)
                  }}
                  style={{ padding: "5px 0px" }}
                >
                  {data._programType === ProgramInfiniteTypeOf ? (
                    <InfinityIcon color={theme.neutralColor900} width={24} height={24} />
                  ) : (
                    <OnOffIcon color={theme.neutralColor900} width={24} height={24} />
                  )}
                </div>
              )}
              className="visibility-col"
            />
          )}
        </StyledTrainingProgramsTable>
      ) : (
        <div className="flex justify-content-center align-items-center flex-grow-1">
          <EmptyView
            image={{ src: ProgramEmpty }}
            title={emptyTitle}
            description={emptyDescription}
            hidden={!emptyTitle}
            flexGrow={false}
            className="p-6"
            style={{ backgroundColor: theme.mainWhite }}
            button={
              emptyButtonAction &&
              emptyButtonLabel && (
                <HUButton
                  type="Left_Icon"
                  colorType="Primary"
                  size="M"
                  icon={
                    emptyButtonIconClassName
                      ? {
                          iconView: <span className={emptyButtonIconClassName} />,
                        }
                      : undefined
                  }
                  onClick={emptyButtonAction}
                  text={emptyButtonLabel}
                />
              )
            }
            paddingType="In_table"
          />
        </div>
      )}
    </>
  )
}

const TagTitle = styled(HUText)<{ $color?: string; $backgroundColor?: string }>`
  padding: 4px 13px;
  border-radius: 1rem;
  border: ${({ $backgroundColor, $color }) => `1px solid ${$backgroundColor ?? $color}`};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
`

const StyledTrainingProgramsTable = styled(DataTable)`
  margin-bottom: 40px;

  .p-datatable-thead > tr > th {
    background-color: transparent;
    color: black;
    font-size: 0.8rem;
    font-weight: 600;
    white-space: nowrap;
    line-height: 20.2px;
    border-bottom: 1px solid ${(props) => props.theme.neutralColor300};
  }

  .p-datatable-tbody > tr {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    line-height: 15.34px;
    color: ${(props) => props.theme.neutralColor900};
  }

  .p-datatable-tbody > tr:hover {
    & td {
      background-color: ${(props) => props.theme.neutralColor100};
    }

    & td:first-child {
      border-top-left-radius: 28px;
      border-bottom-left-radius: 28px;
    }

    & td:last-child {
      border-top-right-radius: 28px;
      border-bottom-right-radius: 28px;
    }
  }

  .p-datatable-tbody > tr > td {
    max-width: 150px;
    cursor: pointer;
    height: 54px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0rem 1em !important;
    font-size: 0.7rem;
  }

  .p-datatable-tbody > tr > td:first-child {
    padding-left: 10px !important;
    padding-right: 0px !important;
  }

  .p-datatable-tbody > tr > td > .subscribed-col div {
    text-align: center;
  }

  .centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-col {
    min-width: 50%;
  }
`
const TitleContainer = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.neutralColor900};
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0px;
`

const SubscribedContainer = styled.div`
  text-align: center;
  padding: 5px 0px;
`

const StatusTag = styled(Tag)`
  align-items: center !important;
  background-color: ${({ theme }) => theme.neutralColor100};
`
