import { LoggerType } from "@modules/utils/loggerUtils"

const colorRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i

const hexToRgb = (hex: string) => {
  const result = colorRegex.exec(hex.trim())

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

const rgbToHex = ({ r, g, b }: { r: number; g: number; b: number }) => `rgb(${r}, ${g}, ${b})`

export const whiten = (color: string, factor = 0.1) => {
  const rgb = hexToRgb(color)
  if (!rgb) {
    console.warn(LoggerType.UI + "Can't convert color")
    return color
  }
  const newColor = {
    r: Math.min(255, rgb.r + 255 * factor),
    g: Math.min(255, rgb.g + 255 * factor),
    b: Math.min(255, rgb.b + 255 * factor),
  }

  return rgbToHex(newColor)
}

export const hexToRgbString = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return `${r}, ${g}, ${b}`
}
