import { ForbiddenIcon } from "@images/svgIcons/forbiddenIcon"
import { useCurrentBox } from "@modules/box/useBox"
import { word } from "@modules/core/utils/i18n"
import { useStripeAccount } from "@modules/finances/hooks/useStripeAccount"
import { Path } from "@modules/navigation/routes"
import { getBoxPath } from "@modules/navigation/useBoxNavigate"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const StripeStatusBanner: React.FC = () => {
  const { result: box } = useCurrentBox()
  const { createStripeAccount, loading: actionLoading } = useStripeAccount()
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Banner>
      <HURow align justifySpaceBetween flexGrow>
        <HURow align gap={18}>
          <IconWrapper>
            <ForbiddenIcon color={theme.mainWhite} />
          </IconWrapper>
          <HUStack gap={0}>
            <HUText fontStyle="BL" color={theme.mainWhite}>
              {word("finances.page.banner.stripe.alert.title")}
            </HUText>
            <HUText fontStyle="BM" color={theme.mainWhite}>
              {word("finances.page.banner.stripe.alert.description")}
            </HUText>
          </HUStack>
        </HURow>
        <Button
          rounded
          label={word("finances.page.banner.stripe.alert.button.label")}
          className="p-button-primary"
          loading={actionLoading}
          onClick={() => {
            if (box) navigate({ pathname: getBoxPath(Path.Box.Admin, { newBoxId: box.id }), search: "tab=banking" })
            else createStripeAccount()
          }}
        />
      </HURow>
    </Banner>
  )
}

const Banner = styled.div`
  position: relative;
  display: flex;
  padding: 28px 24px;
  background-color: ${({ theme }) => theme.primaryColor900};
  border-radius: 6px;
  overflow: hidden;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: ${({ theme }) => theme.primaryColor};
  }
`

const IconWrapper = styled.div`
  display: flex;
  border-radius: 50px;
  padding: 13px;
  background-color: ${({ theme }) => theme.primaryColor800};
  justify-content: center;
  align-items: center;
`
