import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BoxOfferMillenniumIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none" {...props}>
    <mask
      id="mask0_7997_96500"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="11"
      y="21"
      width="15"
      height="25"
    >
      <rect
        width="16.087"
        height="16.087"
        transform="matrix(0.866025 0.5 -2.20305e-08 1 11.5601 21.5029)"
        fill="#6450F7"
      />
    </mask>
    <g mask="url(#mask0_7997_96500)">
      <rect
        width="16.087"
        height="16.087"
        transform="matrix(0.866025 0.5 -2.20305e-08 1 11.5601 21.5029)"
        fill="#3B3184"
      />
      <ellipse cx="16.5735" cy="23.3542" rx="9.15619" ry="15.5124" fill="#6450F7" />
    </g>
    <rect
      width="16.087"
      height="16.087"
      transform="matrix(0.866025 -0.5 2.20305e-08 1 25.4918 29.5464)"
      fill="#3B3184"
    />
    <rect width="16.087" height="16.087" transform="matrix(0.866025 0.5 -0.866025 0.5 25.4918 13.459)" fill="#6450F7" />
    <rect
      width="2.24502"
      height="16.0848"
      transform="matrix(0.866025 0.5 -2.20305e-08 1 16.5736 24.4004)"
      fill="url(#paint0_linear_7997_96500)"
    />
    <rect
      width="2.24502"
      height="16.0848"
      transform="matrix(0.866025 -0.5 2.20305e-08 1 31.4784 26.0815)"
      fill="#D1D1D1"
    />
    <rect
      width="16.0848"
      height="2.24439"
      transform="matrix(0.866025 0.5 -0.866025 0.5 19.5048 16.9238)"
      fill="white"
    />
    <mask
      id="mask1_7997_96500"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="16"
      width="17"
      height="10"
    >
      <rect
        width="16.0848"
        height="2.24439"
        transform="matrix(0.866025 -0.5 0.866025 0.5 16.5736 24.4004)"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_7997_96500)">
      <rect
        width="16.0848"
        height="2.24439"
        transform="matrix(0.866025 -0.5 0.866025 0.5 16.5736 24.4004)"
        fill="white"
      />
      <ellipse cx="24.1163" cy="20.9078" rx="2.27243" ry="1.67638" fill="#D1D1D1" />
    </g>
    <mask
      id="mask2_7997_96500"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="14"
      y="12"
      width="13"
      height="12"
    >
      <path
        d="M26.8358 21.1502L24.2558 19.6606C24.2558 19.6606 20.9568 15.8865 18.7449 13.7932L18.7149 13.7658L18.7089 13.7613C18.3886 13.7795 18.1169 13.9389 17.9135 14.2782C16.9578 15.8713 16.837 18.1016 17.9135 20.7525C18.0366 21.0558 18.1793 21.3225 18.3381 21.5565L18.3344 21.5561L18.3393 21.5583L18.3381 21.5565C20.2702 21.7639 22.2809 20.7701 22.2809 20.7701L24.8609 22.2597C24.8609 22.2597 21.3801 23.98 19.1775 22.4214L16.9529 21.1502C16.2942 20.8016 15.7183 20.2105 15.3335 19.263C14.2571 16.6121 14.3779 14.3817 15.3335 12.7886C15.7971 12.0158 16.6146 12.1764 17.5548 12.8131L19.5425 13.9601C22.023 15.117 26.8358 21.1502 26.8358 21.1502Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask2_7997_96500)">
      <path
        d="M25.8888 20.8858L23.3088 19.3962C23.3088 19.3962 20.9568 15.8865 18.7449 13.7932L18.7149 13.7658L18.7089 13.7613C18.3886 13.7795 18.1169 13.9389 17.9135 14.2782C16.9578 15.8713 16.837 18.1016 17.9135 20.7525C18.0366 21.0558 18.1793 21.3225 18.3381 21.5565L18.3344 21.5561L18.3393 21.5583L18.3381 21.5565C20.2702 21.7639 22.2809 20.7701 22.2809 20.7701L24.8609 22.2597C24.8609 22.2597 21.3801 23.98 19.1775 22.4214L16.9529 21.1502C16.2942 20.8016 15.7183 20.2105 15.3335 19.263C14.2571 16.6121 14.3779 14.3817 15.3335 12.7886C15.7971 12.0158 16.6146 12.1764 17.5548 12.8131L19.5425 13.9601C22.023 15.117 25.8888 20.8858 25.8888 20.8858Z"
        fill="white"
      />
      <path
        d="M26.9974 20.4138C26.9974 22.1417 24.9929 23.5425 22.5202 23.5425C20.0476 23.5425 18.0431 22.1417 18.0431 20.4138C18.0431 19.4598 18.6541 18.6055 19.6175 18.0316C20.3991 17.566 17.6407 13.7632 18.7482 13.7632C21.2208 13.7632 26.9974 18.6858 26.9974 20.4138Z"
        fill="#D1D1D1"
      />
    </g>
    <mask
      id="mask3_7997_96500"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="22"
      y="12"
      width="13"
      height="12"
    >
      <path
        d="M22.2025 21.1502L24.7825 19.6606C24.7825 19.6606 28.0815 15.8865 30.2935 13.7932L30.3234 13.7658L30.3294 13.7613C30.6497 13.7795 30.9214 13.9389 31.1249 14.2782C32.0805 15.8713 32.2013 18.1016 31.1249 20.7525C31.0017 21.0558 30.859 21.3225 30.7002 21.5565L30.704 21.5561L30.699 21.5583L30.7002 21.5565C28.7682 21.7639 26.7574 20.7701 26.7574 20.7701L24.1775 22.2597C24.1775 22.2597 27.6582 23.98 29.8608 22.4214L32.0854 21.1502C32.7441 20.8016 33.3201 20.2105 33.7048 19.263C34.7813 16.6121 34.6604 14.3817 33.7048 12.7886C33.2413 12.0158 32.4237 12.1764 31.4835 12.8131L29.4958 13.9601C27.0153 15.117 22.2025 21.1502 22.2025 21.1502Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask3_7997_96500)">
      <path
        d="M23.1495 20.8858L25.7295 19.3962C25.7295 19.3962 28.0815 15.8865 30.2935 13.7932L30.3234 13.7658L30.3294 13.7613C30.6497 13.7795 30.9214 13.9389 31.1249 14.2782C32.0805 15.8713 32.2013 18.1016 31.1249 20.7525C31.0017 21.0558 30.859 21.3225 30.7002 21.5565L30.704 21.5561L30.699 21.5583L30.7002 21.5565C28.7682 21.7639 26.7574 20.7701 26.7574 20.7701L24.1775 22.2597C24.1775 22.2597 27.6582 23.98 29.8608 22.4214L32.0854 21.1502C32.7441 20.8016 33.3201 20.2105 33.7048 19.263C34.7813 16.6121 34.6604 14.3817 33.7048 12.7886C33.2413 12.0158 32.4237 12.1764 31.4835 12.8131L29.4958 13.9601C27.0153 15.117 23.1495 20.8858 23.1495 20.8858Z"
        fill="#D1D1D1"
      />
      <path
        d="M22.041 20.4138C22.041 22.1417 24.0454 23.5425 26.5181 23.5425C28.9908 23.5425 30.9952 22.1417 30.9952 20.4138C30.9952 19.4598 30.3843 18.6055 29.4208 18.0316C28.6392 17.566 31.3977 13.7632 30.2901 13.7632C27.8175 13.7632 22.041 18.6858 22.041 20.4138Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_7997_96500"
        x1="-0.408449"
        y1="12.09"
        x2="1.93436"
        y2="15.2903"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.5" stopColor="white" />
        <stop offset="0.5" stopColor="#D1D1D1" />
      </linearGradient>
    </defs>
  </svg>
)
