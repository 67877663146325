import { HUButtonIcon, HUButtonIconProps } from "@modules/ui/components/huButtonIcon"
import { HUText } from "@modules/ui/components/huText"
import { HUFontStyle } from "@modules/ui/styles/font"
import { ButtonProps } from "primereact/button"
import { CSSProperties, HTMLAttributeAnchorTarget } from "react"
import { Link, To } from "react-router-dom"
import styled, { useTheme } from "styled-components"

type HUNavigateButtonProps = {
  to: To
  state?: any
  style?: CSSProperties
  className?: string
  buttonStyle?: CSSProperties
  buttonClassName?: string
  onPress?: () => void
  isBlack?: boolean
  icon?: HUButtonIconProps
  iconPosition?: "right" | "left"
  linkTarget?: HTMLAttributeAnchorTarget
  fontStyle?: HUFontStyle
} & Omit<ButtonProps, "icon" | "iconPos">

export const HUNavigateButton: React.FC<HUNavigateButtonProps> = ({
  to,
  state,
  style,
  className,
  buttonStyle,
  buttonClassName,
  onPress,
  isBlack,
  icon,
  iconPosition = "left",
  linkTarget = "_self",
  fontStyle = "LL",
  ...props
}) => {
  const handleClick = (e: { preventDefault: () => void }) => {
    if (props.disabled || onPress) {
      e.preventDefault()
      onPress?.()
    }
  }
  const theme = useTheme()
  const textColor = props.color ? props.color : props.link ? theme.textPrimary : theme.buttonPrimaryTextColor

  return (
    <Link to={to} state={state} className={className} onClick={handleClick} style={style} target={linkTarget}>
      <ButtonContainer
        className={`p-button p-component ${props.link ? "p-button-link" : ""} ${
          icon && !props.label ? "p-button-icon-only" : ""
        } ${isBlack ? "p-button-bg-button-black" : ""} ${buttonClassName ?? ""}`}
        style={{
          ...buttonStyle,
          textAlign: props.link ? "left" : undefined,
          color: textColor,
          gap: 8,
        }}
      >
        {icon && iconPosition === "left" && (
          <HUButtonIcon icon={icon} iconColor={isBlack ? theme.mainWhite : textColor} defaultIconSize={12} />
        )}
        {props.label && (
          <HUText
            fontStyle={fontStyle}
            className={`p-button-label ${isBlack ? "p-button-text-button-white" : ""}  p-c`}
            data-pc-section="label"
            color={textColor}
          >
            {props.label}
          </HUText>
        )}
        {icon && iconPosition === "right" && (
          <HUButtonIcon icon={icon} iconColor={isBlack ? theme.mainWhite : textColor} defaultIconSize={12} />
        )}
      </ButtonContainer>
    </Link>
  )
}

const ButtonContainer = styled.div`
  border-radius: 60px;
  &.p-button-bg-button-black {
    background-color: ${({ theme }) => theme.buttonBlackBgColor};
    border: none;
    padding: 10px 20px 10px 16px;
    transition: background-color 0.2s linear;
    &:hover {
      background-color: ${({ theme }) => theme.buttonBlackHoverBgColor}!important;
    }
  }
  > .p-button-text-button-white {
    line-height: 15.6px;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.mainWhite};
  }
`
