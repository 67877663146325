export const Path = {
  MySpace: {
    Root: "/my-space",
    Training: "training",
    Programs: "programs",
    Finances: "finances",
    Explore: "explore",
    Profile: "profile",
    Tutorials: "tutorials",
    ProgramDetails: (programId: string) => `programs/${programId}`,
    ProgramOnOffDetails: (programId: string) => `programs/on-off/${programId}`,
    SessionDetails: (sessionId: string) => `session/${sessionId}`,
    SessionEditionForm: (sessionId: string) => `session/edit/${sessionId}`,
    SessionCreationForm: "session/creation",
  },
  ConfirmEmail: "/confirm-email",
  CreateBox: "/create-box",
  Login: "/login",
  Welcome: "/welcome",
  Box: {
    Root: (id: string) => `/box/${id}`,
    Finances: "finances",
    Profile: "profile",
    Members: "members",
    MemberForm: (id: string) => `member/${id}`,
    Offers: "offers",
    Planning: "planning",
    ProgrammationDetails: (boxProgrammationId: string) => `programmations/${boxProgrammationId}`,
    ProgrammationTemplate: (boxProgrammationId: string) => `programmations/${boxProgrammationId}/template`,
    ProgrammationSessions: (boxProgrammationId: string) => `programmations/${boxProgrammationId}/sessions`,
    Admin: "admin",
    SlotDetails: (slotId: string) => `slot/${slotId}`,
    SlotEditionForm: (slotId: string) => `slot/edit/${slotId}`,
    SlotCreationForm: "slot/creation",
    Return: "onboarding/return",
    Refresh: "onboarding/refresh",
    OnboardingStripe: "onboarding/stripe",
  },
  Chat: {
    Root: "chat",
  },
  SignUp: "/sign-up",
  Register: "/register",
  ResetPassword: "/reset-password",
  Root: "/",
  SendCode: "/send-code",
  ProgramInvitation: `/program_invitation`,
  BoxParticipation: `/box_participation`,
  PrivacyPolicy: "/privacy-policy",
  Eula: "/eula",
  UseCharter: "/use-charter",
  AladinChallenge: "/aladin-challenge",
}
