import { BoxProgrammationSessionDto } from "@modules/boxProgrammation/services/boxProgrammationDto"
import { BoxSlot, BoxSlotDraft, BoxSlotSession } from "@modules/boxSlot/boxSlotTypes"
import {
  BoxProgrammationSession,
  BoxProgrammationSessionDraft,
  BoxSlotDto,
  convertBoxProgrammationSessionDraftToDraftDto,
  convertBoxProgrammationSessionFromDto,
  convertBoxSlotDraftToDraftDto,
  convertBoxSlotFromDto,
} from "@modules/boxSlot/services/boxSlotDto"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { ProfileDto, convertProfileFromDto } from "@modules/profile/profileDtos"
import { SubscriberDto, convertSubscriberFromDto } from "@modules/profile/subscriber/subscriberDtos"
import { ParticipantStatus } from "@modules/profile/subscriber/subscriberTypes"
import { defaultDateFormat } from "@modules/utils/dateUtils"
import { Paginated, toPage } from "@modules/utils/paginated"
import dayjs from "dayjs"

export class BoxSlotApi {
  private sessionBaseUrl = "/box/sessions"
  private slotBaseUrl = "/boxes/slots"

  constructor(private api: ApiClient) {}

  async getSessionDetails(id: string): Promise<BoxProgrammationSession | null> {
    const resultDto = await this.api.get<BoxProgrammationSessionDto>(`${this.sessionBaseUrl}/${id}`)
    return convertBoxProgrammationSessionFromDto(resultDto.data)
  }

  async getSlotDetails(id: string): Promise<BoxSlot> {
    const resultDto = await this.api.get<BoxSlotDto>(`${this.slotBaseUrl}/${id}`)
    return convertBoxSlotFromDto(resultDto.data)
  }

  async getSlotSessionDetails(id: string): Promise<BoxSlotSession> {
    const slotDetails = await this.getSlotDetails(id)
    const slotSessionDetails: BoxSlotSession = { ...slotDetails, session: null }
    if (slotDetails.sessionId) {
      slotSessionDetails.session = await this.getSessionDetails(slotDetails.sessionId)
    }
    return slotSessionDetails
  }

  async createSession(sessionDraft: BoxProgrammationSessionDraft) {
    const sessionDto = await this.api.post<BoxProgrammationSessionDto>(
      `box/sessions`,
      convertBoxProgrammationSessionDraftToDraftDto(sessionDraft),
    )
    return convertBoxProgrammationSessionFromDto(sessionDto.data)
  }

  async createSlot(slotDraft: BoxSlotDraft, sessionId: string | null) {
    const slotDto = await this.api.post<BoxSlotDto>(
      `${this.slotBaseUrl}`,
      convertBoxSlotDraftToDraftDto(slotDraft, sessionId),
    )
    return convertBoxSlotFromDto(slotDto.data)
  }

  async updateSession(id: string, sessionDraft: BoxProgrammationSessionDraft) {
    const resultDto = await this.api.put<BoxProgrammationSessionDto>(
      `${this.sessionBaseUrl}/${id}`,
      convertBoxProgrammationSessionDraftToDraftDto(sessionDraft),
    )
    return convertBoxProgrammationSessionFromDto(resultDto.data)
  }

  async deleteSession(id: string) {
    await this.api.delete(`${this.sessionBaseUrl}/${id}`)
  }

  async updateSlot(id: string, slot: BoxSlotDraft, sessionId: string | null) {
    const resultDto = await this.api.put<BoxSlotDto>(
      `${this.slotBaseUrl}/${id}`,
      convertBoxSlotDraftToDraftDto(slot, sessionId),
    )
    return convertBoxSlotFromDto(resultDto.data)
  }

  async deleteSlot(id: string) {
    await this.api.delete(`${this.slotBaseUrl}/${id}`)
  }

  async getSlotSubscribers(
    slotId: string,
    page?: number,
    size?: number,
    name?: string,
    participantStatus?: ParticipantStatus,
  ) {
    const result = await this.api.get<Paginated<SubscriberDto>>(`${this.slotBaseUrl}/${slotId}/participants`, {
      params: {
        page,
        size,
        name,
        participantStatus,
      },
    })
    const pageResult = toPage(result.data)

    return { ...pageResult, content: pageResult.content.map((member) => convertSubscriberFromDto(member)) }
  }

  async getSlotNonParticipants(slotId: string, name?: string) {
    const result = await this.api.get<Paginated<ProfileDto>>(`${this.slotBaseUrl}/${slotId}/non-participant-members`, {
      params: {
        page: 0,
        size: 10,
        name,
      },
    })
    const pageResult = toPage(result.data)

    return { ...pageResult, content: pageResult.content.map((member) => convertProfileFromDto(member)) }
  }

  async addParticipantToSlot(slotId: string, memberId: string) {
    const result = await this.api.post(`${this.slotBaseUrl}/${slotId}/participants`, { memberId })
    return result.data
  }

  async removeParticipantToSlot(slotId: string, memberId: string) {
    await this.api.delete(`${this.slotBaseUrl}/${slotId}/participants/${memberId}`)
  }

  async flagParticipant(slotId: string, memberId: string, absent: boolean) {
    const result = await this.api.post<{ absent: boolean }>(
      `${this.slotBaseUrl}/${slotId}/participants/${memberId}/absence`,
      {
        absent,
      },
    )
    return result.data.absent
  }

  async getScheduledSession(programId: string, date: string): Promise<BoxProgrammationSession | null> {
    const sessionDto = await this.api.get<BoxProgrammationSessionDto>(`${this.slotBaseUrl}/scheduled-session`, {
      params: {
        programId,
        date,
      },
    })
    return sessionDto.data ? convertBoxProgrammationSessionFromDto(sessionDto.data) : null
  }

  async getScheduledSessions(
    programId: string,
    startDate: string,
    endDate: string,
  ): Promise<BoxProgrammationSession[]> {
    const result = await this.api.get<BoxProgrammationSessionDto[]>(`${this.slotBaseUrl}/fetch-scheduled-sessions`, {
      params: {
        startDate: dayjs(startDate).format(defaultDateFormat),
        endDate: dayjs(endDate).format(defaultDateFormat),
        programId,
      },
    })

    return result.data ? result.data.map((sessionDto) => convertBoxProgrammationSessionFromDto(sessionDto)) : []
  }
}
