import { word } from "@modules/core/utils/i18n"
import { SimpleFormik } from "@src/typings/formik"
import dayjs from "dayjs"
import * as Yup from "yup"

export const requiredInput = (yup: Yup.StringSchema<string | undefined, Yup.AnyObject, undefined, "">) =>
  yup.required(word("global.form.required"))

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
export const sirenRegExp = /^[0-9]{9}$/
export const dateRegExpMember = /(^0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}$)/

export const startDateTimeMinDate = dayjs().startOf("day").set("hour", 0).toDate()
export const startDateTimeMaxDate = dayjs().startOf("day").set("hour", 23).set("minute", 58).toDate()
export const endDateTimeMinDate = dayjs().startOf("day").set("hour", 1).toDate()
export const endDateTimeMaxDate = dayjs().startOf("day").set("hour", 23).set("minute", 59).toDate()

export const validateStartDateTime = <T, K>(
  formik: SimpleFormik<T & { endDateTime: Date }, K>,
  startDateTime: string | undefined | number | object,
) => {
  if (startDateTime && typeof startDateTime === "number") {
    const newStartDays = dayjs(startDateTime)
    if (!newStartDays.isBefore(formik.values.endDateTime)) {
      if (newStartDays.hour() === 23) {
        formik.setFieldValue("endDateTime", newStartDays.set("minute", 59).toDate())
      } else {
        formik.setFieldValue("endDateTime", newStartDays.add(1, "hour").toDate())
      }
    }
  }
}

export const validateEndDateTime = <T, K>(
  formik: SimpleFormik<T & { startDateTime: Date }, K>,
  endDateTime: string | undefined | number | object,
) => {
  if (endDateTime && typeof endDateTime === "number") {
    if (!dayjs(endDateTime).isAfter(formik.values.startDateTime)) {
      formik.setFieldValue("startDateTime", null)
    }
  }
}
