import { BoxB2BPrograms } from "@modules/boxB2BProgram/boxB2BProgramTypes"
import { convertLightProgramFromDto, LightProgramDto } from "@modules/programs/services/programDto"

export type BoxB2BProgramsDto = {
  cap: LightProgramDto
  subscribedPrograms: LightProgramDto[]
}

export function convertBoxB2BProgramsFromDto(dto?: BoxB2BProgramsDto): BoxB2BPrograms | undefined {
  if (!dto) return undefined
  return {
    cap: convertLightProgramFromDto(dto.cap),
    subscribedPrograms: dto.subscribedPrograms.map((subscribedProgram) =>
      convertLightProgramFromDto(subscribedProgram),
    ),
  }
}
