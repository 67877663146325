import { word } from "@modules/core/utils/i18n"
import { ScoreFormikType } from "@modules/exercises/exerciseFormikTypes"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { ScoreType } from "@modules/scoreboard/scoreTypes"
import { getScoreUnit } from "@modules/scoreboard/scoreUtils"
import { FormikInput } from "@modules/ui/components/formikInput"
import { HUStack } from "@modules/ui/components/layout"
import { useUnit } from "@modules/utils/hooks/useUnit"
import { SimpleFormik } from "@src/typings/formik"

type Props = {
  scoreType: ScoreType
  formik: SimpleFormik<ScoreFormikType, "value" | "value.round" | "value.rep">
}

export const SessionExerciseScoreValueInput: React.FC<Props> = ({ formik, scoreType }) => {
  const unit = useUnit()

  switch (scoreType) {
    case ScoreType.TIME:
      return (
        <FormikInput
          isRequiredInput
          formikStyle={{ flexGrow: 1 }}
          textLabel={word("chrono.settings.label.training_time")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("value", formik)}
          error={getFormErrorMessage("value", formik)}
          label={word("chrono.settings.label.training_time")}
          name={`value`}
          type="maskedInput"
          maskedInputProps={{
            placeholder: "00min 00sec",
            slotChar: "00min 00sec",
            mask: "99min 99sec",
            onChange: (e) => {
              formik.setFieldValue(`value`, e.target.value)
            },
          }}
        />
      )
    case ScoreType.CALORIES:
      return (
        <FormikInput
          isRequiredInput
          textLabel={word("global.score.calories.label")}
          numberProps={{
            maxLength: 5,
            max: 99999,
          }}
          label={word("global.score.calories.unit")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("value", formik)}
          error={getFormErrorMessage("value", formik)}
          setFieldValue={formik.setFieldValue}
          type="number"
          name="value"
          labelInside={true}
          formikStyle={{ flexGrow: 1 }}
        />
      )
    case ScoreType.DISTANCE:
      return (
        <FormikInput
          isRequiredInput
          label={word("global.score.distance.label")}
          textLabel={getScoreUnit(ScoreType.DISTANCE, unit)}
          numberProps={{
            max: 99999,
            minFractionDigits: 0,
            maxFractionDigits: unit === "IMPERIAL" ? 3 : 0,
          }}
          labelInside={true}
          formikStyle={{ flexGrow: 1 }}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("value", formik)}
          error={getFormErrorMessage("value", formik)}
          setFieldValue={formik.setFieldValue}
          type="number"
          name="value"
        />
      )
    case ScoreType.WEIGHT:
      return (
        <FormikInput
          isRequiredInput
          label={word("global.score.weight.label")}
          textLabel={getScoreUnit(ScoreType.WEIGHT, unit)}
          numberProps={{
            max: 99999,
            minFractionDigits: 0,
            maxFractionDigits: 3,
          }}
          labelInside={true}
          formikStyle={{ flexGrow: 1 }}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("value", formik)}
          error={getFormErrorMessage("value", formik)}
          setFieldValue={formik.setFieldValue}
          type="number"
          name="value"
        />
      )
    case ScoreType.REPETITIONS:
      return (
        <FormikInput
          isRequiredInput
          label={word("global.score.repetitions.label")}
          textLabel={word("global.score.repetition.unit", { count: Number(formik.setFieldValue) })}
          numberProps={{
            max: 99999,
          }}
          labelInside={true}
          formikStyle={{ flexGrow: 1 }}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("value", formik)}
          error={getFormErrorMessage("value", formik)}
          setFieldValue={formik.setFieldValue}
          type="number"
          name="value"
        />
      )
    case ScoreType.ROUND_AND_REPETITIONS:
      const round = "round" in formik.getFieldProps("value").value && formik.getFieldProps("value").value.round
      const rep = "rep" in formik.getFieldProps("value").value && formik.getFieldProps("value").value.rep

      return (
        <HUStack gap={12}>
          <FormikInput
            isRequiredInput
            label={word("chrono.settings.label.rounds")}
            textLabel={word("chrono.settings.label.rounds", { count: round })}
            labelInside={true}
            formikStyle={{ flexGrow: 1 }}
            getFieldProps={formik.getFieldProps}
            numberProps={{
              max: 999999,
              value: round,
            }}
            isInvalid={isFormFieldInValid("value.round", formik)}
            error={getFormErrorMessage("value.round", formik)}
            setFieldValue={(fieldName, value) => {
              formik.setFieldValue(fieldName, value ?? 0)
              return Promise.resolve()
            }}
            type="number"
            name="value.round"
          />
          <FormikInput
            isRequiredInput
            label={word("global.score.repetition.unit", { count: rep })}
            textLabel={word("global.score.repetition.unit", { count: rep })}
            getFieldProps={formik.getFieldProps}
            numberProps={{
              max: 999,
              value: rep,
            }}
            labelInside={true}
            formikStyle={{ flexGrow: 1 }}
            isInvalid={isFormFieldInValid("value.rep", formik)}
            error={getFormErrorMessage("value.rep", formik)}
            setFieldValue={(fieldName, value) => {
              formik.setFieldValue(fieldName, value ?? 0)
              return Promise.resolve()
            }}
            type="number"
            name="value.rep"
          />
        </HUStack>
      )
  }
}
