import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const SearchIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21 20.9999L17.3296 17.3295M1 10.4999C1 5.25323 5.25329 0.999939 10.5 0.999939C15.7467 0.999939 20 5.25323 20 10.4999C20 15.7466 15.7467 19.9999 10.5 19.9999C5.25329 19.9999 1 15.7466 1 10.4999Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
