import { SharedUserApi } from "@modules/auth/services/sharedUserApi"
import { ApiClient } from "@modules/core/api/client/apiClient"
import { ConfigService } from "@modules/core/services/configService"
import { UserProfileDto, convertUserProfileFromDto } from "@modules/profile/profileDtos"
import { Profile } from "@modules/profile/profileTypes"

export class UserApi extends SharedUserApi {
  private usersBaseUrl = "/users"

  private buildUsersUrl = () => `${ConfigService.config.BASE_URL.replace("/web", "")}/mobile${this.usersBaseUrl}`

  constructor(protected readonly api: ApiClient) {
    super(api)
  }

  async getProfile(profileId: string): Promise<Profile> {
    const result = await this.api.get<UserProfileDto>(`${this.buildUsersUrl()}/${profileId}`)
    return convertUserProfileFromDto(result.data)
  }
}
