import { HustleupIcon } from "@images/hustleupIcon"
import { HUTheme } from "@modules/profile/useColorTheme"
import { useTheme } from "styled-components"

const SplashScreen: React.FC<{ nextTheme: HUTheme | "loggingout" }> = ({ nextTheme }) => {
  const theme = useTheme()

  return (
    <div
      className={
        nextTheme === "owner"
          ? "splashscreen-box"
          : nextTheme === "athlete"
            ? "splashscreen-athlete"
            : "splashscreen-logout"
      }
    >
      <HustleupIcon color={nextTheme === "athlete" ? theme.base.leGreen400 : theme.base.electricPurple400} />
    </div>
  )
}

export default SplashScreen
