import { useServicesContext } from "@modules/core/services/services.context"
import { Path } from "@modules/navigation/routes"
import { RouteNavigate, RoutePath, useBaseNavigate } from "@modules/navigation/useBaseNavigate"
import { NavigateOptions } from "react-router-dom"

export const useBoxNavigate = () => {
  const { currentSpaceManager } = useServicesContext()
  const navigate = useBaseNavigate()

  return (path: RoutePath, options: NavigateOptions & { newBoxId?: string } = {}) => {
    const { newBoxId, ...navigateOption } = options
    navigate(path, (path) => getBoxPath(path, { newBoxId: newBoxId ?? currentSpaceManager.boxId }), navigateOption)
  }
}

export const getBoxPath = (path: Omit<RoutePath, RouteNavigate.Back>, options?: { newBoxId?: string | null }) => {
  const boxId = options?.newBoxId

  if (boxId) {
    if (path === RouteNavigate.Root) {
      return Path.Box.Root(boxId)
    } else {
      return Path.Box.Root(boxId) + "/" + path
    }
  }
  return ""
}
