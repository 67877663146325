import { AmrapChrono, Chrono, ChronoType, EmomChrono, ForTimeChrono, TabataChrono } from "@modules/chrono/chronoTypes"
import { word } from "@modules/core/utils/i18n"
import { convertDuration4Digits, convertTimeValueToTimeString } from "@modules/duration/timeConverter"
import { ChronoFormik, ExerciseFormikType } from "@modules/exercises/exerciseFormikTypes"

/* return the total time of the training according to the chrono's type */
export const getTotalTime = (chrono: Chrono): number | undefined => {
  switch (chrono.type) {
    case ChronoType.AMRAP:
      return chrono.trainingTime.reduce((acc, trainingTime, index) => {
        return acc + trainingTime + chrono.restTime[index]
      }, 0)
    case ChronoType.FOR_TIME:
      return chrono.noLimit
        ? undefined
        : (chrono.restTime + chrono.trainingTime) * chrono.numberOfSets - chrono.restTime
    case ChronoType.TABATA:
      return (
        ((chrono.trainingTime + chrono.restTime) * chrono.numberOfRounds + chrono.restBetweenSetsTime) *
          chrono.numberOfSets -
        chrono.restBetweenSetsTime
      )
    case ChronoType.EMOM:
      return (
        (chrono.trainingTime * chrono.numberOfRounds + chrono.restBetweenSetsTime) * chrono.numberOfSets -
        chrono.restBetweenSetsTime
      )
  }
}

export const GetChronoDefaults = (chronoType: ChronoType): ChronoFormik => {
  switch (chronoType) {
    case ChronoType.AMRAP:
      return {
        type: ChronoType.AMRAP,
        trainingTime: [convertTimeValueToTimeString(600)],
        restTime: [convertTimeValueToTimeString(0)],
        numberOfSets: 1,
      }
    case ChronoType.EMOM:
      return {
        type: ChronoType.EMOM,
        trainingTime: convertTimeValueToTimeString(60),
        numberOfRounds: 10,
        restBetweenSetsTime: convertTimeValueToTimeString(0),
        numberOfSets: 1,
      }
    case ChronoType.FOR_TIME:
      return {
        type: ChronoType.FOR_TIME,
        trainingTime: convertTimeValueToTimeString(600),
        restTime: convertTimeValueToTimeString(0),
        numberOfSets: 1,
        noLimit: true,
      }
    case ChronoType.TABATA:
      return {
        type: ChronoType.TABATA,
        trainingTime: convertTimeValueToTimeString(20),
        restTime: convertTimeValueToTimeString(10),
        numberOfRounds: 8,
        restBetweenSetsTime: convertTimeValueToTimeString(0),
        numberOfSets: 1,
      }
  }
}

export const MAX_NUMBER_INPUT = 99
export const MIN_NUMBER_INPUT = 1

export const getTrainingChronoTitle = (type: ChronoType): string => {
  switch (type) {
    case "FOR_TIME":
      return word("chrono.title.for_time")
    case "AMRAP":
      return word("chrono.title.amrap")
    case "EMOM":
      return word("chrono.title.emom")
    case "TABATA":
      return word("chrono.title.tabata")
    default:
      return ""
  }
}

export const convertAMRAPChronoToDraft = (chrono: AmrapChrono) => {
  return chrono.trainingTime.map((time, index) => ({
    trainingTime: time,
    restTime: chrono.restTime[index] ?? 0,
  }))
}

export const convertEMOMChronoToDraft = (chrono: EmomChrono) => {
  return {
    ...chrono,
    trainingTime: chrono.trainingTime,
    restBetweenSetsTime: chrono.restBetweenSetsTime,
    areSettingsButtonsVisible: chrono.numberOfSets > 1,
  }
}

export const convertTabataChronoToDraft = (chrono: TabataChrono) => {
  return {
    ...chrono,
    trainingTime: chrono.trainingTime,
    restTime: chrono.restTime,
    restBetweenSetsTime: chrono.restBetweenSetsTime,
    areSettingsButtonsVisible: chrono.numberOfSets > 1,
  }
}

export const convertForTimeChronoToDraft = (chrono: ForTimeChrono) => {
  return {
    ...chrono,
    trainingTime: chrono.trainingTime,
    restTime: chrono.restTime,
    areSettingsButtonsVisible: chrono.numberOfSets > 1,
  }
}

export function displayTotalTime(chrono: Chrono) {
  return getTotalTime(chrono) === undefined
    ? "∞"
    : convertDuration4Digits(getTotalTime(chrono) ?? 0, { unitMinutes: "min" })
}

export function convertChronoDraftIntoChrono(exerciseFormik: ExerciseFormikType) {
  if (exerciseFormik.chronoAmrap) {
    return exerciseFormik.chronoAmrap
  }
  if (exerciseFormik.chronoEmom) {
    return exerciseFormik.chronoEmom
  }
  if (exerciseFormik.chronoForTime) {
    return exerciseFormik.chronoForTime
  }
  if (exerciseFormik.chronoTabata) {
    return exerciseFormik.chronoTabata
  } else return undefined
}
