import React, { useEffect, useState } from "react"
import { convertMemberFromFormik, convertMemberToDraft, Member, MemberDetailed } from "@modules/members/memberTypes"
import { HUText } from "@modules/ui/components/huText"
import { useTheme } from "styled-components"
import { HuCamembert } from "@modules/ui/components/huCambert"
import { WarningIcon } from "@images/svgIcons/warningIcon"
import { FormikInput } from "@modules/ui/components/formikInput"
import { getFormErrorMessage, isFormFieldInValid } from "@modules/formik/formikUtils"
import { getIn, useFormik } from "formik"
import { convertMemberToFormik, getValidationSchema, MemberFormikType } from "@modules/members/memberFormikType"
import { useDraft } from "@modules/utils/useDraft"
import { useServicesContext } from "@modules/core/services/services.context"
import { useToast } from "@modules/ui/components/huToast"
import { word } from "@modules/core/utils/i18n"
import { LoggerType } from "@modules/utils/loggerUtils"
import { HUButton } from "@modules/ui/components/huButton"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import dayjs from "dayjs"
import { HuNextBookingCard } from "@modules/ui/components/huEventMiniCard"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Navigation, Pagination } from "swiper/modules"
import { formatDateWithHyphen } from "@modules/utils/dateUtils"
import { convertLasVisitedToNumberAndDays } from "@modules/members/memberUtils"

type MemberTabDayToDayProps = {
  memberDetailed: MemberDetailed
  member?: Member
} & React.HTMLAttributes<HTMLDivElement>
export const MemberTabDayToDay: React.FC<MemberTabDayToDayProps> = ({ memberDetailed, member, ...props }) => {
  const theme = useTheme()
  const toast = useToast()
  const { hideSidebar } = useSidebar()
  const { memberService } = useServicesContext()
  const { draft: memberDraft } = useDraft(() => convertMemberToDraft(memberDetailed))
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [lastvisited, setLastVisited] = useState({
    number: 0,
    daysSinceLastVisited: 0,
  })

  const formik = useFormik<MemberFormikType>({
    initialValues: convertMemberToFormik(memberDraft),
    validationSchema: getValidationSchema(),
    onSubmit: async () => {
      setIsSubmitting(false)
      try {
        await memberService.updateMember(memberDetailed.id, { ...convertMemberFromFormik(formik.values) })
        toast.show({
          severity: "success",
          summary: word("member.editMember.success"),
        })
        if (member) member.note = formik.values.note
      } catch (e: any) {
        console.error(LoggerType.Service + "update member", e)
        toast.show({ severity: "error", summary: "Error", detail: e.message })
        setIsSubmitting(true)
      }
    },
  })

  const convertNoShow = (noShow: number) => {
    if (noShow > 3) {
      return 1
    } else if (noShow >= 1 && noShow <= 2) {
      return 2
    } else {
      return 3
    }
  }

  useEffect(() => {
    if (memberDetailed.lastVisited) {
      const convert = convertLasVisitedToNumberAndDays(memberDetailed.lastVisited)
      setLastVisited({
        number: convert.number,
        daysSinceLastVisited: convert.daysSinceLastVisited,
      })
    }
  }, [memberDetailed])

  /* Used to check if the formik has changed value and trigger the save button */
  const value = getIn(formik.values, "note")
  const initialValue = getIn(formik.initialValues, "note")

  useEffect(() => {
    setIsSubmitting(value !== initialValue)
  }, [value])

  const handleOnSubmit = async () => {
    formik.submitForm()
  }

  return (
    <div className="flex flex-column w-full gap-5" {...props}>
      {/* LAST VISIT & NO SHOW */}
      <div className="flex mt-2">
        {/* LAST VISIT*/}
        <div className="flex flex-1 flex-column">
          <HUText fontStyle="TM" color={theme.mainWhite}>
            {word("box.member.table.lastVisit")}
          </HUText>
          <div className="flex gap-2 align-items-center mt-2">
            <HuCamembert number={lastvisited.number} />
            <div className="flex flex-column">
              {memberDetailed.lastVisited ? (
                <>
                  <HUText fontStyle="BM" color={theme.mainWhite}>
                    {word("box.member.table.daysAgo", { count: lastvisited.daysSinceLastVisited })}
                  </HUText>

                  <HUText fontStyle="BS" color={theme.neutralColor400}>
                    {`(${formatDateWithHyphen(dayjs(memberDetailed.lastVisited).format("L"))})`}
                  </HUText>
                </>
              ) : (
                <HUText fontStyle="BM" color={theme.neutralColor400}>
                  {word("box.member.table.noVisitYet")}
                </HUText>
              )}
            </div>
          </div>
        </div>
        {/* NO SHOW */}
        <div className="flex flex-1 flex-column ">
          <HUText fontStyle="TM" color={theme.mainWhite}>
            {word("box.member.table.noShow", { count: memberDetailed.noShowPastMonth })}
          </HUText>
          <div className="flex gap-2 align-items-center mt-2">
            <HuCamembert number={memberDetailed.noShowPastMonth ? 0 : convertNoShow(memberDetailed.noShowPastMonth)} />
            <div className="flex flex-column">
              <HUText fontStyle="BM" color={theme.mainWhite}>
                {memberDetailed.noShowPastMonth}{" "}
                {word("box.member.table.noShow", { count: memberDetailed.noShowPastMonth }).toLowerCase()}
              </HUText>
              <HUText fontStyle="BS" color={theme.neutralColor400}>
                {word("box.member.table.dayToDay.pastMonth")}
              </HUText>
            </div>
          </div>
        </div>
      </div>
      {/* NEXT BOOKING */}
      <div className="flex flex-column ">
        <HUText fontStyle="TM" color={theme.mainWhite}>
          {word("box.member.table.dayToDay.nextBooking")}
        </HUText>
        <div className="mt-2">
          {memberDetailed.nextBookings && memberDetailed.nextBookings.length > 0 ? (
            <Swiper
              spaceBetween={10}
              slidesPerView={3}
              freeMode={true}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination, Navigation]}
            >
              {memberDetailed.nextBookings.map((booking) => (
                <SwiperSlide key={booking.slotId}>
                  <HuNextBookingCard nextBooking={booking} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <HuNextBookingCard />
          )}
        </div>
      </div>
      {/* ASSIGNED COACH */}
      {/* <div className="flex flex-column">
        <HUText fontStyle="TM" color={theme.mainWhite}>
          {word("box.member.table.assignedCoach.label")}
        </HUText>
        <HUText fontStyle="BS" color={theme.neutralColor400} className="mt-2">
          {word("box.member.table.assignedCoach.subtitle")}
        </HUText>
      </div> */}
      {/* INTERNAL NOTE*/}
      <div className="flex flex-column">
        <HUText fontStyle="TM" color={theme.mainWhite}>
          {word("box.member.table.dayToDay.internalNote.label")}
        </HUText>
        <div className="flex flex-row gap-2 mt-2 mb-2">
          <WarningIcon color={theme.neutralColor900} width={20} height={20} />
          <HUText fontStyle="BS" color={theme.neutralColor400}>
            {word("box.member.table.dayToDay.internalNote.warning")}
          </HUText>
        </div>
        <FormikInput
          flex
          formikStyle={{ flexGrow: 1 }}
          type="textarea"
          placeHolder={word("member.form.label.note")}
          getFieldProps={formik.getFieldProps}
          isInvalid={isFormFieldInValid("note", formik)}
          name="note"
          error={getFormErrorMessage("note", formik)}
        />
      </div>
      {/* ACTIVE OFFERS */}
      <div className="flex flex-column">
        <HUText fontStyle="TM" color={theme.mainWhite}>
          {word("box.member.table.activeOffer.label", { count: memberDetailed.activeOffersTitles?.length })}
        </HUText>
        <HUText fontStyle="BS" color={theme.neutralColor400} className="mt-2">
          {word("box.member.table.activeOffer.subtitle")}
        </HUText>
        {memberDetailed.activeOffersTitles && memberDetailed.activeOffersTitles.length > 0 ? (
          <div className="flex flex-wrap bg-neutral-900 mt-2 p-4 border-round-2xl border-1 border-electricPurple-500">
            {memberDetailed.activeOffersTitles?.map((offer, index) => (
              <HUText
                key={index}
                fontStyle="LM"
                color={theme.neutralColor100}
                className="m-1 bg-neutral-50 p-3 border-round-3xl"
              >
                {offer}
              </HUText>
            ))}
          </div>
        ) : (
          <div className="flex flex-wrap bg-neutral-900 mt-2 p-4 border-round-2xl border-1 border-neutral-600">
            <HUText fontStyle="LM" color={theme.neutralColor400}>
              {word("box.member.table.activeOffer.empty")}
            </HUText>
          </div>
        )}
      </div>

      {/* BUTTON */}
      <div className=" mb-4 flex flex-row gap-2 align-items-center justify-content-center">
        <HUButton
          type="Left_Icon"
          size="M"
          colorType="Quaternary"
          text={word("global.cancel")}
          onClick={() => hideSidebar()}
          className="w-full"
        />
        <HUButton
          type="Right_Icon"
          size="M"
          colorType="Primary"
          text={word("global.save")}
          onClick={() => isSubmitting && handleOnSubmit()}
          disabled={!isSubmitting}
          className="w-full"
        />
      </div>
    </div>
  )
}
