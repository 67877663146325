import React, { CSSProperties, Children, Fragment, MouseEventHandler } from "react"

type StackProp = {
  gap?: number | number[]
  align?: boolean
  flex?: boolean
  flexGrow?: boolean
  style?: CSSProperties
  className?: string
  children: React.ReactNode
  justifySpaceBetween?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

const Stack: React.FC<StackProp & { direction: "column" | "row"; defaultGap: number }> = ({
  direction,
  defaultGap,
  gap,
  align,
  flex,
  flexGrow,
  style,
  className,
  children,
  justifySpaceBetween,
  onClick,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        gap: gap === undefined ? defaultGap : typeof gap === "number" ? gap : "initial",
        alignItems: align ? "center" : "initial",
        flex: flex ? 1 : "initial",
        flexGrow: flexGrow ? 1 : "initial",
        justifyContent: justifySpaceBetween ? "space-between" : undefined,
        ...style,
      }}
      className={className}
      onClick={onClick}
    >
      {gap === undefined || typeof gap === "number" ? (
        children
      ) : (
        <>
          {Children.toArray(children).map((c, index) => {
            return (
              <Fragment key={index}>
                {index !== 0 && (
                  <div
                    style={{
                      height: direction === "column" ? gap[index - 1] : undefined,
                      width: direction === "row" ? gap[index - 1] : undefined,
                    }}
                  />
                )}
                {c}
              </Fragment>
            )
          })}
        </>
      )}
    </div>
  )
}

export const HUStack: React.FC<StackProp> = (props) => {
  return <Stack direction="column" defaultGap={20} {...props} />
}

export const HURow: React.FC<StackProp> = (props) => {
  return <Stack direction="row" defaultGap={0} {...props} />
}
