import SessionEmpty from "@images/empty/session_empty.svg"
import { word } from "@modules/core/utils/i18n"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { PromoCodeListItem } from "@modules/promoCode/components/promoCodeListItem"
import { usePromoCodeList } from "@modules/promoCode/hooks/usePromoCodes"
import { usePromoCodeSidebar } from "@modules/promoCode/hooks/usePromoCodeSidebar"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { TabMenu } from "primereact/tabmenu"
import React, { useMemo, useState } from "react"
import { useTheme } from "styled-components"

type TabObject = {
  label: string
  command: () => void
  expired: boolean
}

export const PromoCodeListView: React.FC = () => {
  const [expiredFilter, setExpiredFilter] = useState<boolean>(false)

  const { value: promoCodes, loading } = usePromoCodeList(expiredFilter)
  const { navigateToPromoCodeFormView } = usePromoCodeSidebar()
  const theme = useTheme()
  const language = useLanguage()

  const promoCodeOptions = useMemo<TabObject[]>(
    () => [
      {
        label: word("promoCode.list.validCodes"),
        command: () => {
          console.log("Empty function")
        },
        expired: false,
      },
      {
        label: word("promoCode.list.pastCodes"),
        command: () => {
          console.log("Empty function")
        },
        expired: true,
      },
    ],
    [language],
  )

  return (
    <HUStack>
      <HUText fontStyle="TL">{word("promoCode.list.title")}</HUText>
      <HUText fontStyle="BM">{word("promoCode.list.description")}</HUText>
      <HUButton
        colorType="Primary"
        onClick={() => navigateToPromoCodeFormView()}
        type="Left_Icon"
        size="M"
        text={word("promoCode.list.createButton")}
        icon={{
          iconView: <span className={`p-button-icon pi pi-plus`} style={{ color: theme.textButtonPrimary }} />,
        }}
      />
      <TabMenu
        model={promoCodeOptions}
        activeIndex={promoCodeOptions.findIndex((p) => p.expired === expiredFilter)}
        onTabChange={(e) => setExpiredFilter((e.value as TabObject).expired)}
      />
      {loading ? (
        <div className="mt-4">
          <LoadingSpinner />
        </div>
      ) : promoCodes.length === 0 ? (
        <EmptyView
          image={{ src: SessionEmpty }}
          title={expiredFilter ? word("promoCode.list.expired.empty.title") : word("promoCode.list.valid.empty.title")}
          flexGrow={false}
          className="p-2 mt-2"
          textAlign="center"
          disposition="column-reverse"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      ) : (
        <HUStack gap={0}>
          {promoCodes.map((promoCode, promoCodeIndex) => (
            <PromoCodeListItem key={promoCodeIndex} promoCode={promoCode} />
          ))}
        </HUStack>
      )}
    </HUStack>
  )
}
