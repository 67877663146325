import { ImportTemplateIcon } from "@images/svgIcons/importTemplateIcon"
import { ReturnIcon } from "@images/svgIcons/returnIcon"
import { useProgrammationSessionImportSidebar } from "@modules/boxB2BProgram/hooks/useProgrammationSessionImportSidebar"
import { useCalendar } from "@modules/calendar/hooks/useCalendar"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { useToast } from "@modules/ui/components/huToast"
import { HUStack } from "@modules/ui/components/layout"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

export const BoxProgrammingHeaderRightView: React.FC<{ boxProgrammationId?: string }> = ({ boxProgrammationId }) => {
  const theme = useTheme()
  const boxNavigate = useBoxNavigate()
  const { currentWeekdays, selectedDate } = useCalendar()
  const { navigateToImportProgramSelection } = useProgrammationSessionImportSidebar()
  const toast = useToast()

  const { firstDayOfWeek, lastDayOfWeek } = useMemo(() => {
    if (
      currentWeekdays &&
      currentWeekdays.length > 0 &&
      currentWeekdays[0].date &&
      currentWeekdays[currentWeekdays.length - 1].date
    )
      return {
        firstDayOfWeek: currentWeekdays[0].date.format("YYYY-MM-DD"),
        lastDayOfWeek: currentWeekdays[currentWeekdays.length - 1].date?.format("YYYY-MM-DD"),
      }
    else return {}
  }, [currentWeekdays])

  const onImportButtonClick = () => {
    if (boxProgrammationId && firstDayOfWeek && lastDayOfWeek)
      navigateToImportProgramSelection(boxProgrammationId, firstDayOfWeek, lastDayOfWeek)
    else toast.show({ severity: "error", summary: word("global.error.default") })
  }

  return (
    <Container className="mb-2 p-1 md:p-2 xl:p-4 mb-5 justify-content-evenly">
      <HUText fontStyle="TM" className="ml-2">
        {word("box.programmation.activity.viewProgrammation")}
      </HUText>
      <HUStack gap={6} className="align-items-center justify-content-center mt-2">
        <HUButton
          type="Rounded"
          colorType="Primary"
          size="XS"
          icon={{ iconView: <ImportTemplateIcon color={theme.textPrimary} /> }}
          onClick={onImportButtonClick}
        />

        <HUText fontStyle="LM" className="text-center">
          {word("box.programmation.activity.importTemplate")}
        </HUText>
      </HUStack>
      <HUButton
        type="Left_Icon"
        colorType="Underline"
        size="XS"
        onClick={() =>
          boxProgrammationId &&
          boxNavigate(Path.Box.ProgrammationDetails(boxProgrammationId), {
            state: { selectedDate: selectedDate?.toString() ?? "" },
          })
        }
        icon={{ icon: ReturnIcon, iconProps: { color: theme.textPrimary } }}
        text={word("box.backToTimetable")}
      />
    </Container>
  )
}

const Container = styled(HUStack)`
  border-radius: 33px;
  background: ${({ theme }) => theme.neutralColor50};
  position: relative;
  padding: 18px;

  color: ${({ theme }) => theme.mainWhite};
  height: 200px;
  > * {
    z-index: 1;
  }
`
