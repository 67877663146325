import { colors } from "@modules/ui/styles/colors"

import athlete_theme from "@themes/theme-light/athlete/_athlete_theme.module.scss"
import owner_theme from "@themes/theme-light/owner/_owner_theme.module.scss"

export type Theme = {
  mode: "OWNER" | "ATHLETE"
  base: typeof colors

  primaryColor50: string
  primaryColor100: string
  primaryColor200: string
  primaryColor300: string
  primaryColor400: string
  primaryColor: string
  primaryColor600: string
  primaryColor700: string
  primaryColor800: string
  primaryColor900: string

  secondaryColor100: string
  secondaryColor200: string
  secondaryColor300: string
  secondaryColor400: string
  secondaryColor: string
  secondaryColor600: string
  secondaryColor700: string
  secondaryColor800: string
  secondaryColor900: string

  tertiaryColor100: string
  tertiaryColor200: string
  tertiaryColor300: string
  tertiaryColor400: string
  tertiaryColor: string
  tertiaryColor600: string
  tertiaryColor700: string
  tertiaryColor800: string
  tertiaryColor900: string

  neutralColor50: string
  neutralColor100: string
  neutralColor200: string
  neutralColor300: string
  neutralColor400: string
  neutralColor: string
  neutralColor600: string
  neutralColor700: string
  neutralColor800: string
  neutralColor900: string

  error50: string
  error100: string
  error200: string
  error300: string
  error400: string
  error: string
  error600: string
  error700: string
  error800: string
  error900: string

  sucess100: string
  sucess200: string
  sucess300: string
  sucess400: string
  sucess: string
  sucess600: string
  sucess700: string
  sucess800: string
  sucess900: string

  mainWhite: string
  mainBlack: string
  background: string
  foreground: string

  textPrimary: string
  textSecondary: string
  textTertiary: string
  textLink: string
  textWarning: string
  textEmphasized: string
  textButtonCalendarInactive: string
  textButtonCalendarActive: string
  buttonCalendarIcon: string
  svgDefaultColor: string
  defaultIconColor: string

  necessaryInput: string
  financeCardDecoratorPrimary: string
  financeCardDecoratorSecondary: string
  financeCardBackgroundIconOpacity: number
  avatarCardBackgroundIconOpacity: number
  surfaceCard: string
  backgroundLightGreyColor: string

  programSubscribedColor: string
  programUserColor: string

  whiteAtheleBlackOwner:string
  blackAtheleWhiteOwner:string
}

// @ts-expect-error - Theme content from scss is not recognizer
export const ownerTheme: Theme = {
  mode: "OWNER",
  base: colors,

  ...owner_theme,
}

// @ts-expect-error - Theme content from scss is not recognizer
export const athleteTheme: Theme = {
  mode: "ATHLETE",
  base: colors,

  ...athlete_theme,
}
