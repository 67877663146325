import { ApiInterceptor } from "@modules/core/api/interceptors/apiInterceptor"
import { ILanguageService } from "@modules/language/services/ILanguageService"
import { InternalAxiosRequestConfig } from "axios"

export const addLanguageInterceptor: (
  languageService: ILanguageService,
) => ApiInterceptor<InternalAxiosRequestConfig> = (languageService) => ({
  onFulfilled: async (config) => {
    const locale = languageService.getLocalLanguage()
    const timeZone = languageService.getTimeZone()

    return {
      ...config,
      headers: locale ? { ...config.headers, "Accept-Language": locale, "x-time-zone": timeZone } : config.headers,
    } as InternalAxiosRequestConfig
  },
})
