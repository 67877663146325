import { useSelectedBoxId } from "@modules/box/useBox"
import { MemberDetailed } from "@modules/members/memberTypes"
import { Path } from "@modules/navigation/routes"
import { useBoxNavigate } from "@modules/navigation/useBoxNavigate"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { HuFlag } from "@modules/ui/components/huFlag"
import { HUText } from "@modules/ui/components/huText"
import { Mail, MessageCircleMore } from "lucide-react"
import { Link, useNavigate } from "react-router-dom"
import { useTheme } from "styled-components"

export type HuAvatarCardSidebarProps = {
  member: MemberDetailed
  loading?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const HuAvatarCardSidebar: React.FC<HuAvatarCardSidebarProps> = ({ member, loading, ...props }) => {
  const theme = useTheme()
  const { hideSidebar } = useSidebar()
  const boxId = useSelectedBoxId()
  const boxNavigate = useBoxNavigate()
  const navigate = useNavigate()

  /* TODO SKELETON */
  if (loading) {
    return <></>
  }

  const openChatWithUser = async () => {
    if (member) {
      if (boxId) {
        hideSidebar()
        boxNavigate(Path.Chat.Root, { state: { userToChatTo: member.userId } })
      } else {
        hideSidebar()
        navigate(Path.Chat.Root, { state: { userToChatTo: member.userId } })
      }
    }
  }

  return (
    <div
      className="relative flex w-full h-13rem bg-neutral-800 border-round-bottom-3xl"
      style={{ marginTop: "-52px" }}
      {...props}
    >
      <div className="flex gap-3 justify-content-center align-items-center w-full">
        <div className="relative">
          <ProfileAvatar
            className="mb-1"
            profile={{ avatar: member.publicProfile?.avatar, username: member.firstname || "" }}
            size={90}
          />
          {member.publicProfile?.nationality && (
            <div className="absolute bottom-0 " style={{ left: "40%" }}>
              <HuFlag
                className="border-neutral-700 border-1 border-round-lg"
                countryCode={member.publicProfile.nationality || ""}
                svg={true}
                style={{ width: "1.4rem", height: "1.2rem", objectFit: "cover" }}
              />
            </div>
          )}
        </div>
        <div className="flex  flex-column">
          <HUText fontStyle="HS" color={theme.mainWhite} className="flex capitalize">
            {member.firstname + " " + member.lastname}
          </HUText>
          <HUText fontStyle="BM" color={theme.neutralColor700} className="flex justify-content-center capitalize">
            @{member.username}
          </HUText>
        </div>
      </div>

      <div className="absolute flex flex-column gap-1 right-0 mt-7 mr-2">
        <MessageCircleMore
          cursor="pointer"
          className="bg-black rounded-full p-2"
          strokeWidth={2}
          size={35}
          color={theme.mainWhite}
          onClick={openChatWithUser}
        />
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = `mailto:${member.email}`
            e.preventDefault()
          }}
        >
          <Mail className="bg-black rounded-full p-2" strokeWidth={2} size={35} color={theme.mainWhite} />
        </Link>
      </div>
    </div>
  )
}
