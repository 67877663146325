import { word } from "@modules/core/utils/i18n"
import { Language } from "@modules/language/utils/languageUtils"
import { TranslationLanguages } from "stream-chat"
import { Streami18n } from "stream-chat-react"

const languageSupported: TranslationLanguages[] = [
  "de",
  "fr",
  "en",
  "es",
  "it",
  "hi",
  "nl",
  "pt",
  "tr",
  "ru",
  "ja",
  "ko",
]

export function InitStreami18n(languageCode: TranslationLanguages) {
  const streami18n = new Streami18n({
    language: languageSupported.includes(languageCode) ? languageCode : Language.ENGLISH_US,
    translationsForLanguage: {
      "{{ user }} is typing...": word("chat.typing", { user: "{{ user }}" }),
    },
  })

  return streami18n
}
