import { PaginatedStore, Store } from "@betomorrow/micro-stores"
import { Service } from "@modules/core/services/serviceType"
import { PaymentService } from "@modules/payment/services/paymentService"
import { convertSASRequestsProfileFromDto } from "@modules/profile/profileDtos"
import { RequestsSASProfile } from "@modules/profile/profileTypes"
import { ProgramInfiniteDraft, ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ProgramOnOffDraft, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import {
  ItemProgram,
  Program,
  ProgramFilter,
  ProgramSubscriptionStatus,
  ProgramTypeOf,
  SharedCoachRequestAction,
  SharedCoachStatus,
} from "@modules/programs/programTypes"
import { ProgramApi } from "@modules/programs/services/programApi"
import { ProgramInfiniteService } from "@modules/programs/services/programInfiniteService"
import { ProgramOnOffService } from "@modules/programs/services/programOnOffService"
import { PlanningService } from "@modules/training/services/planningService"
import { ListOrder } from "@modules/utils/types"
import dayjs from "dayjs"

export class ProgramService implements Service {
  programStore = new Store<Program>((id) => this.api.getProgram(id))
  paginatedAllprogramStore = new PaginatedStore<ItemProgram, Program, "id">((page: number) =>
    this.getPrograms("SUBSCRIPTION_DATE", "ALL", page),
  ).present(this.programStore)
  paginatedOwnedprogramStore = new PaginatedStore<ItemProgram, Program, "id">((page: number) =>
    this.getPrograms("SUBSCRIPTION_DATE", "OWNED", page),
  ).present(this.programStore)
  paginatedOwnedprogramWithPriceStore = new PaginatedStore<ItemProgram, Program, "id">((page: number) =>
    this.getPrograms("SUBSCRIPTION_DATE", "OWNED", page, undefined, true),
  ).present(this.programStore)
  paginatedSubscribedprogramStore = new PaginatedStore<ItemProgram, Program, "id">((page: number) =>
    this.getPrograms("SUBSCRIPTION_DATE", "SUBSCRIBED", page),
  ).present(this.programStore)
  paginatedSharedCoachProgramStore = new PaginatedStore<ItemProgram, Program, "id">((page: number) =>
    this.getPrograms("CREATION_DATE", "SHARED_COACH", page),
  ).present(this.programStore)
  paginatedProgramSASRequestsStore = new Map<
    string,
    PaginatedStore<RequestsSASProfile, RequestsSASProfile, "subscriptionId">
  >()

  constructor(
    private api: ProgramApi,
    private programInfiniteService: ProgramInfiniteService,
    private programOnOffService: ProgramOnOffService,
    private planningService: PlanningService,
    private paymentService: PaymentService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedOut(): Promise<void> {
    this.programStore.clear()
    this.paginatedAllprogramStore.clear()
    this.paginatedOwnedprogramStore.clear()
    this.paginatedSubscribedprogramStore.clear()
    this.paginatedSharedCoachProgramStore.clear()
    this.paginatedProgramSASRequestsStore.clear()
  }

  async getPrograms(
    order: ListOrder,
    filter: ProgramFilter = "ALL",
    page?: number,
    size?: number,
    onlyWithPrice?: boolean,
  ) {
    return this.api.getPrograms(filter, order, page, size, onlyWithPrice)
  }

  async createProgram(programDraft: ProgramInfiniteDraft | ProgramOnOffDraft) {
    const program =
      programDraft._programType === ProgramOnOffTypeOf
        ? await this.programOnOffService.createOnOffProgram(programDraft)
        : await this.programInfiniteService.createProgramInfinite(programDraft)
    this.programStore.save(program)
    this.planningService.addNewFilteredProgram(program.id)
    this.paginatedOwnedprogramStore.list()
    return program
  }

  async updateProgram(id: string, programDraft: Partial<ProgramInfiniteDraft | ProgramOnOffDraft>) {
    if (programDraft._programType === ProgramOnOffTypeOf) {
      const updatedProgram = await this.programOnOffService.updateProgramOnOff(id, programDraft)
      this.programStore.update(id, () => updatedProgram)
    }
    if (programDraft._programType === ProgramInfiniteTypeOf) {
      const updatedProgram = await this.programInfiniteService.updateProgramInfinite(id, programDraft)
      this.programStore.update(id, () => updatedProgram)
    }
  }

  async deleteProgram(id: string, programType: ProgramTypeOf) {
    if (programType === ProgramInfiniteTypeOf) await this.programInfiniteService.deleteProgramInfinite(id)
    if (programType === ProgramOnOffTypeOf) await this.programOnOffService.deleteProgramOnOff(id)

    this.planningService.removeFilteredProgram(id)
    this.programStore.remove(id)
  }

  getPaginatedProgramSASRequestsStore(programId: string, date?: Date) {
    let paginated = this.paginatedProgramSASRequestsStore.get(programId + dayjs(date).toISOString())

    if (!paginated) {
      paginated = new PaginatedStore<RequestsSASProfile, RequestsSASProfile, "subscriptionId">((page) =>
        this.getProgramSASRequests(programId, date, page),
      )

      this.paginatedProgramSASRequestsStore.set(programId + dayjs(date).toISOString(), paginated)
    }

    return paginated
  }

  async getProgramSASRequests(id: string, date?: Date, page?: number) {
    const result = await this.api.getProgramSASRequests(id, date, page)

    return {
      ...result,
      content: result.content.map((profile) => convertSASRequestsProfileFromDto(profile)),
    }
  }

  async acceptProgramMultipleSASRequests(programId: string, requestIds: string[], acceptAll: boolean) {
    await this.api.acceptProgramMultipleSASRequests(programId, requestIds, acceptAll)
  }

  async acceptProgramSASRequest(programId: string, requestId: string) {
    await this.api.acceptProgramSASRequest(programId, requestId)
  }

  async rejectProgramSASRequest(programId: string, requestId: string) {
    await this.api.rejectProgramSASRequest(programId, requestId)
  }

  async acceptSharedCoachUpdateProgramStore(programId: string, type: ProgramTypeOf, coachId: string) {
    this.programStore.update(programId, (program) => {
      const currentSharedCoach = program.sharedCoaches?.find((coach) => coach.user.id === coachId)
      if (program.sharedCoaches && currentSharedCoach) {
        currentSharedCoach.status = SharedCoachStatus.ACCEPTED
        const updatedSharedCoach = [...program.sharedCoaches, currentSharedCoach]
        return {
          ...program,
          sharedCoaches: updatedSharedCoach,
        }
      } else {
        return { ...program }
      }
    })
    if (type === ProgramOnOffTypeOf) {
      this.programOnOffService.programOnOffStore.update(programId, (program) => {
        const currentSharedCoach = program.sharedCoaches?.find((coach) => coach.user.id === coachId)
        if (program.sharedCoaches && currentSharedCoach) {
          currentSharedCoach.status = SharedCoachStatus.ACCEPTED
          const updatedSharedCoach = [...program.sharedCoaches, currentSharedCoach]
          return {
            ...program,
            sharedCoaches: updatedSharedCoach,
          }
        } else {
          return { ...program }
        }
      })
    } else {
      this.programInfiniteService.programInfiniteStore.update(programId, (program) => {
        const currentSharedCoach = program.sharedCoaches?.find((coach) => coach.user.id === coachId)
        if (program.sharedCoaches && currentSharedCoach) {
          currentSharedCoach.status = SharedCoachStatus.ACCEPTED
          const updatedSharedCoach = [...program.sharedCoaches, currentSharedCoach]
          return {
            ...program,
            sharedCoaches: updatedSharedCoach,
          }
        } else {
          return { ...program }
        }
      })
    }
  }

  async removeSharedCoachUpdateStore(programId: string, type: ProgramTypeOf, coachId: string) {
    this.programStore.update(programId, (program) => {
      return {
        ...program,
        sharedCoaches: [...(program.sharedCoaches?.filter((coach) => coach.user.id !== coachId) ?? [])],
      }
    })
    if (type === ProgramOnOffTypeOf) {
      this.programOnOffService.programOnOffStore.update(programId, (program) => {
        return {
          ...program,
          sharedCoaches: [...(program.sharedCoaches?.filter((coach) => coach.user.id !== coachId) ?? [])],
        }
      })
    } else {
      this.programInfiniteService.programInfiniteStore.update(programId, (program) => {
        return {
          ...program,
          sharedCoaches: [...(program.sharedCoaches?.filter((coach) => coach.user.id !== coachId) ?? [])],
        }
      })
    }
  }

  async requestSharedCoach(programId: string, type: ProgramTypeOf, action: SharedCoachRequestAction, coachId: string) {
    switch (action) {
      case SharedCoachRequestAction.ACCEPT:
        await this.api
          .acceptSharedCoach(programId)
          .then(() => this.acceptSharedCoachUpdateProgramStore(programId, type, coachId))
        break
      case SharedCoachRequestAction.QUIT:
        await this.api
          .quitSharedCoach(programId)
          .then(() => this.removeSharedCoachUpdateStore(programId, type, coachId))
        break
      case SharedCoachRequestAction.REFUSE:
        await this.api
          .refuseSharedCoach(programId)
          .then(() => this.removeSharedCoachUpdateStore(programId, type, coachId))
        break
      case SharedCoachRequestAction.REMOVE:
        coachId &&
          (await this.api
            .removeSharedCoach(programId, coachId)
            .then(() => this.removeSharedCoachUpdateStore(programId, type, coachId)))
        break
    }
  }

  async subscribeProgram(id: string, programType: ProgramTypeOf): Promise<ProgramSubscriptionStatus> {
    if (programType === ProgramInfiniteTypeOf) {
      const status = await this.programInfiniteService.subscribeProgramInfinite(id)

      this.programStore.update(id, (program) => {
        return {
          ...program,
          subscriptionStatus: status,
        }
      })
      this.paginatedSubscribedprogramStore.list()

      return status
    } else {
      const status = await this.programOnOffService.subscribeProgramOnOff(id)

      this.programStore.update(id, (program) => {
        return {
          ...program,
          subscriptionStatus: status,
        }
      })

      if (status === ProgramSubscriptionStatus.ACTIVE) this.programOnOffService.programOnOffProgressionStore.fetch(id)

      this.paginatedSubscribedprogramStore.list()

      return status
    }
  }

  async unsubscribeProgram(id: string, programType: ProgramTypeOf): Promise<ProgramSubscriptionStatus> {
    if (programType === ProgramInfiniteTypeOf) {
      const status = await this.programInfiniteService.unsubscribeProgramInfinite(id)
      this.programStore.update(id, (program) => {
        return {
          ...program,
          subscriptionStatus: status,
        }
      })
      this.paginatedSubscribedprogramStore.list()
      return status
    } else {
      const status = await this.programOnOffService.unsubscribeProgramOnOff(id)
      this.programStore.update(id, (program) => {
        return {
          ...program,
          subscriptionStatus: status,
        }
      })
      this.programOnOffService.programOnOffProgressionStore.fetch(id)
      this.paginatedSubscribedprogramStore.list()

      return status
    }
  }

  async subscribeToPayingProgram(
    id: string,
    programType: ProgramTypeOf,
    paymentCallback: (url: string) => Promise<boolean>,
    successUrl: string,
    cancelUrl: string,
  ): Promise<boolean> {
    const result = await this.paymentService.createProgramPaymentSubscription(id, successUrl, cancelUrl)
    if (!result.url) {
      throw Error("No stripe url to pay")
    }

    const success = await paymentCallback(result.url)
    if (success) {
      this.programStore.update(id, (program) => {
        return {
          ...program,
          subscriptionStatus: ProgramSubscriptionStatus.ACTIVE,
        }
      })

      if (programType === ProgramInfiniteTypeOf) {
        this.programInfiniteService.programInfiniteStripeSubscriptionInfosStore.fetch(id)
        this.programInfiniteService.programInfiniteStore.update(id, (program) => {
          return {
            ...program,
            subscriptionStatus: ProgramSubscriptionStatus.ACTIVE,
          }
        })
      } else {
        this.programOnOffService.programOnOffStripeSubscriptionInfosStore.fetch(id)
        this.programOnOffService.programOnOffStore.update(id, (program) => {
          return {
            ...program,
            subscriptionStatus: ProgramSubscriptionStatus.ACTIVE,
          }
        })

        this.programOnOffService.programOnOffProgressionStore.fetch(id)
      }
    }
    return success
  }
}
