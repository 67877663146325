import { PaginatedStore, Store } from "@betomorrow/micro-stores"
import { Service } from "@modules/core/services/serviceType"
import {
  ItemProgramOnOff,
  ProgramOnOff,
  ProgramOnOffDraft,
  ProgramOnOffProgression,
  ProgramOnOffStripeSubscriptionInfos,
} from "@modules/programs/programOnOffTypes"
import { ProgramFilter, ProgramPrice, ProgramSubscriptionStatus } from "@modules/programs/programTypes"
import { ProgramApi } from "@modules/programs/services/programApi"
import { ProgramOnOffApi } from "@modules/programs/services/programOnOffApi"
import {
  ProgramOnOffDto,
  convertProgramOnOffFromDto,
  convertProgramOnOffStripeSubscriptionInfosFromDto,
} from "@modules/programs/services/programOnOffDto"
import { PlanningService } from "@modules/training/services/planningService"
import { ListOrder } from "@modules/utils/types"

export class ProgramOnOffService implements Service {
  programOnOffStore = new Store<ProgramOnOff>((id) => this.api.getProgramOnOff(id))
  paginatedAllProgramOnOffStore = new PaginatedStore<ItemProgramOnOff, ProgramOnOff, "id">((page: number) =>
    this.getOnOffPrograms("SUBSCRIPTION_DATE", "ALL", page),
  ).present(this.programOnOffStore)
  paginatedOwnedProgramOnOffStore = new PaginatedStore<ItemProgramOnOff, ProgramOnOff, "id">((page: number) =>
    this.getOnOffPrograms("SUBSCRIPTION_DATE", "OWNED", page),
  ).present(this.programOnOffStore)
  paginatedSubscribedProgramOnOffStore = new PaginatedStore<ItemProgramOnOff, ProgramOnOff, "id">((page: number) =>
    this.getOnOffPrograms("SUBSCRIPTION_DATE", "SUBSCRIBED", page),
  ).present(this.programOnOffStore)
  programOnOffProgressionStore = new Store<ProgramOnOffProgression>((id) => this.api.getProgressionsProgramOnOff(id))
  programOnOffStripeSubscriptionInfosStore = new Store<ProgramOnOffStripeSubscriptionInfos>((id) =>
    this.getProgramOnOffStripeSubscriptionInfos(id),
  )

  constructor(
    private api: ProgramOnOffApi,
    private planningService: PlanningService,
    private programApi: ProgramApi,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  async onLoggedIn(): Promise<void> {
    const programs = await this.getOnOffPrograms("SUBSCRIPTION_DATE", "ALL")
    this.planningService.addFilteredPrograms(programs.content.map((p) => p.id))
  }

  async onLoggedOut(): Promise<void> {
    this.programOnOffStore.clear()
    this.paginatedAllProgramOnOffStore.clear()
    this.paginatedOwnedProgramOnOffStore.clear()
    this.paginatedSubscribedProgramOnOffStore.clear()
    this.programOnOffProgressionStore.clear()
  }

  async getProgramOnOff(programId: string) {
    return this.api.getProgramOnOff(programId)
  }

  async createOnOffProgram(programDraft: ProgramOnOffDraft) {
    const program = await this.api.createOnOffProgram(programDraft)
    this.programOnOffStore.save(program)
    this.planningService.addNewFilteredProgram(program.id)
    this.paginatedOwnedProgramOnOffStore.list()
    return program
  }

  async publishProgramOnOff(programId: string) {
    const programDto = await this.api.publishProgramOnOff(programId)
    this.programOnOffStore.update(programId, () => convertProgramOnOffFromDto(programDto))
  }

  async updateProgramOnOff(id: string, programDraft: Partial<ProgramOnOffDraft>) {
    const updatedProgram = await this.api.updateProgramOnOff(id, programDraft)
    this.programOnOffStore.update(id, () => updatedProgram)
    return updatedProgram
  }

  async deleteProgramOnOff(id: string) {
    await this.api.deleteProgramOnOff(id)
    this.planningService.removeFilteredProgram(id)
    this.programOnOffStore.remove(id)
  }

  async getOnOffPrograms(order: ListOrder, filter: ProgramFilter = "ALL", page?: number, size?: number) {
    return this.api.getOnOffPrograms(filter, order, page, size)
  }

  async updateProgramOnOffPrice(programId: string, price: ProgramPrice) {
    const programDto = await this.programApi.updateProgramPrice(programId, price)
    this.programOnOffStore.update(programId, () => convertProgramOnOffFromDto(programDto as ProgramOnOffDto))
  }

  async removeProgramOnOffPrice(programId: string) {
    const programDto = await this.programApi.removeProgramPrice(programId)
    this.programOnOffStore.update(programId, () => convertProgramOnOffFromDto(programDto as ProgramOnOffDto))
  }

  async startProgramOnOff(programId: string) {
    const updatedProgression = await this.api.startProgramOnOff(programId)

    this.programOnOffProgressionStore.update(programId, (progression) => ({
      ...progression,
      ...updatedProgression,
    }))
  }

  async stopProgramOnOff(programId: string) {
    const updatedProgression = await this.api.stopProgramOnOff(programId)

    this.programOnOffProgressionStore.update(programId, (progression) => ({
      ...progression,
      ...updatedProgression,
    }))
  }

  async restartProgramOnOff(programId: string) {
    const updatedProgression = await this.api.restartProgramOnOff(programId)

    this.programOnOffProgressionStore.update(programId, (progression) => ({
      ...progression,
      ...updatedProgression,
    }))
  }

  async subscribeProgramOnOff(id: string): Promise<ProgramSubscriptionStatus> {
    const status = await this.api.subscribeProgramOnOff(id)

    this.programOnOffStripeSubscriptionInfosStore.fetch(id)
    this.programOnOffStore.update(id, (program) => {
      return {
        ...program,
        subscriptionStatus: status,
      }
    })
    this.planningService.addNewFilteredProgram(id)

    return status
  }

  async unsubscribeProgramOnOff(id: string): Promise<ProgramSubscriptionStatus> {
    const status = await this.api.unsubscribeProgramOnOff(id)

    this.programOnOffStripeSubscriptionInfosStore.fetch(id)
    this.programOnOffStore.update(id, (program) => {
      return {
        ...program,
        subscriptionStatus: status,
      }
    })

    this.planningService.removeFilteredProgram(id)

    return status
  }

  async getProgramOnOffStripeSubscriptionInfos(id: string) {
    const result = await this.api.getProgramOnOffStripeSubscriptionInfos(id)

    return convertProgramOnOffStripeSubscriptionInfosFromDto(id, result)
  }
}
