import { FeatureKeys } from "@modules/featureToggle/featureToggleTypes"
import { useFeatureToggle } from "@modules/featureToggle/hooks/useFeatureToggle"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { getLanguageInfos } from "@modules/language/languageUtils"
import { Language } from "@modules/language/utils/languageUtils"
import { HUDropdown } from "@modules/ui/components/huDropdown"
import React from "react"

type Props = {
  onChange: (language: Language) => void
  selectedLanguage?: Language
  longValues?: boolean
}

export const LanguageDropdown: React.FC<Props> = ({ onChange, selectedLanguage, longValues }) => {
  const language = useLanguage()
  const allLanguagesAvailable = useFeatureToggle(FeatureKeys.AllLanguages)

  const languages = getLanguageInfos(allLanguagesAvailable).map((it) => ({
    label: longValues ? `${it.name} (${it.country.name})` : `${it.name} (${it.country.shortName})`,
    value: it.value,
    selected: longValues ? `${it.name} (${it.country.shortName})` : `${it.country.shortName}`,
  }))

  return (
    <HUDropdown
      value={selectedLanguage ?? language}
      options={languages}
      onChange={(language: Language) => onChange(language)}
      valueTemplate={(language) => languages.find((it) => it.value === language.value)?.selected}
    />
  )
}
