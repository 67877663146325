import { BoxProgrammation } from "@modules/boxProgrammation/boxProgrammationTypes"
import { BoxSlotDetailsInfo } from "@modules/boxSlot/components/boxSlotDetailsInfo"
import { BoxSlotFormView } from "@modules/boxSlot/components/boxSlotFormView"
import { BoxSlotSessionContentInfo } from "@modules/boxSlot/components/boxSlotSessionContentInfo"
import { BoxProgrammationSession } from "@modules/boxSlot/services/boxSlotDto"
import { useServicesContext } from "@modules/core/services/services.context"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"

export const useBoxSlotSidebar = () => {
  const { sidebarNavigateTo } = useSidebar()
  const { boxSlotService } = useServicesContext()

  const navigateToBoxSlotView = (
    boxProgrammationId: string,
    boxSlotId: string,
    refreshCalendarEvents: () => void,
    destinationView?: "session",
  ) => {
    sidebarNavigateTo({
      content: (
        <BoxSlotDetailsInfo
          boxProgrammationId={boxProgrammationId}
          boxSlotId={boxSlotId}
          refreshCalendarEvents={refreshCalendarEvents}
          destinationView={destinationView}
        />
      ),
      replaceAllComponents: true,
      onHide: () => {
        boxSlotService.updateBoxSlotsBeingViewed([])
        return Promise.resolve(true)
      },
    })
  }

  const navigateToBoxSlotCreation = (
    boxProgrammation: BoxProgrammation,
    refreshCalendarEvents: () => void,
    startDate?: Date,
  ) => {
    sidebarNavigateTo({
      content: (
        <BoxSlotFormView
          boxProgrammation={boxProgrammation}
          startDate={startDate}
          hideTitle={false}
          refreshCalendarEvents={refreshCalendarEvents}
        />
      ),
    })
  }

  const navigateToBoxSessionView = (
    boxProgrammation: BoxProgrammation,
    session: BoxProgrammationSession,
    refreshCalendarEvents: () => void,
  ) => {
    sidebarNavigateTo({
      content: (
        <BoxSlotSessionContentInfo
          boxProgrammation={boxProgrammation}
          session={session}
          refreshCalendarEvents={refreshCalendarEvents}
          date={session.date}
        />
      ),
      replaceAllComponents: true,
    })
  }

  return { navigateToBoxSlotView, navigateToBoxSlotCreation, navigateToBoxSessionView }
}
