import { BenchmarkIcon } from "@images/svgIcons/benchmarkIcon"
import { BoxIcon } from "@images/svgIcons/boxIcon"
import { InfinityIcon } from "@images/svgIcons/infinityIcon"
import { OnOffIcon } from "@images/svgIcons/onOffIcon"
import { ProfileIcon } from "@images/svgIcons/profileIcon"
import { ItemBenchmark } from "@modules/benchmark/benchmarkTypes"
import { ItemBox } from "@modules/box/boxTypes"
import { word } from "@modules/core/utils/i18n"
import { formatCurrency } from "@modules/currency/currency"
import { ExploreFilter } from "@modules/explore/exploreTypes"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { Profile } from "@modules/profile/profileTypes"
import { ProgramInfiniteTypeOf } from "@modules/programs/programInfiniteTypes"
import { ItemProgram } from "@modules/programs/programTypes"
import { HUText } from "@modules/ui/components/huText"
import { Tag } from "primereact/tag"
import { useMemo } from "react"
import styled, { useTheme } from "styled-components"

type ExploreCardHeaderProps = {
  item: ItemProgram | ItemBox | ItemBenchmark | Profile
}

export const ExploreCardHeader: React.FC<ExploreCardHeaderProps> = ({ item }) => {
  const theme = useTheme()
  const language = useLanguage()

  const itemIcon = useMemo(() => {
    switch (item._type) {
      case ExploreFilter.PROGRAM:
        if (item._programType === ProgramInfiniteTypeOf) {
          return <InfinityIcon color={theme.mainWhite} className="mr-2" width={15} />
        } else return <OnOffIcon color={theme.mainWhite} className="mr-2" width={15} />
      case ExploreFilter.BOX:
        return <BoxIcon color={theme.primaryColor} className="mr-2" />
      case ExploreFilter.BENCHMARK:
        return <BenchmarkIcon color={"white"} className="mr-2" />
      case ExploreFilter.PROFILE:
        return <ProfileIcon color={"white"} className="mr-2" />
    }
  }, [item])

  const itemType = useMemo(() => {
    switch (item._type) {
      case ExploreFilter.PROGRAM:
        return word("global.program.name")
      case ExploreFilter.BOX:
        return word("global.box.name")
      case ExploreFilter.BENCHMARK:
        return word("global.benchmark.name")
      case ExploreFilter.PROFILE:
        return word("global.profile.name")
    }
  }, [item])

  return (
    <>
      <TypeContainer rounded icon={itemIcon}>
        <HUText fontStyle="LM" color={theme.mainWhite}>
          {itemType}
        </HUText>
      </TypeContainer>
      {"price" in item && item.price?.value !== undefined && (
        <PriceContainer rounded>
          {item.subscribed && <i className="pi pi-check mr-1" style={{ fontSize: "0.7rem" }} />}
          <HUText fontStyle="LM" color={theme.mainWhite}>
            {`${formatCurrency(item.price.value, item.price.currency, language)} ${item._programType === ProgramInfiniteTypeOf ? word("program.price.priceSlashPerMonth") : ""}`}
          </HUText>
        </PriceContainer>
      )}
    </>
  )
}

const TypeContainer = styled(Tag)`
  position: absolute;
  top: 20px;
  left: 20px;
  align-items: center !important;
  background-color: rgba(28, 28, 28, 0.8);
  z-index: 300;

  &.p-tag {
    padding: 9px 14px 9px 10px;
    height: 34px;
    display: flex;
  }
`

const PriceContainer = styled(Tag)`
  position: absolute;
  top: 20px;
  right: 20px;
  align-items: center !important;
  background-color: ${({ theme }) => theme.neutralColor900};
  z-index: 300;
  &.p-tag {
    padding: 9px 14px 9px 10px;
    height: 34px;
    display: flex;
  }
`
