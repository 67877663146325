export type EndUserLicenseAgreementTermsType = {
  index: number
  title?: string
  content?: string
  items?: EndUserLicenseAgreementTermsType[]
}

type EndUserLicenseAgreementDataByLanguage = {
  title: string
  effectiveDateLabel: string
  effectiveDateValue: string
  terms: EndUserLicenseAgreementTermsType[]
}

type EndUserLicenseAgreementData = {
  en: EndUserLicenseAgreementDataByLanguage
  fr: EndUserLicenseAgreementDataByLanguage
}

export const endUserLicenseAgreementData: EndUserLicenseAgreementData = {
  en: {
    title: `General Terms and Conditions
    Hustle-Up`,
    effectiveDateLabel: "Effective date:",
    effectiveDateValue: "28 March 2024",
    terms: [
      {
        index: 1,
        title: "Object",
        content: `Hustle-Up allows its users (hereafter: the "<strong>Users</strong>"), professionals or private individuals, to register for sports lessons with sports trainer, to follow their sports practice, to create a training program accessible to other members or to manage the operation of a CrossFit Box, through the application available via the site <a href="https://sport.hustleup-app.com/login" target="_blank">https://sport.hustleup-app.com/login</a> or on the stores (hereafter together: the "<strong>Application</strong>").
          The purpose of these general terms and conditions is to define the terms and conditions of use of the services offered on the Application for the benefit of Users (hereinafter the "<strong>Services</strong>") and to define the rights and obligations of the parties in this context (hereinafter the "<strong>General Terms and Conditions</strong>").
          They can be accessed and printed at any time via a direct link on the Application.
          They may be supplemented, where applicable, by specific terms of use for certain Services, which supplement these General Terms and Conditions and, in the event of any contradiction, take precedence over them.`,
      },
      {
        index: 2,
        title: "Operator of the Application and Services, contact details",
        content: `The Application and the Services are operated by the company Health Tech & Co, a simplified joint stock company (“<i>Société par Actions Simplifiée</i>”) with a share capital of 1,000 euros, registered with the RCS of Lyon under the number 950 774 166, whose registered office is located at 22 BD DES DROITS DE L'HOMME, 69120 VAULX-EN-VELIN (hereinafter: "<strong>Hustle-Up</strong>").
        Hustle-Up can be contacted at the following address, in particular for any complaint:
        Postal address: 22 BD DES DROITS DE L'HOMME, 69120 VAULX-EN-VELIN
        Telephone: +336.69.53.80.24
        E-mail address: <a href="mailto:contact@hustleup-app.com">contact@hustleup-app.com</a>
        CrossFit, LLC is the registered owner of the CrossFit® and CrossFit Games® marks, which are used under license.
        `,
      },
      {
        index: 3,
        title: "Access to the Application and Services",
        items: [
          {
            index: 1,
            title: "Legal capacity",
            content: `The Application and Services are accessible to any natural person who has full legal capacity to enter into commitments under these General Terms and Conditions. Individuals who do not have full legal capacity may only access the Application and Services with the agreement of their legal representative.
            The Application and Services are intended for both private individuals and professionals wishing to use them directly or indirectly in connection with a paid activity carried out on a non-casual basis in all sectors of industry and commerce.
            Thus, certain Services are directed exclusively to the attention of the professionals, who have, if necessary, a legal structure adapted in accordance with the applicable regulation and guarantee Hustle-Up for this reason.`,
          },
        ],
      },
      {
        index: 4,
        title: "Acceptance of the General Terms and Conditions",
        content: `Acceptance of these General Terms and Conditions is evidenced by a box to be ticked on the registration form. This acceptance must be full and complete. Any conditional acceptance is considered null and void. Users who do not agree to be bound by these General Terms and Conditions must not access the Application or use the Services.`,
      },
      {
        index: 5,
        title: "Relationship with Stripe's intervention and general terms and conditions",
        content: `Users are expressly informed and accept that all payments and transfers made via the Platform are managed by Stripe Technology Europe Limited, an Electronic Money Institution registered with the Central Bank of Ireland under number C187865 (<a href="http://registers.centralbank.ie/FirmDataPage.aspx?firmReferenceNumber=C187865" target="_blank">http://registers.centralbank.ie/FirmDataPage.aspx?firmReferenceNumber=C187865</a>) (hereinafter referred to as "Stripe").
        Users contract directly with Stripe for the implementation of these payments by accepting Stripe's terms and conditions by ticking a box when they register on the Application.
        Within the framework of the Services, the Users transmit to Stripe all their instructions relating to the payments through their dedicated space. Consequently, they expressly mandate Hustle-Up to transmit the said instructions to Stripe, in their name and for their account.
        The implementation of the Services requiring that of the payments, the Users are informed and accept that the refusal of Stripe to accept their inscription as user of its own services, as well as the end of the contract between a User and Stripe, whatever is the reason, will involve automatically and of right the resolution of the present General Terms and Conditions between Hustle-Up and the User as well as, by way of consequence, the closing of the Account.
        Conversely, the end of the present General Terms and Conditions between Hustle-Up and the User will involve automatically and of right the resolution of the contract between the aforementioned User and Stripe.
        It is specified that Hustle-Up reserves the faculty to modify constantly the provider of payment retained under the Services. It is up to the User to accept the modifications applicable in the event of change of provider of payment. 
        `,
      },
      {
        index: 6,
        title: "Description of Services",
        content: `The User has access to the Services which follow, under a form and according to the functionalities and technical means which Hustle-Up considers most appropriate.`,
        items: [
          {
            index: 1,
            title: "Services offered on the Application",
            items: [
              {
                index: 1,
                title: "Provisions common to all Users",
                items: [
                  {
                    index: 1,
                    title: "Registration",
                  },
                  { index: 2, content: "To access the Services, the User must register as described below." },
                  {
                    index: 3,
                    content: `The user must register by completing the registration form available on the Application.
                  In all the cases, the User must provide to Hustle-Up all the information marked as obligatory. Any incomplete registration will not be validated.
                  The inscription automatically involves the opening of an account in the name of the User (hereafter: the "<strong>Account</strong>"), giving him access to a personal space (hereafter: the "<strong>Personal Space</strong>") which enables him to manage his use of the Services under a form and according to the technical means that Hustle-Up judges most appropriate to make the aforementioned Services.
                  The User guarantees that all the information he/she provides in the registration form is accurate, up to date and sincere and is not misleading in any way.
                  They undertake to update this information in their Personal Space in the event of any changes, so that it always corresponds to the aforementioned criteria.
                  The User is hereby informed and accepts that the information entered for the purposes of creating or updating his/her Account constitutes proof of his/her identity. The information entered by the User is binding as soon as it has been validated.
                  Users can access their Personal Space at any time by logging in using their login and password.
                  The User undertakes to use the Services personally and not to allow any third party to use them in his/her place or on his/her behalf, unless he/she bears full responsibility for doing so.
                  He is equally responsible for maintaining the confidentiality and security of his login and password. This one must immediately contact Hustle-Up as mentioned in the article " Operator of the Application and the Services, contact " of the present if he notices that his Account was used without his knowledge. He recognizes to Hustle-Up the right to take all appropriate measures in such a case.
                  It is the User's responsibility to manage the information he wishes to make accessible to other members of the Application and that which he does not wish to circulate in his Personal Space`,
                  },
                  {
                    index: 4,
                    title: "Available features",
                    content: `Once registered, the User can access the various functions available on the Application, such as using a stopwatch, viewing a news feed, accessing a chat room, subscribing to a training group, searching for other members, sharing performances with other members or locating nearby sports gym. 
                  The User has access on the Application to all the training programs offered by other members (hereinafter: the "<strong>Program</strong>"). 
                  For each Program, it indicates: 
                  <ul><li>The relevant discipline;</li><li>The level;</li><li>The training details;</li><li>The User who offers the Program, whether or not in his/her capacity as a sports coach;</li><li>Any applicable price.</li></ul>
                  The Programs are given by Users registered on the Application under their own responsibility, being specified that Hustle-Up does not have control on the competences at the same time pedagogical and in the sporting practice data subject of the User proposing the aforementioned Program. The execution of the Program is entirely under the control of the User, under his/her own responsibility.
                  The User can subscribe to a program available on the Application (hereinafter: a "<strong>Program</strong>") individually or in a subscription format (hereinafter: the "<strong>Subscription</strong>").
                  If the Subscriber has opted for a Subscription as part of a Program, it begins on the first billing day (hereinafter: the "<strong>Initial Period</strong>") for an initial period of one month (unless otherwise stated on the Application). 
                  If the Subscription is renewed at the end of the Initial Period, it is then renewed for successive periods of one month, unless terminated by the User at any time up to the last day of the current period.
                  In any event, termination of the Subscription is made by email or on the Application (if offered) and takes effect at the end of the Subscription period during which the request is sent.
                  Any period started is due in full. 
                  The User recognizes and accepts that the Application provides only the technical means necessary to the subscription of the Program but that Hustle-Up could not in no case to be regarded as the holder or the person in charge for the Program. The Users contract directly between themselves concerning the relations relating to the Program and its execution.
                  The Application also enables Users to sign up for the classes and sessions they want at a Box (CrossFit), subject to availability.
                  The User can register for these sessions at a Box in different ways:
                  <ul><li>a subscription, the applicable terms and conditions are indicated on the Application and which enables the User to sign up for all the sessions of his/her choice as proposed by the Box;</li><li>a pack, enabling the User to take part in a set number of sessions, according to the terms offered by the Box (hereinafter: the "<strong>Pack</strong>"). The period of validity and the number of sessions included in the Pack are indicated on the Application.</li></ul>
                  The User also has the option of purchasing certain products offered for sale by a Box on the Application. The terms and conditions for ordering these products are indicated on the Application. The conditions of sale are specific to the User selling the said products.
                  The User is invited to contact the operator of the Box to find out how a session can be cancelled, postponed or refunded.`,
                  },
                ],
              },
              {
                index: 2,
                title: "Specific provisions for the creation of Program",
                content: `All Users have the option of creating a Program on the Application, which they can decide whether or not to make available to other members.
                If he/she wishes to monetize his/her Program, he/she acknowledges and accepts that the provisions of article 5 hereof shall apply. 
                Each Program can be customized in terms of both content and target audience. 
                The User may set a limit on the number of people who may benefit from their Program. 
                The Program will be offered by unit or as a Subscription.
                It is up to the User creator of a Program, in quality of trainer, to define the methods of use and sale of its Program, in quality of operator of the person in charge. He guarantees Hustle-Up against any recourse in this respect and in particular against any difficulty that may arise with a subscriber User. `,
              },
              {
                index: 3,
                title: "Specific provisions for the creation of a Box (CrossFit)",
                content: `For any creation of a Box, Hustle-Up reserves the possibility of requesting additional information on the User: “SIRENE” number, profile picture, photographs of the sports room and the changing room, contact details etc. 
                An interface dedicated to the management of a Box could be opened to the User thus authorized by Hustle-Up.
                If accepted by Hustle-Up, the User holder of a Box will be able to manage the planning of its room, to profit from a CRM integrated on the Application, to manage its authorizations and accesses (coaches, accountancy, etc.), to reach financial ratios etc. 
                The holder of the Box may offer Users, under its own responsibility: 
                <ul><li>a Subscription package;</li><li>a Pack.</li></ul>
                The User remains entirely responsible for the services and products proposed within the framework of its Box and it guarantees Hustle-Up against any recourse of a User for this reason.`,
              },
              {
                index: 4,
                title: "Specific provisions for the creation of events",
                content: `Any User can create an event on the Application.
                This may be a sporting competition for other members of the Application, which may take place physically or virtually. 
                Certain specific information could be requested by Hustle-Up to the creator of the event.
                It will therefore be able to define the financial conditions applicable to its event, in accordance with the terms of article 5 hereof. 
                The event creator remains entirely responsible for the management, registration and running of the events.  `,
              },
            ],
          },
          {
            index: 2,
            title: "Other Services",
            content: `Hustle-Up reserves the possibility of proposing any other Service which it will judge useful, under a form and according to the functionalities and technical means which it will estimate most suitable to return the aforementioned Services.`,
          },
        ],
      },
      {
        index: 7,
        title: "Financial conditions",
        items: [
          {
            index: 1,
            title: "Prices",
            content: `Registration on the Application is free. 
            The prices of Subscriptions or Packs offered by Users (Programs or Boxes) are indicated on the Application. Unless otherwise stated, they are expressed in Euros and include all French taxes.
            For any creation of a Box, Hustle-Up will apply fees of services if it appears that the operator of the aforementioned Box is external to the CrossFit network. For this purpose, Hustle-Up will have several technical tools and in particular an API in order to check the affiliation of the aforementioned operator. In such event, the fees applied will be indicated on the Application. `,
          },
          {
            index: 2,
            title: "Billing and payment terms",
            content: `If applicable, Hustle-Up will provide the User with invoices by any means.
            Payment of the price is made online, by credit card or bank transfer, via the STRIPE secure online payment service or by any other means proposed on the Application.
            The User guarantees to Hustle-Up that he has the necessary authorizations to use the chosen method of payment.`,
          },
          {
            index: 3,
            title: "Late payments and payment incidents ",
            content: `The User is hereby informed and expressly accepts that any delay in payment of all or part of a sum due on the due date will automatically, without prejudice to the provisions of the article entitled "<i>Penalties for default</i>" and on the first presentation of a formal notice by registered letter with acknowledgement of receipt:
            &nbsp&nbsp&nbsp(i) forfeiture of all sums owed by the User and their immediate payment;
            &nbsp&nbsp&nbsp(ii) immediate suspension of Services in progress until full payment of all sums owed by the User;
            &nbsp&nbsp&nbsp(iii) the invoicing with the profit of Hustle-Up of an interest of delay at the rate of 1,5 times (one time and half) the rate of the legal interest, based on the amount of the entirety of the sums due by the User.
            `,
          },
        ],
      },
      {
        index: 8,
        title: "Convention of proof",
        content: `The User expressly acknowledges and accepts:
        &nbsp&nbsp&nbsp(i) that the data collected on the Application and the data-processing equipment of Hustle-Up make evidence of the reality of the operations intervened within the framework of present,
        &nbsp&nbsp&nbsp(ii) that these data constitute the principal mode of proof admitted between the parts, in particular for the calculation of the sums due to Hustle-Up.
        The User can reach these data in his Personal Space or by contacting Hustle-Up with the coordinates indicated in the article "<i>Operator of the Application and the Services, contact</i>".
        `,
      },
      {
        index: 10,
        title: "Obligations of the User",
        content: `Without prejudice to the other obligations set out herein, the User undertakes to comply with the following obligations.`,
        items: [
          {
            index: 1,
            content: `Users are solely responsible for the Programs and sessions in which they choose to participate. It is the User's responsibility to ensure that their state of health allows them to take part in the relevant discipline, by obtaining prior medical advice if necessary.
            It is also the responsibility of the participant to inform the coach, at the Box, before the start of a session or Program, of any necessary information relating to his/her state of health, such as, but not limited to: pregnancy, injury, surgery, etc.
            Finally, during the session or Program, it is up to the User to respect its own limits so as not to injure itself.
            The responsibility of Hustle-Up could not be engaged if the User would suffer from any physical injury occurred during a meeting, a Program or afterwards.`,
          },
          {
            index: 2,
            content: `Users are also solely responsible for the proper completion of all administrative, fiscal and/or social formalities and for all payments of contributions, taxes or duties of any kind that may be incumbent on them in connection with their use of the Services and the Application.`,
          },
          {
            index: 3,
            content: `The User recognizes and accepts that the Application could not in no case to be assimilated, in itself with a sporting device, health, formation or any other similar qualification. Hustle-Up acts solely as operator of the Application enabling Users to contact other Users and to approach professionals on the Application, who are entirely responsible for the content or training offered.`,
          },
          {
            index: 4,
            content: `The User must have taken out civil liability insurance. The User undertakes to ensure that this insurance is in force on the day of registration on the Application and throughout the duration of the Services.`,
          },
          {
            index: 5,
            content: `The User undertakes to make strictly personal use of the Services and his/her Account. Consequently, they may not assign, grant or transfer all or part of their rights or obligations hereunder to a third party in any way whatsoever.
            They will not allow a third party to take part in a Program or session in their place.
            The User is informed and accepts that Hustle-Up can take all the necessary measures to prevent any abuse by a User within the framework of the use of the Services and its Account.`,
          },
          {
            index: 6,
            content: `The User undertakes not to hinder the smooth running of a Program or session by any inappropriate behavior. 
            Users undertake to observe the usual rules of politeness and courtesy.`,
          },
          {
            index: 7,
            content: `The User undertakes to create only one Account. 
            In the event of several Accounts, Hustle-Up reserves the right (i) to remove all the Accounts in surplus or (ii) to put an end to the Services under the conditions stated in the article "<i>Sanctions for breaches</i>".`,
          },
          {
            index: 8,
            content: `In using the Services, the User undertakes to comply with the laws and regulations in force and not to infringe the rights of third parties or public order.`,
          },
          {
            index: 9,
            content: `The User acknowledges that he/she has familiarized himself/herself on the Application with the characteristics and constraints, particularly technical, of all the Services. They are solely responsible for their use of the Services.`,
          },
          {
            index: 10,
            content: `The User recognizes and accepts that Hustle-Up is expressly authorized to diffuse the name and the CrossFit® trademark in accordance with regularized commercial agreements between it and CrossFit LLC. The present document shall not be interpreted in any way whatsoever as an authorization to use or a license granted to the User on the CrossFit® trademark or name. Consequently, the Customer prohibits himself any use, temporary or not, with this title and guarantees Hustle-Up against any recourse of thirds.`,
          },
          {
            index: 11,
            content: `The User commits to supply to Hustle-Up all information necessary to the proper execution of the Services. More generally the User commits to cooperate actively with Hustle-Up regarding the proper execution of the present.`,
          },
          {
            index: 12,
            content: `The User recognizes that the Services offer to him a solution of reservation of Program or sport session and that this solution would not substitute for the other means which the User can have elsewhere to achieve the same objective. The User recognizes and accepts that Hustle-Up could not in no case to be regarded as a sport trainer, a sport gyms or a professional of health and that it belongs to him to approach the operator of a Box or a Program for any question relating to a Subscription or a Pack. The responsibility of Hustle-Up could not in no case be sought with regard to the contents of the Programs or sessions of sport proposed on the Application, this one only makes it possible to the Users to enter in relation and provides them the technical means necessary to this end.`,
          },
          {
            index: 13,
            content: `The User is hereby informed and accepts that in order to use the Services, he/she must be connected to the Internet and that the quality of the Services depends directly on this connection, for which he/she alone is responsible.`,
          },
        ],
      },
      {
        index: 11,
        title: "User guarantee",
        content: `The User agrees to defend, indemnify and hold Hustle-Up harmless from and against any claims, demands, actions and/or grievances whatsoever, that Hustle-Up could incur as a result of a breach by said User of any one of its obligations or guarantees under these General Terms and Conditions.
        The User agrees to compensate Hustle-Up for any damage that the latter could be subject to, and to pay any costs, liabilities, charges and / or convictions that the latter could incur, as a result of such a breach.`,
      },
      {
        index: 12,
        title: "Prohibited behaviour",
        items: [
          {
            index: 1,
            content: `It is strictly forbidden to use the Services for the following purposes: 
            <ul><li>carrying out illegal or fraudulent activities or activities that infringe the rights or safety of third parties,</li><li>undermining public order or violating applicable laws and regulations,</li><li>intrusion into a third party's computer system or any activity of a nature to harm, control, interfere with or intercept all or part of a third party's computer system, or to violate its integrity or security,</li><li>sending unsolicited emails and/or commercial canvassing or solicitation,</li><li>manipulations intended to improve the referencing of a third-party site,</li><li>aiding or inciting, in any form and in any manner whatsoever, one or more of the acts and activities described above,</li><li>and more generally any practice that misuses the Services for purposes other than those for which they were designed.</li></ul>`,
          },
          {
            index: 2,
            content: `It is strictly forbidden to the Users to copy and/or to divert with their ends or those of thirds the concept, technologies, whole or part of the data or any other element of the Application of Hustle-Up.`,
          },
          {
            index: 3,
            content: `The following are also strictly prohibited (i) all behaviors likely to interrupt, suspend, slow down or prevent the continuity of the Services, (ii) all intrusions or attempts of intrusions in the systems of Hustle-Up, (iii) all diversions of the system resources of the Application, (iv) all actions likely to impose a disproportionate load on the infrastructures of this last, (v) all attacks with the measures of safety and authentication, (vi) all acts of nature to attack the rights and financial, commercial or moral interests of Hustle-Up or the users of its Application, and finally more generally (vii) any failure with the present general conditions.`,
          },
          {
            index: 4,
            content: `It is strictly forbidden to monetize, sell or grant access to all or part of the Services or the Application, or to the information hosted and/or shared therein.`,
          },
        ],
      },
      {
        index: 13,
        title: "Sanctions for breaches",
        content: `In the event of failure to comply with provisions of the present General Terms and Conditions or more generally, of infringement with the laws and regulations in force by a User, Hustle-Up reserves the right to take any appropriate measure and in particular of:
        &nbsp&nbsp&nbsp(i) suspend access to the Services of the User who has committed or participated in the breach or infringement,
        &nbsp&nbsp&nbsp(ii) to publish on the Application any message of information which Hustle-Up will consider useful,
        &nbsp&nbsp&nbsp(iii) notify any data subject authorities,
        &nbsp&nbsp&nbsp(iv) take any legal action.
          In the event of failure of the User with an essential obligation resulting from the present general conditions, Hustle-Up reserves the right to cancel its access to whole or part of the Services, with immediate effect, by letter or email. The resolution takes effect as of right with the date of sending, by Hustle-Up, of the writing addressed to the User in application of the present clause. It involves automatically and without prior formal notice the deletion of the Account of the User, without prejudice to other consequences possibly induced in application of these general conditions.
          In particular, payment for the Services and appropriate behavior when using the Application are considered essential obligations.
        `,
      },
      {
        index: 14,
        title: "Responsibility and guarantee of Hustle-Up",
        items: [
          {
            index: 1,
            content: `Hustle-Up undertakes to provide the Services diligently and according to the rules of the trade, it being specified that it has an obligation of means, to the exclusion of any obligation of result, which the Users expressly acknowledge and accept.
          In this respect, Hustle-Up does not carry out any control on the selection of the Users having the quality of trainer and cannot guarantee to the Users that the Programs proposed will correspond perfectly to the expectations of a User.`,
          },
          {
            index: 2,
            content: `Hustle-Up makes a commitment to proceed regularly to controls in order to check the operation and the accessibility of the Application. In this respect, Hustle-Up reserves the right to temporarily interrupt access to the Application for maintenance reasons. In the same way, Hustle-Up could not be held responsible for the difficulties or momentary impossibilities of access to the Application which would have for origin circumstances which are external to him, the absolute necessity, or which would be due to disturbances of the telecommunication networks.`,
          },
          {
            index: 3,
            content: `Hustle-Up does not guarantee to the Users (i) that the Services, subject to constant research in order to improve in particular their performance and progress, will be totally free from errors, defects or faults, (ii) that the Services, being standard and in no way proposed for the sole intention of a given User according to his own personal constraints, will specifically meet his needs and expectations.`,
          },
          {
            index: 4,
            content: `Hustle-Up liability could not be engaged in the event of physical injury undergone by the User regarding the use of the Application. The User remains in charge of its use of the Application and the bonds which it decides to maintain with the other Users, Hustle-Up does not have any control nor moderation in this respect. 
            The User expressly renounces to seek compensation to Hustle-Up for indirect damage, and in particular the loss of profit, the loss of chance, the commercial or financial damage, the increase in general expenses or the losses finding their origin or being the consequence of the execution of present.`,
          },
          {
            index: 5,
            content: `In any event, the responsibility likely to be incurred by Hustle-Up with the title of present is expressly limited to the only direct damage proven undergone by the User.`,
          },
        ],
      },
      {
        index: 15,
        title: "Intellectual property",
        content: `The systems, software, structures, infrastructures, data bases and contents of any nature (texts, articles, images, visual, musics, logos, videos marks, data base, etc) operated by Hustle-Up within the Application are protected by all rights of intellectual property or rights of the producers of data bases in force. All disassemblies, decompilations, decryptings, extractions, re-uses, copies and more generally, all acts of reproduction, representation, diffusion and use of the unspecified one of these elements, entirely or partly, without the authorization of Hustle-Up are strictly prohibited and could be the subject of legal proceedings.
        Users are not authorized to download or share the contents placed at the disposal by Hustle-Up or other Users on the Application. Hustle-Up is the only holder of the intellectual property rights attached to the contents placed at the disposal of the Users on the Application.
        Furthermore, Users guarantee Hustle-Up: 
          (i) that they have all the rights and authorizations required to use and/or distribute content of any kind (editorial, graphic, photographic, logo, brand or other) in connection with the use of the Services and the Application. They undertake to ensure that the said elements are lawful, do not infringe public order, public decency, or the rights of third parties, do not infringe any legislative or regulatory provision and, more generally, are in no way likely to bring into play the civil or criminal liability of Hustle-Up.
        Users are therefore prohibited from distributing, in particular and without this list being exhaustive:
        <ul><li>content that is child pornographic, obscene, indecent, offensive, defamatory, abusive, incites hatred or violence, racist, xenophobic, revisionist or promotes terrorism;</li><li>infringing content;</li><li>content damaging to the image of a third party;</li><li>content that is misleading or deceptive or that proposes or promotes illegal, fraudulent or deceptive activities;</li><li>and more generally any content likely to infringe the rights of third parties or to be prejudicial to third parties, in any manner or form whatsoever.</li></ul>
        Within this framework, the Users guarantee Hustle-Up against any complaint, claim and/or action of one or more other Users and/or of any third party maintaining that the elements would constitute a violation of its rights, whatever they are and in particular its rights of intellectual property, right to the image and to the protection of the private life. Consequently, the data subject User makes a commitment to compensate Hustle-Up of any damage which it would undergo and to take with its load all the damages, as well as the expenses, charges and costs to which Hustle-Up could be condemned, or which would be envisaged by a transactional agreement signed by Hustle-Up after having obtained the prior agreement of the User.
        (ii) have taken note of the characteristics and constraints, particularly technical, of all the Services on the Application.`,
      },
      {
        index: 16,
        title: "Authorisation to broadcast testimonies",
        items: [
          {
            index: 1,
            content: `For the duration of the Services, Users authorize Hustle-Up to use their profiles (hereinafter: the "<strong>Profiles</strong>") and the testimonials they publish on the Application (hereinafter: the "<strong>Testimonials</strong>") for the promotion of the latter, in accordance with the following terms and conditions:
            <ul><li>They agree that their Testimonies and Profiles are diffused on a free basis by Hustle-Up on the Application and on all other French or foreign Internet sites, published by all companies with which Hustle-Up has agreements,</li><li>They agree that their Testimonies and Profiles are diffused by Hustle-Up by any means and on any support at ends of promotion of the Application,</li><li>They accept that their Testimonials and Profiles may be translated into any language,</li><li>They acknowledge and accept that the Testimonials and Profiles may be subject to modifications, particularly as regards their framing, format and colors, as well as alterations or degradations in their quality, depending on the technical constraints of the Application,</li><li>They waive their right to ask Hustle-Up for any remuneration, royalties, indemnity or financial compensation in this respect.</li></ul>`,
          },
        ],
      },
      {
        index: 17,
        title: "Disputes between Users",
        content: `In the event of act carrying damage with a User committed by another User within the framework of the Services, the User will be able to declare the aforementioned behavior to Hustle-Up by postal mail or email in accordance with the contact details stated in the article "<i>Operator of the Site and the Application</i>", after having sought a amicable settlement beforehand.
        The report must include the date of notification, the identity of the complainant (surname, first name, profession, place of residence, nationality, date and place of birth), the identifier of the User causing the damage, a description of the disputed facts accompanied, where applicable, by a link to the web page providing proof, as well as a copy of the correspondence sent to the perpetrator of the infringement seeking an amicable solution.
        Hustle-Up will have the possibility to take all appropriate measures, without any engagement of its share and/or to transmit this complaint to the competent authorities. In particular, Hustle-Up will have the faculty to warn any person in the event of dispute.
        Users shall be personally responsible for any action they may bring before any court against another User for damage suffered as a result.`,
      },
      {
        index: 18,
        title: "Independence of the parties",
        content: `It is expressly agreed that neither party may rely on the provisions hereof to claim, in any way, the status of agent, representative or employee of the other party, nor bind the other party to third parties, beyond the Services provided by the provisions hereof.
        Under the terms of this agreement, no particular legal structure is formed between the parties, each retaining its full autonomy, its responsibilities and its own clientele.`,
      },
      {
        index: 19,
        title: "Personal data",
        content: `Hustle-Up practices a policy of protection of the personal data whose characteristics are explained in the document entitled "<i>Privacy policy</i>", which the User is expressly invited to read.`,
      },
      {
        index: 20,
        title: "Advertising, links and third-party sites",
        content: `Hustle-Up reserves the right to insert on any page of the Application and in any communication to the Users any advertising or promotional messages in a form and under conditions of which Hustle-Up will be the only judge.
        Hustle-Up assumes no responsibility for the content, advertising, products and/or services available on such third-party websites and mobile applications, which are governed by their own terms of use.
        Hustle-Up is not either responsible for the transactions intervened between the User and some advertiser, professional or tradesman (including its possible partners) towards which the User would be directed by the intermediary of the Application and would not know to in no case to be party to some possible litigations that it is with these thirds concerning in particular the delivery of products and/or services, the guarantees, declarations and other unspecified obligations to which these thirds are held.`,
      },
      {
        index: 21,
        title: "Changes",
        content: `Hustle-Up reserves the right to modify these General Terms and Conditions at any time. 
        The User will be informed of these modifications by any useful means.
        Users who do not accept the amended General Terms and Conditions must unsubscribe and not use the Application and Services.
        Any User who uses the Services after the entry into force of the modified General Terms and Conditions is deemed to have accepted these modifications.`,
      },
      {
        index: 22,
        title: "Language",
        content: `In the event of a translation of these General Terms and Conditions into one or more languages, the language of interpretation will be French in the event of a contradiction or dispute over the meaning of a term or provision.`,
      },
      {
        index: 23,
        title: "Unsubscribe",
        content: `The User can unsubscribe of the Application constantly, by addressing a request to this end to Hustle-Up by email, with the co-ordinates mentioned below or via the Application directly. This un-subscription will be effective as soon as possible.`,
      },
      {
        index: 24,
        title: "Mediation",
        content: `The User having the quality of consumer within the meaning of the French Consumer Code has the right to have recourse free of charge to a mediator of consumption for the amicable resolution of any litigation relating to the execution of present which would oppose it to Hustle-Up, under the conditions envisaged in articles L611-1 and following and R612-1 and following of the Code of consumption.
        To do this, they can contact the following consumer ombudsman:
        Consumer mediation centre for court conciliators (CM2C)
        Postal address: 14 rue Saint Jean 75017 Paris
        <a href="https://www.cm2c.net" target="_blank">https://www.cm2c.net</a>`,
      },
      {
        index: 25,
        title: "Applicable law and jurisdiction",
        content: `These terms and conditions are governed by French law.
        In the event of any dispute concerning the validity, interpretation and/or performance of these general terms and conditions, the parties agree that the courts of Lyon shall have exclusive jurisdiction, unless mandatory procedural rules dictate otherwise.`,
      },
    ],
  },
  fr: {
    title: `Conditions générales de vente et d’utilisation
    Hustle-Up`,
    effectiveDateLabel: "Date d’entrée en vigueur :",
    effectiveDateValue: "28 mars 2024",
    terms: [
      {
        index: 1,
        title: "Objet",
        content: `Hustle-Up permet à ses utilisateurs (ci-après : les «<strong>Utilisateurs</strong>»), professionnels ou particuliers, de s’inscrire à des cours de sport auprès de coach sportifs, de suivre sa pratique du sport, de créer un programme d’entrainement accessible aux autres membres ou encore de gérer l’exploitation d’une Box de CrossFit, à travers l’application disponible via le site <a href="https://sport.hustleup-app.com/login" target="_blank">https://sport.hustleup-app.com/login</a> ou sur les stores (ci-après ensemble : l’«<strong>Application</strong>»).
  Les présentes conditions générales ont pour objet de définir les modalités et conditions d’utilisation des services proposés sur l’Application au profit des Utilisateurs (ci-après les «<strong>Services</strong>») ainsi que de définir les droits et obligations des parties dans ce cadre (ci-après les «<strong>Conditions Générales</strong>»).
  Elles sont accessibles et imprimables à tout moment par un lien direct disponible sur l’Application.
  Elles peuvent être complétées le cas échéant par des conditions d’utilisation particulières à certains Services, lesquelles complètent les présentes conditions générales et, en cas de contradiction, prévalent sur ces dernières.`,
      },
      {
        index: 2,
        title: "Exploitant de l’Application et des Services, contact",
        content: `L’Application et les Services sont exploités par la société Health Tech & Co, SAS au capital social de 1.000 euros, immatriculée au RCS de Lyon sous le n° 950 774 166, dont le siège social est situé 22 BD DES DROITS DE L’HOMME, 69120 VAULX-EN-VELIN (ci-après : «<strong>Hustle-Up</strong>»).
        Hustle-Up peut être contactée aux coordonnées suivantes, notamment pour toute réclamation :
        Adresse postale : 22 BD DES DROITS DE L’HOMME, 69120 VAULX-EN-VELIN
        Téléphone : +336.69.53.80.24
        Adresse électronique : <a href="mailto:contact@hustleup-app.com">contact@hustleup-app.com</a>
        CrossFit, LLC est l'unique propriétaire des marques CrossFit® et CrossFit Games®, qui sont utilisées par Hustle Up sous licence.
        `,
      },
      {
        index: 3,
        title: "Accès à l’Application et aux Services",
        items: [
          {
            index: 1,
            title: "Capacité juridique",
            content: `L’Application et les Services sont accessibles à toute personne physique disposant de la pleine capacité juridique pour s’engager au titre des présentes conditions générales. La personne physique qui ne dispose pas de la pleine capacité juridique ne peut accéder à l’Application et aux Services qu’avec l’accord de son représentant légal.
          L’Application et les Services s’adressent indifféremment aux particuliers et aux professionnels désirant réaliser un usage lié directement ou indirectement à une activité rémunérée exercée de façon non occasionnelle dans tous les secteurs d’activité de l’industrie et du commerce.
          Ainsi, certains Services sont exclusivement dirigés à l’attention des professionnels, lesquels disposent, le cas échéant, d’une structure juridique adaptée conformément à la règlementation applicable et garantissent Hustle-Up à ce titre.`,
          },
        ],
      },
      {
        index: 4,
        title: "Acceptation des Conditions Générales",
        content: `L’acceptation des présentes Conditions Générales est matérialisée par une case à cocher dans le formulaire d’inscription. Cette acceptation ne peut être que pleine et entière. Toute adhésion sous réserve est considérée comme nulle et non avenue. L’Utilisateur qui n’accepte pas d’être lié par les présentes Conditions Générales ne doit pas accéder à l’Application ni utiliser les Services.`,
      },
      {
        index: 5,
        title: "Articulation avec l’intervention et les conditions générales de Stripe",
        content: `Les Utilisateurs sont expressément informés et acceptent que tous les paiements et virements effectués à travers la Plateforme sont gérés par la société Stripe Technology Europe Limited, Établissement de Monnaie Electronique enregistré auprès de la Banque Centrale d’Irlande sous le numéro C187865 (<a href="http://registers.centralbank.ie/FirmDataPage.aspx?firmReferenceNumber=C187865" target="_blank">http://registers.centralbank.ie/FirmDataPage.aspx?firmReferenceNumber=C187865</a>) (ci-après : « Stripe »). 
        Les Utilisateurs contractent directement avec Stripe s’agissant de la mise en œuvre de ces paiements, en acceptant les conditions générales de Stripe par le biais d’une case à cocher lors de leur inscription sur l’Application. 
        Dans le cadre des Services, les Utilisateurs transmettent à Stripe toutes leurs instructions relatives aux paiements à travers leur espace dédié. En conséquence, ils mandatent expressément Hustle-Up pour transmettre lesdites instructions à Stripe, en leur nom et pour leur compte.
        La mise en œuvre des Services nécessitant celle des paiements, les Utilisateurs sont informés et acceptent que le refus de Stripe d’accepter leur inscription en tant qu’utilisateur de ses propres services, de même que la fin du contrat entre un Utilisateurs et Stripe, quel qu’en soit le motif, entraînera automatiquement et de plein droit la résolution des présentes Conditions Générales entre Hustle-Up et l’Utilisateur ainsi que, par voie de conséquence, la fermeture du Compte de ce dernier. 
        Inversement, la fin des présentes Conditions Générales entre Hustle-Up et l’Utilisateur entraînera automatiquement et de plein droit la résolution du contrat entre ledit Utilisateur et Stripe.
        Il est précisé que Hustle-Up se réserve la faculté de modifier à tout moment le prestataire de paiement retenu au titre des Services. Il appartient à l’Utilisateur d’accepter les modifications applicables en cas de changement de prestataire de paiement.`,
      },
      {
        index: 6,
        title: "Description des Services",
        content:
          "L’Utilisateur a accès aux Services qui suivent, sous une forme et selon les fonctionnalités et moyens techniques que Hustle-Up juge les plus appropriés.",
        items: [
          {
            index: 1,
            title: "Les services proposés sur l’Application",
            items: [
              {
                index: 1,
                title: "Dispositions communes à tous les Utilisateurs",
                items: [
                  {
                    index: 1,
                    title: "Inscription",
                  },
                  {
                    index: 2,
                    content: `Pour accéder aux Services, l’Utilisateur doit s’inscrire selon les modalités ci-après.`,
                  },
                  {
                    index: 3,
                    content: `L’utilisateur doit s’inscrire en remplissant le formulaire d’inscription disponible sur l’Application.
                    Dans tous les cas, l’Utilisateur doit fournir à Hustle-Up l’ensemble des informations marquées comme obligatoires. Toute inscription incomplète ne sera pas validée.
                    L’inscription entraîne automatiquement l’ouverture d’un compte au nom de l’Utilisateur (ci-après : le «<strong>Compte</strong>»), lui donnant accès à un espace personnel (ci-après : l’ «<strong>Espace Personnel</strong>») qui lui permet de gérer son utilisation des Services sous une forme et selon les moyens techniques que Hustle-Up juge les plus appropriés pour rendre lesdits Services.
                    L’Utilisateur garantit que toutes les informations qu’il donne dans le formulaire d’inscription sont exactes, à jour et sincères et ne sont entachées d’aucun caractère trompeur.
                    Il s’engage à mettre à jour ces informations dans son Espace Personnel en cas de modifications, afin qu’elles correspondent toujours aux critères susvisés.
                    L’Utilisateur est informé et accepte que les informations saisies aux fins de création ou de mise à jour de son Compte vaillent preuve de son identité. Les informations saisies par l’Utilisateur l’engagent dès leur validation.
                    L’Utilisateur peut accéder à tout moment à son Espace Personnel après s’être identifié à l’aide de son identifiant de connexion ainsi que de son mot de passe.
                    L’Utilisateur s’engage à utiliser personnellement les Services et à ne permettre à aucun tiers de les utiliser à sa place ou pour son compte, sauf à en supporter l’entière responsabilité.
                    Il est pareillement responsable du maintien de la confidentialité et de la sécurité de son identifiant et de son mot de passe. Celui-ci doit immédiatement contacter Hustle-Up aux coordonnées mentionnées à l’article « Exploitant de l’Application et des Services, contact » des présentes s’il remarque que son Compte a été utilisé à son insu. Il reconnaît à Hustle-Up le droit de prendre toutes mesures appropriées en pareil cas.
                    Il appartient à l’Utilisateur de gérer sur son Espace Personnel les informations qu’il souhaite rendre accessible aux autres membres de l’Application et celles qu’il souhaite ne pas diffuser.`,
                  },
                  {
                    index: 4,
                    title: "Fonctionnalités disponibles",
                    content: `Une fois inscrit, l’Utilisateur peut accéder à différentes fonctionnalités disponibles sur l’Application, telles que l’utilisation d’un chronomètre, la visualisation d’un fil d’actualité, l’accès à un chat, l’inscription à un groupe d’entraînement, la recherche d’autres membres, le partage de performances avec d’autres membres ou encore la localisation de salles de sport à proximité. 
                    L’Utilisateur a accès sur l’Application à l’ensemble des programmes d’entraînement proposés par d’autres membres (ci-après : le «<strong>Programme</strong>»). 
                    Pour chaque Programme, il indiqué : 
                    <ul><li> Le sport concerné ;</li><li> Le niveau ;</li><li> Les détails de l’entraînement ;</li><li> L’Utilisateur qui propose le Programme, en sa qualité de coach sportif ou non ;</li><li> Le prix éventuellement applicable.</li></ul>
                    Les Programmes sont dispensés par des Utilisateurs inscrits sur l’Application sous leur propre responsabilité, étant précisé que Hustle-Up n’a pas de maîtrise sur les compétences à la fois pédagogiques et dans la pratique sportive concernée de l’Utilisateur proposant ledit Programme. Le déroulement des Programmes relève de l’entière maîtrise de l’Utilisateur, sous sa propre responsabilité.
                    L’Utilisateur peut ainsi souscrire à un programme disponible sur l’Application (ci-après : un «<strong>Programme</strong>») à l’unité ou sur un format d’abonnement (ci-après : l’ «<strong>Abonnement</strong>»).
                    S’il a opté pour un Abonnement dans le cadre d’un Programme, celui-ci débute au premier jour de facturation (ci-après : la «<strong>Période Initiale</strong>») pour une durée initiale d’un mois (saus mention contraire sur l’Application). 
                    Si l’Abonnement est renouvelé à l’issue de la Période Initiale, il est alors reconduit pour des périodes successives d’un mois, sauf dénonciation effectuée par l’Utilisateur à tout moment jusqu’au dernier jour de la période en cours. 
                    En tout état de cause, la dénonciation de l’Abonnement s’effectue par email ou sur l’Application (si proposé) et prend effet à la fin de la période d’Abonnement pendant laquelle la demande est envoyée.
                    Toute période entamée est intégralement due. 
                    L’Utilisateur reconnaît et accepte que l’Application ne fournit que les moyens techniques nécessaires à la souscription du Programme mais que Hustle-Up ne saurait en aucun cas être considérée comme le titulaire ou le responsable du Programme. Les Utilisateurs contractualisent directement entre eux s’agissant des relations relatives au Programme et à son exécution.
                    L’Application permet également à l’Utilisateur de s’inscrire à des cours et séances qu’il souhaite auprès d’une Box (CrossFit), dans la limite des places disponibles.
                    L’Utilisateur peut s’inscrire à ces séances auprès d’une Box selon différentes modalités :
                    <ul><li>Souscription d’une formule d’Abonnement dont les modalités sont indiquées sur l’Application et permettant à l’Utilisateur de s’inscrire à toutes les séances de son choix de son choix selon les propositions de la Box ;</li><li>Achat d’un pack, permettant à l’Utilisateur de participer à un nombre déterminé de séances, selon les modalités proposées par la Box (ci-après : le «<strong>Pack</strong>»). La durée de validité et le nombre de séances compris dans le Pack sont indiqués sur l’Application.</li></ul>
                    L’Utilisateur dispose également de la faculté d’acquérir certains produits mis en vente par une Box sur l’Application. Les modalités de commande ces produits sont indiquées sur l’Application. Les conditions de vente sont propres à l’Utilisateur qui commercialise lesdits produits.
                    L’Utilisateur est invité à se rapprocher de l’exploitant de la Box afin de déterminer les modalités d’annulation d’une séance, de report et de remboursement éventuels.`,
                  },
                ],
              },
              {
                index: 2,
                title: "Dispositions spécifiques à la création de Programme",
                content: `Tout Utilisateur dispose de la faculté de créer un Programme sur l’Application, qu’il peut décider de rendre accessible ou non aux autres membres.
            S’il souhaite monétiser son Programme, il reconnaît et accepte que les dispositions de l’article 5 des présentes s’appliqueront de plein droit. 
            Chaque Programme peut être personnalisé, tant sur le contenu que sur le public visé. 
            L’Utilisateur peut définir une limite de personnes susceptibles de bénéficier de son Programme. 
            Le Programme sera proposé à l’unité ou sur un format d’Abonnement.
            Il appartient à l’Utilisateur créateur d’un Programme, en qualité de coach sportif, de définir les modalités d’utilisation et de vente de son Programme, en qualité d’exploitant du responsable. Il garantit Hustle-Up contre tout recours à ce titre et notamment contre toute difficulté pouvant naître avec un Utilisateur souscripteur. `,
              },
              {
                index: 3,
                title: "Dispositions spécifiques à la création d’une Box (CrossFit)",
                content: `Pour toute création d’une Box, Hustle-Up se réserve la possibilité de solliciter des informations complémentaires sur l’Utilisateur : numéro SIRENE, photo de profil, photographies de la salle de sport et du vestiaire, coordonnées de contact etc. 
            Une interface dédiée à la gestion d’une Box pourra être ouverte à l’Utilisateur ainsi autorisé par Hustle-Up.
            Si accepté par Hustle-Up, l’Utilisateur titulaire d’une Box pourra gérer le planning de sa salle, bénéficier d’un CRM intégré sur l’Application, gérer ses autorisations et accès (coachs, comptabilité, etc.), accéder à des ratios financiers etc. 
            Le titulaire de la Box pourra proposer aux Utilisateurs, sous sa propre responsabilité : 
            <ul><li>La Souscription d’une formule d’Abonnement ;</li><li>L’Achat d’un Pack.</li></ul>
            L’Utilisateur reste entièrement responsable des services et produits proposés dans le cadre de sa Box et il garantit Hustle-Up contre tout recours d’un Utilisateur à ce titre.`,
              },
              {
                index: 4,
                title: "Dispositions spécifiques à la création d’évènements",
                content: `Tout Utilisateur dispose de la possibilité de créer un évènement sur l’Application.	 
            Il peut ainsi s’agir d’une compétition sportive à l’attention des autres membres de l’Application, qui se déroulera en physique ou virtuel. 
            Certaines informations spécifiques pourront être sollicitées par Hustle-Up auprès du créateur de l’évènement.
            Ainsi, il aura la possibilité de définir les conditions financières applicables à son évènement, selon les modalités de l’article 5 des présentes. 
            Le créateur de l’évènement reste entièrement responsable des modalités de gestion, d’inscription et de déroulement des évènements.`,
              },
            ],
          },
          {
            index: 2,
            title: "Autres Services",
            content: `Hustle-Up se réserve la possibilité de proposer tout autre Service qu’elle jugera utile, sous une forme et selon les fonctionnalités et moyens techniques qu’elle estimera les plus appropriés pour rendre lesdits Services.`,
          },
        ],
      },
      {
        index: 7,
        title: "Conditions financières",
        items: [
          {
            index: 1,
            title: "Prix",
            content: `L’inscription sur l’Application est gratuite. 
            Les prix des Abonnements ou des Packs proposés par les Utilisateurs (Programmes ou Box) sont indiqués sur l’Application. Sauf mention contraire, ils sont exprimés en Euros et toutes taxes françaises comprises.
            Pour toute création d’une Box, Hustle-Up appliquera des frais de services s’il apparaît que l’exploitant de ladite Box est externe au réseau CrossFit. A cet effet, Hustle-Up disposera de plusieurs outils techniques et notamment d’une API afin de vérifier l’affiliation dudit exploitant. A défaut, les frais appliqués seront indiqués sur l’Application. `,
          },
          {
            index: 2,
            title: "Facturation et modalités de paiement",
            content: `Les Services payants feront l’objet d’une facture qui sera adressée à l’Utilisateur par tous moyens.
            Le paiement du prix s’effectue en ligne, par carte bancaire ou par virement, à travers le service de paiement sécurisé en ligne STRIPE ou par tout autre moyen qui sera proposé sur l’Application.
            L’Utilisateur garantit à Hustle-Up qu'il dispose des autorisations nécessaires pour utiliser le mode de paiement choisi.`,
          },
          {
            index: 3,
            title: "Retards et incidents de paiement",
            content: `L’Utilisateur est informé et accepte expressément que tout retard de paiement de tout ou partie d’une somme due à son échéance entraînera automatiquement, sans préjudice des dispositions de l’article « Sanction des manquements » et dès la première présentation d’une mise en demeure par lettre recommandée avec accusé de réception :
            &nbsp&nbsp&nbsp(i) la déchéance du terme de l’ensemble des sommes dues par l’Utilisateur et leur exigibilité immédiate ;
            &nbsp&nbsp&nbsp(ii) la suspension immédiate des Services en cours jusqu’au complet paiement de l’intégralité des sommes dues par l’Utilisateur ;
            &nbsp&nbsp&nbsp(iii) la facturation au profit de Hustle-Up d’un intérêt de retard au taux de 1,5 fois (une fois et demie) le taux de l’intérêt légal, assis sur le montant de l’intégralité des sommes dues par l’Utilisateur`,
          },
        ],
      },
      {
        index: 8,
        title: "Absence de droit de rétractation",
        content: `L’Utilisateur est expressément informé et accepte que, dans la mesure où les Services portent sur des activités de loisir qui doivent être fournies à une date déterminée, le droit de rétractation ne peut être exercé conformément à l’article L.221-28 du Code de la consommation.`,
      },
      {
        index: 9,
        title: "Convention de preuve",
        content: `L’Utilisateur reconnaît et accepte expressément :
        &nbsp&nbsp&nbsp(i) que les données recueillies sur l’Application et les équipements informatiques de Hustle-Up font foi de la réalité des opérations intervenues dans le cadre des présentes,
        &nbsp&nbsp&nbsp(ii) que ces données constituent le principal mode de preuve admis entre les parties, notamment pour le calcul des sommes dues à Hustle-Up.
        L’Utilisateur peut accéder à ces données dans son Espace Personnel ou en contactant Hustle-Up aux coordonnées indiquées à l’article « Exploitant de l’Application et des Services, contact ».
        `,
      },
      {
        index: 10,
        title: "Obligations de l’Utilisateur",
        content: `Sans préjudice des autres obligations prévues aux présentes, l’Utilisateur s’engage à respecter les obligations qui suivent.`,
        items: [
          {
            index: 1,
            content: `L’Utilisateur est seul responsable des Programmes et séances auxquels il choisit de participer. Il lui appartient de s’assurer que son état de santé lui permet de pratiquer la discipline concernée, en recueillant, le cas échéant, un avis médical préalable.
            Il lui appartient par ailleurs de signaler au coach, à la Box, avant le début d’une séance ou d’un Programme, toute information nécessaire en lien avec son état de santé, telles que, sans que cette liste soit limitative : grossesse, blessure, opération chirurgicale etc.
            Il lui appartient enfin de veiller, pendant la séance ou le Programme, à respecter ses propres limites de façon à ne pas se blesser.
            La responsabilité de Hustle-Up ne saurait en aucun cas être engagée dans l’hypothèse où l’Utilisateur souffrirait d’un quelconque dommage corporel survenu pendant une séance, un Programme ou postérieurement.`,
          },
          {
            index: 2,
            content: `Les Utilisateurs sont en outre seuls responsables du bon accomplissement de toutes les formalités notamment administratives, fiscales et/ou sociales et de tous les paiements de cotisations, taxes ou impôts de toutes natures qui leur incombent le cas échéant en relation avec leur utilisation des Services et de l’Application.`,
          },
          {
            index: 3,
            content: `L’Utilisateur reconnaît et accepte que l’Application ne saurait en aucun cas être assimilée, en elle-même à un dispositif sportif, de santé, de formation ou tout autre qualification similaire. Hustle-Up agit uniquement à titre d’exploitant de l’Application permettant aux Utilisateurs d’entrer en contact avec d’autres Utilisateurs et de se rapprocher de professionnels sur l’Application, intégralement responsables des contenus ou entraînements proposés.`,
          },
          {
            index: 4,
            content: `L’Utilisateur doit avoir souscrit à une assurance responsabilité civile. Il s’engage à s’assurer que celle-ci est en vigueur au jour de l’inscription sur l’Application ainsi que durant toute la durée des Services.`,
          },
          {
            index: 5,
            content: `L’Utilisateur s’engage à faire un usage strictement personnel des Services et de son Compte. Il s’interdit en conséquence de céder, concéder ou transférer tout ou partie de ses droits ou obligations au titre des présentes à un tiers, de quelque manière que ce soit.
            Il s’interdit ainsi de permettre à un tiers de participer à un Programme ou à une séance à sa place.
            L’Utilisateur est informé et accepte que Hustle-Up puisse prendre toutes les mesures nécessaires pour prévenir tout abus par un Utilisateur dans le cadre de l’utilisation des Services et de son Compte.`,
          },
          {
            index: 6,
            content: `L’Utilisateur s’engage à ne pas entraver le bon déroulement d’un Programme ou d’une séance par tout comportement qui ne serait pas approprié. 
             L’Utilisateur s’engage, dans ses échanges avec les autres Utilisateurs, à respecter les règles usuelles de politesse et de courtoisie.`,
          },
          {
            index: 7,
            content: `L’Utilisateur s’engage à ne créer qu’un seul Compte. 
            Dans l’hypothèse où l’Utilisateur créerait plusieurs Comptes, Hustle-Up aura la faculté (i) de supprimer tous les Comptes en surplus ou (ii) de mettre un terme aux Services dans les conditions visées à l’article « Sanctions des manquements ».`,
          },
          {
            index: 8,
            content: `L’Utilisateur s’engage, dans son usage des Services, à respecter les lois et règlements en vigueur et à ne pas porter atteinte aux droits de tiers ou à l’ordre public.`,
          },
          {
            index: 9,
            content: `L’Utilisateur reconnaît avoir pris connaissance sur l’Application des caractéristiques et contraintes, notamment techniques, de l’ensemble des Services. Il est seul responsable de son utilisation des Services.`,
          },
          {
            index: 10,
            content: `L’Utilisateur reconnaît et accepte que Hustle-Up est expressément autorisée à diffuser le nom et la marque CrossFit® conformément à des accords commerciaux régularisés entre elle et CrossFit LLC. Les présentes ne sauraient être interprétées à quelque titre que ce soit comme une autorisation d’utilisation ou une licence consentie à l’Utilisateur sur la marque ou le nom CrossFit®. En conséquence, le Client s’interdit tout usage, temporaire ou non, à ce titre et garantit Hustle-Up contre tous recours de tiers.`,
          },

          {
            index: 11,
            content: `L’Utilisateur s’engage à fournir à Hustle-Up toutes les informations nécessaires à la bonne exécution des Services. Plus généralement l’Utilisateur s’engage à coopérer activement avec Hustle-Up en vue de la bonne exécution des présentes.`,
          },
          {
            index: 12,
            content: `L’Utilisateur reconnaît que les Services lui offrent une solution de réservation de Programme ou de séances de sport et que cette solution ne saurait se substituer aux autres moyens dont l’Utilisateur peut disposer par ailleurs pour atteindre le même objectif. L’Utilisateur reconnaît et accepte que Hustle-Up ne saurait en aucun cas être considérée comme un coach de sport, une salle de sport ou un professionnel de santé et qu’il lui appartient de se rapprocher de l’exploitant d’une Box ou d’un Programme pour toute question relative à un Abonnement ou à un Pack. La responsabilité de Hustle-Up ne saurait en aucun cas être recherchée au regard du contenu des Programmes ou séances de sport proposés sur l’Application, celle-ci permet uniquement aux Utilisateurs d’entrer en relation et leur fournit les moyens techniques nécessaires à cet effet.`,
          },
          {
            index: 13,
            content: `L’Utilisateur est informé et accepte que la mise en œuvre des Services nécessite qu’il soit connecté à internet et que la qualité des Services dépend directement de cette connexion, dont il est seul responsable.`,
          },
        ],
      },
      {
        index: 11,
        title: "Garantie de l’Utilisateur",
        content: `L’Utilisateur garantit Hustle-Up contre toutes plaintes, réclamations, actions et/ou revendications quelconques que Hustle-Up pourrait subir du fait de la violation, par l’Utilisateur de l’une quelconque de ses obligations ou garanties aux termes des présentes conditions générales. Il s’engage à indemniser Hustle-Up de tout préjudice qu’elle subirait et à lui payer tous les frais, charges et/ou condamnations qu’elle pourrait avoir à supporter de ce fait.`,
      },
      {
        index: 12,
        title: "Comportements prohibés",
        items: [
          {
            index: 1,
            content: `Il est strictement interdit d’utiliser les Services aux fins suivantes : 
            <ul><li>l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la sécurité des tiers,</li><li>l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,</li><li>l’intrusion dans le système informatique d’un tiers ou toute activité de nature à nuire, contrôler, interférer, ou intercepter tout ou partie du système informatique d’un tiers, en violer l’intégrité ou la sécurité,</li><li>l’envoi d’emails non sollicités et/ou de prospection ou sollicitation commerciale,</li><li>les manipulations destinées à améliorer le référencement d’un site tiers,</li><li>l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,</li><li>et plus généralement toute pratique détournant les Services à des fins autres que celles pour lesquelles ils ont été conçus.</li></ul>`,
          },
          {
            index: 2,
            content: `Il est strictement interdit aux Utilisateurs de copier et/ou de détourner à leurs fins ou à celles de tiers le concept, les technologies, tout ou partie des données ou tout autre élément de l’Application de Hustle-Up.`,
          },
          {
            index: 3,
            content: `Sont également strictement interdits : (i) tous comportements de nature à interrompre, suspendre, ralentir ou empêcher la continuité des Services, (ii) toutes intrusions ou tentatives d’intrusions dans les systèmes de Hustle-Up, (iii) tous détournements des ressources système de l’Application, (iv) toutes actions de nature à imposer une charge disproportionnée sur les infrastructures de ce dernier, (v) toutes atteintes aux mesures de sécurité et d’authentification, (vi) tous actes de nature à porter atteinte aux droits et intérêts financiers, commerciaux ou moraux de Hustle-Up ou des usagers de son Application, et enfin plus généralement (vii) tout manquement aux présentes conditions générales.`,
          },
          {
            index: 4,
            content: `Il est strictement interdit de monnayer, vendre ou concéder tout ou partie de l’accès aux Services ou à l’Application, ainsi qu’aux informations qui y sont hébergées et/ou partagées.`,
          },
        ],
      },
      {
        index: 13,
        title: "Sanctions des manquements",
        content: `En cas de manquement à l’une quelconque des dispositions des présentes Conditions Générales ou plus généralement, d’infraction aux lois et règlements en vigueur par un Utilisateur, Hustle-Up se réserve le droit de prendre toute mesure appropriée et notamment de :
        &nbsp&nbsp&nbsp(i) suspendre l’accès aux Services de l’Utilisateur, auteur du manquement ou de l’infraction, ou y ayant participé,
        &nbsp&nbsp&nbsp(ii) publier sur l’Application tout message d’information que Hustle-Up jugera utile,
        &nbsp&nbsp&nbsp(iii) avertir toute autorité concernée,
        &nbsp&nbsp&nbsp(iv) engager toute action judiciaire.
        En cas de manquement de l’Utilisateur à une obligation essentielle découlant des présentes conditions générales, Hustle-Up se réserve le droit de résoudre son accès à tout ou partie des Services, avec effet immédiat, par lettre ou email. La résolution prend effet de plein droit à la date d’envoi, par Hustle-Up, de l’écrit adressé à l’Utilisateur en application de la présente clause. Elle entraîne automatiquement et sans mise en demeure préalable la suppression du Compte de l’Utilisateur, sans préjudice des autres conséquences éventuellement induites en application des présentes conditions générales.
        Sont notamment considérées comme des obligations essentielles le paiement des Services et le comportement adéquat lors de l’utilisation de l’Application.`,
      },
      {
        index: 14,
        title: "Responsabilité et garantie de Hustle-Up",
        items: [
          {
            index: 1,
            content: `Hustle-Up s’engage à fournir les Services avec diligence et selon les règles de l’art, étant précisé qu’il pèse sur elle une obligation de moyens, à l’exclusion de toute obligation de résultat, ce que les Utilisateurs reconnaissent et acceptent expressément.
            A ce titre, Hustle-Up n’effectue aucun contrôle sur la sélection des Utilisateurs ayant la qualité de coach et ne peut garantir aux Utilisateurs que les Programmes proposés par ceux-ci correspondront parfaitement aux attentes d’un Utilisateur.`,
          },
          {
            index: 2,
            content: `Hustle-Up s’engage à procéder régulièrement à des contrôles afin de vérifier le fonctionnement et l’accessibilité de l’Application. A ce titre, Hustle-Up se réserve la faculté d’interrompre momentanément l’accès à l’Application pour des raisons de maintenance. De même, Hustle-Up ne saurait être tenue responsable des difficultés ou impossibilités momentanées d’accès à l’Application qui auraient pour origine des circonstances qui lui sont extérieures, la force majeure, ou encore qui seraient dues à des perturbations des réseaux de télécommunication.`,
          },
          {
            index: 3,
            content: `Hustle-Up ne garantit pas aux Utilisateurs (i) que les Services, soumis à une recherche constante pour en améliorer notamment la performance et le progrès, seront totalement exempts d’erreurs, de vices ou défauts, (ii) que les Services, étant standard et nullement proposés à la seule intention d’un Utilisateur donné en fonction de ses propres contraintes personnelles, répondront spécifiquement à ses besoins et attentes.`,
          },
          {
            index: 4,
            content: `Il est souligné que la responsabilité de Hustle-Up ne pourra en aucun cas être recherchée ou engagée en cas de dommage corporel de quelque nature que ce soit subi par l’Utilisateur à raison de l’utilisation de l’Application. L’Utilisateur reste seul et unique responsable de son usage de l’Application et des liens qu’il décide d’entretenir avec les autres Utilisateurs, Hustle-Up n’exerce aucun contrôle ni modération à cet égard.
            L’Utilisateur renonce expressément à demander réparation à Hustle-Up à quelque titre que ce soit, de dommages indirects, et notamment le manque à gagner, la perte de chance, le préjudice commercial ou financier, l’augmentation de frais généraux ou les pertes trouvant leur origine ou étant la conséquence de l’exécution des présentes.`,
          },
          {
            index: 5,
            content: `En tout état de cause, la responsabilité susceptible d’être encourue par Hustle-Up au titre des présentes est expressément limitée aux seuls dommages directs avérés subis par l’Utilisateur.`,
          },
        ],
      },
      {
        index: 15,
        title: "Propriété intellectuelle de Hustle-Up",
        content: `Les systèmes, logiciels, structures, infrastructures, bases de données et contenus de toute nature (textes, articles, images, visuels, musiques, logos, vidéos marques, base de données, etc …) exploités par Hustle-Up au sein de l’Application sont protégés par tous droits de propriété intellectuelle ou droits des producteurs de bases de données en vigueur. Tous désassemblages, décompilations, décryptages, extractions, réutilisations, copies et plus généralement, tous actes de reproduction, représentation, diffusion et utilisation de l’un quelconque de ces éléments, en tout ou partie, sans l’autorisation de Hustle-Up sont strictement interdits et pourront faire l’objet de poursuites judiciaires.
        A titre de précision, les Utilisateurs ne sont pas autorisés à télécharger ou partager les contenus mis à disposition par Hustle-Up ou d’autres Utilisateurs sur l’Application. Hustle-Up est seul titulaire des droits de propriété intellectuelle attachés aux contenus mis à disposition des Utilisateurs sur l’Application.  
        Par ailleurs, les Utilisateurs garantissent Hustle-Up : 
        (i)  qu’ils disposent de tous les droits et autorisations nécessaires à l’utilisation et/ou à la diffusion des contenus de toute nature (rédactionnels, graphiques, photographiques, logo, marque ou autre) dans le cadre de l’utilisation des Services et de l’Application. Ils s’engagent à ce que lesdits éléments soient licites, ne portent pas atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers, n’enfreignent aucune disposition législative ou règlementaire et plus généralement, ne soient aucunement susceptibles de mettre en jeu la responsabilité civile ou pénale de Hustle-Up.
        Les Utilisateurs s’interdisent ainsi de diffuser, notamment et sans que cette liste soit exhaustive :
        <ul><li>des contenus pédopornographiques, obscènes, indécents, choquants, diffamatoires, injurieux, incitant à la haine ou à la violence, racistes, xénophobes, révisionnistes ou faisant l’apologie du terrorisme ;</li><li>des contenus contrefaisants ;</li><li>des contenus attentatoires à l’image d’un tiers ;</li><li>des contenus mensongers, trompeurs ou proposant ou promouvant des activités illicites, frauduleuses ou trompeuses ;</li><li>des contenus nuisibles aux systèmes informatiques de tiers (tels que virus, vers, chevaux de Troie, etc.) ;</li><li>et plus généralement tout contenu susceptible de porter atteinte aux droits de tiers ou d’être préjudiciable à des tiers, de quelque manière et sous quelque forme que ce soit.</li></ul>
        Dans ce cadre, les Utilisateurs garantissent Hustle-Up contre toute plainte, revendication et/ou action d’un ou plusieurs autres Utilisateurs et/ou de tout tiers soutenant que les éléments constitueraient une violation de ses droits, quels qu’ils soient et notamment ses droits de propriété intellectuelle, droit à l’image et à la protection de la vie privée. En conséquence, l’Utilisateur concerné s’engage à indemniser Hustle-Up de tout préjudice qu’elle subirait et à prendre à sa charge tous les dommages-intérêts, ainsi que les frais, charges et dépens auxquels Hustle-Up pourrait être condamnée, ou qui seraient prévus par un accord transactionnel signé par Hustle-Up après avoir obtenu l’accord préalable de l’Utilisateur.
        (ii)  avoir pris connaissance des caractéristiques et contraintes, notamment techniques, de l’ensemble des Services sur l’Application.`,
      },
      {
        index: 16,
        title: "Autorisation de diffusion des témoignages",
        content: `Pendant la durée de leur utilisation des Services, les Utilisateurs autorisent Hustle-Up à utiliser leurs profils (ci-après : les «<strong>Profils</strong>») et les témoignages qu’ils publient sur l’Application (ci-après : les «<strong>Témoignages</strong>») pour la promotion de ceux-ci, selon les modalités suivantes :
        <ul><li>Ils consentent à ce que leurs Témoignages et Profils soient diffusés à titre gracieux par Hustle-Up sur l’Application et sur tous autres sites internet français ou étrangers, édités par toutes sociétés avec lesquelles Hustle-Up a des accords,</li><li>Ils consentent à ce que leurs Témoignages et Profils soient diffusés par Hustle-Up par tout moyen et sur tout support aux fins de promotion de l’Application,</li><li>Ils acceptent que leurs Témoignages et Profils soient traduits en toutes langues,</li><li>Ils reconnaissent et acceptent que les Témoignages et Profils pourront faire l’objet de modifications, notamment quant à leur cadrage, leur format et leurs couleurs, ainsi que d’altérations ou de dégradations dans leur qualité, en fonction des contraintes techniques de l’Application,</li><li>Ils renoncent à demander à Hustle-Up une quelconque rémunération, redevance, indemnité ou compensation financière à ce titre.</li></ul>`,
      },
      {
        index: 17,
        title: "Différends entre Utilisateurs",
        content: `En cas d’acte portant préjudice à un Utilisateur commis par un autre Utilisateur dans le cadre des Services, l’Utilisateur pourra signaler ledit comportement à Hustle-Up par courrier postal ou par courriel aux coordonnées renseignées à l’article « Exploitant du Site et de l’Application », après avoir préalablement recherché une solution amiable.
        Le signalement doit impérativement comporter la date de sa notification, l’identité du plaignant (nom, prénom, profession, domicile, nationalité, date et lieu de naissance), l’identifiant de l’Utilisateur à l’origine du préjudice, la description des faits litigieux accompagné le cas échéant d’un lien vers la page web permettant d’en rapporter la preuve, ainsi que la copie de la correspondance adressée à l’auteur de l’atteinte et recherchant une solution amiable.
        Hustle-Up aura la libre faculté de prendre toutes mesures appropriées, sans aucun engagement de sa part et/ou de transmettre cette plainte aux autorités compétentes. Notamment, Hustle-Up aura la faculté de prévenir toute personne en cas de différend.
        Les Utilisateurs feront leur affaire personnelle des actions qu’ils pourraient introduire devant toute juridiction contre un autre Utilisateur en raison du préjudice subi de son fait.`,
      },
      {
        index: 18,
        title: "Indépendance des parties",
        content: `Il est expressément convenu qu’aucune des parties ne pourra se réclamer des dispositions des présentes pour revendiquer, en aucune manière, la qualité d’agent, de représentant ou d’employé de l’autre partie, ni engager l’autre partie à l’égard de tiers, au-delà des Services prévus par les dispositions des présentes.
        Aux termes des présentes, il n’est pas formé de structure juridique particulière entre les parties, chacune conservant son entière autonomie, ses responsabilités et sa propre clientèle.`,
      },
      {
        index: 19,
        title: "Données à caractère personnel",
        content: `Hustle-Up pratique une politique de protection des données personnelles dont les caractéristiques sont explicitées dans le document intitulé « <i>Charte relative à la protection des données à caractère personnel</i> », dont l’Utilisateur est expressément invité à prendre connaissance.`,
      },
      {
        index: 20,
        title: "Publicité, liens et sites tiers",
        content: `Hustle-Up se réserve la faculté d’insérer sur toute page de l’Application et dans toute communication aux Utilisateurs tous messages publicitaires ou promotionnels sous une forme et dans des conditions dont Hustle-Up sera seule juge.
        Hustle-Up n'endosse aucune responsabilité au titre des contenus, publicités, produits et/ou services disponibles sur de tels sites et applications mobiles tiers dont il est rappelé qu’ils sont régis par leurs propres conditions d’utilisation.
        Hustle-Up n'est pas non plus responsable des transactions intervenues entre l’Utilisateur et un quelconque annonceur, professionnel ou commerçant (y compris ses éventuels partenaires) vers lequel l’Utilisateur serait orienté par l'intermédiaire de l’Application et ne saurait en aucun cas être partie à quelques litiges éventuels que ce soit avec ces tiers concernant notamment la livraison de produits et/ou services, les garanties, déclarations et autres obligations quelconques auxquelles ces tiers sont tenus.`,
      },
      {
        index: 21,
        title: "Modifications",
        content: `Hustle-Up se réserve la faculté de modifier à tout moment les présentes Conditions Générales. 
        L’Utilisateur sera informé de ces modifications par tout moyen utile.
        L’Utilisateur qui n’accepte pas les Conditions Générales modifiées doit se désinscrire et ne pas utiliser l’Application et les Services.
        Tout Utilisateur qui a recours aux Services postérieurement à l’entrée en vigueur des conditions générales modifiées est réputé avoir accepté ces modifications.`,
      },
      {
        index: 22,
        title: "Langue",
        content: `Dans l’hypothèse d’une traduction des présentes conditions générales dans une ou plusieurs langues, la langue d’interprétation sera la langue française en cas de contradiction ou de contestation sur la signification d’un terme ou d’une disposition.`,
      },
      {
        index: 23,
        title: "Désinscription",
        content: `L’Utilisateur peut se désinscrire de l’Application à tout moment, en adressant une demande à cet effet à Hustle-Up par email, aux coordonnées mentionnées ci-dessous ou via l’Application directement. Cette désinscription sera effective dans les meilleurs délais.`,
      },
      {
        index: 24,
        title: "Médiation",
        content: `L’Utilisateur ayant la qualité de consommateur au sens du Code de la consommation dispose du droit de recourir gratuitement à un médiateur de la consommation en vue de la résolution amiable de tout litige portant sur l’exécution des présentes qui l'opposerait à Hustle-Up, dans les conditions prévues aux articles L611-1 et suivants et R612-1 et suivants du Code de la consommation.
        Il peut contacter à cette fin le médiateur de la consommation suivant :
        Centre de médiation de la consommation de conciliateurs de justice (CM2C)
        Adresse postale : 14 rue Saint Jean 75017 Paris
        <a href="https://www.cm2c.net" target="_blank">https://www.cm2c.net</a>`,
      },
      {
        index: 25,
        title: "Loi applicable et juridiction",
        content: `Les présentes conditions générales sont régies par la loi française.
        En cas de contestation sur la validité, l’interprétation et/ou l’exécution des présentes conditions générales, les parties conviennent que les tribunaux de Lyon seront exclusivement compétents pour en juger, sauf règles de procédure impératives contraires.`,
      },
    ],
  },
}
