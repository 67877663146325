import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BoxOfferPlanIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none" {...props}>
    <path
      d="M3.45981 26.4331C2.93788 25.4596 3.30393 24.2473 4.27741 23.7254L29.4379 10.2356C30.4113 9.71369 31.6236 10.0797 32.1455 11.0532L40.5255 26.6831C41.0474 27.6566 40.6814 28.8689 39.7079 29.3908L14.5474 42.8805C13.574 43.4025 12.3617 43.0364 11.8398 42.0629L3.45981 26.4331Z"
      fill="url(#paint0_radial_7997_96450)"
    />
    <path
      d="M32.4524 8.35303L33.0528 14.2944L37.1697 15.1609L33.0528 16.0274L32.4524 21.9688L31.852 16.0274L27.7351 15.1609L31.852 14.2944L32.4524 8.35303Z"
      fill="white"
    />
    <path
      d="M38.9435 3.0147L37.2834 6.3451L40.0612 8.24079L37.0309 6.78231L34.976 9.88474L36.6361 6.55434L33.8583 4.65865L36.8886 6.11713L38.9435 3.0147Z"
      fill="white"
    />
    <path
      d="M23.2756 24.7041H24.8338C26.4361 24.7041 27.735 26.2035 27.735 28.0532C27.735 29.9029 26.4361 31.4023 24.8338 31.4023H23.2756"
      stroke="white"
    />
    <path
      d="M25.6955 28.0504C25.6955 29.0163 25.4001 29.8716 24.9463 30.4743C24.4927 31.0768 23.9036 31.4036 23.2943 31.4036C22.685 31.4036 22.0959 31.0768 21.6422 30.4743C21.1884 29.8716 20.8931 29.0163 20.8931 28.0504C20.8931 27.0846 21.1884 26.2293 21.6422 25.6266C22.0959 25.0241 22.685 24.6973 23.2943 24.6973C23.9036 24.6973 24.4927 25.0241 24.9463 25.6266C25.4001 26.2293 25.6955 27.0846 25.6955 28.0504Z"
      stroke="white"
    />
    <rect
      x="16.8009"
      y="27.8009"
      width="5.82335"
      height="0.679977"
      rx="0.339989"
      fill="#3B3184"
      stroke="white"
      strokeWidth="0.679977"
    />
    <path
      d="M12.0591 24.6807H13.7863C15.3886 24.6807 16.6876 26.1882 16.6876 28.0479C16.6876 29.9075 15.3886 31.4151 13.7863 31.4151H12.0591"
      stroke="white"
    />
    <path
      d="M14.5239 28.0504C14.5239 29.0163 14.2285 29.8716 13.7747 30.4743C13.321 31.0768 12.732 31.4036 12.1227 31.4036C11.5133 31.4036 10.9243 31.0768 10.4706 30.4743C10.0168 29.8716 9.72144 29.0163 9.72144 28.0504C9.72144 27.0846 10.0168 26.2293 10.4706 25.6266C10.9243 25.0241 11.5133 24.6973 12.1227 24.6973C12.732 24.6973 13.321 25.0241 13.7747 25.6266C14.2285 26.2293 14.5239 27.0846 14.5239 28.0504Z"
      stroke="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_7997_96450"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.62879 19.5221) rotate(30.4342) scale(41.7165 49.2516)"
      >
        <stop offset="0.5" stopColor="#6450F7" />
        <stop offset="0.5" stopColor="#3B2F91" />
      </radialGradient>
    </defs>
  </svg>
)
