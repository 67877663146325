import appIcon from "@images/appIcon.svg"
import { CrossIcon } from "@images/svgIcons/crossIcon"
import { Config } from "@modules/config"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { useObservable } from "micro-observables"
import { Button } from "primereact/button"
import React from "react"
import { Link } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const AppBanner: React.FC = () => {
  const theme = useTheme()
  const { appBannerService } = useServicesContext()
  const hideBanner = useObservable(appBannerService.visibility).hidden
  const isAndroid = navigator.userAgent.match(/Android/i)

  return !hideBanner && isAndroid ? (
    <Container>
      <CrossIcon onClick={() => appBannerService.hideBanner()} color="#B3B4B1" />
      <AppIcon src={appIcon} />
      <TextContainer>
        <Title color={theme.neutralColor900} fontStyle="HL">
          {word("app_banner.title")}
        </Title>
        <HUText color="#959693" fontStyle="BM">
          {word("app_banner.subtitle")}
        </HUText>
      </TextContainer>
      <Spacer />
      <Link to={Config.APP_BANNER_URL}>
        <ButtonStyled rounded>{word("app_banner.button")}</ButtonStyled>
      </Link>
    </Container>
  ) : null
}

const Container = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.mainWhite};
  border-bottom: 1px solid #f2f2f2;
`

const AppIcon = styled.img`
  margin: 0 14px;
  width: 64px;
  height: 64px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled(HUText)`
  font-size: 16px;
  line-height: 23px;
`

const Spacer = styled.div`
  flex: 1;
`

const ButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.primaryColor};
`
