import { BoxProgrammationVisibility } from "@modules/boxProgrammation/boxProgrammationTypes"
import {
  BoxProgrammationColorType,
  boxProgrammationColors,
} from "@modules/boxProgrammation/components/boxProgrammationColors"
import { word } from "@modules/core/utils/i18n"
import { HUText } from "@modules/ui/components/huText"
import { HUStack } from "@modules/ui/components/layout"

export const DEFAULT_BOX_PROGRAMMATION_COLOR: BoxProgrammationColorType = "blue500"

export const getBoxProgrammationColorName = (type: BoxProgrammationColorType) => {
  switch (type) {
    case "purple500":
      return word("programmation.color.purple500")
    case "purple200":
      return word("programmation.color.purple200")
    case "blue500":
      return word("programmation.color.blue500")
    case "blue200":
      return word("programmation.color.blue200")
    case "green500":
      return word("programmation.color.green500")
    case "green200":
      return word("programmation.color.green200")
    case "yellow500":
      return word("programmation.color.yellow500")
    case "yellow200":
      return word("programmation.color.yellow200")
    case "orange500":
      return word("programmation.color.orange500")
    case "orange200":
      return word("programmation.color.orange200")
  }
}

const BoxProgrammationColorByHex = Object.entries(boxProgrammationColors).reduce<
  Record<string, BoxProgrammationColorType>
>((acc, [key, value]) => {
  acc[value.background] = key as BoxProgrammationColorType
  return acc
}, {})

export const getBoxProgrammationColorTypeWithHex = (hex: string): BoxProgrammationColorType => {
  return BoxProgrammationColorByHex[hex] || DEFAULT_BOX_PROGRAMMATION_COLOR
}

export const getBoxProgrammationVisibilityName = (visibility: BoxProgrammationVisibility) => {
  switch (visibility) {
    case BoxProgrammationVisibility.MEMBERS:
      return word("box.programmation.form.access.standard.label")
    case BoxProgrammationVisibility.PRIVATE:
      return word("box.programmation.form.access.private.label")
    case BoxProgrammationVisibility.PUBLIC:
      return word("box.programmation.form.access.public.label")
  }
}

export const getBoxProgrammationVisibilityExplanation = (visibility: BoxProgrammationVisibility) => {
  switch (visibility) {
    case BoxProgrammationVisibility.MEMBERS:
      return (
        <HUStack className="justify-content-between w-full">
          <HUText fontStyle="BS">{word("box.programmation.form.access.standard.explanation1")}</HUText>
          <HUText fontStyle="BS">{word("box.programmation.form.access.standard.explanation2")}</HUText>
        </HUStack>
      )
    case BoxProgrammationVisibility.PRIVATE:
      return (
        <HUStack className="justify-content-center w-full">
          <HUText fontStyle="BS" className="text-center">
            {word("box.programmation.form.access.private.explanation")}
          </HUText>
        </HUStack>
      )

    case BoxProgrammationVisibility.PUBLIC:
      return (
        <HUStack className="justify-content-center w-full">
          <HUText fontStyle="BS" className="text-center">
            {word("box.programmation.form.access.public.explanation")}
          </HUText>
        </HUStack>
      )
  }
}
