import { useBoxProgrammationDefaultSettings } from "@modules/boxProgrammation/hooks/useBoxProgrammations"
import {
  BoxSlotFormikType,
  convertBoxSlotToFormik,
  convertFormikToBoxSlotDraft,
  getBoxSlotValidationSchema,
} from "@modules/boxSlot/boxSlotFormikTypes"
import { BOX_SLOT_PARTICIPANTS_LIMIT_MIN, BoxSlotSession } from "@modules/boxSlot/boxSlotTypes"
import { useBoxSlotSessionScheduled } from "@modules/boxSlot/hooks/useBoxSlotSessionScheduled"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { useToast } from "@modules/ui/components/huToast"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

export const useBoxSlotFormik = (
  boxProgrammationId: string,
  refreshCalendarEvents: () => void,
  slot?: BoxSlotSession,
  startDate?: Date,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { value: defaultSettings, loading: loadingDefaultSettings } =
    useBoxProgrammationDefaultSettings(boxProgrammationId)

  const { value: scheduledSession, loading: loadingScheduledSession } = useBoxSlotSessionScheduled(
    boxProgrammationId,
    startDate ?? null,
  )

  const { boxSlotService } = useServicesContext()
  const toast = useToast()
  const { hideSidebar } = useSidebar()

  const initialSlotFormikValues = (boxSlot?: BoxSlotSession) => {
    const day = dayjs().startOf("day")

    const slotDate = boxSlot?.startDateTime ? dayjs(boxSlot.startDateTime) : undefined
    const stateDate = startDate ? dayjs(startDate) : undefined

    const slotEndDate = boxSlot?.endDateTime ? dayjs(boxSlot.endDateTime) : undefined
    const stateEndDate = startDate ? dayjs(startDate).add(defaultSettings?.duration ?? 60, "minute") : undefined

    const date = slotDate?.toISOString() ?? stateDate?.toISOString() ?? null
    const startDateTime = slotDate ?? stateDate ?? day.set("hour", 8)
    const endDateTime = slotEndDate ?? stateEndDate ?? day.set("hour", 9)

    const validEndDateTime =
      startDateTime.isAfter(endDateTime) || !startDateTime.isSame(endDateTime, "date")
        ? endDateTime.set("hour", 23).set("minute", 59)
        : endDateTime

    return {
      ...convertBoxSlotToFormik({
        date,
        programmationId: boxProgrammationId ?? boxSlot?.programmationId ?? "",
        startDateTime: startDateTime.toISOString(),
        endDateTime: validEndDateTime.toISOString(),
        participantLimit:
          boxSlot?.participantLimit && boxSlot.participantLimit !== 0
            ? boxSlot.participantLimit
            : defaultSettings?.participantLimit
              ? defaultSettings.participantLimit
              : BOX_SLOT_PARTICIPANTS_LIMIT_MIN,
        contentEarlyAccess: !!boxSlot?.contentEarlyAccess,
        location: boxSlot ? boxSlot.location : defaultSettings?.location,
        participation: boxSlot
          ? boxSlot.participation
          : {
              limitStart: defaultSettings?.participation?.limitStart,
              limitEnd: defaultSettings?.participation?.limitEnd,
              limitCancel: defaultSettings?.participation?.limitCancel,
            },
      }),
    }
  }

  const formik = useFormik<BoxSlotFormikType>({
    initialValues: initialSlotFormikValues(slot),
    validationSchema: getBoxSlotValidationSchema(),
    enableReinitialize: true,
    onSubmit: async () => {
      setIsSubmitting(true)
      try {
        if (slot) {
          await boxSlotService.updateSlot(
            slot.id,
            convertFormikToBoxSlotDraft(formik.values),
            slot.sessionId ?? scheduledSession?.id ?? null,
          )
          hideSidebar()
          toast.show({ severity: "success", detail: word("box.slot.form.toast.update.success") })
        } else {
          await boxSlotService.createSlot(convertFormikToBoxSlotDraft(formik.values), scheduledSession?.id ?? null)
          hideSidebar()
          toast.show({ severity: "success", detail: word("box.slot.form.toast.creation.success") })
        }
      } catch (e: any) {
        toast.show({ severity: "error", summary: "Error", detail: e.message })
      } finally {
        refreshCalendarEvents()
        setIsSubmitting(false)
      }
    },
  })

  useEffect(() => {
    // add the created slots (overlayed) to the calendar in background
    boxSlotService.updateBoxSlotsBeingCreated([{ id: slot?.id, ...convertFormikToBoxSlotDraft(formik.values) }])
    return () => {
      // clear the overlayed slots in calendar
      boxSlotService.updateBoxSlotsBeingCreated([])
    }
  }, [formik.values.startDateTime, formik.values.endDateTime])

  return { formik, isSubmitting, isLoading: (loadingScheduledSession && startDate) || loadingDefaultSettings }
}
