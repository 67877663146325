import SearchEmpty from "@images/empty/search_empty.svg"
import ExploreTraining from "@images/exploreTraining.png"
import { BenchmarkInfoResume } from "@modules/benchmark/components/benchmarkInfoResume"
import { BoxInfoResume } from "@modules/box/components/boxInfoResume"
import { word } from "@modules/core/utils/i18n"
import { ExploreCard } from "@modules/explore/components/exploreCard/exploreCard"
import { ExploreContent, ExploreFilter } from "@modules/explore/exploreTypes"
import { useExploreSearchContents } from "@modules/explore/hooks/useExplore"
import { ProfileInfoResume } from "@modules/profile/components/profileInfoResume"
import { ProgramInfoResume } from "@modules/programs/components/programInfoResume"
import { ProgramTypeOf } from "@modules/programs/programTypes"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { EmptyView } from "@modules/ui/components/emptyView"
import { HUButton } from "@modules/ui/components/huButton"
import { HUText } from "@modules/ui/components/huText"
import { LoadingSpinner } from "@modules/ui/components/loading"
import { InputText } from "primereact/inputtext"
import React, { useCallback, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import styled, { useTheme } from "styled-components"

export const ExplorePage: React.FC = () => {
  const theme = useTheme()
  const [searchTerms, setSearchTerms] = useState("")
  const [selectedItem, setSelectedItem] = useState("")
  const [filter, setFilter] = useState<ExploreFilter>(ExploreFilter.DEFAULT)
  const {
    result: exploreContents,
    totalSize,
    loading: loadingExploreContents,
    listMore,
    setSearchTerms: setSearchContentTerms,
  } = useExploreSearchContents(filter)
  const { sidebarNavigateTo } = useSidebar()

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setSearchContentTerms(searchTerms)
    }, 500)
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [searchTerms])

  const returnContent = useCallback(
    (content: ExploreContent, index: number) => {
      let itemContent
      if (content.program) {
        itemContent = content.program
      } else if (content.box) {
        itemContent = content.box
      } else if (content.benchmark) {
        itemContent = content.benchmark
      } else if (content.profile) {
        itemContent = content.profile
      } else {
        throw new Error("No Explore Content find")
      }
      return (
        <ExploreCard
          item={itemContent}
          key={index}
          onViewDetailsClick={onViewDetailsClick}
          isSelected={selectedItem === itemContent.id}
        />
      )
    },
    [exploreContents],
  )

  const onViewDetailsClick = (itemId: string, itemTypeOf: string, programType?: ProgramTypeOf) => {
    setSelectedItem(itemId)
    switch (itemTypeOf) {
      case ExploreFilter.PROGRAM:
        if (programType)
          sidebarNavigateTo({
            content: <ProgramInfoResume programId={itemId} programType={programType} editionAllowed={false} />,
            replaceAllComponents: true,
            onHide: () => {
              setSelectedItem("")
              return Promise.resolve(true)
            },
          })
        break
      case ExploreFilter.BOX:
        sidebarNavigateTo({
          content: <BoxInfoResume boxId={itemId} />,
          replaceAllComponents: true,
          onHide: () => {
            setSelectedItem("")
            return Promise.resolve(true)
          },
        })
        break
      case ExploreFilter.BENCHMARK:
        sidebarNavigateTo({
          content: <BenchmarkInfoResume benchmarkId={itemId} />,
          replaceAllComponents: true,
          onHide: () => {
            setSelectedItem("")
            return Promise.resolve(true)
          },
        })
        break
      case ExploreFilter.PROFILE:
        sidebarNavigateTo({
          content: <ProfileInfoResume userId={itemId} />,
          replaceAllComponents: true,
          onHide: () => {
            setSelectedItem("")
            return Promise.resolve(true)
          },
        })
        break
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <HeaderContainer>
        <ImageContainer>
          <Image src={ExploreTraining} />
        </ImageContainer>
        <span className="p-input-icon-left w-8">
          <i className="pi pi-search" style={{ color: theme.neutralColor900 }} />

          <InputTextCustom
            placeholder={word("searchbar.placeholder")}
            value={searchTerms}
            onChange={(e) => {
              setSearchTerms(e.currentTarget.value)
            }}
          />
        </span>
        <div className="flex flex-wrap justify-content-center mt-2 gap-2">
          <HUButton
            type="Default"
            size="S"
            colorType={filter === ExploreFilter.DEFAULT ? "Primary" : "Quaternary"}
            onClick={() => setFilter(ExploreFilter.DEFAULT)}
            text={word("explore.search.filter.discover")}
          />
          <HUButton
            type="Default"
            size="S"
            colorType={filter === ExploreFilter.PROFILE ? "Primary" : "Quaternary"}
            onClick={() => setFilter(ExploreFilter.PROFILE)}
            text={word("explore.search.filter.user")}
          />
          <HUButton
            type="Default"
            size="S"
            colorType={filter === ExploreFilter.PROGRAM ? "Primary" : "Quaternary"}
            onClick={() => setFilter(ExploreFilter.PROGRAM)}
            text={word("menu.program.detail")}
          />
          <HUButton
            type="Default"
            size="S"
            colorType={filter === ExploreFilter.BENCHMARK ? "Primary" : "Quaternary"}
            onClick={() => setFilter(ExploreFilter.BENCHMARK)}
            text={word("global.benchmark.name")}
          />
          <HUButton
            type="Default"
            size="S"
            colorType={filter === ExploreFilter.BOX ? "Primary" : "Quaternary"}
            onClick={() => setFilter(ExploreFilter.BOX)}
            text={word("global.box.name")}
          />
        </div>
      </HeaderContainer>

      {loadingExploreContents ? (
        <LoadingSpinner />
      ) : (
        <>
          {exploreContents.length > 0 ? (
            <InfiniteScroll
              dataLength={exploreContents.length}
              next={listMore}
              hasMore={exploreContents.length < (totalSize || 0)}
              loader={<HUText fontStyle="BM">{word("global.loading")}</HUText>}
            >
              <CardsContainer>{exploreContents.map((content, i) => returnContent(content, i))}</CardsContainer>
            </InfiniteScroll>
          ) : (
            <EmptyView
              image={{ src: SearchEmpty }}
              title={word("explore.search.no_result.title", { searchTerms })}
              description={word("explore.search.no_result.message")}
              flexGrow={false}
              className="p-6"
              textAlign="left"
              disposition="row-reverse"
              style={{
                backgroundColor: theme.mainWhite,
                display: "flex",
                justifyContent: "center",
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

const HeaderContainer = styled.div`
  padding: 24px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    padding: 12px 16px 12px 16px;
    border-radius: 44px;
  }
  button span span {
    font-size: 0.8rem !important;
  }
`
const InputTextCustom = styled(InputText)`
  background-color: ${({ theme }) => theme.neutralColor100};
  border-radius: 30px;
  padding: 13px, 16px, 13px, 16px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.neutralColor900} !important;
  }
`

const CardsContainer = styled.div`
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
  gap: 20px;
`

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`
const ImageContainer = styled.div`
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  @media screen and (max-width: 460px) {
    margin-top: 15px;
    max-width: 100%;
  }
`
