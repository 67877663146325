import { BoxFormikType, BoxInputKey } from "@modules/box/boxFormikType"
import { BoxFormTemplatePage } from "@modules/box/components/form/boxFormTemplatePage"
import { word } from "@modules/core/utils/i18n"
import { FormikInput } from "@modules/ui/components/formikInput"
import { SimpleFormik } from "@src/typings/formik"

export type BoxFormPageProps = {
  handleSubmit: () => void
  onPrevious: () => void
  formik: SimpleFormik<BoxFormikType, BoxInputKey>
  isSubmitting: boolean
  isFormFieldInValid: (name: BoxInputKey) => boolean
  getFormErrorMessage: (name: BoxInputKey, errorMessage?: string) => JSX.Element | undefined
  disabled: boolean
}

export const BoxFormFirstPage = ({
  handleSubmit,
  onPrevious,
  formik,
  isFormFieldInValid,
  getFormErrorMessage,
  disabled,
}: BoxFormPageProps) => {
  return (
    <BoxFormTemplatePage
      onPrevious={onPrevious}
      handleSubmit={handleSubmit}
      disabled={disabled}
      pageNumber={1}
      header={{
        title: word("box.creation.page.1.title"),
        subtitle: word("box.creation.page.1.subtitle"),
      }}
    >
      <FormikInput
        isRequiredInput
        label={word("box.form.name.label")}
        getFieldProps={formik.getFieldProps}
        isInvalid={isFormFieldInValid("name")}
        name="name"
        error={getFormErrorMessage("name")}
        textProps={{
          className: "w-full",
          onBlur: (e) => {
            if (formik.handleBlur) formik.handleBlur(e, "name")
          },
          onChange: (e) => {
            if (formik.handleChange) formik.handleChange(e, "name")
          },
        }}
      />
    </BoxFormTemplatePage>
  )
}
