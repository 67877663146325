import { BoxSlotTemplateDraft, Weekday } from "@modules/boxSlotTemplate/boxSlotTemplateTypes"
import { word } from "@modules/core/utils/i18n"
import dayjs from "dayjs"

export function generateTimeSlots(
  startTime: Date,
  endTime: Date,
  interval: number,
  participantLimit: number,
  weekDay: Weekday,
): BoxSlotTemplateDraft[] {
  let start = dayjs(startTime)
  const end = dayjs(endTime)
  const slots = []

  if (interval >= 20) {
    while (start < end) {
      const nextTime = start.add(interval, "minute")
      if (nextTime <= end) {
        slots.push({ startTime: start.format("HH:mm"), endTime: nextTime.format("HH:mm"), participantLimit, weekDay })
      }
      start = nextTime
    }
  }

  return slots
}

export function getSlotDurationOptions() {
  return [
    { label: `30 ${word("global.minute.label", { count: 30 })}`, value: "00h 30min" },
    { label: `45 ${word("global.minute.label", { count: 45 })}`, value: "00h 45min" },
    { label: `1 ${word("global.hour.label", { count: 1 })}`, value: "01h 00min" },
    {
      label: `1 ${word("global.hour.label", { count: 1 })} 30${word("global.minute.label", { count: 30 })}`,
      value: "01h 30min",
    },
    { label: `2 ${word("global.hour.label", { count: 2 })}`, value: "02h 00min" },
    { label: word("box.slotTemplateGroup.form.slotDuration.custom"), value: null },
  ]
}
export function getRegistrationBeginningOptions() {
  return [
    { label: word("global.dayPrior.label", { count: 1 }), value: 1 },
    { label: word("global.dayPrior.label", { count: 2 }), value: 2 },
    { label: word("global.dayPrior.label", { count: 3 }), value: 3 },
    { label: word("global.dayPrior.label", { count: 4 }), value: 4 },
    { label: word("global.dayPrior.label", { count: 5 }), value: 5 },
    { label: word("global.dayPrior.label", { count: 6 }), value: 6 },
    { label: word("global.weekPrior.label", { count: 1 }), value: 7 },
    { label: word("global.weekPrior.label", { count: 2 }), value: 14 },
  ]
}
export function getRegistrationEndOptions() {
  return [
    { label: word("global.lastMinutePrior"), value: 1 },
    { label: word("global.minutePrior.label", { count: 30 }), value: 30 },
    { label: word("global.hourPrior.label", { count: 1 }), value: 60 },
    { label: word("global.hourPrior.label", { count: 2 }), value: 120 },
    { label: word("global.hourPrior.label", { count: 4 }), value: 240 },
    { label: word("global.dayPrior.label", { count: 1 }), value: 1440 },
  ]
}
export function getCancellationBeginningOptions() {
  return [
    { label: word("global.lastMinutePrior"), value: 1 },
    { label: word("global.minutePrior.label", { count: 30 }), value: 30 },
    { label: word("global.hourPrior.label", { count: 1 }), value: 60 },
    { label: word("global.hourPrior.label", { count: 2 }), value: 120 },
    { label: word("global.hourPrior.label", { count: 4 }), value: 240 },
    { label: word("global.dayPrior.label", { count: 1 }), value: 1440 },
  ]
}
