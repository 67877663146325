import { AuthService } from "@modules/auth/services/authService"
import { SharedUserApi } from "@modules/auth/services/sharedUserApi"
import { User, UserDraft } from "@modules/auth/user"
import { ServiceManager } from "@modules/core/services/serviceManager"
import { Service } from "@modules/core/services/serviceType"
import { StripeAccountStatus } from "@modules/payment/stripeTypes"
import { LocalStorageKeys } from "@modules/storage/localStorageKeys"
import { LocalStorageService } from "@modules/storage/services/localStorageService"
import { observable } from "micro-observables"

export class SharedUserService implements Service {
  private _user = observable<User | null>(null)
  readonly user = this._user.readOnly()
  private _disconection = false

  constructor(
    protected readonly authService: AuthService,
    protected readonly userApi: SharedUserApi,
    protected readonly localStorage: LocalStorageService,
    protected readonly serviceManager: ServiceManager,
  ) {}

  async init(): Promise<() => void> {
    const unsubscribe = this.localStorage.connect(this._user, LocalStorageKeys.auth.user, null)
    return unsubscribe
  }

  async onAfterInit(): Promise<void> {
    if (this._user.get()) {
      await this.retrieveUser()
    }
  }

  isLoggedIn() {
    return !!this.user.get()
  }

  async login(email: string, password: string) {
    await this.authService.login(email, password)
    await this.retrieveUser()
  }

  isCognitoUser(): boolean {
    return this.authService.isCognitoUser()
  }

  async signUp(email: string, password: string, locale: string) {
    await this.authService.signUp(email, password, locale)
  }

  async register(draft: UserDraft) {
    const user = await this.userApi.register(draft)
    this._user.set(user)
  }

  async update(user: Partial<UserDraft>) {
    const newUser = await this.userApi.update(user)
    this._user.set(newUser)
  }

  updateStripeStatus(status: StripeAccountStatus) {
    this._user.update((user) => (user ? { ...user, stripeAccountStatus: status } : user))
  }

  async confirmationCodeSignUp(email: string, code: string) {
    await this.authService.confirmationCodeSignUp(email, code)
  }

  async resendConfirmationCodeSignUp(email: string) {
    await this.authService.resendConfirmationCodeSignUp(email)
  }

  async sendForgotPasswordConfirmationCode(email: string) {
    await this.authService.sendForgotPasswordConfirmationCode(email)
  }

  async changePasswordWithCode(email: string, code: string, newPassword: string) {
    await this.authService.changePasswordWithCode(email, code, newPassword)
  }

  async retrieveUser() {
    const user = await this.userApi.getUser()
    this._user.set(user)
    return user
  }

  async checkUsernameAvailable(username: string) {
    if (username === this.user.get()?.username) {
      return true
    }
    return this.userApi.checkUsername(username)
  }

  async logout() {
    if (!this._disconection) {
      try {
        this._disconection = true
        await this.authService.logout()
        await this.serviceManager.onLoggedOut()
        this._user.set(null)
        this.authService.resetUser()
      } catch (e) {
        throw e
      } finally {
        this._disconection = false
      }
    }
  }
}
