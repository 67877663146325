import { word } from "@modules/core/utils/i18n"
import { Exercise } from "@modules/exercises/exerciseTypes"
import { ProgramInfiniteSession, ProgramOnOffSession } from "@modules/programSession/programSessionTypes"
import { ScoreboardIndivView } from "@modules/scoreboard/scoreboardIndivView"
import { ScoreboardRanking } from "@modules/scoreboard/scoreboardTypes"
import { ScoreboardView } from "@modules/scoreboard/scoreboardView"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"

export const useScoreboardSidebar = () => {
  const { sidebarNavigateTo } = useSidebar()

  const navigateToScoreboardView = (
    session: ProgramInfiniteSession | ProgramOnOffSession,
    programId: string,
    scoreExercicesRequested: Exercise[],
  ) => {
    sidebarNavigateTo({
      content: (
        <ScoreboardView session={session} programId={programId} scoreExercicesRequested={scoreExercicesRequested} />
      ),
      leftButtonText: word("program.planning.create.exercise.returnSession"),
    })
  }

  const navigateToScoreboardIndivView = (userScore: ScoreboardRanking, isMyRanking: boolean) => {
    sidebarNavigateTo({
      content: <ScoreboardIndivView userScore={userScore} isMyRanking={isMyRanking} />,
    })
  }

  return {
    navigateToScoreboardView,
    navigateToScoreboardIndivView,
  }
}
