export enum LoggerType {
  ApiSuccess = "🔵 [API] ",
  ApiError = "🔴 [API] ",
  Audio = "🔊 [Audio] ",
  Analytics = "📊 [Analytics] ",
  Box = "📦 [Box] ",
  BoxSlot = "📆 [BoxSlot] ",
  Browser = "⛵ [Browser] ",
  Config = "⚙️  [Config] ",
  Chrono = "⏱️ [Chrono] ",
  Chat = "💬 [Chat] ",
  DynamicLink = "🔗 [DynamicLink] ",
  Error = "❌ ",
  FeatureToggle = "⚙️  [FeatureToggle] ",
  Financial = "💸 [Finances] ",
  Login = "👤 [Login] ",
  Notif = "🔔 [Notif] ",
  Permission = "🔑 [Permission] ",
  Profile = "👤 [Profile] ",
  Program = "💪 [Program] ",
  LocalStorage = "💾 [LocalStorage] ",
  Service = "🔧 [Service] ",
  Store = "🏪 [Store] ",
  Stripe = "💰 [Stripe] ",
  SignUp = "👤 [SignUp] ",
  Token = "⚙️  [Token] ",
  UI = "🎨 [UI] ",
  Wording = "🔤 [Wording] ",
  Exercise = "🏋️‍♀️ [Exercise] ",
}
