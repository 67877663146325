import backgroundProfile from "@images/backgroundProfile.png"
import { GriboullisIcon } from "@images/svgIcons/griboullisIcon"
import { HustleupSmallIcon } from "@images/svgIcons/hustleUpSmallIcon"
import { useUser } from "@modules/auth/hooks/useUser"
import { word } from "@modules/core/utils/i18n"
import { NewsResume } from "@modules/dashboard/components/newsResume"
import { ObjectivesResume } from "@modules/dashboard/components/objectivesResume"
import { RightNowResume } from "@modules/dashboard/components/rightNowResume"
import { SessionsResume } from "@modules/dashboard/components/sessionsResume"
import { ProfileAvatar } from "@modules/profile/components/profileAvatar"
import { HUText } from "@modules/ui/components/huText"
import { HURow, HUStack } from "@modules/ui/components/layout"
import { LoadingSpinner } from "@modules/ui/components/loading"
import styled, { useTheme } from "styled-components"

export const DashboardPage: React.FC = () => {
  const user = useUser()
  const theme = useTheme()
  return (
    <>
      {user ? (
        <Container>
          <ColOneContainer>
            <AvatarContainer $bgImage={backgroundProfile} className="align-items-center justify-content-center gap-2">
              <ProfileAvatar profile={{ avatar: user.avatar, username: user.username ?? "" }} size={80} />
              <HUStack gap={0} className="text-align-left">
                <HUText fontStyle="BL" numberOfLine={2}>{`${user.firstname} ${user.lastname}`}</HUText>
                <HUText fontStyle="BS">@{user.username}</HUText>
              </HUStack>
              <HustleupSmallIconStyled />
            </AvatarContainer>
            <HustleToday>
              <HUText fontStyle="BM">{word("home.screen.title", { firstname: user.firstname })}</HUText>
              <HUText fontStyle="HM">{word("home.screen.subtitle")}</HUText>
              {/* <CrossfitLogoIcon color={theme.mainBlack} width={120} /> */}
              <GribouillisIconStyled color={theme.primaryColor} />
            </HustleToday>
            <SessionsResume />
          </ColOneContainer>
          <ColTwoContainer>
            <RightNowResume />
            <ObjectivesResume />
          </ColTwoContainer>
          <ColNewsContainer>
            <NewsResume />
          </ColNewsContainer>
        </Container>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 3.6rem);
  justify-content: space-between;
  width: 100%;
  min-height: 670px;
`

const ColOneContainer = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media screen and (max-width: 1350px) {
    width: 49%;
  }
  @media screen and (max-width: 1015px) {
    height: unset;
    width: 100%;
  }
`
const ColTwoContainer = styled.div`
  width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media screen and (max-width: 1350px) {
    width: 49%;
  }
  @media screen and (max-width: 1015px) {
    height: unset;
    width: 100%;
  }
`
const ColNewsContainer = styled.div`
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media screen and (max-width: 1350px) {
    margin-top: 10px;
    width: 100%;
    height: unset;
  }
`
const AvatarContainer = styled(HURow)<{ $bgImage: string }>`
  position: relative;
  border-radius: 33px;
  border-width: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background-image: ${({ $bgImage }) => `url(${$bgImage})`};
  background-size: cover;
  background-position: center;
  padding: 25px 0px;

  @media screen and (max-width: 1015px) {
    display: none !important;
  }
`
const HustleupSmallIconStyled = styled(HustleupSmallIcon)`
  position: absolute;
  top: 0;
  right: 0;
  width: 42%;
  opacity: ${({ theme }) => theme.avatarCardBackgroundIconOpacity};
`

const HustleToday = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
`

const GribouillisIconStyled = styled(GriboullisIcon)`
  position: absolute;
  bottom: 22px;
  left: 232px;
`
