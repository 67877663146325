import { HUSidebarContext } from "@modules/sidebar/context/sidebarContext"
import { useContext } from "react"

export const useSidebar = () => {
  const {
    sidebarNavigateTo,
    sidebarNavigateBack,
    sidebarPopToTop,
    hideSidebar,
    setCustomHeaderIcons,
    isSidebarVisible,
  } = useContext(HUSidebarContext)

  return {
    sidebarNavigateTo,
    sidebarNavigateBack,
    sidebarPopToTop,
    hideSidebar: (runOnHideCallback?: boolean) => hideSidebar.current(runOnHideCallback),
    setCustomHeaderIcons,
    isSidebarVisible,
  }
}
