import { word } from "@modules/core/utils/i18n"
import { requiredInput } from "@modules/form/formUtils"
import { ProgramOnOffDraft, ProgramOnOffTypeOf } from "@modules/programs/programOnOffTypes"
import { ProgramVisibility, SharedCoach } from "@modules/programs/programTypes"
import { HUImage } from "@modules/types/imageTypes"
import * as Yup from "yup"

export type ProgramOnOffInputKey =
  | "image"
  | "title"
  | "visibility"
  | "description"
  | "defaultHideSessionContent"
  | "entranceCheckEnabled"
  | "duration"
  | "sharedCoaches"

export function getValidationSchema(options?: { imageRequired: boolean }) {
  return Yup.object({
    image: options?.imageRequired ? Yup.mixed().required(word("program.form.uploadImage.requiredText")) : Yup.object(),
    title: requiredInput(
      Yup.string()
        .max(255, word("form.maximum.characters", { count: 255 }))
        .trim(),
    ),
    description: requiredInput(Yup.string().trim()),
    duration: Yup.number()
      .min(1, word("program.duration.minimum.message"))
      .max(365, word("program.duration.maximum.message"))
      .required(word("global.form.required"))
      .integer(),
  })
}

export const convertProgramOnOffDraftToFormik = (program: ProgramOnOffDraft | undefined): ProgramOnOffFormikType => {
  return {
    image: program?.image,
    title: program?.name ?? "",
    description: program?.description ?? "",
    visibility: program?.visibility === ProgramVisibility.PUBLIC,
    defaultHideSessionContent: program?.defaultHideSessionContent ?? false,
    entranceCheckEnabled: program?.entranceCheckEnabled ?? false,
    duration: program?.duration,
    sharedCoaches: program?.sharedCoaches,
  }
}

export const convertProgramOnOffFormFormik = (formik: ProgramOnOffFormikType): ProgramOnOffDraft => {
  return {
    _programType: ProgramOnOffTypeOf,
    image: formik.image,
    name: formik.title,
    visibility: formik.visibility ? ProgramVisibility.PUBLIC : ProgramVisibility.PRIVATE,
    description: formik.description,
    defaultHideSessionContent: formik.defaultHideSessionContent,
    entranceCheckEnabled: formik.entranceCheckEnabled,
    duration: formik.duration,
    sharedCoaches: formik.sharedCoaches,
  }
}

export type ProgramOnOffFormikType = {
  image?: HUImage
  title: string
  description: string
  visibility: boolean
  defaultHideSessionContent: boolean
  entranceCheckEnabled: boolean
  duration?: number
  sharedCoaches?: SharedCoach[]
}
