import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const BoxOfferTrialIcon: React.FC<IconSvgProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 25" fill="none" {...props}>
    <mask
      id="mask0_7983_96039"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="4"
      width="15"
      height="21"
    >
      <path
        d="M0.800781 22.663C0.800781 22.663 0.800781 24.1865 4.1743 24.1865C7.54781 24.1865 14.6075 18.4682 14.6075 18.4682V4.89243C14.6075 4.89243 7.54781 10.6107 4.1743 10.6107C0.800781 10.6107 0.800781 9.08718 0.800781 9.08718L3.86719 17.0697L0.800781 22.663Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_7983_96039)">
      <path
        d="M0.800781 22.663C0.800781 22.663 0.800781 24.1865 4.1743 24.1865C7.54781 24.1865 14.6075 18.4682 14.6075 18.4682V4.89243C14.6075 4.89243 7.54781 10.6107 4.1743 10.6107C0.800781 10.6107 0.800781 9.08718 0.800781 9.08718L3.86719 17.0697L0.800781 22.663Z"
        fill="#3B3184"
      />
      <path
        d="M29.409 10.6314C29.409 18.1178 21.6793 28.1362 14.1929 28.1362C6.70657 28.1362 3.62012 15.7985 3.62012 8.31219C3.62012 0.825821 8.36738 -2.92383 15.8537 -2.92383C23.3401 -2.92383 29.409 3.14507 29.409 10.6314Z"
        fill="#6450F7"
      />
    </g>
    <mask
      id="mask1_7983_96039"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="37"
      y="6"
      width="15"
      height="20"
    >
      <path
        d="M51.1992 24.1083C51.1992 24.1083 51.1992 25.6318 47.8257 25.6318C44.4522 25.6318 37.3925 19.9136 37.3925 19.9136V6.33774C37.3925 6.33774 44.4522 12.056 47.8257 12.056C51.1992 12.056 51.1992 10.5325 51.1992 10.5325L48.1328 18.515L51.1992 24.1083Z"
        fill="#6450F7"
      />
    </mask>
    <g mask="url(#mask1_7983_96039)">
      <path
        d="M51.1992 24.1083C51.1992 24.1083 51.1992 25.6318 47.8257 25.6318C44.4522 25.6318 37.3925 19.9136 37.3925 19.9136V6.33774C37.3925 6.33774 44.4522 12.056 47.8257 12.056C51.1992 12.056 51.1992 10.5325 51.1992 10.5325L48.1328 18.515L51.1992 24.1083Z"
        fill="#3B3184"
      />
      <path
        d="M70.0848 12.565C70.0848 20.0514 62.3551 30.0698 54.8687 30.0698C47.3823 30.0698 44.2959 17.7321 44.2959 10.2458C44.2959 2.75941 49.0432 -0.990234 56.5295 -0.990234C64.0159 -0.990234 70.0848 5.07866 70.0848 12.565Z"
        fill="#6450F7"
      />
    </g>
    <mask
      id="mask2_7983_96039"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="6"
      y="0"
      width="39"
      height="22"
    >
      <path
        d="M6.33081 7.09781C6.33081 7.09781 13.8775 0.82373 23.6612 0.82373C33.445 0.82373 44.2959 8.38977 44.2959 8.38977V21.9656C44.2959 21.9656 33.445 14.3996 23.6612 14.3996C13.8775 14.3996 6.33081 20.6736 6.33081 20.6736V7.09781Z"
        fill="#6450F7"
      />
    </mask>
    <g mask="url(#mask2_7983_96039)">
      <path
        d="M6.33081 7.09781C6.33081 7.09781 13.8775 0.82373 23.6612 0.82373C33.445 0.82373 44.2959 8.38977 44.2959 8.38977V21.9656C44.2959 21.9656 33.445 14.3996 23.6612 14.3996C13.8775 14.3996 6.33081 20.6736 6.33081 20.6736V7.09781Z"
        fill="#3B3184"
      />
      <circle cx="16.2435" cy="8.74395" r="15.4427" fill="#6450F7" />
    </g>
    <path
      d="M11.4886 14.6566C10.4708 15.1464 9.5239 15.2771 8.67514 15.1052L8.79367 14.0732C9.53493 14.2388 10.3408 14.1527 11.0665 13.8035C11.8298 13.4362 12.1947 12.8196 11.8772 12.1599C11.5734 11.5284 11.0045 11.4076 9.80317 11.6607C8.43778 11.9347 7.42881 11.8399 6.90273 10.7467C6.38572 9.67236 6.95591 8.46945 8.26588 7.83906C9.246 7.3674 10.0281 7.3044 10.6853 7.46403L10.5773 8.42135C9.9522 8.3043 9.40425 8.34746 8.67858 8.69666C7.93407 9.05494 7.57446 9.63422 7.86018 10.228C8.14136 10.8123 8.73084 10.8071 9.79118 10.5987C11.2456 10.3166 12.2587 10.4442 12.8211 11.6128C13.4152 12.8474 12.7797 14.0353 11.4886 14.6566Z"
      fill="white"
    />
    <path
      d="M17.9618 4.76954L18.2106 5.69902L15.8263 6.33718L17.4785 12.5102L16.4277 12.7914L14.7756 6.6184L12.3508 7.26737L12.1021 6.33789L17.9618 4.76954Z"
      fill="white"
    />
    <path
      d="M43.8237 10.8682L43.3269 11.6922L41.2131 10.4179L37.9138 15.8906L36.9823 15.329L40.2815 9.85631L38.1319 8.56037L38.6287 7.73633L43.8237 10.8682Z"
      fill="white"
    />
    <path
      d="M25.7941 12.2046L25.253 10.6244L21.7234 10.4291L21.0009 11.9394L19.8626 11.8764L23.4225 4.70965L24.2161 4.75356L26.9428 12.2681L25.7941 12.2046ZM23.7257 6.23478L22.1403 9.51991L24.9285 9.67418L23.7257 6.23478Z"
      fill="white"
    />
    <path
      d="M34.2655 14.5146L33.0377 14.0972L32.2962 11.4369C32.1839 11.4098 32.075 11.3728 31.9561 11.3324L30.7085 10.9082L29.9779 13.0569L28.958 12.7102L31.3247 5.74902L33.6814 6.55026C35.3053 7.10238 36.1347 8.33437 35.5995 9.90879C35.2561 10.9188 34.4522 11.4961 33.4266 11.5561L34.2655 14.5146ZM32.2427 10.4245C33.3319 10.7949 34.2336 10.4497 34.5399 9.54857C34.8833 8.53857 34.3256 7.77453 33.3156 7.43115L32.0383 6.99686L31.0148 10.0071L32.2427 10.4245Z"
      fill="white"
    />
  </svg>
)
