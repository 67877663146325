import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { ExportModal } from "@modules/finances/components/exportModal"
import { TransactionTableFilter } from "@modules/finances/components/transactionTableFilter"
import { boxTransactionsColumns, headerColumn, valueColumn } from "@modules/finances/components/transactionsColumns"
import { TransactionsDetailsPage } from "@modules/finances/components/transactionsDetailsPage"
import { useTransactions } from "@modules/finances/hooks/useTransactions"
import { allTransactionsStatus } from "@modules/finances/types/financesType"
import { useLanguage } from "@modules/language/hooks/useLanguage"
import { useSidebar } from "@modules/sidebar/hooks/useSidebar"
import { DataTable, renderTableColumns } from "@modules/ui/components/dataTables/datatable"
import { ListHeader } from "@modules/ui/components/header"
import { HUButton } from "@modules/ui/components/huButton"
import { HUDropdown } from "@modules/ui/components/huDropdown"
import { HUText } from "@modules/ui/components/huText"
import { HURow } from "@modules/ui/components/layout"
import { usePersistedDatatable } from "@modules/utils/hooks/useDataTable"
import { useObservable } from "micro-observables"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTableValue } from "primereact/datatable"

import { useState } from "react"
import styled, { DefaultTheme, useTheme } from "styled-components"

export const BoxTransactionsTable: React.FC = () => {
  const { size, setSize } = usePersistedDatatable(20, true)
  const { boxFinanceService } = useServicesContext()
  const language = useLanguage()
  const [displayModal, setDisplayModal] = useState(false)
  const lastUpdateLoading = useObservable(boxFinanceService.lastUpdateLoading)
  const { value, loading, next, previous } = useTransactions(size)
  const theme = useTheme()
  const [selectedTransaction, setSelectedTransaction] = useState<DataTableValue>()
  const { sidebarNavigateTo } = useSidebar()

  const tableLoading = lastUpdateLoading || loading
  const sizeOptions = [
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 50, label: "50" },
  ]

  const getRightView = () => {
    return (
      <>
        <TransactionTableFilter filters={["ALL", ...allTransactionsStatus]} />
        <Button
          rounded
          icon="pi pi-reply"
          label={word("global.export")}
          className="p-button-primary"
          onClick={() => setDisplayModal(true)}
        />
      </>
    )
  }

  const onSelectedTransaction = (e: DataTableValue) => {
    sidebarNavigateTo({
      content: <TransactionsDetailsPage transactionId={e.id} />,
      onHide: () => {
        setSelectedTransaction(undefined)
        return Promise.resolve(true)
      },
      replaceAllComponents: true,
    })
    setSelectedTransaction(e)
  }

  return (
    <div className="flex flex-column card flex-grow-1" style={{ gap: 28 }}>
      <ListHeader title={word("finances.page.table.title")} rightView={getRightView()} />
      <StyledDatatable
        value={[...value]}
        dataKey="id"
        paginator
        lazy
        currentPageReportTemplate={""}
        rows={size}
        totalRecords={size}
        onPage={(event) => setSize(event.rows)}
        paginatorTemplate=""
        loading={tableLoading}
        emptyMessage={word("global.no_result")}
        onSelectionChange={(e) => onSelectedTransaction(e.value)}
        selection={selectedTransaction}
        selectionMode="single"
        metaKeySelection={true}
        footer={
          (next || previous) && (
            <FooterRow align gap={14}>
              <HURow gap={8}>
                <HUButton
                  colorType="Tertiary"
                  type="Default"
                  size="S"
                  onClick={() => previous?.()}
                  disabled={!previous}
                  text={word("global.previous")}
                />
                <HUButton
                  type="Default"
                  size="S"
                  colorType="Tertiary"
                  onClick={() => next?.()}
                  disabled={!next}
                  text={word("global.next")}
                />
              </HURow>
              <Separator />
              <SearchSize align gap={8}>
                <HUText fontStyle="LL" color={theme.neutralColor600}>
                  {word("finances.page.table.footer.label")}
                </HUText>
                <HUDropdown value={size} options={sizeOptions} onChange={(e) => setSize(e)} itemFontSize="0.9rem" />
              </SearchSize>
            </FooterRow>
          )
        }
      >
        {renderTableColumns([...boxTransactionsColumns], headerColumn, valueColumn, theme as DefaultTheme, language)}
        <Column
          body={(transaction) => (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <EyeButton
                icon="pi pi-eye"
                outlined
                className="p-button-rounded mr-2"
                onClick={() => {
                  onSelectedTransaction(transaction)
                }}
              />
            </div>
          )}
        />
      </StyledDatatable>
      <ExportModal onHide={() => setDisplayModal(false)} visible={displayModal} />
    </div>
  )
}

const StyledDatatable = styled(DataTable)`
  .p-paginator-bottom {
    border: none;
  }
`

const FooterRow = styled(HURow)`
  justify-content: center;

  button {
    min-width: 110px;
  }

  .p-inputtext {
    font-size: 0.9rem;
  }
`

const Separator = styled.div`
  display: flex;
  width: 1px;
  height: 42px;
  background-color: ${({ theme }) => theme.neutralColor200};
`

const SearchSize = styled(HURow)``

const EyeButton = styled(Button)`
  &.p-button.p-button-outlined {
    color: ${({ theme }) => theme.textPrimary};
  }
`
