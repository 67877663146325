/* eslint-disable react-hooks/rules-of-hooks */
import {
  AuthContent,
  AuthForm,
  AuthFormError,
  AuthHeader,
  AuthSubmitButton,
} from "@modules/auth/components/authComponents"
import { LoginBack } from "@modules/auth/components/loginBack"
import { AuthPage } from "@modules/auth/page/authPage"
import { useServicesContext } from "@modules/core/services/services.context"
import { word } from "@modules/core/utils/i18n"
import { Path } from "@modules/navigation/routes"
import { HUInput } from "@modules/ui/components/huInput"
import { HUText } from "@modules/ui/components/huText"
import { LoggerType } from "@modules/utils/loggerUtils"
import { useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import styled, { useTheme } from "styled-components"

export const ConfirmEmailPage: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const location = useLocation() as { state?: { email?: string } }
  const email = location.state?.email
  if (!email) {
    return <Navigate to={Path.Login} />
  }

  const { userService } = useServicesContext()
  const [loading, setLoading] = useState(false)

  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [infoCode, setInfoCode] = useState("")
  const resendConfirmationCode = async () => {
    try {
      await userService.resendConfirmationCodeSignUp(email)
      setInfoCode(word("auth.check_signup.form.notif_code_resend"))
    } catch (e) {
      console.error(LoggerType.SignUp + "resend confirmation code", e)
    }
  }
  const confirmationCode = async () => {
    try {
      setLoading(true)
      await userService.confirmationCodeSignUp(email, code)
      const currentUser = userService.isCognitoUser()
      if (currentUser) {
        navigate(Path.Register)
      } else {
        navigate(Path.Login)
      }
    } catch (e) {
      console.error(LoggerType.SignUp + "confirmation code", e)
      setError(word("auth.check_signup.form.wrong_code"))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setInfoCode("")
    setError("")
  }, [code])

  return (
    <AuthPage>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault()
          confirmationCode()
        }}
      >
        <AuthHeader
          title={word("auth.check_signup.form.check_account")}
          subtitle={word("auth.check_signup.form.check_account_subtitle")}
        />
        <AuthContent>
          <HUInput
            placeholder={word("auth.check_signup.form.code")}
            value={code}
            onChange={(e) => setCode(e.currentTarget.value)}
            hasError={!!error}
          />

          <ResendCodeButton type="button" onClick={() => resendConfirmationCode()}>
            <ResendCode>{word("auth.check_signup.form.resend_code")}</ResendCode>
          </ResendCodeButton>
          <AuthSubmitButton loading={loading}>{word("global.check")}</AuthSubmitButton>
          <AuthFormError color={infoCode === "" ? theme.textWarning : theme.textEmphasized}>
            {infoCode || error}
          </AuthFormError>

          <LoginBack />
        </AuthContent>
      </AuthForm>
    </AuthPage>
  )
}

const ResendCodeButton = styled.button`
  display: contents;
  align-self: flex-end;
`

const ResendCode = styled(HUText)`
  text-align: right;
  color: ${({ theme }) => theme.textPrimary};
  text-decoration: underline;
`
