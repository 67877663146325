import { IconSvgProps } from "@modules/ui/components/huButtonIcon"
import React from "react"

export const MarkerIcon: React.FC<IconSvgProps> = (props) => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9993 29.2565C19.6399 29.2565 27.3743 20.7288 27.3743 13.9787C27.3743 7.22855 21.8338 1.75647 14.9993 1.75647C8.16474 1.75647 2.62427 7.22855 2.62427 13.9787C2.62427 20.7288 10.3586 29.2565 14.9993 29.2565ZM14.9997 18.2566C17.2779 18.2566 19.1247 16.4098 19.1247 14.1316C19.1247 11.8534 17.2779 10.0066 14.9997 10.0066C12.7216 10.0066 10.8747 11.8534 10.8747 14.1316C10.8747 16.4098 12.7216 18.2566 14.9997 18.2566Z"
      fill={props.color}
    />
  </svg>
)
