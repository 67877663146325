import { AsyncObservable } from "@modules/asyncObservable/asyncObservable"
import { Service } from "@modules/core/services/serviceType"
import { Objective, ObjectiveDraft } from "@modules/objectives/objectivesTypes"
import { ObjectivesApi } from "@modules/objectives/services/objectivesApi"

export class ObjectivesService implements Service {
  constructor(private api: ObjectivesApi) {}
  objectivePrimary = new AsyncObservable<Objective | undefined>(() => this.getObjectivesPrimary())

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async init(): Promise<void> {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onLoggedOut(): Promise<void> {}

  getObjectivesPrimary() {
    return this.api.getObjectivePrimary()
  }

  async createNewObjective(objective: ObjectiveDraft) {
    return await this.api.createNewObjective(objective)
  }

  async updateOjective(objective: ObjectiveDraft) {
    return await this.api.updateObjective(objective)
  }

  async deleteObjective(id: string) {
    await this.api.deleteObjective(id)
  }
}
